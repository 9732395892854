import { useState, useEffect } from 'react';
import DialogModal from './DialogModal';
import BookDetailSnippet from '../../shared-react-components/elements/BookDetailSnippet';
import { Dropdown, Form, Input, Radio } from 'semantic-ui-react';
import _ from 'lodash';
import './PlaceClaimModal.scss';

import { DAMAGE_TYPES } from '../../utils/const';
const damageOptions = Object.entries(DAMAGE_TYPES).map((value) => {
  return {
    key: value[0],
    text: value[1],
    value: value[0],
  };
});

export const PlaceClaimModal = (props) => {
  const {
    onClose,
    bookModalData,
    isOpen,
    claimReason,
    quantityClaimed,
    currentClaim,
    addOrRemoveFromClaims,
  } = props;
  const [state, setState] = useState({
    quantity: 0,
    damageType: undefined,
    requestReplacementBook: false,
  });

  useEffect(() => {
    if (currentClaim) {
      setState({
        quantity: currentClaim.quantity,
        damageType: currentClaim.damageType,
        requestReplacementBook: currentClaim.requestReplacementBook,
      });
    }
  }, [currentClaim]);
  const onSubmit = () => {
    addOrRemoveFromClaims({
      isbn13: bookModalData.isbn,
      purchaseOrderNumber: bookModalData.purchaseOrderNumber,
      salesOrderNumber: bookModalData.salesOrderNumber,
      claimReason: claimReason,
      quantity: state.quantity,
      damageType: state.damageType,
      oldDamageType: currentClaim ? currentClaim.damageType : state.damageType,
      requestReplacementBook: state.requestReplacementBook,
      price: bookModalData.price,
      coverPrice: bookModalData.coverPrice,
      invoiceItem: bookModalData.invoiceItem,
    });
  };

  return (
    <DialogModal
      open={isOpen}
      closeOnEscape={true}
      modalContentClass="id-modal-content"
      closeOnDimmerClick={true}
      onClose={onClose}
      options={{
        className: 'id-modal-style',
      }}
      dialogOptions={{
        header: `REPORT ${
          claimReason.toUpperCase() === 'DAMAGE' ? 'DAMAGED' : 'MISSING'
        } BOOKS`,
        actions: [
          {
            label: 'Cancel',
            color: 'white',
            action: () => {
              onClose();
            },
          },
          {
            label: 'Save',
            color: 'blue',
            backgroundColorOverride: 'blue',
            textColorOverride: 'white',
            borderHoverColorOverride: 'grey',
            iconColorOverride: 'white',
            icon: 'checkmark',
            bold: true,
            disabled:
              quantityClaimed +
                state.quantity -
                (currentClaim ? currentClaim.quantity : 0) >
                bookModalData.quantity ||
              (claimReason === 'DAMAGE' && !state.damageType) ||
              state.quantity === 0,
            preAction: () => ({ proceed: true }),
            action: () => {
              onSubmit();
              if (!currentClaim) {
                setState({
                  quantity: 0,
                  damageType: undefined,
                  requestReplacementBook: false,
                });
              }
              return { proceed: true };
            },
          },
        ],
      }}
    >
      <div className="id-modal-book-row">
        <BookDetailSnippet data={bookModalData} />
      </div>
      <Form className="pcm-form">
        <Form.Group>
          <Form.Field width="5">
            <label>
              Quantity {claimReason === 'DAMAGE' ? 'Damaged' : 'Missing'}
            </label>
            <label style={{ display: 'flex', alignItems: 'center' }}>
              <Input
                type="number"
                min="0"
                max={bookModalData.quantity}
                value={state.quantity}
                onChange={(e, data) =>
                  setState({ ...state, quantity: Number(data.value) })
                }
              />
              <div className="of1">of {bookModalData.quantity}</div>
            </label>
          </Form.Field>
          <Form.Field width="3" />
          {claimReason === 'DAMAGE' && (
            <Form.Field width="8">
              <label>Type of Damage</label>
              <Dropdown
                className="pcm"
                onChange={(e, item) =>
                  setState({ ...state, damageType: item.value })
                }
                options={damageOptions}
                placeholder="Select a type"
                value={state.damageType}
              />
            </Form.Field>
          )}
        </Form.Group>
        <div className="pc-title">
          Request Replacement Books?{' '}
          {bookModalData.isVariant && bookModalData.orderReq > 0 ? (
            <span className="pc-variant-rule">(Title not eligible)</span>
          ) : (
            ''
          )}
        </div>
        <Form.Group>
          <Form.Field style={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              label="Yes"
              name="radioGroup"
              value={true}
              disabled={
                bookModalData.isVariant && bookModalData.orderReq > 0
                  ? true
                  : false
              }
              checked={
                bookModalData.isVariant && bookModalData.orderReq > 0
                  ? false
                  : state.requestReplacementBook === true
              }
              onChange={(e, { value }) =>
                setState({ ...state, requestReplacementBook: value })
              }
            />
          </Form.Field>
          <Form.Field style={{ display: 'flex', alignItems: 'center' }}>
            <Radio
              label="No"
              name="radioGroup"
              value={false}
              disabled={
                bookModalData.isVariant && bookModalData.orderReq > 0
                  ? true
                  : false
              }
              checked={
                bookModalData.isVariant && bookModalData.orderReq > 0
                  ? false
                  : state.requestReplacementBook === false
              }
              onChange={(e, { value }) =>
                setState({ ...state, requestReplacementBook: value })
              }
            />
          </Form.Field>
        </Form.Group>
      </Form>
      {bookModalData.isVariant && bookModalData.orderReq > 0 && (
        <div className="fort-book ml-4 mr-4 mb-2">
          <span className="fort-bold">Note: </span> This title is ineligible for
          order after FOC. Please proceed with your claim for credit and contact
          customer service to request replacements.
        </div>
      )}
    </DialogModal>
  );
};
