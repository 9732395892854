import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cartApi } from "../api/cartApi";
import { ecomApi } from "../api/ecomApi";
import { estimateCartApi } from "../api/estimateCartApi";
import _ from "underscore";
import { isGeneralCart } from "../utils/utilities";

const initialState = {
  customerInfo: {
    shipTos: [],
    shipToAccountNos: [],
    customer: { country: "US", webShippable: [] },
  },
  cart: { items: [] },
  saveForLater: { items: [] },
  estimateCarts: [],
  dirtyTrigger: false,
  loadingTakeoverConfig: {},
  errorMsg: false,
  debug: false, //can set to console logs
};

// export const signUserOut = createAsyncThunk(
//   'user/signUserOut',
//   // Declare the type your function argument here:
//   async (params, {dispatch, getState, rejectWithValue}) => {
//     console.log('Starting signUserOut');
//     await createConfig({
//       clientId: configFile.clientId,
//       redirectUri: configFile.redirectUri,
//       endSessionRedirectUri: configFile.endSessionRedirectUri,
//       discoveryUri: configFile.discoveryUri,
//       scopes: configFile.scopes,
//       requireHardwareBackedKeyStore: configFile.requireHardwareBackedKeyStore,
//     });

//     console.log('Checking for download Tasks');
//     let lostTasks = await RNBackgroundDownloader.checkForExistingDownloads();
//     for (let task of lostTasks) {
//       console.log('Running download task', task);
//       task.stop();
//     }
//     dispatch(removeAllDownloads());

//     await AsyncStorage.removeItem('userSession');

//     try {
//       await clearTokens();
//     } catch (e) {
//       console.log('clearToken', e);
//     }

//     dispatch(resetLibraryAll());
//   },
// );

const ecomSlice = createSlice({
  name: "ecom",
  initialState,
  reducers: {
    resetEcom: (state, action) => {
      return initialState;
    },
    setDirtyOff: (state, action) => {
      // state.dirtyTrigge,r = !state.dirtyTrigger;
      state.dirtyTrigger = action.payload;
    },
    setLoadingTakeoverConfig: (state, action) => {
      state.loadingTakeoverConfig = action.payload;
    },
    setCart: (state, action) => {
      const { cart } = action.payload;
      state.cart = cart;
    },
    clearCart: (state, action) => {
      state.cart = { items: [] };
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      ecomApi.endpoints.getCustomerUserInfo.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        // console.log("getcust", action);
        let shipToAddress = 0;
        let shipTo = -1;
        const shipToAccountNos = data.customer.webShippable.map((s) => {
          return data.shipTos[s];
        });

        state.customerInfo = { ...data, shipToAccountNos };
      }
    );
    builder.addMatcher(
      ecomApi.endpoints.getCustomerInfo.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        // console.log("getcust", action);
        let shipToAddress = 0;
        let shipTo = -1;
        const shipToAccountNos = data.customer.webShippable.map((s) => {
          return data.shipTos[s];
        });

        state.customerInfo = { ...data, shipToAccountNos };
      }
    );

    builder.addMatcher(
      cartApi.endpoints.getUserCart.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        // console.log("getcust", action);
        state.cart = data;
        // console.log(
        //   "toggleing dirty from ",
        //   state.dirtyTrigger,
        //   !state.dirtyTrigger
        // );
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.createCart.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        // console.log("getcust", action);
        state.cart = data;
        // console.log(
        //   "toggleing dirty from ",
        //   state.dirtyTrigger,
        //   !state.dirtyTrigger
        // );
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.removeUserCartItem.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        // console.log("removeUserCartItem result", data);
        state.cart = data;
        // console.log(
        //   "toggleing dirty from ",
        //   state.dirtyTrigger,
        //   !state.dirtyTrigger
        // );
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.removeUserCart.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        // console.log("removeUserCart result", data);
        state.cart = data;
        // console.log(
        //   "toggleing dirty from ",
        //   state.dirtyTrigger,
        //   !state.dirtyTrigger
        // );
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.updateCartHeaders.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        // console.log("removeUserCart result", data);
        state.cart = data;
        // console.log(
        //   "toggleing dirty from ",
        //   state.dirtyTrigger,
        //   !state.dirtyTrigger
        // );
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.updateUserItemQty.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        // console.log("updateUserItemQty result", data);
        state.cart = data;
        // console.log(
        //   "toggleing dirty from ",
        //   state.dirtyTrigger,
        //   !state.dirtyTrigger
        // );
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.addUserCartItems.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        // console.log("addUserCartItems result", data);
        state.cart = data;
        // console.log(
        //   "toggleing dirty from ",
        //   state.dirtyTrigger,
        //   !state.dirtyTrigger
        // );
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );

    builder.addMatcher(
      estimateCartApi.endpoints.getEstimateCarts.matchFulfilled,
      (state, action) => {
        if (!!!action.meta.arg.originalArgs.skipCartLoad) {
          const { data } = action.payload;
          if (action.meta.arg.originalArgs.foc) {
            const newEstimateCarts = state.estimateCarts.map((nec) => {
              let nnec = { ...nec };
              data.carts.forEach((cart) => {
                if (nec.foc === cart.foc) {
                  nnec = cart;
                }
              });
              return nnec;
            });
            state.estimateCarts = newEstimateCarts;
            state.dirtyTrigger = false;
            state.loadingTakeoverConfig = {};
          } else {
            state.estimateCarts = data.carts;
            state.dirtyTrigger = false;
            state.loadingTakeoverConfig = {};
          }
        }
      }
    );

    builder.addMatcher(
      estimateCartApi.endpoints.addVariantsToCart.matchFulfilled,
      (state, action) => {
        // console.log("addvariant", action);
        const { data } = action.payload;
        if (!data?.modalInfo) {
          const existingEstimateCarts = state.estimateCarts.map((nec) => {
            let nnec = { ...nec };
            data.carts.forEach((cart) => {
              if (nec.foc === cart.foc) {
                nnec = cart;
              }
            });
            return nnec;
          });

          const newEstimateCarts = data.carts.filter((nec) => {
            let fnd = true;
            state.estimateCarts.forEach((cart) => {
              if (nec.foc === cart.foc) {
                fnd = false;
              }
            });
            return fnd;
          });
          state.estimateCarts = [...newEstimateCarts, ...existingEstimateCarts];

          // state.estimateCarts = newEstimateCarts;
        }
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      estimateCartApi.endpoints.updateVariantsToCart.matchFulfilled,
      (state, action) => {
        // console.log("addvariant", action);
        const { data } = action.payload;
        if (!data?.modalInfo) {
          const newEstimateCarts = state.estimateCarts.map((nec) => {
            let nnec = { ...nec };
            data.carts.forEach((cart) => {
              if (nec.foc === cart.foc) {
                nnec = cart;
              }
            });
            return nnec;
          });
          state.estimateCarts = newEstimateCarts;
        }
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      estimateCartApi.endpoints.addEstimateCartItems.matchFulfilled,
      (state, action) => {
        // console.log("addEstimateCartItems", action);
        const { data } = action.payload;

        if (data?.modalInfo) {
          // console.log("found modal");
          if (data.modalInfo === "ADDED_ITEMS") {
            // console.log("adding cart");
            const existingEstimateCarts = state.estimateCarts.map((nec) => {
              let nnec = { ...nec };
              data.updatedCarts.forEach((cart) => {
                if (nec.foc === cart.foc) {
                  nnec = cart;
                }
              });
              return nnec;
            });
            const newEstimateCarts = data.updatedCarts.filter((nec) => {
              let fnd = true;
              state.estimateCarts.forEach((cart) => {
                if (nec.foc === cart.foc) {
                  fnd = false;
                }
              });
              return fnd;
            });
            const sortedfocCarts = _.sortBy(
              [...newEstimateCarts, ...existingEstimateCarts],
              (i) => {
                if (isGeneralCart(i.foc)) return "0000-00-00";
                else return i.foc;
              }
            );
            state.estimateCarts = [...sortedfocCarts];
          }
        } else {
          // console.log("adding cart");
          const existingEstimateCarts = state.estimateCarts.map((nec) => {
            let nnec = { ...nec };
            data.carts.forEach((cart) => {
              if (nec.foc === cart.foc) {
                nnec = cart;
              }
            });
            return nnec;
          });
          const newEstimateCarts = data.carts.filter((nec) => {
            let fnd = true;
            state.estimateCarts.forEach((cart) => {
              if (nec.foc === cart.foc) {
                fnd = false;
              }
            });
            return fnd;
          });
          const sortedfocCarts = _.sortBy(
            [...newEstimateCarts, ...existingEstimateCarts],
            (i) => {
              if (isGeneralCart(i.foc)) return "0000-00-00";
              else return i.foc;
            }
          );
          state.estimateCarts = [...sortedfocCarts];
        }
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      estimateCartApi.endpoints.updateEstimateItemQty.matchFulfilled, //jeffishere
      (state, action) => {
        const { data } = action.payload;
        if (!data?.modalInfo) {
          let newEstimateCarts = [];
          // console.log("update qty", data, state);
          if (data?.carts) {
            newEstimateCarts = state.estimateCarts.map((nec) => {
              let nnec = { ...nec };
              data.carts.forEach((cart) => {
                if (nec.foc === cart.foc) {
                  nnec = cart;
                }
              });
              return nnec;
            });
          } else {
            newEstimateCarts = state.estimateCarts.filter((nec) => {
              if (nec.foc === action.meta.arg.originalArgs.foc) {
                return false;
              }
              return true;
            });
          }

          state.estimateCarts = newEstimateCarts;
        }
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      estimateCartApi.endpoints.removeEstimateCartItems.matchFulfilled,
      (state, action) => {
        // console.log("action", action);
        const { data } = action.payload;
        if (!data?.modalInfo) {
          if (data.carts.length === 0) {
            const newEstimateCarts = state.estimateCarts.filter((nec) => {
              if (nec.foc === action.meta.arg.originalArgs.foc) return false;
              else return true;
            });
            state.estimateCarts = newEstimateCarts;
          } else {
            const newEstimateCarts = state.estimateCarts.map((nec) => {
              let nnec = { ...nec };
              data.carts.forEach((cart) => {
                if (nec.foc === cart.foc) {
                  nnec = cart;
                }
              });
              return nnec;
            });
            state.estimateCarts = newEstimateCarts;
          }
        }
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      estimateCartApi.endpoints.removeEstimateCarts.matchFulfilled,
      (state, action) => {
        // console.log("removeEstimateCarts", state, action);
      }
    );
    builder.addMatcher(
      estimateCartApi.endpoints.confirmEstimateCart.matchFulfilled,
      (state, action) => {
        // console.log("confirmestimatecart", state, action);
        const { data } = action.payload;
        if (!data?.modalInfo) {
          if (data.carts.length === 0) {
            const { foc } = action.meta.arg.originalArgs.data;
            // console.log("foc confirmed delete", foc);
            if (foc) {
              const newEstimateCarts = state.estimateCarts.filter((nec) => {
                if (nec.foc === foc) {
                  return false;
                } else {
                  return true;
                }
              });
              // console.log("updating cart from confirmation");
              state.estimateCarts = newEstimateCarts;
            }
          } else {
            const newEstimateCarts = state.estimateCarts.map((nec) => {
              let nnec = { ...nec };
              data.carts.forEach((cart) => {
                if (nec.foc === cart.foc) {
                  nnec = cart;
                }
              });
              return nnec;
            });
            // console.log("updating cart from confirmation");
            state.estimateCarts = newEstimateCarts;
          }
        }
        state.loadingTakeoverConfig = {};
        state.dirtyTrigger = false;
      }
    );
    builder.addMatcher(
      estimateCartApi.endpoints.undoEstimateCart.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;

        if (!data?.modalInfo) {
          const newEstimateCarts = state.estimateCarts.map((nec) => {
            let nnec = { ...nec };
            data.carts.forEach((cart) => {
              if (nec.foc === cart.foc) {
                nnec = cart;
              }
            });
            return nnec;
          });
          state.estimateCarts = newEstimateCarts;
        }
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.getUserSaveForLater.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        state.saveForLater = data;
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.removeUserSaveForLaterItem.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        state.saveForLater = data;
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.addUserSaveForLaterItem.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        state.saveForLater = data;
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.updateItemSaveForLaterQty.matchFulfilled,
      (state, action) => {
        const { data } = action.payload;
        state.saveForLater = data;
        state.dirtyTrigger = false;
        state.loadingTakeoverConfig = {};
      }
    );
    builder.addMatcher(
      cartApi.endpoints.submitOrder.matchFulfilled,
      (state, action) => {
        const newEstimateCarts = state.estimateCarts.filter((nec) => {
          if (nec.foc === "2099-12-31") {
            return false;
          } else {
            return true;
          }
        });
        state.estimateCarts = newEstimateCarts;
        // state.dirtyTrigger = false;
        // state.loadingTakeoverConfig = {};
        // state.cart = { items: [] };
      }
    );
  },
});

export const {
  resetEcom,
  setDirtyOff,
  setLoadingTakeoverConfig,
  setCart,
  clearCart,
  setErrorMsg,
} = ecomSlice.actions;

export default ecomSlice.reducer;
