import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import './OrderDetailPopup.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { OrderDetailDropdownRowMobile } from './OrderDetailDropdownRowMobile';

export const OrderDetailPopup = ({
  onClose,
  items,
  status,
  menuVisible,
  history,
  reqDeliveryDate,
  shippingFrom,
  invoice,
  trackingId,
  trackingUrl,
}) => {
  return (
    <div
      id="order-detail-popup"
      className={`shown-on-mobile mr-2 order-detail-popup ${
        menuVisible === undefined ? '' : menuVisible ? 'mobileIn' : 'mobileOut'
      }`}
    >
      <div className="order-detail-popup-header">
        <div className="fort-bold fs-12px text-uppercase">
          {status} ({items.length})
        </div>
        <div className="order-detail-popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} color="#fa6400" />
        </div>
      </div>
      <div className="dropdown-separator" />
      <div className="order-detail-popup-header-information">
        <Container>
          <Grid>
            <Grid.Row className="my-1">
              <Grid.Column width={8}>Req. Delivery Date</Grid.Column>
              <Grid.Column width={8}>
                <span className="d-flex justify-content-end">
                  {moment(reqDeliveryDate).format('MMM Do, YYYY')}
                </span>
              </Grid.Column>
            </Grid.Row>
            {shippingFrom && (
              <Grid.Row className="my-1">
                <Grid.Column width={8}>Shipped From</Grid.Column>
                <Grid.Column width={8}>
                  <span className="d-flex justify-content-end">
                    {shippingFrom}
                  </span>
                </Grid.Column>
              </Grid.Row>
            )}
            {trackingId && (
              <Grid.Row className="my-1">
                <Grid.Column width={8}>Tracking #</Grid.Column>
                <Grid.Column width={8}>
                  <a
                    className="d-flex justify-content-end"
                    href={trackingUrl}
                    target="_blank"
                  >
                    {trackingId}
                  </a>
                </Grid.Column>
              </Grid.Row>
            )}
            {invoice && (
              <Grid.Row className="my-1">
                <Grid.Column width={8}>Invoice #</Grid.Column>
                <Grid.Column width={8}>
                  <a
                    className="d-flex justify-content-end"
                    href={`/invoices/${invoice}`}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/invoices/${invoice}`);
                    }}
                  >
                    {invoice}
                  </a>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Container>
      </div>
      <div className="dropdown-separator" />
      <Container>
        {items.map((item, index) => (
          <>
            <OrderDetailDropdownRowMobile
              book={item}
              isEditing={false}
              key={
                item.isbn +
                (item?.deliveries?.shipping
                  ? item?.deliveries?.invoiceNo +
                    item?.deliveries?.shipping?.trackingCode +
                    item?.deliveries?.shipping?.vemng
                  : '')
              }
              status={status}
              history={history}
            />
            {index < items.length - 1 && <div className="dropdown-separator" />}
          </>
        ))}
      </Container>
    </div>
  );
};
