import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import './InvoiceDetailPopup.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvoiceDetailDropdownRowMobile } from './InvoiceDetailDropdownRowMobile';

export const InvoiceDetailPopup = ({
  onClose,
  items,
  menuVisible,
  history,
  purchaseOrder,
  salesOrder,
}) => {
  return (
    <div
      id="invoice-detail-popup"
      className={`shown-on-mobile mr-2 invoice-detail-popup ${
        menuVisible === undefined ? '' : menuVisible ? 'mobileIn' : 'mobileOut'
      }`}
    >
      <div className="invoice-detail-popup-header">
        <div className="fort-bold fs-12px text-uppercase">
          {items?.length} {items?.length === 1 ? 'Item' : 'Items'}
        </div>
        <div className="invoice-detail-popup-close" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} color="#fa6400" />
        </div>
      </div>
      <div className="dropdown-separator" />
      <div className="invoice-detail-popup-header-information">
        <Container className="py-2">
          <Grid>
            <Grid.Row className="mb-1">
              <Grid.Column width={8}>Purchase Order:</Grid.Column>
              <Grid.Column width={8} className="d-flex justify-content-end">
                {purchaseOrder}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>Sales Order:</Grid.Column>
              <Grid.Column width={8} className="d-flex justify-content-end">
                {salesOrder}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
      <div className="dropdown-separator" />
      <Container>
        {items?.map((item, index) => (
          <React.Fragment key={item.isbn13 + '_' + index}>
            <InvoiceDetailDropdownRowMobile
              book={{
                ...item,
                purchaseOrderNumber: purchaseOrder,
                salesOrderNumber: salesOrder,
              }}
              isEditing={false}
              key={item.isbn13 + item.invoiceItem}
              history={history}
            />
            {items && index < items.length - 1 && (
              <div className="dropdown-separator" />
            )}
          </React.Fragment>
        ))}
      </Container>
    </div>
  );
};
