import { Component } from 'react';
import { Loader, Modal } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import withApp from '../hc/withApp';
import withAccount from '../hc/withAccount';
import PageStandard from '../elements/PageStandard';
import { Link } from 'react-router-dom';
import { PRHContainerGeneral } from '../elements/elements';
import {
  PRHLabel,
  PRHContainer,
  PRHContainerHeader,
  PRHIcon,
} from '../../shared-react-components/elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import './registration.scss';

class VerifySubAccountResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyEmail: 0,
      loading: true,
    };
  }

  render() {
    const { account } = this.props;
    return (
      <>
        <PRHContainer className="content-block-border">
          {account.formState.updateResponse &&
          account.formState.updateResponse === 'selfreg_not_found' ? (
            <>
              <PRHContainerHeader>
                Account verified!
                </PRHContainerHeader>
              <br />
              You're all set to purchase books and manage your Penguin Random House account.
              <br />
              <br />
              <div className={'button-holder'}>
                <PRHButton
                  iconright
                  icon="arrow right"
                  size="large"
                  className={'ml-auto'}
                  type="submit"
                  borderColorOverride="blue"
                  textColorOverride="white"
                  textColorHoverOverride="blue"
                  backgroundColorOverride="blue"
                  backgroundHoverColorOverride="white"
                  iconColorOverride="white"
                  iconColorHoverOverride="blue"
                  onClick={(e) => this.props.history.push('/login')}
                >
                  Sign In
                </PRHButton>
              </div>
              <br />
              <br />
            </>
          ) : (
            ''
          )}

          {account.formState.updateResponse &&
          account.formState.updateResponse === 'VERIFY_NOT_FOUND' ? (
            <>
              <div className={'white-box-header fort-light'}>
                Registration Failed
              </div>
              <br />
              We were unable to find your account please contact your
              administrator or contact{' '}
              <Link to="mailto:customerservice@penguinrandomhouse.com">
                support
              </Link>
              .
              <br />
              <br />
            </>
          ) : (
            ''
          )}

          {account.formState.updateResponse &&
          account.formState.updateResponse === 'FAILED' ? (
            <>
              <div className={'white-box-header fort-light'}>
                Registration Failed
              </div>
              <br /> 
              We were unable to register your account. Please contact
              <Link to="mailto:customerservice@penguinrandomhouse.com">customer support</Link>.<br/>
              {account.formState.systemResponse}
              .
              <br />
              <br />
            </>
          ) : (
            ''
          )}
        </PRHContainer>
      </>
    );
  }
}

export default withRouter(withApp(withAccount(VerifySubAccountResponse)));
