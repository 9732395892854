import { Route, Switch } from "react-router-dom";
import { Callback } from "../components/auth/callback";
import Logout from "../components/auth/logout";
import { LogoutCallback } from "../components/auth/logoutCallback";
// import { Register } from '../components/auth/register';
import { SilentRenew } from "../components/auth/silentRenew";
import withSecurity from "../components/hc/withSecurity";
import Admin from "../pages/admin/Admin";
import Cancel from "../pages/cancel/Cancel";
import CartOverviewPage from "../pages/cart/CartOverviewPage";
import CartPage from "../pages/cart/CartPage";
import Checkout from "../pages/checkout/Checkout";
import CompCopyLogin from "../pages/compcopylogin/CompCopyLogin";
import CustomerEnrolment from "../pages/customerenrolment/CustomerEnrolment";
import Error from "../pages/error/Error";
// import PostsWrapper from "../pages/external/PostsWrapper";
import Home from "../pages/home/Home";
import { InvoiceDetail } from "../pages/invoicedetail/InvoiceDetail";
import { Returns } from "../pages/returns/Returns";
import { ReturnsTable } from "../pages/returns/ReturnsTable";
import Login from "../pages/login/Login";
import MigrateAccount from "../pages/migrate/MigrateAccount";
import { OrderDetail } from "../pages/orderdetail/OrderDetail";
import OrderInvoice from "../pages/orderinvoice/OrderInvoice";
import OrderSummary from "../pages/ordersummary/OrderSummary";
import LoginHelpPage from "../pages/registration/LoginHelpPage";
import RecoverPassword from "../pages/registration/RecoverPassword";
import RegisterSubAccount from "../pages/registration/RegisterSubAccount";
import Registration from "../pages/registration/Registration";
import ResendInvitation from "../pages/registration/ResendInvitation";
import ResetPassword from "../pages/registration/ResetPassword";
import VerifyEmail from "../pages/registration/VerifyEmail";
import VerifySubAccount from "../pages/registration/VerifySubAccount";
import VerifySubAccountLanding from "../pages/registration/VerifySubAccountLanding";
import Search from "../pages/search/Search";
// import ExampleGrid from "../pages/styleGuide/exampleGrid";
// import StyleGuide from "../pages/styleGuide/styleGuide";
import { TitleInfoPage } from "../pages/title/TitleInfoPage";
import Catalog from "../pages/catalog/Catalog";
import CatalogArchive from "../pages/catalog/CatalogArchive";
import { PrivateRoute } from "./privateRoute";
import { PublicAuthRoute } from "./publicAuthRoute";
import PostsWrapper from "../pages/external/PostsWrapper";
import { Series } from "../pages/series/Series";
import { Author } from "../pages/author/Author";
import SSO from "../components/auth/SSO";
import RegistrationContactUs from "../components/registration/RegistrationContactUs";
import DeliveryDetail from "../pages/deliverydetail/DeliveryDetail";
import StatementDetail from "../pages/statementdetail/StatementDetail";

export const Routes = withSecurity((props) => (
  <Switch>
    {props.user.getDomain().startsWith("compcopy") ? (
      <PrivateRoute exact={true} path='/' component={Home} />
    ) : (
      <PublicAuthRoute
        exact={true}
        path='/'
        publicComponent={Login}
        secureComponent={Home}
      />
    )}

    <Route exact={true} path='/silentrenew' component={SilentRenew} />
    <Route exact={true} path='/signin-oidc' component={Callback} />
    <Route exact={true} path='/logout' component={Logout} />
    <Route exact={true} path='/logout/callback' component={LogoutCallback} />
    <PrivateRoute exact={true} path='/cclogin' component={CompCopyLogin} />
    {/* <Route
      exact={true}
      path="/:lng(en|es|de|fr|pt|it)/register/:form?"
      component={Register}
    /> */}

    <PrivateRoute path='/sso' component={SSO} />
    <PrivateRoute path='/admin/:component' component={Admin} />

    {/* <Route exact={true} path="/" component={Login} /> */}
    <PrivateRoute exact={true} path='/checkout' component={Checkout} />
    <PrivateRoute
      exact={true}
      path={["/cart", "/cart/:cartType", "/cart/:shipTo/:focDate"]}
      component={CartPage}
    />
    <PrivateRoute
      exact={true}
      path={["/cart-overview", "/cart-overview/:cartType"]}
      component={CartOverviewPage}
    />
    <PrivateRoute exact={true} path='/ordersummary' component={OrderSummary} />
    <PrivateRoute exact={true} path='/orders' component={OrderInvoice} />
    <PrivateRoute
      exact={true}
      path='/orders/:orderId'
      component={OrderDetail}
    />
    <PrivateRoute exact={true} path='/deliveries' component={OrderInvoice} />
    <PrivateRoute
      exact={true}
      path='/delivery/:deliveryNo'
      component={DeliveryDetail}
    />
    <PrivateRoute exact={true} path='/statements' component={OrderInvoice} />
    <PrivateRoute
      exact={true}
      path='/statement/:statementNo'
      component={StatementDetail}
    />
    <PrivateRoute exact={true} path='/invoices' component={OrderInvoice} />
    <PrivateRoute
      exact={true}
      path='/invoices/:invoiceId'
      component={InvoiceDetail}
    />
    <PrivateRoute exact={true} path='/backorders' component={OrderInvoice} />
    <PrivateRoute exact={true} path='/series/:seriesId' component={Series} />
    <PrivateRoute exact={true} path='/author/:authorId' component={Author} />

    <PrivateRoute exact={true} path='/returns/' component={Returns} />

    <PrivateRoute exact={true} path={"/search"} component={Search} />

    <PrivateRoute
      exact={true}
      path={["/titleinfo/:titleId"]}
      component={TitleInfoPage}
    />
    <PrivateRoute
      exact={true}
      path='/titleinfo/pow/:titleId'
      component={TitleInfoPage}
    />
    <PrivateRoute
      exact={true}
      path={["/catalog", "/catalog/:catalogId"]}
      component={Catalog}
    />
    <PrivateRoute
      exact={true}
      path={["/catalog-archive"]}
      component={CatalogArchive}
    />
    {/* <Route exact={true} path='/styleguide' component={StyleGuide} /> */}
    {/* <Route exact={true} path='/examplegrid' component={ExampleGrid} /> */}
    <Route exact={true} path='/login' component={Login} />
    <Route exact={true} path='/registration' component={Registration} />
    <Route exact={true} path='/contactus' component={RegistrationContactUs} />
    <Route exact={true} path='/update' component={MigrateAccount} />
    <Route
      exact={true}
      path='/verifysub/:token'
      component={VerifySubAccountLanding}
    />
    <Route
      exact={true}
      path='/update-subaccount'
      component={VerifySubAccount}
    />
    <Route
      exact={true}
      path='/register-subaccount'
      component={RegisterSubAccount}
    />
    <Route
      exact={true}
      path='/newaccountenrollment'
      component={CustomerEnrolment}
    />
    <Route exact={true} path='/error' component={Error} />
    <Route exact={true} path='/verifylegacy' component={MigrateAccount} />
    <Route exact={true} path='/verify/:token' component={VerifyEmail} />
    <Route exact={true} path='/recover-password' component={RecoverPassword} />
    <Route
      exact={true}
      path='/reset-password/:token'
      component={ResetPassword}
    />
    <Route exact={true} path='/recover' component={LoginHelpPage} />
    <Route
      exact={true}
      path='/resend-invitation'
      component={ResendInvitation}
    />
    <Route exact={true} path='/cancel' component={Cancel} />

    {/* WordPress Routes */}
    <PrivateRoute
      path={[
        "/:postType/category/:category/page/:page",
        "/:postType/category/:category",
        "/:postType/page/:page",
        "/:postType/:postId",
        "/:postType",
      ]}
      render={(routeInfo) => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let searchQuery = params.get("s");
        const postType = routeInfo?.match?.params?.postType
          ? routeInfo.match.params.postType
          : "news-from-prh";
        const categorySlug = routeInfo?.match?.params?.category
          ? routeInfo.match.params.category
          : null;
        const page = routeInfo?.match?.params?.page
          ? routeInfo.match.params.page
          : "1";
        const postId = routeInfo?.match?.params?.postId
          ? routeInfo.match.params.postId
          : null;
        return (
          <PostsWrapper
            postType={postType}
            categorySlug={categorySlug}
            page={page}
            postId={postId}
            searchQuery={searchQuery}
          />
        );
      }}
    />
  </Switch>
));
