import ReactGA from 'react-ga';


const EventTrigger = (category = "button", action) =>{
    return ReactGA.event({
        category,
        action
    })
}

export default EventTrigger;