import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class billingDocNumberRenderer extends Component {
  render() {
    const {
        billingDocNumber
    } = this.props.data;
    return (
        <a  href={`/invoices/${billingDocNumber}`} onClick={e => {e.preventDefault();this.props.history.push(`/invoices/${billingDocNumber}`)}}>
            {billingDocNumber}
        </a>
    );
  }
}

export default withRouter(billingDocNumberRenderer);
