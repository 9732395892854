import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Icon, Loader } from 'semantic-ui-react';
import { Divider } from '../../shared-react-components/elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import userRoleConversion from '../../utils/userRoleConversion';
//import withAdmin from '../hc/withAdmin';
import withAccount from '../hc/withAccount';
import withCart from '../hc/withCart';
import withSecurity from '../hc/withSecurity';
import './admin.scss';
import withMobile from "../hc/withMobile";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  handleClick(email) {
    const { account } = this.props;
    account.resendSubVerifyEmail(email);
  }

  editControl(status, header, userID, userStatus) {
    const { modalControl } = this.props;
    modalControl(status, header, userID, userStatus);
  }

  userInitials = (firstName, lastName) => {
    let i = '';

    if (firstName) {
      i += firstName.charAt(0);
    }
    if (lastName) {
      i += lastName.charAt(0);
    }

    return i;
  };

  isEditUser = (email, userStatus) => {
    const { cart, user } = this.props;

    // if (
    //   cart.customerInfo &&
    //   cart.customerInfo.currentUser &&
    //   cart.customerInfo.currentUser.roleNo === 'W9'
    // )
    if (cart.customerInfo?.currentUser?.roleNo === 'W9') return true;

    if (userStatus === 'legacy') return true;
    if (email === user.user.profile.email) return true;

    return false;
  };

  render() {
    // console.log('UserList', this.props);
    const { formState, account, cart } = this.props;
    const combinedUserList = formState.userList.filter(
      (user) => user.userStatus !== 'legacy'
    );

    //const shipTos = {};
    const shipTos = account.formState.addresses;
    cart.customerInfo.shipTos.forEach((shipTo) => {
      shipTos[shipTo.accountNo] = shipTo.address;
    });
    // console.log('addresse', account.formState.addresses);
    const isMobile = this.props.mobile.isMobile;
    return (
      <>
        <div className={`accounts p-relative ${isMobile ? ' mx-2 mt-4' : ' ml-4 mr-4 mt-4'}`}>
          {account.formState.userListLoading ? <Loader active /> : null}
          {combinedUserList
            ? combinedUserList.map((user, i) => {
                return (
                  <div key={i} className={isMobile ? 'mobile-catalogs-container border-gray-300 border-radius-3px p-2 pb-3 mb-3' : ''}>
                    <div className={`d-flex ${isMobile ? '' : 'mb-4'}`}>
                      <div className="d-flex flex-row">
                        <div className={`no-user-image mr-3 ${user.roleNo} hidden-on-mobile`}>
                          <div className="no-image-text">
                            {this.userInitials(
                              user.firstName.toUpperCase(),
                              user.lastName.toUpperCase()
                            )}
                          </div>
                        </div>
                        <div className={isMobile ? 'user-details-box-mobile' : ''}>
                          <span className={'userSubHeader'}>
                            {user.firstName} {user.lastName}
                          </span>
                          <br />
                          {user.email ? (
                            <>
                              {user.email}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {user.phone ? (
                            <>
                              {user.phone}
                              <br />
                            </>
                          ) : (
                            ''
                          )}

                          {user.roleNo === 'W1' &&
                          account.formState.addresses[user.accountNo] &&
                          account.formState.addresses[user.accountNo].name1 ? (
                            <>
                              {
                                account.formState.addresses[user.accountNo]
                                  .name1
                              }
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {user.roleNo === 'W1' &&
                          account.formState.addresses[user.accountNo] &&
                          account.formState.addresses[user.accountNo].name2 ? (
                            <>
                              {
                                account.formState.addresses[user.accountNo]
                                  .name2
                              }
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {user.roleNo === 'W1' &&
                          account.formState.addresses[user.accountNo] &&
                          account.formState.addresses[user.accountNo].name3 ? (
                            <>
                              {
                                account.formState.addresses[user.accountNo]
                                  .name3
                              }
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {user.roleNo === 'W1' &&
                          account.formState.addresses[user.accountNo] &&
                          account.formState.addresses[user.accountNo].street ? (
                            <>
                              {
                                account.formState.addresses[user.accountNo]
                                  .street
                              }
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {user.roleNo === 'W1' &&
                          account.formState.addresses[user.accountNo] &&
                          account.formState.addresses[user.accountNo].city ? (
                            <>
                              {account.formState.addresses[user.accountNo].city}
                              ,{' '}
                            </>
                          ) : (
                            ''
                          )}
                          {user.roleNo === 'W1' &&
                          account.formState.addresses[user.accountNo] &&
                          account.formState.addresses[user.accountNo].state ? (
                            <>
                              {
                                account.formState.addresses[user.accountNo]
                                  .state
                              }{' '}
                            </>
                          ) : (
                            ''
                          )}
                          {user.roleNo === 'W1' &&
                          account.formState.addresses[user.accountNo] &&
                          account.formState.addresses[user.accountNo]
                            .postalCode ? (
                            <>
                              {
                                account.formState.addresses[user.accountNo]
                                  .postalCode
                              }
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {/*account.formState.addresses[user.accountNo] &&
                          account.formState.addresses[user.accountNo].phone ? (
                            <>
                              {
                                account.formState.addresses[user.accountNo]
                                  .phone
                              }
                              <br />
                            </>
                          ) : (
                            ''
                          )*/}
                          {userRoleConversion(user.roleNo) ? (
                            <>
                              {userRoleConversion(user.roleNo)}
                              <br />
                            </>
                          ) : (
                            ''
                          )}
                          {user.userStatus === 'pending' ? (
                            <>
                              <Icon name="exclamation circle" />
                              Unverified{' '}
                              {cart.customerInfo &&
                              cart.customerInfo.currentUser &&
                              cart.customerInfo.currentUser.roleNo === 'W9' ? (
                                <Link
                                  onClick={() => this.handleClick(user.email)}
                                  to="#"
                                >
                                  Resend email.
                                </Link>
                              ) : (
                                ''
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className="ml-auto hidden-on-mobile">
                        {this.isEditUser(user.email, user.userStatus) ? (
                          <div className={'accounts-settings'}>
                            <PRHButton
                              // iconleft
                              fontAwesome={true}
                              icon={faPen}
                              size="small"
                              className="extra-space"
                              // className={'float-right margin-left'}
                              onClick={() =>
                                this.editControl(
                                  true,
                                  'Edit User',
                                  user.email,
                                  null
                                )
                              }
                            >
                              Edit
                            </PRHButton>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {!isMobile && i < combinedUserList.length - 1 ? (
                      <Divider className="mb-4" tight />
                    ) : null}
                  </div>
                );
              })
            : ''}
        </div>
      </>
    );
  }
}

export default withRouter(withSecurity(withAccount(withCart(withMobile(UserList)))));
