import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Container,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Message,
  Popup,
} from 'semantic-ui-react';
import { Divider } from '../../components/elements/elements';
import PageStandard from '../../components/elements/PageStandard';
import { ErrorComponent } from '../../components/ErrorComponent.jsx';
import withApp from '../../components/hc/withApp';
import withCart from '../../components/hc/withCart';
import withSecurity from '../../components/hc/withSecurity';
import PageHeader from '../../components/header/PageHeader';
import DialogModal from '../../components/modals/DialogModal';
import PRHButtonStandard from '../../shared-react-components/elements/prhbutton';
import { MOBILE_SCREEN_WIDTH, TOOLTIPS } from '../../utils/const';
import { Export, ExportCSV } from '../../utils/Export';
import { numberAsCurrency } from '../../utils/utilities';
import LoadingView from '../loading/LoadingView';
import './Returns.scss';
//import { InvoiceDetailDropdown } from './InvoiceDetailDropdown';
//import { InvoiceDetailRowsPrint } from './InvoiceDetailRowsPrint';
//import { InvoiceDetailRowsPrintAlphaSort } from './InvoiceDetailRowsPrintAlphaSort';
import { useMediaQuery } from 'react-responsive';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { MobileOptionsMenu } from '../../components/header/components/MobileOptionsMenu';
//import { InvoiceDetailMobile } from './InvoiceDetailMobile';
import { isMobileOnly } from 'react-device-detect';
import {
  addDecimal
} from '../../utils/utilities';

export const TitleRowReturns = withSecurity(
  withRouter(
    withApp(
      withCart((props) => {
        const isMobile = isMobileOnly;
        // const isMobile = useMediaQuery({
        //   query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
        // });
        const [state, setstate] = useState({
          returns: true,
        });
        const { data, editReturn, inputDisabled, correctReturn} = props;
        return (
        <>
        <Grid container padded className="cart-page mb-8">
        <Grid.Row 
          className="sg-content">
          <Grid.Column
            className="content-column d-lg-flex d-sm-none d-md-none pct-lg-1 pct-md-1 conditional-field column-adjust"
          >{data.isbn}
          </Grid.Column>
          <Grid.Column
            className="content-column d-lg-flex d-sm-none d-md-none pct-lg-2 pct-md-2 conditional-field column-adjust"
          ><Link to={`/titleinfo/${data.isbn}`}>{data.title}</Link>
          </Grid.Column>
          <Grid.Column
            className="header-column d-lg-none d-md-flex pct-lg-2 pct-md-2 column-adjust w-50"
          ><Link to={`/titleinfo/${data.isbn}`}>{data.title}</Link>
           {data.isbn}
          </Grid.Column>
          <Grid.Column
            className="content-column pct-lg-3 pct-md-3 column-adjust hide-mobile right-align-content"
          >{data.formatCode}
          </Grid.Column>
          <Grid.Column
            className="content-column pct-lg-4 pct-md-4 column-adjust hide-mobile right-align-content"
          >${data.listPrice.toFixed(2)}
          </Grid.Column>
          <Grid.Column
          className="content-column pct-lg-5 pct-md-5 column-adjust hide-mobile right-align-content"
          >${data.unitCost.toFixed(2)}
          </Grid.Column>
          <Grid.Column
            className="content-column pct-lg-6 pct-md-6 column-adjust w-25 right-align-content"
          >{data.qtyReturnable}
          </Grid.Column>
          <Grid.Column
            className={`content-column pct-lg-7 pct-md-7 column-adjust w-25 input-adjust last-column-mobile ${inputDisabled? ('right-align-content'): ''}`}
          >
          {!inputDisabled ? (<Input 
          className="qty-input" 
          placeholder={0} 
          value={data.qtyReturn ? data.qtyReturn : ''} 
          onChange={(e, isbn)=>editReturn(e, data.isbn)}
          onBlur={(e, isbn)=>correctReturn(e, data.isbn)}
          step="1"
          min="0"
          pattern="[0-9]*"
          type="number"
          disabled={inputDisabled}
          //nputmode="numeric"
          />): data.qtyReturn}
          </Grid.Column>
          <Grid.Column
            className="content-column pct-lg-8 pct-md-8 est-credit hide-mobile"
          ><div className="est-credit-content column-adjust">{
            data.qtyReturn==0 || !data.qtyReturn? 
          <div style={{width: '100%', textAlign: 'right', color:'#AAAAAA'}}>–</div> : '$' + (data.qtyReturn*data.unitCost).toFixed(2)
          }</div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </>
        );
      })
    )
  )
);



