import React from 'react';
import { formatDateMonthYear, formatDateSlashes } from '../../../utils/utilities';
import { useHistory } from 'react-router';
import { Icon } from 'semantic-ui-react';
import './ArchiveCatalogs.scss';



const ArchiveCatalogs = ({archive, currYear, type}) => {
  const history = useHistory();
  const parseArchives = (data, date, type) => {
    /*
    data.results && data.results.map((result, i) =>{
        archivesCurrent[result.year]['current']=result.current;
        archivesCurrent[result.year]['past']=result.past;
    });
    */
    const archive = data.filter(result => {
      return result.year===date;
    });
    return archive[0][type];
  }

  const goToCatalog = (catalogDate) => {
    history.push(`/catalog/${catalogDate}`);

  }

  if (!archive || archive.length === 0) {
    return <></>;
  }

  return (
    <div
      className={`pt-3px d-flex justify-content-between catalogs-homepage__emulated-flex-gap ${
        archive.length > 3 ? 'flex-wrap' : ''
      }`}
    >
      {parseArchives(archive, currYear, type) && parseArchives(archive, currYear, type).map((obj, i, array) => {
        let marginClass;
        if (array.length === 2) {
          marginClass = i === 0 ? 'mr-2' : 'ml-2';
        }
        if (array.length > 2) {
          marginClass = '';
          if (i === 0) {
            marginClass = '';
          }
          if (i === 2) {
            marginClass = '';
          }
        }

        return (
          <div className={`${''} catalogs-archive${
            archive.length === 4 ? '-half' : ''
          }  mb-4 min-w-400 max-w-500`} key={`${type}-${i}`}>
          <div
            className={'catalog-archive-element cursor-pointer'}
            onClick={()=>goToCatalog(obj.catalogDate)}
          >
            <div
              className={`${
                archive.length === 1 ? 'pb-3' : 'pb-3' // if we want it unbalanced make second one pb-4
              } ${marginClass} bg-gray-100 px-2 px-lg-3  pt-3 border-radius-top-left-3px border-radius-top-right-3px d-flex flex-flow-column justify-content-between height-60`}
            >
              <div
                className={'d-flex'/*`${
                  array.length < 3 || array.length === 4
                    ? `d-flex ${array.length === 1 ? 'align-items-center' : ''}`
                    : 'd-block d-lg-flex'
                }`*/}
              >
                <p
                  className={`m-0 text-center text-xl-left lh-0 ${
                    array.length < 3 ? '' : 'mr-2'
                  }`}
                >
                  <i
                    className={`d-inline-block archive-catalog-icon ${
                      array.length > 2 ? 'mb-2 mr-md-2 mr-xl-0' : 'mr-2'
                    }`}
                  />
                </p>
                <p
                  className={`fort-medium fs-16px lh-18px lsp--01  ${
                    array.length < 3
                      ? array.length === 1
                        ? 'mb-0'
                        : 'mb-2 mb-xl-3'
                      : 'mb-4 mb-xl-3'
                  }`}
                >
                  Catalog: {formatDateMonthYear(obj.catalogDate)}
                </p>
                <Icon
          className="fs-18px text-gray-500 go-to-catalog-icon ml-auto"
          name={`angle right`}
        />{' '}
              </div>

            </div>
            <div
              className={`border-gray-300 px-2 px-lg-3 pt-2 border-radius-bottom-left-3px border-radius-bottom-right-3px archive-holder`}
            >
              {obj.focs.map((obj, i, array) => {
                if (i < 7) {
                return (
                <div className={'category-archive-foc'} key={`${type}-${i}-3`}>FOC {formatDateSlashes(obj)}</div>
                );
                }
                if (i === 8) {
                 return (<div className={'category-archive-foc-plus'} key={`${type}-${i}-3-8`}>+{array.length - 7} more FOCs</div>)
                }
              })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ArchiveCatalogs;
