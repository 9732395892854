import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, Placeholder } from "semantic-ui-react";
import withApp from "../../components/hc/withApp";
import MultipleDocs from "../../assets/images/multiple-docs.svg";
import LoadingView from "../loading/LoadingView";
import NoRowsOrders from "./frameworkComponents/NoRowsOrders";
import salesOrderRenderer from "./frameworkComponents/salesOrderRenderer";
import { useMediaQuery } from "react-responsive";
import { Divider } from "../../components/elements/elements";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import MobileTable, {
  mobileTableColumnsOrders as mobileTableColumns,
} from "../../shared-react-components/elements/OrdersAndInvoicesMobileTable";
//import { useLazyGetRecentOrdersQuery } from "../../api/ecomApi";
import { useLazyGetRecentHistoryHomeQuery } from "../../api/ecomApi";
import {cloneDeep} from "lodash";

const RecentOrders = ({ app, ccSapAccountNo, shipTo = null }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  window.addEventListener("resize", () => {
    if (gridApi) gridApi.sizeColumnsToFit();
  });
  /*
  const [performGetRecentOrders, getRecentOrdersResult] =
    useLazyGetRecentOrdersQuery();
*/
  const [performGetRecentOrders, getRecentOrdersResult] =
    useLazyGetRecentHistoryHomeQuery();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [state, setState] = useState({
    pageSize: 50,
    rowHeight: 70,
    currentSort: "dateCreated_desc",
    columnDefs: [
      {
        headerName: "SALES ORDER #",
        field: "salesOrderNumber",
        // onCellClicked: ({ value }) => {
        //   history.push(`/orders/${value}`);
        // },
        cellRenderer: "salesOrderRenderer",
        cellClass: ["has-link"],
      },
      {
        headerName: "PURCHASE ORDER",
        field: "poNumber",
        checkboxSelection: false,
        headerCheckboxSelection: false,
      },
      {
        headerName: "DATE CREATED",
        field: "dateCreated",
        sortable: true,
      },
      {
        headerName: "SHIP-TO ACCOUNT",
        field: "account",
      },
    ],
    headerOnly: true,
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const getData = async () => {
    // console.log('running getData');
    let args = {};
    if (!!ccSapAccountNo) {
      args = {
        sapAccountNo: ccSapAccountNo,
      };
    }
    // console.log('recent orders args', {...args, shipTo});
    const result = await performGetRecentOrders({ ...args, shipTo });
    // console.log('recent orders', result);
    return result;
  };

  useEffect(() => {
    // console.log('running getRecentOrders');
    getData().then(({ data }) => {
      setDataLoaded(true);
      if (data.status === "OK") {
        setData(cloneDeep(data.data.orders));
      }
    });
  }, []);

  return (
    <>
      <div
        className={`${
          isMobile
            ? "border-gray-300 border-radius-3px p-3 mb-3 d-flex flex-column mt-3 white-background mobile-catalogs-container"
            : "mb-3 d-flex align-items-center"
        }`}
      >
        <div
          className={`d-flex flex-row ${
            isMobile
              ? "justify-content-space-between"
              : "d-flex justify-content-space-between w-100 align-items-center"
          }`}
        >
          <div
            className={`mb-0 fort-light text-gray-900 d-flex ${
              isMobile ? "fs-20px" : "fs-24px"
            }`}
          >
            <div
              style={{
                marginTop: "3px",
                marginLeft: ".2em",
                marginRight: ".5em",
              }}
            >
              <img src={MultipleDocs} alt='Multiple Document Icon' />
            </div>
            <div style={{ lineHeight: "1em", marginTop: "0.1em" }}>
              Recent Orders
            </div>
          </div>
          <div
            className={`lsp--02 fs-13px lh-18 fort-book ${
              isMobile ? "d-flex align-items-center" : ""
            }`}
          >
            <Link to='/orders' className=''>
              {isMobile ? "View All" : "View all orders"}
            </Link>
          </div>
        </div>
        {isMobile && <Divider tight className='mt-2 mb-2' />}
        {isMobile && (
          <MobileTable
            columns={mobileTableColumns}
            data={data}
            noDataMessage={<NoRowsOrders />}
          />
        )}
      </div>
      {!dataLoaded && (
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
      {!isMobile && dataLoaded && (
        <div
          className={`mb-5 h-100 ${
            !Array.isArray(data) || data.length === 0
              ? "ag-grid-root-child-no-items"
              : ""
          }`}
        >
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={state.columnDefs}
            rowData={data}
            rowHeight={state.rowHeight}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            pagination={true}
            defaultColDef={{
              sortable: true,
              minWidth: 150,
              flex: 1,
              resizable: true,
              cellClass: "stringRow",
              suppressMovable: true,
              suppressMenu: true,
            }}
            excelStyles={[
              {
                id: "stringRow",
                dataType: "string",
              },
            ]}
            suppressPaginationPanel={true}
            paginationPageSize={state.pageSize}
            domLayout='autoHeight'
            rowClass='grid-row-style'
            headerHeight={state.rowHeight}
            frameworkComponents={{
              salesOrderRenderer: salesOrderRenderer,
              customLoadingOverlay: LoadingView,
              noRowsOverlayComponent: NoRowsOrders,
            }}
            noRowsOverlayComponent={"noRowsOverlayComponent"}
            loadingOverlayComponent={"customLoadingOverlay"}
            suppressContextMenu={true}
          />
        </div>
      )}
    </>
  );
};

RecentOrders.defaultProps = {
  ccSapAccountNo: false,
};

export default withApp(withRouter(RecentOrders));
