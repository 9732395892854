import { Fragment, useState } from "react";
import { Icon } from "semantic-ui-react";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import "./customerenrolment.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Divider } from "../elements/elements";

const DownloadPdfCard = ({ document, downloadClick }) => {
  const { name, description, url, id } = document;
  return (
    <div className="download-card">
      <div className="download-info-container">
        <Icon name="file pdf outline" className="download-icon" color="red" />
        <div className="document-info">
          {url ? (
            <a
              className={`document-title ${url && "document-url"}`}
              onClick={() => downloadClick(id)}
              href={url}
              target="_blank"
              rel="noreferrer"
              download={`${name}.pdf`}
            >
              {name}
            </a>
          ) : (
            <div className="document-title">{name}</div>
          )}
          {description && (
            <div className="document-description">{description}</div>
          )}
        </div>
      </div>
      {url && (
        <a
          onClick={() => downloadClick(id)}
          href={url}
          target="_blank"
          rel="noreferrer"
          className="download-button"
          download={`${name}.pdf`}
        >
          Download
        </a>
      )}
    </div>
  );
};

const RequiredDocumentsForm = ({ data, documents, onNext, onBack }) => {
  const [documentsTracking, setDocumentsTracking] = useState([]);

  const handleDownloadedDocumentsTracking = (id) => {
    const newDocumentsTracking = [...documentsTracking];
    const docExists = newDocumentsTracking.find((doc) => doc === id);
    if (!docExists) {
      newDocumentsTracking.push(id);
      setDocumentsTracking(newDocumentsTracking);
    }
  };

  return <>
    <div className="bold-label mt-2 mb-2 text-uppercase">
      How to provide required information and documents
    </div>
    <div className="container mb-4 mt-1 documents-container p-4 upload-container">
      <ol className="documents-download-instructions">
        <li>Download documents listed below</li>
        <li>Fill in the downloaded documents</li>
        <li>Upload the documents on the next page</li>
      </ol>
      <div className="mt-1 fs-12px">
        Note that the online New Account Enrollment form does not support
        sign-up as a taxable customer, so you will need access to your tax
        documentation to proceed. If you do not have access to your tax
        documentation, or wish to be a taxable customers, please contact{" "}
        <a href="mailto:newaccount@penguinrandomhouse.com">
          newaccount@penguinrandomhouse.com
        </a>{" "}
        for a PDF or paper application.
      </div>
    </div>

    <div className="bold-label mt-5 mb-2 text-uppercase">
      Documents based on information you provided:
    </div>

    <Divider className="mb-2" />

    {documents.map((document) => (
      <Fragment key={document.name}>
        <DownloadPdfCard
          document={document}
          downloadClick={handleDownloadedDocumentsTracking}
        />
        <Divider className="mb-2" />
      </Fragment>
    ))}

    <div className="d-flex flex-row Xbutton-holder my-5">
      <PRHButton
        size="large"
        iconleft
        icon="arrow left"
        className="mr-auto"
        onClick={() => onBack(data)}
      >
        Back
      </PRHButton>
      <PRHButton
        iconright
        icon="arrow right"
        size="large"
        className="ml-auto"
        type="submit"
        onClick={onNext}
      >
        Upload Documents
      </PRHButton>
    </div>
  </>;
};

export default RequiredDocumentsForm;
