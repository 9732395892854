/* eslint-disable react/no-unused-state */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import DirtyContext from "./DirtyContext";
import LoadingTakeover from "../components/states/LoadingTakeover";
import LocalStorage from "../utils/editorFields/persisters/localStorage";
import SoftError from "../components/modals/SoftError";
import { useSelector } from "react-redux";

const beforeunload = (e) => {
  e.preventDefault();
  e.returnValue = true;
};
const unload = () => {
  if (window.mh2KeyObject) {
    const ls = new LocalStorage(window.mh2KeyObject);
    ls.clear();
  }
};

const DirtyProvider = (props) => {
  const { children } = props;
  const ecom = useSelector((state) => state.ecom);

  const defaultDialogObject = {
    dialogOptions: {
      actions: [
        {
          label: "Yes",
        },
        {
          preAction: async () => ({
            proceed: true,
            cancelAction: true,
          }),

          label: "Cancel",
        },
      ],
    },
  };

  const [loadingTakeover, setLoadingTakeover] = useState();
  const [loadingTakeoverConfig, setLoadingTakeoverConfig] = useState({});
  const [internalDirtyObject, setInternalDirtyObject] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [dialogObject, setDialogObject] = useState(defaultDialogObject);
  const [keyObject, setKeyObject] = useState(null);
  const [softErrorSettings, setSoftErrorSettings] = useState({ open: false });

  useEffect(() => {
    if (isDirty) {
      window.addEventListener("beforeunload", beforeunload);
      // had to bind this variable to the window and call this outside of the class definition
      // tried calling binding a function on unload in `useEffect({},[])`
      // and for some reason that function did not have access to this component's keyObject value
      // it kept showing up as null, so i am following suit with the "beforeunload" just binding
      // the value of the keyObject to window.mh2KeyObject = keyObject;
      // the app then nulls out window.mh2KeyObject when resetting the dirtystate
      window.mh2KeyObject = keyObject;
      window.addEventListener("unload", unload);
      return;
    }
    window.removeEventListener("beforeunload", beforeunload);
    window.removeEventListener("unload", unload);
  }, [isDirty]);

  useEffect(() => {
    // console.log("dirty turned", loadingTakeover);
    if (loadingTakeover === false) {
      setLoadingTakeoverConfig({
        text: "Loading . . .",
        longLoadText: "Still loading . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
    }
  }, [loadingTakeover]);

  useEffect(() => {
    // console.log("dirtyTrigger activated");
    // if (loadingTakeover === false) {

    setLoadingTakeover(ecom.dirtyTrigger);
    setLoadingTakeoverConfig(ecom.loadingTakeoverConfig);
    // }
  }, [ecom.dirtyTrigger, ecom.loadingTakeoverConfig]);
  /**
   * If we need to remove a key from the dirty check
   * @param {*} key
   */
  const removeKey = (key) => {
    const cloneInternalDirtyObject = { ...internalDirtyObject };
    delete cloneInternalDirtyObject[key];
    setInternalDirtyObject(cloneInternalDirtyObject);
  };

  const clearLocalState = () => {
    const ls = new LocalStorage(keyObject);
    ls.clear();
  };

  /**
   * Reset the dirty state
   */
  const resetDirtyState = () => {
    if (keyObject) {
      clearLocalState();
      setKeyObject(null);
      window.mh2KeyObject = null;
    }
    setInternalDirtyObject({});
  };

  /**
   * Used to set a key (id)'s dirty state
   * @param {string|number} id
   * @param {bool} dirty defaults to true
   */
  const setDirtyStateForId = (id, dirty = true) => {
    setInternalDirtyObject({ ...internalDirtyObject, [id]: dirty });
  };

  const triggerSoftErrorModal = (settings) => {
    setSoftErrorSettings({ ...settings, open: true });
  };

  /**
   * if the internalDirtyObject changes let's recalculate the dirty state
   */
  useEffect(() => {
    const dirty = _.values(internalDirtyObject).some((val) => !!val);
    setIsDirty(dirty);
    // setIsDirty(Object.values(internalDirtyObject).some((val) => !!val));
  }, [internalDirtyObject]);

  return (
    <DirtyContext.Provider
      value={{
        isDirty,
        setDirtyStateForId,
        removeKey,
        resetDirtyState,
        dialogObject,
        setDialogObject,
        setLoadingTakeover,
        loadingTakeover,
        setLoadingTakeoverConfig,
        loadingTakeoverConfig,
        setKeyObject,
        triggerSoftErrorModal,
      }}
    >
      <LoadingTakeover
        loadingTakeover={loadingTakeover}
        loadingTakeoverConfig={loadingTakeoverConfig}
      >
        {children}
      </LoadingTakeover>
      <SoftError
        open={softErrorSettings.open}
        softErrorSettings={softErrorSettings}
        onClose={() => setSoftErrorSettings({ open: false })}
      />
    </DirtyContext.Provider>
  );
};

DirtyProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node]),
};

DirtyProvider.defaultProps = {
  children: null,
};

export default DirtyProvider;
