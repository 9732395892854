import { Dropdown } from 'semantic-ui-react';
import { Export, ExportCSV } from '../../utils/Export';
import './DownloadDropdown.scss';

function DownloadDropdown(props) {
  const { fileName, data, classes, page, disabled } = props;
  let finalData = [];
  if (page === 'catalog') {
    finalData = data.map((item) => {
      return {
        'isbn/upc': item.isbn,
        quantity: '',
        isbnHyphenated: item.isbnHyphenated,
        title: item.title,
        subtitle: item.subtitle,
        author: item.author,
        onsale: item.onsale,
        catalog: item.catalog,
        foc: item.foc,
        formatName: item.formatName,
        divisionName: item.divisionName,
        cartonQuantity: item.cartonQuantity,
        inventoryMsg: item.inventoryMsg,
        managedMsg: item.managedMsg,
        currency: item.currency,
        price: item.price,
        variantRules: '',
        productChanges: '',
        demandCheck: '',
      };
    });
  } else {
    finalData = data;
  }

  const downloadOptions = [
    // {
    //   key: 'placeholder',
    //   text: 'Download',
    //   value: 'placeholder',
    // },
    {
      key: 'excel',
      text: 'Download Excel',
      value: 'excel',
      onClick: (e, v) => {
        handleDownload(v.value);
      },
    },
    {
      key: 'csv',
      text: 'Download CSV',
      value: 'csv',
      onClick: (e, v) => {
        handleDownload(v.value);
      },
    },
    // {
    //     key: 'pdf',
    //     text: 'Download PDF',
    //     value: 'pdf',
    // },
  ];

  const handleDownload = (downloadType) => {
    // console.log(data);
    // console.log(downloadType, fileName, data);
    switch (downloadType) {
      case 'excel':
        Export.Excel({
          fileName: fileName,
          csvData: finalData,
          sheetName: 'data',
        });
        break;
      case 'csv':
        ExportCSV.CSV({
          fileName: fileName,
          csvData: finalData,
          sheetName: 'data',
        });
        break;
      case 'pdf':
        break;
      default:
        break;
    }
  };

  return (
    <div className="download-dropdown-div">
      <Dropdown
        selection
        disabled={disabled}
        options={downloadOptions}
        // trigger={'Download'}
        // value={currentSort}
        placeholder="Download"
        direction="left"
        //   onChange={(e, v) => {
        //     handleDownload(v.value);
        //   }}
        defaultValue={'placeholder'}
        className={`download-dropdown ${classes}`}
      />
    </div>
  );
}

DownloadDropdown.defaultProps = {
  classes: '',
  page: '',
  disabled: false,
};

export default DownloadDropdown;
