import { Dropdown, Header, Image } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../../utils/const";
import cn from "classnames";

export const FormatsDropdown = ({ format, formats, formatKeys, setFormat }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const isTitleComic = format.format.code === "CB";

  const dropdownRow = (formatProp) => {
    return (
      <>
        <Header className='d-flex align-items-center tm-dropdown-divider'>
          <Image
            src={`https://images.penguinrandomhouse.com/cover/${formatProp.isbnStr}?alt=image_coming.gif`}
          />
          <Header.Content className='tm-dropdown-header-text align-items-center justify-content-center'>
            {formatProp.title.toUpperCase()}
            <Header.Subheader
              className={`tm-dropdown-subheader-text ${
                !formatProp.isVariant && "font-style-italic"
              }`}
            >
              {!formatProp.isVariant ? "Main Cover" : ""}
            </Header.Subheader>
          </Header.Content>
        </Header>
      </>
    );
  };
  const counter = (formats) => {
    let count = 0;
    formats.map((format) => {
      if (format.isVariant === true) {
        count++;
      }
    });
    return count;
  };

  return (
    <div className='tm-data-left-segment'>
      <div className='tm-info-text-style tm-text fort-bold mb-1'>
        {isTitleComic ? "COVERS" : "FORMATS"} (
        {isTitleComic ? counter(formats) : formats.length}
        {isTitleComic ? " VARIANTS" : ""}):
      </div>
      <Dropdown
        options={formats.map((f) => {
          return {
            key: f.isbnStr,
            value: f.isbnStr,
            text: formatKeys[f.format.code] || f.format.name,
            content: isTitleComic
              ? dropdownRow(f)
              : formatKeys[f.format.code] || f.format.name,
          };
        })}
        onChange={(_e, { value }) => {
          setFormat(formats.find((f) => f.isbnStr === value));
          window.history.pushState("object or string", "PRH Biz", `${value}`);
        }}
        value={format.isbnStr}
        item={true}
        // selection
        className={cn(
          isTitleComic ? "tm-dropdown tm-comic-dropdown" : "tm-dropdown",
          "tm-dropdown-mobile"
        )}
        trigger={
          isTitleComic
            ? dropdownRow(format)
            : formatKeys[format.format.code] || format.format.name
        }
      />
    </div>
  );
};
