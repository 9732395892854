import { Component } from 'react';
import { Form } from 'semantic-ui-react-form-validator';
import PropTypes from 'prop-types';
import './elements.scss';

class PRHForm extends Component {

validateEmail(userId) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (userId.match(mailformat)) {
      return true;
    } else {
      return false;
    }
}

render() {
        const { 
          children, 
          width, 
          ref, 
          instantValidate, 
          onSubmit, 
          method, 
          action,
          id 
        } = this.props;
        Form.addValidationRule('isPhoneNumber', (value) => {
          const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
          if (value.match(phoneno)) {
            return true;
          } else {
            return false;
          }
        });
        
        return (
      <Form
          width={width}
          ref={ref}
          instantValidate={instantValidate}
          onSubmit={onSubmit}
          method={method}
          action={action}
          id={id}
        >
        {children}
        </Form>
    );
}
}

PRHForm.propTypes = {
  width: PropTypes.number,
  ref: PropTypes.string,
  instantValidate: PropTypes.bool,
  onSubmit: PropTypes.func,
  method: PropTypes.string,
  action: PropTypes.string,
  id: PropTypes.string,
};

PRHForm.defaultProps = {
  width: 12,
  ref: '',
  instantValidate: true,
  onSubmit: ()=>{},
  method: 'post',
  action: '#',
  id: 'PropTypes.string',
};

export default PRHForm;