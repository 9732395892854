import { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  formatDate,
  isFocCart,
  isFocCartExpired,
  isGeneralCart,
} from "../../utils/utilities";
import { NumericFormat } from "react-number-format";
import { isIE } from "react-device-detect";
import { Icon, Grid, Form, Input, Popup } from "semantic-ui-react";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import InventoryMessage from "./InventoryMessage";
import { Divider } from "../elements/elements";
import withCart from "../hc/withCart";
import useSecurity from "../hc/useSecurity";
import { withRouter } from "react-router";
import withApp from "../hc/withApp";
import AddToCartButton from "./AddToCartButton";
import "./TitleRow.scss";
import LazyImage from "../../utils/LazyImage";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import VariantRestrictionsInfoModal from "../modals/VariantRestrictionsInfoModal";
import VariantRestrictionsOrderingModal from "../modals/VariantRestrictionsOrderingModal";
import VariantRestrictionsOrderUpdateModal from "../modals/VariantRestrictionsOrderUpdateModal";
import useCart from "../hc/useCart";
import ReturnAbilityStatus from "./ReturnAbilityStatus";

const TitleRowAgGrid = (props) => {
  const { divider, pageType, tab, disabled = false, callback = false } = props;
  const {
    isbn,
    title,
    subtitle,
    formatName,
    onsale,
    author,
    inventoryMsg,
    restockMsg,
    managedMsg,
    status,
    statusFilter,
    currency,
    country,
    price,
    cartonQuantity,
    format,
    divisionName,
    foc,
    available,
    itemId,
    quantity = 1,
    updateQuantity,
    focStatus,
    cartType = false,
    deliveryDate = false,
    isVariant,
    variantCount,
    hasOrderReq,
    orderReq,
    confirmationStatus = false,
    cartQuantity,
    cbReturnable,
    cbReturnStart,
    cbReturnEnd,
    filters,
  } = props.data;

  const [qty, setQty] = useState("1");
  const [updatingItem, setUpdatingItem] = useState(false);
  const [variantOrderingModalState, setVariantOrderingModalState] =
    useState(false);
  const [variantOrderUpdateModalState, setVariantOrderUpdateModalState] =
    useState(false);
  const [restrictionsNew, setRestrictionsNew] = useState(false);
  const [titleRestrictions, setTitleRestrictions] = useState(false);
  const [titleRestrictionsQty, setTitleRestrictionsQty] = useState(false);

  const user = useSecurity();
  const cart = useCart();

  const imgURL = "https://images.penguinrandomhouse.com/cover/" + isbn;

  const addToCart = () => {
    props.context.componentParent.addToCart(isbn, qty);
  };

  const isCartBadge = () => {
    if (user.isAppCompCopy()) return false;

    if (
      props.cart.customerInfo &&
      props.cart.customerInfo.currentUser &&
      props.cart.customerInfo.currentUser.roleNo !== "W1"
    )
      return false;

    return true;
  };

  const formRef = useRef();

  const removeCartItem = () => {
    if (props.cart.isUserPow()) {
      let item = { ...props.data };
      delete item.cartType;
      props.cart
        .removeEstimateItems({ focDate: foc, items: [item] })
        .then((r) => {
          if (r?.modalInfo === "REMOVE_VARIANTS") {
            setTitleRestrictions(r.mainVariantTitles);
            setVariantOrderUpdateModalState(true);
          }
        });
    } else {
      props.cart.removeItem(itemId);
    }
  };

  const cancelCallback = () => {
    setQty(quantity);
    setTitleRestrictions(false);
    setTitleRestrictionsQty(false);
    setRestrictionsNew(false);

    setUpdatingItem(false);
  };

  const coverHover = (isbn) => {
    return (
      <div className='d-flex flex-row'>
        <LazyImage
          className='hover-cover'
          boxShadow={true}
          src={`https://images.penguinrandomhouse.com/cover/${isbn}`}
        ></LazyImage>
      </div>
    );
  };
  // console.log("TilteRow", props);
  //console.log('cart quantity',title, isbn, cartQuantity);
  return (
    <>
      <VariantRestrictionsOrderingModal
        isbn={isbn}
        qtyWant={qty}
        openState={variantOrderingModalState}
        restrictionsNew={restrictionsNew}
        setOpenState={(w) => {
          setVariantOrderingModalState(w);
        }}
        shipTo={cart.getShipToAccount().accountNo}
        foc={foc}
        cancelCallback={cancelCallback}
      ></VariantRestrictionsOrderingModal>
      <VariantRestrictionsOrderUpdateModal
        isbn={isbn}
        title={title}
        openState={variantOrderUpdateModalState}
        titleRestrictions={titleRestrictions}
        setOpenState={(w) => {
          setVariantOrderUpdateModalState(w);
        }}
        shipTo={cart.getShipToAccount().accountNo}
        foc={foc}
        titleRestrictionsQty={titleRestrictionsQty}
        cancelCallback={cancelCallback}
      ></VariantRestrictionsOrderUpdateModal>
      <Grid container>
        <Grid.Row>
          <Grid.Column
            mobile={8}
            tablet={8}
            computer={8}
            largeScreen={10}
            widescreen={10}
          >
            <div className='d-flex'>
              <div style={{ width: "65px" }}>
                <div className='coverImageDiv'>
                  <Popup
                    size='huge'
                    className='hover-cover'
                    content={coverHover(isbn)}
                    on='hover'
                    hoverable={true}
                    position='top left'
                    offset={[-17, 10]}
                    trigger={
                      <LazyImage
                        className='title-cover'
                        // width={50}
                        isVariant={isVariant}
                        variantCount={variantCount}
                        isAgGrid={true}
                        boxShadow={true}
                        src={`https://images.penguinrandomhouse.com/cover/${isbn}`}
                      ></LazyImage>
                    }
                  />
                </div>
              </div>

              <div className='agCellData mt-1'>
                <div className='rowItem title-subtitle-blk'>
                  {available === true ? (
                    <a
                      className='boldLabel text-gray-900'
                      href={`/titleinfo/${isbn}`}
                      onClick={(e) => {
                        e.preventDefault();
                        props.pushFilters(isbn);
                        //props.history.push(`/titleinfo/${isbn}`);
                      }}
                    >
                      {title.length > 50
                        ? title.substring(0, 50) + "..."
                        : title}
                    </a>
                  ) : title.length > 50 ? (
                    title.substring(0, 50) + "..."
                  ) : (
                    title
                  )}

                  {subtitle != null ? (
                    <span className='rowItem'>
                      :{" "}
                      {subtitle.length > 50
                        ? subtitle.substring(0, 50) + "..."
                        : subtitle}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {author != null ? (
                  <div className='rowItem  title-subtitle-blk'>
                    {author.match("^Written") || author.match("^By")
                      ? ""
                      : "By "}
                    {author}
                  </div>
                ) : (
                  ""
                )}
                {divisionName != null ? (
                  <div className='rowItem  title-subtitle-blk'>
                    {divisionName}
                  </div>
                ) : (
                  ""
                )}
                <div className='rowItem'>{isbn}</div>
                <div className='rowItem  title-subtitle-blk'>
                  <span>{formatName}</span> <span className='pipe'> | </span>{" "}
                  <span>{formatDate(onsale, false)}</span>
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={4}
            tablet={4}
            computer={4}
            largeScreen={3}
            widescreen={3}
            className={`second-col d-flex flex-column pt-2 ${
              pageType === "catalog" ? "catalog-sec-col" : ""
            }`}
          >
            {/* <div className="agCellData paragraph-2 second-col-container-ie11"> */}
            {inventoryMsg != null && statusFilter != "restrictedVariant" ? (
              <InventoryMessage msg={inventoryMsg} classes='second-col-items' />
            ) : available === false && statusFilter != "restrictedVariant" ? (
              <InventoryMessage msg={status} classes='second-col-items' />
            ) : (
              <div style={{ marginTop: ".5em" }} />
            )}

            {managedMsg != null ? (
              <InventoryMessage
                msg={managedMsg}
                classes='second-col-items'
                bgColor='grey-back'
              />
            ) : (
              ""
            )}
            {restockMsg != null ? (
              <InventoryMessage
                msg={restockMsg}
                classes='second-col-items'
                bgColor='grey-back'
              />
            ) : (
              ""
            )}

            {cbReturnable && (cbReturnable === "Y" || cbReturnable === "N") ? (
              <ReturnAbilityStatus
                className='second-col-items'
                cbReturnable={cbReturnable}
                cbReturnStart={cbReturnStart}
                cbReturnEnd={cbReturnEnd}
              />
            ) : (
              ""
            )}

            {foc != null && !isGeneralCart(foc) ? (
              <div className='rowItem second-col-items'>
                FOC {formatDateMMDDYYYY(foc, "/")}
              </div>
            ) : (
              ""
            )}
            {cartonQuantity != null ? (
              <div
                className={`rowItem second-col-items ${
                  (inventoryMsg === null && foc === null) || foc !== null
                    ? "carton-div"
                    : ""
                }`}
              >
                <NumericFormat
                  style={{ marginRight: "0.25rem" }}
                  value={cartonQuantity}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  displayType={"text"}
                />{" "}
                per carton
              </div>
            ) : (
              ""
            )}
            {deliveryDate && user.isAppBiz() ? (
              <div className='d-flex flex-column cp-mb-1'>
                <div className='fort-light-italic font-weight-normal'>
                  Est Delivery:
                  {deliveryDate !== "0000-00-00"
                    ? formatDate(deliveryDate, false)
                    : "Not Available"}
                </div>
              </div>
            ) : null}
          </Grid.Column>
          <Grid.Column
            mobile={4}
            tablet={4}
            computer={4}
            largeScreen={3}
            widescreen={3}
            className='third-col'
          >
            {/* <div className="agCellData  paragraph-2"> */}
            <div className='rowItem boldLabel mt-1 third-col-items d-flex justify-content-end'>
              $
              {price === null ? (
                "0.00"
              ) : (
                <NumericFormat
                  value={price}
                  thousandSeparator={true}
                  decimalScale={2}
                  allowNegative={true}
                  fixedDecimalScale={true}
                  displayType={"text"}
                />
              )}
              &nbsp;{currency}
            </div>

            <Form
              className='d-flex align-items-center justify-content-end'
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                if (pageType === "cart") {
                  if (qty === quantity) return;
                  if (!qty || qty === "" || parseInt(qty) === 0) {
                    setQty(quantity);
                    return;
                  }
                  if (pageType === "cart") {
                    setUpdatingItem(true);
                    if (props.cart.isUserPow()) {
                      props.cart
                        .performEstimateItemQtyUpdate({
                          foc: foc,
                          cartType: cartType
                            ? cartType
                            : isGeneralCart(foc)
                            ? "GEN"
                            : "FOC",
                          items: [
                            { ...props.data, updateQuantity: parseInt(qty) },
                          ],
                        })
                        .then((e) => {
                          if (e?.modalInfo === "REMOVE_VARIANTS") {
                            setTitleRestrictions(e.mainVariantTitles);
                            setTitleRestrictionsQty(qty);
                            setVariantOrderUpdateModalState(true);
                          } else if (e?.modalInfo === "ADD_QUALIFYING_ITEMS") {
                            setRestrictionsNew(e.variantInfo);
                            setVariantOrderingModalState(true);
                          } else {
                            setUpdatingItem(false);
                          }
                        });
                    } else {
                      if (tab === 0) {
                        props.cart
                          .performItemQtyUpdate(itemId, qty)
                          .then((e) => {
                            setUpdatingItem(false);
                          });
                      } else {
                        props.cart
                          .performItemSaveForLaterQtyUpdate(itemId, qty)
                          .then((e) => {
                            setUpdatingItem(false);
                          });
                      }
                    }
                  }
                }
              }}
            >
              {cartQuantity > 0 ? (
                <Popup
                  className=''
                  content={
                    <span>
                      {" "}
                      <NumericFormat
                        value={cartQuantity}
                        thousandSeparator={true}
                        decimalScale={0}
                        allowNegative={true}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />{" "}
                      units in cart{" "}
                      {confirmationStatus === "CONFIRMED" ? " (confirmed)" : ""}
                    </span>
                  }
                  mouseEnterDelay={250}
                  on='hover'
                  position='top center'
                  offset={[-1, 8]}
                  trigger={
                    <div
                      className={`flex flex-column align-items-center mr-1 trag-cart-qty ml-auto ${
                        focStatus === "ok" && confirmationStatus === "CONFIRMED"
                          ? "confirmed"
                          : confirmationStatus === "PENDING_UPDATE"
                          ? "updated"
                          : ""
                      } ${props.cart.isUserPow() ? "pow" : "non-pow"} ${
                        isFocCart(foc, focStatus) === false ? "general" : ""
                      }`}
                    >
                      <div className='fort-bold' style={{ marginTop: "-9px" }}>
                        {cartQuantity > 9999 ? (
                          "9999+"
                        ) : (
                          <NumericFormat
                            value={cartQuantity}
                            thousandSeparator={false}
                            decimalScale={0}
                            allowNegative={true}
                            fixedDecimalScale={true}
                            displayType={"text"}
                          />
                        )}
                      </div>
                    </div>
                  }
                />
              ) : null}
              <div className='rowItem qty-box mt-1 third-col-items'>
                <span className='qty-lbl text-gray-700'> Qty </span>

                <Input
                  disabled={disabled ? true : !available || isCartBadge()}
                  type='number'
                  max={9999}
                  min={1}
                  className='search-title-input'
                  id={`qty_${isbn}`}
                  value={qty}
                  onBlur={(e) => {
                    if (isNaN(parseInt(qty)) || parseInt(qty) === 0)
                      setQty("1");
                  }}
                  onChange={(e) => {
                    if (
                      parseInt(e.target.value.replace(/[^0-9\s]/g, "")) > 9999
                    ) {
                      return;
                    }
                    setQty(e.target.value.replace(/[^0-9\s]/g, ""));
                  }}
                />
              </div>
            </Form>
            {pageType === "cart" ? (
              <div className='title-row-action d-flex justify-content-end fs-13px'>
                <div
                  className={`text-blue cursor-pointer mr-1 ${
                    props.cart.isUserPow() ? "ml-auto" : ""
                  }`}
                  onClick={(e) => {
                    tab === 0 && removeCartItem();
                    tab === 1 && props.cart.removeItemSaveForLater(itemId);
                  }}
                >
                  Remove
                </div>
                {!props.cart.isUserPow() ? (
                  <>
                    <div className='text-gray-500'>|</div>
                    <div
                      className='text-blue cursor-pointer ml-1'
                      onClick={(e) => {
                        tab === 0 && props.cart.saveItemForLater(itemId);
                        tab === 1 && props.cart.moveItemToCart(itemId);
                      }}
                    >
                      {tab === 0 ? "Save for Later" : "Move to Cart"}
                    </div>
                  </>
                ) : null}
              </div>
            ) : (
              <div className='rowItem third-col-items d-flex flex-column align-items-end'>
                <AddToCartButton
                  onClickFunction={addToCart}
                  isBtnAvailable={available}
                  focStatus={focStatus}
                  foc={foc}
                  qty={qty}
                  isbn={isbn}
                  onSaleDate={onsale}
                  orderReq={orderReq}
                  callback={(e) => {
                    if (callback) callback(e);
                    setQty(1);
                  }}
                  confirmationStatus={confirmationStatus}
                />
                {hasOrderReq ? (
                  <div className='d-flex justify-content-end mt-1 mb-1'>
                    {/* <span
                      style={{ marginRight: '0.15rem' }}
                      className="fort-book-italic fs-13px lh-18 text-gray-700"
                    >
                      Ordering restrictions
                    </span> */}
                    <VariantRestrictionsInfoModal
                      isbn={isbn}
                      searchClass={cart.customerInfo.customer.searchClass}
                      country={cart.country}
                      orderReq={orderReq}
                      title={title}
                    ></VariantRestrictionsInfoModal>
                  </div>
                ) : null}
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {divider ? <Divider tight className='mt-2 mb-2' /> : null}
    </>
  );
};

export default withRouter(withApp(withCart(TitleRowAgGrid)));

TitleRowAgGrid.propTypes = {
  data: PropTypes.object.isRequired,
  pageType: PropTypes.string,
  tab: PropTypes.number,
  divider: PropTypes.bool,
  user: PropTypes.object,
  cart: PropTypes.object,
  app: PropTypes.object,
};
TitleRowAgGrid.defaultProps = {
  pageType: "",
  tab: 0,
  divider: false,
};
