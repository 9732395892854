import React, { Component } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Loader, Modal, Button, Icon } from 'semantic-ui-react';
import ManageUserForm from './ManageUserForm';
import withSecurity from '../hc/withSecurity';
import withApp from '../hc/withApp';
import withCart from '../hc/withCart';
import withAccount from '../hc/withAccount';
import { withRouter } from 'react-router';
import UserList from './UserList';
import AccountsManageUsers from '../../assets/images/accounts-manage-users.svg';
import { Divider } from '../../shared-react-components/elements/elements';
import {
  Biz2Modal,
  PRHContainerGeneral,
  PRHContainerHeader,
} from '../elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import ToastGenerator from '../../shared-react-components/utils/ToastGenerator';
import './admin.scss';
import NoticeCard from "../elements/NoticeCard";
import withMobile from "../hc/withMobile";

class AccountUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      modalHeader: '',
    };
  }

  async componentDidMount() {
    const { account, cart } = this.props;
    const {
      customerInfo = {
        customer: {
          address: {
            name1: '',
            name2: '',
            name3: '',
            street: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            phone: '',
          },
        },
      },
    } = cart;
    const { currentUser = {} } = customerInfo;
    const { roleNo = false } = currentUser;
    if (account.formState.userList.length === 0) {
      account.getUsers(this.props.cart.customerInfo.customer.accountNo);
    }
  }

  modalControl = (status, header, userID, text) => {
    const { account } = this.props;
    account.editControl(status, header, userID, text);
  };

  handleDismiss = () => {
    this.setState({ visible: false });
    /*
      setTimeout(() => {
        this.setState({ visible: true })
      }, 5000)
      */
  };

  isAddUser = () => {
    const { cart } = this.props;

    if (
      cart.customerInfo &&
      cart.customerInfo.currentUser &&
      cart.customerInfo.currentUser.roleNo === 'W9'
    )
      return true;

    return false;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.account.formState.responseModal === false &&
      this.props.account.formState.responseModal === true
    ) {
      const { account } = this.props;
      let msg = 'Error occured.';
      if (
        account.formState.updateResponse === 'success' &&
        account.formState.updateMethod === 'post'
      ) {
        msg = `${
          account.formState.createUserPost.fullName
            ? account.formState.createUserPost.fullName
            : 'User'
        } has been added to your account and is pending verification.`;
      }
      if (
        account.formState.updateResponse === 'selfRegistered' &&
        account.formState.updateMethod === 'post'
      ) {
        msg = `User has already been created please check list or contact
                    customer service.`;
      }

      if (
        account.formState.updateResponse === 'registered' &&
        account.formState.updateMethod === 'post' 
      ) {
        msg = `User with this information has already been created please check list or contact customer service.
                 `;
      }

      if (
        account.formState.updateResponse === 'registered' &&
        account.formState.updateMethod === 'patch' 
      ) {
        msg = `User with this information has already been created please check list or contact customer service.
                 `;
      }

      if (
        account.formState.updateResponse === 'success' &&
        account.formState.updateMethod === 'patch'
      ) {
        msg = `${
          account.formState.createUserPost.fullName
            ? account.formState.createUserPost.fullName
            : 'User'
        } has been updated.`;
      }

      if (
        account.formState.updateResponse === 'success' &&
        account.formState.updateMethod === 'delete'
      ) {
        msg = `${
          account.formState.createUserPost.fullName
            ? account.formState.createUserPost.fullName
            : 'User'
        } has been deleted.`;
      }

      if (
        account.formState.updateResponse === 'failure' &&
        account.formState.updateMethod === 'post'
      ) {
        msg = `Unable to add ${
          account.formState.createUserPost.fullName
            ? account.formState.createUserPost.fullName
            : 'User'
        }. Please try again or contact customer service.
                  `;
      }

      if (
        account.formState.updateResponse === 'failure' &&
        account.formState.updateMethod === 'patch'
      ) {
        msg = `Unable to update ${
          account.formState.createUserPost.fullName
            ? account.formState.createUserPost.fullName
            : 'User'
        }. Please try again or contact customer service.
                  `;
      }

      if (
        account.formState.updateResponse === 'failure' &&
        account.formState.updateMethod === 'delete'
      ) {
        msg = `Unable to delete ${
          account.formState.createUserPost.fullName
            ? account.formState.createUserPost.fullName
            : 'User'
        }. Please try again or contact customer service.
                  `;
      }

      ToastGenerator({
        text: msg,
      });
      account.dismissCreateSubMessage();
    }
  }

  render() {
    const { admin, account } = this.props;
    const isMobile = this.props.mobile.isMobile;
    return (
      <>
        <div className={'adminX'}>
          <Modal open={account.formState.loading}>
            <Loader inverted />
          </Modal>
          <div className="accounts-settings">
          <div>
          <div className="accounts-page-headers-images">
          <img src={AccountsManageUsers} alt="Billing and Details Icon" />
          </div>
          <div className="accounts-page-headers fort-light text-gray-900 fs-24px">
              Manage Users
          </div>
          <div className="accounts-page-headers-button-holder hidden-on-mobile">
              {this.isAddUser() ? (
                <PRHButton
                  className={'ml-auto'}
                  fontAwesome={true}
                  icon={faPlus}
                  //iconleft
                  onClick={() =>
                    this.modalControl(
                      true,
                      'Add User',
                      null,
                      "A verification email will be sent to the user's email address entered below to complete their registration process."
                    )
                  }
                >
                  Add User
                </PRHButton>
              ) : null}
              </div>
            {!isMobile && <Divider className="accounts-header-divider"/>}
        </div>
        {isMobile && (
          <NoticeCard>
            Management of users is accessible through desktop only.
          </NoticeCard>
        )}
        </div>
          {!isMobile &&
            <PRHContainerGeneral className="content-block-border p-0 mb-6 mt-4 ar-box">
              <div>
                <UserList
                  formState={account.formState}
                  modalControl={(status, header, userID, text) =>
                    this.modalControl(status, header, userID, text)
                  }
                />
              </div>
            </PRHContainerGeneral>
          }
          {isMobile &&
            <UserList
              formState={account.formState}
              modalControl={(status, header, userID, text) =>
                this.modalControl(status, header, userID, text)
              }
            />
          }
          <Biz2Modal
            modalOpen={account.formState.manageUserModal.open}
            header={account.formState.manageUserModal.header}
            modalText={account.formState.manageUserModal.text}
          >
            <ManageUserForm
              admin={admin}
              modalControl={(status, header, userID, text) =>
                this.modalControl(status, header, userID, text)
              }
            />
          </Biz2Modal>
        </div>
      </>
    );
  }
}

export default withRouter(
  withSecurity(withApp(withCart(withAccount(withMobile(AccountUsers)))))
);
