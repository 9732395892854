import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import withSecurity from "../hc/withSecurity";

export const SilentRenew = withSecurity((props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("loader silent renew", props);

    props.user
      .signinSilentCallback()
      .then((e) => {
        // console.log("reloadUser", e, location.hash);
        if (location.hash.includes("#error=")) {
          console.log("silent rewew failed");
          // window.postMessage("silent renew error", window.location);
        } else {
          // props.user.reloadUser();
        }
      })
      .catch((e) => {
        console.log("silent renew error", e);
      });
  }, []);

  return <span>loading</span>;
});
