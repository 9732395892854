import { Fragment, useState, useEffect } from "react";
import { Container, Grid, Loader } from "semantic-ui-react";
import RecentInvoicesAndClaims from "./RecentInvoicesAndClaims";
import RecentOrders from "./RecentOrders";
import PreDeliveryReports from "./PreDeliveryReports";
import NewPreDeliveryReports from "./NewPreDeliveryReports";
//import TestPreDeliveryReports from './TestPreDeliveryReports';
//import OldNewPreDeliveryReports from './OldNewPreDeliveryReports';
import ExportFile from "../../assets/images/export-blue.svg";
// import withApp from '../../components/hc/withApp';
import { v4 as uuid } from "uuid";
import userRoleConversion from "../../utils/userRoleConversion";
import useAll from "../../components/hc/useAll";
import IECreditCardNotification from "../../components/IECreditCardNotification";
import LatestPostsDigest from "../external/partials/LatestPostsDigest";
import Catalogs from "./Catalogs";
import NotificationBanners from "../../components/common/NotificationBanners";
import NotificationBanner from "../../components/common/NotificationBanner";
import { useMediaQuery } from "react-responsive";
import { Divider } from "../../components/elements/elements";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import { useHistory } from "react-router";
import NoticeCard from "../../components/elements/NoticeCard";
import { useLazyGetSalesRepsQuery } from "../../api/ecomApi";
import { DeliveriesHomePage } from "./frameworkComponents/DeliveriesHomePage";

const Biz = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  // const app = useApp();
  // const cart = useCart();

  const { app, cart } = useAll();
  const history = useHistory();

  const hideCsRep = true;

  const {
    customerInfo = {
      customer: {
        address: {
          name1: "",
          name2: "",
          name3: "",
          street: "",
          city: "",
          state: "",
          postalCode: "",
          country: "",
          phone: "",
        },
      },
    },
  } = cart;
  const { currentUser = {} } = customerInfo;
  const { roleNo = false } = currentUser;
  const roleName = userRoleConversion(roleNo);
  const { customer } = customerInfo;
  const [salesReps, setSalesReps] = useState([]);
  const [customerServiceReps, setCustomerServiceReps] = useState([]);
  const [salesRepsLoaded, setSalesRepsLoaded] = useState(false);
  const [customerServiceRepsLoaded, setCustomerServiceRepsLoaded] =
    useState(false);
  const [downloadOptions, setDownloadOptions] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [performGetSalesReps, getSalesRepsResult] = useLazyGetSalesRepsQuery();

  const getSalesReps = async () => {
    const result = await performGetSalesReps({}, true);

    return result;
  };

  const getCustomerServiceReps = async () => {
    return false;
  };

  const getBacklistDownloadsInfo = (countryCode) => {
    cart.getBacklistDownloadsInfo(countryCode).then((result) => {
      if (result.data.status === "OK") {
        const files = result.data.data.files;
        setDownloadData([...files]);
        const downLoadOptions = [];
        files.forEach(function (item, index) {
          let desc = item.desc.split("(");
          downLoadOptions.push({
            fileName: item.fileName,
            desc: desc[0],
          });
        });
        setDownloadOptions(downLoadOptions);
      }
    });
  };

  const handleBacklistDownload = async (fileName, country) => {
    const response = await cart.getBacklistDownloadFile(fileName, country);
    if (response.data?.status === "OK") {
      window.location.assign(response.data.data.downloadUrl);
    }
  };

  useEffect(() => {
    getCustomerServiceReps().then(({ data }) => {
      setCustomerServiceRepsLoaded(true);
      setCustomerServiceReps(data || false);
    });

    getSalesReps().then(({ data }) => {
      setSalesReps(data);
      setSalesRepsLoaded(true);
    });
    getBacklistDownloadsInfo(cart.country);
  }, []);

  const { accountNo, address } = customer;
  if (!address) {
    return <></>;
  }
  const {
    name1,
    name2,
    name3,
    street,
    city,
    state,
    postalCode,
    country,
    phone,
  } = address;
  const locationArray = [];
  !!name1 && locationArray.push(name1);
  !!name2 && locationArray.push(name2);
  !!name3 && locationArray.push(name3);
  !!street && locationArray.push(street);
  if (!!city || !!state || !!postalCode) {
    let cityStatePostal = "";
    if (!!city) {
      cityStatePostal += `${city}, `;
    }
    if (!!state) {
      cityStatePostal += `${state} `;
    }
    if (!!postalCode) {
      cityStatePostal += `${postalCode} `;
    }
    locationArray.push(cityStatePostal);
  }
  !!country && locationArray.push(country);
  !!phone && locationArray.push(phone);

  const location = locationArray.map((c, i) => (
    <Fragment key={i}>
      {c}
      <br />
    </Fragment>
  ));

  return (
    <>
      <Container className='bg-white'>
        <Grid relaxed={!isMobile}>
          <Grid.Row className='flex-column-reverse flex-lg-row'>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={12}
              largeScreen={12}
              widescreen={12}
              className={`order-2 order-lg-1 px-0-md-only ${
                isMobile ? "pt-2 " : "pt-4 keep-padding"
              }`}
            >
              <div className='table-listing-wrap'>
                <IECreditCardNotification className='mb-8 iewarning' />
                {cart.isUserPow() && (
                  <NotificationBanner
                    catalogs={cart.activeCatalogs}
                    setShowNotifications={cart.setShowNotifications}
                    catalogAvailabilityDate={cart.catalogAvailabilityDate}
                    currentMonthCatalogAvailabilityDate={
                      cart.currentMonthCatalogAvailabilityDate
                    }
                    estimateDueDate={cart.estimateDueDate}
                    showNotifications={cart.showNotifications}
                    currentPage='biz'
                  />
                )}
                {cart.isUserPow() && (
                  <NotificationBanners
                    listenToPropertyForChange={
                      cart.getShipToAccount().accountNo
                    }
                    carts={cart.estimateCarts}
                  />
                )}
                <DeliveriesHomePage />
                {cart.isUserPow() && <Catalogs />}

                {cart.isUserPow() && <LatestPostsDigest />}
                {cart.isUserPow() && (
                  <>
                    <NewPreDeliveryReports
                      shipTo={
                        cart?.customerInfo?.currentUser?.roleNo === "W1"
                          ? cart?.customerInfo?.customer?.accountNo
                          : null
                      }
                      className='my-3'
                    />
                    {/*<PreDeliveryReports accountNo={accountNo} className="my-3" />*/}
                  </>
                )}
                {/* {console.log('request recent orders', cart?.customerInfo?.currentUser?.roleNo, cart?.customerInfo?.customer?.accountNo)} */}
                <RecentOrders
                  shipTo={
                    cart?.customerInfo?.currentUser?.roleNo === "W1"
                      ? cart?.customerInfo?.customer?.accountNo
                      : null
                  }
                  className='my-3'
                />
                <RecentInvoicesAndClaims
                  shipTo={
                    cart?.customerInfo?.currentUser?.roleNo === "W1"
                      ? cart?.customerInfo?.customer?.accountNo
                      : null
                  }
                  className='my-3'
                />
              </div>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={4}
              largeScreen={4}
              widescreen={4}
              className={`${
                !isMobile
                  ? "keep-padding bg-gray-100 pt-4 order-1 order-lg-2 px-md-0 px-lg-auto"
                  : ""
              }`}
            >
              <div className='pb-3'>
                <div className='px-md-4'>
                  <div className='d-sm-flex d-lg-block justify-content-between'>
                    <div
                      className={`${
                        isMobile
                          ? "mobile-catalogs-container border-gray-300 border-radius-3px p-2 pb-3 mb-3"
                          : "fb-48"
                      }`}
                    >
                      {isMobile && (
                        <>
                          <div className='catalogs-box-title d-flex flex-row justify-content-space-between align-items-center'>
                            <p className='lh-28 fort-light lsp--05 mb-0 fs-20px'>
                              My Account
                            </p>
                            <a
                              className='text-blue cursor-pointer'
                              href='#'
                              onClick={() => {
                                history.push(`/admin/account-details`);
                              }}
                            >
                              View All Account Details
                            </a>
                          </div>
                          <Divider tight className='mt-2 mb-2' />
                        </>
                      )}
                      {!isMobile && (
                        <p className='lh-28 fort-light lsp--05 mb-3 fs-24px'>
                          My Account
                        </p>
                      )}
                      <div
                        className={`${
                          isMobile
                            ? ""
                            : "border-2-top-orange border-left-gray-300 border-bottom-gray-300 border-right-gray-300 bg-white pt-3 pb-2 mb-lg-6"
                        }`}
                      >
                        <div
                          className={`${
                            isMobile ? "" : "px-3 border-bottom-gray-300 "
                          }`}
                        >
                          <div
                            className={`mb-3 ${
                              isMobile
                                ? "d-flex flex-row justify-content-space-between"
                                : ""
                            }`}
                          >
                            <p
                              className={`my-0 lh-15 lsp--02 fs-12px text-uppercase ${
                                isMobile ? "" : "fort-bold"
                              }`}
                            >
                              Account Number
                            </p>
                            <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                              {accountNo}
                            </p>
                          </div>
                          <div
                            className={`mb-3 ${
                              isMobile
                                ? "d-flex flex-row justify-content-space-between"
                                : ""
                            }`}
                          >
                            <p
                              className={`my-0 lh-15 lsp--02 fs-12px text-uppercase ${
                                isMobile ? "" : "fort-bold"
                              }`}
                            >
                              Access Level
                            </p>
                            <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                              {roleName && roleName}
                              {!roleName && <>Not Available</>}
                            </p>
                          </div>
                          {roleNo === "W1" && (
                            <div
                              className={`mb-3 ${
                                isMobile
                                  ? "d-flex flex-row justify-content-space-between"
                                  : ""
                              }`}
                            >
                              <p
                                className={`my-0 lh-15 lsp--02 fs-12px text-uppercase ${
                                  isMobile ? "" : "fort-bold"
                                }`}
                              >
                                Location
                              </p>
                              <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                                {location}
                              </p>
                            </div>
                          )}
                          {!hideCsRep && (
                            <div
                              className={`mb-3 ${
                                isMobile
                                  ? "d-flex flex-row justify-content-space-between"
                                  : ""
                              }`}
                            >
                              <p
                                className={`my-0 lh-15 lsp--02 fs-12px text-uppercase ${
                                  isMobile ? "" : "fort-bold"
                                }`}
                              >
                                Customer Service Rep
                                {Array.isArray(customerServiceReps) &&
                                  customerServiceReps.length > 1 && <>s</>}
                              </p>
                              <div className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                                {customerServiceReps &&
                                  Array.isArray(customerServiceReps) &&
                                  customerServiceReps.length > 0 &&
                                  customerServiceReps.map(({ name }) => (
                                    <div
                                      key={name}
                                      className='my-0 fort-book lh-18 lsp--02 fs-13px overflow-wrap-break-word mb-1'
                                    >
                                      {name}
                                    </div>
                                  ))}

                                {customerServiceRepsLoaded &&
                                  (!Array.isArray(customerServiceReps) ||
                                    (Array.isArray(customerServiceReps) &&
                                      customerServiceReps.length === 0)) && (
                                    <span className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                                      Not Available
                                    </span>
                                  )}
                              </div>
                            </div>
                          )}
                        
                            <div
                              className={`${
                                isMobile
                                  ? "d-flex flex-column justify-content-space-between "
                                  : "mb-3"
                              }`}
                            >
                              <p
                                className={`my-0 lh-15 lsp--02 fs-12px text-uppercase mb-1 ${
                                  isMobile ? "" : "fort-bold"
                                }`}
                              >
                                Sales Rep
                                {Array.isArray(salesReps) &&
                                  salesReps.length > 1 && <>s</>}
                                 
                              </p>
                              {salesRepsLoaded &&
                                Array.isArray(salesReps) &&
                                salesReps.length > 0 &&
                                salesReps.map(({ name }) => (
                                  <p
                                    key={name}
                                    className='my-0 fort-book lh-18 lsp--02 fs-12px overflow-wrap-break-word'
                                  >
                                    {name}
                                  </p>
                                ))}

                              {salesRepsLoaded &&
                                (!Array.isArray(salesReps) ||
                                  (Array.isArray(salesReps) &&
                                    salesReps.length === 0)) && (
                                  <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                                    Not Available
                                  </p>
                                )}

                              {!salesRepsLoaded && (
                                <Loader
                                  className='position-relative mt-2'
                                  size='mini'
                                  active={true}
                                />
                              )}
                            </div>
                        </div>
                        {!isMobile && (
                          <div className='px-3 mt-2'>
                            <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                              <a
                                className='text-blue cursor-pointer'
                                href='#'
                                onClick={(e) => {
                                  // e.preventDefault();
                                  history.push(`/admin/account-details`);
                                }}
                              >
                                View All Account Details
                              </a>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {cart.isUserPow() && downloadOptions.length > 0 ? (
                      <div
                        className={`${
                          isMobile
                            ? "mobile-catalogs-container border-gray-300 border-radius-3px p-2 pb-3 mb-3"
                            : "fb-48"
                        }`}
                      >
                        <p
                          className={`lh-28 fort-light lsp--05 mb-0 ${
                            isMobile ? "fs-20px" : "fs-24px mb-3"
                          }`}
                        >
                          Backlist Data
                        </p>
                        {isMobile && <Divider tight className='mt-2 mb-2' />}
                        {!isMobile && (
                          <div
                            className={`${
                              isMobile
                                ? ""
                                : "border-2-top-orange border-left-gray-300 border-bottom-gray-300 border-right-gray-300 bg-white pt-3 pb-2 mb-lg-6"
                            }`}
                          >
                            <div className={`${isMobile ? "" : "px-3"}`}>
                              <div
                                className={`${
                                  isMobile
                                    ? "d-flex flex-row justify-content-space-between"
                                    : "mb-3"
                                }`}
                              >
                                <div
                                  className={`my-0 lh-15 lsp--02 fs-12px text-uppercase ${
                                    isMobile ? "" : "fort-bold"
                                  }`}
                                >
                                  CSV data download of all active titles with On
                                  Sale Dates prior to today's date.
                                  <br />
                                  <br />
                                  {downloadOptions.map((option, i) => (
                                    <Fragment key={`${option.fileName}_${i}`}>
                                      <div className={"flex mb-1"} key={i}>
                                        <div className='pt-2px'>
                                          <img
                                            src={ExportFile}
                                            width={13}
                                            height={13}
                                            alt='#'
                                            className='mr-1'
                                          />
                                        </div>
                                        <div className='fort-book cursor-pointer'>
                                          <b>
                                            <a
                                              onClick={() =>
                                                handleBacklistDownload(
                                                  option.fileName,
                                                  cart.country
                                                )
                                              }
                                            >
                                              {option.desc} (CSV)
                                            </a>
                                          </b>
                                          <br />
                                          <span className='fort-book-italic grey-700'>
                                            Updated nightly
                                          </span>
                                        </div>
                                      </div>
                                    </Fragment>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {isMobile && (
                          <NoticeCard>
                            CSV Data Download of all active titles with on sale
                            date is accessible through desktop only.
                          </NoticeCard>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className={`${
                        isMobile
                          ? "mobile-catalogs-container border-gray-300 border-radius-3px p-2 pb-3 mb-3"
                          : "fb-48"
                      }`}
                    >
                      {isMobile && (
                        <>
                          <div className='catalogs-box-title d-flex flex-row justify-content-space-between align-items-center'>
                            <p className='lh-28 fort-light lsp--05 mb-0 fs-20px'>
                              Support
                            </p>
                            <a
                              className='text-blue cursor-pointer'
                              // href="#"
                              onClick={() => {
                                // history.push(
                                window.location.href = `https://selfservice-help.penguinrandomhouse.biz/`;
                                // );
                              }}
                            >
                              Go To Support Center
                            </a>
                          </div>
                          <Divider tight className='mt-2 mb-2' />
                        </>
                      )}
                      {!isMobile && (
                        <p className='fs-24px lh-28 fort-light lsp--05 mb-3'>
                          Support
                        </p>
                      )}
                      <div
                        className={`${
                          isMobile
                            ? ""
                            : "border-2-top-orange border-left-gray-300 border-bottom-gray-300 border-right-gray-300 bg-white pt-3 pb-2 mb-lg-6"
                        }`}
                      >
                        <div className={`${isMobile ? "" : "px-3"}`}>
                          <div className={`${isMobile ? "" : "mb-3"}`}>
                            <p
                              className={`lh-15 lsp--02 fs-12px text-uppercase ${
                                isMobile ? "my-1" : "my-0 fort-bold"
                              }`}
                            >
                              Customer Service
                            </p>
                            <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                              Mon-Fri
                            </p>
                            <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                              {cart.isUserPow() ? (
                                <>8:30am-5:00pm (EST)</>
                              ) : (
                                <>8:30am-5:30pm (EST)</>
                              )}
                            </p>
                            <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                              <a
                                className='text-blue'
                                href={`tel:+1-${
                                  cart.isUserPow()
                                    ? "877-888-2918"
                                    : "800-733-3000"
                                }`}
                              >
                                {cart.isUserPow() ? (
                                  <>877-888-2918</>
                                ) : (
                                  <>1-800-733-3000</>
                                )}
                              </a>
                            </p>
                            <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                              <a
                                className='text-blue'
                                href={`mailto:${
                                  cart.isUserPow()
                                    ? "cscomicmarket@penguinrandomhouse.com"
                                    : "bizcs@prh.com"
                                }`}
                              >
                                {cart.isUserPow() ? (
                                  <>CSComicMarket@prh.com</>
                                ) : (
                                  <>bizcs@prh.com</>
                                )}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

export default Biz;
