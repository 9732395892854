import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Container,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Message,
  Popup,
  Table,
  Label,
} from 'semantic-ui-react';
import { Divider } from '../../components/elements/elements';
import PageStandard from '../../components/elements/PageStandard';
import { ErrorComponent } from '../../components/ErrorComponent.jsx';
import withApp from '../../components/hc/withApp';
import withCart from '../../components/hc/withCart';
import withSecurity from '../../components/hc/withSecurity';
import PageHeader from '../../components/header/PageHeader';
import DialogModal from '../../components/modals/DialogModal';
import PRHButtonStandard from '../../shared-react-components/elements/prhbutton';
import { MOBILE_SCREEN_WIDTH, TOOLTIPS } from '../../utils/const';
import { Export, ExportCSV } from '../../utils/Export';
import { numberAsCurrency } from '../../utils/utilities';
import LoadingView from '../loading/LoadingView';
import './Returns.scss';
import { useMediaQuery } from 'react-responsive';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { MobileOptionsMenu } from '../../components/header/components/MobileOptionsMenu';
import { OrderInvoiceTitleSearchMobile } from '../../components/common/OrderInvoiceTitleSearchMobile';
import { TitleRowReturns } from './TitleRowReturns';
import { isMobileOnly } from 'react-device-detect';

export const ReturnsTable = withSecurity(
  withRouter(
    withApp(
      withCart((props) => {
        const isMobile = isMobileOnly;
        // const isMobile = useMediaQuery({
        //   query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
        // });

        const [state, setstate] = useState({
          returns: true,
          disabled: true,
        });

        const testReturns_old = [{
          isbn: "75960620182100412",
          title: "Avengers Forever 5",
          formatCode: "CM",
          listPrice: "$8.99",
          unitCost: "$4.99",
          eligibleQty: 25,
          returnQty: 0,
          estCredit: null,
        }];

        const testReturns=[
          {
            "isbn": "81781403059400111",
            "title": "IDW Test Variant A 2",
            "formatCode": "CB",
            "listPrice": 3.99,
            "unitCost": 2.00,
            "qtyOrdered": 10,
            "qtyCredited": 0,
            "qtyReturnable": 10,
            "qtyReturn": 0,
            "estCredit": 0.00,
            "cardId": null
          },
          {
            "isbn": "81781403065500121",
            "title": "IDW Test Variant B 2",
            "formatCode": "CB",
            "listPrice": 3.99,
            "unitCost": 2.00,
            "qtyOrdered": 10,
            "qtyCredited": 0,
            "qtyReturnable": 10,
            "qtyReturn": 0,
            "estCredit": 0.00,
            "cardId": null
          }
        ];

        return (
          <div>
            <PageStandard
              headerType="loggedIn"
              containerFluid={true}
              cartBadge={true}
            ><div>
            <div className="od-metadata-container">
              <Container className="od-header">
                <div className="od-header-head">
                  <div className="od-button-right-container">
                    <div className="iconholder hide-in-print">
                      <Icon
                        color="black"
                        name="file alternate"
                        size="large"
                      />
                    </div>
                    <div
                            className="od-header-text"
                            onClick={() =>{}}
                          >
                            Affadavit Return
                          </div>
                  </div>
                </div>
                <div className="od-header-subhead">
                          <div>
                            <span className="od-text-data-medium">
                              Return Window: Nov 1 - Nov 30, 2022
                            </span>
                          </div>
                  </div>
              </Container>
              <Container className="od-metadata-data d-flex">
              <div className="od-metadata-data-left">
                          <div>
                            <span className="od-text-data-medium">
                              Account #:
                            </span>{' '}
                            000000000000000<br/>
                            Company<br/>
                            1745 Broadway<br/>
                            New York, NY 10016<br/>
                          </div>
                </div>
                <div className="od-metadata-data-right tm-order-now-container">
                          <div className="od-modal-data od-text-header"></div>
                          
                </div>
              </Container>
            </div>
            <Container
                className="od-status-container hide-in-print"
                key={3}>
        <div className="d-flex">
        {!isMobile && !state.hasError && (
                <div style={{marginBottom:'20px'}}>
                  <Form >
                    <Input
                      className={"ot-input"}
                      id="searchlist"
                      name="searchlist"
                      type="text"
                      icon={{
                        name: 'search',
                        link: true,
                        //onClick: () => onFilterTextBoxChanged(),
                      }}
                      /*onChange={(e, data) => {
                        if (data.value !== '' && state.hasInput === false) {
                          setState({ ...state, hasInput: true });
                        } else if (data.value === '') {
                          setState({ ...state, hasInput: false });
                        }
                      }}*/
                      //disabled={state.isLoading}
                      placeholder={'Search Order #, Invoice #, or ISBN'}
                    />
                  </Form>
                </div>
        )}
        <div className="d-flex title-returns" style={{marginLeft: 'auto'}}>
        <PRHButtonStandard
         className="ot-button ot-nav-button"
         size="medium"
         backgroundColorOverride="white"
         backgroundColorDisabledOverride="white"
         borderColorOverride="grey">
         Undo 
         </PRHButtonStandard>
         <PRHButtonStandard
         className="ot-button ot-nav-button"
         size="medium"
         backgroundColorOverride="white"
         backgroundColorDisabledOverride="white"
         borderColorOverride="grey">
         Undo All
         </PRHButtonStandard>
         <PRHButton
            size="big-wide"
            bold
            disabled={state.disabled}
            // className="pt-3"
            borderColorOverride="blue"
            textColorOverride="white"
            textColorHoverOverride="blue"
            backgroundColorOverride="blue"
            backgroundHoverColorOverride="white"
            iconColorOverride={state.disabled ? 'grey' : 'white'}
            iconColorHoverOverride="blue"
            icon="check"
            style={{ maxWidth: '100%' }}
            /*
            onClick={(e) => {
              submitForm(e);
            }}*/
          >
            Place Order
          </PRHButton>
         </div>
        </div>
        <div className="sg-table-header">
        <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Header</Table.HeaderCell>
        <Table.HeaderCell>Header</Table.HeaderCell>
        <Table.HeaderCell>Header</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      <Table.Row>
        <Table.Cell>
          <Label ribbon>First</Label>
        </Table.Cell>
        <Table.Cell>Cell</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Cell</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Cell</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
        <Table.Cell>Cell</Table.Cell>
      </Table.Row>
    </Table.Body>
  </Table>
      </div>
            </Container>
            </div>
          
            </PageStandard>
          </div>
        );
      })
    )
  )
);



