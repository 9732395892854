import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Divider } from '../../../components/elements/elements';
import useCart from '../../../components/hc/useCart';

export const TitleMetadataContainer = ({ format, formatKeys }) => {
  const cart = useCart();
  const history = useHistory();
  const hasFocDate = format?.foc && format?.foc !== null;
  const focDate = moment(format?.foc);

  const isComicBook = format.format.code === 'CB';
  const catEnd = (format.categories || [])
    .filter((cat) => cat.deepestNode === true)
    .map((cat) => format.categories.indexOf(cat));
  const catStartEnd = catEnd.map((cat, index) => {
    return [index === 0 ? 0 : catEnd[index - 1] + 1, cat];
  });
  const catArray = catStartEnd.map((startEnd) => {
    return format.categories.slice(startEnd[0], startEnd[1] + 1);
  });

  const renderCategoryRow = (categoryArray) => {
    const names = categoryArray.map((cat) => cat.catDesc);
    return (
      <li key={names.join(' > ')} className="tm-category-text-style">
        {names.join(' > ')}
      </li>
    );
  };
  const hasCatalogDate = format?.catalog && format.catalog !== null;
  const cataDate = moment(format?.catalog);

  return (
    <>
      <div className="mb-5px">
        <div className="tm-metadata-text-section">
          {isComicBook ? 'UPC: ' : ''}
          {format.isbnStr}
          <Divider horizontal />
          {formatKeys[format.format.code] || format.format.name}
        </div>
        <div className="tm-metadata-text-section">
          {focDate &&
            hasFocDate &&
            cart.isUserPow() &&
            `FOC: ${focDate.format('MM/DD/YYYY')}`}
          {focDate && hasFocDate && cart.isUserPow() && <Divider horizontal />}
          On Sale {moment(format.onSaleDate.date).format('MM/DD/YYYY')}
        </div>
      </div>
      {hasCatalogDate && cataDate && cart.isUserPow() && (
        <div className="mb-5px">
          <a
            href={`/catalog/${cataDate.format('YYYY-MM-DD')}`}
            className="tm-metadata-text-section"
            onClick={(e) => {
              e.preventDefault();
              history.push(`/catalog/${cataDate.format('YYYY-MM-DD')}`);
            }}
          >
            Catalog: {cataDate.format('MMMM YYYY')}
          </a>
        </div>
      )}
      <div className="mb-20px">
        <div className="tm-metadata-text-section">
          {parseFloat(format.dimensions.width) > 0
            ? `${parseFloat(format.dimensions.width).toFixed(2)} "W x `
            : ''}
          {parseFloat(format.dimensions.length) > 0
            ? `${parseFloat(format.dimensions.length).toFixed(2)} "H x `
            : ''}
          {parseFloat(format.dimensions.depth) > 0
            ? `${parseFloat(format.dimensions.depth).toFixed(2)} "D`
            : ''}
          {(parseFloat(format.dimensions.width) > 0 ||
            parseFloat(format.dimensions.length) > 0 ||
            parseFloat(format.dimensions.depth)) > 0 && <Divider horizontal />}
          {parseInt(format.totalPages) > 0
            ? `${parseInt(format.totalPages)} pages`
            : format.audioLength
            ? `Audio Length: ${format.audioLength} minutes`
            : ''}
          {(format.totalPages || format.audioLength) &&
            (format.totalPages > 0 || format.audioLength > 0 ? (
              <Divider horizontal />
            ) : (
              ''
            ))}
          {format.cartonQuantity && `${format.cartonQuantity} per carton`}
        </div>
        <div className="tm-metadata-text-section">
          {format.ageRange.description && `Age ${format.ageRange.description}`}
          {parseInt(format.ageRange.description) > 0 &&
            (format.ageRange.description?.length > 0 ? (
              <Divider horizontal />
            ) : (
              ''
            ))}
          {parseInt(format.dimensions.grossWeight) > 0
            ? `${parseInt(format.dimensions.grossWeight).toFixed(0)} Oz`
            : ''}
          {format.mediaRatingDesc &&
            (format.mediaRatingDesc?.length > 0 ? <Divider horizontal /> : '')}
          {format.mediaRatingDesc && `${format.mediaRatingDesc}`}
        </div>
      </div>
      <div className="mb-2">
        <div className="d-flex">
          <div className="tm-metadata-text-section">
            {format.division.description}
          </div>
          {format.division.description?.length > 0 ? (
            <Divider horizontal />
          ) : (
            ''
          )}
          <div className="tm-metadata-text-section">{format.imprint.name}</div>
        </div>
        <div className="tm-metadata-text-section">
          Sales Rights: {format.salesRestriction.description}
        </div>
      </div>
      {format.additionalReadingLevels.Lexile !== null && !isComicBook && (
        <div className="tm-info-text-style">
          Lexile: {format.additionalReadingLevels.Lexile}
        </div>
      )}
      {format.additionalReadingLevels['Fountas/Pinnell'] !== null &&
        !isComicBook && (
          <div className="tm-info-text-style">
            Fountas/Pinnell: {format.additionalReadingLevels['Fountas/Pinnell']}
          </div>
        )}
      {format.educationGradeRange.code !== null && !isComicBook && (
        <div className="tm-info-text-style">
          Education Grade Range: {format.educationGradeRange.description}
        </div>
      )}
      {!isComicBook && (
        <div className="tm-category-ul">
          <ul>{catArray.map(renderCategoryRow)}</ul>
        </div>
      )}
    </>
  );
};
