import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { formatDate, isGeneralCart } from "../../utils/utilities";
import { NumericFormat } from "react-number-format";
import { isFirefox } from "react-device-detect";
import { Grid, Form, Input, Popup } from "semantic-ui-react";
import InventoryMessage from "./InventoryMessage";
import { Divider } from "../../components/elements/elements";
import useSecurity from "../../components/hc/useSecurity";
import { withRouter } from "react-router";
import withApp from "../../components/hc/withApp";
import AddToCartButton from "./AddToCartButton";
import "./TitleRow.scss";
import LazyImage from "../../utils/LazyImage";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import VariantRestrictionsInfoModal from "../modals/VariantRestrictionsInfoModal";
import useCart from "../hc/useCart";
import VariantRestrictionsOrderingModal from "../modals/VariantRestrictionsOrderingModal";
import VariantRestrictionsOrderUpdateModal from "../modals/VariantRestrictionsOrderUpdateModal";
import usePrevious from "../hc/usePrevious";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";

const showOnMobile = false;

const TitleRowMobile = (props) => {
  const {
    divider,
    pageType,
    tab,
    disabled = false,
    simulate,
    update = false,
    onRefreshTab,
    key,
    showShortInformation = false,
    showQty = false,
  } = props;
  const {
    isbn,
    title,
    subtitle,
    formatName,
    onsale,
    author,
    currency,
    inventoryMsg,
    restockMsg,
    managedMsg,
    price,
    discountedPriceTotal,
    priceSingle,
    cartonQuantity,
    divisionName,
    foc,
    available,
    itemId,
    quantity = 1,
    focStatus,
    cartType = false,
    deliveryDate = false,
    basePctDiscount,
    isVariant,
    hasOrderReq,
    variantCount,
    orderReq,
    cartQuantity,
    confirmationStatus = "NONE",
  } = props.data;

  //console.log('###################', props.data);
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const [qty, setQty] = useState(quantity ? quantity : "1");
  const [updatingItem, setUpdatingItem] = useState(false);
  const [variantOrderingModalState, setVariantOrderingModalState] =
    useState(false);
  const [variantOrderUpdateModalState, setVariantOrderUpdateModalState] =
    useState(false);
  const [restrictionsNew, setRestrictionsNew] = useState(false);
  const [titleRestrictions, setTitleRestrictions] = useState(false);
  const [titleRestrictionsQty, setTitleRestrictionsQty] = useState(false);
  const previousQuantity = usePrevious(quantity);
  const user = useSecurity();
  const cart = useCart();

  const [moreInformationVisible, setMoreInformationVisible] = useState(false);
  const showMoreInfo =
    props.context?.shouldShowMoreInformation ||
    (showShortInformation && moreInformationVisible);

  if (previousQuantity && quantity !== previousQuantity && quantity !== qty) {
    setQty(quantity);
  }

  const isCartBadge = () => {
    if (user.isAppCompCopy()) return false;

    if (
      cart.customerInfo &&
      cart.customerInfo.currentUser &&
      cart.customerInfo.currentUser.roleNo !== "W1"
    )
      return false;

    return true;
  };

  const formRef = useRef();

  const cancelCallback = () => {
    setQty(quantity);
    setTitleRestrictions(false);
    setTitleRestrictionsQty(false);
    setRestrictionsNew(false);

    setUpdatingItem(false);
  };

  const proceedCallback = () => {
    setUpdatingItem(false);
  };
  // console.log('---------', pageType, props.location);
  const searchPage = props.location.pathname === "/search" ? true : false;
  return (
    <div id={`${pageType === "catalog" ? "cat-data-mobile" : ""}`} key={key}>
      <VariantRestrictionsOrderingModal
        isbn={isbn}
        qtyWant={qty}
        openState={variantOrderingModalState}
        restrictionsNew={restrictionsNew}
        setOpenState={(w) => {
          setVariantOrderingModalState(w);
        }}
        shipTo={cart.getShipToAccount().accountNo}
        foc={foc}
        cancelCallback={cancelCallback}
        callBackFunction={proceedCallback}
        update={update}
      />
      <VariantRestrictionsOrderUpdateModal
        isbn={isbn}
        title={title}
        openState={variantOrderUpdateModalState}
        titleRestrictions={titleRestrictions}
        setOpenState={(w) => {
          setVariantOrderUpdateModalState(w);
        }}
        shipTo={cart.getShipToAccount().accountNo}
        foc={foc}
        quantity={quantity}
        titleRestrictionsQty={titleRestrictionsQty}
        cancelCallback={cancelCallback}
        proceedCallback={proceedCallback}
      />
      <Grid
        container
        className={`${isMobile ? "my-2" : "my-1"}`}
        //style={{border:'1px solid #ddd', padding:'14px', borderRadius:'2px', backgroundColor:'#fff'}}
      >
        <Grid.Row>
          <Grid.Column
            mobile={5}
            tablet={5}
            computer={1}
            largeScreen={1}
            widescreen={1}
          >
            <div className={`coverImageDiv ${isMobile ? "mobile" : ""}`}>
              {available === true ? (
                <a
                  className=" text-gray-900"
                  href={`/titleinfo/${isbn}`}
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(`/titleinfo/${isbn}`);
                  }}
                >
                  <LazyImage
                    className="title-cover"
                    isVariant={isVariant}
                    variantCount={pageType === "cart" ? 0 : variantCount}
                    isSmallThumbNail={pageType === "cart" ? true : false}
                    boxShadow={true}
                    src={`https://images.penguinrandomhouse.com/cover/${isbn}`}
                  />
                </a>
              ) : (
                <LazyImage
                  className="title-cover"
                  isVariant={isVariant}
                  variantCount={pageType === "cart" ? 0 : variantCount}
                  isSmallThumbNail={pageType === "cart" ? true : false}
                  boxShadow={true}
                  src={`https://images.penguinrandomhouse.com/cover/${isbn}`}
                />
              )}
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={11}
            tablet={11}
            computer={11}
            largeScreen={11}
            widescreen={11}
            className={pageType === "cart" ? "ttl-cart-meta" : ""}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={10}
                  largeScreen={12}
                  widescreen={12}
                >
                  <div className="d-flex">
                    <div className={"agCellData mt-1 ttl-meta"}>
                      <div className={"rowItem title-subtitle-blk lableMedium"}>
                        {available === true ? (
                          <a
                            className=" text-gray-900"
                            href={`/titleinfo/${isbn}`}
                            onClick={(e) => {
                              e.preventDefault();
                              props.history.push(`/titleinfo/${isbn}`);
                            }}
                          >
                            {title?.length > 40
                              ? title.substring(0, 40) + "..."
                              : title}
                          </a>
                        ) : title?.length > 40 ? (
                          title.substring(0, 40) + "..."
                        ) : (
                          title
                        )}

                        {subtitle != null ? (
                          <span className="rowItem">
                            :{" "}
                            {subtitle?.length > 50
                              ? subtitle.substring(0, 50) + "..."
                              : subtitle}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {author != null ? (
                        <div className="rowItem title-subtitle-blk">
                          {author.match("^Written") || author.match("^By")
                            ? ""
                            : "By "}
                          {/* {author} */}
                          {author?.length > 40
                            ? author.substring(0, 40) + "..."
                            : author}
                        </div>
                      ) : (
                        ""
                      )}
                      {showMoreInfo && divisionName != null ? (
                        <div className="rowItem  title-subtitle-blk">
                          {divisionName}
                        </div>
                      ) : (
                        ""
                      )}
                      {showMoreInfo && (
                        <>
                          <div className="rowItem">{isbn}</div>
                          <div className="rowItem  title-subtitle-blk">
                            <span>{formatName}</span>{" "}
                            <span className="pipe"> | </span>{" "}
                            <span>{formatDate(onsale, false)}</span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={6}
                  largeScreen={4}
                  widescreen={4}
                  className={`second-col mobile-inv-msg-height ${
                    pageType === "catalog" ? "catalog-sec-col" : ""
                  } ${searchPage ? "mobile-inv-msg-height" : ""}`}
                >
                  {showMoreInfo && inventoryMsg != null ? (
                    <InventoryMessage
                      msg={inventoryMsg}
                      classes="second-col-items"
                    />
                  ) : (
                    ""
                  )}
                  {showMoreInfo && managedMsg != null ? (
                    <InventoryMessage
                      msg={managedMsg}
                      classes="second-col-items"
                      bgColor="grey-back"
                    />
                  ) : (
                    ""
                  )}
                  {showMoreInfo && restockMsg != null ? (
                    <InventoryMessage
                      msg={restockMsg}
                      classes="second-col-items"
                      bgColor="grey-back"
                    />
                  ) : (
                    ""
                  )}

                  {showMoreInfo && foc != null && !isGeneralCart(foc) ? (
                    <div className="rowItem second-col-itemss">
                      FOC {formatDateMMDDYYYY(foc, "/")}
                    </div>
                  ) : (
                    ""
                  )}
                  {showMoreInfo && cartonQuantity != null ? (
                    <div
                      className={`rowItem second-col-items ${
                        (inventoryMsg === null && foc === null) || foc !== null
                          ? !searchPage
                            ? "carton-div"
                            : ""
                          : ""
                      } ${isMobile ? "px-0" : ""}`}
                    >
                      {cartonQuantity} per carton
                    </div>
                  ) : (
                    ""
                  )}
                  {deliveryDate && user.isAppBiz() ? (
                    <div className="d-flex flex-column cp-mb-1">
                      <div className="fort-book-italic est-del">
                        Est Delivery:
                        {deliveryDate !== "0000-00-00"
                          ? formatDate(deliveryDate, false)
                          : "Not Available"}
                      </div>
                    </div>
                  ) : null}
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={6}
                  largeScreen={4}
                  widescreen={4}
                  className="third-col"
                >
                  {showQty && (
                    <div className="d-flex align-items-center mb-1 fs-13px lh-20px">
                      Qty: {qty}
                    </div>
                  )}
                  {cartQuantity > 0 ? (
                    <div className="d-flex align-items-center lh-18px fs-13px">
                      <div
                        className={`tm-cart-status-ind mr-1 ${
                          confirmationStatus === "CONFIRMED" ? "confirmed" : ""
                        }`}
                      />
                      In cart: &nbsp;
                      <span className="fort-bold">{cartQuantity}</span>
                    </div>
                  ) : null}
                  <div className="rowItem mt-1 third-col-items d-flex justify-content-start">
                    <div
                      className={`lh-16 lableMedium ${
                        isMobile ? "fs-14px" : "fs-12px"
                      } `}
                    >
                      <NumericFormat
                        className={`${isMobile ? "" : "ml-1"}`}
                        value={
                          discountedPriceTotal ? discountedPriceTotal : price
                        }
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        prefix={"$"}
                      />{" "}
                      {currency}
                    </div>
                  </div>
                  <div className="rowItem third-col-items d-flex justify-content-start discount-price-item">
                    <div className="fs-12px lh-16 ">
                      {basePctDiscount > 0 ? (
                        <>
                          <NumericFormat
                            className=""
                            value={basePctDiscount}
                            thousandSeparator={true}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            displayType={"text"}
                            suffix={"%"}
                          />{" "}
                          off <span className="strike-txt">${priceSingle}</span>{" "}
                          (each)
                        </>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                  {/* {showOnMobile && (
                    <Form
                      ref={formRef}
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (qty === quantity) return;
                        if (!qty || qty === '' || parseInt(qty) === 0) {
                          setQty(quantity);
                          return;
                        }
                        if (pageType === 'cart') {
                          setUpdatingItem(true);
                          if (cart.isUserPow()) {
                            cart
                              .performEstimateItemQtyUpdate({
                                simulate: simulate,
                                foc: foc,
                                cartType: cartType
                                  ? cartType
                                  : isGeneralCart(foc)
                                  ? 'GEN'
                                  : 'FOC',
                                items: [
                                  {
                                    ...props.data,
                                    updateQuantity: parseInt(qty),
                                  },
                                ],
                              })
                              .then((e) => {
                                if (e?.modalInfo === 'REMOVE_VARIANTS') {
                                  setTitleRestrictions(e.mainVariantTitles);
                                  setTitleRestrictionsQty(qty);
                                  setVariantOrderUpdateModalState(true);
                                } else if (
                                  e?.modalInfo === 'ADD_QUALIFYING_ITEMS'
                                ) {
                                  setRestrictionsNew(e.variantInfo);
                                  setVariantOrderingModalState(true);
                                } else {
                                  setUpdatingItem(false);
                                }
                              });
                          } else {
                            if (tab === 0) {
                              cart
                                .performItemQtyUpdate(itemId, qty)
                                .then((e) => {
                                  setUpdatingItem(false);
                                });
                            } else {
                              cart
                                .performItemSaveForLaterQtyUpdate(itemId, qty)
                                .then((e) => {
                                  setUpdatingItem(false);
                                });
                            }
                          }
                        }
                      }}
                      className="d-flex align-items-center"
                    >
                      <div className="rowItem qty-box mt-1 third-col-items">
                        <span className="qty-lbl text-gray-700"> Qty </span>

                        <Input
                          disabled={
                            disabled ? true : !available || isCartBadge()
                          }
                          type={isFirefox ? 'text' : 'number'}
                          max="99999"
                          className={`search-title-input ${
                            isFirefox ? 'ff' : ''
                          }`}
                          id={`qty_${isbn}`}
                          value={qty}
                          onBlur={(e) => {
                            if (
                              qty &&
                              qty !== '' &&
                              parseInt(qty) > 0 &&
                              parseInt(quantity) !== parseInt(qty)
                            ) {
                              if (!updatingItem)
                                formRef.current.handleSubmit(e);
                            } else if (!qty || parseInt(qty) === 0)
                              setQty(quantity);
                          }}
                          onChange={(e) => {
                            if (
                              parseInt(e.target.value) > 0 ||
                              e.target.value === ''
                            ) {
                              setQty(e.target.value);
                            }
                          }}
                        />
                      </div>
                      {cartQuantity > 0 ? (
                        <div className="flex flex-column align-items-center tr-cart-qty ml-2">
                          <div className="fort-bold">{cartQuantity}</div>
                        </div>
                      ) : null}
                    </Form>
                  )} */}
                  {pageType === "cart" ? (
                    <>
                      <div
                        className={`text-blue cursor-pointer mr-1 ${
                          showShortInformation ? "mr-auto" : ""
                        }`}
                        onClick={() =>
                          setMoreInformationVisible(!moreInformationVisible)
                        }
                      >
                        {moreInformationVisible
                          ? "View less information"
                          : "View more information"}
                      </div>
                      {hasOrderReq ? (
                        <div className="d-flex flex-row justify-content-start mt-1 mb-1">
                          {/* <span
                            style={{ marginRight: "0.15rem" }}
                            className="fort-book-italic fs-13px lh-18 text-gray-700 align-self-end"
                          >
                            Ordering restrictions
                          </span> */}
                          <VariantRestrictionsInfoModal
                            isbn={isbn}
                            searchClass={cart.customerInfo.customer.searchClass}
                            country={cart.country}
                            orderReq={orderReq}
                            title={title}
                          />
                        </div>
                      ) : null}
                    </>
                  ) : (
                    (showOnMobile || hasOrderReq) && (
                      <div className="rowItem third-col-items d-flex flex-flow-column align-items-start">
                        {showOnMobile && (
                          <AddToCartButton
                            isBtnAvailable={available}
                            focStatus={focStatus}
                            foc={foc}
                            qty={qty}
                            isbn={isbn}
                            onSaleDate={onsale}
                            hasOrderReq={hasOrderReq}
                            orderReq={orderReq}
                            callback={onRefreshTab}
                            className="mobile-add-to-cart-button"
                          />
                        )}
                        {hasOrderReq ? (
                          <div className="d-flex flex-row justify-content-center mt-1 mb-1 mr-4 ">
                            {/* <span
                              style={{ marginRight: "0.15rem" }}
                              className="fort-book-italic fs-13px lh-18 text-gray-700 align-self-end"
                            >
                              Ordering restrictions
                            </span> */}
                            <VariantRestrictionsInfoModal
                              isbn={isbn}
                              searchClass={
                                cart.customerInfo.customer.searchClass
                              }
                              country={cart.country}
                              orderReq={orderReq}
                              title={title}
                            />
                          </div>
                        ) : null}
                      </div>
                    )
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {divider ? <Divider tight className="mt-2 mb-2" /> : null}
    </div>
  );
};

export default withRouter(withApp(TitleRowMobile));

TitleRowMobile.propTypes = {
  data: PropTypes.object.isRequired,
  pageType: PropTypes.string,
  tab: PropTypes.number,
  divider: PropTypes.bool,
  user: PropTypes.object,
  cart: PropTypes.object,
  app: PropTypes.object,
  simulate: PropTypes.bool,
};
TitleRowMobile.defaultProps = {
  pageType: "",
  tab: 0,
  divider: false,
  simulate: false,
};
