import { useState } from 'react';
import { Container, Icon } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import PRHButton from '../shared-react-components/elements/prhbutton';

export const ErrorComponent = withRouter((props) => {
  const [techOpen, setTechOpen] = useState(false);
  return (
    <div className="App bg-gray-100 error-page" style={{ height: '94vh' }}>
      <Container className="pt-5 header-container">
        <div className="err-box-border d-flex mt-3">
          <div className="bg-white p-6 w-100">
            {props.error.status !== 401 ? (
              <div className="fs-6 fort-extrabold text-gray-900 mb-4">
                {props.from === 'title'
                  ? 'This page is not available.'
                  : 'Oops!'}
              </div>
            ) : (
              <div className="fs-4 fort-extrabold text-gray-900 mb-4">
                Your session has expired, you will be redirected to the login
                page...
              </div>
            )}
            <div className="fort-light fs-3 text-gray-900 mb-4">
              {props.error.status === 401 ? (
                <div>{props.error.statusText}</div>
              ) : (
                <>
                  {props.from === 'title'
                    ? 'This product page is not available at the moment.'
                    : 'Something has gone wrong.'}
                </>
              )}
            </div>
            {props.error.status !== 401 ? (
              <div className="mb-4">
                <PRHButton
                  icon="left arrow"
                  onClick={(e) => props.history.go(-1)}
                >
                  Go Back
                </PRHButton>
              </div>
            ) : (
              <></>
            )}

            {props.from !== 'title' && (
              <div className="fort-light mb-1">
                <div>If you keep seeing this page, contact</div>
                <a href="mailto:bizcs@prh.com">bizcs@prh.com</a>
                <div>and mention this error code:</div>
              </div>
            )}
            <div
              className={`r-view-more fort-bold mb-2 text-uppercase cursor-pointer ${
                techOpen ? ' text-orange-important' : ' text-gray-500'
              } `}
              onClick={(e) => setTechOpen(!techOpen)}
            >
              view more details{' '}
              <Icon
                name={`angle ${techOpen ? 'up' : 'down'}`}
                className={`${
                  techOpen ? ' text-orange-important' : ' text-gray-500'
                }`}
              ></Icon>
            </div>
            <div className={`shadow p-3 r-tech ${techOpen && ' r-tech-open'}`}>
              <div>Error: {props.error.errorId}</div>
              <div>
                {props.error.status && (
                  <div className="mt-1">
                    Status Code:&nbsp;
                    {props.error.response
                      ? props.error.response.status
                      : props.error.status}
                  </div>
                )}

                {props.error.config && (
                  <div>
                    <div className="mt-1">
                      {`Url: ${props.error.config.url}`}
                    </div>
                    {props.error.config.headers && (
                      <div className="mt-1">
                        Headers:
                        <div className="mt-1 ml-2">
                          {Object.keys(props.error.config.headers).map(
                            (k, i) => {
                              return (
                                <div key={i} className="word-wrap-break-word">
                                  {k}:{props.error.config.headers[k].toString()}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="mt-1">
                  Messages:
                  {props.error.statusText && (
                    <div className="ml-2">{props.error.statusText}</div>
                  )}
                  {props.error.error && (
                    <div className="ml-2">{props.error.error}</div>
                  )}
                  {props.error.response &&
                    props.error.response.error &&
                    props.error.response.error.message && (
                      <div className="ml-2">
                        {props.error.response.error.message}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          {/*<div className="d-flex err-box-border-left p-6 flex-column w-40 r-text fort-light">
              <div>To have no errors</div>
              <div>Would be life without meaning</div>
              <div>No struggle, no joy</div>
              </div>*/}
        </div>
      </Container>
    </div>
  );
});
