import { useLayoutEffect, useState } from 'react';

export default function  useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  function debounce(func, time) {
    var time = time || 100; // 100 by default if no param
    var timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, time, event);
    };
  }

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', debounce(updateSize, 250));
    updateSize();
    return () => window.removeEventListener('resize', debounce(updateSize, 250));
  }, []);
  return size;
}