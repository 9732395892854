export const specialCharacterRegex = /^[a-zA-Z0-9\s]*$/;
export const validateSpecialCharacters = (string) =>
  specialCharacterRegex.test(string);

export const specialCharacterWithWhitespaceRegex = /^[a-zA-Z0-9\s]*$/;
export const validateSpecialCharacterWithWhitespaceRegex = (string) =>
  specialCharacterWithWhitespaceRegex.test(string);

export const lettersAndNumbersRegex = /[a-zA-Z0-9]*$/;
export const validateLettersAndNumbers = (string) =>
  lettersAndNumbersRegex.test(string);

export const validateNumbers = (number) => !isNaN(number);

export const lettersRegex = /^[a-zA-Z\s-]*$/;
export const validateLetters = (string) => lettersRegex.test(string);

export const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmail = (email) => emailRegex.test(email);

export const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const validatePhone = (phone) => phoneRegex.test(phone);
