import React from "react";
import PropTypes from "prop-types";
import { Loader, Modal } from "semantic-ui-react";
import DialogModal from "./DialogModal";
import useSecurity from "../hc/useSecurity";

function BridgeReceivingNotOwnerModal(props) {
  const { open, setOpen, callback = false, cancelCallback = false } = props;
  const user = useSecurity();
  // console.log('user', user);
  return (
    <DialogModal
      setOpen={setOpen}
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      onXClose={(e) => {
        if (cancelCallback) cancelCallback();
      }}
      contentPt='pt2'
      className='bridge-not-owner-modal'
      dialogOptions={{
        header: "SOMEONE IS ALREADY SIGNED IN",
        actions: [
          {
            hidden: false,
            label: "Cancel",
            preAction: async () => {
              return { proceed: true };
            },
            action: () => {
              if (cancelCallback) cancelCallback();
              return { proceed: true };
            },
          },
          {
            hidden: false,
            label: "Sign In",
            textColorOverride: "white",
            backgroundColorOverride: "blue",
            borderColorOverride: "blue",
            textColorHoverOverride: "blue",
            backgroundHoverColorOverride: "white",
            borderHoverColorOverride: "blue",
            bold: true,
            preAction: async () => {
              return { proceed: true };
            },
            action: () => {
              if (callback) callback();
              return { proceed: true };
            },
          },
        ],
      }}
      options={{ className: "tm-modal-container bridge-not-owner-container" }}
    >
      <div className='fort-bold fs14-px lh19-px'>
        Oops! {user.user.profile.email} is currently signed in to Self-Service.
      </div>
      <div className='fort-book mt-2 mb-1'>
        Please sign in to switch users and finish adding items to your cart.
      </div>
      <div className='fort-book'>
        {user.user.profile.email} will be signed out.
      </div>
    </DialogModal>
  );
}

BridgeReceivingNotOwnerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default BridgeReceivingNotOwnerModal;
