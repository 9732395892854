import { useState, useEffect } from "react";
import {
  Header,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./VariantRestrictionsCartDeleteModal.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import useCart from "../hc/useCart";
import LazyImage from "../../utils/LazyImage";

const VariantRestrictionsCartDeleteModal = (props) => {
  const {
    foc,
    shipTo,
    openState = false,
    mainVariantTitles = [],
    setOpenState,
    proceedCallback,
    cancelCallback,
  } = props;

  // console.log("VariantRestrictionCartDeleteModal", props);
  const cart = useCart();
  const [actionPerformed, setActionPerformed] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const resetState = () => {
    setDialogVisible(false);
  };

  let numVariantTitles = 0;
  let numVariantLocations = 0;
  let numEffectedLocationsMap = {};
  let numQualifyingTitles = 0;
  let numQualifyingTitlesQty = 0;
  let variantLocation = false;
  let currentLocationEffected = false;

  mainVariantTitles.forEach((mvt) => {
    numQualifyingTitles += mvt.qualifyingTitles.length;
    numQualifyingTitlesQty += mvt.totalQualifyingQuantity;
    mvt.affectedVariantTitles.forEach((al) => {
      numVariantTitles++;
      al.affectedLocations.forEach((l) => {
        numEffectedLocationsMap[l.shipTo] = true;
        if (l.shipTo.toString() !== shipTo.toString()) {
          numVariantLocations++;
          variantLocation = l.shipTo;
        } else {
          currentLocationEffected = true;
        }
      });
    });
  });
  let numEffectedLocations = Object.keys(numEffectedLocationsMap).length;

  let headerMsg = (
    <span>
      You are trying to remove all items of FOC {formatDateMMDDYYYY(foc, "/")}.
      This will disqualify and remove {numVariantTitles} variant titles’
      following quantities from {numEffectedLocations} accounts:{" "}
    </span>
  );
  let footerMsg = (
    <span className='fort-book fs-13px lh-18px'>
      Are you sure you want to continue and remove all items from these
      account(s)?
    </span>
  );

  const ContinueAction = {
    label: "Yes, Continue",
    borderColorOverride: "blue",
    textColorOverride: "white",
    textColorHoverOverride: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "white",
    iconColorOverride: "white",
    iconColorHoverOverride: "blue",
    // icon: 'checkmark',
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      setActionPerformed(true);
      // updateRemove();
      if (proceedCallback) proceedCallback();
      resetState();
      return { proceed: true };
    },
  };

  const CancelAction = {
    label: "Cancel",
    className: "ml-auto",
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      resetState();
      if (cancelCallback) cancelCallback();
      return { proceed: true };
    },
  };

  useEffect(() => {
    if (dialogVisible) {
      if (!openState) {
        if (!actionPerformed && cancelCallback) cancelCallback();
        resetState();
        setActionPerformed(false);
      }
    }
    if (openState && !dialogVisible) {
      setDialogVisible(true);
    }
  }, [actionPerformed, cancelCallback, dialogVisible, openState]);

  // console.log(
  //   'titleRestrictions',
  //   shipTo,
  //   foc,
  //   mainVariantTitles,
  //   numVariantTitles,
  //   numVariantLocations
  // );

  return (
    <DialogModal
      open={openState}
      //open
      setOpen={setOpenState}
      // onXClose={cancelCallback}
      modalClassName='vro-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            {numVariantLocations > 0
              ? "Changes to Other Account(s)' Carts"
              : "CHANGES TO YOUR CART"}
          </Header>
        ),
        actions: [CancelAction, ContinueAction],
      }}
    >
      <div className='ar-box'>
        <>
          <div className='ml-4 mr-4 mb-2'>{headerMsg}</div>
          <Divider className='mt-0 mb-0' />
          <div
            style={{ maxHeight: "400px" }}
            className={`overflow-auto bg-gray-100 ${
              numVariantLocations === 0 && "Xpb-2"
            }`}
          >
            {mainVariantTitles.map((mvt, mvt_idx) => {
              return mvt.affectedVariantTitles.map((title, idx) => {
                return (
                  <div key={`mvt-${mvt_idx}-${idx}`}>
                    {(mvt_idx > 0 || idx > 0) && (
                      <Divider className='mb-1' tight />
                    )}
                    <div className='d-flex flex-row pl-4 pr-4 mt-1 mb-1 pt-1'>
                      <LazyImage
                        className='vri-title-cover'
                        boxShadow={true}
                        src={`https://images.penguinrandomhouse.com/cover/${title.isbn}`}
                      ></LazyImage>

                      <div className='d-flex flex-column ml-2 justify-content-center'>
                        <div className='fort-bold fs-13px lh-18px text-gray-700'>
                          {title.title}
                        </div>
                        <div className='fort-book fs-13px lh-18px text-gray-700'>
                          {title.isbn}
                        </div>
                      </div>
                      <div className='d-flex flex-column ml-auto justify-content-center'>
                        <div className='fort-book fs-13px lh-18px text-gray-700 ml-auto'>
                          {title.affectedLocations.length > 1 ||
                          (title.affectedLocations.length === 1 &&
                            title.affectedLocations[0].shipTo.toString() !==
                              shipTo.toString())
                            ? "Total"
                            : ""}{" "}
                          Qty:{" "}
                          <span className='fort-bold'>{title.quantity}</span>
                        </div>
                      </div>
                    </div>
                    {(title.affectedLocations.length > 1 ||
                      title.affectedLocations.length === 1) &&
                      title.affectedLocations[0].shipTo === shipTo && (
                        <div>
                          {title.affectedLocations.map((al, i) => {
                            return (
                              <div key={i} className={`pl-4 pr-4 `}>
                                <Divider tight />
                                <div className='d-flex flex-row mt-1 mb-1'>
                                  <div className=''>
                                    Account #{al.shipTo}{" "}
                                    <span className='fort-book-italic'>
                                      {al.thisAccount ? (
                                        "(This cart)"
                                      ) : al.confirmationStatus ===
                                        "CONFIRMED" ? (
                                        <>
                                          (Confirmed){" "}
                                          <Popup
                                            className='cp-help-tooltip'
                                            content='This account’s cart has had no updates since it was Confirmed. Continuing will update their cart and requires re-confirmation.'
                                            on='hover'
                                            position='right center'
                                            offset={[0, 5]}
                                            trigger={
                                              <Icon
                                                name='question circle'
                                                className='sb_question_icon'
                                              />
                                            }
                                          />
                                        </>
                                      ) : null}
                                    </span>
                                  </div>
                                  <div className='d-flex flex-column ml-auto justify-content-center'>
                                    <div className='fort-book fs-13px lh-18px text-gray-700 ml-auto'>
                                      Qty:{" "}
                                      <span className='fort-bold'>
                                        {al.quantity}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    {(title.affectedLocations.length > 1 ||
                      title.affectedLocations.length === 1) &&
                      title.affectedLocations[0].shipTo !== shipTo && (
                        <div>
                          {title.affectedLocations.map((al, i) => (
                            <div key={i} className={`pl-4 pr-4 `}>
                              <Divider tight />
                              <div className='d-flex flex-row mt-1 mb-1'>
                                <div className=''>
                                  Account #{al.shipTo}{" "}
                                  <span className='fort-book-italic'>
                                    {shipTo === al.shipTo ? (
                                      "(This cart)"
                                    ) : al.confirmationStatus ===
                                      "CONFIRMED" ? (
                                      <>
                                        (Confirmed){" "}
                                        <Popup
                                          className='cp-help-tooltip'
                                          content='This account’s cart has had no updates since it was Confirmed. Continuing will update their cart and requires re-confirmation.'
                                          on='hover'
                                          position='right center'
                                          offset={[0, 5]}
                                          trigger={
                                            <Icon
                                              name='question circle'
                                              className='sb_question_icon'
                                            />
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </span>
                                </div>
                                <div className='d-flex flex-column ml-auto justify-content-center'>
                                  <div className='fort-book fs-13px lh-18px text-gray-700 ml-auto'>
                                    Qty:{" "}
                                    <span className='fort-bold'>
                                      {al.quantity}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                );
              });
            })}
          </div>
          <Divider className='mt-0 mb-0' />
          <div className='ml-4 mr-4 mt-2 fort-bold fs-12px lh-14px'>
            {footerMsg}
          </div>
        </>
      </div>
    </DialogModal>
  );
};

VariantRestrictionsCartDeleteModal.propTypes = {
  openState: PropTypes.bool.isRequired,
  setOpenState: PropTypes.func.isRequired,
};

VariantRestrictionsCartDeleteModal.defaultProps = {};

export default VariantRestrictionsCartDeleteModal;
