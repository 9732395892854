import { useContext } from "react";
import AppContext from "../../providers/ApplicationContext";
import CartContext from "../../providers/CartContext";
import AdminContext from "../../providers/AdminContext";
import SecurityContext from "../../providers/SecurityContext";
import MobileContext from "../../providers/MobileContext";

export default function useAll() {
  const app = useContext(AppContext);
  const user = useContext(SecurityContext);
  const cart = useContext(CartContext);
  const admin = useContext(AdminContext);
  const mobile = useContext(MobileContext);

  return { app, user, cart, admin, mobile };
}
