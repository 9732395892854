import { Component, PureComponent } from 'react';
import localforage from 'localforage';
import withCart from '../components/hc/withCart';
import withAccount from '../components/hc/withRegistration';
import withApp from '../components/hc/withApp';
import withSecurity from '../components/hc/withSecurity';
import AdminContext from './AdminContext';

class AdminProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      addresses: [],
      customer: {},
      shipTosDropDown: [],
      shipTos: [],
      userListLoading: true,
      manageUserModal: { open: false, header: 'Add User' },
      account: {
        accountNo: '',
        sanNo: '',
      },
    };
  }

  handleChange = (e, { name, value }) => {
    const newState = { ...this.state };
    newState.createUserForm[name] = value;
    this.setState(newState);
    localforage.setItem('appState', this.state.createUserForm);
    const { createUserForm, passwordValidationScore } = this.state;
    this.checkPassword(
      createUserForm.createPassword,
      createUserForm.firstName,
      createUserForm.lastName
    );
    if (createUserForm.createPassword !== '' && passwordValidationScore < 7) {
      this.setState({ openPasswordValidationPopUp: true });
    } else {
      this.setState({ openPasswordValidationPopUp: false });
    }
  };

  handleSubmitUser = (event) => {
    const { createUserForm } = this.state;
    const testPasswordValidation = this.checkPassword(
      createUserForm.createPassword,
      createUserForm.firstName,
      createUserForm.lastName
    );
    if (createUserForm.createPassword === createUserForm.confirmPassword) {
      this.setState({ passwordsMatch: 1 });
    }
    if (
      createUserForm.createPassword === createUserForm.confirmPassword &&
      testPasswordValidation === 7
    ) {
      this.setCreateUserPost();
    } else {
      this.setState({ passwordsMatch: 0, openPasswordValidationPopUp: true });
    }
  };

  handleSubmitSubAccountUser = (e) => {
    this.setState((state) => {
      return {
        userList: [
          ...state.userList,
          {
            verified: false,
            firstName: e.firstName,
            lastName: e.lastName,
            role: 'Level 2: Ordering',
            email: '',
            sapAccount: '',
            userAddress: {
              address1: '',
              address2: '',
              city: '',
              state: '',
              postalCode: '',
            },
          },
        ],
        manageUserModal: { open: false, header: '' },
      };
    });
  };

  editControl = (status, id, header) => {
    const { userList } = this.state;
    const newState = { ...this.state };
    const { account } = this.props;
    const user = userList.find((user) => user.email === id);
    account.loadAccountUser(user);
    newState.manageUserModal.open = status;
    newState.manageUserModal.header = header;
    this.setState(newState);
    this.setState({ manageUserModal: { open: status, header: header } });
    //return user;
  };

  manageUserModal = (status, header) => {
    header = typeof header !== 'undefined' ? header : '';
    this.setState({ manageUserModal: { open: status, header: header } });
  };

  setCreateUserPost = () => {
    this.setState((state) => {
      return {
        step: 4,
        createUserPost: {
          san: state.createUserForm.san,
          sapAcct: state.createUserForm.sapAcct,
          firstName: state.createUserForm.firstName,
          lastName: state.createUserForm.lastName,
          fullName:
            state.createUserForm.firstName +
            ' ' +
            state.createUserForm.lastName,
          userName: state.createUserForm.email,
          email: state.createUserForm.email,
          password: 'D3f4ultP4ss!',
        },
      };
    }, this.postUser);
  };

  nextStepHandler = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  previousStepHandler = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  setStepHandler = (e) => {
    this.setState({ step: e });
  };

  async getCustomerInfo(accountNo) {
    const newState = { ...this.state };
    this.props.app.accountApi.getCustomerInfo(accountNo).then((response) => {
      newState.loading = false;
      if (response.data.status === 'System_Error') {
        newState.systemResponse = 'System unavailable, please try again later.';
      } else if (response.data.status === 'OK') {
        newState.shipTos = response.data.data.shipTos;
      } else {
      }
      this.setState(newState);
    });
  }

  async postUser() {
    const { createUserPost } = this.state;
    const userPostData = {
      user: {
        email: createUserPost.email,
        firstName: createUserPost.firstName,
        lastName: createUserPost.lastName,
        fullName: createUserPost.fullName,
        userName: createUserPost.userName,
        groups: null,
        sapAcct: createUserPost.sapAcct,
        pid: '1234',
        san: createUserPost.san,
        zipcode: null,
        mobile: null,
      },
      password: createUserPost.password,
      /*
      notificationEmail: {
        from: 'bdasgupta@penguinrandomhouse.com',
        fromName: 'Biz Notification Services',
        subject: 'Verify Email',
        templateId: 'verificationEmail',
      },
      */
    };
    let asyncData = await this.props.app.registrationApi.createUser(
      userPostData
    );
    if (asyncData.status === 201) {
      this.setState({ addUserModal: false });
    } else {
      this.setState({ step: 'error' });
    }
  }

  checkPassword(password, firstName, lastName) {
    let passwordValidationScore = 0;
    if (!(password.length < 6 || password.length > 24)) {
      passwordValidationScore = passwordValidationScore + 1;
    }
    if (/[a-zA-Z]/.test(password.charAt(0))) {
      passwordValidationScore = passwordValidationScore + 1;
    }
    if (/[0-9]/.test(password)) {
      passwordValidationScore = passwordValidationScore + 1;
    }
    if (/[A-Z]/.test(password)) {
      passwordValidationScore = passwordValidationScore + 1;
    }
    if (/[a-z]/.test(password)) {
      passwordValidationScore = passwordValidationScore + 1;
    }
    if (!/[\"\\\^\&]/.test(password)) {
      passwordValidationScore = passwordValidationScore + 1;
    }
    if (
      !(
        password.toUpperCase().indexOf(firstName.toUpperCase()) >= 0 &&
        password.toUpperCase().indexOf(lastName.toUpperCase()) >= 0
      )
    ) {
      passwordValidationScore = passwordValidationScore + 1;
    }
    this.setState({ passwordValidationScore: passwordValidationScore });
    return passwordValidationScore;
  }

  render() {
    return (
      <AdminContext.Provider
        value={{
          formState: this.state,
          handleSubmitUser: (e) => {
            return this.handleSubmitSubAccountUser(e);
          },
          handleChange: (e, { name, value }) => {
            return this.handleChange(e, { name, value });
          },
          editControl: (status, id, header) => {
            return this.editControl(status, id, header);
          },
          manageUserModal: (status, header) => {
            return this.manageUserModal(status, header);
          },
        }}
      >
        {this.props.children}
      </AdminContext.Provider>
    );
  }
}

export default withSecurity(withApp(withCart(withAccount(AdminProvider))));
