import React, { useState, useEffect } from 'react';
import LazyImage from '../../../utils/LazyImage';
import DialogModal from '../DialogModal';

function AffidavitReturnsUpdate(props) {
  const {
    open,
    setOpen,
    onClose,
    callback,
    cancelCallback,
    submitReturn,
    afdDate,
    afdTime,
  } = props;
  return (
    <DialogModal
      open={open}
      onClose={onClose}
      setOpen={setOpen}
      modalClassName="cp-confirmcart-popup"
      style={{ maxWidth: '448px', fontWeight: 'bold' }}
      dialogOptions={{
        header: 'Updating return',
        actions: [
          {
            label: 'No, go back',
            color: 'white',
            action: () => {
              if (cancelCallback) cancelCallback();
              return { proceed: true };
            },
          },
          {
            label: <span style={{fontWeight: 'bold'}}>Yes, Update Request</span>,
            icon: 'check',
            borderColorOverride: 'blue',
            textColorOverride: 'white',
            textColorHoverOverride: 'blue',
            backgroundColorOverride: 'blue',
            backgroundHoverColorOverride: 'white',
            iconColorOverride: 'white',
            iconColorHoverOverride: 'blue',

            preAction: () => {
              if (callback) callback();
              return { proceed: true };
            },

            action: () => {
              submitReturn();
              return { proceed: true };
            },
          },
        ],
      }}
    >
      <div className="d-flex flex-column">
        <div className="fort-book fs-14px lh-19px mb-1">
          You have already requested a return on {afdDate} at {afdTime}.
          Submitting again will update your return to reflect the current
          quantities.
        </div>
        <div className="fort-bold fs-14px lh-19px mb-2">
          Are you sure you want to update your return?
        </div>
      </div>
    </DialogModal>
  );
}

export default AffidavitReturnsUpdate;
