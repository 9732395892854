import { ValidatorComponent } from 'react-form-validator-core';
import { Input } from 'semantic-ui-react-form-validator';
import { PRHLabel } from './elements';
import './elements.scss';

class TextValidator extends ValidatorComponent {
  render() {
    const {
      maxLength,
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      labelClassName,
      label,
      icon,
      onClick,
      longTextLabel,
      onBlur,
      type,
      validationCheck,
      validationCheckMessage,
      options,
      ...rest
    } = this.props;
    // console.log('input props', this.props)
    return (
      <div className={`${this.errorStyle()} mb-1`}>
        <div className={'input-label-holder'}>
          <label className={'input-label'}>{label}</label>
        </div>
        {longTextLabel ? (
          <div className={'check-box-and-text-holder'}>
            <div className={'check-box-holder'}>
              <Input
                {...rest}
                ref={(r) => {
                  this.input = r;
                }}
                //maxlength={maxLength}
                icon={icon}
                onClick={onClick}
                onBlur={onBlur}
                type={type}
                className={`${this.errorStyle()}`}
              />
            </div>
            <div className={'check-box-longtextlabel-holder'}>
              {longTextLabel}
            </div>
          </div>
        ) : (
          <Input
            {...rest}
            ref={(r) => {
              this.input = r;
            }}
            //maxLength={maxLength}
            icon={icon}
            onClick={onClick}
            onBlur={onBlur}
            type={type}
            className={`${this.errorStyle()}`}
          />
        )}
        {this.errorText(type)}
      </div>
    );
  }

  errorStyle() {
    const { isValid } = this.state;
    const { validationCheck, type, passwordsmatch, inputvalue } = this.props;
    // console.log(
    //   'errorStyle',
    //   validationCheck,
    //   type,
    //   passwordsmatch,
    //   inputvalue
    // );
    if (isValid && validationCheck !== 0) {
      if ((type === 'password') & (passwordsmatch === 0 && inputvalue !== '')) {
        return 'error-input-field';
      }
      return 'input-field';
    }
    return 'error-input-field';
  }

  errorText(type) {
    const { isValid } = this.state;
    const { validationCheck, validationCheckMessage = '' } = this.props;
    // console.log('isValid', isValid);
    if (isValid && validationCheck !== 0) {
      return null;
    }
    /*
    if (type === 'password') {
      return (
        <div className={'error-message'}>
          {this.getErrorMessage()}
          {validationCheckMessage}
        </div>
      );
    } else 
    */
    if (type === 'checkbox') {
      return (
        <div className={'error-message-checkbox'}>{this.getErrorMessage()}</div>
      );
    } else {
      return <div className={'error-message'}>{this.getErrorMessage()}</div>;
    }
  }
}

export default TextValidator;
