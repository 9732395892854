import { useState, useEffect } from "react";
import DialogModal from "./DialogModal";
import BookDetailSnippet from "../../shared-react-components/elements/BookDetailSnippet";
import { Dropdown, Form, Input, Radio } from "semantic-ui-react";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import _ from "lodash";
import "./PlaceClaimModal.scss";

import { DAMAGE_TYPES } from "../../utils/const";
const damageOptions = Object.entries(DAMAGE_TYPES).map((value) => {
  return {
    key: value[0],
    text: value[1],
    value: value[0],
  };
});

export const CreditCardRemoveModal = (props) => {
  const {
    onClose,
    bookModalData,
    isOpen,
    removeCard,
    card,
    ccRemoveModalControl,
  } = props;
  const [state, setState] = useState({
    quantity: 0,
    damageType: undefined,
    requestReplacementBook: false,
    sessionTimedOut: true,
  });

  const ccIframeClass = "hri-billing-address";

  /*
  if (paymentUseBillingAddress) {
      ccIframeClass = 'hri-billing-address';
  }
*/

  return (
    <DialogModal
      open={isOpen}
      closeOnEscape={true}
      modalContentClass='id-modal-content'
      closeOnDimmerClick={true}
      onClose={onClose}
      options={{
        className: "id-modal-style",
      }}
      dialogOptions={{
        header: "Remove Credit Card",
      }}
    >
      {!state.sessionTimedOut ? (
        <iframe
          className={`${ccIframeClass}`}
          title='Payment Form'
          id='payment-form'
          sandbox='allow-same-origin allow-forms allow-scripts'
          frameBorder='0'
          onLoad={(e) => this.ccFormOnload(e)}
          srcDoc={paymentForm}
        ></iframe>
      ) : (
        <div className='d-flex flex-center flex-column'>
          <div className={"ml-auto mr-auto card-info"}>
            <span className={"fort-bold"}>Card ending in {card.lastFour}</span>
            <br />
            Name on card: {card.cardHolderName}
            <br />
            Expiration Date: {card.expiryMonth}/{card.expiryYear}
            <br />
          </div>
          <div className='mt-2 ml-4 mr-4' style={{ paddingLeft: "2px" }}>
            Are you sure you want to remove this card from your saved credit
            cards?
            {card.ccExpiry}
            {card.ccLastFour}
          </div>
          <div className='mt-2 ml-4 mr-4' style={{ paddingLeft: "2px" }}>
            <span className={"fort-bold"}>Note:</span> Removing this card will
            not update current open orders. To update orders placed with this
            credit card information please reach out to Customer Service.
          </div>
          <div className='mt-2 mb-4 ml-4 mr-4 d-flex flex-row flex-end'>
            <PRHButton
              //icon="trash alternate"
              size='medium'
              className={"float-right margin-left mr-2"}
              //borderColorOverride="blue"
              //textColorOverride="white"
              //textColorHoverOverride="blue"
              //backgroundColorOverride="blue"
              //backgroundHoverColorOverride="white"
              //iconColorOverride="white"
              //iconColorHoverOverride="blue"
              onClick={(e) => {
                ccRemoveModalControl(false);
              }}
            >
              Cancel
            </PRHButton>
            <PRHButton
              icon='trash alternate'
              size='medium'
              className={"float-right margin-left mr-2"}
              borderColorOverride='blue'
              textColorOverride='white'
              textColorHoverOverride='blue'
              backgroundColorOverride='blue'
              backgroundHoverColorOverride='white'
              iconColorOverride='white'
              iconColorHoverOverride='blue'
              onClick={(e) => {
                removeCard();
                ccRemoveModalControl(false);
              }}
            >
              <span className={"fort-bold"}>Yes, Remove Card</span>
            </PRHButton>
          </div>
        </div>
      )}
    </DialogModal>
  );
};
