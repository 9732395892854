import React, { useState, useEffect } from 'react';
import { MOBILE_SMALL_SCREEN_WIDTH } from '../../../utils/const';
import LazyImage from '../../../utils/LazyImage';
import DialogModal from '../DialogModal';

function AffidavitReturnsBlockNav(props) {
  const {
    open,
    setOpen,
    onClose,
    discardChanges,
    callback,
    cancelCallback,
    submitReturn,
    setNavtriggered, 
    afdDate = 'November 28,2022',
    afdTime = '11:59 PM',
  } = props;
  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      modalClassName="cp-confirmcart-popup"
      style={{ maxWidth: '448px' }}
      dialogOptions={{
        header: 'Discard Changes',
        //size: MOBILE_SMALL_SCREEN_WIDTH,
        actions: [
          {
            label: 'No, stay on page',
            color: 'white',
            action: () => {
              onClose();
              //if (cancelCallback) cancelCallback();
              return { proceed: true };
            },
          },
          {
            label: 'Yes, discard and leave',
            icon: 'check',
            borderColorOverride: 'blue',
            textColorOverride: 'white',
            textColorHoverOverride: 'blue',
            backgroundColorOverride: 'blue',
            backgroundHoverColorOverride: 'white',
            iconColorOverride: 'white',
            iconColorHoverOverride: 'blue',
            /*
            preAction: () => {
              setNavtriggered(false);
              return { proceed: true };
            },
            */
            action: () => {
              discardChanges(false);
              return { proceed: true };
            },
          },
        ],
      }}
    >
      <div className="d-flex flex-column">
        <div className="fort-book fs-14px lh-19px mb-1">
          There have been changes made to this return request that has not been submitted. Leaving this page will discard all your changes. Would you like to continue?
        </div>
      </div>
    </DialogModal>
  );
}

export default AffidavitReturnsBlockNav;
