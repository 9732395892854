import PropTypes from "prop-types";
import { Icon, Popup } from "semantic-ui-react";
import { dateDifference } from "../../utils/utilities";
import useCart from "../hc/useCart";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import { isIE, osName, isFirefox, browserName } from "react-device-detect";

export default function FOCStatus(props) {
  const { focDate, confirmationStatus, hidePast } = props;
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  // console.log('foccstatus', focDate, confirmationStatus);

  const cart = useCart();

  const isLvl1 = cart?.customerInfo?.currentUser?.roleNo === "W1";

  if (isLvl1) {
    return <></>;
  }
  if (!!!focDate) return <></>;

  let msg = "";
  let msgColor = "grey";
  let contentMsg = "";
  //   let fd = moment(focDate);
  //   let cd = moment(new Date());

  //   let nd = fd.diff(cd, 'days');
  let nd = dateDifference(focDate);
  // console.log('FOCStatus', focDate, nd);
  const FOCArray = focDate.split("-");
  const tooltipFOC = FOCArray[1] + "/" + FOCArray[2] + "/" + FOCArray[0];

  if (nd < 1 && nd > -1) {
    msg = `Order by 11:59PM EST`;
    msgColor = "red";
    contentMsg =
      "These items must be ordered by " +
      tooltipFOC +
      " at 11:59PM EST to guarantee quantities.";
  } else if (nd > 0) {
    msg = `Order within ${Math.floor(nd)} days`;
    if (nd > 0 && nd < 6) {
      msgColor = "orange";
    }
    if (nd > 5 && nd < 10) {
      msgColor = "grey";
    }
    contentMsg =
      "These items must be ordered by " +
      tooltipFOC +
      " at 11:59PM EST to guarantee quantities.";
  } else {
    if (hidePast) return <></>;
    msg = `FOC Date passed`;
    msgColor = "red";
    contentMsg =
      "The FOC date for these items have passed. Items can still be ordered, but quantities will no longer be guaranteed.";
  }

  return (
    <Popup
      className={`ml-1`}
      content={contentMsg}
      on='hover'
      position='top center'
      offset={[-5, 8]}
      trigger={
        <div
          className={`d-flex align-items-center ${
            props.className
          } pl-1 pr-1 foc-status ${nd > 0 && nd < 6 && "warning"}  ${
            nd <= 0 && "error"
          } ${
            isMobile ? "py-2 justify-content-center" : ""
          } ${osName} ${browserName}`}
        >
          <Icon name='exclamation triangle' color={msgColor}></Icon>
          <span className='fort-bold fs-12px lh-15'>{msg}</span>
        </div>
      }
    />
  );
}

FOCStatus.propTypes = {
  focDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  hidePast: PropTypes.bool,
};

FOCStatus.defaultProps = {
  focDate: false,
  hidePast: false,
};
