import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Message,
  Popup,
} from "semantic-ui-react";
import { Divider } from "../../components/elements/elements";
import PageStandard from "../../components/elements/PageStandard";
import { ErrorComponent } from "../../components/ErrorComponent.jsx";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import PageHeader from "../../components/header/PageHeader";
import DialogModal from "../../components/modals/DialogModal";
import PRHButtonStandard from "../../shared-react-components/elements/prhbutton";
import { MOBILE_SCREEN_WIDTH, TOOLTIPS } from "../../utils/const";
import { Export, ExportCSV } from "../../utils/Export";
import { numberAsCurrency } from "../../utils/utilities";
import LoadingView from "../loading/LoadingView";
import "./InvoiceDetail.scss";
import { InvoiceDetailDropdown } from "./InvoiceDetailDropdown";
import { InvoiceDetailRowsPrint } from "./InvoiceDetailRowsPrint";
import { InvoiceDetailRowsPrintAlphaSort } from "./InvoiceDetailRowsPrintAlphaSort";
import { useMediaQuery } from "react-responsive";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { MobileOptionsMenu } from "../../components/header/components/MobileOptionsMenu";
import { InvoiceDetailMobile } from "./InvoiceDetailMobile";
import { isMobileOnly } from "react-device-detect";
import {
  useGetInvoiceDetailsQuery,
  useLazyGetInvoiceDetailsQuery,
  usePostClaimMutation,
} from "../../api/ecomApi";
import { cloneDeep } from "lodash";

export const InvoiceDetail = withSecurity(
  withRouter(
    withApp(
      withCart((props) => {
        const isMobile = isMobileOnly;
        // const isMobile = useMediaQuery({
        //   query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
        // });

        const [performGetInvoiceDetails, getInvoiceDetailsResult] =
          useLazyGetInvoiceDetailsQuery();
        const [performPostClaim, postClaimResult] = usePostClaimMutation();
        const [state, setstate] = useState({
          invoiceData: undefined,
          isLoading: false,
          isModalOpen: false,
          isEditing: false, // defaul false
          isDropdownOpen: false,
          isCashOnly: props.cart.customerInfo.customer.cashOnly, //defaul props.cart.customerInfo.customer.cashOnly
          isPow: props.cart.customerInfo.customer.isPow,
          country: props.cart.customerInfo.customer.country,
          countryGroup: props.cart.customerInfo.customer.countryGroup,
          claim: {
            claimNumber: "",
            name: "",
            phone: "",
            invoiceNumber: props.match.params.invoiceId,
            articles: [],
          },
          totalPrice: 0,
          error: undefined,
          currentSort: "Purchase Order",
          customerClaimLimit: "30",
          parentState: {},
        });
        const downloadOptions = [
          {
            key: 50,
            text: "Print",
            value: 50,
            icon: { name: "print", color: "orange" },
            onClick: () => {
              setstate({ ...state, isDropdownOpen: false });
              setTimeout(() => {
                window.print();
              }, 500);
            },
          },
          {
            key: 100,
            text: "Download Excel",
            value: 100,
            icon: { name: "file excel outline", color: "orange" },
            onClick: () => exportCart("excel"),
          },
          {
            key: 125,
            text: "Download CSV",
            value: 100,
            icon: { name: "file outline", color: "orange" },
            onClick: () => exportCart("csv"),
          },
        ];

        const sortOptions = [
          {
            key: 10,
            text: "Purchase Order",
            value: "Purchase Order",
            onClick: () => {
              setstate({ ...state, currentSort: "Purchase Order" });
            },
          },
          {
            key: 20,
            text: "Title - A to Z",
            value: "Alphabetical",
            onClick: () => {
              setstate({ ...state, currentSort: "Alphabetical" });
            },
          },
        ];

        const getSortTitleByKey = (key) => {
          const item = sortOptions.find((option) => option.value === key);
          return item && item.text;
        };

        const exportCart = (fileType) => {
          const details = {
            fileName: `${state.invoiceData.data.docName}-detail-${props.match.params.invoiceId}-${state.invoiceData.data.createdAccountNumber}`,
            metadata: [
              [
                `${state.invoiceData.data.docName} Number`,
                state.invoiceData.data.billingDocNumber,
              ],
              ["Account Number", state.invoiceData.data.createdAccountNumber],
              [
                "Bill To",
                `${
                  state.invoiceData.data.billingAddress.name +
                  " \n" +
                  state.invoiceData.data.billingAddress.address1 +
                  " \n" +
                  state.invoiceData.data.billingAddress.city +
                  ", " +
                  state.invoiceData.data.billingAddress.state +
                  " " +
                  state.invoiceData.data.billingAddress.zip
                }`,
              ],
              [
                "Ship To",
                `${
                  state.invoiceData.data.shipmentAddress.name +
                  " \n" +
                  state.invoiceData.data.shipmentAddress.address1 +
                  " \n" +
                  state.invoiceData.data.shipmentAddress.city +
                  ", " +
                  state.invoiceData.data.shipmentAddress.state +
                  " " +
                  state.invoiceData.data.shipmentAddress.zip
                }`,
              ],
              [
                ...(state.invoiceData.data.docType === "F2"
                  ? [
                      "Sales Order Number",
                      ...state.invoiceData.data.invoiceGroupDetailsList.map(
                        (i) => i?.salesOrder
                      ),
                    ]
                  : []),
              ],
              [
                ...(state.invoiceData.data.docType === "F2"
                  ? [
                      "Purchase Order Number",
                      ...state.invoiceData.data.invoiceGroupDetailsList.map(
                        (i) => i?.purchaseOrder
                      ),
                    ]
                  : []),
              ],
              [
                `${state.invoiceData.data.docName} Create Date`,
                state.invoiceData.data.dateCreated,
              ],
              ["Promotion Code"],
              [
                "Transportation Chgs",
                `$${numberAsCurrency(
                  Number(state.invoiceData.data.shippingCharges || 0).toFixed(2)
                )}`,
              ],
              [
                "Handling Chgs",
                `$${numberAsCurrency(
                  Number(state.invoiceData.data.handlingCharges || 0).toFixed(2)
                )}`,
              ],
              [
                "Taxes",
                `$${numberAsCurrency(
                  Number(state.invoiceData.data.tax || 0).toFixed(2)
                )}`,
              ],
              [
                "Total Due",
                `$${numberAsCurrency(
                  Number(state.invoiceData.data.totalDue).toFixed(2)
                )}`,
              ],
            ],
            list: state.invoiceData.data.invoiceGroupDetailsList
              .map((invGroup) => {
                return invGroup.invoiceItemDetailsList
                  .filter((inv) => inv?.quantity > 0)
                  .map((invDetail) => {
                    return {
                      Line: parseInt(invDetail.invoiceItem),
                      Description: invDetail.bookTitle,
                      ISBN: invDetail.isbn13,
                      ISBN10: invDetail.isbn10,
                      "UPC/SKU": invDetail.upcSku,
                      CC: invDetail.departureCountry,
                      Price: `$${numberAsCurrency(
                        Number(invDetail.coverPrice || 0).toFixed(2)
                      )}`,
                      "Discount %": Math.abs(Number(invDetail.discount || 0)),
                      "Net Value": `$${numberAsCurrency(
                        Number(invDetail.discountedCoverPrice || 0).toFixed(2)
                      )}`,
                      Qty: invDetail.quantity,
                      "Net Amount": `$${numberAsCurrency(
                        Number(invDetail.discountedPrice || 0).toFixed(2)
                      )}`,
                      ...(state.invoiceData.data.docType === "F2" && {
                        "Purchase Order Number": invGroup.purchaseOrder.replace(
                          '"',
                          ""
                        ),
                      }),
                    };
                  });
              })
              .flat(),
            sheetName: `${state.invoiceData.data.docName} Details`,
          };
          if (fileType === "excel") {
            Export.DocumentDetails(details);
          } else if (fileType === "csv") {
            ExportCSV.DocumentDetails(details);
          }
        };

        useEffect(() => {
          try {
            window.scrollTo(0, 0);
            performGetInvoiceDetails({
              invoiceId: props.match.params.invoiceId,
              sapAccountNo: props.cart.cart.accountNo,
            }).then((invoiceResponse) => {
              if (invoiceResponse.data?.status === "OK") {
                let currentSort = "Purchase Order";
                if (invoiceResponse.data.data.docType !== "F2") {
                  currentSort = "Alphabetical";
                }
                setstate({
                  ...state,
                  currentSort,
                  invoiceData: cloneDeep(invoiceResponse?.data),
                });
              } else {
                setstate({
                  ...state,
                  isLoading: false,
                  error: invoiceResponse?.data,
                });
              }
            });
          } catch (e) {
            return <div> Not found </div>;
          }
        }, [props.match.params.invoiceId]);
        /*
        useEffect(() => {
          console.log('props.history 0', props.history);
          if (props.history.action === "PUSH") {
            console.log('props.history 2', props.history, props.history.location.state);
            setstate({...state, parentState: props.history.location.state});
          }
          return () => {
            if (props.history.action === "POP") {
              console.log('props.history 1', props.history, state);
              props.history.replace({pathname:'/invoices', state: state.parentState});
              //props.history.replace("/invoices");
            }
            
            if (props.history.action === "PUSH") {
              console.log('props.history 2', props.history, props.history.location.state);
              setstate({...state, parentState: props.history.location.state});
            }
          }
        }, [props.history]);
        */
        if (state.error) {
          return (
            <div style={{ backgroundColor: "white" }}>
              <PageHeader headerType="loggedIn" cartBadge={true} />
              <ErrorComponent error={state.error} />
            </div>
          );
        }

        const renderAddress = (address) => {
          return (
            <div key={address[0]} className="od-address-container">
              <div className="od-text-header">{address[0].toUpperCase()}:</div>
              <div
                dangerouslySetInnerHTML={{ __html: address[1].formatted }}
                className="od-text-data-book"
              />
            </div>
          );
        };

        const onSubmit = () => {
          setstate({ ...state, isLoading: true });
          performPostClaim({
            claim: state.claim,
            sapAccountNo: props.cart.cart.accountNo,
          }).then((resp) => {
            if (resp.data?.status === "OK") {
              try {
                performGetInvoiceDetails({
                  invoiceId: props.match.params.invoiceId,
                  sapAccountNo: props.cart.cart.accountNo,
                }).then((invoiceResponse) => {
                  if (invoiceResponse.data?.status === "OK") {
                    setstate({
                      ...state,
                      invoiceData: cloneDeep(invoiceResponse?.data),
                      isEditing: false,
                      isLoading: false,
                    });
                  } else {
                    setstate({
                      ...state,
                      isLoading: false,
                      error: invoiceResponse?.data,
                    });
                  }
                });
              } catch (e) {
                return <div> Not found </div>;
              }
            } else {
              setstate({ ...state, error: resp?.data, isLoading: false });
            }
          });
        };

        const addOrRemoveFromClaims = (item) => {
          const tempArray = state.claim.articles;
          const itemInEditIndex = tempArray.findIndex(
            (data) =>
              data.isbn13 === item.isbn13 &&
              data.invoiceItem === item.invoiceItem &&
              item.oldDamageType === data.damageType
          );
          if (itemInEditIndex !== -1) {
            if (Number(item.quantity) === 0) {
              tempArray.splice(itemInEditIndex, 1);
              const totalPrice = tempArray.reduce(
                (curr, next) => curr + next.coverPrice * next.quantity,
                0
              );
              setstate({
                ...state,
                claim: { ...state.claim, articles: tempArray },
                totalPrice,
              });
            } else {
              tempArray.splice(itemInEditIndex, 1);
              tempArray.push(item);
              const totalPrice = tempArray.reduce(
                (curr, next) => curr + next.coverPrice * next.quantity,
                0
              );
              setstate({
                ...state,
                claim: { ...state.claim, articles: tempArray },
                totalPrice,
              });
            }
          } else {
            if (item.quantity > 0) {
              tempArray.push(item);
              const totalPrice = tempArray.reduce(
                (curr, next) => curr + next.coverPrice * next.quantity,
                0
              );
              setstate({
                ...state,
                claim: { ...state.claim, articles: tempArray },
                totalPrice,
              });
            }
          }
        };

        const regex = /[^a-zA-Z0-9 ]/g;

        const [showClaimCard, setShowClaimCard] = useState(!isMobile);
        const [showMoreDataMobile, setshowMoreDataMobile] = useState(false);
        const [sortMenuVisible, setSortMenuVisible] = useState(false);

        const renderMobileAddress = (address) => {
          return showMoreDataMobile ? (
            <Grid.Row key={address[1].name}>
              <Grid.Column width={6}>
                <span className="fort-bold">{address[0]}:</span>
              </Grid.Column>
              <Grid.Column width={10}>
                <span
                  dangerouslySetInnerHTML={{ __html: address[1].formatted }}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row key={address[1].name}>
              <Grid.Column width={6}>
                <span className="fort-bold">{address[0]}:</span>
              </Grid.Column>
              <Grid.Column width={10}>
                <span>{address[1].name}</span>
              </Grid.Column>
            </Grid.Row>
          );
        };

        const renderMobileOrderDetailsData = () => {
          return state.invoiceData && state.invoiceData.data ? (
            <Container>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <span className="fort-bold">Created:</span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <span>
                      {moment(state.invoiceData.data.dateCreated).format(
                        "MMM Do, YYYY"
                      )}
                    </span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <span className="fort-bold">Account #:</span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <span>
                      {state.invoiceData.data.createdAccountNumber
                        ? state.invoiceData.data.createdAccountNumber
                        : "-"}
                    </span>
                  </Grid.Column>
                </Grid.Row>

                {state.invoiceData &&
                  renderMobileAddress([
                    "Bill-to",
                    state.invoiceData.data.billingAddress
                      ? state.invoiceData.data.billingAddress
                      : { formatted: "-" },
                  ])}
                {state.invoiceData &&
                  renderMobileAddress([
                    "Ship-to",
                    state.invoiceData.data.shipmentAddress
                      ? state.invoiceData.data.shipmentAddress
                      : { formatted: "-" },
                  ])}

                {showMoreDataMobile && (
                  <>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <span className="fort-bold">Subtotal:</span>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <span>
                          $
                          {numberAsCurrency(
                            Number(state.invoiceData.data.subotal).toFixed(2)
                          )}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <span className="fort-bold">Shipping:</span>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <span>
                          {Number(state.invoiceData.data?.shipping).toFixed(2) >
                          0
                            ? `$${numberAsCurrency(
                                Number(
                                  state.invoiceData.data?.shipping
                                ).toFixed(2)
                              )}`
                            : "FREE"}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <span className="fort-bold">TAX:</span>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <span>
                          $
                          {numberAsCurrency(
                            Number(state.invoiceData.data.tax).toFixed(2)
                          )}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
                <Grid.Row className="mt-2">
                  <Grid.Column width={6}>
                    <span className="fort-bold">Total:</span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <span>
                      $
                      {numberAsCurrency(
                        Number(state.invoiceData.data.totalDue).toFixed(2)
                      )}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <span
                onClick={() => setshowMoreDataMobile(!showMoreDataMobile)}
                className="more-link mt-2 fort-bold d-flex"
              >
                {showMoreDataMobile ? "Show less details" : "Show more details"}
              </span>
            </Container>
          ) : null;
        };

        const renderMobileOrdersContent = () => {
          return (
            <div className="od-metadata-container">
              <Container>
                <div className="d-flex flex-direction-row justify-content-space-between align-items-center">
                  <div className="fort-book fs-18px">View Items</div>

                  <div className="d-flex flex-direction-column">
                    <PRHButton
                      className="mobile-options-button"
                      onClick={() => setSortMenuVisible(true)}
                      disabled={state.isLoading}
                    >
                      {getSortTitleByKey(state.currentSort)}
                      <Icon name="triangle down" />
                    </PRHButton>
                    <MobileOptionsMenu
                      title=""
                      onClose={() => setSortMenuVisible(!sortMenuVisible)}
                      onOutsideMenuClick={() =>
                        setSortMenuVisible(!sortMenuVisible)
                      }
                      menuItems={sortOptions}
                      menuVisible={sortMenuVisible}
                      className="download-dropdown-catalog"
                      onOptionClick={(option) => option.onClick()}
                      radio={true}
                      currentSort={state.currentSort}
                    />
                  </div>
                </div>
                <div className="d-flex flex-direction-column">
                  {state.currentSort === "Purchase Order"
                    ? state.invoiceData.data.invoiceGroupDetailsList
                        .sort((a, b) => {
                          const nameA = a?.purchaseOrder?.toUpperCase();
                          const nameB = b?.purchaseOrder?.toUpperCase();
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((lineItemGroup) => (
                          <InvoiceDetailMobile
                            key={lineItemGroup.billingDocNumber}
                            history={props.history}
                            items={lineItemGroup.invoiceItemDetailsList}
                            user={props.user}
                            claims={state.claim.articles}
                            purchaseOrder={lineItemGroup.purchaseOrder}
                            salesOrder={lineItemGroup.salesOrder}
                            {...lineItemGroup}
                          />
                        ))
                    : ""}
                  {state.currentSort === "Alphabetical"
                    ? state.invoiceData.data.invoiceGroupDetailsList
                        .sort((a, b) => {
                          const nameA = a.bookTitle?.toUpperCase();
                          const nameB = b.bookTitle?.toUpperCase();
                          if (nameA < nameB) {
                            return -1;
                          }
                          if (nameA > nameB) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((lineItemGroup) => (
                          <InvoiceDetailMobile
                            key={lineItemGroup.billingDocNumber}
                            history={props.history}
                            items={lineItemGroup.invoiceItemDetailsList}
                            user={props.user}
                            claims={state.claim.articles}
                            purchaseOrder={lineItemGroup.purchaseOrder}
                            salesOrder={lineItemGroup.salesOrder}
                            {...lineItemGroup}
                          />
                        ))
                    : ""}
                </div>
              </Container>
            </div>
          );
        };

        return (
          <div>
            <PageStandard
              headerType="loggedIn"
              containerFluid={true}
              cartBadge={true}
            >
              {state.invoiceData ? (
                <div>
                  <div className="od-metadata-container">
                    <Container className="od-header">
                      <div className="od-header-head">
                        <div className="od-button-right-container">
                          <div className="iconholder hide-in-print">
                            <Icon
                              color="black"
                              name="file alternate"
                              size="large"
                            />
                          </div>
                          <div
                            className="od-header-text"
                            onClick={() =>
                              isMobile && setShowClaimCard(!showClaimCard)
                            }
                          >
                            {state.invoiceData.data.docName} #
                            {state.invoiceData.data.billingDocNumber}
                          </div>
                        </div>
                        <div className="od-button-right-container hide-in-print">
                          {state.invoiceData.data.docType === "F2" &&
                            !isMobile && (
                              <Popup
                                content={
                                  state?.invoiceData?.data?.relatedClaimNumber
                                    ?.length > 0
                                    ? "A claim has already been placed for this invoice."
                                    : `${
                                        state.invoiceData.data
                                          .containsInitials === true &&
                                        state.invoiceData.data
                                          .initialsCustomerType === true
                                          ? `On invoices that contain Initial product, claims must be submitted within 10 calendar days of the earliest On-Sale Date. Please contact customer service if you have questions.`
                                          : "Cannot place claims on invoices older than 30 days"
                                      }`
                                }
                                trigger={
                                  <Icon name="warning circle" size="large" />
                                }
                                position="top center"
                                //wide
                                offset={[0, 15]}
                              />
                            )}
                          {state.invoiceData.data.docType === "F2" &&
                            !isMobile && (
                              <PRHButtonStandard
                                className="ot-button ot-nav-button"
                                size="medium"
                                backgroundColorOverride="white"
                                backgroundColorDisabledOverride="white"
                                borderColorOverride="grey"
                                disabled={
                                  state.isLoading ||
                                  state.isEditing ||
                                  state?.invoiceData?.data?.relatedClaimNumber
                                    ?.length > 0 ||
                                  moment().diff(
                                    moment(
                                      state?.invoiceData?.data?.dateCreated
                                    ),
                                    "days"
                                  ) >= 60 ||
                                  (state.invoiceData.data.containsInitials ===
                                    true &&
                                    moment().diff(
                                      moment(
                                        state?.invoiceData?.data
                                          ?.initialsOnSaleDate
                                      ),
                                      "days"
                                    ) >= 10 &&
                                    state.isPow &&
                                    state.countryGroup != "2") ||
                                  (state.invoiceData.data.containsInitials ===
                                    true &&
                                    moment().diff(
                                      moment(
                                        state?.invoiceData?.data?.dateCreated
                                      ),
                                      "days"
                                    ) >= 30 &&
                                    state.isPow &&
                                    state.countryGroup == "2")
                                }
                                onClick={() =>
                                  setstate({ ...state, isEditing: true })
                                }
                              >
                                {state?.invoiceData?.data?.relatedClaimNumber
                                  ?.length > 0
                                  ? "Claim Placed"
                                  : "Place Claim"}
                              </PRHButtonStandard>
                            )}
                          {!isMobile && (
                            <Dropdown
                              selection
                              className="od-dropdown"
                              onClick={() =>
                                setstate({ ...state, isDropdownOpen: true })
                              }
                              trigger={
                                <div className="od-text-data-book">
                                  Download {state.invoiceData.data.docName}
                                </div>
                              }
                              disabled={state.isLoading}
                              options={downloadOptions}
                              direction="left"
                              open={state.isDropdownOpen}
                              onClose={() =>
                                setstate({ ...state, isDropdownOpen: false })
                              }
                            />
                          )}
                        </div>
                      </div>
                      {!isMobile && (
                        <div className="od-header-subhead">
                          <div>
                            <span className="od-text-data-medium">
                              Created Date:{" "}
                            </span>
                            {moment(state.invoiceData.data.dateCreated).format(
                              "MMM Do, YYYY"
                            )}
                          </div>
                          {state.invoiceData.data.relatedClaimNumber &&
                            state.invoiceData.data.docType !== "F2" && (
                              <div>
                                <Divider horizontal />
                                <span className="od-text-data-medium">
                                  Claim Number:{" "}
                                </span>
                                {state.invoiceData.data.relatedClaimNumber}
                              </div>
                            )}
                        </div>
                      )}
                      {state.invoiceData.data.docType === "F2" &&
                        state.invoiceData.data.relatedCreditMemo !== null &&
                        showClaimCard && (
                          <div
                            className={`id-header-claim-info ${
                              isMobile ? "id-header-claim-info-mobile" : ""
                            }`}
                          >
                            <div className="iconholder">
                              <Icon name="warning circle" color="grey"></Icon>
                            </div>
                            <div className={isMobile ? "ml-1" : ""}>
                              <span className="od-text-data-medium">
                                Placed Claim:{" "}
                              </span>
                              {state.invoiceData.data.relatedClaimNumber}
                            </div>
                            {!isMobile && <Divider horizontal />}
                            <div className={isMobile ? "ml-4" : ""}>
                              <span className="od-text-data-medium">
                                Credit Memo:{" "}
                              </span>
                              <span
                                className="btt-title"
                                onClick={() =>
                                  props.history.push(
                                    `/invoices/${state.invoiceData.data.relatedCreditMemo}`
                                  )
                                }
                              >
                                {state.invoiceData.data.relatedCreditMemo}
                              </span>
                            </div>
                          </div>
                        )}
                    </Container>
                    {isMobile &&
                      state.invoiceData &&
                      state.invoiceData.data &&
                      renderMobileOrderDetailsData()}
                    <Container className="od-metadata-data d-flex">
                      {!isMobile && (
                        <div className="od-metadata-data-left">
                          <div>
                            <span className="od-text-data-medium">
                              Account #:
                            </span>{" "}
                            {state.invoiceData.data.createdAccountNumber
                              ? state.invoiceData.data.createdAccountNumber
                              : "-"}
                          </div>
                          <div className="od-address-data">
                            {state.invoiceData &&
                              renderAddress([
                                "BILL TO",
                                state.invoiceData.data.billingAddress
                                  ? state.invoiceData.data.billingAddress
                                  : { formatted: "-" },
                              ])}
                            {state.invoiceData &&
                              renderAddress([
                                "SHIP TO",
                                state.invoiceData.data.shipmentAddress
                                  ? state.invoiceData.data.shipmentAddress
                                  : { formatted: "-" },
                              ])}
                          </div>
                          {state.invoiceData.data.specialInstructions &&
                            state.invoiceData.data.specialInstructions !==
                              null && (
                              <div>
                                <div className="od-text-header">
                                  SPECIAL INSTRUCTIONS:
                                </div>
                                <div className="od-text-data-book">
                                  {state.invoiceData.data.specialInstructions}
                                </div>
                              </div>
                            )}
                        </div>
                      )}
                      {!isMobile && (
                        <div className="od-metadata-data-right tm-order-now-container">
                          <div className="od-modal-data od-text-header">
                            {/* TODO: add this when design is approved
                          <div>Retail Amount</div>
                          <div>
                            $
                            {numberAsCurrency(
                              Number(
                                state.invoiceData.data.totalRetailAmount
                              ).toFixed(2)
                            )}
                          </div>
                        </div>
                        <div className="od-modal-data od-text-data-book">
                          <div>Base Discount</div>
                          <div>
                            -$
                            {Math.abs(
                              numberAsCurrency(
                                Number(
                                  state.invoiceData.data.totalBaseDiscount
                                ).toFixed(2)
                              )
                            )}
                          </div>
                        </div>
                        {!!Math.abs(
                          state.invoiceData.data.totalPromoDiscount
                        ) && (
                          <div className="od-modal-data od-text-data-book">
                            <div>
                              Promo [{state.invoiceData.data.promoCode}]{' '}
                              Discount
                            </div>
                            <div>
                              -$
                              {Math.abs(
                                numberAsCurrency(
                                  Number(
                                    state.invoiceData.data.totalPromoDiscount
                                  ).toFixed(2)
                                )
                              )}
                            </div>
                          </div>
                        )}
                        <div className="od-modal-data od-text-header mt-1"> */}
                            <div>Subtotal</div>
                            <div>
                              $
                              {numberAsCurrency(
                                Number(state.invoiceData.data.subotal).toFixed(
                                  2
                                )
                              )}
                            </div>
                          </div>
                          <div className="od-modal-data od-text-data-book">
                            <div>Shipping</div>
                            <div>
                              {Number(
                                state.invoiceData.data.shippingCharges
                              ).toFixed(2) > 0
                                ? `$${numberAsCurrency(
                                    Number(
                                      state.invoiceData.data.shippingCharges
                                    ).toFixed(2)
                                  )}`
                                : "FREE"}
                            </div>
                          </div>
                          {Number(state.invoiceData.data.handlingCharges) >
                            0 && (
                            <div className="od-modal-data od-text-data-book">
                              <div>Handling</div>
                              <div>
                                $
                                {numberAsCurrency(
                                  Number(
                                    state.invoiceData.data.handlingCharges
                                  ).toFixed(2)
                                )}
                              </div>
                            </div>
                          )}
                          <div className="od-modal-data od-text-data-book">
                            <div>Tax</div>
                            <div>
                              $
                              {numberAsCurrency(
                                Number(state.invoiceData.data.tax).toFixed(2)
                              )}
                            </div>
                          </div>
                          <div className="line-divider" />
                          <div className="od-modal-data od-text-header">
                            <div>Total Due</div>
                            <div>
                              $
                              {numberAsCurrency(
                                Number(state.invoiceData.data.totalDue).toFixed(
                                  2
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Container>
                  </div>
                  {state.isEditing && !isMobile && (
                    <div className="od-edit-container hide-in-print">
                      <Container className="od-metadata-data d-flex pt-2 pb-2 d-flex flex-row">
                        <div className="flex-column od-credit-memo-left-container">
                          {state.isCashOnly ? (
                            <>
                              <div className="od-white-bold-text">
                                To place a claim for this invoice, fill out the
                                fields below and report issues per item.
                              </div>
                              <div className="od-white-text">
                                If your order was paid by Credit Card, the
                                credit will be applied to your account. To use
                                your credit towards a future order, please reach
                                out to your Customer Service rep. If a
                                replacement order is requested, the credit will
                                be automatically applied to the order.
                              </div>
                            </>
                          ) : (
                            <div className="od-white-bold-text">
                              To place a claim for this invoice, fill out the
                              fields below and report issues per item.
                            </div>
                          )}
                        </div>
                        <div className="flex-column od-button-right-container flex-start">
                          <div className="d-flex flex-row">
                            <Popup
                              content={TOOLTIPS["reportWrongTitles"]}
                              trigger={<Icon name="question circle outline" />}
                              position="top center"
                              offset={[0, 10]}
                            />
                            <div className="od-white-italic-text">
                              How do I report overages or wrong titles?
                            </div>
                          </div>
                        </div>
                      </Container>
                    </div>
                  )}
                  {state.isEditing && !isMobile && (
                    <div className="id-claim-container hide-in-print">
                      <Container className="od-metadata-data">
                        <Form
                          loading={state.isLoading}
                          error={
                            state.totalPrice > 300 ||
                            moment().diff(
                              moment(state.invoiceData.data.dateCreated),
                              "days"
                            ) > 60 ||
                            (state.claim.claimNumber.match(regex) || [])
                              .length > 0 ||
                            state.claim.claimNumber.length > 20
                          }
                        >
                          <Form.Group>
                            <Form.Field
                              width={4}
                              error={
                                (state.claim.claimNumber.match(regex) || [])
                                  .length > 0
                              }
                            >
                              <label htmlFor="claimNumber">Claim Number</label>
                              <Input
                                type="text"
                                name="claimNumber"
                                id="claimNumber"
                                maxLength={17}
                                placeholder="Enter a number or name"
                                onChange={(e, { value }) =>
                                  setstate({
                                    ...state,
                                    claim: {
                                      ...state.claim,
                                      claimNumber: value,
                                    },
                                  })
                                }
                                error={
                                  (state.claim.claimNumber.match(regex) || [])
                                    .length > 0 ||
                                  state.claim.claimNumber.length > 17
                                }
                              />
                              {(state.claim.claimNumber.match(regex) || [])
                                .length > 0 && (
                                <div className="id-error-message">
                                  {
                                    "This is not a valid Claim Number. It must not contain special characters."
                                  }
                                </div>
                              )}
                            </Form.Field>
                            <Form.Field width={4}>
                              <label htmlFor="name">Full Name</label>
                              <Input
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Enter your full name"
                                onChange={(e, { value }) =>
                                  setstate({
                                    ...state,
                                    claim: { ...state.claim, name: value },
                                  })
                                }
                              />
                            </Form.Field>
                            <Form.Field width={4}>
                              <label htmlFor="phone">Phone Number</label>
                              <Input
                                type="text"
                                name="phone"
                                id="phone"
                                placeholder="(123)456-7890"
                                onChange={(e, { value }) =>
                                  setstate({
                                    ...state,
                                    claim: { ...state.claim, phone: value },
                                  })
                                }
                              />
                            </Form.Field>
                            <Form.Button
                              as={PRHButtonStandard}
                              className="ot-button ot-nav-button id-button"
                              size="medium"
                              backgroundColorOverride="white"
                              backgroundColorDisabledOverride="white"
                              borderColorOverride="grey"
                              disabled={state.isLoading}
                              onClick={() => {
                                setstate({
                                  ...state,
                                  isModalOpen: true,
                                });
                              }}
                            >
                              Cancel
                            </Form.Button>
                            <Form.Button
                              as={PRHButtonStandard}
                              onClick={() => onSubmit()}
                              className="ot-button id-button"
                              size="medium"
                              icon="checkmark"
                              borderColorOverride="white"
                              textColorOverride="white"
                              bold={true}
                              backgroundColorOverride="blue"
                              backgroundColorDisabledOverride="transparent"
                              disabled={
                                state.isLoading ||
                                state.totalPrice > 300 ||
                                state.claim.articles.length === 0 ||
                                state.claim.claimNumber === "" ||
                                state.claim.name === "" ||
                                state.claim.phone === "" ||
                                moment().diff(
                                  moment(state.invoiceData.data.dateCreated),
                                  "days"
                                ) > 60 ||
                                (state.claim.claimNumber.match(regex) || [])
                                  .length > 0 ||
                                state.claim.claimNumber.length > 20
                              }
                            >
                              Submit Claim
                            </Form.Button>
                          </Form.Group>
                          {
                            /*!props.cart.isUserPow() &&*/
                            state.totalPrice > 300 && (
                              <Message
                                error
                                header="Cannot Submit Claim"
                                content={`Your claim requires assistance from a Customer Service representative, please contact Customer Service at ${
                                  props.cart.isUserPow()
                                    ? "1-877-888-2918"
                                    : "1-800-733-3000"
                                }`}
                              />
                            )
                          }
                          {/*props.cart.isUserPow() && state.totalPrice > 75 && (
                            <Message
                              error
                              header="Cannot Submit Claim"
                              content={`Your claim requires assistance from a Customer Service representative, please contact Customer Service at 1-877-888-2918`}
                            />
                          )*/}
                        </Form>
                      </Container>
                    </div>
                  )}
                  {isMobile && renderMobileOrdersContent()}
                  {!isMobile && (
                    <Container
                      className="od-status-container hide-in-print"
                      key={3}
                    >
                      {state.invoiceData.data.docType === "F2" && (
                        <div className="sort-dropdown-row">
                          <Dropdown
                            selection
                            value={state.currentSort}
                            className="od-dropdown invoice-sort-dropdown"
                            trigger={
                              <div className="od-text-data-book">
                                Sort by: {getSortTitleByKey(state.currentSort)}
                              </div>
                            }
                            disabled={!state.invoiceData}
                            options={sortOptions}
                            direction="left"
                          />
                        </div>
                      )}

                      {state.currentSort === "Purchase Order"
                        ? state.invoiceData.data.invoiceGroupDetailsList
                            .sort((a, b) => {
                              const nameA = a?.purchaseOrder?.toUpperCase();
                              const nameB = b?.purchaseOrder?.toUpperCase();
                              if (nameA < nameB) {
                                return -1;
                              }
                              if (nameA > nameB) {
                                return 1;
                              }
                              return 0;
                            })
                            .map((lineItemGroup) => (
                              <InvoiceDetailDropdown
                                key={lineItemGroup.billingDocNumber}
                                docType={state.invoiceData.data.docType}
                                isEditing={state.isEditing}
                                addOrRemoveFromClaims={addOrRemoveFromClaims}
                                claims={state.claim.articles}
                                {...lineItemGroup}
                                invoiceItemDetailsList={lineItemGroup.invoiceItemDetailsList.filter(
                                  (item) => item.quantity > 0
                                )}
                                isLoading={state.isLoading}
                              />
                            ))
                        : ""}
                      {state.currentSort === "Alphabetical" ? (
                        <InvoiceDetailRowsPrintAlphaSort
                          isEditing={state.isEditing}
                          addOrRemoveFromClaims={addOrRemoveFromClaims}
                          claims={state.claim.articles}
                          docType={state.invoiceData.data.docType}
                          invoiceDataRows={
                            state.invoiceData.data.invoiceGroupDetailsList
                          }
                        />
                      ) : (
                        ""
                      )}
                      <div className="od-metadata-data hide-in-print">
                        <div className="od-metadata-data-left" />
                        <div className="tm-order-now-container od-metadata-data-right">
                          <div className="od-modal-data od-text-header">
                            <div>Subtotal</div>
                            <div>
                              $
                              {numberAsCurrency(
                                Number(state.invoiceData.data.subotal).toFixed(
                                  2
                                )
                              )}
                            </div>
                          </div>
                          <div className="od-modal-data od-text-data-book">
                            <div>Shipping</div>
                            <div>
                              {Number(
                                state.invoiceData.data.shippingCharges
                              ).toFixed(2) > 0
                                ? `$${numberAsCurrency(
                                    Number(
                                      state.invoiceData.data.shippingCharges
                                    ).toFixed(2)
                                  )}`
                                : "FREE"}
                            </div>
                          </div>
                          {Number(state.invoiceData.data.handlingCharges) >
                            0 && (
                            <div className="od-modal-data od-text-data-book">
                              <div>Handling</div>
                              <div>
                                $
                                {numberAsCurrency(
                                  Number(
                                    state.invoiceData.data.handlingCharges
                                  ).toFixed(2)
                                )}
                              </div>
                            </div>
                          )}
                          <div className="od-modal-data od-text-data-book">
                            <div>Tax</div>
                            <div>
                              $
                              {numberAsCurrency(
                                Number(state.invoiceData.data.tax).toFixed(2)
                              )}
                            </div>
                          </div>
                          <div className="line-divider" />
                          <div className="od-modal-data od-text-header">
                            <div>Total Due</div>
                            <div>
                              $
                              {numberAsCurrency(
                                Number(state.invoiceData.data.totalDue).toFixed(
                                  2
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Container>
                  )}
                  <div className="show-only-in-print">
                    <InvoiceDetailRowsPrint
                      invoiceDataRows={
                        state.invoiceData.data.invoiceGroupDetailsList
                      }
                      docType={state.invoiceData.data.docType}
                    />
                  </div>
                  <DialogModal
                    open={state.isModalOpen}
                    closeOnEscape={true}
                    closeOnDimmerClick={true}
                    history={props.history}
                    onClose={() => setstate({ ...state, isModalOpen: false })}
                    dialogOptions={{
                      header: "Cancel Placing Claim",
                      actions: [
                        {
                          label: "No, go back",
                          color: "white",
                          action: () => {
                            setstate({ ...state, isModalOpen: false });
                          },
                        },
                        {
                          label: "Yes, cancel placing claim",
                          textColorOverride: "white",
                          backgroundColorOverride: "blue",
                          borderColorOverride: "blue",
                          bold: true,
                          action: () => {
                            setstate({
                              ...state,
                              isModalOpen: false,
                              isEditing: false,
                              claim: {
                                claimNumber: "",
                                name: "",
                                phone: "",
                                invoiceNumber: props.match.params.invoiceId,
                                articles: [],
                              },
                              totalPrice: 0,
                            });
                          },
                        },
                      ],
                    }}
                  >
                    <div className="od-text-data-bold">
                      <span className="fort-bold">
                        Are you sure you want to cancel placing claim?
                      </span>
                    </div>
                  </DialogModal>
                </div>
              ) : (
                <div className="ti-loading-container">
                  <LoadingView />
                </div>
              )}
            </PageStandard>
          </div>
        );
      })
    )
  )
);
