import { createRef, Component } from 'react';
import withSecurity from '../hc/withSecurity';
import withApp from '../hc/withApp';
import withAccount from '../hc/withAccount';
import { Popup, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Form } from 'semantic-ui-react-form-validator';
import TextValidator from '../../shared-react-components/elements/input';
import { PRHContainerHeader, PRHContainer } from '../../shared-react-components/elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import PasswordPopup from '../elements/PasswordPopup';
import './migrate.scss';
import { PRHLabel } from '../../shared-react-components/elements/elements';
import ReCAPTCHA from 'react-google-recaptcha';
import EventTrigger from '../analytics/EventTrigger';

class UpdateLegacyFormStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      createPasswordEye: 'eye',
      eyeButtonShow: '',
      eyeButtonHide: '',
      passwordMatchErrorMessage: '',
      recaptchaToken: '',
      recaptchaRef: createRef(),
    };
  }
  handleRecaptchaChange = () => {
    const recaptchaToken = this.state.recaptchaRef.current.getValue();
    this.setState({ recaptchaToken });
  };
  showPassword = (currentShowHide) => {
    const newState = { ...this.state };
    if (newState[currentShowHide] === 'password') {
      newState[currentShowHide] = 'text';
    } else {
      newState[currentShowHide] = 'password';
    }
    this.setState(newState);
  };

  handleSubmit = (e) => {
    if (!!!this.state.recaptchaToken) {
      return;
    }
    this.props.account.setStateField(
      'recaptchaToken',
      this.state.recaptchaToken
    );
    this.props.account.handleSubmitUser('migrate');
    this.state.recaptchaRef.current.reset();
    this.handleRecaptchaChange();
  };

  render() {
    const {
      handleChange,
      handleSubmitUser,
      formState,
      account,
      size,
    } = this.props;
    const { createPasswordFieldType, confirmPasswordFieldType } = this.state;
    // console.log('migrate', this.state);
    return (
      <>
        <PRHContainer className="content-block-border mt-4">
          <PRHContainerHeader>
            Update Account Details
            </PRHContainerHeader>
          We've made some site enhancements that require updates to your account
          details.
          <br />
          <br />
          Going forward, usernames will no longer be needed and the  email
          address and password entered below will be used to sign in to the new
          site.  <br />
          <br />
          <div className="text-uppercase fort-bold mb-1">
            account information
          </div>
          <div className={'grey-box-text-no-height  fort-book'}>
            {account.formState.createUserForm
              ? account.formState.createUserForm.displayName
              : ''}
            {/* User role being temporarily hidden.
            account.formState.createUserForm
              ? account.formState.createUserForm.description
              : ''
            */}
          </div>
          <br />
          <Form
            instantValidate={false}
            width={16}
            onSubmit={this.handleSubmit.bind(this)}
          >
            <TextValidator
              name="firstName"
              type="text"
              value={account.formState.createUserForm.firstName}
              onChange={account.handleChange}
              label={<PRHLabel>First Name</PRHLabel>}
              validators={['required']}
              errorMessages={['this field is required']}
              width={16}
            />
            <TextValidator
              name="lastName"
              type="text"
              value={account.formState.createUserForm.lastName}
              onChange={account.handleChange}
              label={<PRHLabel>Last Name</PRHLabel>}
              validators={['required']}
              errorMessages={['this field is required']}
              width={16}
            />
            <TextValidator
              name="email"
              type="text"
              value={account.formState.createUserForm.email}
              onChange={account.handleChange}
              label={<PRHLabel className={''}>Email address</PRHLabel>}
              validators={['required', 'isEmail']}
              errorMessages={[
                'this field is required',
                'field must be an email',
              ]}
              width={16}
            />
            <Popup
              position={size.width < 700 ? 'below center' : 'right center'}
              open={account.formState.openPasswordValidationPopUp}
              className={`password-popup`}
              trigger={
                <div className={'password-field-holder'}>
                  <div className={'password-field'}>
                    <TextValidator
                      name="createPassword"
                      passwordsmatch={account.formState.passwordsMatch}
                      inputvalue={
                        account.formState.createUserForm.confirmPassword
                      }
                      onFocus={account.handleClickCreatePassword}
                      onChange={account.handleChange}
                      type={createPasswordFieldType}
                      onBlur={account.handleBlur}
                      onClick={account.handleClickCreatePassword}
                      value={account.formState.createUserForm.createPassword}
                      validationCheck={account.formState.createPasswordValid}
                      errorMessages={['this field is required']}
                      label={
                        <PRHLabel className={''}>Create password</PRHLabel>
                      }
                      validators={['required']}
                      errorMessages={['this field is required']}
                      data-position="right center"
                      width={16}
                      icon={
                        createPasswordFieldType === 'text' ? (
                          <Icon
                            name="eye slash"
                            link
                            onClick={(e) =>
                              this.showPassword('createPasswordFieldType')
                            }
                          />
                        ) : (
                          <Icon
                            name="eye"
                            link
                            onClick={(e) =>
                              this.showPassword('createPasswordFieldType')
                            }
                          />
                        )
                      }
                    />
                  </div>
                  {account.formState.passwordsMatch === 1 &&
                  account.formState.passwordValidationScore ===
                    account.formState.createUserForm
                      .requiredPasswordValidationScore ? (
                    <div className={'password-check'}>
                      <Icon name="check" />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              }
            >
              <Popup.Content
                className={
                  'password-requirements-popup-content white-box-text fort-light'
                }
              >
                <PasswordPopup
                  action="create"
                  password={account.formState.createUserForm.createPassword}
                  firstName={account.formState.createUserForm.firstName}
                  lastName={account.formState.createUserForm.lastName}
                />
              </Popup.Content>
            </Popup>
            {/*formState.passwordsMatch===0 ? (<Label className={'error-label'} pointing='below'>Passwords must match.</Label>):''*/}
            <div className={'password-field-holder'}>
              <div className={'password-field'}>
                <TextValidator
                  name="confirmPassword"
                  type={confirmPasswordFieldType}
                  passwordsMatch={account.formState.passwordsMatch}
                  value={account.formState.createUserForm.confirmPassword}
                  inputvalue={account.formState.createUserForm.confirmPassword}
                  validationCheck={account.formState.createPasswordValid}
                  //validationCheckMessage="does not meet requirements"
                  onChange={account.handleChange}
                  onBlur={account.handleBlur}
                  onClick={account.handleClickConfirmPassword}
                  label={<PRHLabel className={''}>Confirm password</PRHLabel>}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  width={16}
                  icon={
                    confirmPasswordFieldType === 'text' ? (
                      <Icon
                        name="eye slash"
                        link
                        onClick={(e) =>
                          this.showPassword('confirmPasswordFieldType')
                        }
                      />
                    ) : (
                      <Icon
                        name="eye"
                        link
                        onClick={(e) =>
                          this.showPassword('confirmPasswordFieldType')
                        }
                      />
                    )
                  }
                />
              </div>
              {account.formState.passwordsMatch === 1 &&
              account.formState.passwordValidationScore ===
                account.formState.createUserForm
                  .requiredPasswordValidationScore ? (
                <div className={'password-check'}>
                  <Icon name="check" />
                </div>
              ) : (
                ''
              )}
            </div>
            {account.formState.passwordsMatch === 0 &&
            account.formState.createUserForm.confirmPassword !== '' ? (
              <div className={'password-match-error-message'}>
                your passwords do not match
              </div>
            ) : (
              ''
            )}
            {account.formState.stepFailed === true ? (
              <div className={'agreement-error-message'}>
                {account.formState.systemResponse}
              </div>
            ) : (
              ''
            )}
            <div className="d-flex mt-4 align-items-center justify-content-center">
              <ReCAPTCHA
                onExpired={this.handleRecaptchaChange}
                onChange={this.handleRecaptchaChange}
                ref={this.state.recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size={'normal'}
              />
            </div>
            <br />
            <br />
            <div className={'button-holder'}>
              <PRHButton
                size="large"
                className={'float-right'}
                icon="arrow right"
                iconright
                type="submit"
                disabled={!!!this.state.recaptchaToken}
                borderColorOverride="blue"
                textColorOverride="white"
                textColorHoverOverride="blue"
                backgroundColorOverride="blue"
                backgroundHoverColorOverride="white"
                iconColorOverride="white"
                iconColorHoverOverride="blue"
                onClick={EventTrigger(
                  'Button',
                  'Registration migrate legacy user'
                )}
              >
                Update Details
              </PRHButton>
              <PRHButton
                onClick={(e) => account.setStepHandler(1)}
                size="large"
                className={'float-left'}
                icon="arrow left"
              >
                Back
              </PRHButton>
            </div>
          </Form>
          <br />
          <br />
        </PRHContainer>
        <div>
          <div className="d-flex justify-content-center mb-4">
            <div
              className="text-blue cursor-pointer"
              onClick={(e) => {
                account.setStepHandler(3);
              }}
            >
              Cancel updates
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withSecurity(withApp(withAccount(UpdateLegacyFormStep2)));
