import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter, Prompt } from "react-router-dom";
import SeriesHistoryModal from "../../components/modals/SeriesHistoryModal";
import { Grid, Icon, Loader } from "semantic-ui-react";
import "./SeriesHistory.scss";
import { SeriesHistory } from "../series/SeriesHistory";
import useCart from "../hc/useCart";

export const SeriesHistoryCatalogWrapper = (props) => {
  const cart = useCart();
  const { shipTo, isbn, formatCode, getAll } = props;
  const [panelOpen, setPanelOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [wrapperQty, setWrapperQty] = useState("");
  const [seriesOrderHistory, setSeriesOrderHistory] = useState([]);
  const [seriesOrderHistoryAll, setSeriesOrderHistoryAll] = useState([]);

  const sendToBlank = (newPage) => {
    localStorage.setItem("shipTo", shipTo);
    window.open(newPage);
  };

  useEffect(() => {
    if (panelOpen) {
      getSeriesInfo();
    }
  }, [shipTo]);

  function getSeriesInfo() {
    cart.getSeriesHistory(isbn, formatCode, shipTo, false).then((result) => {
      let tempSeriesOrderHistory = false;
      tempSeriesOrderHistory = result.data.data.titles;
      tempSeriesOrderHistory.map((f) => {
        f.cartQuantity = 0;
      });
      let st = cart.getShipToAccount().accountNo.toString();
      cart.estimateCarts.forEach((estimate) => {
        estimate.items.forEach((i) => {
          tempSeriesOrderHistory.map((f) => {
            if (i.isbn === f.isbn && st === f.shipTo) {
              f.cartQuantity = i.quantity;
            }
            if (estimate?.confirmationStatus) {
              f.confirmationStatus = estimate?.confirmationStatus;
            }
            if (estimate?.foc) {
              f.foc = estimate.foc;
            }
          });
        });
      });
      setSeriesOrderHistory(tempSeriesOrderHistory.length > 0 ? tempSeriesOrderHistory:false);
    });
  }

  function openClosePanel() {
    // console.log("openClosePanel", shipTo, panelOpen);
    if (panelOpen === false) {
      setPanelOpen(true);
      // if (seriesOrderHistory.length === 0) {
      cart.getSeriesHistory(isbn, formatCode, shipTo, getAll).then((result) => {
        // console.log("result", result);
        let tempSeriesOrderHistory = false;
        // console.log('seriesOrderHistory1', result.data.data.titles);
        tempSeriesOrderHistory = result.data.data.titles;
        // console.log(
        //   'tempSeriesOrderHistory',
        //   tempSeriesOrderHistory,
        //   typeof tempSeriesOrderHistory
        // );
        tempSeriesOrderHistory.map((f) => {
          f.cartQuantity = 0;
          f.confirmationStatus = false;
        });
        let st = cart.getShipToAccount().accountNo.toString();
        // console.log('st', st);
        // console.log('cart.estimateCarts', props.cart.estimateCarts);
        cart.estimateCarts.forEach((estimate) => {
          estimate.items.forEach((i) => {
            tempSeriesOrderHistory.map((f) => {
              if (i.isbn === f.isbn && st === f.shipTo) {
                f.cartQuantity = i.quantity;
                if (estimate?.confirmationStatus) {
                  f.confirmationStatus = estimate?.confirmationStatus;
                }
                if (estimate?.foc) {
                  f.foc = estimate.foc;
                }
              }
            });
          });
        });
        setSeriesOrderHistory(tempSeriesOrderHistory.length > 0 ? tempSeriesOrderHistory:false);
      });
    } else {
      setPanelOpen(false);
    }
  }

  function getAllSeriesHistoryTitles() {
    if (seriesOrderHistoryAll.length === 0) {
      cart.getSeriesHistory(isbn, formatCode, shipTo, true).then((result) => {
        let tempSeriesOrderHistory = false;
        // console.log("getSeriesHistoryTitles1", result.data.data.titles);
        tempSeriesOrderHistory = result.data.data.titles;
        // console.log(
        //   "tempSeriesOrderHistory",
        //   tempSeriesOrderHistory,
        //   typeof tempSeriesOrderHistory
        // );
        tempSeriesOrderHistory.map((f) => {
          f.cartQuantity = 0;
        });
        /*Cart.customerInfo?.currentUser?.roleNo==='W1' && Cart.customerInfo?.currentUser?.accountNo===Cart.customerInfo?.customer?.accountNo*/
        let st = cart.getShipToAccount().accountNo.toString();
        // console.log("st", st);
        // console.log("cart.estimateCarts", props.cart.estimateCarts);
        cart.estimateCarts.forEach((estimate) => {
          estimate.items.forEach((i) => {
            tempSeriesOrderHistory.map((f) => {
              if (i.isbn === f.isbn && st === f.shipTo) {
                f.cartQuantity = i.quantity;
              }
              if (estimate?.confirmationStatus) {
                f.confirmationStatus = estimate?.confirmationStatus;
              }
              if (estimate?.foc) {
                f.foc = estimate.foc;
              }
            });
          });
        });
        setSeriesOrderHistoryAll(tempSeriesOrderHistory);
      });
    }
  }

  return (
    <>
      <div id='series-history '>
        <div
          className='d-flex w-100'
          id='series-history-header'
          style={{ color: `${panelOpen ? "#000" : "#515556"}` }}
        >
          <div onClick={() => openClosePanel()}>
            <Icon
              style={{
                width: "12px",
                color: `${panelOpen ? "#fa6400" : "#aaa"}`,
              }}
              name={`angle ${panelOpen ? "down" : "right"}`}
            />
          </div>
          <div
            className={"fort-bold fs-12px lh-15px series-header-text"}
            onClick={() => openClosePanel()}
          >
            Series Order History
          </div>
          {panelOpen ? (
            <div className={"expand-icon"} onClick={() => setModalOpen(true)}>
              <Icon name='expand' />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* {console.log("props.parentQty wrapper", props.parentQty)} */}
      <SeriesHistory
        data={seriesOrderHistory}
        panelOpen={panelOpen}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        shipTo={shipTo}
        context={"catalogPanel"}
        sendToBlank={sendToBlank}
        getSeriesInfo={() => getSeriesInfo()}
      />
      {modalOpen && (
        <SeriesHistoryModal
          open={modalOpen}
          sendToBlank={sendToBlank}
          titleRow={props.titleRow}
          parentQty={props.parentQty}
          setParentQty={props.setParentQty}
          addAll={props.addAll}
          setOpen={setModalOpen}
          seriesOrderHistory={seriesOrderHistory}
          context={"catalogModal"}
          //seriesOrderHistory={seriesOrderHistoryAll}
          getSeriesHistoryTitles={() => getAllSeriesHistoryTitles()}
        />
      )}
    </>
  );
};
