import { Component } from 'react';
import localforage from 'localforage';
import withSecurity from '../hc/withSecurity';
import withApp from '../hc/withApp';
import withAccount from '../hc/withAccount';
import { PH1 } from '../elements/elements';
import { Link } from 'react-router-dom';
import './migrate.scss';

class RegistrationResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    let asyncData = await this.props.app.registrationApi.getCacheItem(
      'createUser'
    );
    this.setState({ createUser: asyncData });
  }

  render() {
    const {
      formState,
      resendVerifyEmail,
      sendResetPasswordLink,
      account,
    } = this.props;

    return (
      <>
        <div className={'white-background-container margin-bottom-100'}>
          <div className={'white-box-header fort-light'}>
            Verify your account
          </div>
          <br />
          <br />
          {account.formState.createResponse === 'selfRegistered' ? (
            <>
            This account has been updated but is still unverified. Please{' '}
            <Link onClick={account.resendVerifyEmail} to="#">click here</Link>{' '}
            to re-send the verification email to{' '} 
              {formState.createUserForm.email} or{' '}
              <a href="mailto:bizcs@penguinrandomhouse.com?subject=Email%20verification%20help%20for%20Self-Service%20Registration">
                contact Customer Service
              </a>
              . 
            </>
          ) : (
            ''
          )}
          {account.formState.createResponse === 'registered' ? (
            <>
              It looks like you already have an account. Click{' '}
              <Link onClick={account.sendResetPasswordLink} to="#">
                here
              </Link>{' '}
              to send an email to reset your password to{' '}
              {formState.createUserForm.email} or{' '}
              <a href="mailto:bizcs@penguinrandomhouse.com?subject=Email%20verification%20help%20for%20Self-Service%20Registration">
                contact Customer Service
              </a>
              . 
            </>
          ) : (
            ''
          )}
          {account.formState.createResponse === 'created' ? (
            <>
              Before you may access Self-Service we'd like you to verify your
              account. Please check your email{' '}
              <strong>{account.formState.createUserForm.email}</strong> and
              click the verification link.
              <br />
              <br />
              If you don't receive an email within several minutes, please
              <Link onClick={account.resendVerifyEmail} to="#">
                {' '}
                click here to resend{' '}
              </Link>
              or{' '}
              <a href="mailto:bizcs@penguinrandomhouse.com?subject=Email%20verification%20help%20for%20Self-Service%20Registration">
                contact Customer Service
              </a>
              .  .
            </>
          ) : (
            ''
          )}
          {account.formState.createResponse === 'resent' ? (
            <>
              A verification link has been resent to your email{' '}
              <strong>{account.formState.createUserForm.email}</strong>. <br />
              If you don't receive an email within several minutes, please{' '}
              <a href="mailto:bizcs@penguinrandomhouse.com?subject=Email%20verification%20help%20for%20Self-Service%20Registration">
                contact Customer Service
              </a>
              .
            </>
          ) : (
            ''
          )}
          {account.formState.createResponse === 'resent-failed' ? (
            <>
              We were unable to send a link to your email{' '}
              {account.formState.createUserForm.email}. Please click here to{' '}
              <Link onClick={account.resendVerifyEmail} to="#">
                resend
              </Link>{' '}
              or{' '}
              <a href="mailto:bizcs@penguinrandomhouse.com?subject=Email%20verification%20help%20for%20Self-Service%20Registration">
                contact Customer Service
              </a>
              .  . 
            </>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}

export default withSecurity(withApp(withAccount(RegistrationResponse)));
