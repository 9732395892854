import { Placeholder } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const LoadingContent = ({ lines }) => {
  const lineComponents = () => {
    const toReturn = [];
    for (var i = 0; i < lines; i++) {
      toReturn.push(<Placeholder.Line key={i} />);
    }
    return toReturn;
  };

  return <Placeholder>{lineComponents()}</Placeholder>;
};

LoadingContent.defaultProps = {
  lines: 10,
};

LoadingContent.propTypes = {
  lines: PropTypes.number,
};

export default LoadingContent;
