import { Component } from 'react';
import withSecurity from '../hc/withSecurity';
import withAccount from '../hc/withAccount';
import { Form, Input } from 'semantic-ui-react-form-validator';
import { Popup, Icon } from 'semantic-ui-react';

import {
  PRHLabel,
  PRHContainerHeader,
  PRHContainerGeneral,
  PRHContainerSubHeader,
} from '../elements/elements';
import TextValidator from '../../shared-react-components/elements/input';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import PasswordPopup from '../elements/PasswordPopup';
//import FormContainer from '../../elements/FormContainer';
import { Link } from 'react-router-dom';
import './admin.scss';
import withMobile from '../hc/withMobile';

class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyEmail: 0,
      loading: true,
      displayAccountDetailFields: false,
      oldPasswordFieldType: 'password',
      createPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      createPasswordEye: 'eye',
      eyeButtonShow: '',
      eyeButtonHide: '',
      passwordMatchErrorMessage: '',
      passwordValidationScore: null,
    };
  }

  showPassword = (currentShowHide) => {
    const newState = { ...this.state };
    if (newState[currentShowHide] === 'password') {
      newState[currentShowHide] = 'text';
    } else {
      newState[currentShowHide] = 'password';
    }
    this.setState(newState);
  };

  setPasswordValidationScore = (score) => {
    const newState = { ...this.state };
    newState['passwordValidationScore'] = score;
    this.setState(newState);
  }

  submitUser = () => {
    const { account } = this.props;
    account.resetPasswordAdmin(this.props.user.user.profile.email);
  };

  formIsComplete = () => {
    const { account } = this.props;
    if (
      account.formState.createUserForm.oldPassword !== '' &&
      account.formState.createUserForm.createPassword !== '' &&
      account.formState.createUserForm.confirmPassword !== '' &&
      account.formState.passwordsMatch === 1 &&
      !(account.formState.createUserForm.confirmPassword.includes(account.formState.createUserForm.oldPassword))&&
      account.formState.passwordValidationScore === 8
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const { setStepHandler, account, size } = this.props;
    const {
      oldPasswordFieldType,
      createPasswordFieldType,
      confirmPasswordFieldType,
    } = this.state;
    const isMobile = this.props.mobile.isMobile;
    //const timer = setTimeOut(this.closePopUp(), 1000);
    return (
      <>
        <div id="sub-registration">
          <div
            className={`${
              isMobile
                ? 'p-1 border-none reset-password-mobile'
                : 'white-background-container'
            }`}
          >
            <Form
              instantValidate={false}
              width={16}
              //action="#"
              id="change-password"
              onSubmit={(e) => this.submitUser()}
            >
              <div className={'password-field-holder'}>
                <div className={'password-field'}>
                  <TextValidator
                    name="oldPassword"
                    type={oldPasswordFieldType}
                    value={account.formState.createUserForm.oldPassword}
                    onChange={account.handleChangeNoValidations}
                    onBlur={account.handleBlur}
                    type={oldPasswordFieldType}
                    label={
                      <PRHLabel
                        className={`fort-light font-resize ${
                          isMobile ? 'font-weight-400' : 'font-weight-600'
                        }`}
                      >
                        Current Password
                      </PRHLabel>
                    }
                    validators={['required']}
                    errorMessages={['this field is required']}
                    width={16}
                    icon={
                      oldPasswordFieldType === 'text' ? (
                        <Icon
                          name="eye slash"
                          link
                          onClick={(e) =>
                            this.showPassword('oldPasswordFieldType')
                          }
                          style={{ height: '90%' }}
                        />
                      ) : (
                        <Icon
                          name="eye"
                          link
                          onClick={(e) =>
                            this.showPassword('oldPasswordFieldType')
                          }
                          style={{ height: '90%' }}
                        />
                      )
                    }
                  />
                </div>
              </div>
              {account.formState.stepFailed === true ? (
                <div className={'agreement-error-message'}>
                  {account.formState.systemResponse}
                </div>
              ) : (
                ''
              )}
              <Popup
                position={size.width < 920 ? 'top center' : 'right center'}
                //open={account.formState.openPasswordValidationPopUp}
                open={((account.checkPassword() > 2) 
                  && (account.checkPassword() <= 7)
                  && (account.checkPassword() != 8)
                  && !(account.formState.popUpHasBeenOpenTooLong)
                  )}
                //open={account.formState.passwordValidationScore >= 7}
                className={`password-popup`}
                trigger={
                  <div>
                    <div className={'password-field-holder'}>
                      <div className={'password-field'}>
                        <TextValidator
                          name="createPassword"
                          passwordsMatch={account.formState.passwordsMatch}
                          type={createPasswordFieldType}
                          value={
                            account.formState.createUserForm.createPassword
                          }
                          onChange={account.handleChange}
                          validationCheck={
                            account.formState.createPasswordValid
                          }
                          validationCheckMessage="does not meet requirements"
                          label={
                            <PRHLabel
                              className={`fort-light font-resize ${
                                isMobile ? 'font-weight-400' : 'font-weight-600'
                              }`}
                            >
                              Create password
                            </PRHLabel>
                          }
                          validators={['required']}
                          errorMessages={['this field is required']}
                          data-position="right center"
                          onBlur={account.handleBlur}
                          onClick={account.passwordValidationOnFocus}
                          width={16}
                          icon={
                            createPasswordFieldType === 'text' ? (
                              <Icon
                                name="eye slash"
                                link
                                onClick={(e) =>
                                  this.showPassword('createPasswordFieldType')
                                }
                                style={{ height: '90%' }}
                              />
                            ) : (
                              <Icon
                                name="eye"
                                link
                                onClick={(e) =>
                                  this.showPassword('createPasswordFieldType')
                                }
                                style={{ height: '90%' }}
                              />
                            )
                          }
                        />
                      </div>
                      {!this.formIsComplete() ? (
                        <div className={'password-check'}>
                          <Icon name="check" />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                }
              >
                <Popup.Content
                  className={
                    'password-requirements-popup-content white-box-text fort-light'
                  }
                  hideOnScroll
                >
                  <PasswordPopup
                    password={account.formState.createUserForm.createPassword}
                    currentPassword={
                      account.formState.createUserForm.oldPassword
                    }
                    firstName={account.formState.createUserForm.firstName}
                    lastName={account.formState.createUserForm.lastName}
                    action="update"
                    setPasswordValidationScore={()=>this.setPasswordValidationScore()}
                  />
                </Popup.Content>
              </Popup>
              <div className={'password-field-holder'}>
                <div className={'password-field'}>
                  <TextValidator
                    name="confirmPassword"
                    type={confirmPasswordFieldType}
                    passwordsMatch={account.formState.passwordsMatch}
                    value={account.formState.createUserForm.confirmPassword}
                    validationCheck={account.formState.createPasswordValid}
                    validationCheckMessage="does not meet requirements"
                    onChange={account.handleChange}
                    onBlur={account.handleBlur}
                    //onClick={account.passwordValidationOnFocus}
                    onClick={account.passwordValidationOnFocus}
                    label={
                      <PRHLabel
                        className={`fort-light font-resize ${
                          isMobile ? 'font-weight-400' : 'font-weight-600'
                        }`}
                      >
                        Confirm password
                      </PRHLabel>
                    }
                    validators={['required']}
                    errorMessages={['this field is required']}
                    width={16}
                    icon={
                      confirmPasswordFieldType === 'text' ? (
                        <Icon
                          name="eye slash"
                          link
                          onClick={(e) =>
                            this.showPassword('confirmPasswordFieldType')
                          }
                          style={{ height: '90%' }}
                        />
                      ) : (
                        <Icon
                          name="eye"
                          link
                          onClick={(e) =>
                            this.showPassword('confirmPasswordFieldType')
                          }
                          style={{ height: '90%' }}
                        />
                      )
                    }
                  />
                </div>
                {!this.formIsComplete() ? (
                  <div className={'password-check'}>
                    <Icon name="check" />
                  </div>
                ) : (
                  ''
                )}
              </div>
              {account.formState.passwordsMatch === 0 ? (
                <div className={'password-match-error-message'}>
                  your new passwords do not match
                </div>
              ) : (
                ''
              )}
              <br />
              {account.formState.stepFailed === true ? (
                <div className={'agreement-error-message'}>
                  {account.formState.systemResponse}
                </div>
              ) : (
                ''
              )}
              <div className={'button-holder'}>
                {isMobile ? (
                  <PRHButton
                    className={'fort-light-bold float-right biz-form-button'}
                    icon="check"
                    size="large"
                    displayInlineFlex
                    disabled={this.formIsComplete()}
                    backgroundColorOverride="blue"
                    // textColorOverride="white"
                    // iconColorOverride="white"
                  >
                    Update Password
                  </PRHButton>
                ) : (
                  <PRHButton
                    className={'fort-light-bold float-right biz-form-button'}
                    icon="check"
                    size="large"
                    displayInlineFlex
                    disabled={this.formIsComplete()}
                  >
                    Update Password
                  </PRHButton>
                )}
              </div>
            </Form>
          </div>
        </div>
      </>
    );
  }
}

export default withSecurity(withAccount(withMobile(ResetPasswordForm)));
