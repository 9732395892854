import { Route } from "react-router-dom";
import withSecurity from "../components/hc/withSecurity";
import { withRouter } from "react-router-dom";
import LoadingView from "../pages/loading/LoadingView";

export const PrivateRoute = withSecurity(
  withRouter((props) => {
    // console.log('PrivateRoute', props);
    // console.log('isAuth', props.user.isAuthenticated());
    // const renderFn = (Component) => (props) => {
    //   console.log('isAuth', props.user.isAuthenticated());
    //   if (!!Component && props.user.isAuthenticated()) {
    //     return <Component user={props.user.getUser()} {...props} />;
    //   } else {
    //     props.user.signinRedirect();
    //     return <span>loading</span>;
    //   }
    // };

    if (props.user.isAuthenticated()) {
      // const renderFunction = props?.render ? props.render : props.component;
      if (props?.render) {
        return <Route {...props} render={props.render} />;
      } else {
        return <Route {...props} component={props.component} />;
      }
      // return <{props.component} user={props.user.getUser()} {...props} />;
      // return (props.component);
      // <Route {...props} />;
    } else {
      if (localStorage.getItem("redirectUri")) {
        // console.log("found redirect in progress, skipping");
      } else {
        console.log("signinRedirectUnAuthenticated privateroute", props.user);
        props.user.signinRedirectUnAuthenticated({
          state: `${location.pathname}${location.search}`,
        });
      }

      return (
        <Route
          render={(Component) => {
            console.log("loader", props.location);
            return <LoadingView></LoadingView>;
          }}
        />
      );
    }
  })
);
