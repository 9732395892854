import { Component } from 'react';
import { Loader, Modal } from 'semantic-ui-react';
import withSecurity from '../../components/hc/withSecurity';
import withApp from '../../components/hc/withApp';
import PageStandard from '../../components/elements/PageStandard';
import { PRHContainerGeneral } from '../../components/elements/elements';
import {
  PRHContainer,
  PRHContainerHeader,
} from '../../shared-react-components/elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import './registration.scss';

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyEmail: null,
      loading: true,
    };
  }

  async verifyEmail(token) {
    return this.props.app.registrationApi.verifyEmail(token);
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    
    this.verifyEmail(params.token).then((d) =>
      d.data.status === 'OK'
        ? this.setState({ verifyEmail: 1, loading: false })
        : this.setState({ verifyEmail: 2, loading: false })
    );
  }

  render() {
    const { verifyEmail, loading } = this.state;
    return (
      <>
      <div className={'registration'}>
        <PageStandard
          jeff={true}
          cartBadge={false}
          pageType={'registration-path'}
        >
          <Modal open={loading}>
            <Loader inverted></Loader>
          </Modal>
          {verifyEmail && verifyEmail === 1 ? (
            <PRHContainer className="content-block-border">
              <PRHContainerHeader>
              Account verified!
                </PRHContainerHeader>
              <br />
              You're all set to purchase books and manage your Penguin Random House account.
              <br />
              <br />
              <div className={'button-holder'}>
                <PRHButton
                  iconright
                  icon="arrow right"
                  size="large"
                  className={'ml-auto'}
                  type="submit"
                  borderColorOverride="blue"
                  textColorOverride="white"
                  textColorHoverOverride="blue"
                  backgroundColorOverride="blue"
                  backgroundHoverColorOverride="white"
                  iconColorOverride="white"
                  iconColorHoverOverride="blue"
                  className={'float-right margin-left'}
                  type="submit"
                  onClick={(e) => this.props.history.push('/login')}
                  //backgroundColorOverride={'blue'}
                  //textColorOverride={'white'}
                >
                  Sign In
                </PRHButton>
              </div>
              <br/><br/>
            </PRHContainer>
          ) : (
            ''
          )}

          {verifyEmail && verifyEmail === 2 ? (
            <PRHContainer className="content-block-border">
                Your registration failed. Please contact <a href="mailto:bizcs@penguinrandomhouse.com?subject=Email%20verification%20help%20for%20Self-Service%20Registration">customer service</a>.
            </PRHContainer>
          ) : (
            ''
          )}
          <br />
          <br />
        </PageStandard>
        </div>
      </>
    );
  }
}

export default withSecurity(withApp(VerifyEmail));
