import { cloneElement, useState, useEffect } from "react";
import {
  Header,
  Form,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import withApp from "../hc/withApp";
import withCart from "../hc/withCart";
import { Divider, PRHError } from "../../components/elements/elements";
import "./ValidateAddressModal.scss";

const ValidateAddressModal = ({
  cart,
  disabled,
  refresh = false,
  updateAddress,
  trigger,
  openState,
  className,
  name,
  company,
  address1,
  address2,
  city,
  state,
  zip,
  country,
  addressValid,
  setVerifyAddressActive,
  isAddressComplete,
}) => {
  const [open, setOpen] = useState(false);
  const [shipToAddress, setShipToAddress] = useState(1);
  const [addressValidateLoaded, setAddressValidateLoaded] = useState(false);
  const [addressValidateError, setAddressValidateError] = useState(false);
  const [verifiedAddress, setVerifiedAddress] = useState({});

  let realTrigger = trigger;
  if (!trigger) {
    realTrigger = (
      <div className='d-flex flex-column align-items-center'>
        <div>
          <PRHButton
            size='large'
            bold
            disabled={disabled}
            className='ml-auto'
            style={{ height: "40px", width: "180px" }}
            borderColorOverride='blue'
            textColorOverride='white'
            textColorHoverOverride='blue'
            backgroundColorOverride='blue'
            backgroundHoverColorOverride='white'
            iconColorOverride='white'
            icon='check'
            onClick={() => {
              setVerifyAddressActive(true);
              if (isAddressComplete()) {
                setVerifyAddressActive(false);
                setOpen(true);
                if (openState) {
                  openState(true);
                }
              }
            }}
          >
            Verify Address
          </PRHButton>
        </div>
        {/* <PRHButton
          disabled={disabled}
          className={className}
          onClick={() => {
            setOpen(true);
            if (openState) {
              openState(true);
            }
          }}
          icon="plus"
        >
          Validate Address
        </PRHButton> */}
        {/* <div>
          <PRHError
            isError={!addressValid}
            errorMessage="Please validate your address before placing your order."
          />
        </div> */}
      </div>
    );
  } else {
    realTrigger = cloneElement(realTrigger, {
      onClick: () => setOpen(true),
    });
  }

  const resetState = () => {
    setShipToAddress(1);
    setVerifiedAddress({});
    setAddressValidateLoaded(false);
    setAddressValidateError(false);
  };

  // async function useThisAddress() {
  //   console.log('useThisAddress');
  //   return { proceed: true };
  // }

  const validateAddress = async () => {
    // console.log('validateAddress');

    let address = {};
    address.name1 = name;
    address.name2 = company;
    address.name3 = address2;
    address.street = address1;
    address.city = city;
    address.state = state;
    if (country === "CA")
      address.postalCode = zip
        .toUpperCase()
        .replace(/\W/g, "")
        .replace(/(...)/, "$1 ");
    else address.postalCode = zip;
    address.country = country;

    const result = await cart.validateAddress(address);
    if (result.data?.status === "OK") {
      setAddressValidateLoaded(result.data.data.code);
      if (result.data.data.message)
        setAddressValidateError(result.data.data.message);
      switch (result.data.data.code) {
        case 0:
          updateAddress({
            name,
            company,
            address1,
            address2,
            city,
            state,
            zip: address.postalCode,
            country,
          });
          // resetState();
          setOpen(false);
          // console.log('address ok');
          break;
        case 1:
          // console.log('address changed');
          setVerifiedAddress({
            name: result.data.data.address.name1,
            company: result.data.data.address.name2,
            address1: result.data.data.address.street,
            address2: result.data.data.address.name3,
            city: result.data.data.address.city,
            state: result.data.data.address.state,
            zip: result.data.data.address.postalCode,
            country: result.data.data.address.country,
          });
          break;
        case 2:
          // console.log('address warning');
          break;
        case 3:
          // console.log('error');
          break;
        case 4:
          // console.log('address service unavailable');
          updateAddress({
            name,
            company,
            address1,
            address2,
            city,
            state,
            zip,
            country,
          });
          // resetState();
          setOpen(false);
          break;
        default:
        // console.log('default address');
      }
    }
  };

  const useAddressAction = {
    label: "Use this Address",
    color: "orange",
    icon: "check",
    preAction: () => {
      if (addressValidateLoaded === 1 && shipToAddress === 1) {
        updateAddress(verifiedAddress);
      } else if (addressValidateLoaded === 1 && shipToAddress === 0) {
        updateAddress({
          name,
          company,
          address1,
          address2,
          city,
          state,
          zip,
          country,
        });
      } else if (addressValidateLoaded === 2) {
        updateAddress({
          name,
          company,
          address1,
          address2,
          city,
          state,
          zip,
          country,
        });
      }

      return { proceed: true };
    },
    action: () => {
      resetState();
      return { proceed: true };
    },
  };

  const cancelAddressAction = {
    label: "Cancel",
    color: "orange",
    icon: "times",
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      resetState();
      return { proceed: true };
    },
  };

  useEffect(() => {
    if (!open) {
      resetState();
    }
    if (openState) {
      openState(open);
    }
    if (open) {
      validateAddress();
    }
  }, [open]);

  let actions = [cancelAddressAction];
  if (addressValidateLoaded === 1 || addressValidateLoaded === 2) {
    actions = [useAddressAction];
  }

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            Address Verification
          </Header>
        ),
        actions: actions,
      }}
      options={{
        trigger: realTrigger,
      }}
    >
      {addressValidateLoaded === 1 ? (
        <Form>
          <div>
            <div className='mb-2'>You've entered:</div>
            <div className='d-flex flex-row mb-1'>
              <div className='d-flex flex-column mr-4'>
                <Form.Field>
                  <Radio
                    name='selectedAddress'
                    value={0}
                    checked={shipToAddress === 0 ? true : false}
                    onChange={(e) => setShipToAddress(0)}
                  />
                </Form.Field>
              </div>
              <div className='d-flex flex-column'>
                {company ? (
                  <div className='fort-bold mb-1'>{company}</div>
                ) : null}
                {name ? <div className='mb-1'>{name}</div> : null}
                {address1 ? <div className='mb-1'>{address1}</div> : null}
                {address2 ? <div className='mb-1'>{address2}</div> : null}
                <div className='mb-1'>
                  {city}, {state} {zip}
                </div>
              </div>
            </div>
            <div className='mb-2'>We found:</div>
            <div className='d-flex flex-row'>
              <div className='d-flex flex-column mr-4'>
                <Form.Field>
                  <Radio
                    name='selectedAddress'
                    value={1}
                    checked={shipToAddress === 1 ? true : false}
                    onChange={(e) => setShipToAddress(1)}
                  />
                </Form.Field>
              </div>
              <div className='d-flex flex-column'>
                {verifiedAddress.company ? (
                  <div className='fort-bold mb-1'>
                    {verifiedAddress.company}
                  </div>
                ) : null}
                {verifiedAddress.name ? (
                  <div className='mb-1'>{verifiedAddress.name}</div>
                ) : null}
                {verifiedAddress.address1 ? (
                  <div className='mb-1'>{verifiedAddress.address1}</div>
                ) : null}
                {verifiedAddress.address2 ? (
                  <div className='mb-1'>{verifiedAddress.address2}</div>
                ) : null}
                <div className='mb-1'>
                  {verifiedAddress.city}, {verifiedAddress.state}{" "}
                  {verifiedAddress.zip}
                </div>
              </div>
            </div>
          </div>
          <Divider tight className='mt-2' />
        </Form>
      ) : (
        <div></div>
      )}
      {addressValidateLoaded === 2 ||
      addressValidateLoaded === 3 ||
      addressValidateLoaded === 4 ? (
        <div>
          <div className='mb-2'>You've entered:</div>
          <div className='d-flex flex-row mb-1 ml-4'>
            <div className='d-flex flex-column'>
              {company ? <div className='fort-bold mb-1'>{company}</div> : null}
              {name ? <div className='mb-1'>{name}</div> : null}
              {address1 ? <div className='mb-1'>{address1}</div> : null}
              {address2 ? <div className='mb-1'>{address2}</div> : null}
              <div className='mb-1'>
                {city}, {state} {zip}
              </div>
            </div>
          </div>
          <div className='fort-bold'>{addressValidateError}</div>
          <Divider className='mt-2' tight />
        </div>
      ) : (
        <div></div>
      )}
    </DialogModal>
  );
};

ValidateAddressModal.propTypes = {
  name: PropTypes.string.isRequired,
  company: PropTypes.string,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  refresh: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  trigger: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  updateAddress: PropTypes.func,
};

ValidateAddressModal.defaultProps = {
  refresh: false,
  trigger: false,
};

export default withApp(withCart(ValidateAddressModal));
