import { Component } from "react";
import { Container } from "semantic-ui-react";
import PageHeader from "../header/PageHeader";
import PageHeaderLeftAlign from "../header/PageHeaderLeftAlign";
import PageFooter from "../footer/PageFooter";
import withSecurity from "../hc/withSecurity";
import withCart from "../hc/withCart";
import "./elements.scss";
import withAccount from "../hc/withAccount";
//import {PRHContainer} from './elements';

class PageStandard extends Component {
  render() {
    const {
      className,
      children,
      alignMent,
      pageExtension,
      cartBadge,
      pageType,
      columnWidthsProp,
      history,
      headerType,
      containerFluid,
      backgroundClass,
      bg,
      locationSelector,
      navTriggered,
      isReturn,
    } = this.props;
    let columnWidths = columnWidthsProp || [4, 8, 4];
    let header = <PageHeader />;

    if (alignMent === "left") {
      columnWidths = columnWidthsProp || [1, 13, 2];
      header = (
        <PageHeaderLeftAlign
          cartBadge={cartBadge}
          locationSelector={locationSelector}
        />
      );
    }
    header = (
      <PageHeader
        headerType={headerType}
        cartBadge={true}
        locationSelector={locationSelector}
        profile={this.props.user.user && this.props.user.user.profile}
        navTriggered={() => navTriggered()}
        isReturn={isReturn}
      />
    );
    return (
      <div className={bg}>
        {header}
        <Container
          className={className}
          fluid={this.props.containerFluid}
          onClick={() => {
            if (this.props.cart.cartBadgeOpen) {
              this.props.cart.setCartBadgeOpen(false);
            }
          }}
        >
          {children}
        </Container>
        <PageFooter
          //loggedIn={!!this.props.user.user}
          loggedIn={this.props.user.isAuthenticated()}
          which={!!this.props.user.isAppCompCopy() ? "compCopy" : "biz"}
        />
      </div>
    );
  }
}
PageStandard.defaultProps = {
  containerFluid: false,
  className: "",
  backgroundClass: "",
  bg: "",
  locationSelector: false,
  isReturn: false,
};
export default withSecurity(withCart(withAccount(PageStandard)));
