import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { formatDate, isFocCart, isGeneralCart } from "../../utils/utilities";
import { NumericFormat } from "react-number-format";
import { useHistory } from "react-router";
import { isIE, osName, isFirefox, browserName } from "react-device-detect";
import { Grid, Form, Input, Popup, Image, Checkbox } from "semantic-ui-react";
import InventoryMessage from "./InventoryMessage";
import { Divider } from "../../components/elements/elements";
import useSecurity from "../../components/hc/useSecurity";
import AddToCartButton from "./AddToCartButton";
import { SeriesHistoryCatalogWrapper } from "../series/SeriesHistoryCatalogWrapper";
import "./TitleRow.scss";
import LazyImage from "../../utils/LazyImage";
import { formatDateMMDDYYYY, isFocCartExpired } from "../../utils/utilities";
import { TOOLTIPS } from "../../utils/const";
import VariantRestrictionsInfoModal from "../modals/VariantRestrictionsInfoModal";
import useCart from "../hc/useCart";
import VariantRestrictionsOrderingModal from "../modals/VariantRestrictionsOrderingModal";
import VariantRestrictionsOrderUpdateModal from "../modals/VariantRestrictionsOrderUpdateModal";
import { Visibility, Loader } from "semantic-ui-react";
import usePrevious from "../hc/usePrevious";
import LazyLoad from "../../utils/LazyLoad";
import ReturnAbilityStatus from "./ReturnAbilityStatus";
import WarehouseLocation from "./WarehouseLocation";
// import {
//   isSafari,
//   osName,
//   isChrome,
//   isFirefox,
//   isIE,
//   browserVersion,
// } from "react-device-detect";

const TitleRow = (props) => {
  const {
    divider,
    pageType,
    tab,
    disabled = false,
    simulate,
    forceRefresh = false,
    update = false,
    callback = false,
    cartShipTo = false,
    addAll = false,
    clearAddAllQty = false,
    resetAllQty = false,
    variantsNotMetTitles = [],
    selectItem = false,
    selected = false,
    removeItem,
    data,
    isSeriesHistoryModal = false,
    setWrapperQty,
    wrapperQty,
    parentQty = false,
    setParentQty = false,
    initialVisibility = false,
  } = props;
  const {
    isbn,
    title,
    subtitle,
    formatName,
    onsale,
    author,
    currency,
    inventoryMsg,
    restockMsg,
    managedMsg,
    status,
    statusFilter,
    country,
    price,
    discountedPriceTotal,
    priceSingle,
    cartonQuantity,
    format,
    divisionName,
    foc,
    available,
    itemId,
    quantity = 1,
    updateQuantity,
    focStatus,
    cartType = false,
    deliveryDate = false,
    basePctDiscount,
    discountedPriceSingle,
    isVariant,
    hasOrderReq,
    variantCount,
    orderReq,
    cartQuantity,
    confirmationStatus,
    saleStatus,
    cbReturnable,
    cbReturnStart,
    cbReturnEnd,
    hasSeriesHistory,
    hasSeries,
    seriesHistory,
    shipTo,
    formatCode,
    warehouse,
  } = props.data;
  // if (isbn === '75960608936908211') console.log('titlerow', props);
  // console.log('titlerow', props);
  const [qty, setQty] = useState(quantity ? quantity : "1");
  //const [parentQty, setParentQty] = useState(quantity ? quantity : "1");
  const [updatingItem, setUpdatingItem] = useState(false);
  const [variantOrderingModalState, setVariantOrderingModalState] =
    useState(false);
  const [variantOrderUpdateModalState, setVariantOrderUpdateModalState] =
    useState(false);
  const [variantOrderDeleteModalState, setVariantOrderDeleteModalState] =
    useState(false);
  const [restrictionsNew, setRestrictionsNew] = useState(false);
  const [titleRestrictions, setTitleRestrictions] = useState(false);
  const [titleRestrictionsQty, setTitleRestrictionsQty] = useState(false);
  const previousQuantity = usePrevious(quantity);
  const previousResetAllQty = usePrevious(resetAllQty);
  const previousAddAll = usePrevious(addAll);
  const previousClearAddAllQty = usePrevious(clearAddAllQty);
  const [clearLocalAddAllQty, setClearLocalAddAllQty] = useState(false);
  const [isMissing, setIsMissing] = useState(false);
  const [show, setShow] = useState(initialVisibility);
  const user = useSecurity();
  const cart = useCart();
  const [defaultQty, setDefaultQty] = useState(1);

  const history = useHistory();

  useEffect(() => {
    // if (isbn === '75960608936908211') console.log('useeffect addAll', addAll);
    // console.log("title row parent quantity", parentQty);
    if (pageType !== "cart" && pageType !== "sfl") {
      if (addAll === true) {
        setQty(parentQty ? parentQty : 0);
        setDefaultQty(0);
      } else {
        if (previousAddAll && addAll !== previousAddAll) {
          setDefaultQty(1);
          setQty(1);
        }
      }
    }
  }, [addAll]);

  useEffect(() => {
    if (
      pageType !== "cart" &&
      pageType !== "sfl" &&
      previousClearAddAllQty !== undefined
    ) {
      setQty(0);
    }
  }, [clearAddAllQty]);

  useEffect(() => {
    if (
      pageType !== "cart" &&
      pageType !== "sfl" &&
      previousResetAllQty &&
      resetAllQty !== previousResetAllQty
    ) {
      setQty(1);
    }
  }, [cartShipTo, resetAllQty]);

  useEffect(() => {
    if (
      author === null &&
      formatName === null &&
      title === null &&
      onsale === null
    ) {
      setIsMissing(true);
    }
  }, [author, formatName, title, onsale]);

  if (previousQuantity && quantity !== previousQuantity && quantity !== qty) {
    setQty(quantity);
  }

  const isVariantsNotMetTitle = (isbn) => {
    if (
      variantsNotMetTitles.findIndex((i) => {
        if (i.isbn === isbn.toString()) {
          return true;
        }
      }) >= 0
    ) {
      return true;
    } else return false;
  };

  const imgURL = "https://images.penguinrandomhouse.com/cover/" + isbn;

  const addToCart = () => {
    props.context.componentParent.addToCart(isbn, qty);
  };

  const localCallback = useCallback(
    (cart) => {
      if (callback) {
        callback(cart);
      }
      setQty(defaultQty);
    },
    [cart]
  );
  const isCartBadge = () => {
    if (user.isAppCompCopy()) return false;

    if (
      cart.customerInfo &&
      cart.customerInfo.currentUser &&
      cart.customerInfo.currentUser.roleNo !== "W1"
    )
      return false;

    return true;
  };

  const formRef = useRef();

  const resetVariant = () => {
    console.log("resetVariant", quantity);
    setQty(quantity);
    setTitleRestrictions(false);
    setTitleRestrictionsQty(false);
    setRestrictionsNew(false);
    setUpdatingItem(false);
  };

  // const removeCartItem = () => {
  //   if (cart.isUserPow()) {
  //     let item = { ...props.data };
  //     delete item.cartType;
  //     cart
  //       .removeEstimateItems({
  //         focDate: foc,
  //         items: [item],
  //         simulate,
  //       })
  //       .then((r) => {
  //         if (r?.modalInfo === 'REMOVE_VARIANTS') {
  //           setTitleRestrictions(r.mainVariantTitles);
  //           setTitleRestrictionsQty(false);
  //           setVariantOrderUpdateModalState(true);
  //         }
  //       });
  //   } else {
  //     cart.removeItem(itemId);
  //   }
  // };

  const cancelCallback = () => {
    setQty(quantity);
    setTitleRestrictions(false);
    setTitleRestrictionsQty(false);
    setRestrictionsNew(false);
    setUpdatingItem(false);
  };

  /*
  function cancelCallback() {
    setQty(quantity);
    setTitleRestrictions(false);
    setTitleRestrictionsQty(false);
    setRestrictionsNew(false);
    setUpdatingItem(false);
  }
*/
  const proceedCallback = useCallback((cart) => {
    setUpdatingItem(false);
    if (callback) callback(cart);
  });

  const coverHover = (isbn) => {
    return (
      <div className="d-flex flex-row">
        <LazyImage
          className="hover-cover"
          alt={`Cover: ${title}`}
          boxShadow={true}
          src={`https://images.penguinrandomhouse.com/cover/${isbn}`}
        />
      </div>
    );
  };

  const workset = (x) => {
    setShow(x);
  };

  const isAvailable = () => {
    if (available) {
      return available;
    } else if (foc && !!!isFocCartExpired(foc)) {
      return true;
    } else {
      return false;
    }
  };

  //  {pageType === 'cart' && available && saleStatus !== 'NR' && (
  return (
    <Visibility
      fireOnMount
      as="span"
      updateOn="repaint"
      onOnScreen={(e) => setShow(true)}
      onTopVisible={(e) => setShow(true)}
      onTopPassed={(e) => setShow(true)}
      onPassing={(e) => setShow(true)}
      // onOffScreen={(e) => setShow(false)}
      // fireOnMount={true}
    >
      {show && (
        <>
          {show && (
            <>
              <VariantRestrictionsOrderingModal
                isbn={isbn}
                qtyWant={qty}
                openState={variantOrderingModalState}
                restrictionsNew={restrictionsNew}
                setOpenState={(w) => {
                  setVariantOrderingModalState(w);
                }}
                shipTo={cart.getShipToAccount().accountNo}
                foc={foc}
                cancelCallback={cancelCallback}
                callBackFunction={proceedCallback}
                update={update}
              />
              <VariantRestrictionsOrderUpdateModal
                isbn={isbn}
                title={title}
                openState={variantOrderUpdateModalState}
                titleRestrictions={titleRestrictions}
                setOpenState={(w) => {
                  setVariantOrderUpdateModalState(w);
                }}
                shipTo={cart.getShipToAccount().accountNo}
                foc={foc}
                quantity={quantity}
                titleRestrictionsQty={titleRestrictionsQty}
                cancelCallback={cancelCallback}
                proceedCallback={proceedCallback}
              />
            </>
          )}
          <Grid container>
            <Grid.Row
              className={`${pageType === "catalog" ? "cat-adjust" : ""}`}
            >
              <Grid.Column
                mobile={pageType === "cart" || pageType === "sfl" ? 3 : 2}
                tablet={pageType === "cart" || pageType === "sfl" ? 3 : 2}
                computer={
                  pageType === "cart" || pageType === "sfl"
                    ? 2
                    : pageType === "dialog"
                    ? 1
                    : 2
                }
                largeScreen={1}
                widescreen={
                  pageType === "dialog"
                    ? 2
                    : pageType === "cart" || pageType === "sfl"
                    ? 2
                    : 1
                }
                className="order-1"
              >
                <div className="flex flex-row">
                  {pageType === "cart" && (
                    <Checkbox
                      className="mt-2 mr-2 mr-widescreen-3"
                      checked={selected}
                      value={isbn}
                      onChange={(e, d) => {
                        if (selectItem) {
                          selectItem(isbn, d.checked);
                        }
                      }}
                    ></Checkbox>
                  )}

                  <div className="coverImageDiv">
                    {show && (
                      <Popup
                        size="huge"
                        className="hover-cover"
                        content={coverHover(isbn)}
                        on="hover"
                        hoverable={true}
                        position="top left"
                        offset={[0, 10]}
                        trigger={
                          <LazyImage
                            className="title-cover"
                            alt={`Cover: ${title}`}
                            isVariant={isVariant}
                            variantCount={
                              pageType === "cart" || pageType === "sfl"
                                ? 0
                                : variantCount
                            }
                            isSmallThumbNail={
                              pageType === "cart" || pageType === "sfl"
                                ? true
                                : false
                            }
                            boxShadow={true}
                            src={`https://images.penguinrandomhouse.com/cover/${isbn}`}
                          />
                        }
                      />
                    )}
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column
                mobile={pageType === "cart" || pageType === "sfl" ? 9 : 10}
                tablet={pageType === "cart" || pageType === "sfl" ? 9 : 10}
                computer={
                  pageType === "cart" || pageType === "sfl"
                    ? 10
                    : pageType === "dialog"
                    ? 12
                    : 10
                }
                largeScreen={11}
                widescreen={
                  pageType === "dialog"
                    ? 10
                    : pageType === "cart" || pageType === "sfl"
                    ? 10
                    : 11
                }
                className={`order-2 order-lg-3 order-xl-2 ${
                  pageType === "cart" || pageType === "sfl"
                    ? "ttl-cart-meta"
                    : ""
                }`}
              >
                <Grid>
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={8}
                      largeScreen={12}
                      widescreen={12}
                    >
                      <div className="d-flex">
                        <div className="agCellData mt-1 ttl-meta">
                          <div className="rowItem title-subtitle-blk">
                            {available === true ? (
                              <a
                                className="boldLabel text-gray-900"
                                href={`/titleinfo/${isbn}`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  history.push(`/titleinfo/${isbn}`);
                                }}
                              >
                                {title}
                              </a>
                            ) : (
                              title
                            )}

                            {subtitle != null ? (
                              <span className="rowItem">: {subtitle}</span>
                            ) : (
                              ""
                            )}
                          </div>
                          {author != null ? (
                            <div className="rowItem  title-subtitle-blk">
                              {author.match("^Written") || author.match("^By")
                                ? ""
                                : "By "}
                              {author}
                            </div>
                          ) : (
                            ""
                          )}
                          {divisionName != null ? (
                            <div className="rowItem  title-subtitle-blk">
                              {divisionName}
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="rowItem">{isbn}</div>
                          {formatName != null ? (
                            <div className="rowItem  title-subtitle-blk">
                              <span>{formatName}</span>{" "}
                              <span className="pipe"> | </span>{" "}
                              <span>{formatDate(onsale, false)}</span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </Grid.Column>
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={8}
                      largeScreen={4}
                      widescreen={4}
                      className={`second-col ttl-meta ${
                        pageType === "catalog" ? "catalog-sec-col" : ""
                      }`}
                    >
                      <WarehouseLocation warehouse={warehouse} />
                      {inventoryMsg != null ? (
                        <>
                          <InventoryMessage
                            msg={inventoryMsg}
                            classes="second-col-items"
                          />
                        </>
                      ) : (
                        ""
                      )}
                      {managedMsg != null ? (
                        <InventoryMessage
                          msg={managedMsg}
                          classes="second-col-items"
                          bgColor="grey-back"
                        />
                      ) : (
                        ""
                      )}
                      {restockMsg != null ? (
                        <InventoryMessage
                          msg={restockMsg}
                          classes="second-col-items"
                          bgColor="grey-back"
                        />
                      ) : (
                        ""
                      )}

                      {cbReturnable &&
                      (cbReturnable === "Y" || cbReturnable === "N") ? (
                        <ReturnAbilityStatus
                          cbReturnable={cbReturnable}
                          cbReturnStart={cbReturnStart}
                          cbReturnEnd={cbReturnEnd}
                        />
                      ) : (
                        ""
                      )}

                      {!isMissing && foc != null && !isGeneralCart(foc) ? (
                        <div className="rowItem second-col-itemss">
                          FOC {formatDateMMDDYYYY(foc, "/")}
                        </div>
                      ) : (
                        ""
                      )}
                      {cartonQuantity != null ? (
                        <div
                          className={`rowItem second-col-items ${
                            (inventoryMsg === null && foc === null) ||
                            foc !== null
                              ? "carton-div"
                              : ""
                          }`}
                        >
                          <NumericFormat
                            style={{ marginRight: "0.25rem" }}
                            value={cartonQuantity}
                            thousandSeparator={true}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            displayType={"text"}
                          />
                          per carton
                        </div>
                      ) : (
                        ""
                      )}
                      {deliveryDate && user.isAppBiz() ? (
                        <div className="d-flex flex-column cp-mb-1">
                          <div className="fort-book-italic est-del">
                            Est Delivery:
                            {deliveryDate !== "0000-00-00"
                              ? formatDate(deliveryDate, false)
                              : "Not Available"}
                          </div>
                        </div>
                      ) : null}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column
                mobile={4}
                tablet={4}
                computer={
                  pageType === "cart" || pageType === "sfl"
                    ? 4
                    : pageType === "dialog"
                    ? 3
                    : 4
                }
                largeScreen={4}
                widescreen={4}
                className={`third-col order-2 order-lg-3 ipad-correction`}
              >
                {!isMissing ? (
                  <>
                    <div className="rowItem boldLabel Xmt-1 third-col-items d-flex justify-content-end">
                      <div className="fs-12px lh-16">
                        <NumericFormat
                          className="ml-1"
                          value={
                            pageType === "catalog"
                              ? price
                              : discountedPriceTotal
                          }
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          prefix={"$"}
                        />{" "}
                        {currency}
                      </div>
                    </div>
                    {user.isAppBiz ? (
                      <div className="rowItem third-col-items d-flex justify-content-end discount-price-item">
                        <div className="fs-12px lh-16">
                          {basePctDiscount > 0 ? (
                            <>
                              <NumericFormat
                                className=""
                                value={basePctDiscount}
                                thousandSeparator={true}
                                decimalScale={0}
                                fixedDecimalScale={true}
                                displayType={"text"}
                                suffix={"%"}
                              />{" "}
                              off{" "}
                              <span className="strike-txt">${priceSingle}</span>{" "}
                              (each)
                            </>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    ) : null}
                    <div className="d-flex flex-row align-items-center ml-auto">
                      {cartQuantity > 0 ? (
                        <Popup
                          className=""
                          content={`${cartQuantity} units in cart ${
                            confirmationStatus === "CONFIRMED"
                              ? " (confirmed)"
                              : ""
                          }`}
                          mouseEnterDelay={250}
                          on="hover"
                          position="top center"
                          offset={[-1, 8]}
                          trigger={
                            <div
                              className={`flex flex-column align-items-center mr-1 tr-cart-qty ml-auto ${osName} ${browserName} ${
                                focStatus === "ok" &&
                                confirmationStatus === "CONFIRMED"
                                  ? "confirmed"
                                  : confirmationStatus === "PENDING_UPDATE"
                                  ? "updated"
                                  : ""
                              } ${
                                isFocCart(foc, focStatus) === false
                                  ? "general"
                                  : ""
                              } ${!cart.isUserPow() ? "biz" : ""}`}
                            >
                              <div className="fort-bold">
                                {cartQuantity > 9999 ? (
                                  "9999+"
                                ) : (
                                  <NumericFormat
                                    value={cartQuantity}
                                    thousandSeparator={false}
                                    decimalScale={0}
                                    allowNegative={true}
                                    fixedDecimalScale={true}
                                    displayType={"text"}
                                  />
                                )}
                              </div>
                            </div>
                          }
                        />
                      ) : null}

                      {pageType === "dialog" ? (
                        <div>
                          Qty:{" "}
                          <NumericFormat
                            className=""
                            value={quantity}
                            thousandSeparator={true}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            displayType={"text"}
                            // suffix={'%'}
                          />
                        </div>
                      ) : (
                        <Form
                          className="tr-form"
                          ref={formRef}
                          onSubmit={(e) => {
                            e.preventDefault();

                            if (pageType === "cart" || pageType === "sfl") {
                              if (quantity === qty) {
                                return;
                              }
                              setUpdatingItem(true);
                              if (cart.isUserPow()) {
                                cart
                                  .performEstimateItemQtyUpdate({
                                    simulate: simulate,
                                    foc: foc,
                                    cartType: cartType
                                      ? cartType
                                      : isGeneralCart(foc)
                                      ? "GEN"
                                      : "FOC",
                                    items: [
                                      {
                                        ...props.data,
                                        updateQuantity: parseInt(qty),
                                      },
                                    ],
                                  })
                                  .then((e) => {
                                    // console.log("e", e);
                                    if (e?.modalInfo === "REMOVE_VARIANTS") {
                                      console.log("do modal", qty);
                                      setTitleRestrictions(e.mainVariantTitles);
                                      setTitleRestrictionsQty(qty);
                                      setVariantOrderUpdateModalState(true);
                                    } else if (
                                      e?.modalInfo === "ADD_QUALIFYING_ITEMS"
                                    ) {
                                      setRestrictionsNew(e.variantInfo);
                                      setVariantOrderingModalState(true);
                                    } else {
                                      setUpdatingItem(false);
                                    }
                                  });
                              } else {
                                if (tab === 0) {
                                  cart
                                    .performItemQtyUpdate(itemId, qty)
                                    .then((e) => {
                                      setUpdatingItem(false);
                                    });
                                } else {
                                  cart
                                    .performItemSaveForLaterQtyUpdate(
                                      itemId,
                                      qty
                                    )
                                    .then((e) => {
                                      setUpdatingItem(false);
                                    });
                                }
                              }
                            } else {
                              // if (addAll === true) {
                              //   if (isNaN(parseInt(qty)) || parseInt(qty) === 0) {
                              //     setQty(defaultQty);
                              // } else {
                              //   props.updateTotalQty(
                              //     qty,
                              //     isbn,
                              //     confirmationStatus
                              //   );
                              // }
                              // } else {
                              if (isNaN(parseInt(qty)) || parseInt(qty) === 0) {
                                setQty(defaultQty);
                              }
                              // }
                            }
                          }}
                        >
                          <div
                            className={`rowItem qty-box ${
                              isVariantsNotMetTitle(isbn) ? "variantError" : ""
                            } mt-1 third-col-items ml-auto `}
                            style={{
                              border:
                                addAll === true && qty > 0
                                  ? "1px solid #0078a6 "
                                  : "1px solid #aaaaaa",
                              boxShadow:
                                addAll === true && qty > 0
                                  ? "0px 0px 1px #0078a6 "
                                  : "none",
                            }}
                          >
                            <span className="qty-lbl text-gray-700"> Qty </span>
                            <label
                              htmlFor={`qty_${isbn}`}
                              className="visually-hidden"
                            >
                              Quantity of ISBN
                            </label>

                            <Input
                              disabled={
                                disabled
                                  ? true
                                  : !isAvailable() || isCartBadge()
                              }
                              type={
                                isFirefox || (isSeriesHistoryModal && addAll)
                                  ? "text"
                                  : "number"
                              }
                              max={9999}
                              // min={1}
                              className={`search-title-input tr-qty-input ${
                                isFirefox ? "ff" : ""
                              } `}
                              id={`qty_${isbn}`}
                              name={`qty_${isbn}`}
                              value={qty}
                              onFocus={(e) => {
                                if (parseInt(qty) === 0) {
                                  setQty("");
                                }
                              }}
                              onBlur={(e) => {
                                if (!updatingItem)
                                  formRef.current.handleSubmit(e);
                              }}
                              onChange={(e) => {
                                // console.log(
                                //   'onChange',
                                //   e.target.value,
                                //   qty,
                                //   quantity
                                // );

                                if (
                                  parseInt(
                                    e.target.value.replace(/[^0-9\s]/g, "")
                                  ) > 9999
                                ) {
                                  return;
                                }
                                setQty(e.target.value.replace(/[^0-9\s]/g, ""));
                                isSeriesHistoryModal
                                  ? setParentQty(
                                      e.target.value.replace(/[^0-9\s]/g, "")
                                    )
                                  : "";
                                if (addAll === true) {
                                  // if (
                                  //   !isNaN(
                                  //     parseInt(
                                  //       e.target.value.replace(/[^0-9\s]/g, '')
                                  //     )
                                  //   ) &&
                                  //   parseInt(
                                  //     e.target.value.replace(/[^0-9\s]/g, '')
                                  //   ) > 0
                                  // ) {
                                  props.updateTotalQty(
                                    parseInt(
                                      e.target.value.replace(/[^0-9\s]/g, "")
                                    ),
                                    isbn,
                                    confirmationStatus
                                  );
                                  // }
                                }
                              }}
                            />
                          </div>
                        </Form>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {pageType === "cart" || pageType === "sfl" ? (
                  <>
                    <div className="title-row-action d-flex justify-content-end fs-12px">
                      {!isMissing ? (
                        <a
                          href="#"
                          className={`text-blue cursor-pointer mr-1 ${
                            cart.isUserPow() ? "ml-auto" : ""
                          }`}
                          onClick={(e) => {
                            tab === 0 && removeItem && removeItem(data);
                            tab === 1 && cart.removeItemSaveForLater(itemId);
                          }}
                        >
                          Remove
                        </a>
                      ) : (
                        ""
                      )}
                      {!cart.isUserPow() ? (
                        <>
                          <div className="text-gray-500">|</div>
                          <a
                            href="#"
                            className="text-blue cursor-pointer ml-1"
                            onClick={(e) => {
                              tab === 0 && cart.saveItemForLater(itemId);
                              tab === 1 && cart.moveItemToCart(itemId);
                            }}
                          >
                            {tab === 0 ? "Save for Later" : "Move to Cart"}
                          </a>
                        </>
                      ) : null}
                    </div>
                    {show && hasOrderReq ? (
                      <>
                        <div className="d-flex flex-column flex-xl-row justify-content-end mt-1 mb-1">
                          {/* <span className="tr-order-restriction fort-book-italic fs-13px lh-18 text-gray-700 align-self-end text-right text-md-initial">
                        Ordering restrictions
                      </span> */}
                          <VariantRestrictionsInfoModal
                            isbn={isbn}
                            searchClass={cart.customerInfo.customer.searchClass}
                            country={cart.country}
                            orderReq={orderReq}
                            title={title}
                          />
                        </div>
                      </>
                    ) : null}
                  </>
                ) : pageType === "dialog" ? (
                  <div />
                ) : (
                  <div className="rowItem third-col-items d-flex flex-flow-column align-items-end">
                    <div className="flex flex-row align-items-center">
                      {addAll === true ? (
                        ""
                      ) : show && !(isSeriesHistoryModal && addAll) ? (
                        <AddToCartButton
                          // onClickFunction={addToCart}
                          isBtnAvailable={available}
                          focStatus={focStatus}
                          foc={foc}
                          qty={qty}
                          isbn={isbn}
                          onSaleDate={onsale}
                          hasOrderReq={hasOrderReq}
                          orderReq={orderReq}
                          callback={localCallback}
                          confirmationStatus={confirmationStatus}
                          bold={true}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {show && hasOrderReq ? (
                      <>
                        <div className="d-flex flex-column flex-xl-row justify-content-end mt-1 mb-1">
                          {/* <span className="tr-order-restriction fort-book-italic fs-13px lh-18 text-gray-700 align-self-end text-right text-md-initial">
                        Ordering restrictions
                      </span> */}
                          <VariantRestrictionsInfoModal
                            isbn={isbn}
                            searchClass={cart.customerInfo.customer.searchClass}
                            country={cart.country}
                            orderReq={orderReq}
                            title={title}
                          />
                        </div>
                        {isVariantsNotMetTitle(isbn) ? (
                          <div className="d-flex flex-column flex-xl-row justify-content-end variantErrorMsg mb-1">
                            <span className="tr-order-restriction fort-book-italic fs-13px lh-18 text-red align-self-end text-right text-md-initial">
                              *Did not meet requirements
                            </span>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {props.pageType !== "search" &&
          !isSeriesHistoryModal &&
          seriesHistory &&
          cartShipTo ? (
            <SeriesHistoryCatalogWrapper
              shipTo={cartShipTo}
              isbn={isbn}
              formatCode={formatCode}
              getAll={false}
              titleRow={props}
              addAll={addAll}
              parentQty={qty}
              setParentQty={setQty}
            />
          ) : (
            ""
          )}
          {divider ? <Divider tight className="mt-2 mb-2" /> : null}
        </>
      )}
    </Visibility>
  );
};

export default TitleRow;

TitleRow.propTypes = {
  data: PropTypes.object.isRequired,
  pageType: PropTypes.string,
  tab: PropTypes.number,
  divider: PropTypes.bool,
  user: PropTypes.object,
  cart: PropTypes.object,
  app: PropTypes.object,
  simulate: PropTypes.bool,
};
TitleRow.defaultProps = {
  pageType: "",
  tab: 0,
  divider: false,
  simulate: false,
};
