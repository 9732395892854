import "./TitleMetaRow.scss";
import LazyImage from "../../utils/LazyImage";
import { withRouter } from "react-router";

const TitleRow = (props) => {
  const { isbn, title, flapCopy, authors } = props;
  const authorArray = [
    ...new Map(authors.map((item) => [item["authorId"], item])).values(),
  ];
  //comic author 2260160
  //regular author 149907
  const imgURL = "https://images.penguinrandomhouse.com/cover/" + isbn;

  const myFunction = (isbn) => {
    var dots = document.getElementById(isbn + "_dots");
    var moreText = document.getElementById(isbn + "_more");
    var btnText = document.getElementById(isbn + "_myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read More";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = " Read Less";
      moreText.style.display = "inline";
    }
  };

  return (
    <div className='title-meta-row d-flex flex-row justify-content-start h-auto'>
      <div style={{ height: "auto", width: "128px" }}>
        <LazyImage
          className='title-cover'
          boxShadow={true}
          altImageSize='medium'
          size='medium'
          src={`https://images.penguinrandomhouse.com/cover/${isbn}`}
          onClick={() => {
            props.history.push({
              pathname: `/titleinfo/${isbn}`,
            });
          }}
        ></LazyImage>
      </div>
      <div className='d-flex flex-column ml-2 w-100 h-auto'>
        <div className='title'>
          <a
            href={`/titleinfo/${isbn}`}
            onClick={(e) => {
              e.preventDefault();
              props.history.push({
                pathname: `/titleinfo/${isbn}`,
              });
            }}
          >
            {title}
          </a>
        </div>
        {authorArray.length !== 0 ? (
          <div className='rowItem  title-subtitle-blk title-meta fort-light-book'>
            By &nbsp;
            {authorArray.map((author, index) => {
              return (
                <span key={`author_${index}`}>
                  <a
                    href={`/author/${author.authorId}`}
                    className='more-link'
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push({
                        pathname: `/author/${author.authorId}`,
                      });
                    }}
                  >
                    {author.display}
                  </a>
                  {authorArray[index + 1] ? ", " : ""}
                </span>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {flapCopy && flapCopy !== null ? (
          <div className='flapCopy title-meta fort-light-book'>
            <span
              dangerouslySetInnerHTML={{ __html: flapCopy?.slice(0, 269) }}
            />
            {flapCopy.length > 270 ? (
              <span>
                <span id={isbn + "_dots"}>...</span>
                <span
                  id={isbn + "_more"}
                  className='more'
                  dangerouslySetInnerHTML={{
                    __html: flapCopy?.slice(270, flapCopy?.length),
                  }}
                />
                <span
                  onClick={() => myFunction(isbn)}
                  id={isbn + "_myBtn"}
                  className='more-link'
                >
                  Read More
                </span>
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          <div className='no-flap-copy'>
            Information about title is not available
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(TitleRow);
