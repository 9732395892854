import { useState } from "react";
import { Icon, Popup, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import useCart from "../../../components/hc/useCart";
import { NumericFormat } from "react-number-format";
import PRHButton from "../../../shared-react-components/elements/prhbutton";
import LazyImage from "../../../utils/LazyImage";
import PropTypes from "prop-types";
import {
  focDateDifference,
  formatDate,
  formatDateMMDDYYYY,
  formatDateMonthYear,
  isFocCartExpired,
  isGeneralCart,
  expiredFocOffset,
} from "../../../utils/utilities";
import { useHistory } from "react-router";
import ConfirmationStatus from "../../../components/common/ConfirmationStatus";
import DialogModal from "../../../components/modals/DialogModal";
import ExpiredFocBanner from "./ExpiredFocBanner";
import { TOOLTIPS } from "../../../utils/const";

export default function CartView(props) {
  const { cartName, cart, shipToAccountNo, removeCarts, moveFocToGeneral } =
    props;
  const cartProvider = useCart();
  const history = useHistory();
  const [expanded, setExpanded] = useState(true);
  const cartTotal = cartProvider.getCartTotal(cart, true);
  const focDiff = focDateDifference(cartName);
  const FOCArray = cartName.split("-");

  const tooltipFOC = FOCArray[1] + "/" + FOCArray[2] + "/" + FOCArray[0];

  var FOCTooltipMessage = "";

  if (focDiff >= 0) {
    FOCTooltipMessage =
      "These items must be ordered by " +
      tooltipFOC +
      " at 11:59PM EST to guarantee quantities.";
  } else {
    FOCTooltipMessage =
      "The FOC date for these items have passed. Items can still be ordered, but quantities will no longer be guaranteed.";
  }

  const coverHover = (item) => {
    return (
      <div className='d-flex flex-row'>
        <LazyImage
          className='cp-cover'
          width={112}
          isVariant={item.isVariant}
          variantCount={item.variantCount}
          boxShadow={true}
          src={`https://images.penguinrandomhouse.com/cover/${item.isbn}`}
        />

        <div className='ml-2 d-flex flex-column'>
          <div className='fort-bold fs-13px' style={{ lineHeight: "18px" }}>
            <Link to={`/titleinfo/${item.isbn}`}>{item.title}</Link>
          </div>
          <div className='fort-book fs-13px ' style={{ lineHeight: "18px" }}>
            By {item.author}
          </div>
          <div className='fort-book fs-13px ' style={{ lineHeight: "18px" }}>
            {item.divisionName}
          </div>
          <div className='fort-book fs-13px' style={{ lineHeight: "18px" }}>
            {item.isbn}
          </div>
          <div className='fort-book fs-13px ' style={{ lineHeight: "18px" }}>
            <span className='mr-1'>{item.formatName}</span>|
            <span className='ml-1'>{formatDate(item.onsale, false)}</span>
          </div>
          <div className='fort-bold fs-13px' style={{ lineHeight: "18px" }}>
            <NumericFormat
              className=''
              value={item.priceSingle}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              prefix={"$"}
            />{" "}
            {item.currency}
          </div>
          <div className='fort-bold fs-13px' style={{ lineHeight: "18px" }}>
            Quantity:{" "}
            <NumericFormat
              className='fort-bold'
              value={item.quantity}
              thousandSeparator={true}
              decimalScale={0}
              displayType={"text"}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='cv-container d-flex flex-column mb-6'>
      <div
        className={`cv-header d-flex pl-2 pr-2 pt-2 pb-2 align-items-center ${
          !!!expanded ? "cv-detail-closed" : ""
        }`}
      >
        <Grid container>
          <Grid.Row className='align-items-center'>
            <Grid.Column
              mobile={8}
              tablet={8}
              computer={8}
              largeScreen={8}
              widescreen={8}
              className='d-flex flex-row align-items-center'
            >
              <Icon
                className='text-gray-500 cv-chevron pr-1 mr-2'
                name={`chevron ${expanded ? "down" : "right"}`}
                onClick={(e) => {
                  setExpanded(!expanded);
                }}
              />
              <Grid container>
                <Grid.Row className='align-items-center h-100'>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={9}
                    largeScreen={8}
                    widescreen={8}
                    className='d-flex align-items-center'
                  >
                    {isGeneralCart(cartName) ? (
                      <div className=''>
                        <div className='fort-bold fs-16px'>
                          General Cart{" "}
                          <Popup
                            className='ml-1'
                            content={
                              <div className='d-flex flex-column'>
                                {TOOLTIPS["generalCart"]}{" "}
                                <a
                                  href='https://selfservice-help.penguinrandomhouse.biz/managing-direct-market-foc-carts/'
                                  rel='noreferrer'
                                  target='_blank'
                                >
                                  Learn more about the ordering process
                                </a>
                              </div>
                            }
                            on='click'
                            position='top center'
                            offset={[-5, 5]}
                            trigger={
                              <Icon
                                name='question circle outline'
                                className='sb_question_icon'
                              />
                            }
                          />
                        </div>
                      </div>
                    ) : (
                      <div className=''>
                        {cart.catalogDate ? (
                          <div className='text-blue fort-medium fs-12px'>
                            <Link to={`/catalog/${cart?.catalogDate}`}>
                              Catalog: {formatDateMonthYear(cart?.catalogDate)}
                            </Link>
                          </div>
                        ) : (
                          <div />
                        )}
                        <div className='fort-bold'>
                          FOC {formatDateMMDDYYYY(cartName, "/")}
                          {isFocCartExpired(cartName)
                            ? ""
                            : cart.confirmationStatus === "CONFIRMED"
                            ? " (Confirmed)"
                            : cart.confirmationStatus === "PENDING_UPDATE"
                            ? " (Updated)"
                            : " "}
                        </div>
                      </div>
                    )}
                  </Grid.Column>
                  {isGeneralCart(cartName) ? null : (
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={7}
                      largeScreen={8}
                      widescreen={8}
                      className='d-flex justify-content-start align-items-center pt-2 pb-1  pt-lg-0 pb-lg-0'
                    >
                      <ConfirmationStatus
                        confirmationStatus={cart?.confirmationStatus}
                        hidePast={true}
                        focDate={cartName}
                      />
                      {/* <FOCStatus hidePast={true} focDate={cartName} /> */}
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column
              mobile={8}
              tablet={8}
              computer={8}
              largeScreen={8}
              widescreen={8}
            >
              <Grid container>
                <Grid.Row className='align-items-center'>
                  <Grid.Column
                    mobile={16}
                    tablet={9}
                    computer={9}
                    largeScreen={9}
                    widescreen={10}
                    className='d-flex justify-content-end align-items-center mt-1 mt-sm-0 pl-1 pl-sm-0'
                  >
                    <div className='d-flex flex-row justify-content-end'>
                      <div className='d-flex flex-column ml-3'>
                        <div className='fs-12px text-gray-700'>Items</div>
                        <div className='fort-bold fs-12px text-gray-900'>
                          {cart.items.length}
                        </div>
                      </div>
                      <div className='d-flex flex-column ml-3'>
                        <div className='fs-12px text-gray-700'>Units</div>
                        <div className='fort-bold fs-12px text-gray-900'>
                          {cartTotal.unitCount}
                        </div>
                      </div>
                      <div className='d-flex flex-column ml-3'>
                        <div className='fs-12px text-gray-700'>Subtotal</div>
                        <NumericFormat
                          className='fort-bold fs-12px text-gray-900'
                          value={cartTotal.total ? cartTotal.total : 0}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          prefix={"$"}
                        />
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={7}
                    computer={7}
                    largeScreen={7}
                    widescreen={6}
                    className={`d-flex justify-content-end pt-2 pb-2 ${
                      isGeneralCart(cartName) ? "pt-md-0" : "pt-md-0"
                    } pt-lg-0 mt-lg-0 pb-md-0`}
                  >
                    <PRHButton
                      // disabled={cart.items.length === 0}
                      size='medium'
                      bold
                      borderColorOverride='blue'
                      textColorOverride='white'
                      textColorHoverOverride='blue'
                      backgroundColorOverride='blue'
                      backgroundHoverColorOverride='white'
                      iconColorOverride={
                        cart.items.length === 0 ? "white" : "white"
                      }
                      iconColorHoverOverride='blue'
                      icon='arrow right'
                      iconright={true}
                      onClick={(e) => {
                        cartProvider.clearCart();
                        history.push({
                          pathname: "/cart",
                          state: { focDate: cartName, shipTo: shipToAccountNo },
                        });
                      }}
                      className=' mt-0 mt-mobile-2'
                      link={`/cart/${shipToAccountNo}/${cartName}`}
                    >
                      View Cart
                    </PRHButton>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      {focDiff < 0 ? (
        <ExpiredFocBanner
          foc={cartName}
          className={`${expanded ? "" : "cv-collapsed"}`}
          removeCarts={removeCarts}
          items={cart.items}
          moveFocToGeneral={moveFocToGeneral}
        />
      ) : null}
      <div
        className={`cv-detail pl-3 pr-3 pt-2 pb-2 ${
          expanded ? "d-flex flex-column" : "d-none"
        }`}
      >
        {cart.items.length === 0 ? (
          <div className='mt-2 mb-2'>Your Cart is empty.</div>
        ) : (
          <div className='d-flex' style={{ flexWrap: "wrap" }}>
            {cart.items.map((item, i) => (
              <div
                key={i}
                className='d-flex flex-column  justify-content-end align-items-center mr-2 mt-2'
              >
                <div>
                  <Popup
                    size='huge'
                    className='cop-cover-hover ml-1'
                    content={coverHover(item)}
                    on='hover'
                    hoverable={true}
                    position='top left'
                    offset={[-17, 10]}
                    trigger={
                      <LazyImage
                        className='cp-cover'
                        width={34}
                        isSmallThumbNail={true}
                        // isVariant={item.isVariant}
                        isVariant={item.isVariant}
                        boxShadow={true}
                        src={`https://images.penguinrandomhouse.com/cover/${item.isbn}`}
                      />
                    }
                  />
                </div>
                <div className='text-gray-900 fs-12px'>{item.quantity}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

CartView.propTypes = {
  cartName: PropTypes.string.isRequired,
  shipToAccountNo: PropTypes.string,
  cart: PropTypes.object.isRequired,
};

CartView.defaultProps = {};
