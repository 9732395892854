import React from "react";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import useCart from "../../components/hc/useCart";
import { formatDateMonthYear } from "../../utils/utilities";
import { useHistory, Link } from "react-router";
import { useMediaQuery } from "react-responsive";
import { Divider } from "../../components/elements/elements";
import { Icon } from "semantic-ui-react";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";

const Catalogs = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const cart = useCart();
  const history = useHistory();

  // console.log('cart', cart);

  if (
    (!isMobile && !cart.activeCatalogs) ||
    cart.activeCatalogs?.length === 0
  ) {
    return <></>;
  }

  return (
    <div
      className={`pt-3px d-flex justify-content-between catalogs-homepage__emulated-flex-gap ${
        cart.activeCatalogs?.length > 3 ? "flex-wrap" : ""
      } ${
        isMobile
          ? "mobile-catalogs-container border-gray-300 border-radius-3px p-2 pb-3"
          : ""
      }`}
    >
      {isMobile && (
        <div className='catalogs-box-title'>
          <p
            className={`mb-0 fort-light text-gray-900 ${
              isMobile ? "fs-20px" : "fs-24px"
            }`}
          >
            New Catalogs
          </p>
          <Divider tight className='mt-2 mb-3' />
        </div>
      )}
      {isMobile &&
        (!cart.activeCatalogs || cart.activeCatalogs?.length === 0) && (
          <div className='d-flex justify-content-center flex-column align-items-center w-100'>
            <div className='no-content-icon-holder-circle-outline'>
              <Icon name='info' className='fs-16px' />
            </div>
            <br />
            <div className='fs-16px'>You don't have any recent catalogs.</div>
          </div>
        )}
      {cart.activeCatalogs?.map((obj, i, array) => {
        const viewCatalogButton = (
          <div
            className={`d-flex justify-content-xlcenter justify-content-start ${
              array.length < 3 ? "homepage-catalog-icon-button-offset" : ""
            }`}
          >
            <PRHButton
              bold
              borderColorOverride={"transparent"}
              iconColorOverride='white'
              textColorOverride='white'
              backgroundHoverColorOverride='light-blue'
              borderColorHoverOverride='light-blue'
              backgroundColorOverride='blue'
              iconright
              icon='arrow right'
              size='medium'
              className='fs-2 lh-19 sign-in-button lsp--015'
              textClass='px-0 mx-0 mt--1px'
              link={`/catalog/${obj.val}`}
              onClick={() => {
                history.push({
                  pathname: `/catalog/${obj.val}`,
                });
              }}
            >
              View Catalog
            </PRHButton>
          </div>
        );

        let marginClass;
        if (array.length === 2) {
          marginClass = i === 0 ? "mr-2" : "ml-2";
        }
        if (array.length > 2) {
          marginClass = "";
          if (i === 0) {
            marginClass = "";
          }
          if (i === 2) {
            marginClass = "";
          }
        }

        return (
          <React.Fragment key={`${obj.val}_${i}`}>
            <div
              key={i}
              className={`catalogs-homepage${
                cart.activeCatalogs?.length === 4 ? "-half" : ""
              } ${isMobile ? "mb-0" : "mb-4"} `}
            >
              <div
                className={`${marginClass} d-flex ${
                  isMobile
                    ? "flex-row justify-content-between pb-0 pt-0"
                    : "border-gray-300 bg-gray-100 px-2 px-lg-3  pt-3 border-radius-3px h-100 flex-flow-column justify-content-between pb-3"
                }`}
                onClick={() =>
                  isMobile &&
                  history.push({
                    pathname: `/catalog/${obj.val}`,
                  })
                }
              >
                <div
                  className={`${
                    isMobile
                      ? `d-flex flex-row justify-content-between`
                      : array.length < 3 || array.length === 4
                      ? `d-flex ${
                          array.length === 1 ? "align-items-center" : ""
                        }`
                      : "d-block d-lg-flex"
                  }`}
                >
                  <p
                    className={`m-0 text-center text-xl-left lh-0 ${
                      array.length < 3 ? "" : "mr-1"
                    }`}
                  >
                    <i
                      className={`d-inline-block homepage-catalog-icon ${
                        isMobile
                          ? "mb-0"
                          : array.length > 2
                          ? "mb-2 mr-md-2 mr-xl-0"
                          : "mr-2"
                      }`}
                    />
                  </p>
                  <p
                    className={`fort-medium fs-16px lh-18px lsp--01  ${
                      isMobile
                        ? "mb-0"
                        : array.length < 3
                        ? array.length === 1
                          ? "mb-0"
                          : "mb-2 mb-xl-3"
                        : "mb-4 mb-xl-3"
                    }`}
                  >
                    Catalog: {formatDateMonthYear(obj.val)}
                  </p>
                  {!isMobile && cart.activeCatalogs?.length === 1 && (
                    <div className='ml-auto'>{viewCatalogButton}</div>
                  )}
                </div>
                {!isMobile &&
                  cart.activeCatalogs?.length > 1 &&
                  viewCatalogButton}
                {isMobile && (
                  <div
                    onClick={() =>
                      history.push({
                        pathname: `/catalog/${obj.val}`,
                      })
                    }
                  >
                    <Icon name='angle right' className='fs-16px' />
                  </div>
                )}
              </div>
            </div>
            {isMobile && i < cart.activeCatalogs?.length - 1 && (
              <Divider tight className='mt-3 mb-3' />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Catalogs;
