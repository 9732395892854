import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Popup, Icon, Image } from "semantic-ui-react";
import "../TitleMetadata.scss";
import { NumericFormat } from "react-number-format";
import useCart from "../../../components/hc/useCart";
import useApp from "../../../components/hc/useApp";
import useSecurity from "../../../components/hc/useSecurity";
import Invoice from "../../../assets/images/icon-order-16.svg";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../../utils/const";

function TitleHistorySummary(props) {
  // console.log("TitleHistorySummary", props);

  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const { selectedFormat, allFormats, selectedLocation, titleHistory } = props;
  const cart = useCart();
  const user = useSecurity();
  const app = useApp();
  const history = useHistory();

  if (!titleHistory?.summary) return <></>;

  if (titleHistory.summary.length === 0) return <></>;

  if (!selectedLocation) {
    return <></>;
  }
  // let shipTo = null;
  // if (cart.isUserPow()) {
  //   if (cart.shipTo >= 0) {
  //     shipTo = cart.getShipToAccount().accountNo;
  //   } else {
  //     return <></>;
  //   }
  // } else {
  //   shipTo = 'all';
  // }

  let ioq = titleHistory.summary.filter((oi) => oi.shipTo === selectedLocation);
  // console.log('ioq', selectedLocation, titleHistory, ioq);

  // if (ioq.length !== 1) {
  //   return <></>;
  // }
  let qty = 0;
  if (ioq.length > 0) {
    let isbnMeta = ioq[0].isbnQty.filter(
      (iq) => iq.isbn === selectedFormat.isbnStr
    );

    qty = isbnMeta.length > 0 ? isbnMeta[0].qty : 0;
  }

  if (!isMobile) {
    return (
      <div className='w-100'>
        {titleHistory && (
          <div className='tm-poi-box fs-12px lh-12px p-2 d-flex fort-book'>
            <div className='w-20 d-flex'>
              <img className='mr-1' src={Invoice} />
              <span className='fort-bold mr-1'>Previously Ordered</span>{" "}
            </div>
            <div className=''>
              {cart.isUserPow() ? (
                <span className='text-gray-700'>This cover: </span>
              ) : (
                <span className='text-gray-700'>This format: </span>
              )}
              <NumericFormat
                className='text-black fort-medium'
                value={qty}
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
                displayType={"text"}
              />{" "}
              <span className='text-black fort-medium'>units</span>
              <span className='ml-1 mr-1'>|</span>
              {cart.isUserPow() ? (
                <span className='text-gray-700'>All covers: </span>
              ) : (
                <span className='text-gray-700'>All formats: </span>
              )}
              <NumericFormat
                className='text-black fort-medium'
                value={ioq.length > 0 ? ioq[0].allIsbnsQty : 0}
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
                displayType={"text"}
              />{" "}
              <span className='text-black fort-medium'>units</span>
            </div>
            {/* <Link to={`/orders`}>View orders</Link> */}

            {ioq.length > 0 && qty > 0 && (
              <a
                href='#'
                className='fort-medium ml-auto'
                onClick={(e) => {
                  e.preventDefault();
                  if (cart.isUserPow()) {
                    history.push({
                      pathname: "/orders",
                      state: {
                        searchInput: selectedFormat.isbnStr,
                        //searchInput: '',
                        dayFilter: 550,
                        shipTo: cart.getShipToAccount().accountNo,
                        accountFilter: cart.getShipToAccount().accountNo,
                        hasInput: true,
                      },
                    });
                  } else {
                    history.push({
                      pathname: "/orders",
                      state: {
                        //searchInput: '', //BIZ2-4195
                        dayFilter: 550,
                        searchInput: selectedFormat.isbnStr,
                        shipTo:
                          selectedLocation === "all" ? false : selectedLocation, //cart.getShipToAccount().accountNo,
                        accountFilter:
                          selectedLocation === "all" ? false : selectedLocation, //cart.getShipToAccount().accountNo,
                        hasInput: true,
                      },
                    });
                  }
                }}
              >
                Go to Orders
              </a>
            )}
            {user.isAppCompCopy() ? null : (
              <Popup
                className='cp-help-tooltip'
                content='Previously ordered quantity does not include Canceled items. Reshipped quantities are included.'
                on='hover'
                position='right center'
                offset={[0, 5]}
                trigger={
                  <Icon
                    name='question circle'
                    className='sb_question_icon ml-1'
                    style={{ position: "relative", top: "1px" }}
                  />
                }
              />
            )}
          </div>
        )}
      </div>
    );
  } else if (isMobile) {
    return (
      <div className='w-100'>
        {titleHistory && (
          <div className='tm-poi-box fs-12px lh-12px p-2 d-flex fort-book flex-column'>
            <div className='d-flex justify-self-between'>
              <div className='w-50 d-flex'>
                <img className='mr-1' src={Invoice} />
                <span className='fort-bold mr-1'>Previously Ordered</span>{" "}
              </div>

              {ioq.length > 0 && qty > 0 && (
                <a
                  href='#'
                  className='fort-medium ml-auto'
                  onClick={(e) => {
                    e.preventDefault();
                    if (cart.isUserPow()) {
                      history.push({
                        pathname: "/orders",
                        state: {
                          searchInput: selectedFormat.isbnStr,
                          //searchInput: '',
                          dayFilter: 550,
                          shipTo: cart.getShipToAccount().accountNo,
                          accountFilter: cart.getShipToAccount().accountNo,
                          hasInput: true,
                        },
                      });
                    } else {
                      history.push({
                        pathname: "/orders",
                        state: {
                          //searchInput: '', //BIZ2-4195
                          dayFilter: 550,
                          searchInput: selectedFormat.isbnStr,
                          shipTo:
                            selectedLocation === "all"
                              ? false
                              : selectedLocation, //cart.getShipToAccount().accountNo,
                          accountFilter:
                            selectedLocation === "all"
                              ? false
                              : selectedLocation, //cart.getShipToAccount().accountNo,
                          hasInput: true,
                        },
                      });
                    }
                  }}
                >
                  Go to Orders
                </a>
              )}
              {user.isAppCompCopy() ? null : (
                <Popup
                  className='cp-help-tooltip'
                  content='Previously ordered quantity does not include Canceled items. Reshipped quantities are included.'
                  on='hover'
                  position='right center'
                  offset={[0, 5]}
                  trigger={
                    <Icon
                      name='question circle'
                      className='sb_question_icon ml-1'
                      style={{ position: "relative", top: "1px" }}
                    />
                  }
                />
              )}
            </div>
            <div className=''>
              {cart.isUserPow() ? (
                <span className='text-gray-700'>This cover: </span>
              ) : (
                <span className='text-gray-700'>This format: </span>
              )}
              <NumericFormat
                className='text-black fort-medium'
                value={qty}
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
                displayType={"text"}
              />{" "}
              <span className='text-black fort-medium'>units</span>
              <span className='ml-1 mr-1'>|</span>
              {cart.isUserPow() ? (
                <span className='text-gray-700'>All covers: </span>
              ) : (
                <span className='text-gray-700'>All formats: </span>
              )}
              <NumericFormat
                className='text-black fort-medium'
                value={ioq.length > 0 ? ioq[0].allIsbnsQty : 0}
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
                displayType={"text"}
              />{" "}
              <span className='text-black fort-medium'>units</span>
            </div>
            {/* <Link to={`/orders`}>View orders</Link> */}
          </div>
        )}
      </div>
    );
  }
}

TitleHistorySummary.propTypes = {};

export default TitleHistorySummary;
