import { useState } from 'react';
import PropTypes from 'prop-types';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import {
  dateDifference,
  dateDifferenceBetween,
  formatDateMMDDYYYY,
  formatDateAsMonth,
  dateIsBetween,
} from '../../utils/utilities';
import { Icon } from 'semantic-ui-react';
import useCart from '../hc/useCart';
import { useHistory } from 'react-router-dom';

import './NotificationBanner.scss';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_SCREEN_WIDTH } from '../../utils/const';

function NotificationBanner(props) {
  const {
    msg,
    button: optionButton,
    buttonText,
    focDate,
    shipTo,
    catalogAvailabilityDate,
    currentMonthCatalogAvailabilityDate,
    showNotifications,
    currentPage,
    estimateDueDate,
    setShowNotifications,
    catalogs,
    icon,
    iconColor,
    estimateCart = false,
  } = props;
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  // console.log('Notification', props);
  const cart = useCart();
  const history = useHistory();
  const [bannerOpen, setBannerOpen] = useState(true);
  const isLvl1 = cart?.customerInfo?.currentUser?.roleNo === 'W1';

  const viewCartButton = {
    size: 'medium',
    icon: 'arrow right',
    iconright: true,
    className: 'ml-auto align-self-center extra-wide',
    onClick: (e) => {
      cart.clearCart();
      history.push({
        pathname: '/cart',
        state: { focDate: focDate, shipTo: shipTo },
      });
    },
    link: `/cart/${shipTo}/${focDate}`,
  };

  const currentCatalog = catalogs && catalogs[0]?.val;
  const viewCatalogButton = {
    size: 'medium',
    bold: true,
    borderColorOverride: 'blue',
    textColorOverride: 'white',
    textColorHoverOverride: 'blue',
    backgroundColorOverride: 'blue',
    backgroundHoverColorOverride: 'white',
    iconColorOverride: 'white',
    iconColorHoverOverride: 'blue',
    icon: 'arrow right',
    iconright: true,
    className: 'ml-auto align-self-center extra-wide',
    onClick: (e) => {
      history.push({ pathname: `/catalog/${currentCatalog}` });
    },
  };

  const defaultButton = {
    size: 'medium',
    bold: true,
    borderColorOverride: 'blue',
    textColorOverride: 'white',
    textColorHoverOverride: 'blue',
    backgroundColorOverride: 'blue',
    backgroundHoverColorOverride: 'white',
    iconColorOverride: 'white',
    iconColorHoverOverride: 'blue',
    icon: 'arrow right',
    iconright: true,
    className: 'ml-auto align-self-center',
    onClick: (e) => {},
  };

  let buttonProps = {};

  if (isLvl1) {
    return <></>;
  }
  if (optionButton) {
    buttonProps = { ...defaultButton, optionButton };
  }
  if (focDate && shipTo && focDate !== '2099-12-31') {
    let nd = dateDifference(focDate);
    let workMsg = false;
    if (nd < 1 && nd > -1) {
      if (estimateCart?.confirmationStatus === 'CONFIRMED') return <></>;
      workMsg = (
        <>
          <div className="fort-book fs-14px lh-19">
            Your order is due today for titles with{' '}
            <span className="fort-bold">
              FOC {formatDateMMDDYYYY(focDate, '/')}
            </span>
            . Confirm your updated cart quantities now to guarantee inventory
            for your store.
          </div>
        </>
      );
    } else if (nd < 0) {
      workMsg = (
        <>
          <div className="fort-book fs-14px lh-19">
            Your cart{' '}
            <span className="fort-bold">
              "FOC {formatDateMMDDYYYY(focDate, '/')}"
            </span>{' '}
            has passed its FOC date. Please move or clear the items from this
            cart.
          </div>
        </>
      );
    } else if (nd > 0 && nd <= 3) {
      if (estimateCart?.confirmationStatus === 'CONFIRMED') return <></>;
      workMsg = (
        <>
          <div className="fort-book fs-14px lh-19">
            Your order is due in {nd}{' '}
            {parseInt(nd, 10) === 1 ? ' day' : ' days'} for titles with{' '}
            <span className="fort-bold">
              FOC {formatDateMMDDYYYY(focDate, '/')}
            </span>
            . Confirm your cart quantities soon to guarantee inventory for your
            store.
          </div>
        </>
      );
    }

    if (workMsg) {
      return (
        <div
          className={`notification-banner pl-3 pr-3 pt-2 pb-2 mb-4 ${
            !bannerOpen ? 'closed' : ''
          }`}
        >
          <div
            className={`d-flex flex-row ${
              isMobile ? 'justify-content-space-between' : 'align-items-center'
            }`}
          >
            {icon ? (
              <Icon
                name={icon}
                color={iconColor}
                className="mr-1 fs-20px align-self-start mt-lg-1"
                style={{ marginTop: '2px' }}
              />
            ) : null}
            <div className="mr-1 mw-62 mw-lg-67">{workMsg}</div>
            {!isMobile && (
              <PRHButton {...viewCartButton}>View FOC Cart</PRHButton>
            )}
            <PRHButton
              borderColorOverride={'transparent'}
              iconColorOverride={'gray-500'}
              iconOnlyBorderAlways={true}
              borderColorHoverOverride={'orange'}
              icon="close"
              onClick={(e) => setBannerOpen(false)}
              className={`ml-2 ${isMobile ? 'align-self-start' : ''}`}
              iconHolderStyle={{ marginLeft: '-4px' }}
            />
          </div>
          {isMobile && <PRHButton {...viewCartButton}>View FOC Cart</PRHButton>}
        </div>
      );
    }
    return null;
  } else if (
    catalogAvailabilityDate &&
    showNotifications[currentPage] === true &&
    bannerOpen
  ) {
    let currentDateIsBetween = dateIsBetween(
      currentMonthCatalogAvailabilityDate,
      estimateDueDate
    );
    if (currentDateIsBetween) {
      return (
        <div
          className={`notification-banner catalog pl-1 pr-1 pt-1 pb-1 mb-4 ${
            !bannerOpen ? 'closed' : ''
          }`}
        >
          <div className="d-flex flex-row align-items-center">
            {icon ? (
              <Icon
                name={'exclamation circle'}
                color={iconColor}
                className="mr-2 fs-16px"
                style={{ marginTop: '1px' }}
              />
            ) : null}
            <div className="mr-1">
              <b>
                Create your initial orders / estimates for the{' '}
                {formatDateAsMonth(currentCatalog)} catalog by adding items to
                your FOC Carts by {formatDateMMDDYYYY(estimateDueDate, '/')}
              </b>
              .<br /> You only need to add items to your cart to submit an
              initial order. Carts can be checked out at any point prior to the
              FOC.
              <br />
              <br />
              <a
                href="https://selfservice-help.penguinrandomhouse.biz/creating-direct-market-initial-orders-and-submitting-final-orders/ "
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>{' '}
              to learn more about the ordering process.
            </div>
            <Icon
              className="text-gray-500 cursor-pointer fs-20px ml-auto align-self-center "
              name="close"
              onClick={() => {
                setBannerOpen(false);
              }}
            />
          </div>
        </div>
      );
    } else return null;
  } else if (msg) {
    return optionButton ? (
      <div className="notification-banner p-3 mb-3">
        <div className="d-flex flex-row align-self-center">
          {icon ? (
            <Icon name={icon} color={iconColor} className="mr-1 fs-18px" />
          ) : null}
          <div>{msg}</div>
          <PRHButton {...buttonProps}>{buttonText}</PRHButton>
        </div>
      </div>
    ) : (
      <div className="notification-banner-mini p-3 mb-4">
        {icon ? (
          <Icon name={icon} color={iconColor} className="mr-1 fs-18px" />
        ) : null}
        <div className="d-flex flex-row">{msg}</div>
      </div>
    );
  } else {
    return null;
  }
}

export default NotificationBanner;

NotificationBanner.defaultProps = {
  msg: '',
  button: false,
  buttonText: '',
  icon: 'clock outline',
  iconColor: 'orange',
  focDate: false,
  shipTo: false,
  catalogAvailabilityDate: false,
  catalogs: false,
};
NotificationBanner.propTypes = {
  msg: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  buttonText: PropTypes.string,
  focDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  shipTo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  catalogAvailabilityDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  catalogs: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  iconColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
