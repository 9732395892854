import React from 'react';
import {formatDateMMDDYYYY, formatDateMonthYear} from '../../../utils/utilities';
import { useHistory } from 'react-router';
import { Icon } from 'semantic-ui-react';
import './ArchiveCatalogs.scss';
import {Divider} from "../../../components/elements/elements";

const renderFocList = (archive, amountToRender) => {
  const focsToShow = archive.focs.slice(0, amountToRender);
  const remainingFocsListLength = archive.focs.length - amountToRender;
  return {
    focsToShow,
    remainingFocsListLength
  }
}

const ArchiveCatalogsMobile = ({ archives }) => {
  const history = useHistory();

  const groupedArchives = archives && [...archives.current, ...archives.past];

  const goToCatalog = (catalogDate) => history.push(`/catalog/${catalogDate}`);

  return groupedArchives ? groupedArchives?.map((archive) => {
    const focList = renderFocList(archive, 7);
    return (
      <div
        key={archive.catalogDate}
        className="pt-3px d-flex justify-content-between catalogs-homepage__emulated-flex-gap mobile-catalogs-container border-gray-300 border-radius-3px p-2 pb-3 mb-2"
      >
        <div className="catalogs-box-title" onClick={() => goToCatalog(archive.catalogDate)}>
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-row">
              <p className="m-0 mr-1 text-center text-xl-left lh-0">
                <i className="d-inline-block homepage-catalog-icon mb-0" />
              </p>
              <p className={`fort-medium fs-16px lh-18px lsp--01 mb-0`}>
                Catalog: {formatDateMonthYear(archive.catalogDate)}
              </p>
            </div>
            <div onClick={() => goToCatalog(archive.catalogDate)}>
              <Icon name="angle right" className="fs-16px" />
            </div>
          </div>
          <Divider tight className="mt-2 mb-3" />
        </div>
        <div className="d-flex flex-wrap w-100">
          {focList.focsToShow?.map((foc, index) =>
            <div key={archive.catalogDate + '_' + index} className="d-flex w-50 justify-content-start mb-1">
              FOC {formatDateMMDDYYYY(foc, "/")}
            </div>
          )}
          {focList.remainingFocsListLength > 0 &&
            <div className="fort-book-italic">+ {focList.remainingFocsListLength} more {focList.remainingFocsListLength > 1 ? 'FOCs' : 'FOC'}</div>
          }
        </div>
      </div>
    );
  }) : null;
};

export default ArchiveCatalogsMobile;
