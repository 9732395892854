import React, { useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import './InvoiceDetailMobile.scss';
import { Divider } from '../../components/elements/elements';
import { InvoiceDetailPopup } from './InvoiceDetailPopup';

export const InvoiceDetailMobile = (props) => {
  const { purchaseOrder, salesOrder } = props;
  //console.log('---------- props', props);
  const [isOpen, setIsOpen] = useState(undefined);

  return (
    <>
      <div className="mobile-catalogs-container border-gray-300 border-radius-3px p-2 pb-3 mt-2">
        <div className="catalogs-box-title" onClick={() => setIsOpen(true)}>
          <div className="d-flex flex-direction-row justify-content-space-between">
            <div className="d-flex flex-direction-row align-items-center">
              <p className="mb-0 fort-book text-gray-900 fs-16px">
                {props.items?.length}{' '}
                {props.items?.length === 1 ? 'Item' : 'Items'}
              </p>
            </div>
            <Icon name="angle right" className="fs-24px" />
          </div>
          {/* <Divider tight className="mt-2 mb-3" /> */}
        </div>
        <div>
          <div className="d-flex flex-row justify-content-between pb-0 pt-0">
            <Grid>
              <Grid.Row className="mb-1">
                <Grid.Column width={8}>Purchase Order:</Grid.Column>
                <Grid.Column width={8} className="d-flex justify-content-end">
                  {purchaseOrder}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>Sales Order:</Grid.Column>
                <Grid.Column width={8} className="d-flex justify-content-end">
                  {salesOrder}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </div>
      <InvoiceDetailPopup
        onClose={() => setIsOpen(false)}
        items={props.items.sort((a, b) => (a.bookTitle > b.bookTitle ? 1 : -1))}
        history={props.history}
        menuVisible={isOpen}
        purchaseOrder={purchaseOrder}
        salesOrder={salesOrder}
      />
    </>
  );
};
