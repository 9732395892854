import { cloneElement, useState, useEffect, isValidElement } from "react";
import { Header, Form, TextArea, Icon, Popup, Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import ModifiedConfirmedCartModal from "./ModifyConfirmedCartModal";
import useApp from "../hc/useApp";
import useCart from "../hc/useCart";
import "./AddISBNModal.scss";
import {
  formatDateMMDDYYYY,
  isFocCartExpired,
  isGeneralCart,
  isPastOnSaleDate,
} from "../../utils/utilities";
import { useCleanISBNsMutation } from "../../api/ecomApi";
import _ from "underscore";

export const AddUPCISBNModal = ({
  refresh = false,
  trigger,
  listName,
  listID,
  addISBNsToList,
  openState,
  className,
  backgroundColorOverride,
  onAddClick = false,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [validIsbnList, setValidIsbnList] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [helpScreen, setHelpScreen] = useState(false);
  const [confirmedCartCount, setConfirmedCartCount] = useState(0);
  const [totalValidTitles, setTotalValidTitles] = useState(0);
  const [modifyConfirmedCartModalOpen, setModifyConfirmedCartModalOpen] =
    useState(false);
  const cart = useCart();
  const app = useApp();
  const [performCleanIsbns, cleanIsbnResult] = useCleanISBNsMutation();

  const isAnyValid = () => {
    if (!isbnList) return false;
    if (!validIsbnList) return false;
    if (validIsbnList.length === 0) return false;
    const nv = validIsbnList.filter((il) => il.available);
    // console.log('nv', nv);
    if (nv.length === 0) return false;
    return true;
  };
  let realTrigger = trigger;
  if (!trigger) {
    realTrigger = (
      <PRHButton
        size='small'
        disabled={disabled}
        className={className}
        style={{ minWidth: "168px", height: "38px" }}
        backgroundColorOverride={backgroundColorOverride}
        onClick={() => {
          setOpen(true);
          if (openState) {
            openState(true);
          }
        }}
        icon='plus'
      >
        Add UPCs/ISBNs
      </PRHButton>
    );
  } else {
    realTrigger = cloneElement(realTrigger, {
      onClick: () => setOpen(true),
    });
  }

  const [isbnList, setIsbnList] = useState("");
  const resetState = () => {
    setIsbnList("");
    setConfirmedCartCount(0);
    setValidIsbnList(false);
    setHelpScreen(false);
  };

  const checkIsbnAction = {
    label: "Check ISBNs",
    color: "green",
    borderColorOverride: "blue",
    textColorOverride: "white",
    textColorHoverOverride: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "white",
    iconColorHoverOverride: "blue",
    iconColorOverride: !isbnList ? "grey" : "white",
    icon: "check",
    className: "ml-auto",
    disabled: !isbnList,
    preAction: () => cleanISBNListInternal(),
    action: () => {
      resetState();
      return { proceed: true };
    },
  };

  const backFromHelp = {
    label: "Back to enter ISBNs",
    color: "orange",
    icon: "arrow left",
    className: "mr-auto",
    preAction: () => {
      setHelpScreen(false);
      return { proceed: false };
    },
    action: () => {
      return { proceed: true };
    },
  };

  const backToCheckIsbn = {
    label: "Back",
    color: "orange",
    icon: "arrow left",
    disabled: !isbnList,
    className: "mr-auto",
    preAction: () => {
      setValidIsbnList(false);
      return { proceed: false };
    },
    action: () => {
      resetState();
      return { proceed: true };
    },
  };
  async function addIsbnsToCart(closeDialog = false) {
    // console.log('add isbns to cart logic', validIsbnList);
    let carts = [];

    validIsbnList.forEach((item) => {
      // console.log('vi', item);

      // if (isFocCart()) {
      //   ci.items.forEach((item) => (item.cartType = 'FOC'));
      //   Cart.addEstimateItems(ci);
      // } else {
      //   ci.foc = '2099-12-31';
      //   ci.items.forEach((item) => (item.cartType = 'GEN'));
      //   if (isFocCartExpired(foc) && !isPastOnSaleDate(onSaleDate))
      //     ci.items.forEach((item) => (item.cartType = 'FGN'));
      //   Cart.addEstimateItems(ci);
      // }

      if (item.available) {
        let cartType = "FOC";
        if (isGeneralCart(item.foc)) {
          cartType = "GEN";
        } else {
          if (isFocCartExpired(item.foc)) cartType = "FGN";
        }
        carts.push({
          foc:
            isGeneralCart(item.foc) || cartType === "FGN"
              ? "2099-12-31"
              : item.foc,
          shipTo: cart.getShipToAccount().accountNo,
          items: [{ isbn: item.isbn, quantity: item.quantity, cartType }],
        });
      }
    });

    // console.log('passing', titles);
    const x = await cart.addEstimateItems({
      carts,
      supressDirty: closeDialog ? false : true,
      supressAddModal: true,
      simulate: false,
    });

    // console.log('x', x);

    if (onAddClick) onAddClick();
    // if (r.status === 200) {
    // if (closeDialog) {
    setOpen(false);
    return { proceed: true };
    // } else {
    //   return { proceed: false };
    // }
    // } else {
    //   setErrorMsg('An error occured updating your cart.');
    //   return { proceed: false };
    // }
  }
  async function cleanISBNList() {
    // console.log('cleanISBNList');
    const r = await performCleanIsbns({
      data: isbnList,
      sapAccountNo: cart.ccSapAccountNo,
    });
    // console.log('r', r);
    if (r.data?.status === "OK") {
      if (cart.estimateCarts.length > 0) {
        let cc = 0;
        // console.log("compare", r.data.data.items, cart.estimateCarts);
        r.data.data.items.forEach((i) => {
          cart.estimateCarts.forEach((estimate) => {
            if (
              estimate.foc === i.foc &&
              estimate.confirmationStatus === "CONFIRMED"
            ) {
              cc++;
            }
          });
        });
        setConfirmedCartCount(cc);
      }

      const ta = _.uniq(r.data.data.items, "isbn");
      setTotalValidTitles(ta.length);
      setValidIsbnList(r.data.data.items);
    } else {
      setValidIsbnList(false);
    }
    // const { titles } = r.data.search_results;
    // addISBNsToList(titles);
    // if (r.data.status_code === -1) {
    //   return {
    //     proceed: false,
    //     message: { type: 'error', label: r.data.error_msg },
    //   };
    //   // setErrorMessage(r.data.error_msg);
    // }
    // if (refresh) {
    //   // await refresh();
    // }
    // setValidIsbnList(true);

    return { proceed: false };
  }

  const cleanISBNListInternal = () => cleanISBNList();

  useEffect(() => {
    if (!open) {
      resetState();
    }
    if (openState) {
      openState(open);
    }
  }, [open]);

  let totalValidISBNs = 0;

  const addIsbnsToCartAction = (totalValidISBNsProp) => {
    return {
      label: `Add ${totalValidISBNsProp} ISBNs to Cart`,
      borderColorOverride: "blue",
      textColorOverride: "white",
      textColorHoverOverride: "blue",
      backgroundColorOverride: "blue",
      backgroundHoverColorOverride: "white",
      iconColorOverride: !isAnyValid() ? "grey" : "white",
      iconColorHoverOverride: "blue",
      color: "orange",
      icon: "plus",
      // className: 'mr-auto ml-auto',
      disabled: !isAnyValid(),
      preAction: () => {
        if (confirmedCartCount > 0) {
          setModifyConfirmedCartModalOpen(true);
          return { proceed: false };
        } else {
          return addIsbnsToCart(false);
        }
      },
      action: () => {
        resetState();
        return { proceed: true };
      },
    };
  };

  const modifyConfirmedCart = () => {
    // this.props.dirtyHandler.setLoadingTakeoverConfig({
    //   text: 'Submitting order . . .',
    //   longLoadText: 'Updating cart . . .',
    //   inverted: true,
    //   backgroundBlurLight: true,
    // });
    // this.props.dirtyHandler.setLoadingTakeover(true);
    addIsbnsToCart(true);
  };

  let actionList = [];
  if (helpScreen) {
    actionList.push(backFromHelp);
  } else if (validIsbnList === false) {
    actionList.push(checkIsbnAction);
  } else {
    validIsbnList.map((item) => {
      if (item.available) {
        totalValidISBNs++;
      }
    });
    actionList.push(backToCheckIsbn);
    actionList.push(addIsbnsToCartAction(totalValidISBNs));
  }
  return (
    <>
      <ModifiedConfirmedCartModal
        open={modifyConfirmedCartModalOpen}
        setOpen={setModifyConfirmedCartModalOpen}
        callback={modifyConfirmedCart}
        postCallback={(e) => setOpen(false)}
      ></ModifiedConfirmedCartModal>
      <DialogModal
        open={open}
        modalClassName='addupc-modal'
        setOpen={setOpen}
        contentPx={validIsbnList ? "px-0" : "px-4"}
        contentPt='pt-2'
        dialogOptions={{
          header: "ADD ISBNs",
          header: (
            <Header className='fs-12px fort-bold fs-2 lh-2 my-0 mr-5'>
              ADD UPCS/ISBNS TO CART
            </Header>
          ),
          actions: actionList,
        }}
        options={{
          size: "small",
          trigger: realTrigger,
        }}
      >
        {validIsbnList ? (
          <div>
            <div className='px-4 mb-2'>
              <span className='fort-bold'>
                {totalValidISBNs} of {totalValidTitles}
              </span>{" "}
              ISBNs are valid and can be added to your Carts
            </div>
            {errorMsg ? <div className='text-red'>{errorMsg}</div> : null}
            <div className='px-0 aim-isbn-table'>
              <Table celled padded>
                <Table.Header>
                  <Table.Row className='aim-isbn-header'>
                    <Table.HeaderCell>ISBN/UPC</Table.HeaderCell>
                    <Table.HeaderCell>TITLE</Table.HeaderCell>
                    <Table.HeaderCell>QTY</Table.HeaderCell>
                    <Table.HeaderCell>CART</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {validIsbnList.map((item, idx) => (
                    <Table.Row
                      textAlign='center'
                      key={idx}
                      className={`${
                        item.available === false ? "row-error" : ""
                      }`}
                    >
                      <Table.Cell style={{ width: "240px" }} textAlign='left'>
                        {item.available === false ? (
                          <div
                            style={{ marginRight: "5px" }}
                            className='aim-icon-holder invalid'
                          >
                            <Icon name='warning sign'></Icon>
                          </div>
                        ) : (
                          <div
                            style={{ marginRight: "10px" }}
                            className='aim-icon-holder valid'
                          >
                            <Icon name='check'></Icon>
                          </div>
                        )}

                        {item.isbn}
                      </Table.Cell>
                      <Table.Cell textAlign='left'>
                        <div className='text-gray-900 fort-medium'>
                          {item.title}
                        </div>
                        <div>{item.available ? null : item.status}</div>
                      </Table.Cell>
                      <Table.Cell textAlign='left'>
                        {item.quantity && item.quantity}
                      </Table.Cell>

                      <Table.Cell style={{ width: "155px" }} textAlign='left'>
                        {item.available
                          ? isGeneralCart(item.foc)
                            ? "General Cart"
                            : isFocCartExpired(item.foc)
                            ? "General Cart"
                            : `FOC ${formatDateMMDDYYYY(item.foc, "/")}`
                          : "-"}
                      </Table.Cell>
                    </Table.Row>
                  ))}

                  {/* <Table.Row className="text-red">
                <Table.Cell>
                  <div className="aim-icon-holder invalid">
                    <Icon name="warning sign"></Icon>
                  </div>
                  9780307956036
                </Table.Cell>
                <Table.Cell>Invalid ISBN</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row> */}
                </Table.Body>
              </Table>
            </div>
          </div>
        ) : (
          <>
            {helpScreen ? (
              <div>
                <div>
                  <div className='mb-2'>
                    You can import ISBN-13's. ISBNs can have dashes in them.
                  </div>
                  <div className='fort-bold mb-1'>
                    Copy and pasting from excel
                  </div>
                  <div className='mb-2'>
                    You can copy and paste a column of ISBNs, or to adjacent
                    columns of ISBNs and quantities
                  </div>
                  <div className='fort-bold mb-1'>
                    ISBNs without quantity can be separated by line breaks:
                  </div>
                  <div className='mb-1'>
                    Example 1 (ISBN separated by line breaks):
                    <div className='mr-1 mt-1'>ISBN</div>
                    <div className='mr-1'>ISBN</div>
                    <div className='mr-1 mb-1'>ISBN</div>
                  </div>
                </div>
                <div className='mb-1'>
                  Example 2 (ISBN with quantity separated by line breaks):
                  <div className='mr-1 mt-1'>ISBN,10</div>
                  <div className='mr-1'>ISBN,200</div>
                  <div className='mr-1 mb-1'>ISBN,4000</div>
                </div>
              </div>
            ) : (
              <>
                <Form>
                  <div className='mb-1 modal-content--note isbn-description-text'>
                    Enter or paste one or more ISBNs below. You may include
                    quantity per ISBN.{" "}
                  </div>
                  <TextArea
                    autoFocus
                    value={isbnList}
                    placeholder='Type or paste one or more ISBNs'
                    className={` aim-text-area fort-book mb-1`}
                    // ${
                    //   isbnList
                    //     ? 'modal-content--textarea has-text'
                    //     : 'modal-content--textarea no-text'
                    // } aim-text-area

                    rows={10}
                    onChange={(e) => setIsbnList(e.target.value)}
                  />
                </Form>
                <div>
                  <div
                    className='cursor-pointer text-blue hover-orange how-to-paste-button'
                    onClick={(e) => setHelpScreen(true)}
                  >
                    How do I paste in ISBNs?
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </DialogModal>
    </>
  );
};

AddUPCISBNModal.propTypes = {
  refresh: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  trigger: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  backgroundColorOverride: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

AddUPCISBNModal.defaultProps = {
  refresh: false,
  trigger: false,
  backgroundColorOverride: false,
};
