import { Component } from "react";
import { withRouter } from "react-router-dom";
import { formatDate } from "../../utils/utilities";

class BookDetailSnippet extends Component {
  render() {
    const { isbn, title, subtitle, formatName, onsale, author } =
      this.props.data;
    const imgURL = "https://images.penguinrandomhouse.com/cover/" + isbn;
    return (
      <div style={{ display: "flex" }}>
        <div className='coverImageDiv'>
          <img src={imgURL} alt='No image' className='coverImage' />
        </div>
        <div className='agCellData'>
          <div className='rowItem'>
            <span className='boldLabel'>{title}</span>
            {subtitle !== null ? (
              <span className='rowItem'>: {subtitle}</span>
            ) : (
              ""
            )}
          </div>
          <div className='rowItem'>
            {author !== null
              ? author.startsWith("Written")
                ? ""
                : "By "
              : "-"}
            {author}
          </div>
          <div className='rowItem'>
            <span>{formatName}</span> <span className='pipe'> | </span>{" "}
            <span>{formatDate(onsale, false)}</span>
          </div>
          <div className='rowItem'>{isbn}</div>
        </div>
      </div>
    );
  }
}

export default withRouter(BookDetailSnippet);
