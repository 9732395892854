import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  REGISTER,
  REHYDRATE,
  PURGE,
} from "redux-persist";
// import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { Api } from "./api/api";
import ecomSlice, { setErrorMsg } from "./features/ecomSlice";
import createIdbStorage from "@piotr-cz/redux-persist-idb-storage";
import userSlice from "./features/userSlice";
import { ProxyApi } from "./api/proxyApi";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { DEVTOOLS } from "./utils/authConst";
import persistantSlice from "./features/persistantSlice";

const persistConfig = {
  key: "root",
  version: 1,
  stateReconciler: autoMergeLevel2,
  storage: createIdbStorage({ name: "biz", storeName: "root" }),
  // storage,
  // whitelist: [ecomSlice.reducerPath, userSlice.reducerPath],
  whitelist: ["prhpersistant"],
  blacklist: [Api.reducerPath, ProxyApi.reducerPath, "user", "ecom"],
};

export const rtkQueryErrorLogger = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    if (action.payload.status !== 404 && action.payload.status !== 400) {
      console.log("RTK Errored", action.payload.status);
      console.log(action);
      store.dispatch(setErrorMsg(action.payload));
    }
    if (action.payload.status === 404) {
      if (store.getState().ecom.debug) {
        console.log("RTK 404 Errored");
        console.log(action);
      }
    }

    // if (action?.payload?.status === 401) {
    //   NetInfo.fetch().then((state) => {
    //     if (state.isConnected) {
    //       logger.info('rtkQueryErrorLogger:signout')
    //       signOut()
    //     }
    //   })
    // }
  }

  return next(action);
};

const rootReducer = combineReducers({
  user: userSlice,
  ecom: ecomSlice,
  prhpersistant: persistantSlice,
  [Api.reducerPath]: Api.reducer,
  [ProxyApi.reducerPath]: ProxyApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV === "development",
  // devTools: process.env.REACT_APP_DEVELOPMENT_MODE === "true" ? true : false,
  devTools: DEVTOOLS === "true" ? true : false,
  // devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },
      serializableCheck: false,
      // serializableCheck: {
      //   ignoredActions: [
      //     REHYDRATE,
      //     PAUSE,
      //     PERSIST,
      //     // PURGE,
      //     REGISTER,
      //   ],
      // },
    }).concat([
      thunk,
      Api.middleware,
      ProxyApi.middleware,
      rtkQueryErrorLogger,
    ]),
});

export const persistor = persistStore(store);
