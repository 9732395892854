import { Component } from 'react';
import withApp from '../../components/hc/withApp';
import { withRouter } from 'react-router';
import PageStandard from '../../components/elements/PageStandard';
import { PRHContainer } from '../../shared-react-components/elements/elements';
import { PRHButton, PH1, ButtonHolder } from '../../components/elements/elements';


class Error extends Component {
  render() {
    return (
      <>
      <PageStandard jeff={true} alignment="center">
        <PRHContainer className="content-block-border">
            <PH1>Submission failed</PH1>
            <span>Please try again or contact customer service.</span>            
            <br/>
            <br/>      
        </PRHContainer>
        </PageStandard>
      </>
    );
  }
}

export default withApp(withRouter(Error));
