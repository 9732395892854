import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import LazyImage from "../../utils/LazyImage";
import useSecurity from "../../components/hc/useSecurity";
import { numberAsCurrency } from "../../utils/utilities";

import "./OrderDetailDropdown.scss";

export const OrderDetailDropdownRow = ({
  book,
  isEditing,
  addOrRemoveFromEdited,
  status,
  cancelTriggered,
  history,
}) => {
  const user = useSecurity();
  const [isCanceled, setisCanceled] = useState(book.status === "2");
  const [isMissing, setIsMissing] = useState(false);
  const handleCancel = () => {
    addOrRemoveFromEdited(book, !isCanceled ? "WC" : null, book.quantity);
    setisCanceled(!isCanceled);
  };
  useEffect(() => {
    !!cancelTriggered && cancelTriggered();
    if (
      book.titleDetail.author === null &&
      book.titleDetail.format === null &&
      book.titleDetail.onsale === null &&
      book.titleDetail.cartonQty === null
    ) {
      setIsMissing(true);
    }
  }, [cancelTriggered, isCanceled]);

  return (
    <div
      key={book.isbn}
      className={
        isEditing ? "odd-book-row-no-margin " : "odd-book-row flex-row"
      }
    >
      <div className='odd-book-row-column-start'>
        <LazyImage
          src={`https://images.penguinrandomhouse.com/cover/${book.isbn}`}
          className={`odd-title-image ${
            isCanceled && "odd-title-image-canceled"
          }`}
          alt={"Book"}
          isSmallThumbNail={true}
          isVariant={book.titleDetail.isVariant}
          varTagWidth={30}
        />
        <div className='btt-title-data-container'>
          {!isMissing ? (
            <div>
              <a
                className='boldLabel text-gray-900'
                href={`/titleinfo/${book.isbn}`}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/titleinfo/${book.isbn}`);
                }}
              >
                {book.titleDetail.title || book.title || ""}
              </a>
            </div>
          ) : (
            ""
          )}
          {!isMissing ? (
            <div className='od-text-data-book'>
              By {book.titleDetail.author}
            </div>
          ) : (
            ""
          )}
          <div className='od-text-data-book'>{book.isbn}</div>
          {!isMissing ? (
            <div className='od-text-data-book'>
              {book.titleDetail.format}
              <span className='pipe'> | </span>
              {book.titleDetail.onsale}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {!isMissing ? (
        <div className='odd-book-row-column-mid'>
          {book.promo ? (
            <div className='text-blue'>
              <NumericFormat
                className=''
                value={book.promo}
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
                displayType={"text"}
                suffix={"%"}
              />{" "}
              off with Promo {/* {this.props.promoCode} */}
            </div>
          ) : null}

          <div className='od-text-data-book'>
            {book.titleDetail.cartonQty} per carton
          </div>
          {book.statusMsg === "Out of Stock Backordered" && (
            <div className='od-text-data-bold'>Temporarily out of stock</div>
          )}
          {book.statusMsg === "Not Yet Published" && (
            <div className='od-text-data-bold'>On sale soon</div>
          )}
          {!["Billed", "In Progress", "Shipped"].includes(status) && (
            <div className='od-text-data-book'>
              Req. Delivery Date: {book.reqDeliveryDate}
            </div>
          )}
          {!["Billed", "In Progress", "Shipped"].includes(status) && (
            <div className='od-text-data-book'>
              Shipping From: {book.warehouse}
            </div>
          )}
          {["Billed", "In Progress", "Shipped"].includes(status) &&
            book?.deliveries?.shipping?.vemng && (
              <div className='od-text-data-book'>
                Shipment Contains:{" "}
                {book?.deliveries?.shipping &&
                  book?.deliveries?.shipping?.vemng}{" "}
                units
              </div>
            )}
        </div>
      ) : (
        ""
      )}
      {!isMissing ? (
        <div className='odd-book-row-column-end'>
          <div
            className={`od-text-data-bold ${isCanceled && "text-line-through"}`}
          >
            $
            {Number(book.netAmt).toFixed(2) > 0
              ? numberAsCurrency(Number(book.netAmt).toFixed(2))
              : "0.00"}
          </div>
          <div
            className={`od-text-data-book ${isCanceled && "text-line-through"}`}
          >
            $
            {Number(book.netPrice).toFixed(2) > 0
              ? numberAsCurrency(Number(book.netPrice).toFixed(2))
              : "0.00"}{" "}
            (each)
          </div>
          {user.isAppBiz() ? (
            <div className='od-discount-price'>
              <span>{book.disc}% off </span>
              <span className='text-line-through'>
                $
                {Number(book.coverPrice).toFixed(2) > 0
                  ? numberAsCurrency(Number(book.coverPrice).toFixed(2))
                  : "0.00"}
              </span>
            </div>
          ) : null}

          {(!isEditing || isCanceled) && (
            <div
              className={`od-text-data-medium ${isCanceled && "line-through"}`}
            >
              Qty: {book.quantity}
            </div>
          )}
          {isEditing && !isCanceled && (
            <div className='tm-row-item odd-input mb-3'>
              <span className='qty-lbl tm-qty-label'> Qty </span>
              <input
                id={`book-quantity-${book.isbn}`}
                type='number'
                min='1'
                max='99999'
                className='PRHInput tm-qty'
                defaultValue={Number(book.quantity)}
                onInput={() =>
                  addOrRemoveFromEdited(
                    book,
                    null,
                    document.getElementById(`book-quantity-${book.isbn}`)
                      .value !== ""
                      ? Number(
                          document.getElementById(`book-quantity-${book.isbn}`)
                            .value
                        )
                      : book.quantity
                  )
                }
              />
            </div>
          )}
          {isCanceled && (
            <div className='od-text-data-bold odd-input'>Canceled</div>
          )}
          {isEditing && (
            <div className='btt-isbn' onClick={() => handleCancel()}>
              {isCanceled ? "Undo cancelation" : "Cancel item"}
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
