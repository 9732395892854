import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import BridgeReceivingItemsModal from "./BridgeReceivingItemsModal";
import EventTrigger from "../../components/analytics/EventTrigger";
import { Header, Icon, Table } from "semantic-ui-react";
import LazyImage from "../../utils/LazyImage";
import DialogModal from "./DialogModal";
import useCart from "../hc/useCart";
import useApp from "../hc/useApp";
import { useHistory } from "react-router";
import useDirtyHandler from "../../shared-react-components/components/hc/useDirtyHandler";
import BridgeReceivingFailureModal from "./BridgeReceivingFailureModal";
import { useBeforeunload } from "react-beforeunload";
import ToastGenerator from "../../shared-react-components/utils/ToastGenerator";
import BridgeReceivingNotOwnerModal from "./BridgeReceivingNotOwnerModal";
import useSecurity from "../hc/useSecurity";
import {
  useCancelWishListByIDMutation,
  useLazyGetWishListByIDQuery,
  useSubmitWishListByIDMutation,
} from "../../api/wishlistApi";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";

function BridgeAddRetailerTitlesModal(props) {
  const { listID, listName, callback = false } = props;

  const cart = useCart();
  const app = useApp();
  const user = useSecurity();
  const dirtyHandler = useDirtyHandler();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [receivingTitlesOpen, setReceivingTitlesOpen] = useState(false);
  const [receivingFailureOpen, setReceivingFailureOpen] = useState(false);
  const [receivingNotOwnerOpen, setReceivingNotOwnerOpen] = useState(false);
  const [cartQuantities, setCartQuantities] = useState({});
  const [titleList, setTitleList] = useState([]);
  const [titleListLoaded, setTitleListLoaded] = useState(false);
  const [performGetWishListByID, getWishListByIDResult] =
    useLazyGetWishListByIDQuery();
  const [performSubmitWishListByID, submitWishListByIDResult] =
    useSubmitWishListByIDMutation();
  const [performCancelWishListByID, cancelWishListByIDResult] =
    useCancelWishListByIDMutation();
  const ecom = useSelector((state) => state.ecom);

  //   console.log('BridgeAddRetailerTitlesModal', props);

  // console.log('useBeforeUnload hook');
  useBeforeunload((event) => {
    if (open) {
      event.preventDefault();
      return "you will loose your data";
    }
  });

  useEffect(() => {
    // console.log('useEffect B', props);
    if (listID) {
      getWishList(listID);
      EventTrigger("Bridge_To_Biz", "Receive List");
    }

    return () => {
      // console.log('useEffect return');
      //   setOpen(false);
    };
  }, [listID]);

  useEffect(() => {
    // console.log("useEffect C", cart.cart);
    let t = cloneDeep(titleList);
    t.map((title) => {
      cart.cart.items.map((ci) => {
        if (ci.isbn.toString() === title.isbn.toString()) {
          title.cartQuantity = ci.quantity;
        }
      });
    });
    setTitleList(t);
  }, [cart.cart, titleListLoaded === true]);

  const getWishList = async (id) => {
    setReceivingTitlesOpen(true);
    // console.log("get wishlist cart", cart);
    performGetWishListByID({ id }).then((result) => {
      if (result.data?.status === "OK") {
        // console.log('wl result', result);
        if (result.data.data?.error?.code === 34) {
          setReceivingTitlesOpen(false);
          setReceivingNotOwnerOpen(true);
        } else {
          let t = cloneDeep(result.data.data.items);
          // console.log("getwisthlist", ecom.cart);
          t.map((title) => {
            ecom.cart.items.map((ci) => {
              if (ci.isbn.toString() === title.isbn.toString()) {
                title.cartQuantity = ci.quantity;
              }
            });
          });
          setTitleList(t);
          setTitleListLoaded(true);
          setReceivingTitlesOpen(false);
          setOpen(true);
        }
      } else {
        setReceivingTitlesOpen(false);
        setReceivingFailureOpen(true);
      }
    });
  };

  const addTitlesToCart = async () => {
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Adding new items to the cart . . .",
      longLoadText: "Still adding new items to cart . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const items = [...titleList];
    console.log("items b4", items);
    items.map((i) => {
      if (!i.available) {
        i.quantity = 0;
      }
    });
    // console.log('items af', items);
    const result = await performSubmitWishListByID({
      id: listID,
      cart: { items },
    });
    if (result.data?.status === "OK") {
      history.replace({ pathname: "/cart" });
      setOpen(false);
      if (callback) callback();
      ToastGenerator(
        {
          text: `${titleList.filter((t) => t.available).length} item${
            titleList.filter((t) => t.available).length > 1 ? "s were" : " was"
          } successfully added to your cart.`,
          textClassName: "fort-book",
        },
        { autoClose: 3000, closeOnClick: true }
      );
    }
  };

  const cancelAddTitlesToCart = () => {
    performCancelWishListByID({
      id: listID,
    });
    history.replace({ pathname: "/cart" });
    setOpen(false);
    ToastGenerator(
      {
        text: "You cancelled adding your wishlist to the cart!",
        textClassName: "fort-book",
      },
      { autoClose: 3000, closeOnClick: true }
    );
  };

  let performRelogin = () => {
    if (user.user) {
      const ut = user.isAppBiz();
      app.accountApi.logout().then((e) => {
        cart.clearCheckout();
        user.logout();
        user.clearUser();
        //localStorage.setItem('SSO', window.location);
        if (ut) {
          localStorage.setItem(
            "redirectUri",
            window.location.pathname + `/cart?listID=${listID}`
          );
          window.location.href = process.env.REACT_APP_NETIQ_LOGOUT_URL;
          return;
        }
        history.push({ pathname: `/cart?listID=${listID}` });
      });
    } else {
      history.push({ pathname: `/cart?listID=${listID}` });
    }
  };

  // console.log('titles', titleList);
  return (
    <div>
      <BridgeReceivingItemsModal
        open={receivingTitlesOpen}
        setOpen={(x) => setReceivingTitlesOpen(x)}
        listName={listName}
      />
      <BridgeReceivingFailureModal
        open={receivingFailureOpen}
        setOpen={(x) => setReceivingFailureOpen(x)}
      />
      <BridgeReceivingNotOwnerModal
        open={receivingNotOwnerOpen}
        setOpen={(x) => setReceivingNotOwnerOpen(x)}
        callback={performRelogin}
        cancelCallback={(e) => {
          cancelAddTitlesToCart();
          setReceivingNotOwnerOpen(false);
          setOpen(false);
        }}
      />
      <DialogModal
        setOpen={setCancelModalOpen}
        open={cancelModalOpen}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        contentPt='pt2'
        dialogOptions={{
          header: "CANCEL ADDING ITEMS",
          actions: [
            {
              hidden: false,
              label: "No, go back",
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                if (!open) {
                  setOpen(true);
                }
                return { proceed: true };
              },
            },
            {
              hidden: false,
              label: "Yes, I am sure",
              textColorOverride: "white",
              backgroundColorOverride: "blue",
              borderColorOverride: "blue",
              textColorHoverOverride: "blue",
              backgroundHoverColorOverride: "white",
              borderHoverColorOverride: "blue",
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                cancelAddTitlesToCart();
                return { proceed: true };
              },
            },
          ],
        }}
        options={{ className: "tm-modal-container" }}
      >
        <div className='fort-book fs14-px lh19-px'>
          Are you sure you want to cancel adding items
          {listName ? ` from ${listName}` : ""}?
        </div>
      </DialogModal>
      <DialogModal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={open}
        modalClassName='br-add-title-modal'
        setOpen={setOpen}
        onXClose={() => {
          setCancelModalOpen(true);
        }}
        // contentPx={validIsbnList ? 'px-0' : 'px-4'}
        contentPt='pt-2'
        dialogOptions={{
          header: "ADD ISBNs",
          header: (
            <Header className='fs-12px fort-bold fs-2 lh-2 my-0 mr-5'>
              ADD {listName ? listName.toUpperCase() : ""} ITEMS TO CART
            </Header>
          ),
          actions: [
            {
              hidden: false,
              label: "Cancel",
              preAction: async () => {
                setCancelModalOpen(true);
                return { proceed: false };
              },
              action: () => {
                return { proceed: true };
              },
            },
            {
              hidden: false,
              disabled: titleList.filter((t) => t.available).length === 0,
              label: "Add items to cart",
              textColorOverride: "white",
              backgroundColorOverride: "blue",
              borderColorOverride: "blue",
              textColorHoverOverride: "blue",
              backgroundHoverColorOverride: "white",
              borderHoverColorOverride: "blue",
              bold: true,
              icon: "check",
              iconColorHoverOverride: "blue",
              iconColorOverride: "white",
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                addTitlesToCart();
                return { proceed: true };
              },
            },
          ],
        }}
        options={{
          size: "small",
        }}
      >
        <div>
          <div className='pb-1 pl-4 pr-4 mb-1'>
            <span className='fort-bold'>
              {titleList.filter((t) => t.available).length}
            </span>{" "}
            item{titleList.filter((t) => t.available).length > 1 ? "s" : ""}{" "}
            will be added to your cart.{" "}
            {titleList.filter((t) => t.available).length > 0 &&
              `You can edit quantities and remove items in your cart.`}
          </div>
          <div className='px-0 aim-isbn-tableX'>
            <div
              className={`d-flex flex-row pl-4 pr-4 pt-1 pb-1 fort-book fs-12px lh-16px brdg-import-header ${
                titleList.length > 4 ? "scroll-pad" : ""
              } `}
            >
              <div style={{ width: "5%" }}></div>
              <div style={{ width: "60%" }}>TITLE</div>
              <div style={{ width: "25%" }}>ISBN/UPC</div>
              <div style={{ width: "10%" }}>QTY</div>
            </div>

            <div className='d-flex flex-column brdg-import-body'>
              {titleList.map((item, idx) => (
                <div
                  className={`d-flex flex-row pt-1 pb-1 pl-4 pr-4 brdg-import-row fort-book fs-12px lh-15px ${
                    item.available === false ? "warning" : ""
                  }`}
                  key={idx}
                >
                  <div
                    className='d-flex align-items-center justify-content-start'
                    style={{ width: "5%" }}
                  >
                    {item.available === false ? (
                      <div
                        //   style={{ marginRight: '5px' }}
                        className='invalid'
                      >
                        <Icon
                          className='fs-16px'
                          style={{ color: "#D63C3C" }}
                          name='warning sign'
                        ></Icon>
                      </div>
                    ) : (
                      <div
                        //   style={{ marginRight: '10px' }}
                        className=' valid'
                      >
                        <Icon
                          className='fs-16px'
                          style={{ color: "#55B079" }}
                          name='check'
                        ></Icon>
                      </div>
                    )}
                  </div>
                  <div style={{ width: "60%" }}>
                    <div className='d-flex flex-row align-items-center text-gray-900 fort-medium pr-2'>
                      <LazyImage
                        className='hover-cover'
                        boxShadow={true}
                        src={`https://images.penguinrandomhouse.com/cover/${item.isbn}`}
                      />
                      <div className='d-flex flex-column ml-2'>
                        <span className='fort-bold'>{item.title}</span>

                        {item.available ? (
                          item.cartQuantity ? (
                            <div className='fort-book-italic'>
                              Note: You already have {item.cartQuantity}{" "}
                              {item.cartQuantity === 1 ? "copy" : "copies"} of
                              this item in your cart.
                            </div>
                          ) : null
                        ) : (
                          <div className='text-red fort-book-italic'>
                            {item.status}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='title-isbn' style={{ width: "25%" }}>
                    {item.isbn}
                  </div>
                  <div className='title-qty' style={{ width: "10%" }}>
                    {item.quantity && item.quantity}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogModal>
    </div>
  );
}

BridgeAddRetailerTitlesModal.propTypes = {
  listID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default BridgeAddRetailerTitlesModal;
