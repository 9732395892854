import React, { useState, useEffect } from 'react';
import LazyImage from '../../../utils/LazyImage';
import DialogModal from '../DialogModal';

function AffidavitReturnsNoCConFile(props) {
  const {
    open,
    setOpen,
    onClose,
    callback,
    cancelCallback,
    submitReturn, 
    afdDate = 'November 28,2022',
    afdTime = '11:59 PM',
  } = props;
  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      modalClassName="cp-confirmcart-popup"
      style={{ maxWidth: '448px' }}
      dialogOptions={{
        header: 'CHOOSE CREDIT CARD',
        actions: [
          {
            label: 'No, cancel',
            color: 'white',
            action: () => {
              onClose();
              if (cancelCallback) cancelCallback();
              return { proceed: true };
            },
          },
          {
            label: 'Yes, Request Return',
            icon: 'check',
            borderColorOverride: 'blue',
            textColorOverride: 'white',
            textColorHoverOverride: 'blue',
            backgroundColorOverride: 'blue',
            backgroundHoverColorOverride: 'white',
            iconColorOverride: 'white',
            iconColorHoverOverride: 'blue',

            preAction: () => {
              if (callback) callback();
              return { proceed: true };
            },

            action: () => {
              submitReturn();
              return { proceed: true };
            },
          },
        ],
      }}
    >
      <div className="d-flex flex-column">
        <div className="fort-book fs-14px lh-19px mb-1">
          <span className="fort-bold">
            You don't have any valid credit cards on file.
          </span>{' '}
          Submitting this return will mean any credit received will be added to
          your account.
        </div>
        <div className="fort-book fs-14px lh-19px mb-2">
        Would you like to continue your return request?
        </div>
      </div>
    </DialogModal>
  );
}

export default AffidavitReturnsNoCConFile;
