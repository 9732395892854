import { Icon, Message } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const MessageGenerator = (props) => {
  const { message } = props;

  if (!message || (Object.prototype.hasOwnProperty.call(message, 'label') && !message.label)) {
    return <></>;
  }

  const { icon, type = 'info', label } = message;

  return (
    <Message icon={!!icon} className="text-left" {...{ [type]: true }}>
      <Message.Header className="d-flex align-items-center w-100">
        <span className="d-inline-block mr-2">
          {icon && <Icon name={icon} />}
        </span>
        <span>
          {' '}
          {label}
        </span>
      </Message.Header>
    </Message>
  );
};

MessageGenerator.propTypes = {
  message: PropTypes.object.isRequired,
};

export default MessageGenerator;
