import { useState } from 'react';
import { withRouter } from 'react-router';
import { Input } from 'semantic-ui-react';
import { getPrettyName } from '../../../utils/wordpressHelpers';

const WordPressSearch = ({ postType, searchTerm, setSearchTerm, history }) => {
  const [focus, setFocus] = useState(false);

  const searchSite = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      let searchPath = `/${postType}/`;
      if (!!searchTerm) {
        // const { pathname } = location;
        // if we want to search the given filters
        // let searchPath = `${pathname}?s=${searchTerm}`;
        searchPath = `/${postType}/?s=${searchTerm}`;
      }
      history.push(searchPath);
      setSearchTerm('');
    }
  };
  return (
    <Input
      onChange={(e) => setSearchTerm(e.target.value)}
      value={searchTerm}
      onKeyPress={searchSite}
      action={{ icon: 'search' }}
      placeholder={`Search ${getPrettyName(postType).plural}`}
      className={`child-py-0 child-h-32px wordpress-content-search ${
        !!focus ? `focus` : ``
      }`}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    />
  );
};

export default withRouter(WordPressSearch);
