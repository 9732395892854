import "./OrderDetail.scss";
import _ from "lodash";
import { NumericFormat } from "react-number-format";
import LazyImage from "../../utils/LazyImage";
import PRHButtonStandard from "../../shared-react-components/elements/prhbutton";
import { formatDateLong } from "../../utils/utilities";
import useSecurity from "../../components/hc/useSecurity";
import moment from "moment";

export const OrderDetailRowsPrint = (props) => {
  const {
    // key,
    history,
    lineItems,
    items,
    status,
    user,
    shippedOn,
    reqDeliveryDate,
    invoice,
    trackingId,
    trackingUrl,
    shippingFrom,
  } = props;

  // console.log('orderDataRows', props);
  // const formattedData = _.flattenDeep(
  //   orderDataRows.map((groupOfRows) => {
  //     return groupOfRows.invoiceItemDetailsList.map((row) => {
  //       return {
  //         ...row,
  //         purchaseOrder: groupOfRows.purchaseOrder,
  //         salesOrder: groupOfRows.salesOrder,
  //       };
  //     });
  //   })
  // ).sort((a, b) => {
  //   const nameA = a.bookTitle?.toUpperCase();
  //   const nameB = b.bookTitle?.toUpperCase();
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  //   return 0;
  // });

  const renderPrintRow = (book) => {
    return (
      <div key={book.isbn} className='opdd-book-row-no-margin pt-1'>
        <div className='odd-book-fixed-content'>
          <div className='odd-book-row-column-start'>
            <div className='hide-in-print'>
              <LazyImage
                src={`https://images.penguinrandomhouse.com/cover/${book.isbn}`}
                className={`odd-title-image`}
                alt={"Book"}
              />
            </div>
            <div className='btt-title-data-container'>
              <div>
                <a className='boldLabel' href={`/titleinfo/${book.isbn}`}>
                  {book.titleDetail.title}
                </a>
              </div>
              <div className='od-text-data-book'>
                By {book.titleDetail.author}
              </div>
              <div className='od-text-data-book'>
                {book.isbn}
                <span className='pipe'> | </span>
                {book.titleDetail.format}
                <span className='pipe'> | </span>
                {book.titleDetail.onsale}
              </div>
            </div>
          </div>
          <div className='odd-book-row-column-mid'>
            {book.promoDiscount ? (
              <div className='text-blue'>
                <NumericFormat
                  className=''
                  value={book.promo}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  suffix={"%"}
                />{" "}
                off with Promo
              </div>
            ) : null}
            <div className='od-text-data-book'>
              {book.titleDetail.cartonQty} per carton
            </div>
            <div className='od-text-data-book'>{book.departureCountry}</div>
            {/* {book.upcSku.length > 0 && (
              <div className="od-text-data-book">UPC/SKU: {book.upcSku}</div>
            )} */}
          </div>
          <div className='odd-book-row-column-end'>
            <div className={`od-text-data-bold`}>
              {Number(book.netPrice).toFixed(2) > 0
                ? `$${Number(book.netPrice).toFixed(2)}`
                : "FREE"}
            </div>
            <div className={`od-text-data-book`}>
              {Number(book.netPrice).toFixed(2) > 0
                ? `$${Number(book.netPrice).toFixed(2)}`
                : "FREE"}{" "}
              (each)
            </div>
            {Number(book.coverPrice).toFixed(2) > 0 &&
              Math.abs(Number(book?.disc)) > 0 && (
                <div className='od-discount-price '>
                  <span>{String(book.disc).replace("-", "")}% off </span>
                  <span className='text-line-through'>
                    ${Number(book.grossAmt).toFixed(2)}
                  </span>
                </div>
              )}
            <div className={`od-text-data-medium`}>Qty: {book.quantity}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='orders-print-order-title flex-column'>
        <div>
          <span className='fort-bold'>ORDER STATUS: {status} </span>
        </div>
        <div className='orders-print-order-item-container'>
          {reqDeliveryDate && (
            <div className='opdd-dropdown-header-item'>
              <div className='opdd-dropdown-header-title'>
                Req. Delivery Date:
              </div>{" "}
              <div className='opd-text-data-book'>
                {moment(reqDeliveryDate).format("MMM Do, YYYY")}
              </div>
            </div>
          )}
          {shippedOn && (
            <div className='opdd-dropdown-header-item'>
              <div className='opdd-dropdown-header-title'>Shipped On: </div>
              <div className='opd-text-data-book'>
                {" "}
                {moment(shippedOn).format("MMM Do, YYYY")}
              </div>
            </div>
          )}
          {(invoice || trackingId) && (
            <div className='opdd-dropdown-header-item'>
              <div className='opdd-dropdown-header-title'>Shipped From: </div>
              <div className='opd-text-data-book'>{shippingFrom}</div>
            </div>
          )}
          {trackingId && (
            <div className='opdd-dropdown-header-item'>
              <div className='opdd-dropdown-header-title '>Tracking #</div>

              {trackingId}
            </div>
          )}
          {invoice && !user.isAppCompCopy() && (
            <div className='opdd-dropdown-header-item'>
              <div className='opdd-dropdown-header-title'>Invoice #</div>
              <div className=''>{invoice}</div>
            </div>
          )}
        </div>
      </div>

      <div className='orders-container'>
        <div className='orders-alpha-items-container'>
          {items?.map(renderPrintRow)}
        </div>
      </div>
    </div>
  );
};
