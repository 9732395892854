import { Component } from "react";
import Download from "../../../assets/images/download.svg";
//import Download from '../../assets/images/download.svg';
import { withRouter } from "react-router-dom";
import "./preDeliveryDownload.scss";

class preDeliveryDownload extends Component {
  render() {
    const { downloads } = this.props.data;
    const { downloadPreDeliveryReport } = this.props;

    return (
      <>
        <div className={"cell-padding"}>
          {downloads.map((download, index) => {
            return (
              <div className={"inner-row-padding"} key={`Download_${index}`}>
                <img
                  src={Download}
                  width={12}
                  height={12}
                  alt='#'
                  className='mr-1'
                />
                <a
                  href='#'
                  onClick={() =>
                    downloadPreDeliveryReport(
                      download.onSaleDate,
                      download.shipTo
                    )
                  }
                >
                  Download (.csv)
                </a>
                <br />
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default withRouter(preDeliveryDownload);
