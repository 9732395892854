import PropTypes from "prop-types";
import { Icon, Popup } from "semantic-ui-react";
import { dateDifference } from "../../utils/utilities";
import useCart from "../hc/useCart";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import { isIE, osName, isFirefox, browserName } from "react-device-detect";

export default function ConfirmationStatus(props) {
  // console.log('ConfirmationStatus', props);
  const { focDate, confirmationStatus, hidePast, className } = props;
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const cart = useCart();

  const isLvl1 = cart?.customerInfo?.currentUser?.roleNo === "W1";

  if (isLvl1 || !confirmationStatus) {
    return <></>;
  }
  if (!!!focDate) return <></>;

  let msg = "";
  let msgColor = "grey";
  let contentMsg = "";

  let nd = dateDifference(focDate);
  const FOCArray = focDate.split("-");
  const tooltipFOC = FOCArray[1] + "/" + FOCArray[2] + "/" + FOCArray[0];

  if (confirmationStatus === "CONFIRMED") {
    msg = `Confirmed`;
    msgColor = "green";
    contentMsg =
      "This cart has been confirmed and all quantities are guaranteed.";
  } else if (nd < 1 && nd > -1) {
    msg = `Confirm ${
      confirmationStatus === "PENDING_UPDATE" ? "updates" : ""
    } by 11:59PM EST`;
    msgColor = "red";
    contentMsg =
      "This cart must be confirmed by " +
      tooltipFOC +
      " at 11:59PM EST to guarantee quantities.";
  } else if (nd > 0) {
    msg = `Confirm ${
      confirmationStatus === "PENDING_UPDATE" ? "updates" : ""
    } within ${Math.floor(nd)} days`;
    if (nd > 0 && nd < 6) {
      msgColor = "orange";
    }
    if (nd > 5 && nd < 10) {
      msgColor = "grey";
    }
    contentMsg =
      "The cart must be confirmed by " +
      tooltipFOC +
      " at 11:59PM EST to guarantee quantities.";
  } else {
    if (hidePast) return <></>;
    msg = `FOC Date passed`;
    msgColor = "red";
    contentMsg =
      "The FOC date for these items have passed. Items can still be ordered, but quantities will no longer be guaranteed.";
  }

  return (
    <Popup
      className={`ml-1`}
      content={contentMsg}
      on='hover'
      position='top center'
      offset={[-5, 8]}
      trigger={
        <div
          className={`d-flex align-items-center ${className} pl-1 pr-1 foc-status ${
            confirmationStatus === "CONFIRMED"
              ? "good"
              : nd > 0 && nd < 6
              ? "warning"
              : nd <= 0
              ? "error"
              : ""
          } ${
            isMobile ? "py-2 justify-content-center" : ""
          } ${osName} ${browserName}`}
        >
          {confirmationStatus === "CONFIRMED" ? (
            <Icon name='check' />
          ) : (
            <Icon name='exclamation triangle' color={msgColor} />
          )}
          <span className='fort-bold fs-12px lh-15'>{msg}</span>
        </div>
      }
    />
  );
}

ConfirmationStatus.propTypes = {
  focDate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  hidePast: PropTypes.bool,
};

ConfirmationStatus.defaultProps = {
  focDate: false,
  hidePast: false,
};
