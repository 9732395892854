import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container, Icon, Loader, Ref } from "semantic-ui-react";
import { CarouselItem } from "./CarouselItem";
import "./TitleCarousel.scss";
import useCart from "../../components/hc/useCart";
import useSecurity from "../../components/hc/useSecurity";
import useWindowSize from "../../utils/useWindowSize";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";

export const TitleCarousel = (props) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const { title, action, data, dataFunction, currentIsbn } = props;
  const [state, setState] = useState({
    items: undefined,
    dataLoaded: false,
    // numOfItems: 0,
    isLoaderShown: false,
    hasData: true,
    additionalTransfrom: -15,
  });
  const cart = useCart();
  const user = useSecurity();
  const [width, height] = useWindowSize();

  const isCartBadge = () => {
    if (user.isAppCompCopy()) return false;

    if (
      cart.customerInfo &&
      cart.customerInfo.currentUser &&
      cart.customerInfo.currentUser.roleNo !== "W1"
    )
      return false;

    return true;
  };

  const getCarouselData = (requestProps) => {
    dataFunction(requestProps).then((response) => {
      if (response.data?.data?.works) {
        setState({
          ...state,
          items: response.data.data?.works,
          dataLoaded: true,
          isLoaderShown: false,
          hasData: response.data?.status === "OK",
        });
      } else if (response?.data?.data?.titles) {
        setState({
          ...state,
          items: response.data.data?.titles,
          dataLoaded: true,
          isLoaderShown: false,
          hasData: response.data?.status === "OK",
        });
      } else {
        setState({ ...state, items: [], dataLoaded: true });
      }
    });
  };

  useEffect(() => {
    //if (dataFunction && state.hasData) {
      //dataFunction && getCarouselData(data);
    //} else if (data.data) {
      //setState({ ...state, items: data.data, dataLoaded: true });
    //}
    if (dataFunction) {
      dataFunction && getCarouselData(data);
    } else if (data.data) {
      setState({ ...state, items: data.data, dataLoaded: true });
    }
  }, [currentIsbn]);
  // }, [data, currentIsbn]);

  const itemWidth = 190;

  const responsive = (widthProp) => {
    const numOfSlides = Math.round(Math.min(...[widthProp, 1361]) / itemWidth);
    return {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 1360 },
        items: numOfSlides,
        slidesToSlide: numOfSlides,
      },
      desktop: {
        breakpoint: { max: 1359, min: 992 },
        items: numOfSlides,
        slidesToSlide: numOfSlides,
      },
      tablet: {
        breakpoint: { max: 991, min: 768 },
        items: numOfSlides,
        slidesToSlide: numOfSlides,
      },
      mobile: {
        breakpoint: { max: 767, min: 0 },
        items: numOfSlides,
        slidesToSlide: numOfSlides,
        partialVisibilityGutter: 30,
      },
    };
  };

  const CustomRightArrow = ({ onClick, ...rest }) => {
    return (
      <Icon
        name='chevron right'
        color='grey'
        size='large'
        className='carousel-arrow-right-style'
        onClick={() => onClick()}
      />
    );
  };

  const CustomLeftArrow = ({ onClick }) => {
    return (
      <Icon
        name='chevron left'
        color='grey'
        size='large'
        className='carousel-arrow-left-style'
        onClick={() => onClick()}
      />
    );
  };

  return (
    <div className='w-100 bg-white'>
      {!state.dataLoaded && (
        <Container className='border-top-gray-300 pb-4 carousel-placeholder-container'>
          <Loader
            active={!state.dataLoaded}
            size='small'
            inverted
            color='orange'
            inline
          />
        </Container>
      )}
      {state.dataLoaded && state.items?.length > 0 && (
        <Container className='border-top-gray-300 position-relative'>
          <div
            className={`pt-1 d-flex justify-content-space-between ${
              isMobile ? "flex-direction-column pb-0 my-1" : "pb-5"
            }`}
          >
            <div className='fs-18px lh-26 fort-bold'>{title}</div>
            <div
              className='fs-13px lh-18 btt-isbn'
              onClick={() => action?.onClick()}
            >
              {action ? `View ${action.name}` : ""}
            </div>
          </div>
          <Carousel
            showDots={false}
            responsive={responsive(width)}
            keyBoardControl={false}
            arrows={state.items?.length > 7 ? true : false}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            containerClass='carousel-title-style'
            itemClass='carousel-item'
            removeArrowOnDeviceType={["mobile", "tablet"]}
            additionalTransfrom={!isMobile && -8}
            partialVisible={isMobile && state.items && state.items.length > 2}
          >
            {state.items.map((item) => (
              <CarouselItem
                key={item.isbnStr}
                {...item}
                isCartBadge={isCartBadge}
                isPartOfTitle={!!data.data}
              />
            ))}
            {action && action.name === "Catalog" && (
              <div
                onClick={() => action?.onClick()}
                className='last-carousel-item-container carousel-item book-cover-shadow cursor-pointer carousel-item-img-style'
              >
                <div className='last-carousel-item-text'>
                  Go to <br /> {action.name} page
                </div>
                <div className='last-carousel-item-icon'>
                  <Icon name='arrow right' size='large' />
                </div>
              </div>
            )}
          </Carousel>
          {/* <Loader
            active={state.isLoaderShown}
            size="small"
            inverted
            color="orange"
            className="carousel-loader"
          /> */}
        </Container>
      )}
    </div>
  );
};
