import { Component } from 'react';
import localforage from 'localforage';
import { withRouter } from 'react-router';
import withSecurity from '../../components/hc/withSecurity';
import withApp from '../../components/hc/withApp';
import PageStandard from '../../components/elements/PageStandard';
import LoginHelp from '../../components/registration/LoginHelp';
import Cancel from '../../components/elements/RegistrationCancel';
import './registration.scss';

class LoginHelpPage extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      step: 1, 
      allowCreate: 1,
      legacyUserForm :{
      userName:'', 
      password:'', 
    },
    legacyUser :{
      userName:'', 
      password:'', 
    },
    }
}

  handleChange = (e) => {
    const newState = {...state};
    newState.legacyUserForm[e.target.name] =  e.target.value;
    this.setState(newState);
    localforage.setItem('appState', this.state.legacyUserForm);
  }

  handleSubmitUser = () => {
    this.setState((state) => {
      return {legacyUser :{
        userName: state.legacyUserForm.userName, 
        password: state.legacyUserForm.password,
      }}
    }, this.confirmUser);
  }

  nextStepHandler = () => {
    const {step} = this.state;
    this.setState({ step: step+1});
  }

  previousStepHandler = () => {
    const {step} = this.state;
    this.setState({ step: step -1});
  }

  setStepHandler = (e) => {
    const {step} = this.state;
    this.setState({ step: e, priorStep: step});
  }

  confirmUser = () => {
    const { legacyUser } = this.state;
    const postData ={
        userName: legacyUser.userName,
        password: legacyUser.password,
    };
    const contentType={
      'Content-Type': 'application/json',
    };
    this.props.app.registrationApi.getLegacyUser(postData);
  }

  render() {
    return (
      <PageStandard  
      jeff={true}
      alignment="center"
      cartBadge={false}
      pageType={'registration-path'}>
        <LoginHelp 
        handleSubmitUser={this.handleSubmitUser} 
        handleChange={this.handleChange} 
        setStepHandler={e => this.setStepHandler(e)}
        formState={this.state} 
        />
      </PageStandard>
    );
  }
}

export default withRouter(withApp(LoginHelpPage));