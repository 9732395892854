import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import {
  Container,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Message,
  Menu,
  Popup,
} from 'semantic-ui-react';
import {
  showDateByMonth,
  showDateMMMDYYYY,
  showTimeHHMMa,
  showDateMMMMDYYYY,
  showDateFullIfFour,
  showTimeHHMM,
  getStartOfCurrentMonth,
  isCreditCardExpired,
 willCreditCardExpire,
} from '../../utils/utilities';
import { Divider } from '../../components/elements/elements';
import {faCircleEllipsisVertical} from "@fortawesome/free-solid-svg-icons";
import PageStandard from '../../components/elements/PageStandard';
import ActionMenu from '../../components/common/ActionMenu';
import DropdownStoreSelectorReturns from '../../components/header/components/DropdownStoreSelectorReturns';
import { faClosedCaptioning, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CreditCardSmall from '../../assets/images/icon-card-small.svg';
import { ErrorComponent } from '../../components/ErrorComponent.jsx';
import withApp from '../../components/hc/withApp';
import withCart from '../../components/hc/withCart';
import withSecurity from '../../components/hc/withSecurity';

import PageHeader from '../../components/header/PageHeader';
import DialogModal from '../../components/modals/DialogModal';
import AffadavitReturnsChooseCC from '../../components/modals/AffidavitReturns/AffidavitReturnsChooseCC';
import AffadavitReturnsUpdate from '../../components/modals/AffidavitReturns/AffidavitReturnsUpdate';
import AffadavitReturnsSuccess from '../../components/modals/AffidavitReturns/AffidavitReturnsSuccess';
import AffadavitReturnsUpdatedSuccess from '../../components/modals/AffidavitReturns/AffidavitReturnsUpdatedSuccess';
import AffadavitReturnsCancel from '../../components/modals/AffidavitReturns/AffidavitReturnsCancel';
import AffadavitNoCConFile from '../../components/modals/AffidavitReturns/AffidavitReturnsNoCConFile';
import AffadavitReturnsBlockNav from '../../components/modals/AffidavitReturns/AffidavitReturnsBlockNav';
import AffadavitReturnsWindowClosed from '../../components/modals/AffidavitReturns/AffidavitReturnsWindowClosed';
import PRHButtonStandard from '../../shared-react-components/elements/prhbutton';
import useDirtyHandler from '../../shared-react-components/components/hc/useDirtyHandler';
import NoticeCard from '../../components/elements/NoticeCard';
import { MOBILE_SCREEN_WIDTH, TOOLTIPS } from '../../utils/const';
import { Export, ExportCSV } from '../../utils/Export';
import { numberAsCurrency } from '../../utils/utilities';
import LoadingView from '../loading/LoadingView';
import './Returns.scss';
import { useMediaQuery } from 'react-responsive';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { MobileOptionsMenu } from '../../components/header/components/MobileOptionsMenu';
import { OrderInvoiceTitleSearchMobile } from '../../components/common/OrderInvoiceTitleSearchMobile';
import IconReturns from '../../assets/images/icon_returns.svg';
import { TitleRowReturns } from './TitleRowReturns';
import { isMobileOnly } from 'react-device-detect';
import CompCopy from '../home/CompCopy';
import { useLazyGetReturnsQuery, useSubmitReturnsMutation } from '../../api/ecomApi';

export const Returns = withSecurity(
  withRouter(
    withApp(
      withCart((props) => {
        const isMobile = isMobileOnly;
        // const isMobile = useMediaQuery({
        //   query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
        // });
        //git demo

        const [ performGetReturns, getReturnsResult] = useLazyGetReturnsQuery();
        const [ performSubmitReturns, submitReturnsResult] = useSubmitReturnsMutation();
        const [returns, setReturns] = useState([]); //default returns
        const [returnsUndo, setReturnsUndo] = useState([]); //default returns
        const [returnsUndoLastChange, setReturnsUndoLastChange] = useState([]); //default returns
        const [firstChange, setFirstChange]=useState({isbn:null, qtyReturn: null});
        const [lastChange, setLastChange]=useState({isbn:null, qtyReturn: null});
        const [showUndo, setShowUndo] = useState(false);
        const [account, setAccount] = useState(null);
        const [returnsRequested, setReturnsRequested] = useState(false);
        const [returnsRequestedTxt, setReturnsRequestedTxt] = useState('No return requested');
        const [submitButtonTxt, setSubmitButtonTxt] = useState("Request return");
        const [openChooseCC, setOpenChooseCC] = useState(false);
        const [requestDisabled, setRequestDisabled] = useState(false);
        const [creditCardList, setCreditCardList]= useState([]);
        const [returnCreditCard, setReturnCreditCard]= useState(null);
        const [returnCreditCardRevert, setReturnCreditCardRevert]= useState(null);
        const [loading, setLoading]= useState(true);
        const [openReturnsUpdate, setOpenReturnsUpdate]=useState(false);
        const [openReturnsSuccess, setOpenReturnsSuccess]=useState(false);
        const [openReturnsUpdatedSuccess, setOpenReturnsUpdatedSuccess]=useState(false);
        const [dateOfReturn, setDateOfReturn]=useState(null);
        const [timeOfReturn, setTimeOfReturn]=useState(null);
        const [searchTerms, setSearchTerms]=useState(null);
        const [searchISBN, setSearchISBN]=useState(null);
        const [sortReturns, setSortReturns]=useState({direction: 'a', caretDirection:'down'});
        const [sortReturnsISBN, setSortReturnsISBN]=useState({direction: 'a', caretDirection:'down'});
        const [returnWindow, setReturnWindow]=useState(null);
        const [address, setAddress]=useState(null);
        const [openReturnsCancel, setOpenReturnsCancel]=useState(false);
        const [leavePage, setLeavePage]=useState(true)
        const [openNoCConFile, setOpenNoCConFile]=useState(false)
        const [cashOnly, setCashOnly]=useState(false);
        const [noValidCC,setNoValidCC]=useState(false);
        const [blockNav, setBlockNav]=useState(null);
        const [navTriggered, setNavTriggered]=useState(false);
        const [undoDisabled, setUndoDisabled]=useState(false);
        const [changes, setChanges]=useState([]);
        const [undoCount, setUndoCount]=useState(0);
        const [openCancelMenu, setOpenCancelMenu]=useState(false);
        const [windowSize, setWindowSize] = useState(getWindowSize());
        const [ccButtonLabel, setCCButtonLabel]=useState('Request return')
        const [isSearchResult, setIsSearchResult]=useState(false);
        const [returnsWindowClosed, setReturnsWindowClosed]=useState(false);
        const dirtyHandler = useDirtyHandler();

        
/*
        function findAccount () {
        let account;
        if (props.cart?.estimateCarts && props.cart?.estimateCarts[0]) {
        account=props.cart?.estimateCarts[0].headers.shipToAccountNo;
        } else {
        account='test acccount new';
        }
        return account;
        }

        function sortOn (arr, prop) {
          arr.sort (
              function (a, b) {
                  if (a[prop] < b[prop]){
                      return -1;
                  } else if (a[prop] > b[prop]){
                      return 1;
                  } else {
                      return 0;   
                  }
              }
          );
      }
*/

        function getWindowSize() {
          const {innerWidth, innerHeight} = window;
          return {innerWidth, innerHeight};
        }

        function handleCCChange (e){
          setReturnCreditCard(e);
        }

        function pullUpNavtriggered() {
          setNavTriggered(true);
        }

        function verifyReturnQtys() {
          const newReturns =  returns;
          const updateReturns = newReturns.map((r) => {
            if (r.qtyReturnable < r.qtyReturn) {
            return ({...r, qtyReturn: r.qtyReturnable});
            } else {
              return ({...r});
            }
          });
          setReturns([...updateReturns]);
        }

        const dialogObject = {
          ...dirtyHandler.dialogObject,
          content: (
            <>
              There have been changes made to this return request that has not been submitted. Leaving this page will discard all your changes. Would you like to continue?
            </>
          ),
          modalClassName:"cp-confirmcart-popup",
          style:(<>{{width: '460px'}}</>),
          options:{
            className: "cp-confirmcart-popup",
            style: {width: '460px'}
          },
          dialogOptions: {
            //...dirtyHandler.dialogObject.dialogOptions,
            header: <>Discard Changes</>,
            style: 'width: 460px',
            modalClassName:"cp-confirmcart-popup",
            className: "cp-confirmcart-popup",
            actions: [
              {
                label: 'No, stay on page',
                preAction: async () => ({
                  proceed: true,
                  cancelAction: true,
                }),
              },
              {
                label: 'Yes, discard and leave',
                icon: 'check',
                borderColorOverride: 'blue',
                textColorOverride: 'white',
                textColorHoverOverride: 'blue',
                backgroundColorOverride: 'blue',
                backgroundHoverColorOverride: 'white',
                iconColorOverride: 'white',
                iconColorHoverOverride: 'blue',
                preAction: async () => {
                  return {
                    proceed: true,
                  };
                },
              },
            ],
          },
        };


        const editReturnFocus = (e, isbn) => {
          const newReturns =  returns;
          const newChanges = changes;
          const editValue=e.target.value === '' ? 0: e.target.value;
          if (editValue <= newReturns[objIndex].qtyReturnable && editValue >= 0){ 
            newChanges.push({isbn: isbn, qtyReturn:  newReturns[objIndex].qtyReturn});
            setChanges([...newChanges]);
          }
        }

        const editReturn = (e, isbn) => {
          const invalidChars = [
            "-",
            "+",
            "e",
          ];
          const newReturns =  returns;
          const newChanges = changes;
          const objIndex = newReturns.findIndex((obj => obj.isbn == isbn));
          setUndoDisabled(false);
          const editValue=e.target.value === '' ? 0: e.target.value;
          if (/*editValue <= newReturns[objIndex].qtyReturnable && */editValue >= 0){ 
            newChanges.push({isbn: isbn, qtyReturn:  newReturns[objIndex].qtyReturn});
            setChanges([...newChanges]);
            newReturns[objIndex].qtyReturn = parseInt(editValue, 10);
            setReturns([...newReturns]);
            setRequestDisabled(false);
            setShowUndo(true);
            setBlockNav(true);
          }
        }

        const correctReturn = (e, isbn) => {
          const relatedTarget=e.relatedTarget && e.relatedTarget.innerText ?  e.relatedTarget.innerText: null;
          const newReturns =  returns;
          const newChanges = changes;
          const editValue= e.target.value === '' ? 0: e.target.value;
          const objIndex = newReturns.findIndex((obj => obj.isbn == isbn));
          if (editValue > newReturns[objIndex].qtyReturnable && (
            relatedTarget != 'Undo' && 
            relatedTarget != 'Undo All' && 
            relatedTarget != 'Request return' &&
            relatedTarget != 'Update request'
          )) {
            newReturns[objIndex].qtyReturn = parseInt(newReturns[objIndex].qtyReturnable);
            setReturns([...newReturns]);
          }   
        }

        const undo = () => {
          const newReturns = returns;
          const oldReturns = returnsUndo;
          const checkUndoCount = undoCount ===0 ? 0: undoCount-1;
          const newChanges = changes;
          const objIndex = newReturns.findIndex((obj => obj.isbn == newChanges[newChanges.length-1].isbn));
          const objIndexOldReturns = oldReturns.findIndex((obj => obj.isbn == newChanges[newChanges.length-1].isbn));
          let undoMarker;
          if(oldReturns[objIndexOldReturns].qtyReturn == 0 || 
            !newReturns[objIndex].qtyReturn.toString().includes(oldReturns[objIndexOldReturns].qtyReturn.toString()) ||
            oldReturns[objIndexOldReturns].qtyReturn.toString().length === newReturns[objIndex].qtyReturn.toString().length
            ) {
            undoMarker=parseInt(newReturns[objIndex].qtyReturn.toString().length);
          } else {
            undoMarker=parseInt(newReturns[objIndex].qtyReturn.toString().length-oldReturns[objIndexOldReturns].qtyReturn.toString().length);
          }
          newReturns[objIndex].qtyReturn = newChanges[newChanges.length-undoMarker].qtyReturn;
          setReturns(JSON.parse(JSON.stringify(newReturns)));
          const removeChanges = newChanges.slice(0, (newChanges.length - undoMarker));
          setChanges([...removeChanges]);
          if (removeChanges.length === 0) {
            setShowUndo(false);
            setRequestDisabled(true);
            setBlockNav(false);
          }
        } 

        const undoAll = () => {
          const oldReturns =  returnsUndo;
          setReturns(JSON.parse(JSON.stringify(oldReturns)));
          //setReturns(oldReturns);
          setShowUndo(false);
          setBlockNav(false);
          setRequestDisabled(true);
          setUndoCount(0);
          setChanges([]);
        }

        function isNumeric(n) {         
          return !isNaN(n);
        }

        function checkForReturns(){
          const areReturns = returns.findIndex((obj => obj.qtyReturn > 0));
          if (areReturns===-1) {
            return false;
          }
          return true;
        }

        const search = (e) => {
          if (isNumeric(e.target.value)) {
            setSearchISBN(e.target.value);
            setSearchTerms(null);
          } else {
            setSearchISBN(null);
            setSearchTerms(e.target.value);
          }
          //setSearchTerms(e.target.value);
          setSortReturns({direction: 'a', caretDirection:'down'});
          setSortReturnsISBN({direction: 'a', caretDirection:'down'});
        }

        const objectsEqual = (o1, o2) => 
    typeof o1 === 'object' && Object.keys(o1).length > 0 
        ? Object.keys(o1).length === Object.keys(o2).length 
            && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;

        const arraysEqual = (a1, a2) => 
   a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

        const checkReturnsChanges = () => {
          const diffReturnsReturnsUndo = returns.filter(o1 => !returnsUndo.some(o2 => o1.qtyReturn === o2.qtyReturn));
          if ((returns.length > 0) && diffReturnsReturnsUndo.length === 0) {
            setLeavePage(false);
          } else if ((returns.length > 0) && diffReturnsReturnsUndo.length > 0) {
            setLeavePage(true);
          } else {
            setLeavePage(false);
          }
        }

        const displayReturns = () => {
          let returnsToDisplay=[];
          let kindOfReturn=false;
          if (searchTerms && (searchTerms !==null || '') && searchISBN==null) {
            returnsToDisplay = returns.filter(r => r.title.toLowerCase().includes(searchTerms.trim().toLowerCase())).map(filteredTitle => (filteredTitle));
            kindOfReturn=true;
          } else if (searchISBN && (searchISBN !==null|| '') && searchTerms==null) {
            returnsToDisplay = returns.filter(r => r.isbn.toLowerCase().includes(searchISBN.trim())).map(filteredTitle => (filteredTitle));
            kindOfReturn=true;
          } else if  (searchISBN ==null && searchTerms==null || searchISBN =='' && searchTerms==''){
            returnsToDisplay = returns;
          }  else if  (searchISBN =='' && searchTerms==null || searchISBN ==null && searchTerms==''){
            returnsToDisplay = returns;
          } 

          if (!sortReturns && !sortReturnsISBN) {
            return returnsToDisplay;
          }
         
          if (sortReturns) {
            if (sortReturns.direction==='b') 
            return returnsToDisplay.sort( (a, b) => b.title.localeCompare(a.title, 'en', {'sensitivity': 'base'}));
            else 
            return returnsToDisplay.sort( (a, b) => a.title.localeCompare(b.title, 'en', {'sensitivity': 'base'}));
          }

          if (sortReturnsISBN) {
            if (sortReturnsISBN.direction==='b') 
            return returnsToDisplay.sort( (a, b) => b.isbn.localeCompare(a.isbn, 'en', {'sensitivity': 'base'}));
            else 
            return returnsToDisplay.sort( (a, b) => a.isbn.localeCompare(b.isbn, 'en', {'sensitivity': 'base'}));
          }

          return false;

        }

        const ccEnding = (r, l) => {
          const currCardIndex=l.findIndex((cc => cc.cardId == r));
          return l[currCardIndex]?.cardLastFour;
        }

        const submitReturn = (() => {
          //const submissionReturns = [];
          dirtyHandler.setLoadingTakeoverConfig({
            text: 'Submitting return . . .',
            longLoadText: 'Still submitting return . . .',
            inverted: true,
            backgroundBlurLight: true,
          });
          dirtyHandler.setLoadingTakeover(true);
          const submissionReturns = returns.map((r) => {
            return ({isbn: r.isbn, qtyReturn: r.qtyReturn});
          });
          const submission ={
            "shipTo": account,
            "returnStartDate": returnWindow,
            "cardId": returnCreditCard,
            "returns": submissionReturns,
            };
          try {          
            performSubmitReturns({returns: submission, sapAccountNo: account, submissionType: 'update'})
              .then((response) => {
                if (response.data?.status === 'OK') {
                  //setOpenChooseCC(false);
                  dirtyHandler.setLoadingTakeover(false);
                  setOpenChooseCC(false);
                  if (returnsRequested) {
                  setOpenReturnsUpdatedSuccess(true);
                  } else {
                  setOpenReturnsSuccess(true); 
                  }
                  setOpenReturnsUpdate(false);
                  setOpenNoCConFile(false);
                  setChanges([]);
                  refreshReturns();
                } else {
                }
              });
            } catch (e) {
            }
        });

        const cancelReturn = (() => {
          const submission ={
            "shipTo": account,
            "returnStartDate": returnWindow,
            //"cardId": returnCreditCard,
            //"returns": submissionReturns,
            };
          try {
            dirtyHandler.setLoadingTakeoverConfig({
              text: 'Updating return . . .',
              longLoadText: 'Still updating return . . .',
              inverted: true,
              backgroundBlurLight: true,
            });
            dirtyHandler.setLoadingTakeover(true);           
            performSubmitReturns({returns: submission, sapAccountNo: account, submissionType: 'cancel'})
              .then((response) => {
                if (response.data?.status === 'OK') {
                  setOpenReturnsCancel(false);
                  refreshReturns();
                } else {
                }
              });
            } catch (e) {
            }
        });

        const refreshReturns = () => {
          dirtyHandler.setLoadingTakeoverConfig({
            text: '',
            longLoadText: '',
            inverted: true,
            backgroundBlurLight: true,
          });
          dirtyHandler.setLoadingTakeover(true);
          try {
            performGetReturns({
              returnsStartDate: returnWindow,
              sapAccountNo: account}
            )
            .then((response) => {
              if (response.data?.status === 'OK') {
                setReturns(JSON.parse(JSON.stringify(response?.data?.data?.returns))); // default response?.data?.data?.returns
                setReturnsUndo(JSON.parse(JSON.stringify(response?.data?.data?.returns))); // default response?.data?.data?.returns
                if (response?.data?.data?.lastUpdateDate===null || response?.data?.data?.returns.findIndex((obj => obj.qtyReturn > 0))===-1) {
                  setReturnsRequested(false); //real value false
                  setReturnsRequestedTxt('No return requested');
                  setReturnCreditCard(null);
                  setRequestDisabled(true);
                } else {
                  setReturnsRequested(true); //real value true
                  setReturnsRequestedTxt(`Return requested ${showDateMMMDYYYY(response?.data?.data?.lastUpdateDate)}`);
                  setDateOfReturn(response?.data?.data?.lastUpdateDate);
                  setTimeOfReturn(response?.data?.data?.lastUpdateTime);
                  setSubmitButtonTxt('Update request');
                  setRequestDisabled(true);
                  setReturnCreditCard(response?.data?.data?.cardId ? response?.data?.data?.cardId:'');
                  setReturnCreditCardRevert(response?.data?.data?.cardId ? response?.data?.data?.cardId:'');
                }
                dirtyHandler.setDialogObject(dialogObject);
                dirtyHandler.setLoadingTakeover(false);
                setBlockNav(false);
                setShowUndo(false);
                //setShowUndoAll(false);
                
              } else {
              }
              setLoading(false);
            });
        } catch (e) {
        }
          
        }

        const returnState = () => {

        }

        useEffect(() => {
          function handleWindowResize() {
            setWindowSize(getWindowSize());
          }
      
          window.addEventListener('resize', handleWindowResize);
      
          return () => {
            window.removeEventListener('resize', handleWindowResize);
          };
        }, []);
        
        useEffect(() => {
          setReturnWindow(getStartOfCurrentMonth()); //default:getStartOfCurrentMonth(), example:'2022-09-01'//return data window
        }, [returnWindow]);

        useEffect(() => {
          const testIndex = props.cart?.customerInfo?.shipTos?.findIndex((obj => obj.accountNo == props.cart?.getShipToAccount().accountNo));
          if (props.cart?.estimateCarts && props.cart?.estimateCarts[0]) {
            const index = props.cart?.customerInfo?.shipTos?.findIndex((obj => obj.accountNo == props.cart?.estimateCarts[0].headers.shipToAccountNo));
            setAccount(props.cart?.estimateCarts[0].headers.shipToAccountNo);
            setAddress(props.cart?.customerInfo?.shipTos[index]?.address);
            setCashOnly(props.cart?.customerInfo?.customer?.cashOnly);//props.cart?.customerinfo?.customer?.cashOnly
          } else if(props.cart?.customerInfo?.shipToAccountNos.length == 1) {
            //setAccount(props.cart?.estimateCarts[0].headers.shipToAccountNo);
            //setAddress(props.cart?.customerInfo?.shipTos[index]?.address);
            setAccount(props.cart?.customerInfo?.shipToAccountNos[0].accountNo);
            setAddress(props.cart?.customerInfo?.shipToAccountNos[0].address);
            setCashOnly(props.cart?.customerInfo?.customer?.cashOnly);//props.cart?.customerinfo?.customer?.cashOnly
         } else {
            const index = props.cart?.customerInfo?.shipTos?.findIndex((obj => obj.accountNo == props.cart?.getShipToAccount().accountNo));
            setAccount(props.cart?.getShipToAccount().accountNo ? props.cart?.getShipToAccount().accountNo: null);
            setAddress(props.cart?.customerInfo?.shipTos[index]?.address);
            setCashOnly(props.cart?.customerInfo?.customer?.cashOnly);//props.cart?.customerinfo?.customer?.cashOnly
          }
        }, [props.cart?.estimateCarts]);

        useEffect(() => {
          if (account !=null) {
          refreshReturns();
          } else {
          setLoading(false);
          setRequestDisabled(true);
          }
          
        }, [account]);

        useEffect(() => {
          const winHeight = window.screen.height *(80/100);
          //props.app.ecomApi.getCreditCards()
          //if (state.openChooseCC) {
            props.cart.getCreditCards().then(result=>{
              if (result.data?.status === 'OK') {
                result.data.data.creditCards && setCreditCardList(result.data.data.creditCards);
                let expiredCards = 0;
                let expiringCards= 0;
                result.data.data.creditCards && result.data.data?.creditCards.forEach((card) => {
                  if (isCreditCardExpired(
                      card.cardExpiryMonth,
                      card.cardExpiryYear
                    )==true) {
                      expiredCards++; 
                    }

                    console.log('cc expiry 1', willCreditCardExpire(
                      card.cardExpiryMonth,
                      card.cardExpiryYear
                    ));

                    if (willCreditCardExpire(
                      card.cardExpiryMonth,
                      card.cardExpiryYear
                    )==true) {
                      expiringCards++; 
                    }
                 });
                console.log('cc expiry 2', expiredCards);
                console.log('cc expiry 3', expiringCards);

                 if (expiredCards + expiringCards == result.data.data?.creditCards.length) {
                   setNoValidCC(true);
                 }
                 if (result.data.data?.creditCards.length==0) {
                  setNoValidCC(true);
                }
                //ccEnding();
                //cc.data.data?.creditCards.forEach((c) => {
                  //if (c.cardId === cart.cart.headers.cardId) {
                    //setCreditCards(c);
                  //}
                //});
              }
            });
          //}
        }, [account]);

        useEffect(() => {
          checkReturnsChanges();
        }, [returns]);


        return (
          <>
          {loading? (<LoadingView />): (
          <div id="ar">
            <PageStandard
              headerType="loggedIn"
              containerFluid={true}
              cartBadge={true}
              locationSelector={false}
            ><div>
            <div className="dropdown-holder">
            <DropdownStoreSelectorReturns
            shipTo={props.cart.shipTo}
            selectShipTo={(shipTo) => props.cart.setShipTo(shipTo)}
            navTriggered={(e)=>setNavTriggered(e)}
            blockNav={showUndo}
            />
            </div>
            <div className="od-metadata-container">
              <Container className={`${account===null || returns.length === 0 ? ("return-header"):'od-header'}`}>
                <div className="od-header-head">
                <NoticeCard>
                      Submitting affidavit returns is available on desktop only.
                      </NoticeCard>
                  <div className="od-button-right-container">
                    <div className="iconholder hide-in-print mr-1">
                      {/*<Icon
                        color="black"
                        name="file alternate"
                        size="large"
                        />*/}
                      <img
                        name="icon returns"
                        src={IconReturns}
                        //className="mr-1"
                        color="grey"
                      />
                    </div>
                    <div
                            className="od-header-text"
                            onClick={() =>{}}
                          >
                            Comic Book Affidavit Return
                          </div>
                  </div>
        
      <div className={"d-flex"}>
      {!isMobile && account && returns.length > 0 ? (
        <div
          className={`d-flex align-items-center pl-1 pr-1 foc-status ${returnsRequested && returnsRequested !=false ? 
            'returns-requested':'no-returns-requested'}`}
        >
          <Icon 
          name={`${returnsRequested && returnsRequested !=false ? 'check': 'hourglass half'}`} 
          style={returnsRequested && returnsRequested ===false ? {color:'#AAAAA'} : {}}
          className={`${returnsRequested && returnsRequested !=false ? 'icon-white': 'icon-grey'}`} 
          color="white"/>
          <span className={`fort-bold fs-13px lh-15 ${returnsRequested && returnsRequested !=false ? 
            'return-text':''}`}>{returnsRequestedTxt}</span>
       
          </div>):''}

        {!isMobile && returnsRequested && returnsRequested === true ? (       
                 <ActionMenu
                 backgroundColor={'gray-100'}
                 borderColor={'gray-100'}
                 actions={cashOnly==true ? ([
                  {
                    key: 1,
                    disabled:
                      false,
                    content: (
                      <div
                        onClick={(e) =>{
                         setOpenReturnsCancel(true);
                         setOpenCancelMenu(false);
                        }}
                      >
                        Cancel return request
                      </div>
                    ),
                    value: 'clearcart',
                  },
                    {key: 2,
                    disabled: false,
                    content:(
                      <div 
                      onClick={() => {
                      setOpenCancelMenu(false);
                      if (noValidCC) {
                        setOpenNoCConFile(true);
                        } else {
                        setCCButtonLabel('Change card');
                        setOpenChooseCC(true);
                        }
                      }}>{returnCreditCard ? 'Change credit card' : 'Choose credit card'}
                    </div>)
                    //value: 'clearcart',
                  //):''
                  }
                 ]):(
                  [
                    {
                      key: 1,
                      disabled:
                        false,
                      content: (
                        <div
                          onClick={(e) =>{
                           setOpenReturnsCancel(true);
                           setOpenCancelMenu(false);
                          }}
                        >
                          Cancel return request
                        </div>
                      ),
                      value: 'clearcart',
                    }
                   ]
                 )}
               />
        ):''
        }
        </div>
                </div>
                <div className="od-header-subhead">
                          <div>
                            <span className="od-text-data-medium">
                              <span className="fort-bold" style={{marginRight:'4px'}}>Return window:</span> {showDateByMonth()} (by 11:59PM ET)
                            </span>
                          </div>
                  </div>
        {isMobile && account && returns.length > 0 ? (
        <>
        <div
          className={`d-flex align-items-center pl-1 pr-1 foc-status mobile-return-request ${returnsRequested && returnsRequested !=false ? 
            'returns-requested':'no-returns-requested'}`}
        >
          <Icon 
          name={`${returnsRequested && returnsRequested !=false ? 'check': 'hourglass half'}`} 
          style={returnsRequested && returnsRequested ===false ? {color:'#AAAAA'} : {}}
          className={`${returnsRequested && returnsRequested !=false ? 'icon-white': 'icon-grey'}`} 
          color="white"/>
          <span className={`fort-bold fs-13px lh-15 ${returnsRequested && returnsRequested !=false ? 
            'return-text':''}`}>{returnsRequestedTxt}</span>
          </div>
          <Divider className="mb-0" tight />
          </>
          ):''}
              </Container>
              {account && returns.length > 0 ? (
              <Container className="od-metadata-data d-flex dont-show">
              <div className="ar-header-left">
                          <div>
                            <span className="od-text-data-medium">
                              Account #:
                            </span>{' '}
                            {account}<br/>
                            {address ? (
                            <>
                            <span className={"address-capitalization"}>{address.name1}</span><br/>
                            {address.street}<br/>
                            {address.city}, {address.state} {address.postalCode}<br/>
                            </>
                            ):''}   
                          </div>   
                  <div className="fs-2 mt-1">
                {returnCreditCard ? (
                  <>
                  <Icon
                  color="orange"
                  //name={`caret ${sortReturns.caretDirection}`}
                  name="credit card"
                  size="med"
                  />

                    <span className={"fort-bold"}>Return credit:</span> Card ending in <span className={"fort-bold"}>{ccEnding(returnCreditCard, creditCardList)}</span> {/*returnCreditCard.cardLastFour*/}
                    <FontAwesomeIcon
                    icon={faPencilAlt}
                    className="return-edit-cc-icon mr-1" //notice-icon
                    onClick={()=>{
                      setCCButtonLabel('Change card');
                      setOpenChooseCC(true);
                    }}
                    //color="#FA6400"
                    />
                </>
                ):''}
                {returnsRequested && !returnCreditCard && returnsRequested === true ? (
                  <><div className={'d-flex'}><span className={"fort-bold pr-1"}>Return credit:</span> Applied to account.
                  {cashOnly==true ? (
                  <div className="ml-1"><FontAwesomeIcon
                  icon={faPencilAlt}
                  className="return-edit-cc-icon mr-1" //notice-icon
                  onClick={()=>{
                    if (noValidCC) {
                    setOpenNoCConFile(true);
                    } else {
                    setCCButtonLabel('Change card');
                    setOpenChooseCC(true);
                    }

                  }}
                  //color="#FA6400"
                  /></div>):''}
                  </div></>
                ):''}
                  </div>
                </div>
                <div className="ar-header-right">
                <div className="ar-order-now-container returns-message">
                          <p><b>Note:</b> The submission of claims below assumes acceptance of an affidavit returns process. Each Comic Book claimed for return must have its cover stripped and the unit rendered unsellable. Both stripped covers and remaining issue pages should immediately be discarded or recycled. Penguin Random House reserves the right to modify, add terms or conditions to or discontinue this policy at any time.</p>
                </div></div>
              </Container>):''}
            </div>

            {account && returns.length > 0 ? (<Container
                className="od-status-container hide-in-print"
                key={3}>
                <div className="d-flex">
                {!isMobile && (
                <div id="ar-form" style={{marginBottom:'20px', width: '400px'}}>
                  <Form >
                    <Input
                      className={"ar-input"}
                      iconPosition='left'
                      id="searchlist"
                      name="searchlist"
                      type="text"
                      onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                      icon={{
                        name: 'search',
                        link: true,
                        //onClick: () => onFilterTextBoxChanged(),
                      }}
                      onChange={(e)=>{search(e)}}
                      //items={returns}
                      /*onChange={(e, data) => {
                        if (data.value !== '' && state.hasInput === false) {
                          setState({ ...state, hasInput: true });
                        } else if (data.value === '') {
                          setState({ ...state, hasInput: false });
                        }
                      }}*/
                      //disabled={state.isLoading}
                      placeholder={'Search Titles or UPC/ISBN'}
                    />
                  </Form>
                </div>
        )}
        <div className="d-flex title-returns" style={{marginLeft: 'auto'}}>
        {showUndo && !isMobile ? (
        <>
        <PRHButtonStandard
         className="ot-button ot-nav-button"
         size="medium"
         backgroundColorOverride="white"
         backgroundColorDisabledOverride="white"
         borderColorOverride="grey"
         onClick={()=>undoAll()}
         >
         Undo All
         </PRHButtonStandard>
        <PRHButtonStandard
         className="ot-button ot-nav-button"
         size="medium"
         backgroundColorOverride="white"
         backgroundColorDisabledOverride="white"
         borderColorOverride="grey"
         onClick={()=>undo()}
         disabled={undoDisabled}
         >
         Undo 
         </PRHButtonStandard>
         </>
        ):''}
       {!isMobile ? (
         <PRHButton
            //size="big-wide"
            //bold={true}
            disabled={requestDisabled}
            //className="fort-bold"
            btnTxtOverride="fort-weight-bold"
            borderColorOverride="blue"
            textColorOverride="white"
            textColorHoverOverride="blue"
            backgroundColorOverride="blue"
            backgroundHoverColorOverride="white"
            iconColorOverride={requestDisabled ? 'grey' : 'white'}
            iconColorHoverOverride="blue"
            icon="check"
            style={{ maxWidth: '100%' }}
            onClick={(e) => {
              //submitReturn(e);
              verifyReturnQtys();
              if (checkForReturns()===false) {
                setOpenReturnsCancel(true);
              } else if (returnsRequested) {
                setOpenReturnsUpdate(true);
              } else if (creditCardList.length > 0 && cashOnly==true && noValidCC==false) {
                setCCButtonLabel('Request return')
                setOpenChooseCC(true);
              } else if (noValidCC ==true && cashOnly==true) {
                setOpenNoCConFile(true);
               }else  {
               submitReturn();
              }
            }}
          >
            {returnsRequested ? 'Update request': 'Request return'}
          </PRHButton>
       ):''}
         </div>
        </div>
        <div className="sg-table-header" id="title-returns">
        <Grid container 
        columns={8}
        padded 
        className="cart-page mb-8">
        <Grid.Row 
          className="sg-header">
          <Grid.Column
            className="header-column d-lg-flex d-sm-none d-md-none pct-lg-1 pct-md-1 conditional-field"
          ><div 
          className="d-flex">UPC/ISBN 
          <Icon
          color="gray"
          name={`caret ${sortReturnsISBN ? sortReturnsISBN.caretDirection: 'down'}`}
          className={`${sortReturnsISBN && sortReturnsISBN.caretDirection==='up' ? 'caret-style-on':'caret-style-default'}`}
          size="med"
          onClick={()=>{
            setSortReturnsISBN(sortReturnsISBN && sortReturnsISBN.direction=='b' ? {direction:'a', caretDirection: 'down'}: {direction:'b', caretDirection: 'up'});
            setSortReturns(null);
        
        }}
        /></div> 
          </Grid.Column>
          <Grid.Column
            className="header-column d-lg-flex d-sm-none d-md-none pct-lg-2 pct-md-2 conditional-field"
          ><div className="d-flex">Title 
          <Icon
          color="gray"
          name={`caret ${sortReturns ? sortReturns.caretDirection: 'down'}`}
          className={`${sortReturns && sortReturns.caretDirection==='up' ? 'caret-style-on':'caret-style-default'}`}
          size="med"
          onClick={()=>{
            setSortReturns(sortReturns && sortReturns.direction=='b' ? {direction:'a', caretDirection: 'down'}: {direction:'b', caretDirection: 'up'});
            setSortReturnsISBN(null);
        }}
        /></div> 
          </Grid.Column>
          <Grid.Column
          //tablet={2}
            className="header-column d-md-flex d-lg-none pct-lg-2 pct-md-2 w-50"
          >
            ISBN/Title 
          </Grid.Column>
          <Grid.Column
            className="header-column pct-lg-3 pct-md-3 hide-mobile right-align-content"
          ><span>Format Code</span>
          </Grid.Column>
          <Grid.Column
            className="header-column pct-lg-4 pct-md-4 hide-mobile right-align-content"
          >List Price
          </Grid.Column>
          <Grid.Column
          className="header-column pct-lg-5 pct-md-5 hide-mobile right-align-content"
          >Unit Cost
          </Grid.Column>
          <Grid.Column
            className="header-column pct-lg-6 pct-md-6 w-25 right-align-content"
          >Eligible Qty
          </Grid.Column>
          <Grid.Column
            className="header-column pct-lg-7 pct-md-7 w-25 right-align-content last-column-mobile" 
          >Return Qty
          </Grid.Column>
          <Grid.Column
            className="header-column pct-lg-8 pct-md-8 hide-mobile right-align-content last-column" style={{borderLeft: '1px solid #dddddd'}}
          >Est. Credit
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <form
      onKeyPress={(e) => { if(e.key === 'Enter' || e.key === '-' || e.key === '+' || e.key === '.' ) {e.preventDefault();} }}
          >

        {account && returns.length > 0 ? Object.values(displayReturns()).map((showReturn, key) => (
          <TitleRowReturns
            data={showReturn}
            editReturn={(e, isbn)=>editReturn(e, isbn)}
            //testBlur={(e, isbn)=>testBlur(e, isbn)}
            correctReturn={(e, isbn)=>correctReturn(e, isbn)}
            inputDisabled={isMobile}
          />
      )):''}
      </form>
      {account &&  returns.length > 0 && displayReturns().length===0 ? (<div className="no-returns-display">No results found. Make sure your search request is spelled correctly or try different keywords.</div>):''}
            </div>
       </Container>):''}

       <Container className="od-status-container hide-in-print">
            {account === null ? (<div className="no-returns-display">Please select a location to place an affidavit return. </div>):''}
            {account &&  returns.length === 0 && !loading && !searchTerms && !searchISBN ? (<div className="no-returns-display">You have no eligible titles for this return window.</div>):''}
                        </Container>
        </div>
            <div id="returns-global">
            <AffadavitReturnsChooseCC 
            closeIcon
            open={openChooseCC}
            onClose={() => {
              setReturnCreditCard(returnCreditCardRevert);
              setOpenChooseCC(false);
            }}
            creditCardList={creditCardList}
            handleCCChange={(e)=>handleCCChange(e)}
            returnCreditCard={returnCreditCard}
            submitReturn={()=>submitReturn()}
            ccButtonLabel={ccButtonLabel}
            ccModalMaxHeight={windowSize.innerHeight * 25/100}
            />
            <AffadavitReturnsUpdate
              open={openReturnsUpdate}
              onClose={() => setOpenReturnsUpdate(false)}
              submitReturn={()=>submitReturn()}
              afdDate = {dateOfReturn ? showDateMMMMDYYYY(dateOfReturn):''}
              afdTime = {timeOfReturn ? showTimeHHMM(timeOfReturn):''}
            />
            <AffadavitReturnsUpdatedSuccess
              open={openReturnsUpdatedSuccess}
              onClose={() => setOpenReturnsUpdatedSuccess(false)}
              //headerText={returnsRequested ? 'Return request updated' : 'Return request submitted' }
              //date={showDateMMMDYYYY(dateOfReturn)}
              //time={showTimeHHMMa(timeOfReturn)}
              
              //submitReturn={()=>submitReturn()}
            />
            <AffadavitReturnsSuccess
              open={openReturnsSuccess}
              onClose={() => setOpenReturnsSuccess(false)}
              //headerText={returnsRequested ? 'Return request updated' : 'Return request submitted' }
              //date={showDateMMMDYYYY(dateOfReturn)}
              //time={showTimeHHMMa(timeOfReturn)}
              
              //submitReturn={()=>submitReturn()}
            />
            <AffadavitReturnsCancel
              open={openReturnsCancel}
              onClose={() => setOpenReturnsCancel(false)}
              cancelReturn={()=>cancelReturn()}
              afdDate = {dateOfReturn ? showDateMMMMDYYYY(dateOfReturn):''}
              afdTime = {timeOfReturn ? showTimeHHMM(timeOfReturn):''}
              //date={showDateMMMDYYYY(dateOfReturn)}
              //time={showTimeHHMMa(timeOfReturn)}
              
              //submitReturn={()=>submitReturn()}
            />
            <AffadavitNoCConFile
              open={openNoCConFile}
              onClose={() => setOpenNoCConFile(false)}
              submitReturn={()=>submitReturn()}
              afdDate = {dateOfReturn ? showDateMMMMDYYYY(dateOfReturn):''}
              afdTime = {timeOfReturn ? showTimeHHMM(timeOfReturn):''}
            />
            <AffadavitReturnsBlockNav
              open={navTriggered && showUndo}
              onClose={() => setNavTriggered(false)}
              //submitReturn={()=>submitReturn()}
              discardChanges={()=>{
                setNavTriggered(false);
                setShowUndo(false);
              }}
              afdDate = {dateOfReturn ? showDateMMMMDYYYY(dateOfReturn):''}
              afdTime = {timeOfReturn ? showTimeHHMM(timeOfReturn):''}
            />
            <AffadavitReturnsWindowClosed
              open={returnsWindowClosed}
              onClose={() => setReturnsWindowClosed(false)}
              //submitReturn={()=>submitReturn()}
              //afdDate = {dateOfReturn ? showDateMMMMDYYYY(dateOfReturn):''}
              //afdTime = {timeOfReturn ? showTimeHHMM(timeOfReturn):''}
            />
            <div id="ar-variants">
            <Prompt 
            //when={shouldBlockNavigation}
            //when={navTriggered}
            when={blockNav}
            message="Are you sure you want to leave?"
            />
            </div>
            </div>
            </PageStandard>
          </div>)}
          </>
        );
      })
    )
  )
);



