import { Component } from "react";
import { Container, Grid, Image, Icon } from "semantic-ui-react";
import { withRouter } from "react-router";
import Logo from "../../assets/images/prh-logo-small.png";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import "./ErrorPage.scss";
import PageHeader from "../../components/header/PageHeader";
import withSecurity from "../../components/hc/withSecurity";
class ErrorPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorModal: true,
      techOpen: false,
    };
  }
  close = (e, d) => {
    this.setState({ errorModal: false });
  };

  UNSAFE_componentWillUpdate(nextState, nextProps) {
    if (nextProps?.error?.status === 401) {
      // console.log('starting reaload timer');
      if (this.redirectTimer) clearTimeout(this.redirectTimer);
      this.redirectTimer = setTimeout(() => {
        this.reloadHome();
      }, 5000);
    }
  }

  render() {
    // console.log(this.props);
    console.log("ErrorPage", this.props);
    if (
      this.props.error.status === 500 &&
      this.props.error?.error?.status === 0
    ) {
      return <div>Error Occured</div>;
    }

    return (
      <div className='App position-absolute w-100 h-100 bg-gray-100 error-page'>
        <PageHeader headerType='loggedOut' cartBadge={false} />
        <Container className='mt-5 Xheader-container'>
          <div className='err-box-border d-flex mt-3'>
            <div className='bg-white p-6 w-100'>
              {this.props.error.status === 401 ? (
                <div
                  className='fs-4 fort-extrabold text-gray-900 mb-4'
                  style={{ lineHeight: "1.2" }}
                >
                  Your session has expired, you will be redirecting you to the
                  login page...
                </div>
              ) : (
                <div className='fs-6 fort-extrabold text-gray-900 mb-4'>
                  {this.props.from === "title"
                    ? "This page is not available"
                    : "Oops!"}
                </div>
              )}
              <div className='fort-light fs-3 text-gray-900 mb-4'>
                {this.props.error.status === 401 ? (
                  <div>{this.props.error.statusText}</div>
                ) : (
                  <>
                    {this.props.from === "title"
                      ? "This product page is not available at the moment."
                      : "Something has gone wrong."}
                  </>
                )}
              </div>
              {this.props.error.status !== 401 ? (
                <div className='mb-4'>
                  <PRHButton
                    icon='left arrow'
                    onClick={(e) => {
                      window.location.href = this.props.location.pathname;
                    }}
                  >
                    Go Back
                  </PRHButton>
                </div>
              ) : (
                <></>
              )}

              {this.props.from !== "title" && (
                <div className='fort-light mb-1'>
                  <div>If you keep seeing this page, contact</div>
                  {this.props.user.isAppCompCopy() ? (
                    <a href='mailto:csreviews@prh.com'>csreviews@prh.com</a>
                  ) : (
                    <a href='mailto:bizcs@prh.com'>bizcs@prh.com</a>
                  )}
                  <div>and mention this error code:</div>
                </div>
              )}
              <div
                className={`r-view-more fort-bold mb-2 text-uppercase cursor-pointer ${
                  this.state.techOpen
                    ? " text-orange-important"
                    : " text-gray-500"
                } `}
                onClick={(e) =>
                  this.setState({ techOpen: !this.state.techOpen })
                }
              >
                view more details{" "}
                <Icon
                  name={`angle ${this.state.techOpen ? "up" : "down"}`}
                  className={`${
                    this.state.techOpen
                      ? " text-orange-important"
                      : " text-gray-500"
                  }`}
                ></Icon>
              </div>
              <div
                className={`shadow p-3 r-tech ${
                  this.state.techOpen && " r-tech-open"
                }`}
              >
                <div>
                  Error:{" "}
                  {this.props.error?.response?.message
                    ? this.props.error.response.message
                    : this.props.error?.status}
                </div>
                <div>
                  {this.props.error.status && (
                    <div className='mt-1'>
                      Status Code:&nbsp;
                      {this.props.error.status}
                    </div>
                  )}

                  {this.props.error.data && (
                    <div>
                      <div className='mt-1'>
                        {`Url: ${this.props.error.data.path}`}
                      </div>
                    </div>
                  )}
                  <div className='mt-1'>
                    Messages:
                    {this.props.error.statusText && (
                      <div className='ml-2'>{this.props.error.statusText}</div>
                    )}
                    {this.props.error.error && (
                      <div className='ml-2'>{this.props.error.error}</div>
                    )}
                    {this.props.error?.data?.message && (
                      <div className='ml-2'>
                        {this.props.error.data.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="d-flex err-box-border-left p-6 flex-column w-40 r-text fort-light">
              <div>To have no errors</div>
              <div>Would be life without meaning</div>
              <div>No struggle, no joy</div>
            </div>*/}
          </div>
        </Container>
      </div>
    );
  }

  reloadHome = (e) => {
    this.props.history.go(-1);
  };
}

export default withRouter(withSecurity(ErrorPage));
