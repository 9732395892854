import { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class orderNumberRenderer extends Component {
  render() {
    const { salesOrderNumber } = this.props.data;
    return (
      <Link
        to={`/orders/${salesOrderNumber}`}
        // onClick={(e) => {
        //   e.preventDefault();
        //   this.props.history.push(`/orders/${salesOrderNumber}`);
        // }}
      >
        {salesOrderNumber}
      </Link>
    );
  }
}

export default withRouter(orderNumberRenderer);
