import { Children, cloneElement, Fragment, useState, useEffect } from "react";
import { Modal, Image, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
// import Logo from '../../assets/images';
import PRHButton from "../../shared-react-components/elements/prhbutton";
import MessageGenerator from "../../shared-react-components/utils/MessageGenerator";
import "./DialogModal.scss";

const DialogModal = (props) => {
  const {
    returnOnClose,
    open,
    setOpen,
    options,
    dialogOptions,
    children,
    callback,
    closeOnEscape,
    closeOnDimmerClick,
    history,
    onClose,
    onXClose,
    modalClassName,
    modalContentClass,
    contentPx,
    contentPt,
    size,
    isMobile,
  } = props;

  const defaultDialogOptions = {
    header: <div>Logo</div>,
    actions: [],
  };
  const defaultModalOptions = {
    size: size,
  };

  const dOptions = { ...defaultDialogOptions, ...dialogOptions };
  const modalOptions = { ...defaultModalOptions, ...options };
  const [intOpen, setIntOpen] = useState(true);
  const [message, setMessage] = useState(false);
  const [pending, setPending] = useState(false);
  const [actionId, setActionId] = useState(false);
  const [proceedState, setProceedState] = useState(false);

  let hasVisibleActions = false;
  if (
    Array.isArray(dOptions.actions) &&
    dOptions.actions.length > 0 &&
    dOptions.actions.some((item) => !item.hidden)
  ) {
    hasVisibleActions = true;
  }

  // reset the state every time open changes
  useEffect(() => {
    setMessage(false);
    setProceedState(false);
    setPending(false);
  }, [open]);

  let closeIconClass =
    "d-flex align-items-center justify-content-center modal-close-icon cursor-pointer fs-2 position-absolute";
  if (pending) {
    closeIconClass += " modal-close-icon--disabled";
  }

  const handleClose = () => {
    if (onClose) {
      onClose();
      if (returnOnClose) {
        return;
      }
    }

    if (open) {
      setOpen(false);
    } else {
      setIntOpen(false);
      setOpen(null);
    }
  };

  const closeIcon = (
    <PRHButton
      className={`${!!!dOptions.header ? "ml-auto mt--2" : ""}`}
      iconOnlyBorderAlways
      iconClassName='fs-24px'
      borderColorOverride='white'
      borderHoverColorOverride='white'
      iconColorOverride='gray'
      icon='times'
      fontAwesome
      size='small'
      disabled={!!pending}
      onClick={() => {
        if (!pending) {
          onXClose && onXClose();
          handleClose();
        }
      }}
    />
  );

  const startDecorationRecusion = (children) =>
    Children.map(children, (child, i, array) => {
      if (!child || !Object.prototype.hasOwnProperty.call(child, "props")) {
        return <Fragment key={i}>{child}</Fragment>;
      }

      if (
        Object.prototype.hasOwnProperty.call(child, "props") &&
        Object.prototype.hasOwnProperty.call(child.props, "actionId") &&
        child.props.actionId.constructor === String &&
        typeof dOptions.actions[child.props.actionId] !== "undefined"
      ) {
        const {
          action = () => ({ proceed: true }),
          label,
          icon = null,
          disabled = false,
          preAction = () => ({ proceed: true }),
        } = dOptions.actions[child.props.actionId];

        const composedAction = async () => {
          setPending(true);
          setMessage(false);
          setProceedState(true);
          setActionId(child.props.actionId);
          const preActionResult = await preAction();
          const {
            proceed,
            message: msg,
            cancelAction = false,
            redirect = null,
          } = preActionResult;
          if (proceed) {
            if (redirect && history) {
              history.push(redirect);
            }
            const actionResult = await action(preActionResult);

            const { handleCloseAction = true } = actionResult || {};
            setPending(false);

            if (msg) {
              setMessage(msg);
              await new Promise((resolve) => setTimeout(resolve, 3000));
            }

            if (callback) {
              callback(!cancelAction);
            }
            if (handleCloseAction) {
              handleClose();
            }
            return;
          }
          setProceedState(false);
          setPending(false);
          setMessage(msg);
        };

        const spinner = pending &&
          !hasVisibleActions &&
          actionId === child.props.actionId && (
            <div className='modal-spinner modal-spinner--h-auto d-inline-block ml-3'>
              <Loader inline size='tiny' active className='' />
            </div>
          );

        const disabledButtonState =
          Object.prototype.hasOwnProperty.call(child.props, "disabled") &&
          !!child.props.disabled;
        return (
          <>
            {cloneElement(child, {
              onClick: composedAction,
              disabled: pending || disabledButtonState,
            })}
            {spinner}
          </>
        );
      }

      if (child.props.children) {
        return cloneElement(child, {
          children: startDecorationRecusion(child.props.children),
        });
      }

      return child;
    });

  const modalOpenState = open === true || open === false ? open : intOpen;

  return (
    <Modal
      className={dOptions.className ? dOptions.className : modalClassName}
      onClose={handleClose}
      open={modalOpenState}
      size={size}
      {...modalOptions}
      closeOnEscape={!pending && closeOnEscape}
      closeOnDimmerClick={!pending && closeOnDimmerClick}
    >
      {dOptions.header && (
        <Modal.Header className='d-flex justify-content-between px-4 py-2 position-relative text-uppercase align-items-center'>
          {dOptions.header}
          {closeIcon}
        </Modal.Header>
      )}
      <Modal.Content
        className={`${contentPx} ${contentPt} ${modalContentClass}`}
      >
        {!proceedState && message ? (
          <MessageGenerator message={message} />
        ) : (
          <div />
        )}
        {!!!dOptions.header && <> {closeIcon} </>}
        {children && startDecorationRecusion(children)}
      </Modal.Content>
      {hasVisibleActions && (
        <div className={`${isMobile ? "mobile-modal" : ""}`}>
          <Modal.Actions
            className={`d-flex justify-content-end modal-content--actions modal-content--actions-${
              modalOptions.size
            } bg-white position-relative no-border pt-2 ${
              isMobile ? "pb-1 px-2" : "pb-4 px-4"
            }`}
          >
            <div
              className={`d-flex ${isMobile ? "mobile-actions-holder" : ""}`}
              style={{ flex: "100%", justifyContent: "flex-end" }}
            >
              {!pending && !proceedState && (
                <>
                  {dOptions.actions.map((o, i, array) => {
                    if (
                      Object.prototype.hasOwnProperty.call(o, "hidden") &&
                      o.hidden
                    ) {
                      return <Fragment key={i} />;
                    }
                    if (
                      Object.prototype.hasOwnProperty.call(
                        o,
                        "actionMessage"
                      ) &&
                      o.actionMessage
                    ) {
                      return <Fragment key={i}>{o.actionMessage}</Fragment>;
                    }
                    let mr = array.length === i + 1 ? "" : "mr-2";
                    const {
                      action = () => ({ proceed: true }),
                      label,
                      icon = null,
                      disabled = false,
                      iconright = false,
                      textColorOverride = null,
                      focusColorOverride = null,
                      backgroundColorOverride = null,
                      borderHoverColorOverride = null,
                      borderColorOverride = null,
                      textColorHoverOverride = null,
                      backgroundHoverColorOverride = null,
                      iconColorOverride = null,
                      iconColorHoverOverride = null,
                      iconColorDisabledOverride = null,
                      bold = null,
                      className = "",
                      preAction = () => ({ proceed: true }),
                    } = o;
                    if (className) {
                      mr += ` ${className}`;
                    }
                    const composedAction = async () => {
                      setPending(true);
                      setMessage(false);
                      setProceedState(false);
                      const preActionResult = await preAction();
                      const {
                        proceed,
                        message: msg,
                        cancelAction = false,
                        redirect: preActionRedirect = null,
                      } = preActionResult;
                      if (proceed) {
                        if (preActionRedirect && history) {
                          history.push(preActionRedirect);
                        }
                        setProceedState(true);
                        const actionResult = await action(preActionResult);
                        const {
                          handleCloseAction = true,
                          redirect: actionRedirect = null,
                        } = actionResult;
                        if (actionRedirect && history) {
                          return history.push(actionRedirect);
                        }
                        if (msg) {
                          setMessage(msg);
                          await new Promise((resolve) =>
                            setTimeout(resolve, 3000)
                          );
                        }

                        if (callback) {
                          callback(!cancelAction);
                        }
                        if (handleCloseAction) {
                          handleClose();
                        }

                        return;
                      }
                      setProceedState(false);
                      setPending(false);
                      setMessage(msg);
                    };
                    return (
                      <PRHButton
                        borderColorHoverOverride={borderHoverColorOverride}
                        // borderColorHoverOverride={borderHoverColorOverride}
                        focusColorOverride={focusColorOverride}
                        backgroundColorOverride={backgroundColorOverride}
                        textColorOverride={textColorOverride}
                        borderColorOverride={borderColorOverride}
                        textColorHoverOverride={textColorHoverOverride}
                        backgroundHoverColorOverride={
                          backgroundHoverColorOverride
                        }
                        iconColorOverride={iconColorOverride}
                        iconColorHoverOverride={iconColorHoverOverride}
                        iconColorDisabledOverride={iconColorDisabledOverride}
                        iconright={iconright}
                        key={i}
                        onClick={() => composedAction()}
                        icon={icon}
                        className={mr}
                        disabled={disabled}
                        bold={bold}
                      >
                        {label}
                      </PRHButton>
                    );
                  })}
                </>
              )}
            </div>
            {pending && (
              <div className='modal-spinner d-flex justify-content-center mb-0'>
                <Loader
                  size='small'
                  active
                  className='position-static border absolute-center absolute-center-offset'
                />
              </div>
            )}
            {message && proceedState && <MessageGenerator message={message} />}
          </Modal.Actions>
        </div>
      )}
    </Modal>
  );
};

DialogModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  options: PropTypes.object,
  callback: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  dialogOptions: PropTypes.object.isRequired,
  closeOnDimmerClick: PropTypes.bool,
  closeOnEscape: PropTypes.bool,
  history: PropTypes.object,
  onClose: PropTypes.func,
  onXClose: PropTypes.func,
  returnOnClose: PropTypes.bool,
  modalContentClass: PropTypes.string,
  contentPx: PropTypes.string,
  contentPt: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

DialogModal.defaultProps = {
  children: false,
  callback: () => {},
  closeOnEscape: false,
  closeOnDimmerClick: true,
  history: null,
  open: null,
  setOpen: () => {},
  options: {},
  onClose: null,
  onXClose: null,
  returnOnClose: false,
  modalContentClass: "",
  contentPx: "px-4",
  contentPt: "pt-4",
  size: "small",
};
export default DialogModal;
