import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import useApp from "../../../components/hc/useApp";
import LoadingContent from "./LoadingContent";
import PostDigest from "./PostDigest";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../../utils/const";
import { useGetHomepagePostsQuery } from "../../../api/bizcontentApi";

const LatestPostsDigest = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const app = useApp();
  const [shrunk, setShrunk] = useState(true);
  const { data: posts, isSuccess } = useGetHomepagePostsQuery();

  if (!isSuccess) {
    return <LoadingContent />;
  }
  // console.log("posts", posts);
  if ((!isMobile && !Array.isArray(posts)) || typeof posts === "string") {
    return <></>;
  }
  let count = 3;
  if (!shrunk) {
    count = 9;
  }
  let output;
  if (isMobile) {
    output =
      posts && posts.length > 0 ? (
        posts
          .slice(0, count)
          .map((post, i) => <PostDigest key={i} post={post} />)
      ) : (
        <div className='d-flex justify-content-center flex-column align-items-center w-100 mt-2'>
          <div className='no-content-icon-holder-circle-outline'>
            <Icon name='info' className='fs-16px' />
          </div>
          <br />
          <div className='fs-16px'>You don't have any recent news.</div>
        </div>
      );
  } else {
    output = posts
      .slice(0, count)
      .map((post, i) => <PostDigest key={i} post={post} />);
  }

  return (
    <div
      className={`p-3 border-gray-300 ${
        isMobile
          ? "mobile-catalogs-container border-gray-300 p-2 pb-3 mt-3"
          : "mb-5"
      }`}
      style={{ borderRadius: "3px", boxShadow: "0 1px 3px 0 rgba(0,0,0,0.15)" }}
    >
      <div
        className={`d-flex justify-content-between border-bottom-gray-300 align-items-center ${
          isMobile ? "pb-2" : ""
        }`}
      >
        {isMobile && (
          <div className='catalogs-box-title'>
            <p className='mb-0 fort-light text-gray-900 fs-20px'>
              News &amp; Updates
            </p>
          </div>
        )}
        {!isMobile && (
          <p className='fort-medium fs-16px lh-18px mb-2 mt-0'>
            News &amp; Updates
          </p>
        )}
        {!isMobile && (
          <div className='mb-2'>
            <Link
              to='/news-from-prh'
              className={`fs-13px lh-18px fort-book d-inline-block pr-2 ${
                isMobile ? "" : "border-right-gray-300"
              }`}
            >
              All News from PRH
            </Link>
            <Link
              to='/title-changes'
              className={`
              fs-13px lh-18px fort-book d-inline-block px-2 ${
                isMobile ? "" : "border-right-gray-300"
              }
            `}
            >
              All Title Changes
            </Link>
            <Link
              to='/posts'
              className='fs-13px lh-18px fort-book d-inline-block pl-2'
            >
              All Title Collections
            </Link>
          </div>
        )}
        {isMobile && (
          <div>
            <Link
              to='/news-from-prh'
              className='fs-13px lh-18px fort-book d-inline-block pr-2'
            >
              View All
            </Link>
          </div>
        )}
      </div>
      {output}

      {!isMobile ||
        (isMobile && posts && posts.length > 0 && (
          <p
            className='cursor-pointer hover-orange mt-2 d-flex align-items-center'
            onClick={() => setShrunk(!shrunk)}
          >
            <Icon
              className='fs-18px text-gray-500'
              name={`angle ${!!shrunk ? "down" : "up"}`}
            />{" "}
            <span className='fort-book fs-13px lh-18px'>
              {!!shrunk && <>View more recent news ({posts.length - 3})</>}
              {!shrunk && <>View less recent news</>}
            </span>
          </p>
        ))}
    </div>
  );
};

export default LatestPostsDigest;
