import { useState, useEffect } from "react";
import { Header, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import { formatDateMMDDYYYY } from "../../utils/utilities";

const CanceledConfirmedWarningModal = (props) => {
  const { data, open, setOpen, callback, callbackRemove, foc } = props;

  let realTrigger = (
    <div className='d-flex flex-column fort-book-italic fs-13px lh-18 hover-underline text-blue cursor-pointer align-self-end'>
      <div
        onClick={(e) => {
          setOpen(true);
        }}
        className='v-details'
      ></div>
    </div>
  );

  const resetState = () => {
    setOpen(false);
  };

  const proceed = {
    label: "Yes, Clear confirmed cart",
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      // if (callbackRemove) callbackRemove({ foc });
      if (callbackRemove) callbackRemove(false);
      resetState();
      return { proceed: true };
    },
  };

  const okKeep = {
    label: "No, Keep confirmed cart",
    color: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "light-blue",
    textColorOverride: "white",
    borderHoverColorOverride: "light-blue",
    iconColorOverride: "white",
    iconColorHoverOverride: "light-blue",
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      if (callback) callback(false);

      resetState();
      return { proceed: true };
    },
  };

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      modalClassName='vri-modal macancel-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            Cancel Confirmed Quantities
          </Header>
        ),
        actions: [proceed, okKeep],
      }}
      options={{
        trigger: realTrigger,
      }}
    >
      <div className='ar-box ml-4 mr-4'>
        <div className='mb-2'>
          Do you also want to cancel and clear your Confirmed Quantities from
          your{" "}
          <span className='fort-bold'>FOC {formatDateMMDDYYYY(foc, "/")}</span>{" "}
          cart?
        </div>
        <div className='fort-book'>
          <span className='fort-bold'>Note:</span> If you continue, this cart
          will be removed.
        </div>
      </div>
    </DialogModal>
  );
};

CanceledConfirmedWarningModal.propTypes = {};

CanceledConfirmedWarningModal.defaultProps = {};

export default CanceledConfirmedWarningModal;
