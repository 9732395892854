import { useState } from "react";
import { withRouter } from "react-router";
import AddToCartButton from "../../components/common/AddToCartButton";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import LazyImage from "../../utils/LazyImage";
import { numberAsCurrency } from "../../utils/utilities";
import { Input } from "semantic-ui-react";

export const CarouselItem = withSecurity(
  withCart(
    withRouter((props) => {
      const [qty, setQty] = useState(1);
      const correctIsbn = props?.isbnStr;

      const country = props.cart.customerInfo.customer.country;

      const currencyCode = country === "CA" ? "CAD" : "USD";
      const price =
        (country === "CA" ? props.canPrice : props.usPrice) ||
        (props?.price || props?.representative?.price)?.find((p) =>
          country === "CA" ? p.currencyCode === "CAD" : p.currencyCode === "USD"
        )?.amount ||
        "-";

      const isPromoItem = props?.itemTypeCode === "PI";

      const contributors = (props.isPartOfTitle ? [] : props?.author)
        .filter((cont) =>
          (props?.representative?.format?.code === "CB"
            ? ["I", "45", "36"]
            : ["I", "45", "36", "A"]
          ).includes(cont.roleCode)
        )
        .map((cont) => {
          return {
            name: cont.authorDisplay,
            roleCode: cont.roleCode,
          };
        });

      const tempContributors = (
        props.isPartOfTitle ? Object.values(props?.contributors) : []
      ).map((cont) => {
        return {
          name: cont.display,
          roleCode: cont.roleCode,
        };
      });

      const chosenContributors = props.isPartOfTitle
        ? tempContributors
        : contributors;

      const allContributorRolles = [
        ...new Set(chosenContributors.map((cont) => cont.roleCode)),
      ];

      const roleRowData = [
        ...new Set(
          allContributorRolles
            .map((contRole) => {
              return {
                names: chosenContributors
                  .filter((value) => value.roleCode === contRole)
                  .map((cont) => cont.name)[0],
                roleCode: contRole,
              };
            })
            .sort((a, b) => {
              const contributorOrder = ["45", "I", "36"];

              const aContributorIndex = contributorOrder.indexOf(a.roleCode);
              const bContributorIndex = contributorOrder.indexOf(b.roleCode);

              if (aContributorIndex === bContributorIndex) return 0;

              return aContributorIndex - bContributorIndex;
            })
            .map((cont) => cont.names)
        ),
      ];

      return (
        <div className='h-100 justify-content-end d-flex flex-direction-column pt-1'>
          <a
            className='position-relative mb-2 carousel-item-img-style'
            href={`/titleinfo/${correctIsbn}`}
            onClick={(e) => {
              e.preventDefault();
              props.history.push(`/titleinfo/${correctIsbn}`);
            }}
          >
            <LazyImage
              src={`https://images.penguinrandomhouse.com/cover/${correctIsbn}`}
              altImageSize='large'
              className='h-auto w-100 object-fit-contain book-cover-shadow'
              alt={"Book"}
            />
          </a>
          <div className='carousel-item-data-container'>
            <a
              className='fs-13px fort-bold ci-title-link-style'
              href={`/titleinfo/${correctIsbn}`}
              onClick={(e) => {
                e.preventDefault();
                props.history.push(`/titleinfo/${correctIsbn}`);
              }}
            >
              {props?.title}
            </a>
            {!isPromoItem ? (
              <div>
                {roleRowData.length > 0 ? (
                  <div className='two-lines-text fs-13px fort-book lh-21'>
                    {props?.isVariant === "true" ? "Cover by " : "By "}
                    {roleRowData?.join(", ")}
                  </div>
                ) : (
                  ""
                )}
                {!!props?.subtitle && (
                  <div className='two-lines-text ci-subtitle'>
                    {props.subtitle}
                  </div>
                )}
                <div className='fs-13px fort-book lh-21'>
                  {(props?.format || props?.representative?.format)
                    ?.description || props?.format?.name}
                </div>
                {/* {props?.isVariant === 'false' || !props.isVariant ? ( */}
                <div className={"fs-13px fort-book lh-21 carousel-item-isbn"}>
                  {correctIsbn}
                </div>
                {/* ) : (
                  <div className={'fs-13px fort-book lh-21 carousel-item-upc'}>
                    {props.isbn}
                  </div>
                )} */}
              </div>
            ) : (
              <div className='fs-13px fort-book lh-21'>
                {(props?.format || props?.representative?.format)
                  ?.description || props?.format?.name}
              </div>
            )}
            <div className='fort-bold fs-15px lh-21'>
              $
              {price !== "-"
                ? numberAsCurrency(Number(price).toFixed(2))
                : " -"}{" "}
              {currencyCode}
            </div>
          </div>
        </div>
      );
    })
  )
);
