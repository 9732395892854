import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, Placeholder } from "semantic-ui-react";
import withApp from "../../components/hc/withApp";
import LoadingView from "../loading/LoadingView";
import billingDocNumberRenderer from "./frameworkComponents/billingDocNumberRenderer";
import NoRowsInvoicesAndClaims from "./frameworkComponents/NoRowsInvoicesAndClaims";
import MultipleDocs from "../../assets/images/multiple-docs.svg";
import { useMediaQuery } from "react-responsive";
import { Divider } from "../../components/elements/elements";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import MobileTable, {
  mobileTableColumnsInvoices as mobileTableColumns,
} from "../../shared-react-components/elements/OrdersAndInvoicesMobileTable";
//import { useLazyGetRecentInvoicesQuery } from "../../api/ecomApi";
import { useLazyGetRecentHistoryHomeQuery } from "../../api/ecomApi";
import { cloneDeep } from "lodash";

const RecentInvoicesAndClaims = ({ app, history, shipTo }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });

  window.addEventListener("resize", () => {
    if (gridApi) gridApi.sizeColumnsToFit();
  });

  /*
  const [performGetRecentInvoices, getRecentInvoicesResult] =
    useLazyGetRecentInvoicesQuery();
*/

  const [performGetRecentInvoices, getRecentInvoicesResult] =
    useLazyGetRecentHistoryHomeQuery();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [state, setState] = useState({
    pageSize: 50,
    rowHeight: 60,
    currentSort: "dateCreated_desc",
    columnDefs: [
      {
        headerName: "BILLING DOC#",
        field: "billingDocNumber",
        cellRenderer: "billingDocNumberRenderer",
        checkboxSelection: false,
        headerCheckboxSelection: false,
        width: 130,
        cellClass: ["has-link"],
      },
      {
        headerName: "DOCUMENT TYPE",
        field: "docType",
        width: 100,
        valueFormatter: docTypeFormatter,
      },
      {
        headerName: "DATE CREATED",
        field: "dateCreated",
        sortable: true,
        width: 120,
      },
      {
        headerName: "PURCHASE ORDER",
        field: "purchaseOrderNumber",
        width: 150,
      },
      {
        headerName: "SHIP-TO ACCOUNT",
        field: "soldToAccount",
        width: 145,
      },
      {
        headerName: "NET VALUE",
        field: "netValue",
        width: 120,
        valueFormatter: currencyFormatter,
        valueGetter: currencyFormatter,
      },
    ],
    rowData: [],
    headerOnly: true,
  });

  function onGridReady(params) {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }

  function currencyFormatter(params) {
    const number = Number(
      (params.data && params.data.netValue) || params.value
    ).toFixed(2);
    return `$${isNaN(number) ? (0).toFixed(2) : number}`;
  }

  const getData = async () => {
    let args = {};
    const result = await performGetRecentInvoices({ ...args, shipTo });
    // console.log('recent invoices and claims', result);
    return result;
  };
  useEffect(() => {
    getData().then(({ data }) => {
      setDataLoaded(true);
      if (data.status === "OK") {
        setData(cloneDeep(data.data.invoiceListItems));
      }
    });
  }, []);

  const docTypes = {
    F2: "Invoice",
    L2: "Debit Memo",
    G2: "Credit Memo",
    ZL2: "Debit Memo-Return",
    ZG2: "Credit Memo-Return",
  };

  function docTypeFormatter(params) {
    return docTypes[params.value];
  }
  // console.log('recent invoices and claims');
  return (
    <>
      <div
        className={`${
          isMobile
            ? "border-gray-300 border-radius-3px p-3 mb-3 d-flex flex-column white-background mobile-catalogs-container"
            : "mb-3 d-flex align-items-center"
        }`}
      >
        <div
          className={`d-flex flex-row ${
            isMobile
              ? "justify-content-space-between"
              : "d-flex justify-content-space-between w-100 align-items-center"
          }`}
        >
          <div
            className={`mb-0 fort-light text-gray-900 d-flex ${
              isMobile ? "fs-20px" : "fs-24px"
            }`}
          >
            <div
              style={{
                marginTop: "2px",
                marginLeft: ".2em",
                marginRight: ".5em",
              }}
            >
              <img
                src={MultipleDocs}
                //className={"mr--point25em"}
                alt='Multiple Document Icon'
              />
            </div>
            <div style={{ lineHeight: "1em", marginTop: "0.1em" }}>
              Recent Invoices &amp; Claims
            </div>
          </div>
          <div
            className={`lsp--02 fs-13px lh-18 fort-book ${
              isMobile ? "d-flex align-items-center" : ""
            }`}
          >
            <Link to='/invoices' className=''>
              {isMobile ? "View All" : "View all invoices & claims"}
            </Link>
          </div>
        </div>
        {isMobile && <Divider tight className='mt-2 mb-2' />}

        {isMobile && (
          <MobileTable
            columns={mobileTableColumns}
            data={data}
            noDataMessage={<NoRowsInvoicesAndClaims />}
          />
        )}
      </div>
      {!dataLoaded && (
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
      {!isMobile && dataLoaded && (
        <div
          className={`mb-5 h-100 ${
            !Array.isArray(data) || data.length === 0
              ? "ag-grid-root-child-no-items"
              : ""
          }`}
        >
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={state.columnDefs}
            rowData={data}
            rowHeight={state.rowHeight}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            pagination={true}
            defaultColDef={{
              sortable: true,
              resizable: true,
              cellClass: "stringRow",
              suppressMenu: true,
              suppressMovable: true,
            }}
            columnKeys={[
              "billingDocNumber",
              "docType",
              "dateCreated",
              "salesOrderNumber",
              "soldToAccount",
              "netValue",
            ]}
            excelStyles={[
              {
                id: "stringRow",
                dataType: "string",
              },
            ]}
            suppressPaginationPanel={true}
            paginationPageSize={state.pageSize}
            domLayout='autoHeight'
            rowClass='grid-row-style'
            headerHeight={state.rowHeight}
            frameworkComponents={{
              billingDocNumberRenderer: billingDocNumberRenderer,
              customLoadingOverlay: LoadingView,
              noRowsOverlayComponent: NoRowsInvoicesAndClaims,
            }}
            loadingOverlayComponent={"customLoadingOverlay"}
            noRowsOverlayComponent={"noRowsOverlayComponent"}
            suppressContextMenu={true}
          />
        </div>
      )}
    </>
  );
};

export default withRouter(withApp(RecentInvoicesAndClaims));
