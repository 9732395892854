import { Icon } from 'semantic-ui-react';

function ExpiredFocBannerMobile(props) {
  const {
    className,
    containerClassName,
    containerStyle,
  } = props;
  return (
    <div className={`${containerClassName}`} style={containerStyle}>

      <div
        className={`cv-warning d-flex flex-row align-items-center px-2 py-1 my-2 ${className}`}
      >
        <Icon
          name="exclamation triangle"
          color="red"
          className="align-self-start mr-2"
        />
        <div className="fort-medium fs-12px lh-16px w-100">
          This FOC date has passed. We can no longer guarantee quantities, but if you still want to order these items you must move them to your General Cart.
        </div>
      </div>
    </div>
  );
}

export default ExpiredFocBannerMobile;
