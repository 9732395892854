import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import withApp from "../../../components/hc/withApp";
import {
  getPrettyName,
  translatePostTypeSlug,
} from "../../../utils/wordpressHelpers";
import LoadingContent from "./LoadingContent";
import { decode } from "html-entities";
import { useLazyGetTermsQuery } from "../../../api/bizcontentApi";

const TermsList = ({ taxonomy, postType, app, category, searchQuery }) => {
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [performGetTerms, getTermsResult] = useLazyGetTermsQuery();

  const reset = () => {
    setLoading(true);
    setError(false);
  };

  useEffect(() => {
    async function asyncCall() {
      reset();
      const terms = await performGetTerms({ taxonomy });
      if (!!terms?.error?.message) {
        setError(terms.error.message);
      } else if (
        !terms?.data ||
        !Array.isArray(terms.data) ||
        (Array.isArray(terms.data) && terms.data.length === 0)
      ) {
        setError(`No Terms Found!`);
      } else {
        setTerms(terms?.data);
      }
      setLoading(false);
    }

    asyncCall();
  }, [taxonomy, app.bizContentApi]);

  return (
    <>
      <p className='text-uppercase mt-0 fort-bold fs-12px lh-15px lsp--02 pb-2 mb-0'>
        {!!searchQuery ? <>Filter Results By</> : <>Categories</>}
      </p>
      {loading && <LoadingContent />}
      {!loading && error && (
        <div>
          <p className='text-center my-5 px-0'>{error}</p>
        </div>
      )}
      {!loading && !error && (
        <>
          {!category?.slug && (
            <p className='fort-bold m-0 mb-0.78571rem'>
              <span className='pb-0.2857rem  border-3-bottom-orange d-inline-block fs-13px'>
                All {getPrettyName(postType).plural}
              </span>
            </p>
          )}
          {!!category?.slug && (
            <p className='m-0 mb-0.78571rem'>
              <Link
                className='fort-book text-gray-900 fs-13px lh-18px lsp--02 hover-orange d-inline d-inline-block border-3-bottom-transparent'
                to={`/${translatePostTypeSlug(postType)}${
                  !!searchQuery ? `?s=${searchQuery}` : ``
                }`}
              >
                All {getPrettyName(postType).plural}
              </Link>
            </p>
          )}

          {terms.map(({ id: termId, name, slug }) => {
            if (category?.slug === slug) {
              return (
                <p className='fort-bold m-0 mb-0.78571rem' key={termId}>
                  <span className='pb-0.2857rem  border-3-bottom-orange d-inline-block fs-13px'>
                    {decode(name)}
                  </span>
                </p>
              );
            }
            return (
              <p className='m-0 mb-0.78571rem' key={termId}>
                <Link
                  className='fort-book text-gray-900 fs-13px lh-18px lsp--02 hover-orange d-inline d-inline-block border-3-bottom-transparent'
                  to={`/${translatePostTypeSlug(postType)}/category/${slug}${
                    !!searchQuery ? `?s=${searchQuery}` : ``
                  }`}
                >
                  {decode(name)}
                </Link>
              </p>
            );
          })}
        </>
      )}
    </>
  );
};

export default withApp(TermsList);
