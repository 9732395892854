import Logo from "../../assets/images/prh-logo-small.png";
import { Grid, Segment, Image } from "semantic-ui-react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./pageHeader.scss";
import "./pageHeaderLeftAlign.scss";
import CartBadge from "../cart/CartBadge";
import withSecurity from "../../components/hc/withSecurity";
//import {PRHContainer} from './elements';

const pageHeaderLeftAlign = (props) => (
  <>
    <Segment.Group>
      <Grid padded className='ph-left-align'>
        <Grid.Column width={1} className={"header-background"} />
        <Grid.Column width={13} className={"header-background"}>
          <div className={"logo-holder-left-align"}>
            <Link to='/' className='d-flex'>
              <Image src={Logo} />
              <div className={"biz-2 fort-bold text-white"}>
                {props.user.isAppBiz()
                  ? "Penguin Random House Biz"
                  : "Penguin Random House Comp Copy"}
              </div>
            </Link>
          </div>
        </Grid.Column>
        <Grid.Column
          width={2}
          className={
            "header-background d-flex align-items-center justify-content-end"
          }
          style={{ paddingRight: "5rem" }}
        >
          {props.cartBadge === true ? <CartBadge></CartBadge> : ""}
        </Grid.Column>
      </Grid>
    </Segment.Group>
  </>
);

pageHeaderLeftAlign.propTypes = {
  cartBadge: PropTypes.bool,
};

pageHeaderLeftAlign.defaultProps = {
  cartBadge: true,
};

export default withRouter(withSecurity(pageHeaderLeftAlign));
