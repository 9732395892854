import { Component, useEffect, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Popup, Icon } from "semantic-ui-react";
import { Divider } from "../../components/elements/elements";
import withSecurity from "../../components/hc/withSecurity";
import { PRHContainer } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import PageStandard from "../../components/elements/PageStandard";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import CartPreview from "../checkout/components/CartPreview";
import "./OrderSummary.scss";
import LazyImage from "../../utils/LazyImage";
import {
  numberAsCurrency,
  formatDate,
  formatDateMMDDYYYY,
  formatISBN,
} from "../../utils/utilities";
import { NumericFormat } from "react-number-format";
import useCart from "../../components/hc/useCart";
import { useDispatch, useSelector } from "react-redux";
import useSecurity from "../../components/hc/useSecurity";
import { setCart } from "../../features/ecomSlice";

const OrderSummary = (props) => {
  const [cartType, setCartType] = useState(0);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const location = useLocation();
  const cartProv = useCart();
  const dispatch = useDispatch();
  const userProv = useSecurity();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setCart({ cart: { cartKey: false, items: [] } }));

    return () => {};
  }, []);

  const continueShopping = (e) => {
    history.push({
      pathname: "/search",
    });
  };

  const viewOrders = (e) => {
    history.push({
      pathname: "/orders",
    });
  };

  // console.log('order summary render', this.props);

  if (!location.state || location.state === undefined || !location.state.cart) {
    history.push({ pathname: "/cart" });
    return <></>;
  }
  const { cart, cartTotal, shipTo, billTo, ccSapAccountNo } = location.state;

  const items = cartProv.sortCartItems(cart.items);
  console.log("user", user);
  return (
    <PageStandard alignMent='left'>
      <div className='cart-page-main fort-bold fs-3 d-flex justify-content-start mt-4 mb-4 flex-column'>
        <PRHContainer className='content-block-border w-100 mb-3 ord-container p-0'>
          <div className='d-flex mb-1 flex-column'>
            <div className='d-flex flex-row w-100 justify-content-center'>
              <div className='d-flex flex-column align-items-center justify-content-center'>
                <div className='ord-box-icon mb-4 mt-4'>
                  {cart.error && cart.error.code === 500 ? (
                    <LazyImage src='/images/confirmation-warning-icon.svg'></LazyImage>
                  ) : (
                    <LazyImage src='/images/confirmation.svg'></LazyImage>
                  )}
                </div>

                {cart.error?.code === 500 ? (
                  <>
                    <div className='fort-bold mb-4'>
                      Oops! We are not able to confirm if your order was
                      processed
                    </div>
                    <div className='fort-book fs-2 ml-8 mr-8 mb-4 lh-3'>
                      If your order was successful, it will appear on the Orders
                      page, a confirmation email will be sent shortly to{" "}
                      {user.profile.email}, and you may safely clear your cart.
                      Otherwise, please place the order again or contact
                      Customer Service.
                    </div>
                  </>
                ) : cart.error?.code === 31 ? (
                  <>
                    <div className='fort-bold mb-4'>
                      Thank you! We have received your order.
                    </div>
                    {/* <div className="fort-book fs-3 mb-4">
                        We are not able to confirm prices or availability at
                        this time. Please check the{' '}
                        <Link
                          to={`/orders/${
                            cart.orderNumber ? cart.orderNumber : ''
                          }`}
                        >
                          Order Details
                        </Link>{' '}
                        for updated order information.
                      </div> */}
                    <div className='fort-book fs-3 mb-4'>
                      A confirmation email has been sent to {user.profile.email}
                      .
                    </div>
                  </>
                ) : (
                  <>
                    <div className='fort-bold mb-4'>
                      Thank you! We have received your order.
                    </div>
                    <div className='fort-book fs-3 mb-4'>
                      A confirmation email has been sent to {user.profile.email}
                      .
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className='d-flex flex-row w-100 mb-4'>
              <PRHButton
                size='large'
                className='ml-auto mr-2'
                onClick={(e) => history.push({ pathname: "/" })}
              >
                Back to Home
              </PRHButton>
              {/* {!isNaN(parseInt(cart.orderNumber)) && ( */}
              <PRHButton
                disabled={isNaN(parseInt(cart.orderNumber))}
                size='large'
                className='mr-auto '
                onClick={(e) =>
                  history.push({
                    pathname: `/orders/${cart.orderNumber}`,
                  })
                }
              >
                View Order Detail
              </PRHButton>
              {/* )} */}
            </div>
          </div>
          <Divider tight />
          <div className='ord-header d-flex mt-3 mb-3'>
            <div className='d-flex flex-column pl-3'>
              <div className='d-flex flex-row'>
                <div className='fort-bold fs-2 mr-2'>Sales order number:</div>
                <div className='mb-2 fort-book fs-2'>
                  {cart.orderNumber ? (
                    cart.orderNumber
                  ) : (
                    <span>
                      Currently unavailable{" "}
                      <Popup
                        className='cp-help-tooltip'
                        content={cart.error.message}
                        on='hover'
                        position='top center'
                        offset={[0, 5]}
                        trigger={
                          <Icon
                            name='question circle outline'
                            className='sb_question_icon text-orange'
                          />
                        }
                      />{" "}
                    </span>
                  )}
                </div>
              </div>
              <div className='d-flex flex-row'>
                <div className='fort-bold fs-2 mr-2 mb-1'>Purchase order:</div>
                <div className='mb-2 fort-book fs-2'>
                  {cart.headers.poNumber ? cart.headers.poNumber : " "}
                </div>
              </div>
              <div className='d-flex flex-row mb-1'>
                <div className='fort-bold fs-2 mr-2 mb-1'>
                  Invoice description:
                </div>
                <div className='mb-2 fort-book fs-2'>
                  {cart.headers.invoiceText ? cart.headers.invoiceText : " "}
                </div>
              </div>{" "}
              <div className='d-flex flex-row'>
                <div className='fort-bold fs-2 mr-2 mb-1'>Order date:</div>
                <div className='mb-2 fort-book fs-2'>
                  {cart.orderDate
                    ? formatDateMMDDYYYY(cart.orderDate, "/")
                    : ""}
                </div>
              </div>
              {userProv.isAppBiz() ? (
                <div className='d-flex flex-row'>
                  <div className='fort-bold fs-2 mr-2'>Order total:</div>
                  <div className='mb-2 fort-book fs-2'>
                    <NumericFormat
                      value={cartTotal.total}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      prefix={"$"}
                    />
                    {cart.error?.code === 31 ? (
                      <Popup
                        className='cp-help-tooltip'
                        content='We are unable to confirm final pricing at this time.  This number will be available on the Order Details page.'
                        on='hover'
                        position='top center'
                        offset={[0, 5]}
                        trigger={
                          <Icon
                            name='question circle outline ml-1'
                            className='sb_question_icon text-gray'
                          />
                        }
                      />
                    ) : null}
                  </div>
                  {cart.error?.code === 31 ? (
                    <Popup
                      className='cp-help-tooltip'
                      content='We are unable to confirm final pricing at this time.  This number will be available on the Order Details page.'
                      on='hover'
                      position='top center'
                      offset={[0, 5]}
                      trigger={
                        <Icon
                          name='question circle outline ml-1'
                          className='sb_question_icon text-gray'
                        />
                      }
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
            {shipTo ? (
              <div className='ord-header-shipto d-flex flex-column fort-book fs-2'>
                <div className='fort-bold fs-2 mb-1'>Ship to:</div>
                <div>{shipTo.address.name1}</div>
                {shipTo.address.name2 && <div>{shipTo.address.name2}</div>}
                <div>{shipTo.address.street}</div>
                {shipTo.address.name3 && <div>{shipTo.address.name3}</div>}
                <div>
                  {shipTo.address.city}, {shipTo.address.state}{" "}
                  {shipTo.address.postalCode}{" "}
                  {shipTo.address.country ? shipTo.address.country : ""}
                </div>
              </div>
            ) : null}
            {cart.shipTo ? (
              <div className='ord-header-shipto d-flex flex-column fort-book fs-2'>
                <div className='fort-bold fs-2 mb-1'>Ship to:</div>
                <div>{cart.shipTo.name1}</div>
                {cart.shipTo.name2 && <div>{cart.shipTo.name2}</div>}
                <div>{cart.shipTo.street}</div>
                {cart.shipTo.name3 && <div>{cart.shipTo.name3}</div>}
                <div>
                  {cart.shipTo.city}, {cart.shipTo.state}{" "}
                  {cart.shipTo.postalCode}{" "}
                  {cart.shipTo.country ? cart.shipTo.country : ""}
                </div>
              </div>
            ) : null}
            {ccSapAccountNo ? (
              <div className='ord-header-billto d-flex flex-column ml-auto fort-book fs-2'>
                <div className='fort-bold fs-2 mb-1'>Bill to:</div>
                <div>SAP Account: {ccSapAccountNo}</div>
              </div>
            ) : null}
            {cart.card && cart.card.billingAddress ? (
              <div className='ord-header-billto d-flex flex-column ml-auto fort-book fs-2'>
                <div className='fort-bold fs-2 mb-1'>Bill to:</div>
                <div>{cart.card.billingAddress.name1}</div>
                {cart.card.billingAddress.name2 && (
                  <div>{cart.card.billingAddress.name2}</div>
                )}
                <div>{cart.card.billingAddress.street}</div>
                {cart.card.billingAddress.name3 && (
                  <div>{cart.card.billingAddress.name3}</div>
                )}
                <div>
                  {cart.card.billingAddress.city},{" "}
                  {cart.card.billingAddress.state}{" "}
                  {cart.card.billingAddress.postalCode}{" "}
                  {cart.card.billingAddress.country
                    ? cart.card.billingAddress.country
                    : ""}
                </div>
              </div>
            ) : billTo ? (
              <div className='ord-header-billto d-flex flex-column ml-auto fort-book fs-2'>
                <div className='fort-bold fs-2 mb-1'>Bill to:</div>
                <div>{billTo.address.name1}</div>
                {billTo.address.name2 && <div>{billTo.address.name2}</div>}
                <div>{billTo.address.street}</div>
                {billTo.address.name3 && <div>{billTo.address.name3}</div>}
                <div>
                  {billTo.address.city}, {billTo.address.state}{" "}
                  {billTo.address.postalCode}{" "}
                  {billTo.address.country ? billTo.address.country : ""}
                </div>
              </div>
            ) : null}
          </div>
        </PRHContainer>
        <PRHContainer className='content-block-border w-100 mb-3 ord-container p-0 '>
          <div className='ord-items'>
            <div className='text-uppercase fort-bold fs-2 pt-3 pb-3 pl-3'>
              items
            </div>
            <div className='fort-book fs-2'>
              <CartPreview isOrderSummary={true} items={items}></CartPreview>
            </div>
          </div>
          {/* {this.props.user.isAppBiz() ? (
              <div className="d-flex flex-column pb-3 pr-3 pt-3">
                <div className="d-flex flex-row fort-bold fs-2 ml-auto mb-2 mt-2">
                  <div className="ord-total-header text-uppercase ">
                    retailer amount
                  </div>
                  <div>
                    {' '}
                    <NumericFormat
                      value={cartTotal.subtotal}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={'text'}
                      prefix={'$'}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row fort-bold fs-2 ml-auto">
                  <div className="ord-total-header text-uppercase ">
                    net amount
                  </div>
                  <div>
                    <NumericFormat
                      value={cartTotal.total}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={'text'}
                      prefix={'$'}
                    />
                  </div>
                </div>
              </div>
            ) : null} */}
        </PRHContainer>
      </div>
    </PageStandard>
  );
};

export default OrderSummary;
