import React, { useState, useEffect } from "react";
import { PRHContainer } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Divider } from "../../components/elements/elements";
import { Form, Radio, Icon, Loader } from "semantic-ui-react";
import "./CreditCardList.scss";
import {
  isCreditCardExpired,
  creditCardWillExpire,
  isGeneralCart,
} from "../../utils/utilities";
import CreditCardModal from "../../components/modals/CreditCardModal";
import useCart from "../hc/useCart";

const CreditCardList = (props) => {
  const { shipTo, foc, paymentCreditCard, setValue } = props;
  const [creditCardModalOpen, setCreditCardModalOpen] = useState(false);
  const [creditCardList, setCreditCardList] = useState(false);
  const [cardAdded, setCardAdded] = useState(false);
  const [maxOnsaleYear, setMaxOnsaleYear] = useState(false);
  const [maxOnsaleMonth, setMaxOnsaleMonth] = useState(false);
  const cart = useCart();

  const loadCards = (ccAdded, paymentCreditCard = null) => {
    //if (shipTo) {
    cart.getCreditCards(cart.cart?.headers?.cardId).then((cc) => {
      if (cc.data?.status === "OK") {
        if (ccAdded === true) {
          const ccDiff = cc.data.data?.creditCards.filter(({ cardId: id1 }) => {
            if (!creditCardList.some(({ cardId: id2 }) => id2 === id1)) {
              if (
                !isCreditCardExpired(
                  cc.cardExpiryMonth,
                  cc.cardExpiryYear,
                  cart.isUserPow ? (!isGeneralCart(foc) ? foc : false) : false
                )
              ) {
                return true;
              } else {
                return false;
              }
            }
          });
          if (ccDiff.length > 0) {
            setValue("paymentCreditCard", ccDiff[0].cardId);
          } else {
          }
        } else {
          if (!paymentCreditCard && !cart.cart?.headers?.cardId) {
            if (cc.data.data?.creditCards.length > 0) {
              const ccFirst = cc.data.data?.creditCards.find((cc) => {
                if (
                  !isCreditCardExpired(
                    cc.cardExpiryMonth,
                    cc.cardExpiryYear,
                    cart.isUserPow ? (!isGeneralCart(foc) ? foc : false) : false
                  )
                ) {
                  return cc;
                }
              });
              if (ccFirst) {
                setValue("paymentCreditCard", ccFirst.cardId);
              }
            }
          } else if (!paymentCreditCard && cart.cart?.headers?.cardId) {
            setValue("paymentCreditCard", cart.cart?.headers?.cardId);
          }
        }
        setCreditCardList([...cc.data.data?.creditCards]);
        setCardAdded(true);
      }
    });
    //}
  };

  useEffect(() => {
    let workosd = false;
    cart.cart.items.forEach((i) => {
      if (!!!workosd) workosd = i.onsale;
      if (i.onsale > workosd) {
        workosd = i.onsale;
      }
    });
    if (workosd) {
      const x = workosd.split("-");
      // console.log('MaxOSD', x[0], x[1]);
      setMaxOnsaleYear(x[0]);
      setMaxOnsaleMonth(x[1]);
    }
  }, []);

  useEffect(() => {
    if (shipTo) {
      loadCards(false, paymentCreditCard);
    }
  }, [shipTo]);

  const handleCCChange = (cardId) => {
    setValue("paymentCreditCard", cardId);
  };

  return (
    <div className='w-100 m-0 pl-0 pr-0 mr-6 pt-3'>
      <div className=''>
        <div className='ccv-container overflow-auto'>
          {/* <Form> */}
          <Divider tight />
          {!creditCardList && <Loader active />}
          {creditCardList && creditCardList.length === 0 && (
            <div className='d-flex justify-content-center fort-book mt-4'>
              You have no credit cards on file. Please add a credit card for
              payment.
            </div>
          )}
          {creditCardList &&
            creditCardList.map((cc, idx) => (
              <div key={idx} className={`d-flex cc-line pl-3 pr-3 pt-2 pb-2`}>
                <Form.Field>
                  <Radio
                    className='mr-2'
                    name='ccard'
                    value={cc.cardId}
                    checked={paymentCreditCard === cc.cardId}
                    onChange={(e) => handleCCChange(cc.cardId)}
                    disabled={
                      isCreditCardExpired(
                        cc.cardExpiryMonth,
                        cc.cardExpiryYear
                      ) ||
                      isCreditCardExpired(
                        cc.cardExpiryMonth,
                        cc.cardExpiryYear,
                        foc
                      )
                    }
                  />
                </Form.Field>

                <div className='d-flex flex-column'>
                  <div className='d-flex flex-row fort-book'>
                    <div className='fort-bold'>
                      Card ending in {cc.cardLastFour}
                    </div>
                    <Divider horizontal />
                    <div>{cc.cardholderName}</div>
                    <Divider horizontal />
                    {isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear
                    ) ? (
                      <div className='fort-bold fs-13px lh-18px cc-error'>
                        <Icon name='exclamation triangle' color='red'></Icon>
                        Expired: {cc.cardExpiryMonth}/{cc.cardExpiryYear}
                      </div>
                    ) : (
                      <div>
                        Expires: {cc.cardExpiryMonth}/{cc.cardExpiryYear}
                      </div>
                    )}
                  </div>
                  {maxOnsaleYear &&
                    maxOnsaleMonth &&
                    //isGeneralCart(foc) &&
                    !isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear,
                      foc
                    ) &&
                    !isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear
                    ) &&
                    (maxOnsaleYear > cc.cardExpiryYear ||
                      (maxOnsaleYear === cc.cardExpiryYear &&
                        maxOnsaleMonth > cc.cardExpiryMonth)) && (
                      <div className='d-flex flex-row fort-book'>
                        <Icon name='info circle'></Icon>
                        <div>
                          Note: This card will expire before one or more items'
                          On-Sale Date.
                          {/*
                          This card cannot be used because it expires before one
                        of the sale dates*/}
                        </div>
                      </div>
                    )}
                  {cart.isUserPow() &&
                    isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear,
                      foc
                    ) &&
                    !isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear
                    ) &&
                    !isGeneralCart(foc) && (
                      <div className='d-flex flex-row fort-book'>
                        <Icon name='info circle'></Icon>
                        <div>
                          Cannot use a card that expires before FOC date.
                        </div>
                      </div>
                    )}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/*
      
      */}
      <CreditCardModal
        buttonStyles={{ size: "medium", className: "ml-3 mt-2", bold: true, label: 'Add Card' }}
        //size="ml-3 mt-2"
        //classes="ml-3 mt-2"
        //isOpen={creditCardModalOpen}
        onClose={() => setCreditCardModalOpen(false)}
        reloadCards={loadCards}
      />
    </div>
  );
};

export default CreditCardList;
