import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import BridgeReceivingItemsModal from "./BridgeReceivingItemsModal";
import BridgeSelectLocationModal from "./BridgeSelectLocationModal";
import EventTrigger from "../analytics/EventTrigger";
import { Header, Icon, Table } from "semantic-ui-react";
import LazyImage from "../../utils/LazyImage";
import DialogModalAccessible from "./DialogModalAccessible";
import useCart from "../hc/useCart";
import useApp from "../hc/useApp";
import { useHistory } from "react-router";
import useDirtyHandler from "../../shared-react-components/components/hc/useDirtyHandler";
import BridgeReceivingFailureModal from "./BridgeReceivingFailureModal";
import { useBeforeunload } from "react-beforeunload";
import ToastGenerator from "../../shared-react-components/utils/ToastGenerator";
import BridgeReceivingNotOwnerModal from "./BridgeReceivingNotOwnerModal";
import useSecurity from "../hc/useSecurity";
import {
  useCancelWishListByIDMutation,
  useLazyGetWishListByIDQuery,
} from "../../api/wishlistApi";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import {
  formatDateMMDDYYYY,
  isFocCartExpired,
  isGeneralCart,
} from "../../utils/utilities";
import ModifiedConfirmedCartModal from "./ModifyConfirmedCartModal";
import { set } from "lscache";
import _ from "underscore";
import "./BridgeAddComicsTitlesModal.scss";

function BridgeAddComicsTitlesModal(props) {
  const {
    listID,
    listName,
    //shipTo,
    address = "Customer Location",
    callback = false,
    open,
    setOpen,
  } = props;

  const cart = useCart();
  const app = useApp();
  const user = useSecurity();
  const history = useHistory();
  const dirtyHandler = useDirtyHandler();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [receivingListIdOpen, setReceivingListIdOpen] = useState(false);
  const [receivingTitlesOpen, setReceivingTitlesOpen] = useState(false);
  const [receivingFailureOpen, setReceivingFailureOpen] = useState(false);
  const [receivingNotOwnerOpen, setReceivingNotOwnerOpen] = useState(false);
  const [addingItems, setAddingItems] = useState(false);
  const [listSubmitted, setListSubmittted] = useState(false);
  const [previousModal, setPreviousModal] = useState("");
  const [titleList, setTitleList] = useState([]);
  const [titleListLoaded, setTitleListLoaded] = useState(false);
  const [shipTo, setShipTo] = useState(
    cart.customerInfo.shipToAccountNos.length === 1 ? 0 : -1
  );
  const [totalValidTitles, setTotalValidTitles] = useState(0);
  const [shipToAccountNo, setShipToAccountNo] = useState("");
  const [performGetWishListByID, getWishListByIDResult] =
    useLazyGetWishListByIDQuery();
  const [performCancelWishListByID, cancelWishListByIDResult] =
    useCancelWishListByIDMutation();
  const ecom = useSelector((state) => state.ecom);
  const [modifyConfirmedCartModalOpen, setModifyConfirmedCartModalOpen] =
    useState(false);
  const [confirmedCartCount, setConfirmedCartCount] = useState(0);

  useBeforeunload((event) => {
    if (open) {
      event.preventDefault();
      return "you will loose your data";
    }
  });

  useEffect(() => {
    if (listID) {
      getWishList();
      EventTrigger("Bridge_To_Biz", "Receive List");
    }

    return () => {
      //   setOpen(false);
    };
  }, [listID]);

  const clearCartQty = async () => {
    let t = [...titleList];
    t.map((i) => {
      i.cartQuantity = 0;
    });
    setTitleList(t);
    setConfirmedCartCount(0);
  };

  const getEstimateCarts = async (wc = false) => {
    dirtyHandler.setLoadingTakeover(true);
    setTitleListLoaded(true);
    setPreviousModal("BridgeAddTitlesModal");
    const estResult = await cart.loadEstimateCarts({
      silent: false,
      shipTo: cart.customerInfo.shipToAccountNos[shipTo].accountNo,
    });
    let estimateCarts = [];
    if (estResult.status === "OK") {
      estimateCarts = estResult?.data?.carts;
    }
    let t = [];
    if (wc) {
      t = [...wc];
    } else {
      t = [...titleList];
    }

    let cc = 0;
    t.map((i) => {
      i.cartQuantity = 0;
    });

    if (estimateCarts.length > 0) {
      t.map((i) => {
        estimateCarts.forEach((estimate) => {
          if (
            estimate.foc === i.foc &&
            estimate.confirmationStatus === "CONFIRMED"
          ) {
            cc++;
          }
          estimate.items.forEach((item) => {
            if (item.isbn === i.isbn) {
              i.cartQuantity = item.quantity;
            }
          });
        });
      });
    }
    setTitleList(t);
    setConfirmedCartCount(cc);
    dirtyHandler.setLoadingTakeover(false);
  };

  const getWishList = async () => {
    setReceivingTitlesOpen(true);
    performGetWishListByID({ id: listID }).then(async (result) => {
      if (result.data?.status === "OK") {
        if (result.data.data?.error?.code === 34) {
          setReceivingTitlesOpen(false);
          setReceivingNotOwnerOpen(true);
        } else if (result.data.data?.error?.code === 35) {
          setReceivingTitlesOpen(false);
          setReceivingFailureOpen(true);
        } else {
          let t = cloneDeep(result.data.data.items);
          const ta = _.uniq(t, "isbn");
          setTotalValidTitles(ta.length);
          setTitleList(t);
          setReceivingTitlesOpen(false);
          if (cart.customerInfo.shipToAccountNos.length === 1) {
            setTitleListLoaded(true);
            setPreviousModal("BridgeAddTitlesModal");
            getEstimateCarts(t);
          } else {
            setReceivingListIdOpen(true);
            setPreviousModal("BridgeSelectLocationModal");
          }
          //setOpen(true);
        }
      } else {
        setReceivingTitlesOpen(false);
        setReceivingFailureOpen(true);
      }
    });
  };

  const setOpenPreviousModal = () => {
    if (previousModal === 1) {
      setReceivingListIdOpen(true);
    } else if (previousModal === 2) {
      setTitleListLoaded(true);
    }
  };

  const addTitlesToCart = async () => {
    let carts = {};
    dirtyHandler.setLoadingTakeover(true);
    if (cart.customerInfo.shipToAccountNos.length === 1) {
      setShipToAccountNo(cart.customerInfo.currentUser.accountNo);
    } else {
      setShipToAccountNo(cart.customerInfo.shipToAccountNos[shipTo].accountNo);
    }
    titleList.forEach((item) => {
      if (item.available) {
        let cartType = "FOC";
        if (isGeneralCart(item.foc)) {
          cartType = "GEN";
        } else {
          if (isFocCartExpired(item.foc)) cartType = "FGN";
        }
        let foc =
          isGeneralCart(item.foc) || cartType === "FGN"
            ? "2099-12-31"
            : item.foc;

        if (carts[foc]) {
          carts[foc].items.push({
            isbn: item.isbn,
            quantity: item.quantity,
            cartType,
          });
        } else {
          carts[foc] = {
            foc,
            shipTo: cart.customerInfo.shipToAccountNos[shipTo].accountNo,
            items: [{ isbn: item.isbn, quantity: item.quantity, cartType }],
          };
        }
      }
    });

    const result = await cart.addEstimateItems({
      carts: Object.values(carts),
      supressDirty: true,
      supressAddModal: true,
      simulate: false,
      listId: listID,
    });

    if (callback) callback();
    ToastGenerator(
      {
        toastIcon: "check",
        toastIconStyle: { color: "#00702d" },
        text: `${titleList.filter((t) => t.available).length} item${
          titleList.filter((t) => t.available).length > 1 ? "s were" : " was"
        } successfully added to your carts.`,
        textClassName: "fort-book",
      },
      { autoClose: 3000, closeOnClick: true }
    );
    dirtyHandler.setLoadingTakeover(false);
    setReceivingListIdOpen(false);
    setReceivingTitlesOpen(false);
    setListSubmittted(true);
    history.replace({ pathname: "/cart-overview" });
    cart.setShipTo(shipTo);
    return { proceed: true };
  };

  const goBackToLocation = () => {
    if (previousModal === "BridgeSelectLocationModal") {
      setReceivingListIdOpen(true);
    } else {
      setTitleListLoaded(true);
    }
  };

  const goBackToTitleList = () => {
    setReceivingListIdOpen(true);
    setTitleListLoaded(false);
    setPreviousModal("BridgeSelectLocationModal");
  };

  const cancelAddTitlesToCart = () => {
    performCancelWishListByID({
      id: listID,
    });
    if (cart.customerInfo.shipToAccountNos.length === 1) {
      cart.setShipTo(shipTo);
    }
    history.replace({ pathname: "/cart-overview" }); //add to add to cart
    setTitleListLoaded(false);
    setTitleList([]);
    //setOpen(false);
    setReceivingListIdOpen(false);
    ToastGenerator(
      {
        text: "You cancelled adding your Pull List to the cart!",
        textClassName: "fort-book",
      },
      { autoClose: 3000, closeOnClick: true }
    );
  };

  let performRelogin = () => {
    if (user.user) {
      const ut = user.isAppBiz();
      app.accountApi.logout().then((e) => {
        cart.clearCheckout();
        user.logout();
        user.clearUser();
        //localStorage.setItem('SSO', window.location);
        if (ut) {
          localStorage.setItem(
            "redirectUri",
            window.location.pathname + `/cart-overview?listID=${listID}`
          );
          window.location.href = process.env.REACT_APP_NETIQ_LOGOUT_URL;
          return;
        }
        history.push({ pathname: `/cart-overview?listID=${listID}` });
      });
    } else {
      history.push({ pathname: `/cart-overview?listID=${listID}` });
    }
  };

  const modifyConfirmedCart = async () => {
    dirtyHandler.setLoadingTakeover(true);
    await addTitlesToCart();
    dirtyHandler.setLoadingTakeover(false);
  };

  // console.log("shipTo", shipTo);
  // console.log("cart", cart);
  return (
    <div>
      <ModifiedConfirmedCartModal
        open={modifyConfirmedCartModalOpen}
        setOpen={setModifyConfirmedCartModalOpen}
        callback={() => {
          modifyConfirmedCart();
        }}
        postCallback={(e) => {
          setTitleListLoaded(false);
          // cancelAddTitlesToCart();
        }}
      ></ModifiedConfirmedCartModal>
      <BridgeSelectLocationModal
        open={receivingListIdOpen}
        setOpen={(x) => setReceivingListIdOpen(x)}
        listID={listID}
        listName={listName}
        shipTo={shipTo}
        header={
          <Header className="fs-12px fort-bold fs-2 lh-2 my-0 mr-5">
            ADD {listName ? listName.toUpperCase() : ""} ITEMS TO CART
          </Header>
        }
        setCancelModalOpen={(x) => setCancelModalOpen(x)}
        getEstimateCarts={getEstimateCarts}
        //storeLocation={storeLocation}
        setShipTo={(x) => setShipTo(x)}
      />
      <BridgeReceivingItemsModal
        open={receivingTitlesOpen}
        setOpen={(x) => setReceivingTitlesOpen(x)}
        listName={listName}
      />
      <BridgeReceivingFailureModal
        open={receivingFailureOpen}
        setOpen={(x) => setReceivingFailureOpen(x)}
      />
      <BridgeReceivingNotOwnerModal
        open={receivingNotOwnerOpen}
        setOpen={(x) => setReceivingNotOwnerOpen(x)}
        callback={performRelogin}
        cancelCallback={(e) => {
          cancelAddTitlesToCart();
          setReceivingNotOwnerOpen(false);
          setOpen(false);
        }}
      />
      <DialogModalAccessible
        setOpen={setCancelModalOpen}
        onXClose={() => {
          goBackToLocation();
        }}
        open={cancelModalOpen && !listSubmitted && !addingItems}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        contentPt="pt2"
        dialogOptions={{
          header: "CANCEL ADDING ITEMS",
          actions: [
            {
              hidden: false,
              label: "No, go back",
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                /*if (!open) {
                  setOpenPreviousModal();
                }*/
                goBackToLocation();
                return { proceed: true };
              },
            },
            {
              hidden: false,
              label: "Yes, cancel",
              textColorOverride: "white",
              backgroundColorOverride: "blue",
              borderColorOverride: "blue",
              textColorHoverOverride: "blue",
              backgroundHoverColorOverride: "white",
              borderHoverColorOverride: "blue",
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                cancelAddTitlesToCart();
                return { proceed: true };
              },
            },
          ],
        }}
        options={{ className: "tm-modal-container" }}
      >
        <div className="fort-book fs14-px lh19-px">
          Are you sure you want to cancel adding items
          {listName ? ` from ${listName}` : ""}?
        </div>
      </DialogModalAccessible>
      <DialogModalAccessible
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={titleListLoaded}
        modalClassName={"bridge-comics-retail-modal br-add-title-modal"}
        setOpen={setTitleListLoaded}
        //returnOnClose={true}
        onXClose={async () => {
          setCancelModalOpen(true);
          //return { proceed: false };
        }}
        // contentPx={validIsbnList ? 'px-0' : 'px-4'}
        contentPt="pt-2"
        dialogOptions={{
          header: "ADD ISBNs",
          header: (
            <Header className="fs-12px fort-bold fs-2 lh-2 my-0 mr-5">
              ADD {listName ? listName.toUpperCase() : ""} ITEMS TO CART
            </Header>
          ),
          actions: [
            ...(cart.customerInfo.shipToAccountNos.length > 1
              ? [
                  {
                    label: "Back",
                    color: "orange",
                    icon: "arrow left",
                    className: "mr-auto",
                    preAction: () => {
                      clearCartQty();
                      goBackToTitleList();
                      return { proceed: false };
                    },
                    action: () => {
                      return { proceed: true };
                    },
                  },
                ]
              : []),
            ,
            {
              hidden: false,
              label: "Cancel",
              preAction: async () => {
                setCancelModalOpen(true);
                return { proceed: false };
              },
              action: () => {
                return { proceed: true };
              },
            },
            {
              hidden: false,
              disabled: titleList.filter((t) => t.available).length === 0,
              label: "Add items to cart",
              textColorOverride: "white",
              backgroundColorOverride: "blue",
              borderColorOverride: "blue",
              textColorHoverOverride: "blue",
              backgroundHoverColorOverride: "white",
              borderHoverColorOverride: "blue",
              bold: true,
              icon: "check",
              iconColorHoverOverride: "blue",
              iconColorOverride: "white",
              preAction: async () => {
                if (confirmedCartCount > 0) {
                  setModifyConfirmedCartModalOpen(true);
                  return { proceed: false };
                } else {
                  return { proceed: true };
                }
              },
              action: () => {
                if (confirmedCartCount > 0) {
                  return { proceed: true };
                } else {
                  addTitlesToCart();
                  return { proceed: true };
                }
              },
            },
          ],
        }}
        options={{
          size: "medium",
        }}
      >
        <div>
          <div className="pb-1 pl-4 pr-4 mb-1">
            <span className="fort-bold">
              {titleList.filter((t) => t.available).length}
              {titleList.filter((t) => t.available).length > 0 &&
                ` of ${totalValidTitles}`}{" "}
              items
            </span>{" "}
            will be added to your cart for{" "}
            <span className="fort-bold">
              {shipTo !== -1
                ? `${
                    cart.customerInfo.shipToAccountNos[shipTo].accountNo
                  } |${" "}
            ${cart.customerInfo.shipToAccountNos[shipTo].address.name1} |${" "}
            ${cart.customerInfo.shipToAccountNos[shipTo].address.city},${" "}
            ${cart.customerInfo.shipToAccountNos[shipTo].address.state}`
                : ""}
            </span>
            .
            {titleList.filter((t) => t.available).length > 0 && (
              <div>You can edit quantities and remove items in your carts.</div>
            )}
          </div>
          <div className="px-0 aim-isbn-tableX">
            <div
              className={`d-flex flex-row pl-4 pr-4 pt-1 pb-1 fort-book fs-2 lh-16px brdg-import-header ${
                titleList.length > 4 ? "scroll-pad" : ""
              } `}
            >
              <div style={{ width: "5%" }}></div>
              <div style={{ width: "50%" }}>TITLE</div>
              <div style={{ width: "20%", minWidth: "135px" }}>ISBN/UPC</div>
              <div style={{ width: "15%" }}>CART</div>
              <div style={{ width: "10%", textAlign: "end" }}>QTY</div>
            </div>

            <div className="d-flex flex-column brdg-import-body">
              {titleList.map((item, idx) => (
                <div
                  className={`d-flex flex-row pt-1 pb-1 pl-4 pr-4 brdg-import-row fort-book fs-2 bridge-comics-item ${
                    item.available === false ? "warning" : ""
                  }`}
                  key={idx}
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ width: "5%" }}
                  >
                    {item.available === false ? (
                      <div
                        //   style={{ marginRight: '5px' }}
                        className="invalid"
                      >
                        <Icon
                          className="fs-16px"
                          style={{ color: "#D63C3C" }}
                          name="warning sign"
                        ></Icon>
                      </div>
                    ) : (
                      <div
                        //   style={{ marginRight: '10px' }}
                        className=" valid"
                      >
                        <Icon
                          className="fs-16px"
                          style={{ color: "#55B079" }}
                          name="check"
                        ></Icon>
                      </div>
                    )}
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="d-flex flex-row align-items-center fs-2 text-gray-900 fort-medium pr-2">
                      <LazyImage
                        className="hover-cover"
                        boxShadow={true}
                        src={`https://images.penguinrandomhouse.com/cover/${item.isbn}`}
                      />
                      <div className="d-flex flex-column ml-2">
                        <span className="fort-bold">{item.title}</span>

                        {item.available ? (
                          item.cartQuantity ? (
                            <div className="fort-book-italic">
                              Note: You already have {item.cartQuantity}{" "}
                              {item.cartQuantity === 1 ? "copy" : "copies"} of
                              this item in your cart.
                            </div>
                          ) : null
                        ) : (
                          <div className="text-red fort-book-italic">
                            {item.status}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="title-isbn"
                    style={{ width: "20%", minWidth: "135px" }}
                  >
                    {item.isbn}
                  </div>
                  <div className="title-foc" style={{ width: "15%" }}>
                    {isGeneralCart(item.foc)
                      ? "General Cart"
                      : isFocCartExpired(item.foc)
                      ? "General Cart"
                      : `FOC ${formatDateMMDDYYYY(item.foc, "/")}`}
                  </div>
                  <div
                    className="title-qty"
                    style={{ width: "10%", textAlign: "end" }}
                  >
                    {item.quantity && item.quantity}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogModalAccessible>
    </div>
  );
}

BridgeAddComicsTitlesModal.propTypes = {
  listID: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default BridgeAddComicsTitlesModal;
