import { useEffect, useState } from "react";
import { Container } from "semantic-ui-react";
import {
  useLazyGetPostsQuery,
  useLazySearchBizContentQuery,
} from "../../../api/bizcontentApi";
import withApp from "../../../components/hc/withApp";
import LoadingContent from "./LoadingContent";
import PostsList from "./PostsList";
import "./posts.scss";

const Posts = ({ postType, app, page, category, searchQuery }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(-1);
  const [totalResults, setTotalResults] = useState(-1);
  const [performSearch, searchResult] = useLazySearchBizContentQuery();

  const [error, setError] = useState(false);
  const [performGetPosts, getPostsResult] = useLazyGetPostsQuery();

  const reset = () => {
    setLoading(true);
    setError(false);
  };

  useEffect(() => {
    async function asyncCall() {
      reset();
      let posts;
      if (!!searchQuery) {
        const resp = await performSearch({
          searchQuery,
          postType,
          category,
          page,
        });
        posts = resp.data;
      } else {
        const resp = await performGetPosts({ postType, category, page });
        posts = resp.data;
        console.log('get post 3', posts, posts._paging.total, posts._paging.totalPages);
      }
      if (!!posts?.error?.message) {
        setError(posts.error.message);
      } else {
        setPosts(posts);
        setLastPage(posts._paging.totalPages);
        setTotalResults(posts._paging.total);
      }
      setLoading(false);
    }

    asyncCall();
  }, [page, postType, category, app.bizContentApi, searchQuery]);

  return (
    <Container>
      {loading && <LoadingContent />}
      {!loading && error && (
        <div>
          <p className='text-center my-5 px-0'>{error}</p>
        </div>
      )}
      {!loading && !error && (
        <PostsList
          postType={postType}
          posts={posts}
          page={page}
          category={category}
          lastPage={lastPage}
          searchQuery={searchQuery}
          totalResults={totalResults}
        ></PostsList>
      )}
    </Container>
  );
};
Posts.defaultProps = {
  category: "",
  searchQuery: null,
};
export default withApp(Posts);
