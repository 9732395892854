import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import CartContext from "./CartContext";
import ToastGenerator from "../shared-react-components/utils/ToastGenerator";
import ReactGA, { ga } from "react-ga";
//import EventTrigger from '../components/analytics/EventTrigger';
import DialogModal from "../components/modals/DialogModal";
import {
  formatDate,
  formatDateMMDDYYYY,
  formatDateYYYYMMDD,
  isGeneralCart,
} from "../utils/utilities";
import _ from "underscore";
import { cloneDeep } from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLazyGetVersionQuery } from "../api/proxyApi";
import {
  useApplyPromoCodeMutation,
  useDeletePromoCodeMutation,
  useLazyGetAuthorDetailsQuery,
  useLazyGetBacklistDownloadFileQuery,
  useLazyGetBacklistDownloadsInfoQuery,
  useLazyGetCustomerInfoQuery,
  useLazyGetCustomerUserInfoQuery,
  useLazyGetSeriesHistoryQuery,
  useLazyGetSeriesTitlesQuery,
  useLazySearchMultipleBizQuery,
  useLazySearchQuery,
  useValidateAddressMutation,
  useLazyGetCreditCardsQuery,
  useLazyGetPromosQuery,
} from "../api/ecomApi";
import {
  useLazyGetActiveCatalogsQuery,
  useLazyGetCatalogDownloadFileQuery,
  useLazyGetCatalogDownloadsInfoQuery,
  useLazyGetCatalogQuery,
  usePrefetch,
} from "../api/catalogApi";
import {
  useAddEstimateCartItemsMutation,
  useMoveEstimateItemsToGeneralMutation,
  useRemoveEstimateCartItemsMutation,
  useRemoveEstimateCartMutation,
  useRemoveEstimateCartsMutation,
  useAddVariantsToCartMutation,
  useUndoEstimateCartMutation,
  useUpdateVariantsToCartMutation,
  useUpdateEstimateItemQtyMutation,
  useUpdateEstimateVariantMainItemMutation,
  useLazyGetEstimateCartsQuery,
  useConfirmEstimateCartMutation,
} from "../api/estimateCartApi";
import {
  useAddUserCartItemsMutation,
  useAddUserSaveForLaterItemMutation,
  useCreateCartMutation,
  useUpdateCartHeadersMutation,
  useUpdateCartMutation,
  useLazyGetUserCartCardInfoQuery,
  useLazyGetUserCartQuery,
  useLazyGetUserSaveForLaterQuery,
  useRemoveUserCartItemMutation,
  useRemoveUserCartMutation,
  useRemoveUserSaveForLaterItemMutation,
  useSubmitOrderMutation,
  useUpdateItemSaveForLaterQtyMutation,
  useUpdateUserItemQtyMutation,
} from "../api/cartApi";
import useDirtyHandler from "../shared-react-components/components/hc/useDirtyHandler";
import useSecurity from "../components/hc/useSecurity";
import useApp from "../components/hc/useApp";
import { clearCart, resetEcom, setCart } from "../features/ecomSlice";
import { useLazyGetPostsQuery } from "../api/bizcontentApi";
// import { authService } from "../App";
import { logoffUser } from "../features/userSlice";
import { Loader } from "semantic-ui-react";
import AuthService from "../services/authService";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import useAuthService from "../components/hc/useAuthService";

export const CartProvider = (props) => {
  const { children } = props;
  const dirtyHandler = useDirtyHandler();
  const app = useApp();
  const userPriv = useSecurity();
  const user = useSelector((state) => state.user);
  const ecom = useSelector((state) => state.ecom);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  var currentdate = new Date();
  const [
    loadCustomerInfo,
    { isFetching: loadCustomerInfoIsFetching, data: customerInfoData },
  ] = useLazyGetCustomerUserInfoQuery();
  const [
    performGetCustomerInfo,
    {
      isFetching: performGetCustomerInfoIsFetching,
      data: performGetCustomerInfoData,
    },
  ] = useLazyGetCustomerInfoQuery();
  const [
    loadUserCartInfo,
    { isFetching: loadUserCartIsFetching, data: userCartData },
  ] = useLazyGetUserCartQuery();
  const [
    loadEstimateCartsInfo,
    { isFetching: loadEstimateCartsIsFetching, data: estimateCartsData },
  ] = useLazyGetEstimateCartsQuery();

  const [
    loadSearch,
    { isFetching: loadSearchIsFetching, data: useSearchData },
  ] = useLazySearchQuery();
  const [
    loadSearchMultiplBiz,
    {
      isFetching: loadSearchMultiplBizIsFetching,
      data: useSearchMultipleBizData,
    },
  ] = useLazySearchMultipleBizQuery();

  const [
    performGetUserCartCardInfo,
    {
      isFetching: performGetUserCartCardInfoIsFetching,
      data: userCartCardInfoData,
    },
  ] = useLazyGetUserCartCardInfoQuery();
  const [performGetVersion, getVersionResult] = useLazyGetVersionQuery();
  const [performGetActiveCatalogs, getActiveCatalogsResult] =
    useLazyGetActiveCatalogsQuery();
  const [performGetCatalogDownloadsInfo, getCatalogDownloadsInfoResult] =
    useLazyGetCatalogDownloadsInfoQuery();
  const [performGetCatalogDownloadFile, getCatalogDownloadFile] =
    useLazyGetCatalogDownloadFileQuery();
  const [performGetBacklistDownloadsInfo, getBacklistDownloadsInfoResult] =
    useLazyGetBacklistDownloadsInfoQuery();
  const [performGetBacklistDownloadFile, getBacklistDownloadFileResult] =
    useLazyGetBacklistDownloadFileQuery();
  const [performGetSeriesHistory, getSeriesHistoryResult] =
    useLazyGetSeriesHistoryQuery();
  const [performGetSeriesTitles, getSeriesTitlesResult] =
    useLazyGetSeriesTitlesQuery();
  const [performGetAuthorDetails, getAuthorDetailsResult] =
    useLazyGetAuthorDetailsQuery();
  const [performGetPosts, getPostsResult] = useLazyGetPostsQuery();
  const [performGetCreditCards, getCreditCardsResult] =
    useLazyGetCreditCardsQuery();
  const [
    loadUserSaveForLater,
    { isFetching: loadUserSaveForLaterIsFetching, data: userSaveForLaterData },
  ] = useLazyGetUserSaveForLaterQuery();
  const [performGetPromos, getPromosResult] = useLazyGetPromosQuery();
  const [removeUserCartItem, removeUserCartItemResult] =
    useRemoveUserCartItemMutation();
  const [removeUserCart, removeUserCartResult] = useRemoveUserCartMutation();
  const [updateUserItemQty, updateUserItemQtyResult] =
    useUpdateUserItemQtyMutation();
  const [addUserCartItems, addUserCartItemsResult] =
    useAddUserCartItemsMutation();
  const [removeUserSaveForLaterItem, removeUserSaveForLaterItemResult] =
    useRemoveUserSaveForLaterItemMutation();
  const [addUserSaveForLaterItem, addUserSaveForLaterItemResult] =
    useAddUserSaveForLaterItemMutation();
  const [performUpdateCartHeaders, updateCartHeadersResult] =
    useUpdateCartHeadersMutation();
  const [performCreateCart, createCartResult] = useCreateCartMutation();
  const [performAddEstimateCartItems, addEstimateCartItemsResult] =
    useAddEstimateCartItemsMutation();
  const [performAddVariantsToCart, addVariantToCartResult] =
    useAddVariantsToCartMutation();
  const [performUpdateVariantToCart, updateVariantToCartResult] =
    useUpdateVariantsToCartMutation();
  const [performRemoveEstimateCart, removeEstimateCartResult] =
    useRemoveEstimateCartMutation();
  const [performRemoveEstimateCarts, removeEstimateCartsResult] =
    useRemoveEstimateCartsMutation();
  const [performMoveEstimateItemsToGeneral, moveEstimateItemsToGeneralResult] =
    useMoveEstimateItemsToGeneralMutation();
  const [performRemoveEstimateCartItems, removeEstimateCartItemsResult] =
    useRemoveEstimateCartItemsMutation();
  const [performUpdateEstimateItemQty, updateEstimateItemQtyResult] =
    useUpdateEstimateItemQtyMutation();
  const [
    performUpdateEstimateVariantMainItem,
    updateEstimateVariantMainItemResult,
  ] = useUpdateEstimateVariantMainItemMutation();
  const [performConfirmEstimateCart, confirmEstimateCartResult] =
    useConfirmEstimateCartMutation();
  const [performUndoEstimateCart, undoEstimateCartResuslt] =
    useUndoEstimateCartMutation();
  const [performApplyPromoCode, applyPromoCodeResult] =
    useApplyPromoCodeMutation();
  const [performDeletePromoCode, deletePromoCodeResult] =
    useDeletePromoCodeMutation();
  const [performUpdateCart, updateCartResult] = useUpdateCartMutation();
  const [performSubmitOrder, submitOrderResult] = useSubmitOrderMutation();
  const [performValidateAddress, validateAddressResult] =
    useValidateAddressMutation();
  const preFetchCatalogs = usePrefetch("getCatalog");

  // const {
  //   data: customerInfoTK,
  //   isFetching,
  //   isLoading,
  // } = useGetCustomerUserInfoQuery(
  //   { sapAccountNo: ccSapAccountNo },
  //   { skip: true }
  // );

  //const history = useHistory();
  var defaultPurchaseOrder =
    "" +
    (currentdate.getMonth() + 1) +
    currentdate.getDate() +
    +currentdate.getFullYear() +
    +currentdate.getHours() +
    +currentdate.getMinutes() +
    "";

  const [activeCatalogs, setActiveCatalogs] = useState([]);
  const [recentPosts, setRecentPosts] = useState([]);
  const [maxCatalogDate, setMaxCatalogDate] = useState(false);
  const [estimateDueDate, setEstimateDueDate] = useState(false);
  const [catalogAvailabilityDate, setCatalogAvailabilityDate] = useState(false);
  /*
  const [customerInfo, setCustomerInfo] = useState({
    shipTos: [],
    shipToAccountNos: [],
    customer: { country: "US", webShippable: [] },
  });*/
  const [
    currentMonthCatalogAvailabilityDate,
    setCurrentMonthCatalogAvailabilityDate,
  ] = useState(false);
  // const [saveForLater, setSaveForLater] = useState({ items: [] });
  const [cartLoaded, setCartLoaded] = useState(false);
  const [cartInitialized, setCartInitialized] = useState(false);
  const [customerLoaded, setCustomerLoaded] = useState(false);
  const [orderQueryId, setOrderQueryId] = useState(null);
  const [backorderQueryId, setStateBackorderQueryId] = useState(null);
  // const [error, setError] = useState(false);
  const [updatingCart, setUpdatingCart] = useState(false);
  const [ccSapAccountNo, setCcSapAccountNo] = useState(false);
  const [shipTo, setShipTo] = useState(-1);
  const [domain, setDomain] = useState(window.location.hostname);
  const [cartBadgeOpen, setCartBadgeOpen] = useState(false);
  const [cartAddToCartOpen, setCartAddToCartOpen] = useState(false);
  const [cartAddToCartItems, setCartAddToCartItems] = useState([]);
  const [cartTimer, setCartTimer] = useState(false);
  const [latestVersion, setLatestVersion] = useState(false);
  const [checkout, setCheckout] = useState({
    cartConfirmed: false,
    shippingAddressEdit: false,
    paymentCC: false,
    paymentUseBillingAddress: false,
    paymentCCPreAuth: false,
    paymentCCNumber: false,
    paymentCCType: false,
    // New cc info
    paymentCreditCard: false,
    shipToAddress: 0,
    paymentForm: null,
    orderForEvent: false,
    orderEventDate: "",
    orderEventFullName: "",
    orderEventPhone: "",
    purchaseorder: defaultPurchaseOrder,
    invoiceText: "",
    phone: "",
    billOfLading: "",
    cartonMarking: "",
    name: "",
    company: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
    shippingMethod: "01",
    addressValid: false,
    emailAddress: "",
  });
  const [searchResults, setSearchResuts] = useState({});
  const [searchFilters, setSearchFilters] = useState([]);
  const [userList, setUserList] = useState([]);
  const [showNotifications, setShowNotifications] = useState({
    biz: true,
    catalog: true,
  });
  const [catalogFilters, setCatalogFilters] = useState({});
  const [catalogId, setCatalogId] = useState(0);
  const [versionUpdateAvailable, setVersionUpdateAvailable] = useState(false);
  const [initialUserLoadComplete, setInitialUserLoadComplete] = useState(false);
  const [preloadCatalogs, setPreloadCatalogs] = useState(false);
  const [
    performGetCatalog,
    { data: catalogData, isFetching: catalogDataIsFetching },
  ] = useLazyGetCatalogQuery();
  const authService = useAuthService();

  const initialUserLoad = async () => {
    // console.log("start initial load", user);
    //const history = useHistory();
    let keepSpinner = false;
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Loading . . .",
      longLoadText: "Still loading . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const customerInfo = await getCustomerUser(ccSapAccountNo, false);

    // console.log("customerInfo", customerInfo, ecom.customerInfo);
    // if (!customerInfo) {
    //   dirtyHandler.setLoadingTakeover(false);
    //   userPriv.clearUser();
    //   userPriv.logout();
    //   //history.push('/?err=Login+failed%2C+please+try+again.');
    //   // window.location.href = "/?err=Login+disabled";
    //   dirtyHandler.setError({
    //     error: 500,
    //     response: { message: "Sap account is missing" },
    //   });
    //   return;
    //   //location.replace("https://biz-local.penguinrandomhouse.com/?err=Login+failed%2C+please+try+again.");
    //   //history.push("https://biz-local.penguinrandomhouse.com/?err=Login+failed%2C+please+try+again.");
    // }
    if (!customerInfo?.currentUser) {
      dirtyHandler.setLoadingTakeover(false);
      setCustomerLoaded(true);
      setCartLoaded(true);
      // dispatch(logoffUser());
      dispatch(resetEcom());
      console.log("pushing to /logout");
      history.push({ pathname: "/logout" });

      // userPriv.clearUser();
      // dispatch(logoffUser());
      // authService.logout();
      // //history.push('/?err=Login+failed%2C+please+try+again.');
      // // window.location.href = "/?err=Login+disabled";
      // userPriv.setError({
      //   error: 500,
      //   response: { message: "Sap account is missing" },
      // });
      // userPriv.clearUser();
      // userPriv.logout();
      // //history.push('/?err=Login+failed%2C+please+try+again.');
      // window.location.href = "/?err=Login+error";
      return;
      //location.replace("https://biz-local.penguinrandomhouse.com/?err=Login+failed%2C+please+try+again.");
      //history.push("https://biz-local.penguinrandomhouse.com/?err=Login+failed%2C+please+try+again.");
    }

    if (customerInfo && !customerInfo.customer?.isPow) {
      // console.log('path is ', location);
      // if (location.pathname !== '/cart') {
      await loadUserCart(ccSapAccountNo, false, false, customerInfo);
    } else {
      // console.log("initialuser load", customerInfo);
      if (
        customerInfo?.shipToAccountNo &&
        customerInfo?.shipToAccountNos.length === 1
      ) {
        keepSpinner = true;
        setStateShipTo(0);

        console.log("loading estimate cart from initail user");
        //await loadEstimateCarts({ silent: false });
        setCartLoaded(true);
        // dirtyHandler.setLoadingTakeover(false);
      } else if (customerInfo?.currentUser.role === "W1") {
        const st = customerInfo?.shipToAccountNos.findIndex(
          (s) => s.accountNo === customerInfo.currentUser.accountNo
        );
        if (st > -1) {
          setStateShipTo(st);
        }
        setCartLoaded(true);
      } else {
        setCartLoaded(true);
      }
    }

    // if (!!!keepSpinner) dirtyHandler.setLoadingTakeover(false);
    let userType;
    if (customerInfo && customerInfo?.customer?.isPow) {
      getActiveCatalogs();
      getPosts();
      userType = "DM";
    } else {
      userType = "Trade";
    }
    //ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    //ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {dimension1: userType});
    //ReactGA.ga('set', 'dimension1', userType);
    //ReactGA.ga('set', 'dimension1', userType);

    if (location.pathname !== "/silentrenew") {
      ReactGA.ga("set", "dimension1", userType);
    }
    setInitialUserLoadComplete(true);
    setCustomerLoaded(true);
    dirtyHandler.setLoadingTakeover(false);
    // console.log('finished initial load', props);
  };

  const loadUserCart = async (
    workSapAccountNo,
    silent = true,
    simulate = true,
    customerInfo = false
  ) => {
    // console.log("loadUserCart", workSapAccountNo, silent, simulate);
    let shouldTakeOver = true;
    if (dirtyHandler.loadingTakeover) shouldTakeOver = false;

    if (silent && shouldTakeOver) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Loading . . .",
        longLoadText: "Still loading . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    let workCustomerInfo = customerInfo ? customerInfo : ecom.customerInfo;
    let workCart;
    // console.log(
    //   'customerInfo.shipToAccountNos',
    //   customerInfo.shipToAccountNos,
    //   user.isAppBiz()
    // );
    // console.log("work", workCustomerInfo);
    if (
      workCustomerInfo?.shipToAccountNos.length === 1 &&
      userPriv.isAppBiz()
    ) {
      workCart = await performUpdateCartHeaders({
        cartHeaders: {
          shipToAccountNo: workCustomerInfo.shipToAccountNos[0].accountNo,
        },
        cartKey: ecom.cart.cartKey,
        simulate: true,
      });
    } else {
      workCart = await loadUserCartInfo({
        sapAccountNo: workSapAccountNo,
        cartKey: ecom.cart.cartKey,
        simulate,
      }).refetch();
    }
    // console.log(workCart);
    if (workCart && workCart.data?.status === "OK") {
      if (
        workCustomerInfo?.shipToAccountNos &&
        workCart.data.data.headers?.shipToAccountNo
      ) {
        const fst = workCustomerInfo.shipToAccountNos.findIndex(
          (s) => s.accountNo === workCart.data.data.headers.shipToAccountNo
        );

        if (fst >= 0) {
          setStateShipTo(fst);
        }
      }
      setCartLoaded(true);
    }

    // console.log('takeover turning off from cart');
    if (silent && shouldTakeOver) dirtyHandler.setLoadingTakeover(false);

    if (!workCustomerInfo.customer.isPow) {
      loadUserSaveForLater({ sapAccountNo: workSapAccountNo }).then(
        (sfl) => {}
      );
    }
    setCartInitialized(true);
    // console.log('saveforlater', sfl);
  };

  const loadEstimateCarts = async (props = {}) => {
    // console.log("loadEstimateCarts", props);
    // console.log(
    //   "loadEstimateCarts",
    //   props,
    //   shipTo,
    //   getShipToAccount().accountNo
    // );
    // console.log(
    //   'loadEstimateCarts running--------------',
    //   props,
    //   dirtyHandler.loadingTakeover
    // );
    const {
      shipTo = false,
      focDate,
      silent = true,
      forceTakeOver = false,
      titleDetail = true,
      simulate = false,
      orderDetail = false,
    } = props;

    let shouldTakeOver = true;
    if (dirtyHandler.loadingTakeover) shouldTakeOver = false;

    if ((silent && shouldTakeOver) || forceTakeOver) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Loading . . .",
        longLoadText: "Still loading . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    const params = {
      shipTo: shipTo ? [shipTo] : [getShipToAccount().accountNo],
      titleDetail,
      simulate,
      orderDetail,
    };
    if (shipTo) {
      params.skipCartLoad = true;
    }

    if (focDate) params.foc = focDate;

    // console.log("loadEstimateCarts", params);
    const workCart = await loadEstimateCartsInfo(params);
    // console.log("workCart", workCart);

    if ((silent && shouldTakeOver) || forceTakeOver) {
      dirtyHandler.setLoadingTakeover(false);
    }

    if (shipTo) {
      if (workCart && workCart.status === "fulfilled") {
        return workCart.data;
      } else {
        return [];
      }
    }

    if (workCart && workCart.status === "fulfilled") {
      if (focDate && workCart.data.data.carts.length > 0) {
        let workCheckout = { ...checkout };
        if (
          workCart.data.data.carts[0].headers &&
          workCart.data.data.carts[0].headers.poNumber
        )
          workCheckout.purchaseorder =
            workCart.data.data.carts[0].headers.poNumber;
        else {
          var currentdate = new Date();
          workCheckout.purchaseorder =
            "" +
            (currentdate.getMonth() + 1) +
            currentdate.getDate() +
            +currentdate.getFullYear() +
            +currentdate.getHours() +
            +currentdate.getMinutes() +
            "";
        }
        if (
          workCart.data.data.carts[0].headers &&
          workCart.data.data.carts[0].headers.invoiceText
        )
          workCheckout.invoiceText =
            workCart.data.data.carts[0].headers.invoiceText;
        if (
          workCart.data.data.carts[0].headers &&
          workCart.data.data.carts[0].headers.phone
        )
          workCheckout.phone = workCart.data.data.carts[0].headers.phone;
        if (
          workCart.data.data.carts[0].headers &&
          workCart.data.data.carts[0].headers.eventContactName
        ) {
          workCheckout.orderForEvent = true;
          workCheckout.orderEventFullName =
            workCart.data.data.carts[0].headers.eventContactName;
          workCheckout.orderEventPhone =
            workCart.data.data.carts[0].headers.eventContactPhone;
          workCheckout.orderEventDate =
            workCart.data.data.carts[0].headers.eventDate !== "0000-00-00"
              ? new Date(workCart.data.data.carts[0].headers.eventDate + " EST")
              : "";
        }
        if (
          workCart.data.data.carts[0].headers &&
          workCart.data.data.carts[0].headers.cardId
        ) {
          workCheckout.payByCreditCard =
            workCart.data.data.carts[0].headers.payByCreditCard;
          workCheckout.paymentCreditCard =
            workCart.data.data.carts[0].headers.cardId;
          workCheckout.paymentCC =
            typeof workCart.data.data.carts[0].headers.payByCreditCard ===
            "string"
              ? workCart.data.data.carts[0].headers.payByCreditCard === "true"
              : workCart.data.data.carts[0].headers.payByCreditCard;
        } else {
          workCheckout.paymentCreditCard = false;
        }

        setCartLoaded(true);
        setCheckout(workCheckout);
        dispatch(
          setCart({
            cart: { ...workCart.data.data.carts[0] },
          })
        );
        setCartInitialized(true);

        // setEstimateCarts(newEstimateCarts);
        return workCart.data;
      } else {
        // console.log("saving to state", workCart.data.data.carts);
        if (workCart.data.data.carts) {
          setCartInitialized(true);
          return workCart.data.data.carts;
        } else {
          setCartInitialized(true);
          return [];
        }
      }
    }

    setCartInitialized(true);

    return [];
  };

  // const clearGeneralEstimateCart = () => {
  //   if (estimateCarts && estimateCarts.length > 0) {
  //     const nec = [];
  //     estimateCarts.map((ec) => {
  //       if (!isGeneralCart(ec.foc)) {
  //         nec.push(ec);
  //       }
  //     });
  //     setEstimateCarts(nec);
  //   }
  // };

  const clearCheckout = () => {
    var currentdate = new Date();
    var defaultPurchaseOrder =
      "" +
      (currentdate.getMonth() + 1) +
      currentdate.getDate() +
      +currentdate.getFullYear() +
      +currentdate.getHours() +
      +currentdate.getMinutes() +
      "";

    let shipToAddress =
      ecom.customerInfo.customer.webShippable.length > 0
        ? ecom.customerInfo.customer.webShippable[0]
        : 0;

    setCheckout({
      shippingAddressEdit: false,
      paymentCC: ecom.customerInfo.customer.cashOnly ? true : false,
      paymentCCNumber: false,
      paymentCCType: false,
      paymentUseBillingAddress: false,
      shipToAddress,
      paymentForm: null,
      orderForEvent: false,
      orderEventDate: "",
      orderEventFullName: "",
      orderEventPhone: "",
      purchaseorder: defaultPurchaseOrder,
      invoiceText: "",
      phone: "",
      billOfLading: "",
      cartonMarking: "",
      name: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "US",
      shippingMethod: "01",
      addressValid: false,
    });
    if (!ecom?.customerInfo?.customer?.isPow) {
      setShipTo(0);
    }
  };
  const getCCInfo = async (silent = true) => {
    // console.log('loadUserCart', sapAccountNo, silent);
    if (silent) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Verifying credit card . . .",
        longLoadText: "Still verifying credit card . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    const card = await performGetUserCartCardInfo({
      cartKey: ecom.cart.cartKey,
    });
    // console.log('getCCInfo', card);

    if (card.data?.status === "OK") {
      if (card.data.data?.card?.cardLastFour) {
        setCheckout({
          ...checkout,
          paymentUseBillingAddress: false,
          paymentCCNumber: card.data.data.card.cardLastFour,
          paymentCCType: card.data.data.card.cardType,
        });
      }
    }

    if (silent) dirtyHandler.setLoadingTakeover(false);

    return card;
  };

  const getCreditCards = async (paymentCreditCard = null) => {
    // console.log('getCreditCards', props);
    const creditCards = await performGetCreditCards({
      cardId: paymentCreditCard,
    });
    return creditCards;
  };

  const sortCartItems = (items) => {
    var newCartiTems = _.sortBy(items, function (item) {
      return item.updated;
    }).reverse();

    return _.sortBy(newCartiTems, function (item) {
      if (item.inventoryFilter === "backordered") {
        return 0;
      } else {
        return 1;
      }
    });
  };

  const getCartItemsSorted = ({
    sortOrder = "desc",
    sortCart = false,
    sortField = false,
  }) => {
    let workCart = {};
    if (sortCart) {
      workCart = sortCart;
    } else {
      workCart = ecom.cart;
    }

    var newCartiTems;
    if (sortField) {
      if (sortField === "divisionName") {
        newCartiTems = _.chain(workCart.items)
          .sortBy(function (item) {
            return item.onsale;
          })
          .reverse()
          .sortBy(function (item) {
            return item[sortField];
          })
          .value();
      } else {
        newCartiTems = _.sortBy(workCart.items, function (item) {
          return item[sortField];
        });
      }
    } else {
      newCartiTems = [...workCart.items];
      // newCartiTems = _.sortBy(workCart.items, function (item) {
      //   return item?.updated;
      // });
    }

    // if (backorderSort) {
    //   return _.sortBy(newCartiTems, function (item) {
    //     if (item.inventoryFilter === 'backordered') {
    //       return 0;
    //     } else {
    //       return 1;
    //     }
    //   });
    // } else {
    if (sortOrder === "desc") return newCartiTems.reverse();
    else return newCartiTems;
    // }
  };

  const getCartItemsFiltered = (
    filterCart = false,
    excludeBackordered = false
  ) => {
    // const items = getCartItemsSorted(true);
    // console.log('filter', cart, excludeBackordered);
    let items = [];

    if (filterCart.items && Array.isArray(filterCart.items)) {
      items = [...filterCart.items];
    } else {
      items = [...com.cart.items];
    }
    // console.log('getCartItemsFiltered', items);

    var unavailableCartiTems = items.filter((item) => {
      if (!item.available && item.saleStatus !== "NR") {
        return true;
      } else {
        return false;
      }
    });

    var unavailableInRegionCartiTems = items.filter((item) => {
      if (!item.available && item.saleStatus === "NR") {
        return true;
      } else {
        return false;
      }
    });

    var backorderedCartiTems = excludeBackordered
      ? []
      : items.filter((item) => {
          if (
            (item.inventoryFilter === "backordered" ||
              item.inventoryFilter === "prepub") &&
            item.available
          ) {
            return true;
          } else {
            return false;
          }
        });

    var availableCartiTems = items.filter((item) => {
      if (item.available) {
        if (
          excludeBackordered &&
          (item.inventoryFilter === "backordered" ||
            item.inventoryFilter === "prepub")
        ) {
          return true;
        } else if (
          item.inventoryFilter === "backordered" ||
          item.inventoryFilter === "prepub"
        ) {
          return false;
        } else {
          return true;
        }
      } else return false;
    });

    return {
      unavailable: unavailableCartiTems,
      unavailableInRegion: unavailableInRegionCartiTems,
      backordered: backorderedCartiTems,
      available: availableCartiTems,
    };
  };

  useEffect(() => {
    if (customerLoaded) {
      if (ecom.customerInfo.customer.isPow !== true) {
        //if (!ecom.cart.cartKey) {
        // console.log("doing initial cart load for biz user");
        // loadUserCart(ccSapAccountNo, false, false);
        //}
      } else {
        setCartLoaded(true);
      }
    }

    return () => {};
  }, [customerLoaded]);

  useEffect(() => {
    if (
      preloadCatalogs?.catalogs &&
      ecom.customerInfo.customer.isPow === true
    ) {
      const { searchClass, country } = ecom.customerInfo.customer;
      // console.log("preloadcat", preloadCatalogs);
      preloadCatalogs.catalogs.forEach((cat) => {
        preFetchCatalogs({
          catalogDate: cat.val,
          searchClass,
          country,
          shipTo: false,
        });
      });
    }
    return () => {};
  }, [preloadCatalogs, ecom.customerInfo.customer.isPow === true]);

  const getActiveCatalogs = async () => {
    const activeCatalogs = await performGetActiveCatalogs(undefined, true);
    setPreloadCatalogs(activeCatalogs.data?.data);

    setEstimateDueDate(activeCatalogs?.data?.data?.estimateDueDate);
    setCurrentMonthCatalogAvailabilityDate(
      setActiveCatalogs?.data?.data?.currentMonthCatalogAvailabilityDate
    );
    setCatalogAvailabilityDate(
      setActiveCatalogs?.data?.data?.catalogAvailabilityDate
    );
    setMaxCatalogDate(activeCatalogs?.data?.data?.maxCatalogDate);
    setActiveCatalogs(cloneDeep(activeCatalogs?.data?.data?.catalogs));
  };

  const getCurrentCatalogFilters = async (sentCatalogId) => {
    let currentFilters = {};

    if (catalogId !== 0) {
      if (sentCatalogId === catalogId) {
        currentFilters = catalogFilters;
      } else {
        setCatalogId(sentCatalogId);
        // setCurrentFilters({});
      }
    } else {
      setCatalogId(sentCatalogId);
    }

    return currentFilters;
  };

  const setCurrentCatalogFilters = async (filters) => {
    setCatalogFilters(filters);
  };

  const getPosts = async () => {
    const postType = "news-from-prh";
    const posts = await performGetPosts({ postType });
    setRecentPosts(posts);
  };

  const getCustomerUser = async (sapAccountNo = false, loader = true) => {
    let shouldTakeOver = true;
    let shipToAccountNos;
    if (dirtyHandler.loadingTakeover) shouldTakeOver = false;

    if (loader && shouldTakeOver) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Verifying account number . . .",
        longLoadText: "Still verifying account number . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    const custUser = await loadCustomerInfo({ sapAccountNo });
    // console.log("custUser", custUser);

    if (custUser && custUser.status === "fulfilled") {
      let shipToAddress = 0;
      let shipTo = -1;
      shipToAccountNos = custUser.data.data.customer.webShippable.map((s) => {
        return custUser.data.data.shipTos[s];
      });
      //if (Cart.customerInfo?.currentUser?.roleNo==='W1' && Cart.customerInfo?.currentUser?.accountNo===Cart.customerInfo?.customer?.accountNo)
      const value = queryString.parse(location.search);
      if (
        (custUser.data.data?.customer?.isPow &&
          shipToAccountNos.length === 1 &&
          !Object.prototype.hasOwnProperty.call(value, "listID")) ||
        !custUser.data.data?.customer?.isPow
      ) {
        setShipTo(0);
      }
      if (ecom.cart.headers && ecom.cart.headers.shipToAccountNo) {
        // cart.customerInfo.customer.webShippable
        let si = shipToAccountNos.findIndex(
          (s) => s.accountNo === ecom.cart.headers.shipToAccountNo
        );
        if (si >= 0) {
          setShipTo(si);
          shipToAddress = si;
        }
      }
      if (custUser.data.data.currentUser.roleNo === "W1") {
        // cart.customerInfo.customer.webShippable
        let si = shipToAccountNos.findIndex(
          (s) => s.accountNo === custUser.data.data.currentUser.accountNo
        );
        if (si >= 0) {
          setShipTo(si);
          shipToAddress = si;
        }
      }
      // console.log('state', state, custUser, shipToAccountNos);
      setCustomerLoaded(true);
      //setCustomerInfo({ ...custUser.data.data, shipToAccountNos });
      // shipTo: shipTo,
      setCheckout({
        ...checkout,
        shipToAddress,
      });
    } else if (custUser && custUser?.error?.status === 404) {
      loader = false;
      shouldTakeOver = false;
      setCustomerLoaded(true);
      //setCustomerInfo({ ...custUser.data.data, shipToAccountNos: [] });
      setCheckout({});

      return null;
    }

    if (loader && shouldTakeOver) dirtyHandler.setLoadingTakeover(false);
    // return custUser;
    return { ...custUser?.data?.data, shipToAccountNos };
  };

  const getCustomerInfo = async (sapAccountNo = false, loader = true) => {
    // console.log('getCustomerInfo');
    let shouldTakeOver = true;
    if (dirtyHandler.loadingTakeover) shouldTakeOver = false;

    if (loader && shouldTakeOver) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Verifying account number . . .",
        longLoadText: "Still verifying account number . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    const custUser = await performGetCustomerInfo({ sapAccountNo }, true);
    // console.log('customerUser', custUser);

    if (custUser && custUser.data?.status === "OK") {
      let shipToAddress = 0;
      const shipToAccountNos = custUser.data.data.customer.webShippable.map(
        (s) => {
          return custUser.data.data.shipTos[s];
        }
      );

      if (ecom.cart.headers && ecom.cart.headers.shipToAccountNo) {
        // cart.customerInfo.customer.webShippable
        let si = shipToAccountNos.findIndex(
          (s) => s.accountNo === ecom.cart.headers.shipToAccountNo
        );
        if (si >= 0) {
          shipToAddress = si;
        }
      }
      setCustomerLoaded(true);
      //setCustomerInfo({ ...custUser.data.data, shipToAccountNos });
      if (!custUser.data.data.customer.isPow) {
        setShipTo(0);
      }
      setCheckout({
        ...checkout,
        shipToAddress,
      });
    }
    if (loader && shouldTakeOver) dirtyHandler.setLoadingTakeover(false);
    return custUser;
  };

  useEffect(() => {
    setTimeout(() => {
      checkVersion();
    }, 30000);

    return () => {};
  }, []);

  const checkVersion = () => {
    //console.log('Checking for new software version');
    performGetVersion().then((e) => {
      // console.log("e", e, process.env.REACT_APP_VERSION);
      // console.log("getversion", e);

      if (
        e.status === "fulfilled" &&
        e.data.trim() !== process.env.REACT_APP_VERSION.trim()
      ) {
        /*console.log(
          `Found version update, current version: ${process.env.REACT_APP_VERSION}, new version: ${e.data}`
        );*/

        setLatestVersion(e.data);
        setVersionUpdateAvailable(true);
      } else {
      }
      setTimeout(() => {
        checkVersion();
      }, 600000);
    });
  };

  useEffect(() => {
    user?.debug &&
      console.log(
        "user change",
        user,
        ecom.customerInfo,
        userPriv,
        userPriv.isAuthenticated(),
        customerLoaded,
        location
      );
    if (
      userPriv.isAuthenticated() &&
      user.isAuthenticated &&
      !customerLoaded &&
      location.pathname !== "/silentrenew" &&
      location.pathname !== "/signin-oidc"
    ) {
      // console.log("we are here", userPriv.appID);
      if (user.accessToken && !customerLoaded) {
        if (userPriv.appID === "biz") {
          initialUserLoad();
        } else if (userPriv.isAppCompCopy() && ccSapAccountNo) {
          loadUserCart(ccSapAccountNo);
          setCustomerLoaded(true);
        } else if (userPriv.isAppCompCopy()) {
          setCartLoaded(true);
          setCustomerLoaded(true);
          history.push({ pathname: "/cclogin" });
        }
      }

      if (
        userPriv.isAppCompCopy() &&
        user.user !== null &&
        !ccSapAccountNo &&
        location.pathname !== "/cclogin"
      ) {
        history.push({ pathname: "/cclogin" });
      }
      if (userPriv.isAppCompCopy() && ccSapAccountNo && !customerLoaded) {
        loadUserCart(ccSapAccountNo);
        setCustomerLoaded(true);
      }
    }
    // if (authService.isAuthenticated() && ecom.customerInfo.currentUser) {
    //   setCustomerLoaded(true);
    //   setCartLoaded(true);
    // }
    // if (
    //   !authService.isAuthenticated() &&
    //   !user.isAuthenticated
    //   // ecom.customerInfo?.currentUser
    // ) {
    //   console.log("reseting ecom");
    //   dispatch(resetEcom());
    // }
    return () => {};
  }, [user, location, ccSapAccountNo]);

  // isUserPow = () => {
  //   const powClass = ['J9', 'M2', 'M8', 'G3'];
  //   if (powClass.includes(customerInfo?.customer?.customerClass)) {
  //     return true;
  //   }
  //   return false;
  // };
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log('CDU App', props);
  //   if (user.isAuthenticated()) {
  //     user.getUser().then((user) => {
  //       console.log('load token from CDU app user', user);
  //       setApiTokens(user.access_token);
  //     });
  //   }
  // }

  const createCart = async (props) => {
    const { cart, supressDirty = false } = props;

    clearCheckout();

    const newCart = cloneDeep(cart);

    // console.log("createCart", cart, newCart);

    setUpdatingCart(true);
    if (ecom.customerInfo.customer.cashOnly) {
      setCheckout({ ...checkout, paymentCC: true, paymentCreditCard: false });
    }

    if (!supressDirty) {
      // console.log('starting dirty');
      dirtyHandler.setLoadingTakeoverConfig({
        text: "loading your cart . . .",
        longLoadText: "Still loading your cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    delete newCart.headers.cardId;
    delete newCart.headers.eventContactName;
    delete newCart.headers.eventContactPhone;
    delete newCart.headers.eventDate;
    delete newCart.headers.invoiceText;
    delete newCart.headers.orderReason;
    delete newCart.headers.phone;
    delete newCart.headers.poNumber;
    // delete newCart.headers;
    delete newCart.orders;
    delete newCart.orderedItems;
    newCart.items.map((item) => {
      if (item?.itemId) delete item.itemId;
    });

    // console.log('sending', cart, newCart);
    const result = await performCreateCart({
      data: newCart,
      sapAccountNo: ccSapAccountNo,
    });

    // console.log('createCart', result);
    if (result?.data.status === "OK") {
      setUpdatingCart(false);
    }
    if (!supressDirty) dirtyHandler.setLoadingTakeover(false);
  };

  const getOrderQueryId = () => {
    return orderQueryId;
  };

  const getBackorderQueryId = () => {
    return backorderQueryId;
  };

  const setBackorderQueryId = (value) => {
    setStateBackorderQueryId(value);
  };

  const addEstimateItems = async (props) => {
    const {
      items,
      foc,
      carts = false,
      shipTo = false,
      supressDirty = false,
      supressAddModal = false,
      addAll = false,
      simulate = true,
      listId = false,
    } = props;
    // console.log('addEstimateItem', props, getShipToAccount());
    setCartAddToCartOpen(false);
    setCartAddToCartItems([]);

    setUpdatingCart(true);
    if (!supressDirty) {
      // console.log('starting dirty');
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Adding item to cart . . .",
        longLoadText: "Still adding item to cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    let workCarts = [];
    if (foc && items) {
      workCarts.push({
        foc,
        items,
        shipTo: shipTo ? shipTo : getShipToAccount().accountNo,
      });
    } else {
      workCarts = [...carts];
    }

    const result = await performAddEstimateCartItems({
      carts: workCarts,
      simulate,
      addAll,
      listId,
    });

    // console.log("addEstimateCartItems", result, addEstimateCartItemsResult);
    if (result.data?.status === "OK" && !result.data.data.modalInfo) {
      // let workCart = { ...cart };
      // result.data.data.carts.forEach((c) => {
      //   if (c.foc === workCart.foc) {
      //     workCart = { ...c };
      //   }
      // });
      if (supressAddModal) {
        setUpdatingCart(false);
        // dispatch(setCart({ cart: workCart }));
      } else {
        const updatedItems = result.data.data.carts[0].items.filter((i) => {
          if (items.findIndex((ui) => ui.isbn === i.isbn) >= 0) {
            return i;
          }
        });
        // console.log('updatedItems', updatedItems);
        setUpdatingCart(false);
        setCartAddToCartItems(updatedItems);
        setCartAddToCartOpen(true);

        clearTimeout(cartTimer);
        let addToCartTimer = setTimeout(
          () => {
            setCartAddToCartOpen(false);
            setCartAddToCartItems([]);
          },
          updatedItems.length < 2 ? 4000 : 6000
        );
        setCartTimer(addToCartTimer);
      }
      // updateEstimateCarts(result.data.data.carts);
    } else if (
      result.data?.status === "OK" &&
      result.data.data.modalInfo === "ADDED_ITEMS"
    ) {
      // updateEstimateCarts(result.data.data.updatedCarts);
    }

    if (!supressDirty) dirtyHandler.setLoadingTakeover(false);

    return result.data.data;
    // }
  };

  const addEstimateVariantItems = async (props) => {
    const { requestBody, newIsbnsArray, update = false } = props;
    // console.log('addEstimateItem', props, getShipToAccount());
    setCartAddToCartOpen(false);
    setCartAddToCartItems([]);

    setUpdatingCart(true);
    // console.log('starting dirty');
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Adding item to cart . . .",
      longLoadText: "Still adding item to cart . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    let result;

    if (update)
      result = await performUpdateVariantToCart({ data: requestBody });
    else result = await performAddVariantsToCart({ data: requestBody });

    if (result?.data?.status === "OK" && !result.data.data.modalInfo) {
      const updatedItems = result.data.data.carts[0].items.filter((i) => {
        if (newIsbnsArray.includes(i.isbn)) {
          return i;
        }
      });
      setUpdatingCart(false);
      setCartAddToCartOpen(true);
      setCartAddToCartItems(updatedItems);

      dispatch(setCart({ cart: { ...result.data.data.carts[0] } }));

      clearTimeout(cartTimer);
      let addToCartTimer = setTimeout(() => {
        setCartAddToCartOpen(false);
        setCartAddToCartItems([]);
      }, 3000);
      setCartTimer(addToCartTimer);

      // updateEstimateCarts(result.data.data.carts);
    } else {
      dirtyHandler.setLoadingTakeover(false);
      return result.data.data;
    }
    dirtyHandler.setLoadingTakeover(false);
    return result.data.data;
  };

  const addItems = async (items, supressDirty = false, simulate = false) => {
    setUpdatingCart(true);
    if (!supressDirty) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Adding items to cart . . .",
        longLoadText: "Still adding items to cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    items.map((i) => {
      const existingIsbn = ecom.cart.items.filter(
        (item) => item.isbn === i.isbn
      );
      if (existingIsbn && existingIsbn.length === 1) {
        i.quantity += existingIsbn[0].quantity;
        i.itemId = existingIsbn[0].itemId;
      }
    });

    const result = await addUserCartItems({
      items,
      sapAccountNo: ccSapAccountNo,
      simulate,
    });

    // console.log("cart state update", result);
    if (result.data?.status === "OK") {
      const updatedItems = result.data.data.items.filter((i) => {
        if (items.findIndex((ui) => ui.isbn === i.isbn) >= 0) {
          return i;
        }
      });
      setUpdatingCart(false);
      setCartAddToCartOpen(true);
      setCartAddToCartItems(updatedItems);
      clearTimeout(cartTimer);
      let addToCartTimer = setTimeout(() => {
        setCartAddToCartOpen(false);
        setCartAddToCartItems([]);
      }, 3000);
      setCartTimer(addToCartTimer);
    }
    // if (!supressDirty) {
    //   dirtyHandler.setLoadingTakeover(false);
    // }
    return { ...result.data };
  };

  const removeItem = async (
    itemId,
    supressDirty = false,
    movedSaveForLater = false
  ) => {
    if (!supressDirty) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Updating cart . . .",
        longLoadText: "Still updating cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    let msg = "Title Removed";

    if (Array.isArray(itemId)) {
      msg = `${itemId.length} Titles have been removed from your cart`;
    } else {
      const item = ecom.cart.items.filter((i) => i.itemId === itemId);
      if (item.length === 1) {
        if (movedSaveForLater) {
          msg = `${item[0].title} has been moved to your saved for later`;
        } else {
          msg = `${item[0].title} has been removed from your cart`;
        }
      }
    }

    await removeUserCartItem({ itemId, sapAccountNo: ccSapAccountNo });

    // console.log(
    //   "removeUserCartItemResult",
    //   supressDirty,
    //   removeUserCartItemResult
    // );
    if (removeUserCartItemResult.status === "fulfilled") {
      ToastGenerator(
        {
          text: msg,
          textClassName: "fort-book",
        },
        { autoClose: 3000, closeOnClick: true }
      );
    }

    // if (!supressDirty) dirtyHandler.setLoadingTakeover(false);
    // }
  };

  const removeEstimateCart = async (props) => {
    const {
      foc,
      shipTo = false,
      supressDirty = false,
      force = false,
      confirm = false,
    } = props;

    if (!supressDirty) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Updating cart . . .",
        longLoadText: "Still updating cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    let msg = (
      <span>
        <span className='fort-bold'>
          {foc === "2099-12-31"
            ? "General Cart"
            : `FOC ${formatDateMMDDYYYY(foc, "/")}`}
        </span>{" "}
        {foc === "2099-12-31" ? "has been emptied." : "has been emptied."}
      </span>
    );

    const result = await performRemoveEstimateCart({
      foc,
      shipTo: shipTo ? shipTo : getShipToAccount().accountNo,
      force,
      confirm,
    });

    // console.log('removeEstimateCarts', result);
    if (result.data?.status === "OK" && !!!result.data.data?.modalInfo) {
      ToastGenerator(
        {
          text: msg,
          textClassName: "fort-book",
        },
        { autoClose: 3000, closeOnClick: true }
      );
    }

    if (!supressDirty) dirtyHandler.setLoadingTakeover(false);

    return result.data.data;
    // }
  };

  const removeEstimateCarts = async (props) => {
    const {
      foc,
      shipTo = false,
      supressDirty = false,
      force = false,
      confirm = false,
    } = props;

    if (!supressDirty) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Updating cart . . .",
        longLoadText: "Still updating cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    let msg = "Carts Removed";

    if (foc.length > 1) {
      msg = (
        <span>
          {foc.map((f, idx) => (
            <>
              {/*console.log('f', foc.length, idx)*/}
              {idx === foc.length - 1 && ` and `}
              <span key={`foc-${idx}`} className='fort-bold'>
                {f === "2099-12-31"
                  ? "General Cart"
                  : `FOC ${formatDateMMDDYYYY(f, "/")}`}
                {idx + 2 < foc.length && `, `}
              </span>
            </>
          ))}{" "}
          have been emptied.
        </span>
      );
    } else {
      msg = (
        <span>
          <span className='fort-bold'>
            {foc[0] === "2099-12-31"
              ? "General Cart"
              : `FOC ${formatDateMMDDYYYY(foc[0], "/")}`}
          </span>{" "}
          {foc[0] === "2099-12-31" ? "has been emptied." : "has been emptied."}
        </span>
      );
      // const item = cart.items.filter((i) => i.itemId === itemId);
      // if (item.length === 1) {
      //   if (movedSaveForLater) {
      //     msg = `${item[0].title} has been moved to your saved for later`;
      //   } else {
      //     msg = `${item[0].title} has been removed from your cart`;
      //   }
      // }
    }
    const result = await performRemoveEstimateCarts({
      foc,
      shipTo: shipTo ? shipTo : getShipToAccount().accountNo,
      force,
      confirm,
    });

    // console.log('removeEstimateCarts', result);
    if (result.data?.status === "OK" && !!!result.data.data.modalInfo) {
      ToastGenerator(
        {
          text: msg,
          textClassName: "fort-book",
        },
        { autoClose: 3000, closeOnClick: true }
      );
    }

    if (!supressDirty) dirtyHandler.setLoadingTakeover(false);

    return result.data.data;
    // }
  };

  const moveEstimateCartToGeneral = async (props) => {
    const { foc, items, shipTo = false, supressDirty = false } = props;

    if (!supressDirty) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Updating cart . . .",
        longLoadText: "Still updating cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    let msg = (
      <span>
        {items.length} items have been moved to the{" "}
        <span className='fort-bold'>General</span> cart and the{" "}
        <span className='fort-bold'>FOC {formatDateMMDDYYYY(foc, "/")}</span>{" "}
        cart has been removed.
      </span>
    );

    const result = await performMoveEstimateItemsToGeneral({
      foc,
      shipTo: shipTo ? shipTo : getShipToAccount().accountNo,
    });

    // console.log('removeEstimateCarts', result);
    if (result.data?.status === "OK") {
      ToastGenerator(
        {
          text: msg,
          textClassName: "fort-book",
        },
        { autoClose: 3000, closeOnClick: true }
      );
    }

    if (!supressDirty) dirtyHandler.setLoadingTakeover(false);
  };

  const removeEstimateItems = async (props) => {
    const {
      focDate,
      shipTo = false,
      items,
      supressDirty = false,
      simulate = false,
      force = false,
      confirm = false,
    } = props;

    // console.log('removeEstimateItems', props);

    if (!supressDirty) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Updating cart . . .",
        longLoadText: "Still updating cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    let msg = "Title Removed";

    if (items.length > 1) {
      msg = `${items.length} Titles have been removed from your cart`;
    } else {
      msg = (
        <span>
          <span className='fort-bold'>{items[0].title}</span> has been removed
          from your cart.
        </span>
      );
      // const item = cart.items.filter((i) => i.itemId === itemId);
      // if (item.length === 1) {
      //   if (movedSaveForLater) {
      //     msg = `${item[0].title} has been moved to your saved for later`;
      //   } else {
      //     msg = `${item[0].title} has been removed from your cart`;
      //   }
      // }
    }
    const result = await performRemoveEstimateCartItems({
      foc: focDate,
      shipTo: shipTo ? shipTo : getShipToAccount().accountNo,
      items,
      simulate,
      force,
      confirm,
    });

    // console.log('removeEstimateCartItems', result);
    if (result.data?.status === "OK") {
      if (result.data.data.modalInfo) {
        if (!supressDirty) dirtyHandler.setLoadingTakeover(false);
        return result.data.data;
      } else {
        // updateEstimateCarts(result.data.data.carts);
        ToastGenerator(
          {
            text: msg,
            textClassName: "fort-book",
          },
          { autoClose: 3000, closeOnClick: true }
        );

        dispatch(
          setCart({
            cart:
              result.data.data.carts.length === 0
                ? { items: [], confirmationStatus: "DELETED" }
                : result.data.data.carts[0],
          })
        );
      }
    }

    if (!supressDirty) dirtyHandler.setLoadingTakeover(false);
    // }
  };

  const removeItemSaveForLater = async (itemId) => {
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Updating cart . . .",
      longLoadText: "Still updating cart . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const result = await removeUserSaveForLaterItem({
      itemId,
      sapAccountNo: ccSapAccountNo,
    });
  };

  const saveItemForLater = (itemId) => {
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Updating cart . . .",
      longLoadText: "Still updating cart . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const item = ecom.cart.items.findIndex((o) => o.itemId === itemId);

    // console.log("saveItemforlater", itemId, item, ecom.cart);
    if (item >= 0) {
      const sflItem = ecom.saveForLater.items.findIndex(
        (o) => o.isbn === ecom.cart.items[item].isbn
      );

      addUserSaveForLaterItem({
        isbn: ecom.cart.items[item].isbn,
        qty: ecom.cart.items[item].quantity,
        itemId: sflItem >= 0 ? ecom.saveForLater.items[sflItem].itemId : false,
        sapAccountNo: ccSapAccountNo,
      }).then((result) => {
        // console.log("addUserSaveForLater result", result);

        // const sflItem = saveForLater.items.findIndex(
        //   (o) => o.itemId === itemId
        // );
        // if (sflItem >= 0) {
        //   saveForLater.items[sflItem] = result.data.data.items[0];
        // } else {
        //   saveForLater.items.push(result.data.data.items[0]);
        // }

        removeItem(itemId, false, true);

        // dirtyHandler.setLoadingTakeover(false);
      });
    } else {
      dirtyHandler.setLoadingTakeover(false);
    }
  };

  const moveItemToCart = async (itemId) => {
    // console.log('moveItemtocart', itemId);

    dirtyHandler.setLoadingTakeoverConfig({
      text: "Updating cart . . .",
      longLoadText: "Still updating cart . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const item = ecom.saveForLater.items.findIndex((o) => o.itemId === itemId);
    // console.log('moveItemToCart', itemId, item, saveForLater);

    if (item >= 0) {
      // console.log('additems');
      await addItems(
        [
          {
            isbn: ecom.saveForLater.items[item].isbn,
            quantity: ecom.saveForLater.items[item].quantity,
          },
        ],
        true,
        true
      );
      // console.log('remove');
      await removeItemSaveForLater(itemId);
      // console.log('offdirty');
      // dirtyHandler.setLoadingTakeover(false);
    } else {
      // dirtyHandler.setLoadingTakeover(false);
    }
  };

  const performItemQtyUpdate = async (itemId, updateQuantity = false) => {
    const workCart = { ...ecom.cart };

    const item = workCart.items.filter((item) => {
      if (
        item.itemId === itemId &&
        (item.updateQuantity || item.updateQuantity === 0 || updateQuantity)
      ) {
        return true;
      }
      return false;
    });
    if (item.length === 1) {
      setUpdatingCart(true);
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Updating cart . . .",
        longLoadText: "Still updating cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);

      const result = await updateUserItemQty({
        itemId,
        qty: updateQuantity ? updateQuantity : item[0].updateQuantity,
        sapAccountNo: ccSapAccountNo,
      });

      if (result.isSuccess) {
        setUpdatingCart(false);

        // dirtyHandler.setLoadingTakeover(false);
        if (!location.pathname.startsWith("/cart")) setCartAddToCartOpen(true);
        clearTimeout(addToCartTimer);
        addToCartTimer = setTimeout(() => {}, 2000);
      } else {
        setUpdatingCart(false);
        // dirtyHandler.setLoadingTakeover(false);
      }
    }
  };

  const performEstimateItemQtyUpdate = async (props) => {
    const {
      foc,
      cartType = false,
      shipTo = false,
      items,
      simulate = false,
    } = props;

    setUpdatingCart(true);
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Updating cart . . .",
      longLoadText: "Still updating cart . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    let ui = items.map((i) => {
      return { isbn: i.isbn, quantity: i.updateQuantity, cartType: cartType };
    });
    const result = await performUpdateEstimateItemQty({
      items: ui,
      foc,
      simulate,
      shipTo: shipTo ? shipTo : getShipToAccount().accountNo,
    });

    // console.log("updating qty", result);
    if (result.data?.status === "OK") {
      if (result.data.data.modalInfo) {
        setUpdatingCart(false);
        dirtyHandler.setLoadingTakeover(false);
        return result.data.data;
      } else {
        // updateEstimateCarts(result.data.data.carts);
        // console.log("setting cart", result.data.data);
        if (result?.data?.data?.carts) {
          dispatch(setCart({ cart: { ...result.data.data.carts[0] } }));
        } else {
          dispatch(clearCart());
        }
      }
    }

    setUpdatingCart(false);
    dirtyHandler.setLoadingTakeover(false);
  };

  const performEstimateVariantMainQtyUpdate = async (props) => {
    const {
      foc,
      shipTo = false,
      mainItem,
      variantItems,
      simulate = true,
    } = props;

    setUpdatingCart(true);
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Updating cart . . .",
      longLoadText: "Still updating cart . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const result = await performUpdateEstimateVariantMainItem({
      simulate,
      foc,
      shipTo: shipTo ? shipTo : getShipToAccount().accountNo,
      mainItem,
      variantItems,
    });

    if (result.data?.status === "OK") {
      if (result.data.data.modalInfo) {
        setUpdatingCart(false);
        dirtyHandler.setLoadingTakeover(false);
        return result.data.data;
      } else {
        // updateEstimateCarts(result.data.data.carts);
        setUpdatingCart(false);
        dispatch(
          setCart({
            cart: result.data.data.carts
              ? { ...result.data.data.carts[0] }
              : { items: [] },
          })
        );
      }
    }

    setUpdatingCart(false);
    dirtyHandler.setLoadingTakeover(false);
  };

  const performItemSaveForLaterQtyUpdate = async (
    itemId,
    updateQuantity = false
  ) => {
    // console.log(
    //   'performItemSaveForLaterQtyUpdate',
    //   itemId,
    //   updateQuantity,
    //   saveForLater
    // );

    const item = ecom.saveForLater.items.filter((item) => {
      if (item.itemId === itemId && (item.updateQuantity || updateQuantity)) {
        return true;
      }
      return false;
    });
    // console.log('saveForLater item', item);
    if (item.length === 1) {
      setUpdatingCart(true);
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Updating cart . . .",
        longLoadText: "Still updating cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);

      const result = performUpdateItemSaveForLaterQty({
        itemId,
        qty: updateQuantity ? updateQuantity : item[0].updateQuantity,
        sapAccountNo: ccSapAccountNo,
      });

      setUpdatingCart(false);
    }
  };

  const getCart = () => {
    return cart;
  };

  const getCatalog = async (catalogDate, shipTo = false) => {
    const { searchClass, country } = ecom.customerInfo.customer;
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Catalog loading . . .",
      longLoadText: "Catalog still searching . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const result = await performGetCatalog(
      {
        catalogDate,
        searchClass,
        country,
        shipTo,
      },
      true
    );

    if (result.status === "fulfilled") {
      dirtyHandler.setLoadingTakeover(false);
      return result;
    }

    dirtyHandler.setLoadingTakeover(false);
    return {};
  };

  const getSeriesTitles = async (seriesCode) => {
    const { searchClass, country } = ecom.customerInfo.customer;
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Series loading . . .",
      longLoadText: "Series still loading . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);
    const result = await performGetSeriesTitles({
      seriesCode,
      searchClass,
      country,
      sapAccountNo: ccSapAccountNo,
    });

    if (result.data?.status === "OK") {
      dirtyHandler.setLoadingTakeover(false);
      return result;
    }
    dirtyHandler.setLoadingTakeover(false);
    return result;
  };

  const getSeriesHistory = async (
    isbn,
    formatCode,
    shipTo = "",
    getAll = false
  ) => {
    const result = await performGetSeriesHistory(
      {
        sapAccountNo: ccSapAccountNo,
        isbn,
        formatCode,
        shipTo,
        getAll,
      },
      true
    );

    if (result.data?.status === "OK") {
      return cloneDeep(result);
    }

    return result;
  };

  const getAuthorDetails = async (authorId) => {
    const result = await performGetAuthorDetails({ authorId });
    // if(result.status === '200'){
    //   return result;
    // }
    return result;
  };

  const getSaveForLater = () => {
    return ecom.saveForLater;
  };

  const getCartTotal = (workCart = false, estimate = false) => {
    let newCart = { ...ecom.cart };
    if (workCart && workCart?.items) newCart = { ...workCart };
    if (Array.isArray(workCart)) newCart = { items: [...workCart] };
    let unitCount = 0;
    let retailAmount = 0;
    let baseDiscount = 0;

    if (newCart.items)
      newCart.items.map((item) => {
        unitCount += item.quantity;

        if (estimate) {
          retailAmount += item.priceSingle * item.quantity;
          baseDiscount +=
            (item.priceSingle - item.discountedPriceSingle) * item.quantity;
        }
      });

    return {
      itemCount: newCart?.items?.length,
      unitCount,
      tax: newCart.tax ? newCart.tax : 0,
      shipping: newCart.shippingCost ? newCart.shippingCost : 0,
      discount: estimate
        ? baseDiscount
        : newCart.totalBaseDiscount
        ? newCart.totalBaseDiscount
        : 0,
      promo: newCart.totalPromoDiscount,
      retail: estimate ? retailAmount : newCart.subtotal ? newCart.subtotal : 0,
      subtotal: estimate
        ? retailAmount - baseDiscount
        : newCart.subtotal
        ? newCart.subtotal - newCart.totalDiscount
        : 0,
      total: estimate
        ? retailAmount - baseDiscount
        : newCart.total
        ? newCart.total
        : 0,
    };
  };

  const getPromos = async () => {
    const result = await performGetPromos({}, true);

    return result;
  };

  const getCatalogDownloadsInfo = async (catDate) => {
    const result = await performGetCatalogDownloadsInfo(
      { catalogId: catDate },
      true
    );
    return result;
  };

  const getCatlogDownloadFile = async (data, catalogId) => {
    const result = await performGetCatalogDownloadFile(
      { data, catalogId },
      true
    );
    return result;
  };

  const getBacklistDownloadsInfo = async (countryCode) => {
    const result = await performGetBacklistDownloadsInfo({ countryCode }, true);
    return result;
  };

  const getBacklistDownloadFile = async (fileName, countryCode) => {
    const result = await performGetBacklistDownloadFile(
      {
        fileName,
        countryCode,
      },
      true
    );
    return result;
  };

  const applyPromoCode = async (promo) => {
    setUpdatingCart(true);
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Applying promo to cart . . .",
      longLoadText: "Still applying promo to cart . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const result = await performApplyPromoCode({
      promo,
      cartKey: ecom.cart.cartKey,
    });
    if (result.data.code === 0) {
      await loadUserCart();
    }
    dirtyHandler.setLoadingTakeover(false);
    return result;
  };

  const deletePromoCode = async (promo, silent = true) => {
    // console.log('deletePromoCode', cart);

    if (silent) {
      setUpdatingCart(true);
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Removing promo from the cart . . .",
        longLoadText: "Still removing promo from the cart . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    const result = await performDeletePromoCode({
      promo,
      cartKey: ecom.cart.cartKey,
    });
    // if (result.data?.status === "OK") {
    await loadUserCart(ccSapAccountNo);
    // }
    if (silent) dirtyHandler.setLoadingTakeover(false);
    // }
    return result;
  };

  const updateCartHeaders = async (headers, cartKey) => {
    // console.log('updateCartHeaders', headers, cart);

    setUpdatingCart(true);
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Applying cart updates . . .",
      longLoadText: "Still applying updates to cart . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const result = await performUpdateCartHeaders({
      cartHeaders: headers,
      cartKey,
    });

    return result;
  };

  const submitOrder = async ({ headers, shipTo, shippingType }) => {
    // console.log("submitOrder", cart, headers, shipTo, shippingType);

    setUpdatingCart(true);

    let data = {};
    if (!_.isEmpty(headers)) data.headers = headers;
    if (!_.isEmpty(shipTo)) data.shipTo = shipTo;
    if (shippingType) data.shippingType = shippingType;

    data.cartCountry = ecom.customerInfo.customer.country;
    data.cartKey = ecom.cart.cartKey;

    if (userPriv.isAppCompCopy()) data.sapAccountNo = ccSapAccountNo;

    const headerResult = await performUpdateCart(data);
    if (headerResult.data?.status === "OK") {
      let sd = {
        cartCountry: ecom.customerInfo.customer.country,
        cartKey: ecom.cart.cartKey,
      };
      if (userPriv.isAppCompCopy()) sd.sapAccountNo = ccSapAccountNo;

      const result = await performSubmitOrder(sd);

      return result;
    }

    // dirtyHandler.setLoadingTakeover(false);
    return headerResult;
  };

  const confirmEstimateCart = async (props) => {
    if (!dirtyHandler.loadingTakeover) {
      dirtyHandler.setLoadingTakeoverConfig({
        text: "Submitting confirmation . . .",
        longLoadText: "Still submitting confirmation . . .",
        inverted: true,
        backgroundBlurLight: true,
      });
      dirtyHandler.setLoadingTakeover(true);
    }

    const result = await performConfirmEstimateCart(props);

    if (result && result.data?.status === "OK") {
      // loadEstimateCarts({
      //   foc: props.foc,
      //   simulate: true,
      //   orderDetail: true,
      // });

      // jeff
      if (result?.data?.data?.carts.length === 1) {
        // updateEstimateCarts(result.data.data.carts);
        dispatch(setCart({ cart: { ...result.data.data.carts[0] } }));
      }
    }
    // dirtyHandler.setLoadingTakeover(false);
    return result;
  };

  const undoEstimateCart = async (props) => {
    // console.log('undoEstimateCart', props);
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Undoing cart changes . . .",
      longLoadText: "Still undoing cart changes . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const result = await performUndoEstimateCart({
      data: props,
    });

    if (result && result.data?.status === "OK") {
      if (result.data.data?.modalInfo) {
      } else {
        // updateEstimateCarts(result.data.data.carts);
        if (result?.data?.data?.carts?.length === 1) {
          dispatch(setCart({ cart: { ...result.data.data.carts[0] } }));
        }
      }
    }

    dirtyHandler.setLoadingTakeover(false);
    return result;
  };

  const validateAddress = async (address) => {
    // console.log('validateAddress', address);

    let data = { ...address };

    setUpdatingCart(true);
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Verifying address . . .",
      longLoadText: "Still verifying address . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    data.cartCountry = ecom.customerInfo.customer.country;

    if (userPriv.isAppCompCopy()) data.sapAccountNo = ccSapAccountNo;

    const result = await performValidateAddress({ data });
    // console.log("results", result);
    if (result.data?.status === "OK") {
      dirtyHandler.setLoadingTakeover(false);
      return result;
    }

    dirtyHandler.setLoadingTakeover(false);
    return result;
  };

  const storeFilters = (filters) => {
    setSearchResuts({
      ...searchResults,
      filters: filters,
      filterApplied: true,
    });
  };

  /**
   *
   * @param {*} searchTerm
   * @param {*} searchData this is used for hijacking search for title lists
   * @returns
   */
  const performSearch = async (searchTerm, searchData = null) => {
    //EventTrigger('searchTerm', searchTerm);
    /**
     * if we are not passing in search data, let's make sure we are forcing standard search
     */
    // console.log("push perform search", searchTerm);
    const searchType = !searchData ? "normal" : "list";
    if (!searchData) {
      searchData = {
        useStandardSearch: true,
      };
    }
    const { searchClass, country } = ecom.customerInfo.customer;

    dirtyHandler.setLoadingTakeoverConfig({
      text: "Searching . . .",
      longLoadText: "Still searching . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    const result = await loadSearch({
      searchTerm,
      searchClass,
      country,
      searchType,
    });

    // console.log("results", result);

    if (result.status === "fulfilled") {
      let cartData = cloneDeep(result.data.data.results);
      if (ecom.customerInfo.customer.isPow) {
        if (ecom.estimateCarts) {
          cartData.map((rslt) => {
            ecom.estimateCarts.forEach((e) => {
              e.items.forEach((ei) => {
                if (ei.isbn === rslt.isbn) {
                  rslt.cartQuantity = ei.quantity;
                  rslt.confirmationStatus = e.confirmationStatus;
                }
              });
            });
          });
        }
      } else {
        if (ecom.cart) {
          cartData.map((rslt) => {
            ecom.cart.items.forEach((i) => {
              if (i.isbn === rslt.isbn) {
                rslt.cartQuantity = i.quantity;
              }
            });
          });
        }
      }

      dirtyHandler.setLoadingTakeover(false);
      setSearchResuts(cloneDeep({ ...result.data.data, results: cartData }));

      if (!!searchData?.overwriteHistory) {
        console.log("push", !!searchData?.overwriteHistory);
        history.replace({
          pathname: "/search",
          state: {
            searchData,
          },
        });
        return;
      }

      history.push({
        pathname: "/search",
        state: {
          searchData,
        },
      });

      return result;
    }
    dirtyHandler.setLoadingTakeover(false);
    return result;
  };

  const getShipToAccount = (workShipTo = false) => {
    let tshipTo = shipTo;
    if (workShipTo !== false) tshipTo = workShipTo;

    /*console.log(
      "getshiptoaccount",
      shipTo,
      workShipTo,
      tshipTo,
      ecom.customerInfo.shipToAccountNos[tshipTo]
    );*/
    if (tshipTo >= 0 && ecom.customerInfo?.shipToAccountNos.length > 0) {
      return ecom.customerInfo.shipToAccountNos[tshipTo];
    } else if (ecom.customerInfo?.shipToAccountNos.length === 1) {
      return ecom.customerInfo.shipToAccountNos[0];
    } else {
      return false;
    }
  };

  const verifyVariant = async (props) => {
    const {
      isbn = false,
      quantity = false,
      focDate = false,
      orderReq,
      overUpc = false,
      varCart = false,
    } = props;
    let result = {};
    let totalQuantity = 0;

    let workCart = varCart;

    if (!varCart && focDate) {
      workCart = await loadEstimateCarts({
        focDate,
        simulate: true,
        orderDetail: true,
      });
    }
    if (isbn && quantity) {
      let variants = [];
      workCart.items.forEach((i) => {
        if (i.overUpc === isbn) {
          variants.push({ isbn: i.isbn, orderReq: i.orderReq });
        }
      });
      if (variants.length > 0) {
        let isAllPrerequisites = true;
        variants.map((vi) => {
          totalQuantity = quantity;
          workCart.items.forEach((i) => {
            if (i.isbn === vi.isbn) {
              totalQuantity += i.quantity;
            }
          });
          if (workCart.orderItems) {
            workCart.orderItems.forEach((i) => {
              if (i.isbn === vi.isbn && i.orders) {
                i.orders.forEach((oi) => {
                  totalQuantity += oi.quantity;
                });
              }
            });
          }

          if (totalQuantity > vi.orderReq) {
            vi.isPrerequite = true;
          } else vi.isPrerequisite = false;
          isAllPrerequisites = false;
        });
        result = { isPrerequisite: isAllPrerequisites, variants };
      }
    } else if (!isbn && overUpc) {
      workCart.items.forEach((i) => {
        if (i.isbn === overUpc) {
          totalQuantity += i.quantity;
        }
      });
      if (workCart.orderItems) {
        workCart.orderItems.forEach((i) => {
          if (i.isbn === overUpc && i.orders) {
            i.orders.forEach((oi) => {
              totalQuantity += oi.quantity;
            });
          }
        });
      }

      if (totalQuantity > orderReq) {
        result.isPrerequisite = true;
      }
    }

    return result;
  };

  const setStateShipTo = async (shipToLocation, silent = true) => {
    // console.log("setStateShipTo", typeof shipToLocation, shipToLocation);
    setShipTo(shipToLocation);
    // console.log("setshipto load est carts");
    // await loadEstimateCarts({ silent });
  };

  useEffect(() => {
    if (ecom.customerInfo.customer.isPow && shipTo >= 0)
      loadEstimateCarts({ silent: true });
    return () => {};
  }, [shipTo]);

  // const updateEstimateCarts = (workCarts) => {
  // let newEstimateCarts = [];
  // // let newCart = true;
  // estimateCarts.forEach((ec) => {
  //   let foundUpdate = false;
  //   workCarts.forEach((uc) => {
  //     // console.log('comparing', ec.foc, uc.foc);
  //     if (ec.foc === uc.foc) {
  //       // console.log('found new');
  //       foundUpdate = true;
  //       newEstimateCarts.push({ ...uc });
  //     }
  //   });
  //   if (!foundUpdate) {
  //     newEstimateCarts.push({ ...ec });
  //   }
  // });
  // workCarts.forEach((uc) => {
  //   let foundUpdate = false;
  //   estimateCarts.forEach((ec) => {
  //     if (ec.foc === uc.foc) {
  //       foundUpdate = true;
  //     }
  //   });
  //   if (!foundUpdate) {
  //     newEstimateCarts.push({ ...uc });
  //   }
  // });
  // setEstimateCarts(newEstimateCarts);
  // };
  // console.log(cartLoaded, user.isAuthenticated, location.pathname);
  // if (
  //   !cartLoaded &&
  //   user.isAuthenticated &&
  //   // location.pathname !== "/logout"
  //   // loadCustomerInfoIsFetching
  //   // loadUserCartIsFetching
  // ) {
  //   return <Loader />;
  // }

  // if (!userPriv?.authService) {
  //   return <Loader />;
  // }
  // console.log(
  //   "cart gateway",
  //   user,
  //   authService.isAuthenticated(),
  //   ecom?.customerInfo,
  //   location.pathname
  // );
  // console.log(
  //   "cart provider",
  //   authService.isAuthenticated(),
  //   user.isAuthenticated,
  //   ecom?.customerInfo?.currentUser,
  //   location.pathname,
  //   userPriv.appID
  // );
  if (!authService.isAuthenticated() && !user.isAuthenticated) {
  } else if (
    authService.isAuthenticated() &&
    !user.isAuthenticated &&
    location.pathname !== "/logout" &&
    location.pathname !== "/silentrenew" &&
    location.pathname !== "/signin-oidc"
  )
    return <Loader />;
  else if (
    !ecom?.customerInfo?.customer.accountNo &&
    !userPriv.isAppCompCopy() &&
    location.pathname !== "/silentrenew"
  )
    return <Loader />;

  // console.log("Cart provider", ecom);
  return (
    <CartContext.Provider
      value={{
        estimateDueDate: estimateDueDate,
        currentMonthCatalogAvailabilityDate:
          currentMonthCatalogAvailabilityDate,
        catalogAvailabilityDate: catalogAvailabilityDate,
        maxCatalogDate: maxCatalogDate,
        activeCatalogs: activeCatalogs,
        recentPosts: recentPosts,
        shipTo: shipTo,
        showNotifications: showNotifications,
        getShipToAccount: () => {
          return getShipToAccount();
        },
        setShipTo: (shipTo, silent) => {
          return setStateShipTo(shipTo, silent);
        },
        isUserPow: () => {
          return ecom?.customerInfo?.customer?.isPow;
        },
        setCartBadgeOpen: (cbo) => {
          return setCartBadgeOpen(cbo);
        },
        cartBadgeOpen: cartBadgeOpen,
        setCartAddToCartOpen: (cbo) => {
          const ws = { cartAddToCartOpen: cbo };
          // if (!cbo) ws.cartAddToCartItems = [];

          if (!cbo) {
            setCartAddToCartItems([]);
          } else {
            setCartAddToCartItems(cbo);
          }
          return;
        },
        initialUserLoadComplete: initialUserLoadComplete,
        cartAddToCartOpen: cartAddToCartOpen,
        cartAddToCartItems: cartAddToCartItems,
        customerLoaded: customerLoaded,
        updatingCart: updatingCart,
        customerInfo: ecom.customerInfo,
        // cart: getCart(),
        cartInitialized: cartInitialized,
        cart: ecom.cart,
        estimateCarts: ecom.estimateCarts,
        orderQueryId: getOrderQueryId(),
        backorderQueryId: getBackorderQueryId(),
        catalogFilters: catalogFilters,
        setOrderQueryId: (value) => setOrderQueryId(value),
        setBackorderQueryId: (value) => setBackorderQueryId(value),
        createCart: (workCart) => {
          return createCart(workCart);
        },
        getCatalog: (wCatalogDate, wShipTo = false) => {
          return getCatalog(wCatalogDate, wShipTo);
        },
        getCurrentCatalogFilters: (wCatalogId) => {
          return getCurrentCatalogFilters(wCatalogId);
        },
        setCurrentCatalogFilters: (wFilters) => {
          return setCurrentCatalogFilters(wFilters);
        },
        getCatalogDownloadsInfo: (wCatDate) => {
          return getCatalogDownloadsInfo(wCatDate);
        },
        getCatlogDownloadFile: (data, wCatalogId) => {
          return getCatlogDownloadFile(data, wCatalogId);
        },
        getSeriesTitles: (wSeriesCode) => {
          return getSeriesTitles(wSeriesCode);
        },
        getSeriesHistory: (isbn, formatCode, wShipTo, getAll) => {
          return getSeriesHistory(isbn, formatCode, wShipTo, getAll);
        },
        getBacklistDownloadsInfo: (countryCode) => {
          return getBacklistDownloadsInfo(countryCode);
        },
        getBacklistDownloadFile: (data, countryCode) => {
          return getBacklistDownloadFile(data, countryCode);
        },
        getCCInfo: () => {
          return getCCInfo();
        },
        getCartItemsSorted: (props) => {
          return getCartItemsSorted(props);
        },
        getCartItemsFiltered: (wCart = false, excludeBackordered = false) => {
          return getCartItemsFiltered(wCart, excludeBackordered);
        },
        sortCartItems: (items) => {
          return sortCartItems(items);
        },
        loadUserCart: (wCcSapAccountNo) => {
          return loadUserCart(wCcSapAccountNo);
        },
        loadEstimateCarts: (props) => {
          return loadEstimateCarts(props);
        },
        clearCheckout: () => {
          return clearCheckout();
        },
        // clearGeneralEstimateCart: () => {
        //   return clearGeneralEstimateCart();
        // },
        clearCart: () => {
          dispatch(setCart({ cart: { items: [] } }));
        },
        // clearGeneralEstimateCart: () => {
        //   return clearGeneralEstimateCart();
        // },
        getCartTotal: (workCart = false, estimate = false) => {
          return getCartTotal(workCart, estimate);
        },
        addEstimateItems: (props) => {
          return addEstimateItems(props);
        },
        addEstimateVariantItems: (props) => {
          return addEstimateVariantItems(props);
        },
        removeEstimateItems: (props) => {
          return removeEstimateItems(props);
        },
        removeEstimateCarts: (props) => {
          return removeEstimateCarts(props);
        },
        removeEstimateCart: (props) => {
          return removeEstimateCart(props);
        },
        moveEstimateCartToGeneral: (props) => {
          return moveEstimateCartToGeneral(props);
        },
        confirmEstimateCart: (props) => {
          return confirmEstimateCart(props);
        },
        undoEstimateCart: (props) => {
          return undoEstimateCart(props);
        },
        verifyVariant: (props) => {
          return verifyVariant(props);
        },
        addItems: (titles, supressDirty = false, simulate = false) => {
          return addItems(titles, supressDirty, simulate);
        },
        removeItem: (itemNo) => {
          return removeItem(itemNo);
        },
        removeItemSaveForLater: (itemNo) => {
          return removeItemSaveForLater(itemNo);
        },
        saveForLater: getSaveForLater(),
        saveItemForLater: (itemNo) => {
          return saveItemForLater(itemNo);
        },
        moveItemToCart: (itemNo) => {
          return moveItemToCart(itemNo);
        },
        performItemQtyUpdate: (itemId, qty) => {
          return performItemQtyUpdate(itemId, qty);
        },
        performEstimateItemQtyUpdate: (props) => {
          return performEstimateItemQtyUpdate(props);
        },
        performEstimateVariantMainQtyUpdate: (props) => {
          return performEstimateVariantMainQtyUpdate(props);
        },
        // updateItemSaveForLaterQty: (itemNo, qty) => {
        //   return updateItemSaveForLaterQty(itemNo, qty);
        // },
        performItemSaveForLaterQtyUpdate: (itemId, e) => {
          return performItemSaveForLaterQtyUpdate(itemId, e);
        },
        getCustomerInfo: (sapAccountNo = false, loader = false) => {
          return getCustomerInfo(sapAccountNo, loader);
        },
        getCustomerUser: (sapAccountNo = false, loader = false) => {
          return getCustomerUser(sapAccountNo, loader);
        },
        getPromos: () => {
          return getPromos();
        },
        applyPromoCode: (promo) => {
          return applyPromoCode(promo);
        },
        deletePromoCode: (promo) => {
          return deletePromoCode(promo);
        },
        updateCartHeaders: (headers) => {
          return updateCartHeaders(headers);
        },
        submitOrder: (headers) => {
          return submitOrder(headers);
        },
        setShowNotifications: (field, value) => {
          setShowNotifications({
            ...showNotifications,
            [field]: value,
          });
          return;
        },
        ccSapAccountNo: ccSapAccountNo,
        country: ecom.customerInfo.customer.country,
        setCCSapAccountNo: (sapAccountNo) => {
          return setCcSapAccountNo(sapAccountNo);
        },
        validateAddress: (address) => {
          return validateAddress(address);
        },
        storeFilters: (filters = null) => {
          return storeFilters(filters);
        },
        performSearch: (searchTerm, shareableSearch = null) => {
          return performSearch(searchTerm, shareableSearch);
        },
        getAuthorDetails: (authorId) => {
          return getAuthorDetails(authorId);
        },
        checkout: checkout,
        searchResults: searchResults,
        searchFilters: searchFilters,
        setCheckoutValue: (key, value, shipToUpdate = false) => {
          // console.log("setCheckoutValue", key, value);
          if (typeof key === "string") {
            setCheckout({ ...checkout, [key]: value });
            // if (key === "promoCode") {
            //   setCart({ ...cart, promoCode });
            // }
          } else if (typeof key === "object" && key !== null) {
            setCheckout((checkout) => {
              const newCheckout = { ...checkout };
              let promoCode = false;
              let workShipTo = false;
              for (const [k, v] of Object.entries(key)) {
                newCheckout[k] = v;
                if (k === "promoCode") {
                  promoCode = v;
                }
                if (k === "shipToAddress") workShipTo = v;
              }
              if (promoCode) setCheckout({ ...checkout, promoCode });
              if (shipToUpdate) {
                // console.log("updating headers", workShipTo);
                let headers = {};
                headers.shipToAccountNo =
                  getShipToAccount(workShipTo).accountNo;
                updateCartHeaders(headers, ecom.cart.cartKey);
              }
              return newCheckout;
            });
          }
        },
        getCreditCards: (paymentCreditCard) => {
          return getCreditCards(paymentCreditCard);
        },
      }}
    >
      <>
        <DialogModal
          open={versionUpdateAvailable}
          setOpen={(e) => setVersionUpdateAvailable(e)}
          modalClassName='session-modal'
          style={{ maxWidth: "448px" }}
          dialogOptions={{
            header: "Application Version Update",
            actions: [],
          }}
        >
          <div className='d-flex flex-column'>
            There is an update to the application, please close this tab or
            browser window to reload the latest version.
          </div>
          <div className='d-flex flex-column'>
            Your Version: {process.env.REACT_APP_VERSION}
            Latest Version: {latestVersion}
          </div>
        </DialogModal>
        {children}
      </>
    </CartContext.Provider>
  );
};

export default CartProvider;
