import { Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import useCart from "../../../components/hc/useCart";
import { NumericFormat } from "react-number-format";
import PRHButton from "../../../shared-react-components/elements/prhbutton";
import LazyImage from "../../../utils/LazyImage";
import PropTypes from "prop-types";
import {
  focDateDifference,
  formatDateMMDDYYYY,
  formatDateMonthYear,
  isGeneralCart,
} from "../../../utils/utilities";
import { useHistory } from "react-router";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ExpiredFocBannerMobile from "./ExpiredFocBannerMobile";
import ConfirmationStatus from "../../../components/common/ConfirmationStatus";

export default function CartViewMobile(props) {
  const { cartName, cart, shipToAccountNo, isLastItem } = props;
  const cartProvider = useCart();
  const history = useHistory();
  const cartTotal = cartProvider.getCartTotal(cart, true);

  const focDiff = focDateDifference(cartName);

  const responsive = {
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 4,
      slidesToSlide: 4,
      partialVisibilityGutter: 10,
    },
  };

  return (
    <div
      className={`d-flex flex-column pb-3 mb-3 ${
        isLastItem ? "" : "border-bottom-gray-300"
      } `}
    >
      <div className={`d-flex pt-1 pb-1 align-items-center`}>
        <Grid container className='fluid'>
          <Grid.Row className='align-items-center'>
            <Grid.Column
              mobile={8}
              tablet={8}
              className='d-flex align-items-center'
            >
              {isGeneralCart(cartName) ? (
                <div className='fort-light fs-20px'>General Cart </div>
              ) : (
                <Grid.Column>
                  <div className='fort-light fs-20px mb-1'>
                    FOC {formatDateMMDDYYYY(cartName, "/")}{" "}
                  </div>
                  {cart.catalogDate ? (
                    <div className='text-blue fort-book fs-12px'>
                      <Link to={`/catalog/${cart?.catalogDate}`}>
                        Catalog: {formatDateMonthYear(cart?.catalogDate)}
                      </Link>
                    </div>
                  ) : (
                    <div />
                  )}
                </Grid.Column>
              )}
            </Grid.Column>
            <Grid.Column
              mobile={8}
              tablet={8}
              className={`d-flex justify-content-end pt-2 pb-2 ${
                isGeneralCart(cartName) ? "pt-md-0" : "pt-md-0"
              } pt-lg-0 mt-lg-0 pb-md-0`}
            >
              <PRHButton
                disabled={cart.items.length === 0}
                size='medium'
                bold
                borderColorOverride='blue'
                textColorOverride='white'
                textColorHoverOverride='blue'
                backgroundColorOverride='blue'
                backgroundHoverColorOverride='white'
                onClick={(e) => {
                  cartProvider.clearCart();
                  history.push({
                    pathname: "/cart",
                    state: { focDate: cartName, shipTo: shipToAccountNo },
                  });
                }}
                className=' mt-0 mt-mobile-2'
              >
                View Cart
              </PRHButton>
            </Grid.Column>
          </Grid.Row>
          {isGeneralCart(cartName) ? null : (
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                className='d-flex justify-content-start align-items-center pt-2 pb-1  pt-lg-0 pb-lg-0'
              >
                <ConfirmationStatus
                  confirmationStatus={cart?.confirmationStatus}
                  hidePast={true}
                  focDate={cartName}
                  className='w-100'
                />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16}>
              <div className='d-flex flex-row justify-content-space-between mt-3 mb-2'>
                <div className='d-flex flex-column text-center justify-content-center  border-right-gray-300 cart-ttl-mobile'>
                  <div className='fs-12px text-gray-700'>TOTAL ITEMS</div>
                  <div className='fort-book fs-16px text-gray-900'>
                    {cart.items.length}
                  </div>
                </div>
                <div className='d-flex flex-column text-center justify-content-center  border-right-gray-300 cart-ttl-mobile'>
                  <div className='fs-12px text-gray-700'>TOTAL UNITS</div>
                  <div className='fort-book fs-16px text-gray-900'>
                    {cartTotal.unitCount}
                  </div>
                </div>
                <div className='d-flex flex-column text-center justify-content-center cart-ttl-mobile'>
                  <div className='fs-12px text-gray-700'>SUBTOTAL</div>
                  <NumericFormat
                    className='fort-book fs-16px text-gray-900'
                    value={cartTotal.subtotal ? cartTotal.subtotal : 0}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      {focDiff < 0 ? (
        <ExpiredFocBannerMobile foc={cartName} items={cart.items} />
      ) : null}
      <div className='cv-detail pt-2 pb-2 d-flex flex-column'>
        {cart.items.length === 0 ? (
          <div className='mt-2 mb-2'>Your Cart is empty.</div>
        ) : (
          <Carousel
            showDots={false}
            responsive={responsive}
            keyBoardControl={false}
            arrows={false}
            containerClass='carousel-title-style'
            itemClass='carousel-item'
            removeArrowOnDeviceType={["mobile", "tablet"]}
            partialVisible={cart.items.length > 3}
          >
            {cart.items.map((item, i) => (
              <div
                key={i}
                className='d-flex flex-column justify-content-end align-items-center'
              >
                <LazyImage
                  className='cp-cover'
                  width={66}
                  isSmallThumbNail={true}
                  isVariant={item.isVariant}
                  boxShadow={true}
                  src={`https://images.penguinrandomhouse.com/cover/${item.isbn}`}
                />
                <div className='text-gray-900 fs-12px'>{item.quantity}</div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
}

CartViewMobile.propTypes = {
  cartName: PropTypes.string.isRequired,
  shipToAccountNo: PropTypes.string,
  cart: PropTypes.object.isRequired,
  isLastItem: PropTypes.bool,
};

CartViewMobile.defaultProps = {};
