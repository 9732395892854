import { createRef, Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { withRouter } from 'react-router';
//import FormContainer from '../../elements/FormContainer';
import { Link } from 'react-router-dom';
import { Icon, Popup } from 'semantic-ui-react';
import { Form } from 'semantic-ui-react-form-validator';
import {
  PRHContainer,
  PRHContainerHeader,
  PRHLabel,
} from '../../shared-react-components/elements/elements';
import TextValidator from '../../shared-react-components/elements/input';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { TOOLTIPS } from '../../utils/const';
import withAccount from '../hc/withAccount';
import DialogModal from '../modals/DialogModal';
import './registration.scss';

class RegistrationStep1Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailToSubject: '',
      recaptchaToken: '',
      recaptchaRef: createRef(),
      // contactUsModalOpen: false,
    };
  }

  handleRecaptchaChange = () => {
    const recaptchaToken = this.state.recaptchaRef.current.getValue();
    this.setState({ recaptchaToken });
  };

  isValid = () => {
    const { account } = this.props;

    if (account.formState.createUserForm.san === '') return true;
    if (account.formState.createUserForm.sapAcct === '') return true;

    return false;
  };

  render() {
    // console.log('Render Form1', this.props, this.state);
    const { account } = this.props;
    return (
      <>
        {/* <DialogModal
          setOpen={(x) => this.setState({ contactUsModalOpen: x })}
          open={this.state.contactUsModalOpen}
          contentPt="pt2"
          dialogOptions={{
            header: 'Contact Us',
            actions: [
              {
                hidden: false,
                icon: 'left arrow',
                label: 'Back',
                className: 'mr-auto',
                preAction: async () => {
                  return { proceed: true };
                },
                action: () => {
                  return { proceed: true };
                },
              },
            ],
          }}
          options={{ className: 'tm-modal-container' }}
        >
          <div className="fort-book fs14-px lh19-px regcus-modal-sh">
            Please contact us for questions about Penguin Random House
            Self-Service or setting up a new business account:
          </div>
          <div className="d-flex flex-row mb-2">
            <div className="mr-1">
              <Icon name="envelope outline"></Icon>
            </div>
            <div>
              <div className="fort-bold">BIZ Site Support</div>
              <div>
                <a href="mailto:bizcs@penguinrandomhouse.com">bizcs@prh.com</a>
              </div>
              <div>
                Email us to confirm your Account Number or SAN, help with
                completing registration, or password support
              </div>
            </div>
          </div>
          <div className="d-flex flex-row regcus-modal-end">
            <div className="mr-1">
              <Icon name="user circle outline"></Icon>
            </div>
            <div>
              <div className="fort-bold">New Accounts</div>
              <div>
                <a href="mailto:newaccount@penguinrandomhouse.com">
                  newaccount@prh.com
                </a>
              </div>
              <div>
                Email us for help setting up a new Penguin Random House business
                account
              </div>
            </div>
          </div>
        </DialogModal> */}
        <PRHContainer className="content-block-border mt-4">
          <PRHContainerHeader className="fw-700">
            Getting Started
          </PRHContainerHeader>
          <p className="mb-2 fs-17px lh-22px">
            Please enter your Penguin Random House business account information.
            This process will create a new Self-Service account at the
            Administrator level. 
          </p>

          <Form
            instantValidate={false}
            width={16}
            //action="#"
            onSubmit={(e) => {
              if (!!!this.state.recaptchaToken) {
                return;
              }
              this.props.account.setStateField(
                'recaptchaToken',
                this.state.recaptchaToken
              );
              account.validateSAPAccount(this.state.recaptchaToken);
              this.state.recaptchaRef.current.reset();
              this.handleRecaptchaChange();
            }}
          >
            <TextValidator
              id="sapAcct"
              name="sapAcct"
              className={'form-field'}
              label={
                <>
                  <PRHLabel className={'input-label'}>
                    PRH Account Number
                  </PRHLabel>
                  <Popup
                    on="click"
                    content={TOOLTIPS['prhAccNumber']}
                    trigger={
                      <Icon
                        name="question circle outline"
                        className={'input-tool-tip-icon'}
                      />
                    }
                  />
                </>
              }
              type="text"
              onChange={account.handleSanSapAcctChange}
              value={account.formState.createUserForm.sapAcct}
              validators={['required', 'maxStringLength:10']}
              errorMessages={[
                'This field is required',
                'This field may be no more thant 10 digits',
              ]}
              width={16}
            />
            <br />
            <TextValidator
              id="san"
              name="san"
              label={
                <>
                  <PRHLabel className={'font-resize'}>
                    SAN (Standard Address Number)
                  </PRHLabel>
                  <Popup
                    on="click"
                    content={TOOLTIPS['sanNumber']}
                    trigger={
                      <Icon
                        name="question circle outline"
                        className={'input-tool-tip-icon'}
                      />
                    }
                  />
                </>
              }
              type="text"
              onChange={account.handleSanSapAcctChange}
              value={account.formState.createUserForm.san}
              validators={['required', 'maxStringLength:10']}
              errorMessages={[
                'This field is required',
                'This field may be no more thant 10 characters',
              ]}
              width={16}
            />
            <div className="d-flex mt-3 align-items-center justify-content-center">
              <ReCAPTCHA
                onExpired={this.handleRecaptchaChange}
                onChange={this.handleRecaptchaChange}
                ref={this.state.recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size={'normal'}
              />
            </div>
            <br />
            {/* {account.formState.createUserForm.sapAcct !== '' &&
            account.formState.createUserForm.sapAcct !== '' ? (
              <>
                Don't have an account or need to confirm your PRH Account Number
                or SAN?{' '}
                <a
                  href={`mailto:bizcs@penguinrandomhouse.com?subject=${account.formState.createUserForm.sapAcct}/${account.formState.createUserForm.san} Request for Self-Service Registration`}
                >
                  Contact Customer Service
                </a>
              </>
            ) : (
              <>
                Don't have an account or need to confirm your PRH Account Number
                or SAN?{' '}
                <a
                  href={`mailto:bizcs@penguinrandomhouse.com?subject=${account.formState.createUserForm.sapAcct}${account.formState.createUserForm.san} Request for Self-Service Registration`}
                >
                  Contact Customer Service
                </a>
              </>
            )} */}

            {account.formState.validations.sapAcct === false ? (
              <div className={'account-error-message'}>
                {account.formState.systemResponse}
              </div>
            ) : (
              ''
            )}
            {account.formState.createResponse === 'admin_exists' ? (
              <>
                <div className={'account-error-message'}>
                  It looks like an admin has been created for your account.
                  Please ask your admin to create an account for you or{' '}
                  <a
                    href={`mailto:bizcs@penguinrandomhouse.com?subject=${account.formState.createUserForm.sapAcct}${account.formState.createUserForm.san} Request for Self-Service Registration`}
                  >
                    contact support
                  </a>
                  . 
                </div>
              </>
            ) : (
              ''
            )}
            <br />
            <div className={'d-flex flex-row Xbutton-holder'}>
              <PRHButton
                onClick={() => {
                  // account.setStateField('san', '');
                  // account.setStateField('sapAcct', '');
                  account.clearStateField();
                  this.props.history.push('/');
                }}
                size="large"
                className={'mr-auto reg-button-compact'}
                //displayInlineFlex
              >
                Back to Homepage
              </PRHButton>
              <PRHButton
                disabled={!!!this.state.recaptchaToken}
                iconright
                icon="arrow right"
                size="large"
                bold={true}
                className={'ml-auto reg-button-compact'}
                type="submit"
                borderColorOverride="blue"
                textColorOverride="white"
                textColorHoverOverride="blue"
                backgroundColorOverride="blue"
                backgroundHoverColorOverride="white"
                iconColorOverride="white"
                iconColorHoverOverride="blue"
                /*
            disabled={
              !formState.createUserForm.sapAcct
              || !formState.createUserForm.san
            }
            */
                //type="submit"
              >
                Continue Registration
              </PRHButton>
            </div>
          </Form>
          <div className="reg-getting-started-note">
            <div className="mb-2">
              <span className="fort-bold">Note:</span> Registration is available
              only for businesses and retailers that order from Penguin Random
              House.
            </div>
            <div>
              <span className="fort-bold">
                Need Help?{' '}
                <Link to="/contactus" className="fort-book underline-important">
                  Contact Us
                </Link>
              </span>
            </div>
          </div>
        </PRHContainer>
        <div className={'additional-links fort-book'}>
          Already have a Self-Service account?{' '}
          <Link to="/login" className="underline-important">
            Sign in
          </Link>
        </div>
      </>
    );
  }
}

export default withRouter(withAccount(RegistrationStep1Form));
