import { useEffect, useState } from "react";
import "./Series.scss";
import { Container, Dropdown, Grid } from "semantic-ui-react";
import PageStandard from "../../components/elements/PageStandard";
import useApp from "../../components/hc/useApp";
import useCart from "../../components/hc/useCart";
import withDirty from "../../shared-react-components/components/hc/withDirty";
import TitleRow from "../../components/common/TitleRow";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import CustomFilters from "../../components/common/Filters/customFilters";

const resultsSortOPtions = [
  {
    key: "onsale desc",
    text: "On Sale - Old to New",
    value: "onsale_desc",
  },
  {
    key: "onsale asc",
    text: "On Sale - New to Old",
    value: "onsale_asc",
  },
  {
    key: "price asc",
    text: "Price - Low to High",
    value: "price_asc",
  },
  {
    key: "price desc",
    text: "Price - High to Low",
    value: "price_desc",
  },
  {
    key: "title asc",
    text: "Title - A to Z",
    value: "title_asc",
  },
];

export const Series = (props) => {
  const [filtersCount, setFiltersCount] = useState(0);
  const [filters, setFilters] = useState([]);
  const [filterModel, setFilterModel] = useState({});
  const [numOfResults, setNumOfResults] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);
  const [originalResults, setOriginalResults] = useState([]);
  const [variantFilter, setVariantFilter] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  const [currentSort, setCurrentSort] = useState("onsale_asc");
  const Cart = useCart();
  const seriesCode = props?.match?.params?.seriesId;

  useEffect(() => {
    Cart.getSeriesTitles(seriesCode).then((response) => {
      if (response?.data?.status === "OK") {
        const seriesData = response?.data?.data;
        clearResultFilters();
        setOriginalResults(seriesData.results);
        setNumOfResults(seriesData.resultCount);
        setFilters(seriesData.filters);
        setFilteredResults(seriesData.results);
      } else {
        // props.dirtyHandler.setLoadingTakeover(false);
      }
    });
  }, [seriesCode]);

  const updateResults = (e, data) => {
    const dFiltersArray = filters;
    for (let i = 0; i < dFiltersArray.length; i++) {
      if (dFiltersArray[i].resultsMatchID === data.name) {
        for (let j = 0; j < dFiltersArray[i].values.length; j++) {
          if (dFiltersArray[i].values[j].match === data.value) {
            dFiltersArray[i].values[j].checked = data.checked;
          }
          if (data.name === "isVariant") {
            if (data.checked === false) {
              setVariantFilter(false);
              dFiltersArray[i].values[j].checked = true;
            } else {
              setVariantFilter(true);
              dFiltersArray[i].values[j].checked = false;
            }
          }
        }
        const newFilters = createFilterModel(dFiltersArray);
        const filtersKeysArray = Object.keys(newFilters);

        let filteredRows = [];
        let validTitle = 0;
        const totalfilters = filtersKeysArray.length;

        _.each(originalResults, function (title) {
          _.each(filtersKeysArray, function (filter) {
            if (newFilters[filter].indexOf(title[filter]) != -1) {
              validTitle++;
            }
          });
          if (validTitle === totalfilters) {
            filteredRows.push(title);
          }
          validTitle = 0;
        });
        setFilteredResults(filteredRows);
        // setSavedFilters(data);
      }
    }
  };

  const handleSortChange = (e, { value }) => {
    const sortArray = value.split("_");
    // console.log('@@@@@@@@', sortArray[0]);
    let sortedRows = [];
    setCurrentSort(sortArray[0]);

    if (sortArray[1] === "desc") {
      sortedRows = _.sortBy(filteredResults, sortArray[0]).reverse();
    } else {
      sortedRows = _.sortBy(filteredResults, sortArray[0]);
    }
    setFilteredResults(sortedRows);
  };

  const createFilterModel = (filtersArray) => {
    const dFiltersArray = filtersArray;
    const customFilters = {};
    let filterCount = 0;
    // this.setState({ filtersCount: 0 });
    for (let i = 0; i < dFiltersArray.length; i++) {
      const dFilter = dFiltersArray[i];
      const filterOptions = dFilter.values;
      const filterValues = [];
      let hasFilter = false;

      for (let j = 0; j < filterOptions.length; j++) {
        if (filterOptions[j].checked === true) {
          if (filterOptions[j].match !== "None") {
            filterCount++;
            filterValues.push(filterOptions[j].match);
          } else {
            filterCount++;
            filterValues.push(null);
          }
          hasFilter = true;
        }
      }
      if (hasFilter === true) {
        customFilters[dFilter.resultsMatchID] = filterValues;
      }
    }
    setFiltersCount(filterCount);
    setFilterModel(customFilters);
    return customFilters;
  };

  const clearResultFilters = (e, data) => {
    const dFiltersArray = filters;
    for (let i = 0; i < dFiltersArray.length; i++) {
      for (let j = 0; j < dFiltersArray[i].values.length; j++) {
        dFiltersArray[i].values[j].checked = false;
      }
    }
    createFilterModel(dFiltersArray);
    setFilteredResults(originalResults);
    setVariantFilter(true);
    // document.getElementById('variant').checked = true;
  };

  return (
    <div>
      <PageStandard
        headerType='loggedIn'
        containerFluid={true}
        cartBadge={true}
      >
        <div className='series-header'>
          <Grid container className='series-header-content'>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                widescreen={16}
                className='d-flex justify-content-between'
              >
                <div className='ml-2'>
                  <div className='fs-13px fort-book lh-18'>SERIES</div>
                  <div className='fort-bold fs-28px lh-29'>{seriesCode}</div>
                </div>
                <div className='sort-dropdown-div'>
                  <Dropdown
                    selection
                    options={resultsSortOPtions}
                    value={currentSort}
                    direction='left'
                    onChange={handleSortChange}
                    className='sort-dropdown download-dropdown-div'
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Container className='series-content'>
          <Grid container className='searchContainer'>
            {numOfResults > 0 ? (
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={4}
                  largeScreen={4}
                  widescreen={4}
                >
                  <CustomFilters
                    filters={filters}
                    updateResults={updateResults}
                    selectedFilterCount={filtersCount}
                    clearResultFilters={clearResultFilters}
                    numOfResults={numOfResults}
                    variantFilter={variantFilter}
                    closeFiltersMenu={() => setShowFilters(false)}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={12}
                  computer={12}
                  largeScreen={12}
                  widescreen={12}
                >
                  <Grid>
                    <Grid.Row>
                      <div
                        className='ag-theme-alpine'
                        style={{
                          // height: '600px',
                          width: "100%",
                        }}
                      >
                        {filteredResults.map((row) => (
                          <TitleRow
                            data={row}
                            // key={row.isbnStr}
                            divider={true}
                            pageType={"catalog"}
                            key={`ttrow_${row.isbn}`}
                          />
                        ))}
                      </div>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            ) : (
              ""
            )}
          </Grid>
        </Container>
      </PageStandard>
    </div>
  );
};
