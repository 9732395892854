import AuthServiceContext from "../../providers/AuthSericeContext";
import SecurityContext from "../../providers/SecurityContext";

const withAuthService = (Component) => (props) =>
  (
    <AuthServiceContext.Consumer>
      {(context) => <Component {...props} auth={context} />}
    </AuthServiceContext.Consumer>
  );

export default withAuthService;
