import { Fragment, useEffect, useRef } from "react";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import "./customerenrolment.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Divider } from "../elements/elements";
import { PRHLabel } from "../../shared-react-components/elements/elements";
import TextValidator from "../../shared-react-components/elements/input";
import {
  COUNTRY,
  emptyEnrolmentDataLabelsUS,
  EnrolmentStep,
  mapLabelsUStoCAN,
  mapStateCodeToStateValue,
  paymentMethods_MOCK,
} from "./shared";
import { Icon, Loader } from "semantic-ui-react";
import ReCAPTCHA from "react-google-recaptcha";

const DownloadPdfCard = ({ document, downloadClick }) => {
  const { name } = document;
  return (
    <div className="download-card">
      <div className="download-info-container">
        <Icon name="file pdf outline" className="download-icon" color="red" />
        <div className="document-info">
          <a
            className={`document-title ${document && "document-url"}`}
            onClick={() => downloadClick(document)}
            href={document}
            target="_blank"
            rel="noreferrer"
            download={`${name}.pdf`}
          >
            {name}
          </a>
        </div>
      </div>
      <span onClick={() => downloadClick(document)} className="download-button">
        Download
      </span>
    </div>
  );
};

const ReviewAndSign = ({
  data,
  recaptchaToken,
  onChangeRecaptchaToken,
  onChange,
  onEdit,
  onSubmit,
  onBack,
  error,
  loading,
}) => {
  const signature = data[EnrolmentStep.ReviewAndSign];
  const paymentMethod = data[EnrolmentStep.PaymentMethod];
  const selectedCountry =
    data[EnrolmentStep.BusinessInformation].countryOfOperation;
  const emptyEnrolmentDataLabels =
    selectedCountry === COUNTRY.Canada
      ? mapLabelsUStoCAN()
      : emptyEnrolmentDataLabelsUS;
  const legalBusinessName =
    data[EnrolmentStep.BusinessInformation].legalBusinessName;

  const billingAddressType =
    data[EnrolmentStep.BillingAddress].shippingAddressIsBillingAddress === true
      ? "Shipping and Billing"
      : "Billing";

  const recaptchaRef = useRef();

  const documents = Object.keys(data[EnrolmentStep.UploadRequiredDocuments]);

  const downloadBlob = (document) => {
    const blob = new Blob([document], { type: "application/pdf" });
    // Chrome, FF
    const fileUrl = URL.createObjectURL(blob);
    const w = window.open(fileUrl, "_blank");
    w && w.focus();
  };

  const handleRecaptchaChange = () =>
    onChangeRecaptchaToken(recaptchaRef.current.getValue());

  useEffect(() => {
    if (recaptchaToken === null) {
      recaptchaRef.current.reset();
    }
  }, [recaptchaToken]);

  return <>
    <div className="review-data-section">
      <div className="review-title mb-2">
        <div className="bold-label text-uppercase">Business Information</div>
        <PRHButton
          size="large"
          className="edit-button"
          onClick={() => onEdit(EnrolmentStep.BusinessInformation)}
        >
          Edit
        </PRHButton>
      </div>

      <div className="review-data-table d-flex flex-column">
        {Object.keys(
          emptyEnrolmentDataLabels[EnrolmentStep.BusinessInformation]
        ).map((key) => (
          <div key={key} className="table-row d-flex flex-row">
            <span className="label">
              {
                emptyEnrolmentDataLabels[EnrolmentStep.BusinessInformation][
                  key
                ]
              }
            </span>
            <span className="review-data-value">
              {key === "countryOfOperation"
                ? mapStateCodeToStateValue(
                    data[EnrolmentStep.BusinessInformation][key]
                  )
                : data[EnrolmentStep.BusinessInformation][key]}
            </span>
          </div>
        ))}
      </div>
      <Divider className="mb-2" />
    </div>

    <div className="review-data-section">
      <div className="review-title mb-2">
        <div className="bold-label text-uppercase">Billing address</div>
        <PRHButton
          size="large"
          className="edit-button"
          onClick={() => onEdit(EnrolmentStep.BillingAddress)}
        >
          Edit
        </PRHButton>
      </div>

      <div className="review-data-table d-flex flex-column">
        <div className="table-row d-flex flex-row">
          <span className="label">Legal business name</span>
          <span className="review-data-value">{legalBusinessName}</span>
        </div>
        {Object.keys(
          emptyEnrolmentDataLabels[EnrolmentStep.BillingAddress]
        ).map((key) => (
          <div key={key} className="table-row d-flex flex-row">
            <span className="label">
              {emptyEnrolmentDataLabels[EnrolmentStep.BillingAddress][key]}
            </span>
            <span className="review-data-value">
              {key === "billingAddressIsResidence"
                ? data[EnrolmentStep.BillingAddress][key] === true
                  ? "Yes"
                  : "No"
                : data[EnrolmentStep.BillingAddress][key]}
            </span>
          </div>
        ))}
        <div className="table-row d-flex flex-row">
          <span className="label">Address Type</span>
          <span className="review-data-value">{billingAddressType}</span>
        </div>
      </div>
      <Divider className="my-2 tight" />
    </div>

    {data[EnrolmentStep.BillingAddress].shippingAddressIsBillingAddress ===
      false && (
      <div className="review-data-section">
        <div className="review-title mb-2">
          <div className="bold-label text-uppercase">Shipping address</div>
          <PRHButton
            size="large"
            className="edit-button"
            onClick={() => onEdit(EnrolmentStep.ShippingAddress)}
          >
            Edit
          </PRHButton>
        </div>

        <div className="review-data-table d-flex flex-column">
          {Object.keys(
            emptyEnrolmentDataLabels[EnrolmentStep.ShippingAddress]
          ).map((key) => (
            <div key={key} className="table-row d-flex flex-row">
              <span className="label">
                {emptyEnrolmentDataLabels[EnrolmentStep.ShippingAddress][key]}
              </span>
              <span className="review-data-value">
                {key === "shippingAddressIsResidence"
                  ? data[EnrolmentStep.ShippingAddress][key] === true
                    ? "Yes"
                    : "No"
                  : data[EnrolmentStep.ShippingAddress][key]}
              </span>
            </div>
          ))}
          <div className="table-row d-flex flex-row">
            <span className="label">Address Type</span>
            <span className="review-data-value">Shipping</span>
          </div>
        </div>
        <Divider className="mb-2 tight" />
      </div>
    )}

    {documents && !!documents.length && (
      <div className="review-data-section">
        <div className="review-title my-2">
          <div className="bold-label text-uppercase">Required documents</div>
        </div>
        <Divider className="mb-2 tight" />

        <div className="review-data-table d-flex flex-column">
          {documents.map((document) => (
            <Fragment key={document}>
              <DownloadPdfCard
                document={
                  data[EnrolmentStep.UploadRequiredDocuments][document]
                }
                downloadClick={(document) => downloadBlob(document)}
              />
              <Divider className="mb-2" />
            </Fragment>
          ))}
        </div>
      </div>
    )}

    {paymentMethod === paymentMethods_MOCK.Invoice.id && (
      <div className="review-data-section">
        <div className="review-title mb-2">
          <div className="bold-label text-uppercase">
            Bank account information
          </div>
          <PRHButton
            size="large"
            className="edit-button"
            onClick={() => onEdit(EnrolmentStep.BankAccountInformation)}
          >
            Edit
          </PRHButton>
        </div>

        <div className="review-data-table d-flex flex-column">
          {Object.keys(
            emptyEnrolmentDataLabels[EnrolmentStep.BankAccountInformation]
          ).map((key) => (
            <div key={key} className="table-row d-flex flex-row">
              <span className="label">
                {
                  emptyEnrolmentDataLabels[
                    EnrolmentStep.BankAccountInformation
                  ][key]
                }
              </span>
              <span className="review-data-value">
                {data[EnrolmentStep.BankAccountInformation][key]}
              </span>
            </div>
          ))}
        </div>
        <Divider className="mb-2" />
      </div>
    )}

    {paymentMethod === paymentMethods_MOCK.Invoice.id && (
      <div className="review-data-section">
        <div className="review-title mb-2">
          <div className="bold-label text-uppercase">
            Trade Credit Reference
          </div>
          <PRHButton
            size="large"
            className="edit-button"
            onClick={() => onEdit(EnrolmentStep.TradeCreditReference)}
          >
            Edit
          </PRHButton>
        </div>

        <div className="review-data-table d-flex flex-column">
          <div className="bold-label mb-2">CREDIT REFERENCE #1</div>
          {Object.keys(
            emptyEnrolmentDataLabels[EnrolmentStep.TradeCreditReference]
          ).map((key) => (
            <div key={key} className="table-row d-flex flex-row">
              <span className="label">
                {
                  emptyEnrolmentDataLabels[
                    EnrolmentStep.TradeCreditReference
                  ][key]
                }
              </span>
              <span className="review-data-value">
                {data[EnrolmentStep.TradeCreditReference].ref1[key]}
              </span>
            </div>
          ))}
        </div>
        <div className="review-data-table d-flex flex-column">
          <div className="bold-label my-2">CREDIT REFERENCE #2</div>
          {Object.keys(
            emptyEnrolmentDataLabels[EnrolmentStep.TradeCreditReference]
          ).map((key) => (
            <div key={key} className="table-row d-flex flex-row">
              <span className="label">
                {
                  emptyEnrolmentDataLabels[
                    EnrolmentStep.TradeCreditReference
                  ][key]
                }
              </span>
              <span className="review-data-value">
                {data[EnrolmentStep.TradeCreditReference].ref2[key]}
              </span>
            </div>
          ))}
        </div>
        <div className="review-data-table d-flex flex-column">
          <div className="bold-label my-2">CREDIT REFERENCE #3</div>
          {Object.keys(
            emptyEnrolmentDataLabels[EnrolmentStep.TradeCreditReference]
          ).map((key) => (
            <div key={key} className="table-row d-flex flex-row">
              <span className="label">
                {
                  emptyEnrolmentDataLabels[
                    EnrolmentStep.TradeCreditReference
                  ][key]
                }
              </span>
              <span className="review-data-value">
                {data[EnrolmentStep.TradeCreditReference].ref3[key]}
              </span>
            </div>
          ))}
        </div>
        <Divider className="my-3 tight" />
      </div>
    )}

    <div className="review-data-section">
      <div className="review-title mb-2">
        <div className="bold-label text-uppercase">Sign</div>
      </div>
      <p className="mb-4">
        Please provide your digital signature by typing your first and last
        name to verify that the information you have provided is accurate and
        that you consent to the terms outlined in this application.
        <ul className="pl-2 fs-12px">
          <li>
            You agree to be bound by all of Penguin Random House LLC’s
            published Terms of Sale (the “Terms of Sale”) posted on{" "}
            <a
              href="http://www.penguinrandomhouse.biz/booksellers/termsofsale"
              target="_blank"
              rel="noreferrer"
            >
              http://www.penguinrandomhouse.biz/booksellers/termsofsale
            </a>{" "}
            (“the Website”). From time to time, Penguin Random House LLC may,
            in its sole discretion, change, update, amend or modify the Terms
            of Sale by posting them on the Website at any time and without
            notice to you (“the Modifications”). Your signature below
            constitutes your acceptance of such Modifications as set forth on
            the Website. You further agree that by completing this credit
            application, you are affirming financial responsibility, ability
            and willingness to pay invoices according to their terms and the
            Terms of Sale. In the event of non-payment, you are responsible
            for all late fees, finance charges, collection fees and/or legal
            fees incurred.{" "}
          </li>
          <li>
            You understand you are required to report any change of name or
            ownership of business.
          </li>
          <li>
            Applicant certifies that all information contained herein is true
            and correct. Applicant grants permission to Penguin Random House
            LLC to obtain independent credit reports or credit reports and
            other information from its references and bank, and authorizes the
            credit references to release information to Penguin Random House
            LLC that may be used to determine credit worthiness.
          </li>
          <li>
            US ACCOUNTS (only) The federal equal credit opportunity act
            prohibits creditors from discriminating against credit applicants
            on the basis of race, color, religion, national origin, sex,
            marital status, age (provided the applicant has the capacity to
            enter into a binding contract); because all or part of the
            applicant’s income derives from any public assistance program; or
            because the applicant has in good faith exercised any right under
            the consumer credit protection act. The federal agency that
            administers compliance with this law concerning this creditor is
            federal trade commission, equal credit opportunity; Washington, DC
            20580.
          </li>
          <li>
            If your application for business credit is denied, you have the
            right to a written statement of the specific reasons for the
            denial. To obtain the statement, please contact the penguin random
            house credit manager by calling{" "}
            <a href="tel:1-800-726-0600">1-800-726-0600</a> or by sending
            correspondence to 400 Hahn road, Westminster, MD 21157 within 60
            days from the date you are notified of our decision. We will send
            you a written statement of reasons for the denial within 30 days
            of receiving your request for the statement. Please send New
            Account application, order and applicable tax doc to{" "}
            <a href="mailto:newaccount@penguinrandomhouse.com">
              newaccount@penguinrandomhouse.com
            </a>{" "}
            or fax to 1-866-924-1396.
          </li>
        </ul>
      </p>

      <TextValidator
        id="signature"
        name="signature"
        className="form-field"
        label={
          <PRHLabel className="input-label">First and Last Name</PRHLabel>
        }
        type="text"
        onChange={(e) => onChange(e.target.value)}
        value={signature}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />
    </div>

    <div className="d-flex mt-3 align-items-start start">
      <ReCAPTCHA
        onExpired={handleRecaptchaChange}
        onChange={handleRecaptchaChange}
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        size={"normal"}
      />
    </div>
    <div className="d-flex flex-row Xbutton-holder my-5">
      <PRHButton
        size="large"
        iconleft
        icon="arrow left"
        className="mr-auto"
        onClick={onBack}
      >
        Back
      </PRHButton>
      <PRHButton
        disabled={!signature || loading || !recaptchaToken}
        iconright
        icon="arrow right"
        size="large"
        className="ml-auto"
        type="submit"
        onClick={() => onSubmit(data)}
      >
        Complete Application
      </PRHButton>
    </div>

    {error && <div className="error-message text-center">{error}</div>}
    {loading && <Loader inline="centered" size="small" active className="" />}
  </>;
};

export default ReviewAndSign;
