import { useState, useEffect } from "react";
import DialogModal from "./DialogModal";
import { Loader } from "semantic-ui-react";
//import BookDetailSnippet from '../../shared-react-components/elements/BookDetailSnippet';
import withSecurity from "../hc/withSecurity";
import withApp from "../hc/withApp";
import withCart from "../hc/withCart";
import withAccount from "../hc/withAccount";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Divider } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { faFileSignature, faPlus } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import "./CreditCardModal.scss";
import {
  useGetHrdiCallerQuery,
  useLazyGetHrdiCallerQuery,
} from "../../api/ecomApi";

const CreditCardModal = (props) => {
  const {
    onClose,
    buttonStyles,
    //isOpen,
    classes,
    size,
    cart,
    app,
    reloadCards,
  } = props;
  const [paymentForm, setPaymentForm] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [yourPayment, setYourPayment] = useState(false);
  const [state, setState] = useState({
    quantity: 0,
    //paymentForm: null,
    damageType: undefined,
    requestReplacementBook: false,
    sessionTimedOut: true,
    loading: true,
    ccIframeClass: "hri-billing-address",
    paymentUseBillingAddress: false,
    paymentUseBillingAddress: true,
    ccIframeClass: "",
    sessionTimedOut: false,
    iframeHeight: "425",
    showIframe: true,
  });
  const [getHrdiCaller, getHrdiCallerResult] = useLazyGetHrdiCallerQuery();

  const hideSpinner = () => {
    setState({
      ...state,
      loading: false,
    });
  };

  const resetCardPage = () => {
    let ccAdded = true;
    setIsOpen(false);
    reloadCards(ccAdded);
  };

  function loadForm() {
    let hrdiRequest = {};
    // console.log('cart 1', cart.customerInfo);
    // console.log('cart 2', cart.shipTo);
    if (cart.shipTo !== -1) {
      hrdiRequest = {
        address: {
          name1: cart.customerInfo.shipToAccountNos[cart.shipTo]?.address.name1,
          name2: cart.customerInfo.shipToAccountNos[cart.shipTo]?.address.name2,
          name3: cart.customerInfo.shipToAccountNos[cart.shipTo]?.address.name3,
          street:
            cart.customerInfo.shipToAccountNos[cart.shipTo]?.address.street,
          city: cart.customerInfo.shipToAccountNos[cart.shipTo]?.address.city,
          state: cart.customerInfo.shipToAccountNos[cart.shipTo]?.address.state,
          postalCode:
            cart.customerInfo.shipToAccountNos[cart.shipTo]?.address.postalCode,
          country:
            cart.customerInfo.shipToAccountNos[cart.shipTo]?.address.country,
          phone: cart.customerInfo.shipToAccountNos[cart.shipTo]?.address.phone,
        },
      };
    } else {
      hrdiRequest = {
        address: {
          name1: cart.customerInfo.customer?.address.name1,
          name2: cart.customerInfo.customer?.address.name2,
          name3: cart.customerInfo.customer?.address.name3,
          street: cart.customerInfo.customer?.address.street,
          city: cart.customerInfo.customer?.address.city,
          state: cart.customerInfo.customer?.address.state,
          postalCode: cart.customerInfo.customer?.address.postalCode,
          country: cart.customerInfo.customer?.address.country,
          phone: cart.customerInfo.customer?.address.phone,
        },
      };
    }

    getHrdiCaller(hrdiRequest).then((r) => {
      setPaymentForm(r.data);
    });
  }

  useEffect(() => {
    loadForm();
  }, [setPaymentForm]);

  useEffect(() => {
    if (yourPayment) {
      resetCardPage();
      setYourPayment(false);
    }
  }, [yourPayment]);

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data === "YOUR_PAYMENT") {
          setState({ ...state, loading: false });
          setYourPayment(true);
          //resetCardPage();
        }
        if (event.data === "CARD_ERROR") {
          setState({ ...state, loading: false });
          setYourPayment(true);
          //setIsOpen(false);
        }
        if (event.data === "SAMEBILLSHIPADDRESS_UNCHECKED") {
          setState({
            ...state,
            paymentUseBillingAddress: true,
            loading: false,
            iframeHeight: "700",
          });
        }
        if (event.data === "SAMEBILLSHIPADDRESS_CHECKED") {
          setState({
            ...state,
            loading: false,
            paymentUseBillingAddress: false,
            iframeHeight: "425",
          });
        }
        if (event.data === "CC_INPUT_VALIDATION_FAILED") {
          setState({ ...state, ccIframeClass: "hri-validation-failed" });
        }
        if (event.data === "BACK_BUTTON_CLICKED") {
        }
        if (event.data === "SESSION_TIMED_OUT") {
          setState({ ...state, sessionTimedOut: true });
        }
      },
      false
    );
  }, []);

  return (
    <>
      <PRHButton
        //className={'ml-auto'}
        // size="small"
        size={buttonStyles.size}
        className={buttonStyles.className}
        bold={buttonStyles.bold}
        fontAwesome={true}
        icon={faPlus}
        displayInlineFlex
        //iconleft
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {buttonStyles.label}
      </PRHButton>

      <DialogModal
        open={isOpen}
        closeOnEscape={true}
        modalContentClass='id-modal-content'
        closeOnDimmerClick={true}
        onClose={() => {
          setIsOpen(false);
        }}
        /*
      options={{
        className: 'id-modal-style',
      }}
      */
        dialogOptions={{
          header: "ADD NEW CREDIT CARD",
        }}
        size='small'
      >
        <div>
          <div className='mt-2 ml-2 mb-2 d-flex'>
            <div>
              <i className='ml-0 circle-exclamation-grey d-inline-block mr-2 fort-book fs-13px' />
            </div>
            <div>
              <span className='fort-bold'>Please note:</span> By storing a
              credit card on file, you authorize Penguin Random House to charge
              the credit card for agreed upon purchases. The information will be
              saved to file for future transactions on your account and may be
              applied to open orders in the event the previously supplied
              payment method fails.
            </div>
          </div>
          <div className='ml-2 mr-2 d-flex flex-column'>
            {state.loading ? (
              <Loader style={{ marginTop: "40px" }} active inline='centered' />
            ) : null}
            {state.showIframe && isOpen ? (
              <iframe
                className={`${state.ccIframeClass}`}
                title='Payment Form'
                id='payment-form'
                height={state.iframeHeight}
                ssandbox='allow-same-origin allow-forms allow-scripts'
                frameBorder='0'
                onLoad={() => hideSpinner()}
                srcDoc={paymentForm}
              />
            ) : (
              <div className='d-flex flex-row'>
                <div className='ml-2 mr-2 d-flex flex-column'>
                  <div className='mt-4 ml-auto mr-auto fort-book'>
                    Unable to add your card at this time. Please try again or
                    contact customer service.
                  </div>
                  <PRHButton
                    className='mt-4 ml-auto mr-auto'
                    bold
                    icon='redo'
                    onClick={(e) => {
                      setState({
                        ...state,
                        showIframe: true,
                        sessionTimedOut: false,
                      });
                      loadForm();
                    }}
                  >
                    Reload
                  </PRHButton>
                </div>
              </div>
            )}
          </div>
          {!state.sessionTimedOut ? (
            ""
          ) : (
            <div className='d-flex flex-row'>
              <div className='ml-2 mr-2 d-flex flex-column'>
                <div className='mt-4 ml-auto mr-auto fort-book'>
                  Your credit card session expired, please click reload to
                  re-enter your credit card information.
                </div>
                <PRHButton
                  className='mt-4 ml-auto mr-auto'
                  bold
                  icon='redo'
                  onClick={(e) => {
                    setState({ sessionTimedOut: false });
                    loadForm();
                  }}
                >
                  Reload
                </PRHButton>
              </div>
            </div>
          )}
          <div className='mt-1 mb-1 pl-2 pr-2 d-flex mb-1 d-flex'>
            <Divider className='accounts-header-divider' />
          </div>
          {/*
          <div className="mt-2 ml-2 mb-2 d-flex">
            <div>
              <i className="ml-0 circle-exclamation d-inline-block mr-2 fort-book fs-13px"></i>
            </div>
            <div>
              <span className="fort-bold">Please note:</span> After clicking
              "Verify," card details will be saved in your account.
            </div>
          </div>
          */}
        </div>
      </DialogModal>
    </>
  );
};

export default withRouter(
  withSecurity(withApp(withCart(withAccount(CreditCardModal))))
);
