import { useEffect, useState } from "react";
import LazyImage from "../../utils/LazyImage";
import useSecurity from "../../components/hc/useSecurity";

import "./InvoiceDetailDropdown.scss";
import { Grid } from "semantic-ui-react";

export const InvoiceDetailDropdownRowMobile = ({ book }) => {
  const [isCanceled, setisCanceled] = useState(book.status === "2");
  const [isMissing, setIsMissing] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    //console.log(book.author, book.bookFormat, book.bookOnsale, book.cartonQty);
    if (
      book.author === null &&
      book.bookFormat === null &&
      book.bookOnsale === null &&
      book.cartonQty === null
    ) {
      setIsMissing(true);
    }
  });

  return (
    <div key={book.isbn} className='my-4'>
      <Grid>
        <Grid.Row width={16}>
          <Grid.Column width={3}>
            <LazyImage
              src={`https://images.penguinrandomhouse.com/cover/${book.isbn}`}
              className={`odd-title-image mobile ${
                isCanceled && "odd-title-image-canceled"
              }`}
              alt={"Book"}
              isSmallThumbNail={true}
              isVariant={book.isVariant}
              varTagWidth={50}
            />
          </Grid.Column>
          <Grid.Column width={9}>
            {!isMissing && (
              <>
                <div className='fort-bold'>
                  {book.bookTitle.length > 46
                    ? book.bookTitle.substring(0, 46) + "..."
                    : book.bookTitle || ""}
                </div>
                <div className='od-text-data-book'>By: {book.author}</div>
              </>
            )}
            {!isMissing && isShowMore && (
              <>
                <div>{book.bookFormat}</div>
                <div>{book.cartonQty} per carton</div>
                <div>Country: {book.departureCountry}</div>
                {book.upcSku && book.upcSku.length > 0 && (
                  <div className='od-text-data-book'>
                    UPC/SKU: {book.upcSku}
                  </div>
                )}
              </>
            )}
            <div
              className='text-blue cursor-pointer mt-1'
              onClick={() => setIsShowMore(!isShowMore)}
            >
              {isShowMore ? "View less" : "View more"}
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            {!isMissing && (
              <div className='d-flex justify-content-end'>
                Qty: <span className='fort-bold'>{book.quantity}</span>
              </div>
            )}
            {!isMissing && (
              <div className='d-flex justify-content-end fort-bold'>
                {Number(book.itemPrice).toFixed(2) > 0
                  ? `$${Number(book.itemPrice).toFixed(2)}`
                  : "FREE"}
              </div>
            )}
            {!isMissing && (
              <div className='od-discount-price d-flex flex-column justify-content-end align-items-end'>
                {Number(book.coverPrice).toFixed(2) > 0 &&
                  Math.abs(Number(book?.discount)) > 0 && (
                    <div className='od-discount-price d-flex flex-column justify-content-end align-items-end'>
                      <span>
                        {String(book.discount).replace("-", "")}% off{" "}
                      </span>
                      <span className='text-line-through'>
                        ${Number(book.coverPrice).toFixed(2)}
                      </span>
                    </div>
                  )}
              </div>
            )}
            {!isMissing && (
              <div className='text-right'>
                {Number(book.discountedCoverPrice).toFixed(2) > 0
                  ? `$${Number(book.discountedCoverPrice).toFixed(2)}`
                  : "FREE"}{" "}
                (each)
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
