import { useState, useEffect } from "react";
import {
  Header,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./VariantRestrictionsCartsDeleteModal.scss";
import { isGeneralCart, formatDateMMDDYYYY } from "../../utils/utilities";
import useCart from "../hc/useCart";
import LazyImage from "../../utils/LazyImage";

const VariantRestrictionsCartsDeleteModal = ({
  foc,
  shipTo,
  openState = false,
  focVariantTitles = [],
  setOpenState,
  proceedCallback,
  cancelCallback,
}) => {
  // console.log('vrcsdm', focVariantTitles);
  const cart = useCart();
  const [actionPerformed, setActionPerformed] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const resetState = () => {
    setDialogVisible(false);
  };

  const ContinueAction = {
    label: "Yes, Continue",
    borderColorOverride: "blue",
    textColorOverride: "white",
    textColorHoverOverride: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "white",
    iconColorOverride: "white",
    iconColorHoverOverride: "blue",
    // icon: 'checkmark',
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      setActionPerformed(true);
      // updateRemove();
      if (proceedCallback) proceedCallback();
      resetState();
      return { proceed: true };
    },
  };

  const CancelAction = {
    label: "Cancel",
    className: "ml-auto",
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      resetState();
      if (cancelCallback) cancelCallback();
      return { proceed: true };
    },
  };

  useEffect(() => {
    if (dialogVisible) {
      if (!openState) {
        if (!actionPerformed && cancelCallback) cancelCallback();
        resetState();
        setActionPerformed(false);
      }
    }
    if (openState && !dialogVisible) {
      setDialogVisible(true);
    }
  }, [actionPerformed, cancelCallback, dialogVisible, openState]);

  let numVariantLocationsMap = {};
  let numVariantTitles = 0;

  focVariantTitles.forEach((f) => {
    f.locations.forEach((l) => {
      numVariantLocationsMap[l.shipTo] = true;
      numVariantTitles += l.totalVariantTitles;
    });
  });
  let numVariantLocations = Object.keys(numVariantLocationsMap).length;

  return (
    <DialogModal
      open={openState}
      //open
      setOpen={setOpenState}
      // onXClose={cancelCallback}
      modalClassName='vro-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            Changes to Other Account(s)' Carts
          </Header>
        ),
        actions: [CancelAction, ContinueAction],
      }}
    >
      <div className='ar-box'>
        <>
          <div className='ml-4 mr-4 mb-2'>
            {/* Clearing the following FOC carts will disqualify and remove{' '}
            <span className="fort-bold">{numVariantTitles}</span> variant
            titles' following quantities from{' '}
            <span className="fort-bold">{numVariantLocations}</span> accounts: */}
            Clearing the following FOC carts will disqualify and remove variants
            from these accounts:
          </div>
          <Divider className='mt-0 mb-0' />
          <div
            style={{ maxHeight: "400px" }}
            className={`overflow-auto bg-gray-100 ${
              numVariantLocations === 0 && "Xpb-2"
            }`}
          >
            {focVariantTitles.map((fvt, fvt_idx) => {
              return (
                <div key={`fvt-${fvt_idx}`}>
                  {fvt_idx > 0 && <Divider className='mb-1' tight />}
                  <div className='d-flex flex-row pl-4 pr-4 mt-1 mb-1 pt-1'>
                    <div className='d-flex flex-column justify-content-center'>
                      <div className='fort-bold fs-13px lh-18px text-gray-700'>
                        {!isGeneralCart(fvt.foc)
                          ? `FOC ${formatDateMMDDYYYY(fvt.foc, "/")}`
                          : "General"}
                      </div>
                    </div>
                  </div>

                  {fvt.locations.map((al, i) => (
                    <div key={i} className={`pl-4 pr-4 `}>
                      <Divider tight />
                      <div className='d-flex flex-row mt-1 mb-1'>
                        <div className=''>
                          Account #{al.shipTo}{" "}
                          <span className='fort-book-italic'>
                            {al.thisAccount ? (
                              "(This account)"
                            ) : al.confirmationStatus === "CONFIRMED" ? (
                              <>
                                (Confirmed){" "}
                                <Popup
                                  className='cp-help-tooltip'
                                  content='This account’s cart has had no updates since it was Confirmed. Continuing will update their cart and requires re-confirmation.'
                                  on='hover'
                                  position='right center'
                                  offset={[0, 5]}
                                  trigger={
                                    <Icon
                                      name='question circle'
                                      className='sb_question_icon'
                                    />
                                  }
                                />
                              </>
                            ) : null}
                          </span>
                        </div>
                        <div className='d-flex flex-column ml-auto justify-content-center'>
                          <div className='fort-book fs-13px lh-18px text-gray-700 ml-auto'>
                            VAR:{" "}
                            <span className='fort-bold'>
                              {al.totalVariantTitles}
                            </span>
                            <span className='ml-1 mr-1'>|</span>
                            QTY:{" "}
                            <span className='fort-bold'>
                              {al.totalVariantQuantity}
                            </span>
                            <span className='fort-bold'>{al.quantity}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
          <Divider className='mt-0 mb-0' />
          <div className='ml-4 mr-4 mt-2 fort-book fs-13px lh-14px'>
            Are you sure you want to continue clearing your FOC carts and remove
            all items from these accounts?
          </div>
          {cart.isUserPow() && (
            <div className='ml-4 mr-4 mt-2 fort-book fs-13px lh-14px'>
              <span className='fort-bold'>Note:</span> Any FOC carts with no
              Confirmed quantities will be removed.
            </div>
          )}
        </>
      </div>
    </DialogModal>
  );
};

VariantRestrictionsCartsDeleteModal.propTypes = {
  openState: PropTypes.bool.isRequired,
  setOpenState: PropTypes.func.isRequired,
};

VariantRestrictionsCartsDeleteModal.defaultProps = {};

export default VariantRestrictionsCartsDeleteModal;
