//KEEP?
import { Icon, Button } from 'semantic-ui-react';
import {
  isSafari,
  osName,
  isChrome,
  isFirefox,
  isIE,
  browserVersion,
} from 'react-device-detect';
import './elements.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import md5 from 'md5';
import uuid from 'react-uuid';
import { Link } from 'react-router-dom';
import { text } from '@fortawesome/fontawesome-svg-core';

const PRHButton = (props) => {
  const {
    iconOnlyBorderAlways,
    displayInlineFlex,
    iconClassName,
    className,
    children,
    icon,
    fontAwesome,
    iconright,
    onClick,
    disabled,
    size,
    role,
    backgroundColorOverride,
    backgroundHoverColorOverride,
    backgroundDisabledColorOverride,
    iconColorOverride,
    iconColorHoverOverride,
    iconColorDisabledOverride,
    borderColorOverride,
    borderHoverColorOverride,
    bold,
    textColorOverride,
    textColorHoverOverride,
    invertHoverState,
    hideOnDisabled,
    invisibleOnDisabled,
    focusColorOverride,
    backgroundColorDisabledOverride,
    borderColorHoverOverride,
    textClass,
    textNoWrap,
    iconHolderClass,
    iconHolderStyle,
    link,
    btnTxtOverride,
    ...restProps
  } = props;

  // const hash = md5(JSON.stringify(props));
  const hash = uuid();

  const buttonClasses = [
    'prhbutton',
    isSafari ? 'safari' : '',
    isFirefox ? 'firefox' : '',
    isChrome ? 'chrome' : '',
    isIE ? 'ie' : '',
    osName,
    textColorOverride && !disabled ? `text-${textColorOverride}` : '',
    focusColorOverride && !disabled ? `focus-${focusColorOverride}` : '',
    backgroundColorOverride && !disabled ? `bg-${backgroundColorOverride}` : '',
    backgroundColorDisabledOverride && disabled
      ? `bg-${backgroundColorDisabledOverride}`
      : '',
    backgroundHoverColorOverride && !disabled
      ? `hover-bg-${backgroundHoverColorOverride}`
      : '',

    borderColorHoverOverride && !disabled
      ? `hover-border-${borderColorHoverOverride}`
      : '',
    borderColorOverride ? `border-${borderColorOverride}` : '',
    borderHoverColorOverride && !disabled
      ? `hover-border-${borderHoverColorOverride}`
      : '',
    textColorHoverOverride && !disabled
      ? `text-hover-${textColorHoverOverride}`
      : '',
    textColorHoverOverride && !disabled
      ? `icon-hover-${textColorHoverOverride}`
      : '',
    invertHoverState ? 'invert-hover-state' : '',
    className,
  ].filter((r) => !!r);
  const textClasses = !!textClass ? [textClass] : [];

  if (textNoWrap) {
    textClasses.push('text-nowrap');
  }
  if (bold) {
    textClasses.push('fort-bold');
  } else if (!bold && !btnTxtOverride) {
    textClasses.push('fort-book');
  }

  if (btnTxtOverride) {
    textClasses.push(btnTxtOverride);
  }

  if (size === 'small') {
    buttonClasses.push('button-small');
  } else if (size === 'medium') {
    buttonClasses.push('button-medium');
  } else if (size === 'large') {
    buttonClasses.push('button-large');
  } else if (size === 'big-wide') {
    buttonClasses.push('button-big-wide');
  } 

  if (icon && !children) {
    buttonClasses.push('p-0');
    buttonClasses.push('button-icon-only');
    if (!!iconOnlyBorderAlways) {
      buttonClasses.push('button-with-border');
    }
  }

  if (!icon) {
    buttonClasses.push('sans-icon');
  }

  if (iconright) {
    buttonClasses.push('right-icon');
  }

  if (disabled) {
    buttonClasses.push('disabled');
  }

  if (disabled && hideOnDisabled) {
    buttonClasses.push('d-none');
  } else if (displayInlineFlex) {
    buttonClasses.push('d-inline-flex');
  } else {
    buttonClasses.push('d-flex');
  }

  if (disabled && invisibleOnDisabled) {
    buttonClasses.push('v-hidden');
  }

  if (isIE) {
    buttonClasses.push('ie-button');
  }

  if (isFirefox) {
    if (osName === 'Windows') {
      buttonClasses.push('win-ff-button');
    } else {
      buttonClasses.push('osx-ff-button');
    }
  }

  if (isChrome) {
    if (osName === 'Windows') {
      buttonClasses.push('win-chrome-button');
    } else {
      buttonClasses.push('osx-chrome-button');
    }
    buttonClasses.push('version-' + browserVersion);
  }

  const handleKeyUpOnButton = (ev) => {
    if (!disabled && ev.keyCode === 13) {
      if (onClick) {
        onClick();
      }
    }
  };
  let iconElement = null;
  if (icon) {
    if (fontAwesome) {
      iconElement = (
        <FontAwesomeIcon
          className={`${iconClassName} icon-${iconColorOverride} icon`}
          icon={icon}
        />
      );
    } else {
      if (typeof icon !== 'string') {
        iconElement = icon;
      } else {
        if (iconColorOverride) {
          iconElement = (
            <Icon
              name={icon}
              className={`d-flex align-items-center ${iconClassName} ${
                !!children ? ' h-100 lh-inherit' : ''
              }   ${
                iconColorDisabledOverride
                  ? `icon-disabled-${iconColorDisabledOverride}`
                  : ''
              } ${!!!disabled ? `icon-${iconColorOverride}` : ''} ${
                iconColorHoverOverride
                  ? `icon-hover-${iconColorHoverOverride}`
                  : ''
              }
              `}
              // color={iconColorOverride}
            />
          );
        } else {
          iconElement = (
            <Icon
              name={icon}
              className={`d-flex align-items-center ${iconClassName} ${
                !!children ? ' h-100 lh-inherit' : ''
              } lh-inherit icon-${iconColorOverride}`}
            />
          );
        }
      }
    }
  }

  let actualIconHolderClass = `iconholder d-flex align-items-stretch justify-content-end ${iconHolderClass}`;
  if (!!children) {
    actualIconHolderClass += ' pr-1';
  }
  return (
    <>
      {link !== '' ? (
        <Link
          to={link}
          className="buttonLinkStyle"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Button
            {...restProps}
            key={hash}
            className={buttonClasses.join(' ')}
            onClick={!disabled && onClick ? onClick : null}
            onKeyUp={handleKeyUpOnButton}
            role={role}
            tabIndex={0}
          >
            {!!iconElement && !iconright && (
              <div className={actualIconHolderClass} style={iconHolderStyle}>
                {iconElement}
              </div>
            )}
            {!!children && (
              <span
                className={`button-text ${
                  osName === 'Windows' && isChrome ? 'h-50' : ''
                } ${textClasses.join(' ')}`}
              >
                {children}
              </span>
            )}
            {iconright ? (
              <div className={actualIconHolderClass} style={iconHolderStyle}>
                {iconElement}
              </div>
            ) : (
              ''
            )}
          </Button>
        </Link>
      ) : (
        <Button
          {...restProps}
          key={hash}
          className={buttonClasses.join(' ')}
          onClick={!disabled && onClick ? onClick : null}
          onKeyUp={handleKeyUpOnButton}
          role={role}
          tabIndex={0}
        >
          {!!iconElement && !iconright && (
            <div className={actualIconHolderClass} style={iconHolderStyle}>
              {iconElement}
            </div>
          )}
          {!!children && (
            <span className={`button-text ${textClasses.join(' ')}`}>
              {children}
            </span>
          )}
          {iconright ? (
            <div className={actualIconHolderClass} style={iconHolderStyle}>
              {iconElement}
            </div>
          ) : (
            ''
          )}
        </Button>
      )}
    </>
  );
};

PRHButton.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  fontAwesome: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconright: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'big-wide']),
  role: PropTypes.string,
  borderColorOverride: PropTypes.string,
  borderHoverColorOverride: PropTypes.string,
  iconColorOverride: PropTypes.string,
  iconColorHoverOverride: PropTypes.string,
  borderColorHoverOverride: PropTypes.string,
  textColorOverride: PropTypes.string,
  textNoWrap: PropTypes.bool,
  backgroundColorOverride: PropTypes.string,
  backgroundHoverColorOverride: PropTypes.string,
  backgroundDisabledColorOverride: PropTypes.string,
  textColorHoverOverride: PropTypes.string,
  focusColorOverride: PropTypes.string,
  bold: PropTypes.bool,
  invertHoverState: PropTypes.bool,
  hideOnDisabled: PropTypes.bool,
  invisibleOnDisabled: PropTypes.bool,
  displayInlineFlex: PropTypes.bool,
  textClass: PropTypes.string,
  iconOnlyBorderAlways: PropTypes.bool,
  link: PropTypes.string,
  btnTxtOverride: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

PRHButton.defaultProps = {
  bold: false,
  borderColorHoverOverride: '',
  className: '',
  iconClassName: '',
  icon: '',
  iconright: false,
  fontAwesome: false,
  disabled: false,
  size: 'medium',
  children: false,
  role: 'button',
  borderColorOverride: '',
  iconColorOverride: '',
  iconColorHoverOverride: '',
  focusColorOverride: '',
  textColorOverride: '',
  textNoWrap: false,
  onClick: () => {},
  invertHoverState: false,
  backgroundColorOverride: '',
  backgroundHoverColorOverride: '',
  textClass: '',
  backgroundDisabledColorOverride: '',
  textColorHoverOverride: '',
  hideOnDisabled: false,
  displayInlineFlex: false,
  invisibleOnDisabled: false,
  iconOnlyBorderAlways: false,
  iconHolderClass: '',
  iconHolderStyle: {},
  link: '',
  btnTxtOverride: false,
};

export default PRHButton;
