import { Component } from "react";
import PropTypes from "prop-types";
import { Visibility, Loader } from "semantic-ui-react";
import "./LazyImage.scss";

export default class LazyImage extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    size: PropTypes.string,
    altImageSize: PropTypes.string,
    isVariant: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    variantCount: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    isThumbNail: PropTypes.bool,
    isSmallThumbNail: PropTypes.bool,
    isAgGrid: PropTypes.bool,
    boxShadow: PropTypes.bool,
    varTagWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  };

  static defaultProps = {
    altImageSize: `small`,
    isVariant: false,
    variantCount: false,
    isThumbNail: true,
    isSmallThumbNail: false,
    isAgGrid: false,
    boxShadow: false,
    varTagWidth: false,
  };

  state = {
    show: false,
  };

  showImage = () => {
    this.setState({
      show: true,
    });
  };

  render() {
    const {
      size,
      altImageSize,
      isVariant,
      variantCount,
      isThumbNail,
      isSmallThumbNail,
      isAgGrid,
      boxShadow,
      varTagWidth,
    } = this.props;
    let imgSrc =
      this.props.src +
      (altImageSize === "small"
        ? "?alt=image_coming.gif"
        : "?alt=image_coming.gif");
    const decoratedProps = { ...this.props };
    delete decoratedProps.altImageSize;
    delete decoratedProps.isVariant;
    delete decoratedProps.isThumbNail;
    delete decoratedProps.isSmallThumbNail;
    delete decoratedProps.variantCount;
    delete decoratedProps.isAgGrid;
    delete decoratedProps.boxShadow;
    delete decoratedProps.varTagWidth;

    if (!this.state.show) {
      return (
        <Visibility
          fireOnMount
          as='span'
          updateOn='repaint'
          onOnScreen={this.showImage}
          onTopVisible={this.showImage}
          onTopPassed={this.showImage}
          onPassing={this.showImage}
          // fireOnMount={true}
        >
          {/* <Loader active inline="centered" size={size} /> */}
        </Visibility>
      );
    }

    return (
      <div className='d-flex flex-column li-cover-image'>
        <div
          className={`position-relative ${boxShadow ? "li-box-shadow" : ""}`}
        >
          <img {...decoratedProps} src={imgSrc} />
          {isVariant === "true" ||
          (typeof isVariant === "boolean" && !!isVariant) ? (
            <div
              className={`position-absolute fs-10px lh-16 fort-bold-italic li-overlay`}
              style={varTagWidth !== false ? { width: varTagWidth + "px" } : {}}
            >
              {isSmallThumbNail ? `VAR` : `VARIANT`}
            </div>
          ) : null}
        </div>
        {variantCount && variantCount > 0 ? (
          <div
            className={` fort-bold-italic li-tag ${isAgGrid ? "ag-grid" : ""} ${
              isThumbNail ? `fs-11px lh-13px` : `fs-12px lh-14px mt-0`
            }`}
          >
            {variantCount} {isThumbNail ? `VAR` : `VARIANTS AVAILABLE`}
          </div>
        ) : null}
      </div>
    );
  }
}
