import { PureComponent } from "react";
import { withRouter } from "react-router";
//import axios from "axios";
import withApp from "../components/hc/withApp";
import withCart from "../components/hc/withCart";
import withDirty from "../shared-react-components/components/hc/withDirty";
import ToastGenerator from "../shared-react-components/utils/ToastGenerator";
import AccountContext from "./AccountContext";

//import './login.scss';

// touch 0110
class AccountProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      priorStep: 1,
      verified: false,
      popUpHasBeenOpenTooLong: false,
      passwordsMatch: null,
      createPasswordValid: 1,
      confirmPasswordValid: 1,
      passwordMatchErrorMessage: "",
      permissionsAccepted: 0,
      openPasswordValidationPopUp: false,
      passwordValidationScore: 0,
      resetPasswordValidationScore: 9,
      showValidationErrors: false,
      loading: false,
      responseModal: false,
      systemResponse: "",
      stepSucceeded: null,
      showCreateSubSuccess: false,
      createSubMessage: false,
      createSubResponse: "",
      updateMethod: "",
      updateResponse: "default",
      createResponse: "",
      systemResponse: "System unavailable, please try again later.",
      validations: {
        firstName: null,
        lastName: null,
        sapAcct: null,
      },
      createUserForm: {
        san: "",
        sapAcct: "",
        sapSAN: "",
        firstName: "",
        lastName: "",
        oldPassword: "",
        createPassword: "",
        confirmPassword: "",
        role: "",
        token: null,
        email: "",
        mobile: "",
        authorizedAdministrator: false,
        authorizedAdministratorRequired: true,
        userName: "",
        password: "",
        displayName: "",
        description: "",
        legacyPassword: "",
        userStatus: "",
        requiredFields: [],
        requiredPasswordValidationScore: 7,
        isDisabled: true,
        recaptchaToken: "",
      },
      createUserPost: {
        san: "",
        sapAcct: "",
        sapSAN: "",
        groups: { biz: "" },
        firstName: "",
        lastName: "",
        fullName: "",
        userName: "",
        password: "",
        email: "",
        mobile: "",
      },
      updateUser: {},
      userPostData: {},
      userList: [],
      addresses: [],
      sans: [],
      customer: {},
      shipTosDropDown: [],
      shipTos: [],
      userListLoading: true,
      manageUserModal: { open: false, header: "", text: null },
      account: {
        accountNo: "",
        sanNo: "",
      },
      userFormSetup: {
        header: "Forgot Password?",
        text: "",
        buttonText: "",
      },
      dropDowns: {
        role: "",
        sapAcct: "",
      },
      roleOptions: [
        {
          key: "1",
          value: "W1",
          text: "Level 1: Shipper / Receiver ",
          content: (
            <div>
              <div className="fort-book fs-12px">
                Level 1: Shipper / Receiver
              </div>
              <div className="fort-book fs-10px">
                Access to view and track orders, view invoices, and submit
                claims. Permissions limited to a specific store.
              </div>
            </div>
          ),
        },
        {
          key: "2",
          value: "W2",
          text: "Level 2:  Buyer",
          content: (
            <div>
              <div className="fort-book fs-12px">Level 2: Buyer</div>
              <div className="fort-book fs-10px">
                Access to place, view, and track orders, view invoices, and
                submit claims. Permission to access all store accounts
              </div>
            </div>
          ),
        },
        {
          key: "3",
          value: "W3",
          text: "Level 3: Buyer / Co-op",
          content: (
            <div>
              <div className="fort-book fs-12px">Level 3: Buyer / Co-op</div>
              <div className="fort-book fs-10px">
                Access to place, view, and track orders, view invoices, submit
                claims and Co-op advertising. Permission to access all store
                accounts
              </div>
            </div>
          ),
        },
        {
          key: "4",
          value: "W9",
          text: "Level 4: Administrator",
          content: (
            <div>
              <div className="fort-book fs-12px">Level 4: Administrator</div>
              <div className="fort-book fs-10px">
                Access to place, view, and track orders, view invoices, submit
                claims, Co-op advertising, and user management. Permission to
                access all store accounts
              </div>
            </div>
          ),
        },
      ],
    };
  }

  handleChangeNoValidations = (e) => {
    this.setState({
      systemResponse: "",
      createUserForm: {
        ...this.state.createUserForm,
        [e.target.name]:
          e.target.name === "authorizedAdministrator"
            ? e.target.checked
            : e.target.value,
      },
    });
  };

  handleSanSapAcctChange = (e) => {
    this.setState({
      systemResponse: "",
      createUserForm: {
        ...this.state.createUserForm,
        [e.target.name]: e.target.value.trim(),
      },
    });
  };

  /*
  handleChange = (e) => {
    this.setState({
      systemResponse: '',
      createUserForm: {
        ...this.state.createUserForm,
        [e.target.name]:
          e.target.name === 'authorizedAdministrator'
            ? e.target.checked
            : e.target.value.trim(),
      },
    });
    const { createUserForm } = this.state;
    this.checkPassword(
      createUserForm.createPassword,
      createUserForm.firstName,
      createUserForm.lastName
    );
    if (e.target.name === 'createPassword') {
      if (
        e.target.value !== '' &&
        this.checkCreatePassword() <
          createUserForm.requiredPasswordValidationScore
      ) {
        this.setState({ openPasswordValidationPopUp: true });
      } else {
        this.setState({ openPasswordValidationPopUp: false });
      }
    }
    if (
      e.target.name === 'confirmPassword' ||
      e.target.name === 'createPassword'
    ) {
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({ passwordsMatch: 0 });
      } else {
        this.setState({ passwordsMatch: 1 });
      }
    }
    if (e.target.name === 'createPassword') {
      if (createUserForm.confirmPassword !== e.target.value) {
        this.setState({ passwordsMatch: 0 });
      } else {
        this.setState({ passwordsMatch: 1 });
      }
    }
    if (e.target.name === 'confirmPassword') {
      if (createUserForm.createPassword !== e.target.value) {
        this.setState({ passwordsMatch: 0 });
      } else {
        this.setState({ passwordsMatch: 1 });
      }
    }
  };*/

  debounce = (func, time) => {
    //console.log('debounce');
    var time = time || 5000; // 100 by default if no param
    var timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, time, event);
      //console.log('timer', timer);
    };
  };

  handleChange = (e) => {
    this.setState({
      systemResponse: "",
      createUserForm: {
        ...this.state.createUserForm,
        [e.target.name]:
          e.target.name === "authorizedAdministrator"
            ? e.target.checked
            : e.target.value.trim(),
      },
    });
    const { createUserForm } = this.state;
    //console.log('handle change', createUserForm);
    this.setState({ popUpHasBeenOpenTooLong: false });
    let timer;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      this.setState({
        popUpHasBeenOpenTooLong: true,
      });
    }, 8000);
    this.checkPassword(
      createUserForm.createPassword,
      createUserForm.firstName,
      createUserForm.lastName
    );
    //console.log('createUserForm.createPassword', createUserForm.createPassword);
    if (e.target.name === "createPassword") {
      if (
        e.target.value !== "" &&
        this.checkCreatePassword() <
          createUserForm.requiredPasswordValidationScore
      ) {
        this.setState({ openPasswordValidationPopUp: true });
      }
    }
    if (
      e.target.name === "confirmPassword" ||
      e.target.name === "createPassword"
    ) {
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({ passwordsMatch: 0 });
      } else {
        this.setState({ passwordsMatch: 1 });
      }
    }
    if (e.target.name === "createPassword") {
      if (createUserForm.confirmPassword !== e.target.value) {
        this.setState({ passwordsMatch: 0 });
      } else {
        this.setState({ passwordsMatch: 1 });
      }
    }
    if (e.target.name === "confirmPassword") {
      if (createUserForm.createPassword !== e.target.value) {
        this.setState({ passwordsMatch: 0 });
      } else {
        this.setState({ passwordsMatch: 1 });
      }
    }
  };

  passwordValidationOnFocus = (e) => {
    /*
    const { createUserForm } = this.state;
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({
          passwordsMatch: 0,
          passwordMatchErrorMessage: 'passwords must match',
        });
      } else {
        this.setState({ passwordsMatch: 1, passwordMatchErrorMessage: '' });
      }
    */

    this.passwordsValidations();
  };

  handleBlur = (e) => {
    const { createUserForm } = this.state;
    /*
    const { createUserForm } = this.state;
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({
          passwordsMatch: 0,
          passwordMatchErrorMessage: 'passwords must match',
        });
      } else {
        this.setState({ passwordsMatch: 1, passwordMatchErrorMessage: '' });
      }
    */
    if (e.target.name === "createPassword") {
      if (
        e.target.value !== "" &&
        this.checkCreatePassword() <
          createUserForm.requiredPasswordValidationScore
      ) {
        this.setState({ openPasswordValidationPopUp: true });
      } else {
        this.setState({ openPasswordValidationPopUp: false });
      }
    }
    this.passwordsValidations();
  };

  passwordsValidations() {
    const { createUserForm } = this.state;
    if (
      createUserForm.createPassword !== "" &&
      this.checkCreatePassword() <
        createUserForm.requiredPasswordValidationScore
    ) {
      this.setState({ openPasswordValidationPopUp: true });
    } else {
      this.setState({ openPasswordValidationPopUp: false });
    }
    if (createUserForm.createPassword !== createUserForm.confirmPassword) {
      this.setState({ passwordsMatch: 0 });
    } else {
      this.setState({ passwordsMatch: 1 });
    }
  }

  handleChangePublic = (e) => {
    this.setState({
      systemResponse: "",
      createUserForm: {
        ...this.state.createUserForm,
        [e.target.name]:
          e.target.name === "authorizedAdministrator"
            ? e.target.checked
            : e.target.value,
      },
    });
    const { createUserForm } = this.state;
    this.checkPassword(
      createUserForm.createPassword,
      createUserForm.firstName,
      createUserForm.lastName
    );
    if (e.target.name === "createPassword") {
      if (
        createUserForm.createPassword !== "" &&
        this.checkCreatePassword() < 6
      ) {
        this.setState({ openPasswordValidationPopUp: true });
      } else {
        this.setState({ openPasswordValidationPopUp: false });
      }
    }
    if (
      e.target.name === "confirmPassword" ||
      e.target.name === "createPassword"
    ) {
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({ passwordsMatch: 0 });
      } else {
        this.setState({ passwordsMatch: 1 });
      }
    }
    this.forceUpdate();
  };

  handleChangeManageUsers = (e) => {
    this.setState(
      {
        systemResponse: "",
        createUserForm: {
          ...this.state.createUserForm,
          [e.target.name]: e.target.value,
          isDisabled: this.checkValidations(),
        },
      },
      () => {}
    );
  };

  setStateField = (key, value = null) => {
    if (typeof key === "string") {
      this.setState({
        createUserForm: { ...this.state.createUserForm, [key]: value },
      });
    } else if (typeof key === "object" && key !== null) {
      const newCreateUserForm = { ...this.state.createUserForm };
      for (const [k, v] of Object.entries(key)) {
        newCreateUserForm[k] = v;
      }
      this.setState({ createUserForm: newCreateUserForm });
    }
  };
  /*
  clearStateField = () => {
    const createUserForm = {
      san: '',
      sapAcct: '',
      sapSAN: '',
      firstName: '',
      lastName: '',
      oldPassword: '',
      createPassword: '',
      confirmPassword: '',
      role: '',
      token: null,
      email: '',
      mobile: '',
      authorizedAdministrator: false,
      userName: '',
      password: '',
      displayName: '',
      description: '',
      legacyPassword: '',
      userStatus: '',
      requiredFields: [],
      requiredPasswordValidationScore: 7,
      isDisabled: true,
      recaptchaToken: '',
      openPasswordValidationPopUp: false,
    };
    this.setState({ 
      createUserForm,  
      openPasswordValidationPopUp: false,
      passwordsMatch: null
    });
    this.forceUpdate();
  };
*/

  clearStateField = () => {
    this.setState({
      createUserForm: {
        san: "",
        sapAcct: "",
        sapSAN: "",
        firstName: "",
        lastName: "",
        oldPassword: "",
        createPassword: "",
        confirmPassword: "",
        role: "",
        token: null,
        email: "",
        mobile: "",
        authorizedAdministrator: false,
        userName: "",
        password: "",
        displayName: "",
        description: "",
        legacyPassword: "",
        userStatus: "",
        requiredFields: [],
        requiredPasswordValidationScore: 7,
        isDisabled: true,
        recaptchaToken: "",
        openPasswordValidationPopUp: false,
      },
      stepFailed: false,
    });
  };

  clearErrorFields = () => {
    this.setState({
      ...this.state,
      stepFailed: false,
    });
  };

  resetUserState = () => {
    const createUserForm = {
      san: "",
      sapAcct: "",
      sapSAN: "",
      firstName: "",
      lastName: "",
      currentPassword: "",
      createPassword: "",
      confirmPassword: "",
      role: "",
      token: null,
      email: "",
      mobile: "",
      authorizedAdministrator: false,
      userName: "",
      password: "",
      displayName: "",
      description: "",
      legacyPassword: "",
      userStatus: "",
      requiredFields: [],
      requiredPasswordValidationScore: 7,
      isDisabled: true,
      recaptchaToken: "",
    };
    this.setState({ createUserForm });
  };

  handleResetPasswordChange = (e) => {
    this.setState({
      systemResponse: "",
      createUserForm: {
        ...this.state.createUserForm,
        [e.target.name]: e.target.value,
      },
    });
    const { createUserForm } = this.state;
    this.checkPassword(
      createUserForm.createPassword,
      createUserForm.firstName,
      createUserForm.lastName
    );
    const testme = this.checkCreatePassword();
    if (e.target.name === "createPassword") {
      if (
        createUserForm.createPassword !== "" &&
        this.checkCreatePassword() < 8
      ) {
        this.setState({ openPasswordValidationPopUp: true });
      } else {
        this.setState({ openPasswordValidationPopUp: false });
      }
    }
    if (e.target.name === "confirmPassword") {
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({ passwordsMatch: 0 });
      } else {
        this.setState({ passwordsMatch: 1 });
      }
    }
  };

  handleSubAccountChange = (e, data) => {
    if (this.state.manageUserModal.header === "Add User" || "Edit User") {
      this.setState({
        createUserForm: {
          ...this.state.createUserForm,
          [data.name]: data.value,
          isDisabled: this.checkValidations(),
        },
      });
    } else {
      this.setState({
        createUserForm: {
          ...this.state.createUserForm,
          [data.name]: data.value,
        },
      });
    }
  };

  handleSubAccountChangeDropDown = (e, data) => {
    const { roleOptions, shipTosDropDown } = this.state;
    let textUpdate;
    if (data.name === "role") {
      textUpdate = roleOptions.filter((option) => {
        return option.value === data.value;
      });
    }
    if (data.name === "sapAcct") {
      textUpdate = shipTosDropDown.filter((option) => {
        return option.key === data.value;
      });
    }
    this.setState({
      dropDowns: {
        ...this.state.dropDowns,
        [data.name]: textUpdate.content,
      },
      createUserForm: {
        ...this.state.createUserForm,
        [data.name]: data.value,
        isDisabled: this.checkValidations(),
      },
    });
  };

  handleManageUsersChange = (name, value) => {
    this.setState({
      createUserForm: {
        ...this.state.createUserForm,
        [name]: value,
      },
    });
  };

  handleOnClickDropDowns = (id, text) => {
    if (id === "role") {
      this.setState({
        createUserForm: {
          ...this.state.createUserForm,
          role: "",
        },
        dropDowns: {
          ...this.state.dropDowns,
          role: text,
          sapAcct: "",
        },
      });
    } else {
      this.setState({
        createUserForm: {
          ...this.state.createUserForm,
          sapAcct: "",
        },
        dropDowns: {
          ...this.state.dropDowns,
          sapAcct: text,
        },
      });
    }
  };

  /*
  handleBlur = (e) => {
    const { createUserForm } = this.state;
    if (e.target.name === 'confirmPassword') {
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({
          passwordsMatch: 0,
          passwordMatchErrorMessage: 'passwords must match',
        });
      } else {
        this.setState({ passwordsMatch: 1, passwordMatchErrorMessage: '' });
      }
    }
    if (e.target.name === 'createPassword') {
      if (
        createUserForm.currentPassword !== '' &&
        createUserForm.createPassword.indexOf(createUserForm.currentPassword) !== -1
      ) {
        this.setState({ openPasswordValidationPopUp: true });
      } else {
        this.setState({ openPasswordValidationPopUp: false });
      }
      
    }
  };
  */
  /*
 handleBlur = (e) => {
  const { createUserForm } = this.state;
    if (createUserForm.createPassword !== createUserForm.confirmPassword) {
      this.setState({
        passwordsMatch: 0,
        passwordMatchErrorMessage: 'passwords must match',
      });
    } else {
      this.setState({ passwordsMatch: 1, passwordMatchErrorMessage: '' });
    }
};
*/
  handleClickCreatePassword = (e) => {
    const { createUserForm } = this.state;
    this.checkPassword(createUserForm.createPassword);
    if (this.checkCreatePassword() < 7) {
      this.setState({ openPasswordValidationPopUp: true });
    }
  };

  newHandleClickCreatePassword = (e) => {
    const { createUserForm } = this.state;
    this.checkPassword(createUserForm.createPassword);
    if (this.checkCreatePassword() < 7) {
      this.setState({ openPasswordValidationPopUp: true });
    }
  };

  handleClickConfirmPassword = (e) => {
    this.setState({ passwordMatchErrorMessage: "" });
  };

  editControl = (status, header, id, text) => {
    const manageUserModal = { ...this.state.manageUserModal };
    const textFormatted = text ? (
      <>
        <div className={"modal-text"}>{text}</div>
      </>
    ) : null;
    if (id) {
      const { userList } = this.state;
      const user = userList.find((user) => user.email === id);
      this.loadAccountUser(user);
    } else {
      this.loadAccountUser(null);
    }
    manageUserModal.open = status;
    manageUserModal.header = header;
    manageUserModal.text = textFormatted;
    this.setState({ manageUserModal });
  };

  manageUserModal = (status, header) => {
    header = typeof header !== "undefined" ? header : "";
    this.setState({ manageUserModal: { open: status, header: header } });
  };

  handleSubmitUser = (value, recaptchaToken = null) => {
    console.log("account provider, handle submit user", recaptchaToken);
    const passwordValidationRequiredScore =
      this.state.createUserForm.requiredPasswordValidationScore;
    this.checkPassword();
    const { passwordValidationScore, createUserForm, passwordsMatch } =
      this.state;
    if (
      passwordValidationScore === passwordValidationRequiredScore &&
      passwordsMatch === 1 &&
      (createUserForm.authorizedAdministrator === true ||
        createUserForm.authorizedAdministratorRequired === false)
    ) {
      if (value === "migrate") {
        this.setCreateLegacyUserPost();
      } else if (value === "subaccount") {
        this.setUpdateSubUserPatch(recaptchaToken);
      } else {
        this.setCreateUserPost(recaptchaToken);
      }
    } else {
      if (passwordValidationScore !== passwordValidationRequiredScore) {
        this.setState({
          openPasswordValidationPopUp: true,
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (passwordsMatch === 0) {
        this.setState({
          showValidationErrors: true,
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (createUserForm.authorizedAdministrator === false) {
        this.setState({ showValidationErrors: true });
      }
    }
  };

  followLink = (value) => {
    this.setState({
      userFormSetup: { ...this.state.userFormSetup, header: value },
    });
  };

  loadAccountUser = (user) => {
    this.setState({
      createUserForm: {
        sapAcct: user === null ? "" : user.accountNo,
        firstName: user === null ? "" : user.firstName,
        lastName: user === null ? "" : user.lastName,
        email: user === null ? "" : user.email,
        mobile: user === null ? "" : user.phone,
        role: user === null ? "" : user.roleNo,
        userStatus: user === null ? "" : user.userStatus,
        guid: user === null ? "" : user.guid ? user.guid.toLowerCase() : "",
        isDisabled: user === null ? true : false,
      },
    });
  };

  loadAccountUserExternal = (userId) => {
    const createUserForm = { ...this.state.createUserForm };
    const { userList } = this.state;
    const user = userList.find((user) => user.email === userId);
    this.setState({
      createUserForm: {
        sapAcct: user === null ? "" : user.accountNo,
        firstName: user === null ? "" : user.firstName,
        lastName: user === null ? "" : user.lastName,
        email: user === null ? "" : user.email,
        mobile: user === null ? "" : user.phone,
        role: user === null ? "" : user.roleNo,
        userStatus: user === null ? "" : user.userStatus,
        guid: user === null ? "" : user.guid ? user.guid.toLowerCase() : "",
      },
    });
    if (user === null) {
      createUserForm.isDisabled = true;
    } else {
      createUserForm.isDisabled = false;
    }
  };

  setValidationRequirements = (fields, requiredValidationScore) => {
    const createUserForm = { ...this.state.createUserForm };
    createUserForm.requiredFields.push(fields);
    createUserForm.requiredPasswordValidationScore = requiredValidationScore;
    this.setState({ createUserForm });
  };

  handleSubmitSubAccountUser = (account) => {
    this.setCreateSubUserPost(account);
  };

  handleUpdateUser = (recaptchaToken = null) => {
    this.checkPassword();
    const { passwordValidationScore, createUserForm, passwordsMatch } =
      this.state;
    if (
      passwordValidationScore === 8 &&
      createUserForm.authorizedAdministrator === true
    ) {
      this.setUpdateSubUserPatch(recaptchaToken);
    } else {
      if (
        passwordValidationScore !==
        this.state.createUserForm.requiredPasswordValidationScore
      ) {
        this.setState({
          openPasswordValidationPopUp: true,
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (passwordsMatch === 0) {
        this.setState({
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (createUserForm.authorizedAdministrator === false) {
        this.setState({ showValidationErrors: true });
      }
    }
  };

  dismissCreateSubMessage = () => {
    this.setState({ responseModal: false });
  };

  setCreateUserPost = (recaptchaToken = null) => {
    this.setState((state) => {
      return {
        createUserPost: {
          recaptchaToken: recaptchaToken,
          san: state.createUserForm.san,
          sapSAN: state.createUserForm.san,
          sapAcct: state.createUserForm.sapAcct,
          token: state.createUserForm.token,
          firstName: state.createUserForm.firstName,
          lastName: state.createUserForm.lastName,
          fullName:
            state.createUserForm.firstName +
            " " +
            state.createUserForm.lastName,
          userName: state.createUserForm.email,
          email: state.createUserForm.email,
          mobile: state.createUserForm.mobile,
          password: state.createUserForm.createPassword,
        },
      };
    });
    this.registerUser(recaptchaToken);
  };

  deleteSubUser = () => {
    const { createUserForm } = this.state;

    if (createUserForm.userStatus === "pending") {
      this.updateUser(null, "/user/selfreg/" + createUserForm.email, "delete");
    } else {
      this.updateUser(null, "/user/" + createUserForm.email, "delete");
    }
  };

  resetPasswordAdmin = (email, loggedIn = false) => {
    let passwordValidationRequiredScore;
    if (loggedIn === false) {
      passwordValidationRequiredScore = 8;
    } else {
      passwordValidationRequiredScore = 7;
    }
    this.checkPassword();
    const { passwordValidationScore, createUserForm, passwordsMatch } =
      this.state;
    if (
      this.checkCreatePassword() ===
      createUserForm.requiredPasswordValidationScore
    ) {
      const userPostData = {
        email: email,
        password: createUserForm.confirmPassword,
        oldPassword: createUserForm.oldPassword,
      };
      const apiPath = "/user/" + email;
      const method = "patch";
      this.updateUserWithToast(userPostData, apiPath, method, "changePassword");
    } else {
      if (
        this.checkCreatePassword() !==
        createUserForm.requiredPasswordValidationScore
      ) {
        this.setState({
          openPasswordValidationPopUp: true,
          createPasswordValid: 0,
          confirmPasswordValid: 0,
          showValidationErrors: true,
        });
      }
      if (passwordsMatch === 0) {
        this.setState({
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (createUserForm.authorizedAdministrator === false) {
        this.setState({ showValidationErrors: true });
      }
    }
  };

  resetPasswordPublic = (recaptchaToken) => {
    const passwordValidationRequiredScore =
      this.state.createUserForm.requiredPasswordValidationScore;
    this.resetCheckPassword();
    const { passwordValidationScore, createUserForm, passwordsMatch } =
      this.state;
    //console.log('passwordValidationScore', passwordValidationScore, recaptchaToken);
    if (passwordValidationScore === 6) {
      const userPostData = {
        password: createUserForm.confirmPassword,
        token: {
          token: createUserForm.token,
        },
        recaptchaToken,
      };
      const apiPath = "/registration/";
      const method = "patch";
      this.genericUpdate(userPostData, apiPath, method, recaptchaToken);
    } else {
      if (passwordValidationScore !== passwordValidationRequiredScore) {
        this.setState({
          openPasswordValidationPopUp: true,
          createPasswordValid: 0,
          confirmPasswordValid: 0,
          showValidationErrors: true,
        });
      }
      if (passwordsMatch === 0) {
        this.setState({
          createPasswordValid: 0,
          confirmPasswordValid: 0,
          showValidationErrors: true,
        });
      }
      if (createUserForm.authorizedAdministrator === false) {
        this.setState({ showValidationErrors: true });
      }
    }
  };

  oldResetPasswordPublic = () => {
    const { createUserForm, passwordValidationScore, passwordsMatch } =
      this.state;
    if (passwordValidationScore === 7) {
      const userPostData = {
        password: createUserForm.confirmPassword,
        token: {
          token: createUserForm.token,
        },
      };
      const apiPath = "/registration/";
      const method = "patch";
      this.genericUpdate(userPostData, apiPath, method);
    } else {
      if (passwordValidationScore !== 7) {
        this.setState({
          openPasswordValidationPopUp: true,
          createPasswordValid: 0,
          confirmPasswordValid: 0,
          showValidationErrors: true,
        });
      }
      if (passwordsMatch === 0) {
        this.setState({
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (createUserForm.authorizedAdministrator === false) {
        this.setState({ showValidationErrors: true });
      }
    }
  };

  setCreateSubUserPost = (acct) => {
    const { account, createUserForm, manageUserModal, customer } = this.state;
    let method;
    let apiPath;
    let accountNo;
    let sanNo;
    let password;
    let userPostData;

    if (createUserForm.sapAcct === "") {
      accountNo = customer.accountNo;
    } else {
      accountNo = createUserForm.sapAcct;
    }

    if (accountNo !== account.accountNo) {
      sanNo = null;
    } else {
      sanNo = account.sanNo;
    }

    if (createUserForm.userStatus === "pending") {
      apiPath = "/user/selfreg/" + createUserForm.email;
      method = "patch";
    } else {
      apiPath = "/user/" + createUserForm.guid;
      method = "patch";
    }

    if (manageUserModal.header === "Add User") {
      password = "T3mpP4ss!";
      apiPath = "/user/";
      method = "post";
      userPostData = {
        user: {
          san: this.state.sans[accountNo],
          sapSAN: this.state.sans[accountNo],
          sapAcct: accountNo,
          email: createUserForm.email.trim(),
          firstName: createUserForm.firstName.trim(),
          lastName: createUserForm.lastName.trim(),
          fullName:
            createUserForm.firstName.trim() +
            " " +
            createUserForm.lastName.trim(),
          userName: createUserForm.email.trim(),
          groups: { biz: createUserForm.role },
          zipcode: createUserForm.zipcode,
          mobile: createUserForm.mobile,
        },
        password: password,
      };
    } else {
      password = "";
      method = "patch";
      userPostData = {
        email: createUserForm.email.trim(),
        firstName: createUserForm.firstName.trim(),
        lastName: createUserForm.lastName.trim(),
        fullName:
          createUserForm.firstName.trim() +
          " " +
          createUserForm.lastName.trim(),
        userName: createUserForm.email.trim(),
        mobile: createUserForm.mobile,
      };

      if (this.props.cart.customerInfo.currentUser.roleNo === "W9") {
        userPostData.groups = { biz: createUserForm.role };
        userPostData.sapAcct = accountNo;
      }
    }
    this.setState((state) => {
      return {
        createUserPost: {
          san: this.state.account.sanNo,
          sapSAN: this.state.account.sanNo,
          sapAcct: accountNo,
          token: state.createUserForm.token,
          firstName: state.createUserForm.firstName.trim(),
          lastName: state.createUserForm.lastName.trim(),
          groups: { biz: state.createUserForm.role },
          fullName:
            state.createUserForm.firstName.trim() +
            " " +
            state.createUserForm.lastName.trim(),
          userName: state.createUserForm.email.trim(),
          email: state.createUserForm.email.trim(),
          mobile: state.createUserForm.mobile,
          password: password.trim(),
        },
        userPostData: {
          user: {
            san: state.account.sanNo,
            sapSAN: state.account.sanNo,
            sapAcct: accountNo,
            email: state.createUserForm.email.trim(),
            firstName: state.createUserForm.firstName.trim(),
            lastName: state.createUserForm.lastName.trim(),
            fullName:
              state.createUserForm.firstName.trim() +
              " " +
              state.createUserForm.lastName.trim(),
            userName: state.createUserForm.email.trim(),
            groups: { biz: state.createUserForm.role },
            zipcode: state.createUserForm.zipcode,
            mobile: state.createUserForm.mobile,
          },
          password: password.trim(),
        },
      };
    });
    this.updateUser(userPostData, apiPath, method);
  };

  setCreateLegacyUserPost = () => {
    this.setState({
      createUserPost: {
        token: this.state.createUserForm.token,
        firstName: this.state.createUserForm.firstName,
        lastName: this.state.createUserForm.lastName,
        fullName:
          this.state.createUserForm.firstName +
          " " +
          this.state.createUserForm.lastName,
        userName: this.state.createUserForm.email,
        email: this.state.createUserForm.email,
        mobile: this.state.createUserForm.mobile,
        password: this.state.createUserForm.createPassword,
      },
    });
    this.postUser("migrate");
  };

  setUpdateSubUserPatch = (recaptchaToken = null) => {
    const { createUserForm } = this.state;
    const updateUser = {
      firstName: createUserForm.firstName,
      lastName: createUserForm.lastName,
      fullName: createUserForm.firstName + createUserForm.lastName,
      password: createUserForm.createPassword,
      token: {
        token: createUserForm.token,
      },
    };
    this.registerSubAccount(
      updateUser,
      "/registration/",
      "patch",
      recaptchaToken
    );
  };

  nextStepHandler = () => {
    const { step } = this.state;
    this.setState({ step: step + 1, priorStep: step });
  };

  previousStepHandler = () => {
    this.setState({
      step: this.state.priorStep,
      priorStep: this.state.priorStep - 1,
    });
  };

  setStepHandler = (e) => {
    this.setState({ priorStep: this.state.step, step: e });
  };

  setUpdateResponseHandler = (value) => {
    this.setState({ updateResponse: value });
  };

  postUser = (value) => {
    const { createUserPost, createUserForm } = this.state;
    const userPostData = {
      user: {
        email: createUserPost.email,
        firstName: createUserPost.firstName,
        lastName: createUserPost.lastName,
        fullName: createUserPost.fullName,
        userName: createUserPost.userName,
        zipcode: createUserPost.zipcode,
        mobile: createUserPost.mobile,
      },
      password: createUserPost.password,
      legacyToken: createUserPost.token,
    };
    this.setState({ loading: true });
    //console.log('post user', userPostData);
    this.props.app.registrationApi
      .createUser(userPostData, createUserForm.recaptchaToken)
      .then((userReturn) => {
        this.setState({ loading: false });
        if (userReturn.data.status === "System_Error") {
          this.setState({
            systemResponse: "System unavailable, please try again later.",
            stepFailed: true,
          });
        } else if (userReturn.data.status === "OK") {
          this.setState({
            step: 4,
            stepFailed: false,
            userReturn: userReturn.data.data,
            createResponse: "created",
          });
        } else if (userReturn.data.status === "CONFLICT") {
          if (userReturn.data.message.includes("selfreg")) {
            this.setState({
              step: 4,
              stepFailed: false,
              createResponse: "selfRegistered",
            });
          } else {
            this.setState({
              step: 4,
              stepFailed: false,
              createResponse: "registered",
            });
          }
        } else {
          this.setState({
            systemResponse:
              "Registration failed. Please try again or contact customer service",
            stepFailed: true,
          });
        }
      });
  };

  registerUser = (recaptchaToken = null) => {
    const { createUserForm } = this.state;
    const userPostData = {
      /*
      user: {
        email: createUserPost.email,
        firstName: createUserPost.firstName,
        lastName: createUserPost.lastName,
        fullName: createUserPost.fullName,
        userName: createUserPost.userName,
        groups: { biz: 'W9' },
        sapAcct: createUserPost.sapAcct,
        sapSAN: createUserPost.san,
        pid: '1234',
        san: null,
        zipcode: createUserPost.zipcode,
        mobile: createUserPost.mobile,
      },
      password: createUserPost.password,
      */

      user: {
        email: createUserForm.email,
        firstName: createUserForm.firstName,
        lastName: createUserForm.lastName,
        fullName: createUserForm.firstName + " " + createUserForm.lastName,
        userName: createUserForm.email,
        groups: { biz: "W9" },
        sapAcct: createUserForm.sapAcct,
        sapSAN: createUserForm.san,
        pid: "1234",
        san: createUserForm.san,
        zipcode: createUserForm.zipcode,
        mobile: createUserForm.mobile,
      },
      password: createUserForm.createPassword,
    };

    this.setState({ loading: true });
    //console.log('account provider, register user', recaptchaToken);
    //console.log('account provider, register user postdata', userPostData)
    this.props.app.registrationApi
      .createUser(userPostData, recaptchaToken)
      .then((userReturn) => {
        if (userReturn.data.status === "System_Error") {
          this.setState({
            loading: false,
            stepFailed: true,
            systemResponse: "System unavailable, please try again later.",
          });
        } else if (userReturn.data.status === "OK") {
          this.setState({
            loading: false,
            step: 4,
            stepFailed: false,
            userReturn: userReturn.data.data,
            createResponse: "created",
          });
        } else if (userReturn.data.status === "CONFLICT") {
          if (this.readResponse(userReturn.data.message, "selfreg")) {
            this.setState({
              loading: false,
              step: 4,
              stepFailed: false,
              createResponse: "self_registered",
            });
          } else {
            this.setState({
              loading: false,
              step: 4,
              stepFailed: false,
              createResponse: "registered",
            });
          }
        } else {
          this.setState({
            loading: false,
            stepFailed: true,
            systemResponse:
              "Registration failed. Please try again or contact customer service",
          });
        }
      });
  };

  async postSubAccountUser() {
    const { createUserPost } = this.state;
    const userPostData = {
      user: {
        email: createUserPost.email,
        firstName: createUserPost.firstName,
        lastName: createUserPost.lastName,
        fullName: createUserPost.fullName,
        userName: createUserPost.userName,
        groups: createUserPost.groups,
        sapAcct: createUserPost.sapAcct,
        sapSAN: createUserPost.sapSAN,
        pid: "1234",
        san: createUserPost.sapSAN,
        zipcode: createUserPost.zipcode,
        mobile: createUserPost.mobile,
      },
      password: createUserPost.password,
    };
    this.props.app.registrationApi
      .createSubUser(userPostData)
      .then((userReturn) => {
        if (userReturn.data.status === "System_Error") {
          this.setState({
            responseModal: true,
            stepFailed: true,
            systemResponse: "System unavailable, please try again later.",
          });
        } else if (userReturn.data.status === "OK") {
          this.setState({
            responseModal: true,
            step: 4,
            stepFailed: false,
            userReturn: userReturn.data.data,
            createSubMessage: true,
            createResponse: "created",
          });
          this.getUsers(this.props.cart.cart.accountNo);
        } else if (userReturn.data.status === "CONFLICT") {
          if (userReturn.data.message.includes("selfreg")) {
            this.setState({
              responseModal: true,
              step: 4,
              stepFailed: false,
              createSubMessage: true,
              createResponse: "selfRegistered",
            });
          } else {
            this.setState({
              responseModal: true,
              step: 4,
              stepFailed: false,
              createSubMessage: true,
              createResponse: "registered",
            });
          }
        } else {
          this.setState({
            responseModal: true,
            stepFailed: true,
            systemResponse:
              "Registration failed. Please try again or contact customer service",
          });
        }
      });
  }

  updateUser = (data, apiPath, method, updateAction = null) => {
    const { manageUserModal } = this.state;
    let updateMessage;
    this.props.dirtyHandler.setLoadingTakeoverConfig({
      text: "Updating user . . .",
      longLoadText: "Still updating user . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    this.props.dirtyHandler.setLoadingTakeover(true);
    this.setState({
      manageUserModal: { ...this.state.manageUserModal, open: false },
      updateMethod: method,
    });
    this.props.app.registrationApi
      .updateAccount(data, apiPath, method)
      .then((userReturn) => {
        this.props.dirtyHandler.setLoadingTakeover(false);
        this.setState({
          loading: false,
        });
        if (userReturn.data.status === "System_Error") {
          this.setState({
            loading: false,
            responseModal: true,
            stepFailed: true,
            systemResponse: "System unavailable, please try again later.",
          });
        } else if (userReturn.data.status === "OK") {
          this.setState({
            loading: false,
            responseModal: true,
            step: 4,
            stepFailed: false,
            userReturn: userReturn.data.data,
            createSubMessage: true,
            updateResponse: "success",
            createResponse: "created",
          });
          if (
            manageUserModal.header === "Add User" ||
            manageUserModal.header === "Edit User" ||
            manageUserModal.header === "Delete User"
          ) {
            this.getUsers(this.props.cart.cart.accountNo);
          }
          updateMessage = "Account has been updated";
        } else if (userReturn.data.status === "CONFLICT") {
          if (userReturn.data.message.includes("selfreg")) {
            this.setState({
              loading: false,
              responseModal: true,
              createSubMessage: true,
              step: 4,
              stepFailed: false,
              createResponse: "self_registered",
              updateResponse: "selfRegistered",
            });
            updateMessage = "Unable to update account";
          } else if (userReturn.data.status === "FORBIDDEN") {
            this.setState({
              loading: false,
              responseModal: true,
              createSubMessage: true,
              step: 4,
              stepFailed: false,
              createResponse: "self_registered",
              updateResponse: "selfRegistered",
            });
            updateMessage = "Unable to update account";
          } else {
            this.setState({
              loading: false,
              responseModal: true,
              createSubMessage: true,
              step: 4,
              stepFailed: false,
              createResponse: "registered",
              updateResponse: "registered",
            });
            updateMessage = "Unable to update account";
          }
        } else {
          this.setState({
            loading: false,
            responseModal: true,
            stepFailed: true,
            systemResponse:
              "Registration failed. Please try again or contact customer service",
            createResponse: "failure",
            updateResponse: "failure",
          });
        }
        if (updateAction === "update_account") {
          ToastGenerator(
            {
              text: updateMessage,
              history,
            },
            { className: "ap-toast" }
          );
        }
        this.props.dirtyHandler.setLoadingTakeover(false);
      });
  };

  updateUserWithToast = (data, apiPath, method, updateType = null) => {
    const { manageUserModal } = this.state;
    let updateMessage;
    this.props.dirtyHandler.setLoadingTakeoverConfig({
      text: "Updating user . . .",
      longLoadText: "Still updating user . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    this.props.dirtyHandler.setLoadingTakeover(true);
    this.props.app.registrationApi
      .updateAccount(data, apiPath, method)
      .then((userReturn) => {
        this.props.dirtyHandler.setLoadingTakeover(false);
        this.setState({
          loading: false,
        });
        if (userReturn.data.status === "System_Error") {
          updateMessage = "Account has been updated";
        } else if (userReturn.data.status === "OK") {
          updateMessage = "Account has been updated";
          if (updateType === "changePassword") {
            this.props.history.push("/logout");
            /*
            this.props.app.accountApi.logout().then((e) => {
              window.location.href = process.env.REACT_APP_NETIQ_LOGOUT_URL;
          });*/
          }
        } else if (userReturn.data.status === "CONFLICT") {
          updateMessage = "Unable to update account";
        } else if (userReturn.data.status === "FORBIDDEN") {
          updateMessage = userReturn.data.message;
        } else {
          updateMessage = "Unable to update account";
        }
        ToastGenerator(
          {
            text: updateMessage,
            history,
          },
          { className: "ap-toast" }
        );
        this.props.dirtyHandler.setLoadingTakeover(false);
      });
  };

  registerSubAccount = (data, apiPath, method, recaptchaToken) => {
    this.props.dirtyHandler.setLoadingTakeoverConfig({
      text: "Updating user . . .",
      longLoadText: "Still updating user . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    this.props.dirtyHandler.setLoadingTakeover(true);

    this.setState({
      manageUserModal: { ...this.state.manageUserModal, open: false },
      updateMethod: method,
      loading: true,
    });
    this.props.app.registrationApi
      .updateAccount(data, apiPath, method, recaptchaToken)
      .then((userReturn) => {
        if (userReturn.data.status === "System_Error") {
          this.setState({
            loading: false,
            responseModal: true,
            stepFailed: true,
            systemResponse: "System unavailable, please try again later.",
          });
        } else if (userReturn.data.status === "OK") {
          this.setState({
            loading: false,
            responseModal: true,
            step: 4,
            stepFailed: false,
            userReturn: userReturn.data.data,
            createSubMessage: true,
            updateResponse: "success",
            createResponse: "created",
          });
        } else if (userReturn.data.status === "CONFLICT") {
          if (userReturn.data.message.includes("selfreg")) {
            this.setState({
              loading: false,
              responseModal: true,
              createSubMessage: true,
              step: 4,
              stepFailed: false,
              createResponse: "self_registered",
              updateResponse: "selfRegistered",
            });
          } else {
            this.setState({
              loading: false,
              responseModal: true,
              createSubMessage: true,
              step: 4,
              stepFailed: false,
              createResponse: "registered",
              updateResponse: "registered",
            });
          }
        } else {
          this.setState({
            loading: false,
            responseModal: true,
            stepFailed: true,
            systemResponse:
              "Registration failed. Please try again or contact customer service",
            createResponse: "failure",
            updateResponse: "failure",
          });
        }
        this.props.dirtyHandler.setLoadingTakeover(false);

        this.props.history.push("/register-subaccount");
      });
  };

  getLegacyUser = () => {
    const { createUserForm } = this.state;
    const postData = {
      userName: createUserForm.userName,
      password: createUserForm.legacyPassword,
    };
    this.setState({ loading: true });
    this.props.app.registrationApi
      .getLegacyUser(postData, createUserForm.recaptchaToken)
      .then((response) => {
        this.setState({ loading: false });
        if (response.data.status === "System_Error") {
          this.setState({
            systemResponse: "System unavailable, please try again later.",
          });
        } else if (response.data.status === "OK") {
          if (
            response.data.data.migrated &&
            response.data.data.migrated === true
          ) {
            this.setState({
              systemResponse: `The account for ${
                createUserForm.userName ? createUserForm.userName : ""
              } has already been updated.`,
            });
          } else {
            this.setState({
              step: 2,
              createUserForm: {
                ...this.state.createUserForm,
                token: response.data.data.token,
                description: response.data.data.description
                  ? response.data.data.description
                  : "",
                displayName: response.data.data.displayName
                  ? response.data.data.displayName
                  : "",
                migrated: response.data.data.migrated
                  ? response.data.data.migrated
                  : false,
              },
            });
          }
        } else if (response.data.status === "NOT_FOUND") {
          this.setState({
            step: 1,
            systemResponse:
              "User name and password combination not found. Please try again or contact Customer Service.",
          });
        } else {
          this.setState({
            step: 1,
            systemResponse: response.data.message,
          });
        }
      });
  };

  cleanForm = () => {
    this.setState({ systemResponse: "" });
  };

  checkCreatePassword() {
    const { createUserForm } = this.state;
    let validationScore = 0;
    if (
      !(
        createUserForm.createPassword.length < 6 ||
        createUserForm.createPassword.length > 24
      )
    ) {
      validationScore = validationScore + 1;
    }
    if (/[a-zA-Z]/.test(createUserForm.createPassword.charAt(0))) {
      validationScore = validationScore + 1;
    }
    if (/[0-9]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[A-Z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[a-z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (!/[\"\\\^\&]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (
      !(
        createUserForm.createPassword
          .toUpperCase()
          .indexOf(createUserForm.firstName.toUpperCase()) >= 0 &&
        createUserForm.createPassword
          .toUpperCase()
          .indexOf(createUserForm.lastName.toUpperCase()) >= 0
      )
    ) {
      validationScore = validationScore + 1;
    }
    if (
      createUserForm.oldPassword !== "" &&
      !createUserForm.createPassword.includes(createUserForm.oldPassword)
      //createUserForm.createPassword.indexOf(createUserForm.oldPassword) === -1
    ) {
      validationScore = validationScore + 1;
    }
    return validationScore;
  }

  checkPassword() {
    const { createUserForm } = this.state;
    let validationScore = 0;
    if (
      !(
        createUserForm?.createPassword?.length < 6 ||
        createUserForm?.createPassword?.length > 24
      )
    ) {
      validationScore = validationScore + 1;
    }
    if (/[a-zA-Z]/.test(createUserForm.createPassword?.charAt(0))) {
      validationScore = validationScore + 1;
    }
    if (/[0-9]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[A-Z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[a-z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (!/[\"\\\^\&]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (
      !(
        createUserForm?.createPassword
          ?.toUpperCase()
          .indexOf(createUserForm.firstName.toUpperCase()) >= 0 &&
        createUserForm.createPassword
          ?.toUpperCase()
          .indexOf(createUserForm.lastName.toUpperCase()) >= 0
      )
    ) {
      validationScore = validationScore + 1;
    }
    if (
      createUserForm.oldPassword !== "" &&
      //createUserForm.createPassword === createUserForm.oldPassword
      !createUserForm.createPassword?.includes(createUserForm.oldPassword)
    ) {
      validationScore = validationScore + 1;
    }
    this.setState({ passwordValidationScore: validationScore });
    return validationScore;
  }

  resetCheckPassword() {
    const { createUserForm } = this.state;
    let validationScore = 0;
    if (
      !(
        createUserForm.createPassword.length < 6 ||
        createUserForm.createPassword.length > 24
      )
    ) {
      validationScore = validationScore + 1;
    }
    if (/[a-zA-Z]/.test(createUserForm.createPassword.charAt(0))) {
      validationScore = validationScore + 1;
    }
    if (/[0-9]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[A-Z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[a-z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (!/[\"\\\^\&]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (createUserForm.createPassword === createUserForm.confirmPassword) {
      validationScore = validationScore + 1;
    }
    this.setState({ passwordValidationScore: validationScore });
    return validationScore;
  }

  newCheckCreatePassword() {
    const { createUserForm } = this.state;
    let validationScore = 0;
    if (
      !(
        createUserForm.createPassword.length < 6 ||
        createUserForm.createPassword.length > 24
      )
    ) {
      validationScore = validationScore + 1;
    }
    if (/[a-zA-Z]/.test(createUserForm.createPassword.charAt(0))) {
      validationScore = validationScore + 1;
    }
    if (/[0-9]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[A-Z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[a-z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (!/[\"\\\^\&]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    return validationScore;
  }

  newCheckPassword() {
    const { createUserForm } = this.state;
    let validationScore = 0;
    if (
      !(
        createUserForm.createPassword.length < 6 ||
        createUserForm.createPassword.length > 24
      )
    ) {
      validationScore = validationScore + 1;
    }
    if (/[a-zA-Z]/.test(createUserForm.createPassword.charAt(0))) {
      validationScore = validationScore + 1;
    }
    if (/[0-9]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[A-Z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[a-z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (!/[\"\\\^\&]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    return validationScore;
  }
  /*
  resendVerifyEmail = () => {
    const { createUserForm } = this.state;
    this.props.app.registrationApi
      .resendVerifyEmail(createUserForm.email)
      .then((response) => {
        if (response.data.status === 'OK') {
          if (response.data.message === 'resend limit reached') {
            this.setState({ createResponse: 'resent-limit-reached' });
          } else {
            this.setState({ createResponse: 'resent' });
          }
        } else {
          this.setState({ createResponse: 'resent-failed' });
        }
      });
  };
*/

  resendSubVerifyEmail = (email) => {
    let resendMessage = "";
    this.props.app.registrationApi
      .resendVerifyEmail(email, true)
      .then((response) => {
        if (response.data.status === "OK") {
          if (response.data.message === "resend limit reached") {
            resendMessage =
              "Resend email limit reached. Please try again later.";
            this.setState({ createResponse: "resent-limit-reached" });
          } else {
            resendMessage = `Verification email resent to ${email}`;
            this.setState({ createResponse: "resent" });
          }
        } else {
          resendMessage = `Failed to resend to ${email}`;
          this.setState({ createResponse: "resent-failed" });
        }
        ToastGenerator(
          {
            toastIcon:
              response.data.status === "OK" ? null : "exclamation circle",
            text: resendMessage,
          },
          { className: "ap-toast" }
        );
      });
  };

  verifyEmailSubAccount = (token, apiCall, method) => {
    this.setState({ loading: true, verified: true });
    this.props.app.registrationApi
      .updateAccount({ token: token }, apiCall, method)
      .then((response) => {
        this.setState({
          loading: false,
        });
        if (response.data.status === "OK") {
          this.setState({
            createUserForm: {
              ...this.state.createUserForm,
              firstName: response.data.data.firstName,
              lastName: response.data.data.lastName,
              fullName: response.data.data.fullName,
              token: token,
            },
            updateResponse: "self_reg_found",
            verified: true,
          });
          this.props.history.push({
            pathname: "/update-subaccount",
            state: {
              createUserForm: {
                firstName: response.data.data.firstName,
                lastName: response.data.data.lastName,
                token: token,
              },
            },
          });
        } else if (response.data.status === "NOT_FOUND") {
          this.setState({
            updateResponse: "VERIFY_NOT_FOUND",
            verified: false,
            step: 3,
          });
          this.props.history.push("/update-subaccount");
        } else {
          this.setState({
            step: 3,
            updateResponse: "FAILED",
            verified: false,
            systemResponse: response.data.message,
            showValidationErrors: true,
          });
        }
      });
  };

  resendRecoverEmail = (token, apiCall, method) => {
    const { createUserForm } = this.state;
    //console.log('createUserForm.recaptchaToken', createUserForm.recaptchaToken);
    this.setState({ loading: true, verified: true });
    this.props.app.registrationApi
      .updateAccount({ email: createUserForm.email }, apiCall, method, token)
      .then((response) => {
        this.setState({
          loading: false,
          updateResponse: response.data.status ? response.data.status : null,
        });
      });
  };
  /*
  resendInvitationEmail = (data, apiCall, method) => {
    const { createUserForm } = this.state;
    this.setState({ loading: true, verified: true });
    this.props.app.registrationApi
      .updateAccount(
        { email: createUserForm.email },
        apiCall,
        method
        //createUserForm.recaptchaToken
      )
      .then((response) => {
        this.setState({
          loading: false,
          updateResponse: response.data.status ? response.data.status : null,
        });
      });
  };
*/
  resendInvitationEmail = (recaptcha) => {
    //console.log('account, resendInvitationEmail');
    const { createUserForm } = this.state;
    this.props.app.registrationApi
      .resendVerifyEmail(createUserForm.email, false, recaptcha)
      .then((response) => {
        this.setState({
          loading: false,
          updateResponse: response.data.status ? response.data.status : null,
        });
      });
  };

  genericUpdate = (data, apiCall, method, recaptchaToken) => {
    const { createUserForm } = this.state;
    this.setState({ loading: true, verified: true });
    this.props.app.registrationApi
      .updateAccount(
        data,
        apiCall + createUserForm.email,
        method,
        recaptchaToken
      )
      .then((response) => {
        if (response.data.status === "OK") {
          this.setState({
            loading: false,
            updateResponse: "OK",
          });
        } else {
          this.setState({
            loading: false,
            updateResponse: "NOT_FOUND",
          });
        }
      });
  };

  sendResetPasswordLink = () => {
    const { createUserForm } = this.state;
    this.props.app.registrationApi
      .sendResetPasswordLink(createUserForm.email)
      .then((response) => {
        this.setState({ createResponse: "resent" });
      });
  };

  emptyFunc = () => {
    return true;
  };

  setLegacyUser = (description, displayName, token) => {
    this.setState({
      step: 2,
      createUserForm: {
        ...this.state.createUserForm,
        description: description ? description : "",
        displayName: displayName ? displayName : "",
        token: token ? token : "",
      },
    });
  };

  async getUserByID(data, apiCall, method) {
    this.props.app.registrationApi
      .updateAccount(data, apiCall, method)
      .then((response) => {});
  }

  async getUsers(accountNo) {
    //console.log('account provider, get users');
    this.setState({
      account: { ...this.state.account, accountNo: accountNo },
      userListLoading: true,
    });
    this.props.app.accountApi.getUserInfo(accountNo).then((response) => {
      if (response.data.status === "System_Error") {
        this.setState({
          userListLoading: false,
          systemResponse: "System unavailable, please try again later.",
        });
      } else if (response.data.status === "OK") {
        let customer = response.data.data.customer;
        let addresses = [];
        let sans = [];
        /*
        addresses[response.data.data.customer.accountNo] =
          response.data.data.customer.address;
        */
        // const shipTosDefaultDropDown = {
        //   key: accountNo,
        //   value: accountNo,
        //   text: accountNo,
        // };
        response.data.data.shipTos.forEach((shipTo, index) => {
          addresses[shipTo.accountNo] = shipTo.address;
        });
        response.data.data.shipTos.forEach((shipTo, index) => {
          sans[shipTo.accountNo] = shipTo.sanNo;
        });
        let shipTosDropDown = response.data.data.shipTos.map((shipTo) => ({
          key: shipTo.accountNo,
          value: shipTo.accountNo,
          // label: `${shipTo.address.name1}\n`,
          text: (
            <div>
              <div className="fort-book fs-12px">
                {this.props.cart.customerInfo.customer.accountNo ===
                shipTo.accountNo ? (
                  <span>Primary Billing: </span>
                ) : null}
                {shipTo.address.name1 ? shipTo.address.name1 : ""} -{" "}
                {shipTo.accountNo}{" "}
              </div>
              <div className="fort-book fs-10px">
                {shipTo.address.street ? shipTo.address.street : ""}
                {", "}
                {shipTo.address.city ? shipTo.address.city : ""} {", "}
                {shipTo.address.state ? shipTo.address.state : ""}
                {"  "}
                {shipTo.address.postalCode ? shipTo.address.postalCode : ""}
              </div>
            </div>
          ),
          // text: `${shipTo.address.name1} - ${shipTo.accountNo}`,
          // text: `${shipTo.address.name1 ? (shipTo.address.name1):''} ${shipTo.address.name2 ? (shipTo.address.name2):''}
          // ${shipTo.address.city ? (shipTo.address.city):''}, ${shipTo.address.state ? (shipTo.address.state):''}
          // ${shipTo.address.zip ? (shipTo.address.postalCode):''}`,
          content: (
            <div>
              <div>
                {this.props.cart.customerInfo.customer.accountNo ===
                shipTo.accountNo ? (
                  <span>Primary Billing: </span>
                ) : null}
                {shipTo.address.name1 ? shipTo.address.name1 : ""} -{" "}
                {shipTo.accountNo}{" "}
              </div>
              <div>
                {shipTo.address.street ? shipTo.address.street : ""}
                {", "}
                {shipTo.address.city ? shipTo.address.city : ""} {", "}
                {shipTo.address.state ? shipTo.address.state : ""}
                {"  "}
                {shipTo.address.postalCode ? shipTo.address.postalCode : ""}
              </div>
            </div>
          ),
        }));

        let shipToUsers = [];
        response.data.data.shipTos.forEach((shipTo) => {
          if (shipTo.webUsers && shipTo.webUsers.length > 0) {
            shipTo.webUsers.forEach((webUser) => {
              shipToUsers.push(webUser);
            });
          }
        });
        let userList = shipToUsers.sort((a, b) =>
          a.roleNo > b.roleNo ? 1 : -1
        );
        this.setState({
          userListLoading: false,
          customer,
          shipTosDropDown,
          userList,
          addresses,
          sans,
          account: {
            ...this.state.account,
            sanNo: response.data.data.customer.sanNo,
          },
        });
      } else {
        this.setState({ userListLoading: false });
      }
    });
  }

  readResponse = (response, test) => {
    let responseTest = false;
    if (response && response.indexOf(test) !== -1) {
      responseTest = true;
    }
    return responseTest;
  };

  validateSAPAccount = (recaptchaToken) => {
    const { createUserForm } = this.state;
    this.setState({
      loading: true,
      createResponse: "",
      systemResponse: "",
    });
    //console.log('validateSAPAccount provider', createUserForm);
    this.props.app.registrationApi
      .validateSAPAccount(
        createUserForm.san,
        createUserForm.sapAcct,
        recaptchaToken
      )
      .then((validation) => {
        this.setState({ loading: false });
        if (validation.data.status === "System_Error") {
          this.setState({
            systemResponse: "System unavailable, please try again later.",
            validations: { ...this.state.validations, sapAcct: false },
          });
        } else if (validation.data.status === "OK") {
          this.setState({
            step: 2,
            validations: { ...this.state.validations, sapAcct: true },
          });
        } else {
          if (this.readResponse(validation.data.data.message, "Admin")) {
            this.setState({
              stepFailed: false,
              createResponse: "admin_exists",
            });
          } else {
            if (this.readResponse(validation.data.data.message, "active")) {
              this.setState({
                step: 1,
                systemResponse: `The account for ${createUserForm.sapAcct} is no longer active. Please register for a new account or contact Customer Service.`,
                validations: { ...this.state.validations, sapAcct: false },
              });
            } else {
              this.setState({
                step: 1,
                systemResponse:
                  "Unable to verify account. Please contact customer service.",
                validations: { ...this.state.validations, sapAcct: false },
              });
            }
          }
        }
      });
  };

  checkValidations = () => {
    const { createUserForm } = this.state;
    let validRequiredScore;
    let validScore = 0;
    if (createUserForm.firstName !== "") {
      validScore = validScore + 1;
    }
    if (createUserForm.lastName !== "") {
      validScore = validScore + 1;
    }
    if (createUserForm.email !== "") {
      validScore = validScore + 1;
    }
    if (createUserForm.role !== "") {
      validScore = validScore + 1;
    }
    if (createUserForm.role !== "" && createUserForm.sapAcct !== "") {
      validScore = validScore + 1;
    }
    if (createUserForm.role === "W1") {
      validRequiredScore = 5;
    } else {
      validRequiredScore = 4;
    }
    if (validScore >= validRequiredScore) {
      return false;
      // this.setState({
      //   createUserForm: { ...this.state.createUserForm, isDisabled: false },
      // });
    } else {
      return true;
      // this.setState({
      //   createUserForm: { ...this.state.createUserForm, isDisabled: true },
      // });
    }
  };

  render() {
    return (
      <AccountContext.Provider
        value={{
          formState: this.state,
          setLegacyUser: (description, displayName, token) => {
            return this.setLegacyUser(description, displayName, token);
          },
          resendVerifyEmail: (value) => {
            return this.resendVerifyEmail(value);
          },
          previousStepHandler: (value) => {
            return this.previousStepHandler(value);
          },
          handleSubmitUser: (value, recaptchaToken) => {
            return this.handleSubmitUser(value, recaptchaToken);
          },
          setStepHandler: (value) => {
            return this.setStepHandler(value);
          },
          getLegacyUser: () => {
            return this.getLegacyUser();
          },
          handleSubmitSubAccountUser: (value) => {
            return this.handleSubmitSubAccountUser(value);
          },
          handleChange: (e) => {
            return this.handleChange(e);
          },
          handleSanSapAcctChange: (e) => {
            return this.handleSanSapAcctChange(e);
          },
          handleChangeNoValidations: (e) => {
            return this.handleChangeNoValidations(e);
          },
          handleClickCreatePassword: (e) => {
            return this.handleClickCreatePassword(e);
          },
          newHandleClickCreatePassword: (e) => {
            return this.newHandleClickCreatePassword(e);
          },
          handleClickConfirmPassword: (e) => {
            return this.handleClickConfirmPassword(e);
          },
          handleSubAccountChange: (e, data) => {
            return this.handleSubAccountChange(e, data);
          },
          handleSubAccountChangeDropDown: (e, data) => {
            return this.handleSubAccountChangeDropDown(e, data);
          },
          dismissCreateSubMessage: (e) => {
            return this.dismissCreateSubMessage(e);
          },
          resendSubVerifyEmail: (e) => {
            return this.resendSubVerifyEmail(e);
          },
          handleManageUsersChange: (e) => {
            return this.handleManageUsersChange(e);
          },
          loadAccountUser: (e) => {
            return this.loadAccountUser(e);
          },
          loadAccountUserExternal: (e) => {
            return this.loadAccountUserExternal(e);
          },
          verifyEmailSubAccount: (token, apiCall, method) => {
            return this.verifyEmailSubAccount(token, apiCall, method);
          },
          resendRecoverEmail: (token, apiCall, method) => {
            return this.resendRecoverEmail(token, apiCall, method);
          },
          resendInvitationEmail: (recaptcha) => {
            return this.resendInvitationEmail(recaptcha);
          },
          editControl: (status, header, userID, text) => {
            return this.editControl(status, header, userID, text);
          },
          getUsers: (e) => {
            return this.getUsers(e);
          },
          manageUserModal: (status, header) => {
            return this.manageUserModal(status, header);
          },
          deleteSubUser: (e) => {
            return this.deleteSubUser(e);
          },
          updatePassword: (e) => {
            return this.updatePassword(e);
          },
          setValidationRequirements: (fields, value) => {
            return this.setValidationRequirements(fields, value);
          },
          handleResetPasswordChange: (e) => {
            return this.handleResetPasswordChange(e);
          },
          followLink: (e) => {
            return this.followLink(e);
          },
          setUpdateResponseHandler: (e) => {
            return this.setUpdateResponseHandler(e);
          },
          setStateField: (name, value) => {
            return this.setStateField(name, value);
          },
          resetPasswordPublic: (e) => {
            return this.resetPasswordPublic(e);
          },
          validateSAPAccount: (recaptchaToken) => {
            return this.validateSAPAccount(recaptchaToken);
          },
          handleChangeManageUsers: (e) => {
            return this.handleChangeManageUsers(e);
          },
          clearStateField: () => {
            return this.clearStateField();
          },
          clearErrorFields: () => {
            return this.clearErrorFields();
          },
          handleChangePublic: (e) => {
            return this.handleChangePublic(e);
          },
          resetUserState: (e) => {
            return this.resetUserState(e);
          },
          handleBlur: (e) => {
            return this.handleBlur(e);
          },
          resetPasswordAdmin: (e) => {
            return this.resetPasswordAdmin(e);
          },
          handleOnClickDropDowns: (id, text) => {
            return this.handleOnClickDropDowns(id, text);
          },
          checkPassword: () => {
            return this.checkPassword();
          },
        }}
      >
        {this.props.children}
      </AccountContext.Provider>
    );
  }
}
//export default withRouter(withSecurity(withApp(withCart(AccountProvider))));
export default withDirty(withRouter(withApp(withCart(AccountProvider))));
