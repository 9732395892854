import { useEffect, useState } from "react";
import "./AdditionalDetails.scss";
import { AdditionalDetailsBar } from "./AdditionalDetailsBar";
import { Container, Dropdown, Icon } from "semantic-ui-react";
import LazyImage from "../../utils/LazyImage";
import { SeriesHistory } from "../../components/series/SeriesHistory";
import useCart from "../../components/hc/useCart";

const SeriesHistoryTab = (props) => {
  const {
    seriesOrderHistory,
    selectedLocation,
    setSelectedLocation,
    locationDropdownItems,
  } = props;
  const cart = useCart();
  // console.log("SeriesHistoryTab", props);
  function sendToBlank(newPage){
    localStorage.setItem(
      "shipTo",
      selectedLocation
    );
      window.open(newPage);
  }

  useEffect(() => {
    if (cart.isUserPow()) {
      if (cart.shipTo >= 0) {
        setSelectedLocation(cart.getShipToAccount().accountNo);
      }
    }
  }, [cart.getShipToAccount().accountNo]);

  // let shipTo = null;
  // if (cart.isUserPow()) {
  //   if (cart.shipTo >= 0) {
  //     shipTo = cart.getShipToAccount().accountNo;
  //   }
  // }

  // let options = [
  //   {
  //     key: 'all',
  //     value: 'all',
  //     header: <div>All locations</div>,
  //     text: <div>All locations'</div>,
  //     content: <div>All locations</div>,
  //   },
  // ];
  // cart.customerInfo.shipToAccountNos.map((f) => {
  //   // if (f.accountNo !== selectedShipTo) {
  //   // console.log('acct', f.accountNo, selectedShipTo);
  //   options.push({
  //     key: f.accountNo,
  //     value: f.accountNo,
  //     text: f.accountNo,
  //     content: <div>{f.accountNo}</div>,
  //   });
  //   // }
  // });

  // console.log('cart', selectedShipTo, options, seriesOrderHistory);

  return (
    <>
      {seriesOrderHistory?.summary?.length > 0 && (
        <div className='mb-6'>
          {!cart.isUserPow() &&
            cart.customerInfo.shipTos.length > 1 && (
              <div className='tad-oh-shipto-container mb-3'>
                <Dropdown
                  options={locationDropdownItems.filter(
                    (o) => o.value !== selectedLocation
                  )}
                  onChange={(_e, { value }) => {
                    setSelectedLocation(value);
                  }}
                  value={selectedLocation}
                  // item={true}
                  // selection
                  className={""}
                  trigger={
                    <div>
                      {selectedLocation === "all"
                        ? "All locations"
                        : selectedLocation}
                    </div>
                  }
                />
              </div>
            )}
          <SeriesHistory
            data={
              seriesOrderHistory.titles.filter(
                (oh) => oh.shipTo === selectedLocation
              ).length > 0
                ? seriesOrderHistory.titles.filter(
                    (oh) => oh.shipTo === selectedLocation
                  )
                : false
            }
            sendToBlank={sendToBlank}
            panelOpen={true}
          />
        </div>
      )}
    </>
  );
};

const AboutTab = (props) => {
  const { title } = props;
  return (
    <div className='tad-about-container'>
      <div
        className='mb-4'
        dangerouslySetInnerHTML={{ __html: title?.positioning }}
      />
      <div dangerouslySetInnerHTML={{ __html: title?.keynote }} />
      {!title?.keynote && !title?.positioning && (
        <div dangerouslySetInnerHTML={{ __html: title?.aboutTheBook }} />
      )}

      {!title?.aboutTheBook && <div>No information is available</div>}
    </div>
  );
};

const AuthorTab = (props) => {
  const { title } = props;

  let contributorData = Object.values(title?.contributors).filter(
    (contrib) => contrib.spotlight !== null || title?.authorBio !== null
  );
  // .filter(
  //   (contrib, index, self) =>
  //     self.findIndex((t) => t.id === contrib.id) === index
  // );
  contributorData = contributorData.map((contrib) => {
    if (
      contrib.roleName === "Author" &&
      contrib.spotlight === null &&
      title?.authorBio !== null
    ) {
      return { ...contrib, spotlight: title?.authorBio };
    }
    return contrib;
  });

  // console.log('contribs', title.contributors, contributorData);
  return (
    <div>
      {contributorData.map((a) => (
        <div key={a.id} className='tad-author-container'>
          {a.hasAuthorPhoto && (
            <div className='tad-author-image-container'>
              <LazyImage
                className='tad-author-image-content'
                src={a.authorPhotoUrl}
                alt={"Author"}
              ></LazyImage>
              {a.photoCredit && (
                <div className='tad-image-copyright'>
                  PHOTO <Icon color='black' disabled name='copyright outline' />
                </div>
              )}
              {a.photoCredit && (
                <div className='tad-image-copyright'>
                  {a.photoCredit.toUpperCase() + " " + (a.photoDate || "")}
                </div>
              )}
            </div>
          )}
          <div>
            <div className='tad-author-name'>{a.display}</div>
            <div
              className={`tad-author-content" ${
                a.spotlight === null && " tad-author-placeholder"
              }`}
              dangerouslySetInnerHTML={{
                __html:
                  a.spotlight || "No additional information about the author.",
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export const TitleAdditionalDetails = (props) => {
  // console.log("TitleAdditionalDetails", props);
  const {
    title,
    orderHistory,
    tabs,
    currentTab,
    setCurrentTab,
    selectedLocation,
    setSelectedLocation,
    locationDropdownItems,
  } = props;

  // useEffect(() => {
  //   setCurrentTab(tabs[0]);
  // }, [tabs]);

  // const renderAuthors = () => {
  //   // console.log('renderAuthor');
  //   return (
  //     <div>
  //       {Object.values(title.contributors).map((c, idx) => (
  //         <div key={`contrib-${c?.id}-${idx}`} className="tad-author-container">
  //           {props.hasAuthorPhoto && (
  //             <div className="tad-author-image-container">
  //               <LazyImage
  //                 className="tad-author-image-content"
  //                 src={c.authorPhotoUrl}
  //                 alt={'Author'}
  //               ></LazyImage>
  //               {c.photoCredit && (
  //                 <div className="tad-image-copyright">
  //                   PHOTO{' '}
  //                   <Icon color="black" disabled name="copyright outline" />
  //                 </div>
  //               )}
  //               {c.photoCredit && (
  //                 <div className="tad-image-copyright">
  //                   {c.photoCredit.toUpperCase() + ' ' + (c.photoDate || '')}
  //                 </div>
  //               )}
  //             </div>
  //           )}
  //           <div>
  //             <div className="tad-author-content">{c.roleName}</div>
  //             <div className="tad-author-name">{c.display}</div>

  //             <div
  //               className={`tad-author-content" ${
  //                 c.spotlight === null && ' tad-author-placeholder'
  //               }`}
  //               dangerouslySetInnerHTML={{
  //                 __html: c.spotlight || '',
  //               }}
  //             />
  //           </div>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };
  // // No additional information about the author.

  // const renderAuthors = () => {
  //   return <div>{contributorData.map(renderAuthor)}</div>;
  // };

  // console.log('TitleAdditionalDetails', props, currentTab);
  return (
    <Container id='title-detail-tab-line' className='tm-max-width'>
      <div className='tad-container'>
        <AdditionalDetailsBar
          currentTab={currentTab}
          tabs={props.tabs}
          tabChange={(value) => setCurrentTab(value)}
        />
        {currentTab.id === "about" && <AboutTab title={title} />}
        {currentTab.id === "author" && <AuthorTab title={title} />}
        {currentTab.id === "seriesOrderHistory" && (
          <SeriesHistoryTab
            seriesOrderHistory={orderHistory?.seriesHistory}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            locationDropdownItems={locationDropdownItems}
            context={'titleDetail'}
          />
        )}
      </div>
    </Container>
  );
};
