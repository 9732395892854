import {decode} from 'html-entities';
import truncate from 'truncate-html';

export const POST_POST_TYPE = 'posts';
export const NEWS_FROM_PRH_POST_TYPE = 'news-from-prh';
export const TITLE_CHANGES_POST_TYPE = 'title-changes';
export const POST_CATEGORY_TAXONOMY = 'categories';
export const NEWS_FROM_PRH_CATEGORY_TAXONOMY = 'news-from-prh-categories';
export const TITLE_CHANGES_CATEGORY_TAXONOMY = 'title-changes-categories';

const nameMapper = {
    [POST_POST_TYPE] : {
        singular: 'Title Collection',
        plural: 'Title Collections',
        useButton: true,
        singleEntryLinkText: 'View {singular}',
        hide: ['date']
    },
    [POST_CATEGORY_TAXONOMY] : {
        singular: 'Posts Category',
        plural: 'Posts Categories',
    },
    [TITLE_CHANGES_CATEGORY_TAXONOMY] : {
        singular: 'Title Changes Category',
        plural: 'Title Changes Categories',
    },
    [TITLE_CHANGES_POST_TYPE] : {
        singular: 'Title Change',
        plural: 'Title Changes',
    },
    [NEWS_FROM_PRH_CATEGORY_TAXONOMY] : {
        singular: 'News From PRH Category',
        plural: 'News From PRH Categories',
    },
    [NEWS_FROM_PRH_POST_TYPE] : {
        singular: 'News from PRH',
        plural: 'News from PRH',
    },
}

/**
 * Needed to translate post type slug, e.g., post -> posts, should just be posts
 * Because, ya know, WordPress...
 * @param {post_type} type 
 * @returns 
 */
 export const translatePostTypeSlug = (type) => {
    if(type === 'post') {
        return 'posts';
    }
    return type;
}

/**
 * Get fields to hide on single implementations
 * @param {*} what 
 * @returns 
 */
 export const getFieldsToHideOnDigests = (what) => {
    what = translatePostTypeSlug(what);
    if(!!nameMapper?.[what]?.hide){
        return nameMapper?.[what]?.hide;
    }
    return [];
    
    
}


export const getUseButtonForPost = (what) => {
    what = translatePostTypeSlug(what);
    return !!nameMapper?.[what]?.useButton;
}

export const getSingleEntryLinkText = (what) => {
    what = translatePostTypeSlug(what);
    const entryText = nameMapper?.[what]?.singleEntryLinkText;
    if(!entryText){
        return false;
    }
    return entryText.replace('{singular}', nameMapper?.[what]?.singular).replace('{plural}', nameMapper?.[what]?.plural);
    
}

export const getPrettyName = (what) => {
    return nameMapper?.[what];
}

/**
 * Get the first element of embed array and the appropriate image size if it exists, otherwise the full size
 * 
 * @param {array} WordPress embed rest object.
 * @param {*} wordpressImageSize 
 */
export const getWordPressImage = (_embedded, wordpressImageSize) => {

    if(!_embedded || (Array.isArray(_embedded) && _embedded.length === 0)){
        return null;
    }

    if (Object.prototype.hasOwnProperty.call(_embedded, wordpressImageSize)) {
        return _embedded[wordpressImageSize];
    }

    if (Object.prototype.hasOwnProperty.call(_embedded, 'wp:featuredmedia')) {
        const embed = _embedded['wp:featuredmedia'];
        const imageObject = Array.isArray(embed) ? embed[0] : embed;
        if (!!imageObject && !!imageObject.media_details) {
          let innerImageObject = imageObject.media_details.sizes?.[wordpressImageSize];
          if (!innerImageObject) {
            innerImageObject = imageObject.media_details.sizes.full;
          }
          const { source_url: image } = innerImageObject;
          return image;
        }
      }


      return null;


   
}

/**
 * Since WordPress' REST API does not provide an easy way to simulate the response for a WP post in the REST API
 * We have to handle both the native REST API and any custom REST API endpoints we use with an array of get_posts
 * @param {*} post 
 */
export function translatePost(post, wordpressImageSize = 'full'){
    // console.log('post', post)
    // coming from WordPress' native REST API
    // console.log('post?.title?.rendered', post?.title?.rendered, !!post?.title?.rendered);
    if(!!post?.title?.rendered || post?.title?.rendered==='') {
        const { rendered: title } = post.title;
        const { rendered: content } = post.content;
        let excerpt = post?.meta?.excerpt;
        const { type, date, _embedded = null, id } = post;
        const meta = {...post.meta, ...post.extra_meta};
        if(!excerpt){
            excerpt = meta.body_copy;
        }
        const image = getWordPressImage(_embedded, wordpressImageSize);
        return { meta, image, date, id, type, title: decode(title), excerpt: clampWordCount(excerpt), content }
    }
    // coming from get_post
    const {ID: id, post_title: title, post_content: content, post_excerpt: excerpt, post_type: type, post_modified: date, images} = post;
    const meta = post?.meta;
    const image = getWordPressImage(images, wordpressImageSize);
    // console.log('total return', image, date, meta, id, type, title, excerpt, content);
    return { image, date, meta, id, type, title: decode(title), excerpt: clampWordCount(excerpt), content, }    
}



function clampWordCount(markup, count = 40) {
    
    return truncate(markup, count, { byWords: true })
}