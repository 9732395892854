import { CountryRegionData } from "react-country-region-selector";

export const paymentMethods_MOCK = {
  CreditCard: {
    id: "credit_card",
    title: "Credit Card",
    description: null,
    icon: "credit card outline",
    note:
      "You will be contacted by the PRH New Accounts team and instructed on how to safely provide your Credit Card information",
  },
  Invoice: {
    id: "invoice",
    title: "Receive an Invoice",
    description: null,
    icon: "file alternate outline",
    note: "You will be required to apply for credit terms",
  },
};

export const EnrolmentStep = {
  Welcome: "welcome",
  BusinessInformation: "businessInformation",
  BillingAddress: "billingAddress",
  ShippingAddress: "shippingAddress",
  DownloadRequiredDocuments: "requiredDocuments",
  UploadRequiredDocuments: "uploadRequiredDocuments",
  PaymentMethod: "selectPaymentMethod",
  BankAccountInformation: "bankAccountInformation",
  TradeCreditReference: "tradeCreditReference",
  TermsOfSale: "termsOfSale",
  ReviewAndSign: "reviewAndSign",
  FormSubmitted: "formSubmitted",
};

export const steps = [
  { title: "Addresses" },
  { title: "Document Upload" },
  { title: "Upload filled documents" },
  { title: "Payment" },
  { title: "Review & Confirmation" },
];

export const emptyEnrolmentData = {
  [EnrolmentStep.BusinessInformation]: {
    legalBusinessName: null,
    respondentName: null,
    respondentTitle: null,
    countryOfOperation: null,
    federalEmployeeIdentificationNumber: null,
    soleProprietorship: null,
    hstGstPstQstNumber: null,
    governmentIssuedId: null,
    yearOfBusinessEstablished: null,
    lengthOfPresentOwnership: null,
    stateOfIncorporation: null,
  },
  [EnrolmentStep.BillingAddress]: {
    dba: null,
    contactPersonName: null,
    street: null,
    city: null,
    zip: null,
    state: null,
    phone: null,
    email: null,
    apContactName: null,
    apContactPhone: null,
    apContactEmail: null,
    principalOfficer1: null,
    principalOfficer2: null,
    shippingAddressIsBillingAddress: false,
    invoiceViaEmail: false,
    orderConfirmationViaEmail: false,
    shippingNotificationsViaEmail: false,
    billingAddressIsResidence: true,
    termsOfShipping: false,
    residenceType: null,
  },
  [EnrolmentStep.ShippingAddress]: {
    businessOrContactPersonName: null,
    street: null,
    city: null,
    state: null,
    zip: null,
    phone: null,
    email: null,
    shippingAddressIsResidence: true,
    termsOfShipping: false,
  },
  [EnrolmentStep.UploadRequiredDocuments]: {},
  [EnrolmentStep.PaymentMethod]: null,
  [EnrolmentStep.BankAccountInformation]: {
    name: null,
    accountNumber: null,
    address: null,
    phoneNumber: null,
    email: null,
    bankRepresentativeName: null,
    bankRepresentativePhone: null,
    bankRepresentativeEmail: null,
  },
  [EnrolmentStep.TradeCreditReference]: {
    ref1: {
      name: null,
      accountNumber: null,
      phone: null,
      email: null,
    },
    ref2: {
      name: null,
      accountNumber: null,
      phone: null,
      email: null,
    },
    ref3: {
      name: null,
      accountNumber: null,
      phone: null,
      email: null,
    },
  },
  [EnrolmentStep.TermsOfSale]: null,
  [EnrolmentStep.ReviewAndSign]: null,
};

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export const dummyEnrolmentData = {
  [EnrolmentStep.BusinessInformation]: {
    legalBusinessName: `Test Business ${getRandomInt(1000)}`,
    respondentName: "Respondent Your Name",
    respondentTitle: "Respondent Your Title",
    countryOfOperation: "US",
    federalEmployeeIdentificationNumber: null,
    soleProprietorship: "1234",
    hstGstPstQstNumber: null,
    governmentIssuedId: "1234",
    yearOfBusinessEstablished: "2000",
    lengthOfPresentOwnership: "1",
    stateOfIncorporation: "CA",
  },
  [EnrolmentStep.BillingAddress]: {
    dba: "DBA Test",
    contactPersonName: "Test Contact Person",
    street: "Test Street",
    city: "Test City",
    zip: "91824",
    state: "NH",
    phone: "+191918234512",
    email: "test@email.com",
    apContactName: "AP Name Test",
    apContactPhone: "+19191253143512",
    apContactEmail: "ap@email.com",
    principalOfficer1: "Principal Officer 1",
    principalOfficer2: "",
    shippingAddressIsBillingAddress: true,
    invoiceViaEmail: true,
    orderConfirmationViaEmail: false,
    shippingNotificationsViaEmail: true,
    billingAddressIsResidence: true,
    termsOfShipping: true,
    residenceType: "billing",
  },
  [EnrolmentStep.ShippingAddress]: {
    businessOrContactPersonName: null,
    street: null,
    city: null,
    state: null,
    zip: null,
    phone: null,
    email: null,
    shippingAddressIsResidence: true,
    termsOfShipping: false,
  },
  [EnrolmentStep.UploadRequiredDocuments]: {},
  [EnrolmentStep.PaymentMethod]: paymentMethods_MOCK.CreditCard,
  [EnrolmentStep.BankAccountInformation]: {
    name: null,
    accountNumber: null,
    address: null,
    phoneNumber: null,
    email: null,
    bankRepresentativeName: null,
    bankRepresentativePhone: null,
    bankRepresentativeEmail: null,
  },
  [EnrolmentStep.TradeCreditReference]: {
    ref1: {
      name: null,
      accountNumber: null,
      phone: null,
      email: null,
    },
    ref2: {
      name: null,
      accountNumber: null,
      phone: null,
      email: null,
    },
    ref3: {
      name: null,
      accountNumber: null,
      phone: null,
      email: null,
    },
  },
  [EnrolmentStep.TermsOfSale]: true,
  [EnrolmentStep.ReviewAndSign]: "Test Contact Person Signature",
};

export const emptyEnrolmentDataLabelsUS = {
  [EnrolmentStep.BusinessInformation]: {
    legalBusinessName: "Legal business name",
    respondentName: "Respondent Name",
    respondentTitle: "Respondent Title",
    countryOfOperation: "Country of Operation",
    federalEmployeeIdentificationNumber: "F.E.I.N.",
    soleProprietorship: "Sole Proprietorship",
    governmentIssuedId: "Government Issued ID#",
    yearOfBusinessEstablished: "Date (Year) of Business Established",
    lengthOfPresentOwnership: "Length of Present Ownership",
    stateOfIncorporation: "State of incorporation",
  },
  [EnrolmentStep.BillingAddress]: {
    dba: "D.B.A.",
    contactPersonName: "Contact Name",
    street: "Street",
    city: "City",
    zip: "ZIP",
    state: "State",
    phone: "Phone",
    email: "Email address",
    apContactName: "AP Contact Name",
    apContactPhone: "AP Contact Phone Number",
    apContactEmail: "AP Contact Email Address",
    principalOfficer1: "Principal / Officer #1 Name",
    principalOfficer2: "Principal / Officer #2 Name",
    billingAddressIsResidence: "Billing address is residence?",
  },
  [EnrolmentStep.ShippingAddress]: {
    businessOrContactPersonName: "Contact Name",
    street: "Street",
    city: "City",
    zip: "ZIP",
    state: "State",
    phone: "Phone",
    email: "Email address",
    shippingAddressIsResidence: "Shipping address is residence?",
  },
  [EnrolmentStep.BankAccountInformation]: {
    name: "Bank Name",
    accountNumber: "Bank Account Number",
    address: "Bank Address",
    phoneNumber: "Bank Phone Number",
    email: "Bank Email",
    bankRepresentativeName: "Bank Representative  Name",
    bankRepresentativePhone: "Bank Representative  Email",
    bankRepresentativeEmail: "Bank representative  phone",
  },
  [EnrolmentStep.TradeCreditReference]: {
    name: "Name",
    accountNumber: "Account Number",
    phone: "Phone",
    email: "Email",
  },
};

const addressObject = {
  dba: "D.B.A.",
  contactPersonName: "Contact Name",
  businessOrContactPersonName: "Contact Name",
  street: "Street",
  city: "City",
  zip: "ZIP",
  state: "State",
  phone: "Phone",
  email: "Email address",
  billingAddressIsResidence: "Billing address is residence?",
};

export const mapLabelsUStoCAN = () => {
  const labels = {};
  Object.keys(emptyEnrolmentDataLabelsUS).map((key) => {
    if (key === EnrolmentStep.BusinessInformation) {
      labels[key] = {
        legalBusinessName: "Legal business name",
        countryOfOperation: "Country of Operation",
        hstGstPstQstNumber: "HST/GST/PST/QST number",
        governmentIssuedId: "Government Issued ID#",
        yearOfBusinessEstablished: "Date (Year) of Business Established",
        lengthOfPresentOwnership: "Length of Present Ownership",
        stateOfIncorporation: "Province/Territory of Incorporation",
      };
    } else if (
      key === EnrolmentStep.BillingAddress ||
      key === EnrolmentStep.ShippingAddress
    ) {
      labels[key] = { ...addressObject };
      Object.keys(emptyEnrolmentDataLabelsUS[key]).map((label) => {
        if (label === "zip") {
          labels[key][label] = "Postal Code";
        } else if (label === "state") {
          labels[key][label] = "Province";
        } else {
          labels[key][label] = emptyEnrolmentDataLabelsUS[key][label];
        }
        return null;
      });
    } else {
      labels[key] = emptyEnrolmentDataLabelsUS[key];
    }
    return null;
  });
  delete labels[EnrolmentStep.ShippingAddress].dba;
  delete labels[EnrolmentStep.ShippingAddress].contactPersonName;
  delete labels[EnrolmentStep.BillingAddress].businessOrContactPersonName;
  return labels;
};

export const COUNTRY = {
  US: "US",
  Canada: "CA",
};

export const mapStateCodeToStateValue = (stateCode) => {
  let stateValue = (CountryRegionData || []).find(
    (country) => country[1] === stateCode
  );
  return stateValue && stateValue[0];
};
