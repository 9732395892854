import { useState, useEffect } from 'react';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import './BackToTopButton.scss';

export const BackToTopButton = () => {
  const [visible, setVisible] = useState(false);
  var doc = document.documentElement;
  var w = window;

  var checkScroll = function () {
    var curScroll;

    curScroll = w.scrollY || doc.scrollTop;

    toggleButton(curScroll);
  };

  var toggleButton = function (curScroll) {
    if (curScroll < 150) {
      setVisible(false);
    } else if (curScroll > 150) {
      setVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);

    return () => window.removeEventListener('scroll', checkScroll);
  }, []);

  return (
    <div className="back-to-top-button-absolute-container">
      {visible && (
        <div className="back-to-top-button-fixed-container">
          <PRHButton
            onClick={() =>
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }
            displayInlineFlex
            icon="arrow up"
            className="sidePageBtns back-to-top-button"
            size="small"
            key={Math.random()}
          />
          <div className="back-to-top-text">BACK TO TOP</div>
        </div>
      )}
    </div>
  );
};
