import { NumericFormat } from "react-number-format";
import { Icon, Label } from "semantic-ui-react";
import useCart from "../../../components/hc/useCart";
import { Link, useHistory } from "react-router-dom";
import "./cartIcon.scss";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../../utils/const";

export const CartIcon = ({
  addIsbnDialogOopen,
  nonPowIconClassName = "",
  powIconClassName = "",
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const cart = useCart();
  const history = useHistory();
  const handleOpen = () => {
    cart.setCartBadgeOpen(true);
  };

  const handleClose = () => {
    if (!addIsbnDialogOopen) {
      cart.setCartBadgeOpen(false);
    }
  };

  return (
    <>
      {cart.isUserPow() ? (
        <Link to={"/cart-overview"} className='buttonLinkStyle'>
          <div
            className={`cursor-pointer pow-checkout-icon ${powIconClassName}`}
            // onClick={(e) => {
            //   history.push({ pathname: '/cart-overview' });
            // }}
          >
            <Icon className='pow-checkout-icon' name='cart'></Icon>
            <span
              className='visually-hidden'
              style={{ color: "#000", backgroundColor: "#fff" }}
            >
              Checkout Icon
            </span>
          </div>
        </Link>
      ) : (
        <div
          className={`cursor-pointer cart-icon ml-1 ${nonPowIconClassName}`}
          onClick={() =>
            cart.cartBadgeOpen === false ? handleOpen() : handleClose()
          }
        >
          <div className='d-flex'>
            <Icon
              name='cart'
              className={
                isMobile
                  ? cart.cart.items && cart.cart.items.length > 0
                    ? ""
                    : "empty-cart"
                  : ""
              }
            />
            <Label
              circular
              color='grey'
              size='tiny'
              className='cart-label hidden-on-mobile'
            >
              <NumericFormat
                className='text-orange'
                value={cart.cart.items.length}
                thousandSeparator={true}
                displayType={"text"}
              />
            </Label>
          </div>
          <div className='dropdown-text d-flex flex-row align-items-center hidden-on-mobile'>
            My Cart{" "}
            <Icon
              className={`${cart.cartBadgeOpen ? "caret_down" : "cart_up"}`}
              name={`caret ${cart.cartBadgeOpen ? "up" : "down"}`}
            ></Icon>
          </div>
        </div>
      )}
    </>
  );
};
