import moment from 'moment';
import {
  translatePost,
  getFieldsToHideOnDigests,
} from '../../../utils/wordpressHelpers';
import WordPressPostLink from './WordPressPostLink';

const PostDigestHorizontal = ({ post, wordpressImageSize }) => {
  const { image, date, title, excerpt, type } = translatePost(
    post,
    wordpressImageSize
  );
  const fieldsToHide = getFieldsToHideOnDigests(type);
  return (
    <div className="d-flex align-items-start pb-4 border-bottom-gray-300 mb-4">
      {image && (
        <WordPressPostLink post={post}>
          <div className="mr-3">
            <img src={image} alt={title} />
          </div>
        </WordPressPostLink>
      )}
      <div>
        <WordPressPostLink
          post={post}
          className="fort-bold fs-14px lh-19px ls--015 text-gray-900 hover-orange d-inline-block mb-1"
        >
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </WordPressPostLink>
        {!fieldsToHide.includes('date') && (
          <p className="m-0 text-gray-700 fort-book fs-12px lh-16px lsp--02  mb-1">
            {moment(date).format('MMMM DD, YYYY')}
          </p>
        )}

        {excerpt && (
          <p
            className="fort-book fs-13px lh-18px lsp--02 text-gray-900 mb-1"
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        )}
        <WordPressPostLink
          className="fort-book fs-12px lh-16px text-gray-700 hover-orange "
          post={post}
          useButtonOverride={true}
        >
          Read More
        </WordPressPostLink>
      </div>
    </div>
  );
};

PostDigestHorizontal.defaultProps = {
  wordpressImageSize: '300x160',
  sourcePageTitle: null,
};

export default PostDigestHorizontal;
