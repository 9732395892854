import { createRef, Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { withRouter } from 'react-router';
//import FormContainer from '../../elements/FormContainer';
import { Link } from 'react-router-dom';
import { Icon, Modal, Popup } from 'semantic-ui-react';
import { Form } from 'semantic-ui-react-form-validator';
import {
  PRHContainer,
  PRHContainerHeader,
  PRHLabel,
} from '../../shared-react-components/elements/elements';
import TextValidator from '../../shared-react-components/elements/input';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { SizeMe } from 'react-sizeme';
import { TOOLTIPS } from '../../utils/const';
import withAccount from '../hc/withAccount';
import DialogModal from '../modals/DialogModal';
import './registration.scss';
import PageStandard from '../elements/PageStandard';

class RegistrationContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailToSubject: '',
      recaptchaToken: '',
      recaptchaRef: createRef(),
      // contactUsModalOpen: false,
    };
  }

  render() {
    // console.log('Render Form1', this.props, this.state);
    const { account } = this.props;
    return (
      <>
        <div className={'registration'}>
          <SizeMe monitorWidth={true}>
            {({ size }) => (
              <PageStandard
                jeff={true}
                alignment="center"
                cartBadge={false}
                pageType={'registration-path'}
              >
                <div className={'register-page-header fort-bold'}>
                  BUSINESS ACCOUNTS
                </div>
                <PRHContainer className="content-block-border mt-4 mb-14">
                  <PRHContainerHeader className="fw-700">
                    Contact Us
                  </PRHContainerHeader>
                  <div className="fort-book fs14-px lh19-px regcus-modal-sh">
                    Please contact us for questions about Penguin Random House
                    Self-Service or setting up a new business account:
                  </div>
                  <div className="d-flex flex-row mb-2">
                    <div className="mr-1">
                      <Icon
                        name="envelope outline"
                        className="text-gray-500"
                      ></Icon>
                    </div>
                    <div>
                      <div className="fort-bold">BIZ Site Support</div>
                      <div>
                        <a href="mailto:bizcs@penguinrandomhouse.com?subject=Request for Self-Service Registration">
                          bizcs@prh.com
                        </a>
                      </div>
                      <div>
                        Email us to confirm your Account Number or SAN, help
                        with completing registration, or password support
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row regcus-modal-end">
                    <div className="mr-1">
                      <Icon
                        name="user circle outline"
                        className="text-gray-500"
                      ></Icon>
                    </div>
                    <div>
                      <div className="fort-bold">New Accounts</div>
                      <div>
                        <a href="mailto:newaccount@penguinrandomhouse.com?subject=Request for Self-Service Registration">
                          newaccount@prh.com
                        </a>
                      </div>
                      <div>
                        Email us for help setting up a new Penguin Random House
                        business account
                      </div>
                    </div>
                  </div>
                  <div>
                    <PRHButton
                      icon="left arrow"
                      size="large"
                      onClick={(e) => this.props.history.goBack()}
                      className="mt-4 regcus-back-button"
                    >
                      Back
                    </PRHButton>
                  </div>
                </PRHContainer>
              </PageStandard>
            )}
          </SizeMe>
        </div>
      </>
    );
  }
}

export default withRouter(RegistrationContactUs);
