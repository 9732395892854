import { Component } from 'react';
import { Container, Grid, Icon, GridRow, GridColumn } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import withApp from '../../components/hc/withApp';
import withSecurity from '../../components/hc/withSecurity';
import withAccount from '../../components/hc/withAccount';
import PageStandard from '../../components/elements/PageStandard';
import LoginForm from '../../components/login/LoginForm';
import Tout1 from '../../elements/Tout1';
import {
  PH1,
  PH3,
  PRHContainerHeader,
} from '../../components/elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';

import './login.scss';
import { Column } from 'ag-grid-community';
import ContentCard from '../../components/elements/ContentCard';
import LazyImage from '../../utils/LazyImage';
import WordPressNotification from '../external/partials/WordPressNotification';
import withMobile from '../../components/hc/withMobile';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      hideHero: false,
    };
  }
  handleSubmitUser() {
    document.getElementById('loginForm').submit();
  }
  render() {
    // console.log('Home', this.props);
    const headerBoldText = 'Welcome to the new Self-Service website!';
    const headerBelowBoldText =
      "If you're a current Self-Service user and this is your first time visiting the new site, please click Update Account to sign in and update some account details. ";
    const headerButtonText = 'Update Account';
    const headerButtonAction = () => {
      this.props.history.push('/update');
    };
    const loginRightHeading =
      'Connecting your business with our vibrant catalog of ideas and stories.';
    const loginRightText =
      'A fast, reliable, and easy way to order books, track shipments, and place claims';

    const { location, mobile } = this.props;

    const isMobile = mobile.isMobile;

    const pageExtension = (
      <Grid padded stackable>
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={5} />
          <Grid.Column width={3}>
            <PRHContainerHeader>
              Sign up for a [BIZ 2] account:
            </PRHContainerHeader>
            <p>
              Register for an account to take advantage of all the [BIZ 2]
              features
            </p>
            <PRHButton
              className={'fort-light-bold biz-form-button'}
              fontAwesome={true}
              icon={faPen}
              onClick={(e) => this.props.history.push('/registration')}
            >
              Register
            </PRHButton>
            <br />
            <br />
            <PRHContainerHeader>We're here to help:</PRHContainerHeader>
            <p>
              Our Customer Service team is available to answer any questions
              regarding the PRH [BIZ 2] site
            </p>
            <ul className={'ui list'}>
              <li>Browse Help &amp; FAQ</li>
              <li>
                Email our Customer Service team
                <br />
                <a
                  className="text-blue hover-orange"
                  href="mailto:bizcs@penguinrandomhouse.com"
                >
                  bizcs@penguinrandomhouse.com
                </a>
              </li>
              <li>
                Call our Customer Service team
                <br />
                1-800-733-3000
              </li>
            </ul>
            <br />
            <br />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
    return (
      <>
        <PageStandard
          containerFluid
          jeff={true}
          headerType="notLoggedIn"
          alignMent="left"
          pageExtension={pageExtension}
          pageType={'registration-path'}
          cartBadge={false}
          className={''}
        >
          <WordPressNotification />
          {!this.state.hideHero && !mobile && (
            <div className="blue-gradient text-white">
              <Container>
                <Grid className="py-4 d-flex flex-column align-items-center">
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={8}
                      largeScreen={8}
                      widescreen={8}
                    >
                      <h2 className=" mb-2 fs-16px lh-21 fort-bold lsp-017">
                        {headerBoldText}
                      </h2>
                      <p className="fort-book lsp--017">
                        {headerBelowBoldText}
                      </p>
                    </Grid.Column>
                    {!isMobile ? (
                      <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={8}
                        largeScreen={8}
                        widescreen={8}
                        className={`align-items-center d-flex ${
                          isMobile
                            ? 'justify-content-center mt-1'
                            : 'justify-content-end'
                        }`}
                      >
                        <PRHButton
                          iconright
                          backgroundColorOverride="white"
                          icon="arrow right"
                          onClick={headerButtonAction}
                        >
                          {headerButtonText}
                        </PRHButton>{' '}
                        <Icon
                          onClick={() => this.setState({ hideHero: true })}
                          className="text-white ml-3 cursor-pointer"
                          name="times"
                        />
                      </Grid.Column>
                    ) : (
                      ''
                    )}
                  </Grid.Row>
                </Grid>
              </Container>
            </div>
          )}
          {!isMobile && (
            <Container className="login-section py-8" fluid>
              <Grid container>
                <Grid.Row>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={5}
                    largeScreen={5}
                    widescreen={5}
                  >
                    <LoginForm
                      onSubmit={this.handleSubmitUser}
                      location={location}
                    />
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={9}
                    largeScreen={9}
                    widescreen={9}
                    className=""
                  >
                    <div className="pl-7 pt-1">
                      <h1 className="fs-45px text-white fort-bold lh-50 login-shadow lsp--175">
                        {loginRightHeading}
                      </h1>
                      <p className="fort-medium text-white fs-3 lh-29">
                        {loginRightText}
                      </p>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          )}

          {isMobile && (
            <>
              <Container className="login-section py-4" fluid>
                <Grid container>
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={9}
                      largeScreen={9}
                      widescreen={9}
                      className=""
                    >
                      <div className="py-1">
                        <h1 className="fs-24px text-white fort-bold lh-26 login-shadow lsp--175">
                          {loginRightHeading}
                        </h1>
                        <p className="fort-medium text-white fs-3 lh-29">
                          {loginRightText}
                        </p>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
              <Container className="py-4" fluid>
                <Grid container>
                  <Grid.Row>
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={5}
                      largeScreen={5}
                      widescreen={5}
                    >
                      <LoginForm
                        onSubmit={this.handleSubmitUser}
                        location={location}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </>
          )}

          <Container fluid className="bg-gray-100">
            <Grid
              columns="equal"
              container
              className={`${isMobile ? 'py-4' : 'py-8'}`}
            >
              <Grid.Row
                className={`${isMobile ? 'd-flex mobile-cards-columns' : ''}`}
              >
                <Grid.Column
                  className={`keep-padding ${
                    isMobile ? 'mb-4 full-width-mobile-column' : ''
                  }`}
                >
                  <ContentCard className="py-8 px-8">
                    <LazyImage
                      className="mw-100"
                      src="/images/functionality-check.png"
                    />
                  </ContentCard>
                  <h2 className="lh-28 fs-22px fort-light mb-1 mt-3 text-gray-900 lsp--05">
                    Check Title Availability
                  </h2>
                  <p className="fs-16px fort-book text-gray-700 lsp--025 lh-21">
                    Quickly view sales status, territory rights, release dates,
                    and pricing.
                  </p>
                </Grid.Column>
                <Grid.Column
                  className={`keep-padding ${
                    isMobile ? 'mb-4 full-width-mobile-column' : ''
                  }`}
                >
                  <ContentCard className="py-8 px-8">
                    <LazyImage
                      className="mw-100"
                      src="/images/functionality-order.png"
                    />
                  </ContentCard>
                  <h2 className="lh-28 fs-22px fort-light mb-1 mt-3 text-gray-900 lsp--05">
                    Order Books
                  </h2>
                  <p className="fs-16px fort-book text-gray-700 lsp--025 lh-21">
                    Real-time confirmation, substitution, and backorder
                    information.
                  </p>
                </Grid.Column>
                <Grid.Column
                  className={`keep-padding ${
                    isMobile ? 'mb-4 full-width-mobile-column' : ''
                  }`}
                >
                  <ContentCard className="py-8 px-8">
                    <LazyImage
                      className="mw-100"
                      src="/images/functionality-shipping.png"
                    />
                  </ContentCard>
                  <h2 className="lh-28 fs-22px fort-light mb-1 mt-3 text-gray-900 lsp--05">
                    Track Shipments
                  </h2>
                  <p className="fs-16px fort-book text-gray-700 lsp--025 lh-21">
                    Access tracking numbers, estimated ship times, and delivery
                    times.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Container
            fluid
            className="border-top-gray-300 border-bottom-gray-300"
          >
            <Grid container className="py-8">
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={6}
                  largeScreen={6}
                  widescreen={6}
                  className={`keep-padding d-flex align-items-center justify-content-start ${
                    isMobile ? 'mb-4' : ''
                  }`}
                >
                  <LazyImage
                    className="mw-100"
                    src="/images/prh-color-vector.svg"
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={7}
                  largeScreen={7}
                  widescreen={7}
                  className={`keep-padding d-flex align-items-center justify-content-start ${
                    isMobile ? 'mb-4' : ''
                  }`}
                >
                  <p className="fs-22px lh-28 text-gray-900 fort-light lsp--05">
                    We believe that books, and the stories and ideas they hold,
                    have the unique capacity to connect us, change us, and carry
                    humanity forward toward a better future for generations to
                    come.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Container fluid className="bg-gray-100">
            <Grid container className="py-8">
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={2}
                  largeScreen={2}
                  widescreen={2}
                  className="keep-padding"
                />
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={12}
                  largeScreen={12}
                  widescreen={12}
                  className={`${
                    isMobile ? '' : 'keep-padding'
                  } d-flex align-items-center justify-content-start`}
                >
                  <ContentCard className={`${isMobile ? 'p-2' : 'p-4'}`}>
                    <Container fluid={isMobile}>
                      <Grid columns="equal">
                        <Grid.Row
                          className={`${
                            isMobile ? 'd-flex mobile-cards-columns' : ''
                          }`}
                        >
                          <Grid.Column
                            className={`${
                              isMobile ? 'mb-4 full-width-mobile-column' : ''
                            }`}
                          >
                            <LazyImage
                              className="mw-100"
                              src="/images/image-warehouse.jpg"
                            />
                          </Grid.Column>
                          <Grid.Column
                            className={`${
                              isMobile ? 'mb-4 full-width-mobile-column' : ''
                            }`}
                          >
                            <div className={`${isMobile ? '' : 'pl-4 pr-2'}`}>
                              <h2 className="fs-22px lh-28 text-gray-900 fort-light lsp--05">
                                Reliable Ordering and Shipping
                              </h2>
                              <p className="fort-book text-gray-700 fs-16px lh-21 lsp--025">
                                Nearly all orders shipped within 48 hours,
                                shipping to destinations worldwide. Easy
                                ordering, quick and transparent shipment
                                tracking means you can focus more on growing
                                your business.
                              </p>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Container>
                  </ContentCard>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="mt-4">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={2}
                  largeScreen={2}
                  widescreen={2}
                  className="keep-padding"
                />
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={12}
                  largeScreen={12}
                  widescreen={12}
                  className={`${
                    isMobile ? '' : 'keep-padding'
                  } d-flex align-items-center justify-content-start`}
                >
                  <ContentCard className={`${isMobile ? 'p-2' : 'p-4'}`}>
                    <Container fluid={isMobile}>
                      <Grid columns="equal">
                        <Grid.Row
                          className={`${
                            isMobile ? 'd-flex mobile-cards-columns' : ''
                          }`}
                        >
                          {isMobile && (
                            <Grid.Column className="mb-4 full-width-mobile-column">
                              <div>
                                <LazyImage
                                  className="mw-100"
                                  src="/images/image-support.jpg"
                                />
                              </div>
                            </Grid.Column>
                          )}
                          <Grid.Column
                            className={`${
                              isMobile ? 'mb-4 full-width-mobile-column' : ''
                            }`}
                          >
                            <h2 className="fs-22px lh-28 text-gray-900 fort-light lsp--05">
                              Trusted Customer Support{' '}
                            </h2>
                            <p className="fort-book text-gray-700 fs-16px lh-21 lsp--025">
                              An exceptional team of Customer Service
                              representatives, the largest and best Sales group
                              in the industry, and an efficient customer-first
                              operation are the guarantees you need to best
                              continue to serve your readers.
                            </p>
                          </Grid.Column>
                          {!isMobile && (
                            <Grid.Column>
                              <div className="pl-4 pr-2">
                                <LazyImage
                                  className="mw-100"
                                  src="/images/image-support.jpg"
                                />
                              </div>
                            </Grid.Column>
                          )}
                        </Grid.Row>
                      </Grid>
                    </Container>
                  </ContentCard>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="mt-4">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={2}
                  largeScreen={2}
                  widescreen={2}
                  className="keep-padding"
                />
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={12}
                  largeScreen={12}
                  widescreen={12}
                  className={`${
                    isMobile ? '' : 'keep-padding'
                  } d-flex align-items-center justify-content-start`}
                >
                  <ContentCard className={`${isMobile ? 'p-2' : 'p-4'}`}>
                    <Container fluid={isMobile}>
                      <Grid columns="equal">
                        <Grid.Row
                          className={`${
                            isMobile ? 'd-flex mobile-cards-columns' : ''
                          }`}
                        >
                          <Grid.Column
                            className={`${
                              isMobile ? 'mb-4 full-width-mobile-column' : ''
                            }`}
                          >
                            <LazyImage
                              className="mw-100"
                              src="/images/image-sustainability.jpg"
                            />
                          </Grid.Column>
                          <Grid.Column
                            className={`${
                              isMobile ? 'mb-4 full-width-mobile-column' : ''
                            }`}
                          >
                            <div className={`${isMobile ? '' : 'pl-4 pr-2'}`}>
                              <h2 className="fs-22px lh-28 text-gray-900 fort-light lsp--05">
                                Commitment to Sustainability{' '}
                              </h2>
                              <p className="fort-book text-gray-700 fs-16px lh-21 lsp--025">
                                Globally, PRH is on the way to sourcing 100% of
                                its paper from certified programs that ensure
                                the paper we use in our books is sourced
                                responsibly from both an environmental and
                                social standpoint. Our continual improvements in
                                sustainable efficiency will lessen your carbon
                                footprint.
                              </p>
                              <p>
                                <a
                                  className="text-blue hover-orange"
                                  href="https://social-impact.penguinrandomhouse.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Read more about our sustainability initiatives
                                  on our Social Impact website
                                </a>
                              </p>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Container>
                  </ContentCard>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Container fluid className="bg-white border-top-gray-300">
            <Grid container className="py-8">
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={2}
                  largeScreen={2}
                  widescreen={2}
                  className="keep-padding"
                />
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={12}
                  largeScreen={12}
                  widescreen={12}
                  className="keep-padding d-flex align-items-center justify-content-start flex-column"
                >
                  <h2 className="fs-22px lh-28 text-center fort-light my-0 lsp--05 text-gray-900">
                    Have questions? Feel free to reach out.
                  </h2>
                  <div className="mt-8 d-md-flex w-100">
                    <div className="fb-33 mr-1 d-flex flex-column align-items-center">
                      <div className="px-10 ">
                        <LazyImage
                          className="mw-100 mb-3"
                          src="/images/icon-question.jpg"
                        />
                      </div>
                      <p className="m-0 fort-bold fs-12px lh-15 text-uppercase lsp--02">
                        Browse help &amp; FAQ
                      </p>
                      <p className="text-center fort-book mt-1 mb-2 fs-14px lh-19 lsp--015 w-100">
                        Learn how to use the site by browsing our Help &amp; FAQ
                        resources.
                      </p>
                      <p className="fort-bold m-0 fs-14px lh-19 lsp--015">
                        <a
                          className="text-blue hover-orange"
                          href="https://selfservice-help.penguinrandomhouse.biz/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Browse Help &amp; FAQ
                        </a>
                      </p>
                    </div>
                    <div className="my-3 my-md-0 fb-33 mx-1 d-flex flex-column align-items-center">
                      <div className="px-10 ">
                        <LazyImage
                          className="mw-100 mb-3"
                          src="/images/icon-email.jpg"
                        />
                      </div>
                      <p className="m-0 fort-bold fs-12px lh-15 text-uppercase lsp--02">
                        BIZ Support
                      </p>
                      <p className="text-center fort-book mt-1 mb-2 fs-14px lh-19 lsp--015 w-100">
                        Reach out to BIZ support team at any time via the below
                        email address:
                      </p>
                      <p className="fort-bold m-0 fs-14px lh-19 lsp--015">
                        <a
                          href="mailto:bizcs@penguinrandomhouse.com"
                          className="text-blue hover-orange hover-orange"
                        >
                          bizcs@prh.com
                        </a>
                      </p>
                    </div>
                    <div className="fb-33 mx-1 d-flex flex-column align-items-center">
                      <div className="px-10 ">
                        <LazyImage
                          className="mw-100 mb-3"
                          src="/images/icon-phone.jpg"
                        />
                      </div>
                      <p className="m-0 fort-bold fs-12px lh-15 text-uppercase lsp--02">
                        Contact Customer Service
                      </p>
                      <p className="text-center fort-book mt-1 mb-2 fs-14px lh-19 lsp--015 w-100">
                        For any inquiries regarding availability, orders, or
                        accounts, you can contact Customer Service at:
                      </p>
                      <p className="fort-bold m-0 fs-14px lh-19 lsp--015">
                        <a
                          className="text-blue hover-orange"
                          href="tel:+1-800-733-3000"
                        >
                          1-800-733-3000
                        </a>
                      </p>
                      <p className="fort-bold m-0 fs-14px lh-19 lsp--015 mt-1">
                        <a
                          className="text-blue hover-orange"
                          href="mailto:customerservice@penguinrandomhouse.com"
                        >
                          customerservice@prh.com
                        </a>
                      </p>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </PageStandard>
      </>
    );
  }
}

export default withSecurity(withApp(withMobile(Login)));
