import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Container, Dropdown, Grid, Icon, Popup } from "semantic-ui-react";
import { Divider } from "../../components/elements/elements";
import PageStandard from "../../components/elements/PageStandard";
import { ErrorComponent } from "../../components/ErrorComponent.jsx";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import PageHeader from "../../components/header/PageHeader";
import DialogModal from "../../components/modals/DialogModal";
import PRHButtonStandard from "../../shared-react-components/elements/prhbutton";
import { MOBILE_SCREEN_WIDTH, TOOLTIPS } from "../../utils/const";
import { Export, ExportCSV } from "../../utils/Export";
import { numberAsCurrency, separateShipping } from "../../utils/utilities";
import LoadingView from "../loading/LoadingView";
import "./OrderDetail.scss";
import { OrderDetailDropdown } from "./OrderDetailDropdown";
import { OrderDetailDropdownRow } from "./OrderDetailDropdownRow";
import { useMediaQuery } from "react-responsive";
import { OrderDetailMobile } from "./OrderDetailMobile";
import { isMobileOnly } from "react-device-detect";
import { OrderDetailRowsPrint } from "./OrderDetailRowsPrint";
import {
  useDeleteOrderMutation,
  useLazyGetOrderDetailsQuery,
  usePatchOrderMutation,
} from "../../api/ecomApi";

// const mockData = require("./mock.json")
const isMock = false;

export const OrderDetail = withRouter(
  withSecurity(
    withApp(
      withCart((props) => {
        // const isMobile = isMobileOnly;
        const isMobile = useMediaQuery({
          query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
        });

        const [performGetOrderDetails, getOrderDetailsResult] =
          useLazyGetOrderDetailsQuery();
        const [performPatchOrder, patchOrderResult] = usePatchOrderMutation();
        const [performDeleteOrder, deleteOrderReuslt] =
          useDeleteOrderMutation();
        const [state, setstate] = useState({
          orderData: undefined,
          isLoading: false,
          isEditing: false,
          editedData: [],
          isModalOpen: false,
          lineItemsSeparated: [],
          isDropdownOpen: false,
          error: undefined,
        });
        const [openConfirmCancelModal, setOpenConfirmCancelModal] =
          useState(false);
        const [allHaveBeenCanceled, setAllHaveBeenCanceled] = useState(false);
        const [
          openAllHaveBeenCanceledAndUserClickedSaveConfirmCancelModal,
          setOpenAllHaveBeenCanceledAndUserClickedSaveConfirmCancelModal,
        ] = useState(false);

        const [showMoreDataMobile, setshowMoreDataMobile] = useState(false);

        const downloadOptions = [
          {
            key: 50,
            text: "Print",
            value: 50,
            icon: { name: "print", color: "orange" },
            onClick: () => {
              setstate({ ...state, isDropdownOpen: false });
              setTimeout(() => {
                window.print();
              }, 500);
            },
          },
          {
            key: 100,
            text: "Download Excel",
            value: 100,
            icon: { name: "file excel outline", color: "orange" },
            onClick: () => exportCart("excel"),
          },
          {
            key: 125,
            text: "Download CSV",
            value: 100,
            icon: { name: "file outline", color: "orange" },
            onClick: () => exportCart("csv"),
          },
        ];

        const exportCart = (fileType) => {
          //Export.DocumentDetails(
          const details = {
            fileName: `order-detail-${props.match.params.orderId}-${state.orderData.data.account}`,
            metadata: [
              [`Order Number`, props.match.params.orderId],
              ["Account Number", state.orderData.data.account],
              [
                "Bill To",
                `${
                  state.orderData.data.addresses["Bill-to"].name +
                  " \n" +
                  state.orderData.data.addresses["Bill-to"].street +
                  " \n" +
                  state.orderData.data.addresses["Bill-to"].city +
                  ", " +
                  state.orderData.data.addresses["Bill-to"].state +
                  " " +
                  state.orderData.data.addresses["Bill-to"].zip
                }`,
              ],
              [
                "Ship To",
                `${
                  state.orderData.data.addresses["Ship-to"].name +
                  " \n" +
                  state.orderData.data.addresses["Ship-to"].street +
                  " \n" +
                  state.orderData.data.addresses["Ship-to"].city +
                  ", " +
                  state.orderData.data.addresses["Ship-to"].state +
                  " " +
                  state.orderData.data.addresses["Ship-to"].zip
                }`,
              ],
              ["Sales Order Number", state.orderData.data.salesOrderNumber],
              ["Purchase Order Number", state.orderData.data.poNumber],
              [`Order Create Date`, state.orderData.data.createDate],
              ["Promotion Code", state.orderData.data.promoCode],
              [
                "Shipping Chgs",
                `$${numberAsCurrency(
                  Number(state.orderData.data?.shipping || 0).toFixed(2)
                )}`,
              ],
              [
                "Handling Chgs",
                `$${numberAsCurrency(
                  Number(state.orderData.data?.handling || 0).toFixed(2)
                )}`,
              ],
              [
                "Taxes",
                `$${numberAsCurrency(
                  Number(state.orderData.data?.tax || 0).toFixed(2)
                )}`,
              ],
              [
                "Total Due",
                `$${numberAsCurrency(
                  Number(state.orderData.data?.total).toFixed(2)
                )}`,
              ],
            ],
            list: state.orderData.data.lineItems
              .map((invGroup) => {
                return {
                  Line: invGroup.lineNo,
                  Description: invGroup.titleDetail.title,
                  ISBN: invGroup.isbn,
                  Warehouse: invGroup.warehouse,
                  Price: `$${numberAsCurrency(
                    Number(invGroup.coverPrice || 0)
                  )}`,
                  "Discount %": invGroup.disc,
                  "Net Value": `$${numberAsCurrency(
                    Number(invGroup.netPrice || 0)
                  )}`,
                  Qty: invGroup.quantity,
                  "Net Amount": `$${numberAsCurrency(
                    Number(invGroup.netAmt || 0)
                  )}`,
                  "Order Status": [
                    "Open not confirmed",
                    "Not Yet Published",
                    "Out of Stock Backordered",
                  ].includes(invGroup.statusMsg)
                    ? "Backordered"
                    : invGroup.statusMsg,
                };
              })
              .flat(),
          };
          if (fileType === "excel") {
            Export.DocumentDetails(details);
          } else if (fileType === "csv") {
            ExportCSV.DocumentDetails(details);
          }
        };

        const fetchData = () => {
          try {
            setstate({ ...state, isLoading: true });
            window.scrollTo(0, 0);
            performGetOrderDetails({
              orderId: props.match.params.orderId,
              sapAccountNo: props.cart.ccSapAccountNo,
            }).then((orderResponse) => {
              if (orderResponse.data?.status === "OK") {
                const openStatuses = [
                  ...new Set(
                    (!isMock
                      ? orderResponse.data.data.lineItems
                      : mockData.data.lineItems
                    )
                      .filter((item) => item.status === "1")
                      .map((item) =>
                        [
                          "Out of Stock Backordered",
                          "Not Yet Published",
                          "Open not confirmed",
                        ].includes(item.statusMsg)
                          ? "Backordered"
                          : item.statusMsg
                      )
                  ),
                ];

                // console.log('openStatuses', openStatuses);

                const openAll = openStatuses.map((status) => {
                  return {
                    status: status,
                    items: (!isMock
                      ? orderResponse.data.data.lineItems
                      : mockData.data.lineItems
                    ).filter(
                      (item) =>
                        item.status === "1" &&
                        ((status === "Backordered" &&
                          [
                            "Out of Stock Backordered",
                            "Not Yet Published",
                            "Open not confirmed",
                          ].includes(item.statusMsg)) ||
                          item.statusMsg === status)
                    ),
                    icon:
                      status === "Backordered" ? "history" : "warning circle",
                    iconColor: "orange",
                  };
                });

                const canceled = (
                  !isMock
                    ? orderResponse.data.data.lineItems
                    : mockData.data.lineItems
                ).filter((item) => item.status === "2");

                const inProgress = (
                  !isMock
                    ? orderResponse.data.data.lineItems
                    : mockData.data.lineItems
                ).filter((item) => item.status === "3");
                const inProgressTracking = separateShipping(
                  inProgress,
                  "In Progress",
                  "cogs"
                );

                const shipped = (
                  !isMock
                    ? orderResponse.data.data.lineItems
                    : mockData.data.lineItems
                ).filter((item) => item.status === "4");
                const shippedTracking = separateShipping(
                  shipped,
                  "Shipped",
                  "truck"
                );

                const billed = (
                  !isMock
                    ? orderResponse.data.data.lineItems
                    : mockData.data.lineItems
                ).filter((item) => item.status === "5");
                const billedTracking = separateShipping(
                  billed,
                  "Billed",
                  "checkmark"
                );

                const completed = (
                  !isMock
                    ? orderResponse.data.data.lineItems
                    : mockData.data.lineItems
                ).filter((item) => item.status === "6");
                const completedTracking = separateShipping(
                  completed,
                  "Completed",
                  "checkmark"
                );

                // console.log('completed', completed);
                // console.log('completedTracking', completedTracking);

                const allSeparatedItems = [
                  ...openAll,
                  {
                    status: "Canceled",
                    items: canceled,
                    icon: "close",
                    iconColor: "orange",
                  },
                  ...inProgressTracking,
                  ...shippedTracking,
                  ...billedTracking,
                  ...completedTracking,
                ];

                const evenMoreSplit = allSeparatedItems.filter(
                  (i) => i.items.length > 0
                );
                // console.log('evenMoreSplit', evenMoreSplit);
                setstate({
                  ...state,
                  orderData: !isMock ? orderResponse.data : mockData,
                  isLoading: false,
                  isEditing: false,
                  isModalOpen: false,
                  editedData: [],
                  lineItemsSeparated: evenMoreSplit,
                });
              } else {
                setstate({
                  ...state,
                  isLoading: false,
                  error: !isMock ? orderResponse.data : mockData,
                });
              }
            });
          } catch (e) {
            setstate({ ...state, isLoading: false });
          }
        };

        /**
         * This function exists to pass down to orderDetailDropdownRow to trigger after there's a change in cancelation
         * This tests and sets the state variable allHaveBeenCanceled
         * We can't simply use a useEffect because it's a deep change (object inside an array)
         */
        const cancelTriggered = () => {
          const { orderData, editedData } = state;
          if (!orderData) {
            return;
          }
          const { data } = orderData;
          const { lineItems } = data;
          const canceledOrdersCount = editedData.filter(
            (e) => !!e.rejectionReason
          ).length;
          const lineItemsCount = lineItems.filter(
            ({ status }) => parseInt(status, 10) === 1
          ).length;
          setAllHaveBeenCanceled(canceledOrdersCount === lineItemsCount);
        };

        useEffect(() => {
          fetchData();
        }, [props.match.params.orderId]);

        if (state.error) {
          // console.log(state.error);
          return (
            <div style={{ backgroundColor: "white" }}>
              <PageHeader headerType='loggedIn' cartBadge={true} />
              <ErrorComponent error={state.error} />
            </div>
          );
        }

        const renderAddress = (address) => {
          return (
            <div key={address[0]} className='od-address-container'>
              <div className='od-text-header'>{address[0].toUpperCase()}:</div>
              <div
                dangerouslySetInnerHTML={{ __html: address[1].formatted }}
                className='od-text-data-book'
              />
            </div>
          );
        };

        const renderMobileAddress = (address) => {
          return showMoreDataMobile ? (
            <Grid.Row key={address[1].name}>
              <Grid.Column width={6}>
                <span className='fort-bold'>{address[0]}:</span>
              </Grid.Column>
              <Grid.Column width={10}>
                <span
                  dangerouslySetInnerHTML={{ __html: address[1].formatted }}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row key={address[1].name}>
              <Grid.Column width={6}>
                <span className='fort-bold'>{address[0]}:</span>
              </Grid.Column>
              <Grid.Column width={10}>
                <span>{address[1].name}</span>
              </Grid.Column>
            </Grid.Row>
          );
        };

        if (state.isLoading) {
          return <LoadingView />;
        }

        const submitEditOrder = () => {
          setstate({ ...state, isLoading: true });

          performPatchOrder({
            orderId: props.match.params.orderId,
            data: {
              lines: state.editedData,
            },
            sapAccountNo: props.cart.ccSapAccountNo,
          }).then(() => {
            fetchData();
          });
        };

        const deleteOrder = () => {
          setstate({ ...state, isLoading: true });
          return performDeleteOrder({
            orderId: props.match.params.orderId,
            sapAccountNo: props.cart.ccSapAccountNo,
            cartType: props.cart.cart.cartType,
          }).then(() => {
            fetchData();
          });
        };

        const addOrRemoveFromEdited = (item, rejectProp, quantityProp) => {
          const tempArray = state.editedData;
          const itemInEditIndex = tempArray.findIndex(
            (data) => data.lineNo === item.lineNo
          );

          if (itemInEditIndex !== -1) {
            if (rejectProp !== null) {
              tempArray[itemInEditIndex].rejectionReason = "WC";
              tempArray[itemInEditIndex].quantity = item.quantity;
            } else if (quantityProp !== Number(item.quantity)) {
              tempArray[itemInEditIndex].quantity = quantityProp;
              tempArray[itemInEditIndex].rejectionReason = null;
            } else if (
              (quantityProp === 0 || quantityProp === Number(item.quantity)) &&
              rejectProp === null
            ) {
              tempArray.splice(itemInEditIndex, 1);
            }
            setstate({ ...state, editedData: tempArray });
          } else {
            const tempItem = {
              lineNo: item.lineNo,
              quantity: quantityProp > 0 ? quantityProp : item.quantity,
              rejectionReason: rejectProp !== null ? "WC" : null,
            };
            tempArray.push(tempItem);
            setstate({ ...state, editedData: tempArray });
          }
        };

        const renderMobileOrderDetailsData = () => {
          return (
            <Container>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <span className='fort-bold'>Order date:</span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <span>
                      {moment(state.orderData.data.createDate).format(
                        "MMM Do, YYYY"
                      )}
                    </span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <span className='fort-bold'>Purchase order:</span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <span>{state.orderData.data.poNumber}</span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <span className='fort-bold'>Sales order:</span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <span>{state.orderData.data.salesOrderNumber}</span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className='mb-2'>
                  <Grid.Column width={6}>
                    <span className='fort-bold'>Account #:</span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <span>{state.orderData.data.account}</span>
                  </Grid.Column>
                </Grid.Row>

                {state.orderData &&
                  Object.entries(state.orderData.data.addresses)
                    .filter((a) => a[0] === "Ship-to" || a[0] === "Bill-to")
                    .map((address) => renderMobileAddress(address))}

                <Grid.Row className='mb-2'>
                  <Grid.Column width={6}>
                    <span className='fort-bold'>Instructions:</span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <span>{state.orderData.data.specialInstructions}</span>
                  </Grid.Column>
                </Grid.Row>

                {showMoreDataMobile && (
                  <>
                    <Grid.Row className='mt-2'>
                      <Grid.Column width={6}>
                        <span className='fort-bold'>Retail Amount:</span>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <span>
                          $
                          {numberAsCurrency(
                            Number(state.orderData.data.grossAmt).toFixed(2)
                          )}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <span className='fort-bold'>Base Discount:</span>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <span>
                          $
                          {numberAsCurrency(
                            Number(state.orderData.data.discAmt).toFixed(2)
                          )}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <span className='fort-bold'>Shipping:</span>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <span>
                          {Number(state.orderData.data?.shipping).toFixed(2) > 0
                            ? `$${numberAsCurrency(
                                Number(state.orderData.data?.shipping).toFixed(
                                  2
                                )
                              )}`
                            : "FREE"}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <span className='fort-bold'>TAX:</span>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <span>
                          $
                          {numberAsCurrency(
                            Number(state.orderData.data.tax).toFixed(2)
                          )}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={6}>
                        <span className='fort-bold'>Subtotal:</span>
                      </Grid.Column>
                      <Grid.Column width={10}>
                        <span>
                          $
                          {numberAsCurrency(
                            Number(state.orderData.data.netAmt).toFixed(2)
                          )}
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
                <Grid.Row className='mt-2'>
                  <Grid.Column width={6}>
                    <span className='fort-bold'>Total:</span>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <span>
                      $
                      {numberAsCurrency(
                        Number(state.orderData.data.total).toFixed(2)
                      )}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <span
                onClick={() => setshowMoreDataMobile(!showMoreDataMobile)}
                className='more-link mt-2 fort-bold d-flex'
              >
                {showMoreDataMobile ? "Show less details" : "Show more details"}
              </span>
            </Container>
          );
        };

        const renderMobileOrdersContent = () => {
          return (
            <div className='od-metadata-container'>
              <Container>
                <div className='d-flex flex-direction-row justify-content-space-between align-items-center'>
                  <div className='fort-book fs-18px'>
                    View items by order status
                  </div>
                </div>
                <div className='d-flex flex-direction-column'>
                  {!state.isEditing &&
                    state.lineItemsSeparated
                      .filter((group) => group.items.length > 0)
                      .map((lineItemGroup) => (
                        <OrderDetailMobile
                          key={lineItemGroup.status + lineItemGroup.invoice}
                          history={props.history}
                          lineItems={lineItemGroup.items}
                          {...lineItemGroup}
                          user={props.user}
                        />
                      ))}
                </div>
              </Container>
            </div>
          );
        };

        return (
          <div>
            <PageStandard
              headerType='loggedIn'
              containerFluid={true}
              cartBadge={true}
            >
              {state.orderData ? (
                <div>
                  <div className='od-metadata-container'>
                    <Container className='od-header'>
                      <div className='od-header-head'>
                        <div className='od-button-right-container'>
                          <Icon
                            color='black'
                            name='file alternate'
                            className='hide-in-print'
                          />
                          <div className='od-header-text'>Order Details</div>
                        </div>
                        {!isMobile && (
                          <div className='od-button-right-container hide-in-print'>
                            {(props?.cart?.customerInfo?.currentUser?.roleNo !==
                              "W1" ||
                              props.user.isAppCompCopy()) &&
                              !props.cart.isUserPow() && (
                                <PRHButtonStandard
                                  className='ot-button ot-nav-button'
                                  size='medium'
                                  icon='pencil'
                                  backgroundColorOverride='white'
                                  backgroundColorDisabledOverride='white'
                                  iconColorOverride='orange'
                                  borderColorOverride='grey'
                                  disabled={
                                    state.isLoading ||
                                    state.isEditing ||
                                    state.orderData.data.lineItems.filter(
                                      (item) => item.status === "1"
                                    ).length === 0
                                  }
                                  onClick={() =>
                                    setstate({ ...state, isEditing: true })
                                  }
                                >
                                  Edit Order
                                </PRHButtonStandard>
                              )}
                            <Dropdown
                              // selection
                              className='od-dropdown'
                              selection
                              onClick={() =>
                                setstate({ ...state, isDropdownOpen: true })
                              }
                              trigger={
                                <div className='od-text-data-book'>
                                  Download Order
                                </div>
                              }
                              disabled={state.isLoading}
                              options={downloadOptions}
                              direction='left'
                              open={state.isDropdownOpen}
                              onClose={() =>
                                setstate({ ...state, isDropdownOpen: false })
                              }
                            />
                          </div>
                        )}
                      </div>
                      {!isMobile && (
                        <div className='od-header-subhead'>
                          <div>
                            <span className='od-text-data-medium'>
                              Order Date:{" "}
                            </span>
                            {moment(state.orderData.data.createDate).format(
                              "MMM Do, YYYY"
                            )}
                            <Divider horizontal />
                          </div>
                          <div>
                            <span className='od-text-data-medium'>
                              {" "}
                              Purchase Order:{" "}
                            </span>
                            {state.orderData.data.poNumber}
                            <Divider horizontal />
                          </div>
                          <div>
                            <span className='od-text-data-medium'>
                              Sales Order #:{" "}
                            </span>
                            {state.orderData.data.salesOrderNumber}
                          </div>
                        </div>
                      )}
                    </Container>
                    {!isMobile && (
                      <Container className='od-metadata-data  d-flex'>
                        <div className='od-metadata-data-left'>
                          <div>
                            <span className='od-text-data-medium'>
                              Account #:
                            </span>{" "}
                            {state.orderData.data.account}
                          </div>
                          <div className='od-address-data'>
                            {state.orderData &&
                              Object.entries(state.orderData.data.addresses)
                                .filter(
                                  (a) =>
                                    a[0] === "Ship-to" || a[0] === "Bill-to"
                                )
                                .map((address) => renderAddress(address))}
                          </div>
                          {state.orderData.data.specialInstructions !==
                            null && (
                            <div>
                              <div className='od-text-header'>
                                SPECIAL INSTRUCTIONS:
                              </div>
                              <div className='od-text-data-book'>
                                {state.orderData.data.specialInstructions}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='od-metadata-data-right tm-order-now-container'>
                          <div className='od-modal-data od-text-data-book'>
                            <div>Retail Amount</div>
                            <div>
                              $
                              {numberAsCurrency(
                                Number(state.orderData.data.grossAmt).toFixed(2)
                              )}
                            </div>
                          </div>
                          {Number(state.orderData.data.discAmt) > 0 && (
                            <div className='od-modal-data od-text-data-book'>
                              <div>Account Discount</div>
                              <div>
                                -$
                                {numberAsCurrency(
                                  Number(state.orderData.data.discAmt).toFixed(
                                    2
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          {Number(state.orderData.data.promoAmt) > 0 && (
                            <div className='od-modal-data od-text-data-book'>
                              <div>
                                {state.orderData.data.promoCode} Discount
                              </div>
                              <div>
                                -$
                                {numberAsCurrency(
                                  Number(state.orderData.data.promoAmt).toFixed(
                                    2
                                  )
                                )}
                              </div>
                            </div>
                          )}
                          <div className='dotted-divider' />
                          <div className='od-modal-data od-text-header'>
                            <div>Subtotal</div>
                            <div>
                              $
                              {numberAsCurrency(
                                Number(state.orderData.data.netAmt).toFixed(2)
                              )}
                            </div>
                          </div>
                          <div className='od-modal-data od-text-data-book'>
                            <div>Shipping</div>
                            <div>
                              {Number(state.orderData.data?.shipping).toFixed(
                                2
                              ) > 0
                                ? `$${numberAsCurrency(
                                    Number(
                                      state.orderData.data?.shipping
                                    ).toFixed(2)
                                  )}`
                                : "FREE"}
                            </div>
                          </div>
                          {Number(state.orderData.data.handling) > 0 && (
                            <div className='od-modal-data od-text-data-book'>
                              <div>Handling</div>
                              <div>
                                $
                                {numberAsCurrency(
                                  Number(state.orderData.data.total).toFixed(2)
                                )}
                              </div>
                            </div>
                          )}
                          <div className='od-modal-data od-text-data-book'>
                            <div>Tax</div>
                            <div>
                              $
                              {numberAsCurrency(
                                Number(state.orderData.data.tax).toFixed(2)
                              )}
                            </div>
                          </div>
                          <div className='line-divider' />
                          <div className='od-modal-data od-text-big-header'>
                            <div>Total</div>
                            <div>
                              $
                              {numberAsCurrency(
                                Number(state.orderData.data.total).toFixed(2)
                              )}
                            </div>
                          </div>
                        </div>
                      </Container>
                    )}
                    {isMobile && renderMobileOrderDetailsData()}
                  </div>
                  {isMobile && renderMobileOrdersContent()}
                  {!isMobile && state.isEditing && (
                    <div className='od-edit-container hide-in-print'>
                      <Container className='od-edit-data-container d-flex'>
                        <div className='od-white-bold-text d-flex align-items-center'>
                          Displaying all unprocessed items in order{" "}
                          <Popup
                            className='cp-help-tooltip'
                            content={TOOLTIPS["unprocessedItems"]}
                            position='top center'
                            offset={[0, 5]}
                            trigger={
                              <Icon
                                name='question circle outline'
                                className='sb_question_icon'
                              />
                            }
                          />
                        </div>
                        <div className='od-button-right-container'>
                          <PRHButtonStandard
                            className='ot-button ot-nav-button'
                            size='medium'
                            icon='warning circle'
                            iconColorOverride='orange'
                            backgroundColorOverride='white'
                            borderColorOverride='grey'
                            disabled={state.isLoading}
                            onClick={() =>
                              setstate({ ...state, isModalOpen: true })
                            }
                          >
                            Cancel Full Order
                          </PRHButtonStandard>
                          <div className='separating-rule' />

                          <DialogModal
                            setOpen={setOpenConfirmCancelModal}
                            open={openConfirmCancelModal}
                            dialogOptions={{
                              header: "Discard changes?",
                              actions: [
                                {
                                  label: "No, go back",
                                  action: () => {
                                    return { proceed: true };
                                  },
                                },
                                {
                                  hidden: false,
                                  label: "Yes, exit without saving",
                                  textColorOverride: "white",
                                  backgroundColorOverride: "blue",
                                  borderColorOverride: "blue",
                                  preAction: async () => {
                                    setstate({
                                      ...state,
                                      isEditing: false,
                                      editedData: [],
                                    });
                                    return { proceed: true };
                                  },
                                  action: () => {
                                    return { proceed: true };
                                  },
                                },
                              ],
                            }}
                            options={{
                              trigger: (
                                <PRHButtonStandard
                                  className='ot-button ot-nav-button'
                                  size='medium'
                                  backgroundColorOverride='white'
                                  backgroundColorDisabledOverride='white'
                                  borderColorOverride='grey'
                                  disabled={state.isLoading}
                                  onClick={async () => {
                                    if (state.editedData.length > 0) {
                                      return setOpenConfirmCancelModal(true);
                                    }
                                    setstate({
                                      ...state,
                                      isEditing: false,
                                      editedData: [],
                                    });
                                    return;
                                  }}
                                >
                                  Cancel Edits
                                </PRHButtonStandard>
                              ),
                            }}
                          >
                            <p className='paragraph-1'>
                              <span className='fort-bold'>
                                You have made changes to your order. Are you
                                sure you want to exit without saving your
                                changes?
                              </span>
                            </p>
                          </DialogModal>

                          <DialogModal
                            setOpen={
                              setOpenAllHaveBeenCanceledAndUserClickedSaveConfirmCancelModal
                            }
                            open={
                              openAllHaveBeenCanceledAndUserClickedSaveConfirmCancelModal
                            }
                            dialogOptions={{
                              header: "Save Edits - Cancel Order",
                              actions: [
                                {
                                  label: "No, go back",
                                  // color: 'red',
                                  // icon: 'close',
                                  action: () => {
                                    return { proceed: true };
                                  },
                                },
                                {
                                  textColorOverride: "white",
                                  backgroundColorOverride: "blue",
                                  borderColorOverride: "blue",
                                  hidden: false,
                                  label: "Yes, cancel entire order",
                                  preAction: async () => {
                                    await deleteOrder();
                                    return { proceed: true };
                                  },
                                  action: () => {
                                    return { proceed: true };
                                  },
                                },
                              ],
                            }}
                            options={{
                              trigger: (
                                <PRHButtonStandard
                                  className='ot-button ot-nav-button'
                                  size='medium'
                                  icon='checkmark'
                                  iconColorOverride='orange'
                                  borderColorOverride='grey'
                                  backgroundColorOverride='white'
                                  backgroundColorDisabledOverride='white'
                                  disabled={
                                    state.isLoading ||
                                    state.editedData.length === 0
                                  }
                                  onClick={() => {
                                    if (!!allHaveBeenCanceled) {
                                      return setOpenAllHaveBeenCanceledAndUserClickedSaveConfirmCancelModal(
                                        true
                                      );
                                    }
                                    submitEditOrder();
                                  }}
                                >
                                  Save Edits
                                </PRHButtonStandard>
                              ),
                            }}
                          >
                            <p className='paragraph-1'>
                              <span className='fort-bold'>
                                It seems you have canceled all open items in
                                this order. Saving these edits will cancel the
                                entire order. Are you sure?
                              </span>
                              <br />
                              <br />
                              <em className='fort-book'>
                                To reorder canceled items, you must place a new
                                order.
                              </em>
                            </p>
                          </DialogModal>
                        </div>
                      </Container>
                    </div>
                  )}
                  {!isMobile && (
                    <Container className='od-status-container hide-in-print'>
                      {!state.isEditing &&
                        state.lineItemsSeparated
                          .filter((group) => group.items.length > 0)
                          .map((lineItemGroup) => (
                            // console.log('lineItemGroup',lineItemGroup),
                            // console.log('lineItemGroup',lineItemGroup.status),
                            <OrderDetailDropdown
                              key={lineItemGroup.status + lineItemGroup.invoice}
                              history={props.history}
                              lineItems={lineItemGroup.items}
                              {...lineItemGroup}
                              user={props.user}
                            />
                          ))}
                      {state.isEditing && (
                        <div className='od-edit-content-container'>
                          {state.orderData.data.lineItems
                            .filter((i) => i.status === "1")
                            .map((i) => (
                              <OrderDetailDropdownRow
                                cancelTriggered={cancelTriggered}
                                key={i.isbn + i.lineNo}
                                book={i}
                                isEditing={state.isEditing}
                                addOrRemoveFromEdited={addOrRemoveFromEdited}
                              />
                            ))}
                        </div>
                      )}
                    </Container>
                  )}
                  <div className='show-only-in-print'>
                    {state.lineItemsSeparated
                      .filter((group) => group.items.length > 0)
                      .map((lineItemGroup) => (
                        <OrderDetailRowsPrint
                          key={
                            lineItemGroup.status +
                            lineItemGroup.invoice +
                            "orderdetailrowsprint"
                          }
                          history={props.history}
                          lineItems={lineItemGroup.items}
                          {...lineItemGroup}
                          user={props.user}
                        />
                      ))}
                  </div>
                  <DialogModal
                    open={state.isModalOpen}
                    closeOnEscape={true}
                    closeOnDimmerClick={true}
                    history={props.history}
                    onClose={() => setstate({ ...state, isModalOpen: false })}
                    dialogOptions={{
                      header: "Cancel Order",
                      actions: [
                        {
                          label: "No, go back",
                          color: "white",
                          action: () => {
                            setstate({ ...state, isModalOpen: false });
                            return { proceed: true };
                          },
                        },
                        {
                          label: "Yes, cancel entire order",
                          textColorOverride: "white",
                          backgroundColorOverride: "blue",
                          borderColorOverride: "blue",
                          action: () => {
                            deleteOrder();
                            setstate({ ...state, isModalOpen: false });
                            return { proceed: true };
                          },
                        },
                      ],
                    }}
                  >
                    <div className='od-text-data-bold'>
                      <span className='fort-bold'>
                        Are you sure you want to cancel the entire order?
                      </span>
                      <br />
                      <br />
                      <em className='fort-book'>
                        To reorder canceled items, you must place a new order.
                      </em>
                    </div>
                  </DialogModal>
                </div>
              ) : (
                <div className='ti-loading-container'>
                  <LoadingView />
                </div>
              )}
            </PageStandard>
          </div>
        );
      })
    )
  )
);
