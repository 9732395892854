import { Component } from 'react';
import withAccount from '../../components/hc/withAccount';
import { withRouter } from 'react-router';
import { PRHContainerHeader } from '../../shared-react-components/elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { Form } from 'semantic-ui-react';
import { ButtonHolder } from '../../components/elements/elements';

class MigrateCancel extends Component {
  cancel = (e) => {
    const { account } = this.props;
    account.clearStateField();
    account.setStepHandler(1);
    this.props.history.push('/');
  };

  render() {
    const { account } = this.props;
    return (
      <>
        <div className={'account'}>
          <div className={'white-background-container'}>
            <PRHContainerHeader className="d-block h-auto">
              Are you sure you want to cancel updates?
            </PRHContainerHeader>
            <span>
              You will not be able to access PRH Self-Service until you update
              your account details.
            </span>
            <br />
            <br />
            <ButtonHolder>
              <PRHButton
                iconright
                size="large"
                className={'float-right margin-left'}
                icon="arrow right"
                onClick={(e) => this.cancel('cancel')}
                backgroundColorOverride={'blue'}
                textColorOverride={'white'}
              >
                Yes, cancel
              </PRHButton>
              <PRHButton
                size="large"
                className={'float-left'}
                type="submit"
                onClick={(e) => account.setStepHandler(2)}
              >
                No, continue
              </PRHButton>
            </ButtonHolder>
            <br />
            <br />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(withAccount(MigrateCancel));
