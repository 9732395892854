import React, { useState, useEffect, useRouter } from "react";
import {
  Dropdown,
  Input,
  Form,
  Message,
  Icon,
  Container,
} from "semantic-ui-react";
import { useBlocker } from "react-router";
import { AgGridReact } from "ag-grid-react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";

import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import PRHButtonStandard from "../../shared-react-components/elements/prhbutton";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import "./OrdersTab.scss";
import print from "print-js";
import { Export, ExportCSV } from "../../utils/Export";
import invoiceNumberRenderer from "./renderers/invoiceNumberRenderer";
import DialogModal from "../../components/modals/DialogModal";
import { findIconDefinition, icon } from "@fortawesome/fontawesome-svg-core";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { MobileOptionsMenu } from "../../components/header/components/MobileOptionsMenu";
import { Divider } from "../../components/elements/elements";
import MobileTable, {
  mobileTableColumnsInvoices,
} from "../../shared-react-components/elements/OrdersAndInvoicesMobileTable";
import NoticeCard from "../../components/elements/NoticeCard";
import { OrderInvoiceTitleSearchMobile } from "../../components/common/OrderInvoiceTitleSearchMobile";
import {
  useEmailInvoicesMutation,
  useLazyGetInvoicesQuery,
} from "../../api/ecomApi";
import { cloneDeep } from "lodash";
import useDirtyHandler from "../../shared-react-components/components/hc/useDirtyHandler";

//const {history} = useHistory();
//test for BIZ2-4151

const pageSizeOptions = [
  {
    key: 50,
    text: "50 per page",
    value: 50,
  },
  {
    key: 100,
    text: "100 per page",
    value: 100,
  },
  {
    key: 150,
    text: "150 per page",
    value: 150,
  },
  {
    key: 200,
    text: "200 per page",
    value: 200,
  },
];

const dateFilters = [
  {
    key: 30,
    text: "Past 30 days",
    value: 30,
  },
  {
    key: 92,
    text: "Past 3 months",
    value: 92,
  },
  {
    key: 184,
    text: "Past 6 months",
    value: 184,
  },
  {
    key: 366,
    text: "Past 12 months",
    value: 366,
  },
  {
    key: 550,
    text: "Past 18 months",
    value: 550,
  },
];

export const InvoicesTab = withRouter(
  withSecurity(
    withApp(
      withCart((props) => {
        const isMobile = useMediaQuery({
          query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
        });
        //const {history} = useHistory();
        const dirtyHandler = useDirtyHandler();
        const [performGetInvoices, getInvoicesResult] =
          useLazyGetInvoicesQuery();
        const [performEmailInvoices, emailInvoicesResult] =
          useEmailInvoicesMutation();
        const propPage = Number(props.location.search?.split("=")[1] || 1);
        const [rowData, setRowData] = useState(undefined);
        const [printRowData, setPrintRowData] = useState(undefined);
        const [state, setState] = useState({
          isEmailModalVisible: false,
          isEmailErrorModalVisible: false,
          isInfoVisible: true,
          isLoading: false,
          pageSize: props.location.state?.pageSize
            ? props.location.state?.pageSize
            : 50,
          queryId: null,
          currentPageNum: props.location.state?.currentPageNum
            ? props.location.state?.currentPageNum
            : propPage,
          totalPages: props.location.state?.totalPages
            ? props.location.totalPages?.currentPageNum
            : 0,
          rowHeight: 51,
          currentSort: "dateCreated_desc",
          columnDefs: [
            {
              headerName: "BILLING DOC#",
              field: "billingDocNumber",
              checkboxSelection: true,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly:true,
              minWidth: 140,
              cellRenderer: "invoiceNumberRenderer",
              /*
              cellRendererParams: {
                callback: (orderNum)=>viewOrder(orderNum),
              },
              cellRendererParams: {
                clicked: function(field) {
                  props.history.push({
                    pathname: `/invoices/${field}`,
                    state: JSON.stringify(state),
                  });
                },
              },*/
              cellClass: ["btt-isbn no-border"],
            },
            {
              headerName: "DOCUMENT TYPE",
              field: "docName",
              minWidth: 80,
              cellClass: ["no-border"],
            },
            {
              headerName: "DATE CREATED",
              field: "dateCreated",
              sortable: true,
              minWidth: 120,
              sortingOrder: ["desc", "asc"],
              sort: "desc",
              cellClass: ["no-border"],
            },
            {
              headerName: "PURCHASE ORDER",
              field: "purchaseOrderNumber",
              minWidth: 100,
              cellClass: ["no-border"],
            },
            {
              headerName: "SHIP-TO ACCOUNT",
              field: "soldToAccount",
              minWidth: 120,
              cellClass: ["no-border"],
            },
            {
              headerName: "NET AMOUNT",
              field: "grossValue",
              cellClass: ["no-border"],
              minWidth: 120,
              comparator: (a, b, nodeA, nodeB) => {
                const aa = Number(a.substring(1));
                const bb = Number(b.substring(1));
                if (aa > bb) {
                  return 1;
                } else {
                  return -1;
                }
              },
              valueFormatter: currencyFormatter,
              valueGetter: currencyFormatter,
            },
          ],
          dayFilter: props.location.state?.dayFilter
            ? props.location.state?.dayFilter
            : dateFilters[0].value,
          uniqueDocNames: [],
          documentFilter: props.location.state?.documentFilter
            ? props.location.state?.documentFilter
            : "1",
          documentFilters: props.location.state?.documentFilters
            ? props.location.state?.documentFilters
            : [],
          accountFilter: props.location.state?.accountFilter
            ? props.location.state?.accountFilter
            : "1",
          mobileDocumentFilter: props.location.state?.mobileDocumentFilter
            ? props.location.state?.mobileDocumentFilter
            : "1",
          accountFilters: [],
          selectedRows: [],
          searchInput: props.location.state?.searchInput
            ? [props.location.state.searchInput]
            : [],
          headerOnly: true,
          hasInput: props.history.location.state?.hasInput
            ? props.history.location.state?.hasInput
            : false,
          hasError: false,
        });
        const [gridApi, setGridApi] = useState(null);
        const [sortMenuVisible, setSortMenuVisible] = useState(false);
        const [accountMenuVisible, setAccountMenuVisible] = useState(false);
        const [mobileDocumentMenuVisible, setMobileDocumentMenuVisible] =
          useState(false);
        const [mobileRowData, setMobileRowData] = useState(rowData);

        useEffect(() => {
          if (props.history.action !== "PUSH" && props.history.location.state) {
            setState({
              ...state,
              dayFilter: props.history.location.state.dayFilter
                ? props.history.location.state.dayFilter
                : state.dayFilter,
              accountFilter: props.history.location.state?.accountFilter
                ? props.history.location.state?.accountFilter
                : state.accountFilter,
              documentFilter: props.history.location.state?.documentFilter
                ? props.history.location.state?.documentFilter
                : state.documentFilter,
              documentFilters: props.history.location.state?.documentFilters
                ? props.history.location.state?.documentFilters
                : state.documentFilters,
              mobileDocumentFilter: props.location.state?.mobileDocumentFilter
                ? props.location.state?.mobileDocumentFilter
                : state.mobileDocumentFilter,
              pageSize: props.history.location.state?.pageSize
                ? props.history.location.state?.pageSize
                : state.pageSize,
              currentPageNum: props.history.location.state?.currentPageNum
                ? props.history.location.state?.currentPageNum
                : state.currentPageNum,
              totalPages: props.history.location.state?.totalPages
                ? props.history.location.state?.totalPages
                : state.totalPages,
              searchInput: props.history.location.state?.searchInput
                ? [props.history.location.state?.searchInput]
                : state.searchInput,
              hasInput: props.history.location.state?.hasInput
                ? props.history.location.state?.hasInput
                : state.hasInput,
            });
          }
        }, []);

        useEffect(() => {
          if (state.accountFilter === "1") {
            setMobileRowData(rowData);
          } else {
            const filteredData = rowData?.filter(
              (data) => data.soldToAccount === state.accountFilter
            );
            console.log("set mobile row data 2", filteredData);
            setMobileRowData(filteredData);
          }
        }, [rowData, state.accountFilter]);

        function onFilterTextBoxChanged() {
          const { searchInput } = state;
          performSearch();
          props.history.push({
            pathname: "/invoices",
            state: {
              ...props.history.location.state,
              searchInput: searchInput.length > 0 ? [searchInput] : [],
            },
          });
        }

        const search = (event) => {
          var code = event.keyCode || event.which;
          if (code === 13) {
            onFilterTextBoxChanged();
          }
        };

        function filterDocuments(rows) {
          //jeff
          let filteredRows = rows;
          console.log("rows", rows, state.documentFilter, state.accountFilter);
          if (state.documentFilter && state.documentFilter != 1) {
            filteredRows = filteredRows.filter(
              (row) => row.docType === state.documentFilter
            );
          }
          if (state.accountFilter && state.accountFilter != 1) {
            filteredRows = filteredRows.filter(
              (row) => row.soldToAccount === state.accountFilter
            );
          }
          return filteredRows;
        }

        function onGridReady(params) {
          // console.log("onGridReady");
          setGridApi(params.api);
          params.api.sizeColumnsToFit();
          setState({
            ...state,
            accountFilter: props.history.location.state?.accountFilter
              ? props.history.location.state?.accountFilter
              : state.accountFilter,
          });

          //params.api.sizeColumnsToFit(); //jeff
          console.log("accountFilter", state.accountFilter);
          params.api.setQuickFilter(
            state.accountFilter !== "1" ? state.accountFilter : ""
          );

          console.log("documentFilter", state.documentFilter);
          if (state.documentFilter) {
            let filterValue = "";
            let fValue = state.documentFilters.filter((x) => {
              if (x.id === state.documentFilter) {
                return true;
              } else {
                return false;
              }
            });
            if (fValue.length > 0) {
              filterValue = fValue[0].text;
            }
            const filterInstance = params.api.getFilterInstance("docName");
            if (state.documentFilter === "1") {
              filterInstance.setModel(null);
            } else {
              filterInstance.setModel({
                values: [filterValue],
              });
            }
            params.api.onFilterChanged();
          }
        }

        function currencyFormatter(params) {
          const number = Number(params.data.grossValue || params.value).toFixed(
            2
          );
          return `$${isNaN(number) ? (0).toFixed(2) : number}`;
        }

        const setData = (dataProps) => {
          var uniqueShipToAccountNumbers = [];
          var distinctShipToAccountNumbers = [];
          for (let i = 0; i < dataProps.length; i++) {
            if (!uniqueShipToAccountNumbers[dataProps[i].soldToAccount]) {
              distinctShipToAccountNumbers.push({
                id: dataProps[i].soldToAccount,
                text: String(dataProps[i].soldToAccount),
                value: dataProps[i].soldToAccount,
              });
              uniqueShipToAccountNumbers[dataProps[i].soldToAccount] = 1;
            }
          }

          // var uniqueDocNames = state.uniqueDocNames;
          // var uniqueDocNames = state.uniqueDocNames;
          var uniqueDocNames = [];
          // var distinctDocNames = state.documentFilters;
          var distinctDocNames = [];
          for (let i = 0; i < dataProps.length; i++) {
            if (!uniqueDocNames[dataProps[i].docType]) {
              distinctDocNames.push({
                id: dataProps[i].docType,
                text: String(dataProps[i].docName),
                value: dataProps[i].docType,
              });
              uniqueDocNames[dataProps[i].docType] = 1;
            }
          }
          // console.log("setData", dataProps, distinctDocNames);
          setRowData(dataProps);
          setState({
            ...state,
            totalPages: Math.ceil(dataProps.length / state.pageSize),
            isLoading: false,
            accountFilters: [
              {
                key: 1,
                text: "All Accounts",
                value: "1",
              },
              ...distinctShipToAccountNumbers,
            ],
            mobileDocumentFilters: [
              {
                key: 1,
                text: "All Documents",
                value: "1",
              },
              ...distinctDocNames,
            ],
            documentFilter: "1",
            documentFilters: distinctDocNames,
            uniqueDocNames: uniqueDocNames,
          });

          console.log("setting df 1");
          if (gridApi) {
            gridApi.hideOverlay();
          }
        };

        useEffect(() => {
          performSearch();
        }, [state.dayFilter, props.cart.customerLoaded, state.start]);

        const performSearch = () => {
          const dataParams = {
            // docType:
            //   String(state.documentFilter) === '1'
            //     ? []
            //     : [state.documentFilter],
            documentNumber: [],
            isbn: [],
            purchaseOrder: [],
            salesOrder: [],
            //dateRange: state.dayFilter + 'days',
            dateFrom: moment(new Date(), "YYYY-MM-DD")
              .subtract(state.dayFilter, "days")
              .toISOString()
              .slice(0, 10),
            dateTo: moment(new Date(), "YYYY-MM-DD").toISOString().slice(0, 10),
          };
          if (props.cart?.customerInfo?.currentUser?.roleNo === "W1") {
            dataParams.account = [
              props.cart?.customerInfo?.customer?.accountNo,
            ];
          }
          if (gridApi) {
            gridApi.showLoadingOverlay();
          }

          console.log("getting invoices");

          // dirtyHandler.setLoadingTakeoverConfig({
          //   text: "Loading author . . .",
          //   longLoadText: "Still loading . . .",
          //   inverted: true,
          //   backgroundBlurLight: true,
          // });
          // dirtyHandler.setLoadingTakeover(true);
          console.log("dirty", dirtyHandler.loadingTakeover);
          if (state.searchInput.length > 0) {
            const input = state.searchInput[0].toString().trim();
            const isbn = input?.replaceAll("-", "");
            performGetInvoices({
              data: {
                ...dataParams,
                isbn: [isbn],
                dateRange: "",
                dateFrom: null,
              },
              sapAccountNo: props.cart.ccSapAccountNo,
            }).then((invoicesResponse) => {
              // console.log('get invoices 1');
              if (invoicesResponse.data?.status === "OK") {
                const { data } = invoicesResponse;
                if (data.data.invoiceListItems.length === 0) {
                  // console.log(data.data.invoiceListItems);
                  performGetInvoices({
                    data: {
                      ...dataParams,
                      billingDocNumber: [input],
                      dateRange: "",
                      dateFrom: null,
                    },
                    sapAccountNo: props.cart.ccSapAccountNo,
                  }).then((invoicesResponse) => {
                    // console.log('get invoices 2');
                    if (invoicesResponse.data?.status === "OK") {
                      const { data } = invoicesResponse;
                      if (data.data.invoiceListItems.length === 0) {
                        performGetInvoices({
                          data: {
                            ...dataParams,
                            salesOrder: [input],
                            dateRange: "",
                          },
                          sapAccountNo: props.cart.ccSapAccountNo,
                        }).then((invoicesResponse) => {
                          // console.log('get invoices 3');
                          if (invoicesResponse.data?.status === "OK") {
                            const { data } = invoicesResponse;
                            if (data.data.invoiceListItems.length === 0) {
                              // console.log(data.data.invoiceListItems);
                              performGetInvoices({
                                data: {
                                  ...dataParams,
                                  purchaseOrder: [input],
                                  dateRange: "",
                                  dateFrom: null,
                                },
                                sapAccountNo: props.cart.ccSapAccountNo,
                              }).then((invoicesResponse) => {
                                // console.log('get invoices 4');
                                if (invoicesResponse.data?.status === "OK") {
                                  const { data } = invoicesResponse;
                                  setData(
                                    cloneDeep(data.data.invoiceListItems)
                                  );
                                  // console.log(
                                  //   data.data.invoiceListItems
                                  // );
                                } else {
                                  setState({
                                    ...state,
                                    hasError: true,
                                  });
                                }
                              });
                            } else {
                              setData(cloneDeep(data.data.invoiceListItems));
                            }
                          } else {
                            setState({ ...state, hasError: true });
                          }
                        });
                      } else {
                        setData(cloneDeep(data.data.invoiceListItems));
                      }
                    } else {
                      setState({ ...state, hasError: true });
                    }
                  });
                } else {
                  setData(cloneDeep(data.data.invoiceListItems));
                }
              } else {
                setState({ ...state, hasError: true });
              }
            });
          } else {
            performGetInvoices({
              data: dataParams,
              sapAccountNo: props.cart.ccSapAccountNo,
            }).then((invoicesResponse) => {
              // console.log('get invoices 5', dataParams);
              if (invoicesResponse.data?.status === "OK") {
                const { data } = invoicesResponse;
                setData(cloneDeep(data.data.invoiceListItems));
              } else {
                setState({ ...state, hasError: true });
              }
            });
          }

          // if (gridApi) gridApi.refreshCells();
          dirtyHandler.setLoadingTakeover(false);
        };
        /*
        useEffect(() => {
         
          props.history.push({
            pathname: "/invoices",
            state: JSON.stringify(state),
          });
        }, [state]);
*/
        const downloadOptions = [
          {
            key: 50,
            text: "Print",
            value: 50,
            icon: { name: "print", color: "orange" },
            onClick: () =>
              print({
                printable:
                  state.selectedRows.length > 0
                    ? filterDocuments(state.selectedRows)
                    : filterDocuments(rowData),
                properties: [
                  "billingDocNumber",
                  "docName",
                  "dateCreated",
                  "purchaseOrderNumber",
                  "soldToAccount",
                  "grossValue",
                ],
                type: "json",
              }),
          },
          {
            key: 100,
            text: "Download Excel",
            value: 100,
            icon: { name: "file excel outline", color: "orange" },
            onClick: () =>
              Export.DocumentDetails({
                fileName: `invoice-list-${moment().format("YYYY-MM-DD")}`,
                metadata: [
                  [
                    "Inquiry for",
                    `Search for invoices with date between ${moment()
                      .subtract(
                        dateFilters.find((d) => d.value === state.dayFilter)
                          .key,
                        "days"
                      )
                      .format("MM/DD/YYYY")} and ${moment().format(
                      "MM/DD/YYYY"
                    )}`,
                  ],
                  ["Inquiry date", moment().format("MM/DD/YYYY")],
                ],
                list: (state.selectedRows.length > 0
                  ? filterDocuments(state.selectedRows)
                  : filterDocuments(rowData)
                ).map((item) => {
                  return {
                    "Billing Document Number": item.billingDocNumber,
                    "Document Name": item.docName,
                    "Date Created": item.dateCreated,
                    "Purchase Order Number": item.purchaseOrderNumber,
                    "Sold To Account": item.soldToAccount,
                    "Gross Value": `$${Number(item.grossValue).toFixed(2)}`,
                  };
                }),
              }),
          },
          {
            key: 125,
            text: "Download CSV",
            value: 100,
            icon: { name: "file outline", color: "orange" },
            onClick: () =>
              ExportCSV.DocumentDetails({
                fileName: `invoice-list-${moment().format("YYYY-MM-DD")}`,
                metadata: [
                  [
                    "Inquiry for",
                    `Search for invoices with date between ${moment()
                      .subtract(
                        dateFilters.find((d) => d.value === state.dayFilter)
                          .key,
                        "days"
                      )
                      .format("MM/DD/YYYY")} and ${moment().format(
                      "MM/DD/YYYY"
                    )}`,
                  ],
                  ["Inquiry date", moment().format("MM/DD/YYYY")],
                ],
                list: (state.selectedRows.length > 0
                  ? filterDocuments(state.selectedRows)
                  : filterDocuments(rowData)
                ).map((item) => {
                  return {
                    "Billing Document Number": item.billingDocNumber,
                    "Document Name": item.docName,
                    "Date Created": item.dateCreated,
                    "Purchase Order Number": item.purchaseOrderNumber,
                    "Sold To Account": item.soldToAccount,
                    "Gross Value": `$${Number(item.grossValue).toFixed(2)}`,
                  };
                }),
              }),
          },
          {
            key: 150,
            text: "Email Invoices",
            value: 150,
            icon: { name: "mail", color: "orange" },
            onClick: () => {
              if (state.selectedRows.length > 0) {
                if (state.selectedRows.length > 20) {
                  setState({ ...state, isEmailErrorModalVisible: true });
                } else {
                  setState({ ...state, isEmailModalVisible: true });
                }
              } else if (rowData.length > 20) {
                setState({ ...state, isEmailErrorModalVisible: true });
              } else {
                setState({ ...state, isEmailModalVisible: true });
              }
            },
          },
        ];

        const getAccountFilterText = (key, accountFilters) => {
          const item = accountFilters?.find((option) =>
            key === "1" ? option.key === 1 : option.id === key
          );
          return item?.text;
        };

        const getMobileDocumentFilterText = (key, mobileDocumentFilters) => {
          const item = mobileDocumentFilters?.find((option) =>
            key === "1" ? option.key === 1 : option.id === key
          );
          return item?.text;
        };

        const v = (key, documentFilters) => {
          const item = accountFilters?.find((option) =>
            key === "1" ? option.key === 1 : option.id === key
          );
          return item?.text;
        };

        function debounce(func, time) {
          var time = time || 100; // 100 by default if no param
          var timer;
          return function (event) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(func, time, event);
          };
        }

        // Function with stuff to execute
        function resizeContent() {
          if (gridApi) {
            gridApi.sizeColumnsToFit();
            //gridApi.checkGridSize();
          }
        }
        // Eventlistener
        window.addEventListener("resize", debounce(resizeContent, 150));

        const caretUp = findIconDefinition({
          prefix: "fas",
          iconName: "caret-up",
        });
        const caretDown = findIconDefinition({
          prefix: "fas",
          iconName: "caret-down",
        });
        const caretUpIcon = icon(caretUp);
        const caretDownIcon = icon(caretDown);

        // console.log('invoices render', state, props);
        return (
          <div>
            {/* {console.log("state, row data", rowData, state)} */}
            {state.isInfoVisible && rowData && rowData.length > 0 && (
              <div
                className={`${
                  isMobile ? "flex-direction-column m-2" : "oi-info-container"
                }`}
              >
                {!isMobile ? (
                  <>
                    <div className='od-text-data-book'>
                      To place claims, click on an invoice and then click the
                      “Place Claim” button on top of the invoice page.
                    </div>
                    <div className={isMobile ? "mt-2" : ""}>
                      <PRHButtonStandard
                        icon='checkmark'
                        iconColorOverride='orange'
                        onClick={() =>
                          setState({ ...state, isInfoVisible: false })
                        }
                      >
                        Got it
                      </PRHButtonStandard>
                    </div>
                  </>
                ) : (
                  <NoticeCard>
                    Placing claims is accessible through desktop only.
                  </NoticeCard>
                )}
              </div>
            )}
            <div
              className={`oi-filter-row ${
                isMobile ? "mobile-oi-filter-row mb-0" : ""
              }`}
            >
              {isMobile && !state.hasError && (
                <div className='mobile-orders-tab-search-container mb-3 mt-1'>
                  <Container>
                    <OrderInvoiceTitleSearchMobile
                      title={
                        mobileRowData?.length === 1 ? "Invoice" : "Invoices"
                      }
                      count={mobileRowData?.length}
                      onSearch={(search) => {
                        setState({
                          ...state,
                          searchInput: search ? [search] : [],
                        });
                        props.history.push({
                          pathname: "/invoices",
                          state: {
                            ...props.history.location.state,
                            searchInput: search ? [search] : [],
                          },
                        });
                      }}
                      placeholder='Search Order #, PO #, or ISBN'
                    />
                  </Container>
                </div>
              )}
              {!isMobile && !state.hasError && (
                <div className='oi-filter-row-left'>
                  <Dropdown
                    selection
                    disabled={state.isLoading}
                    options={dateFilters}
                    value={state.dayFilter}
                    direction='left'
                    onChange={(e, { value }) => {
                      setState({
                        ...state,
                        documentFilter: "1",
                        dayFilter: value,
                        searchInput: "",
                      });
                      props.history.push({
                        pathname: "/invoices",
                        state: {
                          ...props.history.location.state,
                          dayFilter: value,
                          //searchInput: '',
                        },
                      });
                      if (gridApi) gridApi.setQuickFilter("");
                    }}
                    className='tm-dropdown ot-dropdown '
                  />
                  {!isMobile &&
                    props.cart.customerInfo.currentUser &&
                    props.cart.customerInfo.currentUser.roleNo !== "W1" &&
                    state.accountFilters.length > 2 && ( // adjusted to hide in cases where there is only one location, a generic holder comes back in first field
                      <Dropdown
                        selection
                        disabled={
                          state.isLoading ||
                          rowData?.length === 0 ||
                          (rowData?.length === 0 &&
                            state.searchInput?.length > 0)
                        }
                        options={[...state.accountFilters]}
                        value={state.accountFilter}
                        direction='left'
                        onChange={(e, { value }) => {
                          setState({ ...state, accountFilter: value });
                          gridApi.deselectAll();
                          props.history.push({
                            pathname: "/invoices",
                            state: {
                              ...props.history.location.state,
                              accountFilter: value,
                              searchInput: "",
                            },
                          });
                          if (gridApi)
                            gridApi.setQuickFilter(value !== "1" ? value : "");
                        }}
                        className='tm-dropdown ot-dropdown tm-docname-dropdown'
                      />
                    )}
                  {state.documentFilters.length > 1 && (
                    <Dropdown
                      selection
                      disabled={
                        state.isLoading ||
                        rowData?.length === 0 ||
                        (rowData?.length === 0 && state.searchInput?.length > 0)
                      }
                      options={[
                        {
                          key: 1,
                          text: "All Documents",
                          value: "1",
                        },
                        ...state.documentFilters,
                      ]}
                      value={state.documentFilter}
                      direction='left'
                      style={{ width: "170px" }}
                      onChange={(e, d) => {
                        const { value, options } = d;
                        setState({ ...state, documentFilter: value });
                        gridApi.deselectAll();
                        // props.history.push({
                        //   pathname: '/invoices',
                        //   state: {
                        //     ...props.history.location.state,
                        //     documentFilter: value,
                        //     documentFilters: state.documentFilters,
                        //   },
                        // });
                        let filterValue = "";
                        let fValue = options.filter((x) => {
                          if (x.id === value) {
                            return true;
                          } else {
                            return false;
                          }
                        });
                        if (fValue.length > 0) {
                          filterValue = fValue[0].text;
                        }
                        const filterInstance =
                          gridApi.getFilterInstance("docName");
                        if (value === "1") {
                          filterInstance.setModel(null);
                        } else {
                          filterInstance.setModel({
                            values: [filterValue],
                          });
                        }
                        gridApi.onFilterChanged();
                      }}
                      className='tm-dropdown ot-dropdown tm-docname-dropdown'
                    />
                  )}
                  <Dropdown
                    selection
                    className='od-dropdown'
                    text=' Download, Print or Email'
                    // trigger={
                    //   <div className="od-text-data-book">
                    //     Download, Print or Email
                    //   </div>
                    // }
                    disabled={
                      state.isLoading ||
                      (rowData?.length === 0 &&
                        state.searchInput?.length > 0) ||
                      rowData?.length === 0
                    }
                    options={downloadOptions}
                    direction='left'
                  />
                </div>
              )}
              {isMobile && !state.hasError && (
                <Container className='mobile-orders-tab-filters-container'>
                  <div className='oi-filter-row-left mb-0'>
                    <div>
                      {props.cart.customerInfo.currentUser &&
                        props.cart.customerInfo.currentUser.roleNo !== "W1" &&
                        state.accountFilters.length > 1 && (
                          <>
                            <PRHButton
                              className='mobile-options-button mb-1 px-1 fs-12px'
                              onClick={() => setAccountMenuVisible(true)}
                              disabled={
                                state.isLoading ||
                                rowData?.length === 0 ||
                                (rowData?.length === 0 &&
                                  state.searchInput?.length > 0)
                              }
                            >
                              {state.accountFilter != 1 ? "Acc#: " : ""}
                              {getAccountFilterText(
                                state.accountFilter,
                                state.accountFilters
                              )}{" "}
                              <Icon name='triangle down' />
                            </PRHButton>

                            <MobileOptionsMenu
                              title='Account'
                              onClose={() =>
                                setAccountMenuVisible(!accountMenuVisible)
                              }
                              onOutsideMenuClick={() =>
                                setAccountMenuVisible(!accountMenuVisible)
                              }
                              menuItems={state.accountFilters}
                              menuVisible={accountMenuVisible}
                              className='download-dropdown-catalog'
                              onOptionClick={(option) => {
                                setState({
                                  ...state,
                                  accountFilter: option.value,
                                });
                                props.history.push({
                                  pathname: "/invoices",
                                  state: {
                                    ...props.history.location.state,
                                    accountFilter: option.value,
                                  },
                                });
                              }}
                              radio
                              currentSort={state.accountFilter}
                            />
                          </>
                        )}
                    </div>

                    <div>
                      {props.cart.customerInfo.currentUser &&
                        props.cart.customerInfo.currentUser.roleNo !== "W1" &&
                        state.accountFilters.length > 1 && (
                          <>
                            <PRHButton
                              className='mobile-options-button mb-1 px-1 fs-12px'
                              onClick={() => setMobileDocumentMenuVisible(true)}
                              disabled={
                                state.isLoading ||
                                rowData?.length === 0 ||
                                (rowData?.length === 0 &&
                                  state.searchInput?.length > 0)
                              }
                            >
                              {getMobileDocumentFilterText(
                                state.mobileDocumentFilter,
                                state.mobileDocumentFilters
                              )}
                              {state.mobileDocumentFilter != 1 ? "s " : " "}
                              <Icon name='triangle down' />
                            </PRHButton>

                            <MobileOptionsMenu
                              title='Document'
                              onClose={() =>
                                setMobileDocumentMenuVisible(
                                  !mobileDocumentMenuVisible
                                )
                              }
                              onOutsideMenuClick={() =>
                                setMobileDocumentMenuVisible(
                                  !mobileDocumentMenuVisible
                                )
                              }
                              menuItems={state.mobileDocumentFilters}
                              menuVisible={mobileDocumentMenuVisible}
                              className='download-dropdown-catalog'
                              onOptionClick={(option) => {
                                setState({
                                  ...state,
                                  mobileDocumentFilter: option.value,
                                  documentFilter: option.value,
                                });
                                props.history.push({
                                  pathname: "/invoices",
                                  state: {
                                    ...props.history.location.state,
                                    mobileDocumentFilter: option.value,
                                    documentFilter: option.value,
                                  },
                                });
                              }}
                              radio
                              currentSort={state.mobileDocumentFilter}
                            />
                          </>
                        )}
                    </div>

                    <div>
                      <PRHButton
                        className='mobile-options-button mb-1 px-1 fs-12px'
                        onClick={() => setSortMenuVisible(true)}
                        disabled={state.isLoading}
                      >
                        {
                          dateFilters.find((d) => d.value === state.dayFilter)
                            .text
                        }
                        <Icon name='triangle down' />
                      </PRHButton>
                      <MobileOptionsMenu
                        title='Sort By'
                        onClose={() => setSortMenuVisible(!sortMenuVisible)}
                        onOutsideMenuClick={() =>
                          setSortMenuVisible(!sortMenuVisible)
                        }
                        menuItems={dateFilters}
                        menuVisible={sortMenuVisible}
                        className='download-dropdown-catalog'
                        onOptionClick={(option) => {
                          setState({
                            ...state,
                            dayFilter: option.value,
                            searchInput: [],
                            hasInput: false,
                          });
                          props.history.push({
                            pathname: "/invoices",
                            state: {
                              ...props.history.location.state,
                              dayFilter: option.value,
                            },
                          });
                        }}
                        radio
                        currentSort={state.dayFilter}
                      />
                    </div>
                  </div>
                </Container>
              )}

              {!isMobile && !state.hasError && (
                <div>
                  <Form onSubmit={onFilterTextBoxChanged}>
                    <Input
                      className={`ot-input ${
                        state.searchInput?.length > 0 ? "active" : ""
                      }`}
                      id='searchlist'
                      name='searchlist'
                      type='text'
                      value={
                        state.searchInput.length > 0
                          ? state.searchInput[0].toString().trim()
                          : ""
                      }
                      icon={{
                        name: "search",
                        link: true,
                        onClick: () => onFilterTextBoxChanged(),
                      }}
                      onKeyPress={search}
                      onChange={(e, data) => {
                        if (data.value !== "") {
                          setState({
                            ...state,
                            hasInput: true,
                            searchInput: [data.value],
                          });
                        } else if (data.value === "") {
                          setState({
                            ...state,
                            searchInput: [],
                            hasInput: false,
                          });
                        }
                      }}
                      disabled={state.isLoading}
                      placeholder={"Search Order #, Invoice #, or ISBN"}
                    />
                  </Form>
                </div>
              )}
            </div>
            {!isMobile && rowData && rowData.length > 0 && (
              <div className='ag-theme-alpine ot-order-table'>
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={state.columnDefs}
                  rowData={rowData}
                  rowHeight={state.rowHeight}
                  rowSelection={"multiple"}
                  suppressContextMenu={true}
                  gridOptions={{
                    icons: {
                      sortAscending: () => caretUpIcon.node[0],
                      sortDescending: () => caretDownIcon.node[0],
                    },
                  }}
                  onPaginationChanged={(props) => {
                    window.scrollTo(0, 0);
                    setState({
                      ...state,
                      currentPageNum: props.api.paginationGetCurrentPage() + 1,
                      totalPages: props.api.paginationGetTotalPages(),
                    });
                  }}
                  suppressRowClickSelection={true}
                  pagination={true}
                  defaultColDef={{
                    sortable: true,
                    minWidth: 110,
                    flex: 1,
                    resizable: true,
                    cellClass: "stringRow",
                    suppressMenu: true,
                    suppressMovable: true,
                  }}
                  frameworkComponents={{
                    invoiceNumberRenderer: invoiceNumberRenderer,
                    customLoadingOverlay: () => (
                      <div className='ot-no-rows ag-overlay-loading-center'>
                        {!state.hasError ? "Loading..." : ""}
                      </div>
                    ),
                  }}
                  columnKeys={[
                    "billingDocNumber",
                    "docName",
                    "dateCreated",
                    "purchaseOrderNumber",
                    "soldToAccount",
                    "grossValue",
                  ]}
                  excelStyles={[
                    {
                      id: "stringRow",
                      dataType: "string",
                    },
                  ]}
                  onFirstDataRendered={(dataProps) => {
                    setState({ ...state, currentPageNum: propPage });
                    /*
                    setState({
                      ...state,
                      totalPages: dataProps.api.paginationGetTotalPages(),
                      currentPageNum: propPage,
                    });*/
                    dataProps.api.paginationGoToPage(propPage - 1);
                  }}
                  suppressPaginationPanel={true}
                  paginationPageSize={state.pageSize}
                  domLayout='autoHeight'
                  rowClass='grid-row-style'
                  headerHeight={state.rowHeight}
                  onSelectionChanged={(e) =>
                    setState({
                      ...state,
                      selectedRows: e.api.getSelectedRows(),
                    })
                  }
                  overlayNoRowsTemplate='No invoices'
                  loadingOverlayComponent={"customLoadingOverlay"}
                />
              </div>
            )}
            {isMobile && <Divider tight className='mt-2 mb-2' />}
            {isMobile && !state.isLoading && (
              <Container className='mb-4'>
                <MobileTable
                  pageType='invoice'
                  columns={mobileTableColumnsInvoices}
                  data={mobileRowData}
                  noDataMessage={
                    mobileRowData &&
                    mobileRowData.length === 0 &&
                    state.searchInput?.length === 0 ? (
                      <div className='ot-no-rows-height ml-1'>
                        <div className='ot-no-rows'>
                          Your invoices will appear here.
                        </div>
                        <div className='ot-no-rows-subheader'>
                          Please check back later.
                        </div>
                      </div>
                    ) : mobileRowData &&
                      mobileRowData.length === 0 &&
                      state.searchInput?.length > 0 ? (
                      <div className='ot-no-rows-height'>
                        <div className='ot-no-rows'>No results found.</div>
                        <div className='ot-no-rows-subheader'>
                          Make sure your search request is spelled correctly or
                          try different keywords.
                        </div>
                      </div>
                    ) : null
                  }
                />
              </Container>
            )}

            {state.isLoading && isMobile && (
              <div className='ot-no-rows ot-no-rows-height'>Loading...</div>
            )}

            {(!rowData || rowData?.length === 0) &&
              state.isLoading &&
              !isMobile && (
                <div className='ot-no-rows ot-no-rows-height'>Loading...</div>
              )}

            {!isMobile &&
              rowData &&
              rowData.length === 0 &&
              state.searchInput.length === 0 &&
              !state.isLoading && (
                <div className='ot-no-rows-height'>
                  <div className='ot-no-rows ml-1'>
                    Your invoices will appear here.
                  </div>
                  <div className='ot-no-rows-subheader'>
                    Please check back later.
                  </div>
                </div>
              )}
            {!isMobile &&
              rowData &&
              rowData.length === 0 &&
              state.searchInput.length > 0 &&
              !state.isLoading && (
                <div className='ot-no-rows-height'>
                  <div className='ot-no-rows'>No results found.</div>
                  <div className='ot-no-rows-subheader'>
                    Make sure your search request is spelled correctly or try
                    different keywords.
                  </div>
                </div>
              )}
            {state.hasError && (
              <div className='ot-message'>
                <Message
                  header='Sorry, we are unable to display your invoices at this time.'
                  content='Please try again later or contact Customer Service.'
                  icon={
                    <Icon name='exclamation triangle' color='red' size='mini' />
                  }
                  color='red'
                  warning={true}
                />
              </div>
            )}
            {!isMobile && !state.isLoading && rowData && (
              <div className='ot-bottom-nav-tab'>
                <div style={{ width: "16em" }}>{""}</div>
                {state.totalPages > 1 ? (
                  <div className='ot-pagination'>
                    <PRHButtonStandard
                      className='sidePageBtns'
                      displayInlineFlex
                      disabled={
                        (state.currentPageNum === 1 ? true : false) ||
                        state.isLoading ||
                        rowData.length === 0
                      }
                      size='small'
                      icon='arrow left'
                      key={Math.random()}
                      onClick={() => {
                        gridApi.paginationGoToPreviousPage();
                        setState({
                          ...state,
                          currentPageNum: state.currentPageNum - 1,
                        });
                        props.history.push({
                          pathname: "/invoices",
                          search: `?page=${state.currentPageNum - 1}`,
                          state: {
                            ...props.history.location.state,
                            currentPageNum: state.currentPageNum - 1,
                          },
                        });
                      }}
                    />
                    <div className='ml-2 mr-2'>
                      Page {state.currentPageNum} of {state.totalPages}
                    </div>
                    <PRHButtonStandard
                      displayInlineFlex
                      disabled={
                        (state.currentPageNum === state.totalPages
                          ? true
                          : false) ||
                        state.isLoading ||
                        rowData.length === 0
                      }
                      className='sidePageBtns'
                      size='small'
                      icon='arrow right'
                      key={Math.random()}
                      onClick={() => {
                        gridApi.paginationGoToNextPage();
                        setState({
                          ...state,
                          currentPageNum: state.currentPageNum + 1,
                        });
                        props.history.push({
                          pathname: "/invoices",
                          search: `?page=${state.currentPageNum + 1}`,
                          state: {
                            ...props.history.location.state,
                            currentPageNum: state.currentPageNum + 1,
                          },
                        });
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {!isMobile && rowData && rowData.length > 0 && (
                  <div
                    className='number-of-results-holder'
                    style={{ width: "24em" }}
                  >
                    <div className='fort-medium fs-14px lh-18px ml-2 number-of-results'>
                      {state.accountFilter > 1 || state.documentFilter !== 1
                        ? gridApi?.getDisplayedRowCount()
                        : rowData?.length}{" "}
                      results
                    </div>
                    {rowData.length > 50 && (
                      <div>
                        <Dropdown
                          selection
                          disabled={
                            state.isLoading || (rowData && rowData.length === 0)
                          }
                          options={pageSizeOptions}
                          value={state.pageSize}
                          direction='left'
                          onChange={(e, { value }) => {
                            setState({
                              ...state,
                              pageSize: value,
                              currentPageNum: 1,
                              totalPages: Math.ceil(rowData.length / value),
                            });
                            props.history.push({
                              pathname: "/invoices",
                              state: {
                                ...props.history.location.state,
                                pageSize: value,
                                currentPageNum: value,
                                totalPages: Math.ceil(rowData.length / value),
                              },
                            });
                            gridApi.paginationSetPageSize(value);
                          }}
                          className='tm-dropdown ot-dropdown'
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <DialogModal
              setOpen={(value) =>
                setState({ ...state, isEmailModalVisible: value })
              }
              open={state.isEmailModalVisible}
              dialogOptions={{
                header: "Email Invoices",
                actions: [
                  {
                    label: "Cancel",
                    action: () => {
                      return { proceed: true };
                    },
                  },
                  {
                    textColorOverride: "white",
                    backgroundColorOverride: "blue",
                    borderColorOverride: "blue",
                    hidden: false,
                    bold: true,
                    label: "Ok",
                    preAction: async () => {
                      if (state.selectedRows.length > 0) {
                        const documentNumber = state.selectedRows.map(
                          (row) => row.billingDocNumber
                        );
                        await performEmailInvoices({
                          documentNumber,
                        });
                      } else {
                        const documentNumber = rowData.map(
                          (row) => row.billingDocNumber
                        );
                        await performEmailInvoices({
                          documentNumber,
                        });
                      }
                      return { proceed: true };
                    },
                    action: () => {
                      return { proceed: true };
                    },
                  },
                ],
              }}
            >
              <p className='paragraph-1'>
                <span className='fort-bold'>
                  Are you sure you want to email the following invoices to{" "}
                  {props.user.user.profile.email}
                </span>
                <br />
                <br />
                <em className='fort-book' style={{ whiteSpace: "pre-wrap" }}>
                  {(state.selectedRows.length > 0
                    ? (state.selectedRows || []).map(
                        (row) => row.billingDocNumber
                      )
                    : (rowData || []).map((row) => row.billingDocNumber)
                  ).join("\n")}
                </em>
              </p>
            </DialogModal>
            <DialogModal
              setOpen={(value) =>
                setState({ ...state, isEmailErrorModalVisible: value })
              }
              open={state.isEmailErrorModalVisible}
              dialogOptions={{
                header: "Email Invoices",
                actions: [
                  {
                    textColorOverride: "white",
                    backgroundColorOverride: "blue",
                    borderColorOverride: "blue",
                    hidden: false,
                    bold: true,
                    label: "Ok",
                    preAction: async () => {
                      return { proceed: true };
                    },
                    action: () => {
                      return { proceed: true };
                    },
                  },
                ],
              }}
            >
              <p className='paragraph-1'>
                <span className='fort-bold'>
                  You can only select up to 20 invoices at a time to be emailed
                </span>
              </p>
            </DialogModal>
          </div>
        );
      })
    )
  )
);
