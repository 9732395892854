import { Api } from "./api";
import {
  NEWS_FROM_PRH_CATEGORY_TAXONOMY,
  NEWS_FROM_PRH_POST_TYPE,
  TITLE_CHANGES_CATEGORY_TAXONOMY,
  TITLE_CHANGES_POST_TYPE,
  POST_POST_TYPE,
  POST_CATEGORY_TAXONOMY,
} from "../utils/wordpressHelpers";

const prepareBizContentUrl = (url, paramObjects = {}) => {
  // console.log("prepare", url, paramObjects);
  paramObjects["_embed"] = true;

  const params = Object.entries(paramObjects)
    .map((pair) => `${pair[0]}=${pair[1]}`)
    .join("&");
  params.push;
  // console.log("params", params);
  const bUrl = `${url}?${params}`;
  // const urlObject = new URL(url);
  // const urlParams = urlObject.searchParams;
  // Object.keys(paramObjects).forEach((key) => {
  //   urlParams.set(key, paramObjects[key]);
  // });
  // urlParams.set("_embed", "true");
  // urlObject.search = urlParams.toString();
  // url = urlObject.toString();
  // console.log("return url", bUrl);
  return bUrl;
};

const apiBase = `/content`;
const newsFromPRHBase = `/content/wp/v2/${NEWS_FROM_PRH_POST_TYPE}`;
const titleChangesBase = `/content/wp/v2/${TITLE_CHANGES_POST_TYPE}`;
const postsBase = `/content/wp/v2/${POST_POST_TYPE}`;
const titleChangesCategoryBase = `/content/wp/v2/${TITLE_CHANGES_CATEGORY_TAXONOMY}`;
const newsFromPRHsCategoryBase = `/content/wp/v2/${NEWS_FROM_PRH_CATEGORY_TAXONOMY}`;
const postsCategoryBase = `/content/wp/v2/${POST_CATEGORY_TAXONOMY}`;

const pinnednewsFromPRHsUrl = `/content/biz-data/v1/pinned/${NEWS_FROM_PRH_POST_TYPE}`;
const pinnedTitleChangesUrl = `/content/biz-data/v1/pinned/${TITLE_CHANGES_POST_TYPE}`;
const getGlobalMessageUrl = `/content/biz-data/v1/login-homepage-message`;
const getGlobalJ9MessageUrl = `/content/biz-data/v1/j9-homepage-message`;
const getGlobalNonJ9MessageUrl = `/content/biz-data/v1/non-j9-homepage-message`;
const getGlobalCompCopyMessageUrl = `/content/biz-data/v1/comp-copy-homepage-message`;
const homepagePostsUrl = `/content/biz-data/v1/all-posts`;

export const bizcontentApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    searchBizContent: builder.query({
      query: ({
        searchQuery,
        postType = NEWS_FROM_PRH_POST_TYPE,
        category = null,
        page = 1,
      }) => {
        const headers = {};

        // console.log('searchBizContent',searchQuery, postType,category,page);
        let d = null;
        let url;
        let categoryTaxonomy;

        if (postType === NEWS_FROM_PRH_POST_TYPE) {
          categoryTaxonomy = NEWS_FROM_PRH_CATEGORY_TAXONOMY;
          url = newsFromPRHBase;
        }
        if (postType === TITLE_CHANGES_POST_TYPE) {
          categoryTaxonomy = TITLE_CHANGES_CATEGORY_TAXONOMY;
          url = titleChangesBase;
        }
        if (postType === POST_POST_TYPE) {
          categoryTaxonomy = POST_CATEGORY_TAXONOMY;
          url = postsBase;
        }

        const queryParams = {};
        if (!!page) {
          queryParams.page = page;
        }

        if (!!category) {
          let actualCategory = category?.id;
          if (!actualCategory) {
            actualCategory = category;
          }
          queryParams[categoryTaxonomy] = actualCategory;
        }
        if (!!searchQuery) {
          queryParams.search = searchQuery;
        }
        url = prepareBizContentUrl(url, queryParams);

        return {
          url: url,
          method: "GET",
          headers,
        };
      },
      transformResponse: (response, meta, arg) => {
        // console.log("search transform", response, meta, arg);
        if (response.length === 0) {
          const result = [];
          result._paging = {
            totalPages: 0,
            total: 0,
          };
          return result;
        } else {
          const result = [...response];
          if (meta.response.headers.get("X-WP-TOTALPAGES")) {
            result._paging = {
              totalPages: meta.response.headers.get("X-WP-TOTALPAGES"),
              total: meta.response.headers.get("X-WP-TOTAL"),
            };
          } else {
            result._paging = {
              totalPages: 0,
              total: 0,
            };
          }

          return result;
        }
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - searchBizContent", arg);
      },
    }),
    getGlobalMessage: builder.query({
      query: () => {
        const headers = {};

        return {
          url: getGlobalMessageUrl,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getGlobalMessage", arg);
      },
    }),
    getJ9GlobalMessage: builder.query({
      query: () => {
        const headers = {};

        return {
          url: getGlobalJ9MessageUrl,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getJ9GlobalMessage", arg);
      },
    }),
    getGlobalCompCopyMessage: builder.query({
      query: () => {
        const headers = {};

        return {
          url: getGlobalCompCopyMessageUrl,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getGlobalCompCopyMessage", arg);
      },
    }),
    getNonJ9GlobalMessage: builder.query({
      query: () => {
        const headers = {};

        return {
          url: getGlobalNonJ9MessageUrl,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getNonJ9GlobalMessage", arg);
      },
    }),
    getHomepagePosts: builder.query({
      query: () => {
        const headers = {};

        return {
          url: homepagePostsUrl,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getHomepagePosts", arg);
      },
    }),
    getFeaturedPost: builder.query({
      query: ({ postType = NEWS_FROM_PRH_POST_TYPE }) => {
        const headers = {};

        let url = null;
        if (postType === NEWS_FROM_PRH_POST_TYPE) {
          url = pinnednewsFromPRHsUrl;
        } else if (postType === TITLE_CHANGES_POST_TYPE) {
          url = pinnedTitleChangesUrl;
        }

        //   const response = await this.get({
        //     url,
        //     ignoreError: true,
        //   });

        //   id = response.data;

        //   if (!id?.post_id) {
        //     return false;
        //   }
        //   post = await this.getPost(id.post_id, postType);

        //   if (post?.code === 'rest_forbidden' || post?.status?.status === 401) {
        //     return false;
        //   }

        //   return post;
        // }

        return {
          url: url,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getFeaturedPost", arg);
      },
    }),
    getPost: builder.query({
      query: ({ id, postType = NEWS_FROM_PRH_POST_TYPE }) => {
        const headers = {};
        // console.log("getPost called", id);

        let url = "";
        if (postType === NEWS_FROM_PRH_POST_TYPE) {
          url = `${newsFromPRHBase}/${id}`;
        }
        if (postType === TITLE_CHANGES_POST_TYPE) {
          url = `${titleChangesBase}/${id}`;
        }
        if (postType === POST_POST_TYPE) {
          url = `${postsBase}/${id}`;
        }

        url = prepareBizContentUrl(url);

        return {
          url: url,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug && console.log("onQueryStarted - getPost", arg);
      },
    }),
    getPosts: builder.query({
      query: ({ postType, category = null, page = 1, perPage = 20 }) => {
        const headers = {};
        // console.log('getPosts 2',postType, category,page,perPage)
        let d = null;
        let url;
        let categoryTaxonomy;
        if (postType === NEWS_FROM_PRH_POST_TYPE) {
          categoryTaxonomy = NEWS_FROM_PRH_CATEGORY_TAXONOMY;
          url = newsFromPRHBase;
        }
        if (postType === TITLE_CHANGES_POST_TYPE) {
          categoryTaxonomy = TITLE_CHANGES_CATEGORY_TAXONOMY;
          url = titleChangesBase;
        }
        if (postType === POST_POST_TYPE) {
          categoryTaxonomy = POST_CATEGORY_TAXONOMY;
          url = postsBase;
        }

        const queryParams = {};
        if (!!page) {
          queryParams.page = page;
        }
        if (!!category) {
          queryParams[categoryTaxonomy] = category.id;
        }

        if (!!perPage) {
          queryParams.per_page = perPage;
        }
        url = prepareBizContentUrl(url, queryParams);
        return {
          url: url,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug && console.log("onQueryStarted - getPosts", arg);
      },
      transformResponse: (response, meta, arg) => {
        // console.log("getposts transform", response, meta, arg);
        if (response.length === 0) {
          const result = [];
          result._paging = {
            totalPages: 0,
            total: 0,
          };
          return result;
        } else {
          const result = [...response];
          if (meta.response.headers.get("X-WP-TOTALPAGES")) {
            result._paging = {
              totalPages: meta.response.headers.get("X-WP-TOTALPAGES"),
              total: meta.response.headers.get("X-WP-TOTAL"),
            };
          } else {
            result._paging = {
              totalPages: 0,
              total: 0,
            };
          }

          return result;
        }
      },
    }),
    getData: builder.query({
      query: ({ state, group }) => {
        const headers = {};

        let url = `/content/biz-data/v1/data/${state}`;
        if (group) {
          url += `/${group}`;
        }

        return {
          url: url,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug && console.log("onQueryStarted - getData", arg);
      },
    }),
    getTerms: builder.query({
      query: ({
        taxonomy = NEWS_FROM_PRH_CATEGORY_TAXONOMY,
        searchQuery = null,
        hideEmpty = true,
      }) => {
        const headers = {};

        let url;
        if (taxonomy === TITLE_CHANGES_CATEGORY_TAXONOMY) {
          url = titleChangesCategoryBase;
        }
        if (taxonomy === NEWS_FROM_PRH_CATEGORY_TAXONOMY) {
          url = newsFromPRHsCategoryBase;
        }
        if (taxonomy === POST_CATEGORY_TAXONOMY) {
          url = postsCategoryBase;
        }
        const queryParams = {
          per_page: 99,
        };
        if (!!searchQuery) {
          queryParams.search = searchQuery;
        }
        url = prepareBizContentUrl(url, queryParams);

        return {
          url: url,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug && console.log("onQueryStarted - getTerms", arg);
      },
      transformResponse: (response, meta, arg) => {
        // console.log("search transform", response, meta, arg);

        if (!arg?.hideEmpty) {
          return response.filter((r) => r.count > 0);
        } else {
          return response;
        }
      },
    }),
    getTermObjectFromSlug: builder.query({
      query: ({ slug, postType }) => {
        const headers = {};
        // console.log('getTermobjfromslug',slug,postType);
        let result = [];
        let url;
        if (postType === TITLE_CHANGES_POST_TYPE) {
          url = titleChangesCategoryBase;
        }
        if (postType === NEWS_FROM_PRH_POST_TYPE) {
          url = newsFromPRHsCategoryBase;
        }
        if (postType === POST_POST_TYPE) {
          url = postsCategoryBase;
        }

        url = prepareBizContentUrl(url, { slug: slug });

        return {
          url: url,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getTermObjectFromSlug", arg);
      },
    }),
  }),
});

export const {
  useSearchBizContentQuery,
  useLazySearchBizContentQuery,
  useGetGlobalMessageQuery,
  useLazyGetGlobalMessageQuery,
  useGetJ9GlobalMessageQuery,
  useLazyGetJ9GlobalMessageQuery,
  useGetGlobalCompCopyMessageQuery,
  useLazyGetGlobalCompCopyMessageQuery,
  useGetNonJ9GlobalMessageQuery,
  useLazyGetNonJ9GlobalMessageQuery,
  useGetHomepagePostsQuery,
  useLazyGetHomepagePostsQuery,
  useGetFeaturedPostQuery,
  useLazyGetFeaturedPostQuery,
  useGetPostQuery,
  useLazyGetPostQuery,
  useGetPostsQuery,
  useLazyGetPostsQuery,
  useGetDataQuery,
  useLazyGetDataQuery,
  useGetTermsQuery,
  useLazyGetTermsQuery,
  useGetTermObjectFromSlugQuery,
  useLazyGetTermObjectFromSlugQuery,
} = bizcontentApi;
