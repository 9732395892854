import React from "react";
import PropTypes from "prop-types";
import { Loader, Modal } from "semantic-ui-react";

function BridgeReceivingItemsModal(props) {
  const { open, setOpen, listName } = props;

  return (
    <Modal
      className='br-receiving-modal'
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeOnDimmerClick={false}
    >
      <Modal.Content>
        <div className='d-flex flex-column align-items-center'>
          <Loader size='big'></Loader>
          <div className='d-flex flex-column fs-18px lh-24px align-items-center pb-4'>
            <div>Receiving items from</div>
            <div
              className='d-flex aligh-items-center fort-bold'
              style={{
                paddingLeft: "50px",
                paddingRight: "50px",
                textAlign: "center",
              }}
            >
              {listName}
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

BridgeReceivingItemsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default BridgeReceivingItemsModal;
