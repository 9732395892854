import PropTypes from "prop-types";
import LazyImage from "../../utils/LazyImage";
import "./WarehouseLocation.scss";

const WarehouseLocation = (props) => {
  // console.log('WarehouseLocation', props);
  const { warehouse, className = false } = props;

  if (!!!warehouse) return <></>;
  if (warehouse !== "Reno, NV") return <></>;

  return (
    <div className='d-flex flex-row mt-1 mb-1 warehouse-location'>
      <div className='mr-1 warehouse-icon'>
        <LazyImage src={"/images/Truck.svg"} />
      </div>
      <div className='fort-bold fs-12px lh-15'>Shipping from {warehouse}</div>
    </div>
  );
};

export default WarehouseLocation;

WarehouseLocation.propTypes = {};

WarehouseLocation.defaultProps = {};
