import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import ContentCard from "./ContentCard";
import './NoticeCard.scss';

const NoticeCard = ({ children, className, margin = 'my-2', icon }) => {
  return (
    <ContentCard className={`notice-card ${margin} p-1 ${className} `}>
      <FontAwesomeIcon
        icon={icon || faExclamationCircle}
        className="notice-icon mr-1"
        color="#FA6400"
      />
      {children}
    </ContentCard>
  );
};

export default NoticeCard;
