// import "intro.js/introjs.css";
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Container,
  Dropdown,
  Header,
  Icon,
  Image,
  Input,
  Label,
} from "semantic-ui-react";
import Logo from "../../assets/images/prh-logo-white-redux.svg"; //prh-logo-small.png';
import LogoMobile from "../../assets/images/prh-logo-white-mobile.svg";
import EventTrigger from "../../components/analytics/EventTrigger";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import CartBadge from "../cart/CartBadge";
import DropdownStoreSelector from "./components/DropdownStoreSelector";
import { formatDateMonthYear } from "../../utils/utilities";
import {
  translatePost,
  translatePostTypeSlug,
} from "../../utils/wordpressHelpers";
import { CartIcon } from "./components/CartIcon";
import "./pageHeader.scss";
import "../../pages/title/TitleMetadata.scss";
import { HeaderMenu } from "./components/HeaderMenu";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import useCart from "../hc/useCart";
import classNames from "classnames";

const addIsbnDialogOopen = false;
const itemHieght = window.innerHeight * 0.6;

const pageHeader = (props) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });

  const cart = useCart();
  const [searchTerm, setSearchTerm] = useState("");
  const [visible, setVisible] = useState(true);
  const [menuVisible, setMenuVisible] = useState(undefined);

  const [searchVisible, setSearchVisible] = useState(
    !(props.history.location.pathname === "/")
  );
  const isLvl1 = props.cart?.customerInfo?.currentUser?.roleNo === "W1";

  // const [shipTo, setShipTo] = useState(0);

  var doc = document.documentElement;
  var w = window;
  var prevScroll = w.scrollY || doc.scrollTop;
  var direction = 0;

  var prevDirection = 0;
  const checkScroll = function () {
    var curScroll;

    curScroll = w.scrollY || doc.scrollTop;

    if (curScroll > prevScroll) {
      //scrolled up
      direction = 2;
    } else if (curScroll < prevScroll) {
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }

    if (props.history.location.pathname === "/") {
      toggleSearch(curScroll);
    }

    prevScroll = curScroll;
  };

  const toggleSearch = function (curScroll) {
    if (curScroll <= 192) {
      setSearchVisible(false);
    } else if (!searchVisible && curScroll > 200) {
      setSearchVisible(true);
    }
  };

  const toggleHeader = function (direction, curScroll) {
    if (direction === 2 && curScroll > 50) {
      setVisible(false);

      prevDirection = direction;
    } else if (direction === 1) {
      setVisible(true);

      prevDirection = direction;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);

    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  //tutorial  from here
  // const [steps, setSteps] = useState([{
  //     element: ".bizSearch",
  //     intro: "Search ISBN, title, author.",
  //   },
  //   {
  //     element: ".orders-dropdown",
  //     intro: "Orders, invoices, claims.",
  //   },
  //   {
  //     element: ".account-dropdown",
  //     intro:
  //       "Billing and details, Account Reps, Manage users, Change password, promotions or sign out.",
  //   },
  //   {
  //     element: ".cart-badge",
  //     intro: "Check your cart items.",
  //   },]);
  // const [showFinalModal, setShowFinalModal] = useState(false);
  // const [stepsEnabled, setStepsEnabled] = useState(false);
  // const [initialStep, setInitialStep] = useState(0);

  // const onExit = () => {
  //   setStepsEnabled(false);
  //   setShowFinalModal(true);
  // };

  // toggleSteps = () => {

  //   this.setState(() => ({ stepsEnabled: false }));
  //   this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  // };
  //till here

  const handleClose = () => {
    if (!addIsbnDialogOopen) {
      props.cart.setCartBadgeOpen(false);
    }
  };

  let headerVar = "";
  const appType = props.user.isAppBiz() ? "Self-Service" : "Comp Copy";

  const isCartBadge = () => {
    if (props.user.isAppCompCopy()) return true;

    if (props.cart?.customerInfo?.currentUser?.roleNo !== "W1") return true;

    return false;
  };

  const renderMenuItemRow = (to, text) => {
    const classes = classNames("header-dropdown-item-text", {
      "item-display-none": text === "Deliveries" && props.user.isAppCompCopy(),
    });
    return (
      <Header
        content={
          <Link to={to} className={classes}>
            {text}
          </Link>
        }
        onClick={() => setMenuVisible(false)}
      />
    );
  };

  let accountArray = [];
  if (props.user.isAppBiz()) {
    if (props.user !== null && props.user.user && props.user.user.profile) {
      const userName = props.user.user.profile.given_name;
      accountArray = [
        {
          key: "user",
          className: "dropdown-item",
          content: (
            <Header
              content={`Hello ${userName}`}
              className='header-dropdown-header-text'
            />
          ),
        },
        {
          key: "bd",
          text: "Account Details",
          className: "dropdown-item",
          content: renderMenuItemRow(
            "/admin/account-details",
            //'Account Details'
            "Billing and Details"
          ),
        },
        /*
        {
          key: 'scc',
          text: 'Saved Credit Cards',
          className: 'dropdown-item',
          content: renderMenuItemRow(
            '/admin/saved-credit-cards',
            'Saved Credit Cards'
          ),
        },
        */
        {
          key: "ar",
          text: "Account Reps",
          className: "dropdown-item",
          content: renderMenuItemRow("/admin/account-reps", "Account Reps"),
        },
        {
          key: "mu",
          text: "Manage Users",
          className: "dropdown-item",
          content: renderMenuItemRow("/admin/account-users", "Manage Users"),
        },
        {
          key: "cp",
          text: "Change Password",
          className: "dropdown-item",
          content: renderMenuItemRow(
            "/admin/change-password",
            "Change password"
          ),
        },
      ];

      // if (isCartBadge() && !cart.isUserPow()) {
      if (props.cart?.customerInfo?.currentUser?.roleNo !== "W1") {
        accountArray.push({
          key: "promo",
          text: "Promotions",
          className: "dropdown-item",
          content: renderMenuItemRow("/admin/promotions", "Promotions"),
        });
        accountArray.splice(2, 0, {
          key: "scc",
          text: "Saved Credit Cards",
          className: "dropdown-item",
          content: renderMenuItemRow(
            "/admin/saved-credit-cards",
            "Saved Credit Cards"
          ),
        });
      }
      // }
      if (!isMobile) {
        accountArray.push({
          key: `account-separator`,
          className: "dropdown-item dropdown-separator-container",
          content: <div className='dropdown-separator' />,
        });
      }
      if (!isMobile) {
        accountArray.push({
          key: "signout",
          className: "dropdown-item",
          content: renderMenuItemRow("/logout", "Sign Out"),
        });
      }
    }
  } else {
    if (props.user !== null && props.user.user && props.user.user.profile) {
      const userEmail = props.user.user.profile.email;
      const userName = props.user.user.profile.given_name;
      accountArray = [
        {
          key: "user",
          className: "dropdown-item",
          content: (
            <Header
              content={`Hello ${userName}`}
              className='header-dropdown-header-text'
            />
          ),
        },
      ];
      if (!isMobile) {
        accountArray.push({
          key: `account-separator`,
          className: "dropdown-item dropdown-separator-container",
          content: <div className='dropdown-separator' />,
        });
        accountArray.push({
          key: "signout",
          text: "Signout",
          className: "dropdown-item",
          content: renderMenuItemRow("/logout", "Sign Out"),
        });
      }
    }
  }
  // {console.log('ordersArray', props)}
  let ordersArray = [
    {
      key: "orders",
      className: "dropdown-item",
      content: renderMenuItemRow("/orders", "Orders"),
    },
    {
      key: "deliveries",
      className: "dropdown-item",
      content: renderMenuItemRow("/deliveries", "Deliveries"),
    },

    props.user.isAppBiz() && {
      key: "ic",
      className: "dropdown-item",
      content: renderMenuItemRow("/invoices", "Invoices & Claims"),
    },
    props.user.isAppBiz() && !isMobile &&
      props.cart?.customerInfo?.currentUser?.roleNo === "W9" && {
        key: "statements",
        className: "dropdown-item",
        content: renderMenuItemRow("/statements", "Statements"),
      },
    {
      key: "backorders",
      className: "dropdown-item",
      content: renderMenuItemRow("/backorders", "Backorders"),
    },
    props.cart?.isUserPow() && {
      key: "returns",
      className: "dropdown-item",
      content: renderMenuItemRow("/returns", "Comic Book Affidavit Return"),
    },
  ].filter((item) => item);

  const catalogs =
    props.cart?.activeCatalogs?.length > 0
      ? props.cart.activeCatalogs.map((catalog) => {
          return {
            key: `catalog-${catalog.val}`,
            className: "dropdown-item",
            content: renderMenuItemRow(
              `/catalog/${catalog.val}`,
              formatDateMonthYear(catalog.val)
            ),
          };
        })
      : [
          {
            key: `no-catalogs`,
            className: "dropdown-item",
            content: (
              <Header className='header-dropdown-smaller-header-text'>
                No current catalogs
              </Header>
            ),
          },
        ];

  const posts =
    props.cart?.recentPosts?.length > 0
      ? props.cart.recentPosts
          .map((post) => {
            const { id, type, title, meta } = translatePost(post);
            return {
              key: `post-${id}`,
              className: "dropdown-item",
              content: renderMenuItemRow(
                `/${translatePostTypeSlug(type)}/${id}`,
                title
              ),
            };
          })
          .slice(0, 5)
      : [
          {
            key: `no-posts`,
            className: "dropdown-item",
            content: (
              <Header className='header-dropdown-smaller-header-text'>
                No latest posts
              </Header>
            ),
          },
        ];

  let catalogArray = [
    {
      key: "catalog",
      className: "dropdown-item",
      content: (
        <div className='header-dropdown-smaller-header-text'>
          Latest Catalogs
        </div>
      ),
    },
    ...catalogs,
    {
      key: `catalogs-separator`,
      className: "dropdown-item dropdown-separator-container",
      content: <div className='dropdown-separator' />,
    },
    {
      key: "catalog-archive",
      className: "dropdown-item",
      content: renderMenuItemRow("/catalog-archive", "All Catalogs"),
    },
  ];

  let newsArray = [
    {
      key: "news",
      className: "dropdown-item",
      content: (
        <div className='header-dropdown-smaller-header-text'>Latest News</div>
      ),
    },
    ...posts,
    {
      key: `news-separator`,
      className: "dropdown-item dropdown-separator-container",
      content: <div className='dropdown-separator' />,
    },
    {
      key: `news-4`,
      className: "dropdown-item",
      content: renderMenuItemRow("/news-from-prh", "News from PRH"),
    },
    {
      key: `news-3`,
      className: "dropdown-item",
      content: renderMenuItemRow("/title-changes", "Title Changes"),
    },
    {
      key: `news-5`,
      className: "dropdown-item",
      content: renderMenuItemRow("/posts", "Title Collections"),
    },
  ];

  const renderHeaderItem = (icon, title) => {
    return (
      <div
        onClick={() => (props.cart.cartBadgeOpen ? handleClose() : {})}
        className='position-relative cursor-pointer inline-block header-item'
      >
        <div className='file_icon'>
          <Icon name={icon}></Icon>
        </div>
        <div className='dropdown-text d-flex flex-row'>{title}</div>
      </div>
    );
  };

  const searchSiteOnClick = () => {
    EventTrigger("Search", searchTerm);
    props.cart.performSearch(searchTerm);
  };
  const searchSite = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      EventTrigger("Search", searchTerm);
      //13 is the enter keycode
      props.cart.performSearch(searchTerm);
    }
  };

  const mobileCatalogItems = [
    ...catalogs,
    {
      key: "catalog-archive",
      className: "dropdown-item",
      content: renderMenuItemRow("/catalog-archive", "All Catalogs"),
    },
  ];
  const mobileNewsItems = [
    {
      key: `news-4`,
      className: "dropdown-item",
      content: renderMenuItemRow("/news-from-prh", "News from PRH"),
    },
    {
      key: `news-3`,
      className: "dropdown-item",
      content: renderMenuItemRow("/title-changes", "Title Changes"),
    },
    {
      key: `news-5`,
      className: "dropdown-item",
      content: renderMenuItemRow("/posts", "Title Collections"),
    },
  ];
  const mobileOrdersItems = [...ordersArray];
  const mobileAccountItems = [...accountArray].filter(
    (item) => item.key !== "user"
  );

  if (props.headerType === "loggedIn") {
    headerVar = (
      <div
        id='header-nav-relative-container'
        className={`header-nav-relative-container hide-in-print ${
          props.locationSelector &&
          props.cart.isUserPow() &&
          props.cart?.customerInfo?.currentUser?.roleNo !== "W1" &&
          props.cart.customerInfo.shipToAccountNos.length > 1
            ? "has-location-selector"
            : ""
        }`}
      >
        <div
          id='nav'
          className={`header-nav-fixed-container ${!visible ? "hide" : ""}`}
        >
          <Container className='d-flex align-items-center justify-content-space-between position-relative'>
            <div className='header-menu-icon shown-on-mobile'>
              <div
                className={`header-iconholder`}
                onClick={() => setMenuVisible(true)}
              >
                <Icon name={`${menuVisible ? "close" : "bars"}`} size='large' />
              </div>
            </div>
            <a
              href='/'
              onClick={(e) => {
                e.preventDefault();
                props.history.push("/");
              }}
              className='d-flex align-items-center site-home mr-1'
            >
              <Image src={Logo} id='logo' alt='PRH Self-Service Logo' />
              <span id='site-name'>{appType}</span>
            </a>
            <label htmlFor='search-catalog' className='visually-hidden'>
              Search
            </label>
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={searchSite}
              name='search-catalog'
              id='search-catalog'
              action={
                <Button
                  // color="none"
                  icon='search'
                  className={`search_icon ${
                    searchTerm.length > 0 ? "text_active" : "text_disabled"
                  }`}
                  onClick={() => searchSiteOnClick()}
                  disabled={
                    searchTerm.length === 0 ? true : false || !searchVisible
                  }
                />
              }
              disabled={!searchVisible}
              placeholder='Search for books, authors, series, etc.'
              className={`bizSearch ${
                !searchVisible ? "hide-biz-search" : ""
              } hidden-on-mobile`}
            />
            <div id='navitems' className='ml-1 hidden-on-mobile'>
              {props.cart.isUserPow() && (
                <Dropdown
                  trigger={renderHeaderItem("book", "Catalog")}
                  options={catalogArray}
                  className='orders-dropdown catalog-dropdown'
                  // scrolling
                  direction='right'
                />
              )}
              {props.cart.isUserPow() && (
                <Dropdown
                  trigger={renderHeaderItem("newspaper", "News")}
                  options={newsArray}
                  className='orders-dropdown news-dropdown'
                  // scrolling
                />
              )}
              <Dropdown
                trigger={renderHeaderItem("file text", "Orders")}
                options={ordersArray}
                className='orders-dropdown catalog-dropdown'
                // scrolling
                direction='right'
              />
              <Dropdown
                trigger={renderHeaderItem("user", "Account")}
                options={accountArray}
                className='orders-dropdown'
              />
              {!isLvl1 && <CartIcon addIsbnDialogOopen={addIsbnDialogOopen} />}
            </div>
            <div className='shown-on-mobile cart-icon-height'>
              {!isLvl1 && <CartIcon addIsbnDialogOopen={addIsbnDialogOopen} />}
            </div>
          </Container>
          <Container className='d-flex justify-content-space-between mobile-bar'>
            {/*<label for="search-site" className="visibility-hidden">Search books, authors, etc.</label>*/}
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={searchSite}
              id='search-site'
              name='search-site'
              action={
                <Button
                  // color="none"
                  icon='search'
                  className={`search_icon ${
                    searchTerm.length > 0 ? "text_active" : "text_disabled"
                  }`}
                  onClick={() => searchSiteOnClick()}
                  disabled={searchTerm.length === 0 ? true : false}
                />
              }
              placeholder='Search books, authors, etc.'
              className={`bizSearch shown-on-mobile`}
              //label="Search books, authors, etc."
            />
          </Container>
          <label htmlFor='search-site' className='visually-hidden'>
            Search books, authors, etc.
          </label>
          <Container style={{ position: "relative" }}>
            {props.cartBadge === true && isCartBadge() ? (
              <CartBadge key={"cartbadge"} isMobile={isMobile} />
            ) : (
              ""
            )}
          </Container>
          {props.locationSelector &&
          props.cart.isUserPow() &&
          props.cart.customerInfo?.currentUser?.roleNo !== "W1" ? (
            <DropdownStoreSelector
              shipTo={props.cart.shipTo}
              selectShipTo={(shipTo) => props.cart.setShipTo(shipTo)}
            />
          ) : null}
          {isMobile && (
            <HeaderMenu
              onClose={() => setMenuVisible(false)}
              onOutsideMenuClick={() => setMenuVisible(false)}
              profile={props.profile}
              isUserPow={props.cart.isUserPow()}
              catalogItems={mobileCatalogItems}
              newsItems={mobileNewsItems}
              ordersItems={mobileOrdersItems}
              accountItems={mobileAccountItems}
              menuVisible={menuVisible}
            />
          )}
        </div>
      </div>
    );
  } else if (props.headerType === "notLoggedIn") {
    headerVar = (
      <div id='nav' className='notLoggedIn_Nav'>
        <div id='container'>
          <Container
            fluid={isMobile}
            className={`d-flex notLoggedIn_header py-2 ${
              isMobile ? "justify-content-center full-width-mobile" : ""
            }`}
          >
            <div
              className='site-home'
              onClick={() => {
                props.history.push("/");
              }}
            >
              {isMobile ? (
                <Image src={LogoMobile} id='logo' alt='PRH Self-Service Logo' />
              ) : (
                <Image src={Logo} id='logo' alt='PRH Self-Service Logo' />
              )}
              <span id='site-name'>{appType}</span>
            </div>
            {!isMobile && (
              <PRHButton
                onClick={() => props.history.push("/registration")}
                displayInlineFlex
                className='header-create-account px-1 hover-orange'
              >
                Register an Account
              </PRHButton>
            )}
          </Container>
        </div>
      </div>
    );
  } else {
    headerVar = (
      <div id='nav'>
        <div id='container'>
          <Container className='d-flex primitive_headere pt-1 pb-1'>
            <div
              className='ml-auto mr-auto site-home'
              onClick={() => {
                props.history.push("/");
              }}
            >
              <Image src={Logo} id='logo' alt='PRH Self-Service Logo' />
              <span id='site-name'>{appType}</span>
            </div>
          </Container>
        </div>
      </div>
    );
  }
  return headerVar;
};

export default withRouter(withSecurity(withCart(pageHeader)));
