import { useState, useEffect } from "react";
import {
  Header,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import TitleRow from "../common/TitleRow";
import TitleRowMobile from "../common/TitleRowMobile";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import _ from "underscore";
import useCart from "../hc/useCart";

const CartViewConfirmedQuantitiesModal = ({
  foc,
  shipTo,
  retailAmount = 0,
  baseDiscount = 0,
  taxes = 0,
  subtotal = 0,
  open = false,
  setOpen,
  proceedCallback,
  cancelCallback,
  workCart,
  isMobile,
}) => {
  const cart = useCart();
  const [dialogVisible, setDialogVisible] = useState(false);
  const resetState = () => {
    setDialogVisible(false);
  };

  // console.log('workCart', workCart);
  const cartTotal = cart.getCartTotal(workCart.confirmedCart);
  // console.log('totals', cartTotal);

  const actionMessage = {
    actionMessage: (
      <div className='d-flex align-items-center ml-0 mr-auto mt-1 fort-book fs-13px lh-18px'>
        <div className='ccq-footer'>
          Total Items:{" "}
          <NumericFormat
            className=''
            allowNegative={false}
            value={workCart?.confirmedCart?.items?.length}
            thousandSeparator={true}
            decimalScale={0}
            fixedDecimalScale={true}
            displayType={"text"}
            // prefix={'$'}
            // suffix={'%'}
          />
        </div>
        <div className='ccq-footer'>
          Total Units:{" "}
          <NumericFormat
            className=''
            allowNegative={false}
            value={cartTotal.unitCount}
            thousandSeparator={true}
            // decimalScale={2}
            fixedDecimalScale={true}
            displayType={"text"}
            // prefix={'$'}
            // suffix={'%'}
          />
        </div>
        <div className='ccq-footer'>
          Retail Amount:{" "}
          <NumericFormat
            className=''
            allowNegative={false}
            value={cartTotal.retail}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={"text"}
            prefix={"$"}
            // suffix={'%'}
          />
        </div>
        <div className='ccq-footer'>
          Base Discount:{" "}
          <NumericFormat
            className=''
            allowNegative={false}
            value={cartTotal.discount}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={"text"}
            prefix={"-$"}
            // suffix={'%'}
          />
        </div>
        {cartTotal.tax > 0 && (
          <div className='ccq-footer'>
            Taxes:{" "}
            <NumericFormat
              className=''
              allowNegative={false}
              value={cartTotal.tax}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              prefix={"$"}
              // suffix={'%'}
            />
          </div>
        )}
        <div className='fort-bold'>
          Subtotal:{" "}
          <NumericFormat
            className=''
            allowNegative={false}
            value={cartTotal.total}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={"text"}
            prefix={"$"}
            // suffix={'%'}
          />
        </div>
      </div>
    ),
  };

  const actionMessageIsMobile = {
    actionMessage: (
      <>
        <div style={{ textAlign: "left" }}>
          <div>
            Total Items:{" "}
            <NumericFormat
              className=''
              allowNegative={false}
              value={workCart?.confirmedCart?.items?.length}
              thousandSeparator={true}
              decimalScale={0}
              fixedDecimalScale={true}
              displayType={"text"}
              // prefix={'$'}
              // suffix={'%'}
            />
            <span className='ccq-pipe'>|</span>
            Total Units:{"  "}
            <NumericFormat
              className=''
              allowNegative={false}
              value={cartTotal.unitCount}
              thousandSeparator={true}
              // decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              // prefix={'$'}
              // suffix={'%'}
            />
            <span className='ccq-pipe'>|</span>
          </div>
          <div>
            Retail Amount:{"  "}
            <NumericFormat
              className=''
              allowNegative={false}
              value={cartTotal.retail}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              prefix={"$"}
              // suffix={'%'}
            />
            <span className='ccq-pipe'>|</span>
            Base Discount:{"  "}
            <NumericFormat
              className=''
              allowNegative={false}
              value={cartTotal.discount}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
              prefix={"-$"}
              // suffix={'%'}
            />
          </div>
          <div>
            {cartTotal.tax > 0 && (
              <>
                Taxes:{"  "}
                <NumericFormat
                  className=''
                  allowNegative={false}
                  value={cartTotal.tax}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  prefix={"$"}
                  // suffix={'%'}
                />
                <span className='ccq-pipe'>|</span>
              </>
            )}
            <span style={{ fontWeight: "bold" }}>
              Subtotal:{"  "}
              <NumericFormat
                className=''
                allowNegative={false}
                value={cartTotal.total}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix={"$"}
                // suffix={'%'}
              />
            </span>
          </div>
        </div>
      </>
    ),
  };

  const ContinueAction = {
    label: "Done",
    className: isMobile ? "mobile-continue-holder" : "",
    borderColorOverride: "blue",
    textColorOverride: "white",
    textColorHoverOverride: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "white",
    iconColorOverride: "white",
    iconColorHoverOverride: "blue",
    // icon: 'checkmark',
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      resetState();
      return { proceed: true };
    },
  };

  //   useEffect(() => {
  //     if (dialogVisible) {
  //       if (!openState) {
  //         if (!actionPerformed && cancelCallback) cancelCallback();
  //         resetState();
  //         setActionPerformed(false);
  //       }
  //     }
  //     if (openState && !dialogVisible) {
  //       setDialogVisible(true);
  //     }
  //   }, [actionPerformed, cancelCallback, dialogVisible, openState]);

  return (
    <DialogModal
      open={open}
      isMobile={isMobile}
      setOpen={setOpen}
      size='large'
      // onXClose={cancelCallback}
      modalClassName='ccq-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            VIEW CONFIRMED QUANTITIES
          </Header>
        ),
        actions: [
          isMobile ? actionMessageIsMobile : actionMessage,
          ContinueAction,
        ],
      }}
    >
      <div className='ar-box'>
        <>
          <div className='ml-4 mr-4 mb-2 fs-13px lh-18px text-gray-900'>
            <div className='fort-bold'>
              The following list shows your previously confirmed quantities.
            </div>
            <div>
              Until you confirm the updates to this cart, the below quantities
              will be placed as an order on the FOC date.
            </div>
          </div>
          <Divider className='mt-0 mb-0' />
          <div
            style={{ maxHeight: "400px" }}
            className='pl-4 pr-4 pt-2 pb-2 overflow-auto bg-gray-100'
          >
            {workCart.confirmedCart &&
              _.sortBy(workCart.confirmedCart.items, function (i) {
                return i.itemId;
              })
                .reverse()
                .map((title, idx) => (
                  <div key={idx}>
                    {isMobile && (
                      <TitleRowMobile
                        key={idx}
                        data={title}
                        pageType='catalog'
                        showQty={true}
                        divider={
                          idx + 1 < workCart.confirmedCart.items.length
                            ? true
                            : false
                        }
                        //onRefreshTab={reloadCatalog}
                      />
                    )}
                    {!isMobile && (
                      <TitleRow
                        data={title}
                        pageType='dialog'
                        divider={
                          idx + 1 < workCart.confirmedCart.items.length
                            ? true
                            : false
                        }
                        key={`${idx}`}
                        // callback={reloadCatalog}
                        // addAll={addMultipleTitles}
                        // defaultQty={addMultipleTitles === true ? 0 : false}
                        shipTo={shipTo}
                        // updateTotalQty={updateTotalQty}
                        // clearAddAllQty={clearAddAllQty}
                        // resetAllQty={resetAllQty}
                        // variantsNotMetTitles={variantsNotMetTitles}
                      />
                    )}
                  </div>
                ))}
          </div>
        </>
      </div>
    </DialogModal>
  );
};

CartViewConfirmedQuantitiesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

CartViewConfirmedQuantitiesModal.defaultProps = {};

export default CartViewConfirmedQuantitiesModal;
