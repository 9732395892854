import React, { useState } from "react";
import { Container, Icon } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import useCart from "../../../components/hc/useCart";
import useSecurity from "../../../components/hc/useSecurity";
import { Divider } from "../../../shared-react-components/elements/elements";
import PRHButton from "../../../shared-react-components/elements/prhbutton";
import IECreditCardNotification from "../../../components/IECreditCardNotification";
import { MOBILE_SCREEN_WIDTH } from "../../../utils/const";
import { NumericFormat } from "react-number-format";
import {
  formatDateMMDDYYYY,
  isFocCartExpired,
  isGeneralCart,
} from "../../../utils/utilities";
import "./CartSummary.scss";
import CartUndoAllChangesWarningModal from "../../../components/modals/CartUndoAllChangesWarningModal";
import CartViewConfirmedQuantitiesModal from "../../../components/modals/CartViewConfirmedQuantitiesModal";
import ConfirmationStatus from "../../../components/common/ConfirmationStatus";
import ConfirmationCartDetailsModal from "../../../components/modals/ConfirmationCartDetailsModal";
import { useMediaQuery } from "react-responsive";

function CartSummary(props) {
  const {
    shipTo,
    foc,
    workCart,
    undoChanges,
    confirmationStatus = "",
    error = false,
    disabled = false,
    checkOutOnClick,
    cartLoaded = false,
  } = props;
  const cart = useCart();
  const user = useSecurity();
  const history = useHistory();
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });

  const [undoChangesModalOpen, setUndoChangesModalOpen] = useState(false);
  const [
    viewConfirmedQuantitiesModalOpen,
    setViewConfirmedQuantitiesModalOpen,
  ] = useState(false);
  const [visible, setVisible] = useState(true);
  const [paymentShippingDetailDialogOpen, setPaymentShippingDetailDialogOpen] =
    useState(false);

  const [cartBoxVisible, setCartBoxVisible] = useState("PENDING_UPDATE");

  const isExpired = isFocCartExpired(foc);
  const isGeneral = isGeneralCart(foc);

  let isCartSummary = true;
  if (confirmationStatus === "CONFIRMED") isCartSummary = false;
  const isCartConfirmSummary =
    cart.isUserPow() &&
    !isGeneral &&
    !isExpired &&
    (confirmationStatus === "PENDING_UPDATE" ||
      confirmationStatus === "CONFIRMED");

  const renderSummary = (confirmedSummary = false) => {
    let cartTotal = {};

    cartTotal = cart.getCartTotal(workCart);

    const statusPendingUpdate =
      cart.isUserPow() &&
      !isGeneral &&
      !isExpired &&
      confirmationStatus === "PENDING_UPDATE";

    return (
      <div
        className={`cart-summary m-0 mb-4 ${
          cart.isUserPow() && !isGeneral && confirmationStatus === "CONFIRMED"
            ? "confirmed"
            : ""
        } ${
          cart.isUserPow() &&
          !isGeneral &&
          confirmationStatus === "PENDING_UPDATE"
            ? "pending"
            : ""
        }
        ${isMobile ? "cart-summary-mobile mx-2" : ""}`}
      >
        <div
          className={`d-flex flex-column csc-header ${
            isMobile ? "p-2" : "px-3 py-2"
          } ${
            isMobile &&
            !isGeneral &&
            confirmationStatus !== "CONFIRMED" &&
            cartBoxVisible !== "CONFIRMED"
              ? "bg-gray-700 text-white"
              : ""
          }
          ${
            isMobile &&
            !isGeneral &&
            (confirmationStatus === "CONFIRMED" ||
              cartBoxVisible === "CONFIRMED")
              ? "bg-green-summary"
              : ""
          }`}
        >
          {statusPendingUpdate && cartBoxVisible === "PENDING_UPDATE" && (
            <div className={`d-flex flex-row fs-12px lh-15px mb-1`}>
              <Icon
                name={`exclamation circle`}
                className={`${
                  statusPendingUpdate && isMobile ? "text-white" : ""
                }`}
              />
              <div>UPDATED</div>
            </div>
          )}
          {cart.isUserPow() &&
            !isGeneral &&
            confirmedSummary &&
            cartBoxVisible === "CONFIRMED" && (
              <div className='d-flex flex-row fort-bold fs-12px lh-15px mb-1'>
                <Icon name='check' />
                <div>CONFIRMED</div>
              </div>
            )}

          <div
            className={`fort-book ${
              isMobile && !isGeneral ? "text-white" : "text-gray-900"
            } fs-20px lh-27px border-bottom`}
          >
            Cart Summary
          </div>
          {cart.cart.headers?.poNumber && (
            <div className='mt-1 fs-12px lh-15px'>
              <span className='fort-bold'>PO:</span>{" "}
              {cart.cart.headers.poNumber}
            </div>
          )}
        </div>

        <div
          className={`csc-content ${
            !!!visible &&
            isCartSummary &&
            isCartConfirmSummary &&
            confirmationStatus === "PENDING_UPDATE"
              ? "hide"
              : ""
          }`}
        >
          {cart.isUserPow() && !isGeneral && confirmationStatus === "NONE" ? (
            <ConfirmationStatus
              className='mb-2'
              confirmationStatus={cart.cart?.confirmationStatus}
              focDate={foc}
            />
          ) : (
            <div className='mb-2' />
          )}

          {cart.isUserPow() && confirmationStatus === "CONFIRMED" && (
            <>
              <div className={`pl-3 pr-3 mt-2`}>
                The quantities in this cart will be placed as an order & start
                processing after 11:59PM EST on {formatDateMMDDYYYY(foc, "/")}.
              </div>
              <Divider tight className='mt-2 mb-2' />
            </>
          )}
          {cart.isUserPow() &&
            !isGeneral &&
            confirmationStatus === "PENDING_UPDATE" && (
              <>
                <div
                  className={`mt-2 pl-3 pr-3 fs-13px fort-medium lh-18px text-gray-900 `}
                >
                  This cart has updates which need to be confirmed by 11:59pm
                  EST on {formatDateMMDDYYYY(foc, "/")}.
                </div>

                <Divider tight className='mt-2 mb-2' />
              </>
            )}

          {user.isAppCompCopy() ? (
            <>
              <div className='d-flex flex-row ml-3 mr-3 mt-3 mb-3 fort-book'>
                <div> SAP Account Number: </div>
                <div className='ml-auto'>{cart.ccSapAccountNo}</div>
              </div>
              <Divider className='mb-3' tight />
            </>
          ) : (
            <>
              <div className='d-flex flex-row pl-3 pr-3 pb-1 fort-book fs-13px lh-18px'>
                <div>Retail Amount</div>
                <div className='ml-auto'>
                  <NumericFormat
                    value={cartTotal?.retail}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>

              <div className='d-flex flex-row pl-3 pr-3 pb-1 fort-book fs-13px lh-18px'>
                <div>Base Discount</div>
                <div className='ml-auto'>
                  <NumericFormat
                    value={cartTotal?.discount * -1}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>
              {cart.cart.promoCode ? (
                <>
                  <div className='d-flex pl-3 pr-3  pb-1'>
                    <div>{cart.cart.promoCode} Discount</div>
                    <div className='ml-auto'>
                      <NumericFormat
                        value={cartTotal?.promo * -1}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        prefix={"$"}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div />
              )}
              {/* {cartTotal?.shipping > 0 && ( */}
              <div className='d-flex flex-row pl-3 pr-3 pb-1 fort-book fs-13px lh-18px'>
                <div>Shipping</div>
                <div className='ml-auto'>
                  <NumericFormat
                    value={cartTotal?.shipping}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>
              {/* )} */}
              {/* {cartTotal?.tax > 0 && ( */}
              <div className='d-flex flex-row pl-3 pr-3 pb-1 fort-book fs-13px lh-18px'>
                <div>Taxes</div>
                <div className='ml-auto'>
                  <NumericFormat
                    value={cartTotal?.tax ? cartTotal.tax : 0}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>
              {/* )} */}
              <Divider tight className='cart-summary-divider' />
              <div
                className={`d-flex flex-row fort-bold fs-13px lh-18px pl-3 pr-3 pb-1 pt-1 mb-4
               `}
              >
                <div>
                  {confirmationStatus === "CONFIRMED"
                    ? "Subtotal"
                    : "Estimated Subtotal"}{" "}
                  {/* {confirmationStatus !== 'CONFIRMED' && (
                    <Popup
                      className="cp-help-tooltip"
                      content={TOOLTIPS["estimatedTotal"]}
                      position="top center"
                      offset={[0, 5]}
                      trigger={
                        <Icon
                          name="question circle outline"
                          className="sb_question_icon"
                        />
                      }
                    />
                  )} */}
                </div>

                <div className='ml-auto'>
                  <NumericFormat
                    value={cartTotal?.total}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>

              {/* {cart.isUserPow() && confirmationStatus !== 'CONFIRMED' ? (
                <>
                  <div className="fort-book-italic fs-12px lh-16px pl-3 pr-3 pt-1 pb-2">
                    Promo, discounts, tax and shipping will be calculated after
                    confirmation .
                  </div>

                </>
              ) : (
                <>
                  <div className="fort-book-italic fs-12px lh-16px pl-3 pr-3 pt-1 pb-2">
                    Promo, discounts, tax and shipping will be calculated at
                    checkout.
                  </div>

                </>
              )} */}
            </>
          )}

          {isMobile &&
            confirmationStatus === "PENDING_UPDATE" &&
            cartBoxVisible === "PENDING_UPDATE" && (
              <div className='fort-book-italic fs-12px lh-16px pl-3 pr-3 pt-1 pb-2 error-red'>
                This cart has updates which need to be confirmed by 11:59PM EST
                on {formatDateMMDDYYYY(foc, "/")}. Confirming carts is
                accessible through desktop only.
              </div>
            )}

          {confirmationStatus !== "CONFIRMED" &&
            !(foc && isExpired) &&
            !isMobile && (
              <div
                className={`ml-3 mr-3 ${cart.isUserPow() ? "mb-2" : "mb-4"}`}
              >
                <PRHButton
                  disabled={disabled}
                  className='ml-auto mr-auto w-100'
                  size='big-wide'
                  bold
                  borderColorOverride='blue'
                  textColorOverride='white'
                  textColorHoverOverride='blue'
                  backgroundColorOverride='blue'
                  backgroundHoverColorOverride='white'
                  iconColorOverride={disabled ? "grey" : "white"}
                  iconColorHoverOverride='blue'
                  icon={
                    confirmationStatus !== "PENDING_UPDATE"
                      ? "arrow right"
                      : "check"
                  }
                  iconright={
                    confirmationStatus !== "PENDING_UPDATE" ? true : false
                  }
                  style={{ maxWidth: "100%" }}
                  onClick={(e) => {
                    checkOutOnClick();
                  }}
                >
                  {cart.isUserPow() && !isGeneral
                    ? confirmationStatus === "NONE"
                      ? "Confirm Quantities"
                      : "Confirm Updates"
                    : "Proceed to Checkout"}
                </PRHButton>
              </div>
            )}

          {statusPendingUpdate && !isMobile && (
            <div className='d-flex justify-content-center pb-3'>
              <a href='#' onClick={(e) => setUndoChangesModalOpen(true)}>
                Undo All Updates
              </a>
            </div>
          )}

          <div className='pl-3 pr-3'>
            {statusPendingUpdate && !isMobile && (
              <div className='mt-3 mb-3'>
                <PRHButton
                  disabled={disabled}
                  className='cs-payship ml-auto mr-auto w-100 fs-12px lh-16px mb-1'
                  size='big-wide'
                  style={{ maxWidth: "100%" }}
                  onClick={(e) => {
                    setViewConfirmedQuantitiesModalOpen(true);
                  }}
                >
                  View Confirmed Quantities
                </PRHButton>
              </div>
            )}

            {cart.isUserPow() &&
              !isGeneral &&
              !isExpired &&
              (confirmationStatus === "CONFIRMED" ||
                confirmationStatus === "PENDING_UPDATE") &&
              !isExpired &&
              !!cartLoaded &&
              !isMobile && (
                <div className='mb-4'>
                  <PRHButton
                    className='cs-payship ml-auto mr-auto w-100 fs-12px lh-16px mb-1'
                    size='big-wide'
                    style={{ maxWidth: "100%" }}
                    onClick={(e) => {
                      setPaymentShippingDetailDialogOpen(true);
                    }}
                  >
                    View Payment & Order Details
                  </PRHButton>
                </div>
              )}

            {cart.isUserPow() && confirmationStatus === "CONFIRMED" && (
              <div className='mt-2 mb-3 fs-12px lh-16px'>
                <span className='fort-bold'>Note:</span> You can freely edit the
                contents of this cart until the FOC deadline. Updates will need
                to be reconfirmed.
              </div>
            )}

            {cart.isUserPow() &&
              !isGeneral &&
              !isExpired &&
              confirmationStatus === "NONE" && (
                <div className='pb-2 fort-book fs-12px lh-16px text-gray-900'>
                  <div className='pb-3 word-break'>
                    After confirming quantities, your order will be placed and
                    start processing on the FOC date.
                  </div>
                  <div>
                    You will be able to edit this cart until the FOC date.
                  </div>
                </div>
              )}
            {cart.cart.error ? (
              <div className='mt-3 mb-3 fort-book d-flex'>
                <div className='mr-1'>
                  <Icon className='text-orange' name='exclamation circle' />
                </div>
                <div className='mr-1'>
                  <span className='fort-bold'>
                    We are not able to confirm prices or availability at this
                    time.
                  </span>
                  {"  "}
                  Please check the Order Details page for updates after placing
                  your order.
                </div>
              </div>
            ) : null}
            {cart.customerInfo?.customer?.webShippable.length === 0 ? (
              <div className='mt-2 mb-2 fort-book d-flex'>
                <div className='mr-1'>
                  <Icon className='text-orange' name='exclamation circle' />
                </div>
                <div className='mr-1'>
                  <div className='fort-bold'>
                    Your account is unable to place online orders at this time.
                  </div>
                  <div>
                    To place an order, you may download an Excel file of your
                    cart and submit it to Customer Service.
                  </div>
                </div>
              </div>
            ) : null}

            {user.isAppBiz() ? <IECreditCardNotification /> : null}
          </div>
        </div>
      </div>
    );
  };

  const renderMobileSummary = (confirmedSummary = false) => {
    let cartTotal = {};

    cartTotal = cart.getCartTotal(workCart);
    const confirmedCart = workCart.confirmedCart;

    const statusPendingUpdate =
      cart.isUserPow() &&
      !isGeneral &&
      !isExpired &&
      confirmationStatus === "PENDING_UPDATE";

    const showConfirmedCart =
      confirmationStatus === "PENDING_UPDATE" && cartBoxVisible === "CONFIRMED";

    return (
      <div
        className={`cart-summary m-0 mb-4 ${
          cart.isUserPow() && !isGeneral && confirmationStatus === "CONFIRMED"
            ? "confirmed"
            : ""
        } ${
          cart.isUserPow() &&
          !isGeneral &&
          confirmationStatus === "PENDING_UPDATE"
            ? "pending"
            : ""
        } cart-summary-mobile mx-2
        `}
      >
        <div
          className={`d-flex flex-column csc-header p-2 ${
            !isGeneral &&
            confirmationStatus !== "CONFIRMED" &&
            cartBoxVisible !== "CONFIRMED"
              ? "bg-gray-700 text-white"
              : ""
          }
          ${
            !isGeneral &&
            (confirmationStatus === "CONFIRMED" ||
              cartBoxVisible === "CONFIRMED")
              ? "bg-green-summary"
              : ""
          }`}
        >
          {statusPendingUpdate && cartBoxVisible === "PENDING_UPDATE" && (
            <div className={`d-flex flex-row fs-12px lh-15px mb-1`}>
              <Icon
                name={`exclamation circle`}
                className={`${statusPendingUpdate ? "text-white" : ""}`}
              />
              <div>UPDATED</div>
            </div>
          )}
          {cart.isUserPow() &&
            !isGeneral &&
            confirmedSummary &&
            cartBoxVisible === "CONFIRMED" && (
              <div className='d-flex flex-row fort-bold fs-12px lh-15px mb-1'>
                <Icon name='check' />
                <div>CONFIRMED</div>
              </div>
            )}

          <div
            className={`fort-book ${
              !isGeneral ? "text-white" : "text-gray-900"
            } fs-20px lh-27px border-bottom`}
          >
            Cart Summary
          </div>
          {cart.cart.headers?.poNumber && (
            <div className='mt-1 fs-12px lh-15px'>
              <span className='fort-bold'>PO:</span>{" "}
              {cart.cart.headers.poNumber}
            </div>
          )}
        </div>

        <div
          className={`csc-content ${
            !!!visible &&
            isCartSummary &&
            isCartConfirmSummary &&
            confirmationStatus === "PENDING_UPDATE"
              ? "hide"
              : ""
          }`}
        >
          {cart.isUserPow() && !isGeneral && confirmationStatus === "NONE" ? (
            <ConfirmationStatus
              className='mb-2'
              confirmationStatus={cart.cart?.confirmationStatus}
              focDate={foc}
            />
          ) : (
            <div className='mb-2' />
          )}

          {cart.isUserPow() && confirmationStatus === "CONFIRMED" && (
            <>
              <div className={`pl-3 pr-3 mt-2`}>
                The quantities in this cart will be placed as an order & start
                processing after 11:59PM EST on {formatDateMMDDYYYY(foc, "/")}.
              </div>
              <Divider tight className='mt-2 mb-2' />
            </>
          )}
          {cart.isUserPow() &&
            !isGeneral &&
            confirmationStatus === "PENDING_UPDATE" && (
              <>
                <div
                  className={`mt-2 pl-3 pr-3 fs-13px fort-medium lh-18px text-gray-900 `}
                >
                  This cart has updates which need to be confirmed by 11:59pm
                  EST on {formatDateMMDDYYYY(foc, "/")}.
                </div>

                <Divider tight className='mt-2 mb-2' />
              </>
            )}

          {user.isAppCompCopy() ? (
            <>
              <div className='d-flex flex-row ml-3 mr-3 mt-3 mb-3 fort-book'>
                <div> SAP Account Number: </div>
                <div className='ml-auto'>{cart.ccSapAccountNo}</div>
              </div>
              <Divider className='mb-3' tight />
            </>
          ) : (
            <>
              <div className='d-flex flex-row pl-3 pr-3 pb-1 fort-book fs-13px lh-18px'>
                <div>Retail Amount</div>
                <div className='ml-auto'>
                  <NumericFormat
                    value={
                      showConfirmedCart
                        ? confirmedCart?.subtotal
                        : cartTotal?.retail
                    }
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>

              <div className='d-flex flex-row pl-3 pr-3 pb-1 fort-book fs-13px lh-18px'>
                <div>Base Discount</div>
                <div className='ml-auto'>
                  <NumericFormat
                    value={
                      showConfirmedCart
                        ? confirmedCart?.totalBaseDiscount * -1
                        : cartTotal?.discount * -1
                    }
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>
              {!showConfirmedCart && cart.cart.promoCode && (
                <>
                  <div className='d-flex pl-3 pr-3  pb-1'>
                    <div>{cart.cart.promoCode} Discount</div>
                    <div className='ml-auto'>
                      <NumericFormat
                        value={cartTotal?.promo * -1}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        prefix={"$"}
                      />
                    </div>
                  </div>
                </>
              )}
              {showConfirmedCart && confirmedCart?.promoCode && (
                <>
                  <div className='d-flex pl-3 pr-3  pb-1'>
                    <div>{confirmedCart?.promoCode} Discount</div>
                    <div className='ml-auto'>
                      <NumericFormat
                        value={confirmedCart?.totalPromoDiscount * -1}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        prefix={"$"}
                      />
                    </div>
                  </div>
                </>
              )}
              {/* {cartTotal?.shipping > 0 && ( */}
              <div className='d-flex flex-row pl-3 pr-3 pb-1 fort-book fs-13px lh-18px'>
                <div>Shipping</div>
                <div className='ml-auto'>
                  <NumericFormat
                    value={
                      showConfirmedCart
                        ? confirmedCart?.shippingCost
                        : cartTotal?.shipping
                    }
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>
              <div className='d-flex flex-row pl-3 pr-3 pb-1 fort-book fs-13px lh-18px'>
                <div>Taxes</div>
                <div className='ml-auto'>
                  <NumericFormat
                    value={
                      showConfirmedCart
                        ? confirmedCart?.tax || 0
                        : cartTotal?.tax || 0
                    }
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>
              <Divider tight className='cart-summary-divider' />
              <div
                className={`d-flex flex-row fort-bold fs-13px lh-18px pl-3 pr-3 pb-1 pt-1 mb-4
               `}
              >
                <div>
                  {confirmationStatus === "CONFIRMED"
                    ? "Subtotal"
                    : "Estimated Subtotal"}{" "}
                </div>

                <div className='ml-auto'>
                  <NumericFormat
                    value={
                      showConfirmedCart
                        ? confirmedCart?.total
                        : cartTotal?.total
                    }
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>
            </>
          )}

          {confirmationStatus === "PENDING_UPDATE" &&
            cartBoxVisible === "PENDING_UPDATE" && (
              <div className='fort-book-italic fs-12px lh-16px pl-3 pr-3 pt-1 pb-2 error-red'>
                You have made changes to this FOC cart. To confirm updates,
                please use the desktop version.
              </div>
            )}
          <div className='pl-3 pr-3'>
            {cart.isUserPow() && confirmationStatus === "CONFIRMED" && (
              <div className='mt-2 mb-3 fs-12px lh-16px'>
                <span className='fort-bold'>Note:</span> You can freely edit the
                contents of this cart until the FOC deadline. Updates will need
                to be reconfirmed.
              </div>
            )}

            {cart.isUserPow() &&
              !isGeneral &&
              !isExpired &&
              confirmationStatus === "NONE" && (
                <div className='pb-2 fort-book fs-12px lh-16px text-gray-900'>
                  <div className='pb-3 word-break'>
                    After confirming quantities, your order will be placed and
                    start processing on the FOC date.
                  </div>
                  <div>
                    You will be able to edit this cart until the FOC date.
                  </div>
                </div>
              )}
            {cart.cart.error ? (
              <div className='mt-3 mb-3 fort-book d-flex'>
                <div className='mr-1'>
                  <Icon className='text-orange' name='exclamation circle' />
                </div>
                <div className='mr-1'>
                  <span className='fort-bold'>
                    We are not able to confirm prices or availability at this
                    time.
                  </span>
                  {"  "}
                  Please check the Order Details page for updates after placing
                  your order.
                </div>
              </div>
            ) : null}
            {cart.customerInfo?.customer?.webShippable.length === 0 ? (
              <div className='mt-2 mb-2 fort-book d-flex'>
                <div className='mr-1'>
                  <Icon className='text-orange' name='exclamation circle' />
                </div>
                <div className='mr-1'>
                  <div className='fort-bold'>
                    Your account is unable to place online orders at this time.
                  </div>
                  <div>
                    To place an order, you may download an Excel file of your
                    cart and submit it to Customer Service.
                  </div>
                </div>
              </div>
            ) : null}

            {user.isAppBiz() ? <IECreditCardNotification /> : null}
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return !isMobile ? (
      <>{renderSummary()}</>
    ) : (
      <>
        {cartBoxVisible === "PENDING_UPDATE" && renderMobileSummary(false)}
        {cartBoxVisible === "CONFIRMED" && renderMobileSummary(true)}
        {confirmationStatus === "PENDING_UPDATE" && (
          <>
            {cartBoxVisible === "CONFIRMED" && (
              <Container className='mb-3'>
                <PRHButton
                  className='cart-summary-button w-100'
                  size='big-wide'
                  onClick={() => setCartBoxVisible("PENDING_UPDATE")}
                >
                  Back to updated cart
                </PRHButton>
              </Container>
            )}
            {cartBoxVisible === "PENDING_UPDATE" && (
              <Container className='mb-3'>
                <PRHButton
                  className='cart-summary-button border-radius-3px w-100'
                  size='big-wide'
                  icon='arrow right'
                  iconright={true}
                  onClick={(e) => {
                    setViewConfirmedQuantitiesModalOpen(true);
                  }}
                  //onClick={() => setCartBoxVisible('CONFIRMED')}
                >
                  View Confirmed Cart Summary
                </PRHButton>
              </Container>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <ConfirmationCartDetailsModal
        shipTo={shipTo}
        open={paymentShippingDetailDialogOpen}
        setOpen={setPaymentShippingDetailDialogOpen}
        proceedCallback={(e) => {
          cart
            .loadEstimateCarts({
              focDate: foc,
              simulate: true,
              orderDetail: true,
              forceTakeOver: true,
            })
            .then((x) => {
              history.push({
                pathname: "/checkout",
                state: {
                  shipTo: shipTo,
                  focDate: foc,
                },
              });
            });
          // history.push({
          //   pathname: '/checkout',
          //   state: { shipTo, focDate: foc },
          // });
          // history.push({ pathname: '/checkout' });
        }}
      />
      <CartUndoAllChangesWarningModal
        open={undoChangesModalOpen}
        setOpen={setUndoChangesModalOpen}
        shipTo={shipTo}
        foc={foc}
        callback={undoChanges}
      />
      <CartViewConfirmedQuantitiesModal
        open={viewConfirmedQuantitiesModalOpen}
        setOpen={setViewConfirmedQuantitiesModalOpen}
        shipTo={shipTo}
        foc={foc}
        workCart={workCart}
        isMobile={isMobile}
      />
      {renderContent()}
    </>
  );
}

export default CartSummary;
