import { useState } from "react";
import { useLazyGetPostsQuery } from "../../../api/bizcontentApi";
import withApp from "../../../components/hc/withApp";
import PRHButton from "../../../shared-react-components/elements/prhbutton";

const Pagination = ({
  postType,
  page,
  lastPage,
  category,
  app,
  setLoading,
  addPosts,
}) => {
  const [nextPage, setNextPage] = useState(2);
  const [performGetPosts, getPostsResult] = useLazyGetPostsQuery();

  const getNextPage = async () => {
    setLoading(true);
    console.log('getNextPage', postType, nextPage, category);
    const newPosts = await performGetPosts({
      postType,
      category,
      page: nextPage,
    });
    console.log('get new posts', newPosts);
    addPosts(newPosts.data);
    setLoading(false);
    setNextPage(parseInt(nextPage, 10) + 1);
  };
  console.log('pages', parseInt(nextPage, 10), parseInt(lastPage, 10));
  if (parseInt(nextPage, 10) - 1 === parseInt(lastPage, 10)) {
    return <></>;
  }

  return (
    <>
      <PRHButton onClick={getNextPage}>Load More</PRHButton>
    </>
  );
};

Pagination.defaultProps = {
  category: "",
};

export default withApp(Pagination);
