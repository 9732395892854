import { useCallback, useState } from "react";
import useCart from "../../components/hc/useCart";
import DialogModal from "../../components/modals/DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import {
  isFocCartExpired,
  isPastOnSaleDate,
  expiredFocOffset,
  isGeneralCart,
} from "../../utils/utilities";
import VariantRestrictionsOrderingModal from "../../components/modals/VariantRestrictionsOrderingModal";
import ModifiedConfirmedCartModal from "../modals/ModifyConfirmedCartModal";

const AddToCartButton = (props) => {
  const Cart = useCart();
  const {
    onClickFunction,
    foc,
    confirmationStatus = false,
    onSaleDate = false,
    focStatus,
    isBtnAvailable,
    qty,
    isbn,
    icon,
    bold,
    className,
    iconOnly,
    prerequisite,
    orderReq,
    callback = false,
  } = props;
  // console.log('addtocartbutton', props);
  const [isLocationWarningOpen, setisLocationWarningOpen] = useState(false);
  const [variantModalState, setVariantModalState] = useState(false);
  const [restrictionsNew, setRestrictionsNew] = useState(false);
  const isLvl1 = Cart?.customerInfo?.currentUser?.roleNo === "W1";
  const [modifyConfirmedCartModalOpen, setModifyConfirmedCartModalOpen] =
    useState(false);

  const onClickMethod = useCallback(() => {
    const items = [{ isbn, quantity: parseInt(qty) }].filter((item) => item);
    if (Cart.isUserPow()) {
      const ci = { simulate: false, foc: foc, items };
      if (isFocCart()) {
        ci.items.map((item) => (item.cartType = "FOC"));
        Cart.addEstimateItems(ci).then((d) => {
          if (d?.modalInfo === "ADD_QUALIFYING_ITEMS") {
            setRestrictionsNew(d.variantInfo);
            setVariantModalState(true);
          } else {
            if (callback) callback(d);
          }
        });
      } else {
        ci.foc = "2099-12-31";
        ci.items.forEach((item) => (item.cartType = "GEN"));
        if (isFocCartExpired(foc) && !isPastOnSaleDate(onSaleDate))
          ci.items.forEach((item) => (item.cartType = "FGN"));
        Cart.addEstimateItems(ci).then((d) => {
          if (callback) callback(d);
        });
      }
    } else {
      Cart.addItems(items).then((d) => {
        if (callback) callback(d);
      });
    }
  });

  let localDisabled = false;
  // if (Cart.shipTo < 0 && focStatus === 'ok') {
  //   localDisabled = true;
  // }

  // if (Cart.isUserPow() && Cart.shipTo < 0) {
  //   localDisabled = true;
  // }

  if (Cart?.customerInfo?.currentUser?.roleNo === "W1") localDisabled = true;
  if (isFocCartExpired(foc) && orderReq && orderReq > 0) localDisabled = true;

  const isFocCart = () => {
    if (isGeneralCart(foc)) return false;
    if (
      (focStatus === "ok" || expiredFocOffset !== 0) &&
      foc &&
      !isFocCartExpired(foc)
    )
      return true;
    return false;
  };

  const localisBtnAvailable = () => {
    if (isBtnAvailable) {
      return isBtnAvailable;
    } else {
      if (isFocCart() && expiredFocOffset !== 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <VariantRestrictionsOrderingModal
        isbn={isbn}
        qtyWant={qty}
        openState={variantModalState}
        restrictionsNew={restrictionsNew}
        setOpenState={(w) => {
          setVariantModalState(w);
        }}
        shipTo={Cart.getShipToAccount().accountNo}
        foc={foc}
        // cancelCallback={cancelCallback}
        callBackFunction={callback}
      />

      <PRHButton
        disabled={!localisBtnAvailable() || isLvl1 || localDisabled}
        className={`srch-add-to-cart ${className}`}
        size='medium'
        icon={icon}
        onClick={
          Cart.isUserPow() && Cart.shipTo < 0
            ? () => setisLocationWarningOpen(true)
            : confirmationStatus && confirmationStatus === "CONFIRMED"
            ? () => setModifyConfirmedCartModalOpen(true)
            : () => onClickMethod()
        }
        textNoWrap={true}
        borderColorOverride='blue'
        textColorOverride='white'
        textColorHoverOverride='blue'
        backgroundColorOverride='blue'
        backgroundHoverColorOverride='white'
        iconColorOverride={
          !isBtnAvailable || isLvl1 || localDisabled ? "gray" : "white"
        }
        iconColorHoverOverride='blue'
        bold={bold || false}
      >
        {iconOnly ? "" : isFocCart() ? "Add to FOC Cart" : "Add to Cart"}
      </PRHButton>
      <DialogModal
        setOpen={setisLocationWarningOpen}
        open={isLocationWarningOpen}
        dialogOptions={{
          header: "SELECT A LOCATION",
          actions: [
            {
              hidden: false,
              label: "OK",
              textColorOverride: "white",
              backgroundColorOverride: "blue",
              borderColorOverride: "blue",
              textColorHoverOverride: "blue",
              backgroundHoverColorOverride: "white",
              borderHoverColorOverride: "blue",
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                return { proceed: true };
              },
            },
          ],
        }}
        options={{ className: "tm-modal-container" }}
      >
        <p className='paragraph-2'>
          <span className='fort-book'>
            You must select a location from the header at the top of the page
            before adding items to your cart.
          </span>
        </p>
      </DialogModal>
      <ModifiedConfirmedCartModal
        open={modifyConfirmedCartModalOpen}
        setOpen={setModifyConfirmedCartModalOpen}
        callback={onClickMethod}
      ></ModifiedConfirmedCartModal>
    </>
  );
};

export default AddToCartButton;
