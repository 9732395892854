import { Component } from 'react';
import withAccount from '../../components/hc/withAccount';
import withApp from '../../components/hc/withApp';
import PageStandard from '../../components/elements/PageStandard';
import ResendInvitationForm from '../../components/registration/ResendInvitationForm';
import Cancel from '../../components/elements/AccountCancel';
import ResendInvitationResponse from '../../components/registration/ResendInvitationResponse';
import './registration.scss';

class ResendInvitation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        header: 'Resend Invitation',
      },
    };
  }
  async componentDidMount() {
    const {
      account,
      match: { params },
    } = this.props;
    if (account.formState.createUserForm.token === null) {
      account.setStateField(
        {token: params.token,
        requiredPasswordValidationScore: 7
        }
      );
    }
  }

  render() {
    const { account } = this.props;
    // console.log('resend invitation form', account);
    return (
      <>
        <PageStandard jeff={true} alignment="center">
          {account.formState.updateResponse === 'default' ||
          account.formState.updateResponse === 'NOT_FOUND' ? (
            <ResendInvitationForm
              handleSubmitUser={this.handleSubmitUser}
              handleChange={this.handleChange}
              setStepHandler={(e) => this.setStepHandler(e)}
              formState={this.state}
            />
          ) : (
            ''
          )}
          {account.formState.updateResponse === 'cancel' ? (
            <Cancel
              handleChange={this.handleChange}
              setUpdateResponseHandler={(e) =>
                account.setUpdateResponseHandler(e)
              }
              formState={this.state}
            />
          ) : (
            ''
          )}
          {account.formState.updateResponse === 'OK' ? <ResendInvitationResponse /> : ''}
        </PageStandard>
      </>
    );
  }
}

export default withApp(withAccount(ResendInvitation));
