import React from "react";
import PropTypes from "prop-types";
import { Loader, Modal } from "semantic-ui-react";
import DropdownStoreSelectorLists from "./components/DropdownStoreSelectorLists";
import DialogModalAccessible from "./DialogModalAccessible";
import BridgeSelectorModal from "./BridgeSelectorModal.scss";
import useCart from "../hc/useCart";

function BridgeSelectLocationModal(props) {
  const {
    open,
    setOpen,
    shipTo,
    listID,
    storeLocation,
    setShipTo,
    setCancelModalOpen,
    getEstimateCarts,
    header,
  } = props;

  return (
    // console.log("listID", listID),
    // console.log("shipTo", shipTo),
    <div className="bridge-selector-location-modal">
      <DialogModalAccessible
        setOpen={setOpen}
        open={open}
        contentPt="pt2"
        contentPx="px-1"
        className="bridge-selector-location-modal"
        //returnOnClose={true}
        onXClose={async () => {
          setCancelModalOpen(true);
          //return { proceed: false };
        }}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        modalClassName="br-add-title-modal"
        dialogOptions={{
          header: header,
          actions: [
            {
              hidden: false,
              label: "Cancel",
              preAction: async () => {
                setCancelModalOpen(true);
                return { proceed: false };
              },
              action: () => {
                return { proceed: true };
              },
            },
            {
              hidden: false,
              disabled: shipTo === -1 ? true : false, //titleList.filter((t) => t.available).length === 0,
              className: "bridge-selector-location-modal-next",
              label: "Next",
              textColorOverride: "white",
              backgroundColorOverride: "blue",
              borderColorOverride: "blue",
              textColorHoverOverride: "blue",
              backgroundHoverColorOverride: "white",
              borderHoverColorOverride: "blue",
              bold: true,
              //icon: "check",
              iconColorHoverOverride: "blue",
              iconColorOverride: "white",
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                getEstimateCarts();
                return { proceed: true };
                // return { proceed: true };
              },
            },
          ],
        }}
        options={{ size: "small" }}
      >
        <div className="bridge-selector-location-modal">
          <div className="fort-book fs14-px lh19-px bridge-selector-location-message">
            Select the location where you'd like to add your Comics Retail Pull
            List items.
          </div>
          <div className="fort-bold fs14-px lh19-px">
            <DropdownStoreSelectorLists
              shipTo={shipTo}
              selectShipTo={(x) => {
                setShipTo(x);
              }}
              //selectShipTo={(shipTo) => cart.setShipTo(shipTo)}
            />
          </div>
        </div>
      </DialogModalAccessible>
    </div>
  );
}

BridgeSelectLocationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setCancelModalOpen: PropTypes.func.isRequired,
  getEstimateCarts: PropTypes.func.isRequired,
  shipTo: PropTypes.number.isRequired,
  listID: PropTypes.string.isRequired,
};

export default BridgeSelectLocationModal;
