import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Icon } from "semantic-ui-react";
import "./customerenrolment.scss";
import "react-datepicker/dist/react-datepicker.css";
import { EnrolmentStep } from "./shared";

const PaymentMethodCard = ({ selected, method, onSelect }) => {
  const { title, description, icon, note } = method;
  return (
    <div className="payment-method-container" onClick={onSelect}>
      <div className={`payment-info ${selected ? "mb-2" : ""}`}>
        <div className={`payment-radio-button ${selected && "selected"}`}>
          <div className="selected-payment" />
        </div>
        <Icon name={icon} className="payment-icon" />
        <div className="payment-info-text">
          <div className="payment-info-title">{title}</div>
          <div className="payment-info-description">{description}</div>
        </div>
      </div>
      {selected && <div className="payment-note">{note}</div>}
    </div>
  );
};

const SelectPaymentMethod = ({
  data,
  paymentMethods,
  onChange,
  onNext,
  onBack,
}) => {
  const disableNext = !data[EnrolmentStep.PaymentMethod];
  return (
    <>
      {Object.keys(paymentMethods).map((key) => {
        const method = paymentMethods[key];
        return (
          <PaymentMethodCard
            key={key}
            selected={method.id === data[EnrolmentStep.PaymentMethod]}
            method={method}
            onSelect={() => onChange(method.id)}
          />
        );
      })}

      <div className="d-flex flex-row Xbutton-holder my-5">
        <PRHButton
          size="large"
          iconleft
          icon="arrow left"
          className="mr-auto"
          onClick={() => onBack(data)}
        >
          Back
        </PRHButton>
        <PRHButton
          disabled={disableNext}
          iconright
          icon="arrow right"
          size="large"
          className="ml-auto"
          onClick={() => onNext(data)}
        >
          Next
        </PRHButton>
      </div>
    </>
  );
};

export default SelectPaymentMethod;
