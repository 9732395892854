import { createRef, Component } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import queryString from 'query-string';
import withAccount from '../hc/withAccount';
import withSecurity from '../hc/withSecurity';
import withApp from '../hc/withApp';
import { withRouter } from 'react-router';
import { IDENTITY_CONFIG } from '../../utils/authConst';
import { Input, Form } from 'semantic-ui-react-form-validator';
import TextValidator from '../../shared-react-components/elements/input';
import { Icon } from 'semantic-ui-react';
import {
  PRHLabel,
  PRHContainer,
} from '../../shared-react-components/elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { ButtonHolder } from '../elements/elements';
//import { Button } from './elements'
import { Link } from 'react-router-dom';
import './registration.scss';

class ResendInformationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Ecom_User_ID: '',
      password: '',
      recaptchaToken: '',
      recaptchaRef: createRef(),
    };
  }
/*
  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if(nextProps.account.formState.updateResponse === 'NOT_FOUND') {
      this.state.recaptchaRef.current.reset();
    }
  }
*/
  handleRecaptchaChange = () => {
    const recaptchaToken = this.state.recaptchaRef.current.getValue();
    this.setState({ recaptchaToken });
  };

  enterID = (e) => {
    this.setState({ userId: e.target.value });
  };

  enterPW = (e) => {
    this.setState({ password: e.target.value });
  };

  onSubmit() {
    const { Ecom_User_ID } = this.state;
    this.props.app.accountApi.storeUserId(Ecom_User_ID);
    if (this.validateEmail(Ecom_User_ID) === false) {
      this.props.history.push('/legacy');
    } else {
      document.getElementById('loginForm').submit();
    }
  }

  validateEmail(userId) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (userId.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  handleChange = (e) => {
    const newState = { ...state };
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  };

  render() {
    const { Ecom_User_ID } = this.state;
    const { account, formState } = this.props;
    const loginAction = IDENTITY_CONFIG.login;
    const loginTarget = IDENTITY_CONFIG.target + '/';
    const showMe = IDENTITY_CONFIG.base + '/user/bdasgupta';
    // console.log('recover password account', account.formState);
    return (
      <>
        <PRHContainer className="content-block-border">
          <div className={'white-box-header fort-light'}>
            {formState.form.header}
          </div>
          <br />
          <div className={'white-box-text fort-light'}>
            Enter your email below. If there is an account associated with that
            email you should receive an email with a link to verify your account.
          </div>
          <br />
          <br />
          <Form
            width={12}
            ref={this.loginForm}
            instantValidate={false}
            onSubmit={() =>{

              if (!!!this.state.recaptchaToken) {
                return;
              }
              account.setStateField(
                'recaptchaToken',
                this.state.recaptchaToken
              );
              account.resendInvitationEmail(this.state.recaptchaToken);
            }}
            method="post"
            action={loginAction}
            id="loginForm"
          >
            <TextValidator
              name="email"
              type="text"
              onChange={e=>{account.handleChangeNoValidations(e), this.state.recaptchaRef.current.reset()}}
              validators={['required']}
              errorMessages={['this field is required']}
              value={account.formState.createUserForm.email}
              label={<label className={'fort-light'}>Email</label>}
              validators={['required', 'isEmail']}
              errorMessages={[
                'this field is required',
                'field must be an email',
              ]}
              width={16}
            />
            {account.formState.updateResponse === 'NOT_FOUND' ? (
              <div className="my-2 fort-bold text-red">
                We couldn't find that email. Please contact your administrator
                or customer service.
              </div>
            ) : (
              ''
            )}
            <div className="d-flex mt-3 align-items-center justify-content-center">
             <ReCAPTCHA
                onExpired={this.handleRecaptchaChange}
                onChange={this.handleRecaptchaChange}
                onErrored={
                  e=>{
                console.log('recaptcha error',e);
              }}
                ref={this.state.recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size={'normal'}
            />
            </div>
            <br />
            <br />
            <div className={'button-holder'}>
              <PRHButton
                iconright
                icon="arrow right"
                size="large"
                className={'float-right margin-left'}
                disabled={!!!this.state.recaptchaToken}
                type="submit"
                /*
            disabled={
              !formState.createUserForm.sapAcct
              || !formState.createUserForm.san
            }
            */
                //type="submit"
              >
                Submit
              </PRHButton>
              <PRHButton
                onClick={() => account.setUpdateResponseHandler('cancel')}
                size="large"
                className={'float-right'}
                //displayInlineFlex
              >
                Cancel
              </PRHButton>
            </div>
            <br />
          </Form>
        </PRHContainer>
      </>
    );
  }
}

export default withRouter(withAccount(withApp(ResendInformationForm)));
