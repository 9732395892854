import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon, Loader, Placeholder } from "semantic-ui-react";
import { useLazyGetDeliveriesHomePageQuery } from "../../../api/ecomApi";
import useDirtyHandler from "../../../shared-react-components/components/hc/useDirtyHandler";
import LazyImage from "../../../utils/LazyImage";
import { formatDateMMDDYYYY } from "../../../utils/utilities";
import "./DeliveriesHomePage.scss";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../../utils/const";
import { useDispatch, useSelector } from "react-redux";
import { setDeliveryHomePageWidget } from "../../../features/userSlice";
import useCart from "../../../components/hc/useCart";

export const DeliveriesHomePage = (props) => {
  const cart = useCart();
  const dirtyHandler = useDirtyHandler();
  const [performGetDeliveriesHomePage, getDeliveriesHomePageResult] =
    useLazyGetDeliveriesHomePageQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [deliveries, setDeliveries] = useState([]);
  const [deliveryCount, setDeliveryCount] = useState(false);
  const [bannerOpen, setBannerOpen] = useState(true);
  const { deliveryHomePageWidget } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });

  useEffect(() => {
    const loadDeliveryDetail = async () => {
      let params = {};
      if (cart.customerInfo?.currentUser?.roleNo === "W1") {
        params.shipTo = cart.customerInfo?.customer?.accountNo;
      }
      const result = await performGetDeliveriesHomePage(params, true);
      // console.log("result", result);
      if (result.data?.status === "OK") {
        setDeliveries(result.data.data.deliveries);
        setDeliveryCount(result.data.data.deliveryCount);
      }

      setIsLoading(false);
    };

    loadDeliveryDetail();

    return () => {};
  }, []);

  return (
    <div
      className={`delvies-home ${
        isMobile
          ? "mobile-catalogs-container border-gray-300 border-radius-3px"
          : ""
      } ${bannerOpen ? "" : "d-none"}`}
    >
      <div className='delvies-header d-flex align-items-center pb-1 mb-2'>
        {isMobile ? (
          <>
            <div className='fort-light text-gray-900 fs-20px mt-1 mb-1'>
              Recent Deliveries
            </div>{" "}
            <div className='ml-auto'>
              <Link to='/deliveries'>View All</Link>
            </div>
          </>
        ) : (
          <>
            {" "}
            <LazyImage src={"/images/truck-wire.svg"} />
            <div className='fort-medium fs-13px lh-18px'>Recent Deliveries</div>
            <div className='ml-auto'>
              <Link to='/deliveries'>View All Deliveries</Link>
            </div>
            <LazyImage
              src={"/images/X-close.svg"}
              className='text-black cursor-pointer fs-20px ml-2 align-self-center '
              onClick={() => {
                // dispatch(setDeliveryHomePageWidget(false));
                setBannerOpen(false);
              }}
            />
          </>
        )}
      </div>
      <div
        className='delvies-list d-flex flex-column position-relative'
        style={{ minHeight: "50px" }}
      >
        {/* {isLoading && <Loader active />} */}
        {isLoading && (
          <Placeholder>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        )}
        {!isLoading && deliveries.length === 0 && (
          <div className='d-flex align-items-center justify-content-center mt-2 fort-book-italic'>
            No deliveries occurred in the past 14 days.
          </div>
        )}
        {deliveries.slice(0, 5).map((delivery, index) => (
          <div
            className='delvies-list-item fs-13px lh-16px text-gray-900 mb-1'
            key={index}
          >
            <span className='fort-bold fs-13px lh-18px'>
              {formatDateMMDDYYYY(delivery.shipDate, "/")}:{" "}
            </span>
            <span>{delivery.cartonCt} cartons</span>
            <span className='mr-1 ml-1 text-gray-300'>|</span>
            <span>{delivery.itemCt} items</span>
            <span className='mr-1 ml-1 text-gray-300'>|</span>
            <span>{delivery.unitCt} units</span>
            {!isMobile && (
              <>
                <span className='mr-1 ml-1 text-gray-300'>|</span>
                <span>Account #: {delivery.shipTo}</span>
                <span className='mr-1 ml-1 text-gray-300'>|</span>
                <Link to={`/delivery/${delivery.deliveryNo}`}>
                  View more details
                </Link>
              </>
            )}
          </div>
        ))}
      </div>
      {deliveries.length > 5 && (
        <div className='delvies-footer pt-1 mt-1 fort-book-italic text-gray-700'>
          {deliveryCount - 5} more scheduled deliveries
        </div>
      )}
    </div>
  );
};
