import { Route } from "react-router-dom";
import withSecurity from "../components/hc/withSecurity";
import { withRouter } from "react-router-dom";
import LoadingView from "../pages/loading/LoadingView";
import queryString from "query-string";

export const PublicAuthRoute = withSecurity(
  withRouter((props) => {
    // console.log("PublicAuthRoute", props);

    const values = queryString.parse(props.location.search);
    let performingAuth = false;

    if (Object.prototype.hasOwnProperty.call(values, "auth")) {
      performingAuth = values.auth;
    }
    // console.log('isAuth', props.user.isAuthenticated());
    // const renderFn = (Component) => (props) => {
    //   console.log('isAuth', props.user.isAuthenticated());
    //   if (!!Component && props.user.isAuthenticated()) {
    //     return <Component user={props.user.getUser()} {...props} />;
    //   } else {
    //     props.user.signinRedirect();
    //     return <span>loading</span>;
    //   }
    // };

    if (props.user.isAuthenticated()) {
      // console.log('secure home page');
      return <Route {...props} component={props.secureComponent} />;
      // return <{props.component} user={props.user.getUser()} {...props} />;
      // return (props.component);
    } else if (performingAuth) {
      // console.log("performingauth", performingAuth);
      // props.user.signinRedirectUnAuthenticated({
      //   state: props.location.pathname,
      // });
      return (
        <Route
          render={(Component) => {
            return <LoadingView></LoadingView>;
          }}
        />
      );
    } else {
      // console.log('public home page');
      return <Route {...props} component={props.publicComponent} />;
      // console.log('user', props.user);
      // props.user.signinRedirect();
      // return (
      //   <Route
      //     render={(Component) => {
      //       return <LoadingView></LoadingView>;
      //     }}
      //   />
      // );
    }
  })
);
