import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./StatementsTab.scss";
import { useLazyGetStatementsOverviewQuery } from "../../api/ecomApi";
import { Loader } from "semantic-ui-react";

const StatementsTab = (props) => {
  const [statements, setStatements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [
    performGetStatementsOverview,
    {
      isFetching: performGetStatementsOverviewIsFetching,
      data: getStatementsOverviewResult,
    },
  ] = useLazyGetStatementsOverviewQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
    const loadStatementsOverview = async () => {
      // dirtyHandler.setLoadingTakeoverConfig({
      //   text: "Loading delivery . . .",
      //   longLoadText: "Still loading . . .",
      //   inverted: true,
      //   backgroundBlurLight: true,
      // });
      // dirtyHandler.setLoadingTakeover(true);

      const result = await performGetStatementsOverview({});
      console.log("result", result);
      if (result.data?.status === "OK") {
        setStatements(result.data.data.statements);
      }
      // dirtyHandler.setLoadingTakeover(false);
      setIsLoading(false);
    };

    loadStatementsOverview();

    return () => {};
  }, []);

  // useEffect(() => {
  //   //get statements

  //   setStatements([
  //     { title: "February 2024 Statement", statementNo: 33333 },
  //     { title: "January 2024 Statement", statementNo: 33333 },
  //     { title: "December 2023 Statement", statementNo: 33333 },
  //     { title: "November 2023 Statement", statementNo: 33333 },
  //     { title: "October 2023 Statement", statementNo: 33333 },
  //     { title: "September 2023 Statement (no activity)" },
  //     { title: "August 2023 Statement", statementNo: 33333 },
  //     { title: "July 2023 Statement", statementNo: 33333 },
  //     { title: "June 2023 Statement", statementNo: 33333 },
  //     { title: "May 2023 Statement", statementNo: 33333 },
  //     { title: "April 2023 Statement", statementNo: 33333 },
  //     { title: "March 2023 Statement", statementNo: 33333 },
  //   ]);
  // }, []);

  return (
    <div className="stmt-container">
      <div>
        <span className="fort-book text-black fs-14px lh-16px">
          {/*Note: Statements are not available for months with zero account
          activity.*/}
          New statements will be available on the second business day of every
          month. Statements are not available for months with zero activity.
        </span>
      </div>
      <div className="d-flex flex-column mt-2 stmt-header">
        <div className="fort-book fs-12px lh-14px">DOCUMENT</div>
      </div>
      {isLoading && (
        <Loader className="mt-4" style={{ display: "flex" }} inverted />
      )}
      {!isLoading && statements.length === 0 && (
        <div className="mt-8 mb-8">
          <div className="ot-no-rows">You have no recent statements.</div>
          <div className="ot-no-rows-subheader">
            You have no statements within the past 12 months. Please check back
            later. {/* or upcoming deliveries*/}
          </div>
        </div>
      )}
      {!isLoading &&
        statements.map((stmt) => (
          <div className="fort-book stmt-row">
            {!!!stmt.activity ? (
              <div className="stmt-unavailable fort-book-italic">
                {stmt.monthYear} Statement (no activity)
              </div>
            ) : (
              <Link to={`/statement/${stmt.statementDate}`}>
                {stmt.monthYear} Statement
              </Link>
            )}
          </div>
        ))}
    </div>
  );
};

export default StatementsTab;
