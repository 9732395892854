import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class salesOrderRenderer extends Component {
  render() {
    const {
        salesOrderNumber
    } = this.props.data;
    return (
        <a href={`/orders/${salesOrderNumber}`} onClick={e => {e.preventDefault();this.props.history.push(`/orders/${salesOrderNumber}`)}}>
            {salesOrderNumber}
        </a>
    );
  }
}

export default withRouter(salesOrderRenderer);
