import { Component } from 'react';
import localforage from 'localforage';
import { SizeMe } from 'react-sizeme';
import withAccount from '../../components/hc/withAccount';
import withApp from '../../components/hc/withApp';
import PageStandard from '../../components/elements/PageStandard';
import ResetPasswordPublicForm from '../../components/admin/ResetPasswordPublicForm';
import Cancel from '../../components/elements/AccountCancel';
import SuccessResponse from '../../components/registration/SuccessResponse';
import './registration.scss';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { account, match: { params } } = this.props; 
    if (account.formState.createUserForm.token === null) {
      account.setStateField(
        {
        token: params.token,
        requiredPasswordValidationScore: 6
        }
        );
    }
  }

  render() {
    const { account } = this.props;
    return (
      <>
      <SizeMe monitorHeight={true}>
      {({ size }) =>(
        <PageStandard jeff={true} alignment="center">
        {account.formState.updateResponse === 'default' || account.formState.updateResponse === 'NOT_FOUND' ? (
        
          
        <ResetPasswordPublicForm
        handleSubmitUser={this.handleSubmitUser} 
        handleChange={this.handleChange} 
        setStepHandler={e => this.setStepHandler(e)}
        formState={this.state} 
        size={size}
        />
        ): ''}
        
        {account.formState.updateResponse === 'cancel' ? (
        <Cancel 
        handleChange={this.handleChange} 
        setUpdateResponseHandler={e => account.setUpdateResponseHandler(e)}
        formState={this.state} 
        />
        ): ''}
        {account.formState.updateResponse === 'OK' ? (
        <SuccessResponse />
        ): ''}
        </PageStandard>)}
        </SizeMe>
      </>
    );
  }
}

export default withApp(withAccount(ResetPassword));
