import { Link, withRouter } from 'react-router-dom';
import {
  Dropdown,
  Header,
  Icon,
  Button,
  Input,
  Label,
  Popup,
} from 'semantic-ui-react';
import {
  osVersion,
  osName,
  isChrome,
  isFirefox,
  isIE,
} from 'react-device-detect';
import PRHButtonStandard from '../../shared-react-components/elements/prhbutton';
import ModalStandard from '../../shared-react-components/elements/modalstandard';
import './elements.scss';

export const PH1 = (props) => {
  const { className, children } = props;
  return (
    <div
      className={`PH1 fort-extrabold fs-4 lh-5 d-inline-block text-gray-900 d-inline-block ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
};

export const PH2 = (props) => {
  const { className, children } = props;
  return (
    <div
      className={`PH2 fort-medium text-gray-700 fs-2 lh-3 d-inline-block ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
};
export const PH3 = (props) => {
  const { className, children } = props;
  return (
    <div
      className={`PH3 fort-book text-gray-700 fs-2 lh-2 d-inline-block ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
};
export const PH4 = (props) => {
  const { className, children, allcaps } = props;
  return (
    <div
      className={`PH4 fort-light fs-2 text-gray-900 font-weight-bold ls--0pt25px lh-3 d-inline-block ${
        className || ''
      }${allcaps ? '  fort-medium text-uppercase' : ''}`}
    >
      {children}
    </div>
  );
};
export const PH5 = (props) => {
  const { className, children, onClick, allcaps } = props;
  return (
    <div
      className={`PH5 d-inline-block ls--0pt25px font-weight-light fs-2 lh-3 fort-light text-gray-900 ${
        className || ''
      }${onClick ? ' link cursor-pointer ' : ''}
        ${allcaps ? '  fort-medium text-uppercase' : ''}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
export const PH6 = (props) => {
  const { className, children } = props;

  return (
    <div className={`PH6 fort-book d-inline-block fs-2 ${className || ''}`}>
      {children}
    </div>
  );
};
export const PH7 = (props) => {
  const { className, children, onClick } = props;
  return (
    <div
      className={`PH7 d-inline-block fs-2 lh-4 text-blue fort-book ${
        onClick ? 'link cursor-pointer ' : ''
      }${className || ''}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const PRHContainerHeader = (props) => {
  const { children } = props;
  return <div className={'white-box-header fort-light'}>{children}</div>;
};

export const PRHContainerSubHeader = (props) => {
  const { children } = props;
  return <div className={'fort-bold subhead'}>{children}</div>;
};

export const PRHLabel = (props) => {
  const { children, className } = props;
  return <label className={`input-label ${className || ''}`}>{children}</label>;
};

export const Divider = (props) => {
  const { horizontal, padded, tight, className, style } = props;
  if (horizontal) {
    return (
      <div className={`e-divider-horizontal ${className || ''}`} style={style}>
        |
      </div>
    );
  }
  return (
    <div
      className={`e-divider ${tight ? ' tight' : ''}${
        padded ? ' padded' : ''
      } ${className || ''}`}
      style={style}
    />
  );
};

export const PRHSpan = (props) => {
  const { children, className, rightpadded, large } = props;
  return (
    <div
      className={`d-flex prhspan ${rightpadded ? ' mr-1' : ''}${
        large ? ' large' : ''
      }`}
    >
      <div className={`${className || ''}`}>{children}</div>
    </div>
  );
};
/*
export const PRHButton = (props) => {
  const {
    className = '',
    children,
    icon,
    iconright,
    padded,
    onClick,
    textpadding,
    disabled,
    inverted,
  } = props;

  const classesArray = [
    'prhbutton',
    'fs-2',
    'fort-book',
    'd-inline-flex',
    'justify-content-center',
    'align-items-center',
    className,
  ];
  if (icon && !children) {
    classesArray.push('p-1');
  } else {
    if (icon && children) {
      classesArray.push('icon-text');
    }
    if (children) {
      classesArray.push('text');
    }
    if (iconright) {
      classesArray.push('icon-right');
    }
  }

  if (padded) {
    classesArray.push('padded');
  }

  if (disabled) {
    classesArray.push('disabled');
  }

  if (isIE) {
    classesArray.push('ie-button');
  }

  if (isFirefox) {
    classesArray.push('ff-button');
  }

  if (isChrome) {
    if (osName === 'Windows') {
      classesArray.push('win-chrome-button');
    } else {
      classesArray.push('osx-chrome-button');
    }
  }

  if (inverted) {
    classesArray.push('inverted');
  }

  return (
    <div
      className={classesArray.join(' ')}
      onClick={!disabled && onClick ? onClick : null}
    >
      {icon ? <Icon name={icon} /> : ''}
      <span className={textpadding ? ' text-padding' : ''}>{children}</span>
      {iconright ? <Icon name={iconright} /> : ''}
    </div>
  );
};
*/
export const PRHDropdown = (props) => {
  const {
    className,
    trigger,
    icon,
    options,
    onSelect,
    direction,
    selected,
  } = props;
  const opts = {};
  let noicon = null;

  if (trigger) {
    opts.trigger = trigger;
  }
  // if (className) opts.className = className;
  if (icon) noicon = '';

  return (
    <div className="prhdropdown">
      <Dropdown
        className={`tb-button menu-header home-sort ${className || ''}`}
        {...opts}
        direction={direction || 'right'}
        simple
        item
        openOnFocus
        text={selected}
      >
        <Dropdown.Menu>
          {options.map((uo) => (
            <Dropdown.Item
              key={uo.key}
              className={`prhdropdown dropdown-item ${uo.className}${
                uo.active ? ' active' : ''
              }`}
              onClick={onSelect ? (e) => onSelect(e, uo.value) : null}
            >
              <div className="d-flex">
                {uo.icon ? <Icon name={uo.icon} /> : null}
                <span
                  className={`w-100${
                    uo.active ? ' text-orange-important' : ''
                  }`}
                >
                  {uo.content}
                </span>
                <Icon
                  className={`pl-2${uo.active ? ' text-orange-important' : ''}`}
                  name={uo.icon_r}
                />
              </div>
              {/* <Header
                className="text"
                icon={uo.icon ? uo.icon : noicon}
                content={uo.content}
              /> */}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export const PRHLink = withRouter((props) => {
  const { src, children, title, className, disabled } = props;

  if (disabled) {
    return (
      <a
        title={title || src}
        href={'#'}
        className={`prhlink disabled ${className || ''}`}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      title={title || src}
      to={src}
      className={`prhlink internal ${className || ''}`}
    >
      {children}
    </Link>
  );
});

export const PRHContainer = (props) => {
  const { children } = props;
  return <div className="prh-container-test">{children}</div>;
};

export const PRHSubContainer = (props) => {
  const { children } = props;
  return <div className="prh-container-test">{children}</div>;
};

export const PRHContainerGeneral = (props) => {
  const { children, className } = props;
  const classesArray = ['prh-white-background-container', className];
  return <div className={classesArray.join(' ')}>{children}</div>;
};

export const PRHClearSelection = (props) => (
  <span className="prh-clear-selection">
    <Icon name="times circle outline" />
  </span>
);

export const PRHActiveSelection = (props) => (
  <div className="prh-active-selection" />
);

export const PRHButton = (props) => {
  const { children, icon, iconsvg, onClick, className } = props;
  return (
    <PRHButtonStandard
      className={`fort-light-bold biz-form-button ${className}`}
      icon={icon}
      fontAwesome={true}
      size="large"
      onClick={onClick}
      displayInlineFlex
    >
      {children}
    </PRHButtonStandard>
  );
};

export const PRHPopup = () => {
  const { children, open, trigger } = props;
  // console.log('biz2', modalOpen);
  return (
    <Popup open={open} trigger={trigger}>
      {children}
    </Popup>
  );
};

export const Biz2Modal = (props) => {
  const { children, header, modalOpen, modalText } = props;
  // console.log('biz2', modalOpen);
  return (
    <ModalStandard modalOpen={modalOpen} header={header} modalText={modalText}>
      {children}
    </ModalStandard>
  );
};

export const PRHInput = (props) => {
  const {
    value,
    onChange,
    style,
    placeholder,
    className,
    isError,
    errorMessage,
    label,
    width,
    wrapperClassName,
    ...restProps
  } = props;

  return (
    <div className={`d-flex flex-column ${wrapperClassName}`}>
      {label ? label : null}
      <div>
        <Input
          width={width}
          value={value}
          onChange={onChange}
          style={style}
          placeholder={placeholder}
          className={className}
          {...restProps}
        ></Input>
      </div>
      {isError ? <div className="text-red mt-1">{errorMessage}</div> : null}
    </div>
  );
  // return (
  //   <Input
  //     id="userName"
  //     name="userName"
  //     type="text"
  //     onChange={handleChange}
  //     value={formState.legacyUserForm.userName}
  //     validators={['required']}
  //   />
  // );
};

export const PRHError = (props) => {
  const { isError, errorMessage } = props;

  return (
    <>{isError ? <div className="text-red mt-1">{errorMessage}</div> : null}</>
  );
};

export const ButtonHolder = (props) => {
  const { children, className = '' } = props;
  return <div className={`button-holder ${className}`}>{children}</div>;
};

export const PRHButton2 = (props) => {
  const {
    className = '',
    children,
    icon,
    iconright,
    padded,
    onClick,
    textpadding,
    disabled,
    inverted,
  } = props;

  const classesArray = [
    'prhbutton',
    'fs-2',
    'fort-book',
    'd-inline-flex',
    'justify-content-center',
    'align-items-center',
    className,
  ];
  if (icon && !children) {
    classesArray.push('p-1');
  } else {
    if (icon && children) {
      classesArray.push('icon-text');
    }
    if (children) {
      classesArray.push('text');
    }
    if (iconright) {
      classesArray.push('icon-right');
    }
  }

  if (padded) {
    classesArray.push('padded');
  }

  if (disabled) {
    classesArray.push('disabled');
  }

  if (isIE) {
    classesArray.push('ie-button');
  }

  if (isFirefox) {
    classesArray.push('ff-button');
  }

  if (isChrome) {
    if (osName === 'Windows') {
      classesArray.push('win-chrome-button');
    } else {
      classesArray.push('osx-chrome-button');
    }
  }

  if (inverted) {
    classesArray.push('inverted');
  }

  return (
    <Button
      className={classesArray.join(' ')}
      onClick={!disabled && onClick ? onClick : null}
    >
      {icon ? <Icon name={icon} /> : ''}
      <span className={textpadding ? ' text-padding' : ''}>{children}</span>
      {iconright ? <Icon name={iconright} /> : ''}
    </Button>
  );
};
