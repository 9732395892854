import { useEffect, useState } from "react";
import LazyImage from "../../utils/LazyImage";
import useSecurity from "../../components/hc/useSecurity";
import { numberAsCurrency } from "../../utils/utilities";

import "./OrderDetailDropdown.scss";
import { Grid } from "semantic-ui-react";

export const OrderDetailDropdownRowMobile = ({
  book,
  status,
  cancelTriggered,
}) => {
  const user = useSecurity();
  const [isCanceled, setisCanceled] = useState(book.status === "2");
  const [isMissing, setIsMissing] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    !!cancelTriggered && cancelTriggered();
    if (
      book.titleDetail.author === null &&
      book.titleDetail.format === null &&
      book.titleDetail.onsale === null &&
      book.titleDetail.cartonQty === null
    ) {
      setIsMissing(true);
    }
  }, [cancelTriggered, isCanceled]);

  // console.log('book', book);
  return (
    <div key={book.isbn} className='my-4'>
      <Grid>
        <Grid.Row width={16}>
          <Grid.Column width={3}>
            <LazyImage
              src={`https://images.penguinrandomhouse.com/cover/${book.isbn}`}
              className={`odd-title-image mobile ${
                isCanceled && "odd-title-image-canceled"
              }`}
              alt={"Book"}
              isSmallThumbNail={true}
              isVariant={book.titleDetail.isVariant}
              varTagWidth={50}
            />
          </Grid.Column>
          <Grid.Column width={9}>
            {!isMissing ? (
              <div className='fort-bold'>
                {book.titleDetail.title
                  ? book.titleDetail.title.length > 48
                    ? book.titleDetail.title.substring(0, 48) + "..."
                    : book.titleDetail.title
                  : book.title
                  ? book.title.length > 48
                    ? book.title.substring(0, 48) + "..."
                    : book.title
                  : ""}
              </div>
            ) : (
              <div />
            )}
            {!isMissing ? (
              <div className='od-text-data-book'>
                By: {book.titleDetail.author}
              </div>
            ) : (
              <div />
            )}
            {!isMissing && isShowMore && <div>{book.titleDetail.format}</div>}
            {!isMissing && isShowMore && (
              <div>{book.titleDetail.cartonQty} per carton</div>
            )}
            {!["Billed", "In Progress", "Shipped"].includes(status) &&
              isShowMore && (
                <div className='od-text-data-book'>
                  Shipping From: {book.warehouse}
                </div>
              )}
            {isShowMore && (
              <div className='od-text-data-book'>UPC/SKU: {book.isbn}</div>
            )}
            <div
              className='text-blue cursor-pointer mt-1'
              onClick={() => setIsShowMore(!isShowMore)}
            >
              {isShowMore ? "View less" : "View more"}
            </div>
          </Grid.Column>
          <Grid.Column width={4} className='mobile-correction-order-detail'>
            {!isMissing && (
              <div className='d-flex justify-content-end'>
                Qty: <span className='fort-bold'>{book.quantity}</span>
              </div>
            )}
            {!isMissing && (
              <div className='d-flex justify-content-end fort-bold'>
                $
                {Number(book.netAmt).toFixed(2) > 0
                  ? numberAsCurrency(Number(book.netAmt).toFixed(2))
                  : "0.00"}
              </div>
            )}
            {!isMissing && user.isAppBiz() ? (
              <div className='od-discount-price d-flex flex-column justify-content-end align-items-end'>
                <span>{book.disc}% off </span>
                <span className='text-line-through'>
                  $
                  {Number(book.coverPrice).toFixed(2) > 0
                    ? numberAsCurrency(Number(book.coverPrice).toFixed(2))
                    : "0.00"}
                </span>
              </div>
            ) : null}
            {!isMissing && (
              <div className='text-right'>
                $
                {Number(book.netPrice).toFixed(2) > 0
                  ? numberAsCurrency(Number(book.netPrice).toFixed(2))
                  : "0.00"}{" "}
                (each)
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
