import React, { useState } from 'react';
import './OrdersAndInvoicesMobileTable.scss';
import { Link } from 'react-router-dom';
import { Divider } from '../../components/elements/elements';

const docTypes = {
  F2: 'Invoice',
  L2: 'Debit Memo',
  G2: 'Credit Memo',
  ZL2: 'Debit Memo-Return',
  ZG2: 'Credit Memo-Return',
};

function docTypeFormatter(params) {
  return docTypes[params.value];
}

function currencyFormatter(params) {
  const number = Number(
    (params.data && params.data.netValue) || params.value
  ).toFixed(2);
  return `$${isNaN(number) ? (0).toFixed(2) : number}`;
}

export const mobileTableColumnsOrders = [
  {
    label: 'SALES ORDER',
    key: 'salesOrderNumber',
  },
  {
    label: 'DATE CREATED',
    key: 'dateCreated',
  },
  {
    label: 'PURCHASE ORDER',
    key: 'poNumber',
  },
  {
    label: 'SHIP TO ACCOUNT',
    key: 'account',
  },
];

export const mobileTableColumnsInvoices = [
  {
    label: 'BILLING DOC',
    key: 'billingDocNumber',
    valueFormatter: null,
  },
  {
    label: 'DATE CREATED',
    key: 'dateCreated',
    valueFormatter: null,
  },
  {
    label: 'PURCHASE ORDER',
    key: 'purchaseOrderNumber',
    valueFormatter: null,
  },
  {
    label: 'SHIP TO ACCOUNT',
    key: 'soldToAccount',
    valueFormatter: null,
  },
  {
    label: 'DOC TYPE',
    key: 'docType',
    valueFormatter: docTypeFormatter,
  },
  {
    label: 'NET VALUE',
    key: 'netValue',
    valueFormatter: currencyFormatter,
    // valueFormatter: null
  },
];

const OrdersAndInvoicesMobileTable = ({
  columns,
  data,
  noDataMessage,
  pageType = null,
}) => {
  if (pageType === 'invoice') {
    const newData = data.sort((a, b) => {
      return new Date(a.dateCreated) > new Date(b.dateCreated) ? -1 : 1;
    });
  }

  const mobileData = data.map((d) => {
    d.tableData=[];
    if (columns) {
      for (const index in columns) {
        const value = columns[index].valueFormatter
          ? columns[index].valueFormatter({ value: d[columns[index].key] })
          : d[columns[index].key];
        let link = null;
        if (columns[index].key === 'salesOrderNumber') {
          link = `/orders/${d.salesOrderNumber}`;
        } else if (columns[index].key === 'billingDocNumber') {
          link = `/invoices/${d.billingDocNumber}`;
        } else {
          link = null;
        }
        d.tableData.push({
          label: columns[index].label,
          key: columns[index].key,
          valueFormatter: columns[index].valueFormatter,
          value: value,
          link: link,
        });
      }
    }
    return d;
  });
  // .reverse();

  return (
    <div className="mobile-table">
      {mobileData && mobileData.length === 0 && <>{noDataMessage}</>}
      {mobileData &&
        mobileData.length > 0 &&
        mobileData.map((data, index) => {
          return (
            <React.Fragment key={`${data.salesOrderNumber}_${index}`}>
              {data.tableData &&
                data.tableData.map((d, idx) => {
                  return d.link ? (
                    <div
                      className={`cell row ${
                        idx === data.tableData.length - 1 ||
                        idx === data.tableData.length - 2
                          ? 'mb-0'
                          : ''
                      }`}
                      key={`${d.value}_${index}`}
                    >
                      <div>{d.label}</div>
                      <Link to={d.link}>
                        <div>{d.value}</div>
                      </Link>
                    </div>
                  ) : (
                    <div
                      className={`cell row ${
                        idx === data.tableData.length - 1 ||
                        idx === data.tableData.length - 2
                          ? 'mb-0'
                          : ''
                      }`}
                      key={`${d.value}_${index}`}
                    >
                      <div>{d.label}</div>
                      <div>{d.value}</div>
                    </div>
                  );
                })}
              {index < mobileData.length - 1 && (
                <Divider tight className="mt-2 mb-2" />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};

OrdersAndInvoicesMobileTable.defaultProps = {
  columns: [],
  data: [],
};

export default OrdersAndInvoicesMobileTable;
