//import React from "react";
import React, { useState, useEffect, createRef } from 'react'
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import ReportSingle from '../../assets/images/report-single.svg';
import { formatDateMMDDYYYY } from '../../utils/utilities';
import { Divider } from "../elements/elements";
import "./PreDeliveryReportsModal.scss";

const PreDeliveryReportsModal = ({
  isOpen,
  onClose,
  preDeliveryReports,
  onDownloadPreDeliveryReport,
  accountNumber,
  resolveDateFormat,
}) => {
  const [height, setHeight] = useState(0)
  const ref = createRef();
  useEffect(() => {
    if (ref && ref.current && ref.current.clientHeight) {
      setHeight(ref.current.clientHeight)
    }
  }, [ref]);

  const PreDeliveryReportsList = ({ preDeliveryReport, i, preDeliveryReportLength}) => {
    const { onSaleDate, shipTos, downloads } = preDeliveryReport;
    return (
      <>
                  <div key={`${i}_${onSaleDate}`}>
                  <div className="d-flex flex-row mb-2 mt-2">
                    <div className="d-flex flex-column ml-2 onSaleDate">
                      <div className="fort-bold fs-13px lh-18px text-gray-700 flex">
                        
                    <div className="fort-medium fs-14px text-gray-900 flex">
                    <div>
                      <img
                    name="file alternate outline"
                    src={ReportSingle}
                    className="mr-1"
                      color="grey"
                    />
                    </div>
                    <div>{formatDateMMDDYYYY(onSaleDate)} | {/*shipTo*/} (.csv)</div>
                    </div>
                    
                    </div>
                    </div>
                    <div className="d-flex flex-column ml-auto justify-content-center">
                    {shipTos.map((shipTo, index)=>{
                      return (
                        <div className={"downloadRow"} key={`${index}_${shipTo}`}>{shipTo}</div>
                      );
                    })}
                    </div>
                    <div className="d-flex flex-column ml-auto justify-content-center">
                      <div className="fort-book fs-13px lh-18px text-gray-700 ml-auto mr-1">
                      {downloads.map((download, index)=>{
                      return (
                        <div className={"downloadRow"} key={`${index}_${download.shipTo}`}><PRHButton
                  //iconleft
                  icon="arrow down"
                  size="small"
                  backgroundColorOverride="white"
                  onClick={() => onDownloadPreDeliveryReport(download.onSaleDate, download.shipTo)}
                >
                  Download
                </PRHButton></div>
                  );
                })}
                      </div>
                    </div>
                    
                  </div>
                  {i+1<preDeliveryReportLength ? <Divider className="mt-0 mb-0" />:''}
                </div>
          </>
    );
  };

  return (
    <DialogModal
      open={isOpen}
      size={'small'}
      dialogOptions={{ header: "DOWNLOAD PRE-DELIVERY REPORTS" }}
      onClose={onClose}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      //modalClassName="pre-delivery-modal"
      contentPx="px-0"
      contentPt="pt-2" 
    >
      <div className="ar-box">
          <div className="fort-book fs-12px ml-4 mr-4 mb-2 gray-900">Pre-Delivery Reports (.csv) are available until 7 days after its OSD.</div>
          <Divider className="mt-0 mb-0" />
          <div
            style={{ maxHeight: '400px' }}
            id={"container"}
            className={`pl-2 pr-2 pt-0 pb-0  bg-gray-100 ${height && height <= 400 ? ('overflow-hidden'):('overflow-auto')}`}
          >
            <div ref={ref}>
        {preDeliveryReports.map((preDeliveryReport, i) => (
          <PreDeliveryReportsList
            preDeliveryReport={preDeliveryReport}
            preDeliveryReportLength={Object.keys(preDeliveryReports).length}
            i={i}
            key={i}
          />
        ))}
        </div>
      </div>
      <Divider className="mt-0 mb-0" />
      <PRHButton
                  //iconleft="true"
                  icon="check"
                  size="medium"
                  className={'float-right margin-left mt-2 mr-2'}
                  borderColorOverride="blue"
                  textColorOverride="white"
                  textColorHoverOverride="blue"
                  backgroundColorOverride="blue"
                  backgroundHoverColorOverride="white"
                  iconColorOverride="white"
                  iconColorHoverOverride="blue"
                  textClass="fort-bold"
                  onClick={() => onClose()}
                >
                  Done
                  </PRHButton>
      </div>
    </DialogModal>
  );
};

export default PreDeliveryReportsModal;
