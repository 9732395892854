import { useState, useEffect } from "react";
import {
  Header,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./VariantRestrictionsOrderUpdateModal.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import useCart from "../hc/useCart";
import LazyImage from "../../utils/LazyImage";

const VariantRestrictionsOrderDeleteModal = (props) => {
  const {
    foc,
    shipTo,
    variantOrderTitles,
    openState = false,
    setOpenState,
    proceedCallback,
    cancelCallback,
  } = props;

  const {
    mainVariantTitles,
    totalNumberOfItems,
    totalNumberOfQuantities,
    selectedAllItems,
  } = variantOrderTitles;

  // console.log("VariantRestrictionsOrderDeleteModal", props);
  const cart = useCart();
  const [actionPerformed, setActionPerformed] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const resetState = () => {
    setDialogVisible(false);
  };

  let headerMsg = "";
  let footerMsg = "";

  let numVariantTitles = 0;
  let numEffectedLocationsMap = {};
  let numQualifyingTitles = 0;
  let numQualifyingTitlesQty = 0;
  let variantLocation = false;
  let currentLocationEffected = false;

  mainVariantTitles.forEach((mvt) => {
    numQualifyingTitles += mvt.qualifyingTitles.length;
    numQualifyingTitlesQty += mvt.totalQualifyingQuantity;
    numVariantTitles += mvt.affectedVariantTitles.length;
    mvt.affectedVariantTitles.forEach((al) => {
      // numVariantTitles += al.quantity;
      al.affectedLocations.forEach((l) => {
        // numEffectedLocations++;
        numEffectedLocationsMap[l.shipTo] = true;
        if (l.shipTo.toString() !== shipTo.toString()) {
          variantLocation = l.shipTo;
        } else {
          currentLocationEffected = true;
        }
      });
    });
  });
  let numEffectedLocations = Object.keys(numEffectedLocationsMap).length;

  if (selectedAllItems) {
    headerMsg = (
      <span>
        You are trying to remove <span className='fort-bold'>all items</span> of{" "}
        <span className='fort-bold'>FOC {formatDateMMDDYYYY(foc, "/")}</span>.
        {"  "}
        <span>
          This will disqualify and remove{" "}
          <span className='fort-bold'>{numVariantTitles}</span> variant
          title(s)' following quantities from
        </span>{" "}
        {!!currentLocationEffected && numEffectedLocations === 1 ? (
          <span>your cart:</span>
        ) : (
          <span>
            <span className='fort-bold'>{numEffectedLocations}</span>{" "}
            account(s)':
          </span>
        )}
      </span>
    );
  } else if (numQualifyingTitles === 1) {
    headerMsg = (
      <span>
        You are trying to remove{" "}
        <span className='fort-bold'>{numQualifyingTitlesQty}</span> of{" "}
        <span className='fort-bold'>
          {mainVariantTitles[0].qualifyingTitles[0].title}
        </span>
        .{"  "}
        <span>
          This will disqualify and remove{" "}
          <span className='fort-bold'>{numVariantTitles}</span> variant
          title(s)' following quantities from
        </span>{" "}
        {!!currentLocationEffected && numEffectedLocations === 1 ? (
          <span>your cart:</span>
        ) : (
          <span>
            <span className='fort-bold'>{numEffectedLocations}</span>{" "}
            account(s):
          </span>
        )}
      </span>
    );
  } else {
    headerMsg = (
      <span>
        <span className='fort-bold'>{numQualifyingTitles}</span> of the{" "}
        <span className='fort-bold'>{totalNumberOfItems}</span> items that you
        are trying to remove will disqualify and remove {numVariantTitles}{" "}
        variant titles' following quantities from{" "}
        {!!currentLocationEffected && numEffectedLocations === 1 ? (
          <span>your cart:</span>
        ) : (
          <span>
            <span className='fort-bold'>{numEffectedLocations}</span>{" "}
            account(s):
          </span>
        )}
        {/* <span className="fort-bold">{numEffectedLocations}</span> accounts: */}
      </span>
    );
  }
  footerMsg =
    !!currentLocationEffected && numEffectedLocations === 1 ? (
      <span className='fort-book fs-13px lh-18px'>
        Are you sure you want to continue and remove all items from your cart?
      </span>
    ) : (
      <span className='fort-book fs-13px lh-18px'>
        Are you sure you want to continue and remove all items from these
        account(s)?
      </span>
    );

  const ContinueAction = {
    label: "Yes, Continue",
    borderColorOverride: "blue",
    textColorOverride: "white",
    textColorHoverOverride: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "white",
    iconColorOverride: "white",
    iconColorHoverOverride: "blue",
    // icon: 'checkmark',
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      setActionPerformed(true);
      // updateRemove();
      if (proceedCallback) proceedCallback();
      resetState();
      return { proceed: true };
    },
  };

  const CancelAction = {
    label: "Cancel",
    className: "ml-auto",
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      resetState();
      if (cancelCallback) cancelCallback();
      return { proceed: true };
    },
  };

  useEffect(() => {
    if (dialogVisible) {
      if (!openState) {
        if (!actionPerformed && cancelCallback) cancelCallback();
        resetState();
        setActionPerformed(false);
      }
    }
    if (openState && !dialogVisible) {
      setDialogVisible(true);
    }
  }, [actionPerformed, cancelCallback, dialogVisible, openState]);

  // console.log(
  //   'titleRestrictions',
  //   shipTo,
  //   foc,
  //   titleRestrictions,
  //   numVariantTitles,

  // );

  return (
    <DialogModal
      open={openState}
      //open
      setOpen={setOpenState}
      // onXClose={cancelCallback}
      modalClassName='vro-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            {!!currentLocationEffected && numEffectedLocations === 1
              ? "CHANGES TO YOUR CART"
              : "Changes to Other Account(s)' Carts"}
          </Header>
        ),
        actions: [CancelAction, ContinueAction],
      }}
    >
      <div className='ar-box'>
        <>
          <div className='ml-4 mr-4 mb-2'>{headerMsg}</div>
          <Divider className='mt-0 mb-0' />
          <div
            style={{ maxHeight: "400px" }}
            className={`overflow-auto bg-gray-100`}
          >
            {mainVariantTitles.map((mvt, mvt_idx) => {
              return mvt.affectedVariantTitles.map((title, idx) => (
                <div key={`mvt-${mvt_idx}-${idx}`}>
                  {(mvt_idx > 0 || idx > 0) && (
                    <Divider className='mb-1' tight />
                  )}
                  <div className='d-flex flex-row pl-4 pr-4 mt-1 mb-1 pt-1'>
                    <LazyImage
                      className='vri-title-cover'
                      boxShadow={true}
                      src={`https://images.penguinrandomhouse.com/cover/${title.isbn}`}
                    ></LazyImage>

                    <div className='d-flex flex-column ml-2 justify-content-center'>
                      <div className='fort-bold fs-13px lh-18px text-gray-700'>
                        {title.title}
                      </div>
                      <div className='fort-book fs-13px lh-18px text-gray-700'>
                        {title.isbn}
                      </div>
                    </div>
                    <div className='d-flex flex-column ml-auto justify-content-center'>
                      <div className='fort-book fs-13px lh-18px text-gray-700 ml-auto'>
                        {title.affectedLocations.length > 1 ||
                        (title.affectedLocations.length === 1 &&
                          title.affectedLocations[0].shipTo.toString() !==
                            shipTo.toString())
                          ? "Total"
                          : ""}{" "}
                        Qty: <span className='fort-bold'>{title.quantity}</span>
                      </div>
                    </div>
                  </div>

                  {/* {title.affectedLocations.length > 1 ||
                  (title.affectedLocations.length === 1 &&
                    title.affectedLocations[0].shipTo.toString() !==
                      shipTo.toString()) */}
                  {title.affectedLocations.length > 0
                    ? title.affectedLocations.map((al, i) => (
                        <div key={i} className={`pl-4 pr-4 `}>
                          <Divider tight />
                          <div className='d-flex flex-row mt-1 mb-1'>
                            <div className=''>
                              Account #{al.shipTo}{" "}
                              <span className='fort-book-italic'>
                                {shipTo === al.shipTo ? (
                                  "(This cart)"
                                ) : al.confirmationStatus === "CONFIRMED" ? (
                                  <>
                                    (Confirmed){" "}
                                    <Popup
                                      className='cp-help-tooltip'
                                      content='This account’s cart has had no updates since it was Confirmed. Continuing will update their cart and requires re-confirmation.'
                                      on='hover'
                                      position='right center'
                                      offset={[0, 5]}
                                      trigger={
                                        <Icon
                                          name='question circle'
                                          className='sb_question_icon'
                                        />
                                      }
                                    />
                                  </>
                                ) : null}
                              </span>
                            </div>
                            <div className='d-flex flex-column ml-auto justify-content-center'>
                              <div className='fort-book fs-13px lh-18px text-gray-700 ml-auto'>
                                Qty:{" "}
                                <span className='fort-bold'>{al.quantity}</span>
                              </div>
                            </div>
                          </div>
                          {/* {i + 1 < title.affectedLocations.length && (
                            <Divider className="mb-1" tight />
                          )} */}
                        </div>
                      ))
                    : null}
                  {/* {idx + 1 < mvt.affectedVariantTitles.length && (
                    <Divider className="mt-0 mb-0" />
                  )} */}
                </div>
              ));
            })}
          </div>
          <Divider className='mt-0 mb-0' />
          <div className='ml-4 mr-4 mt-2 fort-bold fs-12px lh-14px'>
            {footerMsg}
          </div>
        </>
      </div>
    </DialogModal>
  );
};

VariantRestrictionsOrderDeleteModal.propTypes = {
  openState: PropTypes.bool.isRequired,
  setOpenState: PropTypes.func.isRequired,
};

VariantRestrictionsOrderDeleteModal.defaultProps = {};

export default VariantRestrictionsOrderDeleteModal;
