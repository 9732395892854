import { Component } from "react";
import { Table, Header, Loader, Modal, Icon } from "semantic-ui-react";
import withSecurity from "../hc/withSecurity";
import withApp from "../hc/withApp";
import withCart from "../hc/withCart";
import AccountsReps from "../../assets/images/accounts-reps.svg";
import { withRouter } from "react-router";
import UserList from "./UserList";
import {
  PRHButton,
  Biz2Modal,
  PRHContainerGeneral,
  PRHContainerHeader,
} from "../elements/elements";
import { userInitialsFullName } from "../../utils/utilities";
import { Divider } from "../../shared-react-components/elements/elements";
import "./admin.scss";
import withMobile from "../hc/withMobile";
import { ecomApi } from "../../api/ecomApi";
import { connect } from "react-redux";

class AccountReps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  modalControl(e) {
    this.setState({ modalOpen: e });
  }

  componentDidMount() {
    this.props.getSalesReps({}, true);
  }

  

  render() {
    const { data: reps, isSuccess, isLoading } = this.props.salesReps;
    const isMobile = this.props.mobile.isMobile;
    const cleanSubjectLine=(text)=>{
      let result = text.replace(/&/g, 'and');
      result = result.replace(/[^a-zA-Z0-9\s]/g, ' ');
      return result;
    }
    return (
      <>
        <div className='accounts-settings'>
          {isLoading ? (
            <Modal open={isLoading}>
              <Loader inverted />
            </Modal>
          ) : (
            ""
          )}
          <div className={isMobile ? "mb-4" : ""}>
            <div className='accounts-page-headers-images'>
              <img src={AccountsReps} alt='Reps Icon' />
            </div>
            <div className='accounts-page-headers fort-light text-gray-900 fs-24px'>
              Account Reps
            </div>
            {!isMobile && <Divider className='accounts-header-divider' />}
          </div>
        </div>
        {reps ? (
          <PRHContainerGeneral className='content-block-border pt-1 pb-0 pl-0 pr-0 mb-6 mt-2 mb-2 '>
            <div
              className={`fort-light-bold text-uppercase pt-1 pb-1 ${
                isMobile ? "ml-2" : "ml-4"
              }`}
            >
              sales rep
            </div>
            {/* {isMobile && <span className="fs-10px text-gray-700 fort-book ml-2">Contact for any questions about... (FPO)</span>} */}
            <Divider tight />
            <div
              className={`ar-box ${isMobile ? "ar-box-mobile" : ""}`}
              style={{ position: "relative" }}
            >
              {reps &&
                reps.map((rep, idx) => (
                  <div
                    key={idx}
                    className={`${isMobile ? "ml-2" : "mb-1 ml-4"}`}
                  >
                    <div
                      className={`d-flex flex-row align-items-center pr-2 ${
                        isMobile ? "" : "mb-2"
                      }`}
                    >
                      {rep.name ? (
                        <div className='no-user-image mr-1 mt-1 mb-1 accounts-purple hidden-on-mobile'>
                          <div className='no-image-text'>
                            {userInitialsFullName(rep.name)}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className='d-flex flex-column'>
                        <div
                          className='pt-1 pb-1 fort-medium'
                          style={{ textTransform: "capitalize" }}
                        >
                          {rep.name ? rep.name.toLowerCase() : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </PRHContainerGeneral>
        ) : (
          ""
        )}

        {this.props.cart.customerInfo.customer.creditRep ? (
          <PRHContainerGeneral className='content-block-border pt-1 pb-1 pl-0 pr-0  mb-6 mt-2 mb-2 ar-box'>
            <div
              className={`fort-light-bold text-uppercase pt-1 pb-1 ${
                isMobile ? "ml-2" : "ml-4"
              }`}
              style={{ textTransform: "capitalize" }}
            >
              credit rep
            </div>
            {/* {isMobile && <span className="fs-10px text-gray-700 fort-book ml-2">Contact for any questions about... (FPO)</span>} */}
            <Divider tight />
            <div
              className={`ar-box ${isMobile ? "ar-box-mobile" : ""}`}
              style={{ position: "relative" }}
            >
              <div className={`${isMobile ? "ml-2" : "mb-1 ml-4"}`}>
                <div
                  className={`d-flex flex-row align-items-center pr-2 ${
                    isMobile ? "" : "mb-3"
                  }`}
                >
                  {this.props.cart.customerInfo.customer.creditRep ? (
                    <div className='no-user-image mr-1 mt-1 mb-1 accounts-green hidden-on-mobile'>
                      <div className='no-image-text'>
                        {userInitialsFullName(
                          this.props.cart.customerInfo.customer.creditRep
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className='d-flex flex-column'
                    style={{ marginTop: "-2px" }}
                  >
                    <div
                      className='pb-1 fort-medium'
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.props.cart.customerInfo.customer.creditRep
                        ? this.props.cart.customerInfo.customer.creditRep.toLowerCase()
                        : ""}
                    </div>
                    {this.props.cart.customerInfo?.customer?.creditRepEmail && (
                      <div>
                        <a
                          className='fort-medium d-flex align-items-center'
                          target='_blank'
                          href={`mailto:${this.props.cart.customerInfo.customer.creditRepEmail.toLowerCase()}?Subject=${
                            this.props.cart.customerInfo.customer.accountNo
                          } - ${
                            cleanSubjectLine(this.props.cart.customerInfo.customer.address.name1)
                          }`}
                        >
                          <Icon style={{ color: "#ddd" }} name='mail'></Icon>
                          {this.props.cart.customerInfo.customer.creditRepEmail.toLowerCase()}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </PRHContainerGeneral>
        ) : (
          ""
        )}

        {this.props.cart.customerInfo.customer.csRep ? (
          <PRHContainerGeneral className='content-block-border pt-1 pb-1 pl-0 pr-0  mb-6 mt-2 mb-2 ar-box'>
            <div
              className={`fort-light-bold text-uppercase pt-1 pb-1 ${
                isMobile ? "ml-2" : "ml-4"
              }`}
            >
              customer service rep
            </div>
            {/* {isMobile && <span className="fs-10px text-gray-700 fort-book ml-2">Contact for any questions about... (FPO)</span>} */}
            <Divider tight />
            <div
              className={`ar-box ${isMobile ? "ar-box-mobile" : ""}`}
              style={{ position: "relative" }}
            >
              <div className={`${isMobile ? "ml-2" : "mb-1 ml-4"}`}>
                <div
                  className={`d-flex flex-row align-items-center pr-2 ${
                    isMobile ? "" : "mb-1"
                  }`}
                >
                  {this.props.cart.customerInfo.customer.csRep ? (
                    <div className='no-user-image mr-1 mt-1 mb-1 accounts-green hidden-on-mobile'>
                      <div className='no-image-text'>
                        {userInitialsFullName(
                          this.props.cart.customerInfo.customer.csRep
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className='d-flex flex-column'>
                    <div
                      className='pt-1 pb-1 fort-medium'
                      style={{ textTransform: "capitalize" }}
                    >
                      {this.props.cart.customerInfo.customer.csRep
                        ? this.props.cart.customerInfo.customer.csRep.toLowerCase()
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </PRHContainerGeneral>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapState = (state) => ({
  salesReps: ecomApi.endpoints.getSalesReps.select({}, true)(state),
});
const mapDispatch = {
  getSalesReps: ecomApi.endpoints.getSalesReps.initiate,
};
const connector = connect(mapState, mapDispatch);

export default connector(
  withRouter(withSecurity(withApp(withCart(withMobile(AccountReps)))))
);
