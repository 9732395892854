import { Component } from 'react';
import withAccount from '../hc/withAccount';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { PRHContainerHeader } from '../../shared-react-components/elements/elements';

class Response extends Component {
  
  cancel = (e) => { 
    const { account } = this.props;
    this.props.history.push('/');
    account.setUpdateResponseHandler('default');
  };

  render() {
    const { account } = this.props;
    return (
      <>
        <div className={'white-background-container'}>
          <PRHContainerHeader>
            Please check your email for password reset link.
          </PRHContainerHeader>
          <br/><br/>
          <span>
          Please check your email <strong>{account.formState.createUserForm.email}</strong> and click 
          the reset password link.<br /><br />
          Don't see an email after a few minutes. <Link 
            onClick={() =>location.reload()}>CLICK HERE</Link> to try again.
          </span>
          <br />
          <br />
        </div>
      </>
    );
  }
}

export default withRouter(withAccount(Response));
