import React, { useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import moment from 'moment';
import './OrderDetailMobile.scss';
import { Divider } from '../../components/elements/elements';
import { OrderDetailPopup } from './OrderDetailPopup';

export const OrderDetailMobile = (props) => {
  const [isOpen, setIsOpen] = useState(undefined);
  return (
    <>
      <div className="mobile-catalogs-container border-gray-300 border-radius-3px p-2 pb-3 mt-2">
        <div className="catalogs-box-title" onClick={() => setIsOpen(true)}>
          <div className="d-flex flex-direction-row justify-content-space-between">
            <div className="d-flex flex-direction-row align-items-center">
              <Icon name={props.icon} color={props.iconColor} size="small" />
              <p className="mb-0 fort-book text-gray-900 fs-16px ml-1">
                {props.status} ({props.lineItems.length})
              </p>
            </div>
            <Icon name="angle right" className="fs-24px" />
          </div>
          {/* <Divider tight className="mt-2 mb-3" /> */}
        </div>
        <div>
          <div className="d-flex flex-row justify-content-between pb-0 pt-0">
            <Grid>
              {props.reqDeliveryDate && (
                <Grid.Row className="odd-dropdown-header-item">
                  <Grid.Column width={8}>Req. Delivery Date</Grid.Column>
                  <Grid.Column width={8}>
                    {moment(props.reqDeliveryDate).format('MMM Do, YYYY')}
                  </Grid.Column>
                </Grid.Row>
              )}
              {props.shippingFrom && (
                <Grid.Row className="odd-dropdown-header-item">
                  <Grid.Column width={8}>Shipped From</Grid.Column>
                  <Grid.Column width={8}>{props.shippingFrom}</Grid.Column>
                </Grid.Row>
              )}
              {props.trackingId && (
                <Grid.Row className="odd-dropdown-header-item">
                  <Grid.Column width={8}>Tracking #</Grid.Column>
                  <Grid.Column width={8}>
                    <a
                      className="btt-isbn"
                      href={props.trackingUrl}
                      target="_blank"
                    >
                      {props.trackingId}
                    </a>
                  </Grid.Column>
                </Grid.Row>
              )}
              {props.invoice && !props.user.isAppCompCopy() && (
                <Grid.Row className="odd-dropdown-header-item">
                  <Grid.Column width={8}>Invoice #</Grid.Column>
                  <Grid.Column width={8}>
                    <a
                      href={`/invoices/${props.invoice}`}
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push(`/invoices/${props.invoice}`);
                      }}
                    >
                      {props.invoice}
                    </a>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </div>
        </div>
      </div>
      <OrderDetailPopup
        onClose={() => setIsOpen(false)}
        items={props.lineItems}
        status={props.status}
        history={props.history}
        menuVisible={isOpen}
        reqDeliveryDate={props.reqDeliveryDate}
        shippingFrom={props.shippingFrom}
        invoice={props.invoice}
        trackingId={props.trackingId}
        trackingUrl={props.trackingUrl}
      />
    </>
  );
};
