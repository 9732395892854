import API from '../../shared-react-components/providers/components/api';
import { CONFIG } from '../../utils/const';

//
//
//  ListApi - docs:  https://randomhouse.app.box.com/file/643847892147
//
//

class RegistrationAPI extends API {
  constructor(props) {
    const { errorHandler } = props;

    super(props);

    this.errorHandler = errorHandler;
  }

  setDebug(debug) {
    this.setDebugValue(debug);
  }

  async setCacheItem(key, value) {
    this.isDebug() && console.log('MyhouseAPI:setCache');

    await this.setCache(key, value);

    this.isDebug() && console.log('MyhosueAPI:setCache Complete');
  }

  async removeCacheItem(key) {
    this.isDebug() && console.log('MyhouseAPI:setCache');
    await this.removeCache(key);
    this.isDebug() && console.log('MyhosueAPI:setCache Complete');
  }

  async getCacheItem(key) {
    this.isDebug() && console.log('MyhouseAPI:setCache');
    // console.log('get cache key', key);
    const testme = await this.getCache(key);
    // console.log('get cache item', testme);
    return this.getCache(key);
    this.isDebug() && console.log('MyhosueAPI:setCache Complete');
  }

  async createUser(createUser, recaptchaToken = null) {
    // console.log('token', this.sessionToken);
    try {
      const d = await this.post({
        url: CONFIG.baseUrl + '/registration/',
        data: createUser,
        headers: {
          'Content-Type': 'application/json',
          recaptchaToken: recaptchaToken,
        },
      });
      this.isDebug() && console.log(`AccountApi:getData Complete`, d);
      // console.log('return from netIQ service', d);
      if (d.status === 'OK') {
        this.setCache('createUser', d.data.data.obj);
      } else {
        this.setCacheItem('createUser', {
          status: 'FAILED',
          message: d.data.message,
        });
      }
      // console.log('d', d);
      return d;
    } catch (e) {
      // console.log('AccountApi:getUser Failure', e);
      return { status: 500, error: e };
    }
  }

  async createSubUser(createUser) {
    if (recaptchaToken === null) {
      headers = {
        'Content-Type': 'application/json',
      };
    } else {
      headers = {
        'Content-Type': 'application/json',
        recaptchaToken: recaptchaToken,
      };
    }
    try {
      const d = await this.post({
        url: CONFIG.baseUrl + '/user/',
        data: createUser,
        headers: headers,
      });
      this.isDebug() && console.log(`AccountApi:getData Complete`, d);
      if (d.status === 'OK') {
        this.setCache('createUser', d.data.data.obj);
      } else {
        this.setCacheItem('createUser', {
          status: 'FAILED',
          message: d.data.message,
        });
      }
      return d;
    } catch (e) {
      return { status: 500, error: e };
    }
  }

  async verifyEmail(token) {
    const tokenJsonObj = { token: token };
    try {
      const d = await this.put({
        url: CONFIG.baseUrl + '/registration/verify/',
        data: tokenJsonObj,
        headers: {
          'Content-Type': 'application/json',
        },
      });
      this.isDebug() && console.log(`Verify:getData Complete`, d);
      if (d.data.status === 'OK') {
        this.setCache('verifyEmail', 1);
      } else {
        this.setCache('verifyEmail', 0);
      }
      return d;
    } catch (e) {
      this.setCache('verifyEmail', 0);
      return { status: 500, error: e };
    }
  }

  async verifyEmailSubAccount(token, apiCall, method) {
    const tokenJsonObj = { token: token };
    let d;
    if (method === 'put') {
      d = await this.put({
        url: CONFIG.baseUrl + apiCall,
        data: tokenJsonObj,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    try {
      this.isDebug() && console.log(`Verify:getData Complete`, d);
      return d;
    } catch (e) {
      //this.setCache('verifyEmail', 0);
      return { status: 500 };
    }
  }

  async updateAccount(data, apiCall, method, recaptchaToken = null) {
    let d;
    let headers;
    if (recaptchaToken === null) {
      headers = {
        'Content-Type': 'application/json',
      };
    } else {
      headers = {
        'Content-Type': 'application/json',
        recaptchaToken: recaptchaToken,
      };
    }
    console.log('update account headers', headers);
    if (method === 'get') {
      d = await this.get({
        url: CONFIG.baseUrl + apiCall,
        headers: headers,
      });
    }
    if (method === 'put') {
      d = await this.put({
        url: CONFIG.baseUrl + apiCall,
        data: data,
        headers: headers,
      });
    } else if (method === 'patch') {
      d = await this.patch({
        url: CONFIG.baseUrl + apiCall,
        data: data,
        headers: headers,
      });
    } else if (method === 'post') {
      d = await this.post({
        url: CONFIG.baseUrl + apiCall,
        data: data,
        headers: headers,
      });
    } else if (method === 'delete') {
      d = await this.delete({
        url: CONFIG.baseUrl + apiCall,
        headers: headers,
      });
    }
    try {
      this.isDebug() && console.log(`Verify:getData Complete`, d);
      return d;
    } catch (e) {
      //this.setCache('verifyEmail', 0);
      return { status: 500 };
    }
  }

  async getLegacyUser(legacyUser, recaptchaToken) {
    // console.log('createUser', legacyUser);
    try {
      const d = await this.post({
        url: CONFIG.baseUrl + '/registration/legacy',
        data: legacyUser,
        headers: {
          'Content-Type': 'application/json',
          recaptchaToken: recaptchaToken,
        },
      });
      this.isDebug() && console.log(`AccountApi:getData Complete`, d);
      // console.log('legacy data', d);
      if (d.status === 201) {
        //this.setCache('createUser', d.data.data.obj);
        //window.location = '/verifylegacy';
      }
      return d;
    } catch (e) {
      // console.log('AccountApi:getUser Failure', e);
      this.errorHandler(e);
      return { status: 500, error: e };
    }
  }

  async validateSAPAccount(san, sapAcct, recaptchaToken) {
    try {
      const d = await this.post({
        url: CONFIG.baseUrl + '/registration/validate',
        data: {
          deptNo: '9005',
          sanNo: san,
          accountNo: sapAcct,
          recaptchaToken,
        },
        headers: {
          'Content-Type': 'application/json',
          recaptchaToken: recaptchaToken,
        },
      });
      this.isDebug() && console.log(`ValidateSAP:getData Complete`, d);
      return d;
    } catch (e) {
      // console.log('AccountApi:getUser Failure', e);
      this.errorHandler(e);
      return { data: { status: 'System_Error', error: e } };
    }
  }

  async resendVerifyEmail(id, subAccount = false, recaptchaToken) {
    let url = CONFIG.baseUrl + '/registration/resendinvitation/';
    if (subAccount) {
      url = CONFIG.baseUrl + '/user/resend-sub-invitation/' + id;
    
    try {
      const d = await this.get({
        url: url,
        // url: CONFIG.baseUrl + '/registration/resend-invitation/' + id,
        /*
        data: {
          from: 'bdasgupta@penguinrandomhouse.com',
          fromName: 'Biz Notification Services',
          subject: 'Verify Email',
          templateId: 'verificationEmail',
        },
        */
        headers: {
          'Content-Type': 'application/json',
        },
      });
      this.isDebug() && console.log(`ValidateSAP:getData Complete`, d);
      return d;
    } catch (e) {
      // console.log('AccountApi:getUser Failure', e);
      this.errorHandler(e);
      return { data: { status: 'System_Error', error: e } };
    }
    } else {
      try {
        const d = await this.post({
          url: url,
          data: {
            email: id
          },
          // url: CONFIG.baseUrl + '/registration/resend-invitation/' + id,
          /*
          data: {
            from: 'bdasgupta@penguinrandomhouse.com',
            fromName: 'Biz Notification Services',
            subject: 'Verify Email',
            templateId: 'verificationEmail',
          },
          */
          headers: {
            'Content-Type': 'application/json',
            recaptchaToken,
          },
        });
        this.isDebug() && console.log(`ValidateSAP:getData Complete`, d);
        return d;
      } catch (e) {
        // console.log('AccountApi:getUser Failure', e);
        this.errorHandler(e);
        return { data: { status: 'System_Error', error: e } };
      }
    }
  }

  async sendResetPasswordLink(id, recaptchaToken) {
    try {
      const d = await this.post({
        url: CONFIG.baseUrl + '/registration/forgot-password/' + id,
        data: {
          from: 'bdasgupta@penguinrandomhouse.com',
          fromName: 'Biz Notification Services',
          subject: 'Verify Email',
          templateId: 'verificationEmail',
        },
        headers: {
          'Content-Type': 'application/json',
          recaptchaToken,
        },
      });
      this.isDebug() && console.log(`ValidateSAP:getData Complete`, d);
      return d;
    } catch (e) {
      // console.log('AccountApi:getUser Failure', e);
      this.errorHandler(e);
      return { data: { status: 'System_Error', error: e } };
    }
  }

  async newCustomerEnrolment(newCustomer, enrolmentToken, recaptchaToken) {
    try {
      const d = await this.post({
        url: CONFIG.baseUrl + '/registration/signup/newaccount',
        data: newCustomer,
        headers: {
          'Content-Type': 'application/json',
          token: enrolmentToken,
          recaptchaToken,
        },
      });
      this.isDebug() &&
        console.log(`NewCustomerEnrollment:createCustomer Complete`, d);
      if (d.status === 'OK') {
        this.setCache('newCustomer', d.data.data.obj);
      } else {
        this.setCacheItem('newCustomer', {
          status: 'FAILED',
          message: d.data.message,
        });
      }
      return d;
    } catch (e) {
      return { status: 500, error: e };
    }
  }

  async newCustomerEnrolmentUploadFile(file, enrolmentToken, recaptchaToken) {
    let formData = new FormData();
    formData.append('file', file);
    try {
      const d = await this.post({
        url: CONFIG.baseUrl + '/registration/signup/upload-documents',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          token: enrolmentToken,
          recaptchaToken,
        },
      });
      this.isDebug() &&
        console.log(`NewCustomerEnrollment:uploadFile Complete`, d);
      return d;
    } catch (e) {
      return { status: 500, error: e };
    }
  }

  async getCustomerEnrolmentToken() {
    try {
      const d = await this.get({
        url: CONFIG.baseUrl + '/registration/signup/generate-token/',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      this.isDebug() &&
        console.log(`NewCustomerEnrollment:getToken Complete`, d);
      return d;
    } catch (e) {
      this.errorHandler(e);
      return { status: 500, error: e };
    }
  }
}

export default RegistrationAPI;
