import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import { dateDifference, formatStartEndDate } from '../../utils/utilities';
import useCart from '../hc/useCart';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_SCREEN_WIDTH } from '../../utils/const';
import LazyImage from '../../utils/LazyImage';
import './ReturnAbilityStatus.scss';

const ReturnAbilityStatus = (props) => {
  // console.log('ConfirmationStatus', props);
  const {
    detailPage = false,
    cbReturnable,
    cbReturnStart,
    cbReturnEnd,
    className = false,
  } = props;

  const cart = useCart();

  const isLvl1 = cart?.customerInfo?.currentUser?.roleNo === 'W1';

  /*if (isLvl1) {
    return <></>;
  }*/
  if (!!!cbReturnable) return <></>;

  let msg = '';
  let contentMsg = '';

  if (detailPage) {
    if (cbReturnable === 'Y') {
      msg = (
        <div className="d-flex flex-column fort-book">
          <div className="fort-book">
            <span className="fort-bold ">Eligible for returns</span>
          </div>
          <div>{formatStartEndDate(cbReturnStart, cbReturnEnd)}</div>
        </div>
      );
    } else {
      msg = (
        <div className="d-flex flex-column fort-book">
          <div className="fort-bold ">Past return date</div>
          <div>{formatStartEndDate(cbReturnStart, cbReturnEnd)}</div>
        </div>
      );
    }

    return (
      <div className="d-flex flex-row mt-1 mb-1">
        <div className="mr-1">
          <LazyImage
            src={
              cbReturnable === 'Y'
                ? '/images/returnable-arrow.svg'
                : '/images/returnable-arrow-gray.svg'
            }
          />
        </div>
        <div className="fort-bold fs-12px lh-15">{msg}</div>
      </div>
    );
  } else {
    if (cbReturnable === 'Y') {
      msg = 'Return eligible';
      contentMsg = (
        <div>
          <div>Return date:</div>
          <div>{formatStartEndDate(cbReturnStart, cbReturnEnd)}</div>
        </div>
      );
    } else {
      msg = 'Past return date';
      contentMsg = (
        <div>
          <div>Return date:</div>
          <div>{formatStartEndDate(cbReturnStart, cbReturnEnd)}</div>
        </div>
      );
    }
    return (
      <Popup
        className={`ml-1`}
        content={contentMsg}
        on="hover"
        position="top center"
        offset={[-5, 8]}
        trigger={
          <div
            className={`d-flex flex-row pl-1 pr-1 align-items-center ${
              className ? className : ''
            } ${
              cbReturnable === 'Y'
                ? 'returnability-status'
                : 'returnability-status-past'
            }`}
          >
            {cbReturnable === 'Y' && (
              <div className="mr-1 lh-16px">
                <LazyImage src={'/images/returnable-arrow.svg'} />
              </div>
            )}
            <div className="fs-12px lh-16px fort-book">{msg}</div>
          </div>
        }
      />
    );
  }
};

export default ReturnAbilityStatus;

ReturnAbilityStatus.propTypes = {};

ReturnAbilityStatus.defaultProps = {};
