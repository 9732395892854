import { createRef, Component } from 'react';
import withApp from '../hc/withApp';
import withSecurity from '../hc/withSecurity';
import withAccount from '../hc/withAccount';
import { faPen } from '@fortawesome/free-solid-svg-icons';
//import { Form, Input } from 'semantic-ui-react-form-validator';
import { Form, Input } from 'semantic-ui-react-form-validator';
import { Loader, Modal, Popup, Label, Icon } from 'semantic-ui-react';
//import { PRHLabel, PRHContainerHeader, PRHContainerSubHeader } from '../../shared-react-components/elements/elements';
import {
  PRHLabel,
  PRHContainerHeader,
  PRHContainerSubHeader,
} from '../elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import PasswordPopup from '../elements/PasswordPopup';
import TextValidator from '../../shared-react-components/elements/input';
//import FormContainer from '../../elements/FormContainer';
import ReCAPTCHA from 'react-google-recaptcha';

import './registration.scss';

class RegistrationSubAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaToken: '',
      recaptchaRef: createRef(),
      verifyEmail: 0,
      loading: true,
      displayAccountDetailFields: false,
      createPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      createPasswordEye: 'eye',
      eyeButtonShow: '',
      eyeButtonHide: '',
      passwordMatchErrorMessage: '',
    };
  }

  onCancel = (e) => {
    e.preventDefault();
    const { account } = this.props; 
    account.setStepHandler(2)
  };

  handleRecaptchaChange = () => {
    const recaptchaToken = this.state.recaptchaRef.current.getValue();
    this.setState({recaptchaToken});
  }

  displayAccountDetailFields = () => {
    this.setState({ displayAccountDetailFields: true });
  };

  showPassword = (currentShowHide) => {
    const newState = { ...this.state };
    if (newState[currentShowHide] === 'password') {
      newState[currentShowHide] = 'text';
    } else {
      newState[currentShowHide] = 'password';
    }
    this.setState(newState);
  };

  async componentDidMount() {
    const { account, formState } = this.props; 
    account.setStateField(
      {firstName:formState.createUserForm.firstName,
      lastName:formState.createUserForm.lastName,
      token: formState.createUserForm.token,
      }
      );
  }

  render() {
    const { handleSubmitUser, formState, account, size } = this.props;
    const {
      createPasswordFieldType,
      confirmPasswordFieldType,
      displayAccountDetailFields,
    } = this.state;
    return (
      <>
        <div id="registration-sub-account">
          <div className={'white-background-container'}>
            <PRHContainerHeader>Complete your profile</PRHContainerHeader>
            <br />
            <br />
            {displayAccountDetailFields === false ? (
              <>
                <div className={'details-header'}>
                  <Modal open={account.formState.loading}>
                    <Loader inverted></Loader>
                  </Modal>
                  <PRHContainerSubHeader>Account Details</PRHContainerSubHeader>
                  <PRHButton
                    iconleft
                    fontAwesome={true}
                    icon={faPen}
                    size="small"
                    className={'flex-end margin-left'}
                    //className={'float-right'}
                    onClick={() => this.displayAccountDetailFields()}
                  >
                    Update
                  </PRHButton>
                </div>
                <div className={'details-box fort-light'}>
                  <>
                    {formState ? (formState.createUserForm.firstName): ''}  {formState ? (formState.createUserForm.lastName):'' }
                  </>
                </div>
              </>
            ) : (
              ''
            )}
            { account.formState.showValidationErrors===true ? (
            <div className={'account-error-message'}>{account.formState.systemResponse}</div>
            ):''}
            <Form
              instantValidate={false}
              width={16}
              onSubmit={(e) => {
                if(!!(!this.state.recaptchaToken)) {
                  return;
                }
                account.handleChange({target: {name: 'recaptchaToken', value: this.state.recaptchaToken}});
                account.handleSubmitUser('subaccount', this.state.recaptchaToken);
                this.state.recaptchaRef.current.reset();
                this.handleRecaptchaChange();
              }
            }
            >
              {displayAccountDetailFields === true ? (
                <>
                  <TextValidator
                    name="firstName"
                    type="text"
                    value={account.formState.createUserForm.firstName}
                    onChange={account.handleChange}
                    label={
                      <PRHLabel className={'fort-light font-resize'}>
                        First Name
                      </PRHLabel>
                    }
                    validators={['required']}
                    errorMessages={['this field is required']}
                    width={16}
                  />
                  <TextValidator
                    name="lastName"
                    type="text"
                    value={account.formState.createUserForm.lastName}
                    onChange={account.handleChange}
                    label={
                      <PRHLabel className={'fort-light font-resize'}>
                        Last Name
                      </PRHLabel>
                    }
                    validators={['required']}
                    errorMessages={['this field is required']}
                    width={16}
                  />
                </>
              ) : (
                ''
              )}
              <Popup
                position={size.width < 700 ? ('below center'): ('right center')}
                className={`password-popup`}
                open={account.formState.openPasswordValidationPopUp}
                trigger={
                    <div className={'password-field-holder'}>
                      <div className={'password-field'}>
                        <TextValidator
                          name="createPassword"
                          passwordsMatch={account.formState.passwordsMatch}
                          type={createPasswordFieldType}
                          value={
                            account.formState.createUserForm.createPassword
                          }
                          onChange={account.handleChange}
                          validationCheck={
                            account.formState.createPasswordValid
                          }
                          validationCheckMessage="does not meet requirements"
                          label={
                            <PRHLabel className={'fort-light font-resize'}>
                              Create password
                            </PRHLabel>
                          }
                          validators={['required']}
                          errorMessages={['this field is required']}
                          data-position="right center"
                          onBlur={account.handleBlur}
                          onClick={account.handleClickCreatePassword}
                          width={16}
                          icon={
                            createPasswordFieldType === 'text' ? (
                              <Icon
                                name="eye slash"
                                link
                                onClick={(e) =>
                                  this.showPassword('createPasswordFieldType')
                                }
                              />
                            ) : (
                              <Icon
                                name="eye"
                                link
                                onClick={(e) =>
                                  this.showPassword('createPasswordFieldType')
                                }
                              />
                            )
                          }
                        />
                      </div>
                      {account.formState.passwordsMatch === 1 &&
                      account.formState.passwordValidationScore === 7 ? (
                        <div className={'password-check'}>
                          <Icon name="check" />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                }
              >
                <Popup.Content
                  className={
                    'password-requirements-popup-content white-box-text fort-light'
                  }
                >
                  <PasswordPopup
                    action='registration'
                    password={account.formState.createUserForm.createPassword}
                    firstName={account.formState.createUserForm.firstName}
                    lastName={account.formState.createUserForm.lastName}
                  />
                </Popup.Content>
              </Popup>
              {/*formState.passwordsMatch===0 ? (<Label className={'error-label'} pointing='below'>Passwords must match.</Label>):''*/}
              <div className={'password-field-holder'}>
                <div className={'password-field'}>
                  <TextValidator
                    name="confirmPassword"
                    type={confirmPasswordFieldType}
                    passwordsMatch={account.formState.passwordsMatch}
                    value={account.formState.createUserForm.confirmPassword}
                    validationCheck={account.formState.createPasswordValid}
                    validationCheckMessage="does not meet requirements"
                    onChange={account.handleChange}
                    onBlur={account.handleBlur}
                    onClick={account.handleClickConfirmPassword}
                    label={
                      <PRHLabel className={'fort-light font-resize'}>
                        Confirm password
                      </PRHLabel>
                    }
                    validators={['required']}
                    errorMessages={['this field is required']}
                    width={16}
                    icon={
                      confirmPasswordFieldType === 'text' ? (
                        <Icon
                          name="eye slash"
                          link
                          onClick={(e) =>
                            this.showPassword('confirmPasswordFieldType')
                          }
                        />
                      ) : (
                        <Icon
                          name="eye"
                          link
                          onClick={(e) =>
                            this.showPassword('confirmPasswordFieldType')
                          }
                        />
                      )
                    }
                  />
                </div>
                {account.formState.passwordsMatch === 1 &&
                account.formState.passwordValidationScore === 7 ? (
                  <div className={'password-check'}>
                    <Icon name="check" />
                  </div>
                ) : (
                  ''
                )}
              </div>
              {account.formState.showValidationErrors ===true && 
            account.formState.passwordsMatch===0 ? (
              <>
            <div className={'password-match-error-message'}>
              Passwords must match
            </div>
            <br />
            </>):''}
              {account.formState.stepFailed === true ? (
                <div className={'account-exists-error-message'}>
                  {account.formState.systemResponse}
                </div>
              ) : (
                ''
              )}
              <div className="d-flex mt-3 align-items-center justify-content-center">
                <ReCAPTCHA onExpired={this.handleRecaptchaChange} onChange={this.handleRecaptchaChange} ref={this.state.recaptchaRef} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} size={'normal'} />
              </div>
              <div className={'white-box-text fort-light spacer-top-1'}>
                <div>
                  <TextValidator
                    type="checkbox"
                    name="authorizedAdministrator"
                    //validators={['required']}
                    //errorMessages={['Agreement required.']}
                    className={'checkbox-container'}
                    checked={
                      account.formState.createUserForm.authorizedAdministrator
                    }
                    //defaultChecked={formState.createUserForm.authorizedAdministrator}
                    longTextLabel="I hereby represent and warrant that I am the authorized Administrator for
            this account. I hereby indemnify and hold harmless Penguin Random House
            from any and all claims alleging, arising from or based upon a breach or
            alleged breach of this warranty. I will immediately notify Penguin Random
            House if I am no longer the authorized Administrator."
                    onChange={account.handleChange}
                  />
                  {account.formState.showValidationErrors &&
                  account.formState.createUserForm.authorizedAdministrator ===
                    false ? (
                    <div className={'agreement-error-message'}>
                      Agreement required.
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <br />
              <br />
              <div className={'button-holder'}>
                <PRHButton
                  iconright
                  icon="arrow right"
                  size="large"
                  className={'float-right margin-left'}
                  type="submit" 
                  disabled={!!(!this.state.recaptchaToken)}
                >
                  Register
                </PRHButton>
                <PRHButton
                  className={'float-right'}
                  size="large"
                  //displayInlineFlex
                  onClick={this.onCancel}
                >
                  Cancel Registration
                </PRHButton>
              </div>
            </Form>
            <br />
            <br />
          </div>
        </div>
      </>
    );
  }
}

export default withApp(withAccount(RegistrationSubAccount));
