import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import useCart from "../hc/useCart";
import usePrevious from "../hc/usePrevious";
import NotificationBanner from "./NotificationBanner";

/**
 *
 * @param {*} param0
 * @returns
 */
const NotificationBanners = ({
  listenTo,
  catalogDate,
  listenToPropertyForChange,
  carts = false,
}) => {
  const cart = useCart();
  const history = useHistory();
  const [estLoaded, setEstLoaded] = useState(false);
  const [estimateCarts, setEstimateCarts] = useState([]);
  const location = history.location.pathname;
  const previousLocation = usePrevious(location);
  const previousListenToPropertyForChange = usePrevious(
    listenToPropertyForChange
  );

  useEffect(() => {
    if (carts) {
      setEstLoaded(true);
      if (!!catalogDate) {
        setEstimateCarts(carts.filter((e) => e.catalogDate === catalogDate));
        return;
      }
      setEstimateCarts(carts);
    } else if (
      (!!listenToPropertyForChange || listenToPropertyForChange === 0) &&
      (listenToPropertyForChange !== previousListenToPropertyForChange ||
        location !== previousLocation) &&
      cart.getShipToAccount().accountNo
    ) {
      setEstimateCarts([]);
      // console.log("notification banner load est");
      cart
        .loadEstimateCarts({ silent: false, titleDetail: false })
        .then((ec) => {
          setEstLoaded(true);
          if (!!catalogDate) {
            setEstimateCarts(ec.filter((e) => e.catalogDate === catalogDate));
            return;
          }
          setEstimateCarts(ec);
        });
    }
  }, [
    carts,
    cart,
    history.location.pathname,
    listenTo,
    location,
    previousLocation,
    catalogDate,
    listenToPropertyForChange,
    previousListenToPropertyForChange,
  ]);

  const shipToAccountNo = cart.getShipToAccount().accountNo;

  if (!estLoaded || !shipToAccountNo) {
    return <></>;
  }

  return (
    <>
      {estimateCarts.map((foc, i) => {
        return (
          <NotificationBanner
            estimateCart={foc}
            key={i}
            focDate={foc.foc}
            shipTo={shipToAccountNo}
          />
        );
      })}
    </>
  );
};

NotificationBanners.defaultProps = {
  listenTo: "/",
  catalogDate: false,
  listenToPropertyForChange: false,
};

export default NotificationBanners;
