export const IDENTITY_CONFIG = {
  authority: process.env.REACT_APP_AUTH_URL,
  login: process.env.REACT_APP_LOGIN_URL,
  base: process.env.REACT_APP_BASE_URL,
  target: process.env.REACT_APP_BASE_URL_FRONT_END,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL,
  audience: process.env.REACT_APP_AUDIENCE,
  speciality_retail_sales: process.env.REACT_APP_SP_URL,
  international_retail: process.env.REACT_APP_ISP_URL,
  prhcomics_sales: process.env.REACT_APP_PRHCOMICS_URL,
  help: process.env.REACT_APP_HELP_URL,
  response_type: "id_token token",
  automaticSilentRenew: false,
  loadUserInfo: true,
  scope: process.env.REACT_APP_SCOPE,
  acr_values: process.env.REACT_APP_ACR_VALUES,
};

export const METADATA_OIDC = {
  issuer: process.env.REACT_APP_ISSUER,
  jwks_uri:
    process.env.REACT_APP_AUTH_URL + "/.well-known/openid-configuration/jwks",
  authorization_endpoint: process.env.REACT_APP_AUTH_URL + "/authz",
  token_endpoint: process.env.REACT_APP_AUTH_URL + "/token",
  userinfo_endpoint: process.env.REACT_APP_AUTH_URL + "/userinfo",
  end_session_endpoint: process.env.REACT_APP_AUTH_URL + "/endsession",
  check_session_iframe: process.env.REACT_APP_AUTH_URL + "/checksession",
  revocation_endpoint: process.env.REACT_APP_AUTH_URL + "/revocation",
  introspection_endpoint: process.env.REACT_APP_AUTH_URL + "/introspect",
};

export const COMPCOPY_IDENTITY_CONFIG = {
  // authority: process.env.REACT_APP_OKTA_AUTH_URL,
  authority: process.env.REACT_APP_COMPCOPY_ISSUER,
  base: process.env.REACT_APP_COMPCOPY_BASE_URL,
  target: process.env.REACT_APP_COMPCOPY_BASE_URL_FRONT_END,
  client_id: process.env.REACT_APP_COMPCOPY_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_COMPCOPY_REDIRECT_URL,
  silent_redirect_uri: process.env.REACT_APP_COMPCOPY_SILENT_REDIRECT_URL,
  post_logout_redirect_uri: process.env.REACT_APP_COMPCOPY_LOGOFF_REDIRECT_URL,
  response_type: "id_token token",
  audience: process.env.REACT_APP_AUDIENCE,
  automaticSilentRenew: true,
  loadUserInfo: true,
  scope: process.env.REACT_APP_COMPCOPY_SCOPE,
};

export const COMPCOPY_METADATA_OIDC = {
  issuer: process.env.REACT_APP_COMPCOPY_ISSUER,
  jwks_uri: process.env.REACT_APP_COMPCOPY_AUTH_URL + "/keys",
  authorization_endpoint:
    process.env.REACT_APP_COMPCOPY_AUTH_URL + "/authorize",
  token_endpoint: process.env.REACT_APP_COMPCOPY_AUTH_URL + "/token",
  userinfo_endpoint: process.env.REACT_APP_COMPCOPY_AUTH_URL + "/userinfo",
  end_session_endpoint: process.env.REACT_APP_COMPCOPY_AUTH_URL + "/endsession",
  check_session_iframe:
    process.env.REACT_APP_COMPCOPY_AUTH_URL + "/checksession",
  revocation_endpoint: process.env.REACT_APP_COMPCOPY_AUTH_URL + "/revocation",
  introspection_endpoint:
    process.env.REACT_APP_COMPCOPY_AUTH_URL + "/introspect",
};

export const DEVTOOLS = process.env.REACT_APP_DEVELOPMENT_MODE;
