import { Component } from "react";
import {
  Divider,
  PRHInput,
  PRHError,
} from "../../../components/elements/elements";
import { PRHContainer } from "../../../shared-react-components/elements/elements";
// import { From, Input } from 'semantic-ui-react-form-validator';
import { NumericFormat } from "react-number-format";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input/input";
import { Checkbox, Icon, Popup, Input } from "semantic-ui-react";
import SelectUSState from "react-select-us-states";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import "react-datepicker/dist/react-datepicker.css";
import PRHButton from "../../../shared-react-components/elements/prhbutton";
import ValidateAddressModal from "../../../components/modals/ValidateAddressModal";

// const PRHInput = React.forwardRef((props, ref) => (
//   <Input
//     ref={ref}
//     {...props}
//     style={{ width: '75%' }}
//     // validators={['isPhoneNumber']}
//     // errorMessages={['The phone format is (###) ###-####']}
//   ></Input>
// ));

export default class CCShippingAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verifyAddressActive: false,
      validateAddressDialogOopen: false,
    };
  }

  setVerifyAddressActive = (e) => {
    // console.log('setting verifyaddress active', e);
    this.setState({ verifyAddressActive: e });
  };

  isAddressComplete = () => {
    // console.log('isAddressComplete', this.props);
    const {
      name,
      address1,
      city,
      state,
      zip,
      country,
      addressValid,
      ccSapAccountNo,
    } = this.props;
    if (name === "") return false;
    if (address1 === "") return false;
    if (city === "") return false;
    if (
      state === "" &&
      (country === "US" || country === "CA" || country === "AU")
    )
      return false;
    if (
      (country === "US" || country === "CA" || country === "AU") &&
      zip === ""
    )
      return false;
    if (country === "") return false;
    if (addressValid === true) return false;

    return true;
  };
  render() {
    const {
      name,
      company,
      address1,
      address2,
      city,
      state,
      zip,
      country,
      addressValid,
    } = this.props;
    return (
      <PRHContainer className='cp-left content-block-border w-100 m-0 pl-0 pr-0 mr-6 mb-3 pt-3'>
        <div className='d-flex ml-3 mr-3 align-items-center mb-1'>
          <div className='d-flex fort-bold mb-2 fs-2 align-items-center co-step-header'>
            <span className='cp-section-number fs-3'>1</span>
            <span className='fs-2 text-uppercase'>Shipping Address</span>
          </div>
        </div>
        <Divider tight />
        <div className='d-flex flex-row mt-2 ml-3 mr-3'>
          <div className='d-flex flex-column w-100'>
            <div className='d-flex flex-row ccs-addr-line'>
              <div className='w-50'>
                <PRHInput
                  className='w-75'
                  // style={{ width: '50%' }}
                  maxLength={35}
                  name='name'
                  type='text'
                  onChange={(e) => this.props.setValue("name", e.target.value)}
                  placeholder='Enter name'
                  value={name}
                  label={
                    <label className={"fort-medium fs-2 mb-1"}>Name</label>
                  }
                  isError={this.state.verifyAddressActive && name === ""}
                  errorMessage='Please enter a name'
                />
              </div>
              <div className='w-50'></div>
            </div>
            <div className='d-flex flex-row ccs-addr-line'>
              <div className='w-50'>
                <PRHInput
                  className='w-75'
                  name='company'
                  type='text'
                  maxLength={35}
                  onChange={(e) =>
                    this.props.setValue("company", e.target.value)
                  }
                  placeholder='Enter company/school name'
                  value={company}
                  label={
                    <label className={"fort-medium fs-2 mb-1"}>
                      Company/School
                      <span className='fort-book-italic'>(Optional)</span>
                    </label>
                  }
                />
              </div>
              <div className='w-50'></div>
            </div>
            <div className='d-flex flex-row ccs-addr-line'>
              <div className='w-50'>
                <PRHInput
                  className='w-75'
                  name='address1'
                  type='text'
                  maxLength={35}
                  onChange={(e) => {
                    this.props.setValue({
                      addressValid: false,
                      address1: e.target.value,
                    });
                  }}
                  placeholder='Enter address'
                  isError={this.state.verifyAddressActive && address1 === ""}
                  errorMessage='Please enter a address'
                  value={address1}
                  label={
                    <label className={"fort-medium fs-2 mb-1"}>Address 1</label>
                  }
                />
              </div>
              <div className='w-50'>
                <PRHInput
                  className='w-75'
                  name='address2'
                  type='text'
                  maxLength={35}
                  onChange={(e) =>
                    this.props.setValue({
                      addressValid: false,
                      address2: e.target.value,
                    })
                  }
                  placeholder='Enter address'
                  value={address2}
                  label={
                    <label className={"fort-medium fs-2 mb-1"}>
                      Address 2{" "}
                      <span className='fort-book-italic'>(Optional)</span>
                    </label>
                  }
                />
              </div>
            </div>
            <div className='d-flex flex-row ccs-addr-line'>
              <div className='w-50'>
                <PRHInput
                  className='w-50'
                  name='city'
                  type='text'
                  maxLength={35}
                  onChange={(e) => {
                    this.props.setValue({
                      addressValid: false,
                      city: e.target.value,
                    });
                  }}
                  placeholder='Enter city'
                  value={city}
                  label={
                    <label className={"fort-medium fs-2 mb-1"}>City</label>
                  }
                  isError={this.state.verifyAddressActive && city === ""}
                  errorMessage='Please enter a city'
                />
              </div>
              <div className='w-50'>
                <div className='mb-2 ccs-region-holder'>
                  <div className='fort-medium fs-2 pb-1'>State / Province</div>
                  <RegionDropdown
                    className={`ccs-region fort-book ${
                      !state ? "text-gray-900" : ""
                    }`}
                    country={country}
                    countryValueType='short'
                    value={state ? state : ""}
                    valueType='short'
                    disabled={
                      country !== "US" && country !== "CA" && country !== "AU"
                        ? true
                        : false
                    }
                    defaultOptionLabel={
                      country === "US"
                        ? "Enter State"
                        : country === "CA" || country === "AU"
                        ? "Enter Province"
                        : "Enter Region"
                    }
                    onChange={(val) => {
                      this.props.setValue({ addressValid: false, state: val });
                    }}
                  />
                </div>
                <PRHError
                  isError={
                    this.state.verifyAddressActive &&
                    state === "" &&
                    country !== "US" &&
                    country !== "CA" &&
                    country !== "AU"
                  }
                  errorMessage='Please select a state/province'
                />
              </div>
            </div>
            <div className='d-flex flex-row ccs-addr-line'>
              <div className='w-50'>
                <PRHInput
                  name='zip'
                  type='text'
                  maxLength={10}
                  onChange={(e) => {
                    this.props.setValue({
                      addressValid: false,
                      zip: e.target.value,
                    });
                  }}
                  placeholder='Enter Zip code'
                  isError={this.state.verifyAddressActive && zip === ""}
                  errorMessage='Please enter a zip code'
                  value={zip}
                  label={
                    <label className={"fort-medium fs-2 mb-1"}>
                      Zip/Postal Code
                    </label>
                  }
                />
              </div>
              <div className='w-50'>
                <div className='mb-2'>
                  <div className='fort-medium fs-2 pb-1'>Country</div>
                  <div className='ui input'>
                    <CountryDropdown
                      classes={`ccs-country fort-book ${
                        !country ? "text-gray-900" : ""
                      }`}
                      whitelist={
                        this.props.ccSapAccountNo.startsWith("009998") ||
                        this.props.ccSapAccountNo.startsWith("9998")
                          ? []
                          : ["US", "CA"]
                      }
                      value={country}
                      valueType='short'
                      // countryValueType="short"
                      onChange={(val) => {
                        let upds = {
                          addressValid: false,
                          state: "",
                          country: val,
                        };
                        if (val !== "US" && val !== "CA" && val !== "AU")
                          upds.shippingMethod = "04";
                        else upds.shippingMethod = "01";
                        this.props.setValue(upds);
                      }}
                    />
                  </div>
                  <PRHError
                    isError={this.state.verifyAddressActive && country === ""}
                    errorMessage='Please select a country'
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-flex flex-row ccs-addr-line">
            <ValidateAddressModal
              name={name}
              disabled={!this.isAddressComplete()}
              addressValid={addressValid}
              company={company}
              address1={address1}
              address2={address2}
              city={city}
              state={state}
              zip={zip}
              country={country}
              className="ml-auto"
              openState={(s) =>
                this.setState({ validateAddressDialogOopen: s })
              }
              updateAddress={(e) => this.props.updateAddress(e)}
              setVerifyAddressActive={this.setVerifyAddressActive}
              isAddressComplete={this.isAddressComplete}
            />
          </div> */}
        </div>
        <div>
          <ValidateAddressModal
            name={name}
            disabled={this.props.addressValid}
            addressValid={addressValid}
            company={company}
            address1={address1}
            address2={address2}
            city={city}
            state={state}
            zip={zip}
            country={country}
            className='ml-auto'
            openState={(s) => this.setState({ validateAddressDialogOopen: s })}
            updateAddress={(e) => this.props.updateAddress(e)}
            setVerifyAddressActive={this.setVerifyAddressActive}
            isAddressComplete={this.isAddressComplete}
          />
        </div>
      </PRHContainer>
    );
  }
}
