import { Api } from "./api";

export const wishlistApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getWishListByID: builder.query({
      query: ({ id }) => {
        const headers = {};
        return {
          url: `/ecom/wishlist?listId=${id}`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getWishListByID", arg);
      },
    }),
    submitWishListByID: builder.mutation({
      query: ({ id, cart }) => {
        const headers = {};

        return {
          url: `/ecom/wishlist/usercart?listId=${id}`,
          method: "POST",
          body: cart,
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - submitWishListByID", arg);
      },
    }),
    cancelWishListByID: builder.mutation({
      query: ({ id }) => {
        const headers = {};

        return {
          url: `/ecom/wishlist/cancel?listId=${id}`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - cancelWishListByID", arg);
      },
    }),
  }),
});

export const {
  useGetWishListByIDQuery,
  useLazyGetWishListByIDQuery,
  useSubmitWishListByIDMutation,
  useCancelWishListByIDMutation,
} = wishlistApi;
