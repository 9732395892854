import { useState, useEffect } from "react";
import { Header, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./MultiAddISBNs.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import useApp from "../hc/useApp";
import LazyImage from "../../utils/LazyImage";

const MultiAddISBNsCancel = (props) => {
  const { data, open, setOpen, callback, cancelCallback } = props;
  // const [open, setOpen] = useState(false);

  let realTrigger = (
    <div className='d-flex flex-column fort-book-italic fs-13px lh-18 hover-underline text-blue cursor-pointer align-self-end'>
      <div
        onClick={(e) => {
          setOpen(true);
        }}
        className='v-details'
      ></div>
    </div>
  );

  const resetState = () => {
    setOpen(false);
  };

  const okCancel = {
    label: "Yes, cancel multi-add",
    color: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "light-blue",
    textColorOverride: "white",
    borderHoverColorOverride: "light-blue",
    iconColorOverride: "white",
    iconColorHoverOverride: "light-blue",
    // icon: 'checkmark',
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      if (callback) callback(false);
      resetState();
      return { proceed: true };
    },
  };

  const okGoBack = {
    label: "No, go back",
    // color: 'blue',
    // backgroundColorOverride: 'blue',
    // backgroundHoverColorOverride: 'light-blue',
    // textColorOverride: 'white',
    // borderHoverColorOverride: 'light-blue',
    // iconColorOverride: 'white',
    // iconColorHoverOverride: 'light-blue',
    // icon: 'checkmark',
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      if (cancelCallback) cancelCallback(false);

      resetState();
      return { proceed: true };
    },
  };

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      modalClassName='vri-modal macancel-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            CANCEL MULTI-ADD TO CARTS
          </Header>
        ),
        actions: [okGoBack, okCancel],
      }}
      options={{
        trigger: realTrigger,
      }}
    >
      <div className='ar-box ml-4 mr-4'>
        You have selected some items in this Catalog. Are you sure you want to
        cancel adding them to your carts?
      </div>
    </DialogModal>
  );
};

MultiAddISBNsCancel.propTypes = {};

MultiAddISBNsCancel.defaultProps = {};

export default MultiAddISBNsCancel;
