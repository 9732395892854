import localforage from 'localforage';

class Persistance {
  constructor({ name, cacheLife = 1, debug = false }) {
    this.storeName = name;
    this.cacheLife = cacheLife;
    this.debug = debug;

    this.cleanCache();
  }

  setPersistantDebug(debug) {
    this.debug = debug;
  }

  getStoreName() {
    return this.storeName;
  }
  setCacheLife(cacheLife) {
    this.cacheLife = cacheLife;
  }

  async isCache(key) {
    let item = await localforage.getItem(`${this.storeName}-${key}`);

    if (item === null) return false;
    else return true;
  }

  async getCache(key) {
    const item = await localforage.getItem(`${this.storeName}-${key}`);

    if (item === null) return false;

    let ct = new Date();
    ct.setHours(ct.getHours() - this.cacheLife);

    if (item.cacheDate < ct) {
      this.debug && console.log('cache is stale');
      this.debug &&
        console.log(`Cache date: ${item.cacheDate} current date: ${item}`);
      return false;
    } else {
      return item.data;
    }
  }

  async removeCache(key) {
    localforage.removeItem(`${this.storeName}-${key}`);
  }

  async setCache(key, value) {
    let cv = {
      cacheDate: new Date(),
      data: value,
    };
    this.debug && console.log('setCache', key, value);
    let item = await localforage.setItem(`${this.storeName}-${key}`, cv);

    return item;
  }

  async cleanCache() {
    this.debug && console.log(`Cleaning cache ${this.storeName}`);
    let ct = new Date();

    ct.setHours(ct.getHours() - this.cacheLife);

    if (localforage.keys().length === 0) {
      // console.log('no keys');
    } else {
      // console.log('looping keys');
      await localforage.keys().then((k) => {
        // console.log('checking skeys', k);
        k.forEach((kv) => {
          this.debug && console.log('checking key ', kv);
          if (kv.startsWith(this.storeName)) {
            localforage.getItem(kv).then((item) => {
              if (item.cacheDate < ct) {
                this.debug &&
                  console.log(
                    `${this.storeName} - Cache stale date: ${item.cacheDate} current date: ${ct}`
                  );
                localforage.removeItem(kv);
              }
            });
          }
        });
        this.debug && console.log(`completed cleaning ${this.storeName}`);
      });
    }
  }
}

export default Persistance;
