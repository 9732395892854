import { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import './OrderDetailDropdown.scss';
import { OrderDetailDropdownRow } from './OrderDetailDropdownRow';

export const OrderDetailDropdown = (props) => {
  const [isOpen, setisOpen] = useState(true);

  return (
    //console.log('props', props),
    <div className="odd-header-container">
      <div className="odd-header">
        <div className="odd-icon-container" onClick={() => setisOpen(!isOpen)}>
          <Icon name={isOpen ? 'chevron down' : 'chevron right'} color="grey" />
        </div>
        <div className="odd-dropdown-header-item">
          <div className="odd-dropdown-header-title">Order status</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Icon name={props.icon} color={props.iconColor} size="small" />
            <div className="od-text-data-bold">{props.status}</div>
          </div>
        </div>
        {props.reqDeliveryDate && (
          <div className="odd-dropdown-header-item">
            <div className="odd-dropdown-header-title">Req. Delivery Date</div>
            <div className="od-text-data-book">
              {moment(props.reqDeliveryDate).format('MMM Do, YYYY')}
            </div>
          </div>
        )}
        {props.shippedOn && (
          <div className="odd-dropdown-header-item">
            <div className="odd-dropdown-header-title">Shipped On</div>
            <div className="od-text-data-book">
              {moment(props.shippedOn).format('MMM Do, YYYY')}
            </div>
          </div>
        )}
        {(props.invoice || props.trackingId) && (
          <div className="odd-dropdown-header-item">
            <div className="odd-dropdown-header-title">Shipped From</div>
            <div className="od-text-data-book">{props.shippingFrom}</div>
          </div>
        )}
        {props.trackingId && (
          <div className="odd-dropdown-header-item">
            <div className="odd-dropdown-header-title">Tracking #</div>
            <a
              className="btt-isbn"
              href={props.trackingUrl}
              target="_blank"
              // onClick={() => window.open(props.trackingUrl, 'mywindow')}
            >
              {props.trackingId}
            </a>
          </div>
        )}
        {props.invoice && !props.user.isAppCompCopy() && (
          <div className="odd-dropdown-header-item">
            <div className="odd-dropdown-header-title">Invoice #</div>
            <div className="btt-isbn">
              <a
                href={`/invoices/${props.invoice}`}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push(`/invoices/${props.invoice}`);
                }}
              >
                {props.invoice}
              </a>
            </div>
          </div>
        )}
      </div>
      {isOpen && (
        <div className={'odd-content-container'}>
          {props.lineItems.map((item) => (
            <OrderDetailDropdownRow
              book={item}
              isEditing={false}
              key={
                item.isbn +
                (item?.deliveries?.shipping
                  ? item?.deliveries?.invoiceNo +
                    item?.deliveries?.shipping?.trackingCode +
                    item?.deliveries?.shipping?.vemng
                  : '')
              }
              status={props.status}
              history={props.history}
            />
          ))}
        </div>
      )}
    </div>
  );
};
