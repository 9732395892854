import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
// import localforage from 'localforage';
//import axios from "axios";
import withSecurity from "../../components/hc/withSecurity";
import withAdmin from "../../components/hc/withAdmin";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import { withRouter } from "react-router";
import PageStandard from "../../components/elements/PageStandard";
import AdminMenu from "../../components/admin/AdminMenu";
//import ManageUsers from '../../components/admin/ManageUsers';
import AccountDetails from "../../components/admin/AccountDetails";
import BillingDetails from "../../components/admin/BillingDetails";
import SavedCreditCards from "../../components/admin/SavedCreditCards";
import AccountUsers from "../../components/admin/AccountUsers";
import AccountReps from "../../components/admin/AccountReps";
import CoOpAdvertising from "../../components/admin/CoOpAdvertising";
import Notifications from "../../components/admin/Notifications";
import Promotions from "../../components/admin/Promotions";
import ResetPassword from "../../components/admin/ResetPassword";
import { SizeMe } from "react-sizeme";
// import Cancel from '../../components/elements/RegistrationCancel';
// import {
//   PRHButton,
//   ButtonHolder,
//   PRHContainerGeneral,
// } from '../../components/elements/elements';
//import { PRHContainer } from '../../shared-react-components/elements/elements';
import "./admin.scss";
import withMobile from "../../components/hc/withMobile";
import AdminMenuMobile from "../../components/admin/AdminMenuMobile";
import { BackToTopButton } from "../../components/common/BackToTopButton";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";
//import './login.scss';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentComponent: "billingDetails",
      allowCreate: 1,
      legacyUserForm: {
        userName: "",
        password: "",
      },
      menu: [
        {
          key: 1,
          name: "accountDetails",
          label: "Account Details",
          component: "account-details",
        },
        {
          key: 2,
          name: "accountReps",
          label: "Account Reps",
          component: "account-reps",
        },
        {
          key: 3,
          name: "accountUsers",
          label: "Manage Users",
          component: "accountUsers",
        },
        {
          key: 4,
          name: "changePassword",
          label: "Change Password",
          component: "change-password",
        },
        {
          key: 5,
          name: "promotions",
          label: "Promotions",
          component: "promotions",
        },
        {
          name: "notifications",
          label: "Notifications",
          component: "notifications",
        },
        {
          name: "coOpAdvertising",
          label: "Co-op Advertising",
          component: "co-op-advertising",
        },
      ],
      legacyUser: {
        userName: "",
        password: "",
      },
      addUserModal: false,
    };
  }

  handleMenuItemClick = (e) => {
    const newState = this.state;
    newState.currentComponent = e;
    this.setState(newState);
    this.props.history.push(`/admin/${e}`);
  };

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    if (params && params.component) {
      this.setState({ currentComponent: params.component });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    // console.log('CWM', this.props, nextProps);
    if (
      nextProps.match.params.component &&
      this.props.match.params.component &&
      nextProps.match.params.component !== this.props.match.params.component
    ) {
      this.setState({ currentComponent: nextProps.match.params.component });
    }
  }

  isCartBadge = () => {
    if (this.props.user.isAppCompCopy()) return true;

    if (
      this.props.cart &&
      this.props.cart.customerInfo &&
      this.props.cart.customerInfo.currentUser &&
      this.props.cart.customerInfo.currentUser.roleNo !== "W1"
    )
      return true;

    return false;
  };

  render() {
    // console.log('Admin render', this.props);
    const { admin } = this.props;
    const { menu, currentComponent } = this.state;
    let isCartBadge = false;

    if (this.props.user.isAppCompCopy()) isCartBadge = true;

    if (
      this.props.cart &&
      this.props.cart.customerInfo &&
      this.props.cart.customerInfo.currentUser &&
      this.props.cart.customerInfo.currentUser.roleNo !== "W1"
    )
      isCartBadge = true;

    const isMobile = this.props.mobile.isMobile;

    return (
      <SizeMe monitorHeight={true}>
        {({ size }) => (
          <PageStandard
            jeff={true}
            alignMent="left"
            headerType="loggedIn"
            containerFluid={isMobile}
          >
            <Grid padded stackable>
              <Grid.Row>
                <Grid.Column width={3}>
                  <AdminMenu
                    menu={menu}
                    className={"menu"}
                    header="Account Settings"
                    handleMenuItemClick={(e) => this.handleMenuItemClick(e)}
                    activeItem={currentComponent}
                    isCartBadge={isCartBadge}
                  />
                </Grid.Column>
                {!isMobile && <Grid.Column width={1} />}
                <Grid.Column width={12}>
                  <div
                    className={`admin-container ${isMobile ? "mt-0 px-2" : ""}`}
                  >
                    {currentComponent === "account-details" ? (
                      <AccountDetails admin={admin} />
                    ) : (
                      ""
                    )}
                    {currentComponent === "billing-details" ? (
                      <BillingDetails admin={admin} />
                    ) : (
                      ""
                    )}
                    {currentComponent === "saved-credit-cards" ? (
                      <SavedCreditCards admin={admin} />
                    ) : (
                      ""
                    )}
                    {currentComponent === "account-reps" ? (
                      <AccountReps admin={admin} />
                    ) : (
                      ""
                    )}
                    {currentComponent === "account-users" ? (
                      <AccountUsers admin={admin} />
                    ) : (
                      ""
                    )}
                    {currentComponent === "change-password" ? (
                      <ResetPassword admin={admin} size={size} />
                    ) : (
                      ""
                    )}
                    {currentComponent === "notifications" ? (
                      <Notifications admin={admin} />
                    ) : (
                      ""
                    )}
                    {currentComponent === "co-op-advertising" ? (
                      <CoOpAdvertising admin={admin} />
                    ) : (
                      ""
                    )}
                    {currentComponent === "promotions" ? (
                      <Promotions admin={admin} />
                    ) : (
                      ""
                    )}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {!isMobile && <BackToTopButton />}
            {(currentComponent === "billing-details" ||
              currentComponent === "account-details" ||
              currentComponent === "saved-credit-cards" ||
              currentComponent === "account-users" ||
              currentComponent === "promotions") &&
              isMobile && <BackToTopButtonMobile />}
          </PageStandard>
        )}
      </SizeMe>
    );
  }
}

export default withSecurity(
  withRouter(withApp(withCart(withAdmin(withMobile(Admin)))))
);
