import { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import "./elements.scss";

class PasswordPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordValidationScore: 0,
    };
  }

  changePasswordValidationScore() {
    const newState = { ...this.state };
    const { passwordValidationScore } = this.state;
    const { setPasswordValidationScore } = this.props;
    //console.log('setPasswordValidationScore');
    newState["passwordValidationScore"] = passwordValidationScore + 1;
    this.setState(newState);
    // console.log('changePasswordValidationScore', passwordValidationScore + 1)
    //setPasswordValidationScore(passwordValidationScore + 1);
  }

  render() {
    const { currentPassword, password, firstName, lastName, action } =
      this.props;
    return (
      <>
        <div className={"password-pop-up"}>
          {password.length < 6 || password.length > 24 ? (
            <>
              <div className={"password-doesnot-meet"}>
                <Icon
                  name='circle outline'
                  color='white'
                  className={"popup-icon-color"}
                />
                6–24 characters
                <br />
              </div>
            </>
          ) : (
            <>
              <div className={"password-meets"}>
                <Icon name='check circle' className={"popup-icon-color"} />
                6–24 characters
                <br />
              </div>
            </>
          )}
          {!/[a-zA-Z]/.test(password.charAt(0)) ? (
            <div className={"password-doesnot-meet"}>
              <Icon name='circle outline' className={"popup-icon-color"} />
              Begins with a letter
              <br />
            </div>
          ) : (
            <>
              <div className={"password-meets"}>
                <Icon name='check circle' className={"popup-icon-color"} />
                Begins with a letter
                <br />
              </div>
            </>
          )}
          {!/[0-9]/.test(password) ? (
            <div className={"password-doesnot-meet"}>
              <Icon name='circle outline' className={"popup-icon-color"} />
              1 number (0–9)
              <br />
            </div>
          ) : (
            <>
              <div className={"password-meets"}>
                <Icon name='check circle' className={"popup-icon-color"} />
                1 number (0–9)
                <br />
              </div>
            </>
          )}
          {!/[A-Z]/.test(password) ? (
            <div className={"password-doesnot-meet"}>
              <Icon name='circle outline' className={"popup-icon-color"} />
              1 uppercase letter (A–Z)
              <br />
            </div>
          ) : (
            <>
              <div className={"password-meets"}>
                <Icon name='check circle' className={"popup-icon-color"} />
                1 uppercase letter (A–Z)
                <br />
              </div>
            </>
          )}
          {!/[a-z]/.test(password) ? (
            <div className={"password-doesnot-meet"}>
              <Icon name='circle outline' className={"popup-icon-color"} />
              1 lowercase letter (a–z)
              <br />
            </div>
          ) : (
            <>
              <div className={"password-meets"}>
                <Icon name='check circle' className={"popup-icon-color"} />
                1 lowercase letter (a–z)
                <br />
              </div>
            </>
          )}
          {!/[\"\\\^\&]/.test(password) ? (
            <>
              <div className={"password-meets"}>
                <Icon name='check circle' className={"popup-icon-color"} />
                Cannot contain " ^ \ & <br />
              </div>
            </>
          ) : (
            <div className={"password-doesnot-meet"}>
              <Icon name='circle outline' className={"popup-icon-color"} />
              Cannot contain " ^ \ & <br />
            </div>
          )}
          {action && action !== "reset" ? (
            <>
              {password.toUpperCase().includes(firstName.toUpperCase()) ||
              password.toUpperCase().includes(lastName.toUpperCase()) ? (
                <div className={"password-doesnot-meet"}>
                  <Icon name='circle outline' className={"popup-icon-color"} />
                  Cannot contain first name or last name
                  <br />
                </div>
              ) : (
                <>
                  <div className={"password-meets"}>
                    <Icon name='check circle' className={"popup-icon-color"} />
                    Cannot contain first name or last name
                    <br />
                  </div>
                </>
              )}
            </>
          ) : (
            ""
          )}
          {action && action === "update" ? (
            <>
              {password.includes(currentPassword) ? (
                <>
                  {/*console.log('password', password, 'current password', currentPassword)*/}
                  <div className={"password-doesnot-meet"}>
                    <Icon
                      name='circle outline'
                      className={"popup-icon-color"}
                    />
                    Cannot contain current password
                    <br />
                  </div>
                </>
              ) : (
                <div className={"password-meets"}>
                  <Icon name='check circle' className={"popup-icon-color"} />
                  Cannot contain current password
                  <br />
                </div>
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

PasswordPopup.propTypes = {
  currentPassword: PropTypes.string,
  password: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  action: PropTypes.string,
};

PasswordPopup.defaultProps = {
  currentPassword: "",
  password: "",
  firstName: "",
  lastName: "",
  action: "",
};

export default PasswordPopup;
