import { useEffect, useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import { useGetJ9GlobalMessageQuery } from "../../../api/bizcontentApi";
import useApp from "../../../components/hc/useApp";

const WordPressNotificationJ9 = ({ className }) => {
  const { data: message, isSuccess } = useGetJ9GlobalMessageQuery();

  // useEffect(() => {
  //   async function asyncCall() {
  //     const message = await app.bizContentApi.getJ9GlobalMessage();
  //     setMessage(message);
  //   }
  //   asyncCall();
  // }, [app.bizContentApi]);

  if (!isSuccess || !message?.message) {
    return <></>;
  }

  return (
    <div className='bg-white'>
      <Container
        className={`py-3 ${className} border-bottom-orange bg-white wordpress-notification`}
      >
        <Grid relaxed className=''>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              largeScreen={16}
              widescreen={16}
              className={`d-flex keep-padding`}
            >
              <div>
                <i className='ml-0 circle-exclamation d-inline-block mr-2'></i>
              </div>
              <div
                className='text-gray-900 lh-19 fs-14px lh--015 child-strong-b-fort-bold'
                dangerouslySetInnerHTML={{
                  __html: message.message,
                }}
              ></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      <div className='bottom-rule-orange'></div>
    </div>
  );
};

WordPressNotificationJ9.defaultProps = {
  className: "",
};

export default WordPressNotificationJ9;
