import "./customerenrolment.scss";
import "react-datepicker/dist/react-datepicker.css";

const FormSubmitted = () => {
  return (
    <div className="container my-4 text-center">
      <p className="fs-16px">
        If you have any questions, please contact us at our toll free number
      </p>
      <p className="fs-22px">1-866-761-6685</p>
      <p className="fs-16px">or via email at</p>
      <p className="PH7 link">
        <a href="mailto:newacccount@penguinrandomhouse.com">
          newacccount@penguinrandomhouse.com
        </a>
      </p>
    </div>
  );
};

export default FormSubmitted;
