import moment from 'moment';
import React, { useState } from 'react';
import { Icon, Input } from 'semantic-ui-react';
import AddToCartButton from '../../../components/common/AddToCartButton';
import FOCStatus from '../../../components/common/FOCStatus';
import useCart from '../../../components/hc/useCart';
import useSecurity from '../../../components/hc/useSecurity';
import VariantRestrictionsInfoModal from '../../../components/modals/VariantRestrictionsInfoModal';
import {
  dateIsBefore,
  dateDifference,
  isGeneralCart,
  isFocCartExpired,
  isFocCart,
} from '../../../utils/utilities';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_SCREEN_WIDTH } from '../../../utils/const';
import NoticeCard from '../../../components/elements/NoticeCard';
import ConfirmationStatus from '../../../components/common/ConfirmationStatus';
import ReturnAbilityStatus from '../../../components/common/ReturnAbilityStatus';

export const AddToCartContainer = ({
  formatKeys,
  format,
  formats,
  itemInCart = 0,
  confirmationStatus = false,
}) => {
  // console.log('addtocartcontainer', itemInCart, confirmationStatus);
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const [state, setState] = useState({
    quantity: 1,
  });
  const hasFocDate = format?.foc && format?.foc !== null;
  const isVariant = format?.isVariant && format?.isVariant !== null;
  const focDate = moment(format?.foc);
  const focDateForSubmission = moment(format?.foc).format('YYYY-MM-DD');

  const cart = useCart();
  const user = useSecurity();
  const hasOrderReq = !!format?.overUpc || !!format?.orderReq;

  const country = cart.customerInfo.customer.country;

  // const isRatioVariantAndValidFOC = () => {
  //   if (format.orderReq > 0) {
  //     if (dateDifference(format?.foc) >= 0) return true;
  //     else {
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // };

  const isCartBadge = () => {
    if (user.isAppCompCopy()) return false;

    if (
      cart.customerInfo &&
      cart.customerInfo.currentUser &&
      cart.customerInfo.currentUser.roleNo !== 'W1'
    )
      return false;

    return true;
  };

  // console.log('format', format);
  return (
    <div
      className={`tm-order-now-container ${
        isMobile ? 'tm-order-now-container-mobile p-0' : ''
      }`}
    >
      <div className="tm-info-text-style">
        {formatKeys[format.format.code].toUpperCase() ||
          format.format.name.toUpperCase()}
      </div>
      <div className="tm-price-text mb-10px">
        $
        {Number(country === 'CA' ? format.canPrice : format.usPrice).toFixed(2)}{' '}
        {country === 'CA' ? ' CAD' : ' USD'}
      </div>
      {isMobile && itemInCart && itemInCart > 0 ? (
        <div className="d-flex align-items-center mb-1 fort-book fs-13px lh-20px">
          <div
            className={`tm-cart-status-ind mr-1 ${
              confirmationStatus === 'CONFIRMED' ? 'confirmed' : ''
            } ${cart.isUserPow() ? 'pow' : 'non-pow'} ${
              isFocCart(focDate, format.focStatus) === false ? 'general' : ''
            }`}
          />
          In cart:{' '}
          <span className="fort-bold" style={{ marginLeft: '3px' }}>
            {itemInCart}
          </span>
        </div>
      ) : null}
      {isMobile && (
        <NoticeCard>
          Adding item to cart is accessible through desktop only.
        </NoticeCard>
      )}
      <div className="mb-10px">
        {format.inventoryMsg ? (
          <div className="tm-warning-container tm-warning-container-grey paragraph-3 mb-5px">
            {format.inventoryMsg}
          </div>
        ) : (
          ''
        )}
        {format.restockMsg ? (
          <div className="tm-warning-container tm-warning-container-orange paragraph-3 mb-5px">
            {format.restockMsg}
          </div>
        ) : (
          ''
        )}
        {format.managedMsg ? (
          <div className="tm-warning-container tm-warning-container-grey paragraph-3 mb-5px">
            {format.managedMsg}
          </div>
        ) : (
          ''
        )}
        {(format.cbReturnable === 'Y' || format.cbReturnable === 'N') && (
          <ReturnAbilityStatus
            detailPage={true}
            cbReturnable={format.cbReturnable}
            cbReturnStart={format.cbReturnStart}
            cbReturnEnd={format.cbReturnEnd}
          />
        )}
        {/* {format.hasOwnProperty('available') && !format?.available ? (
          <div className="tm-warning-container tm-warning-container-red paragraph-3 mb-5px">
            <Icon name="exclamation triangle" color="red" />
            {format.status}
          </div>
        ) : (
          ''
        )} */}
      </div>
      <div className="w-100">
        {!isMobile && (
          <>
            <div className="tm-row-item w-100 mw-100 mb-1">
              <span className="qty-lbl tm-qty-label"> Qty </span>
              <Input
                disabled={isCartBadge() || !format.available}
                id="title-quantity"
                type="number"
                min={1}
                max={99999}
                className="PRHInput tm-qty text-align-center"
                value={state.quantity}
                onBlur={(e) => {
                  if (
                    isNaN(parseInt(state.quantity)) ||
                    parseInt(state.quantity) === 0
                  )
                    setState({
                      ...state,
                      quantity: 1,
                    });
                }}
                onChange={(e, { value }) => {
                  setState({
                    ...state,
                    quantity: value.replace(/[^0-9\s]/g, ''),
                  });
                }}
              />
            </div>
            {itemInCart && itemInCart > 0 ? (
              <div className="d-flex align-items-center mb-1 fort-book fs-13px lh-20px">
                <div
                  className={`tm-cart-status-ind mr-1 ${
                    confirmationStatus === 'CONFIRMED' ? 'confirmed' : ''
                  } ${cart.isUserPow() ? 'pow' : 'non-pow'} ${
                    isFocCart(focDate, format.focStatus) === false
                      ? 'general'
                      : 'foc'
                  }`}
                />
                In cart:
                <span className="fort-bold" style={{ marginLeft: '3px' }}>
                  {itemInCart > 9999 ? '9999+' : itemInCart}
                </span>
              </div>
            ) : (
              <div className="mt-3"></div>
            )}
            <div
              className={`w-100 ${
                focDate && !hasOrderReq && 'mb-20px'
              } d-flex justify-content-center`}
            >
              <AddToCartButton
                icon="shopping cart"
                isbn={format.isbnStr}
                onSaleDate={format.onSaleDate.date}
                qty={state.quantity}
                isBtnAvailable={
                  // format.available &&
                  // state.quantity !== 0 &&
                  // isVariant &&
                  // hasOrderReq &&
                  // hasFocDate &&
                  // isRatioVariantAndValidFOC()
                  format.available
                }
                bold={true}
                foc={hasFocDate ? focDateForSubmission : undefined}
                confirmationStatus={confirmationStatus}
                focStatus={format.focStatus}
                className={'w-100'}
                callback={(e) => {
                  setState({
                    ...state,
                    quantity: 1,
                  });
                }}
              />
            </div>
          </>
        )}
        {hasOrderReq ? (
          <div className="d-flex flex-direction-row justify-content-center mt-1 mb-1">
            {/* <span
              style={{ marginRight: '0.15rem' }}
              className="fort-book-italic fs-13px lh-18 text-gray-700 align-self-end"
            >
              Ordering restrictions
            </span> */}
            <VariantRestrictionsInfoModal
              isbn={format.isbnStr}
              searchClass={cart.customerInfo.customer.searchClass}
              country={country}
              orderReq={format.orderReq}
              title={format.title}
            />
          </div>
        ) : null}
        {cart.isUserPow() && !isGeneralCart(focDate) && (
          <ConfirmationStatus
            hidePast={true}
            className="mb-2"
            confirmationStatus={confirmationStatus}
            focDate={focDate?.format('YYYY-MM-DD')}
          />
        )}
      </div>

      {/* {hasFocDate && focDate && cart.isUserPow() && (
        <FOCStatus
          focDate={focDate?.format('YYYY-MM-DD')}
          className={`${
            isMobile ? 'justify-content-center' : 'w-100 justify-content-center'
          }`}
          hidePast={true}
        />
      )} */}
    </div>
  );
};
