import { Component } from 'react';
import withAccount from '../hc/withAccount';
import { withRouter } from 'react-router';
import { IDENTITY_CONFIG } from '../../utils/authConst';
import { PRHContainerHeader } from '../../shared-react-components/elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { Form } from 'semantic-ui-react';
import { ButtonHolder } from './elements';

class RegistrationCancel extends Component {
  cancel = (e) => {
    const { account } = this.props;

    account.clearStateField();
    account.setStepHandler(1);
    // account.resetUserState();
    // account.setStateField('san', '');
    // account.setStateField('sapAcct', '');
    this.props.history.push('/');
  };

  render() {
    const { previousStepHandler, account } = this.props;
    // console.log('cancel props', this.props);
    return (
      <>
        <div className={'white-background-container'}>
          <PRHContainerHeader className="d-block h-auto">
            <span style={{ fontSize: '21px' }}>
              Are you sure you want to cancel registration?
            </span>
          </PRHContainerHeader>
          <span>
            You will not be able to access PRH Self-Service until you complete
            the registration process.
          </span>
          <br />
          <br />
          <ButtonHolder>
            <PRHButton
              iconright
              size="medium"
              className={'float-right margin-left'}
              icon="arrow right"
              onClick={(e) => this.cancel(e)}
              backgroundColorOverride={'blue'}
              textColorOverride={'white'}
            >
              Yes, cancel
            </PRHButton>
            <PRHButton
              size="medium"
              className={'float-left'}
              onClick={() => account.previousStepHandler()}
            >
              No, continue
            </PRHButton>
          </ButtonHolder>
          <br />
          <br />
        </div>
      </>
    );
  }
}

export default withRouter(withAccount(RegistrationCancel));
