import PRHButton from "../../shared-react-components/elements/prhbutton";
import "./customerenrolment.scss";
import "react-datepicker/dist/react-datepicker.css";

const FileInput = ({ data, uploadDocument, onChange }) => (
  <div className="file-upload-container">
    <div className={"input-label-holder"}>
      <label className={"input-label"}>{uploadDocument.name}</label>
    </div>
    <input
      id={uploadDocument.name.replace(/ /g, "_")}
      type="file"
      hidden
      onChange={onChange}
    />
    <div className="upload-form-field">
      {data[uploadDocument.name.replace(/ /g, "_")] &&
        data[uploadDocument.name.replace(/ /g, "_")].name}
      <PRHButton
        size="large"
        className="upload-button"
        onClick={() =>
          document
            .getElementById(uploadDocument.name.replace(/ /g, "_"))
            .click()
        }
      >
        Browse
      </PRHButton>
    </div>
  </div>
);

const UploadRequiredDocumentsForm = ({
  data,
  documents,
  onChange,
  onNext,
  onBack,
}) => {
  const disableNext = Object.keys(data).length !== documents.length;

  return (
    <>
      {documents.map((document) => (
        <FileInput
          key={document.name}
          data={data}
          uploadDocument={document}
          onChange={(e) =>
            onChange([document.name.replace(/ /g, "_")], e.target.files[0])
          }
        />
      ))}

      <div className="d-flex flex-row Xbutton-holder my-5">
        <PRHButton
          size="large"
          iconleft
          icon="arrow left"
          className="mr-auto"
          onClick={onBack}
        >
          Back
        </PRHButton>
        <PRHButton
          disabled={disableNext}
          iconright
          icon="arrow right"
          size="large"
          className="ml-auto"
          onClick={onNext}
        >
          Payment Method
        </PRHButton>
      </div>
    </>
  );
};

export default UploadRequiredDocumentsForm;
