import { Component } from "react";
import * as Sentry from "@sentry/react";
import "react-datepicker/dist/react-datepicker.css";
import { NumericFormat } from "react-number-format";
import "react-phone-number-input/style.css";
import { withRouter } from "react-router";
import { Grid, Icon } from "semantic-ui-react";
import EventTrigger from "../../components/analytics/EventTrigger";
import CheckoutSummary from "./components/CheckoutSummary";
import PageStandard from "../../components/elements/PageStandard";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
// import { Form, Input } from 'semantic-ui-react-form-validator';
import withSecurity from "../../components/hc/withSecurity";
import DialogModal from "../../components/modals/DialogModal";
import withDirty from "../../shared-react-components/components/hc/withDirty";
import { PRHContainer } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import LazyImage from "../../utils/LazyImage";
import {
  formatDateMMDDYYYY,
  formatDateYYYYMMDD,
  isEmpty,
  isFocCartExpired,
  isGeneralCart,
  isCreditCardExpired,
} from "../../utils/utilities";
import "./Checkout.scss";
import BizShipping from "./components/BizShipping";
import CartPreview from "./components/CartPreview";
import CCShippingAddress from "./components/CCShippingAddress";
import CCShippingInstructions from "./components/CCShippingInstructions";
import CCShippingMethod from "./components/CCShippingMethod";
import OrderDetail from "./components/OrderDetail";
import Payment from "./components/Payment";
import { connect } from "react-redux";
import { setCart } from "../../features/ecomSlice";
import { cartApi } from "../../api/cartApi";
import { cloneDeep } from "lodash";

// const re = new RegExp('^w+([.+-]?w+)*@w+([.-]?w+)(.w{2,3})$');

const sapWarningCodes = [75, 77];
class Checkout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDialog: false,
      errorDialog: false,
      errorMsg: false,
      purchaseOrderNumber: this.props.cart.checkout.purchaseorder,
      shipTo: false,
      focDate: false,
      paymentCCErrorOpen: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // console.log('checkout CDM', this.props, this.state);

    if (
      this.props.cart.cart.promoCode &&
      this.props.cart.checkout.orderForEvent
    )
      this.setState({ confirmDialog: true });

    if (
      this.props.location.state?.shipTo &&
      this.props.location.state?.focDate
    ) {
      this.setState({
        shipTo: this.props.location.state.shipTo,
        focDate: this.props.location.state.focDate,
      });
    }
    /*
    Sentry.setContext('Checkout', {
      cartKey: `${this.props.cart.cart.cartKey}`,
      shipTo: this.props.location.state?.shipTo,
      focDate: this.props.location.state?.focDate,
    });
    Sentry.captureMessage('Checkout Page Loaded');*/
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.location.state?.shipTo &&
      nextProps.location.state?.shipTo !== this.props.location.state?.shipTo &&
      nextProps.location.state?.focDate &&
      nextProps.location.state?.focDate !== this.props.location.state?.focDate
    ) {
      this.setState({
        shipTo: nextProps.location.state.shipTo,
        focDate: nextProps.location.state.focDate,
      });
    }
  }

  componentWillUnmount() {
    this.props.cart.clearCheckout();
  }

  setPaymentCCErrorOpen = (x) => {
    this.setState({ paymentCCErrorOpen: x });
  };

  logout = (e) => {
    window.location.href =
      "https://idp.dev.penguinrandomhouse.com/nidp/app/logout";
  };

  submitConfirmation = (e) => {
    let data = {};
    data.shipTo =
      this.props.cart.customerInfo.shipToAccountNos[
        this.props.cart.shipTo
      ].accountNo;
    data.foc = this.state.focDate;
    data.poNumber = this.state.purchaseOrderNumber
      ? this.state.purchaseOrderNumber
      : this.props.cart.checkout.purchaseorder;
    if (this.props.cart.checkout.invoiceText !== "")
      data.invoiceText = this.props.cart.checkout.invoiceText;
    if (this.props.cart.checkout.phone !== "")
      data.phone = this.props.cart.checkout.phone;
    if (this.props.cart.checkout.cartonMarking !== "")
      data.cartonText = this.props.cart.checkout.cartonMarking;
    if (this.props.cart.checkout.billOfLading !== "")
      data.billOfLadingText = this.props.cart.checkout.billOfLading;
    if (
      this.props.cart.checkout.paymentCC &&
      this.props.cart.checkout.paymentCreditCard
    ) {
      data.payByCreditCard = true;
      data.cardId = this.props.cart.checkout.paymentCreditCard;
    } else {
      data.payByCreditCard = false;
    }

    if (this.props.cart.cart.promoCode) {
      data.promoCode = this.props.cart.cart.promoCode;
    }

    if (this.props.cart.checkout.orderForEvent) {
      // console.log('eventDate', this.props.cart.checkout.orderEventDate);
      data.orderEvent = true;
      data.eventDate = formatDateYYYYMMDD(
        this.props.cart.checkout.orderEventDate,
        "-"
      );
      data.eventContactName = this.props.cart.checkout.orderEventFullName;
      data.eventContactPhone = this.props.cart.checkout.orderEventPhone;
    }

    // console.log('submitting confirmation', data);
    this.props.cart.confirmEstimateCart({ data }).then((o) => {
      this.props.cart.setCheckoutValue({ cartConfirmed: true });
      this.props.history.push({
        pathname: "/cart",
        state: {
          focDate: this.state.focDate,
          shipTo:
            this.props.cart.customerInfo.shipToAccountNos[
              this.props.cart.shipTo
            ].accountNo,
        },
      });
    });
  };

  submitOrder = (e) => {
    // console.log('submit order processing', this.props, this.state);

    window.scrollTo(0, 0);
    let headers = { ...this.props.cart.cart.headers };
    let shipTo = {};
    let shippingType = "01";

    this.props.dirtyHandler.setLoadingTakeoverConfig({
      text: "Submitting order . . .",
      longLoadText: "Still submitting order . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    this.props.dirtyHandler.setLoadingTakeover(true);

    if (this.props.user.isAppBiz()) {
      EventTrigger("Place Order", "Biz Order");
      if (!headers.shipToAccountNo)
        headers.shipToAccountNo =
          this.props.cart.customerInfo.shipToAccountNos[
            this.props.cart.shipTo
          ].accountNo;
    } else {
      EventTrigger("Place Order", "Comp Copy Order");
      shipTo.name1 = this.props.cart.checkout.name
        .replace(/[\u2018\u2019]/g, "'")
        .replace(/[\u201C\u201D]/g, '"');
      shipTo.name2 = this.props.cart.checkout.company;
      shipTo.name3 = this.props.cart.checkout.address2;
      shipTo.street = this.props.cart.checkout.address1;
      shipTo.city = this.props.cart.checkout.city;
      shipTo.state = this.props.cart.checkout.state;
      if (this.props.cart.checkout.country === "CA")
        shipTo.postalCode = this.props.cart.checkout.zip
          .toUpperCase()
          .replace(/\W/g, "")
          .replace(/(...)/, "$1 ");
      else shipTo.postalCode = this.props.cart.checkout.zip;
      // shipTo.postalCode = this.props.cart.checkout.zip;
      shipTo.country = this.props.cart.checkout.country;
      if (this.props.cart.checkout.emailAddress !== "") {
        shipTo.email = this.props.cart.checkout.emailAddress;
      }
      if (this.props.cart.checkout.phone !== "") {
        shipTo.phone = this.props.cart.checkout.phone;
      }
      shippingType = this.props.cart.checkout.shippingMethod;
    }

    headers.poNumber = this.state.purchaseOrderNumber;
    if (this.props.cart.checkout.invoiceText !== "")
      headers.invoiceText = this.props.cart.checkout.invoiceText;
    if (this.props.cart.checkout.phone !== "")
      headers.phone = this.props.cart.checkout.phone;
    if (this.props.cart.checkout.cartonMarking !== "")
      headers.cartonText = this.props.cart.checkout.cartonMarking;
    if (this.props.cart.checkout.billOfLading !== "")
      headers.billOfLadingText = this.props.cart.checkout.billOfLading;

    if (
      this.props.cart.checkout.paymentCC &&
      this.props.cart.checkout.paymentCreditCard
    ) {
      headers.payByCreditCard = true;
      headers.cardId = this.props.cart.checkout.paymentCreditCard;
    } else {
      headers.payByCreditCard = false;
    }

    if (this.props.cart.checkout.orderForEvent) {
      headers.eventDate = formatDateYYYYMMDD(
        this.props.cart.checkout.orderEventDate,
        "-"
      );
      // headers.eventDate = formatDateMMDDYYYY(
      //   this.props.cart.checkout.orderEventDate,
      //   '/'
      // );
      headers.eventContactName = this.props.cart.checkout.orderEventFullName;
      headers.eventContactPhone = this.props.cart.checkout.orderEventPhone;
    }

    // console.log('headers', headers);

    this.props.cart.submitOrder({ headers, shipTo, shippingType }).then((o) => {
      // console.log("submitorder", o);
      // this.setState({ cart: o.data });
      if (o?.data?.status === "OK") {
        if (
          !o.data.data.error ||
          sapWarningCodes.indexOf(o.data.data.error.code) > -1
        ) {
          // console.log("normal order");
          // let ordercart = { ...o.data.data };

          let ordercart = cloneDeep(this.props.cart.cart);
          // console.log("carts", ordercart, o);
          ordercart.error = null;
          ordercart.headers = { ...o.data.data.headers };
          ordercart.orderNumber = o.data.data.orderNumber;
          ordercart.orderDate = o.data.data.orderDate;
          ordercart.card = o.data.data?.card;

          let cartTotals = this.props.cart.getCartTotal(ordercart);

          let shipTo = this.props.user.isAppBiz()
            ? this.props.cart.customerInfo.shipToAccountNos[
                this.props.cart.shipTo
              ]
            : false;
          let billTo = this.props.user.isAppBiz()
            ? o.data.data.billTo
              ? { ...o.data.data.billTo }
              : { address: this.props.cart.customerInfo.customer.address }
            : false;
          // console.log("os cart", ordercart);
          this.clearCart();
          // if (this.props.cart.isUserPow()) {
          //   this.props.cart.clearGeneralEstimateCart();
          // }
          this.props.dirtyHandler.setLoadingTakeover(false);
          // console.log("pusihng to order history");
          this.props.history.push({
            pathname: "/ordersummary",
            state: {
              cart: ordercart,
              cartTotal: cartTotals,
              shipTo,
              billTo,
              ccSapAccountNo: this.props.cart.ccSapAccountNo,
              // customerInfo: this.props.cart.customerInfo,
            },
          });
        } else {
          this.props.dirtyHandler.setLoadingTakeover(false);
          this.setState({
            errorDialog: true,
            errorMsg: o.data.data.error.message,
          });
          /*
          Sentry.setContext('Checkout', {
            cartKey: `${this.props.cart.cart.cartKey}`,
            foc: focDate,
            error: 'Order Submit failure',
            msg: o.data.data.error.message,
          });
          Sentry.captureMessage('Checkout Page Error');*/
        }
      } else {
        this.props.dirtyHandler.setLoadingTakeoverConfig({
          text: "Large orders can take additional time to process . . .",
          longLoadText: "Still submitting order . . .",
          inverted: true,
          backgroundBlurLight: true,
        });
        this.props.dirtyHandler.setLoadingTakeover(true);
        setTimeout(
          () => {
            this.props
              .getSOForCart({
                cartKey: this.props.cart.cart.cartKey,
                sapAccountNo: this.props.cart.ccSapAccountNo,
              })
              .then((r) => {
                if (r?.data?.status === "OK") {
                  // console.log('getSOForCart', r);
                  // console.log('Backup OrderNumber', r);
                  let ordercart = { ...this.props.cart.cart };
                  if (r.data.data.orderNumber) {
                    if (r.data.data.orderNumber === "Pending") {
                      ordercart.error.code = 500;
                      ordercart.error.message =
                        "Order placement timedout, this could be do to a large order";
                    } else {
                      ordercart.error = null;
                      ordercart.orderNumber = r.data.data.orderNumber;
                    }
                  } else {
                    ordercart.error.code = 500;
                    ordercart.error.message =
                      "Order placement timedout, this could be do to a large order";
                  }
                  if (ordercart?.error?.code === 500) {
                    /*
                    Sentry.setContext('Checkout', {
                      cartKey: `${this.props.cart.cart.cartKey}`,
                      foc: focDate,
                      error: 'Order Submit failure',
                      msg: ordercart.error.message,
                    });
                    Sentry.captureMessage('Checkout Page Error');*/
                  }

                  let cartTotals = this.props.cart.getCartTotal(ordercart);

                  let shipTo = this.props.user.isAppBiz()
                    ? this.props.cart.customerInfo.shipToAccountNos[
                        this.props.cart.shipTo
                      ]
                    : false;
                  let billTo = this.props.user.isAppBiz()
                    ? { address: this.props.cart.customerInfo.customer.address }
                    : false;
                  this.props.dirtyHandler.setLoadingTakeover(false);
                  this.clearCart();
                  this.props.history.push({
                    pathname: "/ordersummary",
                    state: {
                      cart: ordercart,
                      cartTotal: cartTotals,
                      shipTo,
                      billTo,
                      ccSapAccountNo: this.props.cart.ccSapAccountNo,
                      // customerInfo: this.props.cart.customerInfo,
                    },
                  });
                } else {
                  this.props.dirtyHandler.setLoadingTakeover(false);
                  this.setState({
                    errorDialog: true,
                    errorMsg: "Untable to determine order status",
                  });
                  /*
                  Sentry.setContext('Checkout', {
                    cartKey: `${this.props.cart.cart.cartKey}`,
                    foc: focDate,
                    error: 'Order status unknown',
                  });
                  Sentry.captureMessage('Checkout Page Error');*/
                }
              });
          },

          30000
        );
      }
      // ToastGenerator({
      //   text: 'Your order has been submitted',
      // });
    });
  };

  clearCart = () => {
    // console.log('reloading cart', this.props.cart.ccSapAccountNo);
    this.props.cart.clearCheckout();
  };

  validateEmail = (emailAddress) => {
    const re = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)(\.\w{2,3})$/;
    return re.test(emailAddress);
  };

  verifySubmit = (cart) => {
    const {
      paymentCC,
      paymentCreditCard,
      purchaseorder,
      name,
      address1,
      city,
      state,
      zip,
      phone,
      country,
      addressValid,
      orderForEvent,
      orderEventDate,
      orderEventFullName,
      orderEventPhone,
      shippingAddressEdit,
      emailAddress,
    } = this.props.cart.checkout;
    const { purchaseOrderNumber } = this.state;

    if (this.props.user.isAppBiz()) {
      // console.log(
      //   'verifySubmit',
      //   this.props.cart.checkout,
      //   isValidPhoneNumber(phone)
      // );

      if (this.props.cart.customerInfo.customer.webShippable.length === 0)
        return false;

      // if (paymentCCPreAuth === false && paymentCC === true) {
      //   return false;
      // }

      if (!paymentCreditCard && paymentCC === true) {
        return false;
      }

      if (
        this.props.cart.customerInfo.customer.cashOnly &&
        (!paymentCreditCard || !paymentCC)
      ) {
        return false;
      }
      // if (
      //   paymentCreditCard &&
      //   paymentCC === true &&
      //   isCreditCardExpired(paymentCreditCard.creditCard.expirationDate)
      // ) {
      //   return false;
      // }

      if (orderForEvent === true) {
        if (!orderEventDate || orderEventDate === "") return false;
        if (!orderEventFullName || isEmpty(orderEventFullName)) return false;
        if (!orderEventPhone || orderEventPhone === "") return false;
        // if (!isValidPhoneNumber(orderEventPhone)) return false;
        if (this.props.cart.cart.promoCode) return false;
      }

      if (shippingAddressEdit) return false;
      // if (phone && phone !== '') {
      //   if (!isValidPhoneNumber(phone)) return false;
      // }

      if (purchaseOrderNumber.trim() === "") {
        return false;
      }
    } else if (this.props.user.isAppCompCopy()) {
      if (isEmpty(name)) return false;
      if (isEmpty(address1)) return false;
      if (isEmpty(city)) return false;
      if (isEmpty(state) && (country === "US" || country === "CA"))
        return false;
      if (isEmpty(zip)) return false;
      if (isEmpty(country)) return false;
      if (isEmpty(purchaseorder)) return false;
      if (addressValid === false) return false;
      if (phone && phone === "") return false;
      if (phone && phone.length > 16) return false;
      if (!phone && country !== "US") return false;
      if (emailAddress && emailAddress === "") return false;
      if (emailAddress && emailAddress.length > 132) return false;
      if (!emailAddress && country !== "US") return false;
      if (emailAddress && this.validateEmail(emailAddress) === false)
        return false;
    }

    return true;
  };

  submitOrderForm = () => {
    // console.log('submit order form');
  };

  setValue = (key, value) => {
    this.setState({ [key]: value });
  };

  updateAddress = (address, addressValid = true) => {
    // console.log('updateAddress', address);

    this.props.cart.setCheckoutValue({
      name: address.name,
      company: address.company,
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      state: address.state,
      zip: address.zip,
      country: address.country,
      addressValid,
    });
  };

  getBillingAddress = () => {
    let address = {};
    if (this.props.user.isAppBiz()) {
      if (
        this.props.cart.customerInfo &&
        this.props.cart.customerInfo.shipToAccountNos
      ) {
        const addressReference =
          this.props.cart.customerInfo.shipToAccountNos[this.props.cart.shipTo];
        address.name1 = addressReference?.address?.name1;
        address.name2 = addressReference?.address?.name2;
        address.name3 = addressReference?.address?.name3;
        address.street = addressReference?.address?.street;
        address.city = addressReference?.address?.city;
        address.state = addressReference?.address?.state;
        address.postalCode = addressReference?.address?.postalCode;
        address.country = addressReference?.address?.country;
        address.phone = addressReference?.address?.phone;
      } else {
        return false;
      }
    } else {
      address.name1 = this.props.cart.checkout.name1;
      address.name2 = this.props.cart.checkout.company;
      address.name3 = this.props.cart.checkout.address2;
      address.street = this.props.cart.checkout.addresss1;
      address.city = this.props.cart.checkout.city;
      address.state = this.props.cart.checkout.state;
      address.postalCode = this.props.cart.checkout.zip;
      address.country = this.props.cart.checkout.country;
      address.phone = this.props.cart.checkout.phone;
    }
    return address;
  };

  setConfirmDialogOpen = (o) => {
    this.setState({ confirmDialog: o });
  };

  setErrorDialogOpen = (o) => {
    this.setState({ errorDialog: o });
  };

  removePromo = () => {
    this.props.cart.deletePromoCode(this.props.cart.cart.promoCode);
    // this.props.setValue('orderForEvent', true);
    this.setState({ confirmDialog: false });
  };

  removeAuthorEvent = () => {
    let upd = {
      orderForEvent: false,
      orderEventDate: "",
      orderEventFullName: "",
      orderEventPhone: "",
    };
    this.props.cart.setCheckoutValue(upd);
    this.setState({ confirmDialog: false });
  };

  getCCInfo = () => {
    this.props.cart.getCCInfo().then((x) => {
      if (x.data.data?.card?.cardLastFour) {
      } else {
        /*
        Sentry.setContext('Checkout', {
          cartKey: `cartkey-${this.props.cart.cart.cartKey}`,
          foc: focDate,
          error: 'Credit Cart failed to authorize w/success from HR',
        });
        Sentry.captureMessage('Checkout Page Error');*/
        this.props.cart.setCheckoutValue({
          paymentCCNumber: false,
          paymentCCType: false,
          paymentCC: true,
        });
        this.setPaymentCCErrorOpen(true);
      }
    });
  };

  render() {
    // console.log("Checkout render", this.props, this.state);
    const { shipTo, focDate, paymentCCErrorOpen } = this.state;
    let cart = this.props.cart.cart;

    if (cart.items.length === 0) {
      this.props.history.push({ pathname: "/cart" });
    }
    const cartTotal = this.props.cart.getCartTotal(cart, false);

    const placeOrder = this.verifySubmit(cart);
    // console.log('verifySubmit', placeOrder);

    return (
      <PageStandard
        alignMent='left'
        className='standard-container checkout-container'
        bg='background-f7'
      >
        <DialogModal
          setOpen={this.setPaymentCCErrorOpen}
          open={paymentCCErrorOpen}
          dialogOptions={{
            header: "Credit card could not be processed",
            actions: [
              {
                hidden: false,
                label: "Got it",
                textColorOverride: "white",
                backgroundColorOverride: "blue",
                borderColorOverride: "blue",
                textColorHoverOverride: "blue",
                backgroundHoverColorOverride: "white",
                borderHoverColorOverride: "blue",
                bold: true,
                preAction: async () => {
                  return { proceed: true };
                },
                action: () => {
                  return { proceed: true };
                },
              },
            ],
          }}
          options={{ className: "tm-modal-container" }}
        >
          <p className='paragraph-2'>
            <span className='fort-book'>
              We're sorry, an issue occured while authorizing your credit card.
              Please review your information and try again.
            </span>
          </p>
        </DialogModal>
        <DialogModal
          open={this.state.confirmDialog}
          setOpen={this.setConfirmDialogOpen}
          options={{ size: "mini" }}
          onXClose={() => this.props.history.push("/cart")}
          dialogOptions={{
            header: "Promo Codes and Author Events",
            actions: [
              {
                label: "Cancel",
                className: "ml-auto",
                // color: 'red',
                // icon: 'times',
                preAction: () => ({ proceed: true }),
                action: () => {
                  this.props.history.push("/cart");
                  return { proceed: true };
                },
              },
              {
                label: "Remove Event",
                borderColorOverride: "blue",
                textColorOverride: "white",
                textColorHoverOverride: "blue",
                backgroundColorOverride: "blue",
                backgroundHoverColorOverride: "white",
                iconColorOverride: "white",
                iconColorHoverOverride: "blue",
                // color: 'green',
                // icon: 'checkmark',
                preAction: () => {
                  // this.discardChanges();
                  return { proceed: true };
                },

                action: () => {
                  this.removeAuthorEvent();
                  return { proceed: true };
                },
              },
              {
                label: "Remove Promo",
                borderColorOverride: "blue",
                textColorOverride: "white",
                textColorHoverOverride: "blue",
                backgroundColorOverride: "blue",
                backgroundHoverColorOverride: "white",
                iconColorOverride: "white",
                iconColorHoverOverride: "blue",
                // color: 'green',
                // icon: 'checkmark',
                preAction: () => {
                  // this.discardChanges();
                  return { proceed: true };
                },

                action: () => {
                  this.removePromo();
                  return { proceed: true };
                },
              },
            ],
          }}
        >
          <div className='modal-content--heading mb-2'>
            You cannot use a Promo Code during checkout with an author event.
            Please contact customer service after ordering if you'd like to
            apply a Promo Code to an author event order.
          </div>
        </DialogModal>
        <DialogModal
          open={this.state.errorDialog}
          setOpen={this.setErrorDialogOpen}
          options={{ size: "tiny" }}
          dialogOptions={{
            header: false,
            // header: 'Checkout Unavailable',
            actions: [
              {
                label: "Close",
                className: "ml-auto mr-auto",
                // color: 'red',
                // icon: 'times',
                preAction: () => ({ proceed: true }),
                action: () => {
                  this.setState({ errorMsg: false });
                  return { proceed: true };
                },
              },
            ],
          }}
        >
          <div className='d-flex flex-column align-items-center'>
            <Icon
              className='text-orange mb-2 fs-3'
              name='exclamation triangle'
            ></Icon>
            <div className='fort-bold mb-2'>
              Sorry, we are unable to process your order at this time.
            </div>
            <div className='fort-book mb-2'>
              Please try again later or contact Customer Service.
            </div>
          </div>
        </DialogModal>
        {/* <Form instantValidate={true} onSubmit={(e) => this.submitOrderForm(e)}> */}
        <Grid container className=''>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={10}
              computer={11}
              largeScreen={12}
              widescreen={12}
              // className="order-2 order-md-1"
            >
              <div className='fort-bold fs-2 d-flex justify-content-center align-items-center mt-4 mb-4 co-header-align'>
                <div className='mr-auto fs-4 d-flex'>
                  <LazyImage
                    width='22'
                    className='text-black-50 mr-1'
                    src='/images/checkout@2x.png'
                  ></LazyImage>
                  <span
                    className='ml-1 fort-light'
                    style={{ fontSize: "24px" }}
                  >
                    Checkout
                  </span>
                </div>
                <div className='cp-back-to-cart'>
                  <PRHButton
                    backgroundColorOverride='white'
                    icon='arrow left'
                    className='cp-back-to-cart'
                    onClick={(e) => {
                      let parm = { pathname: "cart", state: {} };
                      if (shipTo && focDate) {
                        parm.state.shipTo = shipTo;
                        parm.state.focDate = focDate;
                      }
                      this.props.history.push(parm);
                    }}
                  >
                    Back to Cart
                  </PRHButton>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={5}
              largeScreen={4}
              widescreen={4}
              className='order-sm-1'
            ></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={10}
              computer={11}
              largeScreen={12}
              widescreen={12}
              className=' mb-8 order-2 order-md-1'
            >
              <div className='checkout-page d-flex'>
                <div className='w-100 cp-left-panel'>
                  {this.props.user.isAppBiz() ? (
                    <>
                      <BizShipping
                        shippingAddressEdit={
                          this.props.cart.checkout.shippingAddressEdit
                        }
                        shipToAddress={this.props.cart.shipTo}
                        setShipTo={this.props.cart.setShipTo}
                        setValue={this.props.cart.setCheckoutValue}
                      ></BizShipping>
                      <OrderDetail
                        promoCode={this.props.cart.cart.promoCode}
                        purchaseorder={this.state.purchaseOrderNumber}
                        phone={this.props.cart.checkout.phone}
                        initialPurchaseOrder={
                          this.props.cart.checkout.purchaseorder
                        }
                        invoiceText={this.props.cart.checkout.invoiceText}
                        orderForEvent={this.props.cart.checkout.orderForEvent}
                        orderEventDate={this.props.cart.checkout.orderEventDate}
                        orderEventFullName={
                          this.props.cart.checkout.orderEventFullName
                        }
                        orderEventPhone={
                          this.props.cart.checkout.orderEventPhone
                        }
                        setValue={this.props.cart.setCheckoutValue}
                        setStateValue={this.setValue}
                      ></OrderDetail>

                      <Payment
                        isCashOnly={
                          this.props.cart.customerInfo.customer.cashOnly
                        }
                        address={this.getBillingAddress()}
                        paymentCreditCard={
                          this.props.cart.checkout.paymentCreditCard
                        }
                        paymentCC={this.props.cart.checkout.paymentCC}
                        paymentUseBillingAddress={
                          this.props.cart.checkout.paymentUseBillingAddress
                        }
                        setValue={this.props.cart.setCheckoutValue}
                        foc={focDate}
                        shipTo={shipTo}
                        shipToAddress={this.props.cart.shipTo}
                        cardInfo={{
                          cardNumber: this.props.cart.checkout.paymentCCNumber,
                          cardType: this.props.cart.checkout.paymentCCType,
                          cardId: this.props.cart.cart.headers?.cardId,
                        }}
                        getCCInfo={this.getCCInfo}
                      ></Payment>
                    </>
                  ) : (
                    <>
                      <CCShippingAddress
                        name={this.props.cart.checkout.name}
                        company={this.props.cart.checkout.company}
                        address1={this.props.cart.checkout.address1}
                        address2={this.props.cart.checkout.address2}
                        city={this.props.cart.checkout.city}
                        state={this.props.cart.checkout.state}
                        zip={this.props.cart.checkout.zip}
                        country={this.props.cart.checkout.country}
                        setValue={this.props.cart.setCheckoutValue}
                        // validateAddress={this.validateAddress}
                        updateAddress={this.updateAddress}
                        addressValid={this.props.cart.checkout.addressValid}
                        ccSapAccountNo={this.props.cart.ccSapAccountNo}
                      />

                      <CCShippingInstructions
                        // purchaseorder={this.props.cart.checkout.purchaseorder}
                        purchaseorder={this.state.purchaseOrderNumber}
                        initialPurchaseOrder={
                          this.props.cart.checkout.purchaseorder
                        }
                        invoiceText={this.props.cart.checkout.invoiceText}
                        phone={this.props.cart.checkout.phone}
                        country={this.props.cart.checkout.country}
                        billOfLading={this.props.cart.checkout.billOfLading}
                        cartonMarking={this.props.cart.checkout.cartonMarking}
                        emailAddress={this.props.cart.checkout.emailAddress}
                        setValue={this.props.cart.setCheckoutValue}
                        setStateValue={this.setValue}
                        // validateEmail={this.validateEmail}
                        ccSapAccountNo={this.props.cart.ccSapAccountNo}
                      />

                      <CCShippingMethod
                        shippingMethod={this.props.cart.checkout.shippingMethod}
                        shippingCountry={this.props.cart.checkout.country}
                        setValue={this.props.cart.setCheckoutValue}
                      />
                    </>
                  )}
                  <CartPreview
                    focDate={focDate}
                    shipTo={shipTo}
                    isCart={true}
                    promoCode={this.props.cart.cart.promoCode}
                    items={this.props.cart.getCartItemsSorted({
                      sortCart: cart,
                    })}
                  ></CartPreview>

                  {/* <PRHContainer className="cp-left content-block-border w-100 m0 mr-6 mb-3">
                    <div className="d-flex justify-content-center">
                      <div style={{ flex: '1 1 auto' }}>
                        <PRHButton
                          size="big-wide"
                          className="mr-auto"
                          bold
                          disabled={!placeOrder}
                          // className="pt-3"
                          borderColorOverride="blue"
                          textColorOverride="white"
                          textColorHoverOverride="blue"
                          backgroundColorOverride="blue"
                          backgroundHoverColorOverride="white"
                          iconColorOverride="white"
                          icon="check"
                          onClick={(e) => {
                            this.submitOrder(e);
                          }}
                        >
                          Place Order
                        </PRHButton>
                      </div>

                      {this.props.user.isAppBiz() ? (
                        <div className="d-flex flex-column align-items-end fort-bold">
                          <div className="mb-1">Total</div>
                          <div>
                            <NumericFormat
                              value={cartTotal.total}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              displayType={'text'}
                              prefix={'$'}
                            />
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </PRHContainer> */}
                </div>
              </div>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={5}
              largeScreen={4}
              widescreen={4}
              className='order-sm-1'
            >
              <CheckoutSummary
                title='Checkout Summary'
                shipTo={shipTo}
                foc={focDate}
                subtotal={cartTotal.subtotal}
                discount={cartTotal.discount}
                shipping={cartTotal.shipping}
                promo={cartTotal.promo}
                promoCode={this.props.cart.cart.promoCode}
                taxes={cartTotal.tax}
                total={cartTotal.total}
                retail={cartTotal.retail}
                submitForm={
                  this.props.cart.isUserPow() &&
                  !isGeneralCart(this.state.focDate)
                    ? this.submitConfirmation
                    : this.submitOrder
                }
                disabled={!placeOrder}
                setValue={this.props.cart.setCheckoutValue}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* </Form> */}
      </PageStandard>
    );
  }
}

// export const PaymentView = (
//   name1,
//   street,
//   city,
//   state,
//   postalCode,
//   country = 'US',
//   phone
// ) => {
//   const url = 'http://localhost:4000/api/v2/biz/ccform/hrdicaller/';
//   const method = 'post';
//   const data = {
//     name1: name1,
//     name2: null,
//     name3: null,
//     street: street,
//     city: city,
//     state: state,
//     postalCode: postalCode,
//     country: country,
//     phone: phone,
//   };

//   return (
//     <IFrame.Form
//       className="w-100"
//       id="paymentForm2"
//       name="paymentForm"
//       config={{ url, method, data }}
//     />
//   );
// };

const mapState = (state) => ({
  soForCart: cartApi.endpoints.getSOForCart.select({}, true)(state),
});
const mapDispatch = {
  getSOForCart: cartApi.endpoints.getSOForCart.initiate,
};
const connector = connect(mapState, mapDispatch);

export default connector(
  withDirty(withRouter(withSecurity(withApp(withCart(Checkout)))))
);
