import React, { useState, useEffect } from "react";
import { Form, Input, Icon } from "semantic-ui-react";
import FOCStatus from "../../../components/common/FOCStatus";
import useCart from "../../../components/hc/useCart";
import useSecurity from "../../../components/hc/useSecurity";
import { isIE } from "react-device-detect";
import { NumericFormat } from "react-number-format";
import DialogModal from "../../../components/modals/DialogModal";
import { Divider } from "../../../shared-react-components/elements/elements";
import PRHButton from "../../../shared-react-components/elements/prhbutton";
import PromotionsModal from "../../../components/modals/PromotionsModal";
import useDirtyHandler from "../../../shared-react-components/components/hc/useDirtyHandler";
import IECreditCardNotification from "../../../components/IECreditCardNotification";
import {
  formatDateMMDDYYYY,
  isFocCartExpired,
  isGeneralCart,
} from "../../../utils/utilities";
import "./CheckoutSummary.scss";

const CheckoutSummary = (props) => {
  const {
    retail,
    discount,
    subtotal,
    taxes,
    shipping,
    total,
    promo = 0,
    undoChanges,
    foc,
    promoCode,

    submitForm = false,
    disabled,
    setValue,
  } = props;

  const cart = useCart();
  const user = useSecurity();
  const dirtyHandler = useDirtyHandler();

  const [localPromo, setLocalPromo] = useState(promo);
  const [promoErrorMsg, setPromoErrorMsg] = useState(false);
  const [promoErrorModalOpen, setPromoErrorModalOpen] = useState(false);

  const isExpired = isFocCartExpired(foc);
  const isGeneral = isGeneralCart(foc);

  useEffect(() => {
    // const cart = useCart();
    // cart.clearCheckout();
    // cart.clearCart();

    return () => {};
  }, []);

  // useEffect(() => {
  //   setPromo(promoCode);
  // }, [promoCode]);

  // useEffect(() => {
  //   conole.log('ue promo', promo, promoCode);
  //   if (promo && promo !== promoCode) {
  //     cart.applyPromoCode(promo).then((response) => {
  //       if (response.data.code === 0) {
  //         setPromo(false);
  //         setPromoErrorMsg('');
  //       } else {
  //         setPromoErrorMsg(response.data.message);
  //       }
  //     });
  //   }
  // }, [promo]);

  const applyPromoCode = async (p, validate = false) => {
    if (!p || p === "") {
      return;
    }
    // console.log('applying promo', p);
    if (cart.isUserPow() && !isGeneralCart(foc)) {
      if (validate) {
        dirtyHandler.setLoadingTakeoverConfig({
          text: "Applying promo code . . .",
          longLoadText: "Still applying promo code . . .",
          inverted: true,
          backgroundBlurLight: true,
        });
        dirtyHandler.setLoadingTakeover(true);
        const result = await cart.getPromos();
        if (result.data?.status === "OK") {
          let promoFound = false;
          result.data.forEach((sp) => {
            if (sp.code.toLowerCase() === p.toLowerCase()) {
              promoFound = true;
              setValue({ promoCode: p });
            }
          });
          if (!promoFound) {
            setLocalPromo(false);
            setValue({ promoCode: false });
            setPromoErrorModalOpen(true);
          }
        }

        dirtyHandler.setLoadingTakeover(false);
      } else {
        setValue({ promoCode: p });
      }
    } else {
      cart.applyPromoCode(p).then((response) => {
        if (response.data.code === 0) {
          // setPromo(false);
          setPromoErrorMsg("");
        } else {
          setPromoErrorMsg(response.data.message);
        }
      });
    }
  };

  const deletePromoCode = () => {
    // if (cart.isUserPow()) {
    //   setLocalPromo(false);
    //   setValue({ promoCode: false });
    // } else {
    cart.deletePromoCode(promoCode).then((response) => {
      if (response.datra?.status === "OK") {
        setLocalPromo(false);
        setPromoErrorMsg("");
      } else {
        setPromoErrorMsg(response.data.message);
      }
    });
    // }
  };

  const isPromoApplied = () => {
    let pi = 0;
    cart.cart.items.map((i) => {
      if (i.promoDiscount && i.promoDiscount > 0) pi++;
    });

    if (pi === 0) return false;
    else return true;
  };

  return (
    <div className='checkout-summary m-0 mb-4'>
      <DialogModal
        setOpen={setPromoErrorModalOpen}
        open={promoErrorModalOpen}
        dialogOptions={{
          header: "Promo Error",
          actions: [
            {
              hidden: false,
              label: "OK",
              textColorOverride: "white",
              backgroundColorOverride: "blue",
              borderColorOverride: "blue",
              textColorHoverOverride: "blue",
              backgroundHoverColorOverride: "white",
              borderHoverColorOverride: "blue",
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                return { proceed: true };
              },
            },
          ],
        }}
        options={{ className: "tm-modal-container" }}
      >
        <p className='paragraph-2'>
          <span className='fort-book'>
            The promo code you entered is not valid.
          </span>
        </p>
      </DialogModal>
      <div className={`d-flex flex-column csc-header p-3`}>
        <div
          className={`fort-book text-gray-900 fs-20px lh-27px border-bottom`}
        >
          Checkout Summary
        </div>
      </div>
      <Divider tight className='mb-1' />
      {user.isAppCompCopy() ? (
        <>
          <div className='d-flex flex-row ml-3 mr-3 mt-3 mb-3 fort-book'>
            <div> SAP Account Number: </div>
            <div className='ml-auto'>{cart.ccSapAccountNo}</div>
          </div>
          <Divider className='mb-3' tight />
        </>
      ) : (
        <>
          <div>
            <div className='d-flex pl-3 pr-3 pt-1 mb-1'>
              <div className='fort-bold fs-13px lh-18px'>Promo Code</div>
              <div className='cursor-pointer text-blue hover ml-auto'>
                <PromotionsModal
                  applyPromoCode={(p) => {
                    applyPromoCode(p);
                  }}
                ></PromotionsModal>
              </div>
            </div>

            {cart.cart.promoCode ? (
              <div>
                <div
                  className='d-flex pl-3 pr-3  align-items-center mb-1'
                  style={{ color: "#f06000" }}
                >
                  <Icon
                    className='cs-check-adjust'
                    style={{ color: "#f06000" }}
                    name='check'
                  ></Icon>
                  <div className='fort-bold'>
                    Promo {cart.cart.promoCode} Applied
                  </div>
                  <div
                    className='d-flex cs-delete-promo fort-bold justify-content-center cursor-pointer cs-delete-promo-holder'
                    onClick={(e) => {
                      deletePromoCode(e);
                    }}
                  >
                    <Icon style={{ color: "#f06000" }} name='trash'></Icon>
                  </div>
                </div>
              </div>
            ) : (
              <div className='d-flex flex-row align-content-center mb-2 pl-3 pr-3'>
                <Input
                  // style={{ width: '154px' }}
                  // disabled={cart.cart.items.length === 0 ? true : false}
                  value={localPromo ? localPromo : ""}
                  onChange={(e) => {
                    setLocalPromo(e.target.value.toUpperCase());
                  }}
                  className={
                    "w-100 promo-input mr-1 " +
                    (isIE ? "cart-summmary-input" : "")
                  }
                  placeholder='Enter a promo code'
                ></Input>
                <PRHButton
                  // disabled={cart.cart.items.length === 0 ? true : false}
                  // textColorOverride="gray-900"
                  className='align-self-center pl-1 pr-1 ml-auto'
                  onClick={(e) => applyPromoCode(localPromo, true)}
                >
                  Apply
                </PRHButton>
              </div>
            )}
            {promoErrorMsg !== "" && (
              <div className='id-error-message position-relative pl-3 pr-3'>
                {promoErrorMsg}
              </div>
            )}
          </div>

          <div className='d-flex flex-row pl-3 pr-3 mb-1'>
            <div>Retail Amount</div>
            <div className='ml-auto'>
              {" "}
              <NumericFormat
                value={retail}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix={"$"}
              />
            </div>
          </div>
          <div className='d-flex flex-row  pl-3 pr-3 mb-1'>
            <div>Base Discount</div>
            <div className='ml-auto'>
              {" "}
              <NumericFormat
                value={discount * -1}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix={"$"}
              />
            </div>
          </div>

          {cart.cart.promoCode ? (
            <>
              <div className='d-flex pl-3 pr-3  pb-1 mb-1'>
                <div>{cart.cart.promoCode} Discount</div>
                <div className='ml-auto'>
                  <NumericFormat
                    value={promo * -1}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                    prefix={"$"}
                  />
                </div>
              </div>
              {!isPromoApplied() ? (
                <div className='text-red pl-3 pr-3 pb-1 fort-medium'>
                  Discount will apply once offer requirements are met.
                </div>
              ) : null}
            </>
          ) : (
            <div></div>
          )}
          <Divider className='mt-2 mb-2' tight />
          <div className='d-flex flex-row fort-bold  pl-3 pr-3 mb-1'>
            <div>Subtotal</div>
            <div className='ml-auto'>
              <NumericFormat
                value={subtotal}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix={"$"}
              />
            </div>
          </div>
          {/* {taxes > 0 && ( */}
          <div className='d-flex flex-row  pl-3 pr-3 mb-1'>
            <div>Taxes</div>
            <div className='ml-auto'>
              {" "}
              <NumericFormat
                value={taxes ? taxes : 0}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix={"$"}
              />
            </div>
          </div>
          {/* )} */}
          {/* {shipping > 0 && ( */}
          <div className='d-flex flex-row  pl-3 pr-3 mb-1'>
            <div>Shipping</div>
            <div className='ml-auto'>
              {" "}
              <NumericFormat
                value={shipping}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix={"$"}
              />
            </div>
          </div>
          {/* )} */}
          <Divider className='mt-2 mb-2' tight />
          <div className='d-flex flex-row fort-bold  pl-3 pr-3 mb-1'>
            <div>Total</div>
            <div className='ml-auto'>
              <NumericFormat
                value={total}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix={"$"}
              />
            </div>
          </div>
        </>
      )}
      {cart.isUserPow() && !isGeneralCart(foc) && !isFocCartExpired(foc) ? (
        <div className='d-flex  flex-column w-100 align-content-center justify-content-center pl-3 pr-3 pb-3 align-items-center mt-3'>
          <PRHButton
            size='big-wide'
            bold
            disabled={disabled}
            // className="pt-3"
            borderColorOverride='blue'
            textColorOverride='white'
            textColorHoverOverride='blue'
            backgroundColorOverride='blue'
            backgroundHoverColorOverride='white'
            iconColorOverride='white'
            iconColorHoverOverride='blue'
            icon='check'
            style={{ maxWidth: "100%" }}
            onClick={(e) => {
              submitForm(e);
            }}
          >
            Submit Confirmation
          </PRHButton>
          <div className='mt-2 mb-2d-flex'>
            <div className='fort-book fs-12px lh-16px mr-1'>
              <div className=' mb-1'>
                After confirmation, you will be able to update your cart until
                the FOC deadline.
              </div>
            </div>
          </div>
          {cart.cart.error ? (
            // this.props.cart.cart.error.code === 31 ? (
            //   <div className="text-red mt-2 mb-2 fort-bold">
            //     The checkout process is currently unavailable, please try
            //     again later.
            //   </div>
            // ) : (
            <div className='mt-2 mb-2 fort-book d-flex'>
              <div className='mr-1'>
                <Icon className='text-orange' name='exclamation circle' />
              </div>
              <div className='mr-1'>
                <span className='fort-bold'>
                  We are not able to confirm price or availability at this time.
                </span>
                {"  "}
                Please check the Order Details page for updates after placing
                your order.
              </div>
            </div>
          ) : null}
          {cart.customerInfo?.customer?.webShippable.length === 0 ? (
            <div className='mt-2 mb-2 fort-book d-flex'>
              <div className='mr-1'>
                <Icon className='text-orange' name='exclamation circle' />
              </div>
              <div className='mr-1'>
                <div className='fort-bold'>
                  Your account is unable to place online orders at this time.
                </div>
                <div>
                  To place an order, you may download an Excel file of your cart
                  and submit it to Customer Service.
                </div>
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className='d-flex  flex-column w-100 align-content-center justify-content-center pl-3 pr-3 pb-3 align-items-center mt-3'>
          <PRHButton
            size='big-wide'
            bold
            disabled={disabled}
            // className="pt-3"
            borderColorOverride='blue'
            textColorOverride='white'
            textColorHoverOverride='blue'
            backgroundColorOverride='blue'
            backgroundHoverColorOverride='white'
            iconColorOverride={disabled ? "grey" : "white"}
            iconColorHoverOverride='blue'
            icon='check'
            style={{ maxWidth: "100%" }}
            onClick={(e) => {
              submitForm(e);
            }}
          >
            Place Order
          </PRHButton>
          {user.isAppBiz() ? <IECreditCardNotification /> : null}
          {cart.cart.error ? (
            // this.props.cart.cart.error.code === 31 ? (
            //   <div className="text-red mt-2 mb-2 fort-bold">
            //     The checkout process is currently unavailable, please try
            //     again later.
            //   </div>
            // ) : (
            <div className='mt-2 mb-2 fort-book d-flex'>
              <div className='mr-1'>
                <Icon className='text-orange' name='exclamation circle' />
              </div>
              <div className='mr-1'>
                <span className='fort-bold'>
                  We are not able to confirm price or availability at this time.
                </span>
                {"  "}
                Please check the Order Details page for updates after placing
                your order.
              </div>
            </div>
          ) : null}
          {cart.customerInfo?.customer?.webShippable.length === 0 ? (
            <div className='mt-2 mb-2 fort-book d-flex'>
              <div className='mr-1'>
                <Icon className='text-orange' name='exclamation circle' />
              </div>
              <div className='mr-1'>
                <div className='fort-bold'>
                  Your account is unable to place online orders at this time.
                </div>
                <div>
                  To place an order, you may download an Excel file of your cart
                  and submit it to Customer Service.
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CheckoutSummary;
