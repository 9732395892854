import { Component } from 'react';
import { withRouter } from 'react-router';
import withSecurity from '../hc/withSecurity';
import withApp from '../hc/withApp';
import withAccount from '../hc/withAccount';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { Link } from 'react-router-dom';
import './registration.scss';

class SuccessResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={'white-background-container margin-bottom-100'}>
          <div className={'white-box-header fort-light'}>
          Password update Complete!
          </div>
              <br />
              You can log in now.
              <br />
              <br />
              <div className={'button-holder'}>
                <PRHButton
                  iconright
                  icon="arrow right"
                  size="large"
                  className={'float-right margin-left'}
                  type="submit"
                  onClick={(e) => this.props.history.push('/login')}
                >
                  Login
                </PRHButton>
               </div>
               <br/>
               <br/>
               </div>
            </>
    );
  }
}

export default withRouter(withApp(withAccount(SuccessResponse)));
