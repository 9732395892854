import { useState, useEffect } from 'react';
import { Header, Form, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import DialogModal from './DialogModal';
import withDirty from '../hc/withDirty';
import PRHButton from '../../elements/prhbutton';
import ErrorCodes from '../security/ErrorCodes';

const SoftError = ({
  open,
  onClose,
  softErrorSettings: { title, body, error },
}) => {
  const [techOpen, setTechOpen] = useState(false);

  const EC = new ErrorCodes();

  const composedError = { errorCategory: 5000, ...error };

  const decoratedOnClose = () => {
    setTechOpen(false);
    onClose();
  };

  return (
    <DialogModal
      options={{
        size: 'tiny',
      }}
      onClose={decoratedOnClose}
      open={open}
      dialogOptions={{
        header: (
          <Header className="fs-12px fort-bold fs-2 lh-2 my-0 mr-5 text-uppercase">
            {title || 'MyHouse Error'}
          </Header>
        ),
        actions: [
          {
            hidden: true,
            label: 'OK',
            color: 'green',
            icon: 'checkmark',
            action: () => {
              onClose();
              // setRealOpen(false);
              return {
                proceed: true,
              };
            },
          },
        ],
      }}
    >
      <Form className="error-page">
        {body && <div className="modal-content--body mb-2">{body}</div>}
        <div className="d-flex justify-content-between align-items-center">
          <div
            role="button"
            className={`r-view-more fort-bold text-uppercase cursor-pointer ${
              techOpen ? ' text-orange-important' : ' text-gray-500'
            } `}
            onClick={(e) => setTechOpen(!techOpen)}
          >
            view more details{' '}
            <Icon
              name={`angle ${techOpen ? 'up' : 'down'}`}
              className={`${
                techOpen ? ' text-orange-important' : ' text-gray-500'
              }`}
            />
          </div>
          <PRHButton actionId="0" icon="check">
            OK
          </PRHButton>
        </div>
        <div
          className={`shadow word-wrap-break-word p-3 mt-4 r-tech ${
            techOpen && ' r-tech-open'
          }`}
        >
          <div>
            Error:&nbsp;
            {EC.getError(composedError.errorCategory)}
          </div>
          <div>
            {composedError.status && (
              <div className="mt-1">
                Status Code:&nbsp;
                {composedError.status}
              </div>
            )}

            {composedError.config && (
              <div>
                <div className="mt-1">{`Url: ${composedError.config.url}`}</div>
                {composedError.config.headers && (
                  <div className="mt-1">
                    Headers:
                    <div className="mt-1 ml-2">
                      {Object.keys(composedError.config.headers).map((k, i) => (
                        <div key={i}>
                          {k}:{composedError.config.headers[k].toString()}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="mt-1">
              Messages:
              {composedError.statusText && (
                <div className="ml-2">{composedError.statusText}</div>
              )}
              {composedError.response &&
                composedError.response.error &&
                composedError.response.error.message && (
                  <div className="ml-2">
                    {composedError.response.error.message}
                  </div>
                )}
            </div>
          </div>
        </div>
      </Form>
    </DialogModal>
  );
};

SoftError.propTypes = {
  softErrorSettings: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withDirty(SoftError);
