import { useEffect } from 'react';
import { Loader, Modal } from 'semantic-ui-react';
import localforage from 'localforage';
//import axios from "axios";
import withSecurity from '../../components/hc/withSecurity';
import withApp from '../../components/hc/withApp';
import withAccount from '../../components/hc/withAccount';
import PageStandard from '../../components/elements/PageStandard';
import RegistrationSubAccountForm1 from '../../components/registration/RegistrationSubAccountForm1';
import RegistrationCancel from '../../components/elements/RegistrationCancel';
import UpdateResponse from '../../components/registration/UpdateResponse';
import { PRHContainerGeneral } from '../../components/elements/elements';
import { CONFIG } from '../../utils/const';
import './registration.scss';
//import './login.scss';
const VerifySubAccount = ({account, match: { params }}) => {
  useEffect(() => {
      account.verifyEmailSubAccount(
        params.token,
        '/registration/verifysub/',
        'put'
      );
  }, []);

    return (
      <>
        <Modal open={account.formState.loading}>
          <Loader inverted></Loader>
        </Modal>
        <PageStandard jeff={true} alignment="center">
          <br />
          <br />
          <br />
          <br />
        </PageStandard>
      </>
    );
};


export default withSecurity(withApp(withAccount(VerifySubAccount)));
