import { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { Grid } from "semantic-ui-react";
import PageFooter from "../../components/footer/PageFooter";
import withApp from "../../components/hc/withApp";
import withSecurity from "../../components/hc/withSecurity";
import PageHeader from "../../components/header/PageHeader";
import LoadingContent from "./partials/LoadingContent";
import Post from "./partials/Post";
import Posts from "./partials/Posts";
import WordPressContentHeader from "./partials/WordPressContentHeader";
import WordPressHero from "./partials/WordPressHero";
import TermsList from "./partials/TermsList";
import RecentPosts from "./partials/RecentPosts";
import useCart from "../../components/hc/useCart";
import PropTypes from "prop-types";
import {
  NEWS_FROM_PRH_CATEGORY_TAXONOMY,
  NEWS_FROM_PRH_POST_TYPE,
  POST_CATEGORY_TAXONOMY,
  POST_POST_TYPE,
  TITLE_CHANGES_CATEGORY_TAXONOMY,
  TITLE_CHANGES_POST_TYPE,
} from "../../utils/wordpressHelpers";
import { useLazyGetTermObjectFromSlugQuery } from "../../api/bizcontentApi";

const PostsWrapper = ({
  app,
  page,
  categorySlug,
  user,
  postId,
  postType,
  searchQuery,
}) => {
  const history = useHistory();
  const cart = useCart();
  const [mainComponent, setMainComponent] = useState(<LoadingContent />);
  const [category, setCategory] = useState(null);
  const [leftSidebarVisible, setLeftSidebarVisible] = useState(false);
  const [rightSidebarVisible, setRightSidebarVisible] = useState(false);
  const [performGetTermObjectFromSlug, getTermObjectFromSlugResult] =
    useLazyGetTermObjectFromSlugQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function asyncCall() {
      // console.log("PostWrapper", categorySlug);
      let category = false;
      if (categorySlug) {
        const resp = await performGetTermObjectFromSlug({
          slug: categorySlug,
          postType,
        });
        // console.log("terms rc", resp);
        if (resp.isSuccess) {
          if (resp.data.length > 0) {
            category = resp.data[0];
          }
        }
      }

      setCategory(category);
      if (postId) {
        setLeftSidebarVisible(false);
        setRightSidebarVisible(true);
        setMainComponent(<Post postType={postType} postId={postId} />);
        window.scrollTo(0, 0);
      } else {
        setLeftSidebarVisible(true);
        setRightSidebarVisible(false);
        setMainComponent(
          <Posts
            category={category}
            page={page}
            postType={postType}
            searchQuery={searchQuery}
          />
        );
        window.scrollTo(0, 0);
      }
    }
    asyncCall();
  }, [postId, page, postType, categorySlug, searchQuery]);

  if (!cart.customerLoaded) {
    return <></>;
  }

  if (!cart.isUserPow()) {
    history.push("/");
  }

  let taxonomy;
  if (postType === NEWS_FROM_PRH_POST_TYPE) {
    taxonomy = NEWS_FROM_PRH_CATEGORY_TAXONOMY;
  }
  if (postType === TITLE_CHANGES_POST_TYPE) {
    taxonomy = TITLE_CHANGES_CATEGORY_TAXONOMY;
  }
  if (postType === POST_POST_TYPE) {
    taxonomy = POST_CATEGORY_TAXONOMY;
  }

  let singlePost = false;

  let mainContentColumns = 16;
  if (!!rightSidebarVisible) {
    mainContentColumns = 11;
    singlePost = true;
  }
  if (!!leftSidebarVisible) {
    mainContentColumns = 12;
  }

  return (
    <div className='wordpress-posts-wrapper'>
      <PageHeader headerType='loggedIn' cartBadge={true} />
      <WordPressContentHeader
        // on search or an individual post, we should allow the user to click back to all post types
        allowActiveToBeClicked={!!postId || !!searchQuery}
        postType={postType}
        className='border-bottom-gray-300 bg-gray-100'
      />

      {!postId && !searchQuery && <WordPressHero postType={postType} />}
      <Grid
        container
        className={`wordpress-content ${singlePost ? "single-post" : ""}`}
      >
        <Grid.Row>
          {!!leftSidebarVisible && (
            <Grid.Column
              mobile={16}
              tablet={4}
              computer={4}
              largeScreen={4}
              widescreen={4}
              className='pt-8 keep-padding'
            >
              <TermsList
                searchQuery={searchQuery}
                category={category}
                postType={postType}
                taxonomy={taxonomy}
              />
            </Grid.Column>
          )}
          <Grid.Column
            mobile={16}
            tablet={mainContentColumns}
            computer={mainContentColumns}
            largeScreen={mainContentColumns}
            widescreen={mainContentColumns}
            className='keep-padding pt-5'
          >
            {mainComponent}
          </Grid.Column>
          {!!rightSidebarVisible && (
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={5}
              largeScreen={5}
              widescreen={5}
              className='keep-padding pt-0'
            >
              <RecentPosts postType={postType} currentPostId={postId} />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>

      <PageFooter
        loggedIn={true}
        which={!!user.isAppCompCopy() ? "compCopy" : "biz"}
      />
    </div>
  );
};

PostsWrapper.propTypes = {
  postType: PropTypes.string.isRequired,
};
PostsWrapper.defaultProps = {
  page: 1,
  category: null,
  postId: null,
  searchQuery: null,
};

export default withRouter(withSecurity(withApp(PostsWrapper)));
