import { Container, Grid } from "semantic-ui-react";
import RecentOrders from "./RecentOrders";
import { withRouter } from "react-router";

// import IECreditCardNotification from '../../components/IECreditCardNotification';

const CompCopy = ({ cart, ccSapAccountNo }) => {
  const { customerInfo } = cart;
  const { customer } = customerInfo;

  const { accountNo } = customer;

  return (
    <Container fluid className='bg-white'>
      <Grid container>
        <Grid.Row className=''>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={11}
            largeScreen={12}
            widescreen={12}
            className='keep-padding pt-8'
          >
            <div className='table-listing-wrap'>
              {/* <IECreditCardNotification className="mb-8 iewarning" />{' '} */}

              <RecentOrders ccSapAccountNo={ccSapAccountNo} className='my-3' />
            </div>
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={5} //4
            largeScreen={4}
            widescreen={4}
            className='keep-padding bg-gray-100 pt-8 '
          >
            <div className='pb-3'>
              <div className='px-4 '>
                <p className='fs-24px lh-28 fort-light lsp--05 mb-3'>
                  My Account
                </p>
                <div className='border-2-top-orange border-left-gray-300 border-bottom-gray-300 border-right-gray-300 bg-white pt-3 pb-2 mb-6'>
                  <div className='px-3'>
                    <div className=''>
                      <p className='text-uppercase my-0 fort-bold lh-15 lsp--02 fs-12px'>
                        Account Number
                      </p>
                      <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                        {accountNo}
                      </p>
                    </div>
                  </div>
                </div>

                <p className='fs-24px lh-28 fort-light lsp--05 mb-3'>Support</p>
                <div className='border-2-top-orange border-left-gray-300 border-bottom-gray-300 border-right-gray-300 bg-white pt-3 mb-6'>
                  <div className='px-3'>
                    <div className='mb-3'>
                      <p className='text-uppercase my-0 fort-bold lh-15 lsp--02 fs-12px'>
                        Customer Service
                      </p>
                      <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                        Mon-Fri
                      </p>
                      <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                        8:30am-5:30pm (EST)
                      </p>
                      <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                        <a className='text-blue' href='tel:+1-800-733-3000'>
                          1-800-733-3000
                        </a>
                      </p>
                      <p className='mb-0 mt-2 fort-book lh-18 lsp--02 fs-13px'>
                        US:
                      </p>
                      <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                        <a
                          className='text-blue word-wrap-break-word'
                          href='mailto:csreviews@prh.com'
                        >
                          csreviews@prh.com
                        </a>
                      </p>
                      <p className='mb-0 mt-2 fort-book lh-18 lsp--02 fs-13px'>
                        Clients:
                      </p>
                      <p className='my-0 fort-book lh-18 lsp--02 fs-13px'>
                        <a
                          className='text-blue word-wrap-break-word'
                          href='mailto:prhpsreviews@prh.com'
                        >
                          prhpsreviews@prh.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default withRouter(CompCopy);
