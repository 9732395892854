import { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import { NumericFormat } from "react-number-format";
import { PlaceClaimModal } from "../../components/modals/PlaceClaimModal";
import PRHButtonStandard from "../../shared-react-components/elements/prhbutton";
import LazyImage from "../../utils/LazyImage";
import { DAMAGE_TYPES } from "../../utils/const";
import { isReturnable } from "../../utils/utilities";

export const InvoiceDetailDropdownRow = ({
  book,
  isEditing,
  claims,
  addOrRemoveFromClaims,
  isLoading,
  history,
}) => {
  const [state, setstate] = useState({
    isReportDamagedModalOpen: false,
    isReportMissingModalOpen: false,
    isItemModalOpen: false,
    currentClaim: undefined,
  });
  const [isMissing, setIsMissing] = useState(false);
  const [returnable, setReturnable] = useState(false);
  const bookModalData = {
    isbn: book.isbn13,
    title: book.bookTitle,
    subtitle: "",
    formatName: book.bookFormat,
    onsale: book.bookOnsale,
    author: book.author,
    purchaseOrderNumber: book.purchaseOrderNumber,
    salesOrderNumber: book.salesOrderNumber,
    quantity: book.quantity,
    price: book.discountedCoverPrice
      ? book.discountedCoverPrice
      : book.coverPrice,
    coverPrice: book.coverPrice,
    invoiceItem: book.invoiceItem,
    orderReq: book.orderReq,
    isVariant: book.isVariant,
  };

  const damaged = claims.filter(
    (art) =>
      art.claimReason === "DAMAGE" &&
      art.isbn13 === book.isbn13 &&
      art.invoiceItem === book.invoiceItem
  );
  const missing = claims.filter(
    (art) =>
      art.claimReason === "MISSING" &&
      art.isbn13 === book.isbn13 &&
      art.invoiceItem === book.invoiceItem
  );

  const quantityClaimed = [...damaged, ...missing].reduce(
    (curr, next) => curr + next.quantity,
    0
  );

  useEffect(() => {
    //console.log(book.author, book.bookFormat, book.bookOnsale, book.cartonQty);
    // console.log('calling use effect');
    if (
      book.author === null &&
      book.bookFormat === null &&
      book.bookOnsale === null &&
      book.cartonQty === null
    ) {
      setIsMissing(true);
    }
    // console.log('isReturnable', isReturnable(book.cbReturnable, book.cbReturnStart, book.cbReturnEnd));
    setReturnable(
      isReturnable(book.cbReturnable, book.cbReturnStart, book.cbReturnEnd)
    );
  });

  const renderClaimRow = (item) => {
    return (
      <div
        key={item.damageType + item.isbn + item.invoiceItem}
        className='iddr-claim-row-container'
      >
        <div className='iddr-claim-row-left'>
          <div className='iconholder'>
            <Icon color='orange' name='warning circle' />
          </div>
          <div style={{ textAlign: "start" }}>
            <div className='fort-medium'>
              {item.quantity} of {book.quantity}{" "}
              {item.damageType && item.damageType !== "undefined"
                ? DAMAGE_TYPES[item.damageType]
                : "Missing"}
              {/* {item.claimReason === 'DAMAGE' ? 'Damaged' : 'Missing'} */}
            </div>
            {item.requestReplacementBook && (
              <div className='fort-book text-gray-700'>
                Replacement Requested
              </div>
            )}
          </div>
        </div>
        <PRHButtonStandard
          disabled={isLoading}
          className='iddr-icon-container'
          onClick={() =>
            setstate({ ...state, currentClaim: item, isItemModalOpen: true })
          }
        >
          Edit
        </PRHButtonStandard>
        <PRHButtonStandard
          disabled={isLoading}
          onClick={() =>
            addOrRemoveFromClaims({
              isbn13: bookModalData.isbn,
              purchaseOrderNumber: bookModalData.purchaseOrderNumber,
              salesOrderNumber: bookModalData.salesOrderNumber,
              claimReason: "",
              quantity: 0,
              damageType: item.damageType,
              oldDamageType: item.damageType,
              requestReplacementBook: item.requestReplacementBook,
              price: bookModalData.price,
              coverPrice: bookModalData.coverPrice,
              invoiceItem: bookModalData.invoiceItem,
            })
          }
          icon='trash alternate'
          iconColorOverride='gray'
          className='id-icon'
        />
      </div>
    );
  };
  // console.log('claims', quantityClaimed, damaged, bookModalData, missing);
  return (
    <div key={book.isbn13} className='odd-book-row'>
      <div className='odd-book-fixed-content'>
        <div className='odd-book-row-column-start'>
          <LazyImage
            src={`https://images.penguinrandomhouse.com/cover/${book.isbn13}`}
            className={`odd-title-image hide-in-print`}
            alt={"Book"}
            isSmallThumbNail={true}
            isVariant={book.isVariant}
            varTagWidth={30}
          />
          <div className='btt-title-data-container'>
            {!isMissing ? (
              <div>
                <a
                  className='boldLabel text-gray-900'
                  href={`/titleinfo/${book.isbn13}`}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/titleinfo/${book.isbn13}`);
                  }}
                >
                  {book.bookTitle}
                </a>
              </div>
            ) : (
              ""
            )}
            {!isMissing ? (
              <div className='od-text-data-book'>By {book.author}</div>
            ) : (
              ""
            )}
            <div className='od-text-data-book'>{book.isbn13}</div>
            {!isMissing ? (
              <div className='od-text-data-book'>
                {book.bookFormat}
                <span className='pipe'> | </span>
                {book.bookOnsale}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {!isMissing ? (
          <div className='odd-book-row-column-mid'>
            {book.promoDiscount ? (
              <div className='text-blue'>
                <NumericFormat
                  className=''
                  value={book.promoDiscount}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  suffix={"%"}
                />{" "}
                off with Promo
              </div>
            ) : null}
            <div className='od-text-data-book'>{book.cartonQty} per carton</div>
            <div className='od-text-data-book'>
              Country: {book.departureCountry}
            </div>
            {book.upcSku.length > 0 && (
              <div className='od-text-data-book'>UPC/SKU: {book.upcSku}</div>
            )}
          </div>
        ) : (
          ""
        )}
        {!isMissing ? (
          <div className='odd-book-row-column-end'>
            <div className={`od-text-data-bold`}>
              {Number(book.itemPrice).toFixed(2) > 0
                ? `$${Number(book.itemPrice).toFixed(2)}`
                : "FREE"}
            </div>
            <div className={`od-text-data-book`}>
              {Number(book.discountedCoverPrice).toFixed(2) > 0
                ? `$${Number(book.discountedCoverPrice).toFixed(2)}`
                : "FREE"}{" "}
              (each)
            </div>
            {Number(book.coverPrice).toFixed(2) > 0 &&
              Math.abs(Number(book?.discount)) > 0 && (
                <div className='od-discount-price '>
                  <span>{String(book.discount).replace("-", "")}% off </span>
                  <span className='text-line-through'>
                    ${Number(book.coverPrice).toFixed(2)}
                  </span>
                </div>
              )}
            <div className={`od-text-data-medium`}>Qty: {book.quantity}</div>
          </div>
        ) : (
          ""
        )}
      </div>
      {/*defaults: book.claimQualified !returnable*/}
      {isEditing && book.claimQualified && !returnable && (
        <div className='odd-book-row-column-edit'>
          {damaged.length > 0 &&
            damaged.map((damagedItem) => renderClaimRow(damagedItem))}
          {damaged.length === 0 && (
            <PRHButtonStandard
              disabled={quantityClaimed >= bookModalData.quantity || isLoading}
              className='odd-book-row-button'
              onClick={() =>
                setstate({ ...state, isReportDamagedModalOpen: true })
              }
            >
              Report Damaged Books
            </PRHButtonStandard>
          )}
          <div className='odd-separator' />
          {missing.length > 0 &&
            missing.map((missingItem) => renderClaimRow(missingItem))}
          {missing.length === 0 && (
            <PRHButtonStandard
              disabled={quantityClaimed >= bookModalData.quantity || isLoading}
              className='odd-book-row-button'
              onClick={() =>
                setstate({ ...state, isReportMissingModalOpen: true })
              }
            >
              Report Missing Books
            </PRHButtonStandard>
          )}
        </div>
      )}

      {isEditing && !book.claimQualified && !returnable ? (
        <div className='odd-book-row-column-edit'>
          Please contact Customer Service for more information on claiming this
          item.
        </div>
      ) : (
        ""
      )}

      {isEditing && returnable ? (
        <div className='odd-book-row-column-edit'>
          Return window open. Claim damaged product in Affidavit Returns.
        </div>
      ) : (
        ""
      )}

      <PlaceClaimModal
        isOpen={state.isReportDamagedModalOpen}
        onClose={() => setstate({ ...state, isReportDamagedModalOpen: false })}
        bookModalData={bookModalData}
        quantityClaimed={quantityClaimed}
        claimReason='DAMAGE'
        addOrRemoveFromClaims={addOrRemoveFromClaims}
      />
      <PlaceClaimModal
        isOpen={state.isReportMissingModalOpen}
        onClose={() => setstate({ ...state, isReportMissingModalOpen: false })}
        bookModalData={bookModalData}
        quantityClaimed={quantityClaimed}
        claimReason='MISSING'
        addOrRemoveFromClaims={addOrRemoveFromClaims}
      />
      <PlaceClaimModal
        isOpen={state.isItemModalOpen}
        onClose={() => setstate({ ...state, isItemModalOpen: false })}
        bookModalData={bookModalData}
        quantityClaimed={quantityClaimed}
        claimReason={state.currentClaim ? state.currentClaim.claimReason : ""}
        currentClaim={state.currentClaim}
        addOrRemoveFromClaims={addOrRemoveFromClaims}
      />
    </div>
  );
};
