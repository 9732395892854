import { useState, useEffect, useCallback } from "react";
import {
  Header,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
  Input,
} from "semantic-ui-react";
import { isIE, isFirefox } from "react-device-detect";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./VariantRestrictionsOrderingModal.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import useCart from "../hc/useCart";
import LazyImage from "../../utils/LazyImage";
import { TOOLTIPS } from "../../utils/const";
import ModifiedConfirmedCartModal from "./ModifyConfirmedCartModal";

const VariantRestrictionsOrderingModal = ({
  isbn,
  qtyWant,
  openState,
  restrictionsNew,
  setOpenState,
  foc,
  shipTo,
  cancelCallback,
  callBackFunction,
  update = false,
}) => {
  // console.log('restrictionsNew', restrictionsNew);
  const cart = useCart();
  const [addToCartDisabled, SetAddToCartDisabled] = useState(true);
  const [remainingQty, setRemainingQty] = useState(0);
  const [qualifyingIsbns, setQualifyingIsbns] = useState([]);
  const [actionPerformed, setActionPerformed] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [totalRemainingQty, setTotalRemainingQty] = useState(0);
  const [modifyConfirmedCartModalOpen, setModifyConfirmedCartModalOpen] =
    useState(false);

  let inputQtyArray = [];

  const resetState = () => {
    setDialogVisible(false);
    SetAddToCartDisabled(true);
    setQualifyingIsbns([]);
    // setOpenState(false);
  };

  const addAllToCartAction = {
    label: "Add all to Cart",
    borderColorOverride: "blue",
    textColorOverride: "white",
    textColorHoverOverride: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "white",
    iconColorHoverOverride: "blue",
    iconColorOverride: addToCartDisabled ? "grey" : "white",
    icon: "checkmark",
    bold: true,
    disabled: addToCartDisabled,
    //() => addItemsToCart(),
    preAction: () => {
      setActionPerformed(true);
      return { proceed: true };
    },
    action: () => {
      // let modifiedConfirmedCart = false;
      // restrictionsNew?.qualifyingTitles?.forEach((title) => {
      //   if (title?.cartConfirmationStatus === 'CONFIRMED')
      //     modifiedConfirmedCart = true;
      // });
      // if (modifiedConfirmedCart) {
      //   setModifyConfirmedCartModalOpen(true);
      // } else {
      addItemsToCart();
      // }
      resetState();
      // addItemsToCart();

      return { proceed: true };
    },
  };

  const actionMessage = {
    actionMessage: (
      <div className="d-flex align-items-center ml-auto mr-2 mt-1 fort-book fs-13px lh-18px">
        <span className="fort-bold">
          {totalRemainingQty} of{" "}
          {parseInt(restrictionsNew.remainingQuantityToOrder) +
            parseInt(restrictionsNew.totalQualifyingTitlesInCarts)}
        </span>
        &nbsp; copies remaining
      </div>
    ),
  };
  const CancelAction = {
    label: "Cancel",
    className: "mr-auto",
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      resetState();
      SetAddToCartDisabled(true);
      if (cancelCallback) cancelCallback();
      return { proceed: true };
    },
  };

  const setQty = (qty) => {
    let totalItems = 0;
    qualifyingIsbns.forEach((item) => {
      const item_qty = document.getElementById("quantity" + item).value;
      totalItems =
        totalItems + (isNaN(parseInt(item_qty)) ? 0 : parseInt(item_qty));
    });
    if (totalItems > 0) {
      setTotalRemainingQty(
        remainingQty - totalItems >= 0 ? remainingQty - totalItems : 0
      );
    } else {
      setTotalRemainingQty(remainingQty);
    }
    totalItems >= remainingQty
      ? SetAddToCartDisabled(false)
      : SetAddToCartDisabled(true);
  };

  useEffect(() => {
    if (restrictionsNew) {
      setRemainingQty(restrictionsNew?.remainingQuantityToOrder);
      setTotalRemainingQty(restrictionsNew?.remainingQuantityToOrder);
      let isbnsArray = [];
      if (restrictionsNew?.qualifyingTitles.length > 0) {
        restrictionsNew?.qualifyingTitles.forEach((item, idx) => {
          if (!isbnsArray.includes(item.isbn)) {
            isbnsArray.push(item.isbn);
          }
        });
      }
      setQualifyingIsbns([...qualifyingIsbns, ...isbnsArray]);
    }
    if (!openState) {
      resetState();
    }
  }, [openState]);

  useEffect(() => {
    if (dialogVisible) {
      if (!openState) {
        if (!actionPerformed && cancelCallback) cancelCallback();
        resetState();
        setActionPerformed(false);
      }
    }
    if (openState && !dialogVisible) {
      setDialogVisible(true);
    }
  }, [actionPerformed, cancelCallback, dialogVisible, openState]);

  const addItemsToCart = async (x = false) => {
    let isbnsWithQuantity = [];
    let newIsbnsArray = [];
    qualifyingIsbns.forEach((item) => {
      if (document.getElementById("quantity" + item).value > 0) {
        isbnsWithQuantity.push({
          isbn: item,
          quantity: document.getElementById("quantity" + item).value,
          cartType: "FOC",
        });
        newIsbnsArray.push(item);
      }
    });
    const requestBody = {
      foc: foc,
      shipTo: shipTo,
      variantItem: {
        isbn: isbn,
        quantity: qtyWant,
        cartType: "FOC",
      },
      qualifyingItems: isbnsWithQuantity,
    };
    newIsbnsArray.push(isbn);
    const result = await cart.addEstimateVariantItems({
      requestBody,
      newIsbnsArray,
      update,
    });
    if (callBackFunction) {
      callBackFunction(result);
    }
  };

  return (
    <>
      <ModifiedConfirmedCartModal
        open={modifyConfirmedCartModalOpen}
        setOpen={setModifyConfirmedCartModalOpen}
        callback={addItemsToCart}
      ></ModifiedConfirmedCartModal>
      <DialogModal
        open={openState}
        setOpen={setOpenState}
        // onXClose={cancelCallback}
        modalClassName="vro-modal"
        modalContentClass="m-0 pt-0"
        dialogOptions={{
          header: (
            <Header className="fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase">
              Adding Variant and Qualifying Titles to Cart
            </Header>
          ),
          actions: [CancelAction, actionMessage, addAllToCartAction],
        }}
        // options={{
        //   trigger: realTrigger,
        // }}
      >
        <div className="ar-box">
          <>
            <div className="ml-4 mr-4 mb-1">
              {restrictionsNew?.searchMessage}
            </div>
            {/* <Divider className="mt-0 mb-0" /> */}
            <div className="fort-book fs-13px lh-16px ml-4 mr-4">
              {update ? "To update total quantity to " : "To add "}
              <span className="fort-bold-italic">{qtyWant}</span> units of{" "}
              <span className="fort-bold-italic">
                {restrictionsNew?.variantTitle},
              </span>{" "}
              there must be{" "}
              <span className="fort-bold">
                {restrictionsNew?.remainingQuantityToOrder +
                  restrictionsNew?.totalQualifyingTitlesInCarts}{" "}
                total copies{" "}
              </span>
              of the following qualifying titles in your cart
              {restrictionsNew.qualifyingTitlesInOtherCarts > 0
                ? "/other accounts carts:"
                : ":"}{" "}
              <Popup
                on="hover"
                content={
                  <>
                    To add this variant to your cart, you must have the required
                    number of qualifying titles currently in your cart or other
                    accounts’ carts.
                    <br />
                    <br />
                    “Currently in cart” quantities are always from the{" "}
                    <b>latest quantities</b> in your carts; if you’ve confirmed
                    quantities and then made updates, the{" "}
                    <b>updated quantities</b> will be used to calculate your
                    eligibility.
                  </>
                }
                trigger={
                  <Icon name="question circle" className="sb_question_icon" />
                }
              />
            </div>
            <div className="d-flex flex-row  pl-4 pr-4 pt-2 pb-2">
              <div className="d-flex align-items-center fort-book fs-13px lh-18px mr-2 justify-content-left  w-100">
                <LazyImage
                  className="vri-title-cover"
                  src={`https://images.penguinrandomhouse.com/cover/${restrictionsNew?.variantIsbn}?alt=image_coming.gif`}
                ></LazyImage>
                <div className="d-flex flex-column justify-content-center ml-2">
                  <div className="fort-bold fs-13px lh-18px">
                    {restrictionsNew?.variantTitle}
                  </div>
                  <div className="fort-book fs-13px lh-18px">
                    {restrictionsNew?.variantIsbn}
                  </div>
                </div>
                {restrictionsNew?.oldVariantQtyCurrentAcc > 0 && (
                  <div className="d-flex flex-column ml-auto">
                    Currently in cart:{" "}
                    {restrictionsNew?.oldVariantQtyCurrentAcc}
                  </div>
                )}
              </div>
            </div>
            <Divider className="mt-0 mb-0" />
            <div
              style={{ maxHeight: "330px" }}
              className="pl-4 pr-4 pt-2 pb-2 overflow-auto bg-gray-100"
            >
              <div className="fort-bold mb-1 ">
                QUALIFYING TITLES ({restrictionsNew?.qualifyingTitles?.length})
              </div>
              <Divider className="mb-1" />
              {restrictionsNew?.qualifyingTitles?.map((title, idx) => (
                <div key={idx}>
                  <div className="d-flex flex-row mb-2 mt-2 justify-content-between">
                    <div className="d-flex flex-row mb-1 mr-2">
                      <LazyImage
                        className="vri-title-cover"
                        boxShadow={true}
                        src={`https://images.penguinrandomhouse.com/cover/${title.isbn}`}
                      ></LazyImage>

                      <div className="d-flex flex-column ml-2 justify-content-center">
                        <div className="fort-bold fs-13px lh-18px text-gray-700">
                          {title.title}
                        </div>
                        <div className="fort-book fs-13px lh-18px text-gray-700">
                          {title.isbn}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <div className="rowItem qty-box mt-1 third-col-items ml-auto">
                        <span className="qty-lbl text-gray-700"> Qty </span>

                        <Input
                          type={isFirefox ? "text" : "number"}
                          min="0"
                          max="99999"
                          className={`search-title-input ${
                            isFirefox ? "ff" : ""
                          }`}
                          defaultValue={0}
                          id={`quantity${title.isbn}`}
                          name={`quantity${title.isbn}`}
                          onChange={(e) => {
                            if (
                              parseInt(e.target.value) >= 0 ||
                              e.target.value === ""
                            ) {
                              setQty(e.target.value);
                            }
                          }}
                        />
                      </div>
                      <div className="fs-12px lh-16px">
                        Currently in cart: {title.quantityInCart}
                      </div>
                    </div>
                  </div>
                  {idx + 1 < restrictionsNew?.qualifyingTitles?.length && (
                    <Divider className="mb-1" tight />
                  )}
                </div>
              ))}
            </div>
            <Divider className="mt-0 mb-0" />
            {restrictionsNew.qualifyingTitlesInOtherCarts > 0 && (
              <div className="fort-book fs-13px lh-16px ml-4 mr-4 mr-2 mt-2 fort-italic">
                <Icon
                  className="cursor-pointer inline-flex orange search-msg-icon"
                  name="info circle"
                />{" "}
                <span className="fort-bold-italic">Note:</span>{" "}
                <span className="fort-book-italic">
                  <span className="fort-bold-italic">
                    {restrictionsNew.qualifyingTitlesInOtherCarts +
                      restrictionsNew.qualifyingQuantityOrdered}{" "}
                    total copies
                  </span>{" "}
                  of qualifying titles are in other accounts' carts.
                </span>
              </div>
            )}
          </>
        </div>
      </DialogModal>
    </>
  );
};

VariantRestrictionsOrderingModal.propTypes = {
  isbn: PropTypes.string.isRequired,
};

VariantRestrictionsOrderingModal.defaultProps = {};

export default VariantRestrictionsOrderingModal;
