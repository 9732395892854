import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "semantic-ui-react";
import { useLazyGetPostsQuery } from "../../../api/bizcontentApi";
import withApp from "../../../components/hc/withApp";
import {
  getPrettyName,
  NEWS_FROM_PRH_POST_TYPE,
  translatePostTypeSlug,
} from "../../../utils/wordpressHelpers";
import LoadingContent from "./LoadingContent";
import PostDigestVertical from "./PostDigestVertical";

const RecentPosts = ({
  postType,
  app,
  page,
  category,
  searchQuery,
  currentPostId,
  currentTitle,
}) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [performGetPosts, getPostsResult] = useLazyGetPostsQuery();

  const reset = () => {
    setLoading(true);
    setError(false);
  };

  useEffect(() => {
    async function asyncCall() {
      reset();
      const resp = await performGetPosts({ postType });
      if (resp.isSuccess) {
        if (!!resp?.data?.error?.message) {
          setError(resp.data.error.message);
        } else {
          setPosts(resp.data);
        }
      }

      setLoading(false);
    }

    asyncCall();
  }, [page, postType, category, app.bizContentApi, searchQuery]);

  return (
    <Container>
      <div className='bg-gray-100 px-4 pt-5'>
        <p className='fort-bold fs-12px lh-15px lsp--02 text-gray-900 text-uppercase pb-2 border-bottom-gray-300'>
          Recent {getPrettyName(postType).plural}
        </p>
        {loading && <LoadingContent />}
        {!loading && error && (
          <div>
            <p className='text-center my-5 px-0'>{error}</p>
          </div>
        )}
        <Grid className='border-bottom-md-only-gray-300 mb-md-2 mb-lg-0'>
          <Grid.Row>
            {!loading &&
              !error &&
              posts
                .filter(
                  (p) => parseInt(p.id, 10) !== parseInt(currentPostId, 10)
                )
                .slice(0, 4)
                .map((post, index, arr) => {
                  let extraClass = [];
                  if (index === 0) {
                    extraClass = "mr-lg-0";
                  } else if (index + 1 === arr.length) {
                    extraClass = "ml-4 ml-lg-0";
                  } else {
                    extraClass = "mx-2 mx-lg-0";
                  }

                  return (
                    <Grid.Column
                      tablet={4}
                      largeScreen={16}
                      computer={16}
                      widescreen={16}
                      key={index}
                    >
                      <PostDigestVertical
                        className={`fb-33 fb-xl-100 ${extraClass}`}
                        key={post.id}
                        post={post}
                      />
                    </Grid.Column>
                  );
                })}
          </Grid.Row>
        </Grid>
        <Link
          to={`/${translatePostTypeSlug(postType)}`}
          className='fort-book fs-14px lh-19px lsp--015 text-blue hover-orange mt-2 mb-4 d-inline-block'
        >
          View all {getPrettyName(postType).plural}
        </Link>
      </div>
    </Container>
  );
};
RecentPosts.defaultProps = {
  postType: NEWS_FROM_PRH_POST_TYPE,
};
export default withApp(RecentPosts);
