import "./InvoiceDetail.scss";
import _ from "lodash";
import { NumericFormat } from "react-number-format";
import LazyImage from "../../utils/LazyImage";
import { InvoiceDetailDropdownRowAlpha } from "./InvoiceDetailDropdownRowAlpha";

export const InvoiceDetailRowsPrintAlphaSort = (props) => {
  const { invoiceDataRows, docType } = props;
  const formattedData = _.flattenDeep(
    invoiceDataRows.map((groupOfRows) => {
      return groupOfRows.invoiceItemDetailsList.map((row) => {
        return {
          ...row,
          purchaseOrder: groupOfRows.purchaseOrder,
          salesOrder: groupOfRows.salesOrder,
        };
      });
    })
  ).sort((a, b) => {
    const nameA = a.bookTitle?.toUpperCase();
    const nameB = b.bookTitle?.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const renderPrintRow = (book) => {
    return (
      <div key={book.isbn13} className='odd-book-row-no-margin'>
        <div className='odd-book-fixed-content'>
          <div className='odd-book-row-column-start'>
            <div className='hide-in-print'>
              <LazyImage
                src={`https://images.penguinrandomhouse.com/cover/${book.isbn13}`}
                className={`odd-title-image`}
                alt={"Book"}
              ></LazyImage>
            </div>
            <div className='btt-title-data-container'>
              <div>
                <a
                  className='boldLabel text-gray-900'
                  href={`/titleinfo/${book.isbn13}`}
                >
                  {book.bookTitle}
                </a>
              </div>
              <div className='od-text-data-book'>By {book.author}</div>
              <div className='od-text-data-book'>
                {book.isbn13}
                <span className='pipe'> | </span>
                {book.bookFormat}
                <span className='pipe'> | </span>
                {book.bookOnsale}
              </div>
              <div className='od-text-data-book'>
                Sales #:{" "}
                <a href={`/orders/${book.salesOrder}`}>{book.salesOrder}</a>
                <span className='pipe'> | </span>
                PO #: {book.purchaseOrder}
              </div>
            </div>
          </div>
          <div className='odd-book-row-column-mid'>
            {book.promoDiscount ? (
              <div className='text-blue'>
                <NumericFormat
                  className=''
                  value={book.promoDiscount}
                  thousandSeparator={true}
                  decimalScale={0}
                  fixedDecimalScale={true}
                  displayType={"text"}
                  suffix={"%"}
                />{" "}
                off with Promo
              </div>
            ) : null}
            <div className='od-text-data-book'>
              {book.cartonQty} per cartonqty
            </div>
            <div className='od-text-data-book'>{book.departureCountry}</div>
            {book.upcSku.length > 0 && (
              <div className='od-text-data-book'>UPC/SKU: {book.upcSku}</div>
            )}
          </div>
          <div className='odd-book-row-column-end'>
            <div className={`od-text-data-bold`}>
              {Number(book.itemPrice).toFixed(2) > 0
                ? `$${Number(book.itemPrice).toFixed(2)}`
                : "FREE"}
            </div>
            <div className={`od-text-data-book`}>
              {Number(book.discountedCoverPrice).toFixed(2) > 0
                ? `$${Number(book.discountedCoverPrice).toFixed(2)}`
                : "FREE"}{" "}
              (each)
            </div>
            {Number(book.coverPrice).toFixed(2) > 0 &&
              Math.abs(Number(book?.discount)) > 0 && (
                <div className='od-discount-price '>
                  <span>{String(book.discount).replace("-", "")}% off </span>
                  <span className='text-line-through'>
                    ${Number(book.coverPrice).toFixed(2)}
                  </span>
                </div>
              )}
            <div className={`od-text-data-medium`}>Qty: {book.quantity}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='invoice-alpha-items-container odd-content-container odd-header-container'>
      {formattedData
        .filter((item) => item.quantity > 0)
        .map((item) => (
          <InvoiceDetailDropdownRowAlpha
            book={{
              ...item,
              purchaseOrderNumber: item.purchaseOrder,
              salesOrderNumber: item.salesOrder,
            }}
            key={item.isbn13 + item.invoiceItem}
            isEditing={props.isEditing}
            claims={props.claims}
            addOrRemoveFromClaims={props.addOrRemoveFromClaims}
            docType={docType}
          />
        ))}
    </div>
  );
};
