import React from "react";
import {Container, Icon} from "semantic-ui-react";
import "./HeaderMenu.scss";
import {Link, useHistory} from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const HeaderMenu = ({
  onClose,
  onOutsideMenuClick,
  profile,
  isUserPow,
  catalogItems,
  newsItems,
  ordersItems,
  accountItems,
  menuVisible,
}) => {
  const history = useHistory();
  return (
    <>
      <div
        id="mobile-header-menu"
        className={`shown-on-mobile header-menu ${
          menuVisible === undefined
            ? ""
            : menuVisible
            ? "mobileIn"
            : "mobileOut"
        }`}
      >
        <Container>
          <div id="mobile-greeting-message" className="user-greeting-container">
            <div className="header-iconholder active mobile" onClick={onClose}>
              <FontAwesomeIcon
                icon={faTimes}
                className="mr-1"
                color="#0078a6 "
                size="lg"
              />
            </div>
            <div className="user-info-container">
              <div>
                <Icon name="user" className="mobile-menu-icon" />{" "}
                <span className="user-greeting-message">
                  Hello, {profile && profile.given_name}
                </span>
              </div>
            </div>
            <div className="dropdown-separator mb-0" />
          </div>
          <div className="mobile-menu-title mt-2">
            <Icon name="home" className="mobile-menu-icon" />{' '}
            <Link to="/" className="mobile-menu-title title-link">
              Home
            </Link>
          </div>
          <div className="dropdown-separator" />
          {isUserPow && (
            <>
              <div className="mobile-menu-title">
                <Icon name="book" className="mobile-menu-icon" /> Catalog
              </div>
              <div className="mobile-menu-items-container">
                {catalogItems.map((item) => (
                  <React.Fragment key={item.key}>{item.content}</React.Fragment>
                ))}
              </div>
              <div className="dropdown-separator" />
            </>
          )}
          {isUserPow && (
            <>
              <div className="mobile-menu-title">
                <Icon name="newspaper" className="mobile-menu-icon" /> News
              </div>
              <div className="mobile-menu-items-container">
                {newsItems.map((item) => (
                  <React.Fragment key={item.key}>{item.content}</React.Fragment>
                ))}
              </div>
              <div className="dropdown-separator" />
            </>
          )}
          <div className="mobile-menu-title">
            <Icon name="box" className="mobile-menu-icon" /> My Orders and
            Invoices
          </div>
          <div className="mobile-menu-items-container">
            {ordersItems.map((item) => (
              <React.Fragment key={item.key}>{item.content}</React.Fragment>
            ))}
          </div>
          <div className="dropdown-separator" />
          <div className="mobile-menu-title">
            <Icon name="user" className="mobile-menu-icon" /> Account
          </div>
          <div className="mobile-menu-items-container">
            {accountItems.map((item) => (
              <React.Fragment key={item.key}>{item.content}</React.Fragment>
            ))}
          </div>
          <div className="dropdown-separator" />
          <div className="mobile-menu-title log-out">
            <Icon name="log out" className="mobile-menu-icon" />
            <span className="header-dropdown-item-text ml-1" onClick={() => history.push('/logout')}>Sign Out</span>
          </div>
        </Container>
      </div>
      {menuVisible && (
        <div
          className="header-menu-background-overlay"
          onClick={onOutsideMenuClick}
        />
      )}
    </>
  );
};
