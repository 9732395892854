import { PureComponent, useEffect, useState } from "react";
// import { withRouter } from 'react-router-dom';
// import queryString from 'query-string';
import * as Sentry from "@sentry/react";
import { v4 as uuid } from "uuid";
import withSecurity from "../components/hc/withSecurity";
import withDirty from "../shared-react-components/components/hc/withDirty";
import { useHistory, withRouter } from "react-router";
import ApplicationContext from "./ApplicationContext";
import AccountApi from "./components/accountApi";
import RegistrationAPI from "./components/registrationApi";
import EcomApi from "./components/EcomApi";
import ErrorPage from "./components/ErrorPage";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import useSecurity from "../components/hc/useSecurity";

export const ApplicationProvider = (props) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const userPriv = useSecurity();
  const ecomErrorMsg = useSelector((state) => state.ecom.errorMsg);
  const [unlisten, setUnlisten] = useState(
    history.listen((location, action) => {
      // console.log("on route change - " + location.pathname);
      ReactGA.pageview(location.pathname + location.search);
    })
  );
  const [accountApi, setAccountApi] = useState(
    new AccountApi({
      appID: userPriv.getAppID(),
      storeName: "account",
      errorCategory: 1000,
      errorHandler: (errMsg) => setError(errMsg),
      failedAuthHandler: () => failedAuthHandler(),
    })
  );
  const [registrationApi, setRegistrationApi] = useState(
    new RegistrationAPI({
      storeName: "registration",
      errorCategory: 2000,
      errorHandler: (errMsg) => setError(errMsg),
      failedAuthHandler: () => failedAuthHandler(),
    })
  );
  const [ecomApi, setEcomApi] = useState(
    new EcomApi({
      appID: userPriv.getAppID(),
      storeName: "ecom",
      errorCategory: 3000,
      errorHandler: (errMsg) => setError(errMsg),
      failedAuthHandler: () => failedAuthHandler(),
    })
  );

  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    return () => {
      if (unlisten) unlisten();
    };
  }, []);

  const setApiTokens = (sessionToken) => {
    accountApi.setSessionToken(sessionToken);
    registrationApi.setSessionToken(sessionToken);
    ecomApi.setSessionToken(sessionToken);
  };

  const setProxyRequestId = () => {
    if (!localStorage.getItem("proxyRequestId")) {
      "proxyRequestId", uuid();
    }
  };

  const getProxyRequestId = () => {
    if (!localStorage.getItem("proxyRequestId")) {
      return "";
    }
    return localStorage.getItem("proxyRequestId");
  };

  const setError = (e) => {
    setErrorMsg(e);
    dirtyHandler.setLoadingTakeover(false);
  };

  const failedAuthHandler = (apiName) => {
    const rUrl = `${window.location.href}`;

    userPriv.clearUser(rUrl);
    // console.log('failedAuthHandler', rUrl);
    // window.location = rUrl;
  };

  useEffect(() => {
    // console.log("AP CM - setting tokens", user);
    //console.log('api access token', nextProps.user.user.access_token);
    if (user.isAuthenticated) {
      setApiTokens(user.accessToken);
      setProxyRequestId();
      // accountApi.getUser(user.profile.preferred_username).then((userInfo) => {
      //   // console.log('user', user);
      //   if (userInfo?.data?.data?.obj) {
      //     userPriv.updateUser({
      //       user: {
      //         firstName: userInfo.data.data.obj.firstName,
      //         lastName: userInfo.data.data.obj.lastName,
      //       },
      //     });
      //   }
      // });
    }
    return () => {};
  }, [user.accessToken]);

  // console.log('AppProvider render', this.props, this.state);
  if (errorMsg !== null) {
    return <ErrorPage error={errorMsg} />;
  }
  if (ecomErrorMsg) {
    return <ErrorPage error={ecomErrorMsg} />;
  }

  return (
    <ApplicationContext.Provider
      value={{
        accountApi: accountApi,
        registrationApi: registrationApi,
        ecomApi: ecomApi,
      }}
    >
      {props.children}
    </ApplicationContext.Provider>
  );
};
