const userRoleConversion = (role) => {
    const conversion =  {
        W1 : 'Level 1 - Shipper/Receiver',
        W2 : 'Level 2 - Buyer',
        W3 : 'Level 3 - Buyer/Co-op',
        W9 : 'Level 4 - Admin',
        W4 : 'SAP Role W4'
      }
      if(!role || role === null) return false;

      if(conversion.hasOwnProperty(role.toUpperCase())){
          return conversion[role.toUpperCase()];
      }
      return false;
}

export default userRoleConversion;