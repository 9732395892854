import { Component } from 'react';
import withApp from '../hc/withApp';
import withAccount from '../hc/withAccount';
import { withRouter } from 'react-router';
import { IDENTITY_CONFIG } from '../../utils/authConst';
import { Icon } from 'semantic-ui-react';
import {
  PRHLabel,
  PRHContainer,
} from '../../shared-react-components/elements/elements';
//import { Button } from './elements'
import { Link } from 'react-router-dom';
import './registration.scss';

class LoginHelp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Ecom_User_ID: '',
      password: '',
    };
  }

  enterID = (e) => {
    this.setState({ userId: e.target.value });
  };

  enterPW = (e) => {
    this.setState({ password: e.target.value });
  };

  onSubmit() {
    const { Ecom_User_ID } = this.state;
    this.props.app.accountApi.storeUserId(Ecom_User_ID);
    if (this.validateEmail(Ecom_User_ID) === false) {
      this.props.history.push('/legacy');
    } else {
      document.getElementById('loginForm').submit();
    }
  }

  validateEmail(userId) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (userId.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  handleChange = (e) => {
    const newState = { ...state };
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  };

  followLink = (header, link) => {
    const { account } = this.props;
    // console.log('follow link');
    account.followLink('Forgot Password?');
    this.props.history.push(link);
  };

  render() {
    const { Ecom_User_ID } = this.state;
    const { setStepHandler, account } = this.props;
    const loginAction = IDENTITY_CONFIG.login;
    const loginTarget = IDENTITY_CONFIG.target + '/';
    const showMe = IDENTITY_CONFIG.base + '/user/bdasgupta';
    return (
      <>
        <div id="registration">
          <PRHContainer className="content-block-border">
            <div className={'white-box-header fort-light'}>
              Trouble Logging In?
            </div>
            <div className={'white-box-text fort-light'}>
              Please choose an option below and we'll help resolve the login
              issue.
            </div>
            <br />
            <div
              className={'link'}
              onClick={() =>
                this.followLink('Forgot Password?', '/recover-password')
              }
            >
              I forgot my password.
            </div>
            <br />
            <div
              className={'link'}
              onClick={() =>
                this.followLink('Forgot Password?', '/resend-invitation')
              }
            >
              I did not receive a verification email to complete my
              registration.
            </div>
            <br />
            {/*<div
              className={'link'}
              onClick={() =>
                this.followLink('Forgot Password?', '/verifylegacy')
              }
            >
              I have a Penguin Random House Biz account, but I'm unable to log
              in.
            </div>
            <br />*/}
            {
            /*<div className={'white-box-text fort-light'}>
              Biz 2 requires you to update some account information before you
              can log in. Selecting this option will walk you through those
              steps.
            </div>
            <br />*/}
            <div className={'white-box-text fort-light'}>
              Something else? Contact Customer Service.
            </div>
            <Icon
              name="envelope"
              className="text-gray-500 mr-2 lh-18 fs-13px"
            />
            <a href="mailto:bizcs@prh.com">
            bizcs@prh.com
            </a>
            {/*<br />
            <div className={'white-box-text fort-light'}>
              <Icon
                name="phone"
                flipped="horizontally"
                className="text-gray-500 mr-2 lh-18 fs-13"
          />
              1-800-733-3000
            </div>*/}
          </PRHContainer>
        </div>
      </>
    );
  }
}

export default withRouter(withApp(withAccount(LoginHelp)));
