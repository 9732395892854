//import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";
/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js';
// import 'regenerator-runtime/runtime';
// update legacy
// touch 12/02
import "core-js";
import "core-js/es/symbol";
import "core-js/es/object";
import "core-js/es/function";
import "core-js/es/parse-int";
import "core-js/es/parse-float";
import "core-js/es/number";
import "core-js/es/math";
import "core-js/es/string";
import "core-js/es/date";
import "core-js/es/array";
import "core-js/es/regexp";
import "core-js/es/map";
import "core-js/es/weak-map";
import "core-js/es/set";
import "core-js/es/promise";

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es/reflect';

/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import 'core-js/es/reflect';

//import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "semantic-ui-less/definitions/globals/reset.less";
import "semantic-ui-less/definitions/globals/site.less";
import "semantic-ui-less/semantic.less";
import DirtyProvider from "./shared-react-components/providers/DirtyProvider";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faCheck,
  faFileAlt,
  faListAlt,
  faWrench,
  faDownload,
  faBell,
  faUserCog,
  faCog,
  faArrowCircleLeft,
  faTimes,
  faTimesCircle,
  faQuestionCircle,
  faAngleLeft,
  faAngleRight,
  faChevronDown,
  faChevronUp,
  faArrowRight,
  faSearch,
  faSearchPlus,
  faSlidersH,
  faStar,
  faPlus,
  faThLarge,
  faTh,
  faList,
  faCaretDown,
  faCaretUp,
  faExternalLinkSquareAlt,
  faFileArchive,
  faFileCsv,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { LicenseManager } from "ag-grid-enterprise";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";

library.add(
  faPhone,
  faCheck,
  faFileAlt,
  faListAlt,
  faWrench,
  faDownload,
  faBell,
  faUserCog,
  faCog,
  faTimes,
  faTimesCircle,
  faQuestionCircle,
  faArrowCircleLeft,
  faAngleLeft,
  faAngleRight,
  faChevronDown,
  faChevronUp,
  faArrowRight,
  faSearch,
  faSearchPlus,
  faSlidersH,
  faStar,
  faPlus,
  faThLarge,
  faTh,
  faList,
  faExternalLinkSquareAlt,
  faCaretUp,
  faCaretDown,
  faFileArchive,
  faFileCsv,
  faPencilAlt
);

// Ag-grid enterprise service key
//update 08/26a
//touch to push 01007

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_ENTERPRISE_KEY);
/*
Sentry.init({
  dsn: "https://96e54673591241bf8a4294b18be527b2@o626573.ingest.sentry.io/5754398",
    //'https://e91493d6318c4305b7e135b103572a00@sentry.dev.penguinrandomhouse.com/5',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});
*/
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <DirtyProvider>
        <App />
      </DirtyProvider>
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
