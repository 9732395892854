import { cloneElement, useState, useEffect } from "react";
import {
  Header,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import withApp from "../hc/withApp";
import withCart from "../hc/withCart";
import { Divider, PRHError } from "../elements/elements";
import {
  useLazyGetPromosQuery,
  useLazyGetSalesRepsQuery,
} from "../../api/ecomApi";
import { NumericFormat } from "react-number-format";
import "./PromotionsModal.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";

const PromotionsModal = ({
  cart,
  disabled,
  refresh = false,
  trigger,
  openState,
  className,
  applyPromoCode,
}) => {
  const [open, setOpen] = useState(false);
  const [promos, setPromos] = useState([]);
  const [promosLoaded, setPromosLoaded] = useState(false);
  const [currentPromo, setCurrentPromo] = useState(false);
  const [performGetPromos, getPromosResult] = useLazyGetPromosQuery();

  const getPromos = async () => {
    const result = await performGetPromos({}, true);

    return result;
  };

  let realTrigger = trigger;
  if (!trigger) {
    realTrigger = (
      <div className='d-flex flex-column fort-book fs-13px lh-18px orange-hover'>
        <div
          onClick={(e) => {
            setOpen(true);
            if (openState) {
              openState(true);
            }
          }}
        >
          View my promotions
        </div>
      </div>
    );
  } else {
    realTrigger = cloneElement(realTrigger, {
      onClick: () => setOpen(true),
    });
  }

  const resetState = () => {
    setPromos([]);
    setPromosLoaded(false);
    setCurrentPromo(false);
  };

  /*
  const getPromotions = async () => {
    const result = await cart.getPromos();
    if (result.status === 200) {
      setPromos(result.data);
      setCurrentPromo(cart.cart.promoCode);
      setPromosLoaded(true);
    }
  };*/

  useEffect(() => {
    if (!open) {
      resetState();
    }
    if (openState) {
      openState(open);
    }
    if (open) {
      getPromos().then(({ data }) => {
        setPromos(data);
        setPromosLoaded(true);
      });
    }
  }, [open]);

  let workPromos = [];
  let workPromo = false;
  let prevPromoCode = false;
  let addTrailing = false;
  promos.forEach((promo) => {
    if (promo.code && promo.code !== "") {
      addTrailing = false;
      if (prevPromoCode !== promo.code) {
        if (prevPromoCode) {
          workPromos.push(workPromo);
        } else addTrailing = true;
        workPromo = { code: promo.code, promos: [promo] };
        prevPromoCode = promo.code;
      } else {
        workPromo.promos.push(promo);
      }
    }
  });
  // if (addTrailing) workPromos.push(workPromo);
  if (workPromo || addTrailing) workPromos.push(workPromo);

  // console.log('promo', workPromos, currentPromo);
  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            Promotions
          </Header>
        ),
        actions: [],
      }}
      options={{
        trigger: realTrigger,
      }}
    >
      <div className='mb-4 ar-box'>
        {!promosLoaded && <Loader active />}
        {workPromos.length > 0 ? (
          <>
            <div className='mb-4'>
              Please note: Only one discount may be applied at a time.
              Promotional codes cannot be applied on event orders. Please
              contact Customer Service if there's a promotion you'd like to use
              on an event order.
            </div>
            <Divider tight />
            <div
              className='mt-3'
              style={{ maxHeight: "600px", overflow: "auto" }}
            >
              {workPromos.map((promo, idx) => (
                <div key={idx} className='mb-1'>
                  <div className='d-flex flex-row mb-2 pr-2'>
                    <div className='d-flex flex-column'>
                      <div className='fort-medium'>
                        {promo.promos[0].description}
                      </div>
                      {promo.promos.map((p, i2) => (
                        <div key={i2}>
                          {i2 === 0 ? (
                            <div>
                              <div>
                                Use code{" "}
                                <span className='fort-bold'>{p.code}</span> to
                                save{" "}
                                <NumericFormat
                                  className=''
                                  allowNegative={false}
                                  value={p.discount}
                                  thousandSeparator={true}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  displayType={"text"}
                                  // prefix={'-'}
                                  suffix={"%"}
                                />
                              </div>
                              {p.minQty > 0 ? (
                                <div>
                                  Order must contain at least {p.minQty} books
                                </div>
                              ) : null}
                              {p.terms !== "" ? <div>{p.terms}</div> : null}
                            </div>
                          ) : (
                            <div key={i2}>
                              <div>
                                for{" "}
                                <NumericFormat
                                  className=''
                                  allowNegative={false}
                                  value={p.discount}
                                  thousandSeparator={true}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  displayType={"text"}
                                  // prefix={'-'}
                                  suffix={"%"}
                                />{" "}
                                off,{" "}
                                {p.minQty > 0 ? (
                                  <span>
                                    order must contain at least {p.minQty} books
                                  </span>
                                ) : null}
                                {p.terms !== "" ? <span>{p.terms}</span> : null}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      {promo.promos[0].endDate ? (
                        <div>
                          Expires{" "}
                          {formatDateMMDDYYYY(promo.promos[0].endDate, "/")}
                        </div>
                      ) : null}
                    </div>

                    <div className='d-flex flex-column ml-auto'>
                      <PRHButton
                        disabled={promo.code === currentPromo}
                        style={{ width: "135px" }}
                        icon={promo.code === currentPromo ? "check" : "plus"}
                        onClick={(e) => {
                          applyPromoCode(promo.code);
                          setCurrentPromo(promo.code);
                          // setOpen(false);
                        }}
                      >
                        {promo.code === currentPromo ? "Applied" : "Apply Code"}
                      </PRHButton>
                    </div>
                  </div>
                  <Divider tight />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className='mt-2 mb-2'>
            {promosLoaded
              ? "No promotions are currently available for your account."
              : ""}
          </div>
        )}
      </div>
    </DialogModal>
  );
};

PromotionsModal.propTypes = {
  refresh: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  trigger: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  applyPromoCode: PropTypes.func,
};

PromotionsModal.defaultProps = {
  refresh: false,
  trigger: false,
};

export default withApp(withCart(PromotionsModal));
