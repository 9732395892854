import { Component } from 'react';
import withAccount from '../hc/withAccount';
import { withRouter } from 'react-router';
import { PRHContainerHeader } from '../../shared-react-components/elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { Form } from 'semantic-ui-react';
import { ButtonHolder } from './elements';

class AccountCancel extends Component {
  
  cancel = (e) => { 
    const { account } = this.props;
    this.props.history.push('/');
    account.setUpdateResponseHandler('default');
  };

  render() {
    const { account } = this.props;
    return (
      <>
      <div className={'account'}>
        <div className={'white-background-container'}>
          <PRHContainerHeader>
            Are you sure you want to cancel?
          </PRHContainerHeader>
          <span>
            You will not be able to access Biz 2 until your account details have
            been updated.
          </span>
          <br />
          <br />
          <ButtonHolder>
            <PRHButton
              size="large"
              className={'float-left'}
              type="submit"
              onClick={(e) => this.cancel('cancel')}
            >
              Cancel
            </PRHButton>
            <PRHButton
              iconright
              size="large"
              className={'float-right margin-left'}
              icon="arrow right"
              onClick={(e) => account.setUpdateResponseHandler('default')}
            >
              Continue
            </PRHButton>
          </ButtonHolder>
          <br />
          <br />
        </div>
        </div>
      </>
    );
  }
}

export default withRouter(withAccount(AccountCancel));
