import { Api } from "./api";

export const estimateCartApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getEstimateCarts: builder.query({
      query: ({
        shipTo,
        foc,
        simulate,
        orderDetail,
        titleDetail,
        skipCartLoad = false,
      }) => {
        const headers = {};

        let data = {
          shipTo: shipTo ? shipTo : [],
        };
        if (foc) {
          if (Array.isArray(foc)) {
            data.foc = foc;
          } else if (typeof foc === "string") {
            data.foc = [foc];
          }
        }

        return {
          url: `/ecom/estimates/carts/search?titleDetail=${titleDetail}${
            simulate ? "&simulate=true" : ""
          }${orderDetail ? "&orderDetail=true" : ""}`,
          method: "POST",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getEstimateCarts", arg);
      },
    }),
    getVariantRestriction: builder.query({
      query: ({ isbn, searchClass, country }) => {
        const headers = {};

        let data = {
          isbn,
          searchClass,
          country,
        };

        return {
          url: `/ecom/search/variants`,
          method: "POST",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getVariantRestriction", arg);
      },
    }),
    undoEstimateCart: builder.mutation({
      query: ({ data }) => {
        const headers = {};

        return {
          url: `/ecom/estimates/carts/rollback?simulate=true`,
          method: "PATCH",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - undoEstimateCart", arg);
      },
    }),
    confirmEstimateCart: builder.mutation({
      query: ({ data }) => {
        const headers = {};

        return {
          url: `/ecom/estimates/carts/submit-confirmation?simulate=true`,
          method: "PATCH",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - confirmEstimateCart", arg);
      },
    }),
    removeEstimateCarts: builder.mutation({
      query: ({ shipTo, foc, force, confirm }) => {
        const headers = {};

        const data = {};
        if (shipTo) {
          data.shipTo = shipTo;
        }
        if (foc) {
          if (Array.isArray(foc)) {
            data.foc = foc;
          } else if (typeof foc === String) {
            data.foc = [foc];
          }
        }

        return {
          url: `/ecom/estimates/carts${force ? "?forceDelete=true" : ""}${
            confirm ? (force ? "&confirm=true" : "?confirm=true") : ""
          }`,
          method: "DELETE",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  removeEstimateCarts", arg);
      },
    }),
    removeEstimateCart: builder.mutation({
      query: ({ shipTo, foc, force, confirm }) => {
        const headers = {};
        return {
          url: `/ecom/estimates/carts/${foc}${
            force ? "?forceDelete=true" : ""
          }${confirm ? (force ? "&confirm=true" : "?confirm=true") : ""}`,
          method: "DELETE",
          headers,
          body: { shipTo },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  removeEstimateCarts", arg);
      },
    }),
    removeEstimateCartItems: builder.mutation({
      query: ({ shipTo, foc, items, simulate, force, confirm }) => {
        const headers = {};

        return {
          url: `/ecom/estimates/carts/${foc}/items?simulate=${simulate}${
            force ? "&forceDelete=true" : ""
          }${confirm ? "&confirm=true" : ""}`,
          method: "DELETE",
          headers,
          body: { shipTo, items },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  removeEstimateCartItems", arg);
      },
    }),
    addEstimateCartItems: builder.mutation({
      query: ({ carts, simulate, addAll, listId = false }) => {
        const headers = {};

        return {
          url: `/ecom/estimates/carts?simulate=${simulate ? "true" : "false"}${
            addAll ? `&addAll=true` : ""
          }${listId ? `&listId=${listId}` : ""}`,
          method: "PATCH",
          headers,
          body: { carts },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  addEstimateCartItems", arg);
      },
      transformErrorResponse: (response, meta, arg) => response.status,
    }),
    updateEstimateItemQty: builder.mutation({
      query: ({ foc, shipTo, catalogDate, items, simulate }) => {
        const headers = {};

        const data = {};

        if (foc) {
          data.foc = foc;
        }
        if (shipTo) {
          data.shipTo = shipTo;
        }
        if (catalogDate) {
          data.catalogDate = catalogDate;
        }
        if (items && Array.isArray(items)) {
          data.items = items;
        }

        return {
          url: `/ecom/estimates/carts?simulate=${simulate}`,
          method: "PUT",
          headers,
          body: { carts: [data] },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  addEstimateCartItems", arg);
      },
    }),
    updateEstimateVariantMainItem: builder.mutation({
      query: ({ foc, shipTo, mainItem, variantItems, simulate }) => {
        const headers = {};

        return {
          url: `/ecom/estimates/carts/variants/main?simulate=${simulate}`,
          method: "PUT",
          headers,
          body: { foc, shipTo, mainItem, variantItems },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  updateEstimateVariantMainItem", arg);
      },
    }),
    moveEstimateItemsToGeneral: builder.mutation({
      query: ({ foc, shipTo }) => {
        const headers = {};

        return {
          url: `/ecom/estimates/carts/${foc}/move-to/general`,
          method: "PUT",
          headers,
          body: { shipTo },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  moveEstimateItemsToGeneral", arg);
      },
    }),
    addVariantsToCart: builder.mutation({
      query: ({ data }) => {
        const headers = {};

        return {
          url: `/ecom/estimates/carts/variants`,
          method: "PATCH",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  addVariantsToCart", arg);
      },
    }),
    updateVariantsToCart: builder.mutation({
      query: ({ data }) => {
        const headers = {};

        return {
          url: `/ecom/estimates/carts/variants`,
          method: "PUT",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  addVariantsToCart", arg);
      },
    }),
  }),
});

export const {
  useGetEstimateCartsQuery,
  useLazyGetEstimateCartsQuery,
  useGetVariantRestrictionQuery,
  useLazyGetVariantRestrictionQuery,
  useUndoEstimateCartMutation,
  useConfirmEstimateCartMutation,
  useRemoveEstimateCartsMutation,
  useRemoveEstimateCartMutation,
  useRemoveEstimateCartItemsMutation,
  useAddEstimateCartItemsMutation,
  useUpdateEstimateItemQtyMutation,
  useUpdateEstimateVariantMainItemMutation,
  useMoveEstimateItemsToGeneralMutation,
  useAddVariantsToCartMutation,
  useUpdateVariantsToCartMutation,
} = estimateCartApi;
