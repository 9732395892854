import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { InvoiceDetailDropdownRow } from "./InvoiceDetailDropdownRow";

export const InvoiceDetailDropdown = withRouter((props) => {
  const [isOpen, setisOpen] = useState(true);

  return (
    <div className='odd-header-container' key={props.billingDocNumber}>
      {/* {props.invoiceItemDetailsList.length > 0 && 
          <div className="odd-header-container" key={props.billingDocNumber}> */}
      <div className='odd-header'>
        <div className='odd-icon-container' onClick={() => setisOpen(!isOpen)}>
          <Icon name={isOpen ? "chevron down" : "chevron right"} color='grey' />
        </div>
        <div className='odd-dropdown-header-item'>
          <div className='odd-dropdown-header-title'>Purchase Order</div>
          <a
            href={`/orders/${props.salesOrder}`}
            className='od-text-data-book '
          >
            {props.purchaseOrder}
          </a>
        </div>
        <div className='odd-dropdown-header-item'>
          <div className='odd-dropdown-header-title'>Sales Number</div>
          {props.docType === "F2" ? (
            <a
              href={`/orders/${props.salesOrder}`}
              className='od-text-data-book '
            >
              {props.salesOrder}
            </a>
          ) : (
            props.salesOrder
          )}
        </div>
      </div>
      {isOpen && (
        <div className={"odd-content-container"}>
          {props.invoiceItemDetailsList
            .filter((item) => item.quantity > 0)
            .map((item) => (
              <InvoiceDetailDropdownRow
                isLoading={props.isLoading}
                key={item.isbn13 + item.invoiceItem}
                book={{
                  ...item,
                  purchaseOrderNumber: props.purchaseOrder,
                  salesOrderNumber: props.salesOrder,
                }}
                isEditing={props.isEditing}
                claims={props.claims}
                addOrRemoveFromClaims={props.addOrRemoveFromClaims}
                history={props.history}
              />
            ))}
        </div>
      )}
    </div>
  );
});
