import { useState, useEffect } from "react";
import { Header } from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import { Divider, PRHError } from "../elements/elements";
import _ from "underscore";
import LazyImage from "../../utils/LazyImage";
import useCart from "../hc/useCart";

const UndoBlockedDialogModal = ({
  open = false,
  setOpen,
  callback,
  unsatisfiedTitles,
}) => {
  const cart = useCart();
  const [dialogVisible, setDialogVisible] = useState(false);
  const resetState = () => {
    setDialogVisible(false);
  };

  const ContinueAction = {
    label: "Ok",
    borderColorOverride: "blue",
    textColorOverride: "white",
    textColorHoverOverride: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "white",
    iconColorOverride: "white",
    iconColorHoverOverride: "blue",
    // icon: 'checkmark',
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      resetState();
      if (callback) callback();
      return { proceed: true };
    },
  };

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      size='large'
      // onXClose={cancelCallback}
      modalClassName='ub-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            Cannot Undo All Updates
          </Header>
        ),
        actions: [ContinueAction],
      }}
    >
      <div className='ar-box'>
        <>
          <div className='ml-4 mr-4 mb-2 fs-13px lh-18px text-gray-900'>
            <div className='fort-bold'>
              You cannot revert to your previously Confirmed quantities because
              the following variant(s)' quantities no longer qualify due to
              changes in other account(s)' carts.
            </div>
          </div>
          <Divider className='mt-0 mb-0' />
          <div
            style={{ maxHeight: "400px" }}
            className='pl-4 pr-4 pt-2 pb-2 overflow-auto bg-gray-100'
          >
            {unsatisfiedTitles &&
              unsatisfiedTitles.map((title, idx) => (
                <div key={idx}>
                  <div className='d-flex flex-row'>
                    <LazyImage
                      className='vri-title-cover'
                      boxShadow={true}
                      src={`https://images.penguinrandomhouse.com/cover/${title.isbn}`}
                    ></LazyImage>

                    <div className='d-flex flex-column ml-2 justify-content-center'>
                      <div className='fort-bold fs-13px lh-18px text-gray-700'>
                        {title.title}
                      </div>
                      <div className='fort-book fs-13px lh-18px text-gray-700'>
                        {title.isbn}
                      </div>
                    </div>
                    <div className='d-flex flex-column ml-auto justify-content-center'>
                      <div className='fort-bold fs-13px lh-18px text-gray-700 ml-auto'>
                        Qty: {title.disqualifiedQuantity}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <Divider className='mt-0 mb-0' />
          <div className='mt-2 ml-4 mr-4 mb-2 fs-13px lh-18px text-gray-900'>
            <div className='fort-book'>
              Please add the correct number of qualifying titles in other
              accounts if you want to undo this cart's updates.
            </div>
          </div>
          <div className='ml-4 mr-4 mb-2 fs-13px lh-18px text-gray-900'>
            <div className='fort-book'>
              <span className='fort-bold'>Note:</span> if no action is taken by
              the FOC date, your Confirmed Cart will be processed without the
              variant quantities listed above.
            </div>
          </div>
        </>
      </div>
    </DialogModal>
  );
};

UndoBlockedDialogModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

UndoBlockedDialogModal.defaultProps = {};

export default UndoBlockedDialogModal;
