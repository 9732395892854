import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../utils/const";
import MobileContext from "./MobileContext";

const MobileProvider = (props) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  return (
    <MobileContext.Provider
      value={{
        isMobile: isMobile,
      }}
    >
      {props.children}
    </MobileContext.Provider>
  );
};

export default MobileProvider;
