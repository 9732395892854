import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Popup, Icon, Input, Select, Radio } from "semantic-ui-react";
import useCart from "../../../components/hc/useCart";
import { Divider } from "../../../components/elements/elements";
import LazyImage from "../../../utils/LazyImage";
import "./DropdownStoreSelectorLists.scss";
export default function DropdownStoreSelectorLists(props) {
  const { shipTo, selectShipTo } = props;
  const cart = useCart();
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [searchText, setSearchText] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [storeListImmutable, setStoreListImmutable] = useState([]);
  // console.log(shipTo, typeof shipTo);

  const memoisedBuildStoreList = useCallback(() => {
    let storeList = [];
    cart.customerInfo.shipToAccountNos &&
      cart.customerInfo.shipToAccountNos.forEach((shipToInfo, i) => {
        // console.log(i, typeof i);
        //if (i >= 4) return;
        if (searchText && searchText !== "") {
          if (
            shipToInfo.address.name1
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) === -1 &&
            shipToInfo.address.city
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) === -1 &&
            shipToInfo.address.state
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) === -1 &&
            shipToInfo.accountNo
              .toString()
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) === -1
          )
            return;
        }
        storeList.push({
          key: i,
          value: i,
          text: (
            <div className={`${i === shipTo ? "current-select" : ""}`}>
              <div className="fort-bold">{shipToInfo.address.name1}</div>
              <div>
                {shipToInfo.accountNo}
                <Divider horizontal />
                {shipToInfo.address.city},{shipToInfo.address.state}
              </div>
            </div>
          ),
          content: (
            <div
              className={`d-flex flex-row cursor-pointer bridge-select-location-item ${
                i === 0 ? "bridge-select-location-item-first" : ""
              }`}
              onClick={(e) => {
                selectShipTo(i);
                //setSelectorOpen(false);
              }}
            >
              <div className="radio-container">
                <Radio
                  //disabled={shippingEdit}
                  name={`shipTo-${i}`}
                  //value={"01"}
                  checked={shipTo == i ? true : false}
                  //onChange={() => selectShipTo(i)}
                />
              </div>
              <div className="d-flex flex-column">
                <div className="fort-bold dd-radio-text-1">
                  {shipToInfo.address.city}, {shipToInfo.address.state}
                </div>
                <div className="fort-book dd-radio-text-2">
                  {shipToInfo.accountNo} | {shipToInfo.address.name1}
                </div>
              </div>
            </div>
          ),
        });
      });
    if (
      storeList.length === 0 &&
      cart.customerInfo.shipToAccountNos.length > 1
    ) {
      storeList.push({
        key: "not-found",
        value: -1,
        text: (
          <div className={``}>
            <div></div>
          </div>
        ),
        content: <div className="hls-item d-flex flex-row"></div>,
      });
    }
    setStoreList(storeList);
    if (storeListImmutable.length === 0) {
      setStoreListImmutable(JSON.parse(JSON.stringify(storeList)));
    }
  }, [cart.customerInfo.shipToAccountNos, selectShipTo, searchText]);

  useEffect(() => {
    memoisedBuildStoreList();
  }, [searchText, memoisedBuildStoreList]);
  const handleClear = () => setSearchText("");
  // console.log("Dropdown", storeList);

  if (storeList.length < 1 || cart.customerInfo.shipToAccountNos.length === 1) {
    return null;
  } else {
    let colorProps = {};
    // if (selectorOpen) colorProps.color = 'white';
    return (
      <div
        //className="container hl-banner location-selector"
        style={{ display: "flex", alignItems: "center" }}
        className="bridge-selector-location-modal"
      >
        <div
          id="container"
          className="ui container"
          // style={{
          //   padding: 5,
          //   height: 'auto',
          // }}
        >
          <div
            className="d-flex cursor-pointer location-selection bridge-select-location-notice"
            onClick={(e) => setSelectorOpen(!selectorOpen)}
          >
            <Icon
              name="map marker alternate"
              size="large"
              //color="white"
              //{...colorProps}
              //className="mr-1"
              style={{ marginLeft: "-2px", marginRight: "12px" }}
            />

            <div className="d-flex flex-column w-100 text-white">
              <div className="d-flex flex-row w-100">
                {shipTo === -1 ? (
                  <span className="fort-bold location">Select a Location</span>
                ) : (
                  <div
                    className="location"
                    // style={{
                    //   display: "flex",
                    //   flexDirection: "column",
                    // }}
                  >
                    <span className="fort-medium mr-1">Ordering For:</span>{" "}
                    {cart.customerInfo.shipToAccountNos[shipTo].accountNo} |{" "}
                    {cart.customerInfo.shipToAccountNos[shipTo].address.name1} |{" "}
                    {cart.customerInfo.shipToAccountNos[shipTo].address.city},{" "}
                    {cart.customerInfo.shipToAccountNos[shipTo].address.state}{" "}
                  </div>
                )}
                {/*<Icon
                  name={`caret ${selectorOpen ? "up" : "down"}`}
                  {...colorProps}
                  className="ml-2"
                />*/}
              </div>
            </div>
          </div>

          <div className="-d-flex flex-column">
            {storeListImmutable.length > 4 ? (
              <div className="bridge-select-location-notice">
                <Input
                  icon
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText ? searchText : ""}
                >
                  <input
                    placeholder="Search for a location"
                    style={{ paddingLeft: "3em" }}
                  />

                  <Icon
                    name="search"
                    style={{ position: "absolute", left: "1em" }}
                  />
                  {searchText && (
                    <Icon
                      name="close"
                      link
                      style={{ position: "absolute", right: "1em" }}
                      onClick={handleClear}
                    />
                  )}
                </Input>
              </div>
            ) : (
              ""
            )}

            <div
              className={
                storeListImmutable.length > 4
                  ? "locatation-holder"
                  : "locatation-holder-no-scroll"
              }
            >
              {storeList.map((s, i) => {
                return <div key={i}>{s.content}</div>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DropdownStoreSelectorLists.propTypes = {
  shipTo: PropTypes.number.isRequired,
  //selectShipTo: PropTypes.func.isRequired,
};

DropdownStoreSelectorLists.defaultProps = {};
