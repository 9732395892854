import { useEffect, useState } from "react";
import withApp from "../../components/hc/withApp";
import PageStandard from "../../components/elements/PageStandard";
import CustomerEnrolmentLanding from "../../components/customerenrolment/CustomerEnrolmentLanding";
import BusinessInformationForm from "../../components/customerenrolment/BusinessInformationForm";
import "./customerenrolment.scss";
import {
  PRHContainer,
  PRHContainerHeader,
} from "../../shared-react-components/elements/elements";
import { Divider } from "../../components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Form } from "semantic-ui-react-form-validator";
import BillingAddressForm from "../../components/customerenrolment/BillingAddressForm";
import ShippingAddressForm from "../../components/customerenrolment/ShippingAddressForm";
import RequiredDocumentsForm from "../../components/customerenrolment/RequiredDocumentsForm";
import UploadRequiredDocumentsForm from "../../components/customerenrolment/UploadRequiredDocumentsForm";
import SelectPaymentMethod from "../../components/customerenrolment/SelectPaymentMethod";
import BankAccountInformation from "../../components/customerenrolment/BankAccountInformation";
import TermsOfSale from "../../components/customerenrolment/TermsOfSale";
import ReviewAndSign from "../../components/customerenrolment/ReviewAndSign";
import TradeCreditReference from "../../components/customerenrolment/TradeCreditReference";
import FormSubmitted from "../../components/customerenrolment/FormSubmitted";
import CESuccessImage from "../../assets/images/customer_enrolment_graphic.png";
import {
  COUNTRY,
  emptyEnrolmentData,
  EnrolmentStep,
  mapStateCodeToStateValue,
  paymentMethods_MOCK,
  steps,
} from "../../components/customerenrolment/shared";
import StepperSmall from "../../components/customerenrolment/StepperSmall";
import { cloneDeep } from "lodash-es";
import { useLazyGetDataQuery } from "../../api/bizcontentApi";

const CustomerEnrolment = (props) => {
  const [enrolmentToken, setEnrolmentToken] = useState(null);
  const [enrolmentData, setEnrolmentData] = useState(emptyEnrolmentData);
  const [enrolmentErrorMsg, setEnrolmentErrorMsg] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [enrolmentFlowStep, setEnrolmentFlowStep] = useState(
    EnrolmentStep.Welcome
  );
  const [confirmedTermsOfSale, setConfirmedTermsOfSale] = useState(false);
  const [documents, setDocuments] = useState({
    list: null,
    fetched: false,
  });
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [disableNext, setDisableNext] = useState(false);
  const [ performGetData, getDataResult] = useLazyGetDataQuery();

  const fetchDocuments = async (state) => {
    const { data } = await performGetData({state});
    let documentsMerged;
    if (data) {
      const { exempt_forms, tax_forms } = data;
      documentsMerged = exempt_forms
        ? tax_forms
          ? exempt_forms.concat(tax_forms)
          : exempt_forms
        : tax_forms;
    }
    return documentsMerged;
  };

  useEffect(() => {
    props.app.registrationApi.getCustomerEnrolmentToken().then((r) => {
      r?.data?.data?.obj && setEnrolmentToken(r.data.data.obj);
    });
  }, [props.app.registrationApi]);

  useEffect(() => {
    const { match } = props;
    if (match.url === "/newaccountenrollment") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
        switch (enrolmentFlowStep) {
          case EnrolmentStep.BusinessInformation:
            setEnrolmentFlowStep(EnrolmentStep.Welcome);
            break;
          case EnrolmentStep.BillingAddress:
            setEnrolmentFlowStep(EnrolmentStep.BusinessInformation);
            break;
          case EnrolmentStep.ShippingAddress:
            setEnrolmentFlowStep(EnrolmentStep.BillingAddress);
            break;
          case EnrolmentStep.DownloadRequiredDocuments:
            enrolmentData[EnrolmentStep.BillingAddress]
              .shippingAddressIsBillingAddress === true
              ? setEnrolmentFlowStep(EnrolmentStep.BillingAddress)
              : setEnrolmentFlowStep(EnrolmentStep.ShippingAddress);
            break;
          case EnrolmentStep.UploadRequiredDocuments:
            setEnrolmentFlowStep(EnrolmentStep.DownloadRequiredDocuments);
            break;
          case EnrolmentStep.PaymentMethod:
            if (documents.fetched === true && documents.list) {
              setEnrolmentFlowStep(EnrolmentStep.UploadRequiredDocuments);
            } else {
              if (
                enrolmentData[EnrolmentStep.BillingAddress]
                  .shippingAddressIsBillingAddress === true
              ) {
                setEnrolmentFlowStep(EnrolmentStep.BillingAddress);
              } else {
                setEnrolmentFlowStep(EnrolmentStep.ShippingAddress);
              }
            }
            break;
          case EnrolmentStep.BankAccountInformation:
            setEnrolmentFlowStep(EnrolmentStep.PaymentMethod);
            break;
          case EnrolmentStep.TradeCreditReference:
            setEnrolmentFlowStep(EnrolmentStep.BankAccountInformation);
            break;
          case EnrolmentStep.TermsOfSale:
            enrolmentData[EnrolmentStep.PaymentMethod] ===
            paymentMethods_MOCK.CreditCard.id
              ? setEnrolmentFlowStep(EnrolmentStep.PaymentMethod)
              : setEnrolmentFlowStep(EnrolmentStep.BankAccountInformation);
            break;
          case EnrolmentStep.ReviewAndSign:
            setEnrolmentFlowStep(EnrolmentStep.TermsOfSale);
            setEnrolmentErrorMsg(null);
            break;
          default:
            setEnrolmentFlowStep(EnrolmentStep.Welcome);
            break;
        }
      });
    }
  }, [
    documents.fetched,
    documents.list,
    enrolmentData,
    enrolmentFlowStep,
    props,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [enrolmentFlowStep]);

  const formData = {
    [EnrolmentStep.Welcome]: {
      title: (
        <div className="header-container blue-gradient mt-4">
          <div className="landing-header-title mb-5">
            Welcome to
            <br />
            Penguin Random House New Account Enrollment.
          </div>
          <div className="title-tagline-text">
            We've created the following forms to make it easier for you to
            provide the information required to set up a new
            business-to-business account with us.
            <br />
            Please note, this functionality is only available to certain account
            types. We will expand in the future, but unless you've already
            talked to your sales rep or our New Accounts team, please contact
            them before proceeding.
          </div>

          {enrolmentToken && (
            <PRHButton
              disabled={false}
              iconright
              icon="arrow right"
              size="large"
              className="mx-auto my-4"
              type="submit"
              backgroundColorOverride="white"
              onClick={() =>
                setEnrolmentFlowStep(EnrolmentStep.BusinessInformation)
              }
            >
              Create a New Account
            </PRHButton>
          )}
        </div>
      ),
      subtitle: null,
      stepperStatus: null,
      content: () => {
        return (
          <CustomerEnrolmentLanding
            onEnrolCustomer={() =>
              setEnrolmentFlowStep(EnrolmentStep.BusinessInformation)
            }
          />
        );
      },
    },
    [EnrolmentStep.BusinessInformation]: {
      title: "Information About Your Business",
      subtitle: null,
      stepperStatus: 1,
      content: () => {
        return (
          <BusinessInformationForm
            data={enrolmentData[EnrolmentStep.BusinessInformation]}
            onChange={(field, value) => {
              if (field === "countryOfOperation") {
                setEnrolmentData((prevState) => ({
                  ...prevState,
                  [EnrolmentStep.BusinessInformation]: {
                    ...prevState[EnrolmentStep.BusinessInformation],
                    [field]: value,
                    federalEmployeeIdentificationNumber: null,
                    soleProprietorship: null,
                    hstGstPstQstNumber: null,
                    stateOfIncorporation: null,
                  },
                }));
              } else {
                setEnrolmentData((prevState) => ({
                  ...prevState,
                  [EnrolmentStep.BusinessInformation]: {
                    ...prevState[EnrolmentStep.BusinessInformation],
                    [field]: value,
                  },
                }));
              }
            }}
            onNext={() => setEnrolmentFlowStep(EnrolmentStep.BillingAddress)}
            onBack={() => setEnrolmentFlowStep(EnrolmentStep.Welcome)}
          />
        );
      },
    },
    [EnrolmentStep.BillingAddress]: {
      title: "Billing Address Information",
      subtitle: null,
      stepperStatus: 1,
      content: () => {
        return (
          <BillingAddressForm
            data={enrolmentData[EnrolmentStep.BillingAddress]}
            legalBusinessName={
              enrolmentData[EnrolmentStep.BusinessInformation].legalBusinessName
            }
            onChangeBusinessName={(value) =>
              setEnrolmentData((prevState) => ({
                ...prevState,
                [EnrolmentStep.BusinessInformation]: {
                  ...prevState[EnrolmentStep.BusinessInformation],
                  legalBusinessName: value,
                },
              }))
            }
            selectedCountry={
              enrolmentData[EnrolmentStep.BusinessInformation]
                .countryOfOperation
            }
            onChange={(field, value) => {
              if (field === "shippingAddressIsBillingAddress") {
                setEnrolmentData((prevState) => ({
                  ...prevState,
                  [EnrolmentStep.BillingAddress]: {
                    ...prevState[EnrolmentStep.BillingAddress],
                    [field]: value,
                    residenceType: value === true ? "billing" : "shipping",
                  },
                  [EnrolmentStep.ShippingAddress]:
                    emptyEnrolmentData[EnrolmentStep.ShippingAddress],
                }));
              } else {
                setEnrolmentData((prevState) => ({
                  ...prevState,
                  [EnrolmentStep.BillingAddress]: {
                    ...prevState[EnrolmentStep.BillingAddress],
                    [field]: value,
                  },
                  [EnrolmentStep.UploadRequiredDocuments]:
                    field === "state"
                      ? {}
                      : { ...prevState[EnrolmentStep.UploadRequiredDocuments] },
                }));
              }
              if (
                enrolmentData[EnrolmentStep.BusinessInformation]
                  .countryOfOperation === COUNTRY.US &&
                field === "state"
              ) {
                setDisableNext(true);
                fetchDocuments(value)
                  .then((docs) => {
                    setDocuments({ list: docs, fetched: true });
                    setDisableNext(false);
                  })
                  .catch((error) => {
                    console.error(error);
                    setDisableNext(false);
                  });
              }
            }}
            onNext={(data) => {
              if (data.shippingAddressIsBillingAddress === false) {
                setEnrolmentFlowStep(EnrolmentStep.ShippingAddress);
              } else {
                if (documents.fetched === true && documents.list) {
                  setEnrolmentFlowStep(EnrolmentStep.DownloadRequiredDocuments);
                } else {
                  setEnrolmentFlowStep(EnrolmentStep.PaymentMethod);
                }
              }
            }}
            onBack={() =>
              setEnrolmentFlowStep(EnrolmentStep.BusinessInformation)
            }
            documents={documents}
            disableNextStep={disableNext}
          />
        );
      },
    },
    [EnrolmentStep.ShippingAddress]: {
      title: "Shipping Address Information",
      subtitle: "Note: We are not able to drop ship to the end customer.",
      stepperStatus: 1,
      content: () => {
        return (
          <ShippingAddressForm
            data={enrolmentData[EnrolmentStep.ShippingAddress]}
            selectedCountry={
              enrolmentData[EnrolmentStep.BusinessInformation]
                .countryOfOperation
            }
            legalBusinessName={
              enrolmentData[EnrolmentStep.BusinessInformation].legalBusinessName
            }
            onChange={(field, value) =>
              setEnrolmentData((prevState) => ({
                ...prevState,
                [EnrolmentStep.ShippingAddress]: {
                  ...prevState[EnrolmentStep.ShippingAddress],
                  [field]: value,
                },
              }))
            }
            onNext={() => {
              if (documents.fetched === true && documents.list) {
                setEnrolmentFlowStep(EnrolmentStep.DownloadRequiredDocuments);
              } else {
                setEnrolmentFlowStep(EnrolmentStep.PaymentMethod);
              }
            }}
            onBack={() => setEnrolmentFlowStep(EnrolmentStep.BillingAddress)}
            documents={documents}
          />
        );
      },
    },
    [EnrolmentStep.DownloadRequiredDocuments]: {
      title: "Download Required Documents",
      subtitle: null,
      stepperStatus: 2,
      content: () => {
        return (
          <RequiredDocumentsForm
            data={enrolmentData[EnrolmentStep.BillingAddress]}
            documents={documents.list}
            onNext={() =>
              setEnrolmentFlowStep(EnrolmentStep.UploadRequiredDocuments)
            }
            onBack={(data) =>
              data.shippingAddressIsBillingAddress === true
                ? setEnrolmentFlowStep(EnrolmentStep.BillingAddress)
                : setEnrolmentFlowStep(EnrolmentStep.ShippingAddress)
            }
          />
        );
      },
    },
    [EnrolmentStep.UploadRequiredDocuments]: {
      title: "Upload Required Documents",
      subtitle:
        "Please fill out and upload the following documents. When you submit your application, these documents will be submitted to the PRH New Accounts team. You will be able to review these documents again before submitting.",
      stepperStatus: 3,
      content: () => {
        return (
          <UploadRequiredDocumentsForm
            data={enrolmentData[EnrolmentStep.UploadRequiredDocuments]}
            documents={documents.list}
            onChange={(field, value) =>
              setEnrolmentData((prevState) => ({
                ...prevState,
                [EnrolmentStep.UploadRequiredDocuments]: {
                  ...prevState[EnrolmentStep.UploadRequiredDocuments],
                  [field]: value,
                },
              }))
            }
            onNext={() => setEnrolmentFlowStep(EnrolmentStep.PaymentMethod)}
            onBack={() =>
              setEnrolmentFlowStep(EnrolmentStep.DownloadRequiredDocuments)
            }
          />
        );
      },
    },
    [EnrolmentStep.PaymentMethod]: {
      title: "Select Payment Method",
      subtitle: null,
      stepperStatus: 4,
      content: () => {
        return (
          <SelectPaymentMethod
            data={enrolmentData}
            paymentMethods={paymentMethods_MOCK}
            onChange={(value) =>
              setEnrolmentData((prevState) => ({
                ...prevState,
                [EnrolmentStep.PaymentMethod]: value,
                [EnrolmentStep.BankAccountInformation]:
                  emptyEnrolmentData[EnrolmentStep.BankAccountInformation],
                [EnrolmentStep.TradeCreditReference]:
                  emptyEnrolmentData[EnrolmentStep.TradeCreditReference],
              }))
            }
            onNext={(data) => {
              data[EnrolmentStep.PaymentMethod] ===
              paymentMethods_MOCK.CreditCard.id
                ? setEnrolmentFlowStep(EnrolmentStep.TermsOfSale)
                : setEnrolmentFlowStep(EnrolmentStep.BankAccountInformation);
            }}
            onBack={(data) => {
              if (documents.fetched === true && documents.list) {
                setEnrolmentFlowStep(EnrolmentStep.UploadRequiredDocuments);
              } else {
                if (
                  data[EnrolmentStep.BillingAddress]
                    .shippingAddressIsBillingAddress === true
                ) {
                  setEnrolmentFlowStep(EnrolmentStep.BillingAddress);
                } else {
                  setEnrolmentFlowStep(EnrolmentStep.ShippingAddress);
                }
              }
            }}
          />
        );
      },
    },
    [EnrolmentStep.BankAccountInformation]: {
      title: "Bank Account Information",
      subtitle: null,
      stepperStatus: 4,
      content: () => {
        return (
          <BankAccountInformation
            data={enrolmentData[EnrolmentStep.BankAccountInformation]}
            onChange={(field, value) =>
              setEnrolmentData((prevState) => ({
                ...prevState,
                [EnrolmentStep.BankAccountInformation]: {
                  ...prevState[EnrolmentStep.BankAccountInformation],
                  [field]: value,
                },
              }))
            }
            onNext={() =>
              setEnrolmentFlowStep(EnrolmentStep.TradeCreditReference)
            }
            onBack={() => setEnrolmentFlowStep(EnrolmentStep.PaymentMethod)}
          />
        );
      },
    },
    [EnrolmentStep.TradeCreditReference]: {
      title: "Trade Credit Reference",
      subtitle:
        "Please provide 3 business references. We cannot accept credit card info or personal friends as references",
      stepperStatus: 4,
      content: () => {
        return (
          <TradeCreditReference
            data={enrolmentData[EnrolmentStep.TradeCreditReference]}
            onChange={(reference, field, value) =>
              setEnrolmentData((prevState) => ({
                ...prevState,
                [EnrolmentStep.TradeCreditReference]: {
                  ...prevState[EnrolmentStep.TradeCreditReference],
                  [reference]: {
                    ...prevState[EnrolmentStep.TradeCreditReference][reference],
                    [field]: value,
                  },
                },
              }))
            }
            onNext={() => setEnrolmentFlowStep(EnrolmentStep.TermsOfSale)}
            onBack={() =>
              setEnrolmentFlowStep(EnrolmentStep.BankAccountInformation)
            }
          />
        );
      },
    },
    [EnrolmentStep.TermsOfSale]: {
      title: "Review Terms Of Sale",
      subtitle: null,
      stepperStatus: 5,
      content: () => {
        return (
          <TermsOfSale
            data={enrolmentData[EnrolmentStep.PaymentMethod]}
            confirmed={confirmedTermsOfSale}
            selectedCountry={
              enrolmentData[EnrolmentStep.BusinessInformation]
                .countryOfOperation
            }
            onConfirm={() => {
              setEnrolmentData((prevState) => ({
                ...prevState,
                [EnrolmentStep.TermsOfSale]: !confirmedTermsOfSale,
              }));
              setConfirmedTermsOfSale(!confirmedTermsOfSale);
            }}
            onNext={() => setEnrolmentFlowStep(EnrolmentStep.ReviewAndSign)}
            onBack={(data) =>
              data === paymentMethods_MOCK.CreditCard.id
                ? setEnrolmentFlowStep(EnrolmentStep.PaymentMethod)
                : setEnrolmentFlowStep(EnrolmentStep.TradeCreditReference)
            }
          />
        );
      },
    },
    [EnrolmentStep.ReviewAndSign]: {
      title: "Review And Sign",
      subtitle: null,
      stepperStatus: 5,
      content: () => {
        return (
          <ReviewAndSign
            data={enrolmentData}
            recaptchaToken={recaptchaToken}
            onChangeRecaptchaToken={(token) => setRecaptchaToken(token)}
            onChange={(value) =>
              setEnrolmentData((prevState) => ({
                ...prevState,
                [EnrolmentStep.ReviewAndSign]: value,
              }))
            }
            onEdit={(step) => {
              setEnrolmentFlowStep(step);
              setEnrolmentErrorMsg(null);
            }}
            onSubmit={async (data) => {
              setSubmitting(true);
              setEnrolmentErrorMsg(null);
              const documents =
                enrolmentData[EnrolmentStep.UploadRequiredDocuments];

              Promise.all(
                Object.keys(documents).map((doc) => {
                  return props.app.registrationApi.newCustomerEnrolmentUploadFile(
                    documents[doc],
                    enrolmentToken,
                    recaptchaToken
                  );
                })
              ).then(() => {
                const dataWithStateValue = cloneDeep(data);
                dataWithStateValue[
                  EnrolmentStep.BusinessInformation
                ].countryOfOperation = mapStateCodeToStateValue(
                  dataWithStateValue[EnrolmentStep.BusinessInformation]
                    .countryOfOperation
                );
                props.app.registrationApi
                  .newCustomerEnrolment(
                    dataWithStateValue,
                    enrolmentToken,
                    recaptchaToken
                  )
                  .then((response) => {
                    setRecaptchaToken(null);
                    if (response?.data?.status === "OK") {
                      setEnrolmentFlowStep(EnrolmentStep.FormSubmitted);
                    } else {
                      setEnrolmentErrorMsg(
                        `${response?.data?.message}: ${response?.data?.error}`
                      );
                    }
                    setSubmitting(false);
                  });
              });
            }}
            onBack={() => {
              setEnrolmentFlowStep(EnrolmentStep.TermsOfSale);
              setEnrolmentErrorMsg(null);
              setRecaptchaToken(null);
            }}
            error={enrolmentErrorMsg}
            loading={submitting}
          />
        );
      },
    },
    [EnrolmentStep.FormSubmitted]: {
      title: (
        <div className="form-submitted-header mt-4">
          <img src={CESuccessImage} alt="Successful enrolment" />
          <div className="fs-24px mb-1">
            Your account application is being processed
          </div>
        </div>
      ),
      subtitle: null,
      stepperStatus: null,
      content: () => {
        return <FormSubmitted />;
      },
    },
  };

  const data = formData[enrolmentFlowStep];

  return (
    <div className="customer-enrolment">
      <PageStandard
        alignment="center"
        cartBadge={false}
        pageType="registration-path"
        containerFluid={true}
        className="mb-8"
        backgroundClass="customer-enrolment-page"
      >
        {enrolmentFlowStep === EnrolmentStep.Welcome && (
          <div className="landing-header">{data.title}</div>
        )}
        {data.stepperStatus && (
          <div className="stepper-section my-3">
            <StepperSmall steps={steps} stepperStatus={data.stepperStatus} />
          </div>
        )}
        <PRHContainer
          className={`content-block-border ${
            enrolmentFlowStep === EnrolmentStep.ReviewAndSign
              ? "review-container"
              : enrolmentFlowStep !== EnrolmentStep.Welcome &&
                "narrow-container"
          } ${enrolmentFlowStep === EnrolmentStep.FormSubmitted && `mt-3`}`}
        >
          {enrolmentFlowStep !== EnrolmentStep.Welcome && (
            <div className="header-container">
              <PRHContainerHeader>{data.title}</PRHContainerHeader>

              {data.subtitle && <p className="mb-2">{data.subtitle}</p>}
              <Divider tight className="mb-2" />
            </div>
          )}
          <Form
            encType="multipart/form-data"
            instantValidate
            width={16}
            onSubmit={(e) => {
              e.preventDefault();
              console.debug("on submit e", e);
            }}
            onKeyPress={(e) => {
              const code = e.keyCode || e.which;
              if (code === 13) {
                e.preventDefault();
              }
            }}
          >
            {data.content()}
          </Form>
        </PRHContainer>
      </PageStandard>
    </div>
  );
};

export default withApp(CustomerEnrolment);
