import { Component, PureComponent } from 'react';
import localforage from 'localforage';
import { withRouter } from 'react-router';
//import axios from "axios";
import withApp from '../components/hc/withApp';
import withAdmin from '../components/hc/withAdmin';
import withCart from '../components/hc/withCart';
import withSecurity from '../components/hc/withSecurity';
import RegistrationContext from './RegistrationContext';
//import './login.scss';

class RegistrationProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      priorStep: 1,
      passwordsMatch: null,
      createPasswordValid: 1,
      confirmPasswordValid: 1,
      passwordMatchErrorMessage: '',
      permissionsAccepted: 0,
      openPasswordValidationPopUp: false,
      passwordValidationScore: 0,
      showValidationErrors: false,
      loading: false,
      responseModal: false,
      systemResponse: '',
      stepSucceeded: null,
      showCreateSubSuccess: false,
      createSubMessage: false,
      createSubResponse: '',
      validations: {
        firstName: null,
        lastName: null,
        sapAcct: null,
      },
      createUserForm: {
        san: '',
        sapAcct: '',
        sapSAN: '',
        firstName: '',
        lastName: '',
        createPassword: '',
        confirmPassword: '',
        role: '',
        token: null,
        email: '',
        mobile: '',
        authorizedAdministrator: true,
        userName: '',
        password: '',
        displayName: '',
        description: '',
        legacyPassword: '',
        recaptchaToken: '',
      },
      createUserPost: {
        san: '',
        sapAcct: '',
        sapSAN: '',
        groups: { biz: '' },
        firstName: '',
        lastName: '',
        fullName: '',
        userName: '',
        password: '',
        email: '',
        mobile: '',
      },
      updateUser: {},
      userList: [],
      addresses: [],
      customer: {},
      shipTosDropDown: [],
      shipTos: [],
      userListLoading: true,
      manageUserModal: { open: false, header: 'Add User' },
      account: {
        accountNo: '',
        sanNo: '',
      },
    };
  }

  handleChange = (e) => {
    // const newState = { ...this.state };
    // if (e.target.name === 'authorizedAdministrator') {
    //   newState.createUserForm[e.target.name] = e.target.checked;
    // } else {
    //   newState.createUserForm[e.target.name] = e.target.value;
    // }
    this.setState({
      systemResponse: '',
      createUserForm: {
        ...this.state.createUserForm,
        [e.target.name]:
          e.target.name === 'authorizedAdministrator'
            ? e.target.checked
            : e.target.value,
      },
    });

    localforage.setItem('appState', this.state.createUserForm);

    const { createUserForm } = this.state;
    this.checkPassword(
      createUserForm.createPassword,
      createUserForm.firstName,
      createUserForm.lastName
    );
    if (e.target.name === 'createPassword') {
      if (
        createUserForm.createPassword !== '' &&
        this.checkCreatePassword() < 7
      ) {
        this.setState({ openPasswordValidationPopUp: true });
      } else {
        this.setState({ openPasswordValidationPopUp: false });
      }
    }

    if (
      e.target.name === 'confirmPassword' ||
      e.target.name === 'createPassword'
    ) {
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({ passwordsMatch: 0 });
      } else {
        this.setState({ passwordsMatch: 1 });
      }
    }
    // this.forceUpdate();
  };

  handleSubAccountChange = (e, data) => {
    const newState = { ...this.state };
    newState.createUserForm[data.name] = data.value;
    this.setState(newState);
    this.forceUpdate();
  };

  handleManageUsersChange = (name, value) => {
    const newState = { ...this.state };
    newState.createUserForm[name] = value;
    this.setState(newState);
    this.forceUpdate();
  };

  cleanForm = () => {
    const newState = { ...this.state };
    newState.systemResponse = '';
    newState.step = 1;
    this.setState(newState);
  };

  clearUserForm = () => {
    const createUserForm = {
      san: '',
      sapAcct: '',
      sapSAN: '',
      firstName: '',
      lastName: '',
      createPassword: '',
      confirmPassword: '',
      role: '',
      token: null,
      email: '',
      mobile: '',
      authorizedAdministrator: true,
      userName: '',
      password: '',
      displayName: '',
      description: '',
      legacyPassword: '',
      recaptchaToken: '',
    };
    this.setState({ createUserForm, systemResponse: '', step: 1 });
  };

  handleBlur = (e) => {
    const { createUserForm } = this.state;
    if (e.target.name === 'confirmPassword') {
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({
          passwordsMatch: 0,
          passwordMatchErrorMessage: 'passwords must match',
        });
      } else {
        this.setState({ passwordsMatch: 1, passwordMatchErrorMessage: '' });
      }
    }
    if (e.target.name === 'createPassword') {
      this.setState({ openPasswordValidationPopUp: false });
    }
  };

  handleClickCreatePassword = (e) => {
    const { createUserForm } = this.state;
    this.checkPassword(createUserForm.createPassword);
    if (this.checkCreatePassword() < 7) {
      this.setState({ openPasswordValidationPopUp: true });
    }
  };

  handleClickConfirmPassword = (e) => {
    this.setState({ passwordMatchErrorMessage: '' });
  };

  handleSubmitUser = (value) => {
    this.checkPassword();
    const {
      passwordValidationScore,
      createUserForm,
      passwordsMatch,
    } = this.state;
    if (
      passwordValidationScore === 7 &&
      passwordsMatch === 1 &&
      createUserForm.authorizedAdministrator === true
    ) {
      if (value === 'migrate') {
        this.setCreateLegacyUserPost();
      } else {
        this.setCreateUserPost();
      }
    } else {
      if (passwordValidationScore !== 7) {
        this.setState({
          openPasswordValidationPopUp: true,
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (passwordsMatch === 0) {
        this.setState({
          showValidationErrors: true,
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (createUserForm.authorizedAdministrator === false) {
        this.setState({ showValidationErrors: true });
      }
    }
  };

  loadAccountUser = (user) => {
    const newState = { ...this.state };
    //newState.createUserForm.san= user.sanNo;
    newState.createUserForm.sapAcct = user.accountNo;
    //newState.createUserForm.sapSAN= user.sanNo;
    newState.createUserForm.firstName = user.firstName;
    newState.createUserForm.lastName = user.lastName;
    newState.createUserForm.role = 'level1';
    this.setState(newState);
  };

  handleSubmitSubAccountUser = (account) => {
    this.setCreateSubUserPost(account);
    /*
    if (newState.createUserForm.role==='') {
      this.setState({ showValidationErrors: true });
    }
    */
  };

  handleUpdateUser = (value) => {
    this.checkPassword();
    const {
      passwordValidationScore,
      createUserForm,
      passwordsMatch,
    } = this.state;
    if (
      passwordValidationScore === 8 &&
      createUserForm.authorizedAdministrator === true
    ) {
      this.setUpdateSubUserPost();
    } else {
      if (passwordValidationScore !== 8) {
        this.setState({
          openPasswordValidationPopUp: true,
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (passwordsMatch === 0) {
        this.setState({
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (createUserForm.authorizedAdministrator === false) {
        this.setState({ showValidationErrors: true });
      }
    }
  };

  dismissCreateSubMessage = () => {
    const newState = { ...this.state };
    newState.responseModal = false;
    this.setState(newState);
  };

  setCreateUserPost = () => {
    this.setState((state) => {
      return {
        createUserPost: {
          recaptchaToken: state.createUserForm.recaptchaToken,
          san: state.createUserForm.san,
          sapSAN: state.createUserForm.san,
          sapAcct: state.createUserForm.sapAcct,
          token: state.createUserForm.token,
          firstName: state.createUserForm.firstName,
          lastName: state.createUserForm.lastName,
          fullName:
            state.createUserForm.firstName +
            ' ' +
            state.createUserForm.lastName,
          userName: state.createUserForm.email,
          email: state.createUserForm.email,
          mobile: state.createUserForm.mobile,
          password: state.createUserForm.createPassword,
        },
      };
    }, this.postUser);
  };

  setCreateSubUserPost = (account) => {
    const newState = { ...this.state };
    let accountNo;
    if (this.state.createUserForm.sapAcct !== '') {
      accountNo = this.state.createUserForm.sapAcct;
    } else {
      accountNo = this.state.account.accountNo;
    }
    this.setState((state) => {
      return {
        createUserPost: {
          san: this.state.account.sanNo,
          sapSAN: this.state.account.sanNo,
          sapAcct: accountNo,
          token: state.createUserForm.token,
          firstName: state.createUserForm.firstName,
          lastName: state.createUserForm.lastName,
          groups: { biz: state.createUserForm.role },
          fullName:
            state.createUserForm.firstName +
            ' ' +
            state.createUserForm.lastName,
          userName: state.createUserForm.email,
          email: state.createUserForm.email,
          mobile: state.createUserForm.mobile,
          password: 'T3mpP4ss!',
        },
      };
    }, this.postSubAccountUser);
  };

  setCreateLegacyUserPost = () => {
    const newState = { ...this.state };
    newState.createUserPost.recaptchaToken =
      newState.createUserForm.recaptchaToken;
    newState.createUserPost.token = newState.createUserForm.token;
    newState.createUserPost.firstName = newState.createUserForm.firstName;
    newState.createUserPost.lastName = newState.createUserForm.lastName;
    newState.createUserPost.fullName =
      newState.createUserForm.firstName +
      ' ' +
      newState.createUserForm.lastName;
    newState.createUserPost.role = 'W9';
    newState.createUserPost.userName = newState.createUserForm.email;
    newState.createUserPost.email = newState.createUserForm.email;
    newState.createUserPost.mobile = newState.createUserForm.mobile;
    newState.createUserPost.password = newState.createUserForm.createPassword;
    this.setState(newState);
    this.postUser('migrate');
  };

  setUpdateSubUserPost = () => {
    this.setState((state) => {
      return {
        updateUser: {
          token: state.createUserForm.token,
          password: state.createUserForm.createPassword,
        },
      };
    }, this.patchSubAccountUser);
  };

  nextStepHandler = () => {
    const { step } = this.state;
    this.setState({ step: step + 1, priorStep: step });
  };

  previousStepHandler = () => {
    // const newState = { ...this.state };
    // newState.step = newState.priorStep;
    // newState.priorStep = newState.priorStep - 1;
    // this.setState(newState);
    this.setState({
      step: this.state.priorStep,
      priorStep: this.state.priorStep - 1,
    });
  };

  setStepHandler = (e) => {
    // const newState = { ...this.state };
    // newState.priorStep = newState.step;
    // newState.step = e;
    // this.setState(newState);
    // this.forceUpdate();
    this.setState({ priorStep: this.state.step, step: e });
  };

  postUser = (value) => {
    const { createUserPost } = this.state;
    const newState = { ...this.state };
    const userPostData = {
      user: {
        email: createUserPost.email,
        firstName: createUserPost.firstName,
        lastName: createUserPost.lastName,
        fullName: createUserPost.fullName,
        groups: { biz: createUserPost.role },
        userName: createUserPost.userName,
        zipcode: createUserPost.zipcode,
        mobile: createUserPost.mobile,
      },
      password: createUserPost.password,
      legacyToken: createUserPost.token,
    };
    newState.loading = true;
    this.setState(newState);
    this.props.app.registrationApi
      .createUser(userPostData, createUserPost.recaptchaToken)
      .then((userReturn) => {
        newState.loading = false;
        if (userReturn.data.status === 'System_Error') {
          newState.systemResponse =
            'System unavailable, please try again later.';
          newState.stepFailed = true;
        } else if (userReturn.data.status === 'ReCAPTCHA_Error') {
          //dummy code begins here
          newState.step = 4;
          newState.stepFailed = false;
          newState.userReturn = userReturn.data.data;
          newState.createResponse = 'created';
          //dummy code ends here
        } else if (userReturn.data.status === 'OK') {
          newState.step = 4;
          newState.stepFailed = false;
          newState.userReturn = userReturn.data.data;
          newState.createResponse = 'created';
        } else if (userReturn.data.status === 'CONFLICT') {
          if (userReturn.data.message.includes('selfreg')) {
            newState.step = 4;
            newState.stepFailed = false;
            newState.createResponse = 'selfRegistered';
          } else {
            newState.step = 4;
            newState.stepFailed = false;
            newState.createResponse = 'registered';
          }
        } else {
          newState.systemResponse =
            'Registration failed. Please try again or contact customer service';
          newState.stepFailed = true;
        }
        this.setState(newState);
      });
  };

  async postSubAccountUser() {
    const { createUserPost } = this.state;
    const { admin } = this.props;
    const newState = { ...this.state };
    const userPostData = {
      user: {
        email: createUserPost.email,
        firstName: createUserPost.firstName,
        lastName: createUserPost.lastName,
        fullName: createUserPost.fullName,
        userName: createUserPost.userName,
        groups: createUserPost.groups,
        sapAcct: createUserPost.sapAcct,
        sapSAN: createUserPost.sapSAN,
        pid: '1234',
        san: createUserPost.sapSAN,
        zipcode: createUserPost.zipcode,
        mobile: createUserPost.mobile,
      },
      password: createUserPost.password,
    };
    //newState.loading = true;
    this.setState(newState);
    this.props.app.registrationApi
      .createSubUser(userPostData)
      .then((userReturn) => {
        //newState.loading = false;
        newState.responseModal = true;
        if (userReturn.data.status === 'System_Error') {
          newState.systemResponse =
            'System unavailable, please try again later.';
          newState.stepFailed = true;
        } else if (userReturn.data.status === 'OK') {
          newState.step = 4;
          newState.stepFailed = false;
          newState.userReturn = userReturn.data.data;
          newState.createSubMessage = true;
          newState.createResponse = 'created';
          this.getUsers(this.props.cart.cart.accountNo);
        } else if (userReturn.data.status === 'CONFLICT') {
          newState.createSubMessage = true;
          if (userReturn.data.message.includes('selfreg')) {
            newState.step = 4;
            newState.stepFailed = false;
            newState.createResponse = 'selfRegistered';
          } else {
            newState.step = 4;
            newState.stepFailed = false;
            newState.createResponse = 'registered';
          }
        } else {
          newState.systemResponse =
            'Registration failed. Please try again or contact customer service';
          newState.stepFailed = true;
        }
        this.setState(newState);
        //this.forceUpdate();
      });
  }

  updateUser = () => {
    const { updateUser } = this.state;
    const { newState } = { ...this.state };
    newState.loading = true;
    this.setState(newState);

    this.props.app.registrationApi.updateUser(updateUser).then((userReturn) => {
      newState.loading = false;
      newState.responseModal = true;
      if (userReturn.data.status === 'System_Error') {
        newState.systemResponse = 'System unavailable, please try again later.';
        newState.stepFailed = true;
      } else if (userReturn.data.status === 'OK') {
        newState.step = 4;
        newState.stepFailed = false;
        newState.userReturn = userReturn.data.data;
        newState.createSubMessage = true;
        newState.createResponse = 'created';
      } else if (userReturn.data.status === 'CONFLICT') {
        newState.createSubMessage = true;
        if (userReturn.data.message.includes('selfreg')) {
          newState.step = 4;
          newState.stepFailed = false;
          newState.createResponse = 'selfRegistered';
        } else {
          newState.step = 4;
          newState.stepFailed = false;
          newState.createResponse = 'registered';
        }
      } else {
        newState.systemResponse =
          'Registration failed. Please try again or contact customer service';
        newState.stepFailed = true;
      }
      this.setState(newState);
      this.forceUpdate();
    });
  };

  getLegacyUser = () => {
    const newState = { ...this.state };
    const postData = {
      userName: newState.createUserForm.userName,
      password: newState.createUserForm.legacyPassword,
    };
    newState.loading = true;
    this.setState(newState);
    this.props.app.registrationApi
      .getLegacyUser(postData, newState.createUserForm.recaptchaToken)
      .then((response) => {
        newState.loading = false;
        if (response.data.status === 'System_Error') {
          newState.systemResponse =
            'System unavailable, please try again later.';
        } else if (response.data.status === 'OK') {
          newState.step = 2;
          newState.createUserForm.token = response.data.data.token;
          newState.createUserForm.description = response.data.data.description
            ? response.data.data.description
            : '';
          newState.createUserForm.displayName = response.data.data.displayName
            ? response.data.data.displayName
            : '';
        } else {
          newState.step = 1;
          newState.systemResponse = response.data.message;
        }
        this.setState(newState);
      });
  };

  checkCreatePassword() {
    const { createUserForm } = this.state;
    let validationScore = 0;
    if (
      !(
        createUserForm.createPassword.length < 6 ||
        createUserForm.createPassword.length > 24
      )
    ) {
      validationScore = validationScore + 1;
    }
    if (/[a-zA-Z]/.test(createUserForm.createPassword.charAt(0))) {
      validationScore = validationScore + 1;
    }
    if (/[0-9]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[A-Z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[a-z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (!/[\"\\\^\&]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (
      !(
        createUserForm.createPassword
          .toUpperCase()
          .indexOf(createUserForm.firstName.toUpperCase()) >= 0 &&
        createUserForm.createPassword
          .toUpperCase()
          .indexOf(createUserForm.lastName.toUpperCase()) >= 0
      )
    ) {
      validationScore = validationScore + 1;
    }
    return validationScore;
  }

  checkPassword() {
    const { createUserForm } = this.state;
    let validationScore = 0;
    if (
      !(
        createUserForm.createPassword.length < 6 ||
        createUserForm.createPassword.length > 24
      )
    ) {
      validationScore = validationScore + 1;
    }
    if (/[a-zA-Z]/.test(createUserForm.createPassword.charAt(0))) {
      validationScore = validationScore + 1;
    }
    if (/[0-9]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[A-Z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (/[a-z]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (!/[\"\\\^\&]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (
      !(
        createUserForm.createPassword
          .toUpperCase()
          .indexOf(createUserForm.firstName.toUpperCase()) >= 0 &&
        createUserForm.createPassword
          .toUpperCase()
          .indexOf(createUserForm.lastName.toUpperCase()) >= 0
      )
    ) {
      validationScore = validationScore + 1;
    }
    this.setState({ passwordValidationScore: validationScore });
    return validationScore;
  }

  resendVerifyEmail = () => {
    const { createUserForm } = this.state;
    const newState = { ...this.state };
    this.props.app.registrationApi
      .resendVerifyEmail(createUserForm.email)
      .then((response) => {
        if (response.data.status === 'OK') {
          newState.createResponse = 'resent';
        } else {
          newState.createResponse = 'resent-failed';
        }
        this.setState(newState);
      });
  };

  /**
   * @todo rewrite as promise based
   * @param {*} email
   */
  resendSubVerifyEmail = (email, cb) => {
    const newState = { ...this.state };
    this.props.app.registrationApi.resendVerifyEmail(email).then((response) => {
      if (response.data.status === 'OK') {
        newState.createResponse = 'resent';
      } else {
        newState.createResponse = 'resent-failed';
      }
      this.setState(newState, () => {
        if (cb && typeof cb === 'function') {
          cb(this.state.createResponse === 'resent');
        }
      });
    });
  };

  verifyEmailSubAccount = (token, apiCall) => {
    const newState = { ...this.state };
    this.props.app.registrationApi
      .verifyEmailSubAccount(token, apiCall)
      .then((response) => {
        if (response.data.status === 'OK') {
          newState.createResponse = 'resent';
        } else {
          newState.createResponse = 'resent-failed';
        }
        this.setState(newState);
      });
  };

  sendResetPasswordLink = () => {
    const { createUserForm } = this.state;
    const newState = { ...this.state };
    this.props.app.registrationApi
      .sendResetPasswordLink(createUserForm.email)
      .then((response) => {
        newState.createResponse = 'resent';
      });
    this.setState(newState);
  };

  emptyFunc = () => {
    return true;
  };

  setLegacyUser = (description, displayName, token) => {
    const newState = { ...this.state };
    newState.step = 2;
    newState.createUserForm.description = description ? description : '';
    newState.createUserForm.displayName = displayName ? displayName : '';
    newState.createUserForm.token = token ? token : '';
    this.setState(newState);
  };

  async getUsers(accountNo) {
    const newState = { ...this.state };
    newState.account.accountNo = accountNo;
    newState.userListLoading = true;
    this.setState(newState);
    this.props.app.accountApi.getUserInfo(accountNo).then((response) => {
      newState.userListLoading = false;
      if (response.data.status === 'System_Error') {
        newState.systemResponse = 'System unavailable, please try again later.';
      } else if (response.data.status === 'OK') {
        newState.customer = response.data.data.customer;
        let addresses = [];
        addresses[response.data.data.customer.accountNo] =
          response.data.data.customer.address;
        const shipTosDefaultDropDown = {
          key: accountNo,
          value: accountNo,
          text: accountNo,
        };
        response.data.data.shipTos.forEach((shipTo, index) => {
          addresses[shipTo.accountNo] = shipTo.address;
        });
        newState.addresses = addresses;
        newState.shipTosDropDown = response.data.data.shipTos.map((shipTo) => ({
          key: shipTo.accountNo,
          value: shipTo.accountNo,
          text: shipTo.accountNo,
        }));
        newState.shipTosDropDown.splice(0, 0, shipTosDefaultDropDown);
        newState.account.sanNo = response.data.data.customer.sanNo;
        let shipToUsers = [];
        response.data.data.shipTos.forEach((shipTo) => {
          if (shipTo.webUsers && shipTo.webUsers.length > 0) {
            shipTo.webUsers.forEach((webUser) => {
              shipToUsers.push(webUser);
            });
          }
        });
        //newState.userList = [...response.data.data.customer.webUsers, ...shipToUsers];
        newState.userList = response.data.data.customer.webUsers
          .concat(shipToUsers)
          .sort((a, b) => (a.roleNo > b.roleNo ? 1 : -1));
      } else {
      }
      this.setState(newState);
      this.forceUpdate();
    });
  }

  render() {
    return (
      <RegistrationContext.Provider
        value={{
          formState: this.state,
          setLegacyUser: (description, displayName, token) => {
            return this.setLegacyUser(description, displayName, token);
          },
          resendVerifyEmail: (value) => {
            return this.resendVerifyEmail(value);
          },
          previousStepHandler: (value) => {
            return this.previousStepHandler(value);
          },
          handleSubmitUser: (value) => {
            return this.handleSubmitUser(value);
          },
          setStepHandler: (value) => {
            return this.setStepHandler(value);
          },
          getLegacyUser: () => {
            return this.getLegacyUser();
          },
          handleSubmitSubAccountUser: (value) => {
            return this.handleSubmitSubAccountUser(value);
          },
          handleChange: (e) => {
            return this.handleChange(e);
          },
          handleClickCreatePassword: (e) => {
            return this.handleClickCreatePassword(e);
          },
          handleClickConfirmPassword: (e) => {
            return this.handleClickConfirmPassword(e);
          },
          handleSubAccountChange: (e, data) => {
            return this.handleSubAccountChange(e, data);
          },
          dismissCreateSubMessage: (e) => {
            return this.dismissCreateSubMessage(e);
          },
          resendSubVerifyEmail: (e, cb) => {
            return this.resendSubVerifyEmail(e, cb);
          },
          handleManageUsersChange: (e) => {
            return this.handleManageUsersChange(e);
          },
          loadAccountUser: (e) => {
            return this.loadAccountUser(e);
          },
          getUsers: (e) => {
            return this.getUsers(e);
          },
          cleanForm: (e) => {
            return this.cleanForm(e);
          },
          clearUserForm: () => {
            return this.clearUserForm();
          },
        }}
      >
        {this.props.children}
      </RegistrationContext.Provider>
    );
  }
}
export default withAdmin(withSecurity(withApp(withCart(RegistrationProvider))));
