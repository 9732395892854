import axios from 'axios';

const commonHeaders = {
  Accept: 'application/json',
};

//  apmbypass: 'TRUE'
// withCredentials: true,

export class HttpClient {
  static request = ({
    method,
    url,
    data = {},
    headers = {},
    validateStatus,
    session = 'None',
    timeout = 0,
    opts = { timeout: 0 },
  }) => {
    //let opts = { timeout: 0 };

    if (session !== 'None' && session !== null) {
      commonHeaders['Authorization'] = `Bearer ${session}`;
    }

    const request = {
      method,
      url,
      data,
      headers: { ...commonHeaders, ...headers },
      validateStatus,
      timeout,
      opts,
    };
    return axios(request);
  };
}
