import { Children, cloneElement, Fragment, useState, useEffect } from 'react';
import { Modal, Image, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../../assets/images/myhouse-logo.svg';
import PRHButton from '../../elements/prhbutton';
import MessageGenerator from '../../utils/MessageGenerator';

const DialogModal = (props) => {
  const {
    open,
    setOpen,
    options,
    dialogOptions,
    children,
    callback,
    closeOnEscape,
    closeOnDimmerClick,
    history,
    onClose,
  } = props;

  const defaultDialogOptions = {
    header: <Image src={Logo} className="site-logo" />,
    actions: [],
  };
  const defaultModalOptions = {
    size: 'small',
  };

  const dOptions = { ...defaultDialogOptions, ...dialogOptions };
  const modalOptions = { ...defaultModalOptions, ...options };
  const [intOpen, setIntOpen] = useState(true);
  const [message, setMessage] = useState(false);
  const [pending, setPending] = useState(false);
  const [actionId, setActionId] = useState(false);
  const [proceedState, setProceedState] = useState(false);

  let hasVisibleActions = false;
  if (
    Array.isArray(dOptions.actions) &&
    dOptions.actions.length > 0 &&
    dOptions.actions.some((item) => !item.hidden)
  ) {
    hasVisibleActions = true;
  }

  // reset the state every time open changes
  useEffect(() => {
    setMessage(false);
    setProceedState(false);
    setPending(false);
  }, [open]);

  let closeIconClass =
    'd-flex align-items-center justify-content-center modal-close-icon cursor-pointer fs-2 position-absolute';
  if (pending) {
    closeIconClass += ' modal-close-icon--disabled';
  }

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    if (open) {
      setOpen(false);
    } else {
      setIntOpen(false);
      setOpen(null);
    }
  };

  const closeIcon = (
    <PRHButton
      icon="times"
      fontAwesome
      disabled={!!pending}
      onClick={() => {
        if (!pending) {
          handleClose();
        }
      }}
    />
  );

  const startDecorationRecusion = (children) =>
    Children.map(children, (child, i, array) => {
      if (!child || !Object.prototype.hasOwnProperty.call(child, 'props')) {
        return child;
      }

      if (
        Object.prototype.hasOwnProperty.call(child, 'props') &&
        Object.prototype.hasOwnProperty.call(child.props, 'actionId') &&
        child.props.actionId.constructor === String &&
        typeof dOptions.actions[child.props.actionId] !== 'undefined'
      ) {
        const {
          action = () => ({ proceed: true }),
          label,
          icon = null,
          disabled = false,
          preAction = () => ({ proceed: true }),
        } = dOptions.actions[child.props.actionId];

        const composedAction = async () => {
          setPending(true);
          setMessage(false);
          setProceedState(true);
          setActionId(child.props.actionId);
          const preActionResult = await preAction();
          const {
            proceed,
            message: msg,
            cancelAction = false,
            redirect = null,
          } = preActionResult;
          if (proceed) {
            if (redirect && history) {
              history.push(redirect);
            }
            const actionResult = await action(preActionResult);

            const { handleCloseAction = true } = actionResult || {};
            setPending(false);

            if (msg) {
              setMessage(msg);
              await new Promise((resolve) => setTimeout(resolve, 3000));
            }

            if (callback) {
              callback(!cancelAction);
            }
            if (handleCloseAction) {
              handleClose();
            }
            return;
          }
          setProceedState(false);
          setPending(false);
          setMessage(msg);
        };

        const spinner = pending &&
          !hasVisibleActions &&
          actionId === child.props.actionId && (
            <div className="modal-spinner modal-spinner--h-auto d-inline-block ml-3">
              <Loader inline size="tiny" active className="" />
            </div>
          );

        const disabledButtonState =
          Object.prototype.hasOwnProperty.call(child.props, 'disabled') &&
          !!child.props.disabled;
        return <>
          {cloneElement(child, {
            onClick: composedAction,
            disabled: pending || disabledButtonState,
          })}
          {spinner}
        </>;
      }

      if (child.props.children) {
        return cloneElement(child, {
          children: startDecorationRecusion(child.props.children),
        });
      }

      return child;
    });

  const modalOpenState = open === true || open === false ? open : intOpen;

  return (
    <Modal
      onClose={handleClose}
      open={modalOpenState}
      {...modalOptions}
      closeOnEscape={!pending && closeOnEscape}
      closeOnDimmerClick={!pending && closeOnDimmerClick}
    >
      {dOptions.header && (
        <Modal.Header className="d-flex justify-content-between px-4 py-4 position-relative text-uppercase">
          {dOptions.header}
          {closeIcon}
        </Modal.Header>
      )}
      <Modal.Content className="px-4 pt-4 pb-6">
        {!proceedState && message ? (
          <MessageGenerator message={message} />
        ) : (
          <div />
        )}
        {children && startDecorationRecusion(children)}
      </Modal.Content>
      {hasVisibleActions && (
        <Modal.Actions
          className={`d-flex justify-content-end modal-content--actions modal-content--actions-${modalOptions.size} bg-white position-relative no-border pt-2 px-4 pb-4`}
        >
          {!pending && !proceedState && (
            <>
              {dOptions.actions.map((o, i, array) => {
                if (
                  Object.prototype.hasOwnProperty.call(o, 'hidden') &&
                  o.hidden
                ) {
                  return <Fragment key={i} />;
                }
                const mr = array.length === i + 1 ? '' : 'mr-2';
                const {
                  action = () => ({ proceed: true }),
                  label,
                  icon = null,
                  disabled = false,
                  preAction = () => ({ proceed: true }),
                } = o;

                const composedAction = async () => {
                  setPending(true);
                  setMessage(false);
                  setProceedState(false);
                  const preActionResult = await preAction();
                  const {
                    proceed,
                    message: msg,
                    cancelAction = false,
                    redirect: preActionRedirect = null,
                  } = preActionResult;
                  if (proceed) {
                    if (preActionRedirect && history) {
                      history.push(preActionRedirect);
                    }
                    setProceedState(true);
                    const actionResult = await action(preActionResult);
                    const {
                      handleCloseAction = true,
                      redirect: actionRedirect = null,
                    } = actionResult;
                    if (actionRedirect && history) {
                      return history.push(actionRedirect);
                    }
                    if (msg) {
                      setMessage(msg);
                      await new Promise((resolve) => setTimeout(resolve, 3000));
                    }

                    if (callback) {
                      callback(!cancelAction);
                    }
                    if (handleCloseAction) {
                      handleClose();
                    }

                    return;
                  }
                  setProceedState(false);
                  setPending(false);
                  setMessage(msg);
                };
                return (
                  <PRHButton
                    key={i}
                    onClick={() => composedAction()}
                    icon={icon}
                    className={mr}
                    disabled={disabled}
                  >
                    {label}
                  </PRHButton>
                );
              })}
            </>
          )}
          {pending && (
            <div className="modal-spinner d-flex justify-content-center mb-0">
              <Loader
                size="small"
                active
                className="position-static border absolute-center absolute-center-offset"
              />
            </div>
          )}
          {message && proceedState && <MessageGenerator message={message} />}
        </Modal.Actions>
      )}
    </Modal>
  );
};

DialogModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  options: PropTypes.object,
  callback: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  dialogOptions: PropTypes.object.isRequired,
  closeOnDimmerClick: PropTypes.bool,
  closeOnEscape: PropTypes.bool,
  history: PropTypes.object,
  onClose: PropTypes.func,
};

DialogModal.defaultProps = {
  children: false,
  callback: () => {},
  closeOnEscape: false,
  closeOnDimmerClick: true,
  history: null,
  open: null,
  setOpen: () => {},
  options: {},
  onClose: null,
};
export default DialogModal;
