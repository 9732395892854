import { Component } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import './LoadingView.scss';

class LoadingView extends Component {
  render() {
    let msg = 'Loading...';

    if (this.props.msg) {
      msg = this.props.msg;
    }
    return (
      <div className="centered">
        <Dimmer active inverted>
          <Loader inverted></Loader>
          <div className="loading-msg">{msg}</div>
        </Dimmer>
      </div>
    );
  }
}

export default LoadingView;
