/* /src/components/auth/logout.jsx */

import { Component } from "react";
import withSecurity from "../hc/withSecurity";
import { withRouter } from "react-router";
import withCart from "../hc/withCart";
import withApp from "../hc/withApp";

class Logout extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // console.log("lgout", this.props);
    if (this.props.user.user) {
      const ut = this.props.user.isAppBiz();
      // this.props.app.accountApi.logout().then((e) => {
      this.props.cart.clearCheckout();
      this.props.user.logout();
      this.props.user.clearUser();
      //localStorage.setItem('SSO', window.location);
      if (ut) {
        window.location.href = process.env.REACT_APP_NETIQ_LOGOUT_URL;
        return;
      }
      this.props.history.push({ pathname: "/" });
      // });
    } else {
      this.props.history.push({ pathname: "/" });
    }
  }

  render() {
    return <div>Logging out . . .</div>;
  }
}
export default withRouter(withSecurity(withApp(withCart(Logout))));
