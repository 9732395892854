import moment from 'moment';

import {
  getFieldsToHideOnDigests,
  translatePost,
} from '../../../utils/wordpressHelpers';
import WordPressPostLink from './WordPressPostLink';

const PostDigestVertical = ({
  post,
  wordpressImageSize,
  currentTitle,
  className,
}) => {
  const { type, image, date, title, excerpt } = translatePost(
    post,
    wordpressImageSize
  );

  const fieldsToHide = getFieldsToHideOnDigests(type);

  return (
    <div className={`my-2 py-2 border-bottom-lg-gray-300 ${className}`}>
      {image && (
        <WordPressPostLink post={post}>
          <img className="mb-1 mw-100 w-100" src={image} alt={title} />
        </WordPressPostLink>
      )}
      <div>
        <WordPressPostLink
          post={post}
          className="fort-bold fs-14px lh-19px ls--015 text-gray-900 hover-orange d-block d-inline-block mb-1"
        >
          <span dangerouslySetInnerHTML={{ __html: title }} />
        </WordPressPostLink>
        {!fieldsToHide.includes('date') && (
          <p className="my-1 text-gray-700 fort-book fs-12px lh-16px lsp--02">
            {moment(date).format('MMMM DD, YYYY')}
          </p>
        )}
        {excerpt && (
          <p
            className="d-md-none d-lg-block fort-book fs-12px lh-16px lsp--02 text-gray-900"
            dangerouslySetInnerHTML={{ __html: excerpt }}
          />
        )}
      </div>
    </div>
  );
};

PostDigestVertical.defaultProps = {
  wordpressImageSize: '422x422',
  currentTitle: null,
  className: '',
};

export default PostDigestVertical;
