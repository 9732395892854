import { createRef, Component } from "react";
import withSecurity from "../hc/withSecurity";
import withAccount from "../hc/withAccount";
import { Form, Input } from "semantic-ui-react-form-validator";
import { Popup, Icon, Modal, Button, Loader } from "semantic-ui-react";
import {
  PRHLabel,
  PRHContainerHeader,
  PRHContainerGeneral,
  PRHContainerSubHeader,
} from "../elements/elements";
import TextValidator from "../../shared-react-components/elements/input";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import PasswordPopup from "../elements/PasswordPopup";
//import FormContainer from '../../elements/FormContainer';
import { Link } from "react-router-dom";
import "./admin.scss";
import ReCAPTCHA from "react-google-recaptcha";

class ResetPasswordPublicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyEmail: 0,
      loading: true,
      displayAccountDetailFields: false,
      currentPasswordFieldType: "password",
      createPasswordFieldType: "password",
      confirmPasswordFieldType: "password",
      createPasswordEye: "eye",
      eyeButtonShow: "",
      eyeButtonHide: "",
      passwordMatchErrorMessage: "",
      recaptchaToken: "",
      recaptchaRef: createRef(),
    };
  }

  handleRecaptchaChange = () => {
    const recaptchaToken = this.state.recaptchaRef.current.getValue();
    this.setState({ recaptchaToken });
  };

  showPassword = (currentShowHide) => {
    const newState = { ...this.state };
    if (newState[currentShowHide] === "password") {
      newState[currentShowHide] = "text";
    } else {
      newState[currentShowHide] = "password";
    }
    this.setState(newState);
  };

  submitUser = () => {
    const { account } = this.props;
    if (!!!this.state.recaptchaToken) {
      return;
    }
    account.setStateField("recaptchaToken", this.state.recaptchaToken);
    // console.log('resetPasswordPublic', this.state.recaptchaToken);
    account.resetPasswordPublic(this.state.recaptchaToken);
    //this.state.recaptchaRef.current.reset();
    //this.handleRecaptchaChange();
  };

  checkFormComplete = () => {
    const { account } = this.props;
    if (
      account.formState.createUserForm.confirmPassword === "" ||
      account.formState.createUserForm.createPassword === ""
    ) {
      return true;
    } else {
      return false;
    }
    //return true;
  };

  render() {
    const { setStepHandler, account, size } = this.props;
    const {
      currentPasswordFieldType,
      createPasswordFieldType,
      confirmPasswordFieldType,
    } = this.state;
    return (
      <>
        <Modal
          dimmer='inverted'
          size='tiny'
          className={"admin-modal"}
          centered={false}
          open={account.formState.responseModal}
        >
          <Button
            className={"admin-button"}
            onClick={(e) => account.dismissCreateSubMessage(e)}
          >
            <Icon name='remove' />
          </Button>
          <Modal.Content>
            <p>
              {account.formState.updateResponse === "OK" ? (
                <>Account has been updated.</>
              ) : (
                ""
              )}
              {account.formState.updateResponse === "NOT_FOUND" ? (
                <>Please try again or contact customer service.</>
              ) : (
                ""
              )}
            </p>
          </Modal.Content>
        </Modal>
        <Modal open={account.formState.loading}>
          <Loader inverted></Loader>
        </Modal>
        <div className={"white-background-container"}>
          <PRHContainerHeader>Reset Password</PRHContainerHeader>
          <Form
            instantValidate={false}
            width={16}
            //action="#"
            onSubmit={(e) => this.submitUser()}
          >
            <div className={"form-adjustments"}>
              <Popup
                position={size.width < 700 ? "top center" : "right center"}
                open={account.formState.openPasswordValidationPopUp}
                className={`password-popup`}
                trigger={
                  <div className={"password-field-holder"}>
                    <div className={"password-field"}>
                      <TextValidator
                        name='createPassword'
                        passwordsMatch={account.formState.passwordsMatch}
                        type={createPasswordFieldType}
                        value={account.formState.createUserForm.createPassword}
                        onChange={account.handleChange}
                        onBlur={account.handleBlur}
                        onClick={account.passwordValidationOnFocus}
                        validationCheck={account.formState.createPasswordValid}
                        validationCheckMessage='does not meet requirements'
                        label={
                          <PRHLabel className={"fort-Medium font-resize"}>
                            Create password
                          </PRHLabel>
                        }
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        data-position='right center'
                        width={16}
                        icon={
                          createPasswordFieldType === "text" ? (
                            <Icon
                              name='eye slash'
                              link
                              onClick={(e) =>
                                this.showPassword("createPasswordFieldType")
                              }
                            />
                          ) : (
                            <Icon
                              name='eye'
                              link
                              onClick={(e) =>
                                this.showPassword("createPasswordFieldType")
                              }
                            />
                          )
                        }
                      />
                    </div>
                    {account.formState.passwordsMatch === 1 &&
                    account.formState.passwordValidationScore ===
                      account.formState.createUserForm
                        .requiredPasswordValidationScore ? (
                      <div className={"password-check"}>
                        <Icon name='check' />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                }
              >
                <Popup.Content
                  className={
                    "password-requirements-popup-content white-box-text fort-light"
                  }
                >
                  <PasswordPopup
                    password={account.formState.createUserForm.createPassword}
                    currentPassword={
                      account.formState.createUserForm.currentPassword
                    }
                    firstName={account.formState.createUserForm.firstName}
                    lastName={account.formState.createUserForm.lastName}
                    action='reset'
                  />
                </Popup.Content>
              </Popup>
              <div className={"password-field-holder"}>
                <div className={"password-field"}>
                  <TextValidator
                    name='confirmPassword'
                    type={confirmPasswordFieldType}
                    passwordsMatch={account.formState.passwordsMatch}
                    value={account.formState.createUserForm.confirmPassword}
                    validationCheck={account.formState.createPasswordValid}
                    validationCheckMessage='does not meet requirements'
                    onChange={account.handleChange}
                    onBlur={account.handleBlur}
                    onClick={account.passwordValidationOnFocus}
                    label={
                      <PRHLabel className={"fort-Medium font-resize"}>
                        Confirm password
                      </PRHLabel>
                    }
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    width={16}
                    icon={
                      confirmPasswordFieldType === "text" ? (
                        <Icon
                          name='eye slash'
                          link
                          onClick={(e) =>
                            this.showPassword("confirmPasswordFieldType")
                          }
                        />
                      ) : (
                        <Icon
                          name='eye'
                          link
                          onClick={(e) =>
                            this.showPassword("confirmPasswordFieldType")
                          }
                        />
                      )
                    }
                  />
                </div>
                {account.formState.passwordsMatch === 1 &&
                account.formState.passwordValidationScore ===
                  account.formState.createUserForm
                    .requiredPasswordValidationScore ? (
                  <div className={"password-check"}>
                    <Icon name='check' />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            {account.formState.passwordsMatch === 0 &&
            account.formState.createUserForm.confirmPassword !== "" ? (
              <div className={"password-match-error-message"}>
                passwords do not match
              </div>
            ) : (
              ""
            )}
            <br />
            {account.formState.stepFailed === true ? (
              <div className={"agreement-error-message"}>
                {account.formState.systemResponse}
              </div>
            ) : (
              ""
            )}
            {account.formState.updateResponse === "NOT_FOUND" ? (
              <div className='my-2 fort-bold text-red fs-12px'>
                Unable to upate your account.
              </div>
            ) : (
              ""
            )}
            <br />
            <div className='d-flex mt-4 align-items-center justify-content-center'>
              <ReCAPTCHA
                onExpired={this.handleRecaptchaChange}
                onChange={this.handleRecaptchaChange}
                ref={this.state.recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size={"normal"}
              />
            </div>
            <br />
            <br />
            <div className={"button-holder"}>
              <PRHButton
                className={
                  "fort-light-bold float-right biz-form-button margin-left"
                }
                icon='check'
                size='large'
                displayInlineFlex
                disabled={
                  !!!this.state.recaptchaToken ||
                  (account.formState.passwordsMatch === 0 &&
                    account.formState.createUserForm.confirmPassword !== "")
                }
              >
                Update Password
              </PRHButton>
              <PRHButton
                onClick={() => account.setUpdateResponseHandler("cancel")}
                size='large'
                className={"float-right"}
              >
                Cancel
              </PRHButton>
            </div>
            <br />
            <br />
          </Form>
        </div>
      </>
    );
  }
}

export default withAccount(ResetPasswordPublicForm);
