import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Popup, Icon, Input, Select } from "semantic-ui-react";
import useCart from "../../../components/hc/useCart";
import { Divider } from "../../../components/elements/elements";
import LazyImage from "../../../utils/LazyImage";
export default function DropdownStoreSelector(props) {
  const { shipTo, selectShipTo } = props;
  const cart = useCart();
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [searchText, setSearchText] = useState(false);
  const [storeList, setStoreList] = useState([]);

  const memoisedBuildStoreList = useCallback(() => {
    let storeList = [];
    cart.customerInfo.shipToAccountNos &&
      cart.customerInfo.shipToAccountNos.forEach((shipTo, i) => {
        if (searchText && searchText !== "") {
          if (
            shipTo.address.name1
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) === -1 &&
            shipTo.address.city
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) === -1 &&
            shipTo.address.state
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) === -1 &&
            shipTo.accountNo
              .toString()
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) === -1
          )
            return;
        }
        storeList.push({
          key: i,
          value: i,
          text: (
            <div className={`${i === shipTo ? "current-select" : ""}`}>
              <div className='fort-bold'>{shipTo.address.name1}</div>
              <div>
                {shipTo.accountNo}
                <Divider horizontal />
                {shipTo.address.city},{shipTo.address.state}
              </div>
            </div>
          ),
          content: (
            <div
              className='hls-item d-flex flex-row cursor-pointer'
              onClick={(e) => {
                selectShipTo(i);
                setSelectorOpen(false);
              }}
            >
              {shipTo.accountNo} | {shipTo.address.name1} |{" "}
              {shipTo.address.city}, {shipTo.address.state}
            </div>
          ),
        });
      });
    if (
      storeList.length === 0 &&
      cart.customerInfo.shipToAccountNos.length > 1
    ) {
      storeList.push({
        key: "not-found",
        value: -1,
        text: (
          <div className={``}>
            <div>No Results Found!</div>
          </div>
        ),
        content: (
          <div className='hls-item d-flex flex-row'>No Results Found!</div>
        ),
      });
    }

    setStoreList(storeList);
  }, [cart.customerInfo.shipToAccountNos, selectShipTo, searchText]);

  useEffect(() => {
    memoisedBuildStoreList();
  }, [searchText]);

  // console.log('Dropdown', storeList);

  if (storeList.length < 1 || cart.customerInfo.shipToAccountNos.length === 1) {
    return null;
  } else {
    let colorProps = {};
    // if (selectorOpen) colorProps.color = 'white';
    return (
      <div
        className='container hl-banner location-selector'
        style={{ display: "flex", alignItems: "center" }}
      >
        <div
          id='container'
          className='ui container'
          // style={{
          //   padding: 5,
          //   height: 'auto',
          // }}
        >
          <Popup
            className='hls-popup-wrapper'
            trigger={
              <div
                className='d-flex cursor-pointer'
                onClick={(e) => setSelectorOpen(!selectorOpen)}
              >
                <Icon
                  name='map marker alternate'
                  {...colorProps}
                  className='mr-1'
                  style={{ marginLeft: "-2px" }}
                />

                <div className='d-flex flex-column w-100 text-white'>
                  <div className='d-flex flex-row w-100 '>
                    {shipTo === -1 ? (
                      <span className='fort-bold location'>
                        Select a Location
                      </span>
                    ) : (
                      <div
                        className='location'
                        // style={{
                        //   display: "flex",
                        //   flexDirection: "column",
                        // }}
                      >
                        <span className='fort-bold mr-1'>Ordering For:</span>{" "}
                        {cart.customerInfo.shipToAccountNos[shipTo].accountNo} |{" "}
                        {
                          cart.customerInfo.shipToAccountNos[shipTo].address
                            .name1
                        }{" "}
                        |{" "}
                        {
                          cart.customerInfo.shipToAccountNos[shipTo].address
                            .city
                        }
                        ,{" "}
                        {
                          cart.customerInfo.shipToAccountNos[shipTo].address
                            .state
                        }{" "}
                      </div>
                    )}
                    <Icon
                      name={`caret ${selectorOpen ? "up" : "down"}`}
                      {...colorProps}
                      className='ml-2'
                    />
                  </div>
                </div>
              </div>
            }
            position='bottom left'
            offset={[5, 5]}
            content={
              <div className='hls-popup -d-flex flex-column'>
                <Input
                  placeholder='Search for a location'
                  icon='search'
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  // iconPosition="right"
                />
                <div>
                  {storeList.map((s, i) => {
                    return <div key={i}>{s.content}</div>;
                  })}
                </div>
              </div>
            }
            on='click'
            open={selectorOpen}
            onOpen={(e) => {
              setSelectorOpen(true);
            }}
            onClose={(e) => {
              setSearchText("");
              setSelectorOpen(false);
            }}
            basic
          />
        </div>
      </div>
    );
  }
}

DropdownStoreSelector.propTypes = {
  shipTo: PropTypes.number.isRequired,
  selectShipTo: PropTypes.func.isRequired,
};

DropdownStoreSelector.defaultProps = {};
