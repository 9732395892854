import { ValidatorComponent } from 'react-form-validator-core';
import { Dropdown } from 'semantic-ui-react-form-validator';
import { PRHLabel } from '../../components/elements/elements';
import './elements.scss';

class PRHDropDown extends ValidatorComponent {
  render() {
    const {
      className,
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      labelClassName,
      label,
      icon,
      onClick,
      longTextLabel,
      onBlur,
      validationCheck,
      validationCheckMessage,
      options,
      variant=null,
      errorCustomStyle,
      ...rest
    } = this.props;
    return (
      <div className={`${this.errorStyle()}`}>
        <div className={'input-label-holder'}>
          <label className={'input-label'}>{label}</label>
        </div>
        <div className={`${this.variantStyle()}`}> 
        <div className={`${className}`}>
          <Dropdown
            {...rest}
            ref={(r) => {
              this.input = r;
            }}
            fluid
            selection
            onClick={onClick}
            onBlur={onBlur}
            options={options}
            className={`${this.errorStyle()}`}
          />
          </div>
          </div>
        {this.errorText()}
      </div>
    );
  }

  variantStyle() {
    const { variant } = this.props;
    if (variant !== null) {
      return variant;
    } else  {
      return '';
    }

  }

  errorStyle() {
    const { isValid } = this.state;
    const { validationCheck } = this.props;
    if (isValid && validationCheck !== 0) {
      return 'input-field';
    }
    return 'error-input-field';
  }

  errorText() {
    const { isValid } = this.state;
    const { validationCheck, errorCustomStyle } = this.props;
    if (isValid && validationCheck !== 0) {
      return null;
    } else {
      return <div className={`error-message ${errorCustomStyle && (errorCustomStyle)}`}>{this.getErrorMessage()}</div>;
    }
  }
}

export default PRHDropDown;
