import { Component } from 'react';
import Download from '../../../assets/images/download.svg';
//import Download from '../../assets/images/download.svg';
import { withRouter } from 'react-router-dom';
import { formatDateMMDDYYYY } from '../../../utils/utilities';
import './onSaleDate.scss';

class onSaleDate extends Component {
  render() {
    const {
      onSaleDate
    } = this.props.data;
    const {
      downloadPreDeliveryReport,
    } = this.props;
    
    return (
        <><div className="cell-padding"><b>{formatDateMMDDYYYY(onSaleDate, '/')}</b></div></>
    );
  }
}

export default withRouter(onSaleDate);
