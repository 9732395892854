import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { COMPCOPY_IDENTITY_CONFIG, IDENTITY_CONFIG } from "../utils/authConst";

const getAPIUrl = () => {
  const hostName = window.location.hostname;

  if (hostName.startsWith("biz") || hostName.startsWith("selfservice")) {
    return IDENTITY_CONFIG.base;
  }
  if (hostName.startsWith("compcopy")) {
    return COMPCOPY_IDENTITY_CONFIG.base;
  }
  return "";
};

const baseQuery = fetchBaseQuery({
  baseUrl: getAPIUrl(),
  prepareHeaders: async (headers, { getState }) => {
    const user = getState().user;
    headers.set("Authorization", `Bearer ${user.accessToken}`);
    return headers;
  },
  keepUnusedDataFor: 30,
});

export const Api = createApi({
  reducerPath: "Api",
  baseQuery: baseQuery,
  keepUnusedDataFor: 30,
  tagTypes: [
    "customer",
    "catalog",
    "title",
    "cart",
    "saveforlater",
    "estimatecarts",
    "seriesTitle",
  ],
  endpoints: () => ({}),
});
