import { Component } from "react";
import { isIE } from "react-device-detect";
import { NumericFormat } from "react-number-format";
import { withRouter } from "react-router";
import { Icon, Popup } from "semantic-ui-react";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import withDirty from "../../shared-react-components/components/hc/withDirty";
import LazyImage from "../../utils/LazyImage";
import { formatDateMMDDYYYY, isGeneralCart } from "../../utils/utilities";
import { Divider } from "../elements/elements";
import withCart from "../hc/withCart";
import withSecurity from "../hc/withSecurity";
import AddISBNModal from "../modals/AddISBNModal";
import "./CartBadge.scss";
import { isMoment } from "moment";

class CartBadge extends Component {
  constructor(props) {
    super(props);

    // console.log("CartBadge", props);

    this.state = {
      addIsbnDialogOopen: false,
      itemHieght: window.innerHeight * 0.6,
      isMobile: this.props.isMobile,
    };
    // console.log('############', this.props);
    const isMobile = this.props.isMobile;
    // console.log('IS MOBILE - ', this.isMobile)
  }

  cartContent = () => {
    // console.log("cartContent");
    const cartTotal = this.props.cart.getCartTotal();
    const itemHeight = window.innerHeight * 0.5;

    return (
      <div className='d-flex flex-column cart-drop'>
        <div className='cb-header d-flex pl-3 pr-3 align-items-center pt-2 pb-2'>
          <div className='fort-bold'>
            {this.props.cart.cart.items?.length > 0
              ? `MY CART (${this.props.cart.cart.items?.length} TITLES)`
              : "MY CART"}
          </div>
          {this.state.isMobile ? (
            ""
          ) : (
            <AddISBNModal
              className='ml-auto'
              openState={(s) => this.setState({ addIsbnDialogOopen: s })}
            />
          )}
        </div>
        <div
          className='cb-items overflow-auto border-top border-bottom bg-gray-100'
          style={{ maxHeight: `${this.state.itemHieght}px` }}
        >
          {this.renderCartItems()}
        </div>
        <div className='cb-footer d-flex flex-column p-2 pl-3 pr-3 align-items-center'>
          {this.props.user.isAppBiz() ? (
            <div className='d-flex flex-row pt-1 pb-2 w-100'>
              <span className='mr-auto fs-16pt fort-book'>Subtotal</span>
              <NumericFormat
                className='fort-bold ml-auto fs-16pt'
                value={cartTotal.subtotal}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType={"text"}
                prefix={"$"}
              />
            </div>
          ) : null}

          <PRHButton
            size='big-wide'
            bold
            borderColorOverride='blue'
            textColorOverride='white'
            textColorHoverOverride='blue'
            backgroundColorOverride='blue'
            backgroundHoverColorOverride='white'
            iconColorOverride='white'
            iconColorHoverOverride='blue'
            icon='arrow right'
            iconright={true}
            onClick={(e) => {
              // console.log('view cart', this.props);
              if (this.props.location.pathname !== "/cart") {
                this.props.cart.setCartBadgeOpen(false);
                this.props.history.push({
                  pathname: "/cart",
                });
              } else {
                this.props.cart.setCartBadgeOpen(false);
              }
            }}
            className='view-my-cart'
          >
            View My Cart
          </PRHButton>
        </div>
      </div>
    );
  };

  renderCartItems = (limit = false) => {
    // console.log('cart', this.props);
    if (this.props.cart.cart.items?.length === 0) {
      return (
        <div className='d-flex flex-column justify-content-center pl-3 pr-3 pt-4 pb-4 cb-no-items'>
          <div className='fort-bold mb-2'>You have no items in your cart.</div>
          <div>
            To add items to your cart, perform a search or use the Add ISBNs
            button above.
          </div>
        </div>
      );
    } else {
      let items = [];
      if (limit) {
        const wi = this.props.cart.getCartItemsSorted({ backorderSort: false });
        if (wi.length > 0) {
          items.push(wi[0]);
        }
      } else {
        items = this.props.cart.getCartItemsSorted({ backorderSort: false });
      }
      return (
        <>
          {items?.map((item, idx) => (
            <div
              key={idx}
              className='cb-title-item d-flex flex-row  p-2 pl-3 pr-3 fort-book'
            >
              <div>
                <LazyImage
                  // width={50}
                  // height={70}
                  // src={`http://images.penguinrandomhouse.com/cover/${item.isbn}`}
                  width={30}
                  src={`https://images.penguinrandomhouse.com/cover/${item.isbn}`}
                ></LazyImage>
              </div>
              <div className='d-flex flex-column ml-2 lh-3 w-100'>
                <div className='fort-bold'>{`${item.title}`}</div>
                <div>By {`${item.author}`}</div>
                <div>{`${item.formatName}`}</div>
                <div className='d-flex'>
                  <div>{`${item.isbn}`}</div>
                  <div className='ml-auto mr-1 fort-bold'>
                    <NumericFormat
                      value={item.discountedPriceTotal}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      prefix={"$"}
                    />
                  </div>
                </div>
                <div className='d-flex'>
                  <div>
                    {" "}
                    Qty: <span>{`${item.quantity}`}</span>
                  </div>
                  <div className='ml-auto mr-1 fort-light text-gray-500'>
                    {item.discountedPriceSingle < item.priceSingle ? (
                      <NumericFormat
                        className='text-line-through'
                        value={item.priceSingle * item.quantity}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        prefix={"$"}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    }
  };

  renderCartAdd = () => {
    // console.log("renderCartAdd");
    // let item = {};
    // const wi = this.props.cart.getCartItemsSorted(false);
    // if (wi.length > 0) {
    //   item = wi[0];
    // }
    // console.log('addtocart', this.props);
    let foc = false;
    if (
      this.props.cart.cartAddToCartItems.length > 0 &&
      this.props.cart.cartAddToCartItems[0].focStatus === "ok" &&
      !isGeneralCart(this.props.cart.cartAddToCartItems[0].foc)
    ) {
      foc = this.props.cart.cartAddToCartItems[0].foc;
    }
    return (
      <div className='d-flex flex-column cart-drop'>
        <div className='cb-header d-flex pl-3 pr-3 align-items-center pt-2 pb-2 add-to-cart'>
          <div className='fort-bold d-flex w-100 fs12-px lh-15'>
            <Icon
              className='text-black fs-20px'
              name='check'
              color='orange'
            ></Icon>
            {this.props.cart.isUserPow() ? (
              <div className='d-flex flex-column ml-1 fs-12px lh-15'>
                <div className='mr-auto fort-book'>ADDED TO CART:</div>
                {this.props.cart.customerInfo.shipToAccountNos.length ===
                1 ? null : (
                  <div className='fort-bold'>
                    Location: {this.props.cart.getShipToAccount().accountNo}
                  </div>
                )}
                {foc ? (
                  <div className='fort-bold'>
                    FOC {formatDateMMDDYYYY(foc, "/")}
                  </div>
                ) : (
                  <div className='fort-bold'>General Cart</div>
                )}
              </div>
            ) : (
              <span className='mr-auto fs-12px fort-book'>ADDED TO CART</span>
            )}

            <Icon
              className='text-gray-500 cursor-pointer fs-3 ml-auto'
              name='close'
              onClick={(e) => this.props.cart.setCartAddToCartOpen(false)}
            ></Icon>
          </div>
        </div>
        <div
          className='cb-items overflow-auto border-top border-bottom'
          style={{ maxHeight: `${this.state.itemHieght}px` }}
        >
          {this.props.cart.cartAddToCartItems.map((item, i) => (
            <div
              key={i}
              className='cb-title-item d-flex flex-row  p-2 pl-3 pr-3 fort-book'
            >
              <div style={{ paddingTop: "0.25rem" }}>
                <LazyImage
                  width={30}
                  src={`https://images.penguinrandomhouse.com/cover/${item.isbn}`}
                ></LazyImage>
              </div>
              <div className='d-flex flex-column ml-2 fs-12px lh-15 w-100'>
                <div className='fort-bold'>{`${item.title}`}</div>
                <div className='fort-book'>
                  Qty: <span>{`${item.quantity}`}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='cb-footer d-flex flex-row pt-2 pb-2 pr-2'>
          {this.props.cart.isUserPow() ? (
            <PRHButton
              size='small'
              // borderColorOverride="blue"
              // textColorOverride="white"
              // textColorHoverOverride="blue"
              // backgroundColorOverride="blue"
              // backgroundHoverColorOverride="white"
              // iconColorOverride="white"
              // iconColorHoverOverride="blue"
              // icon="arrow right"
              // iconright={true}
              onClick={(e) => {
                if (this.props.location.pathname !== "/cart") {
                  this.props.cart.setCartAddToCartOpen(false);
                  this.props.history.push("/cart-overview");
                } else {
                  this.props.cart.setCartAddToCartOpen(false);
                }
              }}
              className='view-my-cartX ml-auto'
            >
              View All Carts
            </PRHButton>
          ) : null}

          <PRHButton
            size='small'
            bold
            borderColorOverride='blue'
            textColorOverride='white'
            textColorHoverOverride='blue'
            backgroundColorOverride='blue'
            backgroundHoverColorOverride='white'
            iconColorOverride='white'
            iconColorHoverOverride='blue'
            icon='arrow right'
            iconright={true}
            onClick={(e) => {
              // console.log('view cart', this.props);
              if (this.props.location.pathname !== "/cart") {
                this.props.cart.setCartAddToCartOpen(false);
                let params = { pathname: "/cart" };
                if (this.props.cart.isUserPow()) {
                  let focDate = "2099-12-31";
                  if (foc) {
                    focDate = foc;
                  }
                  params.state = {
                    focDate: focDate,
                    shipTo: this.props.cart.getShipToAccount().accountNo,
                  };
                }
                this.props.history.push(params);
              } else {
                this.props.cart.setCartAddToCartOpen(false);
              }
            }}
            className={`view-my-cartX ${
              this.props.cart.isUserPow() ? "ml-2" : "ml-auto"
            }`}
          >
            View Cart
          </PRHButton>
        </div>
      </div>
    );
  };

  render() {
    // console.log("CartBadge", this.props);

    if (
      this.props.cart.cartAddToCartOpen &&
      this.props.cart.cartAddToCartItems.length === 0
    ) {
      return <></>;
    }
    return (
      <div
        className={`cart-badge-new cart-add-popup ${
          this.props.cart.cartAddToCartOpen || this.props.cart.cartBadgeOpen
            ? "cbn-open"
            : "cbn-close"
        }`}
      >
        {this.props.cart.cartAddToCartOpen
          ? this.renderCartAdd()
          : this.cartContent()}
        {/* <Popup
          open={
            this.props.cart.cartBadgeOpen ||
            (!!!this.props.dirtyHandler.loadingTakeover &&
              this.props.cart.cartAddToCartOpen)
          }
          // open={true}
          basic
          positionFixed={true}
          popperDependencies={[]}
          position={`${this.props.isMobile ? "top center" : "top right"}`}
          offset={[1, 0]}
          wide='very'
          className={
            this.props.cart.cartAddToCartOpen
              ? `cart-add-popup ${isIE ? "ie" : ""}`
              : `cart-popup`
          }
          popper={
            <section className='position-fixed' style={{ display: "none" }} />
          }
          content={
            this.props.cart.cartAddToCartOpen
              ? this.renderCartAdd()
              : this.cartContent()
          }
          on='click'
          trigger={<div />}
        /> */}
      </div>
    );
  }
}

export default withDirty(withRouter(withSecurity(withCart(CartBadge))));
