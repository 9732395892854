import { useEffect, useState } from "react";
import { Image } from "semantic-ui-react";
import TitleMetaRow from "../../components/common/TitleMetaRow";
import PageStandard from "../../components/elements/PageStandard";
import useApp from "../../components/hc/useApp";
import useCart from "../../components/hc/useCart";
import withDirty from "../../shared-react-components/components/hc/withDirty";
import "./Author.scss";

export const Author = withDirty((props) => {
  const Cart = useCart();
  const [authorDetails, setAuthorDetails] = useState("");
  const [authorTitleData, setAuthorTitleData] = useState([]);
  //TODO add filtering logic
  const authorId = props?.match?.params?.authorId;
  const authorImage =
    "https://images.penguinrandomhouse.com/author/" + authorId;

  const viewMore = () => {
    var dots = document.getElementById("bio_dots");
    var moreText = document.getElementById("bio_more");
    var btnText = document.getElementById("bio_myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read More";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = " Read Less";
      moreText.style.display = "inline";
    }
  };

  useEffect(() => {
    props.dirtyHandler.setLoadingTakeoverConfig({
      text: "Loading author . . .",
      longLoadText: "Still loading . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    props.dirtyHandler.setLoadingTakeover(true);
    Cart.getAuthorDetails(authorId).then((response) => {
      if (response?.data?.status === "OK") {
        props.dirtyHandler.setLoadingTakeover(false);
        const data = response?.data?.data;
        setAuthorDetails(data);
        if (data.works.length > 0) {
          const workArray = data.works.map((item) => {
            return {
              title: item.name,
              isbn: item.isbn,
              flapCopy:
                item.flapCopy != null
                  ? item.flapCopy.replace(/(<([^>]+)>)/gi, "")
                  : "",
              authors: item.authors,
            };
          });
          setAuthorTitleData(workArray);
        }
      } else {
        props.dirtyHandler.setLoadingTakeover(false);
      }
    });
  }, [authorId]);

  return (
    <PageStandard headerType='loggedIn' containerFluid={true}>
      {/* <Container> */}
      <div className='d-flex flex-column mb-2 author-block'>
        <div className='center-display '>
          {/* <Image src={authorImage} className="author-iamge" /> */}
        </div>
        <div className='fort-bold fs-28px lh-29 pb-3 center-display mt-3'>
          {authorDetails.display}
        </div>
        {authorDetails?.spotlight && authorDetails?.spotlight.length > 0 ? (
          <div className='fs-16px fort-light-book author-bio'>
            <span
              dangerouslySetInnerHTML={{
                __html: authorDetails.spotlight?.slice(0, 269),
              }}
            />
            {authorDetails?.spotlight?.length > 270 ? (
              <span>
                <span id={"bio_dots"}>...</span>
                <span
                  id={"bio_more"}
                  className='more'
                  dangerouslySetInnerHTML={{
                    __html: authorDetails.spotlight?.slice(
                      270,
                      authorDetails.spotlight?.length
                    ),
                  }}
                />
                <span
                  onClick={() => viewMore()}
                  id={"bio_myBtn"}
                  className='more-link'
                >
                  Read More
                </span>
              </span>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {authorTitleData?.length > 0 ? (
        <div className='d-flex justify-content-center flex-column'>
          <div className='fort-light-bold mb-3 fs-16px books-by'>
            Books by {authorDetails.display}
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            {authorTitleData.map((row) => (
              <TitleMetaRow
                isbn={row.isbn}
                title={row.title}
                flapCopy={row.flapCopy}
                authors={row.authors}
                key={row.isbn}
              />
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </PageStandard>
  );
});
