import { isChrome, isIE, osName } from 'react-device-detect';
import { Icon } from 'semantic-ui-react';
import { Divider } from '../../components/elements/elements';
import './AdditionalDetails.scss';

export const AdditionalDetailsBar = (props) => {
  let { tabs, currentTab } = props;

  // if (currentTab.id === undefined || currentTab.id === '') {
  //   currentTab = tabs[0];
  // }

  return (
    <div className="tad-tab-bar mb-3">
      <div className="tb-row">
        {tabs.map((tab, idx) => (
          <div key={idx}>
            {tab.id === 'divider' ? (
              <Divider key={idx} horizontal />
            ) : (
              <div
                key={idx}
                className={
                  'mr-1 tb-column ' +
                  (currentTab.id === tab.id ? ' active' : '')
                }
                onClick={(e) => props.tabChange(tab)}
              >
                <span className={'ttb-tab'}>
                  {tab.icon === 'user' ? (
                    <Icon className="fs-2" name="user" />
                  ) : (
                    <div></div>
                  )}
                  {tab.icon === 'group' ? (
                    <Icon className="fs-2" name="group" />
                  ) : (
                    <div></div>
                  )}
                  <span
                    className={
                      isIE || (osName === 'Windows' && isChrome)
                        ? 'ttb-tab-desc'
                        : ''
                    }
                  >
                    {tab.name}
                  </span>
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
