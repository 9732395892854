import { isIE } from 'react-device-detect';
import { Icon } from 'semantic-ui-react';

const IECreditCardNotification = ({ className }) => {
  return (
    <>
      {isIE ? (
        <div className={`${className} d-flex flex-row mt-2 px-3 py-3`}>
          <div className="mr-1">
            <Icon
              name="exclamation"
              className="cs-warning text-white bg-orange"
            />
          </div>
          <div className="lh-3">
            <span className="fort-bold">
              Payment with Credit Card is not supported on your browser.
            </span>
            <br />{' '}
            <span className="fort-book">
              We recommend the latest version of Safari, Firefox, Chrome or Edge
              for a full Self-Service experience.
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
};

IECreditCardNotification.defaultProps = {
  className: '',
};

export default IECreditCardNotification;
