import { Component } from "react";
import { withRouter } from "react-router";
import { Loader } from "semantic-ui-react";
import * as Sentry from "@sentry/react";
import withSecurity from "../../components/hc/withSecurity";
import withCart from "../../components/hc/withCart";
import withAccount from "../../components/hc/withAccount";
import { PRHContainer } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import PageStandard from "../../components/elements/PageStandard";
import withApp from "../../components/hc/withApp";
import Biz from "./Biz";
import CompCopy from "./CompCopy";
import HomeHero from "./HomeHero";
import { isMobileOnly } from "react-device-detect";
import WordPressNotification from "../external/partials/WordPressNotification";
import WordPressNotificationJ9 from "../external/partials/WordPressNotificationJ9";
import WordPressNotificationNonJ9 from "../external/partials/WordPressNotificationNonJ9";
import WordPressNotificationCompCopy from "../external/partials/WordPressNotificationCompCopy";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";
import CreditHoldBanner from "./frameworkComponents/CreditHoldBanner";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getProxyRequestId() {
    if (!localStorage.getItem("proxyRequestId")) {
      return "";
    }
    return localStorage.getItem("proxyRequestId");
  }

  componentDidMount() {
    Sentry.setUser({ requestid: this.getProxyRequestId() });
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // console.log(
    //   'Home CWU',
    //   nextProps.cart.ccSapAccountNo,
    //   nextProps,
    //   this.props,
    //   nextState,
    //   this.state
    // );

    if (
      nextProps.user.isAppCompCopy() &&
      nextProps.cart &&
      !nextProps.cart.ccSapAccountNo
    ) {
      this.props.history.push({ pathname: "/cclogin" });
    }

    // if (nextProps.user.user !== null && nextProps.user.user.lastName) {
    //   this.setState({
    //     user: {
    //       first: this.props.user.user.firstName,
    //       last: this.props.user.user.lastName,
    //     },
    //   });
  }

  componentWillUnmount() {
    this.props.cart.setShowNotifications("biz", false);
  }

  // this.props.user.getUser().then((user) => {
  //   console.log('this user', user);
  //   //console.log('this.props.app', this.props);
  //   //this.props.app.dummyApi.setSessionToken(user.access_token);
  //   //this.props.app.accountApi.storeAccessToken(user.access_token);

  //   this.props.app.dummyApi.getSBData().then((data) => {
  //     console.log('data call', data);
  //   });

  //   this.setState({
  //     user: {
  //       first: user.profile.given_name,
  //       last: user.profile.family_name,
  //     },
  //   });
  // });

  logout = (e) => {
    window.location.href =
      "https://idp.dev.penguinrandomhouse.com/nidp/app/logout";
  };
  render() {
    // console.log("home", this.props);
    const isMobile = isMobileOnly;
    return (
      <PageStandard
        containerFluid={true}
        jeff={true}
        alignMent='left'
        headerType='loggedIn'
        className='logged-in-homepage'
        locationSelector={true}
      >
        {!!this.props.user.isAppBiz() && <WordPressNotification />}
        {!!this.props.user.isAppBiz() && !!this.props.cart.isUserPow() && (
          <WordPressNotificationJ9 />
        )}
        {!!this.props.user.isAppBiz() && !this.props.cart.isUserPow() && (
          <WordPressNotificationNonJ9 />
        )}
        {!this.props.user.isAppBiz() && this.props.user.isAppCompCopy() && (
          <WordPressNotificationCompCopy />
        )}
        <CreditHoldBanner />
        <HomeHero
          profile={this.props.user.user.profile}
          which={!!this.props.user.isAppCompCopy() ? "compCopy" : "biz"}
        />

        {!!this.props.cart && !!this.props.user.isAppBiz() && <Biz />}
        {!!this.props.cart &&
          this.props.user.isAppCompCopy() &&
          !!this.props.cart.ccSapAccountNo && (
            <CompCopy
              ccSapAccountNo={this.props.cart.ccSapAccountNo}
              cart={this.props.cart}
            />
          )}
        {isMobile && <BackToTopButtonMobile />}
      </PageStandard>
    );
  }
}

export default withRouter(withSecurity(withApp(withCart(withAccount(Home)))));
