import propTypes from "prop-types";
import { Accordion, Form, Icon, List, Popup, Menu } from "semantic-ui-react";
import { TOOLTIPS } from "../../utils/const";
import { PRHActiveSelection } from "../../components/elements/elements";
import { isArray } from "underscore";
import { cloneDeep } from "lodash";

const CustomFilter = (props) => {
  let optionsList = null;
  const {
    options,
    colName,
    updateResults,
    activeInd,
    index,
    name,
    handleClick,
  } = props;

  let activeFilterCheck = false;
  let totalCount = 0;
  for (let i = 0; i < options.length; i += 1) {
    if (options[i].checked === true) {
      activeFilterCheck = true;
      totalCount++;
    }
  }

  const getCatalogDisplayTitle = (displayDate) => {
    const dateArray = displayDate.split("-");
    if (isArray(dateArray) && dateArray.length === 3) {
      // const d = new Date();
      // d.setMonth(dateArray[1] - 1);
      const month = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      // d.setMonth(dateArray[1] - 1);
      // const monthName = d.toLocaleString('default', { month: 'long' });
      return month[parseInt(dateArray[1]) - 1] + " " + dateArray[0];
    }
  };

  const getFOCDisplayTitle = (displayDate) => {
    const dateArray = displayDate.split("-");
    if (isArray(dateArray) && dateArray.length === 3) {
      return dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
    }
  };

  CustomFilter.propTypes = {
    options: propTypes.array.isRequired,
    colName: propTypes.string.isRequired,
    updateResults: propTypes.func.isRequired,
    activeInd: propTypes.number.isRequired,
    index: propTypes.number.isRequired,
    handleClick: propTypes.func.isRequired,
    name: propTypes.string.isRequired,
  };

  let accordianTitle = "";

  if (activeFilterCheck === true) {
    accordianTitle = (
      <span className='filterHeaderActive'>
        <PRHActiveSelection /> {name}
        {" ("}
        {totalCount}
        {")"}
      </span>
    );
  } else {
    accordianTitle = name;
  }

  let OptionsData = [];
  if (colName === "catalog") {
    const OptionsData = cloneDeep(options).map((item) => {
      item["display"] = item["display"].includes("-")
        ? getCatalogDisplayTitle(item["display"])
        : item["display"];
      return item;
    });
  } else if (colName === "foc") {
    const OptionsData = cloneDeep(options).map((item) => {
      item["display"] = item["display"].includes("-")
        ? getFOCDisplayTitle(item["display"])
        : item["display"];
      return item;
    });
  } else {
    OptionsData = options;
  }

  optionsList = (
    <List.Item className='filter-item'>
      {options.map((filterOption) => (
        <div key={Math.random()} className='flex mb-2'>
          <Form.Checkbox
            label={filterOption.display}
            name={colName}
            value={filterOption.match}
            checked={filterOption.checked}
            onClick={updateResults}
            key={filterOption.match + 1}
            className='filter-checkbox inline-flex'
          />
          {filterOption.match === "newrelease" ||
          filterOption.match === "backlist" ||
          filterOption.match === "instock" ? (
            <Popup
              className='metachart-popup  mt-2 mb-1'
              position='top center'
              offset={[0, 5]}
              trigger={
                <Icon
                  className='cursor-pointer inline-flex ml-2'
                  name='question circle outline'
                />
              }
              content={TOOLTIPS[filterOption.match]}
            ></Popup>
          ) : (
            <></>
          )}
        </div>
      ))}
    </List.Item>
  );

  return (
    <Menu.Item>
      <Accordion.Title
        active={activeInd === index}
        content={accordianTitle}
        index={index}
        onClick={handleClick}
      />
      <Accordion.Content active={activeInd === index} content={optionsList} />
    </Menu.Item>
  );
};

export default CustomFilter;
