export const CONFIG = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  versionNumber: "0.1.1",
};

export const CONFIG_APP = {
  versionNumber: "Version 0.1.1",
};

export const DAMAGE_TYPES = {
  /*
  AB: "Audio Tape broken",
  AD: "Audio Tape/CD damaged",
  AL: "Audio Duplicate Tape/CD in package",
  AM: "Audio Tape/CD missing",
  AP: "Audio Tape/CD does not play",
  AS: "Audio CD scratched",
  AX: "Audio Tape/CD Box/Binder Damaged",
  */
  AD: "Audio damage",
  B: "Bent Corners",
  BC: "Product Sliced by Cutting Tool",
  C: "Carrier Damage in Transit",
  //CD: "Carton Damage",
  CS: "Carton Split",
  DB: "Dirty Book",
  DG: "Damaged Garment",
  FC: "Full Carton Damage",
  IG: "Issue with Graphic",
  J: "Jacket Damage",
  NP: "Not Enough Packing",
  //O: "Other",
  P: "Pages Cut",
  //PC: "Paperback Book Cover",
  PD: "Production Printer Defect",
  PS: "Production Printer Shortage",
  PT: "Pages Torn or Folded",
  S: "Spine Dent",
  SH: "Shortage",
  SL: "Size Label Error",
  //SW: "Shelf Worn Book",
  //WR: "Wrong title",
};

export const TOOLTIPS = {
  instock: "Only show items that are available and stocked",
  newrelease:
    "Only show items that have a future on-sale date or went on-sale within the last 3 months",
  backlist: "Only show items that have been on-sale for more than 90 days",
  percentOff: "Base price discount",
  estimatedTotal:
    "The estimated subtotal reflects the total price of your order before promo discounts, final base discounts, shipping, and tax, if applicable, are calculated.",
  purchaseOrder:
    "This is for your reference. The default number is based on the current day and time, but may be overwritten with any alphanumeric value. Note: If your order is for an event, please append “AA” to your entry.",
  invoiceDescription:
    "This is for your reference and will appear on your Order and Invoice Details under 'Special Instructions.' Note: Contact Customer Service for specific order requests.",
  ccSecurityCode:
    "The Security Code / CVV is a 3- digit number generally printed by the signature line on the back of credit cards. On American Express cards, it’s a 4-digit number on the front of the card above the account number.",
  unprocessedItems:
    "These items are Not Yet Published, Backordered, or Open. They have not been sent yet to the warehouse for processing.",
  reportWrongTitles:
    "Books for which you received a credit memo or books you received in error (which were not included in your original billing) are the property of Penguin Random House, Inc and must be destroyed or donated to your local school or library.",
  prhAccNumber:
    "PRH Payer Account Numbers are provided upon new business registration and contain up to 10 digits",
  sanNumber:
    "SANs contain 7 digits, are case sensitive, and are Bowker registered or previously assigned by PRH",
  sapNumber:
    "SAP Account Numbers are uniquely assigned to each department and contain up to 10 digits.",
  checkoutPurchaseOrder:
    "This is for your reference. The default number is based on the current day and time but may be overwritten with any alphanumeric value. Note: It may be convenient to include the last name of the person you’re shipping to.",
  checkoutInvoiceDescription:
    "This is for your reference. The content will appear on your Order Details under 'Special Instructions.' Note: Contact Customer Service for specific order requests.",
  billOfLading:
    "These are delivery instructions for the carrier. International orders must include the recipient's name and phone number.",
  cartonMarking: "These notes will appear on the shipping label.",
  generalCart:
    "Items in this cart do not have an upcoming FOC date and may be ordered at any time.",
  variantOrderRestrictions:
    "To add this variant to your cart, you must have the required number of qualifying titles in your cart or other accounts’ carts.",
  statementDetailInvoiceDue:
    "The total amount of any current and overdue invoices on your account for this statement period",
  statementDetailAccountDue:
    "The total amount of all open items on your account, regardless of their due dates, when this statement was created.",
};

export const TABLET_SCREEN_WIDTH = 991;
export const MOBILE_SCREEN_WIDTH = 576;
export const MOBILE_SMALL_SCREEN_WIDTH = 360;
