import { Component } from 'react';
import localforage from 'localforage';
//import axios from "axios";
import withSecurity from '../../components/hc/withSecurity';
import withApp from '../../components/hc/withApp';
import withAccount from '../../components/hc/withAccount';
import PageStandard from '../../components/elements/PageStandard';
import RegistrationSubAccountForm1 from '../../components/registration/RegistrationSubAccountForm1';
import RegistrationCancel from '../../components/elements/RegistrationCancel';
import UpdateResponse from '../../components/registration/UpdateResponse';
import { PRHContainerGeneral } from '../../components/elements/elements';
import { CONFIG } from '../../utils/const';
import './registration.scss';
//import './login.scss';

class RegisterSubAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      priorStep: 1,
      allowCreate: 1,
    };
  }

  render() {
    const { step } = this.state;
    const { account } = this.props;
    // console.log('registration sub account account', account);
    return (
      <>
      <div className={'registration'}>
        <PageStandard jeff={true} alignment="center">
          <UpdateResponse formState={this.state} />
        </PageStandard>
        </div>
      </>
    );
  }
}

export default withApp(withAccount(RegisterSubAccount));
