import { toast, cssTransition } from "react-toastify";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { isFirefox } from "react-device-detect";
import PRHButton from "../elements/prhbutton";

const ToastGenerator = (
  {
    history,
    text,
    textClassName,
    button,
    toastIcon = null,
    toastIconStyle = "",
  },
  config = {}
) => {
  const Slide = cssTransition({
    enter: "slideInDown",
    exit: "slideOutUp",
    duration: 300,
  });
  let realButton = <></>;

  if (button) {
    const realButtonOptions = {
      iconright: null,
      icon: null,
      text: null,
      link: null,
      ...button,
    };
    const {
      iconright,
      text: buttonText,
      icon,
      link,
      fontAwesome = false,
    } = realButtonOptions;
    realButton = (
      <PRHButton
        onClick={() => {
          if (history) {
            history.push(link);
          }
        }}
        className='mr-2'
        icon={icon}
        fontAwesome={fontAwesome}
        iconright={iconright}
      >
        {buttonText}
      </PRHButton>
    );
  }

  const msg = (
    <>
      <p className={`my-0 toast-title mr-2`}>
        {toastIcon && (
          <Icon className='list-icon' style={toastIconStyle} name={toastIcon} />
        )}{" "}
        <span className={textClassName}>{text}</span>
      </p>
      {button && realButton}
    </>
  );

  const className = ["toast"];
  if (isFirefox) {
    className.push("firefox");
  }

  const defaultConfig = {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    transition: Slide,
    className: className.join(" "),
    bodyClassName: "toast--message",
    draggable: false,
    //autoClose: 1500,
    //autoClose: 200,
  };
  const toastConfig = { ...defaultConfig, ...config };
  // console.log("toastgen", toastConfig);
  toast(msg, toastConfig);
};

ToastGenerator.propTypes = {
  text: PropTypes.object.isRequired,
  textClassName: PropTypes.string,
  button: PropTypes.object,
  history: PropTypes.object,
};

ToastGenerator.defaultProps = {
  history: null,
  button: null,
  textClassName: "",
};

export default ToastGenerator;
