import { createRef, Component } from 'react';
import withSecurity from '../hc/withSecurity';
import withAccount from '../hc/withAccount';
import { withRouter } from 'react-router';
import { Icon } from 'semantic-ui-react';
import { Form, Input } from 'semantic-ui-react-form-validator';
import TextValidator from '../../shared-react-components/elements/input';
import { PRHContainerHeader, PRHContainer } from '../../shared-react-components/elements/elements';
import PRHButton from '../../shared-react-components/elements/prhbutton';
//import { PRHLabel, PRHContainer, PRHContainerHeader, PRHIcon } from '../../shared-react-components/elements/elements';
import { Link } from 'react-router-dom';
import './migrate.scss';
import { faTemperatureHigh } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';

class UpdateLegacyFormStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaToken: '',
      recaptchaRef: createRef(),
    };
  }

  onCancel = (e) => {
    e.preventDefault();
    // console.log('Cancel');
  };

  handleRecaptchaChange = () => {
    const recaptchaToken = this.state.recaptchaRef.current.getValue();
    this.setState({ recaptchaToken });
  };

  isValidForm = () => {
    if (this.props.account.formState.createUserForm.userName === '')
      return true;
    if (this.props.account.formState.createUserForm.legacyPassword === '')
      return true;
    return false;
  };

  render() {
    const { account, setStepHandler, history } = this.props;
    return (
      <>
        <PRHContainer className="content-block-border mt-4">
          <PRHContainerHeader>
            Enter your BIZ username and password
          </PRHContainerHeader>
          <br />
          <br />
          <div className={'white-box-text fort-book'}>
            We’ve made some site enhancements that require updates to your
            account before you can access it. First, please enter your login
            information to verify your account.
          </div>
          <br />
          <Form
            instantValidate={false}
            onSubmit={(e) => {
              if (this.isValidForm() || !this.state.recaptchaToken) {
                return;
              }
              account.setStateField(
                'recaptchaToken',
                this.state.recaptchaToken
              );
              account.getLegacyUser(e);
            }}
            width={16}
          >
            <TextValidator
              id="userName"
              name="userName"
              type="text"
              label={<label>User Name</label>}
              onChange={this.props.account.handleChange}
              value={this.props.account.formState.createUserForm.userName}
              validators={['required']}
              errorMessages={['this field is required']}
              width={16}
            />
            <TextValidator
              name="legacyPassword"
              type="password"
              label={<label>Password</label>}
              onChange={this.props.account.handleChange}
              validators={['required']}
              errorMessages={['this field is required']}
              value={this.props.account.formState.createUserForm.legacyPassword}
              width={16}
            />
            <Link to="/recover">Forgot your username or password?</Link>
            <br />
            {account.formState.systemResponse !== '' ? (
              <div className={'agreement-error-message'}>
                {account.formState.systemResponse}
              </div>
            ) : (
              ''
            )}
            <div className="d-flex mt-4 align-items-center justify-content-center">
              <ReCAPTCHA
                onExpired={this.handleRecaptchaChange}
                onChange={this.handleRecaptchaChange}
                ref={this.state.recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size={'normal'}
              />
            </div>
            <br />
            <br />
            <div className={'d-flex flex-row Xbutton-holder'}>
              <PRHButton
                className={'mr-auto'}
                size="large"
                type="button"
                //displayInlineFlex
                onClick={() => {
                  // console.log('back to home');
                  account.clearStateField();
                  history.push('/');
                }}
              >
                Back to Homepage
              </PRHButton>
              <PRHButton
                className={'float-right margin-left'}
                iconright={true}
                icon="arrow right"
                size="large"
                type="submit"
                disabled={!!!this.state.recaptchaToken}
                borderColorOverride="blue"
                textColorOverride="white"
                textColorHoverOverride="blue"
                backgroundColorOverride="blue"
                backgroundHoverColorOverride="white"
                iconColorOverride="white"
                iconColorHoverOverride="blue"
                //displayInlineFlex
              >
                Next
              </PRHButton>
            </div>
          </Form>
        </PRHContainer>
      </>
    );
  }
}

export default withSecurity(withRouter(withAccount(UpdateLegacyFormStep1)));
