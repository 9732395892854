import { Component, PureComponent } from "react";
import AuthService from "../services/authService";
import AuthServiceContext from "./AuthSericeContext";

class AuthServiceProvider extends PureComponent {
  constructor(props) {
    super(props);
    // console.log("Initializing AuthService");
    this.authService = new AuthService({});
  }

  render() {
    return (
      <AuthServiceContext.Provider
        value={{
          authService: this.authService,
          isAuthenticated: () => {
            return this.authService.isAuthenticated();
          },
        }}
      >
        {this.props.children}
      </AuthServiceContext.Provider>
    );
  }
}

export default AuthServiceProvider;
