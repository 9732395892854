import { useState, useEffect } from "react";
import {
  Header,
  Dropdown,
  Grid,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import { SeriesHistory } from "../series/SeriesHistory";
import TitleRow from "../../components/common/TitleRow";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./VariantRestrictionsInfoModal.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import { TOOLTIPS } from "../../utils/const";
import useApp from "../hc/useApp";
import useCart from "../hc/useCart";
import LazyImage from "../../utils/LazyImage";
import "./SeriesHistoryModal.scss";

const SeriesHistoryModal = ({
  open,
  setOpen,
  getSeriesHistoryTitles,
  sendToBlank,
  seriesOrderHistory,
  titleRow,
  addAll = false,
  titleRowCopy,
  parentQty,
  setParentQty,
}) => {
  const app = useApp();
  const cart = useCart();
  //const [openModal, setOpenModal] = useState(false);
  const [restrictions, setRestrictions] = useState({});
  const [selectedShipTo, setSelectedShipTo] = useState(null);
  const [restrictionsLoaded, setRestrictionsLoaded] = useState(false);
  const [seriesHistoryTitles, setSeriesHistoryTitles] = useState([]);
  let realTrigger = (
    <div className='d-flex flex-column fort-book-italic fs-13px lh-18 hover-underline text-blue cursor-pointer align-self-end'>
      <div
        onClick={(e) => {
          setOpen(true);
        }}
        className='v-details'
      >
        View ordering restrictions
      </div>
    </div>
  );
  useEffect(() => {
    /*
    if (open) { 
      getSeriesHistoryTitles();
    }*/
  }, [open]);

  useEffect(() => {
    if (cart.isUserPow()) {
      if (cart.shipTo >= 0) {
        setSelectedShipTo(cart.getShipToAccount().accountNo);
      } else {
        setSelectedShipTo("all");
      }
    }
  }, [cart.getShipToAccount().accountNo]);

  let options = [
    {
      key: "all",
      value: "all",
      header: <div>All locations</div>,
      text: <div>All locations'</div>,
      content: <div>All locations</div>,
    },
  ];
  cart.customerInfo.shipToAccountNos.map((f) => {
    options.push({
      key: f.accountNo,
      value: f.accountNo,
      text: f.accountNo,
      content: <div>{f.accountNo}</div>,
    });
    // }
  });
  return (
    // console.log('this is the modal', titleRow, titleRow.addAll),
    <div id='series-catalog-modal'>
      <DialogModal
        open={open}
        setOpen={setOpen}
        size='large'
        modalClassName='vri-modal'
        modalContentClass='series-modal-content'
        //modalContentClass="m-0 pt-0"
        dialogOptions={{
          header: (
            <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
              Series Order History
            </Header>
          ),
          //actions: [okAction],
        }}
        options={
          {
            //trigger: realTrigger,
          }
        }
      >
        {console.log("series history modal", parentQty)}
        <div className='series-history-title-row-container'>
          <TitleRow
            data={titleRow.data}
            isSeriesHistoryModal={true}
            pageType='catalog'
            divider={false}
            key={`valid-accord-title-${titleRow.data.isbn}`}
            callback={titleRow.reloadCatalog}
            addAll={addAll}
            defaultQty={titleRow.addMultipleTitles}
            cartShipTo={titleRow.data.cartShipTo}
            updateTotalQty={titleRow.updateTotalQty}
            clearAddAllQty={titleRow.clearAddAllQty}
            resetAllQty={titleRow.resetAllQty}
            variantsNotMetTitles={titleRow.data.variantsNotMetTitles}
            parentQty={parentQty}
            setParentQty={setParentQty}
          />
        </div>
        <Divider tight className='mt-2 mb-2' />
        <div id='series-history-grid'>
          <div id='series-history-modal-subheader'>
            <div
              className='fort fs12-px lh-15 mb-1'
              style={{ fontWeight: "700" }}
            >
              Series Order History
            </div>
            <div
              className='tad-oh-shipto-container'
              style={{ marginTop: "-7px" }}
            >
              {!cart.isUserPow() && (
                <Dropdown
                  options={options}
                  onChange={(_e, { value }) => {
                    setSelectedShipTo(value);
                  }}
                  value={selectedShipTo}
                  // item={true}
                  // selection
                  className={""}
                  trigger={
                    <div>
                      {selectedShipTo === "all"
                        ? "All locations"
                        : selectedShipTo}
                    </div>
                  }
                />
              )}
            </div>
          </div>
          {seriesOrderHistory.length !== 0 ? (
            <SeriesHistory
              //data={seriesOrderHistory}
              data={
                selectedShipTo === null || seriesOrderHistory === false
                  ? seriesOrderHistory
                  : seriesOrderHistory.filter(
                      (oh) => oh.shipTo === selectedShipTo
                    ).length > 0
                  ? seriesOrderHistory.filter(
                      (oh) => oh.shipTo === selectedShipTo
                    )
                  : false
              }
              isCatalog={true}
              sendToBlank={sendToBlank}
              panelOpen={true}
            />
          ) : (
            <Loader
              style={{
                marginTop: "40px",
                paddingBottom: "10px",
                marginBottom: "50px",
              }}
              size='small'
              active
              inline='centered'
            />
          )}
        </div>
      </DialogModal>
    </div>
  );
};

SeriesHistoryModal.propTypes = {
  //isbn: PropTypes.string.isRequired,
  //searchClass: PropTypes.string.isRequired,
  //country: PropTypes.string.isRequired,
};

SeriesHistoryModal.defaultProps = {};

export default SeriesHistoryModal;
