import { createRef, Component } from "react";
import withAccount from "../hc/withAccount";
import TextValidator from "../../shared-react-components/elements/input";
import { IDENTITY_CONFIG } from "../../utils/authConst";
import { Popup, Label, Icon } from "semantic-ui-react";
import { Input, Form } from "semantic-ui-react-form-validator";
import {
  PRHLabel,
  PRHContainer,
} from "../../shared-react-components/elements/elements";
import { Divider } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import PasswordPopup from "../../components/elements/PasswordPopup";
import "./registration.scss";
import ReCAPTCHA from "react-google-recaptcha";
import EventTrigger from "../analytics/EventTrigger";

class RegistrationStep2Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createPasswordFieldType: "password",
      confirmPasswordFieldType: "password",
      createPasswordEye: "eye",
      eyeButtonShow: "",
      eyeButtonHide: "",
      passwordMatchErrorMessage: "",
      recaptchaToken: "",
      recaptchaRef: createRef(),
    };
  }

  handleRecaptchaChange = () => {
    const recaptchaToken = this.state.recaptchaRef.current.getValue();
    this.setState({ recaptchaToken });
  };

  showPassword = (currentShowHide) => {
    const newState = { ...this.state };
    if (newState[currentShowHide] === "password") {
      newState[currentShowHide] = "text";
    } else {
      newState[currentShowHide] = "password";
    }
    this.setState(newState);
  };

  isValidForm = () => {
    const { account } = this.props;

    if (!this.state.recaptchaToken) return true;
    if (account.formState.createUserForm.firstName === "") return true;
    if (account.formState.createUserForm.lastName === "") return true;
    if (account.formState.createUserForm.email === "") return true;
    if (
      account.formState.passwordsMatch !== 1 ||
      account.formState.passwordValidationScore < 7
    )
      return true;
    if (account.formState.createUserForm.firstName === "") return true;
    if (!account.formState.createUserForm.authorizedAdministrator) return true;

    return false;
  };

  render() {
    // console.log('Render Form2', this.props, this.state);
    const {
      // handleChange,
      // previousStepHandler,
      // formState,
      // nextStepHandler,
      // handleSubmitUser,
      account,
      size,
    } = this.props;
    const { createPasswordFieldType, confirmPasswordFieldType } = this.state;
    return (
      <>
        <PRHContainer className='content-block-border mt-4'>
          <div className={"white-box-header fort-light"}>
            Complete Your Profile
          </div>
          <div className={"white-box-text fort-book"}>
            Please confirm your account information and enter your details.
          </div>
          <Divider />
          <div className='d-flex flex-column mb-2'>
            <div className='text-uppercase fort-bold'>account information</div>
            <div className='reg-account-info flex-column'>
              <div>
                PRH Account Number: {account.formState.createUserForm.sapAcct}
              </div>
              <div>SAN: {account.formState.createUserForm.san}</div>
            </div>
          </div>
          <Form
            instantValidate={false}
            width={16}
            //action="#"
            onSubmit={(e) => {
              if (!!!this.state.recaptchaToken) {
                return;
              }
              account.setStateField(
                "recaptchaToken",
                this.state.recaptchaToken
              );
              account.handleSubmitUser("create", this.state.recaptchaToken);
              this.state.recaptchaRef.current.reset();
              this.handleRecaptchaChange();
            }}
          >
            <TextValidator
              name='firstName'
              type='text'
              maxLength='17'
              value={account.formState.createUserForm.firstName}
              onChange={account.handleChange}
              label={
                <PRHLabel className={"fort-Medium font-resize"}>
                  First Name
                </PRHLabel>
              }
              validators={["maxStringLength:17", "required"]}
              errorMessages={[
                "maximum number of chars is 17",
                "this field is required",
              ]}
              width={16}
            />
            <TextValidator
              name='lastName'
              type='text'
              maxLength='17'
              value={account.formState.createUserForm.lastName}
              onChange={account.handleChange}
              label={
                <PRHLabel className={"fort-Medium font-resize"}>
                  Last Name
                </PRHLabel>
              }
              validators={["maxStringLength:17", "required"]}
              errorMessages={[
                "maximum number of chars is 17",
                "this field is required",
              ]}
              width={16}
            />
            <TextValidator
              name='email'
              type='text'
              value={account.formState.createUserForm.email}
              onChange={account.handleChange}
              label={
                <PRHLabel className={"fort-Medium font-resize"}>
                  Email address
                </PRHLabel>
              }
              validators={["required", "isEmail"]}
              errorMessages={[
                "this field is required",
                "field must be an email",
              ]}
              width={16}
            />
            <Popup
              position={size.width < 700 ? "below center" : "right center"}
              open={account.formState.openPasswordValidationPopUp}
              className={`password-popup`}
              trigger={
                <div className={"password-field-holder"}>
                  <div className={"password-field"}>
                    <TextValidator
                      name='createPassword'
                      passwordsmatch={account.formState.passwordsMatch}
                      type={createPasswordFieldType}
                      inputvalue={
                        account.formState.createUserForm.confirmPassword
                      }
                      value={account.formState.createUserForm.createPassword}
                      onFocus={account.handleClickCreatePassword}
                      onChange={account.handleChange}
                      validationCheck={account.formState.createPasswordValid}
                      errorMessages={["this field is required"]}
                      label={
                        <PRHLabel className={""}>Create password</PRHLabel>
                      }
                      validators={["required"]}
                      // errorMessages={["this field is required"]}
                      data-position='right center'
                      onBlur={account.handleBlur}
                      onClick={account.handleClickCreatePassword}
                      width={16}
                      icon={
                        createPasswordFieldType === "text" ? (
                          <Icon
                            name='eye slash'
                            link
                            onClick={(e) =>
                              this.showPassword("createPasswordFieldType")
                            }
                          />
                        ) : (
                          <Icon
                            name='eye'
                            link
                            onClick={(e) =>
                              this.showPassword("createPasswordFieldType")
                            }
                          />
                        )
                      }
                    />
                  </div>
                  {account.formState.passwordsMatch === 1 &&
                  account.formState.passwordValidationScore ===
                    account.formState.createUserForm
                      .requiredPasswordValidationScore ? (
                    <div className={"password-check"}>
                      <Icon name='check' />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              }
            >
              <Popup.Content
                className={
                  "password-requirements-popup-content white-box-text fort-book"
                }
              >
                <PasswordPopup
                  action='registration'
                  password={account.formState.createUserForm.createPassword}
                  firstName={account.formState.createUserForm.firstName}
                  lastName={account.formState.createUserForm.lastName}
                />
              </Popup.Content>
            </Popup>
            {/*formState.passwordsMatch===0 ? (<Label className={'error-label'} pointing='below'>Passwords must match.</Label>):''*/}
            <div className={"password-field-holder"}>
              <div className={"password-field"}>
                <TextValidator
                  name='confirmPassword'
                  type={confirmPasswordFieldType}
                  passwordsmatch={account.formState.passwordsMatch}
                  inputvalue={account.formState.createUserForm.confirmPassword}
                  validationCheck={account.formState.createPasswordValid}
                  value={account.formState.createUserForm.confirmPassword}
                  //validationCheckMessage="does not meet requirements"
                  onChange={account.handleChange}
                  onBlur={account.handleBlur}
                  onClick={account.passwordValidationOnFocus}
                  label={<PRHLabel className={""}>Confirm password</PRHLabel>}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                  width={16}
                  icon={
                    confirmPasswordFieldType === "text" ? (
                      <Icon
                        name='eye slash'
                        link
                        onClick={(e) =>
                          this.showPassword("confirmPasswordFieldType")
                        }
                      />
                    ) : (
                      <Icon
                        name='eye'
                        link
                        onClick={(e) =>
                          this.showPassword("confirmPasswordFieldType")
                        }
                      />
                    )
                  }
                />
              </div>
              {account.formState.passwordsMatch === 1 &&
              account.formState.passwordValidationScore ===
                account.formState.createUserForm
                  .requiredPasswordValidationScore ? (
                <div className={"password-check"}>
                  <Icon name='check' />
                </div>
              ) : (
                ""
              )}
            </div>
            {account.formState.passwordsMatch === 0 &&
            account.formState.createUserForm.confirmPassword !== "" ? (
              <div className={"password-match-error-message"}>
                your passwords do not match
              </div>
            ) : (
              ""
            )}
            <div className='d-flex mt-3 align-items-center justify-content-center'>
              <ReCAPTCHA
                onExpired={this.handleRecaptchaChange}
                onChange={this.handleRecaptchaChange}
                ref={this.state.recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                size={"normal"}
              />
            </div>
            <div className={"white-box-text fort-book spacer-top-1"}>
              <div>
                <TextValidator
                  type='checkbox'
                  name='authorizedAdministrator'
                  //validators={['required']}
                  //errorMessages={['Agreement required.']}
                  className={"checkbox-container"}
                  checked={
                    account.formState.createUserForm.authorizedAdministrator
                  }
                  //defaultChecked={formState.createUserForm.authorizedAdministrator}
                  longTextLabel='I hereby represent and warrant that I am the authorized Administrator for
            this account. I hereby indemnify and hold harmless Penguin Random House
            from any and all claims alleging, arising from or based upon a breach or
            alleged breach of this warranty. I will immediately notify Penguin Random
            House if I am no longer the authorized Administrator.'
                  onChange={account.handleChange}
                />
                {account.formState.showValidationErrors &&
                account.formState.createUserForm.authorizedAdministrator ===
                  false ? (
                  <div className={"agreement-error-message"}>
                    Agreement required.
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <br />
            <br />
            <div className={"button-holder"}>
              <PRHButton
                onClick={() => account.setStepHandler(1)}
                className={"fort-light-bold biz-form-button"}
                displayInlineFlex
                size='large'
                icon='arrow left'
              >
                Back
              </PRHButton>
              <PRHButton
                iconright
                icon='arrow right'
                size='large'
                className={"float-right margin-left"}
                type='submit'
                disabled={
                  !!!this.state.recaptchaToken ||
                  (account.formState.passwordsMatch === 0 &&
                    account.formState.createUserForm.confirmPassword !== "")
                }
                //disabled={this.isValidForm()}
                onClick={EventTrigger("Button", "Registration create new user")}
                borderColorOverride='blue'
                textColorOverride='white'
                textColorHoverOverride='blue'
                backgroundColorOverride='blue'
                backgroundHoverColorOverride='white'
                iconColorOverride='white'
                iconColorHoverOverride='blue'
              >
                Submit Profile
              </PRHButton>
            </div>
            <br />
            <br />
          </Form>
        </PRHContainer>
        <div>
          <div className='d-flex justify-content-center mb-4'>
            <div
              className='text-blue cursor-pointer'
              onClick={(e) => {
                account.setStepHandler(3);
              }}
            >
              Cancel Registration
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withAccount(RegistrationStep2Form);
