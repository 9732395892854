import { PRHLabel } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import TextValidator from "../../shared-react-components/elements/input";
import "./customerenrolment.scss";
import "react-datepicker/dist/react-datepicker.css";
import { validateEmail, validateNumbers } from "./helpers";

const TradeCreditReference = ({ data, onChange, onNext, onBack }) => {
  const { ref1, ref2, ref3 } = data;

  const validateFields =
    validateNumbers(ref1.accountNumber) &&
    validateNumbers(ref2.accountNumber) &&
    validateNumbers(ref3.accountNumber) &&
    validateEmail(ref1.email) &&
    validateEmail(ref2.email) &&
    validateEmail(ref3.email);

  const disableNext =
    !ref1.name ||
    !ref1.accountNumber ||
    !ref1.phone ||
    !ref1.email ||
    !ref2.name ||
    !ref2.accountNumber ||
    !ref2.phone ||
    !ref2.email ||
    !ref3.name ||
    !ref3.accountNumber ||
    !ref3.phone ||
    !ref3.email ||
    !validateFields;

  return (
    <>
      <div className="bold-label my-2">CREDIT REFERENCE #1</div>
      <TextValidator
        id="ref1name"
        name="ref1name"
        className="form-field"
        label={<PRHLabel className="input-label">Reference Name</PRHLabel>}
        type="text"
        onChange={(e) => onChange("ref1", "name", e.target.value)}
        value={ref1.name}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="ref1AccountNumber"
        name="ref1AccountNumber"
        className="form-field"
        label={
          <PRHLabel className="input-label">Reference Account Number</PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("ref1", "accountNumber", e.target.value)}
        value={ref1.accountNumber}
        validators={[
          "required",
          "isNumber",
          "minStringLength: 6",
          "maxStringLength: 17",
        ]}
        errorMessages={[
          "This field is required",
          "This field must be a number",
          "Min 6 digits",
          "Min 17 digits",
        ]}
        width={16}
      />

      <TextValidator
        id="ref1phone"
        name="ref1phone"
        className="form-field"
        label={<PRHLabel className="input-label">Reference Phone</PRHLabel>}
        type="text"
        onChange={(e) => onChange("ref1", "phone", e.target.value)}
        value={ref1.phone}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="ref1email"
        name="ref1email"
        className="form-field"
        label={<PRHLabel className="input-label">Reference Email</PRHLabel>}
        type="text"
        onChange={(e) => onChange("ref1", "email", e.target.value)}
        value={ref1.email}
        validators={["required", "isEmail"]}
        errorMessages={["This field is required", "This field is email"]}
        width={16}
      />

      <div className="bold-label my-2">CREDIT REFERENCE #2</div>
      <TextValidator
        id="ref2name"
        name="ref2name"
        className="form-field"
        label={<PRHLabel className="input-label">Reference Name</PRHLabel>}
        type="text"
        onChange={(e) => onChange("ref2", "name", e.target.value)}
        value={ref2.name}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="ref2AccountNumber"
        name="ref2AccountNumber"
        className="form-field"
        label={
          <PRHLabel className="input-label">Reference Account Number</PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("ref2", "accountNumber", e.target.value)}
        value={ref2.accountNumber}
        validators={[
          "required",
          "isNumber",
          "minStringLength: 6",
          "maxStringLength: 17",
        ]}
        errorMessages={[
          "This field is required",
          "This field must be a number",
          "Min 6 digits",
          "Min 17 digits",
        ]}
        width={16}
      />

      <TextValidator
        id="ref2phone"
        name="ref2phone"
        className="form-field"
        label={<PRHLabel className="input-label">Reference Phone</PRHLabel>}
        type="text"
        onChange={(e) => onChange("ref2", "phone", e.target.value)}
        value={ref2.phone}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="ref2email"
        name="ref2email"
        className="form-field"
        label={<PRHLabel className="input-label">Reference Email</PRHLabel>}
        type="text"
        onChange={(e) => onChange("ref2", "email", e.target.value)}
        value={ref2.email}
        validators={["required", "isEmail"]}
        errorMessages={["This field is required", "This field is email"]}
        width={16}
      />

      <div className="bold-label my-2">CREDIT REFERENCE #3</div>
      <TextValidator
        id="ref3name"
        name="ref3name"
        className="form-field"
        label={<PRHLabel className="input-label">Reference Name</PRHLabel>}
        type="text"
        onChange={(e) => onChange("ref3", "name", e.target.value)}
        value={ref3.name}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="ref3AccountNumber"
        name="ref3AccountNumber"
        className="form-field"
        label={
          <PRHLabel className="input-label">Reference Account Number</PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("ref3", "accountNumber", e.target.value)}
        value={ref3.accountNumber}
        validators={[
          "required",
          "isNumber",
          "minStringLength: 6",
          "maxStringLength: 17",
        ]}
        errorMessages={[
          "This field is required",
          "This field must be a number",
          "Min 6 digits",
          "Min 17 digits",
        ]}
        width={16}
      />

      <TextValidator
        id="ref3phone"
        name="ref3phone"
        className="form-field"
        label={<PRHLabel className="input-label">Reference Phone</PRHLabel>}
        type="text"
        onChange={(e) => onChange("ref3", "phone", e.target.value)}
        value={ref3.phone}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="ref3email"
        name="ref3email"
        className="form-field"
        label={<PRHLabel className="input-label">Reference Email</PRHLabel>}
        type="text"
        onChange={(e) => onChange("ref3", "email", e.target.value)}
        value={ref3.email}
        validators={["required", "isEmail"]}
        errorMessages={["This field is required", "This field is email"]}
        width={16}
      />

      <div className="d-flex flex-row Xbutton-holder my-5">
        <PRHButton
          size="large"
          iconleft
          icon="arrow left"
          className="mr-auto"
          onClick={onBack}
        >
          Back
        </PRHButton>
        <PRHButton
          disabled={disableNext}
          iconright
          icon="arrow right"
          size="large"
          className="ml-auto"
          type="submit"
          onClick={onNext}
        >
          Terms Of Sale
        </PRHButton>
      </div>
      <div className="d-flex flex-row my-5 fs-12px">
        Note that all fields on this page are required to proceed.
      </div>
    </>
  );
};

export default TradeCreditReference;
