import moment from 'moment';
import { Component } from 'react';
import DatePicker from 'react-datepicker';
// import moment from 'moment-business-days';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import { Checkbox, Grid, Icon, Input, Popup } from 'semantic-ui-react';
import {
  Divider,
  PRHError,
  PRHInput,
} from '../../../components/elements/elements';
import withCart from '../../../components/hc/withCart';
import DialogModal from '../../../components/modals/DialogModal';
import { PRHContainer } from '../../../shared-react-components/elements/elements';
import { TOOLTIPS } from '../../../utils/const';
import './OrderDetail.scss';

class OrderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDialog: false,
    };
  }
  addWorkDays = (startDate, days) => {
    var isAddingDays = days > 0;
    var isDaysToAddMoreThanWeek = days > 5 || days < -5;

    if (isNaN(days)) {
      // console.log('Value provided for "days" was not a number');
      return;
    }
    if (!(startDate instanceof Date)) {
      // console.log('Value provided for "startDate" was not a Date object');
      return;
    }
    var dow = startDate.getDay();
    var daysToAdd = parseInt(days);

    if ((dow === 0 && isAddingDays) || (dow === 6 && !isAddingDays)) {
      daysToAdd = daysToAdd + 1 * (isAddingDays ? 1 : -1);
    } else if ((dow === 6 && isAddingDays) || (dow === 0 && !isAddingDays)) {
      daysToAdd = daysToAdd + 2 * (isAddingDays ? 1 : -1);
    }

    if (isDaysToAddMoreThanWeek) {
      daysToAdd = daysToAdd + 2 * Math.floor(days / 5);

      if (days % 5 != 0) daysToAdd = daysToAdd + 2 * (isAddingDays ? -1 : 1);
    }

    startDate.setDate(startDate.getDate() + daysToAdd);
    var newDate = moment(startDate).format('MM/DD/YYYY');
    return newDate;
  };

  setConfirmDialogOpen = (o) => {
    this.setState({ confirmDialog: o });
  };

  removePromo = () => {
    this.props.cart.deletePromoCode(this.props.cart.cart.promoCode);
    this.props.setValue('orderForEvent', true);
    this.setState({ confirmDialog: false });
  };

  render() {
    // console.log('OrderDetail render', this.props);
    const {
      purchaseorder,
      initialPurchaseOrder,
      phone,
      invoiceText,
      orderForEvent,
      orderEventDate,
      orderEventFullName,
      orderEventPhone,
      promoCode,
      setStateValue,
    } = this.props;

    const cd = new Date(this.addWorkDays(new Date(), 10));
    const regex = /[^a-zA-Z0-9 ]/g;

    return (
      <PRHContainer className="cp-left content-block-border w-100 m-0 pl-0 pr-0 mr-6 mb-3 pt-3">
        <DialogModal
          open={this.state.confirmDialog}
          setOpen={this.setConfirmDialogOpen}
          options={{ size: 'mini' }}
          dialogOptions={{
            header: 'Promo Codes and Author Events',
            actions: [
              {
                label: 'Cancel',
                className: 'ml-auto',
                // color: 'red',
                // icon: 'times',
                preAction: () => ({ proceed: true }),
                action: () => ({ proceed: true }),
              },
              {
                label: 'Remove Promo',
                borderColorOverride: 'blue',
                textColorOverride: 'white',
                textColorHoverOverride: 'blue',
                backgroundColorOverride: 'blue',
                backgroundHoverColorOverride: 'white',
                iconColorOverride: 'white',
                iconColorHoverOverride: 'blue',
                // color: 'green',
                // icon: 'checkmark',
                preAction: () => {
                  // this.discardChanges();
                  return { proceed: true };
                },

                action: () => {
                  this.removePromo();
                  return { proceed: true };
                },
              },
            ],
          }}
        >
          <div className="modal-content--heading mb-2">
            You cannot use a Promo Code during checkout with an author event.
            Please contact customer service after ordering if you'd like to
            apply a Promo Code to an author event order.
          </div>
        </DialogModal>
        <div className="d-flex ml-3 mr-3 align-items-center mb-1 co-step-header ">
          <div className="d-flex fort-bold mb-2 fs-2 align-items-center">
            <span className="cp-section-number fs-3">2</span>
            <span className="fs-2">ORDER DETAILS</span>
          </div>
        </div>
        <Divider tight />

        <Grid container className="pl-3 pr-3 pt-2">
          <Grid.Row className="">
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={8}
              largeScreen={5}
              widescreen={5}
              className="mb-2 order-left-fields" //"keep-padding mb-2"
            >
              <div className="mb-1 fort-medium">
                Purchase Order{' '}
                <Popup
                  className="cp-help-tooltip"
                  content={TOOLTIPS['purchaseOrder']}
                  on="click"
                  position="top center"
                  offset={[0, 5]}
                  trigger={
                    <Icon
                      name="question circle outline"
                      className="sb_question_icon"
                    />
                  }
                />
              </div>
              <div>
                <PRHInput
                  value={purchaseorder}
                  className="w-100 order-detail-input"
                  onChange={(e) => {
                    if (e.target.value.replace(regex, '').trim().length < 18) {
                      this.props.setStateValue(
                        'purchaseOrderNumber',
                        e.target.value.replace(regex, '').trim()
                      );
                    }
                  }}
                  // style={{ width: '95%' }}
                  placeholder="Enter a name for order"
                  isError={!purchaseorder}
                  errorMessage="Purchase order number required"
                  icon={
                    <Icon
                      as={() => (
                        <div
                          className="revert-text-container"
                          onClick={() =>
                            this.props.setStateValue(
                              'purchaseOrderNumber',
                              initialPurchaseOrder
                            )
                          }
                        >
                          {initialPurchaseOrder !== purchaseorder
                            ? 'Revert'
                            : ''}
                        </div>
                      )}
                    />
                  }
                  // validators={['required']}
                  // errorMessages={['this field is required']}
                ></PRHInput>
              </div>
            </Grid.Column>

            <Grid.Column
              mobile={16}
              tablet={16}
              computer={8}
              largeScreen={6}
              widescreen={6}
              className="keep-padding mb-2 invoice-desc"
            >
              <div className="mb-1 fort-medium">
                Invoice Description{' '}
                <span className="od-optional fort-book-italic">(Optional)</span>{' '}
                <Popup
                  className="cp-help-tooltip"
                  content={TOOLTIPS['invoiceDescription']}
                  on="click"
                  position="top center"
                  offset={[0, 5]}
                  trigger={
                    <Icon
                      name="question circle outline"
                      className="sb_question_icon"
                    />
                  }
                />
              </div>
              <div>
                <Input
                  value={invoiceText}
                  className="w-100"
                  onChange={(e) =>
                    this.props.setValue('invoiceText', e.target.value)
                  }
                  // style={{ width: '95%' }}
                  placeholder="Enter a description for the order"
                ></Input>
              </div>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={8}
              largeScreen={5}
              widescreen={5}
              className="mb-2 order-left-fields"
            >
              <div className="mb-1 fort-medium">
                Phone Number{' '}
                <span className="od-optional fort-book-italic">(Optional)</span>
              </div>
              <div className="ui input d-block">
                <PhoneInput
                  placeholder="(###) ###-####"
                  className="w-100"
                  // defaultCountry="US"
                  // style={{ width: 'unset' }}
                  defaultCountry="US"
                  value={phone}
                  // style={{ width: '95%' }}
                  onChange={(e) => {
                    // console.log('input phone', e);
                    this.props.setValue('phone', e);
                  }}
                  error={
                    phone
                      ? isValidPhoneNumber(phone)
                        ? 'good undefined'
                        : 'Invalid phone number'
                      : 'Phone number required'
                  }
                />
              </div>
              <PRHError
                isError={phone && phone !== '' && !isValidPhoneNumber(phone)}
                errorMessage="Please enter a valid phone number"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="d-flex flex-row mt-3 ml-3 mr-3">
          <Checkbox
            checked={orderForEvent}
            onChange={(e, d) => {
              // console.log('event check', d, this.props);
              if (d.checked === false) {
                this.props.setValue({
                  orderEventDate: '',
                  orderEventFullName: '',
                  orderEventPhone: '',
                  orderForEvent: d.checked,
                });
              } else {
                let upd = { orderForEvent: d.checked };
                if (!this.props.cart.cart.promoCode) this.props.setValue(upd);
                else this.setState({ confirmDialog: true });
              }
            }}
          ></Checkbox>
          <div className="ml-1">This order is for an event</div>
        </div>
        {orderForEvent ? (
          <div className="d-flex flex-column ml-3 mr-3">
            {promoCode ? (
              <div>
                <div className="fort-book mt-1">
                  Note: Promotional codes cannot be applied on event orders.
                  Please contact Customer Service if there's a promotional code
                  you'd like to use on the event order.
                </div>
                <Divider className="mt-2" tight />
              </div>
            ) : null}
            <Grid container className="mt-3">
              <Grid.Row className="">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={8}
                  largeScreen={5}
                  widescreen={5}
                  className="keep-padding mb-2"
                >
                  <div className="mb-1 cp-event-date-header fort-medium">
                    Event Date
                  </div>
                  <DatePicker
                    minDate={cd}
                    className="cp-event-date"
                    strictParsing
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                    selected={orderEventDate}
                    onChange={(e) => {
                      this.props.setValue('orderEventDate', e);
                      // console.log('datepicker', e);
                    }}
                  />
                  <PRHError
                    isError={orderEventDate === ''}
                    errorMessage="Event date is required"
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={8}
                  largeScreen={6}
                  widescreen={6}
                  className="keep-padding mb-2"
                >
                  <div className="mb-1 fort-medium">
                    Coordinator's Full Name
                  </div>
                  <PRHInput
                    value={orderEventFullName}
                    className="w-100"
                    onChange={(e) =>
                      this.props.setValue('orderEventFullName', e.target.value)
                    }
                    // style={{ width: '95%' }}
                    placeholder="Enter Full Name"
                    isError={!orderEventFullName}
                    errorMessage="Full name is required"
                    // validators={['required']}
                    // errorMessages={['this field is required']}
                  ></PRHInput>
                  {/* <Input
                  id="eventFullName"
                  name="eventFullName"
                  label={<div className="mb-1">Contibutors's Full Name</div>}
                  type="text"
                  onChange={(e) => {
                    this.props.setValue('orderEventFullName', e.target.value);
                  }}
                  placeholder="Enter first and last name"
                  value={orderEventFullName}
                  // validators={['required']}
                  // errorMessages={['this field is required']}
                  width={12}
                /> */}
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={8}
                  largeScreen={5}
                  widescreen={5}
                  className="keep-padding mb-2"
                >
                  <div className="mb-1 fort-medium">Coordinator's Phone #</div>
                  <div className="ui input w-100">
                    <PhoneInput
                      placeholder="(###) ###-####"
                      // defaultCountry="US"
                      className=""
                      defaultCountry="US"
                      value={orderEventPhone}
                      onChange={(e) => {
                        // console.log('input phone', e);
                        this.props.setValue('orderEventPhone', e);
                      }}
                      // error={
                      //   orderEventPhone
                      //     ? isValidPhoneNumber(orderEventPhone)
                      //       ? 'good undefined'
                      //       : 'Invalid phone number'
                      //     : 'Phone number required'
                      // }
                      // inputComponent={PRHInput}
                      // inputprops={{ value: phone }}
                    />
                  </div>
                  <PRHError
                    isError={orderEventPhone === ''}
                    errorMessage="Please enter a valid phone number"
                  />
                  {/* <Input
                  id="eventPhone"
                  name="eventPhone"
                  label={<div className="mb-1">Contibutors's Phone #</div>}
                  type="text"
                  onChange={(e) => {
                    this.props.setValue('orderEventPhone', e.target.value);
                  }}
                  value={orderEventPhone}
                  validators={[
                    'required',
                    'isPhone',
                    // 'matchRegexp:^([0-9][0-9][0-9]) [0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]$',
                  ]}
                  errorMessages={[
                    'this field is required',
                    'Format (000) 000-0000',
                  ]}
                  placeholder="(000) 000-0000"
                  width={12}
                /> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        ) : (
          <div></div>
        )}
      </PRHContainer>
    );
  }
}

export default withCart(OrderDetail);
