import React from "react";
import { useHistory } from "react-router-dom";
import "./BackordersMobileTable.scss";
import { Grid } from "semantic-ui-react";
import LazyImage from "../../utils/LazyImage";

const BackordersMobileTable = ({ data, noDataMessage }) => {
  const history = useHistory();
  return (
    <div className='mobile-table'>
      <Grid container className='my-2'>
        {data && data.length > 0 ? (
          data.map((d, index) => {
            return (
              <Grid.Row key={d.salesOrderNumber + `_` + index}>
                <Grid.Column
                  mobile={5}
                  tablet={5}
                  computer={1}
                  largeScreen={1}
                  widescreen={1}
                >
                  <div className='coverImageDiv mobile mt-0 mb-2'>
                    <LazyImage
                      className='title-cover'
                      isSmallThumbNail={true}
                      boxShadow={true}
                      src={`https://images.penguinrandomhouse.com/cover/${d.isbn}`}
                    />
                  </div>
                </Grid.Column>
                <Grid.Column
                  mobile={11}
                  tablet={11}
                  computer={11}
                  largeScreen={11}
                  widescreen={11}
                >
                  <Grid>
                    <Grid.Row>
                      <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={10}
                        largeScreen={12}
                        widescreen={12}
                      >
                        <a
                          className='boldLabel text-gray-900'
                          href={`/titleinfo/${d.isbn}`}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(`/titleinfo/${d.isbn}`);
                          }}
                        >
                          {d.title}
                        </a>
                        {d.author != null ? (
                          <div className='rowItem title-subtitle-blk'>
                            {d.author.match("^Written") || d.author.match("^By")
                              ? ""
                              : "By "}
                            {d.author}
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={10}
                        largeScreen={12}
                        widescreen={12}
                      >
                        {/* <a
                        href={`/titleinfo/${d.isbn}`}
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/titleinfo/${d.isbn}`);
                        }}
                      > */}
                        {d.isbn}
                        {/* </a> */}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column
                        mobile={16}
                        tablet={16}
                        computer={16}
                        largeScreen={16}
                        widescreen={16}
                      >
                        <div className='d-flex flex-row'>
                          <div className='fort-book w-50 text-uppercase'>
                            Sales Order{" "}
                          </div>
                          <div className='fort-book w-50 d-flex justify-content-end'>
                            <a
                              href={`/orders/${d.salesOrderNumber}`}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(`/orders/${d.salesOrderNumber}`);
                              }}
                            >
                              {d.salesOrderNumber}
                            </a>
                          </div>
                        </div>
                        <div className='d-flex flex-row'>
                          <div className='fort-book w-50 text-uppercase'>
                            Purchase Order{" "}
                          </div>
                          <div className='fort-book w-50 d-flex justify-content-end'>
                            {d.poNumber}
                          </div>
                        </div>
                        <div className='d-flex flex-row'>
                          <div className='fort-book w-50 text-uppercase'>
                            Created On{" "}
                          </div>
                          <div className='fort-book w-50 d-flex justify-content-end'>
                            {d.dateCreated}
                          </div>
                        </div>
                        <div className='d-flex flex-row'>
                          <div className='fort-book w-50 text-uppercase'>
                            Account{" "}
                          </div>
                          <div className='fort-book w-50 d-flex justify-content-end'>
                            {d.account}
                          </div>
                        </div>
                        <div className='d-flex flex-row'>
                          <div className='fort-book w-50 text-uppercase'>
                            Qty{" "}
                          </div>
                          <div className='fort-book w-50 d-flex justify-content-end'>
                            {d.quantity}
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            );
          })
        ) : (
          <Grid.Row>{noDataMessage}</Grid.Row>
        )}
      </Grid>
    </div>
  );
};

BackordersMobileTable.defaultProps = {
  columns: [],
  data: [],
};

export default BackordersMobileTable;
