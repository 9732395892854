import { useState, useEffect } from 'react';
import { formatDateMMDDYYYY, isFocCart } from '../../utils/utilities';
import DialogModal from './DialogModal';
import CanceledConfirmedWarningModal from './CanceledConfirmedWarningModal';

const RemoveCartModal = (props) => {
  const {
    open,
    setOpen,
    callback,
    callbackRemove,
    cancelCallback,
    itemCount = 0,
    removeAllItems = false,
    foc,
    confirmationStatus,
    clearCartAction,
  } = props;
  const [cancelConfirmedWarningOpen, SetCancelConfirmedWarningOpen] =
    useState(false);
  return (
    <>
      <CanceledConfirmedWarningModal
        open={cancelConfirmedWarningOpen}
        setOpen={(e) =>
          SetCancelConfirmedWarningOpen(!cancelConfirmedWarningOpen)
        }
        foc={foc}
        callback={callback}
        callbackRemove={callbackRemove}
      />
      <DialogModal
        setOpen={setOpen}
        open={open}
        contentPt="pt2"
        dialogOptions={{
          header: removeAllItems ? 'Clear Cart' : 'Remove Items from cart',
          actions: [
            {
              hidden: false,
              label: 'Cancel',
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                if (cancelCallback) cancelCallback();
                return { proceed: true };
              },
            },
            {
              hidden: false,
              label: 'Yes, Continue',
              textColorOverride: 'white',
              backgroundColorOverride: 'blue',
              borderColorOverride: 'blue',
              textColorHoverOverride: 'blue',
              backgroundHoverColorOverride: 'white',
              borderHoverColorOverride: 'blue',
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                if (
                  removeAllItems &&
                  (confirmationStatus === 'CONFIRMED' ||
                    confirmationStatus === 'PENDING_UPDATE')
                ) {
                  SetCancelConfirmedWarningOpen(true);
                } else {
                  if (callback) callback();
                }
                return { proceed: true };
              },
            },
          ],
        }}
        options={{ className: 'tm-modal-container' }}
      >
        <div className="Xparagraph-2">
          {confirmationStatus === 'CONFIRMED' ||
          confirmationStatus === 'PENDING_UPDATE' ? (
            <div className="">
              Are you sure you want to {removeAllItems ? `clear all` : `remove`}{' '}
              {itemCount} items from your <br />
              <span className="fort-bold">
                FOC {formatDateMMDDYYYY(foc, '/')}
              </span>{' '}
              cart?
            </div>
          ) : isFocCart(foc) && confirmationStatus === 'NONE' ? (
            <div className="">
              Are you sure you want to {removeAllItems ? `clear all` : `remove`}{' '}
              {itemCount} items from your <br />
              <span className="fort-bold">
                FOC {formatDateMMDDYYYY(foc, '/')}
              </span>{' '}
              cart?
            </div>
          ) : (
            <div className="">
              Are you sure you want to {removeAllItems ? `clear all` : `remove`}{' '}
              {itemCount} items from your cart?
            </div>
          )}
        </div>
        {isFocCart(foc) &&
          confirmationStatus === 'NONE' &&
          (clearCartAction || removeAllItems) && (
            <div className="mt-2">
              <span className="fort-bold">Note:</span> If you continue, this
              cart will be removed.
            </div>
          )}
        {(confirmationStatus === 'CONFIRMED' ||
          confirmationStatus === 'PENDING_UPDATE') &&
          (clearCartAction || removeAllItems) && (
            <div className="mt-2">
              <span className="fort-bold">Note:</span> Your cart will be updated
              to have 0 items.
            </div>
          )}
      </DialogModal>
    </>
  );
};

export default RemoveCartModal;
