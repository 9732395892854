import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import PRHButton from '../../../shared-react-components/elements/prhbutton';
import DialogModal from '../../../components/modals/DialogModal';
import {
  formatDate,
  formatDateMMDDYYYY,
  isGeneralCart,
} from '../../../utils/utilities';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_SCREEN_WIDTH } from '../../../utils/const';

ExpiredFocBanner.propTypes = {};

function ExpiredFocBanner(props) {
  const {
    clearCart,
    moveCart,
    foc,
    items,
    className,
    containerClassName,
    containerStyle,
    removeCarts,
    moveFocToGeneral,
  } = props;
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmMoveDialog, setConfirmMoveDialog] = useState(false);

  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });

  const setConfirmDialogOpen = (o) => {
    setConfirmDialog(o);
  };

  const setConfirmMoveDialogOpen = (o) => {
    setConfirmMoveDialog(o);
  };

  let moveDisabled = false;
  if (items.filter((i) => i.available === true).length === 0)
    moveDisabled = true;

  // console.log('expiredbanner', items);
  return (
    <div className={`${containerClassName}`} style={containerStyle}>
      <DialogModal
        open={confirmDialog}
        setOpen={setConfirmDialogOpen}
        options={{ size: 'mini' }}
        dialogOptions={{
          header: 'CLEAR CART',
          actions: [
            {
              label: 'Cancel',
              className: 'ml-auto',
              preAction: () => ({ proceed: true }),
              action: () => {
                return { proceed: true };
              },
            },
            {
              label: 'Yes, clear cart',
              borderColorOverride: 'blue',
              textColorOverride: 'white',
              textColorHoverOverride: 'blue',
              backgroundColorOverride: 'blue',
              backgroundHoverColorOverride: 'white',
              iconColorOverride: 'white',
              iconColorHoverOverride: 'blue',

              preAction: () => {
                return { proceed: true };
              },

              action: () => {
                removeCarts();
                return { proceed: true };
              },
            },
          ],
        }}
      >
        <div className="mb-1">
          <div>
            Are you sure you want to clear all {items.length} items from your{' '}
            {isGeneralCart(foc) ? (
              ''
            ) : (
              <span className="fort-bold">
                FOC {formatDateMMDDYYYY(foc, '/')}
              </span>
            )}{' '}
            cart?
          </div>
          <div className="mt-1">
            The cart will be removed once it's emptied.
          </div>
        </div>
      </DialogModal>
      <DialogModal
        open={confirmMoveDialog}
        setOpen={setConfirmMoveDialogOpen}
        options={{ size: 'mini' }}
        dialogOptions={{
          header: 'MOVE ITEMS',
          actions: [
            {
              label: 'Cancel',
              className: 'ml-auto',
              preAction: () => ({ proceed: true }),
              action: () => {
                return { proceed: true };
              },
            },
            {
              label: 'Yes, move items',
              borderColorOverride: 'blue',
              textColorOverride: 'white',
              textColorHoverOverride: 'blue',
              backgroundColorOverride: 'blue',
              backgroundHoverColorOverride: 'white',
              iconColorOverride: 'white',
              iconColorHoverOverride: 'blue',

              preAction: () => {
                return { proceed: true };
              },

              action: () => {
                moveFocToGeneral({ foc, items });
                return { proceed: true };
              },
            },
          ],
        }}
      >
        <div>
          Are you sure you want to move {items.length} items from your{' '}
          <span className="fort-bold">FOC {formatDateMMDDYYYY(foc, '/')}</span>{' '}
          cart to your <span className="fort-bold">General</span> cart?
        </div>
        <div className="mt-1">
          This cart will be removed once all items are moved.
        </div>
      </DialogModal>

      <div
        className={`cv-warning d-flex align-items-center ${className} ${
          isMobile ? 'p-1' : 'px-3 py-1'
        }`}
      >
        <Icon
          name="exclamation triangle"
          color="red"
          className="align-self-start"
        />
        <div
          className={`fort-medium fs-12px lh-16px ${
            isMobile ? 'w-100' : 'w-50'
          }`}
        >
          <span className="fort-bold">This FOC date has passed.</span> We can no
          longer guarantee quantities, but if you still want to order these
          items you must move them to your General Cart.
        </div>
        {!isMobile && (
          <>
            <PRHButton
              size="small"
              // icon="alternate trash"
              className="ml-auto"
              backgroundColorOverride="white"
              onClick={(e) => setConfirmDialog(true)}
            >
              Clear Cart
            </PRHButton>
            <PRHButton
              disabled={moveDisabled}
              size="small"
              // icon="arrow right"
              className="ml-1"
              backgroundColorOverride="white"
              onClick={(e) => setConfirmMoveDialog(true)}
            >
              Move Items
            </PRHButton>
          </>
        )}
      </div>
    </div>
  );
}

export default ExpiredFocBanner;
