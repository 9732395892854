import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Container, Grid, Icon } from "semantic-ui-react";
import { MOBILE_SCREEN_WIDTH, TABLET_SCREEN_WIDTH } from "../../../utils/const";
import "./CreditHoldBanner.scss";
import LazyImage from "../../../utils/LazyImage";
import { useSelector } from "react-redux";
import useCart from "../../../components/hc/useCart";
import { Link } from "react-router-dom";
import {
  osVersion,
  osName,
  isChrome,
  isFirefox,
  isIE,
} from "react-device-detect";

const CreditHoldBanner = (props) => {
  const [bannerOpen, setBannerOpen] = useState(true);
  const Cart = useCart();
  const ecom = useSelector((state) => state.ecom);
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(min-width: ${MOBILE_SCREEN_WIDTH}px) AND (max-width: ${TABLET_SCREEN_WIDTH}px)`,
  });

  if (Cart?.customerInfo?.currentUser?.roleNo === "W9") {
  } else {
    return <></>;
  }

  if (
    ecom?.customerInfo?.customer?.creditCategory === "HD" ||
    ecom?.customerInfo?.customer?.creditCategory === "FD"
  ) {
  } else {
    return <></>;
  }

  return (
    <div
      className={`  home-ch-container pt-1 pb-1 fs-16px lh-20px ${
        bannerOpen ? "d-flex" : "d-none"
      }`}
    >
      {isMobile || isTablet ? (
        <div className="d-flex w-100 home-ch-mobile-container">
          <div className="mr-2">
            <Icon
              className="fs-18px mr-1"
              style={{ marginTop: "1px", color: "#C22929" }}
              name="exclamation triangle"
            />
          </div>
          <div className="d-flex flex-column w-100">
            <div>
              <span className="fort-bold mr-1">
                Your account is on credit hold.
              </span>
            </div>
            {ecom?.customerInfo?.customer?.creditRepEmail && (
              <div
                className={`d-flex ${isTablet ? "flex-row" : "flex-column"}`}
              >
                <div className={`${isTablet ? "mr-1" : ""}`}>
                  To resolve this matter, contact
                </div>
                <div>
                  {console.log(
                    "email",
                    ecom.customerInfo.customer.creditRepEmail
                  )}
                  <Link to="/admin/account-reps">
                    your Credit Representative
                  </Link>
                  {/* <a
                    href={`mailto:${ecom.customerInfo.customer.creditRepEmail}`}
                  >
                    your Credit Representative
                  </a> */}
                </div>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center ml-auto">
            <LazyImage
              src={"/images/X-close.svg"}
              className="text-black cursor-pointer fs-20px ml-2 align-self-center "
              onClick={() => {
                // dispatch(setDeliveryHomePageWidget(false));
                setBannerOpen(false);
              }}
            />
          </div>
        </div>
      ) : (
        <Container className={`d-flex mt-1 mb-1 `}>
          <div className="d-flex w-100">
            <div>
              <Icon
                className="fs-18px mr-1"
                style={{ marginTop: "1px", color: "#C22929" }}
                name="exclamation triangle"
              />
              <span className="fort-bold mr-1">
                Your account is on credit hold.
              </span>
            </div>
            {ecom?.customerInfo?.customer?.creditRepEmail && (
              <div className={``}>
                <div
                  className={`${
                    osName === "Windows" && isChrome ? "cb-windows-chrome" : ""
                  } ${isFirefox ? "cb-firefox" : ""}`}
                >
                  To resolve this matter, contact{" "}
                  <Link to="/admin/account-reps">
                    your Credit Representative
                  </Link>
                  {/* <a
                    href={`mailto:${ecom.customerInfo.customer.creditRepEmail.toLowerCase()}`}
                  >
                    your Credit Representative
                  </a> */}
                </div>
              </div>
            )}

            <div className="d-flex align-items-center ml-auto">
              <LazyImage
                src={"/images/X-close.svg"}
                className="text-black cursor-pointer fs-20px ml-2 align-self-center "
                onClick={() => {
                  // dispatch(setDeliveryHomePageWidget(false));
                  setBannerOpen(false);
                }}
              />
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default CreditHoldBanner;
