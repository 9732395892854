import { Button, Header, Modal } from 'semantic-ui-react';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import withAccount from '../../components/hc/withAccount';

const ModalStandard = (props) => {
  const { children, header, modalOpen, modalText, account } = props;
  // console.log('modal standard', modalOpen);

  // const handleClose = () => {
  //   if (onClose) {
  //     onClose();
  //     if (returnOnClose) {
  //       return;
  //     }
  //   }

  //   if (open) {
  //     setOpen(false);
  //   } else {
  //     setIntOpen(false);
  //     setOpen(null);
  //   }
  // };

  const closeIcon = (
    <PRHButton
      iconOnlyBorderAlways
      borderColorOverride="white"
      borderHoverColorOverride="white"
      iconColorOverride="gray-500"
      icon="times"
      fontAwesome
      size="large"
      className="ml-auto"
      // disabled={!!pending}
      onClick={() => {
        account.editControl(false, 'Add User', null, null);
      }}
    />
  );
  return (
    <Modal dimmer="inverted" size="tiny" open={modalOpen}>
      <Modal.Header className="d-flex align-items-center">
        <span className="text-uppercase">{header}</span> {closeIcon}
      </Modal.Header>
      {modalText ? modalText : ''}
      <Modal.Content>
        <Modal.Description>{children}</Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default withAccount(ModalStandard);
