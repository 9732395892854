import { findIconDefinition, icon } from "@fortawesome/fontawesome-svg-core";
//import "ag-grid-community/dist/styles/ag-grid.css";
//import "ag-grid-community/dist/styles/ag-theme-alpine.css";
//import "ag-grid-enterprise";
//import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { DateTime, Settings } from "luxon";
import print from "print-js";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter } from "react-router";
import {
  Container,
  Dropdown,
  Form,
  Icon,
  Input,
  Message,
  Grid,
  Popup,
  Placeholder,
  Loader,
} from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import LazyImage from "../../utils/LazyImage";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Export, ExportCSV } from "../../utils/Export";
import {
  convertDateToDay,
  formatDateMonDayYear,
  formatDateYYYY,
  formatDateMonDay,
  formatFirstDateYY,
} from "../../utils/utilities";
import "./DeliveriesTab.scss";
import orderNumberRenderer from "./renderers/orderNumberRenderer";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import { CartonRows } from "./renderers/deliveriesCartonRows";
import { MobileOptionsMenu } from "../../components/header/components/MobileOptionsMenu";
import MobileTable, {
  mobileTableColumnsOrders,
} from "../../shared-react-components/elements/OrdersAndInvoicesMobileTable";
import { Divider } from "../../components/elements/elements";
import { OrderInvoiceTitleSearchMobile } from "../../components/common/OrderInvoiceTitleSearchMobile";
import {
  useLazyGetDeliveriesOverviewQuery,
  useLazyGetDeliveriesSearchQuery,
} from "../../api/ecomApi";
import { func } from "prop-types";
import { set } from "lscache";
import { BackToTopButton } from "../../components/common/BackToTopButton";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";

const timeFilter = [
  {
    key: 1,
    text: "Upcoming",
    value: "Upcoming",
  },
  {
    key: 2,
    text: "Past 7 days",
    value: "Past 7 days",
  },
];

const orderFilter = [
  {
    key: 1,
    text: "Ascending",
    value: "asc",
  },
  {
    key: 2,
    text: "Descending",
    value: "desc",
  },
];

const pageSizeOptions = [
  {
    key: 50,
    text: "50 per page",
    value: 50,
  },
  {
    key: 100,
    text: "100 per page",
    value: 100,
  },
  {
    key: 150,
    text: "150 per page",
    value: 150,
  },
  {
    key: 200,
    text: "200 per page",
    value: 200,
  },
];

const dateFilters = [
  {
    key: 30,
    text: "Past 30 days",
    value: 30,
  },
  {
    key: 92,
    text: "Past 3 months",
    value: 92,
  },
  {
    key: 184,
    text: "Past 6 months",
    value: 184,
  },
  {
    key: 366,
    text: "Past 12 months",
    value: 366,
  },
  {
    key: 550,
    text: "Past 18 months",
    value: 550,
  },
];

export const contentsColorConverter = (name) => {
  const colorTable = [
    { content: "Initials", color: "#DDEFE4" },
    { content: "Reorders", color: "#D3ECF3" },
    { content: "Managed Stock", color: "#DDD" },
    { content: "Order for Events", color: "#E6DDEF" },
  ];
  return colorTable.find((item) => item.content === name)?.color || "#F2F2F2";
};

const tempContents = [
  "Initials",
  "Reorders",
  "Managed stock",
  "Order for Events",
];

export const DeliveriesTab = withRouter(
  withSecurity(
    withApp(
      withCart((props) => {
        const isMobile = useMediaQuery({
          query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
        });
        const ref = useRef(null);
        const deliveryRef = useRef(null);
        const [performGetDeliveries, getDeliveriesResult] =
          useLazyGetDeliveriesOverviewQuery();
        const [
          performGetSearchQuery,
          { isFetching: performGetSearchIsFetching, data: getSearchResult },
        ] = useLazyGetDeliveriesSearchQuery();
        const [shouldScrollTop, setShouldScrollTop] = useState(false);
        const [searchTerm, setSearchTerm] = useState("");
        const [order, setOrder] = useState("desc"); //asc or desc
        const propPage = Number(props.location.search?.split("=")[1] || 1);
        const [rowData, setRowData] = useState(false);
        const [showResults, setShowResults] = useState(false);
        const [rowDataToDisplay, setRowDataToDisplay] = useState(false);
        const [deliveries, setDeliveries] = useState([]);
        const [locationDropdownItems, setLocationDropdownItems] = useState([]);
        const [selectedShipTo, setSelectedShipTo] = useState("all");
        const [selectedLocation, setSelectedLocation] = useState(false);
        const [scrollToDelivery, setScrollToDelivery] = useState(false);
        const [searchResults, setSearchResults] = useState(false);
        const [resultType, setResultType] = useState("full");
        const [timeFilterValue, setTimeFilterValue] = useState("Upcoming");
        const [numberOfDeliveries, setNumberOfDeliveries] = useState("");
        const [numberOfCartons, setNumberOfCartons] = useState("");
        const [isSearch, setIsSearch] = useState(false);
        const [lastDeliveryDate, setLastDeliveryDate] = useState(false);
        const columnDefs = (deliveryNo) => {
          return [
            {
              headerName: `Delivery # ${deliveryNo}`,
              field: "salesOrderNumber",
              cellRenderer: "orderNumberRenderer",
              cellClass: ["btt-isbn no-border"],
              checkboxSelection: true,
              headerCheckboxSelection: true,
            },
            {
              headerName: "Ships From",
              field: "poNumber",
              cellClass: ["btt-po no-border"],
            },
            {
              headerName: "Ships To",
              field: "dateCreated",
              sortable: true,
              sort: "desc",
              sortingOrder: ["desc", "asc"],
              cellClass: "no-border",
            },
            {
              headerName: "Contents",
              field: "account",
              cellClass: "no-border",
            },
          ];
        };
        const [state, setState] = useState({
          isLoading: false,
          pageSize: props.location.state?.pageSize
            ? props.location.state?.pageSize
            : 50,
          /*
          currentPageNum: props.location.state?.currentPageNum
            ? props.location.state?.currentPageNum
            : 1,
          */
          currentPageNum: 1,
          totalLength: 0,
          totalPages: props.location.state?.totalPages
            ? props.location.totalPages?.currentPageNum
            : 0,
          rowHeight: 51,
          rowCount: 0,
          queryId: null,
          hasMore: false,
          currentSort: "dateCreated_desc",
          start: 1,
          end: 50,
          //rowData: undefined,
          dayFilter: props.location.state?.dayFilter
            ? props.location.state?.dayFilter
            : dateFilters[0].value,
          accountFilter: props.location.state?.accountFilter
            ? props.location.state?.accountFilter
            : "1",
          accountFilters: [],
          selectedRows: [],
          isSearchResult: false,
          searchInput: props.location.state?.searchInput
            ? [props.location.state.searchInput]
            : [],
          searchShipTo: props.location.state?.searchShipTo
            ? props.location.state.searchShipTo
            : false,
          headerOnly: true,
          hasInput: props.location.state?.hasInput
            ? props.location.state.hasInput
            : false,
          searchClicked: props.location.state?.hasInput
            ? props.location.state.hasInput
            : false,
          testme: 0,
          selectedShipTo: props.location.state?.selectedShipTo
            ? props.location.state.selectedShipTo
            : "all",
          hasInput: props.history.location.state?.hasInput
            ? props.history.location.state?.hasInput
            : false,
          hasError: false,
          rows: 1000,
          itemsPerPage: 50,
        });
        const [gridApi, setGridApi] = useState(null);

        const [sortMenuVisible, setSortMenuVisible] = useState(false);
        const [accountMenuVisible, setAccountMenuVisible] = useState(false);

        const [mobileRowData, setMobileRowData] = useState(rowData);
        const location = useLocation();
        const [currentPage, setCurrentPage] = useState(1);
        const ITEMS_PER_PAGE = 10;
        const CURRENT_DATE = "2023-03-02";
        const start = (currentPage - 1) * ITEMS_PER_PAGE;
        const end = start + ITEMS_PER_PAGE;
        /*
        const itemsToShow = use
          Object.keys(data).length > 24 && context === "titleDetail"
            ? data.slice(start, end)
          : data;
        */
        const [totalPages, setTotalPages] = useState(false);
        //const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);

        const handleNextPage = () => {
          setCurrentPage(currentPage + 1);
          handleScroll();
          //ref.current.scrollTop = 0;
        };

        const handlePrevPage = () => {
          setCurrentPage(currentPage - 1);
          handleScroll();
          //ref.current.scrollTop = 0;
        };

        function regex() {}

        useEffect(() => {
          // console.log("use effect 1");
          if (state.accountFilter === "1") {
            setMobileRowData(rowData);
          } else {
            const filteredData = rowData?.filter(
              (data) => data.account === state.accountFilter
            );
            setMobileRowData(filteredData);
          }
        }, [rowData, state.accountFilter]);

        useEffect(() => {
          if (!(state.searchInput?.length > 0)) {
            setState({ ...state, selectedShipTo: "all" });
          }
        }, [searchResults]);

        useEffect(() => {
          // console.log("use effect 2");
          if (shouldScrollTop === false) {
            window.scrollTo(0, 0);
            //setShouldScrollTop(false);
          }
          //scrollToTop();
        }, [shouldScrollTop]);

        useEffect(() => {
          // console.log(
          //   "use effect 3",
          //   state.selectedShipTo,
          //   props.location?.state?.selectedShipTo
          // );
          if (rowData) {
            setCurrentPage(1);
            setTotalPages(determineTotalPages(filterByShipTo(orderedRowData)));
            setNumberOfDeliveries(filterByShipTo(orderedRowData)?.length);
            setNumberOfCartons(
              calculateAggregatedLength(filterByShipTo(orderedRowData))
            );
            setRowDataToDisplay(
              paginateDeliveries(filterByShipTo(orderChange(orderedRowData)))
            );
            handleScroll();
          }
        }, [state.selectedShipTo]);

        useEffect(() => {
          // console.log("use effect 4");
          if (rowData) {
            setRowDataToDisplay(
              paginateDeliveries(filterByShipTo(orderChange(orderedRowData)))
            );
            handleScroll();
          }
        }, [currentPage, order]);

        useEffect(() => {
          // console.log("use effect 5");
          if (props.history.action !== "PUSH" && props.history.location.state) {
            setState({
              ...state,
              dayFilter: props.history.location.state.dayFilter
                ? props.history.location.state.dayFilter
                : state.dayFilter,
              accountFilter: props.history.location.state?.accountFilter
                ? props.history.location.state?.accountFilter
                : state.accountFilter,
              pageSize: props.history.location.state?.pageSize
                ? props.history.location.state?.pageSize
                : state.pageSize,
              /*
              currentPageNum: props.history.location.state?.currentPageNum
                ? props.history.location.state?.currentPageNum
                : state.currentPageNum,
              */
              totalPages: props.history.location.state?.totalPages
                ? props.history.location.state?.totalPages
                : state.totalPages,
              searchInput:
                props.history.location.state?.searchInput &&
                props.history.location.state?.searchInput?.length > 0
                  ? props.history.location.state?.searchInput
                  : state.searchInput,

              hasInput: props.history.location.state?.hasInput
                ? props.history.location.state?.hasInput
                : state.hasInput,

              //hasInput: false,
            });
          }
          if (props.cart.customerInfo.shipTos.length === 1) {
            setSelectedLocation("all");
          } else if (props.cart.customerInfo.shipTos.length > 1) {
            let options = [
              {
                key: "all",
                value: "all",
                header: <div>All Accounts</div>,
                text: <div>All Accounts'</div>,
                content: <div>All Accounts</div>,
              },
            ];
            props.cart.customerInfo.shipTos.map((f) => {
              let locTitle = f.accountNo;
              if (f?.address.name1) {
                locTitle += ` | ${f?.address.name1}`;
              }
              if (f?.address.city) {
                locTitle += ` | ${f?.address.city}`;
              }
              if (f?.address.state) {
                locTitle += `, ${f?.address.state}`;
              }

              options.push({
                key: f.accountNo,
                value: f.accountNo,
                text: locTitle,
                content: <div className="d-flex flex-row">{f.accountNo}</div>,
              });
              // }
            });
            //setOptions(options);
            setLocationDropdownItems(options);
            if (props.cart.isUserPow()) {
              if (props.cart.shipTo >= 0) {
                setSelectedLocation(props.cart.getShipToAccount().accountNo);
              } else {
                setSelectedLocation(false);
              }
            } else {
              if (history?.location?.state?.shipTo) {
                setSelectedLocation(history?.location?.state?.shipTo);
              } else {
                setSelectedLocation("all");
              }
            }
          }
        }, []);

        /* Maybe necessary, may be causing issue with applying filter before user searches */
        /*
        useEffect(() => {
          console.log('use effect 6');
          if (props?.location?.state?.searchInput) {
            setState({
              ...state,
              hasInput: true,
              // searchShipTo: 'jeff',
              searchInput: [props.location.state.searchInput],
              isSearchResult: true,
            });
          }
        }, [props.location.state]);
        */

        useEffect(() => {
          // console.log("use effect 7");
          if (!rowData) {
            performSearch();
          }
          // Clean up the listener when the component unmounts
          //}, [state.dayFilter, props.cart.customerLoaded, state.start]);
        }, []);

        const orderedRowData = useMemo(() => {
          let sortedItems;
          if (order === "asc") {
            sortedItems = Object.values(rowData).sort(
              (a, b) => new Date(a.shipDate) - new Date(b.shipDate)
            );
          } else {
            sortedItems = Object.values(rowData).sort(
              (a, b) => new Date(b.shipDate) - new Date(a.shipDate)
            );
          }
          return sortedItems;
        }, [rowData, order]);

        function orderChange(arr) {
          //currently not used
          let sortedItems;
          if (order === "asc") {
            sortedItems = Object.values(arr).sort(
              (a, b) => new Date(a.shipDate) - new Date(b.shipDate)
            );
          } else {
            sortedItems = Object.values(arr).sort(
              (a, b) => new Date(b.shipDate) - new Date(a.shipDate)
            );
          }
          return sortedItems;
        }

        function handleScroll() {
          if (shouldScrollTop === false) {
            setShouldScrollTop(true);
          } else {
            setShouldScrollTop(false);
          }
        }

        function filterByShipTo(arr) {
          var shipTo = props.location?.state?.selectedShipTo
            ? props.location?.state?.selectedShipTo
            : state.selectedShipTo;
          if (shipTo === "all" || state.searchInput?.length > 0) {
            return arr;
          }
          return arr.filter((item) => item.shipTo === shipTo);
        }

        function determineTotalPages(data) {
          return Math.ceil(data.length / ITEMS_PER_PAGE);
        }

        /*
        function filterByDay(arr) { //currently not used
          let currentDate = DateTime.fromISO('2023-03-02').startOf('day');
          let futureDate;
          let pastDate;
          let deliveriesByDate;
          if (timeFilterValue === 'Upcoming') {
            futureDate = currentDate.plus({ days: 14 }).startOf('day');
            deliveriesByDate = arr.filter(item => {
              let itemDate = DateTime.fromISO(item.deliveryDay).startOf('day');
              return (currentDate <= itemDate) && (itemDate <= futureDate);
          });
          } else {
            pastDate = currentDate.minus({ days: 7 }).startOf('day');
            deliveriesByDate = arr.filter(item => {
              let itemDate = DateTime.fromISO(item.deliveryDay).startOf('day');
              return (pastDate < itemDate) && (itemDate < currentDate);
          });
          }
          setNumberOfDeliveries(deliveriesByDate.length);
          setNumberOfCartons(calculateAggregatedLength(deliveriesByDate));
          console.log('deliveriesByDate', deliveriesByDate);
          return deliveriesByDate;
        }
        */
        function paginateDeliveries(arr) {
          let deliveriesForDisplay = arr;
          if (isMobile) return arr;
          let deliveriesForDisplayPaginated =
            Object.keys(arr).length > 10
              ? deliveriesForDisplay.slice(start, end)
              : deliveriesForDisplay;
          return deliveriesForDisplayPaginated;
        }

        const calculateAggregatedLength = (arrayOfObjects) => {
          const aggregatedLength = arrayOfObjects.reduce((totalLength, obj) => {
            return totalLength + obj.cartons.length;
          }, 0);
          return aggregatedLength;
        };

        function onFilterTextBoxChanged() {
          const { searchInput } = state;
          // this is present in other tabs, so maybe keep
          // console.log("input filter text box changed");
          setOrder("desc");
          setSelectedShipTo("all");
          setState({
            ...state,
            selectedShipTo: "all",
            testme: 1,
          });
          props.history.push({
            pathname: "/deliveries",
            state: {
              ...props.history.location.state,
              searchInput: searchInput.length > 0 ? searchInput : [],
              isSearchResult: searchInput > 0 ? true : false,
              hasInput: true,
              selectedShipTo: "all",
            },
          });
          /*
          if (searchInput.length > 0) {
            setSearchResults(true);
            setFullResults(false);
          } else {
            setFullResults(true);
            setSearchResults(false);
          }*/
          performSearch();
        }

        function groupByDeliveryDay(array) {
          const grouped = array.reduce((acc, obj) => {
            const key = obj.deliveryDay;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
          }, {});

          return Object.values(grouped);
        }
        /*
      function getTotalLengthOfDeliveries(arr) {
        return arr.map(subArray => subArray.length).reduce((a, b) => a + b, 0);
    }
*/

        function getTotalLengthOfDeliveries(arr, arrayKey) {
          let totalLength = 0;

          arr.forEach((obj) => {
            if (Array.isArray(obj[arrayKey])) {
              totalLength += obj[arrayKey].length;
            }
          });

          return totalLength;
        }

        const gotoDetailPage = (detail) => {
          //alert('show me');\
          window.open(`delivery/${detail}`);
        };

        function resetSearch(event, data) {
          var code = event.keyCode || event.which;
          // console.log("resetSearch", event, data);
          if (code === 13) {
            onFilterTextBoxChanged();
          }
          /*
          if (code === 8) {
          console.log('resetSearch', code);
          setState({
            ...state,
            searchInput: [],
            hasInput: false,
            isSearchResult: false,
            selectedShipTo: "all",
          });
          props.history.push({
            pathname: "/deliveries",
            state: {
              ...props.history.location.state,
              hasInput: false,
              isSearchResult: false,
              searchInput:[],
              selectedShipTo: "all",
            },
          });
          setSearchResults(false);
          performSearch();
        }*/
        }

        /*
        const search = (event) => {
          const { searchInput } = state;
          var code = event.keyCode || event.which;
          if (code === 13 && searchInput?.length > 0) {
            onFilterTextBoxChanged();
            setState({
              ...state,
              searchClicked: true,
            });
          } else if (searchInput?.length === 0) {
            setState({
              ...state,
              searchClicked: false,
            });
          }
        };
*/

        const showAddressOfShipTo = (shipTo) => {
          const address = props.cart.customerInfo?.shipToAccountNos?.find(
            (item) => item.accountNo === shipTo
          )?.address;
          return (
            <span>
              {shipTo} {address?.city}
              {address?.city ? "," : ""} {address?.state}
            </span>
          );
        };

        function onGridReady(params) {
          setGridApi(params.api);
          params.api.sizeColumnsToFit();
          setState({
            ...state,
            accountFilter: props.history.location.state?.accountFilter
              ? props.history.location.state?.accountFilter
              : state.accountFilter,
          });
          params.api.setQuickFilter(
            state.accountFilter !== "1" ? state.accountFilter : ""
          );
        }

        const setData = (dataProps) => {
          // dataProps is the data from the API
          //dataProps = [];
          const uniqueShipToAccountNumbers = [
            ...new Set(dataProps.map((item) => item.account)),
          ].map((item) => {
            return {
              id: item,
              text: String(item),
              value: item,
            };
          });
          setRowData(dataProps); // set the data to the state
          setTotalPages(
            determineTotalPages(
              state.searchInput?.length > 0
                ? dataProps
                : filterByShipTo(dataProps)
            )
          );
          setRowDataToDisplay(
            paginateDeliveries(filterByShipTo(orderChange(dataProps)))
          );
          //setNumberOfDeliveries(dataProps.length);
          setNumberOfDeliveries(filterByShipTo(dataProps)?.length);
          setNumberOfCartons(
            calculateAggregatedLength(filterByShipTo(dataProps))
          );
          //setNumberOfCartons(calculateAggregatedLength(dataProps));
          setState({
            ...state,
            totalLength: getTotalLengthOfDeliveries(dataProps, "cartons"),
            totalPages: Math.ceil(
              getTotalLengthOfDeliveries(dataProps, "cartons") / state.pageSize
            ),
            isLoading: false,
            accountFilters: [
              {
                key: 1,
                text: "All Accounts",
                value: "1",
              },
              ...uniqueShipToAccountNumbers,
            ],
          });

          if (gridApi) {
            gridApi.hideOverlay();
          }
        };

        const performSearch = () => {
          const dataParams = {
            dateFrom: moment(new Date(), "YYYY-MM-DD")
              .subtract(state.dayFilter, "days")
              .toISOString()
              .slice(0, 10),
            dateTo: moment(new Date(), "YYYY-MM-DD").toISOString().slice(0, 10),
            allowPricing: true,
            boOnly: false,
            headerOnly: true,
            start: state.start,
            rows: state.rows,
            account: [
              props.cart?.customerInfo?.currentUser?.roleNo === "W1"
                ? props.cart?.customerInfo?.customer?.accountNo
                : "",
            ],
          };
          if (state.searchShipTo) {
            dataParams.account = [state.searchShipTo];
          }
          if (gridApi) {
            gridApi.showLoadingOverlay();
          }
          setState({ ...state, isLoading: true });
          // console.log(
          //   "input",
          //   state,
          //   state.searchInput?.length,
          //   props.history.location.state
          // );
          if (state.hasInput && state.searchInput?.length > 0) {
            const input = state.searchInput[0].toString().trim();
            const isbn = input?.replaceAll("-", "");
            setResultType("search");
            performGetSearchQuery({
              searchTerm: input /*.replace(/-/g, ''),*/,
              //data: { ...dataParams, isbn: [isbn], dateFrom: null },
              //sapAccountNo: props.cart.ccSapAccountNo,
            }).then((response) => {
              const { data } = response;
              setSearchResults(true);
              setSearchTerm(response.data.data.searchTerm);
              setData(response.data.data.deliveries);
              /*
              if (ordersResponse.data?.status === "OK") {
                if (data.data.rowCount === 0) {
                  performGetDeliveries({
                    data: {
                      ...dataParams,
                      purchaseOrder: [input],
                      dateFrom: null,
                    },
                    sapAccountNo: props.cart.ccSapAccountNo,
                  }).then((ordersResponse) => {
                    const { data } = ordersResponse;
                    if (ordersResponse.data?.status === "OK") {
                      if (data.data.rowCount === 0) {
                        performGetDeliveries({
                          data: {
                            ...dataParams,
                            documentNumber: [input],
                            dateFrom: null,
                          },
                          sapAccountNo: props.cart.ccSapAccountNo,
                        }).then((ordersResponse) => {
                          if (ordersResponse.data?.status === "OK") {
                            const { data } = ordersResponse;
                            // console.log('orders ordersResponse', ordersResponse);
                            setData(data);
                          } else {
                            setState({
                              ...state,
                              hasError: true,
                              isLoading: false,
                            });
                          }
                        });
                      } else {
                        setState({ ...state });
                        setData(data);
                      }
                    } else {
                      setState({
                        ...state,
                        hasError: true,
                        isLoading: false,
                      });
                    }
                  });
                } else {
                  setData(data);
                }
              } else {
                setState({
                  ...state,
                  hasError: true,
                  isLoading: false,
                });
              }*/
            });
          } else {
            setResultType("full");
            performGetDeliveries({
              data: dataParams,
              sapAccountNo: props.cart.ccSapAccountNo,
              shipTo:
                props.cart?.customerInfo?.currentUser?.roleNo === "W1"
                  ? props.cart?.customerInfo?.customer?.accountNo
                  : null,
            }).then((response) => {
              if (response.data?.status === "OK") {
                setData(response.data.data.deliveries);
                totalPages;
              } else {
                setState({ ...state, hasError: true });
              }
            });
          }
        };

        const downloadOptions = [
          {
            key: 50,
            text: "Print",
            value: 50,
            icon: { name: "print", color: "orange" },
            onClick: () =>
              print({
                printable:
                  state.selectedRows.length > 0 ? state.selectedRows : rowData,
                properties: [
                  "salesOrderNumber",
                  "poNumber",
                  "dateCreated",
                  "account",
                ],
                type: "json",
              }),
          },
          {
            key: 100,
            text: "Download Excel",
            value: 100,
            icon: { name: "file excel outline", color: "orange" },
            onClick: () =>
              Export.DocumentDetails({
                fileName: `order-list-${moment().format("YYYY-MM-DD")}`,
                metadata: [
                  [
                    "Inquiry for",
                    `Search for orders with date between ${moment()
                      .subtract(state.dayFilter, "days")
                      .format("MM/DD/YYYY")} and ${moment().format(
                      "MM/DD/YYYY"
                    )}`,
                  ],
                  ["Inquiry date", moment().format("MM/DD/YYYY")],
                ],
                list: (state.selectedRows.length > 0
                  ? state.selectedRows
                  : rowData
                ).map((item) => {
                  return {
                    "Ship-to Account": item.account,
                    "Purchase Order Number": item.poNumber,
                    "Date Created": item.dateCreated,
                    "Sales Order Number": item.salesOrderNumber,
                  };
                }),
              }),
          },
          {
            key: 150,
            text: "Download CSV",
            value: 150,
            icon: { name: "file outline", color: "orange" },
            onClick: () =>
              ExportCSV.DocumentDetails({
                fileName: `order-list-${moment().format("YYYY-MM-DD")}`,
                metadata: [
                  [
                    "Inquiry for",
                    `Search for orders with date between ${moment()
                      .subtract(state.dayFilter, "days")
                      .format("MM/DD/YYYY")} and ${moment().format(
                      "MM/DD/YYYY"
                    )}`,
                  ],
                  ["Inquiry date", moment().format("MM/DD/YYYY")],
                ],
                list: (state.selectedRows.length > 0
                  ? state.selectedRows
                  : rowData
                ).map((item) => {
                  return {
                    "Ship-to Account": item.account,
                    "Purchase Order Number": item.poNumber,
                    "Date Created": item.dateCreated,
                    "Sales Order Number": item.salesOrderNumber,
                  };
                }),
              }),
          },
        ];

        const getAccountFilterText = (key, accountFilters) => {
          const item = accountFilters?.find((option) =>
            key === "1" ? option.key === 1 : option.id === key
          );
          return item?.text;
        };

        function debounce(func, time) {
          var time = time || 100; // 100 by default if no param
          var timer;
          return function (event) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(func, time, event);
          };
        }

        // Function with stuff to execute
        function resizeContent() {
          if (gridApi) {
            gridApi.sizeColumnsToFit();
          }
        }

        // Eventlistener
        window.addEventListener("resize", debounce(resizeContent, 150));

        const caretUp = findIconDefinition({
          prefix: "fas",
          iconName: "caret-up",
        });
        const caretDown = findIconDefinition({
          prefix: "fas",
          iconName: "caret-down",
        });
        const caretUpIcon = icon(caretUp);
        const caretDownIcon = icon(caretDown);
        return (
          <div ref={ref} className="container-deliveries-page">
            <div
              className={`delivery-filter-row ${
                isMobile ? "mobile-oi-filter-row mb-0" : ""
              }`}
            >
              {/*isMobile && !state.hasError && (
                <div className="mobile-orders-tab-search-container mb-3 mt-1">
                  <Container>
                    <OrderInvoiceTitleSearchMobile
                      title={mobileRowData?.length === 1 ? "Order" : "Orders"}
                      count={mobileRowData?.length}
                      onSearch={(search) => {
                        setState({
                          ...state,
                          searchInput: search ? [search] : [],
                        });
                        props.history.push({
                          pathname: "/orders",
                          state: {
                            ...props.history.location.state,
                            searchInput: search ? [search] : [],
                          },
                        });
                      }}
                      placeholder="Search Order #, PO #, or ISBN"
                    />
                  </Container>
                </div>
              )*/}
              {
                /*!isMobile && */ !state.hasError && (
                  <div className="deliveries-filter-row-left">
                    {" "}
                    {/*deliveries-filter-row-left oi-filter-row-left*/}
                    {props.cart.customerInfo.currentUser.roleNo !== "W1" &&
                      props.cart.customerInfo?.shipTos?.length > 1 && (
                        <div className="deliveries-shipto-dropdown-container">
                          <Dropdown
                            options={locationDropdownItems}
                            className="od-dropdown"
                            disabled={
                              searchResults && state.searchInput?.length > 0
                            }
                            onChange={(_e, { value }) => {
                              //setSelectedShipTo(value);
                              setState({
                                ...state,
                                selectedShipTo: value,
                              });
                              props.history.push({
                                pathname: "/deliveries",
                                state: {
                                  ...props.history.location.state,
                                  selectedShipTo: value,
                                },
                              });
                            }}
                            value={state.selectedShipTo}
                            // item={true}
                            // selection
                            trigger={
                              <div>
                                {state.selectedShipTo === "all" ||
                                searchResults ||
                                (props.location?.state?.selectedShipTo &&
                                  props.location?.state?.selectedShipTo ===
                                    "all")
                                  ? "All Accounts"
                                  : state.selectedShipTo}
                              </div>
                            }
                          />
                        </div>
                      )}
                    {/*
                  <div className="tad-shipto-dropdown-container" style={{marginLeft:'.2em'}}>
                  <Dropdown
                        options={timeFilter}
                        className="od-dropdown"
                        onChange={(_e, { value }) => {

                          console.log('timeFilterValue', value);
                          setTimeFilterValue(value);
                        }}
                        value={timeFilterValue}
                        trigger={
                          <div>
                            {timeFilterValue === "upcoming"
                              ? "Upcoming"
                              : timeFilterValue}
                          </div>
                        }
                      />
                      </div>*/}
                  </div>
                )
              }
              {/* {console.log('state', state.hasInput, state.searchInput.length )} */}
              {
                /*!isMobile && */ !state.hasError && (
                  <div className="mobile-100-percent">
                    <Form onSubmit={onFilterTextBoxChanged}>
                      <Input
                        className={`ot-input deliveries ${
                          state.hasInput ? "active" : ""
                        }`}
                        id="searchlist"
                        // iconPosition='right'
                        name="searchlist"
                        type="text"
                        icon={{
                          name: "search",
                          link: true,
                          onClick: () => onFilterTextBoxChanged(),
                        }}
                        value={
                          state.hasInput && state.searchInput.length > 0
                            ? state.searchInput[0].toString().trim()
                            : ""
                        }
                        disabled={state.isLoading}
                        //onKeyDown={(e, data)=>resetSearch(e, data)}
                        onChange={(e, data) => {
                          // console.log("data.value", data.value);
                          setState({
                            ...state,
                            searchInput:
                              data.value.trim() !== ""
                                ? [data.value.trim()]
                                : [],
                            hasInput: true,
                          });
                          /*
                        if (data.value.trim() !== "") {
                          console.log('on change', data.value.trim());
                          setState({
                            ...state,
                            searchInput: [data.value.trim()],
                            hasInput: true,
                          });
                        } else {
                          
                        }*/
                        }}
                        placeholder={"Search by Delivery #, ISBN, PO etc."}
                        //placeholder={'Search Order #, PO #, or ISBN'}
                        //placeholder={state.searchInput = [] ? 'Search by Delivery #, ISBN, PO etc.': state.searchInput}
                        //placeholder={state.searchInput}
                      />
                    </Form>
                  </div>
                )
              }
              {isMobile && (
                <div
                  className="mobile-100-percent"
                  style={{ marginTop: "16px" }}
                >
                  <Dropdown
                    options={orderFilter}
                    className="od-dropdown"
                    onChange={(_e, { value }) => {
                      setOrder(value);
                    }}
                    value={order}
                    trigger={
                      <div>
                        {order === "asc"
                          ? "Sort by: Ascending"
                          : "Sort by: Descending"}
                      </div>
                    }
                  />
                </div>
              )}
            </div>
            {resultType === "full" &&
            rowDataToDisplay?.length >
              0 /*&& !(state.searchInput > 0)/*&& !state.isSearchResult && !(state.searchInput > 0)*/ ? (
              <div className="range-container">
                {/*<div className="search-header search-padding">{timeFilterValue==='Upcoming' ? 'Upcoming Deliveries': 'Recent Deliveries'}</div>*/}
                <div className={`${!isMobile ? "d-flex" : ""}`}>
                  <div>
                    <span style={{ fontFamily: "Fort-Bold" }}>
                      Date Range:{" "}
                    </span>
                    {orderedRowData[0]?.shipDate !==
                    orderedRowData[orderedRowData?.length - 1]?.shipDate
                      ? `${formatFirstDateYY(
                          orderedRowData[0]?.shipDate,
                          orderedRowData[orderedRowData?.length - 1]?.shipDate
                        )} - `
                      : ""}
                    {`${formatDateMonDayYear(
                      orderedRowData[orderedRowData?.length - 1]?.shipDate
                    )}`}
                  </div>
                  <div style={{ marginLeft: `${!isMobile ? "1em" : ""}` }}>
                    <span style={{ fontFamily: "Fort-Bold" }}>Total:</span>
                    {` ${numberOfDeliveries} deliveries`}{" "}
                    <span className="deliveries-divider">|</span>{" "}
                    {`${numberOfCartons} cartons`}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {resultType === "search" &&
            !state.isLoading /*&& state.searchInput?.length > 0*/ ? (
              <div className="info-container">
                {/*<div className="search-header search-padding">Search Results</div>*/}
                <div className="search-results-subhead d-flex">
                  <span
                    style={{
                      fontFamily: "Fort-Bold",
                      paddingRight: "2px",
                      marginBottom: "1rem",
                    }}
                  >{`${rowDataToDisplay.length} `}</span>
                  deliveries matching{" "}
                  <span
                    style={{
                      fontFamily: "Fort-Bold",
                      paddingLeft: "2px",
                      paddingRight: "3px",
                    }}
                  >
                    "{searchTerm}"
                  </span>
                  <div>
                    <Popup
                      className="cp-help-tooltip"
                      content="When searching by ISBN, UPC, Purchase Order Number, or Sales Number, only the past 14 days of deliveries will be displayed. Searching by Delivery Number will display results from the past 18 months."
                      on="hover"
                      position="top center"
                      offset={[0, 5]}
                      trigger={
                        <LazyImage
                          width="12"
                          //className='text-black-50 mr-1'
                          src="/images/info-circle.svg"
                        ></LazyImage>
                      }
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {rowDataToDisplay?.length > 0 ? (
              <div
                className="info-container search-padding d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex deliveries-note">
                  <LazyImage
                    width="12"
                    //className='text-black-50 mr-1'
                    src="/images/info-circle.svg"
                  ></LazyImage>
                  <span style={{ marginLeft: ".5em", fontSize: "15px" }}>
                    Note: Dates below show when cartons have left our warehouse.
                    Please use the carrier tracking links for the most
                    up-to-date delivery estimates.
                  </span>
                </div>
                {!isMobile && (
                  <div
                    className="deliveries-shipto-dropdown-container"
                    style={{ marginLeft: ".2em" }}
                  >
                    <Dropdown
                      options={orderFilter}
                      className="od-dropdown"
                      onChange={(_e, { value }) => {
                        setOrder(value);
                      }}
                      value={order}
                      trigger={
                        <div>
                          {order === "asc"
                            ? "Sort by: Ascending"
                            : "Sort by: Descending"}
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            {
              /*!isMobile && */ rowDataToDisplay &&
                rowDataToDisplay.length > 0 && (
                  <div className="ag-theme-alpine ot-order-table deliveries-styles">
                    {rowDataToDisplay &&
                      //Object.values(rowDataToDisplay).map((deliveryGroup, i) => ((
                      Object.values(rowDataToDisplay).map(
                        (delivery, index, self) => (
                          <>
                            {!lastDeliveryDate &&
                            delivery.shipDate !== lastDeliveryDate
                              ? setLastDeliveryDate(delivery.shipDate)
                              : ""}
                            {index === 0 ||
                            delivery.shipDate !== self[index - 1].shipDate ? (
                              <div
                                style={{ display: "flex" }}
                                className="delivery-header"
                                id={delivery.deliveryNo}
                              >
                                <div
                                  style={{
                                    width: "fit-content",
                                    marginRight: "0.25rem",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "16px",
                                      position: "relative",
                                      top: "3px",
                                    }}
                                    src="/images/truck-overview-dark.svg"
                                  />
                                </div>
                                <div
                                  style={{
                                    flexGrow: 1,
                                    alignItems: "flex-end",
                                  }}
                                >
                                  <p>
                                    Shipped:{" "}
                                    <span className="fort-bold">
                                      {convertDateToDay(delivery.shipDate)}
                                    </span>{" "}
                                    {formatDateMonDayYear(delivery.shipDate)}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <Grid
                              columns={5}
                              padded
                              className="deliveries-grid"
                            >
                              <Grid.Row className="deliveries-header">
                                <Grid.Column
                                  className="deliveries-table-header-column delivery-num"
                                  mobile={10}
                                  computer={2}
                                  tablet={3}
                                >
                                  Delivery # {!isMobile ? <br /> : ""}
                                  <Link
                                    className="deliveries-header-data"
                                    to={`/delivery/${delivery.deliveryNo}`}
                                  >
                                    {delivery.deliveryNo}
                                  </Link>
                                  {isMobile && (
                                    <>
                                      <br />
                                      Ships From: {delivery.shipsFrom}
                                      <br />
                                      Ships to {delivery.shipTo}
                                    </>
                                  )}
                                </Grid.Column>
                                <Grid.Column
                                  className="deliveries-table-header-column"
                                  only="computer tablet"
                                  computer={3}
                                  tablet={3}
                                >
                                  Ships From: <br />
                                  <span
                                    className="deliveries-header-data"
                                    style={{
                                      fontWeight: "500",
                                      color: "#515556",
                                    }}
                                  >
                                    {delivery.shipsFrom}
                                  </span>
                                </Grid.Column>
                                <Grid.Column
                                  className="deliveries-table-header-column"
                                  only="computer tablet"
                                  computer={4}
                                  tablet={3}
                                >
                                  Ships To:
                                  <br />
                                  <span
                                    className="deliveries-header-data"
                                    style={{
                                      fontWeight: "500",
                                      color: "#515556",
                                    }}
                                  >
                                    {/* {showAddressOfShipTo(delivery.shipTo)} */}
                                    {delivery?.shipTo} {delivery?.address?.city}
                                    , {delivery?.address?.state}
                                  </span>
                                </Grid.Column>
                                <Grid.Column
                                  computer={3}
                                  className="deliveries-table-header-column"
                                  only="computer tablet"
                                  tablet={3}
                                >
                                  Contents:
                                  <br />
                                  {delivery.contents?.map((content, i) => (
                                    //tempContents?.map((content, i) => (
                                    <>
                                      <div
                                        className="delivery-content-item"
                                        style={{
                                          backgroundColor: `${contentsColorConverter(
                                            content
                                          )}`,
                                        }}
                                      >
                                        {content}
                                      </div>
                                    </>
                                  ))}
                                </Grid.Column>
                                <Grid.Column
                                  computer={2}
                                  mobile={6}
                                  tablet={3}
                                  className="deliveries-table-header-column"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    //width:`${!isMobile ? '20%':'30%'}`
                                  }}
                                >
                                  <PRHButton
                                    bold
                                    borderColorOverride={"transparent"}
                                    iconColorOverride="white"
                                    textColorOverride="white"
                                    backgroundHoverColorOverride="light-blue"
                                    borderColorHoverOverride="light-blue"
                                    backgroundColorOverride="blue"
                                    //iconright
                                    //icon="arrow right"
                                    //size={isMobile ? 'large':'medium'}
                                    size="medium"
                                    className="fs-2 lh-19 sign-in-button lsp--015"
                                    textClass="px-0 mx-0 mt--1px"
                                    onClick={() => {
                                      window.location.assign(
                                        `delivery/${delivery.deliveryNo}`
                                      );
                                    }}
                                    style={{ marginLeft: "auto" }}
                                    //link={`/delivery/${delivery.deliveryNo}`}
                                    /*
                                  onClick={() => {
                                    history.push({
                                      pathname: `/deliveries`,
                                    });
                                  }}*/
                                  >
                                    <span
                                      className={`${
                                        isMobile ? "mobile-button-font" : ""
                                      }`}
                                    >
                                      View Details
                                    </span>
                                  </PRHButton>
                                </Grid.Column>
                              </Grid.Row>
                              {isMobile && (
                                <Grid.Row
                                  className="content-row"
                                  style={{
                                    borderRight: "1px solid #ddd",
                                    borderLeft: "1px solid #ddd",
                                    height: "30px",
                                  }}
                                >
                                  <Grid.Column
                                    style={{ paddingTop: ".3rem" }}
                                    width={16}
                                  >
                                    Contents:
                                    {delivery.contents?.map((content, i) => (
                                      <span className="delivery-content-item">
                                        {` ${content}`}{" "}
                                        {delivery.contents > 1 ? "," : ""}
                                      </span>
                                    ))}
                                  </Grid.Column>
                                </Grid.Row>
                              )}
                              <CartonRows
                                isMobile={isMobile}
                                ref={deliveryRef}
                                cartons={delivery.cartons}
                                deliveryNo={delivery.deliveryNo}
                                setScrollToDelivery={setScrollToDelivery}
                                shipDateMsg={delivery.shipDateMsg}
                                cartonCt={delivery.cartonCt}
                              />
                            </Grid>
                          </>
                        )
                      )}
                  </div>
                )
            }

            {
              /*(!rowData || rowData?.length === 0 || state.searchInput?.length >0 || !searchResults && !(state.searchInput?.length >0)) &&*/
              state.isLoading && (
                /*!isMobile &&*/ <Loader style={{ display: "flex" }} inverted />
                //<div className="ot-no-rows ot-no-rows-height">Loading...</div>
              )
            }
            {
              /*!isMobile &&*/
              (rowData || rowDataToDisplay) &&
                (rowData.length === 0 || rowDataToDisplay.length === 0) &&
                state.searchInput?.length > 0 &&
                !state.isLoading && (
                  <div>
                    <div className="ot-no-rows">No results found.</div>
                    <div className="ot-no-rows-subheader">
                      Make sure your search request is spelled correctly or try
                      different keywords.
                    </div>
                  </div>
                )
            }
            {
              /*!isMobile &&*/
              rowData &&
                rowData.length === 0 &&
                typeof state.searchInput === "string" &&
                !state.isLoading && (
                  <div>
                    <div className="ot-no-rows">No results found.</div>
                    <div className="ot-no-rows-subheader">
                      You have no recent deliveries. Please check back later.{" "}
                      {/* or upcoming deliveries*/}
                    </div>
                  </div>
                )
            }
            {/*!isMobile &&*/
            /*searchResults && state.searchInput?.length > 0 ? (
                <div className="ot-no-rows-height">
                  <div className="ot-no-rows-subheader">
                  To see older deliveries, search by <span style={{fontFamily:'Fort-Bold'}}>delivery number.</span> 
                  </div>
                </div>
              ):''*/}
            {
              /*!isMobile &&*/ //old message: There are no recent or upcoming deliveries. Please check back later.
              (rowData || rowDataToDisplay) &&
                (rowData.length === 0 || rowDataToDisplay.length === 0) &&
                state.searchInput?.length === 0 &&
                !state.isLoading && (
                  <div className="ot-no-rows-height">
                    <div className="ot-no-rows-subheader">
                      {timeFilterValue === "Upcoming" ? (
                        <>
                          <span
                            className="empty-header"
                            style={{ paddingBottom: ".5em" }}
                          >
                            You have no recent deliveries.
                          </span>
                          <br />
                          <br />
                          <span style={{ paddingTop: "1rem" }}>
                            Please check back later.
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="empty-header"
                            style={{ paddingBottom: ".5em" }}
                          >
                            You have no recent deliveries.
                          </span>
                          <br />
                          <br />
                          <span style={{ paddingTop: "1rem" }}>
                            Please check back later.
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                )
            }

            {state.hasError && (
              <div className="ot-message">
                <Message
                  header="Sorry, we are unable to display your orders at this time."
                  content="Please try again later or contact Customer Service."
                  icon={
                    <Icon name="exclamation triangle" color="red" size="mini" />
                  }
                  color="red"
                  warning={true}
                />
              </div>
            )}
            {!isMobile && !state.isLoading && rowData && (
              <div className="ot-bottom-nav-tab">
                {/*
                <div style={{ width: "16em" }}>{""}</div>
                {state.totalPages > 1 ? (
                  <div className='ot-pagination'>
                    <PRHButton
                      disabled={
                        state.currentPageNum === 1
                          ? true
                          : false || state.isLoading
                      }
                      displayInlineFlex
                      icon='arrow left'
                      className='sidePageBtns'
                      size='small'
                      key={Math.random()}
                      onClick={() => {
                        gridApi.paginationGoToPreviousPage();
                        setState({
                          ...state,
                          currentPageNum: state.currentPageNum - 1,
                        });
                        props.history.push({
                          pathname: "/deliveries",
                          search: `?page=${state.currentPageNum - 1}`,
                          state: {
                            ...props.history.location.state,
                            currentPageNum: state.currentPageNum - 1,
                          },
                        });
                      }}
                    />*/}
                {/*<div className='ml-2 mr-2'>
                      Page {state.currentPageNum} of {state.totalPages}
                    </div>*/}
                {totalPages > 1 ? (
                  <>
                    <div className="ot-pagination ml-auto mr-auto">
                      <PRHButton
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        displayInlineFlex
                        icon="arrow left"
                        className="sidePageBtnsSeries"
                        size="small"
                        key={Math.random()}
                      />
                      <div className="ml-2 mr-2">
                        Page {currentPage} of {totalPages}
                      </div>
                      <PRHButton
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        displayInlineFlex
                        icon="arrow right"
                        className="sidePageBtnsSeries"
                        size="small"
                        key={Math.random()}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                {
                  /*!isMobile && */ showResults &&
                    rowData &&
                    !state.hasError && (
                      <div
                        className="number-of-results-holder"
                        style={{
                          width: `${state.totalPages === 1 ? "100%" : "18em"}`,
                        }}
                      >
                        <div className="fort-medium fs-14px lh-18px ml-2 number-of-results">
                          {/*state.accountFilter > 1
                        ? gridApi?.getDisplayedRowCount()
                        : 'test'/*delivery.cartons.length*/}{" "}
                          {rowDataToDisplay.length !== 0
                            ? `${calculateAggregatedLength(rowData)} results `
                            : ""}
                        </div>
                        {/* {(state.accountFilter == 1 && rowData?.length > 50) ||
                    (state.accountFilter > 1 &&
                      gridApi?.getDisplayedRowCount() > 50) ? ( */}
                        {state.totalLength > 50 && (
                          <Dropdown
                            selection
                            disabled={state.isLoading}
                            options={pageSizeOptions}
                            value={state.pageSize}
                            direction="left"
                            onChange={(e, { value }) => {
                              setState({
                                ...state,
                                pageSize: value,
                                currentPageNum: 1,
                                //start: value,
                                //end: value,
                                totalPages: Math.ceil(
                                  calculateAggregatedLength(rowData) / value
                                ),
                              });
                              props.history.push({
                                pathname: "/deliveries",
                                search: `?page=1`,
                                state: {
                                  ...props.history.location.state,
                                  pageSize: value,
                                  currentPageNum: 1,
                                  //start: value,
                                  //end: value,
                                  totalPages: Math.ceil(
                                    calculateAggregatedLength(rowData) / value
                                  ),
                                },
                              });
                              gridApi.paginationSetPageSize(value);
                            }}
                            className="tm-dropdown ot-dropdown"
                          />
                        )}
                      </div>
                    )
                }
              </div>
            )}
            {!isMobile && <BackToTopButton />}
            {/*isMobile && <BackToTopButtonMobile />*/}
          </div>
        );
      })
    )
  )
);
