import { Icon, Popup, Grid } from "semantic-ui-react";
import { PRHLabel } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import TextValidator from "../../shared-react-components/elements/input";
import "./customerenrolment.scss";
import "react-datepicker/dist/react-datepicker.css";
import CountryStateDropdownField from "./CountryStateDropdownField";
import { COUNTRY } from "./shared";

const BusinessInformationForm = ({ data, onChange, onNext, onBack }) => {
  const {
    legalBusinessName,
    respondentName,
    respondentTitle,
    countryOfOperation,
    federalEmployeeIdentificationNumber,
    soleProprietorship,
    governmentIssuedId,
    hstGstPstQstNumber,
    yearOfBusinessEstablished,
    lengthOfPresentOwnership,
    stateOfIncorporation,
  } = data;

  const mandatoryCountryFields =
    countryOfOperation === COUNTRY.US
      ? !federalEmployeeIdentificationNumber && !soleProprietorship
      : !hstGstPstQstNumber;

  const disableNext =
    (mandatoryCountryFields && !governmentIssuedId) ||
    !legalBusinessName ||
    !respondentName ||
    !respondentTitle ||
    !countryOfOperation ||
    !yearOfBusinessEstablished ||
    !lengthOfPresentOwnership ||
    !stateOfIncorporation;

  return (
    <>
      <TextValidator
        name="legalBusinessName"
        className="form-field"
        label={
          <>
            <PRHLabel className="input-label">
              <span className="text-red">*</span>Legal Business Name
            </PRHLabel>
            <Popup
              on="click"
              content="Based on how it appears on your taxes. (include LLC, INC, ..., if applicable)"
              trigger={
                <Icon
                  name="question circle outline"
                  className="input-tool-tip-icon"
                />
              }
            />
          </>
        }
        type="text"
        onChange={(e) =>
          onChange(
            "legalBusinessName",
            e.target.value === "" ? null : e.target.value
          )
        }
        value={legalBusinessName}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />
      <TextValidator
        name="respondentName"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Your Name
          </PRHLabel>
        }
        type="text"
        onChange={(e) =>
          onChange(
            "respondentName",
            e.target.value === "" ? null : e.target.value
          )
        }
        value={respondentName}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />
      <TextValidator
        name="respondentTitle"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Your Title
          </PRHLabel>
        }
        type="text"
        onChange={(e) =>
          onChange(
            "respondentTitle",
            e.target.value === "" ? null : e.target.value
          )
        }
        value={respondentTitle}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />
      <CountryStateDropdownField
        className="mb-4"
        type="country"
        value={countryOfOperation}
        important={true}
        label="Country of Operation"
        whitelist={[COUNTRY.US, COUNTRY.Canada]}
        onChange={(val) => onChange("countryOfOperation", val)}
      />
      {countryOfOperation === COUNTRY.US && (
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column width={7}>
              <TextValidator
                id="federalEmployeeIdentificationNumber"
                name="federalEmployeeIdentificationNumber"
                placeholder="12-3456789"
                className="form-field"
                validators={["required"]}
                label={
                  <>
                    <PRHLabel className="input-label">F.E.I.N.</PRHLabel>
                    <Popup
                      on="click"
                      content={`Please enter one of "F.E.I.N." (Federal Employer Identification
                  Number) "Sole Proprietorship" (Social Security Number), or
                  "Government Issued ID" (primarily for international customers).`}
                      trigger={
                        <Icon
                          name="question circle outline"
                          className="input-tool-tip-icon"
                        />
                      }
                    />
                  </>
                }
                onChange={(e) =>
                  onChange(
                    "federalEmployeeIdentificationNumber",
                    e.target.value === "" ? null : e.target.value
                  )
                }
                value={federalEmployeeIdentificationNumber}
                errorMessages={["This field is required"]}
                width={16}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle" className="mt-3">
              <p className="my-2 text-center">OR</p>
            </Grid.Column>
            <Grid.Column width={7}>
              <TextValidator
                id="soleProprietorship"
                name="soleProprietorship"
                placeholder="Last 4 digits of SS#"
                className="form-field"
                label={
                  <>
                    <PRHLabel className="input-label">
                      Sole Proprietorship
                    </PRHLabel>
                    <Popup
                      on="click"
                      content={`Please enter one of "F.E.I.N." (Federal Employer Identification
                  Number) "Sole Proprietorship" (Social Security Number), or
                  "Government Issued ID" (primarily for international customers).`}
                      trigger={
                        <Icon
                          name="question circle outline"
                          className="input-tool-tip-icon"
                        />
                      }
                    />
                  </>
                }
                type="text"
                onChange={(e) =>
                  onChange(
                    "soleProprietorship",
                    e.target.value === "" ? null : e.target.value
                  )
                }
                value={soleProprietorship}
                validators={["required"]}
                errorMessages={["This field is required"]}
                width={16}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {countryOfOperation === COUNTRY.Canada && (
        <TextValidator
          id="hstGstPstQstNumber"
          name="hstGstPstQstNumber"
          className="form-field"
          placeholder="e.g. 121832950RT0001"
          label={
            <PRHLabel className="input-label">
              Provide the applicable HST/GST/PST/QST number
            </PRHLabel>
          }
          type="text"
          onChange={(e) =>
            onChange(
              "hstGstPstQstNumber",
              e.target.value === "" ? null : e.target.value
            )
          }
          value={hstGstPstQstNumber}
          validators={["required"]}
          errorMessages={["This field is required"]}
          width={16}
        />
      )}

      <TextValidator
        id="governmentIssuedId"
        name="governmentIssuedId"
        className="form-field"
        placeholder="This is for international accounts only"
        label={
          <>
            <PRHLabel className="input-label">Government Issued ID#</PRHLabel>
            <Popup
              on="click"
              content={`Please enter one of "F.E.I.N." (Federal Employer Identification
                  Number) "Sole Proprietorship" (Social Security Number), or
                  "Government Issued ID" (primarily for international customers).`}
              trigger={
                <Icon
                  name="question circle outline"
                  className="input-tool-tip-icon"
                />
              }
            />
          </>
        }
        type="text"
        onChange={(e) =>
          onChange(
            "governmentIssuedId",
            e.target.value === "" ? null : e.target.value
          )
        }
        value={governmentIssuedId}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="yearOfBusinessEstablished"
        name="yearOfBusinessEstablished"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Date (year) business was
            established
          </PRHLabel>
        }
        type="text"
        onChange={(e) =>
          onChange(
            "yearOfBusinessEstablished",
            e.target.value === "" ? null : e.target.value
          )
        }
        value={yearOfBusinessEstablished}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="lengthOfPresentOwnership"
        name="lengthOfPresentOwnership"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Length of Present Ownership
          </PRHLabel>
        }
        onChange={(e) =>
          onChange(
            "lengthOfPresentOwnership",
            e.target.value === "" ? null : e.target.value
          )
        }
        value={lengthOfPresentOwnership}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <CountryStateDropdownField
        className="mb-4"
        type="region"
        value={stateOfIncorporation}
        countryForRegion={countryOfOperation}
        label={
          countryOfOperation === COUNTRY.Canada
            ? "Province/Territory of Incorporation"
            : "State of Incorporation"
        }
        important={true}
        onChange={(val) => onChange("stateOfIncorporation", val)}
      />

      <div className="d-flex flex-row Xbutton-holder my-5">
        <PRHButton
          size="large"
          iconleft
          icon="arrow left"
          className="mr-auto"
          onClick={onBack}
        >
          Back
        </PRHButton>
        <PRHButton
          disabled={disableNext}
          iconright
          icon="arrow right"
          size="large"
          className="ml-auto"
          onClick={onNext}
        >
          Billing Address
        </PRHButton>
      </div>
      <div className="d-flex flex-row my-5 fs-12px">
        <span className="text-red mr-1">*</span> denotes that this field is
        required to proceed
      </div>
    </>
  );
};

export default BusinessInformationForm;
