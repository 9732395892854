import lscache from 'lscache';
import Persister from './persister';

export default class LocalStorage extends Persister {
  constructor(keyObject) {
    super(keyObject);
    this.LS_KEY = 'localstorage';
  }

  clear() {
    lscache.remove(this.getKey());
  }

  async get(what = null) {
    let whatToGet = what;
    if (this.isArray && !whatToGet) {
      return [];
    } if (!whatToGet) {
      whatToGet = this.keyObject.getObject().assetTypeCode;
    }

    let data = await lscache.get(this.getKey());
    data = data || {};
    return data[whatToGet];
  }

  async set(value) {
    try {
      let data = await lscache.get(this.getKey());
      data = data || {};
      data[this.keyObject.getObject().assetTypeCode] = value;
      await lscache.set(this.getKey(), data);
      return true;
    } catch (e) {
      //console.log(e);
      return 'ERROR';
    }
  }
}
