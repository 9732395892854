import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Dropdown,
  Input,
  Form,
  Message,
  Icon,
  Popup,
  Container,
} from "semantic-ui-react";
import { withRouter } from "react-router";
import { AgGridReact } from "ag-grid-react";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import PRHButtonStandard from "../../shared-react-components/elements/prhbutton";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import "./OrdersTab.scss";
import "./BackorderedTitlesTab.scss";
import LazyImage from "../../utils/LazyImage";
import print from "print-js";
import { Export, ExportCSV } from "../../utils/Export";
import orderNumberRenderer from "./renderers/orderNumberRenderer";
import { findIconDefinition, icon } from "@fortawesome/fontawesome-svg-core";
import _ from "lodash";
import { MobileOptionsMenu } from "../../components/header/components/MobileOptionsMenu";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Divider } from "../../components/elements/elements";
import { OrderInvoiceTitleSearchMobile } from "../../components/common/OrderInvoiceTitleSearchMobile";
import BackordersMobileTable from "../../shared-react-components/elements/BackordersMobileTable";
import { useLazyGetOrdersQuery } from "../../api/ecomApi";

const pageSizeOptions = [
  {
    key: 50,
    text: "50 per page",
    value: 50,
  },
  {
    key: 100,
    text: "100 per page",
    value: 100,
  },
  {
    key: 150,
    text: "150 per page",
    value: 150,
  },
  {
    key: 200,
    text: "200 per page",
    value: 200,
  },
];

const dateFilters = [
  {
    key: 30,
    text: "Past 30 days",
    value: 30,
  },
  {
    key: 92,
    text: "Past 3 months",
    value: 92,
  },
  {
    key: 184,
    text: "Past 6 months",
    value: 184,
  },
  {
    key: 366,
    text: "Past 12 months",
    value: 366,
  },
  {
    key: 550,
    text: "Past 18 months",
    value: 550,
  },
];

export const BackorderedTitlesTab = withRouter(
  withSecurity(
    withApp(
      withCart((props) => {
        const isMobile = useMediaQuery({
          query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
        });

        const [performGetOrders, getOrdersResult] = useLazyGetOrdersQuery();

        const propPage = Number(props.location.search?.split("=")[1] || 1);

        const [rowData, setRowData] = useState(undefined);
        const [isLoading, setIsLoading] = useState(false);
        const [state, setState] = useState({
          // isLoading: false,
          pageSize: 50,
          currentPageNum: propPage,
          totalPages: 0,
          rowHeight: 90,
          currentSort: "dateCreated_desc",
          columnDefs: [
            {
              headerName: "TITLE, ISBN",
              field: "title",
              cellRenderer: "titleColumnComponent",
              checkboxSelection: true,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              cellClass: ["no-border"],
              autoHeight: true,
              minWidth: 280,
              suppressSizeToFit: true,
            },
            {
              headerName: "SALES ORDER #",
              field: "salesOrderNumber",
              cellRenderer: "orderNumberRenderer",
              cellClass: ["btt-isbn no-border"],
              minWidth: 110,
            },
            {
              headerName: "PURCHASE ORDER",
              field: "poNumber",
              cellClass: ["btt-po no-border"],
              minWidth: 110,
            },
            {
              headerName: "DATE CREATED",
              field: "dateCreated",
              sortable: true,
              sort: "desc",
              sortingOrder: ["desc", "asc"],
              cellClass: ["no-border"],
              minWidth: 110,
            },
            {
              headerName: "SHIP-TO ACCOUNT",
              field: "account",
              cellClass: ["no-border"],
              minWidth: 110,
            },
            {
              headerName: "QTY",
              field: "quantity",
              cellClass: ["no-border"],
              maxWidth: 60,
              suppressSizeToFit: true,
            },
          ],
          dayFilter: props.location.state?.dayFilter
            ? props.location.state?.dayFilter
            : dateFilters[0].value,
          accountFilter: props.location.state?.accountFilter
            ? props.location.state?.accountFilter
            : "1",
          accountFilters: [],
          selectedRows: [],
          searchInput: props.location.state?.searchInput
            ? [props.location.state.searchInput]
            : [],
          hasInput: props.history.location.state?.hasInput
            ? props.history.location.state?.hasInput
            : false,
          hasMore: false,
          hasError: false,
          start: 0,
          rows: 1000,
        });
        const [gridApi, setGridApi] = useState(null);

        const [sortMenuVisible, setSortMenuVisible] = useState(false);
        const [accountMenuVisible, setAccountMenuVisible] = useState(false);

        const [mobileRowData, setMobileRowData] = useState(rowData);

        useEffect(() => {
          // console.log('backorder load', props);
          if (props.history.action !== "PUSH" && props.history.location.state) {
            setState({
              ...state,
              dayFilter: props.history.location.state.dayFilter
                ? props.history.location.state.dayFilter
                : state.dayFilter,
              accountFilter: props.history.location.state?.accountFilter
                ? props.history.location.state?.accountFilter
                : state.accountFilter,
              documentFilter: props.history.location.state?.documentFilter
                ? props.history.location.state?.documentFilter
                : state.documentFilter,
              documentFilters: props.history.location.state?.documentFilters
                ? props.history.location.state?.documentFilters
                : state.documentFilters,
              mobileDocumentFilter: props.location.state?.mobileDocumentFilter
                ? props.location.state?.mobileDocumentFilter
                : state.mobileDocumentFilter,
              pageSize: props.history.location.state?.pageSize
                ? props.history.location.state?.pageSize
                : state.pageSize,
              currentPageNum: props.history.location.state?.currentPageNum
                ? props.history.location.state?.currentPageNum
                : state.currentPageNum,
              totalPages: props.history.location.state?.totalPages
                ? props.history.location.state?.totalPages
                : state.totalPages,
              searchInput: props.history.location.state?.searchInput
                ? [props.history.location.state?.searchInput]
                : state.searchInput,
              hasInput: props.history.location.state?.hasInput
                ? props.history.location.state?.hasInput
                : state.hasInput,
            });
          }
        }, []);

        useEffect(() => {
          if (state.accountFilter === "1") {
            setMobileRowData(rowData);
          } else {
            const filteredData = rowData?.filter(
              (data) => data.account === state.accountFilter
            );
            setMobileRowData(filteredData);
          }
        }, [rowData, state.accountFilter]);

        function onFilterTextBoxChanged() {
          const { searchInput } = state;
          performSearch();
          props.history.push({
            pathname: "/backorders",
            state: {
              ...props.history.location.state,
              searchInput: searchInput.length > 0 ? [searchInput] : [],
            },
          });
        }

        const search = (event) => {
          var code = event.keyCode || event.which;
          if (code === 13) {
            onFilterTextBoxChanged();
          }
        };

        function onGridReady(params) {
          setGridApi(params.api);
          params.api.sizeColumnsToFit();
        }

        const setData = (dataProps) => {
          const currentQueryId = props.cart.backorderQueryId;

          const uniqueShipToAccountNumbers = [
            ...new Set(dataProps.data.orders.map((item) => item.account)),
          ].map((item) => {
            return {
              id: item,
              text: String(item),
              value: item,
            };
          });

          if (currentQueryId === null) {
            props.cart.setBackorderQueryId(dataProps.params.queryId);
          }
          setRowData(dataProps.data.orders);
          setState({
            ...state,
            totalPages: Math.ceil(
              dataProps.data.orders.length / state.pageSize
            ),
            queryId: dataProps.params.queryId,

            accountFilters: [
              {
                key: 1,
                text: "All Accounts",
                value: "1",
              },
              ...uniqueShipToAccountNumbers,
            ],
          });
          setIsLoading(false);

          if (gridApi) {
            gridApi.hideOverlay();
          }
        };

        useEffect(() => {
          if (props?.location?.state?.searchInput) {
            setState({
              ...state,
              hasInput: true,
              // searchShipTo: 'jeff',
              searchInput: [props.location.state.searchInput],
            });
          }
        }, [props.location.state]);

        useEffect(() => {
          performSearch();
        }, [state.dayFilter, props.cart.customerLoaded, state.start]);

        const performSearch = () => {
          console.log("performSearch");
          const dataParams = {
            //queryId: props.cart.backorderQueryId,
            account: null,
            docType: [],
            documentNumber: [],
            isbn: [],
            partnerFunction: [],
            purchaseOrder: [],
            salesOrg: [],
            dateFrom: moment(new Date(), "YYYY-MM-DD")
              .subtract(state.dayFilter, "days")
              .format("YYYY-MM-DD")
              .slice(0, 10),
            dateTo: moment(new Date(), "YYYY-MM-DD").toISOString().slice(0, 10),
            allowPricing: true,
            boOnly: true,
            headerOnly: false,
            start: state.start,
            rows: state.dayFilter !== 270 ? state.rows : 100000,
            account: [
              props.cart?.customerInfo?.currentUser?.roleNo === "W1"
                ? props.cart?.customerInfo?.customer?.accountNo
                : "",
            ],
          };

          if (gridApi) {
            gridApi.showLoadingOverlay();
          }

          // console.log('performSearch', state, props);
          // setState({ ...state, isLoading: true });
          setIsLoading(true);
          if (state.searchInput.length > 0) {
            const input = state.searchInput[0].toString().trim();
            // const isIsbn =
            //   (input.startsWith('978') || input.startsWith('979')) &&
            //   input.replaceAll('-', '').length === 13;
            // if (isIsbn) {
            const isbn = input?.replaceAll("-", "");
            performGetOrders({
              data: { ...dataParams, isbn: [isbn], dateFrom: null },
              sapAccountNo: props.cart.ccSapAccountNo,
            }).then((ordersResponse) => {
              const { data } = ordersResponse;
              if (ordersResponse.data?.status === "OK") {
                if (data.data.rowCount === 0) {
                  performGetOrders({
                    data: {
                      ...dataParams,
                      purchaseOrder: [input],
                      dateFrom: null,
                    },
                    sapAccountNo: props.cart.ccSapAccountNo,
                  }).then((ordersResponse) => {
                    const { data } = ordersResponse;
                    if (ordersResponse.data?.status === "OK") {
                      // console.log('orders ordersResponse', ordersResponse);
                      if (data.data.rowCount === 0) {
                        performGetOrders({
                          data: {
                            ...dataParams,
                            documentNumber: [input],
                            dateFrom: null,
                          },
                          sapAccountNo: props.cart.cart.accountNo,
                        }).then((ordersResponse) => {
                          if (ordersResponse.data?.status === "OK") {
                            const { data } = ordersResponse;
                            // console.log('orders ordersResponse', ordersResponse);
                            setData(data);
                          } else {
                            setState({
                              ...state,
                              hasError: true,
                            });
                            setIsLoading(false);
                          }
                        });
                      } else {
                        setData(data);
                      }
                    } else {
                      setState({
                        ...state,
                        hasError: true,
                      });
                      setIsLoading(false);
                    }
                  });
                } else {
                  setData(data);
                }
              } else {
                setState({ ...state, hasError: true });
                setIsLoading(false);
              }
            });
          } else {
            performGetOrders({
              data: dataParams,
              sapAccountNo: props.cart.ccSapAccountNo,
            }).then((ordersResponse) => {
              if (ordersResponse.data?.status === "OK") {
                // console.log("ordersResponse", ordersResponse);
                const { data } = ordersResponse;
                setData(data);
              } else {
                setState({ ...state, hasError: true });
                setIsLoading(false);
              }
            });
          }
        };

        const downloadOptions = [
          {
            key: 50,
            text: "Print",
            value: 50,
            icon: { name: "print", color: "orange" },
            onClick: () => printFile(),
          },
          {
            key: 100,
            text: "Download Excel",
            value: 100,
            icon: { name: "file excel outline", color: "orange" },
            onClick: () => exportExcelFile(),
          },
          {
            key: 150,
            text: "Download CSV",
            value: 100,
            icon: { name: "file outline", color: "orange" },
            onClick: () => exportCSVFile(),
          },
        ];

        const printFile = () => {
          const dataToExport =
            state.accountFilter === "1"
              ? rowData
              : rowData.filter((item) => {
                  if (state.accountFilter === item.account) {
                    return item;
                  }
                });
          print({
            printable:
              state.selectedRows.length > 0 ? state.selectedRows : dataToExport,
            properties: [
              "isbn",
              "title",
              "salesOrderNumber",
              "poNumber",
              "dateCreated",
              "account",
              "quantity",
            ],
            type: "json",
          });
        };

        const exportCSVFile = () => {
          const dataToExport =
            state.accountFilter === "1"
              ? rowData
              : rowData.filter((item) => {
                  if (state.accountFilter === item.account) {
                    return item;
                  }
                });
          ExportCSV.DocumentDetails({
            fileName: `backordered-titles-list-${moment().format(
              "YYYY-MM-DD"
            )}`,
            metadata: [
              [
                "Inquiry for",
                `Search for backordered titles with date between ${moment()
                  .subtract(state.dayFilter, "days")
                  .format("MM/DD/YYYY")} and ${moment().format("MM/DD/YYYY")}`,
              ],
              ["Inquiry date", moment().format("MM/DD/YYYY")],
            ],
            list: (state.selectedRows.length > 0
              ? state.selectedRows
              : dataToExport
            ).map((item) => {
              return {
                ISBN: item.isbn,
                Title: item.title,
                "Sales Order Number": item.salesOrderNumber,
                "Purchase Order Number": item.poNumber,
                "Date Created": item.dateCreated,
                "Sold To Account": item.account,
                Quantity: item.quantity,
              };
            }),
          });
        };

        const exportExcelFile = () => {
          const dataToExport =
            state.accountFilter === "1"
              ? rowData
              : rowData.filter((item) => {
                  if (state.accountFilter === item.account) {
                    return item;
                  }
                });
          Export.DocumentDetails({
            fileName: `backordered-titles-list-${moment().format(
              "YYYY-MM-DD"
            )}`,
            metadata: [
              [
                "Inquiry for",
                `Search for backordered titles with date between ${moment()
                  .subtract(state.dayFilter, "days")
                  .format("MM/DD/YYYY")} and ${moment().format("MM/DD/YYYY")}`,
              ],
              ["Inquiry date", moment().format("MM/DD/YYYY")],
            ],
            list: (state.selectedRows.length > 0
              ? state.selectedRows
              : dataToExport
            ).map((item) => {
              return {
                ISBN: item.isbn,
                Title: item.title,
                "Sales Order Number": item.salesOrderNumber,
                "Purchase Order Number": item.poNumber,
                "Date Created": item.dateCreated,
                "Sold To Account": item.account,
                Quantity: item.quantity,
              };
            }),
          });
        };

        const getNumOfDisplayedRows = () => {
          const dataToExport =
            state.accountFilter === "1"
              ? rowData
              : rowData?.filter((item) => {
                  if (state.accountFilter === item.account) {
                    return item;
                  }
                });
          return dataToExport.length;
        };

        const getAccountFilterText = (key, accountFilters) => {
          const item = accountFilters?.find((option) =>
            key === "1" ? option.key === 1 : option.id === key
          );
          return item?.text;
        };

        function debounce(func, time) {
          var time = time || 100; // 100 by default if no param
          var timer;
          return function (event) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(func, time, event);
          };
        }

        // Function with stuff to execute
        function resizeContent() {
          if (gridApi) {
            // gridApi.sizeColumnsToFit();
            // gridApi.resetRowHeights();
          }
        }

        const coverHover = (isbn) => {
          return (
            <div className='d-flex flex-row'>
              <LazyImage
                className='hover-cover'
                src={`https://images.penguinrandomhouse.com/cover/${isbn}`}
                isSmallThumbNail={true}
              />
            </div>
          );
        };

        const accountChange = (accountId) => {
          setState({
            ...state,
            accountFilter: accountId,
            selectedRows: [],
          });
          gridApi.setQuickFilter(accountId !== "1" ? accountId : "");
          // gridApi.deselectAllFiltered();
        };

        const titleColumnComponent = (componentProps) => {
          return (
            <div
              className='btt-title-container btt-ie11'
              key={componentProps.data.isbn}
            >
              <Popup
                size='huge'
                className='hover-cover'
                content={coverHover(componentProps.data.isbn)}
                on='hover'
                hoverable={true}
                position='top center'
                offset={[0, 10]}
                trigger={
                  <div style={{ height: "45px" }}>
                    <img
                      alt='/'
                      className='btt-title-image mr-1'
                      width={30}
                      src={`https://images.penguinrandomhouse.com/cover/${componentProps.data.isbn}?alt=image_coming.gif`}
                    />
                    {componentProps.data.isVariant ? (
                      <div
                        className={`position-absolute fs-10px lh-16 fort-bold-italic var-overlay`}
                        style={{ width: "30px" }}
                      >
                        VAR
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                }
              />
              <a
                href={`/titleinfo/${componentProps.data.isbn}`}
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push(`/titleinfo/${componentProps.data.isbn}`);
                }}
                className='m-auto d-flex align-items-center'
              />
              <div className='btt-title-data-container'>
                <div className='btt-title'>
                  <a
                    href={`/titleinfo/${componentProps.data.isbn}`}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push(
                        `/titleinfo/${componentProps.data.isbn}`
                      );
                    }}
                  >
                    {componentProps.data.title}
                  </a>
                </div>
                <div className='btt-isbn'>
                  <a
                    href={`/titleinfo/${componentProps.data.isbn}`}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push(
                        `/titleinfo/${componentProps.data.isbn}`
                      );
                    }}
                  >
                    {componentProps.data.isbn}
                  </a>
                </div>
              </div>
            </div>
          );
        };

        // Eventlistener
        window.addEventListener("resize", debounce(resizeContent, 150));

        const caretUp = findIconDefinition({
          prefix: "fas",
          iconName: "caret-up",
        });
        const caretDown = findIconDefinition({
          prefix: "fas",
          iconName: "caret-down",
        });
        const caretUpIcon = icon(caretUp);
        const caretDownIcon = icon(caretDown);

        return (
          <div>
            <div
              className={`oi-filter-row ${
                isMobile ? "mobile-oi-filter-row mb-0" : ""
              }`}
            >
              {isMobile && !state.hasError && (
                <div className='mobile-orders-tab-search-container mb-3 mt-1'>
                  <Container>
                    <OrderInvoiceTitleSearchMobile
                      title={
                        mobileRowData?.length === 1
                          ? "Backorder Title"
                          : "Backordered Titles"
                      }
                      count={mobileRowData?.length}
                      onSearch={(search) => {
                        setState({
                          ...state,
                          searchInput: search ? [search] : [],
                        });
                        props.history.push({
                          pathname: "/backorders",
                          state: {
                            ...props.history.location.state,
                            searchInput: search ? [search] : [],
                          },
                        });
                      }}
                      placeholder='Search Order #, PO #, or ISBN'
                    />
                  </Container>
                </div>
              )}
              {!isMobile && (
                <div className='oi-filter-row-left'>
                  <Dropdown
                    selection
                    disabled={isLoading}
                    options={dateFilters}
                    value={state.dayFilter}
                    direction='left'
                    onChange={(e, { value }) => {
                      setState({ ...state, dayFilter: value, searchInput: "" });
                      props.history.push({
                        pathname: "/backorders",
                        state: {
                          ...props.history.location.state,
                          dayFilter: value,
                        },
                      });
                    }}
                    className='tm-dropdown ot-dropdown'
                  />
                  {props.cart.customerInfo.currentUser &&
                    props.cart.customerInfo.currentUser.roleNo !== "W1" &&
                    state.accountFilters.length > 2 && (
                      <Dropdown
                        selection
                        disabled={
                          isLoading ||
                          rowData?.length === 0 ||
                          (rowData?.length === 0 &&
                            state.searchInput?.length > 0)
                        }
                        options={state.accountFilters}
                        value={state.accountFilter}
                        direction='left'
                        onChange={(e, { value }) => {
                          accountChange(value);
                          props.history.push({
                            pathname: "/backorders",
                            state: {
                              ...props.history.location.state,
                              accountFilter: value,
                            },
                          });
                        }}
                        className='tm-dropdown ot-dropdown'
                      />
                    )}
                  <Dropdown
                    selection
                    className='od-dropdown'
                    text='Download or Print'
                    disabled={isLoading || rowData?.length === 0}
                    options={downloadOptions}
                    direction='left'
                  />
                </div>
              )}

              {isMobile && !state.hasError && (
                <Container className='mobile-orders-tab-filters-container'>
                  <div className='oi-filter-row-left mb-0'>
                    <div>
                      {props.cart.customerInfo.currentUser &&
                        props.cart.customerInfo.currentUser.roleNo !== "W1" &&
                        state.accountFilters.length > 1 && (
                          <>
                            <PRHButton
                              className='mobile-options-button mb-1 px-1 fs-12px'
                              onClick={() => setAccountMenuVisible(true)}
                              disabled={
                                isLoading ||
                                rowData?.length === 0 ||
                                (rowData?.length === 0 &&
                                  state.searchInput?.length > 0)
                              }
                            >
                              {state.accountFilter != 1 ? "Acc#: " : ""}
                              {getAccountFilterText(
                                state.accountFilter,
                                state.accountFilters
                              )}{" "}
                              <Icon name='triangle down' />
                            </PRHButton>
                            <MobileOptionsMenu
                              title='Account'
                              onClose={() =>
                                setAccountMenuVisible(!accountMenuVisible)
                              }
                              onOutsideMenuClick={() =>
                                setAccountMenuVisible(!accountMenuVisible)
                              }
                              menuItems={state.accountFilters}
                              menuVisible={accountMenuVisible}
                              className='download-dropdown-catalog'
                              onOptionClick={(option) => {
                                setState({
                                  ...state,
                                  accountFilter: option.value,
                                });
                                props.history.push({
                                  pathname: "/backorders",
                                  state: {
                                    ...props.history.location.state,
                                    accountFilter: option.value,
                                  },
                                });
                              }}
                              radio
                              currentSort={state.accountFilter}
                            />
                          </>
                        )}
                    </div>
                    <div>
                      <PRHButton
                        className='mobile-options-button mb-1 px-1 fs-12px'
                        onClick={() => setSortMenuVisible(true)}
                        disabled={isLoading}
                      >
                        {
                          dateFilters.find((d) => d.value === state.dayFilter)
                            .text
                        }

                        <Icon name='triangle down' />
                      </PRHButton>
                      <MobileOptionsMenu
                        title='Sort By'
                        onClose={() => setSortMenuVisible(!sortMenuVisible)}
                        onOutsideMenuClick={() =>
                          setSortMenuVisible(!sortMenuVisible)
                        }
                        menuItems={dateFilters}
                        menuVisible={sortMenuVisible}
                        className='download-dropdown-catalog'
                        onOptionClick={(option) =>
                          setState({
                            ...state,
                            dayFilter: option.value,
                            searchInput: "",
                          })
                        }
                        radio
                        currentSort={state.dayFilter}
                      />
                    </div>
                  </div>
                </Container>
              )}

              {!isMobile && (
                <div>
                  {/*<Form onSubmit={onFilterTextBoxChanged}>*/}
                  <Input
                    className={`ot-input ${
                      state.searchInput.length > 0 ? "active" : ""
                    }`}
                    id='searchlist'
                    name='searchlist'
                    type='text'
                    icon={{
                      name: "search",
                      link: true,
                      onClick: () => onFilterTextBoxChanged(),
                    }}
                    onKeyPress={search}
                    value={
                      state.searchInput.length > 0
                        ? state.searchInput[0].toString().trim()
                        : ""
                    }
                    /*
                      onChange={(e, data) => {
                        if (data.value !== '' && state.hasInput === false) {
                          setState({ ...state, hasInput: true });
                        } else if (data.value === '') {
                          setState({ ...state, hasInput: false });
                        }
                      }}*/
                    onChange={(e, data) => {
                      // console.log('value', data);
                      if (data.value !== "") {
                        setState({
                          ...state,
                          searchInput: [data.value],
                          hasInput: true,
                        });
                      } else if (data.value === "") {
                        setState({
                          ...state,
                          searchInput: [],
                          hasInput: false,
                        });
                      }
                    }}
                    disabled={isLoading}
                    placeholder={"Search Order #, PO #, or ISBN"}
                  />
                  {/*</Form>*/}
                </div>
              )}
            </div>
            {!isMobile && rowData?.length > 0 && !state.hasError && (
              <div className='ag-theme-alpine ot-order-table'>
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={state.columnDefs}
                  rowData={rowData}
                  // rowHeight={state.rowHeight}
                  rowSelection={"multiple"}
                  gridOptions={{
                    icons: {
                      sortAscending: () => caretUpIcon.node[0],
                      sortDescending: () => caretDownIcon.node[0],
                    },
                  }}
                  onPaginationChanged={(props) => {
                    window.scrollTo(0, 0);
                    setState({
                      ...state,
                      currentPageNum: props.api.paginationGetCurrentPage() + 1,
                      totalPages: props.api.paginationGetTotalPages(),
                    });
                    // props.api.resetRowHeights()
                  }}
                  suppressRowClickSelection={true}
                  pagination={true}
                  suppressContextMenu={true}
                  defaultColDef={{
                    wrapText: true,
                    sortable: true,
                    resizable: true,
                    cellClass: "stringRow",
                    suppressMenu: true,
                    suppressMovable: true,
                  }}
                  excelStyles={[
                    {
                      id: "stringRow",
                      dataType: "string",
                    },
                  ]}
                  onFirstDataRendered={(dataProps) => {
                    setState({
                      ...state,
                      currentPageNum: propPage,
                    });
                    dataProps.api.paginationGoToPage(propPage - 1);
                    dataProps.api.sizeColumnsToFit();
                    dataProps.api.resetRowHeights();
                  }}
                  suppressPaginationPanel={true}
                  paginationPageSize={state.pageSize}
                  domLayout='autoHeight'
                  rowClass='grid-row-style'
                  // headerHeight={51}
                  onSelectionChanged={(e) => {
                    setState({
                      ...state,
                      selectedRows: e.api.getSelectedRows(),
                    });
                  }}
                  frameworkComponents={{
                    titleColumnComponent: titleColumnComponent,
                    orderNumberRenderer: orderNumberRenderer,
                    customLoadingOverlay: () => (
                      <div className='ot-no-rows ag-overlay-loading-center'>
                        Loading...
                      </div>
                    ),
                  }}
                  overlayNoRowsTemplate={"No backordered titles"}
                  loadingOverlayComponent={"customLoadingOverlay"}
                  // onColumnResized={onColumnResized}
                  // onColumnVisible={onColumnVisible}
                />
              </div>
            )}

            {isMobile && <Divider tight className='mt-2 mb-2' />}
            {isMobile && !isLoading && (
              <Container className='mb-4'>
                <BackordersMobileTable
                  data={mobileRowData}
                  noDataMessage={
                    mobileRowData &&
                    mobileRowData.length === 0 &&
                    state.searchInput?.length === 0 ? (
                      <div className='ot-no-rows-height'>
                        <div className='ot-no-rows'>
                          You have no backordered titles.
                        </div>
                        <div className='ot-no-rows-subheader'>
                          Any backordered titles in your orders will appear
                          here.
                        </div>
                      </div>
                    ) : mobileRowData &&
                      mobileRowData.length === 0 &&
                      state.searchInput?.length > 0 ? (
                      <div className='ot-no-rows-height'>
                        <div className='ot-no-rows'>No results found.</div>
                        <div className='ot-no-rows-subheader'>
                          Make sure your search request is spelled correctly or
                          try different keywords.
                        </div>
                      </div>
                    ) : null
                  }
                />
              </Container>
            )}

            {isLoading && isMobile && (
              <div className='ot-no-rows ot-no-rows-height'>Loading...</div>
            )}

            {(_.isNil(rowData) || rowData?.length === 0) &&
              isLoading &&
              !isMobile && (
                <div className='ot-no-rows ot-no-rows-height'>Loading...</div>
              )}
            {!isMobile &&
              !state.hasError &&
              rowData?.length === 0 &&
              state.searchInput.length === 0 &&
              !isLoading && (
                <div className='ot-no-rows-height'>
                  <div className='ot-no-rows'>
                    You have no backordered titles.
                  </div>
                  <div className='ot-no-rows-subheader'>
                    Any backordered titles in your orders will appear here.
                  </div>
                </div>
              )}
            {!isMobile &&
              rowData &&
              rowData.length === 0 &&
              state.searchInput.length > 0 &&
              !isLoading && (
                <div className='ot-no-rows-height'>
                  <div className='ot-no-rows'>No results found.</div>
                  <div className='ot-no-rows-subheader'>
                    Make sure your search request is spelled correctly or try
                    different keywords.
                  </div>
                </div>
              )}
            {state.hasError && (
              <div className='ot-message'>
                <Message
                  header='Sorry, we are unable to display your backordered titles at this time.'
                  content='Please try again later or contact Customer Service.'
                  icon={
                    <Icon name='exclamation triangle' color='red' size='mini' />
                  }
                  color='red'
                  warning={true}
                />
              </div>
            )}
            {!isMobile && !isLoading && rowData && !state.hasError && (
              <div className='ot-bottom-nav-tab'>
                <div style={{ width: "16em" }}>{""}</div>
                {state.totalPages > 1 ? (
                  <div className='ot-pagination'>
                    <PRHButtonStandard
                      className='sidePageBtns'
                      displayInlineFlex
                      disabled={
                        (state.currentPageNum === 1 ? true : false) || isLoading
                      }
                      size='small'
                      icon='arrow left'
                      key={Math.random()}
                      onClick={() => {
                        gridApi.paginationGoToPreviousPage();
                        setState({
                          ...state,
                          currentPageNum: state.currentPageNum - 1,
                        });
                        props.history.push({
                          pathname: "/backorders",
                          search: `?page=${state.currentPageNum - 1}`,
                        });
                      }}
                    />
                    <div className='ml-2 mr-2'>
                      Page {state.currentPageNum} of {state.totalPages}
                    </div>
                    <PRHButtonStandard
                      displayInlineFlex
                      disabled={
                        (state.currentPageNum === state.totalPages
                          ? true
                          : false) || isLoading
                      }
                      className='sidePageBtns'
                      size='small'
                      icon='arrow right'
                      key={Math.random()}
                      onClick={() => {
                        gridApi.paginationGoToNextPage();
                        setState({
                          ...state,
                          currentPageNum: state.currentPageNum + 1,
                        });
                        props.history.push({
                          pathname: "/backorders",
                          search: `?page=${state.currentPageNum + 1}`,
                        });
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                {!isMobile && rowData && !state.hasError && (
                  <div
                    className='number-of-results-holder'
                    style={{ width: "16em" }}
                  >
                    <div className='fort-medium fs-14px lh-18px ml-2 number-of-results'>
                      {/* {state.rowData?.length} results
                       */}
                      {getNumOfDisplayedRows()} results
                    </div>
                    {rowData.length > 50 && (
                      <Dropdown
                        selection
                        disabled={isLoading}
                        options={pageSizeOptions}
                        value={state.pageSize}
                        direction='left'
                        onChange={(e, { value }) => {
                          setState({
                            ...state,
                            pageSize: value,
                            currentPageNum: 1,
                            totalPages: Math.ceil(rowData.length / value),
                          });
                          props.history.push({
                            pathname: "/backorders",
                            search: `?page=1`,
                          });
                          gridApi.paginationSetPageSize(value);
                        }}
                        className='tm-dropdown ot-dropdown'
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })
    )
  )
);
