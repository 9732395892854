import { useState, useEffect } from "react";
import {
  Header,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./VariantRestrictionsOrderUpdateModal.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import useCart from "../hc/useCart";
import LazyImage from "../../utils/LazyImage";

const VariantRestrictionsOrderUpdateModal = (props) => {
  const {
    foc,
    shipTo,
    isbn,
    title = false,
    quantity,
    titleRestrictionsQty = false,
    titleRestrictions,
    openState = false,
    setOpenState,
    proceedCallback,
    cancelCallback,
  } = props;
  // console.log(
  //   "VariantOrderUpdateModalState",
  //   isbn,
  //   title,
  //   titleRestrictionsQty,
  //   titleRestrictions,
  //   openState
  // );

  const cart = useCart();
  const [actionPerformed, setActionPerformed] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const resetState = () => {
    setDialogVisible(false);
  };
  const updateRemove = async () => {
    await cart.performEstimateVariantMainQtyUpdate({
      foc,
      shipTo,
      mainItem: {
        isbn,
        quantity: titleRestrictionsQty ? titleRestrictionsQty : 0,
        cartType: "FOC",
      },
      variantItems: titleRestrictions[0]?.affectedVariantTitles?.map((tri) => {
        return { isbn: tri.isbn, quantity: 0, cartType: "FOC" };
      }),
    });

    // console.log('proceedCallback', proceedCallback);
    if (proceedCallback) proceedCallback();
  };

  useEffect(() => {
    if (dialogVisible) {
      if (!openState) {
        if (!actionPerformed && cancelCallback) cancelCallback();
        resetState();
        setActionPerformed(false);
      }
    }
    if (openState && !dialogVisible) {
      setDialogVisible(true);
    }
  }, [actionPerformed, cancelCallback, dialogVisible, openState]);

  if (!titleRestrictions) return <div />;

  let headerMsg = "";
  let footerMsg = "";

  let numVariantTitles = 0;
  let numEffectedLocationsMap = {};

  let variantLocation = false;
  let currentLocationEffected = false;
  numVariantTitles = titleRestrictions[0].affectedVariantTitles.length;
  titleRestrictions[0].affectedVariantTitles.forEach((al) => {
    al.affectedLocations.forEach((l) => {
      numEffectedLocationsMap[l.shipTo] = true;
      if (l.shipTo.toString() !== shipTo.toString()) {
        variantLocation = l.shipTo;
      } else {
        currentLocationEffected = true;
      }
    });
  });
  let numVariantLocations = Object.keys(numEffectedLocationsMap).length;

  // console.log(
  //   'tots',
  //   numVariantTitles,
  //   numVariantLocations,
  //   numEffectedLocationsMap,
  //   variantLocation,
  //   currentLocationEffected,
  //   titleRestrictions
  // );

  headerMsg = (
    <span>
      You are trying to remove{" "}
      <span className='fort-bold'>
        {titleRestrictionsQty ? quantity - titleRestrictionsQty : 0}
      </span>{" "}
      of <span className='fort-bold'>{title}</span>.{"  "}
      <span>
        This will disqualify and remove{" "}
        <span className='fort-bold'>{numVariantTitles}</span> variant title(s)'
        following quantities from
      </span>{" "}
      {!!currentLocationEffected && numVariantLocations === 1 ? (
        <span>your cart:</span>
      ) : (
        <span>
          <span className='fort-bold'>{numVariantLocations}</span> account(s):
        </span>
      )}
    </span>
  );
  footerMsg =
    !!currentLocationEffected && numVariantLocations === 1 ? (
      <span className='fort-book fs-13px lh-18px'>
        Are you sure you want to continue and remove all items from your cart?
      </span>
    ) : (
      <span className='fort-book fs-13px lh-18px'>
        Are you sure you want to continue and remove all items from these
        account(s)?
      </span>
    );

  const ContinueAction = {
    label: "Yes, Continue",
    borderColorOverride: "blue",
    textColorOverride: "white",
    textColorHoverOverride: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "white",
    iconColorOverride: "white",
    iconColorHoverOverride: "blue",
    // icon: 'checkmark',
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      setActionPerformed(true);
      updateRemove();
      resetState();
      return { proceed: true };
    },
  };

  const CancelAction = {
    label: "Cancel",
    className: "ml-auto",
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      resetState();
      if (cancelCallback) cancelCallback();
      return { proceed: true };
    },
  };

  // console.log(
  //   'titleRestrictions',
  //   shipTo,
  //   foc,
  //   titleRestrictions,
  //   numVariantTitles,
  //   numVariantLocations
  // );

  let alertMessages = false;
  /* jeff */
  titleRestrictions[0]?.affectedVariantTitles?.forEach((title) => {
    title.affectedLocations.forEach((al) => {
      if (al.confirmationStatus === "CONFIRMED" && al.shipTo !== shipTo)
        alertMessages = true;
    });
  });

  return (
    <DialogModal
      open={openState}
      //open
      setOpen={setOpenState}
      // onXClose={cancelCallback}
      modalClassName='vro-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            {numVariantLocations > 1
              ? "Changes to Other Account(s)' Carts"
              : "CHANGES TO YOUR CART"}
          </Header>
        ),
        actions: [CancelAction, ContinueAction],
      }}
    >
      <div className='ar-box'>
        <>
          <div className='ml-4 mr-4 mb-2'>{headerMsg}</div>
          <Divider className='mt-0 mb-0' />
          <div
            style={{ maxHeight: "400px" }}
            className={`pt-1 overflow-auto bg-gray-100 ${
              numVariantLocations === 0 && "pb-2"
            }`}
          >
            {titleRestrictions &&
              titleRestrictions[0]?.affectedVariantTitles?.map((title, idx) => (
                <div key={idx}>
                  <div className='d-flex flex-row pl-4 pr-4 mt-1 mb-1'>
                    <LazyImage
                      className='vri-title-cover'
                      boxShadow={true}
                      src={`https://images.penguinrandomhouse.com/cover/${title.isbn}`}
                    ></LazyImage>

                    <div className='d-flex flex-column ml-2 justify-content-center'>
                      <div className='fort-bold fs-13px lh-18px text-gray-700'>
                        {title.title}
                      </div>
                      <div className='fort-book fs-13px lh-18px text-gray-700'>
                        {title.isbn}
                      </div>
                    </div>
                    <div className='d-flex flex-column ml-auto justify-content-center'>
                      <div className='fort-book fs-13px lh-18px text-gray-700 ml-auto'>
                        {title.affectedLocations.length > 1 ? "Total" : ""} Qty:{" "}
                        <span className='fort-bold'>{title.quantity}</span>
                      </div>
                    </div>
                  </div>
                  {(!!currentLocationEffected && numVariantLocations > 1) ||
                  (!!!currentLocationEffected && numVariantLocations > 0) ? (
                    <>
                      {title.affectedLocations.map((al, i) => (
                        <div key={i} className='pl-4 pr-4'>
                          <Divider tight />
                          <div className='d-flex flex-row mt-1 mb-1'>
                            <div className=''>
                              Account #{al.shipTo}{" "}
                              <span className='fort-book-italic'>
                                {shipTo === al.shipTo ? (
                                  "(This cart)"
                                ) : al.confirmationStatus === "CONFIRMED" ? (
                                  <>
                                    (Confirmed){" "}
                                    <Popup
                                      className='cp-help-tooltip'
                                      content='This account’s cart has had no updates since it was Confirmed. Continuing will update their cart and requires re-confirmation.'
                                      on='hover'
                                      position='right center'
                                      offset={[0, 5]}
                                      trigger={
                                        <Icon
                                          name='question circle'
                                          className='sb_question_icon'
                                        />
                                      }
                                    />
                                  </>
                                ) : null}
                              </span>
                            </div>
                            <div className='d-flex flex-column ml-auto justify-content-center'>
                              <div className='fort-book fs-13px lh-18px text-gray-700 ml-auto'>
                                Qty:{" "}
                                <span className='fort-bold'>{al.quantity}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {idx + 1 <
                        titleRestrictions[0]?.affectedVariantTitles.length && (
                        <Divider className='mb-1' tight />
                      )}
                    </>
                  ) : (
                    idx + 1 <
                      titleRestrictions[0]?.affectedVariantTitles.length && (
                      <Divider className='mb-1' tight />
                    )
                  )}
                </div>
              ))}
          </div>
          <Divider className='mt-0 mb-0' />
          {/* {titleRestrictions[0]?.affectedVariantTitles?.map((title, idx) => (
            <div key={`avt-${idx}`}>
              {title.affectedLocations.map((al, i) => (
                <div key={`csl-${i}`}>
                  {al.confirmationStatus === 'CONFIRMED' &&
                    al.shipTo !== shipTo && (
                      <div className="d-flex flex-row pl-4 pr-4 mt-2">
                        <Icon
                          name="exclamation triangle"
                          color="red"
                          className="mr-1"
                        ></Icon>
                        <div className="fs-13px lh-18px fort-book">
                          <span className="fort-bold">
                            Note: Account #{al.shipTo}
                          </span>
                          's cart has had no updates since it was confirmed.
                          Continuing will update their cart and require a
                          re-confirmation.
                        </div>
                      </div>
                    )}
                </div>
              ))}
            </div>
          ))} */}
          {/* {alertMessages && (
            <Divider
              className="mt-0 mb-0 ml-4 mr-4 mt-2"
              style={{ width: '93%' }}
            />
          )} */}
          <div className='ml-4 mr-4 mt-2 fort-bold fs-12px lh-14px'>
            {footerMsg}
          </div>
        </>
      </div>
    </DialogModal>
  );
};

// VariantRestrictionsOrderUpdateModal.propTypes = {
//   isbn: PropTypes.string.isRequired,
//   qty: PropTypes.number,
//   titleRestrictionsQty: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
//   openState: PropTypes.bool.isRequired,
//   setOpenState: PropTypes.func.isRequired,
// };

VariantRestrictionsOrderUpdateModal.defaultProps = {
  titleRestrictionsQty: false,
};

export default VariantRestrictionsOrderUpdateModal;
