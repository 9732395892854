import { PureComponent, useEffect, useMemo, useRef, useState } from "react";
import DialogModal from "../components/modals/DialogModal";
import localforage from "localforage";
import queryString from "query-string";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import withDirty from "../shared-react-components/components/hc/withDirty";
import withAuthService from "../components/hc/withAuthService";
import SecurityContext from "./SecurityContext";
//import withApp from './components/hoc/withApp';
import DummyApi from "./components/DummyApi";
import AccountApi from "./components/accountApi";
import RegistrationAPI from "./components/registrationApi";
import { DateTime, Settings } from "luxon";
import { useIdleTimer } from "react-idle-timer";
import { Loader } from "semantic-ui-react";
import { prhconsole } from "../utils/utilities";
import { logoffUser, setUserInfo } from "../features/userSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import useDirtyHandler from "../shared-react-components/components/hc/useDirtyHandler";
import { store } from "../store";
// import { authService } from "../App";
import ErrorPage from "./components/ErrorPage";
import { resetEcom } from "../features/ecomSlice";
import { useLogoutMutation } from "../api/ecomApi";
import { v4 as uuid } from "uuid";
import { setUser } from "@sentry/browser";

// DateTime.local().setZone('America/New York');
Settings.defaultZoneName = "America/New_York";

let userActivityTimeout = false;
let lastUserActivityTimeout = DateTime.now();

export const SecurityProvider = withAuthService((props) => {
  const { authService } = props.auth;
  const user = useSelector((state) => state.user);
  const [userLoaded, setuserLoaded] = useState(false);
  const [appID, setAppID] = useState();
  const [domain, setDomain] = useState(window.location.hostname);
  const sessionModalOpenRef = useRef(false);
  const [sessionModalOpen, setSessionModalOpen] = useState(false);
  const [refreshTokenInProgress, setRefreshTokenInProgress] = useState(false);
  const [performLogout, performLogoutResults] = useLogoutMutation();
  const sessionExpiredModalOpenRef = useRef(false);
  const [sessionExpiredModalOpen, setSessionExpiredModalOpen] = useState(false);
  const [userMissmatchModalOpen, setUserMissmatchModalOpen] = useState(false);
  const userMissmatchModalOpenRef = useRef(false);
  const [userMissmatchData, setUserMissmatchData] = useState({
    oldEmail: "",
    newEmail: "",
  });
  const [expirationTime, setExpirationTime] = useState({
    minute: 0,
    seconds: 0,
  });
  const [inRedirect, setInRedirect] = useState(false);
  const [sessionTimeout, setSessionTimeout] = useState(60); //60
  const [sessionTimeoutWarning, setSessionTimeoutWarning] = useState(5); //5
  // const [idleTimer, setIdleTimer] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const dirtyHandler = useDirtyHandler();

  const values = queryString.parse(location.search);

  const handleOnAction = (event) => {
    // console.log("user did something", event, sessionModalOpenRef.current);
    if (!sessionModalOpenRef.current) {
      // console.log(
      //   "reseting handleOnAction timer",
      //   sessionModalOpen,
      //   sessionModalOpenRef.current
      // );
      userActivityTimeout = false;
      lastUserActivityTimeout = DateTime.now();
    }
  };

  const handleOnActive = (event) => {
    // console.log("handle user is active", event);
    if (!sessionModalOpenRef.current) {
      // console.log(
      //   "reseting handleOnActive timer",
      //   sessionModalOpen,
      //   sessionModalOpenRef.current
      // );
      // console.log("reseting activity timer");
      userActivityTimeout = false;
      lastUserActivityTimeout = DateTime.now();
    }
  };

  const handleOnIdle = (event) => {
    // console.log("handle on idle");
    // console.log("setting activity timer");
    userActivityTimeout = true;
    // lastUserActivityTimeout = idleTimer.getLastActiveTime();
    lastUserActivityTimeout = DateTime.fromJSDate(getLastActiveTime());
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    timeout: 1000 * 60 * sessionTimeout,
    throttle: 500,
  });

  useEffect(() => {
    console.log("security privider useEffect");
    if (Object.prototype.hasOwnProperty.call(values, "auth")) {
      console.log("performingauth");
      authService.signinRedirect({ state: location.pathname });
      // return <div>working on auth</div>;
    }
    // } else if (Object.prototype.hasOwnProperty.call(values, "listID")) {
    //   authService
    //     .signinSilent()
    //     .then((x) => {
    //       // console.log("signin silent success", x);
    //       dirtyHandler.setLoadingTakeover(false);
    //     })
    //     .catch((e) => {
    //       console.log("signinsilent catch error", e.message);
    //       history.push({ pathname: "/" });
    //       dirtyHandler.setLoadingTakeover(false);
    //     });
    // }

    return () => {};
  }, []);

  useEffect(() => {
    sessionExpiredModalOpenRef.current = sessionExpiredModalOpen;
  }, [sessionExpiredModalOpen]);

  useEffect(() => {
    userMissmatchModalOpenRef.current = userMissmatchModalOpen;
  }, [userMissmatchModalOpen]);

  useEffect(() => {
    sessionModalOpenRef.current = sessionModalOpen;
  }, [sessionModalOpen]);

  useEffect(() => {
    const hostName = window.location.hostname;

    let aid = "biz";
    if (hostName.startsWith("biz") || hostName.startsWith("selfservice")) {
      window.document.title = "PRH Biz";
      aid = "biz";
    }
    if (hostName.startsWith("compcopy")) {
      window.document.title = "PRH Compcopy";
      aid = "compcopy";
    }
    setAppID(aid);

    authService.UserManager.events.addUserLoaded((usr) => {
      let userState = store.getState().user;
      console.log("adduserloaded event", usr, userState);

      if (
        usr?.profile?.guid &&
        userState?.profile?.guid &&
        userState.profile.guid !== usr.profile.guid
      ) {
        console.log(
          "user profile changed",
          userState.profile.guid,
          usr.profile.guid
        );
        // console.log("user email missmatch", usr, userState);
        setSessionModalOpen(false);
        setSessionExpiredModalOpen(false);
        setUserMissmatchData({
          oldEmail: userState.profile.email,
          newEmail: usr.profile.email,
        });
        setUserMissmatchModalOpen(true);
        return;
        // // dispatch(r());
        // dispatch(resetEcom());
        // authService.logout();
      }

      dispatch(
        setUserInfo({
          user: {
            isAuthenticated: authService.isAuthenticated(),
            id_token: usr.id_token,
            accessToken: usr.access_token,
            expires_at: usr.expires_at,
            profile: usr.profile,
          },
        })
      );
    });

    authService.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    authService.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      authService.logout();
    });

    authService.UserManager.events.addAccessTokenExpiring((user) => {
      console.log("addAccessTokenExpiring event", user);
    });

    authService.UserManager.events.addUserSignedIn((user) => {
      console.log("addUserSignedIn event", user);
    });

    authService.UserManager.events.addUserSessionChanged((user) => {
      console.log("addUserSessionChanged event", user);
    });

    // window.addEventListener("focus", (e) => {
    //   let userState = store.getState().user;
    //   console.log("window focus", e);
    //   console.log("userState", userState);
    // });
    // window.addEventListener("blur", (e) => {
    //   let userState = store.getState().user;
    //   console.log("window blur", e);
    //   console.log("userState", userState);
    // });

    document.addEventListener("visibilitychange", async (e) => {
      if (document.visibilityState === "visible") {
        let userState = store.getState().user;

        const user = await authService.UserManager.getUser();
        // console.log(
        //   "visibilitychange",
        //   document.visibilityState,
        //   user,
        //   userState
        // );
      }
    });

    localforage.config({
      driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
      name: "biz2",
      version: 1.0,
      storeName: "root", // Should be alphanumeric, with underscores.
      description: "Persistance Store",
    });

    const values = queryString.parse(location.search);
    // console.log("SP startup ", user, authService.isAuthenticated(), location);
    if (
      !user.isAuthenticated &&
      authService.isAuthenticated() &&
      location.pathname !== "/silentrenew"
    ) {
      console.log("running getuser");
      authService.getUser().then((user) => {
        // console.log('Loading User Object');
        dispatch(
          setUserInfo({
            user: {
              isAuthenticated: authService.isAuthenticated(),
              id_token: user.id_token,
              accessToken: user.access_token,
              expires_at: user.expires_at,
              profile: user.profile,
            },
          })
        );
      });
    }

    user.debug &&
      console.log(
        "auth checkup",
        authService.isAuthenticated(),
        location.pathname,
        values
      );
    if (
      !authService.isAuthenticated() &&
      !Object.prototype.hasOwnProperty.call(values, "err") &&
      location.pathname !== "/signin-oidc" &&
      location.pathname !== "/silentrenew" &&
      !location.pathname.startsWith("/verify") &&
      location.pathname !== "/update-subaccount" &&
      !location.pathname.startsWith("/reset-password") &&
      !location.pathname.startsWith("/recover-password") &&
      !location.pathname.startsWith("/registration") &&
      !location.pathname.startsWith("/resend-invitation") &&
      location.search !== "?auth=true" &&
      !Object.prototype.hasOwnProperty.call(values, "listID")
    ) {
      // dirtyHandler.setLoadingTakeoverConfig({
      //   text: "Loading . . .",
      //   longLoadText: "Still loading . . .",
      //   inverted: true,
      //   backgroundBlurLight: true,
      // });
      // dirtyHandler.setLoadingTakeover(true);
      // // if (Object.prototype.hasOwnProperty.call(values, "listID")) {
      // //   authService.signinRedirect({
      // //     state: location.pathname + location.search,
      // //   });
      if (location.pathname === "/") {
        console.log('location.pathname === "/"');
        // signinRedirectUnAuthenticated({ state: "/?jeff=true" });
        // }
        // } else {
        // console.log("signinRedirectUnAuthenticated");
        authService
          .signinSilent()
          .then((x) => {
            // console.log("signin silent success", x);
            checkUserTokenTimer();
            dirtyHandler.setLoadingTakeover(false);
          })
          .catch((e) => {
            console.log("signinsilent catch error", e.message);
            // history.push({ pathname: "/" });
            // dirtyHandler.setLoadingTakeover(false);
          });
      }
    } else {
      checkUserTokenTimer();
    }

    // //jeff
    // setSessionModalOpen(false);
    // setSessionExpiredModalOpen(false);
    // setUserMissmatchData({
    //   oldEmail: "jphipps@phipps.ws",
    //   newEmail: "fred@phipps.ws",
    // });
    // setUserMissmatchModalOpen(true);

    return () => {};
  }, []);

  const redirectUri = (uri, state = "") => {
    const url = uri.split("?");
    history.push({ pathname: url[0], search: url[1], state: state });
  };

  const getDomain = () => {
    return domain;
  };

  const checkUserTokenTimer = () => {
    setTimeout(() => {
      checkUserToken();
    }, 10000);
  };

  const checkUserToken = () => {
    // console.log("checkusertoken");
    let userState = store.getState().user;

    if (userMissmatchModalOpenRef.current) return;

    const userTokenTimeLeft = () => {
      if (!userState?.expires_at) return 0;
      let cd = DateTime.now();
      let td = DateTime.fromSeconds(userState.expires_at);
      return td.diff(cd, ["minutes", "seconds"]).values;
    };

    if (
      userState?.expires_at &&
      !refreshTokenInProgress
      // !this.state.sessionExpiredModalOpen
    ) {
      let expirationTime = userTokenTimeLeft();

      let wcd = DateTime.now();
      // let uad = DateTime.fromMillis(lastUserActivityTimeout);
      let uad = lastUserActivityTimeout;

      let workUadDiff = wcd.diff(uad, ["minutes", "seconds"]).values;

      user.debug &&
        console.log(
          "Auth Check",
          expirationTime,
          workUadDiff,
          userActivityTimeout,
          sessionModalOpenRef
        );
      if (expirationTime.minutes < 5) {
        setRefreshTokenInProgress(true);
        const refreshUser = authService.signinSilent();
      } else if (!userActivityTimeout && !sessionModalOpenRef.current) {
        let curDate = DateTime.now();
        let userActivityDate = lastUserActivityTimeout;
        let uadDiff = curDate.diff(userActivityDate, [
          "minutes",
          "seconds",
        ]).values;
        user.debug &&
          console.log(
            "expringMinutes - User is active",
            expirationTime,
            userActivityTimeout,
            sessionExpiredModalOpen,
            uadDiff
          );
      } else {
        let curDate = DateTime.now();
        let userActivityDate = lastUserActivityTimeout;
        let uadDiff = curDate.diff(userActivityDate, [
          "minutes",
          "seconds",
        ]).values;

        user.debug &&
          console.log(
            "sessions info",
            sessionModalOpenRef.current,
            sessionExpiredModalOpenRef.current,
            curDate,
            userActivityDate,
            uadDiff
          );
        if (
          !sessionModalOpenRef.current &&
          !sessionExpiredModalOpenRef.current
        ) {
          setSessionModalOpen(true);
          setExpirationTime(
            sessionTimeout + sessionTimeoutWarning - uadDiff.minutes
          );
        } else if (sessionExpiredModalOpenRef.current) {
          user.debug && console.log("UserExpiredModal is displayed");
        } else if (
          sessionTimeout + sessionTimeoutWarning - uadDiff.minutes <
          1
        ) {
          user.debug && console.log("doing expired session modal");
          setSessionModalOpen(false);
          setSessionExpiredModalOpen(true);
          // dispatch(r());
          dispatch(resetEcom());
          authService.logout();
        } else {
          setExpirationTime(
            sessionTimeout + sessionTimeoutWarning - uadDiff.minutes
          );
        }
      }
    }
    if (!sessionExpiredModalOpenRef.current) checkUserTokenTimer();
    // this.checkUserTokenTimer();
  };

  const reloadUser = () => {
    // console.log("reloadUser", location);
    authService
      .getUser()
      .then((user) => {
        // console.log("Loading User Object", user);
        dispatch(setUserInfo({ user }));
      })
      .catch((e) => {
        console.log("reloadUserException", e);
      });
  };

  const clearUser = (rUrl = false) => {
    // console.log('clearUser', rUrl);
    dispatch(setUserInfo({ user: null }));
    if (rUrl) window.location = rUrl;
  };

  const signinRedirectUnAuthenticated = (state) => {
    console.log("signinRedirectUnAuthenticated", state);
    const x = localStorage.getItem("redirectUri");
    // console.log("signinRedirectUnAuthenticated x", x);
    if (x && !window.location.hostname.startsWith("compcopy")) {
      // console.log("found redirect", x);
    } else {
      // console.log("security provider redirectUri", state.state);
      // localStorage.setItem("redirectUri", state.state);
      // const y = localStorage.getItem("redirectUri");
      // console.log("y", y);
      if (!sessionExpiredModalOpen) authService.signinRedirect(state);
    }
  };

  const setError = (e) => {
    setErrorMsg(e);
    dirtyHandler.setLoadingTakeover(false);
  };

  // if (
  //   !authService ||
  //   (authService.isAuthenticated() &&
  //     !user.isAuthenticated &&
  //     location.pathname !== "/silentrenew")
  // ) {
  //   return <Loader />;
  // }

  // console.log("render SP");

  return (
    <>
      <DialogModal
        open={sessionModalOpen}
        setOpen={(e) => setSessionModalOpen(e)}
        onXClose={(e) => {
          userActivityTimeout = false;
          lastUserActivityTimeout = DateTime.now();
          setSessionModalOpen(false);
        }}
        // options={{ size: 'mini' }}
        modalClassName='session-modal'
        style={{ maxWidth: "448px" }}
        dialogOptions={{
          header: "Session Timeout",
          actions: [
            {
              label: "Sign out",
              // borderColorOverride: 'blue',
              // textColorOverride: 'white',
              // textColorHoverOverride: 'blue',
              // backgroundColorOverride: 'blue',
              // backgroundHoverColorOverride: 'white',
              // iconColorOverride: 'white',
              // iconColorHoverOverride: 'blue',

              preAction: () => {
                return { proceed: true };
              },

              action: async () => {
                await performLogout();
                history.push({ pathname: "/logout" });
                return { proceed: true };
              },
            },
            {
              label: "I'm here",
              borderColorOverride: "blue",
              textColorOverride: "white",
              textColorHoverOverride: "blue",
              backgroundColorOverride: "blue",
              backgroundHoverColorOverride: "white",
              iconColorOverride: "white",
              iconColorHoverOverride: "blue",

              preAction: () => {
                return { proceed: true };
              },

              action: () => {
                userActivityTimeout = false;
                lastUserActivityTimeout = DateTime.now();
                // this.setState({ sessionModalOpen: false });
                return { proceed: true };
              },
            },
          ],
        }}
      >
        <div className='d-flex flex-column'>
          <div className='fort-bold'>Are you still there?</div>
          <div className='fort-book mt-1'>
            If not, we'll close this session in: {Math.floor(expirationTime)}{" "}
            minutes.
          </div>
        </div>
      </DialogModal>
      <DialogModal
        open={sessionExpiredModalOpen}
        setOpen={(e) => setSessionExpiredModalOpen(e)}
        onXClose={(e) => history.push({ pathname: "/logout" })}
        // options={{ size: 'mini' }}
        modalClassName='session-modal'
        style={{ maxWidth: "448px" }}
        dialogOptions={{
          header: "Session has expired",
          actions: [
            {
              label: "Login",
              borderColorOverride: "blue",
              textColorOverride: "white",
              textColorHoverOverride: "blue",
              backgroundColorOverride: "blue",
              backgroundHoverColorOverride: "white",
              iconColorOverride: "white",
              iconColorHoverOverride: "blue",

              preAction: () => {
                // console.log('triggering logout');
                // this.authService.logout();
                return { proceed: true };
              },

              action: async () => {
                console.log(
                  "redirectUri, modal",
                  window.location.pathname + window.location.search
                );
                localStorage.setItem(
                  "redirectUri",
                  window.location.pathname + window.location.search
                );
                await performLogout({});
                const ut = appID === "biz";
                authService.logout();
                clearUser();
                if (ut) {
                  window.location.href = process.env.REACT_APP_NETIQ_LOGOUT_URL;
                } else {
                  window.location.href = "/logout";
                }
                // history.push({ pathname: "/logout" });
                return { proceed: true };
              },
            },
          ],
        }}
      >
        <div className='d-flex flex-column'>
          Your session has expired, you will be redirected to the login page.
        </div>
      </DialogModal>
      <DialogModal
        open={userMissmatchModalOpen}
        setOpen={(e) => setUserMissmatchModalOpen(e)}
        onXClose={(e) => (window.location.href = "/")}
        // options={{ size: 'mini' }}
        modalClassName='session-modal'
        style={{ maxWidth: "448px" }}
        dialogOptions={{
          header: "Recent User Activity",
          actions: [
            {
              label: "Ok",
              className: "ml-auto mr-auto",
              borderColorOverride: "blue",
              textColorOverride: "white",
              textColorHoverOverride: "blue",
              backgroundColorOverride: "blue",
              backgroundHoverColorOverride: "white",
              iconColorOverride: "white",
              iconColorHoverOverride: "blue",

              preAction: () => {
                // console.log('triggering logout');
                // this.authService.logout();
                return { proceed: true };
              },

              action: async () => {
                // console.log(
                //   "redirectUri, modal",
                //   window.location.pathname + window.location.search
                // );

                window.location.href = "/";
                return { proceed: true };
              },
            },
          ],
        }}
      >
        <div className='d-flex flex-column align-items-center'>
          <div className='fort-bold fs-16px'>Only one team member</div>
          <div className='fort-bold fs-16px mb-2'>can sign in at a time</div>
          <div className='fort-bold'> {userMissmatchData.newEmail}</div>
          <div className='mb-2'>is currently signed in</div>
          <div className='fort-bold'>{userMissmatchData.oldEmail}</div>
          <div>has been signed out</div>
          <div className='mt-1'>you will be redirected to the home page</div>
          <div className=''>if you need to sign in as a different user</div>
        </div>
      </DialogModal>
      <SecurityContext.Provider
        value={{
          isAuthenticated: () => {
            return false;
          },
          ...authService,
          signinRedirectUnAuthenticated: (state) => {
            return signinRedirectUnAuthenticated(state);
          },
          user: user,
          setUser: (user) => {
            return dispatch(setUserInfo({ user }));
          },
          clearUser: () => {
            return clearUser();
          },
          reloadUser: () => {
            return reloadUser();
          },
          getDomain: () => {
            return getDomain();
          },
          getAppID: () => {
            return appID;
          },
          isAppBiz: () => {
            return appID === "biz";
          },
          isAppCompCopy: () => {
            return appID === "compcopy";
          },
          setError,
          errorMsg,
        }}
      >
        <>{props.children}</>
      </SecurityContext.Provider>
    </>
  );
});
