import API from "../../shared-react-components/providers/components/api";
import {
  IDENTITY_CONFIG,
  COMPCOPY_IDENTITY_CONFIG,
} from "../../utils/authConst";
import FileSaver from "file-saver";
//
//
//  ListApi - docs:  https://randomhouse.app.box.com/file/643847892147
//
//

class EcomApi extends API {
  constructor(props) {
    const { errorHandler, appID } = props;

    super(props);

    this.appID = appID;

    this.cartType = "biz";
    // console.log('EcomApi', props);
    if (appID && appID === "compcopy") {
      this.cartType = "fnr";
      this.urlBase = COMPCOPY_IDENTITY_CONFIG.base;
    } else {
      this.urlBase = IDENTITY_CONFIG.base;
    }
    this.errorHandler = errorHandler;
  }

  setDebug(debug) {
    this.setDebugValue(debug);
  }

  // async getVersion() {
  //   this.isDebug() && console.log(`EcomApi:getVersion`);

  //   const headers = {};

  //   try {
  //     const d = await this.get({
  //       url: `/version.json`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getVersion Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getVersion Failure', e);
  //     // this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async getCatalog(catalogDate, searchClass, country, shipTo = false) {
  //   let data = {
  //     catalogDate: catalogDate,
  //     searchClass: searchClass,
  //     country: country,
  //   };
  //   if (shipTo) {
  //     data.shipTo = shipTo;
  //   }
  //   try {
  //     const d = await this.post({
  //       //url: `${this.urlBase}/ecom/search/catalog?extra=true&seriesHistory=true`,
  //       url: `${this.urlBase}/ecom/search/catalog?extra=true`,
  //       data: data,
  //     });
  //     return d;
  //   } catch (e) {
  //     return { status: 500, error: e };
  //   }
  // }

  // async getSeriesTitles(seriesCode, searchClass, country) {
  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/search`,
  //       data: {
  //         searchClass: searchClass,
  //         country: country,
  //         seriesCode: seriesCode,
  //       },
  //     });
  //     return d;
  //   } catch (e) {}
  // }

  // async getSeriesHistory(
  //   sapAccountNo = false,
  //   isbn,
  //   formatCode,
  //   shipTo,
  //   getAll
  // ) {
  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }
  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/serieshistory/detail${
  //         getAll === true ? "/all" : ""
  //       }`,
  //       data: {
  //         shipTo: shipTo,
  //         isbn: isbn,
  //         formatCode: formatCode,
  //       },
  //       headers,
  //     });
  //     return d;
  //   } catch (e) {}
  // }

  // async getVariantRestriction(isbn, searchClass, country) {
  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/search/variants`,
  //       data: {
  //         isbn: isbn,
  //         searchClass: searchClass,
  //         country: country,
  //       },
  //     });
  //     return d;
  //   } catch (e) {
  //     return { status: 500, error: e };
  //   }
  // }

  // async addVariantsToCart(requestBody) {
  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/estimates/carts/variants`,
  //       data: requestBody,
  //     });
  //     // console.log(d);
  //     return d;
  //   } catch (e) {
  //     return { status: 500, error: e };
  //   }
  // }

  // async updateVariantsToCart(requestBody) {
  //   try {
  //     const d = await this.put({
  //       url: `${this.urlBase}/ecom/estimates/carts/variants`,
  //       data: requestBody,
  //     });
  //     // console.log(d);
  //     return d;
  //   } catch (e) {
  //     return { status: 500, error: e };
  //   }
  // }

  // async search(searchItem, searchClass, country, searchType) {
  //   // console.log('*****************************************');
  //   // console.log(
  //   //   'in search bizz call',
  //   //   searchItem,
  //   //   searchClass,
  //   //   country,
  //   //   searchType
  //   // );
  //   const isbn_array = searchItem.split(" ");

  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/search`,
  //       data: {
  //         ...(searchType === "list" && { isbns: isbn_array }),
  //         ...(searchType === "normal" && { q: searchItem }),
  //         searchClass: searchClass,
  //         country: country,
  //       },
  //     });
  //     // console.log('search results : ', d);
  //     return d;
  //     // this.isDebug() && console.log(`EcomApi:getUserCart Complete`, d);
  //   } catch (e) {
  //     // console.log('search failuer : ', e);
  //     // this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async searchMultipleBiz(searchItems, country, searchClass) {
  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/search`,
  //       data: {
  //         isbns: searchItems,
  //         country: country,
  //         searchClass: searchClass,
  //       },
  //     });
  //     // console.log('search results : ', d);
  //     return d;
  //     // this.isDebug() && console.log(`EcomApi:getUserCart Complete`, d);
  //   } catch (e) {
  //     // console.log('search failuer : ', e);
  //     // this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async createCart(props) {
  //   this.isDebug() && console.log(`EcomApi:createCart`);

  //   const headers = { "content-type": "application/json" };
  //   const { data } = props;

  //   if (data.cartKey) {
  //     try {
  //       const d = await this.patch({
  //         url: `${this.urlBase}/ecom/usercart/?cartKey=${data.cartKey}`,
  //         headers,
  //         data,
  //       });
  //       this.isDebug() && console.log(`EcomApi:createCart Complete`, d);

  //       return d;
  //     } catch (e) {
  //       //console.log('EcomApi:createCart Failure', e);
  //     }
  //   } else {
  //     try {
  //       const d = await this.post({
  //         url: `${this.urlBase}/ecom/usercart/`,
  //         headers,
  //         data,
  //       });
  //       this.isDebug() && console.log(`EcomApi:createCart Complete`, d);

  //       return d;
  //     } catch (e) {
  //       //console.log('EcomApi:createCart Failure', e);
  //     }
  //   }
  // }

  // async getUserCart(sapAccountNo = false, cartKey = false, simulate = true) {
  //   this.isDebug() && console.log(`EcomApi:getUserCart`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers['sapAccountNo'] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/usercart/?${
  //         simulate ? 'simulate=true' : 'simulate=false'
  //       }${cartKey ? `&cartKey=${cartKey}` : ''}`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getUserCart Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getUserCart Failure', e);
  //     // this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async getUserCartCardInfo(cartKey = false) {
  //   this.isDebug() && console.log(`EcomApi:getUserCartCardInfo`);

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/usercart/?simulate=true&titleDetail=false${
  //         cartKey ? `&cartKey=${cartKey}` : ""
  //       }`,
  //       cacheResults: false,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getUserCartCardInfo Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getUserCartCardInfo Failure', e);
  //     // this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async removeUserCartItem(itemId, sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:removeUserCartItem`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   let items = [];
  //   if (Array.isArray(itemId)) {
  //     itemId.forEach((item) => {
  //       items.push({ itemId: item.itemId, quantity: -1 });
  //     });
  //   } else {
  //     items.push({ itemId: itemId, quantity: -1 });
  //   }

  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/usercart/`,
  //       data: { items: items },
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:removeUserCartItem Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getUserCart Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async removeUserCart(sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:removeUserCart`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.delete({
  //       url: `${this.urlBase}/ecom/usercart/`,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:removeUserCart Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:removeUserCart Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async updateUserItemQty(itemId, qty, sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:updateUserItemQty`, itemId, qty);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/usercart/`,
  //       data: { items: [{ itemId: itemId, quantity: qty }] },
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:updateUserItemQty Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getUserCart Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async addUserCartItem(isbn, qty, sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:addUserCartItem`, isbn, qty);

  //   let headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }
  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/usercart/?simulate=false`,
  //       data: { items: [{ isbn: isbn, quantity: qty }] },
  //       headers: headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:addUserCartItem Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:addUserCartItem Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async addUserCartItems(titles, sapAccountNo = false, simulate = false) {
  //   this.isDebug() && console.log(`EcomApi:addUserCartItems`, titles);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/usercart/?simulate=${simulate}`,
  //       data: { items: titles },
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:addUserCartItems Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:addUserCartItems Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }
  // async getCustomerInfo(sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:getCustomerInfo`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/user/customer/info/`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getCustomerInfo Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getCustomerInfo Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async getCustomerUser(sapAccountNo = false) {
  //   // console.log('ecom api, get users');
  //   this.isDebug() && console.log(`EcomApi:getCustomerUser`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/user/customer/user/`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getCustomerUser Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getCustomerUser Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async getHrdiCaller(address = {}, cartKey = false) {
  //   this.isDebug() && console.log(`EcomApi:getHrdiCaller`);
  //   try {
  //     const d = await this.post({
  //       //url: `${this.urlBase}/ecom/usercart/hrdicaller${
  //       //cartKey ? `?cartKey=${cartKey}` : ''
  //       //}`,
  //       url: `${this.urlBase}/ecom/card/caller`,
  //       data: address,
  //       headers: {
  //         Accept: "text/html",
  //       },
  //     });
  //     this.isDebug() && console.log(`EcomApi:getHrdiCaller Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getHrdiCaller Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async applyPromoCode(promo, cartKey = false) {
  //   this.isDebug() && console.log(`EcomApi:applyPromoCode`);
  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/usercart/promocode${
  //         cartKey ? `?cartKey=${cartKey}` : ""
  //       }`,
  //       data: {
  //         value: promo,
  //       },
  //     });
  //     this.isDebug() && console.log(`EcomApi:applyPromoCode Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:applyPromoCode Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async deletePromoCode(promo, cartKey = false) {
  //   this.isDebug() && console.log(`EcomApi:deletePromoCode`);
  //   try {
  //     const d = await this.delete({
  //       url: `${this.urlBase}/ecom/usercart/promocode${
  //         cartKey ? `?cartKey=${cartKey}` : ""
  //       }`,
  //       data: {
  //         value: promo,
  //       },
  //     });
  //     this.isDebug() && console.log(`EcomApi:deletePromoCode Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:deletePromoCode Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  //jeff
  // async performOrderSubmit(headers, sapAccountNo = false) {
  //   const u = await this.updateCartHeaders(headers);

  //   if (u.status === 200) {
  //     const s = await this.submitOrder();
  //     return s;
  //   }

  //   return u;
  // }

  // async updateCartHeaders({ headers, cartKey, sapAccountNo, simulate }) {
  //   this.isDebug() && console.log(`EcomApi:updateCartHeaders`, headers);

  //   let params = [];
  //   cartKey && params.push(`cartKey=${cartKey}`);
  //   simulate && params.push(`simulate=true`);
  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/usercart/${
  //         params.length > 0 ? `?${params.join("&")}` : ""
  //       }`,
  //       data: { headers },
  //     });
  //     this.isDebug() && console.log(`EcomApi:updateCartHeaders Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:updateCartHeaders Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async updateCart(props) {
  //   this.isDebug() && console.log(`EcomApi:updateCart`, props);

  //   const { headers, shipTo, items, sapAccountNo, shippingType, cartKey } =
  //     props;

  //   let data = {};
  //   if (headers) data.headers = headers;
  //   if (shipTo) data.shipTo = shipTo;
  //   if (items) data.items = items;
  //   if (shippingType) data.shippingType = shippingType;

  //   const httpHeaders = {};
  //   if (sapAccountNo) {
  //     httpHeaders["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/usercart/?titleDetail=true&simulate=false${
  //         cartKey ? `&cartKey=${cartKey}` : ""
  //       }`,
  //       data: data,
  //       headers: httpHeaders,
  //     });
  //     this.isDebug() && console.log(`EcomApi:updateCart Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:updateCart Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async submitOrder(props) {
  //   this.isDebug() && console.log(`EcomApi:submitOrder`, props);

  //   const { sapAccountNo, cartKey = false } = props;

  //   const httpHeaders = {};
  //   if (sapAccountNo) {
  //     httpHeaders["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.post({
  //       url: `${
  //         this.urlBase
  //       }/ecom/usercart/submit/?simulate=true&titleDetail=false${
  //         cartKey ? `&cartKey=${cartKey}` : ""
  //       }`,
  //       data: {},
  //       headers: httpHeaders,
  //       ignoreError: true,
  //     });
  //     this.isDebug() && console.log(`EcomApi:submitOrder Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:submitOrder Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async getSOForCart(cartKey, sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:getSOForCart`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/usercart/key/${cartKey}/ordernumber`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getSOForCart Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getSOForCart Failure', e);
  //     // this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async cleanISBNs(data, sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:cleanISBNs`);

  //   const httpHeaders = {};
  //   if (sapAccountNo) {
  //     httpHeaders["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/usercart/isbns`,
  //       data: { data: data.trim() },
  //       headers: httpHeaders,
  //     });
  //     this.isDebug() && console.log(`EcomApi:cleanISBNs Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:cleanISBNs Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async validateAddress(data, sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:validateAddress`);

  //   const httpHeaders = {};
  //   if (sapAccountNo) {
  //     httpHeaders["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/validate/address?compCopy=true`,
  //       data: { ...data },
  //       headers: httpHeaders,
  //     });
  //     this.isDebug() && console.log(`EcomApi:validateAddress Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:validateAddress Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async getUserSaveForLater(sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:getUserSaveForLater`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/userlist/sfl?titleDetail=true&simulate=false`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getUserSaveForLater Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getUserSaveForLater Failure', e);
  //     // this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async removeUserSaveForLaterItem(itemId, sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:removeUserSaveForLaterItem`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/userlist/sfl?titleDetail=true&simulate=true`,
  //       data: { items: [{ itemId: itemId, quantity: 0 }] },
  //       headers,
  //     });
  //     this.isDebug() &&
  //       console.log(`EcomApi:removeUserSaveForLaterItem Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:removeUserSaveForLaterItem Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async addUserSaveForLaterItem(isbn, qty, itemId, sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:addUserSaveForLaterItem`, isbn, qty);
  //   let data = { items: [{ isbn: isbn, quantity: qty }] };
  //   if (itemId) {
  //     data.items[0].itemId = itemId;
  //   }

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/userlist/sfl/`,
  //       data: data,
  //       headers,
  //     });
  //     this.isDebug() &&
  //       console.log(`EcomApi:addUserSaveForLaterItem Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:addUserSaveForLaterItem Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async getTitleInfo(isbn, sapAccountNo = false, userGuid) {
  //   this.isDebug() && console.log(`EcomApi:getTitleInfo`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //     headers["proxy_sapacct"] = sapAccountNo;
  //   }

  //   // if (userGuid) {
  //   //   headers['proxy_guid'] = userGuid;
  //   // }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/title/product-display?isbn=${isbn}`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getTitleInfo Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getTitleInfo Failure', e);
  //   }
  // }

  // async getPOWTitleInfo(isbn) {
  //   this.isDebug() && console.log(`EcomApi:getTitleInfo`);

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/title/titles?isbn=${isbn}`,
  //       cacheResults: false,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getTitleInfo Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getTitleInfo Failure', e);
  //   }
  // }

  // async getSalesReps(sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:getSalesReps`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/reps/sales/`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getSalesReps Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getSalesReps Failure', e);
  //   }
  // }
/*
  async getPromos(sapAccountNo = false) {
    this.isDebug() && console.log(`EcomApi:getPromos`);

    const headers = {};
    if (sapAccountNo) {
      headers["sapAccountNo"] = sapAccountNo;
    }

    try {
      const d = await this.get({
        url: `${this.urlBase}/ecom/promotions/`,
        cacheResults: false,
        headers,
      });
      this.isDebug() && console.log(`EcomApi:getPromos Complete`, d);

      return d;
    } catch (e) {
      //console.log('EcomApi:getPromos Failure', e);
    }
  }
*/
  // async getOrders(data, sapAccountNo = false, userGuid) {
  //   this.isDebug() && console.log(`EcomApi:getOrders`, data, sapAccountNo);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   // if (userGuid) {
  //   //   headers['proxy_guid'] = userGuid;
  //   // }

  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/orders/views/customer`,
  //       cacheResults: false,
  //       headers,
  //       data,
  //       timeout: 30000,
  //       ignoreError: true,
  //       opts: { timeout: 0 },
  //     });
  //     this.isDebug() && console.log(`EcomApi:getOrders Complete`, d);
  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getOrders Failure', e);
  //   }
  // }

  // async getInvoices(data, sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:getInvoices`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   // if (userGuid) {
  //   //   headers['proxy_guid'] = userGuid;
  //   // }
  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/invoices`,
  //       cacheResults: false,
  //       headers,
  //       data,
  //       timeout: 30000,
  //       ignoreError: true,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getInvoices Complete`, d);
  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getInvoices Failure', e);
  //   }
  // }

  // async getRecentInvoices(args = {}) {
  //   this.isDebug() && console.log(`EcomApi:getRecentInvoices`);

  //   const headers = {};
  //   if (!!args.sapAccountNo) {
  //     headers["sapAccountNo"] = args.sapAccountNo;
  //   }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/home/history/invoices/${
  //         !!args.shipTo ? `?=shipTo=${args.shipTo}` : ""
  //       }`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getRecentInvoices Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getRecentInvoices Failure', e);
  //   }
  // }

  // async getRecentOrders(args = {}) {
  //   this.isDebug() && console.log(`EcomApi:getRecentOrders`);

  //   const headers = {};
  //   if (!!args.sapAccountNo) {
  //     headers["sapAccountNo"] = args.sapAccountNo;
  //   }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/home/history/orders/${
  //         !!args.shipTo ? `?=shipTo=${args.shipTo}` : ""
  //       }`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getRecentOrders Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getRecentOrders Failure', e);
  //   }
  // }

  // async getListOfReports(args = {}) {
  //   this.isDebug() && console.log(`EcomApi:getRecentOrders`);

  //   const headers = {};
  //   if (!!args.sapAccountNo) {
  //     headers["sapAccountNo"] = args.sapAccountNo;
  //   }

  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/delivery/osd`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getListOfReports Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getListOfReports Failure', e);
  //   }
  // }

  // async downloadPreDeliveryReport(onSaleDate, shipTo) {
  //   this.isDebug() && console.log(`EcomApi:getRecentOrders`);
  //   const data = {
  //     onSaleDate: onSaleDate,
  //     shipTo: shipTo,
  //   };
  //   const headers = {};
  //   //if (!!args.sapAccountNo) {
  //   //headers['sapAccountNo'] = args.sapAccountNo;
  //   //}

  //   //console.log('api downloadPreDeliveryReport', data);

  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/delivery/osd`,
  //       data: data,
  //       cacheResults: false,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getListOfReports Complete`, d);
  //     //console.log('api d', d);
  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getListOfReports Failure', e);
  //   }
  // }

  // async getRecentHistory(sapAccountNo = false) {
  //   this.isDebug() && console.log(`EcomApi:getRecentHistory`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/home/history`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getRecentHistory Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getRecentHistory Failure', e);
  //   }
  // }

  // async updateItemSaveForLaterQty(itemId, qty, sapAccountNo = false) {
  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }
  //   try {
  //     this.isDebug() &&
  //       console.log(`EcomApi:updateItemSaveForLaterQty`, itemId, qty);
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/userlist/sfl/`,
  //       data: { items: [{ itemId: itemId, quantity: qty }] },
  //       headers,
  //     });
  //     this.isDebug() &&
  //       console.log(`EcomApi:updateItemSaveForLaterQty Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:updateItemSaveForLaterQty Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async getOrderDetails(orderId, sapAccountNo = false, userGuid) {
  //   this.isDebug() && console.log(`EcomApi:getOrderDetails`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   // if (userGuid) {
  //   //   headers['proxy_guid'] = userGuid;
  //   // }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/orders/${orderId}/views/customer`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getOrderDetails Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getOrderDetails Failure', e);
  //   }
  // }

  // async patchOrder(orderId, data, sapAccountNo = false, userGuid) {
  //   this.isDebug() && console.log(`EcomApi:patchOrder`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   if (userGuid) {
  //     headers["proxy_guid"] = userGuid;
  //   }

  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/orders/${orderId}`,
  //       cacheResults: false,
  //       headers,
  //       data,
  //     });
  //     this.isDebug() && console.log(`EcomApi:patchOrder Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:patchOrder Failure', e);
  //   }
  // }

  // async deleteOrder(orderId, sapAccountNo = false, cartType, userGuid) {
  //   this.isDebug() && console.log(`EcomApi:deleteOrder`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   if (userGuid) {
  //     headers["proxy_guid"] = userGuid;
  //   }

  //   try {
  //     const d = await this.delete({
  //       url: `${this.urlBase}/ecom/orders/${orderId}`,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:deleteOrder Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:deleteOrder Failure', e);
  //   }
  // }

  // async getInvoiceDetails(invoiceId, sapAccountNo = false, userGuid) {
  //   this.isDebug() && console.log(`EcomApi:getInvoiceDetails`);

  //   const headers = {};
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //   }

  //   if (userGuid) {
  //     headers["proxy_guid"] = userGuid;
  //   }

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/invoices/${invoiceId}`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getInvoiceDetails Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getInvoiceDetails Failure', e);
  //   }
  // }

  // async postClaim(data, sapAccountNo = false, userGuid) {
  //   this.isDebug() && console.log(`EcomApi:postClaim`);

  //   const headers = { "content-type": "application/json" };
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //     headers["proxy_sapacct"] = sapAccountNo;
  //   }
  //   if (userGuid) {
  //     headers["proxy_guid"] = userGuid;
  //   }

  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/invoices/claim`,
  //       cacheResults: false,
  //       headers,
  //       data,
  //     });
  //     this.isDebug() && console.log(`EcomApi:postClaim Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:postClaim Failure', e);
  //   }
  // }

  // async emailInvoices(data, sapAccountNo = false, userGuid) {
  //   this.isDebug() && console.log(`EcomApi:emailInvoices`);

  //   const headers = { "content-type": "application/json" };
  //   if (sapAccountNo) {
  //     headers["sapAccountNo"] = sapAccountNo;
  //     headers["proxy_sapacct"] = sapAccountNo;
  //   }
  //   if (userGuid) {
  //     headers["proxy_guid"] = userGuid;
  //   }
  //   //console.log(data);
  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/invoices/email`,
  //       cacheResults: false,
  //       headers,
  //       data,
  //     });
  //     this.isDebug() && console.log(`EcomApi:emailInvoices Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:emailInvoices Failure', e);
  //   }
  // }

  // async getAuthorDetails(authorId) {
  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/title/author-display?authorId=${authorId}`,
  //     });
  //     this.isDebug() && console.log("EcomApi: getAuthorDetails Complete", d);
  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi: getauthorDetail Failure', e);
  //   }
  // }

  // async getCarouselData(data) {
  //   this.isDebug() && console.log(`EcomApi:getCarouselData`);

  //   const headers = {
  //     "content-type": "application/json",
  //     "content-disposition": "attachment",
  //   };
  //   const params = Object.entries(data)
  //     .map((pair) => `${pair[0]}=${pair[1]}`)
  //     .join("&");

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/title/works/list-display?${params}`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getCarouselData Complete`, d);
  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getCarouselData Failure', e);
  //   }
  // }

  // async getCarouselFormatData(data) {
  //   this.isDebug() && console.log(`EcomApi:getCarouselData`);

  //   const headers = { "content-type": "application/json" };
  //   const params = Object.entries(data)
  //     .map((pair) => `${pair[0]}=${pair[1]}`)
  //     .join("&");

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/title/carousel/titles?${params}`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getCarouselData Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getCarouselData Failure', e);
  //   }
  // }

  // async undoEstimateCart(props) {
  //   this.isDebug() && console.log(`EcomApi:undoEstimateCart`);

  //   const headers = { "content-type": "application/json" };
  //   const { data } = props;

  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/estimates/carts/rollback?simulate=true`,
  //       headers,
  //       data,
  //     });
  //     this.isDebug() && console.log(`EcomApi:undoEstimateCart Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:undoEstimateCart Failure', e);
  //   }
  // }

  // async confirmEstimateCart(props) {
  //   this.isDebug() && console.log(`EcomApi:confirmEstimateCart`);

  //   const headers = { "content-type": "application/json" };
  //   const { data } = props;

  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/estimates/carts/submit-confirmation?simulate=true`,
  //       headers,
  //       data,
  //     });
  //     this.isDebug() && console.log(`EcomApi:confirmEstimateCart Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:confirmEstimateCart Failure', e);
  //   }
  // }

  // async getEstimateCarts(props) {
  //   this.isDebug() && console.log(`EcomApi:getEstimateCarts`);

  //   const headers = { 'content-type': 'application/json' };
  //   const data = {};

  //   data.shipTo = props.shipTo ? props.shipTo : [];

  //   if (props.foc) {
  //     if (Array.isArray(props.foc)) {
  //       data.foc = props.foc;
  //     } else if (typeof props.foc === 'string') {
  //       data.foc = [props.foc];
  //     }
  //   }

  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/estimates/carts/search?titleDetail=${
  //         props.titleDetail
  //       }${props.simulate ? '&simulate=true' : ''}${
  //         props.orderDetail ? '&orderDetail=true' : ''
  //       }`,
  //       cacheResults: false,
  //       headers,
  //       data,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getEstimateCarts Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getEstimateCarts Failure', e);
  //   }
  // }

  // async removeEstimateCarts(props) {
  //   this.isDebug() && console.log(`EcomApi:removeEstimateCarts`);

  //   const headers = { "content-type": "application/json" };
  //   const data = {};
  //   if (props.shipTo) {
  //     data.shipTo = props.shipTo;
  //   }
  //   if (props.foc) {
  //     if (Array.isArray(props.foc)) {
  //       data.foc = props.foc;
  //     } else if (typeof props.foc === String) {
  //       data.foc = [props.foc];
  //     }
  //   }

  //   try {
  //     const d = await this.delete({
  //       url: `${this.urlBase}/ecom/estimates/carts${
  //         !!props.force ? "?forceDelete=true" : ""
  //       }`,
  //       cacheResults: false,
  //       headers,
  //       data,
  //     });
  //     this.isDebug() && console.log(`EcomApi:removeEstimateCarts Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:removeEstimateCarts Failure', e);
  //   }
  // }

  // async removeEstimateCart(props) {
  //   this.isDebug() && console.log(`EcomApi:removeEstimateCart`);

  //   const headers = { "content-type": "application/json" };
  //   const data = { shipTo: props?.shipTo };

  //   try {
  //     const d = await this.delete({
  //       url: `${this.urlBase}/ecom/estimates/carts/${props.foc}${
  //         props.force ? "?forceDelete=true" : ""
  //       }${
  //         props.confirm ? (props.force ? "&confirm=true" : "?confirm=true") : ""
  //       }`,
  //       cacheResults: false,
  //       headers,
  //       data,
  //     });
  //     this.isDebug() && console.log(`EcomApi:removeEstimateCart Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:removeEstimateCarts Failure', e);
  //   }
  // }

  // async removeEstimateCartItems(props) {
  //   this.isDebug() && console.log(`EcomApi:removeEstimateCartItems`, props);
  //   const { shipTo, items, simulate, force } = props;

  //   const headers = {};
  //   const data = { shipTo: shipTo, items };

  //   try {
  //     const d = await this.delete({
  //       url: `${this.urlBase}/ecom/estimates/carts/${
  //         props.foc
  //       }/items?simulate=${simulate}${force ? "&forceDelete=true" : ""}`,
  //       data,
  //       headers,
  //     });
  //     this.isDebug() &&
  //       console.log(`EcomApi:removeEstimateCartItems Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:removeEstimateCartItems Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async addEstimateCartItems(props) {
  //   this.isDebug() && console.log(`EcomApi:addEstimateCartItems`, props);

  //   let headers = {};

  //   // if (props.foc) {
  //   //   data.foc = props.foc;
  //   // }
  //   // if (props.shipTo) {
  //   //   data.shipTo = props.shipTo;
  //   // }
  //   // if (props.catalogDate) {
  //   //   data.catalogDate = props.catalogDate;
  //   // }
  //   // if (props.items && Array.isArray(props.items)) {
  //   //   data.items = props.items;
  //   // }

  //   try {
  //     const d = await this.patch({
  //       url: `${this.urlBase}/ecom/estimates/carts?simulate=${
  //         props.simulate ? "true" : "false"
  //       }${props.addAll ? `&addAll=true` : ""}`,
  //       data: { carts: props.carts },
  //       headers: headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:addEstimateCartItems Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:addEstimateCartItems Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async updateEstimateItemQty(props) {
  //   this.isDebug() && console.log(`EcomApi:updateEstimateItemQty`, props);
  //   const { foc, shipTo, catalogDate, items, simulate = false } = props;

  //   let headers = {};

  //   const data = {};

  //   if (foc) {
  //     data.foc = foc;
  //   }
  //   if (shipTo) {
  //     data.shipTo = shipTo;
  //   }
  //   if (catalogDate) {
  //     data.catalogDate = catalogDate;
  //   }
  //   if (items && Array.isArray(items)) {
  //     data.items = items;
  //   }

  //   try {
  //     const d = await this.put({
  //       url: `${this.urlBase}/ecom/estimates/carts?simulate=${simulate}`,
  //       data: { carts: [data] },
  //       headers: headers,
  //     });
  //     this.isDebug() &&
  //       console.log(`EcomApi:updateEstimateItemQty Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:updateEstimateItemQty Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async updateEstimateVariantMainItem(props) {
  //   this.isDebug() &&
  //     console.log(`EcomApi:updateEstimateVariantMainItem`, props);
  //   const { foc, shipTo, mainItem, variantItems, simulate = true } = props;

  //   let headers = {};

  //   try {
  //     const d = await this.put({
  //       url: `${this.urlBase}/ecom/estimates/carts/variants/main?simulate=${simulate}`,
  //       data: { foc, shipTo, mainItem, variantItems },
  //       headers: headers,
  //     });
  //     this.isDebug() &&
  //       console.log(`EcomApi:updateEstimateVariantMainItem Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:updateEstimateVariantMainItem Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  // async moveEstimateItemsToGeneral(props) {
  //   this.isDebug() && console.log(`EcomApi:moveEstimateItemsToGeneral`, props);

  //   let headers = {};

  //   try {
  //     const d = await this.put({
  //       url: `${this.urlBase}/ecom/estimates/carts/${props.foc}/move-to/general`,
  //       data: { shipTo: props.shipTo },
  //       headers: headers,
  //     });
  //     this.isDebug() &&
  //       console.log(`EcomApi:moveEstimateItemsToGeneral Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:moveEstimateItemsToGeneral Failure', e);
  //     this.errorHandler(e);
  //     return { status: 500, error: e };
  //   }
  // }

  //async getCreditCards(paymentCreditCard /*props = { shipTo: []}*/) {
  //  this.isDebug() && console.log(`EcomApi:getCreditCards`, paymentCreditCard);
  //  let data = {};
  //  if (paymentCreditCard) {
  //    data = { cardId: paymentCreditCard };
  //  }
  //  try {
  //    const d = await this.post({
  //      url: `${this.urlBase}/ecom/card/list`,
  //      data: data,
  //    });
  //    this.isDebug() && console.log(`EcomApi:getCreditCards Complete`, d);

  //    return d;
  //  } catch (e) {
      //console.log('EcomApi:getCreditCards Failure', e);
  //  }
  //}

  //async deleteCreditCard(cardGroup) {
  //  this.isDebug() && console.log(`EcomApi:deleteCreditCard`);
  //  try {
  //    const d = await this.post({
  //      url: `${this.urlBase}/ecom/card/delete`,
  //      data: {
  //        cardGroup: cardGroup,
  //      },
  //    });
  //    this.isDebug() && console.log(`EcomApi:deleteCreditCard Complete`, d);

  //    return d;
  //  } catch (e) {
      //console.log('EcomApi:deleteCreditCard Failure', e);
  //    this.errorHandler(e);
  //    return { status: 500, error: e };
   // }
  //}

  async getHrdiCallerForAdd(address, cartKey = false) {
    this.isDebug() && console.log(`EcomApi:getHrdiCallerForAdd`);
    try {
      const d = await this.post({
        url: `${this.urlBase}/ecom/hrdicaller/caller`,
        data: address,
        headers: {
          Accept: "text/html",
        },
      });
      this.isDebug() && console.log(`EcomApi:getHrdiCallerForAdd Complete`, d);

      return d;
    } catch (e) {
      //console.log('EcomApi:getHrdiCallerForAdd Failure', e);
      this.errorHandler(e);
      return { status: 500, error: e };
    }
  }

  

  // async getActiveCatalogs() {
  //   let url = `${this.urlBase}/ecom/search/catalog/list`;

  //   try {
  //     const d = await this.get({
  //       url,
  //     });
  //     return d;
  //   } catch (e) {
  //     return { status: 500, error: e };
  //   }
  // }

  // async getCatalogDownloadsInfo(catalogId) {
  //   const url = `${this.urlBase}/ecom/downloads/?cataDate=${catalogId}`;
  //   try {
  //     const d = await this.get({
  //       url,
  //     });
  //     return d;
  //   } catch (e) {
  //     //console.log('Error handler :', e);
  //   }
  // }

  // async getCatlogDownloadFile(data, catalogId) {
  //   const url = `${this.urlBase}/ecom/downloads/?cataDate=${catalogId}&fileName=${data.fileName}`;
  //   try {
  //     const d = await this.get({
  //       url,
  //     });
  //     return d;
  //   } catch (e) {
  //     //console.log('Error handler :', e);
  //   }
  // }

  // async getBacklistDownloadsInfo(countryCode) {
  //   const url = `${this.urlBase}/ecom/downloads/backlist?country=${countryCode}`;
  //   try {
  //     const d = await this.get({
  //       url,
  //     });
  //     return d;
  //   } catch (e) {
  //     console.log("Error handler :", e);
  //   }
  // }

  // async getBacklistDownloadFile(fileName, countryCode) {
  //   const url = `${this.urlBase}/ecom/downloads/backlist?country=${countryCode}&fileName=${fileName}`;
  //   try {
  //     const d = await this.get({
  //       url,
  //     });
  //     //console.log(d);
  //     return d;
  //   } catch (e) {
  //     //console.log('Error handler :', e);
  //   }
  // }

  // async getCatalogArchive() {
  //   const url = `${this.urlBase}/ecom/search/catalog/archive`;
  //   try {
  //     const d = await this.get({
  //       url,
  //     });
  //     return d;
  //   } catch (e) {
  //     //console.log('Error handler :', e);
  //   }
  // }

  // async getPreDeliveryReports(sapAccountNo) {
  //   const url = `${this.urlBase}/ecom/delivery`;
  //   const headers = {
  //     "Content-Type": "application/json",
  //     proxy_sapacct: sapAccountNo,
  //   };

  //   try {
  //     const d = await this.post({
  //       url,
  //       headers,
  //       data: {},
  //     });
  //     return d;
  //   } catch (e) {
  //     //console.log('Error handler :', e);
  //   }
  // }

  // async getPreDeliveryReport(reportDate, sapAccountNo) {
  //   const url = `${this.urlBase}/ecom/delivery`;
  //   const headers = {
  //     "Content-Type": "application/json",
  //     proxy_sapacct: sapAccountNo,
  //   };
  //   try {
  //     const d = await this.post({
  //       url,
  //       headers,
  //       data: { deliveryDate: reportDate, shipTo: sapAccountNo },
  //     });
  //     return d;
  //   } catch (e) {
  //     //console.log('Error handler :', e);
  //   }
  // }

  // async getReturns(returnsStartDate, sapAccountNo) {
  //   const url = `${this.urlBase}/ecom/returns`;
  //   const headers = {
  //     "Content-Type": "application/json",
  //     proxy_sapacct: sapAccountNo,
  //   };
  //   try {
  //     const d = await this.post({
  //       url,
  //       headers,
  //       data: {
  //         shipTo: sapAccountNo,
  //         returnStartDate: returnsStartDate,
  //       },
  //     });
  //     return d;
  //   } catch (e) {
  //     //console.log('Error handler :', e);
  //   }
  // }

  // async submitReturns(returns, sapAccountNo, submissionType) {
  //   let url;
  //   if (submissionType === "update") {
  //     url = `${this.urlBase}/ecom/returns/update`;
  //   } else if (submissionType === "cancel") {
  //     url = `${this.urlBase}/ecom/returns/cancel`;
  //   }
  //   const headers = {
  //     "Content-Type": "application/json",
  //     proxy_sapacct: sapAccountNo,
  //   };

  //   try {
  //     const d = await this.post({
  //       url,
  //       headers,
  //       data: returns,
  //     });
  //     return d;
  //   } catch (e) {
  //     //console.log('Error handler :', e);
  //   }
  // }

  // async getWishListByID(data) {
  //   this.isDebug() && console.log(`EcomApi:getWishListByID`);

  //   const { id } = data;
  //   const headers = { "content-type": "application/json" };

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/wishlist?listId=${id}`,
  //       cacheResults: false,
  //       headers,
  //     });
  //     this.isDebug() && console.log(`EcomApi:getWishListByID Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:getWishListByID Failure', e);
  //   }
  // }
  // async submitWishListByID(data) {
  //   this.isDebug() && console.log(`EcomApi:submitWishListByID`);

  //   const { id, cart } = data;
  //   const headers = { "content-type": "application/json" };

  //   try {
  //     const d = await this.post({
  //       url: `${this.urlBase}/ecom/wishlist/usercart?listId=${id}`,
  //       headers,
  //       data: cart,
  //     });
  //     this.isDebug() && console.log(`EcomApi:submitWishListByID Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:submitWishListByID Failure', e);
  //   }
  // }
  // async cancelWishListByID(data) {
  //   this.isDebug() && console.log(`EcomApi:cancelWishListByID`);

  //   const { id, cart } = data;
  //   const headers = { "content-type": "application/json" };

  //   try {
  //     const d = await this.get({
  //       url: `${this.urlBase}/ecom/wishlist/cancel?listId=${id}`,
  //       headers,
  //       cacheResults: false,
  //     });
  //     this.isDebug() && console.log(`EcomApi:cancelWishListByID Complete`, d);

  //     return d;
  //   } catch (e) {
  //     //console.log('EcomApi:cancelWishListByID Failure', e);
  //   }
  // }

  // async getTitleOrderHistory(props) {
  //   this.isDebug() && console.log(`EcomApi:getTitleOrderHistory`);
  //   const url = `${this.urlBase}/ecom/titlehistory/detail/all`;
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   if (props.sapAccountNo) {
  //     headers["sapAccountNo"] = props.sapAccountNo;
  //   }
  //   try {
  //     const d = await this.post({
  //       url,
  //       headers,
  //       data: {
  //         shipTo: props.shipTo,
  //         isbn: props.isbn,
  //       },
  //     });
  //     this.isDebug() && console.log(`EcomApi:getTitleOrderHistory Complete`, d);
  //     return d;
  //   } catch (e) {
  //     //console.log('Error handler :', e);
  //   }
  // }
  // async getOrderHistory(props) {
  //   this.isDebug() && console.log(`EcomApi:getOrderHistory`);
  //   const url = `${this.urlBase}/ecom/orderhistory/detail/all`;
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   try {
  //     const d = await this.post({
  //       url,
  //       headers,
  //       data: {
  //         shipTo: props.shipTo,
  //         isbn: props.isbn,
  //         formatCode: props.formatCode,
  //       },
  //     });
  //     this.isDebug() && console.log(`EcomApi:getOrderHistory Complete`, d);
  //     return d;
  //   } catch (e) {
  //     //console.log('Error handler :', e);
  //   }
  // }
}

export default EcomApi;
