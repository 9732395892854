import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  deliveryHomePageWidget: true,
};

// export const signUserOut = createAsyncThunk(
//   'user/signUserOut',
//   // Declare the type your function argument here:
//   async (params, {dispatch, getState, rejectWithValue}) => {
//     console.log('Starting signUserOut');
//     await createConfig({
//       clientId: configFile.clientId,
//       redirectUri: configFile.redirectUri,
//       endSessionRedirectUri: configFile.endSessionRedirectUri,
//       discoveryUri: configFile.discoveryUri,
//       scopes: configFile.scopes,
//       requireHardwareBackedKeyStore: configFile.requireHardwareBackedKeyStore,
//     });

//     console.log('Checking for download Tasks');
//     let lostTasks = await RNBackgroundDownloader.checkForExistingDownloads();
//     for (let task of lostTasks) {
//       console.log('Running download task', task);
//       task.stop();
//     }
//     dispatch(removeAllDownloads());

//     await AsyncStorage.removeItem('userSession');

//     try {
//       await clearTokens();
//     } catch (e) {
//       console.log('clearToken', e);
//     }

//     dispatch(resetLibraryAll());
//   },
// );

const persistantSlice = createSlice({
  name: "prhpersistant",
  initialState,
  reducers: {
    setDeliveryHomePageWidget: (state, action) => {
      const value = action.payload;
      state.deliveryHomePageWidget = value;
    },
  },
});

export const { setDeliveryHomePageWidget } = persistantSlice.actions;

export default persistantSlice.reducer;
