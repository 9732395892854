import { useMemo } from "react";
import DialogModal from "./DialogModal";

const ModifiedConfirmedCartModal = (props) => {
  const { open, setOpen, callback, postCallback, cancelCallback } = props;

  const localOptions = useMemo(() => {
    return { className: "tm-modal-container" };
  });
  return (
    <DialogModal
      setOpen={setOpen}
      open={open}
      contentPt='pt2'
      dialogOptions={{
        header: "Adding to a confirmed cart",
        actions: [
          {
            hidden: false,
            label: "Cancel",
            bold: true,
            preAction: async () => {
              return { proceed: true };
            },
            action: () => {
              if (cancelCallback) cancelCallback();
              return { proceed: true };
            },
          },
          {
            hidden: false,
            label: "Yes, continue",
            textColorOverride: "white",
            backgroundColorOverride: "blue",
            borderColorOverride: "blue",
            textColorHoverOverride: "blue",
            backgroundHoverColorOverride: "white",
            borderHoverColorOverride: "blue",
            bold: true,
            preAction: async () => {
              if (callback) callback();
              return { proceed: true };
            },
            action: () => {
              if (postCallback) postCallback();
              return { proceed: true };
            },
          },
        ],
      }}
      options={localOptions}
    >
      <div className='Xparagraph-2'>
        <div className='mb-2'>
          You are adding items to an FOC cart that is already confirmed.
        </div>
        <div className='fort-book mb-2'>
          Note: You will need to go to the cart and confirm any updates before
          the FOC date to ensure the correct quantities are placed and processed
          on FOC date.
        </div>
        <div className=''>Would you like to continue?</div>
      </div>
    </DialogModal>
  );
};

export default ModifiedConfirmedCartModal;
