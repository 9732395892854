import { Component } from 'react';
import withSecurity from '../hc/withSecurity';
import withAccount from '../hc/withAccount';
import withCart from '../hc/withCart';
import AccountsChangePassword from '../../assets/images/accounts-change-password.svg';
import { Icon, Modal, Button } from 'semantic-ui-react';
import { Divider } from '../../shared-react-components/elements/elements';
import ResetPasswordForm from './ResetPasswordForm';

import './admin.scss';
import withMobile from '../hc/withMobile';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyEmail: 0,
      loading: true,
      displayAccountDetailFields: false,
      createPasswordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      createPasswordEye: 'eye',
      eyeButtonShow: '',
      eyeButtonHide: '',
      passwordMatchErrorMessage: '',
    };
  }

  async componentDidMount() {
    const { account } = this.props;
    account.clearStateField();
    account.clearErrorFields();
    account.setStateField({
      firstName: this.props.user.user.profile.given_name + 11,
      lastName: this.props.user.user.profile.family_name,
      oldPassword: '',
      createPassword: '',
      confirmPassword: '',
      requiredPasswordValidationScore: 8,
    });
  }

  showPassword = (currentShowHide) => {
    const newState = { ...this.state };
    if (newState[currentShowHide] === 'password') {
      newState[currentShowHide] = 'text';
    } else {
      newState[currentShowHide] = 'password';
    }
    this.setState(newState);
  };

  submitUser = () => {
    const { account } = this.props;
    account.updatePassword(this.props.user.user.profile.email);
  };

  render() {
    const { account, size } = this.props;
    const isMobile = this.props.mobile.isMobile;
    return (
      <div>
        {/*console.log('reset password load', account.formState)*/}
        <Modal
          dimmer="inverted"
          size="tiny"
          className={'admin-modal'}
          centered={false}
          open={account.formState.responseModal}
        >
          <Button
            className={'admin-button'}
            onClick={(e) => account.dismissCreateSubMessage(e)}
          >
            <Icon name="remove" />
          </Button>
          <Modal.Content>
            <p>
              {account.formState.updateResponse === 'success' &&
              account.formState.updateMethod === 'patch' ? (
                <div>
                  {account.formState.createUserPost.fullName
                    ? account.formState.createUserPost.fullName
                    : 'User'}{' '}
                  has been updated.
                </div>
              ) : (
                ''
              )}
              {account.formState.updateResponse === 'failure' &&
              account.formState.updateMethod === 'patch' ? (
                <div>
                  Unable to update{' '}
                  {account.formState.createUserPost.fullName
                    ? account.formState.createUserPost.fullName
                    : 'User'}
                  . Please try again or contact customer service.
                </div>
              ) : (
                ''
              )}
            </p>
          </Modal.Content>
        </Modal>
        <div className="accounts-settings">
          <div className="d-flex">
            <div className="accounts-page-headers-images">
              <img src={AccountsChangePassword} alt="Change Password" />
            </div>
            <div
              className={`accounts-page-headers fort-light text-gray-900 fs-24px ${
                isMobile ? 'mb-4' : ''
              }`}
            >
              Change Password
            </div>
          </div>
          {!isMobile && <Divider className="accounts-header-divider" />}
        </div>
        <ResetPasswordForm size={size} />
      </div>
    );
  }
}

export default withSecurity(withAccount(withCart(withMobile(ResetPassword))));
