import React from 'react';
import { createRoot } from 'react-dom/client';
import DialogModal from './modals/DialogModal';

const UserConfirmation = (callback, dirtyHandler) => {
  const id = 'confirmation-container';
  let container = document.getElementById(id);
  if (container) {
    container.parentNode.removeChild(container);
  }
  container = document.createElement('div');
  container.setAttribute('id', id);
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);

  const decoratedCallback = (v) => {
    if (v) {
      dirtyHandler.resetDirtyState();
    }
    callback(v);
  };

  const root = createRoot(container); // createRoot(container!) if you use
  root.render(
    <DialogModal
      dialogOptions={dirtyHandler.dialogObject.dialogOptions}
      callback={decoratedCallback}
    >
      {dirtyHandler.dialogObject.content ||
        'You have unsaved fields are you sure you want to proceed?'}
    </DialogModal>
  );
};
export default UserConfirmation;
