import { Component } from 'react';
import { withRouter } from 'react-router';
import { Form } from 'semantic-ui-react-form-validator';
import PRHDropDown from '../../shared-react-components/elements/dropdown';
import TextValidator from '../../shared-react-components/elements/input';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import PRHForm from '../../shared-react-components/elements/PRHForm';
import { IDENTITY_CONFIG } from '../../utils/authConst';
import userRoleConversion from '../../utils/userRoleConversion';
import { ButtonHolder } from '../elements/elements';
import withAccount from '../hc/withAccount';
import withApp from '../hc/withApp';
import withCart from '../hc/withCart';
import withSecurity from '../hc/withSecurity';
import DialogModal from '../modals/DialogModal';
import './admin.scss';

// import { findAllByDisplayValue } from '@testing-library/react';

class ManageUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmDialog: false,
      dataDirty: false,
      Ecom_User_ID: '',
      firstName: '',
      lastName: '',
      mobile: '',
      password: '',
      sappAcct: '',
      role: '',
      storeOptions: [
        {
          key: '5004049404',
          text: '[5004049404] Biz Demo, Westminster, MD',
          value: '5004049404',
        },
        {
          key: '4004049404',
          text: '[4004049404] Biz Demo, NY, NY',
          value: '4004049404',
        },
      ],
      roleOptions: [
        {
          key: '1',
          value: 'W1',
          text: 'Level 1: Shipper / Receiver ',
          content: (
            <div>
              <div className="fort-book fs-12px">
                Level 1: Shipper / Receiver
              </div>
              <div className="fort-book fs-10px">
                Access to view and track orders, view invoices, and submit
                claims. Permissions limited to a specific store.
              </div>
            </div>
          ),
        },
        {
          key: '2',
          value: 'W2',
          text: 'Level 2:  Buyer',
          content: (
            <div>
              <div className="fort-book fs-12px">Level 2: Buyer</div>
              <div className="fort-book fs-10px">
                Access to place, view, and track orders, view invoices, and
                submit claims. Permission to access all store accounts
              </div>
            </div>
          ),
        },
        {
          key: '3',
          value: 'W3',
          text: 'Level 3: Buyer / Co-op',
          content: (
            <div>
              <div className="fort-book fs-12px">Level 3: Buyer / Co-op</div>
              <div className="fort-book fs-10px">
                Access to place, view, and track orders, view invoices, submit
                claims and Co-op advertising. Permission to access all store
                accounts
              </div>
            </div>
          ),
        },
        {
          key: '4',
          value: 'W9',
          text: 'Level 4: Administrator',
          content: (
            <div>
              <div className="fort-book fs-12px">Level 4: Administrator</div>
              <div className="fort-book fs-10px">
                Access to place, view, and track orders, view invoices, submit
                claims, Co-op advertising, and user management. Permission to
                access all store accounts
              </div>
            </div>
          ),
        },
      ],
    };
  }

  async componentDidUpdate() {
    const { account } = this.props;
    if (account.formState.step === 'verify') {
      this.props.history.push('/registration-subaccount');
    }
  }

  enterID = (e) => {
    this.setState({ userId: e.target.value });
  };

  enterPW = (e) => {
    this.setState({ password: e.target.value });
  };

  userInitials = (firstName, lastName) => {
    let i = '';

    if (firstName) {
      i += firstName.charAt(0);
    }
    if (lastName) {
      i += lastName.charAt(0);
    }

    return i;
  };

  handleChange = (e) => {
    const { account } = this.props;
    this.setState({ dataDirty: true });
    account.handleChangeManageUsers(e);
  };

  handleSubmitUser() {
    const { account } = this.props;
    account.handleSubmitSubAccountUser(account.formState.account);
  }

  isDeleteUser = () => {
    const { account, user, cart } = this.props;

    if (account.formState.manageUserModal.header === 'Add User') return false;

    if (
      cart.customerInfo.currentUser.roleNo === 'W9' &&
      account.formState.createUserForm.email !== '' &&
      account.formState.createUserForm.email !== user.user.profile.email
    )
      return true;

    return false;
  };

  isAdmin = () => {
    const { cart } = this.props;
    if (cart.customerInfo.currentUser.roleNo === 'W9') return true;

    return false;
  };

  isUnverifiedUser = () => {
    const { account } = this.props;

    if (account.formState.createUserForm.userStatus === 'pending') {
      return true;
    }
    return false;
  };
  isLocatoinRestriction = () => {
    const { cart } = this.props;
    if (cart.customerInfo.currentUser.roleNo !== 'W1') return false;

    return true;
  };

  isLevelRestriction = () => {
    const { account, user } = this.props;

    if (
      this.isAdmin() &&
      account.formState.createUserForm.email !== user.user.profile.email &&
      account.formState.createUserForm.userStatus !== 'pending'
    )
      return false;

    return true;
  };

  setConfirmDialogOpen = (o) => {
    this.setState({ confirmDialog: o });
  };

  discardChanges = () => {
    const { modalControl, account } = this.props;
    this.setState({ confirmDialog: false });
    account.clearStateField();
    modalControl(false, 'Add User', null, null);
  };

  restrictRoleAssignment = (role) => {
    const { account } = this.props;
    const { roleOptions } = this.state;
    let roleOptionsReturn;
    if (account.formState.manageUserModal.header === 'Add User') {
      roleOptionsReturn = roleOptions;
    } else {
      roleOptionsReturn = roleOptions.slice(1);
    }
    return roleOptionsReturn;
  };

  setDropDownTextStyle = (text) => {
    let dropDownTextStyle;
    if (text !== undefined && (text==='Select Access Level' || text==='Select a Store')) {
      dropDownTextStyle='account-dropdown-text';
    } else {
      dropDownTextStyle='';
    }
    return dropDownTextStyle;
  }


  render() {
    const { account, user, admin, setStepHandler, modalControl } = this.props;
    const { firstName, lastName, email, mobile, sapAcct, role } = this.state;
    const loginAction = IDENTITY_CONFIG.login;
    const loginTarget = IDENTITY_CONFIG.target + '/';
    const showMe = IDENTITY_CONFIG.base + '/user/bdasgupta';

    Form.addValidationRule('isPhoneNumber', (value) => {
      const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (value.match(phoneno)) {
        return true;
      } else {
        return false;
      }
    });

    const userRole = userRoleConversion(account.formState.createUserForm.role);
    return (
      <>
        <div className={'manage-user-form'}>
          <DialogModal
            open={this.state.confirmDialog}
            setOpen={this.setConfirmDialogOpen}
            options={{ size: 'mini' }}
            dialogOptions={{
              header: 'DISCARD UNSAVED CHANGES?',
              actions: [
                {
                  label: 'Cancel',
                  className: 'ml-auto',
                  // color: 'red',
                  // icon: 'times',
                  preAction: () => ({ proceed: true }),
                  action: () => ({ proceed: true }),
                },
                {
                  label: 'Discard',
                  borderColorOverride: 'blue',
                  textColorOverride: 'white',
                  textColorHoverOverride: 'blue',
                  backgroundColorOverride: 'blue',
                  backgroundHoverColorOverride: 'white',
                  iconColorOverride: 'white',
                  iconColorHoverOverride: 'blue',
                  // color: 'green',
                  // icon: 'checkmark',
                  preAction: () => {
                    // this.discardChanges();
                    return { proceed: true };
                  },

                  action: () => {
                    this.discardChanges();
                    return { proceed: true };
                  },
                },
              ],
            }}
          >
            <div className="modal-content--heading mb-2">
              Edits to the user's information will be discarded.
            </div>
          </DialogModal>
          {account.formState.manageUserModal.header === 'Delete User' ? (
            <div>
              <div className="d-flex flex-row">
                <div className="no-user-image mr-3">
                  <div className="no-image-text">
                    {this.userInitials(
                      account.formState.createUserForm.firstName,
                      account.formState.createUserForm.lastName
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {account.formState.createUserForm.firstName}{' '}
                    {account.formState.createUserForm.lastName}
                  </span>
                  {account.formState.createUserForm.email}
                  <br />
                  {account.formState.createUserForm.mobile}
                  <br />
                  {account.formState.addresses[
                    account.formState.account.accountNo
                  ] &&
                  account.formState.addresses[
                    account.formState.account.accountNo
                  ].name1 ? (
                    <>
                      {
                        account.formState.addresses[
                          account.formState.account.accountNo
                        ].name1
                      }
                      <br />
                    </>
                  ) : (
                    ''
                  )}
                  {account.formState.addresses[
                    account.formState.account.accountNo
                  ] &&
                  account.formState.addresses[
                    account.formState.account.accountNo
                  ].name2 ? (
                    <>
                      {
                        account.formState.addresses[
                          account.formState.account.accountNo
                        ].name2
                      }
                      <br />
                    </>
                  ) : (
                    ''
                  )}
                  {account.formState.addresses[
                    account.formState.account.accountNo
                  ] &&
                  account.formState.addresses[
                    account.formState.account.accountNo
                  ].name3 ? (
                    <>
                      {
                        account.formState.addresses[
                          account.formState.account.accountNo
                        ].name3
                      }
                      <br />
                    </>
                  ) : (
                    ''
                  )}
                  {account.formState.addresses[
                    account.formState.account.accountNo
                  ] &&
                  account.formState.addresses[
                    account.formState.account.accountNo
                  ].street ? (
                    <>
                      {
                        account.formState.addresses[
                          account.formState.account.accountNo
                        ].street
                      }
                      <br />
                    </>
                  ) : (
                    ''
                  )}
                  {account.formState.addresses[
                    account.formState.account.accountNo
                  ] &&
                  account.formState.addresses[
                    account.formState.account.accountNo
                  ].city ? (
                    <>
                      {
                        account.formState.addresses[
                          account.formState.account.accountNo
                        ].city
                      }
                      ,{' '}
                    </>
                  ) : (
                    ''
                  )}
                  {account.formState.addresses[
                    account.formState.account.accountNo
                  ] &&
                  account.formState.addresses[
                    account.formState.account.accountNo
                  ].state ? (
                    <>
                      {
                        account.formState.addresses[
                          account.formState.account.accountNo
                        ].state
                      }{' '}
                    </>
                  ) : (
                    ''
                  )}
                  {account.formState.addresses[
                    account.formState.account.accountNo
                  ] &&
                  account.formState.addresses[
                    account.formState.account.accountNo
                  ].postalCode ? (
                    <>
                      {
                        account.formState.addresses[
                          account.formState.account.accountNo
                        ].postalCode
                      }
                      <br />
                    </>
                  ) : (
                    ''
                  )}
                  {userRole}
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <ButtonHolder className="mb-2">
                <PRHButton
                  //disabled
                  icon="trash alternate"
                  size="large"
                  className={'float-right margin-left mr-2'}
                  borderColorOverride="blue"
                  textColorOverride="white"
                  textColorHoverOverride="blue"
                  backgroundColorOverride="blue"
                  backgroundHoverColorOverride="white"
                  iconColorOverride="white"
                  iconColorHoverOverride="blue"
                  onClick={() => account.deleteSubUser(false, '', null, null)}
                >
                  {account.formState.manageUserModal.header}
                </PRHButton>
                <PRHButton
                  size="large"
                  className={'float-right'}
                  // autoFocus
                  onClick={() =>
                    modalControl(
                      true,
                      'Edit User',
                      account.formState.createUserForm.email,
                      null
                    )
                  }
                >
                  Cancel
                </PRHButton>
              </ButtonHolder>
            </div>
          ) : (
            <>
              <PRHForm
                width={12}
                // ref={loginForm}
                instantValidate
                onSubmit={(e) => this.handleSubmitUser(e)}
                method="post"
                //action={loginAction}
                id="loginForm"
              >
                <TextValidator
                  name="firstName"
                  type="text"
                  disabled={this.isUnverifiedUser()}
                  onChange={(e) => this.handleChange(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  value={account.formState.createUserForm.firstName}
                  label={<label className={'fort-book'}>First Name</label>}
                  placeholder="First Name"
                  width={16}
                />
                <TextValidator
                  name="lastName"
                  type="text"
                  disabled={this.isUnverifiedUser()}
                  onChange={(e) => this.handleChange(e)}
                  validators={['required']}
                  errorMessages={['this field is required']}
                  value={account.formState.createUserForm.lastName}
                  label={<label className={'fort-book'}>Last Name</label>}
                  placeholder="Last Name"
                  width={16}
                />
                <TextValidator
                  disabled={
                    account.formState.createUserForm.email ===
                    user.user.profile.email
                  }
                  name="email"
                  type="text"
                  disabled={this.isUnverifiedUser()}
                  onChange={(e) => this.handleChange(e)}
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'this field is required',
                    'this field must be an email',
                  ]}
                  value={account.formState.createUserForm.email}
                  label={<label className={'fort-book'}>Email</label>}
                  placeholder="Email"
                  width={16}
                />
                <TextValidator
                  name="mobile"
                  type="text"
                  disabled={this.isUnverifiedUser()}
                  onChange={(e) => this.handleChange(e)}
                  value={account.formState.createUserForm.mobile}
                  label={
                    <label className={'fort-book'}>
                      Phone Number{' '}
                      <span style={{ fontStyle: 'italic' }}>(optional)</span>
                    </label>
                  }
                  placeholder="(###) ###-####"
                  width={16}
                />
                {account.formState.manageUserModal.header === 'Add User' ||
                (account.formState.manageUserModal.header === 'Edit User' &&
                  account.formState.createUserForm.role !== 'W1') ? (
                  <PRHDropDown
                    name="role"
                    id="role"
                    className={`inpage-dropdown mb-1 ${this.setDropDownTextStyle(account.formState.dropDowns.role)}`}
                    disabled={this.isLevelRestriction()}
                    value={account.formState.createUserForm.role}
                    text={account.formState.dropDowns.role}
                    onClick={()=>account.handleOnClickDropDowns('role', 'Select Access Level')}
                    //onClick={()=>this.onClickDropDown('role', 'Select Access Level')}
                    onChange={(e, data) => {
                      this.setState({ dataDirty: true });
                      account.handleSubAccountChangeDropDown(e, data);
                    }}
                    validators={['required']}
                    errorMessages={['this field is required']}
                    placeholder="Select Access Level"
                    fluid
                    selection
                    options={this.restrictRoleAssignment(
                      account.formState.createUserForm.role
                    )}
                    label={<label className={'fort-book'}>Access Level</label>}
                    width={16}
                  />
                ) : (
                  ''
                )}
                {account.formState.createUserForm.role === 'W1' &&
                account.formState.manageUserModal.header === 'Add User' ? (
                  <>
                    <PRHDropDown
                      //type="dropdown"
                      id="sapAcct"
                      name="sapAcct"
                      className={`inpage-dropdown ${this.setDropDownTextStyle(account.formState.dropDowns.sapAcct)}`}
                      variant="manage-users"
                      disabled={this.isLocatoinRestriction()}
                      text={account.formState.dropDowns.sapAcct}
                      onClick={()=>account.handleOnClickDropDowns('sapAcct', 'Select a Store')}
                      onChange={(e, data) => {
                        this.setState({ dataDirty: true });
                        account.handleSubAccountChangeDropDown(e, data);
                      }}
                      validators={['required']}
                      errorMessages={['this field is required']}
                      errorCustomStyle={'mt-1'}
                      placeholder="Select a Store"
                      fluid
                      selection
                      value={account.formState.createUserForm.sapAcct}
                      options={account.formState.shipTosDropDown}
                      label={<label className={'fort-book'}>Location</label>}
                      width={16}
                    />
                    <div className={'form-spacer'} />
                    <div className={'grey-box-text-additional-info'}>
                      Permissions for Level 1 users will be limited to this
                      address only. Users with access Levels 2-4 do not have
                      location restrictions.
                    </div>
                  </>
                ) : (
                  ''
                )}
                {account.formState.manageUserModal.header === 'Edit User' ? (
                  <>
                    <div className={'form-spacer'} />
                    <div className={'grey-box-text-additional-info'}>
                      If you'd like to change a user's role to level 1, or
                      change the store assigned to a level 1 user, please delete
                      the user and recreate the user with the desired setup.
                    </div>
                  </>
                ) : (
                  ''
                )}
                <br />
                <br />
                <ButtonHolder>
                  {this.isDeleteUser() ? (
                    <PRHButton
                      size="large"
                      className={'float-left'}
                      onClick={() =>
                        modalControl(
                          true,
                          'Delete User',
                          account.formState.createUserForm.email,
                          "This user's account will be permanently removed and you will not be able to access it again."
                        )
                      }
                    >
                      Delete User
                    </PRHButton>
                  ) : null}

                  <PRHButton
                    disabled={this.isUnverifiedUser()}
                    icon="check"
                    size="large"
                    className={'float-right margin-left'}
                    borderColorOverride="blue"
                    textColorOverride="white"
                    textColorHoverOverride="blue"
                    backgroundColorOverride="blue"
                    backgroundHoverColorOverride="white"
                    iconColorOverride="white"
                    iconColorHoverOverride="blue"
                    // type="submit"
                    // onClick={(e) => loginForm.submit()}
                  >
                    {account.formState.manageUserModal.header}
                  </PRHButton>
                  <PRHButton
                    size="large"
                    className={'float-right'}
                    // autoFocus
                    type="button"
                    onClick={() => {
                      // modalControl(false, 'Add User', null, null)
                      if (this.state.dataDirty) this.setConfirmDialogOpen(true);
                      else this.discardChanges();
                    }}
                  >
                    Cancel
                  </PRHButton>
                </ButtonHolder>
                <br />
                <br />
              </PRHForm>
            </>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(
  withSecurity(withApp(withCart(withAccount(ManageUserForm))))
);
