import { useEffect, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";

/*
  it appears that setting inverted on the dimmer trickles
  down to the Loader
*/

const LoadingTakeover = (props) => {
  // console.log('LoadingTakeover', props);
  const { loadingTakeover, loadingTakeoverConfig, children } = props;
  const {
    inverted,
    text,
    longLoadText,
    longLoadTextTimeout = 4000,
    backgroundBlurLight,
    displayTimeout = 0,
  } = loadingTakeoverConfig;

  const blurLight = backgroundBlurLight
    ? "loading-takeover-dimmer-blur-light"
    : "";
  const classNames = [blurLight, "loading-takeover-dimmer"];

  const [msgText, setMsgText] = useState(text || "Loading");
  const [displayLoader, setDisplayLoader] = useState();

  let timer;
  let displayTimer;

  useEffect(() => {
    // console.log("LoadTakeover", text, loadingTakeover, loadingTakeoverConfig);
    if (loadingTakeover && displayTimeout > 0) {
      setDisplayLoader(false);
      clearTimeout(displayTimer);
      displayTimer = setTimeout(() => {
        setDisplayLoader(true);
      }, displayTimeout);
      return () => clearTimeout(displayTimer);
    } else {
      clearTimeout(displayTimer);
      setDisplayLoader(loadingTakeover);
    }
  }, [loadingTakeover]);

  useEffect(() => {
    setMsgText(text);
    if (longLoadText) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setMsgText(longLoadText);
      }, longLoadTextTimeout);
      return () => clearTimeout(timer);
    }
  }, [text]);

  return (
    <>
      <Dimmer
        className={classNames.join(" ")}
        inverted={inverted || true}
        active={displayLoader || false}
        page
      >
        <div className='loading-takeover'>
          <Loader inverted={inverted || true}>{msgText}</Loader>
        </div>
      </Dimmer>
      {children}
    </>
  );
};

LoadingTakeover.propTypes = {
  loadingTakeoverConfig: PropTypes.object,
  loadingTakeover: PropTypes.bool,
  // inverted: PropTypes.bool,
  // text: PropTypes.string,
  // backgroundBlurLight: PropTypes.bool,
};

LoadingTakeover.defaultProps = {
  // loadingTakeover: false,
  // inverted: true,
  // text: 'Loading',
  // backgroundBlurLight: true,
};
export default LoadingTakeover;
