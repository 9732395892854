import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import withApp from "../../../components/hc/withApp";
import LoadingContent from "./LoadingContent";
import PRHButton from "../../../shared-react-components/elements/prhbutton";
import { translatePost } from "../../../utils/wordpressHelpers";
import useCart from "../../../components/hc/useCart";
import {
  useLazyGetPostQuery,
  useLazyGetPostsQuery,
} from "../../../api/bizcontentApi";

const Post = ({ postId, app, postType }) => {
  const history = useHistory();
  const location = useLocation();
  const cart = useCart();
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [date, setDate] = useState(false);
  const [titleListChecked, setTitleListChecked] = useState(false);
  const [performGetPost, getPostResult] = useLazyGetPostQuery();

  const reset = () => {
    setLoading(true);
    setError(false);
    setTitleListChecked(false);
  };

  useEffect(() => {
    async function asyncCall() {
      reset();

      const resp = await performGetPost({ id: postId, postType });
      // console.log("calling getpost", postId, resp);
      let post = {};
      if (resp.isSuccess) {
        // if (resp.data.length === 1) {
        post = resp.data;
        // }
      }

      if (!!post?.error?.message) {
        setError(post.error.message);
      } else {
        const { date, title, content, meta } = translatePost(post);

        setTitle(title);
        /*
        if (content) {
          setContent(content.replace(/max-width:\s*\w+/gi, "max-width: 100%"))
        }*/
        setContent(content);
        setDate(date);
        const title_list = meta?.title_list;

        if (
          Array.isArray(title_list) &&
          !!title_list.filter((e) => !!e).length > 0
        ) {
          cart.performSearch(title_list.join(" "), {
            title,
            title_list,
            body_copy: meta?.body_copy,
            overwriteHistory: true,
          });
          return;
        }
        setTitleListChecked(true);
      }
      setLoading(false);
    }

    asyncCall();
  }, [postId, postType, cart]);

  const titleFilter = (title) => {
    if (title.indexOf('"') !== -1) {
      const titleSplit = title.split('"');
      return `to ${titleSplit[1]}`;
    }
    return "";
  };

  if (!titleListChecked) {
    return <></>;
  }

  const previousPageTitle = location?.state?.sourcePageTitle
    ? `${titleFilter(location.state.sourcePageTitle)}`
    : null;

  return (
    <div className='pb-4'>
      {loading && <LoadingContent />}
      {!loading && error && <p>No Post Found</p>}
      {!loading && !error && (
        <>
          <PRHButton
            className='mb-5'
            icon='arrow left'
            onClick={history.goBack}
          >
            Back {previousPageTitle}
          </PRHButton>

          <h1 className='mt-0 mb-2 pb-3 border-bottom-gray-300 p-0 fs-24px fort-light lh-30px lsp--05 text-gray-900'>
            {title}
          </h1>
          <p className='mt-0 mb-4 text-gray-700 fs-12px lh-16px lsp--02'>
            {moment(date).format("MMMM DD, YYYY")}
          </p>
          <div
            className='dangerous-html'
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </>
      )}
    </div>
  );
};

Post.defaultProps = {
  postId: null,
  postType: "post",
};

export default withApp(Post);
