import { forwardRef, Component } from "react";
import { Divider } from "../../../components/elements/elements";
import { PRHContainer } from "../../../shared-react-components/elements/elements";
import { From, Input } from "semantic-ui-react-form-validator";
import { Radio } from "semantic-ui-react";
import { NumericFormat } from "react-number-format";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input/input";
import { Checkbox, Icon, Popup } from "semantic-ui-react";
import SelectUSState from "react-select-us-states";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";
import "react-datepicker/dist/react-datepicker.css";

const PRHInput = forwardRef((props, ref) => (
  <Input
    ref={ref}
    {...props}
    style={{ width: "75%" }}
    // validators={['isPhoneNumber']}
    // errorMessages={['The phone format is (###) ###-####']}
  ></Input>
));

export default class CCShippingMethod extends Component {
  render() {
    const { shippingMethod, shippingCountry } = this.props;
    let shippingEdit = false;
    if (shippingCountry !== "US" && shippingCountry !== "CA")
      shippingEdit = true;
    return (
      <PRHContainer className='cp-left content-block-border w-100 m-0 pl-0 pr-0 mr-6 mb-3 pt-3'>
        <div className='d-flex ml-3 mr-3 align-items-center mb-1'>
          <div className='d-flex fort-bold mb-2 fs-2 align-items-center'>
            <span className='cp-section-number fs-3'>3</span>
            <span className='fs-2 text-uppercase'>Shipping Method</span>
          </div>
        </div>
        <Divider tight className='mb-3' />
        <div className='d-flex flex-row mt-2 ml-3 mr-3'>
          <div className='w-50 d-flex flex-column ml-4 ccs-shipping-method'>
            <div className='d-flex flex-row mb-2 align-items-center'>
              <Radio
                disabled={shippingEdit}
                name='shippingMethod'
                value={"01"}
                checked={shippingMethod === "01" ? true : false}
                onChange={(e) => this.props.setValue("shippingMethod", "01")}
              />
              <div className='ml-2'>Standard Shipping</div>
            </div>
            <div className='d-flex flex-row mb-2 align-items-center'>
              <Radio
                disabled={shippingEdit}
                name='shippingMethod'
                value={"04"}
                checked={shippingMethod === "04" ? true : false}
                onChange={(e) => this.props.setValue("shippingMethod", "04")}
              />
              <div className='ml-2'>Next Day Air</div>
            </div>
            <div className='d-flex flex-row align-items-center'>
              <Radio
                disabled={shippingEdit}
                name='shippingMethod'
                value={"05"}
                checked={shippingMethod === "05" ? true : false}
                onChange={(e) => this.props.setValue("shippingMethod", "05")}
              />
              <div className='ml-2'>Second Day Air</div>
            </div>
          </div>
          <div className='w-50'>
            <div className='ccs-shipping-method-box'>
              For Next Day or Second Day Air, If the order must ship today, due
              to our 11:00 am cut off, please contact the Reviews team.
              {/* Expedited shipping options are currently unavailable. If you have
              urgent need, please reach out to your Division’s Approvers: PYR:
              Emily Romero; RHCB: John Adamo; PPG: Liza Cassity; RHPG: Stacey
              Witcraft; KDPG: Tyler Goodson. All other departments or groups:
              Lynda Shepard. These shipping options will be evaluated and
              implemented when circumstances allow. */}
            </div>
          </div>
        </div>
      </PRHContainer>
    );
  }
}
