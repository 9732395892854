import React from "react";
import PropTypes from "prop-types";
import { Loader, Modal } from "semantic-ui-react";
import DialogModal from "./DialogModal";

function BridgeReceivingFailureModal(props) {
  const { open, setOpen } = props;

  return (
    <DialogModal
      setOpen={setOpen}
      open={open}
      contentPt='pt2'
      closeOnEscape={false}
      closeOnDimmerClick={false}
      dialogOptions={{
        header: "ITEMS COULD NOT BE RECEIVED",
        actions: [
          {
            hidden: false,
            label: "OK",
            textColorOverride: "white",
            backgroundColorOverride: "blue",
            borderColorOverride: "blue",
            textColorHoverOverride: "blue",
            backgroundHoverColorOverride: "white",
            borderHoverColorOverride: "blue",
            bold: true,
            preAction: async () => {
              return { proceed: true };
            },
            action: () => {
              return { proceed: true };
            },
          },
        ],
      }}
      options={{ className: "tm-modal-container" }}
    >
      <div className='fort-bold fs14-px lh19-px'>
        Sorry, your items could not be received at this time. Please try again
        later.
      </div>
      <div className='fort-book fs14-px lh19-px'>
        Your items are still available in your Wish List.
      </div>
    </DialogModal>
  );
}

BridgeReceivingFailureModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default BridgeReceivingFailureModal;
