import React, {useEffect, useState} from "react";
import { DefaultTabBar, Tabs } from 'rmc-tabs';
import { useLocation } from 'react-router-dom';
import _ from "underscore";



export const AdminMenuMobile = ({ menu, handleMenuItemClick, activeItem }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { pathname } = useLocation();

  let tabDataTemp = [];
  if(menu){
    _.each(menu, (value, key) => {
      tabDataTemp.push({
        title: value.label,
        tab: value.component,
        key: key,
      });
    });
  }

  useEffect(() => {
    if(tabDataTemp.length > 0){
      const currentActiveComponent = pathname.split('/')[2];
      const currentActiveTabIndex = tabDataTemp.findIndex(tab => tab.tab === currentActiveComponent);
      setActiveIndex(currentActiveTabIndex);
    }
  }, [tabDataTemp]);

  return (
    <div className="admin-menu-mobile">
      <Tabs
        swipeable
        activeTab={activeIndex}
        page={activeIndex}
        animated={false}
        tabs={tabDataTemp}
        onChange={(tab, x) => {
          setActiveIndex(x);
          handleMenuItemClick(tab.tab);
        }}
        renderTabBar={(props) => (
          <DefaultTabBar
            page={2}
            onTabClick={(tabProps) => handleMenuItemClick(tabProps.tab.tab)}
            activeTab={props.activeTab === activeItem}
            {...props}
          />
        )}
        tabBarActiveTextColor="#0078a6 "
        tabBarUnderlineStyle={{
          borderBottom: '3px solid #0078a6 ',
        }}
      >
      </Tabs>
    </div>
  );
};
