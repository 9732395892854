import { useState } from "react";
import "./customerenrolment.scss";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import "react-datepicker/dist/react-datepicker.css";
import { COUNTRY } from "./shared";

const CountryStateDropdownField = ({
  className = "",
  style = null,
  type = "region",
  value,
  label,
  important = false,
  onChange,
  countryForRegion = COUNTRY.US,
  whitelist = null,
}) => {
  const [dropdownArrow, setDropdownArrow] = useState("angle-down");
  return (
    <div className={className} style={style}>
      <div className="input-label-holder">
        <label className="input-label">
          {important && <span className="text-red">*</span>}
          {label}
        </label>
      </div>
      {type === "country" ? (
        whitelist ? (
          <CountryDropdown
            classes={`country-state-dropdown fort-book w-100 ${
              !value ? "text-gray-900" : ""
            } ${dropdownArrow}`}
            value={value}
            valueType="short"
            onChange={onChange}
            whitelist={whitelist}
          />
        ) : (
          <CountryDropdown
            classes={`country-state-dropdown fort-book w-100 ${
              !value ? "text-gray-900" : ""
            } ${dropdownArrow}`}
            value={value}
            valueType="short"
            onChange={onChange}
          />
        )
      ) : (
        <RegionDropdown
          classes={`country-state-dropdown fort-book w-100 ${
            !value ? "text-gray-900" : ""
          } ${dropdownArrow}`}
          country={countryForRegion}
          countryValueType="short"
          value={value}
          valueType="short"
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default CountryStateDropdownField;
