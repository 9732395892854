const InventoryMessage = (props) => {
  const message = props.msg;
  const classes = props.classes;
  const bgColor = props.bgColor;

  const greyArray = [
    'Low stock',
    'Low stock, moving quickly',
    'Moving quickly, act fast',
    'Managed stock: all items',
    'Managed stock: 2 items',
  ];
  const orangeArray = [
    'Temporarily out of stock',
    'Out of stock, back soon',
    'On sale soon',
  ];
  const redArray = [
    'Restricted Market',
    'Restricted Markets',
    'Out of Print',
    'Restricted Item',
  ];

  let back_color = '';
  if (greyArray.includes(message)) {
    back_color = 'grey-back';
  }
  if (orangeArray.includes(message)) {
    back_color = 'orange-back';
  }
  if (redArray.includes(message)) {
    back_color = 'red-back';
  }
  if (bgColor && bgColor != '') {
    back_color = 'grey-back';
  }
  return (
    <div className={`inventory-msg ${back_color} ${classes}`}>{message}</div>
  );
};

export default InventoryMessage;
