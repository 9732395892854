import { IDENTITY_CONFIG, COMPCOPY_IDENTITY_CONFIG } from "../utils/authConst";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { useLocation } from "react-router-dom";

export default class AuthService {
  UserManager;

  constructor(props) {
    // this.redirectUri = props.redirectUri;
    this.appID = "biz";
    this.authUrl = "";
    this.clientID = "";
    let umParams = {};
    const hostName = window.location.hostname;

    if (hostName.startsWith("biz") || hostName.startsWith("selfservice")) {
      window.document.title = "PRH Biz";
      this.appID = "biz";
      umParams = { ...IDENTITY_CONFIG };
      this.authUrl = process.env.REACT_APP_AUTH_URL;
      this.clientID = process.env.REACT_APP_IDENTITY_CLIENT_ID;
    }
    if (hostName.startsWith("compcopy")) {
      window.document.title = "PRH Compcopy";
      this.appID = "compcopy";
      umParams = { ...COMPCOPY_IDENTITY_CONFIG };
      this.authUrl = process.env.REACT_APP_COMPCOPY_ISSUER;
      this.clientID = process.env.REACT_APP_COMPCOPY_IDENTITY_CLIENT_ID;
      this.responseType = "token";
    }

    umParams.userStore = new WebStorageStateStore({
      store: window.sessionStorage,
    });
    umParams.stateStore = umParams.userStore;
    // console.log("AuthService", umParams);
    this.UserManager = new UserManager({
      ...umParams,
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
    // this.UserManager.events.addUserLoaded((user) => {
    //   // console.log('addUserLoaded executed', user);
    //   // if ( props.loadUser && this.appID === 'comopcopy') {
    //   // console.log('loading the user from authService addUserLoaded event');
    //   props.loadUser(user);
    //   // }

    //   // if (window.location.href.indexOf('signin-oidc') !== -1) {
    //   //   this.navigateToScreen();
    //   // } else {
    //   //   if (props.loadUser) {
    //   //     console.log('running loadUser');
    //   //     this.UserManager.getUser().then((user) => {
    //   //       console.log('running loadUser with ', user);
    //   //       props.loadUser(user);
    //   //     });
    //   //   }
    //   // }
    //   // if (props.loadUser) {
    //   //   console.log('running loadUser');
    //   //   this.UserManager.getUser().then((user) => {
    //   // console.log('running loadUser with ', user);
    //   // props.loadUser(user);
    //   // this.redirectUri(user.state);
    //   // if (window.location.href.indexOf('signin-oidc') !== -1) {
    //   //   this.navigateToScreen();
    //   // }
    //   // });
    //   // } else {
    //   //   if (window.location.href.indexOf('signin-oidc') !== -1) {
    //   //     this.navigateToScreen();
    //   //   }
    //   // }
    // });

    // this.UserManager.events.addSilentRenewError((e) => {
    //   console.log("silent renew error", e.message);
    // });

    // this.UserManager.events.addAccessTokenExpired(() => {
    //   console.log("token expired");
    //   this.logout();
    // });
  }

  signinRedirectCallback = () => {
    // console.log("signinRedirectCallback event");
    return this.UserManager.signinRedirectCallback();
    // this.UserManager.signinRedirectCallback().then((user) => {
    //   console.log('signinRedirectCallback', user);
    // });
  };

  getUser = async () => {
    // console.log("getUser");
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = (state) => {
    console.log("signinRedirect called", state);
    const location = window.location;
    if (location.pathname !== "/" && location.search !== "auth=true") {
      console.log(
        "signinRedirect location",
        `${location.pathname}${location.search}`
      );
      localStorage.setItem(
        "redirectUri",
        //location.href + "/" + location.search
        `${location.pathname}${location.search}`
      );
    }
    this.UserManager.signinRedirect(state);
  };

  navigateToScreen = () => {
    // window.location.replace('/');
    // console.log('nav to screen');
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(`oidc.user:${this.authUrl}:${this.clientID}`)
    );

    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = () => {
    return this.UserManager.signinSilent();
    // .then((user) => {
    //   console.log('signed in', user);
    // })
    // .catch((err) => {
    //   console.log('signed', err);
    // });
  };
  signinSilentCallback = async () => {
    // console.log("signinSilentCallback");
    // this.UserManager.signinSilentCallback().then((e) => {
    //   console.log('UserManager.signinSilentCallback', e);
    // });
    const result = await this.UserManager.signinSilentCallback();
    console.log("signinSilentCallback result", result);
    return result;
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    console.log("authService logout");
    this.UserManager.removeUser();
    // this.UserManager.signoutRedirect({
    //   id_token_hint: localStorage.getItem('id_token'),
    // });
    this.UserManager.clearStaleState();
    localStorage.clear();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL);
    });
    this.UserManager.clearStaleState();
  };
}
