import { useState } from "react";
import { Icon, Popup, Grid, Checkbox, Radio } from "semantic-ui-react";
import { PRHLabel } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import TextValidator from "../../shared-react-components/elements/input";
import "./customerenrolment.scss";
import "react-datepicker/dist/react-datepicker.css";
import CountryStateDropdownField from "./CountryStateDropdownField";
import DialogModal from "../modals/DialogModal";
import { validateEmail, validateLetters } from "./helpers";
import { COUNTRY } from "./shared";

const BillingAddressForm = ({
  data,
  legalBusinessName,
  onChangeBusinessName,
  selectedCountry,
  onChange,
  onNext,
  onBack,
  documents,
  disableNextStep,
}) => {
  const [showShippingTermsModal, setShowShippingTermsModal] = useState(false);

  const {
    dba,
    contactPersonName,
    street,
    city,
    zip,
    state,
    phone,
    email,
    apContactName,
    apContactPhone,
    apContactEmail,
    principalOfficer1,
    principalOfficer2,
    shippingAddressIsBillingAddress,
    invoiceViaEmail,
    orderConfirmationViaEmail,
    shippingNotificationsViaEmail,
    billingAddressIsResidence,
    termsOfShipping,
  } = data;

  const validateFields = validateLetters(state) && validateEmail(email);

  const validateToS =
    billingAddressIsResidence === false ? true : termsOfShipping;

  const disableNext =
    !contactPersonName ||
    !street ||
    !city ||
    !zip ||
    !state ||
    !phone ||
    !email ||
    !apContactName ||
    !apContactPhone ||
    !apContactEmail ||
    !principalOfficer1 ||
    !validateToS ||
    !validateFields ||
    disableNextStep;

  return (
    <>
      <TextValidator
        id="legalBusinessName"
        name="legalBusinessName"
        className="form-field"
        label={
          <>
            <PRHLabel className="input-label">
              <span className="text-red">*</span>Legal Business Name
            </PRHLabel>
            <Popup
              on="click"
              content="Based on how it appears on your taxes. (include LLC, INC, ..., if applicable)"
              trigger={
                <Icon
                  name="question circle outline"
                  className="input-tool-tip-icon"
                />
              }
            />
          </>
        }
        type="text"
        onChange={(e) => onChangeBusinessName(e.target.value)}
        value={legalBusinessName}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />
      <TextValidator
        id="dba"
        name="dba"
        className="form-field"
        label={
          <>
            <PRHLabel className="input-label">D.B.A.</PRHLabel>
            <Popup
              on="click"
              content={`Doing Business As, if applicable.  You might be "Doing business as" a different name than your legal business name.`}
              trigger={
                <Icon
                  name="question circle outline"
                  className="input-tool-tip-icon"
                />
              }
            />
          </>
        }
        type="text"
        onChange={(e) => onChange("dba", e.target.value)}
        value={dba}
        width={16}
      />
      <TextValidator
        id="contactPersonName"
        name="contactPersonName"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Contact Name
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("contactPersonName", e.target.value)}
        value={contactPersonName}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="street"
        name="street"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Street Address
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("street", e.target.value)}
        value={street}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <p className="mb-2 info-message">Note: We do not ship to PO boxes</p>

      <TextValidator
        id="city"
        name="city"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>City
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("city", e.target.value)}
        value={city}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <Grid className="state-grid" columns={2} padded>
        <Grid.Row>
          <Grid.Column width={11}>
            <CountryStateDropdownField
              className="mb-4"
              style={{ marginTop: 2 }}
              type="region"
              value={state}
              label={
                selectedCountry === COUNTRY.US ? "State" : "Province/Territory"
              }
              important={true}
              countryForRegion={selectedCountry}
              onChange={(val) => onChange("state", val)}
            />
          </Grid.Column>
          <Grid.Column width={4} floated="right">
            <TextValidator
              id="zip"
              name="zip"
              className="form-field"
              label={
                <PRHLabel className="input-label">
                  <span className="text-red">*</span>
                  {selectedCountry === COUNTRY.US ? "Zip Code" : "Postal Code"}
                </PRHLabel>
              }
              type="text"
              onChange={(e) => onChange("zip", e.target.value)}
              value={zip}
              validators={["required"]}
              errorMessages={["This field is required"]}
              width={16}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <TextValidator
        id="phone"
        name="phone"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Phone Number
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("phone", e.target.value)}
        value={phone}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="email"
        name="email"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Email Address
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("email", e.target.value)}
        value={email}
        validators={["required", "isEmail"]}
        errorMessages={["This field is required", "This field is email"]}
        width={16}
      />

      <div className="bold-label mt-5 mb-2">ACCOUNTS PAYABLE CONTACT</div>
      <TextValidator
        id="apContactName"
        name="apContactName"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>AP Contact Name
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("apContactName", e.target.value)}
        value={apContactName}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />
      <TextValidator
        id="apContactPhone"
        name="apContactPhone"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>AP Contact Phone Number
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("apContactPhone", e.target.value)}
        value={apContactPhone}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="apContactEmail"
        name="apContactEmail"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>AP Contact Email Address
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("apContactEmail", e.target.value)}
        value={apContactEmail}
        validators={["required", "isEmail"]}
        errorMessages={["This field is required", "This field is email"]}
        width={16}
      />

      <div className="bold-label mt-5 mb-2">PRINCIPALS / OFFICERS</div>
      <TextValidator
        id="principalOfficer1"
        name="principalOfficer1"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Principal / Officer #1, Name
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("principalOfficer1", e.target.value)}
        value={principalOfficer1}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />
      <TextValidator
        id="principalOfficer2"
        name="principalOfficer2"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            Principal / Officer #2, Name
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("principalOfficer2", e.target.value)}
        value={principalOfficer2}
        width={16}
      />

      <div className="bold-label mt-5 mb-2">SHIPPING AND INVOICE DELIVERY</div>
      <Checkbox
        className="my-1"
        label="Shipping address is same as billing address"
        checked={shippingAddressIsBillingAddress}
        onChange={() =>
          onChange(
            "shippingAddressIsBillingAddress",
            !shippingAddressIsBillingAddress
          )
        }
      />
      <Checkbox
        className="my-1"
        label="Send invoices via email"
        checked={invoiceViaEmail}
        onChange={() => onChange("invoiceViaEmail", !invoiceViaEmail)}
      />
      <Checkbox
        className="my-1"
        label="Send order confirmations via email"
        checked={orderConfirmationViaEmail}
        onChange={() =>
          onChange("orderConfirmationViaEmail", !orderConfirmationViaEmail)
        }
      />
      <Checkbox
        className="my-1"
        label="Send advance shipping notifications via email"
        checked={shippingNotificationsViaEmail}
        onChange={() =>
          onChange(
            "shippingNotificationsViaEmail",
            !shippingNotificationsViaEmail
          )
        }
      />

      <div className="bold-label mt-5 mb-2">
        IS THE ABOVE ADDRESS A RESIDENCE?
      </div>
      <Radio
        label="Yes"
        name="residenceAddress"
        value={true}
        checked={billingAddressIsResidence === true}
        onChange={() => onChange("billingAddressIsResidence", true)}
        className="mr-2"
      />
      <Radio
        label="No"
        name="residenceAddress"
        value={false}
        checked={billingAddressIsResidence === false}
        onChange={() => onChange("billingAddressIsResidence", false)}
      />
      <br />
      <div className="terms-of-shipping-container">
        <Checkbox
          className="my-3"
          label="I agree to"
          checked={!billingAddressIsResidence ? false : termsOfShipping}
          onChange={() => onChange("termsOfShipping", !termsOfShipping)}
          disabled={!billingAddressIsResidence}
        />
        <span
          className="terms-of-shipping"
          onClick={() => setShowShippingTermsModal(true)}
        >
          Terms Of Shipping
        </span>
      </div>

      <div className="d-flex flex-row Xbutton-holder my-5">
        <PRHButton
          size="large"
          iconleft
          icon="arrow left"
          className="mr-auto"
          onClick={onBack}
        >
          Back
        </PRHButton>
        <PRHButton
          disabled={disableNext}
          iconright
          icon="arrow right"
          size="large"
          className="ml-auto"
          onClick={() => onNext(data)}
        >
          {shippingAddressIsBillingAddress === false
            ? "Shipping address"
            : documents.fetched && documents.list
            ? "Download Documents"
            : "Payment Method"}
        </PRHButton>
      </div>
      <div className="d-flex flex-row my-5 fs-12px">
        <span className="text-red mr-1">*</span> denotes that this field is
        required to proceed
      </div>
      {showShippingTermsModal && (
        <DialogModal
          open={true}
          closeOnEscape={true}
          closeOnDimmerClick={true}
          onClose={() => setShowShippingTermsModal(false)}
          options={{
            className: "terms-of-sale-modal",
          }}
          dialogOptions={{
            header: "TERMS OF SHIPPING",
          }}
        >
          <div className="p-4">
            <span className="d-block text-uppercase fort-bold mb-1">
              {legalBusinessName}
            </span>
            <span className="d-block text-uppercase fort-bold mb-1">
              {street}, {city}
            </span>
            <span className="d-block text-uppercase fort-bold mb-2">{zip}</span>
            <p>
              In an effort to avoid any future misunderstandings I want to
              clarify our position regarding <b>{legalBusinessName}</b> purchase
              orders that involve shipping product to{" "}
              <b>
                {street}, {zip}, {state}
              </b>
              , a residential ship to location, which will be billed directly to{" "}
              <b>{legalBusinessName}</b>.<br />
              <br />
              Please confirm in writing (email is acceptable) that{" "}
              <b>{legalBusinessName}</b> agrees to pay the invoice(s) in full
              according to our terms of sale which are based on invoice date.
              Additionally, <b>{legalBusinessName}</b> will pay all taxes
              charged unless a proper proof of exemption for the state of
              <b>{legalBusinessName}</b> is supplied.
              <br />
              <br />
              <b>{legalBusinessName}</b> understands that Penguin Random House
              LLC will accept responsibility for the condition of only the
              initial shipment to{" "}
              <b>
                {street}, {zip}, {state}
              </b>
              .<br />
              <br />
              Any claims for damages and or shortages must be reported to our
              Customer Service department within 72 hours of delivery to be
              considered valid. A UPS delivery record will be conclusive proof
              of delivery. UPS may not obtain a signature upon delivery.
              <br />
              <b>
                {street}, {zip}, {state}
              </b>{" "}
              agrees that Penguin Random House LLC will not be held responsible
              for any loss or damage after delivery to
              <b>{legalBusinessName}</b> if not reported within 72 hours of
              delivery.
              <br />
              <br />
              <b>{legalBusinessName}</b> acknowledges that Penguin Random House
              LLC guidelines specifically state that shipping addresses must be
              to warehouses solely owned and operated by the primary account
              holder (the bill to).
              <br />
              <br />
              Shipping to a residential address is outside of this parameter.
              <br />I have been advised this is a temporary agreement and will
              be reviewed at a later date.
            </p>
            <br />
            <br />
            <PRHButton
              iconright
              icon="checkmark"
              size="large"
              className="m-auto"
              onClick={() => {
                onChange("termsOfShipping", true);
                setShowShippingTermsModal(false);
              }}
            >
              I agree to Terms Of Shipping
            </PRHButton>
            <br />
          </div>
        </DialogModal>
      )}
    </>
  );
};

export default BillingAddressForm;
