import { errors } from './errors';

class ErrorCodes {
  errorTable = errors;
  constructor() {}

  getError = (errorCode) => {
    if (errorCode in this.errorTable) {
      return this.errorTable[errorCode];
    } else {
      return this.errorTable[5000];
    }
  };
}

export default ErrorCodes;
