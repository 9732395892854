import { Component } from "react";
import withSecurity from "../../../components/hc/withSecurity";
import { withRouter } from "react-router";
import { NumericFormat } from "react-number-format";
import PRHButton from "../../../shared-react-components/elements/prhbutton";
import { Divider } from "../../../components/elements/elements";
import { PRHContainer } from "../../../shared-react-components/elements/elements";
import LazyImage from "../../../utils/LazyImage";
import {
  numberAsCurrency,
  formatDate,
  formatISBN,
} from "../../../utils/utilities";
import { Icon } from "semantic-ui-react";
import InventoryMessage from "../../../components/common/InventoryMessage";
class CartPreview extends Component {
  render() {
    // console.log('CartPreview', this.props);
    return (
      <PRHContainer className='cp-left content-block-border w-100 m-0 pl-0 pr-0 mr-6 pb-0 pt-3'>
        {this.props.isCart ? (
          <>
            <div className='d-flex ml-3 mr-3 align-items-center mb-1 co-step-header'>
              <div className='d-flex fort-bold mb-2 fs-2 align-items-center'>
                <span className='cp-section-number fs-3'>4</span>REVIEW YOUR
                CART ({this.props.items.length} TITLES){" "}
              </div>
              <div className='ml-auto mb-1'>
                <PRHButton
                  icon='arrow left'
                  // size="large"
                  onClick={(e) => {
                    let parm = { pathname: "cart", state: {} };
                    if (this.props.shipTo && this.props.focDate) {
                      parm.state.shipTo = this.props.shipTo;
                      parm.state.focDate = this.props.focDate;
                    }
                    this.props.history.push(parm);
                  }}
                >
                  Edit Cart
                </PRHButton>
              </div>
            </div>
            <Divider className='mb-0' tight />
          </>
        ) : null}

        <div className='cp-items'>
          {this.props.items.reverse().map((item, idx) => {
            return (
              <div
                key={idx}
                className={`${
                  this.props.isCart || this.props.isOrderSummary ? "mr-2" : ""
                }`}
              >
                <div className='d-flex flex-row mt-2 mb-2'>
                  <div className='d-flex align-items-center mr-1 ml-3'></div>
                  <div>
                    <div>
                      <LazyImage
                        className='cp-cover'
                        width={21}
                        boxShadow={true}
                        src={`https://images.penguinrandomhouse.com/cover/${item.isbn}`}
                      ></LazyImage>
                    </div>
                  </div>
                  <div
                    className='ml-2 lh-3'
                    style={{
                      width: `${this.props.user.isAppBiz() ? "39%" : "50%"}`,
                      marginRight: "2%",
                    }}
                  >
                    <div className='fort-bold mr-1'>{item.title}</div>
                    <div> By {item.author}</div>
                    <div>
                      {item.isbn}
                      <span className='ml-1 mr-1 text-gray-500'>|</span>
                      {item.formatName}
                    </div>
                  </div>
                  <div className='lh-3 d-flex flex-column fort-bold'>
                    {item.promoDiscount ? (
                      <div className='text-blue'>
                        <NumericFormat
                          className=''
                          value={item.promoPctDiscount}
                          thousandSeparator={true}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          suffix={"%"}
                        />{" "}
                        off with Promo {this.props.promoCode}
                      </div>
                    ) : null}
                    {this.props.user.isAppCompCopy() ? (
                      <div className=''>Qty: {item.quantity}</div>
                    ) : null}
                    {item.inventoryMsg != null ? (
                      <InventoryMessage
                        msg={item.inventoryMsg}
                        classes='second-col-items'
                      />
                    ) : null}
                    {item.deliveryDate && this.props.user.isAppBiz() ? (
                      <div
                        className='fort-light-italic font-weight-normal'
                        style={{ fontWeight: 600 }}
                      >
                        Est. Delivery:{" "}
                        {item.deliveryDate !== "0000-00-00"
                          ? formatDate(item.deliveryDate, false)
                          : "Not Available"}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  {this.props.user.isAppBiz() ? (
                    <div className='d-flex flex-column ml-auto lh-3'>
                      <div
                        className={`ml-auto fort-bold text-align-end ${
                          item.promoDiscount ? "text-blue" : ""
                        }`}
                      >
                        <NumericFormat
                          value={item.discountedPriceTotal}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          prefix={"$"}
                        />
                      </div>
                      <div className='ml-auto'>
                        <div className=''>Qty: {item.quantity}</div>
                      </div>
                      {/* <div className="ml-auto">
                        {' '}
                        <NumericFormat
                          value={item.discountedPriceSingle}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={'text'}
                          prefix={'$'}
                        />{' '}
                        (each)
                      </div>
                      <div className="ml-auto">
                        {' '}
                        <NumericFormat
                          className="text-line-through"
                          value={item.priceSingle}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={'text'}
                          prefix={'$'}
                        />
                      </div> */}
                    </div>
                  ) : null}
                </div>
                {idx < this.props.items.length - 1 ? <Divider tight /> : null}
              </div>
            );
          })}
        </div>
      </PRHContainer>
    );
  }
}

export default withRouter(withSecurity(CartPreview));
