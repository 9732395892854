import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  deliveryHomePageWidget: true,
  debug: false, //false is default
};

// export const signUserOut = createAsyncThunk(
//   'user/signUserOut',
//   // Declare the type your function argument here:
//   async (params, {dispatch, getState, rejectWithValue}) => {
//     console.log('Starting signUserOut');
//     await createConfig({
//       clientId: configFile.clientId,
//       redirectUri: configFile.redirectUri,
//       endSessionRedirectUri: configFile.endSessionRedirectUri,
//       discoveryUri: configFile.discoveryUri,
//       scopes: configFile.scopes,
//       requireHardwareBackedKeyStore: configFile.requireHardwareBackedKeyStore,
//     });

//     console.log('Checking for download Tasks');
//     let lostTasks = await RNBackgroundDownloader.checkForExistingDownloads();
//     for (let task of lostTasks) {
//       console.log('Running download task', task);
//       task.stop();
//     }
//     dispatch(removeAllDownloads());

//     await AsyncStorage.removeItem('userSession');

//     try {
//       await clearTokens();
//     } catch (e) {
//       console.log('clearToken', e);
//     }

//     dispatch(resetLibraryAll());
//   },
// );

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      // console.log("setUserInfo", action);
      const { user } = action.payload;
      if (user) {
        Object.keys(user).map((k) => {
          state[k] = user[k];
        });
      } else {
        console.log("reseting user", action);
        return { ...initialState };
      }
      // state.id_token = user.id_token;
      // state.accessToken = user.accessToken;
      // state.expires_at = user.expires_at;
      // state.profile = user.profile;
      // state.isAuthenticated = true;
    },
    logoffUser: (state, action) => {
      console.log("logoffUser", action);
      return { ...initialState };
    },
    setDeliveryHomePageWidget: (state, action) => {
      state.deliveryHomePageWidget = action.payload;
    },
  },
});

export const { setUserInfo, logoffUser, setDeliveryHomePageWidget } =
  userSlice.actions;

export default userSlice.reducer;
