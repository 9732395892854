import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

export default function SSO() {
  const history = useHistory();
  useEffect(() => {
    history.push({ pathname: '/' });
  }, []);
  return <div>SSO Login: Redirecting . . .</div>;
}
