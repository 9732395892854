import { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class invoiceNumberRenderer extends Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler(billingDocNumber) {
    this.props.clicked(billingDocNumber);
  }
  render() {
    const { billingDocNumber } = this.props.data;
    /*
    const {
      callback=false,
      clicked=false,
    } = this.props;*/
    return (
      <Link className='no-border' to={`/invoices/${billingDocNumber}`}>
        {billingDocNumber}
      </Link>
    );
  }
}

export default withRouter(invoiceNumberRenderer);
