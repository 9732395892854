import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState, useCallback } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon, Placeholder } from "semantic-ui-react";
import withApp from "../../components/hc/withApp";
import LoadingView from "../loading/LoadingView";
import NoRowsOrders from "./frameworkComponents/NoRowsOrders";
import preDeliveryDownload from "./frameworkComponents/preDeliveryDownload";
import preDeliveryShipTos from "./frameworkComponents/preDeliveryShipTos";
import onSaleDate from "./frameworkComponents/onSaleDate";
import deliveries from "./frameworkComponents/deliveries";
import NoRowsNewPredeliveryReports from "./frameworkComponents/NoRowsNewPredeliveryReports";
import PreDeliveryReportsModal from "../../components/modals/PreDeliveryReportsModal";
import { useMediaQuery } from "react-responsive";
import { Divider } from "../../components/elements/elements";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import { Export, ExportCSV } from "../../utils/Export";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import MobileTable, {
  mobileTableColumnsOrders as mobileTableColumns,
} from "../../shared-react-components/elements/PreDeliveryReportsMobileTable";
import "./NewPreDeliveryReport.scss";
import {
  useDownloadPreDeliveryReportMutation,
  useLazyGetListOfReportsQuery,
} from "../../api/ecomApi";
import {cloneDeep} from "lodash";

const NewPreDeliveryReports = ({ app, ccSapAccountNo, shipTo=null }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  window.addEventListener("resize", () => {
    if (gridApi) gridApi.sizeColumnsToFit();
  });

  const [performGetListOfReports, getListOfReportsResult] =
    useLazyGetListOfReportsQuery();
  const [performDownloadPreDeliveryReport, downloadPreDeliveryReportResult] =
    useDownloadPreDeliveryReportMutation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [mobileData, setMobileData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [rowHeight, setRowHeight] = useState("70");
  const [numberOfReports, setNumberOfReports] = useState(0);
  const [isPreDeliveryModalOpen, setIsPreDeliveryModalOpen] = useState(false);
  const [state, setState] = useState({
    pageSize: 50,
    //rowHeight: 70,
    headerHeight: 200,
    currentSort: "dateCreated_desc",
    columnDefs: [
      {
        headerName: "ON-SALE DATE",
        field: "onSaleDate",
        cellRenderer: "onSaleDateRenderer",
        cellClass: "cell-pdr-mod",

        resizable: true,
        // onCellClicked: ({ value }) => {
        //   history.push(`/orders/${value}`);
        // },
        //cellRenderer: "salesOrderRenderer",
        //cellClass: ["has-link"],
      },

      {
        headerName: "LOCATION",
        field: "shipTos",
        checkboxSelection: false,
        headerCheckboxSelection: false,
        cellRenderer: "preDeliveryShipTosRenderer",
        cellClass: "cell-pdr-mod",
        resizable: true,
        //autoHeight: true,
        //cellStyle: {"white-space": "normal"},
        //autoHeight: true,
      },
      {
        headerName: "DELIVERY INFORMATION",
        field: "deliveries",
        cellRenderer: "deliveriesRenderer",
        cellClass: "cell-pdr-mod",
        sortable: true,
        width: 200,
        minWidth: 200,
        //maxWidth: 200,
      },
      {
        headerName: "",
        field: "downloads",
        cellRenderer: "preDeliveryDownloadRenderer",
        cellClass: "cell-pdr-mod",
        cellRendererParams: {
          downloadPreDeliveryReport: (onSaleDate, shipTo) =>
            downloadPreDeliveryReport(onSaleDate, shipTo),
        },
        sortable: true,
        width: 200,
      },
      /*
      {
        headerName: "SHIP-TO ACCOUNT",
        field: "account",
      },*/
    ],
    headerOnly: true,
  });

  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const getRowHeight = useCallback((params) => {
    let tempHeight;
    const differentHeights = [50, 70, 90, 200];
    if (params.data.shipTos.length < 4) {
      tempHeight = differentHeights[params.data.shipTos.length - 1];
    } else {
      tempHeight = params.data.shipTos.length * 28;
    }
    return tempHeight;
  }, []);

  const getData = async () => {
    let args = {};
    if (!!ccSapAccountNo) {
      args = {
        sapAccountNo: ccSapAccountNo,
      };
    }

    const result = await performGetListOfReports({...args, shipTo}, true);
    return result;
  };

  const downloadPreDeliveryReport = async (onSaleDate, shipTo) => {
    const result = await performDownloadPreDeliveryReport(
      {
        onSaleDate,
        shipTo,
      },
      true
    );
    if (result.data.data) {
      const rows = [...result.data.data.deliveryItems];
      const details = {
        //fileName: `predelivery_${shipTo}_${deliveryDate}`,
        fileName: "Pre-Delivery Report_" + formatDateMMDDYYYY(onSaleDate, "_"),
        metadata: [],
        list: rows
          .map((row) => {
            return {
              "Delivery Date": formatDateMMDDYYYY(row.deliveryDate, "/"),
              "Ship-to Account": row.shipTo,
              "UPC/ISBN ": row.isbn,
              Title: row.title.replace(",", " "),
              Publisher: row.publisher,
              "USD List Price": row.usPrice,
              "CAD List Price ": row.canPrice,
              "On Sale Date": formatDateMMDDYYYY(row.onSaleDate, "/"),
              "Carton Quantity": row.cartonQuantity,
              "Total Receiving Quantity": row.totalQuantity,
              "Purchase Order Number": row.poNumber,
              "Catalog Date": formatDateMMDDYYYY(row.catalogDate, "/"),
            };
          })
          .flat(),
      };
      let list = [];
      list = rows.map((row) => {
        return {
          "Delivery Date": formatDateMMDDYYYY(row.deliveryDate, "/"),
          "Ship-to Account": row.shipTo,
          "UPC/ISBN": row.isbn,
          Title: row.title.replace(",", " "),
          Publisher: row.publisher,
          "USD List Price": row.usPrice,
          "CAD List Price": row.canPrice,
          "On Sale Date": formatDateMMDDYYYY(row.onSaleDate, "/"),
          "Carton Quantity": row.cartonQuantity,
          "Total Receiving Quantity": row.totalQuantity,
          "Purchase Order Number": row.poNumber,
          "Catalog Date": formatDateMMDDYYYY(row.catalogDate, "/"),
          Format: row.format,
        };
      });
      ExportCSV.CSV({
        fileName:
          "Pre-Delivery Report_" +
          formatDateMMDDYYYY(onSaleDate, "_") +
          "_" +
          shipTo,
        csvData: list,
        sheetName: "data",
      });
    }

    return result;
  };

  useEffect(() => {
    getData().then(({ data }) => {
      if (data.data?.status === "OK") {
        setMobileData(cloneDeep(data.data.onSaleDates));
      }

      const onSalesDates = data.data.onSaleDates.map((order) => {
        let temp = [];
        temp += order.onSaleDate;
        return temp;
      });
      let uniqueDates = [...new Set(onSalesDates)];
      let cleanedOrders = [];
      let cleanedAllOrders = [];
      let downloadsLength = 0;
      for (const date of uniqueDates) {
        let cleanedOrder = [];
        let shipToInfo = [];
        let deliveries = [];
        let downloads = [];
        let matchedOrder = [];
        matchedOrder = data.data.onSaleDates.filter(
          (order) => order.onSaleDate.indexOf(date) !== -1
        );
        for (const order of matchedOrder) {
          shipToInfo.push(order.shipTo);
          deliveries.push({
            deliveryCount: order.deliveryCount,
            deliveryItemCount: order.deliveryItemCount,
          });
          downloads.push({
            onSaleDate: order.onSaleDate,
            shipTo: order.shipTo,
          });
        }
        downloadsLength = downloadsLength + downloads.length;
        cleanedOrder["onSaleDate"] = date;
        cleanedOrder["shipTos"] = shipToInfo;
        cleanedOrder["deliveries"] = deliveries;
        cleanedOrder["downloads"] = downloads;
        cleanedOrders.push(cleanedOrder);
      }
      let cleanedOrders2 = [];
      for (const date of uniqueDates) {
        let cleanedOrder2 = [];
        let shipToInfo2 = [];
        let deliveries2 = [];
        let downloads2 = [];
        let matchedOrder = [];
        matchedOrder = data.data.onSaleDates.filter(
          (order) => order.onSaleDate.indexOf(date) !== -1
        );
        for (const order of matchedOrder) {
          shipToInfo2.push(order.shipTo);
          deliveries2.push({
            deliveryCount: order.deliveryCount,
            deliveryItemCount: order.deliveryItemCount,
          });
          downloads2.push({
            onSaleDate: order.onSaleDate,
            shipTo: order.shipTo,
          });
        }
        cleanedOrder2["onSaleDate"] = date;
        cleanedOrder2["shipTos"] = shipToInfo2;
        cleanedOrder2["deliveries"] = deliveries2;
        cleanedOrder2["downloads"] = downloads2;
        cleanedOrders2.push(cleanedOrder2);
        setAllData(cleanedOrders2);
      }

      const completeOrders = [...cleanedOrders];
      setDataLoaded(true);
      setNumberOfReports(downloadsLength);

      let forHomecleanedOrders = [];
      if (downloadsLength > 10) {
        let downloadsLengthForHome = 0;
        let newCompleteOrders = [];
        for (const orderToFix of completeOrders) {
          let forHomecleanedOrder = [];
          downloadsLengthForHome =
            downloadsLengthForHome + orderToFix["downloads"].length;
          if (downloadsLengthForHome + orderToFix["downloads"].length > 10) {
            newCompleteOrders.push(orderToFix);
            forHomecleanedOrder["onSaleDate"] = orderToFix["onSaleDate"];
            forHomecleanedOrder["shipTos"] = orderToFix["shipTos"].splice(
              0,
              10 - downloadsLengthForHome + orderToFix["downloads"].length
            );
            forHomecleanedOrder["deliveries"] = orderToFix["deliveries"].splice(
              0,
              10 - downloadsLengthForHome + orderToFix["downloads"].length
            );
            forHomecleanedOrder["downloads"] = orderToFix["downloads"].splice(
              0,
              10 - downloadsLengthForHome + orderToFix["downloads"].length
            );
            if (forHomecleanedOrder["downloads"].length > 0) {
              forHomecleanedOrders.push(forHomecleanedOrder);
            }
          } else {
            newCompleteOrders.push(orderToFix);
            forHomecleanedOrder["onSaleDate"] = orderToFix["onSaleDate"];
            forHomecleanedOrder["shipTos"] = orderToFix["shipTos"];
            forHomecleanedOrder["deliveries"] = orderToFix["deliveries"];
            forHomecleanedOrder["downloads"] = orderToFix["downloads"];
            forHomecleanedOrders.push(forHomecleanedOrder);
          }
        }
        setData(forHomecleanedOrders);
      } else {
        setData(completeOrders);
      }
    });
  }, []);

  return (
    <>
      <div
        className={`${
          isMobile
            ? "border-gray-300 border-radius-3px p-3 mb-3 d-flex flex-column mt-3 white-background mobile-catalogs-container"
            : "mb-3 d-flex align-items-center"
        }`}
      >
        <div
          className={`d-flex flex-row ${
            isMobile
              ? "justify-content-space-between"
              : "d-flex justify-content-space-between w-100 align-items-center"
          }`}
        >
          <p
            className={`mb-0 fort-light text-gray-900 ${
              isMobile ? "fs-20px" : "fs-24px"
            }`}
          >
            <Icon
              name='star outline'
              //className="view-all-icon"
              color='black'
            />
            Pre-Delivery Reports
          </p>
          <p
            className={`lsp--02 fs-13px lh-18 fort-book ${
              isMobile ? "d-flex align-items-center" : ""
            }`}
          ></p>
        </div>

        {isMobile && (
          <>
            <Divider tight className='mt-2 mb-2' />
            <div className={"flex"}>
              <div>
                <Icon name='info circle'></Icon>
              </div>
              <div>
                Pre-Delivery reports download is accessible through desktop
                only.
              </div>
            </div>
          </>
        )}
        {isMobile && <Divider tight className='mt-2 mb-2' />}
        {isMobile && (
          <MobileTable
            columns={mobileTableColumns}
            data={mobileData}
            cleanedData={data}
            noDataMessage={<NoRowsNewPredeliveryReports />}
          />
        )}
      </div>
      {!dataLoaded && (
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      )}
      {!isMobile && dataLoaded && (
        <div
          className={`mb-5 h-100 pdrAdjustGrid ${
            !Array.isArray(data) || data.length === 0
              ? "ag-grid-root-child-no-items"
              : ""
          }`}
        >
          <AgGridReact
            onGridReady={onGridReady}
            columnDefs={state.columnDefs}
            rowData={data}
            //rowHeight={state.rowHeight}
            rowSelection={"multiple"}
            suppressRowClickSelection={true}
            getRowHeight={getRowHeight}
            //getRowHeight={getRowHeight}
            pagination={true}
            headerHeight={70}
            defaultColDef={{
              sortable: true,
              minWidth: 150,
              flex: 1,
              resizable: true,
              //cellClass: "stringRow",
              cellClass: "cell-wrap-text",
              suppressMovable: true,
              suppressMenu: true,
              resizable: true,
            }}
            excelStyles={[
              {
                id: "stringRow",
                dataType: "string",
              },
            ]}
            suppressPaginationPanel={true}
            paginationPageSize={state.pageSize}
            domLayout='autoHeight'
            rowClass='grid-row-style'
            //headerHeight={state.rowHeight}
            frameworkComponents={{
              preDeliveryDownloadRenderer: preDeliveryDownload,
              preDeliveryShipTosRenderer: preDeliveryShipTos,
              onSaleDateRenderer: onSaleDate,
              deliveriesRenderer: deliveries,
              customLoadingOverlay: LoadingView,
              noRowsOverlayComponent: NoRowsNewPredeliveryReports,
            }}
            noRowsOverlayComponent={"noRowsOverlayComponent"}
            loadingOverlayComponent={"customLoadingOverlay"}
            suppressContextMenu={true}
          />
          {numberOfReports > 10 ? (
            <div className={"viewAllLinkBottom"}>
              <a onClick={() => setIsPreDeliveryModalOpen(true)}>
                {isMobile ? "" : <>View All Pre-Delivery Reports</>}
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <PreDeliveryReportsModal
        isOpen={isPreDeliveryModalOpen}
        onClose={() => setIsPreDeliveryModalOpen(false)}
        preDeliveryReports={allData}
        //resolveDateFormat={resolveDateFormat}
        onDownloadPreDeliveryReport={downloadPreDeliveryReport}
        //accountNumber={accountNo}
      />
    </>
  );
};

NewPreDeliveryReports.defaultProps = {
  ccSapAccountNo: false,
};

export default withApp(withRouter(NewPreDeliveryReports));
