import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Grid,
  Dropdown,
  Accordion,
  Icon,
  Loader,
} from "semantic-ui-react";
import PageStandard from "../../components/elements/PageStandard";
import { useHistory, useParams } from "react-router";
import useCart from "../../components/hc/useCart";
import CustomFilters from "../../components/common/Filters/customFilters";
import TitleRow from "../../components/common/TitleRow";
import _ from "underscore";
import "./Catalog.scss";
import FOCStatus from "../../components/common/FOCStatus";
import NotificationBanners from "../../components/common/NotificationBanners";
import NotificationBanner from "../../components/common/NotificationBanner";
import { BackToTopButton } from "../../components/common/BackToTopButton";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { useMediaQuery } from "react-responsive";
import TitleRowMobile from "../../components/common/TitleRowMobile";
import "rmc-tabs/assets/index.css";
import MobileCatalogItemsTabs from "../../components/common/MobileCatalogItemsTabs";
import { MobileOptionsMenu } from "../../components/header/components/MobileOptionsMenu";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import useDirtyHandler from "../../shared-react-components/components/hc/useDirtyHandler";
import CatalogFileModal from "../../components/modals/CatalogFileModal";
import ReportMulti from "../../assets/images/report-multi.svg";
import ReportSingle from "../../assets/images/report-single.svg";
import MultiAddISBNsWarning from "../../components/modals/MultiAddISBNsWarning";
import MultiAddISBNsSuccess from "../../components/modals/MultiAddISBNsSuccess";
import { Divider } from "../../shared-react-components/elements/elements";
import { isFocCartExpired, isGeneralCart } from "../../utils/utilities";
import DialogModal from "../../components/modals/DialogModal";
import MultiAddISBNsCancel from "../../components/modals/MultiAddISBNsCancel";
import ConfirmationStatus from "../../components/common/ConfirmationStatus";
import { DateTime } from "luxon";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";
import { cloneDeep } from "lodash-es";

const showOnMobile = false;

export const getAccordionTitleDate = (accDate) => {
  if (accDate.split("-").length < 3) {
    return "Unknown";
  } else {
    const tempDate = accDate.split("-");
    const newAccDate = tempDate[1] + "/" + tempDate[2] + "/" + tempDate[0];
    return newAccDate;
  }
};

export const sortObj = (obj) => {
  return Object.keys(obj)
    .sort()
    .reduce(function (result, key) {
      result[key] = obj[key];
      return result;
    }, {});
};

export const getTodaysDate = () => {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export default function Catalog(props) {
  // console.log('Catalog render', props);
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const match = useParams();
  const history = useHistory();
  const Cart = useCart();
  const dirtyHandler = useDirtyHandler();
  const [catalogId, setCatalogId] = useState("");
  const [filtersCount, setFiltersCount] = useState(0);
  const [filters, setFilters] = useState([]);
  const [selectValue, setSelectValue] = useState(null);
  // const [filterModel, setFilterModel] = useState({});
  const [numOfResults, setNumOfResults] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);
  const [originalResults, setOriginalResults] = useState([]);
  const [hasSeriesHistory, setHasSeriesHistory] = useState([]);
  const [currentSort, setCurrentSort] = useState("foc");
  const [customerLoaded, setCustomerLoaded] = useState(true);
  // const [numOfSortedGroups, setNumOfSortedGroups] = useState([]);
  const [focDatesHeaderString, setFocDatesHeaderString] = useState("");
  // const [accordionState, setaccordionState] = useState({});
  const [catalogDownloadData, setCatalogDownloadData] = useState([]);
  const [downloadDropdownOptions, setDownloadDropdownOptions] = useState([]);
  const [focs, setFocs] = useState([]);
  const [variantFilter, setVariantFilter] = useState(true);
  const [addMultipleTitles, setAddMultipleTitles] = useState(false);
  const [addMultipleTotalQty, setAddMultipleTotalQty] = useState(0);
  const [addMultipleISBNs, setAddMultipleISBNs] = useState(0);
  const [cartShipTo, setCartShipTo] = useState(false);
  // const [shipToUpdated, setShipToUpdated] = useState(false);
  // const prevCartShipTo = usePrevious(cartShipTo);
  const [addMultipleISBNQtyObject, setAddMultipleISBNQtyObject] = useState({});
  const [variantsNotMetTitles, setVariantsNotMetTitles] = useState([]);
  const [clearAddAllQty, setClearAddAllQty] = useState(false);
  const [resetAllQty, setResetAllQty] = useState(false);
  const [multiAddISBNsWarningOpen, setMultiAddISBNsWarningOpen] =
    useState(false);
  const [multiAddISBNsSuccessOpen, setMultiAddISBNsSuccessOpen] =
    useState(false);
  const [multiAddISBNsCancelOpen, setMultiAddISBNsCancelOpen] = useState(false);
  const [multiAddISBNsData, setMultiAddsData] = useState(false);
  const [isLocationWarningOpen, setisLocationWarningOpen] = useState(false);
  const [modifyConfirmedCartModalOpen, setModifyConfirmedCartModalOpen] =
    useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const [downloadMenuVisible, setDownloadMenuVisible] = useState(false);
  const [sortMenuVisible, setSortMenuVisible] = useState(false);
  const [downloadDiaglogVisible, setdownloadDiaglogVisible] = useState(false);
  const [inCartFlag, setInCartFlag] = useState(false);

  const [currentTabData, setCurrentTabData] = useState();
  //const [seriesHistoryRefresh, setSeriesHistoryRefresh] = useState(false);

  let resultsSortOPtions = [
    {
      key: "foc",
      text: "Sort by: Upcoming FOC",
      content: "Upcoming FOC",
      value: "foc",
      label: (
        <label htmlFor='foc' className='visually-hidden'>
          Sort by: Upcoming FOC aaa
        </label>
      ),
      name: "foc",
      id: "foc",
    },
    {
      key: "divisionName",
      text: "Sort by: Publisher - A to Z",
      content: "Publisher - A to Z",
      value: "divisionName",
      label: (
        <label htmlFor='divisionName' className='visually-hidden'>
          Sort by: Publisher - A to Z
        </label>
      ),
      name: "divisionName",
      id: "divisionName",
    },
    {
      key: "title",
      text: "Sort by: Title - A to Z",
      content: "Title - A to Z",
      value: "title",
      label: (
        <label htmlFor='title' className='visually-hidden'>
          Sort by: Title - A to Z
        </label>
      ),
      name: "title",
      id: "title",
    },
  ];

  /*
let resultsSortOPtions = [
      <Dropdown.Item
      key="foc"
      text="Sort by: Upcoming FOC"
      value="foc"
      label="Sort by: Upcoming FOC"
      />
]
*/

  if (Cart?.customerInfo?.currentUser?.roleNo !== "W1") {
    resultsSortOPtions.push({
      key: "cartQuantity",
      text: "Sort By: Cart Quantity",
      content: "Cart Qty - High to Low",
      value: "cartQuantity",
    });
  }

  useEffect(() => {
    // if (Cart.shipTo >= 0 && customerLoaded && Cart.shipTo !== cartShipTo) {
    if (filters.length > 0) {
      refreshCatalogData(catalogId, Cart.getShipToAccount().accountNo);
    }

    // }
  }, [Cart.estimateCarts]);

  useEffect(() => {
    // console.log(
    //   "useEffect 1",
    //   match,
    //   "jef",
    //   catalogId,
    //   "jef",
    //   Cart.shipTo,
    //   customerLoaded
    // );

    if (match.catalogId && match.catalogId != catalogId) {
      window.scrollTo(0, 0);
      setCatalogId(match.catalogId);
      createCatalog(
        match.catalogId,
        Cart.shipTo >= 0 ? Cart.getShipToAccount().accountNo : false
      );
    }
    if (Cart.shipTo >= 0) {
      setCartShipTo(Cart.getShipToAccount().accountNo);
    } else if (
      Cart.customerInfo?.currentUser?.roleNo === "W1" &&
      Cart.customerInfo?.currentUser?.accountNo ===
        Cart.customerInfo?.customer?.accountNo
    ) {
      setCartShipTo(Cart.customerInfo?.customer?.accountNo);
    } else {
      setCartShipTo(false);
    }
    //setCartShipTo(Cart.shipTo >= 0 ? Cart.getShipToAccount().accountNo : false);
    //setSeriesFlags(Cart.shipTo >= 0 ? Cart.getShipToAccount().accountNo : false);
    // }
    return function cleanup() {
      Cart.setCurrentCatalogFilters({});
      Cart.setShowNotifications("catalog", false);
    };
  }, [match]);

  const setSeriesFlags = (accountNo) => {
    let accountSeriesHistory = [];
    let filterResultswithSeries = [];
    //console.log('series history 1',hasSeriesHistory);
    //console.log('series history 2',accountNo);
    if (accountNo) {
      accountSeriesHistory = hasSeriesHistory.find(
        (e) => e.shipTo == accountNo
      );
      //console.log('series history 3', accountSeriesHistory);
      //if (typeof accountSeriesHistory?.isbns !=='undefined') {
      filterResultswithSeries = filteredResults.map((x) => ({
        ...x,
        hasSeriesHistory:
          accountSeriesHistory?.isbns &&
          accountSeriesHistory?.isbns?.includes(x.isbn)
            ? true
            : false,
        shipTo: accountNo ? accountNo : false,
      }));
      //console.log('series history 4', filterResultswithSeries);
      setFilteredResults(filterResultswithSeries);
      setOriginalResults(filterResultswithSeries);
      //};
    }
  };

  const setDownloadDialogState = () => {
    setdownloadDiaglogVisible(!downloadDiaglogVisible);
  };

  const clearResultFilters = async (e, data) => {
    const dFiltersArray = filters;
    for (let i = 0; i < dFiltersArray.length; i++) {
      for (let j = 0; j < dFiltersArray[i].values.length; j++) {
        dFiltersArray[i].values[j].checked = false;
      }
    }
    createFilterModel(dFiltersArray);
    setFilteredResults(originalResults);
    setVariantFilter(true);
    await Cart.setCurrentCatalogFilters({});
    // document.getElementById('variant').checked = true;
  };

  const cleanFilters = (e, data) => {
    let dFiltersArray = cloneDeep(filters);
    for (let i = 0; i < dFiltersArray.length; i++) {
      for (let j = 0; j < dFiltersArray[i].values.length; j++) {
        dFiltersArray[i].values[j].checked = false;
      }
    }

    // let dFiltersArray = filters.map(f => {
    //   return {...f, values: f.values.map(fv => {
    //     return fv
    //   })}
    // })
    createFilterModel(dFiltersArray);
    setFilteredResults(originalResults);
    setVariantFilter(true);
  };

  const updateResults = (e, data) => {
    // console.log('updateREsults', data, originalResults);
    const dFiltersArray = cloneDeep(filters);
    for (let i = 0; i < dFiltersArray.length; i++) {
      if (dFiltersArray[i].resultsMatchID === data.name) {
        for (let j = 0; j < dFiltersArray[i].values.length; j++) {
          if (dFiltersArray[i].values[j].match === data.value) {
            dFiltersArray[i].values[j].checked = data.checked;
          }
          if (data.name === "isVariant") {
            if (data.checked === false) {
              setVariantFilter(false);
              dFiltersArray[i].values[j].checked = true;
            } else {
              setVariantFilter(true);
              dFiltersArray[i].values[j].checked = false;
            }
          }
        }
        const newFilters = createFilterModel(dFiltersArray);
        setContextFilters(newFilters);
        const filtersKeysArray = Object.keys(newFilters);

        let filteredRows = [];
        let validTitle = 0;
        const totalfilters = filtersKeysArray.length;

        _.each(originalResults, function (title) {
          _.each(filtersKeysArray, function (filter) {
            if (newFilters[filter].indexOf(title[filter]) != -1) {
              validTitle++;
            }
            if (filter === "cartQuantity") {
              let match = false;
              newFilters[filter].forEach((fv) => {
                if (
                  (fv === false || fv === "false") &&
                  (!!!title[filter] || title[filter] === 0)
                ) {
                  match = true;
                } else if (
                  (fv === true || fv === "true") &&
                  title[filter] &&
                  title[filter] > 0
                ) {
                  match = true;
                }
              });
              if (match) validTitle++;
            }
          });
          if (validTitle === totalfilters) {
            filteredRows.push(title);
          }
          validTitle = 0;
        });
        setFilteredResults(filteredRows);
        //setSeriesFlags(Cart.shipTo >= 0 ? Cart.getShipToAccount().accountNo : false);
        //setSeriesFlags(Cart.shipTo >= 0 ? Cart.getShipToAccount().accountNo : false);
        // setSavedFilters(data);
      }
    }
    setFilters(dFiltersArray);
  };

  // for retoring appplied filters to a catalog - a new catalog display will clear the preserved filters
  const applyCurrentContextFilters = (
    newFilters,
    fullCatalogRows,
    allFilters
  ) => {
    const filtersKeysArray = Object.keys(newFilters);

    // console.log('applyCurrentContexFilers', newFilters, allFilters);
    let filteredRows = [];
    let validTitle = 0;
    const totalfilters = filtersKeysArray.length;
    let newFilterCount = 0;
    _.each(fullCatalogRows, function (title) {
      _.each(filtersKeysArray, function (filter) {
        if (newFilters[filter].indexOf(title[filter]) != -1) {
          validTitle++;
        }
        if (filter === "cartQuantity") {
          let match = false;
          newFilters[filter].forEach((fv) => {
            if (
              (fv === false || fv === "false") &&
              (!!!title[filter] || title[filter] === 0)
            ) {
              match = true;
            } else if (
              (fv === true || fv === "true") &&
              title[filter] &&
              title[filter] > 0
            ) {
              match = true;
            }
          });
          if (match) validTitle++;
        }
      });
      if (validTitle === totalfilters) {
        filteredRows.push(title);
      }
      validTitle = 0;
    });
    _.each(allFilters, function (filterObj) {
      if (filtersKeysArray.includes(filterObj.resultsMatchID)) {
        _.each(filterObj.values, function (filter) {
          if (newFilters[filterObj.resultsMatchID]?.includes(filter.match)) {
            filter.checked = true;
            newFilterCount++;
          }
        });
      }
    });
    setFilters(allFilters);
    setFiltersCount(newFilterCount);
    setFilteredResults(filteredRows);
  };

  const setContextFilters = async (filters) => {
    // console.log('setContextFilers', filters);
    const response = await Cart.setCurrentCatalogFilters(filters);
    return response;
  };

  const clearCartQty = () => {
    let newFilteredResults = filteredResults.map((ci) => {
      return { ...ci, cartQuantity: 0 };
    });
    let newOriginalResults = originalResults.map((ci) => {
      return { ...ci, cartQuantity: 0 };
    });
    setFilteredResults(newFilteredResults);
    setOriginalResults(newOriginalResults);
  };

  const reloadCatalogItems = async (items = []) => {
    // console.log('reloadCatalogItems', items);
    // const newOriginalResults = [...originalResults];

    const newFilteredResults = filteredResults.map((ci) => {
      items.forEach((c) => {
        if (c.isbn === ci.isbn) {
          return {
            ...ci,
            cartQuantity: c.quantity,
            confirmationStatus: "PENDING_UPDATE",
          };
        }
      });
      return { ...ci };
    });
    const newOriginalResults = originalResults.map((ci) => {
      items.forEach((c) => {
        if (c.isbn === ci.isbn) {
          return {
            ...ci,
            cartQuantity: c.quantity,
            confirmationStatus: "PENDING_UPDATE",
          };
        }
      });
      return { ...ci };
    });

    setFilteredResults(newFilteredResults);
    setOriginalResults(newOriginalResults);

    const currentContextFilters = await Cart.getCurrentCatalogFilters(
      match.catalogId
    );
    // console.log('currentContextFilters', currentContextFilters);
    if (Object.keys(currentContextFilters).length > 0) {
      applyCurrentContextFilters(
        currentContextFilters,
        newOriginalResults,
        filters
      );
    }
    // setFilteredResults(newFilteredResults);
  };

  const reloadCatalog = async (clear = false, ignore = true) => {
    // const newOriginalResults = [...originalResults];
    const currentContextFilters = Cart.catalogFilters;

    // console.log(
    //   'reloadCatalog',
    //   Cart,
    //   clear,
    //   ignore,
    //   filters,
    //   originalResults,
    //   filteredResults,
    //   currentContextFilters
    // );
    if (ignore) {
      // if (Object.keys(currentContextFilters).length > 0) {
      //   applyCurrentContextFilters(
      //     currentContextFilters,
      //     newOriginalResults,
      //     filters
      //   );
      // }
      // console.log('skipping catalog refresh');
      return;
    }

    const newFilteredResults = filteredResults.map((ci) => {
      let wCi = { ...ci };
      if (clear) {
        wCi.cartQuantity = false;
        wCi.confirmationStatus = false;
      }
      Cart.estimateCarts.forEach((c) => {
        // let noCurrentFocItemsInCart = true;
        if (c.foc === ci.foc) {
          wCi.confirmationStatus = c.confirmationStatus;
        }

        c.items.forEach((x) => {
          // ci.confirmationStatus = c.confirmationStatus;
          if (x.isbn.toString() === ci.isbn.toString()) {
            // console.log('found match newfilt', x.isbn, x.quantity);
            // noCurrentFocItemsInCart = false;
            wCi.cartQuantity = x.quantity;
            setInCartFlag(true);
          }
        });
        // }
        // if (noCurrentFocItemsInCart) {
        //   // making sure accordion confirmation status is not rendering if no items are in foc cart
        //   ci.confirmationStatus = undefined;
        // }
      });
      return wCi;
    });
    const newOriginalResults = originalResults.map((ci) => {
      let wCi = { ...ci };
      if (clear) {
        wCi.cartQuantity = false;
        wCi.confirmationStatus = false;
      }
      Cart.estimateCarts.forEach((c) => {
        // let noCurrentFocItemsInCart = true;
        if (c.foc === ci.foc) {
          wCi.confirmationStatus = c.confirmationStatus;
        }

        c.items.forEach((x) => {
          // ci.confirmationStatus = c.confirmationStatus;
          if (x.isbn.toString() === ci.isbn.toString()) {
            // console.log('found match newfilt', x.isbn, x.quantity);
            // noCurrentFocItemsInCart = false;
            wCi.cartQuantity = x.quantity;
            setInCartFlag(true);
          }
        });
      });
      return wCi;
    });
    // setFilteredResults(newFilteredResults);
    setOriginalResults(cloneDeep(newOriginalResults));

    const cqFilterActive = filters.findIndex((fi) => {
      if (
        fi.resultsMatchID === "cartQuantity" &&
        fi.values[1]?.checked === true
      ) {
        return true;
      } else {
        return false;
      }
    });
    if (cqFilterActive >= 0) {
      // console.log('applying filters on reload');
      updateResults(null, { name: "cartQuantity", match: false, value: true });
    } else {
      setFilteredResults(newFilteredResults);
    }
  };

  const AccordionPanels = useCallback(() => {
    // console.log('AccordionPanels', filteredResults, originalResults);
    let passedDateFOCs = {};
    let validDateFOCs = {};
    // const todaysDate = getTodaysDate();
    const todaysDate = DateTime.now().toFormat("yyyy-MM-dd");
    // console.log("todaysDate", todaysDate, td);

    let onSaleSortedResults = [];
    if (currentSort === "cartQuantity") {
      onSaleSortedResults = _.sortBy(filteredResults, "cartQuantity").reverse();
    } else {
      onSaleSortedResults = _.sortBy(filteredResults, "onsale");
    }

    const focSortedResults = sortObj(_.groupBy(onSaleSortedResults, "foc"));

    _.each(focSortedResults, function (value, key) {
      key < todaysDate
        ? (passedDateFOCs[key] = value)
        : (validDateFOCs[key] = value);
    });

    let newPanels = [];
    let i = 0;
    let accordionTitlePara = "";

    const focFiltersCount = _.filter(
      _.find(filters, { resultsMatchID: "foc" }).values,
      {
        checked: true,
      }
    );
    const isOneFocDate = focFiltersCount.length === 1;
    if (
      currentSort === "foc" ||
      currentSort === "cartQuantity" ||
      (currentSort === "title" && isOneFocDate)
    ) {
      //valid FOC panels
      _.each(validDateFOCs, function (value, key) {
        let confirmationStatus = false;
        const TitleRowsArray = value.map((title, key) => {
          if (!confirmationStatus)
            confirmationStatus = title.confirmationStatus;
          return (
            <div key={key} className='ipad-correction mb-2 cat-row'>
              <TitleRow
                data={title}
                pageType='catalog'
                divider={false}
                key={`valid-accord-title-${title.isbn}`}
                callback={reloadCatalog}
                addAll={addMultipleTitles}
                defaultQty={addMultipleTitles === true ? 0 : false}
                cartShipTo={cartShipTo}
                updateTotalQty={updateTotalQty}
                clearAddAllQty={clearAddAllQty}
                resetAllQty={resetAllQty}
                variantsNotMetTitles={variantsNotMetTitles}
                initialVisibility={filteredResults.length < 400}
              />
            </div>
          );
        });

        const panelItem = {
          key: key,
          title: {
            content: (
              <div
                key={key}
                className='d-flex w-100 justify-content-between align-items-center'
              >
                <div>FOC {getAccordionTitleDate(key)}</div>
                <ConfirmationStatus
                  confirmationStatus={confirmationStatus}
                  hidePast={true}
                  focDate={key}
                />
                {/* <FOCStatus
                  focDate={key}
                  confirmationStatus={confirmationStatus}
                /> */}
                {Cart?.customerInfo?.currentUser?.roleNo !== "W1" && (
                  <PRHButton
                    // disabled={Cart.shipTo < 0}
                    backgroundColorOverride='white'
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        addMultipleTitles &&
                        addMultipleISBNs &&
                        addMultipleISBNs > 0
                      ) {
                        setMultiAddISBNsCancelOpen(true);
                      } else {
                        Cart.shipTo < 0
                          ? setisLocationWarningOpen(true)
                          : toggleMultiAdd();
                      }
                    }}
                  >
                    {addMultipleTitles === true
                      ? "Cancel Multi-Add"
                      : "Multi-add Mode"}
                  </PRHButton>
                )}
              </div>
            ),
            // icon: `${active ? 'chevron down' : 'chevron right'}`,
          },
          content: TitleRowsArray,
        };
        newPanels.push(panelItem);
        i++;
      });
      //expired FOC panels
      _.each(passedDateFOCs, function (value, key) {
        const TitleRowsArray = value.map((title, key) => (
          <div key={key} className='ipad-correction mb-2 cat-row'>
            <TitleRow
              data={title}
              pageType='catalog'
              divider={false}
              key={`accord-title-${title.isbn}`}
              callback={reloadCatalog}
              addAll={addMultipleTitles}
              multiAddQty={addMultipleTitles === true ? 0 : false}
              cartShipTo={cartShipTo}
              updateTotalQty={updateTotalQty}
              clearAddAllQty={clearAddAllQty}
              variantsNotMetTitles={variantsNotMetTitles}
              initialVisibility={filteredResults.length < 400}
            />
          </div>
        ));
        const panelItem = {
          key: key,
          title: {
            content: (
              <div
                key={key}
                className='d-flex w-100 justify-content-between align-items-center'
              >
                <div>FOC {getAccordionTitleDate(key)}</div>
                <FOCStatus focDate={key} />

                {Cart?.customerInfo?.currentUser?.roleNo !== "W1" && (
                  <PRHButton
                    // disabled={Cart.shipTo < 0}
                    backgroundColorOverride='white'
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        addMultipleTitles &&
                        addMultipleISBNs &&
                        addMultipleISBNs > 0
                      ) {
                        setMultiAddISBNsCancelOpen(true);
                      } else {
                        Cart.shipTo < 0
                          ? setisLocationWarningOpen(true)
                          : toggleMultiAdd();
                      }
                    }}
                  >
                    {addMultipleTitles === true
                      ? "Cancel Multi-Add"
                      : "Multi-add Mode"}
                  </PRHButton>
                )}
              </div>
            ),
            // icon: `${active ? 'chevron down' : 'chevron right'}`,
          },
          content: TitleRowsArray,
        };
        newPanels.push(panelItem);
        i++;
      });
    } else {
      const onSaleDateSortedResults = sortObj(
        _.groupBy(_.sortBy(filteredResults, "title"), currentSort)
      );
      const titleSortedResults = _.groupBy(
        sortObj(_.sortBy(filteredResults, "title"))
      );
      const sortedResults =
        currentSort === "title" ? titleSortedResults : onSaleDateSortedResults;

      _.each(sortedResults, function (value, key) {
        const TitleRowsArray = value.map((title, key) => (
          <div className='ipad-correction mb-2 cat-row'>
            <TitleRow
              data={title}
              pageType='catalog'
              divider={false}
              key={`valid-accord-title-${title.isbn}`}
              callback={reloadCatalog}
              addAll={addMultipleTitles}
              multiAddQty={addMultipleTitles === true ? 0 : false}
              cartShipTo={cartShipTo}
              updateTotalQty={updateTotalQty}
              clearAddAllQty={clearAddAllQty}
              variantsNotMetTitles={variantsNotMetTitles}
              initialVisibility={filteredResults.length < 400}
            />
          </div>
        ));
        const panelItem = {
          key: key,
          title: {
            content: (
              <div className='d-flex w-100 justify-content-between align-items-center'>
                <div>
                  {accordionTitlePara}{" "}
                  {currentSort === "title"
                    ? "Titles A - Z"
                    : value[0].divisionName}
                </div>
                {Cart?.customerInfo?.currentUser?.roleNo !== "W1" && (
                  <PRHButton
                    // disabled={Cart.shipTo < 0}
                    backgroundColorOverride='white'
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        addMultipleTitles &&
                        addMultipleISBNs &&
                        addMultipleISBNs > 0
                      ) {
                        setMultiAddISBNsCancelOpen(true);
                      } else {
                        Cart.shipTo < 0
                          ? setisLocationWarningOpen(true)
                          : toggleMultiAdd();
                      }
                    }}
                  >
                    {addMultipleTitles === true
                      ? "Cancel Multi-Add"
                      : "Multi-add Mode"}
                  </PRHButton>
                )}
                {/* <FOCStatus focDate={key}></FOCStatus> */}
              </div>
            ),
            // icon: `${active ? 'chevron down' : 'chevron right'}`,
          },
          content: TitleRowsArray,
        };
        newPanels.push(panelItem);
        i++;
      });
    }

    return (
      <Accordion
        defaultActiveIndex={Array.from(Array(i).keys())}
        panels={newPanels}
        exclusive={false}
        fluid
        key={`accord-${i}`}
      />
    );
  });

  const createFilterModel = (filtersArray) => {
    const dFiltersArray = filtersArray;
    const customFilters = {};
    let filterCount = 0;
    // this.setState({ filtersCount: 0 });
    for (let i = 0; i < dFiltersArray.length; i++) {
      const dFilter = dFiltersArray[i];
      const filterOptions = dFilter.values;
      const filterValues = [];
      let hasFilter = false;

      for (let j = 0; j < filterOptions.length; j++) {
        if (filterOptions[j].checked === true) {
          if (filterOptions[j].match !== "None") {
            filterCount++;
            filterValues.push(filterOptions[j].match);
          } else {
            filterCount++;
            filterValues.push(null);
          }
          hasFilter = true;
        }
      }
      if (hasFilter === true) {
        customFilters[dFilter.resultsMatchID] = filterValues;
      }
    }
    setFiltersCount(filterCount);
    // setFilterModel(customFilters);
    return customFilters;
  };

  const handleSortChange = (value) => {
    let sortedRows = [];
    setCurrentSort(value);
    sortedRows = _.sortBy(filteredResults, value);
    setFilteredResults(
      value === "cartQuantity" ? sortedRows.reverse() : sortedRows
    );
  };

  const createTitleSortedRows = () => {
    const titleSortedResults = _.sortBy(filteredResults, function (i) {
      return i.title.toLowerCase();
    });
    return titleSortedResults.map((title, index) =>
      isMobile ? (
        <React.Fragment key={title.key}>
          <TitleRowMobile
            data={title}
            pageType='catalog'
            divider={false}
            callback={reloadCatalog}
            addAll={addMultipleTitles}
            defaultQty={addMultipleTitles === true ? 0 : false}
            cartShipTo={cartShipTo}
            updateTotalQty={updateTotalQty}
            clearAddAllQty={clearAddAllQty}
            resetAllQty={resetAllQty}
            variantsNotMetTitles={variantsNotMetTitles}
          />
        </React.Fragment>
      ) : (
        <React.Fragment key={title.isbn}>
          <TitleRow
            data={title}
            pageType='catalog'
            divider={false}
            callback={reloadCatalog}
            addAll={addMultipleTitles}
            defaultQty={addMultipleTitles === true ? 0 : false}
            cartShipTo={cartShipTo}
            updateTotalQty={updateTotalQty}
            clearAddAllQty={clearAddAllQty}
            resetAllQty={resetAllQty}
            variantsNotMetTitles={variantsNotMetTitles}
            initialVisibility={filteredResults.length < 400}
          />
        </React.Fragment>
      )
    );
  };

  const createCatalog = async (cID, shipTo = false) => {
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Catalog loading . . .",
      longLoadText: "Catalog still searching . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);
    setCatalogId(cID);
    setResetAllQty(!resetAllQty);
    setAddMultipleISBNQtyObject({});
    setVariantsNotMetTitles([]);
    setAddMultipleISBNs(0);
    setAddMultipleTotalQty(0);
    setAddMultipleTitles(false);
    const catalogData = await Cart.getCatalog(cID);
    // const catalogData = await Cart.getCatalog(cID, shipTo);
    // console.log("catalogData", catalogData);
    if (catalogData.status === "fulfilled") {
      if (catalogData.data.status === "OK") {
        // clearResultFilters();
        cleanFilters();
        let catData = [...catalogData.data.data.results];
        //let seriesHistoryData = [...catalogData.data.data.hasSeriesHistory];
        if (
          Cart.estimateCarts.length > 0 &&
          Cart?.customerInfo?.currentUser?.roleNo !== "W1"
        ) {
          // console.log(
          //   'loading cart data in catalog',
          //   catData,
          //   Cart.estimateCarts
          // );
          catData = catalogData.data.data.results.map((ci) => {
            let wCi = { ...ci };
            Cart.estimateCarts.forEach((c) => {
              if (c.foc === ci.foc) {
                wCi.confirmationStatus = c.confirmationStatus;
              }
              c.items.forEach((x) => {
                if (x.isbn === ci.isbn) {
                  wCi.cartQuantity = x.quantity;
                  setInCartFlag(true);
                }
              });
            });
            return wCi;
          });
        }
        //setHasSeriesHistory(seriesHistoryData);
        setOriginalResults(catData);
        setNumOfResults(catalogData.data.data.resultCount);
        let newFilters = [...catalogData.data.data.filters];
        if (Cart?.customerInfo?.currentUser?.roleNo === "W1") {
          newFilters = catalogData.data.data.filters.filter((f) => {
            if (f.filterDisplayName === "In Cart") {
              return false;
            } else {
              return true;
            }
          });
        }
        setFilters(newFilters);
        setFilteredResults(catData);
        const groupedResults = _.groupBy(_.sortBy(catData, "foc"), "foc");
        let focDatesHeader = [];
        const focDatesArray = Object.keys(groupedResults);
        if (focDatesArray.length !== 0) {
          focDatesHeader = focDatesArray.filter((f) => {
            if (f != "null" && f != null && f !== "undefined") {
              return f;
            }
          });
          focDatesHeader = focDatesHeader.map((f) => {
            if (f !== "null" && f !== null && f !== "undefined") {
              const tempArray = f.split("-");
              return tempArray[1] + "/" + tempArray[2];
            }
          });
        }
        const currentCotextFilters = await Cart.getCurrentCatalogFilters(cID);
        if (Object.keys(currentCotextFilters).length > 0) {
          applyCurrentContextFilters(
            currentCotextFilters,
            catData,
            catalogData.data.data.filters
          );
        }
        setFocDatesHeaderString(focDatesHeader.join(", "));
        getCatalogDownloadsInfo(cID);
        // setEstimateCarts(
        //   catalogData.data.data.carts ? catalogData.data.data.carts : []
        // );
      }
    } else {
      return <div>There is no catalog with this date</div>;
    }
    // setCustomerLoaded(true);
  };

  const refreshCatalogData = async (cID, shipTo = false) => {
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Catalog loading . . .",
      longLoadText: "Catalog still searching . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);
    setResetAllQty(!resetAllQty);
    setAddMultipleISBNQtyObject({});
    setVariantsNotMetTitles([]);
    setAddMultipleISBNs(0);
    setAddMultipleTotalQty(0);
    setAddMultipleTitles(false);
    clearCartQty();

    // const catalogData = await Cart.loadEstimateCarts({
    //   silent: false,
    //   titleDetail: false,
    // });

    // setEstimateCarts(catalogData);

    reloadCatalog(true, false);

    dirtyHandler.setLoadingTakeover(false);
    // setCustomerLoaded(true);
  };

  const handleCatDownload = async (downloadItem) => {
    let dataToPost = {};
    catalogDownloadData.forEach(function (item, index) {
      if (item.fileName === downloadItem) {
        dataToPost = item;
      }
    });
    const response = await Cart.getCatlogDownloadFile(dataToPost, catalogId);
    if (response.data?.status === "OK") {
      window.location.assign(response.data.data.downloadUrl);
    }
  };

  const handlePreCatalogDownload = (downloadItem) => {
    if (downloadItem === "focs") {
      setDownloadDialogState();
    } else {
      handleCatDownload(downloadItem);
    }
    setSelectValue(null);
  };

  const getCatalogDownloadsInfo = (cID) => {
    Cart.getCatalogDownloadsInfo(cID).then((result) => {
      if (result.data.status === "OK") {
        const files = result.data.data.files;
        //console.log('result.data.data.files', result.data.data.files);
        setCatalogDownloadData([...files]);
        const dropdownOptions = [];
        let focsCount = 0;
        let focItems = [];
        files.forEach(function (item, index) {
          focsCount;
          if (item.type !== "foc") {
            dropdownOptions.push({
              key: item.fileName,
              text: (
                <div className='flex'>
                  <div>
                    {item.type === "covers" ? (
                      <Icon
                        icon={faFileArchive}
                        className='mr-1'
                        color='grey'
                      />
                    ) : (
                      <img
                        name='file alternate outline'
                        alt={`Download ${item.fileName}`}
                        src={ReportSingle}
                        className='mr-1'
                        color='grey'
                      />
                    )}
                  </div>
                  <div>
                    {item.desc}
                    <div className='download-link-subtxt'>
                      {item.desc.indexOf("Master") >= 0 ||
                      item.desc.indexOf("Product") >= 0
                        ? "Updated nightly"
                        : "Updated weekly"}
                    </div>
                  </div>
                </div>
              ),
              value: item.fileName,
            });
          } else {
            focsCount = focsCount + 1;
            focItems.push(item);
          }
          setFocs(focItems);
          if (focsCount > 0) {
            dropdownOptions[1] = {
              key: "focs",
              text: (
                <div className='flex'>
                  <div>
                    <img
                      name='file alternate outline'
                      src={ReportMulti}
                      alt={`Download ${item.fileName}`}
                      className='mr-1'
                      color='grey'
                    />
                  </div>
                  <div>
                    FOC Title Data (.csv)
                    <div className='download-link-subtxt'>Updated nightly</div>
                  </div>
                </div>
              ),
              value: "focs",
            };
          }
        });
        const reIndexedDropdownOptions = Object.values(dropdownOptions);
        setDownloadDropdownOptions(reIndexedDropdownOptions);
        //touch 2
      }
    });
  };

  const getCatalogDisplayTitle = () => {
    const dateArray = catalogId.split("-");
    // const d = new Date();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // d.setMonth(dateArray[1] - 1);
    // const monthName = d.toLocaleString('default', { month: 'long' });
    return month[parseInt(dateArray[1]) - 1] + " " + dateArray[0];
  };

  const toggleMultiAdd = () => {
    if (addMultipleTitles === true) {
      setAddMultipleTitles(false);

      dirtyHandler.resetDirtyState();
      dirtyHandler.setDirtyStateForId("multiadd", false);
    } else {
      setAddMultipleTitles(true);
      // const dialogObject = {
      //   ...dirtyHandler.dialogObject,
      //   content: (
      //     <>
      //       You have changed quantities for some of this catalog’s items.
      //       Leaving this page will close Multi-add Mode and cancel adding them
      //       to your carts. Are you sure you want to continue and discard your
      //       changes?
      //     </>
      //   ),
      //   dialogOptions: {
      //     ...dirtyHandler.dialogObject.dialogOptions,
      //     header: <>Discard changes</>,
      //     actions: [
      //       {
      //         label: 'No, stay on page',
      //         preAction: async () => ({
      //           proceed: true,
      //           cancelAction: true,
      //         }),
      //       },
      //       {
      //         label: 'Yes, discard and leave',
      //         preAction: async () => {
      //           return {
      //             proceed: true,
      //           };
      //         },
      //       },
      //     ],
      //   },
      // };
      // dirtyHandler.setDialogObject(dialogObject);
      // dirtyHandler.setDirtyStateForId('multiadd', true);
    }

    if (addMultipleTitles === false) {
      setClearAddAllQty(!clearAddAllQty);
      setAddMultipleISBNQtyObject({});
      setVariantsNotMetTitles([]);
    }
  };

  // useEffect(() => {
  //   if (clearAddAllQty === true) {
  //     setAddMultipleISBNQtyObject({});
  //     setAddMultipleISBNs(0);
  //     setAddMultipleTotalQty(0);
  //   }
  // }, [clearAddAllQty]);

  const getSortTitleByKey = (key) => {
    const item = resultsSortOPtions.find((option) => option.key === key);
    return item.text;
  };

  const cancelMultiAdd = (reload = false) => {
    setAddMultipleTitles(false);
    setAddMultipleISBNs(0);
    setResetAllQty(!resetAllQty);
    setAddMultipleISBNQtyObject({});
    setVariantsNotMetTitles([]);
    dirtyHandler.resetDirtyState();
    dirtyHandler.setDirtyStateForId("multiadd", false);
    if (reload)
      refreshCatalogData(catalogId, Cart.getShipToAccount().accountNo);
  };

  const clearMultiAddQty = () => {
    // setClearAddAllQty(false);

    setClearAddAllQty(!clearAddAllQty);
    setAddMultipleISBNQtyObject({});
    setVariantsNotMetTitles([]);
    setAddMultipleISBNs(0);
    setAddMultipleTotalQty(0);
    dirtyHandler.resetDirtyState();
    dirtyHandler.setDirtyStateForId("multiadd", false);
  };

  const addAllToCart = (variantsNotMetTitles = false) => {
    // jeff
    // console.log(
    //   'addAllToCart',
    //   addMultipleISBNQtyObject,
    //   originalResults,
    //   variantsNotMetTitles
    // );
    let workTitles = {};
    Object.keys(addMultipleISBNQtyObject).forEach((i) => {
      let workQty = false;
      if (variantsNotMetTitles) {
        const vf = variantsNotMetTitles.findIndex(
          (vnm) => vnm.isbn === i.toString()
        );
        if (vf >= 0) {
          if (
            variantsNotMetTitles[vf].notEligible <
            variantsNotMetTitles[vf].quantityEntered
          ) {
            workQty =
              variantsNotMetTitles[vf].quantityEntered -
              variantsNotMetTitles[vf].notEligible;
          } else {
            return;
          }
        }
      }
      const iItem = originalResults.find((or) => or.isbn === i.toString());
      if (iItem) {
        let foc = iItem.foc;
        let cartType = "GEN";
        if (!isGeneralCart(foc)) {
          if (isFocCartExpired(foc)) {
            foc = "2099-12-31";
            cartType = "FGN";
          } else if (iItem.focStatus !== "ok") {
            foc = "2099-12-31";
            cartType = "GEN";
          } else {
            cartType = "FOC";
          }
        }
        if (workTitles[foc]) {
          workTitles[foc].items.push({
            isbn: i,
            quantity: workQty ? workQty : addMultipleISBNQtyObject[i].qty,
            cartType,
          });
        } else {
          workTitles[foc] = {
            foc: foc,
            shipTo: Cart.getShipToAccount().accountNo,
            items: [
              {
                isbn: i,
                quantity: workQty ? workQty : addMultipleISBNQtyObject[i].qty,
                cartType,
              },
            ],
          };
        }
      }
    });

    const carts = [];
    Object.keys(workTitles).forEach((wi) => {
      carts.push(workTitles[wi]);
    });

    // console.log('carts', carts);

    if (carts.length > 0) {
      Cart.addEstimateItems({ simulat: false, carts, addAll: true }).then(
        (d) => {
          // console.log("d", d);
          if (d?.modalInfo === "VARIANTS_NOT_MET") {
            setMultiAddsData(d);
            setMultiAddISBNsWarningOpen(true);
          } else if (d?.modalInfo === "ADDED_ITEMS") {
            dirtyHandler.resetDirtyState();
            dirtyHandler.setDirtyStateForId("multiadd", false);
            setMultiAddsData(d.carts);
            reloadCatalogItems(d.items);
            setMultiAddISBNsSuccessOpen(true);
          } else {
            dirtyHandler.resetDirtyState();
            dirtyHandler.setDirtyStateForId("multiadd", false);
            setResetAllQty(!resetAllQty);
            setAddMultipleISBNQtyObject({});
            setVariantsNotMetTitles([]);
            setAddMultipleTitles(false);
          }
        }
      );
    } else {
      dirtyHandler.resetDirtyState();
      dirtyHandler.setDirtyStateForId("multiadd", false);
      setResetAllQty(!resetAllQty);
      setAddMultipleISBNQtyObject({});
      setVariantsNotMetTitles([]);
      setAddMultipleTitles(false);
    }
  };

  const updateTotalQty = (newQty, isbn, confirmationStatus) => {
    let totalQty = 0;
    const temp = { ...addMultipleISBNQtyObject };
    if (!newQty || parseInt(newQty) === 0) {
      delete temp[isbn];
    } else {
      temp[isbn] = { qty: newQty, confirmationStatus };
    }
    setAddMultipleISBNQtyObject(temp);
    setAddMultipleISBNs(Object.keys(temp).length);
    for (const key in temp) {
      if (temp[key]) totalQty = totalQty + parseInt(temp[key].qty);
    }
    setAddMultipleTotalQty(totalQty);
    const nvnmt = variantsNotMetTitles.filter((v) => {
      if (v.isbn === isbn) {
        return false;
      } else {
        return true;
      }
    });
    setVariantsNotMetTitles(nvnmt);
    // console.log('addMultipleQty', addMultipleISBNs, temp);
    if (Object.keys(temp).length > 0) {
      const dialogObject = {
        ...dirtyHandler.dialogObject,
        content: (
          <>
            You have changed quantities for some of this catalog’s items.
            Leaving this page will close Multi-add Mode and cancel adding them
            to your carts. Are you sure you want to continue and discard your
            changes?
          </>
        ),
        dialogOptions: {
          ...dirtyHandler.dialogObject.dialogOptions,
          header: <>Discard changes</>,
          actions: [
            {
              label: "No, stay on page",
              preAction: async () => ({
                proceed: true,
                cancelAction: true,
              }),
            },
            {
              label: "Yes, discard and leave",
              preAction: async () => {
                return {
                  proceed: true,
                };
              },
            },
          ],
        },
      };
      dirtyHandler.setDialogObject(dialogObject);
      dirtyHandler.setDirtyStateForId("multiadd", true);
    } else {
      dirtyHandler.resetDirtyState();
      dirtyHandler.setDirtyStateForId("multiadd", false);
    }
  };

  // if (!Cart.customerLoaded) {
  //   return <></>;
  // }

  const multiAddContinue = (variantsNotMetTitles) => {
    // console.log('continue', variantsNotMetTitles);
    addAllToCart(variantsNotMetTitles);
  };

  // const multiAddCancel = (reload = false) => {
  //   console.log('Cancel');
  //   setResetAllQty(!resetAllQty);
  //   setAddMultipleISBNQtyObject({});
  //   setAddMultipleTitles(false);
  // };

  const multiAddVariantCancel = (variantsNotMetTitles) => {
    if (variantsNotMetTitles) setVariantsNotMetTitles(variantsNotMetTitles);
    //     variantsNotMetTitles: [{isbn: "75960620229400121", title: "STAR WARS: CRIMSON REIGN 1 LOPEZ SABACC CARD VARIANT",…}]
    // 0: {isbn: "75960620229400121", title: "STAR WARS: CRIMSON REIGN 1 LOPEZ SABACC CARD VARIANT",…}
    // eligible: 0
    // foc: "2021-11-08"
    // isbn: "75960620229400121"
    // notEligible: 2000
    // quantityEntered: 2000
    // title: "STAR WARS: CRIMSON REIGN 1 LOPEZ SABACC CARD VARIANT"
  };

  // const isVariantsNotMetTitle = (isbn) => {
  //   console.log('isVariantsNotMetTitle', isbn, variantsNotMetTitles);
  //   if (
  //     variantsNotMetTitles.findIndex((i) => {
  //       if (i.isbn === isbn.toString()) {
  //         console.log('matched isbn');
  //         return true;
  //       }
  //     }) >= 0
  //   ) {
  //     return true;
  //   } else return false;
  // };

  const multiAddSuccessGoToCarts = () => {
    history.push({ pathname: "/cart-overview" });
  };
  // console.log('Catalog render', Cart);

  const renderMobileRows = () => {
    return filteredResults.map((result, index) => (
      <React.Fragment key={`mobile-result-row-${result.key}_${index}`}>
        <TitleRowMobile
          data={result}
          pageType='catalog'
          divider={false}
          onRefreshTab={reloadCatalog}
          key={`mobile-result-row-${result.key}_${index}`}
        />
      </React.Fragment>
    ));
  };

  const renderMobileTabsOrRows = () =>
    currentSort === "foc" || currentSort === "cartQuantity" ? (
      <MobileCatalogItemsTabs
        filteredResults={filteredResults}
        currentTabData={currentTabData}
        currentSort={currentSort}
        reloadCatalog={reloadCatalog}
        onSetCurrentTabData={(tab) => setCurrentTabData(tab)}
        filters={filters}
      />
    ) : (
      renderMobileRows()
    );

  return (
    <PageStandard
      containerFluid={true}
      headerType='loggedIn'
      className='cop-container cat-container'
      locationSelector={true}
    >
      <MultiAddISBNsWarning
        setOpen={setMultiAddISBNsWarningOpen}
        open={multiAddISBNsWarningOpen}
        data={multiAddISBNsData}
        callback={multiAddContinue}
        cancelCallback={multiAddVariantCancel}
      />
      <MultiAddISBNsSuccess
        setOpen={setMultiAddISBNsSuccessOpen}
        open={multiAddISBNsSuccessOpen}
        data={multiAddISBNsData}
        callback={multiAddSuccessGoToCarts}
        cancelCallback={cancelMultiAdd}
      />
      <MultiAddISBNsCancel
        setOpen={setMultiAddISBNsCancelOpen}
        open={multiAddISBNsCancelOpen}
        callback={cancelMultiAdd}
        cancelCallback={multiAddVariantCancel}
      />
      <DialogModal
        setOpen={setisLocationWarningOpen}
        open={isLocationWarningOpen}
        dialogOptions={{
          header: "SELECT A LOCATION",
          actions: [
            {
              hidden: false,
              label: "OK",
              textColorOverride: "white",
              backgroundColorOverride: "blue",
              borderColorOverride: "blue",
              textColorHoverOverride: "blue",
              backgroundHoverColorOverride: "white",
              borderHoverColorOverride: "blue",
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                return { proceed: true };
              },
            },
          ],
        }}
        options={{ className: "tm-modal-container" }}
      >
        <p className='paragraph-2'>
          <span className='fort-book'>
            You must select a location from the header at the top of the page
            before adding items to your cart.
          </span>
        </p>
      </DialogModal>
      <DialogModal
        setOpen={setModifyConfirmedCartModalOpen}
        open={modifyConfirmedCartModalOpen}
        contentPt='pt2'
        dialogOptions={{
          header: "Adding to a confirmed cart",
          actions: [
            {
              hidden: false,
              label: "Cancel",
              // textColorOverride: 'white',
              // backgroundColorOverride: 'blue',
              // borderColorOverride: 'blue',
              // textColorHoverOverride: 'blue',
              // backgroundHoverColorOverride: 'white',
              // borderHoverColorOverride: 'blue',
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                return { proceed: true };
              },
            },
            {
              hidden: false,
              label: "Yes, continue",
              textColorOverride: "white",
              backgroundColorOverride: "blue",
              borderColorOverride: "blue",
              textColorHoverOverride: "blue",
              backgroundHoverColorOverride: "white",
              borderHoverColorOverride: "blue",
              bold: true,
              preAction: async () => {
                return { proceed: true };
              },
              action: () => {
                addAllToCart();
                return { proceed: true };
              },
            },
          ],
        }}
        options={{ className: "tm-modal-container" }}
      >
        <div className='Xparagraph-2'>
          <div className='mb-2'>
            You are adding items to an FOC cart that is already confirmed.
          </div>
          <div className='fort-book mb-2 '>
            Note: You will need to go to the cart and confirm any updates before
            the FOC date to ensure the correct quantities are placed and
            processed on FOC date.
          </div>
          <div className=''>Would you like to continue?</div>
        </div>
      </DialogModal>
      <div
        className={`d-flex flex-column w-100 cop-header cat-header ${
          isMobile ? "" : "mb-3"
        }`}
      >
        <Container>
          {downloadDiaglogVisible ? (
            <CatalogFileModal
              openState={downloadDiaglogVisible}
              setDownloadDialogState={setDownloadDialogState}
              focs={focs}
              handleCatDownload={handleCatDownload}
            />
          ) : (
            ""
          )}
          <div
            className={`d-flex justify-content-between ${
              isMobile ? "flex-column" : "flex-row"
            }`}
          >
            <div className='d-flex flex-column cat-ttl-meta'>
              <div className='catalog-title'>
                <div className='catalogIcon' /> Catalog:{" "}
                {catalogId !== 0 ? getCatalogDisplayTitle() : ""}
              </div>
              <div className='catalog-title-metadata'>
                <div>
                  <span className='catalog-meta-data'>FOC Dates:</span>{" "}
                  {focDatesHeaderString}
                  {!isMobile ? "| " : <br />}
                  <span className='catalog-meta-data'>Items: </span>
                  {numOfResults}{" "}
                  {filteredResults.length < numOfResults
                    ? `(${filteredResults.length} shown)`
                    : ""}
                </div>
              </div>
            </div>
            <div
              className={`d-flex align-items-end ${
                !isMobile
                  ? "justify-content-end cat-dropdowns flex-column"
                  : "justify-content-space-between mt-2 flex-row"
              }`}
            >
              {isMobile && (
                <div className='catalog-filter-button'>
                  <PRHButton
                    size='medium'
                    icon='sliders horizontal'
                    backgroundColorOverride='blue'
                    textColorOverride='white'
                    iconColorOverride='white'
                    onClick={() => setShowFilters(true)}
                  >
                    Filter {filtersCount > 0 ? `(${filtersCount})` : ""}
                  </PRHButton>
                </div>
              )}
              <div
                className={`catalog-titles-sort  ${
                  isMobile ? "d-flex flex-column align-items-end" : ""
                }`}
              >
                {catalogId !== 0 && downloadDropdownOptions.length > 0 ? (
                  <div
                    className={`download-dropdown-div ${
                      isMobile ? "m-0 mb-2" : ""
                    }`}
                  >
                    {/* <Button onClick={() => addMultiple()}>Add all</Button> */}
                    {!isMobile ? (
                      <Dropdown
                        options={downloadDropdownOptions}
                        direction='left'
                        value={selectValue}
                        closeOnChange
                        onChange={(e, v) => {
                          handlePreCatalogDownload(v.value);
                        }}
                        trigger={
                          <span style={{ marginRight: "10px" }}>Download</span>
                        }
                        selectOnBlur={false}
                        // defaultValue={'Download'}
                        className={`download-dropdown-catalog`}
                      />
                    ) : (
                      showOnMobile && (
                        <PRHButton
                          className='mobile-options-button'
                          onClick={() => setDownloadMenuVisible(true)}
                        >
                          Download <Icon name='triangle down' />
                        </PRHButton>
                      )
                    )}
                    {showOnMobile && (
                      <MobileOptionsMenu
                        title='Download'
                        onClose={() => setDownloadMenuVisible(false)}
                        onOutsideMenuClick={() => setDownloadMenuVisible(false)}
                        menuItems={downloadDropdownOptions}
                        menuVisible={downloadMenuVisible}
                        className='download-dropdown-catalog'
                        onOptionClick={(option) =>
                          handlePreCatalogDownload(option.value)
                        }
                      />
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className='sort-dropdown-div'>
                  {!isMobile ? (
                    <>
                      <Dropdown
                        selection
                        id='sortmenu'
                        name='sortmenu'
                        options={resultsSortOPtions}
                        value={currentSort}
                        labeled={true}
                        direction='left'
                        onChange={(e, v) => {
                          handleSortChange(v.value);
                        }}
                        className='sort-dropdown sort-dropdown-catalog download-dropdown-div'
                      />
                    </>
                  ) : (
                    <PRHButton
                      className='mobile-options-button sort-mobile-btn'
                      onClick={() => setSortMenuVisible(true)}
                    >
                      {getSortTitleByKey(currentSort)}{" "}
                      <Icon name='triangle down' />
                    </PRHButton>
                  )}
                  <MobileOptionsMenu
                    title='Sort by'
                    onClose={() => setSortMenuVisible(!sortMenuVisible)}
                    onOutsideMenuClick={() =>
                      setSortMenuVisible(!sortMenuVisible)
                    }
                    menuItems={resultsSortOPtions}
                    menuVisible={sortMenuVisible}
                    className='download-dropdown-catalog'
                    onOptionClick={(option) => handleSortChange(option.value)}
                    radio={true}
                    currentSort={currentSort}
                  />
                </div>
              </div>

              {/* {currentSort === 'cartQuantity' ||
              currentSort === 'foc' ||
              currentSort === 'divisionName'
                ? null
                : isMobile && (
                    <div className="mt-2" style={{ marginRight: '10px' }}>
                      {Cart?.customerInfo?.currentUser?.roleNo !== 'W1' && (
                        <PRHButton
                          // disabled={Cart.shipTo < 0}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (
                              addMultipleTitles &&
                              addMultipleISBNs &&
                              addMultipleISBNs > 0
                            ) {
                              setMultiAddISBNsCancelOpen(true);
                            } else {
                              Cart.shipTo < 0
                                ? setisLocationWarningOpen(true)
                                : toggleMultiAdd();
                            }
                          }}
                        >
                          {addMultipleTitles === true
                            ? 'Cancel Multi-Add'
                            : 'Multi-add Mode'}
                        </PRHButton>
                      )}
                    </div>
                  )} */}
            </div>
          </div>
          <NotificationBanner
            catalogs={Cart.activeCatalogs}
            setShowNotifications={Cart.setShowNotifications}
            catalogAvailabilityDate={Cart.catalogAvailabilityDate}
            currentMonthCatalogAvailabilityDate={
              Cart.currentMonthCatalogAvailabilityDate
            }
            estimateDueDate={Cart.estimateDueDate}
            showNotifications={Cart.showNotifications}
            currentPage='catalog'
          />
          <NotificationBanners
            listenToPropertyForChange={Cart.shipTo}
            carts={Cart.estimateCarts}
          />
        </Container>
      </div>

      <>
        <Container
          className={`position-relative ${
            !isMobile ? "cat-data" : "cat-data-mobile"
          }`}
          fluid={isMobile}
        >
          <Grid className={`${!isMobile ? "cat-data-mobile" : ""}`}>
            <Grid.Row className={`search-container`}>
              {isMobile && (
                <Grid.Column
                  mobile={16}
                  tablet={5}
                  computer={4}
                  largeScreen={3}
                  widescreen={3}
                  className={`p-0 ${
                    isMobile ? "mobile-catalog-filters p-3" : ""
                  } ${showFilters ? "filters-show" : "filters-hide"}`}
                >
                  {isMobile && showFilters && (
                    <div className='mobile-catalog-filters-background' />
                  )}
                  {numOfResults > 0 ? (
                    <CustomFilters
                      filters={filters}
                      updateResults={updateResults}
                      selectedFilterCount={filtersCount}
                      clearResultFilters={clearResultFilters}
                      numOfResults={numOfResults}
                      variantFilter={variantFilter}
                      closeFiltersMenu={() => setShowFilters(false)}
                      inCartQuantity={inCartFlag}
                      pageType='catalog'
                    />
                  ) : (
                    ""
                  )}
                </Grid.Column>
              )}
              {!isMobile && (
                <Grid.Column
                  mobile={16}
                  tablet={5}
                  computer={4}
                  largeScreen={3}
                  widescreen={3}
                  className='p-0'
                >
                  {numOfResults > 0 ? (
                    <CustomFilters
                      filters={filters}
                      updateResults={updateResults}
                      selectedFilterCount={filtersCount}
                      clearResultFilters={clearResultFilters}
                      numOfResults={numOfResults}
                      variantFilter={variantFilter}
                      closeFiltersMenu={() => setShowFilters(false)}
                      inCartQuantity={inCartFlag}
                      pageType='catalog'
                    />
                  ) : (
                    ""
                  )}
                </Grid.Column>
              )}
              <Grid.Column
                mobile={16}
                tablet={11}
                computer={12}
                largeScreen={13}
                widescreen={13}
                className='p-0 catalog-rows'
              >
                <div id='cat-rows'>
                  {filteredResults.length > 0 ? (
                    [isMobile ? renderMobileTabsOrRows() : AccordionPanels()]
                  ) : (
                    <div className='no-rows'>
                      No titles fulfill the selected criteria
                    </div>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {!isMobile && <BackToTopButton />}
        </Container>
        {addMultipleTitles === true ? (
          <div className='add-all-footer-wrapper w-100'>
            <div className='add-all-footer ui container'>
              <div className='d-flex align-items-center'>
                <PRHButton
                  className='mr-2'
                  onClick={() => {
                    if (addMultipleISBNs > 0) {
                      setMultiAddISBNsCancelOpen(true);
                    } else {
                      cancelMultiAdd();
                    }
                  }}
                >
                  Cancel
                </PRHButton>
                <PRHButton
                  disabled={Object.keys(addMultipleISBNQtyObject).length === 0}
                  onClick={() => clearMultiAddQty()}
                >
                  Clear Qty
                </PRHButton>
              </div>
              <div className='d-flex align-items-center'>
                <span className='mr-1 fort-book fs-13px'>
                  Selected items:{" "}
                  <span className='fort-bold'>{addMultipleISBNs}</span>
                </span>{" "}
                <Divider horizontal />
                <span className='mr-4 ml-1 fort-book fs13px'>
                  Total item qty:{" "}
                  <span className='fort-bold'>{addMultipleTotalQty}</span>
                </span>
                <PRHButton
                  disabled={Object.keys(addMultipleISBNQtyObject).length === 0}
                  borderColorOverride='blue'
                  textColorOverride='white'
                  textColorHoverOverride='blue'
                  backgroundColorOverride='blue'
                  backgroundHoverColorOverride='white'
                  iconColorOverride={
                    Object.keys(addMultipleISBNQtyObject).length === 0
                      ? "grey"
                      : "white"
                  }
                  iconColorHoverOverride='blue'
                  bold={true}
                  onClick={() => {
                    let confirmed = false;
                    Object.keys(addMultipleISBNQtyObject).forEach((k) => {
                      if (
                        addMultipleISBNQtyObject[k].confirmationStatus ===
                        "CONFIRMED"
                      )
                        confirmed = true;
                    });
                    if (confirmed) {
                      setModifyConfirmedCartModalOpen(true);
                    } else {
                      addAllToCart();
                    }
                  }}
                >
                  Add all to cart
                </PRHButton>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>

      {isMobile && <BackToTopButtonMobile />}
    </PageStandard>
  );
}

Catalog.propTypes = {};
