import { useState, useEffect } from "react";
import { Header, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./MultiAddISBNs.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import useApp from "../hc/useApp";
import LazyImage from "../../utils/LazyImage";

const MultiAddISBNsWarning = (props) => {
  const { data, open, setOpen, callback, cancelCallback } = props;
  let realTrigger = (
    <div className='d-flex flex-column fort-book-italic fs-13px lh-18 hover-underline text-blue cursor-pointer align-self-end'>
      <div
        onClick={(e) => {
          setOpen(true);
        }}
        className='v-details'
      ></div>
    </div>
  );

  const resetState = () => {
    setOpen(false);
  };

  const okAction = {
    label: "Yes, continue",
    // color: 'blue',
    size: "large",
    // backgroundColorOverride: 'blue',
    // backgroundHoverColorOverride: 'light-blue',
    // textColorOverride: 'white',
    // borderHoverColorOverride: 'light-blue',
    // iconColorOverride: 'white',
    // iconColorHoverOverride: 'light-blue',
    // icon: 'checkmark',
    bold: true,
    preAction: () => {
      if (callback) callback(data?.variantsNotMetTitles);
      return { proceed: true };
    },
    action: () => {
      resetState();
      return { proceed: true };
    },
  };

  const cancelAction = {
    label: "No, go back",
    color: "blue",
    size: "large",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "light-blue",
    textColorOverride: "white",
    borderHoverColorOverride: "light-blue",
    iconColorOverride: "white",
    iconColorHoverOverride: "light-blue",
    bold: true,
    preAction: () => {
      if (cancelCallback) cancelCallback(data?.variantsNotMetTitles);
      return { proceed: true };
    },
    action: () => {
      resetState();
      return { proceed: true };
    },
  };

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      modalClassName='vri-modal vornm-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            Variant Ordering Requirements Not Met
          </Header>
        ),
        actions: [okAction, cancelAction],
      }}
      options={{
        trigger: realTrigger,
      }}
    >
      <div className='ar-box'>
        <>
          <div className='ml-4 mr-4 mb-2'>
            <div className=''>
              You have entered quantities for the following variant titles but
              have not met their ordering requirements.
            </div>
          </div>
          {data?.variantsNotMetTitles &&
            _.sortBy(data.variantsNotMetTitles, function (i) {
              return i.title;
            }).map((title, i) => (
              <div key={i} className='ml-4 mr-4 '>
                {i === 0 && <Divider className='mt-0 mb-2' />}
                <div className='d-flex flex-row fort-book fs-12px lh-14px'>
                  <div className='d-flex mr-2'>
                    <LazyImage
                      className='vri-title-cover'
                      boxShadow={true}
                      src={`https://images.penguinrandomhouse.com/cover/${title.isbn}`}
                    ></LazyImage>
                  </div>
                  <div className='d-flex flex-column'>
                    <div className='fort-bold'>{title.title}</div>
                    <div>{title.isbn}</div>
                  </div>
                  <div className='d-flex ml-auto flex-column'>
                    <div>Qty entered: {title.quantityEntered}</div>
                    <div>Not eligible: {title.notEligible}</div>
                  </div>
                </div>
                <Divider className='mt-2 mb-2' />
              </div>
            ))}

          <div className='ml-4 mr-4 mb-1 mt-1'>
            The ineligible quantities won't be added to your carts. Eligible
            quantities will be added. Do you want to continue?
          </div>
        </>
      </div>
    </DialogModal>
  );
};

MultiAddISBNsWarning.propTypes = {};

MultiAddISBNsWarning.defaultProps = {};

export default MultiAddISBNsWarning;
