import { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from 'react-phone-number-input/input';
import { Icon, Popup } from 'semantic-ui-react';
import {
  Divider,
  PRHError,
  PRHInput,
} from '../../../components/elements/elements';
import { PRHContainer } from '../../../shared-react-components/elements/elements';
import { isEmpty } from '../../../utils/utilities';
import { TOOLTIPS } from '../../../utils/const';

// const PRHInput = React.forwardRef((props, ref) => (
//   <Input
//     ref={ref}
//     {...props}
//     style={{ width: '75%' }}
//     // validators={['isPhoneNumber']}
//     // errorMessages={['The phone format is (###) ###-####']}
//   ></Input>
// ));

export default class CCShippingInstructions extends Component {
  constructor(props) {
    super(props);
    this.state = { isValidateEmail: false };
  }

  validateEmail = (emailAddress) => {
    const re = /^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)(\.\w{2,3})$/;
    return re.test(emailAddress);
  };

  render() {
    const {
      purchaseorder,
      invoiceText,
      phone,
      country,
      billOfLading,
      cartonMarking,
      initialPurchaseOrder,
      emailAddress,
      setStateValue,
      ccSapAccountNo,
    } = this.props;

    const regex = /[^a-zA-Z0-9 ]/g;

    return (
      <PRHContainer className="cp-left content-block-border w-100 m-0 pl-0 pr-0 mr-6 mb-3 pt-3">
        <div className="d-flex ml-3 mr-3 align-items-center mb-1">
          <div className="d-flex  fort-bold mb-2 fs-2 align-items-center">
            <span className="cp-section-number fs-3">2</span>
            <span className="fs-2 text-uppercase">Shipping Instructions</span>
          </div>
        </div>
        <Divider tight />
        <div className="d-flex flex-row mt-2 ml-3 mr-3">
          <div className="d-flex flex-column w-100">
            <div className="d-flex flex-row ccs-addr-line">
              <div className="w-50">
                <PRHInput
                  wrapperClassName="mb-1"
                  className="w-75"
                  name="purchaseorder"
                  type="text"
                  onChange={(e) =>
                    this.props.setStateValue(
                      'purchaseOrderNumber',
                      e.target.value
                    )
                  }
                  icon={
                    <Icon
                      as={() => (
                        <div
                          className="revert-text-container"
                          onClick={() =>
                            this.props.setStateValue(
                              'purchaseOrderNumber',
                              initialPurchaseOrder
                            )
                          }
                        >
                          {initialPurchaseOrder !== purchaseorder
                            ? 'Revert'
                            : ''}
                        </div>
                      )}
                    />
                  }
                  placeholder="Enter purchase order"
                  isError={isEmpty(purchaseorder) ? true : false}
                  errorMessage="Purchase order is required"
                  // validators={['required']}
                  // errorMessages={['this field is required']}
                  value={purchaseorder}
                  label={
                    <label className={'fort-medium fs-2 mb-2'}>
                      Purchase Order{'  '}
                      <Popup
                        className="cp-help-tooltip"
                        content={TOOLTIPS['checkoutPurchaseOrder']}
                        on="click"
                        position="top center"
                        offset={[0, 5]}
                        trigger={
                          <Icon
                            name="question circle outline"
                            className="sb_question_icon"
                          />
                        }
                      />
                    </label>
                  }
                />
              </div>

              <div className="w-50">
                <PRHInput
                  wrapperClassName="mb-1"
                  className="w-75"
                  name="invoiceText"
                  type="text"
                  onChange={(e) =>
                    this.props.setValue('invoiceText', e.target.value)
                  }
                  placeholder="Enter invoice description"
                  // isError={!purchaseorder ? true : false}
                  // errorMessage="Purchuse order is required"
                  // validators={['required']}
                  // errorMessages={['this field is required']}
                  value={invoiceText}
                  label={
                    <label className={'fort-medium fs-2 mb-2'}>
                      Invoice Description{'  '}{' '}
                      <span className="od-optional fort-book-italic">
                        (Optional)
                      </span>
                      {'  '}
                      <Popup
                        className="cp-help-tooltip"
                        content={TOOLTIPS['checkoutInvoiceDescription']}
                        on="click"
                        position="top center"
                        offset={[0, 5]}
                        trigger={
                          <Icon
                            name="question circle outline"
                            className="sb_question_icon"
                          />
                        }
                      />
                    </label>
                  }
                />
              </div>
            </div>
            <div className="d-flex flex-row ccs-addr-line">
              <div className="w-50">
                <div className="mb-1 fort-medium">
                  Phone Number{' '}
                  <span className="od-optional fort-book-italic">
                    {country === 'US' ? '(Optional)' : '(Required)'}
                  </span>
                </div>
                <div className="ui input d-block">
                  <PhoneInput
                    placeholder="(###) ###-####"
                    // defaultCountry="US"
                    // style={{ width: 'unset' }}
                    defaultCountry="US"
                    value={phone}
                    onChange={(e) => {
                      this.props.setValue('phone', e);
                    }}
                    // error={
                    //   phone
                    //     ? isPossiblePhoneNumber(phone)
                    //       ? 'good undefined'
                    //       : 'Invalid phone number'
                    //     : 'Phone number required'
                    // }
                    //&& !isPossiblePhoneNumber(phone)
                  />
                </div>

                <PRHError
                  isError={
                    (phone && phone === '') ||
                    (phone && phone.length > 16) ||
                    (!phone && country !== 'US')
                  }
                  errorMessage="Please enter a valid phone number"
                />
              </div>
              <div className="w-50">
                <PRHInput
                  wrapperClassName="mb-1"
                  className="w-75"
                  name="emailAddress"
                  type="text"
                  onChange={(e) => {
                    this.props.setValue('emailAddress', e.target.value);
                    // this.setState({
                    //   isValidateEmail: this.props.validateEmail(e.target.value),
                    // });
                  }}
                  placeholder="Enter email address"
                  // isError={!purchaseorder ? true : false}
                  // errorMessage="Purchuse order is required"
                  // validators={['required']}
                  // errorMessages={['this field is required']}
                  value={emailAddress}
                  label={
                    <label className={'fort-medium fs-2 mb-1'}>
                      Email Address{'  '}{' '}
                      <span className="od-optional fort-book-italic">
                        {country === 'US' ? '(Optional)' : '(Required)'}
                      </span>
                    </label>
                  }
                />
                <PRHError
                  isError={
                    (emailAddress && emailAddress === '') ||
                    // (emailAddress && emailAddress.length > 132) ||
                    (!emailAddress && country !== 'US') ||
                    (emailAddress && this.validateEmail(emailAddress) === false)
                  }
                  errorMessage="Please enter a valid email address"
                />
              </div>
            </div>
            <div className="d-flex flex-row ccs-addr-line">
              <div className="w-50">
                <PRHInput
                  wrapperClassName="mb-1"
                  className="w-75"
                  name="billOfLading"
                  type="text"
                  onChange={(e) =>
                    this.props.setValue('billOfLading', e.target.value)
                  }
                  placeholder="Enter bill of lading"
                  // isError={!purchaseorder ? true : false}
                  // errorMessage="Purchuse order is required"
                  // validators={['required']}
                  // errorMessages={['this field is required']}
                  value={billOfLading}
                  label={
                    <label className={'fort-medium fs-2 mb-2'}>
                      Bill of Lading{'  '}
                      <span className="od-optional fort-book-italic">
                        (Optional)
                      </span>{' '}
                      <Popup
                        className="cp-help-tooltip"
                        content={TOOLTIPS['billOfLading']}
                        on="click"
                        position="top center"
                        offset={[0, 5]}
                        trigger={
                          <Icon
                            name="question circle outline"
                            className="sb_question_icon"
                          />
                        }
                      />
                    </label>
                  }
                />
              </div>
              <div className="w-50">
                <PRHInput
                  className="w-75"
                  name="cartonMarking"
                  type="text"
                  onChange={(e) =>
                    this.props.setValue('cartonMarking', e.target.value)
                  }
                  placeholder="Enter carton marking"
                  value={cartonMarking}
                  label={
                    <label className={'fort-medium fs-2 mb-2'}>
                      Carton Marking{' '}
                      <span className="od-optional fort-book-italic">
                        (Optional)
                      </span>{' '}
                      <Popup
                        className="cp-help-tooltip"
                        content={TOOLTIPS['cartonMarking']}
                        on="click"
                        position="top center"
                        offset={[0, 5]}
                        trigger={
                          <Icon
                            name="question circle outline"
                            className="sb_question_icon"
                          />
                        }
                      />
                    </label>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </PRHContainer>
    );
  }
}
