import moment from "moment-timezone";
import { DateTime, Settings } from "luxon";

// DateTime.local().setZone('America/New York');
// Settings.defaultZoneName = "America/New_York";
// Settings.defaultZone("America/New_York");
Settings.defaultZone = "America/New_York";

export const expiredFocOffset = 0;

export const formatDate = (date, short = true) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  if (!date) return "";
  let workDate = date;

  if (date.constructor === String) {
    workDate = new Date(date);
    workDate.setDate(workDate.getDate() + 1);
  }
  // debugger;
  const day = workDate.getDate();
  const monthIndex = workDate.getMonth();
  const year = workDate.getFullYear();
  // console.log(date, workDate, workDate.getDate()); console.log(day+ ' : '+ monthIndex);

  if (short) {
    return `${monthNames[monthIndex]} ${year}`;
  }
  return `${monthNames[monthIndex].substr(0, 3)} ${day}, ${year}`;
};

export const formatDateAsMonth = (date) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (!date) return "";
  let workDate = date;

  if (date.constructor === String) {
    workDate = new Date(date);
    workDate.setDate(workDate.getDate() + 1);
  }
  const monthIndex = workDate.getMonth();
  return `${monthNames[monthIndex]}`;
};

export const formatDateAsNextMonth = (date) => {
  // Calculate the first day of the next month
  if (!date) return "";
  let workDate = date;
  if (date.constructor === String) {
    workDate = new Date(date);
  }
  let nextMonth = new Date(workDate.getFullYear(), workDate.getMonth() + 1, 1);

  // Array of month names, you can localize this as necessary
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Construct the formatted date string
  const day = nextMonth.getDate(); // This will always be '1'
  const monthIndex = nextMonth.getMonth();
  const year = nextMonth.getFullYear();

  return monthNames[monthIndex] + " " + day + ", " + year;
};

export const formatDateMMDDYYYY = (d, del = "-") => {
  // const date = moment(d).toDate();
  // console.log(moment(d),'********************',moment.utc(d).format("MM/DD/YYYY"), '********************', date);
  // const date = new Date(d);
  // const day = date.getDate(); console.log(day);
  // const monthIndex = date.getMonth() + 1;
  // const year = date.getFullYear();
  //   const day = moment.utc(d).format("DD"); console.log(day)
  // const month = moment.utc(d).format("MM");
  // const year = moment.utc(d).format("YYYY");
  if (!d) return "";

  // const date = moment.utc(d).format(`MM${del}DD${del}YYYY`);
  const date = DateTime.fromISO(d).toFormat(`LL${del}dd${del}yyyy`);
  return date;
  // return `${month}${del}${day}${del}${year}`;
  // return `${pad(monthIndex)}${del}${pad(day)}${del}${year}`;
};

export const formatFirstDateYY = (d, d2, del = "-") => {
  if (!d) return "";
  const dateYY1 = DateTime.fromISO(d).toFormat(`yyyy`);
  const dateYY2 = DateTime.fromISO(d2).toFormat(`yyyy`);
  if (dateYY1 === dateYY2) {
    return DateTime.fromISO(d).toFormat(`MMMM dd`);
  }
  return DateTime.fromISO(d).toFormat(`LL${del}dd${del}yyyy`);
};

export const formatDateLong = (d, del = "-") => {
  if (!d) return "";

  const date = DateTime.fromISO(d).toFormat(`MMMM dd, yyyy`);
  return date;
  // return `${month}${del}${day}${del}${year}`;
  // return `${pad(monthIndex)}${del}${pad(day)}${del}${year}`;
};

export const formatStartEndDate = (sd, ed) => {
  if (!sd || !ed) return "";

  const startDate = DateTime.fromISO(sd).toFormat(`MMMM d`);
  const endDate = DateTime.fromISO(ed).toFormat(`d, yyyy`);
  return `${startDate} - ${endDate}`;
};

export const formatDateYYYYMMDD = (d, del = "-") => {
  // const date = moment.utc(d).format(`YYYY${del}MM${del}DD`);
  // console.log(
  //   'd',
  //   d,
  // );

  // let dt = DateTime.fromISO(new Date(d).toISOString());
  // if (!DateTime.isDateTime(dt)) {
  //   console.log('invalid1', DateTime.isDateTime(dt));
  //   dt = DateTime.fromRFC2822(d);
  //   if (!DateTime.isDateTime(dt)) {
  //     console.log('invalid2', DateTime.isDateTime(dt));
  //     dt = DateTime.fromJSDate(d);
  //   }
  // }

  // DateTime.fromJSDate(d);
  // DateTime.fromRFC2822(d);
  // const date = DateTime.fromISO(d).toFormat(`yyyy${del}LL${del}dd`);
  const date = DateTime.fromISO(new Date(d).toISOString()).toFormat(
    `yyyy${del}LL${del}dd`
  );

  return date;
  // return `${month}${del}${day}${del}${year}`;
  // return `${pad(monthIndex)}${del}${pad(day)}${del}${year}`;
};

export const formatDateMonthYear = (d) => {
  // const date = moment(d).toDate();
  // console.log(moment(d),'********************',moment.utc(d).format("MM/DD/YYYY"), '********************', date);
  // const date = new Date(d);
  // const day = date.getDate(); console.log(day);
  // const monthIndex = date.getMonth() + 1;
  // const year = date.getFullYear();
  //   const day = moment.utc(d).format("DD"); console.log(day)
  // const month = moment.utc(d).format("MM");
  // const year = moment.utc(d).format("YYYY");
  // const date = moment.utc(d).format(`MMMM YYYY`);
  const date = DateTime.fromISO(d).toFormat(`LLLL yyyy`);
  return date;
  // return `${month}${del}${day}${del}${year}`;
  // return `${pad(monthIndex)}${del}${pad(day)}${del}${year}`;
};

export const formatDateMMDDYYYYHHMM = (d, del = "/") => {
  // console.log('formatDateMMDDYYYYHHMM', d);
  const date = moment(d).toDate();
  // const date = DateTime.now();
  // const date = new Date(d);
  const day = date.getDate();
  const monthIndex = date.getMonth() + 1;
  const year = date.getFullYear();
  let hour = date.getHours();
  const min = date.getMinutes();
  let ampm = "am";
  if (hour > 12) {
    hour -= 12;
    ampm = "pm";
  }

  return `${pad(monthIndex)}${del}${pad(day)}${del}${year}, ${hour}:${pad(
    min
  )}${ampm}`;
};

export const formatDateMMMMYYYY = (d) => {
  // console.log('formatDateMMDDYYYYHHMM', d);
  const date = moment(d).toDate();
  // const date = new Date(d);

  return date.format("MMMM YYYY");
};

export const showDateByMonth = (d) => {
  // console.log('formatDateMMDDYYYYHHMM', d);
  const date = moment(d).toDate();
  // const date = new Date(d);
  let startOfMonth;
  let endOfMonth;
  const monthLength = moment().format("MMMM").length;
  if (monthLength <= 4) {
    startOfMonth = moment().clone().startOf("month").format("MMMM D");
    endOfMonth = moment().clone().endOf("month").format("MMMM D, YYYY");
  } else {
    startOfMonth = moment().clone().startOf("month").format("MMM D");
    endOfMonth = moment().clone().endOf("month").format("MMM D, YYYY");
  }
  return startOfMonth + " - " + endOfMonth;
};

export const getStartOfCurrentMonth = (d) => {
  const startOfMonth = moment().clone().startOf("month").format("YYYY-MM-DD");
  return startOfMonth;
};

export const showDateMMMDYYYY = (d) => {
  return moment(d).format("MMM D, YYYY");
};

export const showDateMMMMDYYYY = (d) => {
  return moment(d).format("MMMM D, YYYY");
};

export const showDateFullIfFour = (d) => {
  if ((moment(d).format("MMMM").length = 4)) {
    return moment(d).format("MMMM D, YYYY");
  } else {
    return moment(d).format("MMM D, YYYY");
  }
};

export const showTimeHHMMa = (d) => {
  const time_part_array = d.split(":");
  let ampm = "AM";
  if (time_part_array[0] >= 12) {
    ampm = "PM";
  }
  if (time_part_array[0] > 12) {
    time_part_array[0] = time_part_array[0] - 12;
  }
  const formatted_time =
    time_part_array[0] +
    ":" +
    time_part_array[1] +
    ":" +
    time_part_array[2] +
    " " +
    ampm;
  console.log("formatted_time", formatted_time);
  return formatted_time;
  //return moment(d).format('hh:mm:ss a')
};

export const showTimeHHMM = (d) => {
  const time_part_array = d.split(":");
  let ampm = "AM";
  if (time_part_array[0] >= 12) {
    ampm = "PM";
  }
  if (time_part_array[0] > 12) {
    time_part_array[0] = time_part_array[0] - 12;
  }
  const formatted_time =
    time_part_array[0] + ":" + time_part_array[1] + " " + ampm;
  return formatted_time;
  //return moment(d).format('hh:mm:ss a')
};

export const formatDateSlashes = (d) => {
  const date = moment(d).toDate();
  // const date = new Date(d);

  return moment(d).format("MM/DD/YYYY");
};

export const formatDateDashes = (d) => {
  const date = moment(d).toDate();
  // const date = new Date(d);

  return moment(d).format("MM-DD-YYYY");
};

export const formatDateMMDDYYYYHHMMSS = (d, del = "/") => {
  const date = moment(d).toDate();
  // const date = new Date(d);
  const day = date.getDate();
  const monthIndex = date.getMonth() + 1;
  const year = date.getFullYear();
  let hour = date.getHours();
  const min = date.getMinutes();
  const sec = date.getSeconds();
  let ampm = "am";
  if (hour > 12) {
    hour -= 12;
    ampm = "pm";
  }

  return `${pad(monthIndex)}${del}${pad(day)}${del}${year}, ${hour}:${pad(
    min
  )}:${pad(sec)}${ampm}`;
};

export const dateDifference = (dt, d = "days") => {
  // let fd = moment(dt);
  // let cd = moment(new Date().toISOString().slice(0, 10));
  // moment.tz.add('America/New_York|EST EDT|50 40|0101|1Lz50 1zb0 Op0');

  // let fd = moment(dt).utcOffset(-4); //.tz('America/New_York');
  // let cd = moment(new Date().toISOString().slice(0, 10)).utcOffset(-4);
  // return fd.diff(cd, d);

  let fd = DateTime.fromISO(dt).set({ hour: 0, minute: 0 });
  let cd = DateTime.now().set({ hour: 0, minute: 0 });

  // console.log('dateDifference', fd, cd, fd.diff(cd, d));
  return Math.round(fd.diff(cd, d).toObject().days);
};

export const dateDifferenceBetween = (dt1, dt2, d = "days") => {
  // let fd = moment(dt1).utcOffset(-4); //.tz('America/New_York');
  // let cd = moment(dt2).utcOffset(-4); //.tz('America/New_York');

  // return fd.diff(cd, d);
  let fd = DateTime.fromISO(dt1).set({ hour: 0, minute: 0 });
  let cd = DateTime.fromISO(dt2).set({ hour: 0, minute: 0 });
  // console.log('dateDifferenceBetween', fd, cd, fd.diff(cd, d));
  return fd.diff(cd, d).toObject().days;
};

export const dateIsBefore = (dt1) => {
  let fd = DateTime.fromISO(dt1).set({ hour: 0, minute: 0 });
  let cd = DateTime.now().set({ hour: 0, minute: 0 });
  let fy = fd.toFormat("yyyy");
  let fm = fd.toFormat("L");
  let cy = cd.toFormat("yyyy");
  let cm = cd.toFormat("L");
  if (parseInt(fy) > parseInt(cy)) return false;
  if (parseInt(fy) === parseInt(cy) && parseInt(fm) >= parseInt(cm))
    return false;
  return true;
  return cd > fd;
};

export const dateIsBetween = (dt1, dt2) => {
  // let fd = moment(dt1);
  // let sd = moment(dt2);
  // let cd = moment(new Date().toISOString().slice(0, 10));
  // return cd.isBetween(fd, sd);
  let fd = DateTime.fromISO(dt1);
  let sd = DateTime.fromISO(dt2);
  let cd = DateTime.now().set({ hour: 0, minute: 0 });
  let cy = cd.toFormat("yyyy");
  let cm = cd.toFormat("L");
  cd.set({ hour: 0, minute: 0 });
  // console.log('dateDifferenceBetween', fd, sd, cd);
  if (cd <= dt2 && cd >= dt1) {
    return true;
  } else {
    return false;
  }
  // return cd.isbetween(fd, sd);
};

export const convertDateToDay = (dt) => {
  const date = moment(dt).toDate();
  // console.log('date', date);
  const dayOfWeek = date.getDay();
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayName = days[dayOfWeek];
  const tomorrow = moment().toDate();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const isTomorrow = date.toDateString() === tomorrow.toDateString();
  const label = isTomorrow ? "Tomorrow" : dayName;
  return label;
};

export const formatDateMonDayYear = (dateString) => {
  const options = { month: "short", day: "numeric", year: "numeric" };
  const date = moment(dateString).toDate();
  return date.toLocaleDateString("en-US", options);
};

export const formatDateMonDay = (dateString) => {
  const options = { month: "short", day: "numeric" };
  const date = moment(dateString).toDate();
  return date.toLocaleDateString("en-US", options);
};

export const getUpcoming14 = (dt) => {};

export const getUpcoming7 = (dt) => {};

export const isReturnable = (returnAble, dt1, dt2) => {
  // let fd = moment(dt1);
  // let sd = moment(dt2);
  // let cd = moment(new Date().toISOString().slice(0, 10));
  // return cd.isBetween(fd, sd);
  let fd = DateTime.fromISO(dt1);
  let sd = DateTime.fromISO(dt2);
  let cd = DateTime.now().set({ hour: 0, minute: 0 });
  let cy = cd.toFormat("yyyy");
  let cm = cd.toFormat("L");
  cd.set({ hour: 0, minute: 0 });
  // console.log('dateDifferenceBetween', fd, sd, cd);
  let result = false;
  if (returnAble === "Y" && cd <= sd && cd >= fd) {
    result = true;
  }
  return result;
  // return cd.isbetween(fd, sd);
};

export const testMe = (d) => {
  if (d === "Y") {
    return true;
  } else {
    return false;
  }
};

export const isCurrentYear = (d) => {
  let cd = DateTime.now();
  let cy = moment(cd).format("YYYY");
  return cy === d;
};

export const pad = (n) => {
  return n < 10 ? `0${n}` : n;
};
export const numberWithCommas = (x) => {
  if (x === undefined || x === null || x === "") {
    return "";
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberAsCurrency = (x) => {
  if (x === undefined || x === null || x === "") {
    return "";
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const contributorTranslation = {
  Author: "Written by",
  Illustrator: "Illustrated by",
};

export const coverImageOverride = (src) => {
  if (process.env.REACT_APP_COVER_IMAGE_OVERRIDE) {
    return src.replace(`${process.env.REACT_APP_COVER_IMAGE_OVERRIDE}\.`, "");
  }
  return src;
};

export const renderTitle = (title) => {
  if (title.length > 95) {
    return `${title.substr(0, title.slice(0, 95).lastIndexOf(" "))} . . .`;
  }
  return title;
};

export const isMyhouse2 = (history) => {
  if (history.location.pathname.startsWith(process.env.REACT_APP_BASE_PATH)) {
    return true;
  } else {
    return false;
  }
};

export const isISBN = (str) => {
  // console.log('string', str);
  let ss = new RegExp(/^(\d{13})$/, "ig");

  // console.log('search is', str.search(ss));
  if (str.search(ss) >= 0) {
    return true;
  } else {
    return false;
  }
};

export const formatISBN = (isbn) => {
  if (isISBN(isbn)) {
    const fisbn =
      isbn.substr(0, 3) + "-" + isbn.substr(3, 8) + "-" + isbn.substr(12, 1);
    return fisbn;
  } else {
    return isbn;
  }
};

export const appVersionNumber = { version: "0.1.0" };

export const currentStore = "myhouse2-v4";

export const flattenObject = function (ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object") {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

export const separateShipping = (array, status, icon) => {
  const tracking = array
    .map((item) =>
      item?.deliveries[0]?.shipping?.length > 0
        ? item?.deliveries[0]?.shipping.map((shipItem) => {
            return {
              ...item,
              deliveries: {
                ...item?.deliveries[0],
                shipping: shipItem,
              },
            };
          })
        : item?.status === "3" || ("6" && item?.deliveries?.length === 0)
        ? {
            ...item,
            deliveries: {
              carrier: null,
              dateShipped: null,
              invoiceDate: null,
              invoiceNo: "",
              shipping: { trackingCode: "" },
              traid: "",
            },
          }
        : item?.deliveries?.map((del) => {
            return {
              ...item,
              deliveries: {
                ...del,
                shipping: { trackingCode: "" },
              },
            };
          })
    )
    .flat();
  const uniqueInvoices = [
    ...new Set(tracking.map((b) => b?.deliveries?.invoiceNo)),
  ];
  const uniqueTracking = [
    ...new Set(tracking.map((b) => b?.deliveries?.shipping?.trackingCode)),
  ];

  const combined = uniqueTracking
    .map((trackId) => {
      return uniqueInvoices.map((inv) => {
        return tracking.filter(
          (b) =>
            b?.deliveries?.invoiceNo === inv &&
            b?.deliveries?.shipping?.trackingCode === trackId
        );
      });
    })
    .flat()
    .filter((a) => a.length > 0);

  const all = combined.map((com) => {
    return {
      status,
      items: com,
      icon,
      iconColor: "orange",
      trackingId: com[0]?.deliveries?.shipping?.trackingCode,
      invoice: com[0]?.deliveries?.invoiceNo,
      reqDeliveryDate: com[0]?.reqDeliveryDate,
      shippingFrom: com[0]?.warehouse,
      shippedOn: com[0]?.deliveries?.dateShipped,
      trackingUrl: com[0]?.deliveries?.shipping?.trackingUrl,
    };
  });

  all.sort((prev, next) => {
    if (prev.invoice > next.invoice) {
      return 1;
    } else if (prev.invoice < next.invoice) {
      return -1;
    } else {
      return 0;
    }
  });

  return all;
};

export const userInitialsFullName = (fullName) => {
  let i = "";
  const names = fullName.split(" ");
  if (names[0]) {
    i += names[0].charAt(0);
  }
  if (names[0]) {
    i += names[names.length - 1].charAt(0);
  }
  return i;
};

export const isEmpty = (str) => {
  return !str || 0 === str.length || /^\s*$/.test(str);
};

export const focDateDifference = (focDate) => {
  let nd = dateDifference(focDate);

  return nd;
};

export const isFocCart = (foc, focStatus = "ok") => {
  if (isGeneralCart(foc)) return false;
  if (
    (focStatus === "ok" || expiredFocOffset !== 0) &&
    foc &&
    !isFocCartExpired(foc)
  )
    return true;
  return false;
};

export const isFocCartExpired = (focDate) => {
  let nd = dateDifference(focDate);

  if (nd < expiredFocOffset) return true;
  return false;
};

export const isPastOnSaleDate = (onSaleDate) => {
  let nd = dateDifference(onSaleDate);

  if (nd < 0) return true;
  return false;
};

export const isGeneralCart = (focDate) => {
  if (focDate === "2099-12-31") return true;
  if (!!!focDate) return true;
  return false;
};
export const isCreditCardExpired = (
  expirationMonth,
  expirationYear,
  foc = false
) => {
  let workFoc = foc;
  if (isGeneralCart(foc)) {
    workFoc = false;
  }
  let cd = DateTime.now().set({ hour: 0, minute: 0 });
  let fd = false;
  let fy = false;
  let fm = false;

  if (workFoc) {
    fd = DateTime.fromISO(workFoc).set({ hour: 0, minute: 0 });
    fy = fd.toFormat("yyyy");
    fm = fd.toFormat("L");
  }
  let cy = cd.toFormat("yyyy");
  let cm = cd.toFormat("L");
  if (!!!workFoc) {
    if (parseInt(expirationYear) > parseInt(cy)) return false;
    if (
      parseInt(expirationYear) === parseInt(cy) &&
      parseInt(expirationMonth) >= parseInt(cm)
    )
      return false;
    return true;
  } else {
    console.log(
      "expire",
      parseInt(expirationYear),
      parseInt(expirationMonth),
      parseInt(fy),
      parseInt(fm)
    );
    if (parseInt(expirationYear) > parseInt(fy)) return false;
    if (
      parseInt(expirationYear) === parseInt(fy) &&
      parseInt(expirationMonth) >= parseInt(fm)
    )
      return false;
    // if (parseInt(expirationYear) > parseInt(cy)) return false;
    // if (expirationYear === cy && parseInt(expirationMonth) < parseInt(cm))
    //   return false;
    return true;
  }
};

export const willCreditCardExpire = (
  expirationMonth,
  expirationYear,
  foc = false
) => {
  let workFoc = foc;
  if (isGeneralCart(foc)) {
    workFoc = false;
  }
  let cd = DateTime.now().set({ hour: 0, minute: 0 });
  let fd = false;
  let fy = false;
  let fm = false;

  if (workFoc) {
    fd = DateTime.fromISO(workFoc).set({ hour: 0, minute: 0 });
    fy = fd.toFormat("yyyy");
    fm = fd.toFormat("L");
  }
  let cy = cd.toFormat("yyyy");
  let cm = cd.toFormat("L");
  if (!!!workFoc) {
    if (parseInt(expirationYear) > parseInt(cy)) return false;
    if (
      parseInt(expirationYear) === parseInt(cy) &&
      parseInt(expirationMonth) === parseInt(cm)
    )
      return true;
    return false;
  } else {
    console.log(
      "expire",
      parseInt(expirationYear),
      parseInt(expirationMonth),
      parseInt(fy),
      parseInt(fm)
    );
    if (parseInt(expirationYear) > parseInt(fy)) return false;
    if (
      parseInt(expirationYear) === parseInt(fy) &&
      parseInt(expirationMonth) === parseInt(fm)
    )
      return false;
    // if (parseInt(expirationYear) > parseInt(cy)) return false;
    // if (expirationYear === cy && parseInt(expirationMonth) < parseInt(cm))
    //   return false;
    return true;
  }
};

export const debounce = function (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const prhconsole = () => {
  // if (window.prhdebug) {
  console.log.apply(console, arguments);
  // }
};

export const getDefaultPO = () => {
  var currentdate = new Date();
  var defaultPurchaseOrder =
    "" +
    padNumber(currentdate.getMonth() + 1) +
    currentdate.getDate() +
    +currentdate.getFullYear() +
    +padNumber(currentdate.getHours()) +
    +padNumber(currentdate.getMinutes()) +
    "";
  return defaultPurchaseOrder;
};

export const addDecimal = (price) => {
  let fixedPrice = price;
  if (Number.isInteger(price)) {
    fixedPrice = price.toFixed(2);
  }
  return fixedPrice;
};

export const padNumber = (num, size) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
};
