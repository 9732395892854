import React from "react";
import { Container, Icon, Radio } from "semantic-ui-react";
import "./MobileOptionsMenu.scss";

export const MobileOptionsMenu = ({
  title,
  onClose,
  onOutsideMenuClick,
  menuItems,
  menuVisible,
  onOptionClick,
  radio = false,
  currentSort,
}) => {
  return (
    <>
      <div
        id="mobile-options-menu"
        className={`mobile-options-menu ${menuVisible ? "mobile" : ""}`}
      >
        <Container fluid>
          <div className="border-bottom-gray-300 py-1 px-1">
            <div className="d-flex flex-row justify-content-space-between align-items-center">
              <p className="lh-18 fs-12px fort-bold text-uppercase m-0">
                {title}
              </p>
              <div className="close-button-round" onClick={onClose}>
                <Icon name="close" color="orange" />
              </div>
            </div>
          </div>
          <div className="py-1 px-2 d-flex flex-column">
            {menuItems &&
              menuItems.map((item, index) =>
                radio ? (
                  <React.Fragment key={`${item.key}_${index}`}>
                    <Radio
                      className="my-2"
                      label={<label htmlFor={item.text}>{item.text}</label>}
                      name="menuOption"
                      id={item.text}
                      onChange={() => {
                        onOptionClick(item);
                        onClose();
                      }}
                      checked={
                        item.key === currentSort || item.value === currentSort
                      }
                    />
                  </React.Fragment>
                ) : (
                  <div
                    key={item.key}
                    className="my-2"
                    onClick={() => {
                      onOptionClick(item);
                      onClose();
                    }}
                  >
                    {item.text}
                  </div>
                )
              )}
          </div>
        </Container>
      </div>
      {menuVisible && (
        <div
          className="mobile-options-menu-background-overlay"
          onClick={onOutsideMenuClick}
        />
      )}
    </>
  );
};
