import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Grid } from "semantic-ui-react";
import {
  useGetFeaturedPostQuery,
  useGetPostQuery,
  useLazyGetFeaturedPostQuery,
  useLazyGetPostQuery,
} from "../../../api/bizcontentApi";
import withApp from "../../../components/hc/withApp";
import PRHButton from "../../../shared-react-components/elements/prhbutton";
import {
  getPrettyName,
  translatePost,
  translatePostTypeSlug,
} from "../../../utils/wordpressHelpers";
import LoadingContent from "./LoadingContent";

const WordPressHero = ({ postType, app, wordpressImageSize }) => {
  const [post, setPost] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const [performGetFeaturedPost, getFeaturedPostResult] =
    useLazyGetFeaturedPostQuery();
  const [performGetPost, getPostPostResult] = useLazyGetPostQuery();

  const history = useHistory();

  const reset = () => {
    setPost(null);
    // setLoaded(null);
  };

  useEffect(() => {
    reset();
    async function asyncCall() {
      const featuredPost = await performGetFeaturedPost(postType);
      // console.log('gfp',featuredPost);
      if (featuredPost?.data?.post_id) {
        const resp = await performGetPost({ id: featuredPost.data.post_id });
        //  console.log('getfeature',resp);
        // setPost(translatePost(featuredPost, wordpressImageSize));
        // setLoaded(true);
      }
    }
    asyncCall();
  }, [postType, wordpressImageSize]);

  // console.log("post", post);
  if (!post || !loaded || !post?.id) {
    return <></>;
  }

  // translatePost(featuredPost, wordpressImageSize);
  const { image, title, excerpt, type, id } = post;

  if (!loaded) {
    return <LoadingContent />;
  }
  return (
    <div className='wordpress-hero'>
      <Container className=' py-5 pr-5'>
        <Grid>
          <Grid.Column
            className='d-none d-computer-block keep-padding'
            width={1}
          ></Grid.Column>
          {!!image && (
            <Grid.Column
              mobile={16}
              tablet={7}
              computer={7}
              largeScreen={7}
              widescreen={6}
              className='keep-padding pr-0 '
              // added pr-0 which goes against the grid, but matches designs
            >
              <Link to={`/${translatePostTypeSlug(type)}/${id}`}>
                <img src={image} alt={title} />
              </Link>
            </Grid.Column>
          )}
          <Grid.Column
            mobile={16}
            tablet={image ? 8 : 15}
            computer={image ? 8 : 15}
            largeScreen={image ? 8 : 15}
            widescreen={image ? 9 : 15}
            className='keep-padding d-flex align-items-center'
          >
            <div>
              <p className='m-0 fort-bold fs-12px lh-15px lsp--02 text-gray-900 text-uppercase mb-1'>
                Featured
              </p>
              <p className='m-0 fort-bold fs-14px lh-19px lsp--015 text-gray-900 mb-1'>
                {title}
              </p>
              {!!excerpt && (
                <p
                  className='mt-0 mb-2 fs-14px fort-book lh-19px lsp--025'
                  dangerouslySetInnerHTML={{ __html: excerpt }}
                ></p>
              )}

              <PRHButton
                size='small'
                iconright
                backgroundColorOverride={"white"}
                icon={"arrow right"}
                iconHolderClass='ml-0'
                textClass={
                  "fs-12px lh-16px lsp--02 fort-book hover-no-underline"
                }
                onClick={() => {
                  history.push(`/${translatePostTypeSlug(type)}/${id}`);
                }}
              >
                Read More
              </PRHButton>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};

WordPressHero.defaultProps = {
  wordpressImageSize: "full",
};

export default withApp(WordPressHero);
