import { useState, useEffect } from "react";
import { Header, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import useApp from "../hc/useApp";
import LazyImage from "../../utils/LazyImage";

const CartUndoAllChangesWarningModal = (props) => {
  const { data, open, setOpen, callback, cancelCallback, shipTo, foc } = props;

  let realTrigger = (
    <div className='d-flex flex-column fort-book-italic fs-13px lh-18 hover-underline text-blue cursor-pointer align-self-end'>
      <div
        onClick={(e) => {
          setOpen(true);
        }}
        className='v-details'
      ></div>
    </div>
  );

  const resetState = () => {
    setOpen(false);
  };

  const undoChanges = {
    label: "Yes, undo all updates",
    color: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "light-blue",
    textColorOverride: "white",
    borderHoverColorOverride: "light-blue",
    iconColorOverride: "white",
    iconColorHoverOverride: "light-blue",
    icon: "redo",
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      if (callback) callback({ shipTo, foc });
      resetState();
      return { proceed: true };
    },
  };

  const okCancel = {
    label: "Cancel",
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      if (cancelCallback) cancelCallback(false);

      resetState();
      return { proceed: true };
    },
  };

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      modalClassName='vri-modal macancel-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            UNDO ALL UPDATES
          </Header>
        ),
        actions: [okCancel, undoChanges],
      }}
      options={{
        trigger: realTrigger,
      }}
    >
      <div className='ar-box ml-4 mr-4'>
        <div className='mb-2'>
          Your cart will revert to the previously confirmed quantities which
          will be placed and processed as an order after FOC date.
        </div>
        <div className='fort-bold'>
          Are you sure you want to undo all changes to your cart?
        </div>
      </div>
    </DialogModal>
  );
};

CartUndoAllChangesWarningModal.propTypes = {};

CartUndoAllChangesWarningModal.defaultProps = {};

export default CartUndoAllChangesWarningModal;
