import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Icon,
  Loader,
  Modal,
  Grid,
  Dropdown,
  Input,
  Form,
} from "semantic-ui-react";
import {
  useGetDeliveriesDetailQuery,
  useGetDeliveriesOverviewQuery,
  useLazyGetDeliveriesDetailQuery,
} from "../../api/ecomApi";
import { Divider } from "../../components/elements/elements";
import PageStandard from "../../components/elements/PageStandard";
import useDirtyHandler from "../../shared-react-components/components/hc/useDirtyHandler";
import LazyImage from "../../utils/LazyImage";
import { formatDate } from "../../utils/utilities";
import DeliveryCarton from "./components/DeliveryCarton";
import { Export, ExportCSV } from "../../utils/Export";
import "./DeliveryDetail.scss";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import useCart from "../../components/hc/useCart";
import useDetectPrint from "use-detect-print";
import { BackToTopButton } from "../../components/common/BackToTopButton";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";
import { contentsColorConverter } from "../orderinvoice/DeliveriesTab";

const DeliveryDetail = (props) => {
  const isPrinting = useDetectPrint();
  const cart = useCart();
  const history = useHistory();
  const deliveryNo = props.match.params.deliveryNo;
  const dirtyHandler = useDirtyHandler();
  const [performGetDeliveriesDetail, getDeliveriesDetailResult] =
    useLazyGetDeliveriesDetailQuery();
  const [isLoading, setIsLoading] = useState(true);
  const [deliveryDetail, setDeliveryDetail] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchItems, setSearchItems] = useState(0);

  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });

  // const y = useGetDeliveriesOverviewQuery({});
  // console.log("y", y);

  useEffect(() => {
    window.scrollTo(0, 0);
    const loadDeliveryDetail = async () => {
      // dirtyHandler.setLoadingTakeoverConfig({
      //   text: "Loading delivery . . .",
      //   longLoadText: "Still loading . . .",
      //   inverted: true,
      //   backgroundBlurLight: true,
      // });
      // dirtyHandler.setLoadingTakeover(true);

      const result = await performGetDeliveriesDetail({
        deliveryNo: deliveryNo,
      });
      // console.log("result", result);
      if (result.data?.status === "OK") {
        setDeliveryDetail(result.data.data.deliveries[0]);
      }
      // dirtyHandler.setLoadingTakeover(false);
      setIsLoading(false);
    };

    loadDeliveryDetail();

    return () => {};
  }, []);

  const downloadOptions = [
    {
      key: 50,
      text: "Print",
      value: 50,
      icon: { name: "print", color: "orange" },
      onClick: () => {
        // setstate({ ...state, isDropdownOpen: false });
        setTimeout(() => {
          window.print();
        }, 500);
      },
    },
    {
      key: 100,
      text: "Download Excel",
      value: 100,
      icon: { name: "file excel outline", color: "orange" },
      onClick: () => exportCart("excel"),
    },
    {
      key: 125,
      text: "Download CSV",
      value: 100,
      icon: { name: "file outline", color: "orange" },
      onClick: () => exportCart("csv"),
    },
  ];

  const salesOrders = [];
  const invoices = [];
  if (deliveryDetail) {
    deliveryDetail.orderNos.map((so, idx) => {
      salesOrders.push({
        key: so,
        text: so,
        value: so,
        onClick: () => {
          history.push({ pathname: `/orders/${so}` });
        },
      });
    });

    deliveryDetail.invoiceNos.map((ivno, idx) => {
      invoices.push({
        key: ivno,
        text: ivno,
        value: ivno,
        onClick: () => {
          history.push({ pathname: `/invoices/${ivno}` });
        },
      });
    });
  }

  const exportCart = (fileType) => {
    // console.log("export deliveries", deliveryDetail);

    if (fileType === "excel") {
      // let list = { lists: [] };
      // deliveryDetail.cartons.forEach((carton, cartonIndex) => {
      //   const c1 = `Carton ${cartonIndex + 1} of ${
      //     deliveryDetail.cartons.length
      //   }`;
      //   const c2 = `Estimated delivery date: ${carton.deliveryDate}`;
      //   const c3 = `Items | Units: ${carton.itemCt} | ${carton.unitCt}`;
      //   const c4 = carton.trackingCode
      //     ? `Tracking #: ${carton.shipper} ${carton.trackingCode}`
      //     : "Tracking link unavailable";
      //   list.lists.push(
      //     [
      //       {
      //         headerLine: true,
      //         [c1]: null,
      //         [c2]: null,
      //         [c3]: null,
      //         [c4]: null,
      //       },
      //     ].flat()
      //   );

      //   let l = [];

      //   carton.items.forEach((item, idx) => {
      //     l.push({
      //       Line: idx + 1,
      //       Description: item.title,
      //       ISBN: item.isbn,
      //       // Warehouse: carton.warehouse,
      //       // "Price": carton.price,
      //       // Shipper: carton.shipper,
      //       Qty: item.qty,
      //     });
      //   });
      //   list.lists.push(l.flat());
      // });

      // const details = {
      //   fileName: `delivery-detail-${deliveryDetail.deliveryNo}-${deliveryDetail.shipTo}`,
      //   metadata: [
      //     [`Delivery Number`, deliveryDetail.deliveryNo],
      //     ["Shipped on", deliveryDetail.shipDate],
      //     [
      //       "Total Quantity",
      //       `${deliveryDetail.cartonCt} cartons | ${deliveryDetail.itemCt} items | ${deliveryDetail.unitCt} units`,
      //     ],
      //     ["Contents", deliveryDetail.contents.join(", ")],
      //     ["Shipping from", deliveryDetail.shipsFrom],
      //     ["Ship-to Account#", deliveryDetail.shipTo],
      //     ["Sales Order#", deliveryDetail.orderNos.join(", ")],
      //     ["Invoice(s)#", deliveryDetail.invoiceNos.join(", ")],
      //   ],
      //   list: list,
      // };
      const details = {
        fileName: `delivery-detail-${deliveryDetail.deliveryNo}-${deliveryDetail.shipTo}`,
        metadata: [
          [`Delivery Number`, deliveryDetail.deliveryNo],
          ["Shipped on", deliveryDetail.shipDate],
          [
            "Total Quantity",
            `${deliveryDetail.cartonCt} cartons | ${deliveryDetail.itemCt} items | ${deliveryDetail.unitCt} units`,
          ],
          ["Contents", deliveryDetail.contents.join(", ")],
          ["Shipping from", deliveryDetail.shipsFrom],
          ["Ship-to Account#", deliveryDetail.shipTo],
          ["Sales Order#", deliveryDetail.orderNos.join(", ")],
          ["Invoice(s)#", deliveryDetail.invoiceNos.join(", ")],
        ],

        list: deliveryDetail.cartons
          .map((carton, cartonIndex) => {
            return carton.items.map((item) => {
              return {
                "Carton #": carton.cartonSeq,
                "Estimated Delivery Date": carton.deliveryDate,
                Shipper: carton.shipper,
                "Tracking Number": carton.trackingCode,
                "Tracking Number": carton.trackingLink,
                Title: item.title,
                Author: item.author,
                ISBN: item.isbn,
                Format: item.format,
                "On Sale": item.onsale,
                "Carton Qty": item.cartonQuantity,
                Quantity: item.qty,
              };
            });
          })
          .flat(),
      };
      Export.DocumentDetails(details);
    } else if (fileType === "csv") {
      const details = {
        fileName: `delivery-detail-${deliveryDetail.deliveryNo}-${deliveryDetail.shipTo}`,
        metadata: [
          [`Delivery Number`, deliveryDetail.deliveryNo],
          ["Shipped on", deliveryDetail.shipDate],
          [
            "Total Quantity",
            `${deliveryDetail.cartonCt} cartons | ${deliveryDetail.itemCt} items | ${deliveryDetail.unitCt} units`,
          ],
          ["Contents", deliveryDetail.contents.join(", ")],
          ["Shipping from", deliveryDetail.shipsFrom],
          ["Ship-to Account#", deliveryDetail.shipTo],
          ["Sales Order#", deliveryDetail.orderNos.join(", ")],
          ["Invoice(s)#", deliveryDetail.invoiceNos.join(", ")],
        ],

        list: deliveryDetail.cartons
          .map((carton, cartonIndex) => {
            return carton.items.map((item) => {
              return {
                "Carton #": carton.cartonSeq,
                "Estimated Delivery Date": carton.deliveryDate,
                Shipper: carton.shipper,
                "Tracking Number": carton.trackingCode,
                "Tracking Number": carton.trackingLink,
                Title: item.title,
                Author: item.author,
                ISBN: item.isbn,
                Format: item.format,
                "On Sale": item.onsale,
                "Carton Qty": item.cartonQuantity,
                Quantity: item.qty,
              };
            });
          })
          .flat(),
      };
      ExportCSV.DocumentDetails(details);
    }
  };
  const NoDeliveryFound = (props) => {
    return (
      <div className='bg-gray-300'>
        <Container className='d-flex pt-5 pb-5 ml-auto mr-auto'>
          Delivery not found.
        </Container>
      </div>
    );
  };

  const displayCartons = useMemo(() => {
    // console.log("displayCartons useMemo", deliveryDetail);
    if (!deliveryDetail) {
      return [];
    }
    let totalItems = 0;
    let cartons = [];
    if (searchText !== "") {
      deliveryDetail.cartons.forEach((c) => {
        let itms = [];
        const fi = c.items.forEach((i) => {
          if (
            i.isbn.includes(searchText.replaceAll("-", "")) ||
            i?.author?.toLowerCase().includes(searchText.toLowerCase()) ||
            i?.title?.toLowerCase().includes(searchText.toLowerCase())
          ) {
            itms.push(i);
            totalItems++;
          }
        });
        if (itms.length > 0) {
          cartons.push({ ...c, items: itms });
        }
      });
      setSearchItems(totalItems);
      // console.log("results", cartons);
      return cartons;
    } else {
      setSearchItems(0);
      return deliveryDetail.cartons;
    }
  }, [deliveryDetail, searchText]);

  const DeliveryShipTo = (props) => {
    // console.log("Delivery shipto", props);
    const { address } = props;
    // const st = cart.customerInfo.shipToAccountNos.filter(
    //   (st) => st.accountNo === shipTo
    // );

    // if (st.length === 1) {
    if (address) {
      // console.log("st", st);
      return (
        <div className='d-flex flex-column'>
          <div>{address.name1}</div>
          {address?.name2 && <div>{address.name2}</div>}
          {address?.name3 && <div>{address.name3}</div>}
          <div>{address.street}</div>
          <div>
            {address.city}, {address.state} {address.postalCode}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };
  // console.log("deliveryDetail", displayCartons);
  // console.log("isPrinting", isPrinting, isMobile && !isPrinting);
  return (
    <div>
      <PageStandard
        headerType='loggedIn'
        containerFluid={true}
        cartBadge={true}
      >
        <div className='dd-container'>
          {isLoading && <Loader style={{ display: "flex" }} inverted />}

          {!isLoading && !deliveryDetail && (
            <Container className='d-flex flex-column'>
              <NoDeliveryFound />
            </Container>
          )}
          {!isLoading && deliveryDetail && (
            <div>
              <div className='dd-header bg-gray-100 pb-4'>
                <Container className='d-flex flex-column'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex flex-row mt-4 mb-1'>
                      {!isPrinting && (
                        <LazyImage
                          src={"/images/Truck-DeliveryDetails.svg"}
                          className='dd-truck mr-2'
                          styles={{ marginTop: "2px" }}
                        />
                      )}
                      <span className='text-gray-900 fort-book fs-24px lh-29px'>
                        Delivery Details
                      </span>
                      {!isMobile && (
                        <Dropdown
                          // selection
                          className='dd-dropdown dd-hide-in-print'
                          style={{ marginLeft: "auto" }}
                          selection
                          onClick={() => {
                            setDropdownOpen(true);
                            // setstate({ ...state, isDropdownOpen: true })
                          }}
                          trigger={
                            <div className='od-text-data-book'>Download</div>
                          }
                          disabled={isLoading}
                          options={downloadOptions}
                          direction='left'
                          open={isDropdownOpen}
                          onClose={() => {
                            setDropdownOpen(false);
                            // setstate({ ...state, isDropdownOpen: false })
                          }}
                        />
                      )}
                    </div>
                    {isMobile && !isPrinting ? (
                      <div className='fs-13px lh-18px mb-1 dd-delivery-header '>
                        <div className='d-flex flex-row'>
                          <div className='fort-bold w-33'> Delivery #:</div>{" "}
                          <div>{deliveryDetail.deliveryNo}</div>
                        </div>
                        <div className='d-flex flex-row'>
                          <div className='fort-bold w-33'>Shipped on:</div>{" "}
                          {formatDate(deliveryDetail.shipDate, false)}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`fs-14px fort-medium lh-18px mb-1 dd-delivery-header ${
                          isPrinting ? "printing" : ""
                        }`}
                      >
                        <span className=''>
                          <span className='fort-medium'>Delivery #:</span>{" "}
                          <span className='fort-book'>
                            {deliveryDetail.deliveryNo}
                          </span>
                        </span>
                        <span className='mr-1 ml-1 text-gray-300'>|</span>
                        <span>
                          <span className='fort-medium'>Shipped on: </span>
                          <span className='fort-book'>
                            {formatDate(deliveryDetail.shipDate, false)}
                          </span>
                        </span>
                      </div>
                    )}

                    <Divider tight className='mb-1' />
                    <div className='fs-14px lh-18px mt-1 mb-1 '>
                      <div>
                        <div className='d-flex flex-row'>
                          <div
                            className={`fort-bold ${
                              isMobile && !isPrinting ? "w-33" : "mr-1"
                            }`}
                          >
                            Total Quantity:
                          </div>

                          <div className='d-flex flex-row'>
                            <div>{deliveryDetail.cartonCt} cartons</div>
                            <div className='mr-1 ml-1 text-gray-300'>|</div>
                            <div>{deliveryDetail.itemCt} items</div>
                            <div className='mr-1 ml-1 text-gray-300'>|</div>
                            <div>{deliveryDetail.unitCt} units</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='fs-14px lh-18px mb-1'>
                      <div className='d-flex flex-row'>
                        <div
                          className={`fort-bold ${
                            isMobile && !isPrinting ? "w-33" : "mr-1"
                          }`}
                        >
                          Contents:{" "}
                        </div>
                        <div>
                          {deliveryDetail.contents.map((content, idx) => (
                            <div
                              className='delivery-content-item'
                              style={{
                                backgroundColor: `${contentsColorConverter(
                                  content
                                )}`,
                              }}
                            >
                              {content}
                            </div>

                            // <span
                            //   key={idx}
                            //   className={`dd-content-${content
                            //     .replace(/\s/g, "")
                            //     .toLowerCase()}`}
                            // >
                            //   {content}
                            // </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='fs-14px lh-18px mb-1'>
                      <div className='d-flex flex-row'>
                        <div
                          className={`fort-bold ${
                            isMobile && !isPrinting ? "w-33" : "mr-1"
                          }`}
                        >
                          Shipping From:
                        </div>
                        <div>{deliveryDetail.shipsFrom}</div>
                      </div>
                    </div>
                    <Grid className='mt-1'>
                      <Grid.Row>
                        <Grid.Column
                          mobile={isPrinting ? 8 : 16}
                          tablet={16}
                          computer={6}
                          largeScreen={8}
                          widescreen={8}
                          // style={{
                          //   border: "1px solid black",
                          //   overflow: "hidden",
                          // }}
                        >
                          <div className='fs-13px lh-18px mb-1'>
                            <div className='d-flex flex-row'>
                              <div
                                className={`fort-bold ${
                                  isMobile && !isPrinting ? "w-33" : "mr-1"
                                }`}
                              >
                                SHIP-TO ACCOUNT #:
                              </div>
                              <div>{deliveryDetail.shipTo}</div>
                            </div>
                          </div>
                          <div className='fs-13px lh-18px mb-1'>
                            <div
                              className={`d-flex ${
                                isMobile && !isPrinting
                                  ? "flex-row"
                                  : "flex-column"
                              }`}
                            >
                              <div
                                className={`fort-bold mb-1 ${
                                  isMobile && !isPrinting ? "w-33" : ""
                                }`}
                              >
                                SHIP TO:
                              </div>
                              <DeliveryShipTo
                                address={deliveryDetail?.address}
                              />
                            </div>
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          mobile={isPrinting ? 4 : 16}
                          tablet={8}
                          computer={5}
                          largeScreen={4}
                          widescreen={4}
                          // style={{
                          //   border: "1px solid black",
                          //   overflow: "hidden",
                          // }}
                        >
                          <div
                            className={`fs-13px lh-18px mb-1 ${
                              isMobile ? "d-flex flex-row" : ""
                            }`}
                          >
                            <div
                              className={`fort-bold ${
                                isMobile && !isPrinting ? "w-33" : "mr-1"
                              }`}
                            >
                              SALES ORDER(S)#:
                            </div>
                            <div
                              className={`d-flex fs-14px ${
                                isMobile && !isPrinting
                                  ? "flex-row"
                                  : "flex-column flex-wrap wrap-items"
                              }`}
                            >
                              {isPrinting ? (
                                deliveryDetail.orderNos.map((so, idx) => (
                                  <Link key={idx} to={`/orders/${so}`}>
                                    {so}
                                  </Link>
                                ))
                              ) : isMobile ? (
                                <div>
                                  <Dropdown
                                    trigger={<span>More details</span>}
                                    className='text-blue fort-book fs-14px lh-18px'
                                    icon={false}
                                  >
                                    <Dropdown.Menu>
                                      {salesOrders.map((option) => (
                                        <Dropdown.Item
                                          className='mobile-dropdown-item'
                                          key={option.value}
                                          {...option}
                                        />
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              ) : (
                                deliveryDetail.orderNos.map((so, idx) => (
                                  <Link key={idx} to={`/orders/${so}`}>
                                    {so}
                                  </Link>
                                ))
                              )}
                            </div>
                          </div>
                        </Grid.Column>
                        <Grid.Column
                          mobile={isPrinting ? 4 : 16}
                          tablet={8}
                          computer={5}
                          largeScreen={4}
                          widescreen={4}
                          // style={{ border: "1px solid black" }}
                        >
                          <div
                            className={`fs-13px lh-18px mb-1 ${
                              isMobile ? "d-flex flex-row" : ""
                            }`}
                          >
                            <div
                              className={`fort-bold ${
                                isMobile ? "w-33" : "mr-1"
                              }`}
                            >
                              INVOICE(S)#:
                            </div>
                            <div
                              className={`d-flex ${
                                isMobile
                                  ? "flex-row"
                                  : "flex-column flex-wrap wrap-items"
                              }`}
                            >
                              {isPrinting ? (
                                deliveryDetail.invoiceNos.map((invNo, idx) => (
                                  <Link key={idx} to={`/invoices/${invNo}`}>
                                    {invNo}
                                  </Link>
                                ))
                              ) : isMobile ? (
                                <div>
                                  <Dropdown
                                    trigger={<span>More details</span>}
                                    className='text-blue fort-book fs-14px lh-18px'
                                    icon={false}
                                  >
                                    <Dropdown.Menu>
                                      {invoices.map((option) => (
                                        <Dropdown.Item
                                          className='mobile-dropdown-item'
                                          key={option.value}
                                          {...option}
                                        />
                                      ))}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              ) : (
                                deliveryDetail.invoiceNos.map((invNo, idx) => (
                                  <Link key={idx} to={`/invoices/${invNo}`}>
                                    {invNo}
                                  </Link>
                                ))
                              )}
                            </div>
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </Container>
              </div>
              <Container className='d-flex flex-column mb-8'>
                <div
                  className={`dd-search-bar d-flex ${
                    isMobile ? "flex-column" : "flex-row"
                  } align-items-center mt-3`}
                >
                  {searchText !== "" && !isMobile && (
                    <div className='mr-auto'>
                      <span className='fort-bold'>{searchItems}</span> items
                      matching "{searchText}"
                    </div>
                  )}
                  <div
                    className={`ml-auto hide-in-print ${
                      isMobile ? "w-100" : ""
                    }`}
                  >
                    <Form onSubmit={() => console.log("submit")}>
                      <Input
                        className={`dd-search-input ${
                          searchText ? "active" : ""
                        }  ${isMobile ? "w-100" : ""}`}
                        id='deliverySearch'
                        name='deliverySearch'
                        type='text'
                        icon={{
                          name: "search",
                          link: true,
                          onClick: () => console.log("search"),
                        }}
                        // iconPosition='right'
                        value={searchText}
                        // disabled={state.isLoading}
                        onKeyPress={() => console.log("")}
                        onChange={(e, data) => {
                          setSearchText(data.value.trim());
                        }}
                        //placeholder={'Search Order #, PO #, or ISBN'}
                        placeholder={"Search for a title, ISBN or UPC"}
                      />
                    </Form>
                  </div>
                </div>
                {searchText !== "" && isMobile && (
                  <div className='mr-auto pt-2'>
                    <span className='fort-bold'>{searchItems}</span> items
                    matching "{searchText}"
                  </div>
                )}
                {displayCartons.length === 0 && (
                  <div className='d-flex flex-column align-items-center mt-8'>
                    <div className='fs-24px lh-28px'>No results found.</div>
                    <div className='fs-14px lh-18px mt-2'>
                      Make sure your search request is spelled correctly or try
                      different keywords.
                    </div>
                  </div>
                )}
                {displayCartons.map((carton, idx) => (
                  <DeliveryCarton
                    key={idx}
                    carton={carton}
                    cartonIndex={idx + 1}
                    cartonTotal={deliveryDetail.cartons.length}
                  />
                ))}
              </Container>
            </div>
          )}
        </div>
        {!isMobile && <BackToTopButton />}
        {isMobile && <BackToTopButtonMobile />}
      </PageStandard>
    </div>
  );
};

export default DeliveryDetail;
