import "./StepperSmall.scss";
import "react-datepicker/dist/react-datepicker.css";

const Stepper = ({ steps, active }) => {
  return (
    <>
      {steps.map((step, index) => (
        <div
          key={`${step.title}-${index}`}
          className={`step ${active === index ? "active" : ""} ${
            index < active ? "visited" : ""
          }`}
        >
          {active === index && <div className="active-step" />}
          {index > 0 && <div className="step-offset step-left-offset" />}
          {index < steps.length - 1 && (
            <div className="step-offset step-right-offset" />
          )}
        </div>
      ))}
    </>
  );
};

const StepperSmall = ({ stepperStatus, steps }) => {
  return stepperStatus ? (
    <div className="small-stepper">
      <div className="stepper-container">
        <Stepper steps={steps} active={stepperStatus - 1} />
      </div>
      <div className="stepper-status">
        {`Section ${stepperStatus} of ${steps.length}: ${
          steps[stepperStatus - 1].title
        }`}
      </div>
    </div>
  ) : null;
};

export default StepperSmall;
