import { findIconDefinition, icon } from "@fortawesome/fontawesome-svg-core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import print from "print-js";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import {
  Container,
  Dropdown,
  Form,
  Icon,
  Input,
  Message,
} from "semantic-ui-react";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Export, ExportCSV } from "../../utils/Export";
import "./OrdersTab.scss";
import orderNumberRenderer from "./renderers/orderNumberRenderer";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import { MobileOptionsMenu } from "../../components/header/components/MobileOptionsMenu";
import MobileTable, {
  mobileTableColumnsOrders,
} from "../../shared-react-components/elements/OrdersAndInvoicesMobileTable";
import { Divider } from "../../components/elements/elements";
import { OrderInvoiceTitleSearchMobile } from "../../components/common/OrderInvoiceTitleSearchMobile";
import { useLazyGetOrdersQuery } from "../../api/ecomApi";
import { cloneDeep } from "lodash";

const pageSizeOptions = [
  {
    key: 50,
    text: "50 per page",
    value: 50,
  },
  {
    key: 100,
    text: "100 per page",
    value: 100,
  },
  {
    key: 150,
    text: "150 per page",
    value: 150,
  },
  {
    key: 200,
    text: "200 per page",
    value: 200,
  },
];

const dateFilters = [
  {
    key: 30,
    text: "Past 30 days",
    value: 30,
  },
  {
    key: 92,
    text: "Past 3 months",
    value: 92,
  },
  {
    key: 184,
    text: "Past 6 months",
    value: 184,
  },
  {
    key: 366,
    text: "Past 12 months",
    value: 366,
  },
  {
    key: 550,
    text: "Past 18 months",
    value: 550,
  },
];

export const OrdersTab = withRouter(
  withSecurity(
    withApp(
      withCart((props) => {
        const isMobile = useMediaQuery({
          query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
        });

        const [performGetOrders, getOrdersResult] = useLazyGetOrdersQuery();
        const propPage = Number(props.location.search?.split("=")[1] || 1);
        const [rowData, setRowData] = useState(undefined);
        const [isLoading, setIsLoading] = useState(false);
        const [state, setState] = useState({
          pageSize: props.location.state?.pageSize
            ? props.location.state?.pageSize
            : 50,
          currentPageNum: props.location.state?.currentPageNum
            ? props.location.state?.currentPageNum
            : propPage,
          totalPages: props.location.state?.totalPages
            ? props.location.totalPages?.currentPageNum
            : 0,
          rowHeight: 51,
          rowCount: 0,
          hasMore: false,
          currentSort: "dateCreated_desc",
          columnDefs: [
            {
              headerName: "SALES ORDER #",
              field: "salesOrderNumber",
              cellRenderer: "orderNumberRenderer",
              cellClass: ["btt-isbn no-border"],
              checkboxSelection: true,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly:true,
            },
            {
              headerName: "PURCHASE ORDER",
              field: "poNumber",
              cellClass: ["btt-po no-border"],
            },
            {
              headerName: "DATE CREATED",
              field: "dateCreated",
              sortable: true,
              sort: "desc",
              sortingOrder: ["desc", "asc"],
              cellClass: "no-border",
            },
            {
              headerName: "SHIP-TO ACCOUNT",
              field: "account",
              cellClass: "no-border",
            },
          ],
          //rowData: undefined,
          dayFilter: props.location.state?.dayFilter
            ? props.location.state?.dayFilter
            : dateFilters[0].value,
          accountFilter: props.location.state?.accountFilter
            ? props.location.state?.accountFilter
            : "1",
          accountFilters: [],
          selectedRows: [],
          searchInput: props.location.state?.searchInput
            ? [props.location.state.searchInput]
            : [],
          searchShipTo: props.location.state?.searchShipTo
            ? props.location.state.searchShipTo
            : false,
          headerOnly: true,
          hasInput: props.history.location.state?.hasInput
            ? props.history.location.state?.hasInput
            : false,
          hasError: false,
          start: 0,
          rows: 1000,
        });
        const [gridApi, setGridApi] = useState(null);

        const [sortMenuVisible, setSortMenuVisible] = useState(false);
        const [accountMenuVisible, setAccountMenuVisible] = useState(false);

        const [mobileRowData, setMobileRowData] = useState(rowData);

        useEffect(() => {
          if (props.history.action !== "PUSH" && props.history.location.state) {
            setState({
              ...state,
              dayFilter: props.history.location.state.dayFilter
                ? props.history.location.state.dayFilter
                : state.dayFilter,
              accountFilter: props.history.location.state?.accountFilter
                ? props.history.location.state?.accountFilter
                : state.accountFilter,
              pageSize: props.history.location.state?.pageSize
                ? props.history.location.state?.pageSize
                : state.pageSize,
              currentPageNum: props.history.location.state?.currentPageNum
                ? props.history.location.state?.currentPageNum
                : state.currentPageNum,
              totalPages: props.history.location.state?.totalPages
                ? props.history.location.state?.totalPages
                : state.totalPages,
              searchInput: props.history.location.state?.searchInput
                ? [props.history.location.state?.searchInput]
                : state.searchInput,
              hasInput: props.history.location.state?.hasInput
                ? props.history.location.state?.hasInput
                : state.hasInput,
            });
          }
        }, []);

        useEffect(() => {
          if (state.accountFilter === "1") {
            setMobileRowData(rowData);
          } else {
            const filteredData = rowData?.filter(
              (data) => data.account === state.accountFilter
            );
            setMobileRowData(filteredData);
          }
        }, [rowData, state.accountFilter]);

        function onFilterTextBoxChanged() {
          const { searchInput } = state;
          performSearch();
          props.history.push({
            pathname: "/orders",
            state: {
              ...props.history.location.state,
              searchInput: searchInput.length > 0 ? [searchInput] : [],
            },
          });
        }

        const search = (event) => {
          var code = event.keyCode || event.which;
          if (code === 13) {
            onFilterTextBoxChanged();
          }
        };

        function onGridReady(params) {
          // console.log("onGridReady");
          setGridApi(params.api);
          params.api.sizeColumnsToFit();
          setState({
            ...state,
            accountFilter: props.history.location.state?.accountFilter
              ? props.history.location.state?.accountFilter
              : state.accountFilter,
          });
          params.api.setQuickFilter(
            state.accountFilter !== "1" ? state.accountFilter : ""
          );
        }

        const setData = (dataProps) => {
          const currentQueryId = props.cart.orderQueryId;
          const uniqueShipToAccountNumbers = [
            ...new Set(dataProps.data.orders.map((item) => item.account)),
          ].map((item) => {
            return {
              id: item,
              text: String(item),
              value: item,
            };
          });

          if (currentQueryId === null) {
            props.cart.setOrderQueryId(dataProps.params.queryId);
          }
          setRowData(dataProps.data.orders);
          setState({
            ...state,
            //rowData: dataProps.data.orders,
            // rowData: undefined,
            totalPages: Math.ceil(
              dataProps.data.orders.length / state.pageSize
            ),
            queryId: dataProps.params.queryId,
            accountFilters: [
              {
                key: 1,
                text: "All Accounts",
                value: "1",
              },
              ...uniqueShipToAccountNumbers,
            ],
          });
          setIsLoading(false);

          if (gridApi) {
            gridApi.hideOverlay();
          }
        };

        useEffect(() => {
          if (props?.location?.state?.searchInput) {
            setState({
              ...state,
              hasInput: true,
              // searchShipTo: 'jeff',
              searchInput: [props.location.state.searchInput],
            });
          }
        }, [props.location.state]);

        useEffect(() => {
          performSearch();
        }, [state.dayFilter, props.cart.customerLoaded, state.start]);
        // console.log('props.cart', props.cart);
        const performSearch = () => {
          const dataParams = {
            // queryId: props.cart.orderQueryId,
            // account: null,
            // docType: [],
            // documentNumber: [],
            // isbn: [],
            // partnerFunction: [],
            // purchaseOrder: [],
            // salesOrg: [],
            dateFrom: moment(new Date(), "YYYY-MM-DD")
              .subtract(state.dayFilter, "days")
              .toISOString()
              .slice(0, 10),
            dateTo: moment(new Date(), "YYYY-MM-DD").toISOString().slice(0, 10),
            allowPricing: true,
            boOnly: false,
            headerOnly: true,
            start: state.start,
            rows: state.rows,
            account: [
              props.cart?.customerInfo?.currentUser?.roleNo === "W1"
                ? props.cart?.customerInfo?.customer?.accountNo
                : "",
            ],
          };
          if (state.searchShipTo) {
            dataParams.account = [state.searchShipTo];
          }
          if (gridApi) {
            gridApi.showLoadingOverlay();
          }
          setIsLoading(true);
          // console.log('selecting orders', props, state, dataParams);
          if (state.searchInput.length > 0) {
            const input = state.searchInput[0].toString().trim();
            // const isIsbn =
            //   (input.startsWith('978') || input.startsWith('979')) &&
            //   input.replaceAll('-', '').length === 13;
            // if (isIsbn) {
            const isbn = input?.replaceAll("-", "");
            performGetOrders({
              data: { ...dataParams, isbn: [isbn], dateFrom: null },
              sapAccountNo: props.cart.ccSapAccountNo,
            }).then((ordersResponse) => {
              const { data } = ordersResponse;
              if (ordersResponse.data?.status === "OK") {
                if (data.data.rowCount === 0) {
                  performGetOrders({
                    data: {
                      ...dataParams,
                      purchaseOrder: [input],
                      dateFrom: null,
                    },
                    sapAccountNo: props.cart.ccSapAccountNo,
                  }).then((ordersResponse) => {
                    const { data } = ordersResponse;
                    if (ordersResponse.data?.status === "OK") {
                      // console.log('orders ordersResponse', ordersResponse);
                      if (data.data.rowCount === 0) {
                        performGetOrders({
                          data: {
                            ...dataParams,
                            documentNumber: [input],
                            dateFrom: null,
                          },
                          sapAccountNo: props.cart.ccSapAccountNo,
                        }).then((ordersResponse) => {
                          if (ordersResponse.data?.status === "OK") {
                            const { data } = ordersResponse;
                            // console.log('orders ordersResponse', ordersResponse);
                            setData(cloneDeep(data));
                          } else {
                            setState({
                              ...state,
                              hasError: true,
                            });
                            setIsLoading(false);
                          }
                        });
                      } else {
                        setState({ ...state });
                        setData(cloneDeep(data));
                      }
                    } else {
                      setState({
                        ...state,
                        hasError: true,
                      });
                      setIsLoading(false);
                    }
                  });
                } else {
                  setData(cloneDeep(data));
                }
              } else {
                setState({
                  ...state,
                  hasError: true,
                });
                setIsLoading(false);
              }
            });
          } else {
            performGetOrders({
              data: dataParams,
              sapAccountNo: props.cart.ccSapAccountNo,
            }).then((ordersResponse) => {
              if (ordersResponse.data?.status === "OK") {
                const { data } = ordersResponse;
                setData(cloneDeep(data));
              } else {
                setState({ ...state, hasError: true });
              }
            });
          }
        };
        /*
        useEffect(() => {
          props.history.push({
            pathname: "/orders",
            state: JSON.stringify(state),
          });
        }, [state]);
*/
        const downloadOptions = [
          {
            key: 50,
            text: "Print",
            value: 50,
            icon: { name: "print", color: "orange" },
            onClick: () => {
              const filteredData = rowData?.filter(
                (data) => data.account === state.accountFilter
              );

              print({
                printable:
                  state.selectedRows.length > 0
                    ? state.selectedRows
                    : state.accountFilter === "1"
                    ? rowData
                    : filteredData,
                // rowData
                properties: [
                  "salesOrderNumber",
                  "poNumber",
                  "dateCreated",
                  "account",
                ],
                type: "json",
              });
            },
          },
          {
            key: 100,
            text: "Download Excel",
            value: 100,
            icon: { name: "file excel outline", color: "orange" },
            onClick: () =>
              Export.DocumentDetails({
                fileName: `order-list-${moment().format("YYYY-MM-DD")}`,
                metadata: [
                  [
                    "Inquiry for",
                    `Search for orders with date between ${moment()
                      .subtract(state.dayFilter, "days")
                      .format("MM/DD/YYYY")} and ${moment().format(
                      "MM/DD/YYYY"
                    )}`,
                  ],
                  ["Inquiry date", moment().format("MM/DD/YYYY")],
                ],
                list: (state.selectedRows.length > 0
                  ? state.selectedRows
                  : rowData
                ).map((item) => {
                  return {
                    "Ship-to Account": item.account,
                    "Purchase Order Number": item.poNumber,
                    "Date Created": item.dateCreated,
                    "Sales Order Number": item.salesOrderNumber,
                  };
                }),
              }),
          },
          {
            key: 150,
            text: "Download CSV",
            value: 150,
            icon: { name: "file outline", color: "orange" },
            onClick: () =>
              ExportCSV.DocumentDetails({
                fileName: `order-list-${moment().format("YYYY-MM-DD")}`,
                metadata: [
                  [
                    "Inquiry for",
                    `Search for orders with date between ${moment()
                      .subtract(state.dayFilter, "days")
                      .format("MM/DD/YYYY")} and ${moment().format(
                      "MM/DD/YYYY"
                    )}`,
                  ],
                  ["Inquiry date", moment().format("MM/DD/YYYY")],
                ],
                list: (state.selectedRows.length > 0
                  ? state.selectedRows
                  : rowData
                ).map((item) => {
                  return {
                    "Ship-to Account": item.account,
                    "Purchase Order Number": item.poNumber,
                    "Date Created": item.dateCreated,
                    "Sales Order Number": item.salesOrderNumber,
                  };
                }),
              }),
          },
        ];

        const getAccountFilterText = (key, accountFilters) => {
          // console.log('#########', key, accountFilters);
          const item = accountFilters?.find((option) =>
            key === "1" ? option.key === 1 : option.id === key
          );
          return item?.text;
        };

        function debounce(func, time) {
          var time = time || 100; // 100 by default if no param
          var timer;
          return function (event) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(func, time, event);
          };
        }

        // Function with stuff to execute
        function resizeContent() {
          if (gridApi) {
            gridApi.sizeColumnsToFit();
          }
        }

        // Eventlistener
        window.addEventListener("resize", debounce(resizeContent, 150));

        const caretUp = findIconDefinition({
          prefix: "fas",
          iconName: "caret-up",
        });
        const caretDown = findIconDefinition({
          prefix: "fas",
          iconName: "caret-down",
        });
        const caretUpIcon = icon(caretUp);
        const caretDownIcon = icon(caretDown);
        return (
          <div>
            <div
              className={`oi-filter-row ${
                isMobile ? "mobile-oi-filter-row mb-0" : ""
              }`}
            >
              {isMobile && !state.hasError && (
                <div className='mobile-orders-tab-search-container mb-3 mt-1'>
                  <Container>
                    <OrderInvoiceTitleSearchMobile
                      title={mobileRowData?.length === 1 ? "Order" : "Orders"}
                      count={mobileRowData?.length}
                      onSearch={(search) => {
                        setState({
                          ...state,
                          searchInput: search ? [search] : [],
                        });
                        props.history.push({
                          pathname: "/orders",
                          state: {
                            ...props.history.location.state,
                            searchInput: search ? [search] : [],
                          },
                        });
                      }}
                      placeholder='Search Order #, PO #, or ISBN'
                    />
                  </Container>
                </div>
              )}
              {!isMobile && !state.hasError && (
                <div className='oi-filter-row-left'>
                  <Dropdown
                    selection
                    disabled={isLoading || state.searchInput.length > 0}
                    options={
                      state.searchInput.length > 0
                        ? [
                            {
                              key: 550,
                              text: "Past 18 Months",
                              value: 550,
                            },
                          ]
                        : dateFilters
                    }
                    value={state.dayFilter}
                    direction='left'
                    onChange={(e, { value }) => {
                      setState({
                        ...state,
                        dayFilter: value,
                        searchInput: [],
                        accountFilter: "1",
                      });
                      props.history.push({
                        pathname: "/orders",
                        state: {
                          ...props.history.location.state,
                          dayFilter: value,
                        },
                      });
                      if (gridApi) {
                        gridApi.setQuickFilter("");
                      }
                    }}
                    className='ot-dropdown tm-dropdown'
                  />
                  {props.cart.customerInfo.currentUser &&
                    props.cart.customerInfo.currentUser.roleNo !== "W1" &&
                    state.accountFilters.length > 2 && (
                      <Dropdown
                        selection
                        disabled={
                          isLoading ||
                          rowData?.length === 0 ||
                          (rowData?.length === 0 &&
                            state.searchInput?.length > 0)
                        }
                        options={state.accountFilters}
                        value={state.accountFilter}
                        direction='left'
                        onChange={(e, { value }) => {
                          // setState({ ...state, accountFilter: value });
                          gridApi.setQuickFilter(value !== "1" ? value : "");
                          setState({
                            ...state,
                            accountFilter: value,
                            totalPages: Math.ceil(
                              gridApi.getDisplayedRowCount() / state.pageSize
                            ),
                          });
                          props.history.push({
                            pathname: "/orders",
                            state: {
                              ...props.history.location.state,
                              accountFilter: value,
                            },
                          });
                        }}
                        className='ot-dropdown tm-dropdown'
                      />
                    )}
                  <Dropdown
                    selection
                    className='od-dropdown'
                    text='Download or Print'
                    disabled={isLoading || rowData?.length === 0}
                    options={downloadOptions}
                    direction='left'
                  />
                </div>
              )}
              {isMobile && !state.hasError && (
                <Container className='mobile-orders-tab-filters-container'>
                  <div className='oi-filter-row-left mb-0'>
                    {props.cart.customerInfo.currentUser &&
                      props.cart.customerInfo.currentUser.roleNo !== "W1" &&
                      state.accountFilters.length > 1 && (
                        <div>
                          <PRHButton
                            className='mobile-options-button mb-1 px-1 fs-12px'
                            onClick={() => setAccountMenuVisible(true)}
                            disabled={
                              isLoading ||
                              rowData?.length === 0 ||
                              (rowData?.length === 0 &&
                                state.searchInput?.length > 0)
                            }
                          >
                            {state.accountFilter != 1 ? "Acc#: " : ""}
                            {getAccountFilterText(
                              state.accountFilter,
                              state.accountFilters
                            )}{" "}
                            <Icon name='triangle down' />
                          </PRHButton>
                          <MobileOptionsMenu
                            title='Account'
                            onClose={() =>
                              setAccountMenuVisible(!accountMenuVisible)
                            }
                            onOutsideMenuClick={() =>
                              setAccountMenuVisible(!accountMenuVisible)
                            }
                            menuItems={state.accountFilters}
                            menuVisible={accountMenuVisible}
                            className='download-dropdown-catalog'
                            onOptionClick={(option) => {
                              setState({
                                ...state,
                                accountFilter: option.value,
                              });
                              props.history.push({
                                pathname: "/orders",
                                state: {
                                  ...props.history.location.state,
                                  accountFilter: option.value,
                                },
                              });
                            }}
                            radio
                            currentSort={state.accountFilter}
                          />
                        </div>
                      )}
                    <div>
                      <PRHButton
                        className='mobile-options-button mb-1 px-1 fs-12px'
                        onClick={() => setSortMenuVisible(true)}
                        disabled={isLoading}
                      >
                        {
                          dateFilters.find((d) => d.value === state.dayFilter)
                            .text
                        }
                        <Icon name='triangle down' />
                      </PRHButton>
                      <MobileOptionsMenu
                        title='Sort By'
                        onClose={() => setSortMenuVisible(!sortMenuVisible)}
                        onOutsideMenuClick={() =>
                          setSortMenuVisible(!sortMenuVisible)
                        }
                        menuItems={dateFilters}
                        menuVisible={sortMenuVisible}
                        className='download-dropdown-catalog'
                        onOptionClick={(option) => {
                          setState({
                            ...state,
                            dayFilter: option.value,
                            searchInput: [],
                            hasInput: false,
                          });
                          props.history.push({
                            pathname: "/orders",
                            state: {
                              ...props.history.location.state,
                              dayFilter: option.value,
                            },
                          });
                        }}
                        radio
                        currentSort={state.dayFilter}
                      />
                    </div>
                  </div>
                </Container>
              )}
              {!isMobile && !state.hasError && (
                <div>
                  <Form onSubmit={onFilterTextBoxChanged}>
                    <Input
                      className={`ot-input ${state.hasInput ? "active" : ""}`}
                      id='searchlist'
                      name='searchlist'
                      type='text'
                      icon={{
                        name: "search",
                        link: true,
                        onClick: () => onFilterTextBoxChanged(),
                      }}
                      value={
                        state.hasInput && state.searchInput.length > 0
                          ? state.searchInput[0].toString().trim()
                          : ""
                      }
                      disabled={isLoading}
                      onKeyPress={search}
                      onChange={(e, data) => {
                        if (data.value !== "") {
                          setState({
                            ...state,
                            hasInput: true,
                            searchInput: [data.value],
                          });
                        } else if (data.value === "") {
                          setState({
                            ...state,
                            searchInput: [],
                            hasInput: false,
                          });
                        }
                      }}
                      //placeholder={'Search Order #, PO #, or ISBN'}
                      placeholder={state.searchInput}
                    />
                  </Form>
                </div>
              )}
            </div>
            {!isMobile && rowData && rowData.length > 0 && (
              <div className='ag-theme-alpine ot-order-table'>
                <AgGridReact
                  onGridReady={onGridReady}
                  columnDefs={state.columnDefs}
                  rowData={rowData}
                  rowHeight={state.rowHeight}
                  rowSelection={"multiple"}
                  suppressContextMenu={true}
                  /*
                  onPaginationChanged={(paginationProps) => {
                    window.scrollTo(0, 0);
                    if (
                      state.hasMore &&
                      Number(paginationProps.api.getDisplayedRowCount()) ===
                        Number(paginationProps.api.getLastDisplayedRow() + 1)
                    ) {
                      setState({
                        ...state,
                        start: state.start + state.rows,
                      });
                    }
                  }}*/
                  onPaginationChanged={(props) => {
                    window.scrollTo(0, 0);
                    setState({
                      ...state,
                      currentPageNum: props.api.paginationGetCurrentPage() + 1,
                      totalPages: props.api.paginationGetTotalPages(),
                    });
                  }}
                  gridOptions={{
                    icons: {
                      sortAscending: () => caretUpIcon.node[0],
                      sortDescending: () => caretDownIcon.node[0],
                    },
                  }}
                  frameworkComponents={{
                    orderNumberRenderer: orderNumberRenderer,
                    customLoadingOverlay: () => (
                      <div className='ot-no-rows ag-overlay-loading-center'>
                        Loading...
                      </div>
                    ),
                  }}
                  loadingOverlayComponent={"customLoadingOverlay"}
                  suppressRowClickSelection={true}
                  pagination={true}
                  defaultColDef={{
                    sortable: true,
                    minWidth: 150,
                    flex: 1,
                    resizable: true,
                    cellClass: "stringRow no-border",
                    suppressMovable: true,
                    suppressMenu: true,
                  }}
                  excelStyles={[
                    {
                      id: "stringRow",
                      dataType: "string",
                    },
                  ]}
                  onFirstDataRendered={(dataProps) => {
                    setState({ ...state, currentPageNum: propPage });
                    dataProps.api.paginationGoToPage(propPage - 1);
                  }}
                  suppressPaginationPanel={true}
                  paginationPageSize={state.pageSize}
                  domLayout='autoHeight'
                  rowClass='grid-row-style'
                  headerHeight={state.rowHeight}
                  onSelectionChanged={(e) =>
                    setState({
                      ...state,
                      selectedRows: e.api.getSelectedRows(),
                    })
                  }
                  overlayNoRowsTemplate={"No orders"}
                />
              </div>
            )}
            {isMobile && <Divider tight className='mt-2 mb-2' />}
            {isMobile && !isLoading && (
              <Container className='mb-4'>
                <MobileTable
                  columns={mobileTableColumnsOrders}
                  data={mobileRowData}
                  noDataMessage={
                    mobileRowData &&
                    mobileRowData.length === 0 &&
                    state.searchInput?.length === 0 ? (
                      <div className='ot-no-rows-height'>
                        <div className='ot-no-rows'>
                          Your orders will appear here.
                        </div>
                        <div className='ot-no-rows-subheader'>
                          Please check back later.
                        </div>
                      </div>
                    ) : mobileRowData &&
                      mobileRowData.length === 0 &&
                      state.searchInput?.length > 0 ? (
                      <div className='ot-no-rows-height'>
                        <div className='ot-no-rows'>No results found.</div>
                        <div className='ot-no-rows-subheader'>
                          Make sure your search request is spelled correctly or
                          try different keywords.
                        </div>
                      </div>
                    ) : null
                  }
                />
              </Container>
            )}
            {isLoading && isMobile && (
              <div className='ot-no-rows ot-no-rows-height'>Loading...</div>
            )}
            {(!rowData || rowData?.length === 0) && isLoading && !isMobile && (
              <div className='ot-no-rows ot-no-rows-height'>Loading...</div>
            )}

            {!isMobile &&
              rowData &&
              rowData.length === 0 &&
              state.searchInput?.length === 0 &&
              !isLoading && (
                <div className='ot-no-rows-height'>
                  <div className='ot-no-rows'>
                    Your orders will appear here.
                  </div>
                  <div className='ot-no-rows-subheader'>
                    Please check back later.
                  </div>
                </div>
              )}
            {!isMobile &&
              rowData &&
              rowData.length === 0 &&
              state.searchInput?.length > 0 &&
              !isLoading && (
                <div className='ot-no-rows-height'>
                  <div className='ot-no-rows'>No results found.</div>
                  <div className='ot-no-rows-subheader'>
                    Make sure your search request is spelled correctly or try
                    different keywords.
                  </div>
                </div>
              )}
            {state.hasError && (
              <div className='ot-message'>
                <Message
                  header='Sorry, we are unable to display your orders at this time.'
                  content='Please try again later or contact Customer Service.'
                  icon={
                    <Icon name='exclamation triangle' color='red' size='mini' />
                  }
                  color='red'
                  warning={true}
                />
              </div>
            )}
            {!isMobile && !isLoading && rowData && (
              <div className='ot-bottom-nav-tab'>
                <div style={{ width: "16em" }}>{""}</div>
                {state.totalPages > 1 ? (
                  <div className='ot-pagination'>
                    <PRHButton
                      disabled={
                        state.currentPageNum === 1 ? true : false || isLoading
                      }
                      displayInlineFlex
                      icon='arrow left'
                      className='sidePageBtns'
                      size='small'
                      key={Math.random()}
                      onClick={() => {
                        gridApi.paginationGoToPreviousPage();
                        setState({
                          ...state,
                          currentPageNum: state.currentPageNum - 1,
                        });
                        props.history.push({
                          pathname: "/orders",
                          search: `?page=${state.currentPageNum - 1}`,
                          state: {
                            ...props.history.location.state,
                            currentPageNum: state.currentPageNum - 1,
                          },
                        });
                      }}
                    />
                    <div className='ml-2 mr-2'>
                      Page {state.currentPageNum} of {state.totalPages}
                    </div>
                    <PRHButton
                      disabled={
                        state.currentPageNum === state.totalPages
                          ? true
                          : false || isLoading
                      }
                      onClick={() => {
                        gridApi.paginationGoToNextPage();
                        setState({
                          ...state,
                          currentPageNum: state.currentPageNum + 1,
                        });
                        props.history.push({
                          pathname: "/orders",
                          search: `?page=${state.currentPageNum + 1}`,
                          state: {
                            ...props.history.location.state,
                            currentPageNum: state.currentPageNum + 1,
                          },
                        });
                      }}
                      displayInlineFlex
                      icon='arrow right'
                      className='sidePageBtns'
                      size='small'
                      key={Math.random()}
                    />
                  </div>
                ) : (
                  ""
                )}
                {!isMobile && rowData && !state.hasError && (
                  <div
                    className='number-of-results-holder'
                    style={{ width: "16em" }}
                  >
                    <div className='fort-medium fs-14px lh-18px ml-2 number-of-results'>
                      {state.accountFilter > 1
                        ? gridApi?.getDisplayedRowCount()
                        : rowData?.length}{" "}
                      results
                    </div>
                    {/* {(state.accountFilter == 1 && rowData?.length > 50) ||
                    (state.accountFilter > 1 &&
                      gridApi?.getDisplayedRowCount() > 50) ? ( */}
                    {rowData?.length > 50 && (
                      <Dropdown
                        selection
                        disabled={isLoading}
                        options={pageSizeOptions}
                        value={state.pageSize}
                        direction='left'
                        onChange={(e, { value }) => {
                          setState({
                            ...state,
                            pageSize: value,
                            currentPageNum: 1,
                            totalPages: Math.ceil(rowData.length / value),
                          });
                          props.history.push({
                            pathname: "/orders",
                            search: `?page=1`,
                            state: {
                              ...props.history.location.state,
                              pageSize: value,
                              currentPageNum: 1,
                              totalPages: Math.ceil(rowData.length / value),
                            },
                          });
                          gridApi.paginationSetPageSize(value);
                        }}
                        className='tm-dropdown ot-dropdown'
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })
    )
  )
);
