import React, { useState, useEffect } from 'react';
import LazyImage from '../../../utils/LazyImage';
import DialogModal from '../DialogModal';

function AffidavitReturnsUpdatedSuccess(props) {
  const { 
    open,
    setOpen,
    onClose,
    headerText,
  } = props;
  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      modalClassName="cp-confirmcart-popup"
      style={{ maxWidth: '448px' }}
      dialogOptions={{
        header: 'Return request updated',
        actions: [
          {
            label: 'Got it',
            icon: 'check',
            borderColorOverride: 'blue',
            textColorOverride: 'white',
            textColorHoverOverride: 'blue',
            backgroundColorOverride: 'blue',
            backgroundHoverColorOverride: 'white',
            iconColorOverride: 'white',
            iconColorHoverOverride: 'blue',

            preAction: () => {
              return { proceed: true };
            },

            action: () => {
              return { proceed: true };
            },
          },
        ],
      }}
    >
      <div className="d-flex flex-column">
        <LazyImage
          src={'/images/cart-conf-success.png'}
          className="ml-auto mr-auto"
        />
        <div className="fort-bold fs-14px lh-19px mb-1">
          We've received your updated return request. These quantities will convert into
          a credit statement at the end of the return window.
        </div>
        <div className="fort-book fs-14px lh-19px mb-2">
          You can continue to make updates until 11:59PM EST at the end of the
          return window.
        </div>
      </div>
    </DialogModal>
  );
}

export default AffidavitReturnsUpdatedSuccess;
