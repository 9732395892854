import API from '../../shared-react-components/providers/components/api';
import {
  IDENTITY_CONFIG,
  COMPCOPY_IDENTITY_CONFIG,
} from '../../utils/authConst';

//
//
//  ListApi - docs:  https://randomhouse.app.box.com/file/643847892147
//
//

class AccountApi extends API {
  constructor(props) {
    const { appID } = props;

    super(props);

    this.appID = appID;

    // console.log('AccountAPI', props);
    if (appID && appID === 'compcopy') {
      this.urlBase = COMPCOPY_IDENTITY_CONFIG.base;
    } else {
      this.urlBase = IDENTITY_CONFIG.base;
    }
    this.errorHandler = props.errorHandler;
  }

  setDebug(debug) {
    this.setDebugValue(debug);
  }

  async login(user, password) {
    this.isDebug() && console.log(`AccountApi:getData`);

    const fd = new FormData();

    fd.set('Ecom_User_ID', user);
    fd.set('Ecom_Password', password);
    fd.set('target', IDENTITY_CONFIG.redirect_uri);

    try {
      const d = await this.post({
        url: `https://idp.dev.penguinrandomhouse.com/nidp/app/login`,
        data: fd,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      this.isDebug() && console.log(`AccountApi:getData Complete`, d);

      return d;
    } catch (e) {
      // console.log('AccountApi:getUser Failure', e);
      this.errorHandler(e);
      return { status: 500, error: e };
    }
  }

  async getUser(userId) {
    // console.log('token', this.sessionToken, this.urlBase);
    // const userId = await this.getCache('userId');

    if (!userId) {
      // console.log('AccountApi:getUser not exist');
      this.errorHandler({ user: { data: { data: { obj: {} } } } });
      return { status: 500, data: { data: { obj: {} } } };
    }

    // const sessionToken = await this.getCache('sessionToken');
    try {
      const d = await this.get({
        url: `${this.urlBase}/user/${this.appID === 'compcopy' ? 'okta/' : ''}`,
        // url: `${this.urlBase}/user/listHeaders`,
        //data: fd,
        // headers: {
        //   Authorization: 'Bearer ' + sessionToken,
        // },
      });
      this.isDebug() && console.log(`get user:getData Complete`, d);
      // console.log('get user data', d);
      return d;
    } catch (e) {
      // console.log('AccountApi:getUser Failure', e);
      this.errorHandler(e);
      return { status: 500, error: e };
    }
  }

  async storeUserId(userId) {
    this.setCache('userId', userId);
  }

  // async setSessionToken(accessToken) {
  //   console.log('accessToken', accessToken);
  //   this.setCache('sessionToken', accessToken);
  // }
  /*
  async getUserByID(data) {
    this.isDebug() && console.log(`EcomApi:getUsers`);

    const headers = {};
    if (sapAccountNo) {
      headers['sapAccountNo'] = sapAccountNo;
    }

    try {
      const d = await this.get({
        url: `${this.urlBase}/ecom/user/customer/users`,
        cacheResults: false,
        headers,
      });
      this.isDebug() && console.log(`EcomApi:getUsers Complete`, d);
      console.log('get users', d)
      return d;
    } catch (e) {
      console.log('EcomApi:getCustomerInfo Failure', e);
      this.errorHandler(e);
      return { status: 500, error: e };
    }
  }
*/
  async getUserInfo(sapAccountNo = false) {
    this.isDebug() && console.log(`EcomApi:getUsers`);

    const headers = {};
    if (sapAccountNo) {
      headers['sapAccountNo'] = sapAccountNo;
    }

    try {
      const d = await this.get({
        url: `${this.urlBase}/ecom/user/customer/users`,
        cacheResults: false,
        headers,
      });
      this.isDebug() && console.log(`EcomApi:getUsers Complete`, d);
      // console.log('get users', d)
      return d;
    } catch (e) {
      //console.log('EcomApi:getCustomerInfo Failure', e);
      this.errorHandler(e);
      return { status: 500, error: e };
    }
  }

  async getCustomerInfo(sapAccountNo = false) {
    this.isDebug() && console.log(`EcomApi:getUsers`);

    const headers = {};
    if (sapAccountNo) {
      headers['sapAccountNo'] = sapAccountNo;
    }

    try {
      const d = await this.get({
        url: `${this.urlBase}/ecom/user/customer/info`,
        cacheResults: false,
        headers,
      });
      this.isDebug() && console.log(`EcomApi:getCustomerInfo Complete`, d);
      return d;
    } catch (e) {
      //console.log('EcomApi:getCustomerInfo Failure', e);
      this.errorHandler(e);
      return { status: 500, error: e };
    }
  }

  async logout(sapAccountNo = false) {
    this.isDebug() && console.log(`EcomApi:logout`);

    const headers = {};
    if (sapAccountNo) {
      headers['sapAccountNo'] = sapAccountNo;
    }

    try {
      const d = await this.get({
        url: `${this.urlBase}/ecom/user/logout`,
        cacheResults: false,
        headers,
      });
      this.isDebug() && console.log(`EcomApi:logout Complete`, d);
      return d;
    } catch (e) {
      //console.log('EcomApi:logout Failure', e);
      this.errorHandler(e);
      return { status: 500, error: e };
    }
  }
}

export default AccountApi;
