import WordPressPostLink from './WordPressPostLink';

const PostDigest = ({ post }) => {
  return (
    <WordPressPostLink
      post={post}
      className="d-block fort-book fs-13px lh-18px ls--015 mt-1"
      inheritTitle={true}
    ></WordPressPostLink>
  );
};

export default PostDigest;
