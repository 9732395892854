import Stepper from "react-stepper-horizontal";
import "./customerenrolment.scss";
import { steps } from "./shared";

const CustomerEnrolmentLanding = () => {
  return (
    <>
      <div className="container mb-4 mt-1 documents-container p-4">
        <div className="stepper-container welcome-page-stepper">
          <p className="fs-16px">Overview of New Customer Enrollment process</p>
          <Stepper
            steps={steps}
            activeStep={null}
            circleFontColor="#aaaaaa"
            defaultColor="#FFF"
            defaultBorderColor="#DDDDDD"
            circleFontSize={12}
            titleFontSize={12}
            defaultTitleColor="#222222"
            size={25}
            defaultBorderStyle="solid"
            defaultBorderWidth={2}
            lineMarginOffset={0}
          />
        </div>
      </div>
      <div className="container my-4 text-center">
        <p className="fs-16px">
          If you have any questions, please contact us at our toll free number
        </p>
        <p className="fs-22px">1-866-761-6685</p>
        <p className="fs-16px">or via email at</p>
        <p className="PH7 link">
          <a href="mailto:newacccount@penguinrandomhouse.com">
            newacccount@penguinrandomhouse.com
          </a>
        </p>
      </div>
    </>
  );
};

export default CustomerEnrolmentLanding;
