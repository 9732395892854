import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import {
  Container,
  Icon,
  Loader,
  Modal,
  Grid,
  Dropdown,
  Input,
  Form,
  Popup,
} from "semantic-ui-react";
import { toast, useToastContainer } from "react-toastify";
import _ from "lodash";
import {
  useGetDeliveriesDetailQuery,
  useGetDeliveriesOverviewQuery,
  useLazyGetDeliveriesDetailQuery,
  useLazyDownloadStatementQuery,
  useDownloadStatementMutation,
} from "../../api/ecomApi";
import { Divider } from "../../components/elements/elements";
import PageStandard from "../../components/elements/PageStandard";
import useDirtyHandler from "../../shared-react-components/components/hc/useDirtyHandler";
import LazyImage from "../../utils/LazyImage";
import {
  formatDate,
  formatDateAsMonth,
  formatDateAsNextMonth,
  formatDateDashes,
} from "../../utils/utilities";
import DeliveryCarton from "./components/DeliveryCarton";
import { Export, ExportCSV, ExportMultiple } from "../../utils/Export";
import "./StatementDetail.scss";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH, TOOLTIPS } from "../../utils/const";
import useCart from "../../components/hc/useCart";
import useDetectPrint from "use-detect-print";
import { BackToTopButton } from "../../components/common/BackToTopButton";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";
import { contentsColorConverter } from "../orderinvoice/DeliveriesTab";
import {
  useGetStatementsDetailQuery,
  useLazyGetStatementsDetailQuery,
} from "../../api/ecomApi";
import { create } from "lodash";
import { set } from "lscache";
import NoAccessMessage from "../../components/common/NoAccessMessage";
import ToastGenerator from "../../shared-react-components/utils/ToastGenerator";

const deliveryDetail = (props) => {
  const isPrinting = useDetectPrint();
  const cart = useCart();
  const history = useHistory();
  const match = useParams();
  const statementNo = props.match.params.statementNo;
  const dirtyHandler = useDirtyHandler();
  const [order, setOrder] = useState("shipTo");
  const [displayGroups, setDisplayGroups] = useState(false);
  const [printGroups, setPrintGroups] = useState(false);
  const [performGetStatementsDetail, getStatementsDetailResult] =
    useLazyGetStatementsDetailQuery();
  const [performDownloadStatement, getDownloadStatment] =
    useDownloadStatementMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [isOverDue, setIsOverDue] = useState(false);
  const [statementDetail, setStatementDetail] = useState(false);
  const [deliveryDetail, setDeliveryDetail] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [lastCollapsed, setLastCollapsed] = useState(false);
  const [nextCollapsed, setNextCollapsed] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchItems, setSearchItems] = useState(0);
  const [visibility, setVisibility] = useState([]);
  const [groupSortOrder, setGroupSortOrder] = useState([]);
  const [show, setShow] = useState(true);

  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });

  // const y = useGetDeliveriesOverviewQuery({});
  //statementDate
  async function downloadStatement(statementDate) {
    //const result = await performDownloadStatement({statementDate});
    if (statementDetail?.summary?.hasPdf === true) {
      const result = await performDownloadStatement({
        statementDate: statementDate,
      });
      if (result.data === 404) {
        ToastGenerator(
          {
            text: "No statement currently available for download.",
            textClassName: "fort-book",
          },
          { autoClose: 3000, closeOnClick: true }
        );
        /*
        toast.info("No statement currently available for download.", {
          position: toast.POSITION.TOP_CENTER,
          textClassName: "fort-book",
          hideProgressBar: true,
          icon: false,
          autoClose: 1500,
        });*/
      }
    }
    return true;
  }

  useEffect(() => {
    if (cart?.customerInfo?.currentUser?.roleNo === "W9") {
    } else {
      return;
    }

    window.scrollTo(0, 0);
    setIsLoading(true);
    const loaddeliveryDetail = async () => {
      const result = await performGetStatementsDetail({
        statementDate: match.statementNo,
      });
      if (result.data?.status === "OK") {
        //const groupsToclone = groupBy(result.data.data.docs, 'shipTo');
        setDisplayGroups(_.cloneDeep(groupBy(result.data.data.docs, "shipTo")));
        setPrintGroups(_.cloneDeep(groupBy(result.data.data.docs, "none")));
        setVisibility(Array(result.data.data.docs.length).fill(true));
        //setVisibility(createVisible(result.data.data.docs, 'shipTo'));_.cloneDeep(originalArray);
        setStatementDetail(result.data.data);
      }
      setIsLoading(false);
    };
    loaddeliveryDetail();
    return () => {};
  }, []);

  useEffect(() => {
    //window.scrollTo(0, 0);
    statementDetail && setDisplayGroups(groupBy(statementDetail?.docs, order));
    setVisibility(visibility.fill(true));
    //setVisibility(Array(statementDetail?.docs).fill(true));
  }, [order]);

  useEffect(() => {
    //console.log('openclose visibility', visibility);  // This will log the updated state
  }, [visibility]);

  const downloadOptions = [
    {
      key: 50,
      text: "Print",
      value: 50,
      icon: { name: "print", color: "orange" },
      onClick: () => {
        setTimeout(() => {
          window.print();
        }, 500);
      },
    },
    {
      key: 75,
      text: "Download PDF",
      value: 50,
      icon: { name: "print", color: "orange" },
      disabled: !statementDetail?.summary?.hasPdf,
      /*
      onClick: () => {
        setTimeout(() => {
          window.print();
        }, 500);
      },*/
      onClick: () => downloadStatement(statementDetail?.summary?.statementDate),
    },
    {
      key: 100,
      text: "Download Excel",
      value: 100,
      icon: { name: "file excel outline", color: "orange" },
      onClick: () => exportCart("excel"),
    },

    {
      key: 125,
      text: "Download CSV",
      value: 100,
      icon: { name: "file outline", color: "orange" },
      onClick: () => exportCart("csv"),
    },
  ];

  const salesOrders = [];
  const invoices = [];
  if (deliveryDetail) {
    deliveryDetail.orderNos.map((so, idx) => {
      salesOrders.push({
        key: so,
        text: so,
        value: so,
        onClick: () => {
          history.push({ pathname: `/orders/${so}` });
        },
      });
    });

    deliveryDetail.invoiceNos.map((ivno, idx) => {
      invoices.push({
        key: ivno,
        text: ivno,
        value: ivno,
        onClick: () => {
          history.push({ pathname: `/invoices/${ivno}` });
        },
      });
    });
  }

  const exportCart = (fileType) => {
    if (fileType === "excel") {
      let list = { lists: [] };
      /*
      printGroups.forEach((group) => {
      let groupArray = [];
      console.log('group', group);
      const accountNumber = statementDetail.summary.payer;
      const index = cart?.customerInfo?.shipTos?.findIndex((obj => obj.accountNo == accountNumber));
      group.forEach((doc) => {
        groupArray.push({
          "Date Created": doc.createdDate,
          "Document Number": doc.docNumber,
          "Document Type": doc.docTypeDesc,
          "PO/Claim # Reference": doc.refNumber,
          "Ship-To Account": doc.shipTo,
          "Due Date": doc.dueDate,
          "Amount": formatWithDollarSign(doc.amount)
        });
      });
      list.lists.push([]);
      list.lists.push([{ "": `${accountNumber} ${cart?.customerInfo?.shipTos[index]?.address.name1}, ${cart?.customerInfo?.shipTos[index]?.address.city}, ${cart?.customerInfo?.shipTos[index]?.address.state}`}]);
      list.lists.push(groupArray);
      console.log(list);
    });
    */
      printGroups.forEach((group) => {
        let groupArray = [];
        console.log("group", group);
        const accountNumber = statementDetail.summary.payer;
        const index = cart?.customerInfo?.shipTos?.findIndex(
          (obj) => obj.accountNo == accountNumber
        );
        group.forEach((doc) => {
          groupArray.push({
            "Date Created": formatDateDashes(doc.createdDate),
            "Document Number": doc.docNumber,
            "Document Type": doc.docTypeDesc,
            "PO/Claim # Reference": doc.refNumber,
            "Ship-To Account": doc.shipTo,
            "Due Date": formatDateDashes(doc.dueDate),
            Amount: formatForExcel(doc.amount),
          });
        });
        //list.lists.push([]);
        //list.lists.push([{ "": `${accountNumber} ${cart?.customerInfo?.shipTos[index]?.address.name1}, ${cart?.customerInfo?.shipTos[index]?.address.city}, ${cart?.customerInfo?.shipTos[index]?.address.state}`}]);
        list.lists.push(groupArray);
      });

      // Add the structured group to list.lists

      //Meta data, ignoring for now
      const details = {
        fileName: `statement-detail-${statementDetail.summary.statementDate}-${statementDetail.summary.payer}`,
        metadata: [
          [
            `${formatDateAsMonth(
              statementDetail?.summary?.statementDate
            )} Statement`,
          ],
          //["Statement"],
          [
            "Created Date",
            `${formatDateAsNextMonth(statementDetail?.summary?.statementDate)}`,
          ],
          ["Account Number", statementDetail.summary.payer],
          [""],
          ["Bill To", excelAddress(cart?.customerInfo?.customer.address)],
          ["Remit To", excelAddress(statementDetail?.remitAddress)],
          [""],
          ["Payment Information"],
          [
            "Invoices Due",
            formatWithDollarSign(statementDetail.summary.invoicesDue),
          ],
          ["Debits", formatWithDollarSign(statementDetail.summary.debits)],
          [
            "Credits and unapplied cash",
            formatWithDollarSign(statementDetail.summary.creditsCashTotal),
          ],
          ["Total Due", formatWithDollarSign(statementDetail.summary.totalDue)],
          ["Account Summary"],
          [
            "Current Due",
            formatWithDollarSign(statementDetail.summary.currentDue),
          ],
          [
            "Overdue 1-30 days",
            formatWithDollarSign(statementDetail.summary.overdue30),
          ],
          [
            "Overdue 31-60 days",
            formatWithDollarSign(statementDetail.summary.overdue60),
          ],
          [
            "Overdue 61-90 days",
            formatWithDollarSign(statementDetail.summary.overdue90),
          ],
          [
            "Overdue 90+ days",
            formatWithDollarSign(statementDetail.summary.overdue90plus),
          ],
          [
            "Future Due",
            formatWithDollarSign(statementDetail.summary.futureDue),
          ],
          [
            "Open Debits",
            formatWithDollarSign(statementDetail.summary.openDebits),
          ],
          [
            "Open Credits",
            formatWithDollarSign(statementDetail.summary.openCredits),
          ],
          [
            "Unapplied Cash",
            formatWithDollarSign(statementDetail.summary.unappliedCash),
          ],
          [
            "Total Account Balance",
            formatWithDollarSign(statementDetail.summary.accountBalance),
          ],
        ],
        /*
        list: printGroups
        .map((group, groupIndex) => {
          return group.map((doc, docIndex) => {
            return {
              "Date Created": doc.createdDate,
              "Document Type": doc.docTypeDesc,
              "Ship-To Account": doc.shipTo,
              "Due Date": doc.dueDate,
              "Amount": doc.amount,
            };
          });
        })
        .flat(),
        */
        list: list,
      };

      const detailsNoMeta = {
        fileName: `statement-detail-${statementDetail.summary.statementDate}-${statementDetail.summary.payer}`,
        metadata: [],
        list: list,
      };

      ExportMultiple.DocumentDetails(detailsNoMeta);
    } else if (fileType === "csv") {
      let list = [];
      printGroups.forEach((group) => {
        let groupArray = [];
        console.log("group", group);
        const accountNumber = statementDetail.summary.payer;
        const index = cart?.customerInfo?.shipTos?.findIndex(
          (obj) => obj.accountNo == accountNumber
        );
        group.forEach((doc) => {
          groupArray.push({
            "Date Created": formatDateDashes(doc.createdDate),
            "Document Number": doc.docNumber,
            "Document Type": doc.docTypeDesc,
            "PO/Claim # Reference": doc.refNumber,
            "Ship-To Account": doc.shipTo,
            "Due Date": formatDateDashes(doc.dueDate),
            Amount: formatForExcel(doc.amount),
          });
        });
        //list.lists.push([]);
        //list.lists.push([{ "": `${accountNumber} ${cart?.customerInfo?.shipTos[index]?.address.name1}, ${cart?.customerInfo?.shipTos[index]?.address.city}, ${cart?.customerInfo?.shipTos[index]?.address.state}`}]);
        list.push(groupArray);
        console.log("list", list);
      });
      const details = {
        fileName: `statement-detail-${statementDetail.summary.statementDate}-${statementDetail.summary.payer}`,
        metadata: [],

        list: list.flat(),
        /*deliveryDetail.cartons
          .map((carton, cartonIndex) => {
            return carton.items.map((item) => {
              return {
                "Carton #": carton.cartonSeq,
                "Estimated Delivery Date": carton.deliveryDate,
                Shipper: carton.shipper,
                "Tracking Number": carton.trackingCode,
                "Tracking Number": carton.trackingLink,
                Title: item.title,
                Author: item.author,
                ISBN: item.isbn,
                Format: item.format,
                "On Sale": item.onsale,
                "Carton Qty": item.cartonQuantity,
                Quantity: item.qty,
              };
            });
          })
          .flat()*/
      };
      ExportCSV.DocumentDetails(details);
    }
  };

  const StatementRemitTo = (props) => {
    const { address } = props;
    // const st = cart.customerInfo.shipToAccountNos.filter(
    //   (st) => st.accountNo === shipTo
    // );
    // if (st.length === 1) {
    console.log("address", address);
    if (address) {
      return (
        <div className='d-flex flex-column address-fix'>
          <div>{address.name1}</div>
          {address?.name2 && <div>{address.name2}</div>}
          {address?.name3 && <div>{address.name3}</div>}
          {address?.poBox && <div>P.O. Box {address.poBox}</div>}
          <div>{address.street}</div>
          <div>
            {address.city}
            {address.state ? `, ${address.state}` : ""} {address.postalCode}{" "}
          </div>
          <div>{address.countryName}</div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const StatementBillTo = (props) => {
    const { address } = props;
    // const st = cart.customerInfo.shipToAccountNos.filter(
    //   (st) => st.accountNo === shipTo
    // );
    // if (st.length === 1) {
    console.log("address", address);
    if (address) {
      return (
        <div className='d-flex flex-column address-fix'>
          <div>{address.name1}</div>
          {address?.name2 && <div>{address.name2}</div>}
          {address?.name3 && <div>{address.name3}</div>}
          {address?.poBox && <div>P.O. Box {address.poBox}</div>}
          <div>{address.street}</div>
          <div>
            {address.city}
            {address.state ? `, ${address.state}` : ""} {address.postalCode}{" "}
          </div>
          <div>{address.countryName}</div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const excelAddress = (address) => {
    console.log(excelAddress);
    console.log(address.poBox);
    console.log(address.poBox !== null);
    console.log(address.poBox !== "null");
    console.log(typeof address.poBox);
    return `${address.name1}, 
${address.name2 !== null ? `${address.name2},` : ""} 
${address.name3 !== null ? `${address.name3},` : ""}
${address.poBox !== null ? `${address.poBox},` : ""}
${address.city}, 
${address.state}, 
${address.postalCode}`;
  };

  const orderFilter = [
    {
      key: 1,
      text: "Group by: Ship-to Location",
      value: "shipTo",
    },
    {
      key: 2,
      text: "Group by: Document Type",
      value: "docTypeSort",
    },

    {
      key: 3,
      text: "Group by: All Documents",
      value: "none",
    },
  ];

  const docTypeFilter = [
    {
      key: 1,
      value: "INVOICE_DEBIT",
      text: "Invoices & Debits",
    },
    {
      key: 2,
      value: "CREDIT_CASH",
      text: "Credits & Unapplied Cash",
    },
  ];

  const getTextByValue = (value, filter) => {
    const obj = filter.find((item) => item.value === value);
    return obj ? obj.text : null;
  };

  function groupBy(arr, key) {
    const groupsUpdate = {};
    if (key === "none") {
      return [arr];
    }
    arr.forEach((obj) => {
      const group = obj[key];
      if (!groupsUpdate[group]) {
        groupsUpdate[group] = [];
      }
      groupsUpdate[group].push(obj);
    });
    const groupWithInfo = [groupsUpdate];
    groupWithInfo.push({ sortType: key });
    return Object.values(groupsUpdate);
  }

  function cleanDocTypeHeader(name) {
    const stringsToRemove = ["D-U-N-S", "EIN#", "GST#"];

    let result = name;
    stringsToRemove.forEach((str) => {
      const regex = new RegExp(str, "g");
      result = result.replace(regex, "");
    });

    // Remove any leading spaces
    result = result.trimStart();

    return result;
  }

  function showGroupHeader(group) {
    let groupHeader = {};
    if (order === "shipTo") {
      groupHeader = {
        title: group[0].shipTo,
        total: statementDetail.docSortTotals[group[0].shipTo],
      };
    } else if (order === "docTypeSort") {
      groupHeader = {
        title: getTextByValue(group[0].docTypeSort, docTypeFilter),
        total: statementDetail.docSortTotals[group[0].docTypeSort],
      };
    } else {
      groupHeader = {
        title: "All Documents",
        total: statementDetail?.summary?.accountBalance,
      };
    }
    return groupHeader;
  }

  const updateDisplayGroup = (groupIndex, updatedGroup) => {
    setDisplayGroups((prevDisplayGroups) => {
      const newDisplayGroups = [...prevDisplayGroups];
      newDisplayGroups[groupIndex] = updatedGroup;
      return newDisplayGroups;
    });
  };

  const updateDisplayGroupItem = (groupIndex, field) => {
    const sortOrder = groupSortOrder[groupIndex]?.[field]?.order || "asc";
    const updatedGroup = [...displayGroups[groupIndex]];
    const newgroup = [...updatedGroup].sort((a, b) => {
      if (a[field] < b[field]) {
        return sortOrder === "desc" ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return sortOrder === "desc" ? 1 : -1;
      }
      return 0;
    });
    setGroupSortOrder((prevGroupSortOrder) => {
      const newGroupSortOrder = [...prevGroupSortOrder];
      if (!newGroupSortOrder[groupIndex]) {
        newGroupSortOrder[groupIndex] = {};
      }
      newGroupSortOrder[groupIndex][field] = {
        order: sortOrder === "asc" ? "desc" : "asc",
      };
      Object.keys(newGroupSortOrder[groupIndex]).forEach((key) => {
        if (key !== field) {
          delete newGroupSortOrder[groupIndex][key];
        }
      });
      return newGroupSortOrder;
    });
    updateDisplayGroup(groupIndex, newgroup);
  };

  function createVisible(arr, key) {
    const groups = {};
    arr.forEach((obj) => {
      const group = obj[key];
      if (!groups[group]) {
        groups[group] = [];
      }
      groups[group].push({ visible: true });
    });
    return Object.values(groups);
  }

  const openCloseGroup = (index) => {
    if (index >= 0 && index < visibility.length) {
      setVisibility((prevArray) =>
        prevArray.map((value, i) => (i === index ? !value : value))
      );
    }
  };

  const NoStatementFound = (props) => {
    return (
      <div>
        <Container className='pt-5 pb-5 ml-auto mr-auto'>
          <div className='statement-not-found-header ml-auto mr-auto'>
            Statement not found.
          </div>
          <div className='statement-not-found-text ml-auto mr-auto'>
            Please check the <Link to='/statements'>Statements page</Link> for
            available documents.
          </div>
        </Container>
      </div>
    );
  };

  function formatForExcel(number) {
    // Check if the input is a number
    if (typeof number !== "number") {
      throw new Error("Input must be a number");
    }

    // If you want to round it to two decimal places
    return parseFloat(number.toFixed(2));
  }

  function formatWithDollarSign(number) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal", // This is the default style, so it's optional
      minimumFractionDigits: 2, // Minimum number of decimal places to always display
      maximumFractionDigits: 2, // Maximum number of decimal places to display
    });
    const absoluteFormattedNumber = formatter.format(Math.abs(number));
    if (number < 0) {
      // Use Math.abs to convert the number to positive for display, then format it with two decimal places

      // Prepend '-$' to the formatted number
      return `-$${absoluteFormattedNumber}`;
    } else {
      // If the number is positive, just format it with two decimal places and prepend '$'
      return `$${absoluteFormattedNumber}`;
    }
  }

  return (
    <div>
      {console.log("download pdf", downloadPDF)}
      <PageStandard
        headerType='loggedIn'
        containerFluid={true}
        cartBadge={true}
      >
        <div className='dd-container'>
          {isLoading && <Loader style={{ display: "flex" }} inverted />}

          {!isLoading &&
            !statementDetail &&
            cart?.customerInfo?.currentUser?.roleNo === "W9" && (
              <Container className='d-flex flex-column mt-4'>
                <NoStatementFound />
              </Container>
            )}

          {cart?.customerInfo?.currentUser?.roleNo !== "W9" && (
            <NoAccessMessage />
          )}

          {!isLoading &&
            statementDetail &&
            cart?.customerInfo?.currentUser?.roleNo === "W9" && (
              <div>
                <div className='dd-header bg-gray-100 pb-4'>
                  <Container className='d-flex flex-column'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex flex-row mt-4 mb-1'>
                        {!isPrinting && (
                          <Icon
                            color='black'
                            name='file alternate'
                            size='large'
                            className='statement-icon-spacer'
                          />
                        )}
                        <div>
                          <div className='text-gray-900 fort-book fs-24px lh-29px'>
                            {formatDateAsMonth(
                              statementDetail?.summary?.statementDate
                            )}{" "}
                            Statement
                          </div>
                        </div>
                        {!isMobile && (
                          <Dropdown
                            // selection
                            className='dd-dropdown dd-hide-in-print'
                            style={{ marginLeft: "auto" }}
                            selection
                            onClick={() => {
                              setDropdownOpen(true);
                              // setstate({ ...state, isDropdownOpen: true })
                            }}
                            trigger={
                              <div className='od-text-data-book'>
                                Download or Print
                              </div>
                            }
                            disabled={isLoading}
                            options={downloadOptions}
                            direction='left'
                            open={isDropdownOpen}
                            onClose={() => {
                              setDropdownOpen(false);
                              // setstate({ ...state, isDropdownOpen: false })
                            }}
                          />
                        )}
                      </div>
                      {isMobile && !isPrinting ? (
                        <div className='fs-13px lh-18px mb-1 dd-delivery-header '>
                          <div className='d-flex flex-row'>
                            <div className='fort-bold w-33'> Delivery #:</div>{" "}
                            <div>{deliveryDetail.deliveryNo}</div>
                          </div>
                          <div className='d-flex flex-row'>
                            <div className='fort-bold w-33'>Shipped on:</div>{" "}
                            {formatDate(deliveryDetail.shipDate, false)}
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`fs-14px fort-medium lh-18px mb-1 dd-delivery-header ${
                            isPrinting ? "printing" : ""
                          }`}
                        >
                          <span className=''>
                            <span className='fort-medium'>Date:</span>{" "}
                            <span className='fort-book'>
                              {/*statementDetail?.summary?.statementDate*/}
                              {formatDateAsNextMonth(
                                statementDetail?.summary?.statementDate
                              )}
                            </span>
                            {statementDetail?.summary?.duns ? (
                              <span style={{ marginLeft: "16px" }}>
                                <span className='fort-medium'>D-U-N-S:</span>{" "}
                                <span className='fort-book'>
                                  {cleanDocTypeHeader(
                                    statementDetail?.summary?.duns
                                  )}
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                            {statementDetail?.summary?.ein ? (
                              <span style={{ marginLeft: "16px" }}>
                                <span className='fort-medium'>EIN#:</span>{" "}
                                <span className='fort-book'>
                                  {cleanDocTypeHeader(
                                    statementDetail?.summary?.ein
                                  )}
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                            {statementDetail?.summary?.gst ? (
                              <span style={{ marginLeft: "16px" }}>
                                <span className='fort-medium'>GST#:</span>{" "}
                                <span className='fort-book'>
                                  {cleanDocTypeHeader(
                                    statementDetail?.summary?.gst
                                  )}
                                </span>
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      )}

                      <Divider tight className='mb-1' />

                      <Grid className='mt-1'>
                        <Grid.Row>
                          <Grid.Column
                            mobile={isPrinting ? 8 : 16}
                            tablet={9}
                            computer={10}
                            largeScreen={12}
                            widescreen={12}
                            print={8}
                            className={"statement-billing"}
                            /*style={{
                           border: "1px solid black",
                          overflow: "hidden",
                          }}*/
                          >
                            <div className='fs-14px lh-18px mt-1 mb-3'>
                              <div>
                                <div className='d-flex flex-row mb-3'>
                                  <div
                                    className={`fort-bold statement-label-color ${
                                      isMobile && !isPrinting ? "w-33" : "mr-1"
                                    }`}
                                  >
                                    Account #:{" "}
                                    <span className='account-number'>
                                      {cart?.customerInfo?.customer?.accountNo}
                                    </span>
                                  </div>
                                </div>
                                <div className='billing-info-container'>
                                  <div className='fs-13px lh-18px mb-1 mr-10 remit-print-margin'>
                                    <div
                                      className={`d-flex ${
                                        isMobile && !isPrinting
                                          ? "flex-row"
                                          : "flex-column"
                                      }`}
                                    >
                                      <div
                                        className={`fort-bold mb-1 statement-label-color ${
                                          isMobile && !isPrinting ? "w-33" : ""
                                        }`}
                                      >
                                        BILL TO:
                                      </div>
                                      <StatementBillTo
                                        address={
                                          cart?.customerInfo?.customer?.address
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className='fs-13px lh-18px mb-1 remit-print-margin'>
                                    <div
                                      className={`d-flex ${
                                        isMobile && !isPrinting
                                          ? "flex-row"
                                          : "flex-column"
                                      }`}
                                    >
                                      <div
                                        className={`fort-bold mb-1 statement-label-color ${
                                          isMobile && !isPrinting ? "w-33" : ""
                                        }`}
                                      >
                                        REMIT TO:
                                      </div>
                                      <StatementRemitTo
                                        address={statementDetail?.remitAddress}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            mobile={isPrinting ? 4 : 16}
                            tablet={7}
                            computer={6}
                            largeScreen={2}
                            widescreen={4}
                            className={"statement-billing"}
                            // style={{ border: "1px solid black" }} name='question circle outline'
                          >
                            <div className='payment-info-box fs-14px lh-18px'>
                              <div className='payment-info-box-header pl-3 pr-3 d-flex flex-row'>
                                Payment Information
                              </div>
                              <div className='payment-info-inner'>
                                <div className='d-flex flex-row mb-2 ml-3 mr-3 mt-2'>
                                  <div className='d-flex'>
                                    <div>Invoices Due</div>
                                    <Popup
                                      content={
                                        TOOLTIPS["statementDetailInvoiceDue"]
                                      }
                                      trigger={
                                        <Icon
                                          name='question circle outline'
                                          className='invoice-due-icon'
                                        />
                                      }
                                      position='top center'
                                      offset={[0, 10]}
                                    />
                                  </div>
                                  <div style={{ marginLeft: "auto" }}>
                                    {formatWithDollarSign(
                                      statementDetail?.summary?.invoicesDue
                                    )}
                                  </div>
                                </div>
                                <div className='d-flex flex-row mb-2 ml-3 mr-3'>
                                  <div>Debits</div>
                                  <div style={{ marginLeft: "auto" }}>
                                    {formatWithDollarSign(
                                      statementDetail?.summary?.openDebits
                                    )}
                                  </div>
                                </div>
                                <div className='d-flex flex-row mb-2 ml-3 mr-3'>
                                  <div>Credits & Unapplied Cash </div>
                                  <div style={{ marginLeft: "auto" }}>
                                    {formatWithDollarSign(
                                      statementDetail?.summary?.creditsCashTotal
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='total-due-rule' />
                              <div className='d-flex flex-row ml-3 mr-3 mt-2 mb-2 total-due'>
                                <div>Total Due </div>
                                <div style={{ marginLeft: "auto" }}>
                                  {formatWithDollarSign(
                                    statementDetail?.summary?.totalDue
                                  )}
                                </div>
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className='account-summary-row'>
                          <Grid.Column
                            tablet={16}
                            computer={16}
                            largeScreen={16}
                            widescreen={16}
                          >
                            <div className='payment-info-box info-box-margin'>
                              <div className='account-info-box-header d-flex pl-3 pr-3 pt-1 pb-1'>
                                <div className='account-info-box-header-text'>
                                  Account Summary
                                </div>
                                {statementDetail?.summary.pastDueMessage ===
                                "YOUR ACCOUNT IS NOW OVERDUE" ? (
                                  <div className='txt-align-right overdue-notice'>
                                    <Icon
                                      name='exclamation triangle'
                                      style={{ color: "#C2929" }}
                                    />
                                    YOUR ACCOUNT IS OVERDUE
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className='d-flex flex-row mb-2 ml-3 mr-3 mt-2'>
                                <div
                                  style={{ width: "50%" }}
                                  className='account-summary-container mr-4'
                                >
                                  <span className='account-summary-subheaders'>
                                    Open Invoice Balances
                                  </span>
                                  <div className='account-summary-line-items account-summary-line-items-border'>
                                    <div className='account-summary-line-items-item'>
                                      Current Due
                                    </div>
                                    <div className='account-summary-line-items-item txt-align-right'>
                                      {formatWithDollarSign(
                                        statementDetail?.summary?.currentDue
                                      )}
                                    </div>
                                  </div>
                                  <div className='account-summary-line-items account-summary-line-items-border-thin'>
                                    <div className='account-summary-line-items-item'>
                                      Overdue 1-30 days
                                    </div>
                                    <div className='account-summary-line-items-item txt-align-right'>
                                      {formatWithDollarSign(
                                        statementDetail?.summary?.overdue30
                                      )}
                                    </div>
                                  </div>
                                  <div className='account-summary-line-items account-summary-line-items-border-thin'>
                                    <div className='account-summary-line-items-item'>
                                      Overdue 31-60 days
                                    </div>
                                    <div className='account-summary-line-items-item txt-align-right'>
                                      {formatWithDollarSign(
                                        statementDetail?.summary?.overdue60
                                      )}
                                    </div>
                                  </div>
                                  <div className='account-summary-line-items account-summary-line-items-border-thin'>
                                    <div className='account-summary-line-items-item'>
                                      Overdue 61-90 days
                                    </div>
                                    <div className='account-summary-line-items-item txt-align-right'>
                                      {formatWithDollarSign(
                                        statementDetail?.summary?.overdue90
                                      )}
                                    </div>
                                  </div>
                                  <div className='account-summary-line-items account-summary-line-items-border'>
                                    <div className='account-summary-line-items-item'>
                                      Overdue 90+ days
                                    </div>
                                    <div className='account-summary-line-items-item txt-align-right'>
                                      {formatWithDollarSign(
                                        statementDetail?.summary?.overdue90plus
                                      )}
                                    </div>
                                  </div>
                                  <div className='account-summary-line-items'>
                                    <div className='account-summary-line-items-item'>
                                      Future Due
                                    </div>
                                    <div className='account-summary-line-items-item txt-align-right'>
                                      {formatWithDollarSign(
                                        statementDetail?.summary?.futureDue
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  style={{ width: "50%" }}
                                  className='account-summary-container'
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      height: "200px",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <span className='account-summary-subheaders'>
                                        Other Open Items
                                      </span>
                                      <div className='account-summary-line-items account-summary-line-items-border'>
                                        <div className='account-summary-line-items-item'>
                                          Open Debits
                                        </div>
                                        <div className='account-summary-line-items-item txt-align-right'>
                                          {formatWithDollarSign(
                                            statementDetail?.summary?.openDebits
                                          )}
                                        </div>
                                      </div>
                                      <div className='account-summary-line-items account-summary-line-items-border'>
                                        <div className='account-summary-line-items-item'>
                                          Open Credits
                                        </div>
                                        <div className='account-summary-line-items-item txt-align-right'>
                                          {formatWithDollarSign(
                                            statementDetail?.summary
                                              ?.openCredits
                                          )}
                                        </div>
                                      </div>
                                      <div className='account-summary-line-items'>
                                        <div className='account-summary-line-items-item'>
                                          Unapplied Cash
                                        </div>
                                        <div className='account-summary-line-items-item txt-align-right'>
                                          {formatWithDollarSign(
                                            statementDetail?.summary
                                              ?.unappliedCash
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className='total-account-balance d-flex'>
                                      <div className='total-account-balance-label'>
                                        TOTAL ACCOUNT BALANCE{" "}
                                        <Popup
                                          content={
                                            TOOLTIPS[
                                              "statementDetailAccountDue"
                                            ]
                                          }
                                          trigger={
                                            <Icon
                                              name='question circle outline'
                                              className='total-due-icon'
                                            />
                                          }
                                          position='top center'
                                          offset={[0, 10]}
                                        />
                                      </div>
                                      <div className='txt-align-right balance-due-amount'>
                                        {formatWithDollarSign(
                                          statementDetail?.summary
                                            ?.accountBalance
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </Container>
                </div>
                <Container className='d-flex flex-column mb-8'>
                  <div
                    className={`dd-search-bar d-flex ${
                      isMobile ? "flex-column" : "flex-row"
                    } align-items-center mt-3`}
                  >
                    {searchText !== "" && !isMobile && (
                      <div className='mr-auto'>
                        <span className='fort-bold'>{searchItems}</span> items
                        matching "{searchText}"
                      </div>
                    )}
                    <div
                      className={`ml-auto hide-in-print ${
                        isMobile ? "w-100" : ""
                      }`}
                    >
                      {!isMobile && (
                        <div
                          className='deliveries-shipto-dropdown-container'
                          style={{ marginLeft: ".2em" }}
                        >
                          <Dropdown
                            options={orderFilter}
                            className='od-dropdown'
                            onChange={(_e, { value }) => {
                              setOrder(value);
                            }}
                            value={order}
                            trigger={
                              <div style={{ marginRight: ".5rem" }}>
                                {getTextByValue(order, orderFilter)}
                              </div>
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {searchText !== "" && isMobile && (
                    <div className='mr-auto pt-2'>
                      <span className='fort-bold'>{searchItems}</span> items
                      matching "{searchText}"
                    </div>
                  )}

                  {displayGroups &&
                    displayGroups.map((group, idx) => (
                      <div className='d-flex flex-column'>
                        <div className='d-flex group-container'>
                          <div className='group-header-toggle'>
                            {order !== "none" ? (
                              <Icon
                                //onClick={(e) => openCloseGroup(visibility, idx)}
                                onClick={(e) => {
                                  openCloseGroup(idx);
                                  //setLastCollapsed(!lastCollapsed);
                                }}
                                className='carton-item-toggle fs-24px'
                                style={{
                                  // top: `${colapsed ? "10px" : "10px"}`,
                                  position: "relative",
                                }}
                                color='grey'
                                name={`angle ${
                                  visibility[idx] ? "down" : "right"
                                }`}
                              />
                            ) : (
                              ""
                            )}
                            {console.log("group", group)}
                          </div>
                          <div
                            className='d-flex'
                            style={{
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div className='group-headers'>
                              {showGroupHeader(group)?.title}
                            </div>
                            <div className='group-headers'>
                              Total:{" "}
                              {formatWithDollarSign(
                                showGroupHeader(group)?.total
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          key={idx}
                          className={
                            visibility[idx] == true ? "showMe" : "hideMe"
                          }
                        >
                          <Grid>
                            <Grid.Row
                              className='dd-column-names'
                              style={{ borderBottom: "1px solid #ddd" }}
                            >
                              <Grid.Column className='statement-group-header-text statement-group-col-date-created'>
                                <div className='d-flex group-sub-header'>
                                  <div
                                    onClick={(e) => {
                                      updateDisplayGroupItem(
                                        idx,
                                        "createdDate"
                                      );
                                    }}
                                  >
                                    Date Created
                                  </div>
                                  <div>
                                    {groupSortOrder[idx]?.["createdDate"] && (
                                      <Icon
                                        name={`caret ${
                                          groupSortOrder[idx]?.["createdDate"]
                                            .order === "asc"
                                            ? "up"
                                            : "down"
                                        }`}
                                      />
                                    )}
                                  </div>
                                </div>
                              </Grid.Column>
                              <Grid.Column className='statement-group-header-text statement-group-col-document'>
                                <div className='d-flex group-sub-header'>
                                  Document#
                                </div>
                              </Grid.Column>
                              <Grid.Column className='statement-group-header-text statement-group-col-document-type'>
                                <div className='d-flex group-sub-header'>
                                  <div
                                    onClick={(e) => {
                                      updateDisplayGroupItem(
                                        idx,
                                        "docTypeDesc"
                                      );
                                    }}
                                  >
                                    Document Type
                                  </div>
                                  <div>
                                    {groupSortOrder[idx]?.["docTypeDesc"] && (
                                      <Icon
                                        name={`caret ${
                                          groupSortOrder[idx]?.["docTypeDesc"]
                                            .order === "asc"
                                            ? "up"
                                            : "down"
                                        }`}
                                      />
                                    )}
                                  </div>
                                </div>
                              </Grid.Column>
                              <Grid.Column className='statement-group-header-text statement-group-col-document-type-pov'>
                                <div className='d-flex group-sub-header'>
                                  Reference Document
                                </div>
                              </Grid.Column>
                              <Grid.Column className='statement-group-header-text statement-group-col-document-type-shipto'>
                                <div className='d-flex group-sub-header'>
                                  <div
                                    onClick={(e) => {
                                      updateDisplayGroupItem(idx, "shipTo");
                                    }}
                                  >
                                    Ship-To Account
                                  </div>
                                  <div>
                                    {groupSortOrder[idx]?.["shipTo"] &&
                                      order !== "shipTo" && (
                                        <Icon
                                          name={`caret ${
                                            groupSortOrder[idx]?.["shipTo"]
                                              .order === "asc"
                                              ? "up"
                                              : "down"
                                          }`}
                                        />
                                      )}
                                  </div>
                                </div>
                              </Grid.Column>
                              <Grid.Column className='statement-group-header-text statement-group-col-due-date'>
                                <div className='d-flex group-sub-header'>
                                  <div
                                    onClick={(e) => {
                                      updateDisplayGroupItem(idx, "dueDate");
                                    }}
                                  >
                                    Due Date
                                  </div>
                                  <div>
                                    {groupSortOrder[idx]?.["dueDate"] && (
                                      <Icon
                                        name={`caret ${
                                          groupSortOrder[idx]?.["dueDate"]
                                            .order === "asc"
                                            ? "up"
                                            : "down"
                                        }`}
                                      />
                                    )}
                                  </div>
                                </div>
                              </Grid.Column>
                              <Grid.Column className='statement-group-header-text statement-group-col-amount'>
                                <div className='d-flex'>
                                  <div style={{ marginLeft: "auto" }}>
                                    <div className='d-flex group-sub-header'>
                                      <div
                                        onClick={(e) => {
                                          updateDisplayGroupItem(idx, "amount");
                                        }}
                                      >
                                        Amount
                                      </div>
                                      <div>
                                        {groupSortOrder[idx]?.["amount"] && (
                                          <Icon
                                            name={`caret ${
                                              groupSortOrder[idx]?.["amount"]
                                                .order === "asc"
                                                ? "up"
                                                : "down"
                                            }`}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Grid.Column>
                            </Grid.Row>
                            {group?.map((doc, index) => (
                              <Grid.Row
                                style={{ borderBottom: "1px solid #DDD" }}
                                className='statement-items-row'
                              >
                                <Grid.Column
                                  style={{ padding: "14px" }}
                                  className='statement-group-col-date-created statement-group-item-text'
                                >
                                  {formatDateDashes(doc.createdDate)}
                                </Grid.Column>
                                <Grid.Column
                                  style={{ padding: "14px" }}
                                  className='statement-group-col-document statement-group-item-text'
                                >
                                  {doc.docNumber}
                                </Grid.Column>
                                <Grid.Column
                                  style={{ padding: "14px" }}
                                  className='statement-group-col-document-type statement-group-item-text'
                                >
                                  {doc.docTypeDesc}
                                </Grid.Column>
                                <Grid.Column
                                  style={{ padding: "14px" }}
                                  className='statement-group-col-document-type-pov statement-group-item-text'
                                >
                                  {doc.refNumber}
                                </Grid.Column>
                                <Grid.Column
                                  style={{ padding: "14px" }}
                                  className='statement-group-col-document-type-shipto statement-group-item-text'
                                >
                                  {doc.shipTo}
                                </Grid.Column>
                                <Grid.Column
                                  only=''
                                  style={{ padding: "14px" }}
                                  className='statement-group-col-due-date statement-group-item-text'
                                >
                                  {formatDateDashes(doc.dueDate)}
                                </Grid.Column>
                                <Grid.Column
                                  only=''
                                  style={{ padding: "14px" }}
                                  className='statement-group-col-amount statement-group-item-text'
                                >
                                  {formatWithDollarSign(doc.amount)}
                                </Grid.Column>
                              </Grid.Row>
                            ))}
                          </Grid>
                        </div>
                      </div>
                    ))}
                </Container>
              </div>
            )}
        </div>
        {!isMobile && <BackToTopButton />}
        {isMobile && <BackToTopButtonMobile />}
      </PageStandard>
    </div>
  );
};

export default deliveryDetail;
