import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router";
import { Container, Dropdown, Grid, Icon } from "semantic-ui-react";
import PageStandard from "../../components/elements/PageStandard";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import CustomFilters from "./customFilters";
import "./Search.scss";
import EventTrigger from "../../components/analytics/EventTrigger";
import TitleRowAgGrid from "../../components/common/TitleRowAgGrid";
import ActionMenu from "../../components/common/ActionMenu";
import { Link } from "react-router-dom";
import {
  getPrettyName,
  POST_POST_TYPE,
  translatePostTypeSlug,
} from "../../utils/wordpressHelpers";
import queryString from "query-string";
import withMobile from "../../components/hc/withMobile";
import { MobileOptionsMenu } from "../../components/header/components/MobileOptionsMenu";
import TitleRowMobile from "../../components/common/TitleRowMobile";
import NoticeCard from "../../components/elements/NoticeCard";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";
import { BackToTopButton } from "../../components/common/BackToTopButton";
import { cloneDeep } from "lodash";
import { useHistory } from "react-router-dom";

const pageSizeOptions = [
  {
    key: 50,
    text: "50 per page",
    value: 50,
  },
  {
    key: 100,
    text: "100 per page",
    value: 100,
  },
  {
    key: 150,
    text: "150 per page",
    value: 150,
  },
  {
    key: 200,
    text: "200 per page",
    value: 200,
  },
];
const resultsSortOPtions = [
  {
    key: "relevence asc",
    text: "Relevance",
    value: "relevence_asc",
  },
  {
    key: "onsale desc",
    text: "On Sale - New to Old",
    value: "onsale_desc",
  },
  {
    key: "onsale asc",
    text: "On Sale - Old to New",
    value: "onsale_asc",
  },
  {
    key: "price asc",
    text: "Price - Low to High",
    value: "price_asc",
  },
  {
    key: "price desc",
    text: "Price - High to Low",
    value: "price_desc",
  },
  {
    key: "title asc",
    text: "Title - A to Z",
    value: "title_asc",
  },
];

class Search extends Component {
  constructor(props) {
    super(props);
    const propPage = Number(props.location.search?.split("=")[1] || 1);

    this.state = {
      pageSize: 50,
      overlayNoRowsTemplate:
        "<span style=\"padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;\">This is a custom 'no rows' overlay</span>",
      currentPageNum: propPage,
      totalPages: 0,
      totalTitlesToDisplay: 0,
      rowHeight: props.mobile.isMobile ? 256 : 150,
      currentSort: "relevence_asc",
      searchMsgLine1: "",
      searchMsgLine2: "",
      columnDefs: [
        {
          headerName: "",
          field: "isbn",
          cellRenderer: props.mobile.isMobile
            ? "TitleRowMobile"
            : "TitleRowAgGrid",
          cellRendererParams: {
            country: this.props.cart.customerInfo.customer.country,
            roleNo: this.props.cart?.customerInfo?.currentUser?.roleNo,
            //filters: this.state.filters,
            pushFilters: this.pushFilters,
            callback: this.cartUpdate,
          },
          sortable: true,
          resizable: true,
          cellClass: "first-col-class",
          cellStyle: props.mobile.isMobile
            ? { "padding-left": 0, "padding-right": 0 }
            : "",
          wrapText: true,
        },
        {
          headerName: "",
          field: "title",
          hide: true,
          sortable: true,
        },
        {
          headerName: "",
          field: "author",
          hide: true,
          sortable: true,
        },
        {
          headerName: "",
          field: "formatCode",
          hide: true,
        },
        {
          headerName: "",
          field: "onsaleFilter",
          hide: true,
        },
        {
          headerName: "",
          field: "inventoryFilter",
          hide: true,
        },
        {
          headerName: "",
          field: "relevance",
          hide: true,
          sort: "asc",
        },
        {
          headerName: "",
          field: "price",
          hide: true,
          sortable: true,
        },
        {
          headerName: "",
          field: "onsale",
          hide: true,
          sortable: true,
        },
        {
          headerName: "",
          field: "ageRangeCode",
          hide: true,
        },
        {
          headerName: "",
          field: "divisionCode",
          hide: true,
        },
        {
          headerName: "",
          field: "maturityLevel",
          hide: true,
        },
        {
          headerName: "",
          field: "seriesCode",
          hide: true,
        },
        {
          headerName: "",
          field: "foc",
          hide: true,
        },
        {
          headerName: "",
          field: "catalog",
          hide: true,
        },
        {
          headerName: "",
          field: "isVariant",
          hide: true,
        },
      ],
      rowData: [],
      frameworkComponents: {
        TitleRowAgGrid: TitleRowAgGrid,
        TitleRowMobile: TitleRowMobile,
      },
      context: { componentParent: this, shouldShowMoreInformation: true },
      defaultColDef: {
        flex: 1,
        // wrapText: true,
        autoHeight: true,
        filter: false,
        suppressMenu: true,
      },
      searchResults: this.props.cart.searchResults,
      filterText: "Hide Filters",
      filters: [],
      filterColumnsList: {},
      filtersCount: "",
      searchedTerm: "",
      copyValue: "",
      urlFilters: [],
      variantFilter: true,
      showFilters: false,
      sortMenuVisible: false,
      // urlFiltersApplied: false,
    };
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(0);
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
      this.gridApi.resetRowHeights();
    }
    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
      this.gridApi.resetRowHeights();
    };
    if (this.state.urlFilters.length > 0) {
      const updatedfitlers = this.updateResultsUrlFilters();
      if (updatedfitlers.length > 0) {
        const newUrlFilterModel = this.createFilterModel(updatedfitlers);
        this.updateGrid(newUrlFilterModel);
        this.setState({ urlFilters: [] });
      }
    }
    if (this.state.searchResults?.filters?.length > 0) {
      this.updateGrid(
        this.createFilterModel(this.state.searchResults?.filters)
      );
    }
    window.scrollTo(0, 0);
  };

  addToCart = (isbn, isbnQty) => {
    if (isbnQty > 0) {
      this.props.cart.addItem(isbn, isbnQty);
    }
  };

  /**
   * Search Data passes along any data from hijacking search for title lists, if using regular search searchData should be null
   * @param {*} searchData
   */
  onBtNext = (searchData = null) => {
    const { currentPageNum, pageSize } = this.state;
    const filteredRows = this.gridApi.getModel().rowsToDisplay;
    const filteredNumOfPages = Math.ceil(filteredRows.length / pageSize);

    if (currentPageNum < filteredNumOfPages) {
      this.setState({ currentPageNum: currentPageNum + 1 });
      this.gridApi.paginationGoToNextPage();
      window.scrollTo(0, 0);
      const toPush = {
        pathname: "/search",
        search: `?page=${currentPageNum + 1}`,
      };
      if (!!searchData) {
        toPush.state = {
          searchData,
        };
      }
      this.props.history.push(toPush);
    }
  };

  /**
   * Search Data passes along any data from hijacking search for title lists, if using regular search searchData should be null
   * @param {*} searchData
   */
  onBtPrevious = (searchData = null) => {
    const { currentPageNum, pageSize } = this.state;
    if (currentPageNum > 1) {
      this.setState({ currentPageNum: currentPageNum - 1 });
      this.gridApi.paginationGoToPreviousPage();
      window.scrollTo(0, 0);
      const toPush = {
        pathname: "/search",
        search: `?page=${currentPageNum - 1}`,
      };
      if (!!searchData) {
        toPush.state = {
          searchData,
        };
      }
      this.props.history.push(toPush);
    }
  };

  handlePageSizeChange = (newPageSize) => {
    const { rowData } = this.state;
    this.gridApi.paginationSetPageSize(Number(newPageSize));
    this.setState({ pageSize: newPageSize, currentPageNum: 1 }, () => {
      const filteredRows = this.gridApi.getModel().rowsToDisplay;
      // this.gridApi.setFocusedCell(0,0,'top');
      this.gridApi.paginationGoToPage(0);
      // this.gridApi.redrawRows();
      const filteredNumOfPages = Math.ceil(filteredRows.length / newPageSize);
      this.setState({ totalPages: filteredNumOfPages });
      window.scrollTo(0, 0);
    });
    // this.gridApi.paginationChanged();
  };

  titleSort = (col, sortType) => {
    var sort = [
      {
        colId: col,
        sort: sortType,
      },
    ];
    this.gridApi.setSortModel(sort);
  };

  pushFilters = (isbn) => {
    this.props.cart.storeFilters(this.state.filters);
    this.props.history.push(`/titleinfo/${isbn}`);
  };

  handleSortChange = (e, { value }) => {
    const sortArray = value.split("_");
    this.titleSort(sortArray[0], sortArray[1]);
    this.setState(
      { currentPageNum: 1, currentSort: value },
      this.gridApi.paginationGoToFirstPage()
    );
  };

  getSortTitleByKey = (key) => {
    const item = resultsSortOPtions.find((option) => option.value === key);
    return item && item.text;
  };

  getSortValueByKey = (key) => {
    const item = resultsSortOPtions.find((option) => option.value === key);
    return item && item.value;
  };

  createFilterModel = (filtersArray) => {
    const dFiltersArray = filtersArray;
    const customFilters = {};
    let filterCount = 0;
    this.setState({ filtersCount: 0 });
    for (let i = 0; i < dFiltersArray.length; i++) {
      const dFilter = dFiltersArray[i];
      const filterOptions = dFilter.values;
      const filterValues = [];
      let hasFilter = false;

      for (let j = 0; j < filterOptions.length; j++) {
        if (filterOptions[j].checked === true) {
          if (filterOptions[j].match !== "None") {
            filterCount++;
            if (dFilter.resultsMatchID === "isVariant") {
              filterValues.push(filterOptions[j].match.toString());
            } else {
              filterValues.push(filterOptions[j].match);
            }
          } else {
            filterCount++;
            filterValues.push(null);
          }
          hasFilter = true;
        }
      }
      if (hasFilter === true) {
        const tempObj = {};
        tempObj.type = "set";
        tempObj.values = filterValues;
        customFilters[dFilter.resultsMatchID] = tempObj;
      }
    }
    this.setState({ filtersCount: filterCount });
    return customFilters;
  };

  updateGrid = (filterModel) => {
    this.clearFilters();
    this.gridApi.setFilterModel(filterModel);
    this.gridApi.onFilterChanged();

    // Get grid to run filter operation again
    this.gridApi.onFilterChanged();
    const filteredRows = this.gridApi.getModel().rowsToDisplay;
    this.setState({ totalTitlesToDisplay: filteredRows.length });
    const filteredNumOfPages = Math.ceil(
      filteredRows.length / this.state.pageSize
    );
    this.setState({ totalPages: filteredNumOfPages, currentPageNum: 1 });
  };

  clearFilters = () => {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  };

  updateFilters = () => {
    this.gridApi.onFilterChanged();
  };

  cartUpdate = (carts) => {
    if (this.state.searchResults?.results) {
      const newSR = [...this.state.searchResults.results];
      // let newSR = cloneDeep(this.state.searchResults.results);
      // let newRD = cloneDeep(this.state.rowData);
      if (this.props.cart.isUserPow()) {
        if (this.props.cart.estimateCarts) {
          let eCarts;

          if (carts) {
            if (carts.carts) {
              eCarts = carts.carts;
            } else {
              eCarts = carts;
            }
          } else {
            eCarts = this.props.cart.estimateCarts;
          }

          // console.log("cartUpdsate", eCarts, newSR);
          newSR.map((rslt) => {
            // rslt.cartQuantity = false;
            // rslt.confirmationStatus = false;
            eCarts.forEach((ec) => {
              if (ec.foc === rslt.foc) {
                rslt.confirmationStatus = ec.confirmationStatus;
              }
              ec.items.forEach((ei) => {
                if (ei.isbn === rslt.isbn) {
                  rslt.cartQuantity = ei.quantity;
                  rslt.confirmationStatus = ec.confirmationStatus;
                }
              });
            });
          });
          // newRD.map((rslt) => {
          //   rslt.cartQuantity = false;
          //   rslt.confirmationStatus = false;
          //   eCarts.forEach((ec) => {
          //     if (ec.foc === rslt.foc) {
          //       rslt.confirmationStatus = ec.confirmationStatus;
          //     }
          //     ec.items.forEach((ei) => {
          //       if (ei.isbn === rslt.isbn) {
          //         rslt.cartQuantity = ei.quantity;
          //         rslt.confirmationStatus = ec.confirmationStatus;
          //       }
          //     });
          //   });
          // });
        }
      } else {
        newSR.map((rslt) => {
          rslt.cartQuantity = false;
          this.props.cart.cart.items.forEach((i) => {
            if (i.isbn === rslt.isbn) {
              rslt.cartQuantity = i.quantity;
            }
          });
        });
        // newRD.map((rslt) => {
        //   rslt.cartQuantity = false;
        //   this.props.cart.cart.items.forEach((i) => {
        //     if (i.isbn === rslt.isbn) {
        //       rslt.cartQuantity = i.quantity;
        //     }
        //   });
        // });
      }
      // if (returnResults) {
      //   return newSR;
      // } else {
      // console.log("storing results", newSR);
      this.setState(
        {
          searchResults: {
            ...this.state.searchResults,
            results: newSR,
          },
        },
        () => {
          if (this.gridApi) {
            this.updateGrid(this.createFilterModel(this.state.filters));
            // this.gridApi.onFilterChanged();
          }
          // console.log("new state", this.state);
        }
      );
      // }
    }
  };
  //update filters and then results on single filter click
  updateResults = (e, data = null) => {
    const { filters } = this.state;
    const dFiltersArray = cloneDeep(filters);
    for (let i = 0; i < dFiltersArray.length; i++) {
      if (dFiltersArray[i].resultsMatchID === data?.name) {
        for (let j = 0; j < dFiltersArray[i].values.length; j++) {
          if (dFiltersArray[i].values[j].match === data.value) {
            dFiltersArray[i].values[j].checked = data.checked;
          }
          if (data?.name === "isVariant") {
            if (data?.checked === false) {
              this.setState({ variantFilter: false });
              dFiltersArray[i].values[j].checked = true;
            } else {
              this.setState({ variantFilter: true });
              dFiltersArray[i].values[j].checked = false;
            }
          }
        }
        this.updateGrid(this.createFilterModel(dFiltersArray));
        this.gridApi.deselectAll();
      }
    }
    this.setState({ filters: dFiltersArray });
    if (data?.name === "onsaleFilter") {
      EventTrigger("onsaleFilter", data.value);
    }
  };

  //update filters and then results on single filter click
  updateResultsNoData = (e, data = null) => {
    const { filters } = this.state;
    const dFiltersArray = cloneDeep(filters);
    for (let i = 0; i < dFiltersArray.length; i++) {
      if (dFiltersArray[i].resultsMatchID === data?.name) {
        for (let j = 0; j < dFiltersArray[i].values.length; j++) {
          if (dFiltersArray[i].values[j].match === data.value) {
            dFiltersArray[i].values[j].checked = data.checked;
          }
          if (data?.name === "isVariant") {
            if (data?.checked === false) {
              this.setState({ variantFilter: false });
              dFiltersArray[i].values[j].checked = true;
            } else {
              this.setState({ variantFilter: true });
              dFiltersArray[i].values[j].checked = false;
            }
          }
        }
        this.updateGrid(this.createFilterModel(dFiltersArray));
        this.gridApi.deselectAll();
      }
    }
    this.setState({ filters: dFiltersArray });
    if (data?.name === "onsaleFilter") {
      EventTrigger("onsaleFilter", data.value);
    }
  };

  //update filters and then results coming from sharable URL
  updateResultsUrlFilters = (data) => {
    const { filters, urlFilters } = this.state;
    const dFiltersArray = filters;
    _.each(urlFilters, function (item) {
      for (let i = 0; i < dFiltersArray.length; i++) {
        if (dFiltersArray[i].resultsMatchID === item.filterName) {
          for (let j = 0; j < dFiltersArray[i].values.length; j++) {
            if (item.values.includes(dFiltersArray[i].values[j].match)) {
              dFiltersArray[i].values[j].checked = true;
            }
          }
        }
      }
    });
    return dFiltersArray;
  };

  clearResultFilters = (e, data) => {
    this.setState({ variantFilter: true });
    const { filters } = this.state;
    const dFiltersArray = filters;
    for (let i = 0; i < dFiltersArray.length; i++) {
      for (let j = 0; j < dFiltersArray[i].values.length; j++) {
        dFiltersArray[i].values[j].checked = false;
      }
    }
    this.updateGrid(this.createFilterModel(dFiltersArray));
    this.gridApi.deselectAll();
  };

  updateGridSize = () => {
    if (this.gridApi) {
      // this.gridApi.resetRowHeights();
    }
  };

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (values.terms && values.terms.length > 0) {
      const urlFilters = JSON.parse(values.filters);
      this.setState({ searchedTerm: values.terms });
      this.setState({ currentSort: values.sort });
      this.setState({ urlFilters });
      this.props.cart.performSearch(values.terms).then(() => {
        this.search();
      });
    } else {
      this.search();
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.cart.searchResults !== nextProps.cart.searchResults) {
      this.setState({ searchResults: nextProps.cart.searchResults }, () => {
        this.search();
      });
    }

    if (this.props.cart.isUserPow()) {
      if (this.props.cart.estimateCarts !== nextProps.cart.estimateCarts) {
        this.cartUpdate(nextProps.cart.estimateCarts);
      }
    } else {
      if (this.props.cart.carts !== nextProps.cart.carts) {
        this.cartUpdate(nextProps.cart.carts);
      }
    }
  }

  search = () => {
    if (Object.keys(this.state.searchResults).length > 0) {
      const { filters, results, resultCount, searchString, searchMessage } =
        this.state.searchResults;
      // const results = this.cartUpdate(true);
      this.cartUpdate();
      const numberOfPages = Math.ceil(resultCount / 50);
      this.setState({
        totalPages: numberOfPages,
        totalTitlesToDisplay: resultCount,
        rowData: results,
        filters: filters,
        searchedTerm: searchString,
        currentPageNum: 1,
        pageSize: 50,
        variantFilter: true,
      });
      if (this.gridApi) {
        const sortArray = this.state.currentSort.split("_");
        var sort = [
          {
            colId: sortArray[0],
            sort: sortArray[1],
          },
        ];
        this.gridApi.setSortModel(sort);
        this.gridApi.paginationGoToFirstPage();
        if (this.state.searchResults?.filters?.length > 0) {
          this.gridApi.onFilterChanged();
          //this.updateGrid(this.createFilterModel(this.state.searchResults?.filters));
        }
      }

      if (searchMessage) {
        const firstLine = searchMessage.substring(
          0,
          searchMessage.indexOf(".")
        );
        const secondLine = searchMessage.substring(
          searchMessage.indexOf(".") + 2,
          searchMessage.length
        );
        this.setState({
          searchMsgLine1: firstLine,
          searchMsgLine2: secondLine,
        });
      }
    } else {
      // this.props.history.push('/');
    }
  };
  createShareLink() {
    const { filters } = this.state;
    let selectedFilter = {};
    let selectedFilters = [];
    _.each(filters, function (filter) {
      let filterFlag = false;
      let selectedValues = [];
      _.each(filter.values, function (item) {
        if (item.checked === true) {
          filterFlag = true;
          selectedValues.push(item.match);
        }
      });
      if (filterFlag === true) {
        selectedFilter = {
          filterName: filter.resultsMatchID,
          values: selectedValues,
        };
        selectedFilters.push(selectedFilter);
      }
    });

    let srchTerm = this.state.searchedTerm;

    if (this.props.location.state?.searchData?.title_list) {
      srchTerm = this.props.location.state.searchData.title_list.join(" ");
    }
    const linkTxt =
      "https://" +
      location.hostname +
      "/search?terms=" +
      srchTerm +
      "&sort=" +
      this.state.currentSort +
      "&filters=" +
      JSON.stringify(selectedFilters);
    var textField = document.createElement("textarea");
    textField.innerText = linkTxt;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  }

  render() {
    let useStandardSearch = true; // default to standard search, this is to handle the hack...
    let searchData;
    if (!!this?.props?.location?.state?.searchData) {
      useStandardSearch =
        !!this?.props?.location?.state?.searchData?.useStandardSearch;
      searchData = this?.props?.location?.state?.searchData;
    }
    if (this?.state?.searchedTerm && this?.state?.searchedTerm !== "") {
      ReactGA.ga("send", "pageview", "/search?q=" + this?.state?.searchedTerm);
    }

    const {
      filters,
      rowData,
      filtersCount,
      totalTitlesToDisplay,
      searchedTerm,
      totalPages,
      currentPageNum,
      currentSort,
      pageSize,
      searchResults,
      searchMsgLine1,
      searchMsgLine2,
      urlFilters,
      variantFilter,
      sortMenuVisible,
      showFilters,
    } = this.state;
    let pageTxt = "";
    if (totalPages > 1 && currentPageNum === 1) {
      pageTxt = totalTitlesToDisplay + " results for ";
    }
    if (totalPages > 1 && currentPageNum > 1) {
      pageTxt =
        "Page " + currentPageNum + " of " + totalTitlesToDisplay + " results ";
    } else {
      pageTxt = totalTitlesToDisplay + " results ";
    }

    if (this.gridApi && currentSort != "relevence asc") {
      const sortArray = this.state.currentSort.split("_");
      var sort = [
        {
          colId: sortArray[0],
          sort: sortArray[1],
        },
      ];
      this.gridApi.setSortModel(sort);
    }

    const isMobile = this.props.mobile.isMobile;

    return (
      <PageStandard
        className={` ${isMobile ? "" : "searchContainer"} ${
          !!searchData?.title & !useStandardSearch ? "mt-0" : ""
        }`}
        alignMent='left'
        headerType='loggedIn'
        locationSelector={true}
        containerFluid
      >
        <>
          {!!searchData?.title && !useStandardSearch ? (
            <Grid className='bg-gray-100 border-bottom-gray-300'>
              <Container className='px-0 pt-4 pb-3'>
                <p className='mt-0 mb-4px fort-book fs-12px lh-16px lsp--02 text-gray-700'>
                  {getPrettyName(POST_POST_TYPE).singular}
                </p>
                <h1
                  className={`mt-0 fort-bold fs-24px lh-28px lsp--05 text-gray-900 ${
                    !!searchData?.body_copy && !useStandardSearch
                      ? "mb-1 "
                      : "mb-0"
                  }`}
                >
                  {searchData?.title} {/*pageTxt*/}{" "}
                </h1>
                {!useStandardSearch && !!searchData?.body_copy && (
                  <p className='m-0 fort-medium fResults fors-14px lsp-19px lsp--015 text-gray-900'>
                    {searchData?.body_copy}
                  </p>
                )}

                <Link
                  to={`/${translatePostTypeSlug(POST_POST_TYPE)}`}
                  className='fort-book fs-12px lh-16px lsp--02 text-blue hover-orange mt-2 mb-0 d-inline-block'
                >
                  View all {getPrettyName(POST_POST_TYPE).plural}
                </Link>
              </Container>
            </Grid>
          ) : (
            <></>
          )}
        </>
        <Grid container={!isMobile} className={` ${isMobile ? "px-1" : ""}`}>
          {rowData.length > 0 ? (
            <Grid.Row
              className={`infoPanel   ${
                isMobile ? "flex-column mobile-search-header" : ""
              }`}
            >
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={8}
                largeScreen={8}
                widescreen={8}
                className={`infoPanelText fort-light ${isMobile ? "mb-3" : ""}`}
              >
                {!!useStandardSearch && !searchData?.title && (
                  <div style={{ lineHeight: "28px" }}>
                    <span className='paragraph-1 fort-light'>{pageTxt} </span>{" "}
                    <span>for "</span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          searchedTerm.length > 125
                            ? searchedTerm.substring(0, 124) + "..."
                            : searchedTerm,
                      }}
                    />
                    "
                  </div>
                )}
              </Grid.Column>
              {!isMobile && (
                <Grid.Column
                  floated='right'
                  mobile={16}
                  tablet={16}
                  computer={8}
                  largeScreen={8}
                  widescreen={8}
                  className='d-flex align-items-center flex-row justify-content-end'
                >
                  {totalTitlesToDisplay > 50 ? (
                    <div className='infoPanelItem'>
                      <Dropdown
                        selection
                        options={pageSizeOptions}
                        // defaultValue={pageSizeOptions[0].value}
                        value={pageSize}
                        direction='left'
                        onChange={(e, v) => {
                          this.handlePageSizeChange(v.value);
                        }}
                        className='pageDropdown pagesize-dropdown'
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className='infoPanelItem search-sort-container'>
                    <Dropdown
                      selection
                      className='search-sort-dropdown'
                      options={resultsSortOPtions}
                      value={currentSort}
                      text={`Sort by: ${this.getSortTitleByKey(currentSort)}`}
                      direction='left'
                      onChange={this.handleSortChange}
                    />
                  </div>

                  <div className='infoPanelItem'>
                    {/* <CopyToClipboard text={this.state.copyValue}> */}
                    {!this.props.user.isAppCompCopy() && (
                      <ActionMenu
                        actions={[
                          {
                            key: 1,
                            content: (
                              <div onClick={(e) => this.createShareLink()}>
                                <Icon name='linkify' /> Copy shareable link
                              </div>
                            ),
                            value: "copyLink",
                          },
                        ]}
                      />
                    )}
                    {/* </CopyToClipboard> */}
                  </div>
                </Grid.Column>
              )}
              {isMobile && (
                <Grid.Column mobile={16} tablet={16}>
                  <Grid.Row className='d-flex justify-content-space-between'>
                    <div className='catalog-filter-button'>
                      <PRHButton
                        size='medium'
                        icon='sliders horizontal'
                        borderColorHoverOverride={"grey-900"}
                        backgroundColorOverride={"white"}
                        // textColorOverride="white"
                        // iconColorOverride="white"
                        onClick={() => this.setState({ showFilters: true })}
                      >
                        Filter {filtersCount > 0 ? `(${filtersCount})` : ""}
                      </PRHButton>
                    </div>
                    <div className='sort-dropdown-div'>
                      <PRHButton
                        className='mobile-options-button sort-mobile-button'
                        onClick={() => this.setState({ sortMenuVisible: true })}
                      >
                        Sort By: {this.getSortTitleByKey(currentSort)}{" "}
                        <Icon name='triangle down' />
                      </PRHButton>
                      <MobileOptionsMenu
                        title='Sort by'
                        onClose={() =>
                          this.setState({
                            sortMenuVisible: false,
                          })
                        }
                        onOutsideMenuClick={() =>
                          this.setState({
                            sortMenuVisible: false,
                          })
                        }
                        menuItems={resultsSortOPtions}
                        menuVisible={sortMenuVisible}
                        className='download-dropdown-catalog'
                        onOptionClick={(option) =>
                          this.handleSortChange(_, { value: option.value })
                        }
                        radio={true}
                        currentSort={currentSort}
                      />
                    </div>
                  </Grid.Row>
                </Grid.Column>
              )}
            </Grid.Row>
          ) : (
            <div className={`empty_results ${isMobile ? "px-1" : ""}`}>
              <Grid.Row
                className={`infoPanel d-flex pb-2 ${
                  isMobile ? "flex-direction-column mb-2" : ""
                }`}
                style={{ borderBottom: "4px solid #ddd" }}
              >
                <Grid.Column
                  mobile={6}
                  tablet={6}
                  computer={8}
                  largeScreen={8}
                  widescreen={8}
                  className={`infoPanelText ${isMobile ? "mb-2" : ""}`}
                >
                  {!!useStandardSearch && !searchData?.title && (
                    <>
                      <span className='paragraph-1'>0 Results </span> for "
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            searchedTerm.length > 125
                              ? searchedTerm.substring(0, 124) + "..."
                              : searchedTerm,
                        }}
                      />
                      "
                    </>
                  )}
                </Grid.Column>
                <Grid.Column
                  floated='right'
                  mobile={10}
                  tablet={10}
                  computer={8}
                  largeScreen={8}
                  widescreen={8}
                  className='d-flex align-items-center flex-row justify-content-end'
                >
                  <div className='infoPanelItem'>
                    <Dropdown
                      selection
                      options={pageSizeOptions}
                      disabled
                      // defaultValue={pageSizeOptions[0].value}
                      value={pageSize}
                      direction='left'
                      className='pageDropdown pagesize-dropdown'
                    />
                  </div>
                  <div className='infoPanelItem'>
                    <Dropdown
                      disabled
                      selection
                      options={resultsSortOPtions}
                      // value={currentSort}
                      placeholder='Sort By'
                      direction='left'
                      onChange={this.handleSortChange}
                      className='pageDropdown search-sort-dropdown'
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className='mt-11 mb-14'>
                <div className='no-results-header'>No results found</div>
                <div className='no-results-content'>
                  Make sure your search request is spelled correctly or try
                  different keywords.
                  <div />
                </div>
              </Grid.Row>
            </div>
          )}
          {rowData.length > 0 ? (
            <Grid.Row className='search-container'>
              {isMobile && (
                <Grid.Column
                  mobile={16}
                  tablet={5}
                  computer={4}
                  largeScreen={3}
                  widescreen={3}
                  className={`p-0 ${
                    isMobile ? "mobile-catalog-filters p-3" : ""
                  } ${showFilters ? "filters-show" : "filters-hide"}`}
                >
                  {showFilters && (
                    <div className='mobile-catalog-filters-background' />
                  )}
                  {isMobile && showFilters && (
                    <CustomFilters
                      filters={filters}
                      updateResults={this.updateResults}
                      selectedFilterCount={filtersCount}
                      clearResultFilters={this.clearResultFilters}
                      numOfResults={totalTitlesToDisplay}
                      variantFilter={variantFilter}
                      closeFiltersMenu={() =>
                        this.setState({ showFilters: false })
                      }
                    />
                  )}
                </Grid.Column>
              )}
              {!isMobile && (
                <Grid.Column
                  mobile={16}
                  tablet={4}
                  computer={4}
                  largeScreen={4}
                  widescreen={4}
                >
                  {/* <div className="h-100 w-100 d-flex flex-column max-width"> */}
                  {/* <div className="d-flex flex-row h-100 w-100"> */}
                  <CustomFilters
                    filters={filters}
                    updateResults={this.updateResults}
                    // numOfFilters={this.numOfFilters}
                    selectedFilterCount={filtersCount}
                    clearResultFilters={this.clearResultFilters}
                    numOfResults={rowData.length}
                    variantFilter={variantFilter}
                    isUserPow={this.props.cart.isUserPow()}
                  />
                </Grid.Column>
              )}
              <Grid.Column
                mobile={16}
                tablet={12}
                computer={12}
                largeScreen={12}
                widescreen={12}
              >
                {searchResults.searchMessage ? (
                  <div className='search-message p-3 mt-3 mb-3'>
                    <div className='d-flex'>
                      {/* <Icon className="sm-info-icon " name="info"></Icon> */}
                      <Icon
                        className='cursor-pointer inline-flex orange search-msg-icon'
                        name='info circle'
                      />
                      {searchMsgLine1 !== "" ? (
                        <div>
                          <b>{searchMsgLine1}. </b>
                          {searchMsgLine2}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : null}
                <Grid>
                  <Grid.Row>
                    {isMobile && (
                      <NoticeCard>
                        Adding items to cart is accessible through desktop only.
                      </NoticeCard>
                    )}
                    <div
                      className='ag-theme-alpine'
                      style={{
                        // height: '600px',
                        width: "100%",
                      }}
                    >
                      <AgGridReact
                        modules={this.state.modules}
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        onGridReady={this.onGridReady.bind(this)}
                        frameworkComponents={this.state.frameworkComponents}
                        onFirstDataRendered={(dataProps) => {
                          const propPage = Number(
                            this.props.location.search?.split("=")[1] || 1
                          );
                          this.setState({
                            totalPages: dataProps.api.paginationGetTotalPages(),
                            currentPageNum: propPage,
                          });
                          dataProps.api.paginationGoToPage(propPage - 1);
                        }}
                        rowHeight={this.state.rowHeight}
                        pagination='true'
                        suppressPaginationPanel={true}
                        paginationPageSize={this.state.pageSize}
                        context={this.state.context}
                        domLayout='autoHeight'
                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                        suppressContextMenu='true'
                        // onColumnResized= {this.updateGridSize}
                      />
                    </div>
                  </Grid.Row>
                  <Grid.Row>
                    <div
                      className={`paginationFuntions ${
                        totalTitlesToDisplay > 0 ? "foot-border" : ""
                      }`}
                    >
                      <div className='pageBtnList'>
                        {totalPages > 1 ? (
                          <>
                            <PRHButton
                              className='sidePageBtns'
                              displayInlineFlex
                              disabled={currentPageNum === 1 ? true : false}
                              size='small'
                              icon='arrow left'
                              key={Math.random()}
                              onClick={() => this.onBtPrevious(searchData)}
                            />
                            <div className='ml-2 mr-2'>
                              Page {currentPageNum} of {totalPages}
                            </div>
                            <PRHButton
                              displayInlineFlex
                              disabled={
                                currentPageNum === totalPages ? true : false
                              }
                              className='sidePageBtns'
                              size='small'
                              icon='arrow right'
                              key={Math.random()}
                              onClick={() => this.onBtNext(searchData)}
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {totalTitlesToDisplay > 50 ? (
                        <Dropdown
                          selection
                          options={pageSizeOptions}
                          // defaultValue={pageSizeOptions[0].value}
                          value={pageSize}
                          direction='left'
                          onChange={(e, v) => {
                            this.handlePageSizeChange(v.value);
                          }}
                          className='pageDropdown pagesize-dropdown bottom-page-dropdown'
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <input type='hidden' value='' id='clipboardInput' />
                  </Grid.Row>
                </Grid>
                {totalTitlesToDisplay > 0 ? (
                  ""
                ) : (
                  <Grid.Row className='mb-8 no_results_align'>
                    <div className='no-results-header'>No results found</div>
                  </Grid.Row>
                )}
              </Grid.Column>
            </Grid.Row>
          ) : (
            ""
          )}
        </Grid>

        {/* {!isMobile && <BackToTopButton />} */}
        {isMobile && <BackToTopButtonMobile />}
      </PageStandard>
    );
  }
}

export default withRouter(withSecurity(withApp(withCart(withMobile(Search)))));
