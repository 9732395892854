import { Api } from "./api";

export const ecomApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerUserInfo: builder.query({
      query: ({ sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return { url: `/ecom/user/customer/user/`, headers };
      },
      providesTags: ["customer"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getCustomerUser", arg);
      },
      // keepUnusedDataFor: 0.001,
    }),
    getCustomerInfo: builder.query({
      query: ({ sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return { url: `/ecom/user/customer/info/`, headers };
      },
      // providesTags: ["customer"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getCustomerInfo", arg);
      },
      // keepUnusedDataFor: 0.001,
    }),

    getTitleInfo: builder.query({
      query: ({ isbn, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/title/product-display?isbn=${isbn}`,
          headers,
        };
      },
      providesTags: ["title"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getTitleInfo", arg);
      },
    }),

    getSeriesTitles: builder.query({
      query: ({ seriesCode, searchClass, country, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        let data = {
          searchClass: searchClass,
          country: country,
          seriesCode: seriesCode,
        };

        return { url: `/ecom/search`, method: "POST", headers, body: data };
      },
      providesTags: ["seriesTitle"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getSeriesTitles", arg);
      },
    }),
    getOrderHistory: builder.query({
      query: ({ shipTo, isbn, formatCode, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        let data = {
          shipTo,
          isbn,
          formatCode,
        };

        return {
          url: `/ecom/orderhistory/detail/all`,
          method: "POST",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getOrderHistory", arg);
      },
    }),
    getSeriesHistory: builder.query({
      query: ({ sapAccountNo, isbn, formatCode, shipTo, getAll }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        let data = {
          shipTo: shipTo,
          isbn: isbn,
          formatCode: formatCode,
        };

        return {
          url: `/ecom/serieshistory/detail${getAll === true ? "/all" : ""}`,
          method: "POST",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getSeriesHistory", arg);
      },
    }),
    search: builder.query({
      query: ({ searchTerm, searchClass, country, searchType }) => {
        const headers = {};
        // console.log("searchItem", searchTerm);

        let data = {
          ...(searchType === "list" && { isbns: searchTerm.split(" ") }),
          ...(searchType === "normal" && { q: searchTerm }),
          searchClass: searchClass,
          country: country,
        };

        return {
          url: `/ecom/search`,
          method: "POST",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug && console.log("onQueryStarted - search", arg);
      },
    }),
    searchMultipleBiz: builder.query({
      query: ({ searchItems, country, searchClass }) => {
        const headers = {};

        let data = {
          isbns: searchItems,
          country: country,
          searchClass: searchClass,
        };

        return {
          url: `/ecom/search`,
          method: "POST",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - searchMultipleBiz", arg);
      },
    }),

    getReturns: builder.query({
      query: ({ returnsStartDate, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/returns`,
          method: "POST",
          body: {
            shipTo: sapAccountNo,
            returnStartDate: returnsStartDate,
          },
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getReturns", arg);
      },
    }),
    submitReturns: builder.mutation({
      query: ({ returns, sapAccountNo, submissionType }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        let url;
        if (submissionType === "update") {
          url = `/ecom/returns/update`;
        } else if (submissionType === "cancel") {
          url = `/ecom/returns/cancel`;
        }

        return { url, method: "POST", body: returns, headers };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - submitReturns", arg);
      },
    }),
    getHrdiCaller: builder.query({
      query: ({ address, cartKey }) => {
        const headers = { Accept: "text/html" };

        return {
          url: `/ecom/card/caller`,
          method: "POST",
          headers,
          body: { address },
          responseHandler: (response) => response.text(),
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  getHrdiCaller", arg);
      },
    }),
    applyPromoCode: builder.mutation({
      query: ({ promo, cartKey }) => {
        const headers = {};

        return {
          url: `/ecom/usercart/promocode${
            cartKey ? `?cartKey=${cartKey}` : ""
          }`,
          method: "POST",
          headers,
          body: { value: promo },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  applyPromoCode", arg);
      },
    }),
    deletePromoCode: builder.mutation({
      query: ({ promo, cartKey }) => {
        const headers = {};

        return {
          url: `/ecom/usercart/promocode${
            cartKey ? `?cartKey=${cartKey}` : ""
          }`,
          method: "DELETE",
          headers,
          body: { value: promo },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  deletePromoCode", arg);
      },
    }),
    cleanISBNs: builder.mutation({
      query: ({ sapAccountNo, data }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        return {
          url: `/ecom/usercart/isbns`,
          method: "POST",
          headers,
          body: { data: data.trim() },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  cleanISBNs", arg);
      },
    }),
    validateAddress: builder.mutation({
      query: ({ sapAccountNo, data }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/validate/address?compCopy=true`,
          method: "POST",
          headers,
          body: { ...data },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  validateAddress", arg);
      },
    }),
    getPreDeliveryReports: builder.query({
      query: ({ sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return { url: `/ecom/delivery`, method: "POST", body: {}, headers };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getPreDeliveryReports", arg);
      },
    }),
    getPreDeliveryReport: builder.query({
      query: ({ reportDate, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/delivery`,
          method: "POST",
          body: { deliveryDate: reportDate, shipTo: sapAccountNo },
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getPreDeliveryReports", arg);
      },
    }),
    getBacklistDownloadsInfo: builder.query({
      query: ({ countryCode }) => {
        const headers = {};

        return {
          url: `/ecom/downloads/backlist?country=${countryCode}`,
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getBacklistDownloadsInfo", arg);
      },
    }),
    getBacklistDownloadFile: builder.query({
      query: ({ fileName, countryCode }) => {
        const headers = {};

        return {
          url: `/ecom/downloads/backlist?country=${countryCode}&fileName=${fileName}`,
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getBacklistDownloadFile", arg);
      },
    }),
    getListOfReports: builder.query({
      query: ({ shipTo = null, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/delivery/osd`,
          method: "POST",
          body: { shipTo },
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getListOfReports", arg);
      },
    }),
    downloadPreDeliveryReport: builder.mutation({
      query: ({ onSaleDate, shipTo }) => {
        const headers = {};

        return {
          url: `/ecom/delivery/osd`,
          method: "POST",
          body: {
            onSaleDate,
            shipTo,
          },
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - downloadPreDeliveryReport", arg);
      },
    }),
    downloadStatement: builder.mutation({
      /*
      query: ({ statementDate }) => {
        const headers = {};

        return {
          url: `/ecom/statements/pdf`,
          method: "POST",
          body: {statementDate},
          headers,
          //responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
          //responseHandler: async (response) => window.open(window.URL.createObjectURL(await response.blob()), '_blank'),
          cache: "no-cache",
        };
      },*/
      queryFn: async ({ statementDate }, api, extraOptions, baseQuery) => {
        const headers = {};
        const result = await baseQuery({
             url: `/ecom/statements/pdf`,
             method: "POST",
            body: {statementDate},
            headers,
            responseHandler: ((response) => response.blob())
        })
        if (result?.data){
        var hiddenElement = document.createElement('a');
        var url = window.URL || window.webkitURL;
        var blobPDF = url.createObjectURL(result.data);
        hiddenElement.href = blobPDF;
        hiddenElement.target = '_blank';
        hiddenElement.download = `${statementDate}_statement.pdf`;
        hiddenElement.click();
        }
        return { data: result?.error?.status ?  result.error.status: 200}
    },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - downloadStatement", arg);
      },
    }),
    getRecentHistory: builder.query({
      query: ({ sapAccountNo = null, shipTo = null }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        let data = { account: [] };
        if (shipTo) {
          data = { account: [shipTo] };
        }

        return {
          url: `/ecom/home/history`,
          method: "POST",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getRecentHistory", arg);
      },
    }),
    getPOWTitleInfo: builder.query({
      query: ({ isbn }) => {
        const headers = {};

        return {
          url: `/ecom/title/titles?isbn=${isbn}`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getPOWTitleInfo", arg);
      },
    }),
    getSalesReps: builder.query({
      query: ({ sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/reps/sales/`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getSalesReps", arg);
      },
    }),
    getPromos: builder.query({
      query: ({ sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/promotions/`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug && console.log("onQueryStarted - getPromos", arg);
      },
    }),
    getCreditCards: builder.query({
      query: ({ cardId }) => {
        let data = {};
        if (cardId) {
          data = { cardId: cardId };
        }
        return {
          url: `/ecom/card/list`,
          method: "POST",
          data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getCreditCards", arg);
      },
    }),

    removeCreditCard: builder.mutation({
      query: ({ cardGroup }) => {
        let data = {};
        if (cardGroup) {
          data = { cardGroup };
        }
        return {
          url: `/ecom/card/delete`,
          method: "POST",
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getCreditCards", arg);
      },
    }),

    getOrders: builder.query({
      query: ({ data, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/orders/views/customer`,
          method: "POST",
          body: data,
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug && console.log("onQueryStarted - getOrders", arg);
      },
    }),

    getInvoices: builder.query({
      query: ({ data, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/invoices`,
          method: "POST",
          body: data,
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getInvoices", arg);
      },
    }),
    getRecentHistoryHome: builder.query({
      query: ({ shipTo = null, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: "/ecom/home/history",
          method: "POST",
          body: shipTo ? { account: [shipTo] } : {},
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getRecentHistory", arg);
      },
    }),
    getRecentInvoiceHistoryHome: builder.query({
      query: ({ shipTo = null, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        return {
          url: "/ecom/home/history",
          method: "POST",
          body: shipTo ? { account: [shipTo] } : {},
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getRecentHistory", arg);
      },
    }),
    getRecentInvoices: builder.query({
      query: ({ shipTo, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/home/history/invoices/${
            !!shipTo ? `?=shipTo=${shipTo}` : ""
          }`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getRecentInvoices", arg);
      },
    }),
    getRecentOrders: builder.query({
      query: ({ shipTo, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/home/history/orders/${
            !!shipTo ? `?=shipTo=${shipTo}` : ""
          }`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getRecentOrders", arg);
      },
    }),
    getOrderDetails: builder.query({
      query: ({ orderId, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/orders/${orderId}/views/customer`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getOrderDetails", arg);
      },
    }),
    patchOrder: builder.mutation({
      query: ({ orderId, data, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/orders/${orderId}`,
          method: "PATCH",
          body: data,
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - patchOrder", arg);
      },
    }),
    deleteOrder: builder.mutation({
      query: ({ orderId, sapAccountNo, cartType }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/orders/${orderId}`,
          method: "DELETE",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - deleteOrder", arg);
      },
    }),
    getInvoiceDetails: builder.query({
      query: ({ invoiceId, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/invoices/${invoiceId}`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getInvoiceDetails", arg);
      },
    }),
    postClaim: builder.mutation({
      query: ({ claim, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/invoices/claim`,
          method: "POST",
          body: claim,
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug && console.log("onQueryStarted - postClaim", arg);
      },
    }),
    emailInvoices: builder.mutation({
      query: ({ documentNumber, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/invoices/email`,
          method: "POST",
          body: { documentNumber },
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - emailInvoices", arg);
      },
    }),
    getAuthorDetails: builder.query({
      query: ({ authorId }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/title/author-display?authorId=${authorId}`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getAuthorDetails", arg);
      },
    }),
    getCarouselData: builder.query({
      query: ({ data }) => {
        const headers = {};

        const params = Object.entries(data)
          .map((pair) => `${pair[0]}=${pair[1]}`)
          .join("&");

        return {
          url: `/ecom/title/works/list-display?${params}`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getCarouselData", arg);
      },
    }),
    getCarouselFormatData: builder.query({
      query: ({ data }) => {
        const headers = {};

        const params = Object.entries(data)
          .map((pair) => `${pair[0]}=${pair[1]}`)
          .join("&");

        return {
          url: `/ecom/title/carousel/titles?${params}`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getCarouselFormatData", arg);
      },
    }),
    getDeliveriesHomePage: builder.query({
      query: ({ shipTo = false }) => {
        const headers = {};

        let data = {};

        if (shipTo) {
          data.shipTo = shipTo;
        }

        return {
          url: `/ecom/tracking/homepage`,
          method: "POST",
          headers,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getDeliveriesHomePage", arg);
      },
    }),
    getDeliveriesOverview: builder.query({
      query: ({ shipTo }) => {
        const headers = {};

        return {
          url: `/ecom/tracking/overview`,
          method: "POST",
          headers,
          body: { shipTo },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getDeliveriesOverview", arg);
      },
    }),
    getDeliveriesSearch: builder.query({
      query: ({ searchTerm }) => {
        const headers = {};

        return {
          url: `/ecom/tracking/overview/search`,
          method: "POST",
          headers,
          body: { searchTerm },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getDeliveriesSearch", arg);
      },
    }),
    getDeliveriesDetail: builder.query({
      query: ({ deliveryNo }) => {
        const headers = {};

        return {
          url: `/ecom/tracking/detail`,
          method: "POST",
          headers,
          body: { deliveryNo },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getDeliveriesDetail", arg);
      },
    }),
    getStatementsOverview: builder.query({
      query: ({}) => {
        const headers = {};

        return {
          url: `/ecom/statements`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getStatementsOverview", arg);
      },
    }),
    getStatementsDetail: builder.query({
      query: ({ statementDate }) => {
        const headers = {};

        return {
          url: `/ecom/statements`,
          method: "POST",
          headers,
          body: { statementDate },
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getStatementsDetail", arg);
      },
    }),
    logout: builder.mutation({
      query: ({ sapAccountNo }) => {
        const headers = {};

        return {
          url: `/ecom/user/logout`,
          method: "GET",
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug && console.log("onQueryStarted - logout", arg);
      },
    }),
  }),
});

export const {
  useGetCustomerUserInfoQuery,
  useLazyGetCustomerUserInfoQuery,
  useGetCustomerInfoQuery,
  useLazyGetCustomerInfoQuery,
  useGetTitleInfoQuery,
  useLazyGetTitleInfoQuery,
  useGetSeriesHistoryQuery,
  useLazyGetSeriesHistoryQuery,
  useGetOrderHistoryQuery,
  useLazyGetOrderHistoryQuery,
  useGetSeriesTitlesQuery,
  useLazyGetSeriesTitlesQuery,
  useSearchQuery,
  useLazySearchQuery,
  useSearchMultipleBizQuery,
  useLazySearchMultipleBizQuery,
  useGetHrdiCallerQuery,
  useLazyGetHrdiCallerQuery,
  useApplyPromoCodeMutation,
  useDeletePromoCodeMutation,
  useCleanISBNsMutation,
  useValidateAddressMutation,
  useGetReturnsQuery,
  useLazyGetReturnsQuery,
  useSubmitReturnsMutation,
  useGetBacklistDownloadsInfoQuery,
  useLazyGetBacklistDownloadsInfoQuery,
  useGetBacklistDownloadFileQuery,
  useLazyGetBacklistDownloadFileQuery,
  useGetPreDeliveryReportsQuery,
  useLazyGetPreDeliveryReportsQuery,
  useGetPreDeliveryReportQuery,
  useLazyGetPreDeliveryReportQuery,
  useGetListOfReportsQuery,
  useLazyGetListOfReportsQuery,
  useDownloadPreDeliveryReportMutation,
  useGetRecentHistoryQuery,
  useLazyGetRecentHistoryQuery,
  useGetPOWTitleInfoQuery,
  useLazyGetPOWTitleInfoQuery,
  useGetSalesRepsQuery,
  useLazyGetSalesRepsQuery,
  useGetPromosQuery,
  useLazyGetPromosQuery,
  useGetOrdersQuery,
  useLazyGetOrdersQuery,
  useGetInvoicesQuery,
  useLazyGetInvoicesQuery,
  useGetRecentInvoicesQuery,
  useLazyGetRecentInvoicesQuery,
  useGetRecentOrdersQuery,
  useLazyGetRecentOrdersQuery,
  useGetOrderDetailsQuery,
  useLazyGetOrderDetailsQuery,
  usePatchOrderMutation,
  useDeleteOrderMutation,
  useGetInvoiceDetailsQuery,
  useLazyGetInvoiceDetailsQuery,
  usePostClaimMutation,
  useEmailInvoicesMutation,
  useGetAuthorDetailsQuery,
  useLazyGetAuthorDetailsQuery,
  useGetCarouselDataQuery,
  useLazyGetCarouselDataQuery,
  useGetCarouselFormatDataQuery,
  useLazyGetCarouselFormatDataQuery,
  useGetCreditCardsQuery,
  useLazyGetCreditCardsQuery,
  useRemoveCreditCardMutation,
  useGetDeliveriesHomePageQuery,
  useLazyGetDeliveriesHomePageQuery,
  useGetDeliveriesOverviewQuery,
  useLazyGetDeliveriesOverviewQuery,
  useGetDeliveriesSearchQuery,
  useLazyGetDeliveriesSearchQuery,
  useGetDeliveriesDetailQuery,
  useLazyGetDeliveriesDetailQuery,
  useGetRecentHistoryHomeQuery,
  useLazyGetRecentHistoryHomeQuery,
  useGetRecentInvoiceHistoryHomeQuery,
  useLazyGetRecentInvoiceHistoryHomeQuery,
  useLazyGetStatementsDetailQuery,
  useGetStatementsDetailQuery,
  useGetStatementsOverviewQuery,
  useLazyGetStatementsOverviewQuery,
  useLogoutMutation,
  useDownloadStatementMutation,
} = ecomApi;
