import { Component } from 'react';
import Download from '../../../assets/images/download.svg';
//import Download from '../../assets/images/download.svg';
import { withRouter } from 'react-router-dom';
import './preDeliveryShipTos.scss';

class preDeliveryShipTos extends Component {
  render() {
    const {
      shipTos
    } = this.props.data;
    
    return (
      <>
      <div className={"cell-padding"}  >
      {shipTos.map((shipTo, index)=>{
        return (
       <div className={"inner-row-padding"} key={`${shipTo}_${index}`}>{shipTo}</div>
      );})}
      </div>
    </>
    );
  }
}

export default withRouter(preDeliveryShipTos);
