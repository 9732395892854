import md5 from "md5";
import Persisance from "./persistance";
import * as Sentry from "@sentry/react";
import { HttpClient } from "../../utils/HttpClient";

class API extends Persisance {
  constructor(props) {
    const {
      storeName,
      sessionToken,
      globalCache,
      debug,
      errorCategory = 5000,
      errorHandler,
      failedAuthHandler,
    } = props;
    super({ name: storeName || "api", debug });

    this.storeName = storeName || "api";
    this.sessionToken = sessionToken || null;
    this.globalCache = globalCache || false;
    this.debug = false;
    this.errorCategory = errorCategory;
    this.errorHandler = errorHandler;
    this.failedAuthHandler = failedAuthHandler;
    this.authMessage =
      "You have been logged out. Redirecting you now to log in . . .";
  }

  setDebug(debug) {
    this.debug = debug;
    this.setPersistantDebug(debug);
  }

  isDebug() {
    return this.debug;
  }

  setSessionToken(sessionToken) {
    this.sessionToken = sessionToken;
  }

  getProxyRequestId() {
    if (!localStorage.getItem("proxyRequestId")) {
      return "";
    }
    return localStorage.getItem("proxyRequestId");
  }

  async postImportant(props) {
    const response = await this.post(props.url, props.data);

    // if (response.status === 500 || response.status === 502) {
    if (response.status !== 200) {
      this.isDebug() &&
        // console.log(`API:${this.getStoreName()}:Error`, response);
        this.errorHandler({
          errorCategory: this.errorCategory,
          status: 500,
          response,
        });
      return response;
    }

    return response;
  }

  async post(props) {
    let headers = [];
    if (!props.headers) {
      headers["requestId"] = this.getProxyRequestId();
    } else {
      headers = props.headers;
      headers["requestId"] = this.getProxyRequestId();
    }
    try {
      const response = await HttpClient.request({
        session: this.sessionToken,
        method: "post",
        url: props.url,
        data: props.data,
        headers: headers,
        timeout: props.timeout,
        opts: props.opts,
      }).catch((error) => {
        this.isDebug() && console.log("post error", error, error.response);
        if (error.response) {
          error.response.errorCategory = this.errorCategory;
          return error.response;
        }
        if (error.request) {
          return {
            status: 500,
            errorCategory: this.errorCategory,
            error: error.request,
          };
        }
        return { status: 500, errorCategory: this.errorCategory, error };
      });

      if (
        (response.status === 500 || response.status === 502) &&
        !!!props.ignoreError
      ) {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Error`, response);
        console.log("error response", response);
        Sentry.setContext("POST", {
          requestid: this.getProxyRequestId(),
        });
        Sentry.captureException({
          error: response?.data?.error,
          message: response?.data?.message,
        });
        this.errorHandler({
          errorCategory: this.errorCategory,
          status: 500,
          response,
        });
        return response;
      }

      if (
        (response.headers &&
          "x-frame-options" in response.headers &&
          response.headers["x-frame-options"] === "DENY") ||
        (response.status === 302 && response.location === "/my.policy") ||
        response.status === 401
      ) {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Error`, response);
        this.failedAuthHandler(this.getStoreName());
        this.errorHandler({
          status: 401,
          errorCategory: this.errorCategory,
          response: {
            error: {
              message: this.authMessage,
            },
          },
        });
        return {
          status: 401,
          errorCategory: this.errorCategory,
          response: {
            error: {
              message: this.authMessage,
            },
          },
        };
      }
      // console.log('response', response);
      if (
        response.status != 200 ||
        response?.data?.status === "INTERNAL_SERVER_ERROR"
      ) {
        Sentry.setContext("POST", {
          requestid: this.getProxyRequestId(),
          url: props.url,
        });
        Sentry.captureException();
      }

      return response;
    } catch (error) {
      this.isDebug() && console.log(`API:${this.getStoreName()}:Error`, error);
      // this.errorHandler({
      //   errorCategory: this.errorCategory,
      //   status: 500,
      //   error,
      // });
      return { status: 500, errorCategory: this.errorCategory, error };
    }
  }

  async deleteImportant(props) {
    const response = await this.delete(props.url, props.data);

    if (response.status === 500 || response.status === 502) {
      this.isDebug() &&
        console.log(`API:${this.getStoreName()}:Error`, response);
      this.errorHandler({
        errorCategory: this.errorCategory,
        status: 500,
        response,
      });
      return response;
    }
    return response;
  }

  async delete(props) {
    let headers = [];
    if (!props.headers) {
      headers["requestId"] = this.getProxyRequestId();
    } else {
      headers = props.headers;
      headers["requestId"] = this.getProxyRequestId();
    }
    try {
      const response = await HttpClient.request({
        session: this.sessionToken,
        method: "delete",
        url: props.url,
        data: props.data,
        headers: headers,
      }).catch((error) => {
        this.isDebug() && console.log("delete error", error, error.response);
        if (error.response) {
          error.response.errorCategory = this.errorCategory;
          return error.response;
        }
        if (error.request) {
          console.log("request", error.request);
          return {
            status: 500,
            errorCategory: this.errorCategory,
            error: error.request,
          };
        }
        return { status: 500, errorCategory: this.errorCategory, error };
      });

      if (response.status === 500 || response.status === 502) {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Error`, response);
        Sentry.setContext("DELETE", {
          requestid: this.getProxyRequestId(),
        });
        Sentry.captureException({
          error: response?.data?.error,
          message: response?.data?.message,
        });
        this.errorHandler({
          errorCategory: this.errorCategory,
          status: 500,
          response,
        });
        return response;
      }

      if (
        (response.headers &&
          "x-frame-options" in response.headers &&
          response.headers["x-frame-options"] === "DENY") ||
        (response.status === 302 && response.location === "/my.policy") ||
        response.status === 401
      ) {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Error`, response);
        this.failedAuthHandler(this.getStoreName());
        this.errorHandler({
          status: 401,
          errorCategory: this.errorCategory,
          response: {
            error: {
              message: this.authMessage,
            },
          },
        });
        return {
          status: 401,
          errorCategory: this.errorCategory,
          response: {
            error: {
              message: this.authMessage,
            },
          },
        };
      }
      if (response.status != 200) {
        Sentry.setContext("DELETE", {
          requestid: this.getProxyRequestId(),
          url: props.url,
        });
        Sentry.captureException();
      }
      return response;
    } catch (error) {
      this.isDebug() && console.log(`API:${this.getStoreName()}:Error`, error);
      // this.errorHandler({
      //   errorCategory: this.errorCategory,
      //   status: 500,
      //   error,
      // });
      return { status: 500, errorCategory: this.errorCategory, error };
    }
  }

  async patch(props) {
    let headers = [];
    if (!props.headers) {
      headers["requestId"] = this.getProxyRequestId();
    } else {
      headers = props.headers;
      headers["requestId"] = this.getProxyRequestId();
    }
    try {
      const response = await HttpClient.request({
        session: this.sessionToken,
        method: "patch",
        url: props.url,
        data: props.data,
        headers: headers,
      }).catch((error) => {
        this.errorHandler(error.response);
        return error.response;
      });

      if (response.status === 500 || response.status === 502) {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Error`, response);
        Sentry.setContext("PATCH", {
          requestid: this.getProxyRequestId(),
        });
        Sentry.captureException({
          error: response?.data?.error,
          message: response?.data?.message,
        });
        this.errorHandler({
          errorCategory: this.errorCategory,
          status: 500,
          response,
        });
        return response;
      }

      if (
        (response.headers &&
          "x-frame-options" in response.headers &&
          response.headers["x-frame-options"] === "DENY") ||
        (response.status === 302 && response.location === "/my.policy") ||
        response.status === 401
      ) {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Error`, response);
        this.failedAuthHandler(this.getStoreName());
        this.errorHandler({
          status: 401,
          errorCategory: this.errorCategory,
          response: {
            error: {
              message: this.authMessage,
            },
          },
        });
        return {
          status: 401,
          errorCategory: this.errorCategory,
          response: {
            error: {
              message: this.authMessage,
            },
          },
        };
      }
      if (
        response.status != 200 ||
        response?.data?.status === "INTERNAL_SERVER_ERROR"
      ) {
        Sentry.setContext("PATCH", {
          requestid: this.getProxyRequestId(),
          url: props.url,
        });
        Sentry.captureException();
      }
      return response;
    } catch (error) {
      //console.log(`API:${this.getStoreName()}:Error`, error);
      this.errorHandler(error.response);
      return error;
    }
  }

  async put(props) {
    let headers = [];
    if (!props.headers) {
      headers["requestId"] = this.getProxyRequestId();
    } else {
      headers = props.headers;
      headers["requestId"] = this.getProxyRequestId();
    }
    try {
      const response = await HttpClient.request({
        session: this.sessionToken,
        method: "put",
        url: props.url,
        data: props.data,
        headers: headers,
      }).catch((error) => {
        //console.log('put error', error, error.response);
        this.errorHandler(error.response);
        return error.response;
      });
      // console.log('put response', response);
      if (response.status === 500 || response.status === 502) {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Error`, response);
        Sentry.setContext("PUT", {
          requestid: this.getProxyRequestId(),
        });
        Sentry.captureException({
          error: response?.data?.error,
          message: response?.data?.message,
        });
        this.errorHandler({
          errorCategory: this.errorCategory,
          status: 500,
          response,
        });
        return response;
      }

      if (
        (response.headers &&
          "x-frame-options" in response.headers &&
          response.headers["x-frame-options"] === "DENY") ||
        (response.status === 302 && response.location === "/my.policy") ||
        response.status === 401
      ) {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Error`, response);
        this.failedAuthHandler(this.getStoreName());
        this.errorHandler({
          status: 401,
          errorCategory: this.errorCategory,
          response: {
            error: {
              message: this.authMessage,
            },
          },
        });
        return {
          status: 401,
          errorCategory: this.errorCategory,
          response: {
            error: {
              message: this.authMessage,
            },
          },
        };
      }
      if (
        response.status != 200 ||
        response?.data?.status === "INTERNAL_SERVER_ERROR"
      ) {
        Sentry.setContext("PUT", {
          requestid: this.getProxyRequestId(),
          url: props.url,
        });
        Sentry.captureException();
      }
      return response;
    } catch (error) {
      console.log(`API:${this.getStoreName()}:Error`, error);
      this.errorHandler(error.response);
      return error;
    }
  }

  async getImportant(url, cacheResults = false) {
    const response = await this.get(url, cacheResults);

    if (
      response.status !== 200 &&
      response.status !== 0
      // response.status === 500 ||
      // response.status === 502 ||
      // response.status === 404
    ) {
      this.isDebug() &&
        console.log(`API:${this.getStoreName()}:Error`, response);
      this.errorHandler(response);
      return response;
    }
    return response;
  }

  async clearCacheKey(url) {
    const cr = await this.removeCache(md5(url));
    return cr;
  }

  // async get(url, cacheResults = false) {
  //   try {
  //     if (cacheResults || this.globalCache) {
  //       const cr = await this.getCache(md5(url));
  //       this.isDebug() && console.log('cache Check', md5(url), cr);
  //       if (cr) return { status: 200, data: cr };
  //     }

  //     const response = await HttpClient.request({
  //       session: this.sessionToken,
  //       method: 'get',
  //       url,
  //     }).catch((error) => {
  //       this.isDebug() &&
  //         console.log(`API:${this.getStoreName()}:Axios Error`, error.message);

  //       if (error.response) {
  //         error.response.errorCategory = this.errorCategory;
  //         return error.response;
  //       }
  //       if (error.request) {
  //         // console.log('this is error.request', error.request);
  //         // if (error.request.status !== 0) {
  //         return {
  //           status: 500,
  //           errorCategory: this.errorCategory,
  //           error: error.request,
  //         };
  //       }
  //       return { status: 500, errorCategory: this.errorCategory, error };
  //     });

  //     if (
  //       (response.headers &&
  //         'x-frame-options' in response.headers &&
  //         response.headers['x-frame-options'] === 'DENY') ||
  //       (response.status === 302 && response.location === '/my.policy') ||
  //       response.status === 401
  //     ) {
  //       this.isDebug() &&
  //         console.log(`API:${this.getStoreName()}:Catch Error`, response);
  //       this.failedAuthHandler(this.getStoreName());
  //       this.errorHandler({
  //         status: 401,
  //         errorCategory: this.errorCategory,
  //         response: {
  //           error: {
  //             message: this.authMessage,
  //           },
  //         },
  //       });
  //       return {
  //         status: 401,
  //         errorCategory: this.errorCategory,
  //         response: {
  //           error: {
  //             message: this.authMessage,
  //           },
  //         },
  //       };
  //     }

  //     // console.log('get', response);
  //     if ((cacheResults || this.globalCache) && response.status === 200) {
  //       this.setCache(md5(url), response.data);
  //     }

  //     // if (response.status !== 200) {
  //     //   this.isDebug() &&
  //     //     console.log(`API:${this.getStoreName()}:Error`, response);
  //     //   this.errorHandler({
  //     //     errorCode,
  //     //     errorCategory: this.errorCategory,
  //     //     status: response.status,
  //     //     response,
  //     //   });
  //     //   return response;
  //     // }

  //     return response;
  //   } catch (error) {
  //     this.isDebug() && console.log(`API:${this.getStoreName()}:Error`, error);
  //     // this.errorHandler({
  //     //   errorCategory: this.errorCategory,
  //     //   status: 500,
  //     //   error,
  //     // });
  //     return { status: 500, errorCategory: this.errorCategory, error };
  //   }
  // }

  async get(props) {
    // console.log('get props', props);
    let headers = [];
    if (!props.headers) {
      headers["requestId"] = this.getProxyRequestId();
    } else {
      headers = props.headers;
      headers["requestId"] = this.getProxyRequestId();
    }
    try {
      if (props.cacheResults || this.globalCache) {
        const cr = await this.getCache(md5(props.url));
        this.isDebug() && console.log("cache Check", md5(props.url), cr);
        if (cr) return { status: 200, data: cr };
      }

      // console.log('get headers', headers);
      const response = await HttpClient.request({
        session: this.sessionToken,
        method: "get",
        url: props.url,
        headers: headers,
      }).catch((error) => {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Axios Error`, error.message);

        if (error.response) {
          error.response.errorCategory = this.errorCategory;
          return error.response;
        }
        if (error.request) {
          // console.log('this is error.request', error.request);
          // if (error.request.status !== 0) {
          return {
            status: 500,
            errorCategory: this.errorCategory,
            error: error.request,
          };
        }
        return { status: 500, errorCategory: this.errorCategory, error };
      });

      if (
        (response.status === 500 || response.status === 502) &&
        !!!props.ignoreError
      ) {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Error`, response);
        Sentry.setContext("GET", {
          requestid: this.getProxyRequestId(),
        });
        Sentry.captureException({
          error: response?.data?.error,
          message: response?.data?.message,
        });
        this.errorHandler({
          errorCategory: this.errorCategory,
          status: 500,
          response,
        });
        return response;
      }

      if (
        ((response.headers &&
          "x-frame-options" in response.headers &&
          response.headers["x-frame-options"] === "DENY") ||
          (response.status === 302 && response.location === "/my.policy") ||
          response.status === 401) &&
        !!!props.ignoreError
      ) {
        this.isDebug() &&
          console.log(`API:${this.getStoreName()}:Catch Error`, response);
        this.failedAuthHandler(this.getStoreName());
        this.errorHandler({
          status: 401,
          errorCategory: this.errorCategory,
          response: {
            error: {
              message: this.authMessage,
            },
          },
        });
        return {
          status: 401,
          errorCategory: this.errorCategory,
          response: {
            error: {
              message: this.authMessage,
            },
          },
        };
      }

      // console.log('get', response);
      if ((props.cacheResults || this.globalCache) && response.status === 200) {
        this.setCache(md5(props.url), response.data);
      }

      if (response.status != 200) {
        Sentry.setContext("GET", {
          requestid: this.getProxyRequestId(),
          url: props.url,
        });
        // console.log('response get error', response);
        Sentry.captureException();
      }

      return response;
    } catch (error) {
      this.isDebug() && console.log(`API:${this.getStoreName()}:Error`, error);
      /*
      Sentry.setContext('GET', {
        requestid: this.getProxyRequestId(),
      });
      Sentry.captureMessage('get error', props.url);
      Sentry.captureException(error);*/
      // this.errorHandler({
      //   errorCategory: this.errorCategory,
      //   status: 500,
      //   error,
      // });
      return { status: 500, errorCategory: this.errorCategory, error };
    }
  }
}

export default API;
