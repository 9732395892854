import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer } from 'react-toastify';

export default () => {
  const closeIconClass = 'd-flex align-items-center justify-content-center modal-close-icon cursor-pointer fs-2 position-absolute mr-1px';

  const closeIcon = (
    <>
      <div
        role="button"
        tabIndex="-1"
        className={closeIconClass}

      >
        <FontAwesomeIcon icon={faTimes} />
      </div>
    </>
  );

  return <ToastContainer closeButton={closeIcon} />;
};
