import { useState, useEffect } from "react";
import {
  Header,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCsv,
  faFileArchive,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./VariantRestrictionsOrderUpdateModal.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import useCart from "../hc/useCart";
import LazyImage from "../../utils/LazyImage";
import ReportSingle from "../../assets/images/report-single.svg";

const CatalogFileModal = ({
  openState,
  setDownloadDialogState,
  handleCatDownload,
  focs,
}) => {
  const cart = useCart();
  const [actionPerformed, setActionPerformed] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const resetState = () => {
    setDialogVisible(false);
  };

  let headerMsg = "";
  let footerMsg = "";
  headerMsg = (
    <span>
      Click below to download FOC title data. This data is updated nightly.
    </span>
  );

  /*
  useEffect(() => {
    if (dialogVisible) {
      if (!openState) {
        if (!actionPerformed && cancelCallback) cancelCallback();
        resetState();
        setActionPerformed(false);
      }
    }
    if (openState && !dialogVisible) {
      setDialogVisible(true);
    }
  }, []);
*/
  return (
    <DialogModal
      open={openState}
      //setOpen={setOpenState}
      onXClose={setDownloadDialogState}
      modalClassName='vro-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            DOWNLOAD FOC TITLE DATA
          </Header>
        ),
        actions: [],
      }}
    >
      <div className='ar-box'>
        <div className='ml-4 mr-4 mb-2'>{headerMsg}</div>
        <Divider className='mt-0 mb-0' />
        <div
          style={{ maxHeight: "400px" }}
          className='pl-2 pr-2 pt-0 pb-2 overflow-auto bg-gray-100'
        >
          {focs.map((foc, idx) => (
            <>
              <div key={idx}>
                <div className='d-flex flex-row mb-2 mt-2' key={idx}>
                  <div className='d-flex flex-column ml-2 justify-content-center'>
                    <div className='fort-bold fs-13px lh-18px text-gray-700 flex'>
                      <div>
                        <img
                          name='file alternate outline'
                          alt={`Download Catalog ${foc.fileName}`}
                          src={ReportSingle}
                          className='mr-1'
                          color='grey'
                        />
                      </div>
                      <div className='fort-medium fs-14px text-gray-900'>
                        {foc.desc}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-column ml-auto justify-content-center'>
                    <div className='fort-book fs-13px lh-18px text-gray-700 ml-auto mr-1'>
                      <PRHButton
                        //iconleft
                        icon='arrow down'
                        size='small'
                        backgroundColorOverride='white'
                        onClick={() => {
                          handleCatDownload(foc.fileName);
                        }}
                      >
                        Download
                      </PRHButton>
                    </div>
                  </div>
                </div>
                <Divider className='mt-0 mb-0' />
              </div>
            </>
          ))}

          <div className='ml-4 mr-4 mt-2 fort-bold fs-12px lh-14px'></div>
        </div>
        <PRHButton
          //iconleft="true"
          icon='check'
          size='medium'
          className={"float-right margin-left mt-2 mr-2"}
          borderColorOverride='blue'
          textColorOverride='white'
          textColorHoverOverride='blue'
          backgroundColorOverride='blue'
          backgroundHoverColorOverride='white'
          iconColorOverride='white'
          iconColorHoverOverride='blue'
          onClick={() => setDownloadDialogState(false)}
        >
          Done
        </PRHButton>
      </div>
    </DialogModal>
  );
};

CatalogFileModal.propTypes = {
  openState: PropTypes.bool.isRequired,
};

CatalogFileModal.defaultProps = {
  openState: false,
};

export default CatalogFileModal;
