import { Api } from "./api";
import { removeUserCartItemState } from "../features/ecomSlice";

export const cartApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getUserCart: builder.query({
      query: ({ sapAccountNo, cartKey, simulate }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        return {
          url: `/ecom/usercart/?${
            simulate ? "simulate=true" : "simulate=false"
          }${cartKey ? `&cartKey=${cartKey}` : ""}`,
          headers,
        };
      },
      providesTags: ["cart"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getUserCart", arg);
      },
    }),
    createCart: builder.mutation({
      query: ({ data, sapAccountNo }) => {
        const headers = { "content-type": "application/json" };
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/usercart/${
            data?.cartKey ? `?cartKey=${data.cartKey}` : ""
          }`,
          method: data?.cartKey ? "PATCH" : "POST",
          headers,
          body: data,
        };
      },
      // providesTags: ["cart"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - createCart", arg);
      },
    }),
    updateCartHeaders: builder.mutation({
      query: ({ cartHeaders, cartKey, sapAccountNo, simulate }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        let params = [];
        cartKey && params.push(`cartKey=${cartKey}`);
        simulate && params.push(`simulate=true`);

        return {
          url: `/ecom/usercart/${
            params.length > 0 ? `?${params.join("&")}` : ""
          }`,
          method: "PATCH",
          headers,
          body: { headers: cartHeaders },
        };
      },
      // providesTags: ["cart"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - updateCartHeaders", arg);
      },
    }),
    removeUserCartItem: builder.mutation({
      query: ({ itemId, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        let items = [];
        if (Array.isArray(itemId)) {
          itemId.forEach((item) => {
            items.push({ itemId: item.itemId, quantity: -1 });
          });
        } else {
          items.push({ itemId: itemId, quantity: -1 });
        }

        let data = { items: items };

        return {
          url: `/ecom/usercart/`,
          method: "PATCH",
          headers,
          body: data,
        };
      },
      // invalidatesTags: (result, error) => ["cart"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - removeUserCartItem", getCacheEntry);
      },
    }),
    removeUserCart: builder.mutation({
      query: ({ sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/usercart/`,
          method: "DELETE",
          headers,
        };
      },
      // invalidatesTags: (result, error) => ["cart"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - removeUserCart", arg);
      },
    }),
    updateUserItemQty: builder.mutation({
      query: ({ itemId, qty, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/usercart/`,
          method: "PATCH",
          headers,
          body: { items: [{ itemId: itemId, quantity: qty }] },
        };
      },
      // invalidatesTags: (result, error) => ["cart"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - updateUserItemQty", arg);
      },
    }),
    addUserCartItems: builder.mutation({
      query: ({ items, sapAccountNo, simulate }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        return {
          url: `/ecom/usercart/?simulate=${simulate ? "true" : "false"}`,
          method: "PATCH",
          headers,
          body: { items: items },
        };
      },
      // invalidatesTags: (result, error) => ["cart"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - addUserCartItems", arg);
      },
    }),
    getUserSaveForLater: builder.query({
      query: ({ sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        return {
          url: `/ecom/userlist/sfl?titleDetail=true&simulate=false`,
          method: "GET",
          headers,
        };
      },
      providesTags: ["saveforlater"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getUserSaveForLater", arg);
      },
    }),
    removeUserSaveForLaterItem: builder.mutation({
      query: ({ itemId, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        return {
          url: `/ecom/userlist/sfl?titleDetail=true&simulate=true`,
          method: "PATCH",
          headers,
          body: { items: [{ itemId: itemId, quantity: 0 }] },
        };
      },
      // invalidatesTags: (result, error) => ["saveforlater"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - removeUserSaveForLaterItem", arg);
      },
    }),
    addUserSaveForLaterItem: builder.mutation({
      query: ({ isbn, qty, itemId, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        let data = { items: [{ isbn: isbn, quantity: qty }] };
        if (itemId) {
          data.items[0].itemId = itemId;
        }
        return {
          url: `/ecom/userlist/sfl/`,
          method: "PATCH",
          headers,
          body: data,
        };
      },
      // invalidatesTags: (result, error) => ["saveforlater"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - addUserSaveForLaterItem", arg);
      },
    }),
    updateItemSaveForLaterQty: builder.mutation({
      query: ({ itemId, qty, sapAccountNo }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        return {
          url: `/ecom/userlist/sfl/`,
          method: "PATCH",
          headers,
          body: { items: [{ itemId: itemId, quantity: qty }] },
        };
      },
      // invalidatesTags: (result, error) => ["saveforlater"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - updateItemSaveForLaterQty", arg);
      },
    }),
    getUserCartCardInfo: builder.query({
      query: ({ cartKey }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }
        return {
          url: `/ecom/usercart/?simulate=true&titleDetail=false${
            cartKey ? `&cartKey=${cartKey}` : ""
          }`,
          method: "GET",
          headers,
        };
      },
      // providesTags: ["saveforlater"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getUserCartCardInfo", arg);
      },
    }),
    updateCart: builder.mutation({
      query: ({
        headers,
        shipTo,
        items,
        sapAccountNo,
        shippingType,
        cartKey,
      }) => {
        const httpHeaders = {};
        if (sapAccountNo) {
          httpHeaders["sapAccountNo"] = sapAccountNo;
        }

        let data = {};
        if (headers) data.headers = headers;
        if (shipTo) data.shipTo = shipTo;
        if (items) data.items = items;
        if (shippingType) data.shippingType = shippingType;
        return {
          url: `/ecom/usercart/?titleDetail=true&simulate=false${
            cartKey ? `&cartKey=${cartKey}` : ""
          }`,
          method: "PATCH",
          headers: httpHeaders,
          body: data,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  updateCart", arg);
      },
    }),
    submitOrder: builder.mutation({
      query: ({ sapAccountNo, cartKey }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/usercart/submit/?simulate=true&titleDetail=false${
            cartKey ? `&cartKey=${cartKey}` : ""
          }`,
          method: "POST",
          headers,
          body: {},
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  submitOrder", arg);
      },
    }),
    getSOForCart: builder.query({
      query: ({ sapAccountNo, cartKey }) => {
        const headers = {};
        if (sapAccountNo) {
          headers["sapAccountNo"] = sapAccountNo;
        }

        return {
          url: `/ecom/usercart/key/${cartKey}/ordernumber`,
          method: "GET",
          headers,
          body: {},
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted -  getSOForCart", arg);
      },
    }),
  }),
});

export const {
  useGetUserCartQuery,
  useLazyGetUserCartQuery,
  useCreateCartMutation,
  useUpdateCartHeadersMutation,
  useRemoveUserCartItemMutation,
  useRemoveUserCartMutation,
  useUpdateUserItemQtyMutation,
  useAddUserCartItemsMutation,
  useGetUserSaveForLaterQuery,
  useLazyGetUserSaveForLaterQuery,
  useRemoveUserSaveForLaterItemMutation,
  useAddUserSaveForLaterItemMutation,
  useUpdateItemSaveForLaterQtyMutation,
  useGetUserCartCardInfoQuery,
  useLazyGetUserCartCardInfoQuery,
  useUpdateCartMutation,
  useSubmitOrderMutation,
  useGetSOForCartQuery,
  useLazyGetSOForCartQuery,
} = cartApi;
