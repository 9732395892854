import { Checkbox } from "semantic-ui-react";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import "./customerenrolment.scss";
import "react-datepicker/dist/react-datepicker.css";
import { COUNTRY } from "./shared";

const USText = (
  <div>
    <div className="bold-label mt-5 mb-2 text-uppercase">
      U.S. Non-returnable Retail Terms of Sale
    </div>
    <div className="container my-1 documents-container p-4 terms-of-sale">
      NON-RETURNABLE* (NR) OPTION: An account is limited to either returnable or
      non-returnable terms. The option will remain in effect for the entire
      calendar year. Other restrictions apply
      <br />
      <br />
      BASE DISCOUNT = 50% (some exceptions may apply).*For more information, or
      for a change in status, please contact your rep for details.
      <br />
      <br />
      FREIGHT: All formats will ship free freight on outgoing orders to
      established trade accounts. Initial shipments do not combine with
      reorders. Customers requesting expedited freight will be responsible for
      any additional charges.
      <br />
      <br />
      SHIPMENT MINIMUM: $200 retail value for reorders, $100 retail value for
      initials. All publishers and formats will combine to meet the shipment
      minimum within each warehouse. Shipments that do not meet the minimum will
      not leave the warehouse and will backorder for up to 90 days, in order to
      combine with other orders. Once a combination of orders exceeds the
      minimum retail value, it will be shipped
      <br />
      <br />
      CREDIT: Shipments will be made to all accounts in good credit standing, as
      determined by the Penguin Random House Credit Department. In addition,
      accounts must also be in good credit standing to participate in any
      promotional offers and to receive any incentive payments, rebates or
      allowances, including without limitation, cooperative advertising
      allowances. All publishers and formats will combine to meet the shipment
      minimum within each warehouse. Shipments that do not meet the minimum will
      not leave the warehouse and will backorder for up to 90 days, in order to
      combine with other orders. Once a combination of orders exceeds the
      minimum retail value, it will be shipped
      <br />
      <br />
      CREDIT: Shipments will be made to all accounts in good credit standing, as
      determined by the Penguin Random House Credit Department. In addition,
      accounts must also be in good credit standing to participate in any
      promotional offers and to receive any incentive payments, rebates or
      allowances, including without limitation, cooperative advertising
      allowances.
    </div>
  </div>
);
const CANText = (
  <div>
    <div className="bold-label mt-5 mb-2 text-uppercase">
      Canada Non-returnable Retail Terms of Sale
    </div>
    <div className="container my-1 documents-container p-4 terms-of-sale">
      NON-RETURNABLE* (NR) OPTION: An account is limited to either returnable or
      non-returnable terms. The option will remain in effect for the entire
      calendar year. Other restrictions apply
      <br />
      <br />
      BASE DISCOUNT = 50% for Comic Books and Graphic Novels. 45% on standard
      trade lines (some exceptions may apply). *For more information, or for a
      change in status, please contact your rep for details.
      <br />
      <br />
      FREIGHT: All formats will ship free freight on outgoing orders to
      established trade accounts. Initial shipments do not combine with
      reorders. Customers requesting expedited freight will be responsible for
      any additional charges.
      <br />
      <br />
      SHIPMENT MINIMUM: $200 retail value for reorders, $100 retail value for
      initials. All publishers and formats will combine to meet the shipment
      minimum within each warehouse. Shipments that do not meet the minimum will
      not leave the warehouse and will backorder for up to 90 days, in order to
      combine with other orders. Once a combination of orders exceeds the
      minimum retail value, it will be shipped
      <br />
      <br />
      CREDIT: Shipments will be made to all accounts in good credit standing, as
      determined by the Penguin Random House Credit Department. In addition,
      accounts must also be in good credit standing to participate in any
      promotional offers and to receive any incentive payments, rebates or
      allowances, including without limitation, cooperative advertising
      allowances. All publishers and formats will combine to meet the shipment
      minimum within each warehouse. Shipments that do not meet the minimum will
      not leave the warehouse and will backorder for up to 90 days, in order to
      combine with other orders. Once a combination of orders exceeds the
      minimum retail value, it will be shipped
      <br />
      <br />
      CREDIT: Shipments will be made to all accounts in good credit standing, as
      determined by the Penguin Random House Credit Department. In addition,
      accounts must also be in good credit standing to participate in any
      promotional offers and to receive any incentive payments, rebates or
      allowances, including without limitation, cooperative advertising
      allowances.
    </div>
  </div>
);
const TermsOfSale = ({
  data,
  confirmed,
  selectedCountry,
  onConfirm,
  onNext,
  onBack,
}) => {
  return (
    <>
      {selectedCountry === COUNTRY.US ? USText : CANText}
      <div className="terms-of-shipping-container">
        <Checkbox
          className="my-3 bold-label"
          label={
            selectedCountry === COUNTRY.US
              ? "I agree with Penguin Random House Terms of Sale"
              : "I agree with the Penguin Random House terms of Sale and I agree to be contacted by representatives of Penguin Random House Canada."
          }
          checked={confirmed}
          onChange={onConfirm}
        />
      </div>

      <div className="d-flex flex-row Xbutton-holder my-5">
        <PRHButton
          size="large"
          iconleft
          icon="arrow left"
          className="mr-auto"
          onClick={() => onBack(data)}
        >
          Back
        </PRHButton>
        <PRHButton
          disabled={!confirmed}
          iconright
          icon="arrow right"
          size="large"
          className="ml-auto"
          type="submit"
          onClick={onNext}
        >
          Review and Sign
        </PRHButton>
      </div>
    </>
  );
};

export default TermsOfSale;
