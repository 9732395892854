import { Component } from 'react';
import queryString from 'query-string';
import TextValidator from '../../shared-react-components/elements/input';
import withSecurity from '../hc/withSecurity';
import withApp from '../hc/withApp';
import withAccount from '../hc/withAccount';
import { withRouter } from 'react-router';
import { IDENTITY_CONFIG } from '../../utils/authConst';
import localforage from 'localforage';
import { Input, Form } from 'semantic-ui-react-form-validator';
import { Loader, Modal } from 'semantic-ui-react';
//import { Icon } from 'semantic-ui-react';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import FormContainer from '../../elements/FormContainer';
//import { Button } from './elements'
import { Link } from 'react-router-dom';
import './login.scss';
import EventTrigger from '../analytics/EventTrigger';
import withMobile from "../hc/withMobile";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Ecom_User_ID: '',
      password: '',
      loading: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    const newState = { ...this.state };
    const value = queryString.parse(this.props.location.search);
    let errorMessage = null;

    if (value.err && value.err.indexOf('failed') !== -1) {
      errorMessage = 'failedLogin';
    } else if (!!value.err && value.err.indexOf('disabled') !== -1) {
      errorMessage = 'disabledLogin';
    }
    newState.errorMessage = errorMessage;
    this.setState(newState);
  }

  enterID = (e) => {
    this.setState({ userId: e.target.value });
  };

  enterPW = (e) => {
    this.setState({ password: e.target.value });
  };

  getLegacyUser = () => {
    const { Ecom_User_ID, password } = this.state;
    const newState = { ...this.state };
    const postData = {
      userName: Ecom_User_ID,
      password: password,
    };
    const contentType = {
      'Content-Type': 'application/json',
    };
    newState.loading = true;
    this.setState(newState);
    this.props.app.registrationApi.getLegacyUser(postData).then((response) => {
      newState.loading = false;
      if (response.status===200){
      if (response.data.status === 'System_Error') {
        newState.systemResponse = 'System unavailable, please try again later.';
      } else if (response.data.status === 'OK') {
        this.props.account.setLegacyUser(
          response.data.data.description,
          response.data.data.displayName,
          response.data.data.token
        );
        this.props.history.push('/update');
      } else {
        newState.systemResponse = response.data.message;
      }
    } else {
      newState.systemResponse = 'We were unable to find this account. Please try again or contact customer service.';
    }
      this.setState(newState);
    });
  };

  onSubmit() {
    const { Ecom_User_ID } = this.state;
    this.props.app.accountApi.storeUserId(Ecom_User_ID);
    if (this.validateEmail(Ecom_User_ID.trim()) === false) {
      this.setState({
          ...this.state,
          errorMessage:'failedLoginNonEmail',
        });
    } else {
      document.getElementById('loginForm').submit();
    }
  }

  validateEmail(userId) {
    var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (userId.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  handleChange = (e) => {
    const newState = { ...this.state };
    newState[e.target.name] = e.target.value;
    this.setState(newState);
  };

  render() {
    const {
      Ecom_User_ID,
      password,
      loading,
      systemResponse,
      errorMessage,
    } = this.state;
    const { location, mobile } = this.props;
    const isMobile = mobile.isMobile;

    const loginAction = IDENTITY_CONFIG.login;
    const loginTarget = IDENTITY_CONFIG.target + '/?auth=true';
    return (
      <>
        <div className={'login'}>
          <Modal open={loading}>
            <Loader inverted/>
          </Modal>
          <FormContainer>
            {location.state &&
            location.state.err === 'Login failed, please try again.' ? (
              <div className={`ui negative message`}>
                <div className={`header`}>{location.state.err}</div>
              </div>
            ) : (
              ''
            )}
            <Form
              width={12}
              ref={this.loginForm}
              instantValidate={false}
              onSubmit={(e) => this.onSubmit()}
              method="post"
              //action="https://idp.dev.penguinrandomhouse.com/nidp/app/login?option=credential&forceAuth=true"
              action={loginAction}
              id="loginForm"
            >
              <TextValidator
                placeholder="Enter email address"
                name="Ecom_User_ID"
                type="text"
                onChange={this.handleChange}
                validators={['required']}
                errorMessages={[
                  <div className="my-2 fort-bold text-red fs-12px">
                    This field is required
                  </div>,
                ]}
                value={Ecom_User_ID}
                label={
                  <label
                    className={
                      'fort-medium fs-13px lh-18 d-inline-block text-gray-900 lsp--02'
                    }
                  >
                    Email
                  </label>
                }
                width={16}
              />
              <TextValidator
                placeholder="Enter Password"
                name="Ecom_Password"
                type="password"
                onChange={this.enterPW}
                validators={['required']}
                errorMessages={[
                  <div className="my-2 fort-bold text-red fs-12px">
                    This field is required
                  </div>,
                ]}
                value={password}
                label={
                  <label
                    className={
                      'fort-medium fs-13px lh-18 d-inline-block text-gray-900 lsp--02 mt-2'
                    }
                  >
                    Password
                  </label>
                }
                width={16}
              />
              <Link
                className="fs-13px lh-18 mt-1 d-inline-block fort-book text-blue lsp--02 d-block hover-orange"
                to="/recover"
              >
                Trouble signing in?
              </Link>
              {errorMessage && errorMessage === 'failedLogin' ? (
                <div className="my-2 fort-bold text-red fs-12px">
                  We could not find that email and password combination. Please try again, contact Customer Service, or{' '}
                  <Link className="text-blue hover-orange" to="/recover-password">
                  reset
                  </Link>{' '}your password.
                </div>
              ) : (
                ''
              )}
              {!!errorMessage && errorMessage === 'disabledLogin' ? (
                <div className="my-2 fort-bold text-red fs-12px">
                  Your login has been disabled. Please contact BIZCS.
                </div>
              ) : (
                ''
              )}
              {errorMessage && errorMessage === 'failedLoginNonEmail' ? (
                <div className="my-2 fort-bold text-red fs-12px">
                  We were unable to find this account. Please try again, <Link className="text-blue hover-orange" to="/update">
                  update your account</Link>, or contact Customer Service.
                </div>
              ) : (
                ''
              )}
              {!!systemResponse && String(systemResponse).trim() !== '' ? (
                <>
                  <div
                    className={
                      'my-2 fort-bold text-red fs-12px mx-0 agreement-error-message mt-2'
                    }
                  >
                    {systemResponse}
                  </div>
                </>
              ) : (
                ''
              )}
              <PRHButton
                bold
                borderColorOverride={'transparent'}
                iconColorOverride="white"
                textColorOverride="white"
                backgroundColorOverride="blue"
                iconright
                icon="arrow right"
                size="large"
                className="mt-4 mb-6 fs-2 lh-19 sign-in-button lsp--015"
                textClass="px-3"
              >
                Sign In
              </PRHButton>

              <Input
                className="d-none"
                name="target"
                type="hidden"
                //"https://localhost/dashboard"
                value={loginTarget}
              />
              {!isMobile && <>
                <span className="fs-13px mt-5 d-inline-block mr-1 fort-book text-gray-900">
                  New customer? <> </>
                </span>
                  <Link
                  className="fort-book fs-13px text-blue hover-orange"
                  to="/registration"
                  onClick={(e) => EventTrigger('link', 'Registration form')}
                  >
                  Register an account today.
                  </Link>
              </>}
            </Form>
          </FormContainer>
        </div>
      </>
    );
  }
}

export default withRouter(withSecurity(withApp(withAccount(withMobile(LoginForm)))));
