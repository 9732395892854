import React, { useState, useEffect } from 'react';
import './BackToTopButton.scss';
import { Icon } from 'semantic-ui-react';

export const BackToTopButtonMobile = () => {
  const [sticky, setSticky] = useState(false);
  const doc = document.documentElement;
  const w = window;

  const checkScroll = function () {
    let curScroll;

    curScroll = w.scrollY || doc.scrollTop;

    toggleButton(curScroll);
  };

  const toggleButton = function (curScroll) {
    if (curScroll < 450) {
      setSticky(false);
    } else if (curScroll > 450) {
      setSticky(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);

    return () => window.removeEventListener('scroll', checkScroll);
  }, []);

  return (
    <div
      className={`back-to-top-mobile-button p-2 ${
        sticky ? 'back-top-mobile-sticky' : ''
      }`}
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    >
      Back to top
      <Icon name="arrow up" className="ml-1" />
    </div>
  );
};
