import React from "react";

const NoAccessMessage = (props) => {
  return (
    <div className='d-flex align-items-center flex-column mt-8 mb-8 nam-container'>
      <div
        style={{ color: "#515556" }}
        className='mb-2 fort-light fs-24px lh-28px'
      >
        You do not currently have access to this page.
      </div>
      <div style={{ color: "#22222" }} className='fs-14px lh-19px fort-book'>
        Please verify your permissions with your account administrator.
      </div>
    </div>
  );
};

export default NoAccessMessage;
