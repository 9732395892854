import { useState } from 'react';
import { Accordion, Form, Menu, Input, Checkbox } from 'semantic-ui-react';
import './customFilters.scss';
import CustomFilter from './customFilter';
import PRHButton from '../../../shared-react-components/elements/prhbutton';
import { useMediaQuery } from 'react-responsive';
import {
  MOBILE_SCREEN_WIDTH,
  MOBILE_SMALL_SCREEN_WIDTH,
} from '../../../utils/const';

const CustomFilters = (props) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const isSmallScreenMobileDevice = useMediaQuery({
    maxWidth: MOBILE_SMALL_SCREEN_WIDTH,
  });

  const [activeIndex, setActiveIndex] = useState(-1);
  const [searchText, setSearchText] = useState('');
  const [hasFocus, setHasFocus] = useState(false);

  const { selectedFilterCount } = props;

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const searchInput = (e) => {
    props.searchCompTitles(e.target.value);
    setSearchText(e.target.value); // just to track
  };

  const variantsHandler = (e, data) => {
    props.updateResults(e, data);
  };

  let filters = null;
  let customSearch = '';
  let filterHeaderTxt = '';

  if (isMobile && props.selectedFilterCount > 0 && props.numOfResults > 0) {
    selectedFilterCount === 1
      ? (filterHeaderTxt = 'Clear (1)')
      : (filterHeaderTxt = `Clear (${selectedFilterCount})`);
  }
  if (!isMobile && props.selectedFilterCount > 0 && props.numOfResults > 0) {
    selectedFilterCount === 1
      ? (filterHeaderTxt = 'Clear Filter (1)')
      : (filterHeaderTxt = `Clear Filters (${selectedFilterCount})`);
  }
  if (props.customSearch === true) {
    customSearch = (
      <Form onSubmit={search}>
        <Input
          id="searchlist"
          name="searchlist"
          type="text"
          icon="search"
          iconPosition="left"
          placeholder={'Search Lists'}
          onChange={searchInput}
          className={hasFocus || searchText ? 'active' : ''}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
        />
      </Form>
    );
  }

  if (props.filters) {
    const tempFilters = props.filters;
    const nonZeroFilters = _.filter(tempFilters, function (item) {
      return item.values.length > 0;
    });
    filters = (
      <Accordion as={Menu} vertical className={`catalog-filters-accordion ${props.pageType && props.pageType==='catalog' ?'cat-display': ''}`}>
        {nonZeroFilters.map((filter, index) => {
          if (
            filter.resultsMatchID !== 'isVariant' &&
            filter.resultsMatchID !== 'cartQuantity'
          ) {
            return (
              <CustomFilter
                name={filter.filterDisplayName}
                colName={filter.resultsMatchID}
                options={filter.values}
                index={index}
                key={index}
                activeInd={activeIndex}
                handleClick={handleClick}
                updateResults={props.updateResults}
              />
            );
          } else {
            if (
              filter.resultsMatchID === 'cartQuantity' &&
              props.inCartQuantity
            ) {
              return (
                <CustomFilter
                  name={filter.filterDisplayName}
                  colName={filter.resultsMatchID}
                  options={filter.values}
                  index={index}
                  key={index}
                  activeInd={activeIndex}
                  handleClick={handleClick}
                  updateResults={props.updateResults}
                />
              );
            }
          }
        })}
      </Accordion>
    );
  }

  return (
    <div className="searchFilters">
      <Form>
        {isMobile && isSmallScreenMobileDevice && (
          <div className="d-flex justify-content-end">
            <PRHButton
              size="large"
              icon="close"
              className="clear-filters-close-button"
              iconHolderClass="clear-filters-close-button-holder"
              iconClassName="clear-filters-close-button-icon"
              onClick={() => {
                props.clearResultFilters();
                props.closeFiltersMenu();
              }}
            />
          </div>
        )}
        <div
          className={`filterHeader d-flex align-items-center ${
            selectedFilterCount > 0
              ? 'justify-content-space-between'
              : 'justify-content-end'
          }`}
        >
          {selectedFilterCount > 0 && (
            <PRHButton
              size="large"
              icon="close"
              onClick={props.clearResultFilters}
              className={`clear-filters ${isMobile ? '' : ''}`}
            >
              {filterHeaderTxt}
            </PRHButton>
          )}
          {isMobile && (
            <PRHButton
              size="medium"
              className={`ap-button ${
                isSmallScreenMobileDevice ? 'my-2 mr-0' : 'mx-1'
              }`}
              icon="check"
              backgroundColorOverride="blue"
              textColorOverride="white"
              iconColorOverride="white"
              onClick={props.closeFiltersMenu}
            >
              Apply
            </PRHButton>
          )}
          {isMobile && !isSmallScreenMobileDevice && (
            <PRHButton
              size="large"
              icon="close"
              className="clear-filters-close-button"
              iconHolderClass="clear-filters-close-button-holder"
              iconClassName="clear-filters-close-button-icon"
              onClick={() => {
                props.clearResultFilters();
                props.closeFiltersMenu();
              }}
            />
          )}
        </div>
        {customSearch}
        <Menu.Item className="variants">
          Show Variants{' '}
          <Checkbox
            toggle
            name="isVariant"
            id="isVariant"
            label={<label htmlFor="isVariant" className="visually-hidden">Show Variants</label>}
            checked={props.variantFilter}
            onClick={(e, data) => props.updateResults(e, data)}
          />
        </Menu.Item>
        {filters}
      </Form>
    </div>
  );
};

export default CustomFilters;
