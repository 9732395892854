import React, { Component } from "react";
import withSecurity from "../hc/withSecurity";
import withCart from "../hc/withCart";
import AccountsBillingAndDetails from "../../assets/images/accounts-billing-and-details.svg";
import { withRouter } from "react-router";
import { PRHContainerGeneral } from "../elements/elements";
import { Divider } from "../../shared-react-components/elements/elements";
import "./admin.scss";
import withMobile from "../hc/withMobile";

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billTos: [{ name: "jeff" }],
      modalOpen: false,
    };
  }

  modalControl(e) {
    this.setState({ modalOpen: e });
  }

  render() {
    // console.log('BD', this.props);

    const { billTos } = this.state;

    const isMobile = this.props.mobile.isMobile;

    return (
      <>
        <div className='accounts-settings'>
          <div>
            <div
              className='accounts-page-headers-images'
              style={{ marginLeft: isMobile ? "-7px" : "0" }}
            >
              <img
                src={AccountsBillingAndDetails}
                alt='Billing and Details Icon'
              />
            </div>
            <div className='accounts-page-headers fort-light text-gray-900 fs-24px'>
              Billing and Details
            </div>
            {!isMobile && <Divider className='accounts-header-divider' />}
          </div>
          <div className={isMobile ? "mt-2" : ""}>
            Contact our New Accounts Team at{" "}
            <a href='mailto:newaccount@penguinrandomhouse.com'>
              newaccount@penguinrandomhouse.com
            </a>{" "}
            regarding any account changes or additions.
          </div>
          {isMobile && <Divider className='accounts-header-divider' />}
        </div>
        <PRHContainerGeneral
          className={`content-block-border pb-0 mb-6 mt-4 ${
            isMobile ? "account-details-container-mobile p-0" : ""
          }`}
        >
          <div className='d-flex flex-row w-100'>
            <div className='d-flex flex-column w-50 mb-1'>
              <div
                className={`text-uppercase fs-2 mb-1 ${
                  isMobile ? "fort-medium text-gray-700" : "fort-bold"
                }`}
              >
                {isMobile ? "account" : "account number"}
              </div>
              <div>{this.props.cart.customerInfo.customer.accountNo}</div>
            </div>
            <div className='d-flex flex-column w-50'>
              <div
                className={`text-uppercase fs-2 mb-1 ${
                  isMobile ? "fort-medium text-gray-700" : "fort-bold"
                }`}
              >
                {isMobile ? "SAN#" : "SAN NUMBER"}
              </div>
              <div>{this.props.cart.customerInfo.customer.sanNo}</div>
            </div>
          </div>
          {!isMobile && <Divider tight />}
          <div
            className={`d-flex w-100 mt-3 mb-1 ${
              isMobile ? "flex-column" : "flex-row"
            }`}
          >
            <div
              className={`d-flex flex-column ${
                isMobile ? "w-100 mb-1" : "w-50"
              }`}
            >
              <div
                className={`text-uppercase fs-2 mb-1 ${
                  isMobile ? "fort-medium text-gray-700 w-100" : "fort-bold"
                }`}
              >
                terms of payment
              </div>
              <div>{this.props.cart.customerInfo.customer.termsDesc}</div>
            </div>
            <div className='d-flex flex-column w-50'>
              <div
                className={`text-uppercase fs-2 mb-1 ${
                  isMobile ? "fort-medium text-gray-700 w-100" : "fort-bold"
                }`}
              >
                substitutions
              </div>
              <div>{this.props.cart.customerInfo.customer.subDesc}</div>
            </div>
          </div>
          <Divider tight />
          <div className='d-flex flex-column mt-3'>
            <div
              className={`text-uppercase fs-2 mb-2 ${
                isMobile ? "fort-medium text-gray-700" : "fort-bold"
              }`}
            >
              addresses
            </div>
            <div className={isMobile ? "d-flex flex-row flex-wrap" : ""}>
              {this.props.cart.customerInfo.shipTos.map((bt, i) => (
                <div key={i} className={`mb-3 ${isMobile ? "fs-13px" : ""}`}>
                  {bt.address.name1 && (
                    <div className={isMobile ? "fort-bold" : ""}>
                      {bt.address.name1} - {bt.accountNo}
                    </div>
                  )}
                  {bt.address.name2 && <div>{bt.address.name2}</div>}
                  <div>{bt.address.street}</div>
                  {bt.address.name3 && <div>{bt.address.name3}</div>}
                  <div>
                    {bt.address.city}
                    {bt.address.state ? `, ${bt.address.state}  ` : "  "}{" "}
                    {bt.address.postalCode} {bt.address.country}
                  </div>
                  {bt.accountNo ===
                    this.props.cart.customerInfo.customer.accountNo &&
                    this.props.cart.customerInfo.currentUser.roleNo !==
                      "W1" && (
                      <div className='bd-primary-billing'>Primary Billing</div>
                    )}
                </div>
              ))}
            </div>
          </div>
        </PRHContainerGeneral>
      </>
    );
  }
}

export default withRouter(withSecurity(withCart(withMobile(AccountDetails))));
