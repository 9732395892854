import { Component } from 'react';
import Download from '../../../assets/images/download.svg';
//import Download from '../../assets/images/download.svg';
import { withRouter } from 'react-router-dom';
import './deliveries.scss';

class deliveries extends Component {
  render() {
    const {
      deliveries
    } = this.props.data;
    return (
      <>
      <div className={"cell-padding"}>
      {deliveries.map((delivery, index)=>{
        return (
          <div className={"inner-row-padding"} key={`${index}_${index}`}>
            Deliveries: {delivery.deliveryCount}   <span className={"deliveries-pipe"}>|</span>   Total Qty: {delivery.deliveryItemCount}
            </div>
      );})}
      </div>
    </>
    );
  }
}

export default withRouter(deliveries);
