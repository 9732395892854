import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * 
 * Pass 2 props, links and active 
 * 
 * Links should be an objects like this:
 * {
        titleChanges: {
            url: '/title-changes',
            text: 'Title Changes',
        },
        newsFromPRH: {
            url: '/news-from-prh/',
            text: 'News From PRH',
        },
    }
 * 
 * active, optionally, should be the key for a tab, e.g., in this example `titleChanges`
 *  
 * 
 * @param {*} param0 
 * @returns 
 */
const Tabs = ({ links, active, allowActiveToBeClicked }) => {
  const linksOutput = Object.keys(links).map((key, i, array) => {
    const menuItemExtraClass = i === 0 ? 'pl-2' : '';
    const linkExtraClass = i === 0 ? 'pr-2' : 'px-2';
    const activeExtraClass = i > 0 && i < array.length - 1 ? 'mx-2' : 'mr-2';
    const borderPrefix = i + 1 < array.length ? ' ' : 'no';
    if (!!active && key === active && allowActiveToBeClicked) {
      return (
        <div
          className={`fort-medium fs-12px lh-18px text-uppercase ${menuItemExtraClass}`}
          key={i}
        >
          <Link
            className={`mt-2 d-inline-block hover-orange text-gray-900`}
            to={links[key].url}
          >
            <span
              className={`d-inline-block ${borderPrefix}border-right-gray-300 pr-2 ${linkExtraClass}`}
            >
              {links[key].text}
            </span>
            <span
              className={`h-3px bg-orange d-block mb-2 ${borderPrefix}border-right-gray-300 ${activeExtraClass}`}
              style={{
                marginLeft: i === 0 ? '0' : '1rem',
              }}
            ></span>
          </Link>
        </div>
      );
    }
    if (!!active && key === active) {
      return (
        <div
          className={`fort-medium fs-12px lh-18px text-uppercase ${menuItemExtraClass}`}
          key={i}
        >
          <span
            className={`mt-2 d-inline-block text-gray-900 ${
              i < array.length - 1 ? 'border-right-gray-300' : ''
            }  ${linkExtraClass}`}
          >
            <span className="d-inline-block">{links[key].text}</span>
          </span>
          <span
            className={`h-3px bg-orange d-block mb-2 ${borderPrefix}border-right-gray-300 ${activeExtraClass}`}
            style={{
              marginLeft: i === 0 ? '0' : '1rem',
            }}
          ></span>
        </div>
      );
    }
    return (
      <div
        className={`fort-medium fs-12px lh-18px text-uppercase ${menuItemExtraClass}`}
        key={i}
      >
        <span className="d-inline-block  ">
          <Link
            to={links[key].url}
            className={`my-2 text-hover-orange d-inline-block text-gray-500 ${borderPrefix}border-right-gray-300  pr-2  ${linkExtraClass}`}
          >
            {links[key].text}
          </Link>
        </span>
      </div>
    );
  });

  return <div className="d-flex">{linksOutput}</div>;
};

Tabs.defaultProps = {
  active: false,
  allowActiveToBeClicked: false,
};

Tabs.propTypes = {
  links: PropTypes.object.isRequired,
  active: PropTypes.string,
  allowActiveToBeClicked: PropTypes.bool,
};

export default Tabs;
