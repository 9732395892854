import { Component } from "react";
import { BrowserRouter, Prompt } from "react-router-dom";
import { Routes } from "./routes/routes";
import { SecurityProvider } from "./providers/SecurityProvider";
import { ApplicationProvider } from "./providers/ApplicationProvider";
import "./shared-react-components/style-utils/utilities.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Analytics from "react-router-ga";
// import "./shared-react-components/assets/scss/_buttons.scss";
import "./assets/scss/main.scss";
import "./shared-react-components/assets/scss/main.scss";
import CartProvider from "./providers/CartProvider";
import RegistrationProvider from "./providers/RegistrationProvider";
import AdminProvider from "./providers/AdminProvider";
import AccountProvider from "./providers/AccountProvider";
import AuthServiceProvider from "./providers/AuthServiceProvider";
import ToastManager from "./shared-react-components/components/toasts/ToastManager";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga";
import MobileProvider from "./providers/MobileProvider";
import UserConfirmation from "./components/UserConfirmation";
import withDirty from "./shared-react-components/components/hc/withDirty";

// Sentry.init({
//   dsn: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
//   integrations: [new Integrations.BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

class App extends Component {
  constructor() {
    super();
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    /*
    Sentry.init({
      environment: process.env.REACT_APP_SENTRY_URL,
      dsn: process.env.REACT_APP_SENTRY_URL,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.2,
    });*/
    // this.state = {
    //   showFinalModal: false,
    //   stepsEnabled: false,
    //   initialStep: 0,
    //   steps: [
    //     {
    //       element: ".bizSearch",
    //       intro: "Search ISBN, title, author.",
    //     },
    //     {
    //       element: ".orders-dropdown",
    //       intro: "Orders, invoices, claims.",
    //     },
    //     {
    //       element: ".account-dropdown",
    //       intro:
    //         "Billing and details, Account Reps, Manage users, Change password, promotions or sign out.",
    //     },
    //     {
    //       element: ".cart-badge",
    //       intro: "Check your cart items.",
    //     },
    //   ],
    // };
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false, showFinalModal: true }));
  };

  // toggleSteps = () => {
  //   this.setState(() => ({ stepsEnabled: false }));
  //   this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  // };

  userConfirmationBuilder(callback, dirtyHandler) {
    UserConfirmation(callback, dirtyHandler);
  }

  render() {
    // tutorial use from here
    // const { stepsEnabled, steps, initialStep } = this.state;
    //till here
    return (
      <div>
        {/* Tutorial display from here*/}
        {/* <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={initialStep}
          onExit={this.onExit}
        /> */}
        {/* till here */}
        <BrowserRouter
          basename={"/"}
          getUserConfirmation={(message, callback) => {
            this.userConfirmationBuilder(callback, this.props.dirtyHandler);
          }}
        >
          <Analytics id='UA-ANALYTICS-1'>
            <AuthServiceProvider>
              <SecurityProvider>
                <ToastManager />
                <Prompt
                  when={this.props.dirtyHandler.isDirty}
                  message={(f) => ``}
                />
                <ApplicationProvider>
                  <MobileProvider>
                    <CartProvider>
                      <RegistrationProvider>
                        <AccountProvider>
                          <AdminProvider>
                            <Routes />
                          </AdminProvider>
                        </AccountProvider>
                      </RegistrationProvider>
                    </CartProvider>
                  </MobileProvider>
                </ApplicationProvider>
              </SecurityProvider>
            </AuthServiceProvider>
          </Analytics>
        </BrowserRouter>
      </div>
    );
  }
}

export default withDirty(App);
