import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Popup, Icon, Image } from "semantic-ui-react";
import "../TitleMetadata.scss";
import { NumericFormat } from "react-number-format";
import useCart from "../../../components/hc/useCart";
import useApp from "../../../components/hc/useApp";
import useSecurity from "../../../components/hc/useSecurity";
import Invoice from "../../../assets/images/icon-order-16.svg";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../../utils/const";

function SeriesOrderHistorySummary(props) {
  // console.log("SeriesOrderHistorySummary", props);

  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const {
    selectedFormat,
    allFormats,
    selectedLocation,
    seriesOrderHistory,
    setSeriesHistoryTab,
  } = props;
  const cart = useCart();
  const user = useSecurity();
  const app = useApp();
  const history = useHistory();

  // const [orderInfo, setOrderInfo] = useState(false);
  const [colapsed, setColapsed] = useState(false);

  // useEffect(() => {
  //   console.log('SeriesOrderHistorySumary', props);
  //   setOrderInfo(false);
  //   if (cart.isUserPow() && cart.shipTo === -1) {
  //     return;
  //   }

  //   let shipTo = null;
  //   if (cart.shipTo >= 0) {
  //     shipTo = cart.getShipToAccount().accountNo;
  //   }

  //   console.log('getting series history', shipTo, selectedFormat.isbn);

  //   cart
  //     .getSeriesHistory(
  //       selectedFormat.isbnStr,
  //       selectedFormat.formatCode,
  //       shipTo,
  //       true
  //     )
  //     .then((ordersResponse) => {
  //       console.log('ordersummaryresponse', ordersResponse);

  //       // if (ordersResponse?.data?.data?.orders?.length > 0) {
  //       //   let totalSelectedItems = 0;
  //       //   let totalItems = 0;

  //       // ordersResponse.data.data.orders.forEach((order) => {
  //       //   if (order.status !== '2') {
  //       //     if (order.isbn === selectedFormat.isbnStr) {
  //       //       totalSelectedItems += order.quantity;
  //       //     }
  //       //     totalItems += order.quantity;
  //       //   }
  //       // });
  //       // if (totalItems > 0) {
  //       //   setOrderInfo({ totalItems, totalSelectedItems });
  //       // }
  //       // setOrderInfo(ordersResponse.data.data);
  //       // }
  //       let summ = ordersResponse.data.data.summary.filter((si) => {
  //         if (si.shipTo === shipTo) {
  //           return true;
  //         }
  //       });
  //       console.log('summ', summ);
  //       setOrderInfo(summ);
  //     });
  // }, [cart.getShipToAccount(), selectedFormat.isbn]);

  if (!selectedLocation) {
    return <></>;
  }

  if (!seriesOrderHistory || seriesOrderHistory?.summary?.length === 0)
    return <></>;

  if (
    seriesOrderHistory?.summary?.filter(
      (oh) => oh.shipTo.toString() === selectedLocation.toString()
    ).length === 0
  )
    return <></>;

  // if (
  //   orderInfo.summary.length === 0 ||
  //   (orderInfo.summary[0].thisIsbnQty === 0 &&
  //     orderInfo.summary[0].otherIsbnsQty === 0)
  // )
  //   return <></>;
  // console.log(
  //   'SeriesOrderHistorySummary',
  //   selectedLocation,
  //   seriesOrderHistory
  // );
  if (!isMobile) {
    return (
      <div className='w-100'>
        {seriesOrderHistory && (
          <div className='tm-poi-box fs-12px lh-12px mt-3 pl-2 pr-2 pt-1 pb-1 d-flex fort-book'>
            <div
              className={`w-20 d-flex ${colapsed ? "align-items-center" : ""}`}
            >
              <div
                className='oh-dropdown'
                onClick={() => {
                  setColapsed(!colapsed);
                }}
              >
                <Icon
                  className='fs-24px'
                  style={{
                    top: `${colapsed ? "3px" : "4px"}`,
                    position: "relative",
                  }}
                  name={`angle ${colapsed ? "right" : "down"}`}
                />
              </div>
              <span className='fort-bold mr-1' style={{ marginTop: "4px" }}>
                Series Order History
              </span>{" "}
            </div>
            {!colapsed && (
              <div
                className={`d-flex flex-row ${colapsed ? "d-none" : ""}`}
                style={{ marginTop: "4px" }}
              >
                {seriesOrderHistory?.summary
                  ?.filter(
                    (oh) => oh.shipTo.toString() === selectedLocation.toString()
                  )
                  .map((oi, idx) => (
                    <div key={idx} className='d-flex flex-column mr-6'>
                      <div className='fs-12px lh-12px fort-medium'>
                        <Link to={`/titleinfo/${oi.linkIsbn}`}>
                          {oi.formatCode === "CB"
                            ? `Issue #${oi.seriesNo}`
                            : `#${oi.seriesNo} ${
                                oi.title.length > 20
                                  ? oi.title.substring(0, 20) + "..."
                                  : oi.title
                              }`}
                        </Link>
                      </div>
                      <div className='fs-16px lh-20px text-gray-900 fort-medium'>
                        {oi.qty} units{" "}
                        {oi?.cartQuantity > 0
                          ? `(${oi.cartQuantity} in cart)`
                          : ""}
                      </div>
                      <div className='fs-12px lh-12px fort-medium'>
                        {oi.formatCode === "CB" ? `All covers` : `All formats`}
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {/* {cart.isUserPow() ? (
                <span className="text-gray-700">This cover: </span>
              ) : (
                <span className="text-gray-700">This format: </span>
              )}
              <NumericFormat
                className="text-black"
                value={orderInfo.summary[0].thisIsbnQty}
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
                displayType={'text'}
              />{' '}
              <span className="text-black">units</span>
              <span className="ml-1 mr-1">|</span>
              {cart.isUserPow() ? (
                <span className="text-gray-700">All covers: </span>
              ) : (
                <span className="text-gray-700">All formats: </span>
              )}
              <NumericFormat
                className="text-black"
                value={orderInfo.summary[0].allIsbnsQty}
                thousandSeparator={true}
                decimalScale={0}
                fixedDecimalScale={true}
                displayType={'text'}
              />{' '}
              <span className="text-black">units</span>
            </div> */}
            <div className='ml-auto d-flex justify-content-start '>
              <Icon
                className='fs-12px book-detail-down-icon'
                name='arrow circle down'
              ></Icon>
              <a
                className='fort-medium text-gray-700'
                href='#title-detail-tab-line'
                onClick={() => setSeriesHistoryTab()}
              >
                View more
              </a>
            </div>
          </div>
        )}
      </div>
    );
  } else if (isMobile) {
    return (
      <div className='w-100'>
        {seriesOrderHistory && (
          <div className='tm-poi-box fs-12px lh-12px mt-3 pl-2 pr-2 pt-1 pb-1 d-flex fort-book flex-column'>
            <div className='d-flex justify-self-between'>
              <div
                className={`w-50 d-flex ${
                  colapsed ? "align-items-center" : ""
                }`}
              >
                <div
                  className='oh-dropdown'
                  onClick={() => {
                    setColapsed(!colapsed);
                  }}
                >
                  <Icon
                    className='fs-24px'
                    style={{
                      top: `${colapsed ? "3px" : "4px"}`,
                      position: "relative",
                    }}
                    name={`angle ${colapsed ? "right" : "down"}`}
                  />
                </div>
                <span className='fort-bold mr-1' style={{ marginTop: "4px" }}>
                  Series Order History
                </span>{" "}
              </div>
              <div className='ml-auto d-flex justify-content-start '>
                <Icon
                  className='fs-12px book-detail-down-icon'
                  name='arrow circle down'
                ></Icon>
                <a
                  className='fort-medium text-gray-700'
                  href='#title-detail-tab-line'
                  onClick={() => setSeriesHistoryTab()}
                >
                  View more
                </a>
              </div>
            </div>

            {!colapsed && (
              <div
                className={`d-flex flex-row justify-content-center ${
                  colapsed ? "d-none" : ""
                }`}
                style={{ marginTop: "4px" }}
              >
                {seriesOrderHistory?.summary
                  ?.filter(
                    (oh) => oh.shipTo.toString() === selectedLocation.toString()
                  )
                  .map((oi, idx) => (
                    <div key={idx} className='d-flex flex-column mr-6'>
                      <div className='fs-12px lh-12px fort-medium'>
                        <Link to={`/titleinfo/${oi.linkIsbn}`}>
                          {oi.formatCode === "CB"
                            ? `Issue #${oi.seriesNo}`
                            : `#${oi.seriesNo} ${
                                oi.title.length > 20
                                  ? oi.title.substring(0, 20) + "..."
                                  : oi.title
                              }`}
                        </Link>
                      </div>
                      <div className='fs-16px lh-20px text-gray-900 fort-medium'>
                        {oi.qty} units{" "}
                        {oi?.cartQuantity > 0
                          ? `(${oi.cartQuantity} in cart)`
                          : ""}
                      </div>
                      <div className='fs-12px lh-12px fort-medium'>
                        {oi.formatCode === "CB" ? `All covers` : `All formats`}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

SeriesOrderHistorySummary.propTypes = {};

export default SeriesOrderHistorySummary;
