import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { useCheckOverFlow } from "../hooks/useCheckOverFlow";
import { NumericFormat } from "react-number-format";
import { withRouter, Prompt, Link } from "react-router-dom";
import { Grid, Icon, Loader } from "semantic-ui-react";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import "./SeriesHistory.scss";
import { isFocCart } from "../../utils/utilities";
import useCart from "../hc/useCart";
import useSecurity from "../hc/useSecurity";

const ITEMS_PER_PAGE = 25;

export const SeriesHistory = (props) => {
  const {
    data,
    panelOpen,
    context = null,
    shipTo = false,
    sendToBlank,
  } = props;
  const user = useSecurity();
  const cart = useCart();
  const ref = useRef(null);
  const [seriesHistoryPanel, setSeriesHistoryPanel] = useState(<></>);
  const isOverflow = useCheckOverFlow(ref, (isOverflowFromCallback) => {
    console.log("SeriesHistory", props);
    //console.log(isOverflowFromCallback);
    // true
  });
  const containerRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const start = (currentPage - 1) * ITEMS_PER_PAGE;
  const end = start + ITEMS_PER_PAGE;
  const itemsToShow =
    Object.keys(data).length > 24 && context === "titleDetail"
      ? data.slice(start, end)
      : data;
  const totalPages = Math.ceil(data.length / ITEMS_PER_PAGE);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    ref.current.scrollTop = 0;
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
    ref.current.scrollTop = 0;
  };

  useEffect(() => {
    if (panelOpen) {
      setSeriesHistoryPanel(seriesTitleRows(itemsToShow));
    } else {
      setSeriesHistoryPanel(<></>);
    }
  }, [panelOpen]);

  useEffect(() => {
    if (panelOpen && data.length !== 0) {
      ref.current.scrollTop = 0;
    }
  }, [data]);

  /*
        useEffect(() => {
          function updateState() {
             const el = ref.current;
             el && setHasScrollBar(el.scrollWidth > el.getBoundingClientRect().width);
          }
    
          updateState();
    
          window.addEventListener('resize', updateState);
          return () => window.removeEventListener('resize', updateState);
       }, [state]);
    */
  /*
      const sendToBlank = (newPage) =>{
      localStorage.setItem(
        "shipTo",
        shipTo
      );
        window.open(newPage);
       }
*/
  const getAccordionTitleDate = (accDate) => {
    if (accDate.split("-").length < 3) {
      return "Unknown";
    } else {
      const tempDate = accDate.split("-");
      const newAccDate = tempDate[1] + "/" + tempDate[2] + "/" + tempDate[0];
      return newAccDate;
    }
  };

  const loadingPanel = () => {
    return (
      <Grid.Row>
        <Grid.Column width={16}>
          {
            <Loader
              style={{ marginTop: "40px", marginBottom: "40px" }}
              size='small'
              active
              inline='centered'
            />
          }
        </Grid.Column>
      </Grid.Row>
    );
  };

  const seriesTitleRows = (titleData) => {
    let titleCount = 0;
    return titleData.length > 0
      ? Object.values(
          titleData.map(
            (title, key) => (
              titleCount++,
              (
                <Grid.Row
                  key={key}
                  className={`${
                    !(titleCount === titleData.length)
                      ? "sg-content pb-2"
                      : "sg-content-last pb-2"
                  } pt-2`}
                >
                  <Grid.Column
                    mobile={11}
                    tablet={11}
                    computer={8}
                    largeScreen={7}
                    widescreen={7}
                    className='pl-2 pr-2'
                  >
                    {title.available && !user.isAppCompCopy() ? (
                      <a
                        className='titleLabel text-gray-900'
                        href={`/titleinfo/${title.isbn}`}
                        //href='#'
                        //target='_blank'
                        onClick={(e) => {
                          e.preventDefault();
                          sendToBlank(`/titleinfo/${title.isbn}`);
                          //newWindow(`/titleinfo/${title.isbn}`)
                          //e.preventDefault();
                          //window.open(`/titleinfo/${title.isbn}`);
                          //self.focus();
                          //return false;
                          //history.push(`/titleinfo/${title.isbn}`);
                        }}
                      >
                        {title.title}
                      </a>
                    ) : (
                      title.title
                    )}
                  </Grid.Column>
                  <Grid.Column
                    mobile={1}
                    tablet={1}
                    largeScreen={1}
                    widescreen={1}
                    className='d-none d-xl-flex'
                  >
                    {title.formatCode}
                  </Grid.Column>
                  <Grid.Column
                    mobile={2}
                    tablet={2}
                    largeScreen={2}
                    widescreen={2}
                    className='d-none d-xl-flex'
                  >
                    {title.isbn}
                  </Grid.Column>
                  <Grid.Column
                    computer={4}
                    className='d-none d-lg-flex d-xl-none'
                  >
                    <div className='d-flex flex-column'>
                      <div>{title.isbn}</div>
                      <div>
                        {title.formatCode} | {getAccordionTitleDate(title.osd)}
                      </div>
                    </div>
                  </Grid.Column>

                  <Grid.Column
                    mobile={2}
                    tablet={2}
                    largeScreen={3}
                    widescreen={3}
                    className='d-none d-xl-flex pl-6'
                  >
                    {getAccordionTitleDate(title.osd)}
                  </Grid.Column>
                  <Grid.Column
                    mobile={2}
                    tablet={2}
                    computer={2}
                    largeScreen={1}
                    widescreen={1}
                    className='d-none d-lg-flex pl-lg-2 pl-xl-4'
                  >
                    <NumericFormat
                      className=''
                      value={title.price}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      prefix={"$"}
                    />
                  </Grid.Column>
                  <Grid.Column
                    mobile={5}
                    tablet={5}
                    computer={2}
                    largeScreen={2}
                    widescreen={2}
                    className='d-flex flex-column align-items-end pr-2 pr-xl-4'
                  >
                    <div className='d-flex flex-row align-items-center'>
                      {title.cartQuantity && title.cartQuantity > 0 ? (
                        <div className='p-relative'>
                          <div
                            className={`${
                              title.cartQuantity < 10
                                ? "tr-cart-qty-sm"
                                : "tr-cart-qty"
                            } ${
                              title?.confirmationStatus === "CONFIRMED"
                                ? "confirmed"
                                : title?.confirmationStatus === "PENDING_UPDATE"
                                ? "updated"
                                : ""
                            }  ${!cart.isUserPow() ? "biz" : ""} ${
                              isFocCart(title.foc, title.focStatus) === false
                                ? "general"
                                : ""
                            }`}
                            style={{ marginTop: "-4px" }}
                          >
                            <NumericFormat
                              value={title.cartQuantity}
                              thousandSeparator={false}
                              decimalScale={0}
                              allowNegative={true}
                              fixedDecimalScale={true}
                              displayType={"text"}
                            />
                          </div>
                        </div>
                      ) : null}
                      <NumericFormat
                        /*
                              style={
                                title.cartQuantity && title.cartQuantity > 0
                                  ? { paddingRight: ".25rem", paddingBottom: "4px" }
                                  : { paddingRight: ".5rem" }
                              }*/
                        className='title-quantity'
                        value={title.qty}
                        thousandSeparator={false}
                        decimalScale={0}
                        allowNegative={true}
                        fixedDecimalScale={true}
                        displayType={"text"}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              )
            )
          )
        )
      : "";
  };

  return (
    <>
      {panelOpen ? (
        <>
          <div
            className={`${
              isOverflow ? "sg-header-adjust" : ""
            } sh-header-adjust`}
          >
            <Grid container padded className='cart-page mb-4 sh-grid'>
              <Grid.Row className='sg-header align-items-center'>
                <Grid.Column
                  mobile={11}
                  tablet={11}
                  computer={8}
                  largeScreen={7}
                  widescreen={7}
                  className='pl-2'
                >
                  Title
                </Grid.Column>
                <Grid.Column
                  largeScreen={1}
                  widescreen={1}
                  className='d-none d-xl-flex'
                >
                  Fmt
                </Grid.Column>
                <Grid.Column
                  largeScreen={2}
                  widescreen={2}
                  className='d-none d-xl-flex'
                >
                  UPC/ISBN
                </Grid.Column>
                <Grid.Column
                  computer={4}
                  className='d-none d-lg-flex d-xl-none'
                >
                  ISBN/UPC <span className='safe-pipe'> | </span> Fmt{" "}
                  <span className='safe-pipe'> | </span> OSD
                </Grid.Column>
                <Grid.Column
                  largeScreen={3}
                  widescreen={3}
                  className='d-none d-xl-flex pl-6'
                >
                  On-Sale Date
                </Grid.Column>
                <Grid.Column
                  mobile={2}
                  tablet={2}
                  computer={2}
                  largeScreen={1}
                  widescreen={1}
                  className='d-none d-lg-flex pl-lg-2 pl-xl-4' //'d-none d-lg-flex pl-lg-2 pl-xl-4'
                >
                  <span>Price</span>
                </Grid.Column>
                <Grid.Column
                  mobile={5}
                  tablet={5}
                  computer={2}
                  largeScreen={2}
                  widescreen={2}
                  className={"d-flex flex-column align-items-end"}
                >
                  <span
                    className={`${
                      isOverflow ? "qty-overflow" : "qty-no-overflow"
                    }`}
                  >
                    Order Qty
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
          {props?.data.length === 0 ? (
            loadingPanel()
          ) : (
            <div id='sh-grid-body' ref={ref}>
              <Grid
                container
                padded
                className={`cart-page mb-4 ${
                  props.data.length === 0 ? "sh-grid-loading" : "sh-grid"
                } sh-body`}
              >
                {!!!props?.data && (
                  <div className='d-flex flex-column w-100 align-items-center mt-4 mb-4'>
                    <div className='fort-book fs-24px mb-2 text-gray-700'>
                      No series order history found.
                    </div>
                    <div className='fort-book fs-14px text-gray-750'>
                      Orders for previous items in this series will appear here.
                    </div>
                  </div>
                )}
                {seriesTitleRows(itemsToShow)}

                {/*props.data.length === 0 ? (loadingPanel): (seriesHistoryPanel)*/}
              </Grid>
            </div>
          )}
          {Object.keys(data).length > 24 && context === "titleDetail" ? (
            <div className='ot-pagination'>
              <div
                className='ot-pagination'
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "18%",
                  paddingTop: "10px",
                }}
              >
                <PRHButton
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  displayInlineFlex
                  icon='arrow left'
                  className='sidePageBtnsSeries'
                  size='small'
                  key={Math.random()}
                />
                <div className='ml-2 mr-2'>
                  Page {currentPage} of {totalPages}
                </div>
                <PRHButton
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  displayInlineFlex
                  icon='arrow right'
                  className='sidePageBtnsSeries'
                  size='small'
                  key={Math.random()}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};
