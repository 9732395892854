import { useState } from "react";
import { Button, Container, Grid, Input } from "semantic-ui-react";
import EventTrigger from "../../components/analytics/EventTrigger";
import useCart from "../../components/hc/useCart";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import "./HomeHero.scss";

const HomeHero = ({ profile, which }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });

  const cart = useCart();
  const [searchTerm, setSearchTerm] = useState("");

  let greeting = <>Hello, {profile.given_name}</>;
  const prhTitle = isMobile ? "PRH" : "Penguin Random House";
  let heading = "";
  let sentence = "";
  if (which === "biz") {
    heading = `Welcome to ${prhTitle} Self-Service`;
    sentence = "";
  }
  if (which === "compCopy") {
    heading = `Welcome to ${prhTitle} Comp Copy`;
    sentence = "";
  }

  const searchSiteOnClick = () => {
    EventTrigger("Search", searchTerm);
    cart.performSearch(searchTerm);
  };
  const searchSite = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      EventTrigger("Search", searchTerm);
      //13 is the enter keycode
      cart.performSearch(searchTerm);
    }
  };

  return (
    <div className="home-hero-container home-hero d-flex home-hero__gradient ">
      <Container
        className={`${isMobile ? "mobile-container-full-width" : "d-flex"}`}
      >
        <Grid className="w-100" relaxed>
          <Grid.Row>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={16}
              largeScreen={16}
              widescreen={16}
              className="keep-padding no-padding-md"
            >
              <div
                className={`${
                  isMobile ? "pt-4 pb-2" : "py-4"
                } d-flex justify-content-center home-hero__copy flex-column`}
              >
                <div className={`${isMobile ? "" : "px-2 px-md-0"}`}>
                  <p
                    className={`my-0 text-uppercase fs-12px lh-15 lsp--02 fort-bold ${
                      isMobile ? "text-gray-700" : "text-white"
                    }`}
                  >
                    {greeting}
                  </p>
                  <h1
                    className={`mt-1 lsp--079 lh-28 fort-light ${
                      isMobile
                        ? "text-gray-700 fs-20px"
                        : "mb-2 fs-24px text-white"
                    }`}
                  >
                    {heading}
                  </h1>
                  {sentence && (
                    <p className="my-0 text-white fs-16px lh-21 fort-book lsp--017 hidden-on-mobile">
                      {sentence}
                    </p>
                  )}
                </div>

                <Grid container className=" m-0-md-only hidden-on-mobile">
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={12}
                    largeScreen={12}
                    widescreen={12}
                    className="pl-0 pr-0 pr-lg-2"
                  >
                    <Input
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyPress={searchSite}
                      action={
                        <Button
                          // color="none"
                          icon="search"
                          className={`search_icon ${
                            searchTerm.length > 0
                              ? "text_active"
                              : "text_disabled"
                          }`}
                          onClick={() => searchSiteOnClick()}
                          disabled={searchTerm.length === 0 ? true : false}
                        />
                      }
                      // icon={<Icon name='search' onClick={searchSite.bind(this)}/>}
                      placeholder="Search for books, authors, series, etc."
                      className="bizSearch max-width-none max-width-xl-700px mx-0 w-100"
                    />
                  </Grid.Column>
                </Grid>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

export default HomeHero;
