import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import useCart from '../../../components/hc/useCart';
import PRHButton from '../../../shared-react-components/elements/prhbutton';
import {
  getSingleEntryLinkText,
  getUseButtonForPost,
  translatePost,
  translatePostTypeSlug,
} from '../../../utils/wordpressHelpers';

/**
 * Handles post data for either a single view of posts or title lists
 *
 * if useButtonOverride is set to true, it will check getPostsButtonStyle, if it is true will output a
 * button with configured text (or default to View {Singular Post Type})
 *
 * @param {*} param0
 * @returns
 */
const WordPressPostLink = ({
  children,
  post,
  className,
  inheritTitle,
  useButtonOverride,
}) => {
  const cart = useCart();
  const history = useHistory();
  // console.log('post link',post);
  const { id, type, title, meta = {} } = translatePost(post);
//  console.log('post type',type);
  // console.log('translatePostTypeSlug',translatePostTypeSlug(type));
  return (
    <Link
      to={`/${translatePostTypeSlug(type)}/${id}`}
      onClick={(e) => {
        const { title_list = null } = meta;
        if (
          Array.isArray(title_list) &&
          !!title_list.filter((e) => !!e).length > 0
        ) {
          e.preventDefault();
          cart.performSearch(title_list.join(' '), {
            title,
            ...meta,
          });

          return;
        }
        history.push({
          pathname: `/${translatePostTypeSlug(type)}/${id}`,
          state: {
            pageMeta: meta,
          },
        });
        e.preventDefault();
      }}
      className={`${className} ${
        useButtonOverride && !!getUseButtonForPost(type)
          ? `hover-no-underline`
          : ''
      }`}
    >
      {!!inheritTitle && <span dangerouslySetInnerHTML={{ __html: title }} />}

      {useButtonOverride && !!getUseButtonForPost(type) ? (
        <PRHButton
          iconHolderClass="ml-0"
          className="mt-2 hover-no-underline-child fs-12px"
          size="small"
          iconright
          icon="arrow right"
        >
          {!!getSingleEntryLinkText(type)
            ? getSingleEntryLinkText(type)
            : 'Read More'}
        </PRHButton>
      ) : (
        <>{children}</>
      )}
    </Link>
  );
};

WordPressPostLink.defaultProps = {
  className: '',
  inheritTitle: false,
  useButtonOverride: false,
};

export default WordPressPostLink;
