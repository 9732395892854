import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import withApp from '../../../components/hc/withApp';
import {
  getPrettyName,
  translatePostTypeSlug,
} from '../../../utils/wordpressHelpers';
import LoadingContent from './LoadingContent';
import { decode } from 'html-entities';
import { Button } from 'semantic-ui-react';

const CatalogList = ({ taxonomy, postType, app, category, searchQuery, filters, year, setYear }) => {
  const [terms, setTerms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const reset = () => {
    setLoading(true);
    setError(false);
  };

/*
  useEffect(() => {
    async function asyncCall() {
      reset();
      const terms = await app.bizContentApi.getTerms(taxonomy);
      if (!!terms?.error?.message) {
        setError(terms.error.message);
      } else if (
        !terms ||
        !Array.isArray(terms) ||
        (Array.isArray(terms) && terms.length === 0)
      ) {
        setError(`No Terms Found!`);
      } else {
        setTerms(terms);
      }
      setLoading(false);
    }

    asyncCall();
  }, [taxonomy, app.bizContentApi]);
*/
  return (
    <>
      <p className="text-uppercase mt-0 fort-bold fs-12px lh-15px lsp--02 pb-2 mb-0">
        {!!searchQuery ? <>Filter Results By</> : <>Years</>}
      </p>
      {/*loading && <LoadingContent />*/}
      {/*!loading && error && (
        <div>
          <p className="text-center my-5 px-0">{error}</p>
        </div>
      )*/}
      {//!loading && !error && (
        <>
          {filters ? Object.keys(filters).map(function(i) {
            if (filters[i].display === year) {
              return (
                <p className="fort-bold m-0 mb-0.78571rem" key={i}>
                  <span className="pb-0.2857rem  border-3-bottom-orange d-inline-block fs-13px">
                  {filters[i].display}
                  </span>
                </p>
              );
            }
            return (
              <p className="m-0 mb-0.78571rem" key={i}>
                <Link
                  className="fort-book text-gray-900 fs-13px lh-18px lsp--02 hover-orange d-inline d-inline-block border-3-bottom-transparent"
                  //to={`/catalog/${filters[i].display}`}
                  to="#"
                  onClick={()=>setYear(filters[i].display)}
                >
                  {filters[i].display}
                </Link>
              </p>
            );
          }):''}
        </>
      /*)*/}
    </>
  );
};

export default withApp(CatalogList);
