import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const getProxyAPIUrl = () => {
  const hostName = window.location.hostname;

  return hostName;
};

const baseProxyQuery = fetchBaseQuery({
  baseUrl: "/",
  prepareHeaders: async (headers, { getState }) => {
    const user = getState().user;
    headers.set("Authorization", `Bearer ${user.accessToken}`);
    return headers;
  },
  keepUnusedDataFor: 30,
});

export const ProxyApi = createApi({
  reducerPath: "ProxyApi",
  baseQuery: baseProxyQuery,
  keepUnusedDataFor: 0.001,

  endpoints: (builder) => ({
    getVersion: builder.query({
      query: () => {
        const headers = { Accept: "text/html" };

        return {
          url: `/version.json`,
          method: "GET",
          headers,
          responseHandler: (response) => response.text(),
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getVersion", arg);
      },
    }),
  }),
});

export const { useGetVersionQuery, useLazyGetVersionQuery } = ProxyApi;
