import { Component } from 'react';
import { Table, Header, Icon } from 'semantic-ui-react';
import ManageUserForm from './ManageUserForm';
import withSecurity from '../hc/withSecurity';
import withCart from '../hc/withCart';
import AccountsBillingAndDetails from '../../assets/images/accounts-billing-and-details.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
import UserList from './UserList';
import {
  Biz2Modal,
  PRHContainerGeneral,
  PRHContainerHeader,
} from '../elements/elements';
import { Divider, PRHButton } from '../../shared-react-components/elements/elements';
import CreditCardModal from "../modals/CreditCardModal";
import './admin.scss';

class BillingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billTos: [{ name: 'jeff' }],
      modalOpen: false,
    };
  }

  modalControl(e) {
    this.setState({ modalOpen: e });
  }

  render() {
    // console.log('BD', this.props);

    const { modalOpen } = this.state;
    return (
      <>
        <div className="accounts-settings">
          <div>
            <div className="accounts-page-headers-images">
            <FontAwesomeIcon
                icon={faCreditCard}
                className={'subhead-icon'}
                color="black"
                />
            </div>
            <div className="accounts-page-headers fort-light text-gray-900 fs-24px">
              Account Details
            </div>
            <Divider className="accounts-header-divider" />
          </div>
        </div>
        <div className="text-uppercase fs-2 fort-bold mb-1">
                Primary Bill-To Address
        </div>
        <PRHContainerGeneral className="content-block-border pb-0 mb-6 mt-4">
          <div className="d-flex flex-column mt-3 fs-13px">
            {this.props.cart.customerInfo.shipTos.map((bt, i) => (
              <>
              {bt.accountNo ===
                  this.props.cart.customerInfo.customer.accountNo && (
              <div key={i} className="mb-3">
                {
                bt.address.name1 && (
                  <div className="font-weight-700">
                    {bt.address.name1} - {bt.accountNo}
                  </div>
                )}
                {bt.address.name2 && <div>{bt.address.name2}</div>}
                <div>{bt.address.street}</div>
                {bt.address.name3 && <div>{bt.address.name3}</div>}
                <div>
                  {bt.address.city}
                  {bt.address.state ? `, ${bt.address.state}  ` : '  '}{' '}
                  {bt.address.postalCode} {bt.address.country}
                </div>
                  <div className="bd-primary-billing">Primary Billing</div>
              </div>

            )}
            </>
            ))}
          </div>
        </PRHContainerGeneral>
        <div className="d-flex justify-content-space-between">
        <div className="text-uppercase fs-2 fort-bold d-flex">
          Saved Credit Cards
        </div>
        <div className="d-flex">
        <PRHButton size="medium"bold icon="plus" onClick={() => this.modalControl(true)}>
        Add Credit Card
        </PRHButton>
        </div>
      </div>
        <PRHContainerGeneral className="content-block-border pb-0 mb-6 mt-4">

        </PRHContainerGeneral>
        <CreditCardModal
        isOpen={modalOpen}
        onClose={() => this.modalControl(false)}
        buttonStyles={{
          size: 'small',
          className: 'ml-auto',
          bold: false,
          label:'Add Card'
        }}
      />
      </>
    );
  }
}

export default withRouter(withSecurity(withCart(BillingDetails)));
