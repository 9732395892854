import { Component } from 'react';
import withApp from '../../components/hc/withApp';
import { withRouter } from 'react-router';
import PageStandard from '../../components/elements/PageStandard';
import { PRHContainer } from '../../shared-react-components/elements/elements';
import { PRHButton, PH1, ButtonHolder } from '../../components/elements/elements';


class Cancel extends Component {
  render() {
    return (
      <>
      <PageStandard jeff={true} alignment="center">
        <PRHContainer className="content-block-border">
            <PH1>Are you sure you want to cancel?</PH1>
            <span>You will not be able to access Biz 2 until your account details have been updated.</span>            
            <br/>
            <br/>      
            <ButtonHolder>
            <PRHButton onClick={(e) => this.props.history.push('/')}>Cancel</PRHButton>
            <PRHButton >Continue Updates</PRHButton>
            </ButtonHolder>
        </PRHContainer>
        </PageStandard>
      </>
    );
  }
}

export default withApp(withRouter(Cancel));
