import { Component } from 'react';
import localforage from 'localforage';
import { withRouter } from 'react-router';
//import axios from "axios";
import { SizeMe } from 'react-sizeme';
import withSecurity from '../../components/hc/withSecurity';
import withApp from '../../components/hc/withApp';
import withAccount from '../../components/hc/withAccount';
import PageStandard from '../../components/elements/PageStandard';
import RegistrationSubAccountForm1 from '../../components/registration/RegistrationSubAccountForm1';
import RegistrationCancel from '../../components/elements/RegistrationCancel';
import VerifySubAccountResponse from '../../components/registration/VerifySubAccountResponse';
import { PRHContainerGeneral } from '../../components/elements/elements';
import { CONFIG } from '../../utils/const';
import './registration.scss';
//import './login.scss';

class VerifySubAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (e) => {
    const newState = { ...this.state };
    newState.createUserForm[e.target.name] = e.target.value;
    this.setState(newState);
  }

  render() {
    const { account } = this.props;
    return (
      <>
      <div className={'registration'}>
      <SizeMe monitorHeight={true}>
              {({ size }) =>(
        <PageStandard jeff={true} alignment="center">
          {account.formState.step === 1 ? (
            <RegistrationSubAccountForm1
              nextStepHandler={this.nextStepHandler}
              setStepHandler={(e) => this.setStepHandler(e)}
              //handleChange={this.handleChange}
              formState={this.props.location.state}
              size={size}
            />
          ) : (
            ''
          )}

          {account.formState.step === 2 ? (
            <RegistrationCancel
              handleChange={this.handleChange}
              previousStepHandler={this.previousStepHandler}
              setStepHandler={(e) => this.setStepHandler(e)}
              formState={this.state}
            />
          ) : (
            ''
          )}

          {account.formState.step === 3 ? (
              <VerifySubAccountResponse
                formState={this.state}
              />
            ) : (
              ''
            )}
        </PageStandard>)}
        </SizeMe>
        </div>
      </>
    );
  }
}

export default withRouter(withApp(withAccount(VerifySubAccount)));
