import propTypes from "prop-types";
import { Accordion, Form, Icon, List, Popup, Menu } from "semantic-ui-react";
import { TOOLTIPS } from "../../../utils/const";
import { PRHActiveSelection } from "../../../components/elements/elements";
import { isArray } from "underscore";

const CustomFilter = (props) => {
  let optionsList = null;
  const {
    options,
    colName,
    updateResults,
    activeInd,
    index,
    name,
    handleClick,
  } = props;

  let activeFilterCheck = false;
  let totalCount = 0;
  for (let i = 0; i < options.length; i += 1) {
    if (options[i].checked === true) {
      activeFilterCheck = true;
      totalCount++;
    }
  }

  CustomFilter.propTypes = {
    options: propTypes.array.isRequired,
    colName: propTypes.string.isRequired,
    updateResults: propTypes.func.isRequired,
    activeInd: propTypes.number.isRequired,
    index: propTypes.number.isRequired,
    handleClick: propTypes.func.isRequired,
    name: propTypes.string.isRequired,
  };

  let accordianTitle = "";

  if (activeFilterCheck === true) {
    accordianTitle = (
      <span className='filterHeaderActive'>
        <PRHActiveSelection /> {name}
        {" ("}
        {totalCount}
        {")"}
      </span>
    );
  } else {
    accordianTitle = name;
  }

  const getFOCDisplayTitle = (displayDate) => {
    const dateArray = displayDate.split("-");
    if (isArray(dateArray) && dateArray.length === 3) {
      return dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
    }
  };
  let OptionsData = [];
  if (colName === "foc") {
    OptionsData = options.map((item) => {
      item["display"] === item["display"].includes("-")
        ? getFOCDisplayTitle(item["display"])
        : item["display"];
      return item;
    });
  } else {
    OptionsData = options;
  }

  optionsList = (
    <List.Item className='filter-item'>
      {OptionsData.map((filterOption) => (
        <div key={Math.random()} className='flex mb-2'>
          <Form.Checkbox
            label={
              <label htmlFor={filterOption.display}>
                {filterOption.display}
              </label>
            }
            name={colName}
            id={filterOption.display}
            value={filterOption.match}
            checked={filterOption.checked}
            onClick={updateResults}
            key={filterOption.match + 1}
            className='filter-checkbox inline-flex'
          />
          {filterOption.match === "newrelease" ||
          filterOption.match === "backlist" ||
          filterOption.match === "instock" ? (
            <Popup
              className='metachart-popup  mt-2 mb-1'
              position='top center'
              offset={[0, 5]}
              // className="help-tooltip"
              // on="click"
              trigger={
                <Icon
                  className='cursor-pointer inline-flex ml-2'
                  name='question circle outline'
                />
              }
              content={TOOLTIPS[filterOption.match]}
            ></Popup>
          ) : (
            <></>
          )}
        </div>
      ))}
    </List.Item>
  );

  return (
    // <List className="filter-type">
    //   <List.Item className="filter-name">{accordianTitle}</List.Item>
    //   {optionsList}
    // </List>
    <Menu.Item>
      <Accordion.Title
        active={activeInd === index}
        content={accordianTitle}
        index={index}
        onClick={handleClick}
      />
      <Accordion.Content active={activeInd === index} content={optionsList} />
    </Menu.Item>
  );
};

export default CustomFilter;
