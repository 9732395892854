import { Component } from "react";
import withCart from "../../../components/hc/withCart";
import { Icon, Form, Radio } from "semantic-ui-react";
import PRHButton from "../../../shared-react-components/elements/prhbutton";
import { Divider } from "../../../components/elements/elements";
import { PRHContainer } from "../../../shared-react-components/elements/elements";
import { set } from "lscache";

class BizShipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shipToAddress: props.shipToAddress,
    };
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // console.log('BizShipping', nextProps, this.props, nextState, this.state);
    if (nextProps.shipToAddress !== this.props.shipToAddress) {
      this.setState({ shipToAddress: nextProps.shipToAddress });
    }
  }

  componentWillUnmount() {
    // console.log('CWU');
    // this.props.setValue({
    //   shippingAddressEdit: false,
    // });
  }

  handleChangeAddress = (e, d) => {
    // console.log('handleChangeAddress', e, d.value);
    this.setState({ shipToAddress: d.value });
  };
  render() {
    // console.log('bizshipping render', this.props, this.state);
    // console.log('shipto', this.props.cart.customerInfo.shipToAccountNos);
    const { shippingAddressEdit } = this.props;
    const { shipToAddress } = this.state;

    return (
      <PRHContainer className='cp-left content-block-border w-100 m-0 pl-0 pr-0 mr-6 mb-3 pt-3'>
        <div className='d-flex ml-3 mr-3 align-items-center mb-1 co-step-header'>
          <div className='d-flex fort-bold mb-2 fs-2 align-items-center'>
            <span className='cp-section-number fs-3'>1</span>
            <span className='fs-2'>SHIPPING ADDRESS</span>
          </div>
          <div className='ml-auto mb-1 d-flex' style={{ marginTop: "-5px" }}>
            {this.props.cart.customerInfo.shipToAccountNos.length > 1 &&
            !this.props.cart.isUserPow() ? (
              <PRHButton
                // size="large"
                // textColorOverride
                icon={shippingAddressEdit ? "" : "pencil"}
                onClick={(e) => {
                  this.props.setValue(
                    "shippingAddressEdit",
                    !shippingAddressEdit
                  );
                  this.setState({ shipToAddress: this.props.shipToAddress });
                }}
              >
                {shippingAddressEdit ? "Cancel" : "Change Address"}
              </PRHButton>
            ) : (
              <div></div>
            )}

            {shippingAddressEdit ? (
              <PRHButton
                // invertHoverState
                bold
                // size="large"
                // textColorOverride="gray-900"
                // backgroundHoverColorOverride="orange"
                className='ml-1'
                borderColorOverride='blue'
                textColorOverride='white'
                textColorHoverOverride='blue'
                backgroundColorOverride='blue'
                backgroundHoverColorOverride='white'
                iconColorOverride='white'
                iconColorHoverOverride='blue'
                icon='check'
                onClick={(e) => {
                  // console.log("shiptoaddress", shipToAddress);
                  this.props.setShipTo(shipToAddress);
                  this.props.setValue(
                    {
                      shippingAddressEdit: false,
                      shipToAddress: shipToAddress,
                    },
                    true,
                    true
                  );
                }}
              >
                Confirm Address
              </PRHButton>
            ) : null}
          </div>
        </div>
        <Divider tight className='mb-0' />
        {shippingAddressEdit === true ? (
          <div className=''>
            <div className='cp-ship-container overflow-auto'>
              {/* <Form> */}
              {this.props.cart.customerInfo.shipToAccountNos.map(
                (shipTo, idx) => (
                  <div
                    key={idx}
                    className={`cp-ship-address d-flex`}
                    //   onClick={(e) => {
                    //     this.props.setValue('shippingAddressEdit', false);
                    //     this.props.setValue('shipToAddress', idx);
                    //   }}
                  >
                    <Form.Field>
                      <Radio
                        name='shippingaddress'
                        value={idx}
                        checked={shipToAddress === idx ? true : false}
                        onChange={this.handleChangeAddress}
                        // checked={this.state.value === 'that'}
                        // onChange={this.handleChange}
                      />
                    </Form.Field>

                    <div className='d-flex flex-column'>
                      <div>
                        {shipTo.address.name1} - {shipTo.accountNo}
                      </div>
                      {shipTo.address?.name2 && (
                        <div> {shipTo.address.name2}</div>
                      )}
                      {shipTo.address?.name3 && (
                        <div> {shipTo.address.name3}</div>
                      )}
                      <div>
                        {shipTo.address.street}, {shipTo.address.city}
                        {shipTo.address.state
                          ? `, ${shipTo.address.state}`
                          : ""}{" "}
                        {shipTo.address.postalCode} {shipTo.address.country}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className='mt-1 d-flex align-items-center justify-content-center'></div>
          </div>
        ) : (
          <div className='mt-3 lh-3  ml-3 mr-3'>
            {shipToAddress >= 0 &&
            this.props.cart.customerInfo.shipToAccountNos?.length > 0 ? (
              <div>
                <div className='fort-bold mb-1'>
                  {
                    this.props.cart.customerInfo.shipToAccountNos[shipToAddress]
                      .address.name1
                  }{" "}
                  -{" "}
                  {
                    this.props.cart.customerInfo.shipToAccountNos[shipToAddress]
                      .accountNo
                  }
                </div>
                <div className='fort-bold mb-1'>
                  {
                    this.props.cart.customerInfo.shipToAccountNos[shipToAddress]
                      .address.name2
                  }
                </div>
                <div className='fort-bold mb-1'>
                  {
                    this.props.cart.customerInfo.shipToAccountNos[shipToAddress]
                      .address.name3
                  }
                </div>
                <div className='mb-1'>
                  {
                    this.props.cart.customerInfo.shipToAccountNos[shipToAddress]
                      .address.street
                  }
                </div>
                <div className='mb-1'>
                  {
                    this.props.cart.customerInfo.shipToAccountNos[shipToAddress]
                      .address.city
                  }
                  {this.props.cart.customerInfo.shipToAccountNos[shipToAddress]
                    .address.state
                    ? `, ${this.props.cart.customerInfo.shipToAccountNos[shipToAddress].address.state}`
                    : ""}
                  {
                    this.props.cart.customerInfo.shipToAccountNos[shipToAddress]
                      .address.postalCode
                  }{" "}
                  {
                    this.props.cart.customerInfo.shipToAccountNos[shipToAddress]
                      .address.country
                  }
                </div>
              </div>
            ) : (
              <div>There are no available ship to locations</div>
            )}
          </div>
        )}
      </PRHContainer>
    );
  }
}

export default withCart(BizShipping);
