import { useState, useEffect } from "react";
import { Table, Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ManageUserForm from "./ManageUserForm";
import withSecurity from "../hc/withSecurity";
import withApp from "../hc/withApp";
import withCart from "../hc/withCart";
import withMobile from "../hc/withMobile";
import CreditCardLarge from "../../assets/images/icon-card-large.svg";
import CreditCardSmall from "../../assets/images/icon-card-small.svg";
import { withRouter } from "react-router";
import { useRemoveCreditCardMutation } from "../../api/ecomApi";
import UserList from "./UserList";
import {
  Biz2Modal,
  PRHContainerGeneral,
  PRHContainerHeader,
} from "../elements/elements";
import CreditCardModal from "../modals/CreditCardModal";
import { dateIsBefore } from "../../utils/utilities";
import { CreditCardRemoveModal } from "../modals/CreditCardRemoveModal";
import { Divider } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import NoticeCard from "../elements/NoticeCard";
import NoAccessMessage from "../../components/common/NoAccessMessage";
import useCart from "../../components/hc/useCart";
import "./admin.scss";
import _ from "underscore";

const SavedCreditCards = (props) => {
  const [state, setState] = useState({
    sessionTimedOut: false,
    modalOpen: false,
    ccRemoveModalOpen: false,
    cards: [],
    cardId: null,
    cardGroup: null,
  });
  const cart = useCart();
  const [performRemoveCreditCard, removeCreditCardsResult] =
    useRemoveCreditCardMutation();

  const modalControl = (e) => {
    // console.log('modalControl');
    //cc
    setState({ ...state, modalOpen: e });
  };

  const getCreditCards = async () => {
    const result = await props.cart.getCreditCards();
    // console.log('Promos', result);
    if (result.data.status === "OK") {
      setState({
        ...state,
        cards: _.sortBy(result.data.data.creditCards, "cardExpiryDate"),
        ccRemoveModalOpen: false,
      });
    }
  };

  const ccRemoveModalControl = (
    ccRemoveModalState,
    cardGroup,
    lastFour,
    expiryMonth,
    expiryYear,
    cardHolderName
  ) => {
    setState({
      ...state,
      ccRemoveModalOpen: ccRemoveModalState,
      /*cardGroup: cardGroup, 
      lastFour: lastFour, 
      expiryMonth:expiryMonth, 
      expiryMonth:expiryMonth,
      cardHolderName:cardHolderName,*/
      cardGroup,
      lastFour,
      expiryMonth,
      expiryYear,
      cardHolderName,
    });
  };

  function removeCard() {
    performRemoveCreditCard({ cardGroup }).then((result) => {
      if (result.data?.status === "OK") {
        getCreditCards();
      }
    });
  }

  function reloadCards(ccAdded) {
    // console.log('reload cards');
    getCreditCards();
    /*
    props.app.ecomApi.getCreditCards().then((r) => {
      if (r?.status === 200) {
        setState({ ...state, cards: r.data.data.creditCards });
      }
    });*/
  }

  useEffect(() => {
    getCreditCards();
    /*
    props.app.ecomApi.getCreditCards().then((r) => {
      if (r?.status === 200) {
        setState({ ...state, cards: r.data.data.creditCards });
      }
    });*/
  }, []);

  const {
    billTos,
    modalOpen,
    ccRemoveModalOpen,
    cards,
    cardId,
    cardGroup,
    lastFour,
    expiryMonth,
    expiryYear,
    cardHolderName,
  } = state;
  const isMobile = props.mobile.isMobile;
  {
    if (cart?.customerInfo?.currentUser?.roleNo === "W1")
      return <NoAccessMessage />;
    else
      return (
        <>
          <div className="accounts-settings">
            <div>
              <div className="accounts-page-headers-images">
                <img src={CreditCardLarge} alt="Saved Credit Card Icon" />
              </div>
              <div className="accounts-page-headers fort-light text-gray-900 fs-24px">
                Saved Credit Cards
              </div>
              <div className="accounts-page-headers-button-holder hidden-on-mobile">
                <CreditCardModal
                  buttonStyles={{
                    size: "medium",
                    className: "ml-auto",
                    bold: false,
                    label: "Add New Card",
                  }}
                  isOpen={modalOpen}
                  onClose={() => modalControl(false)}
                  reloadCards={() => {
                    reloadCards();
                  }}
                />
              </div>

              {!isMobile && <Divider className="accounts-header-divider" />}
              {/*<div className="flex justify-content-left">
            <div className="fort-bold fs-13px">Saved Credit Cards!</div>
            <CreditCardModal
              buttonStyles={{
                size: 'small',
                className: 'ml-auto',
                bold: false,
              }}
              isOpen={modalOpen}
              onClose={() => modalControl(false)}
              reloadCards={() => {
                reloadCards();
              }}
            />
            </div>*/}
            </div>
          </div>
          {isMobile && (
            <NoticeCard>
              Card management is accessible through desktop only.
            </NoticeCard>
          )}
          <PRHContainerGeneral
            className={`content-block-border mb-6 mt-2 ${
              isMobile ? "pl-3 pt-3 pr-3 pb-1" : "pb-0"
            }`}
          >
            {cards.length > 0 &&
              cards?.map((card, i) => (
                <div key={i}>
                  <div
                    className={`d-flex flex-row cc-vertical-spacing ${
                      i != 0 ? "mt-3" : ""
                    }`}
                  >
                    <div>
                      <img
                        src={CreditCardSmall}
                        className="small-credit-card-icon"
                        alt="Saved Credit Card Icon"
                      />
                    </div>
                    <div className="ml-2">
                      <div className="fs-2 fort-bold">
                        Card ending in {card.cardLastFour}
                      </div>
                      <div>
                        <div>
                          {card.cardholderName}
                          <br />

                          {dateIsBefore(card.cardExpiryDate) ? (
                            //dateIsBefore('2022-03-31') ? (
                            <>
                              <Icon
                                name="exclamation triangle"
                                color="red"
                              ></Icon>{" "}
                              <span className={"fort-bold expired-card"}>
                                Expired: {card.cardExpiryMonth}/
                                {card.cardExpiryYear}
                              </span>
                            </>
                          ) : (
                            `Expires: ${card.cardExpiryMonth}/${card.cardExpiryYear}`
                          )}
                        </div>
                      </div>
                    </div>
                    {!isMobile && (
                      <div className={"ml-auto"}>
                        <div className="accounts-settings d-flex flex-wrap justify-content-right">
                          <div className="saved-credit-card-remove-ui-holder d-flex justify-content-right">
                            <PRHButton
                              // iconleft
                              size="small"
                              fontAwesome={true}
                              disabled={card?.hasOrders}
                              className={"ml-auto "}
                              //className="extra-space fifty-percent mb-1"
                              // className={'float-right margin-left'}
                              onClick={() =>
                                ccRemoveModalControl(
                                  true,
                                  card.cardGroup,
                                  card.cardLastFour,
                                  card.cardExpiryMonth,
                                  card.cardExpiryYear,
                                  card.cardholderName
                                )
                              }
                            >
                              Remove
                            </PRHButton>
                            {!isMobile && card?.hasOrders && (
                              <div
                                className={
                                  "saved-credit-card-remove-txt-holder"
                                }
                              >
                                Cards currently used as payment method for
                                unprocessed FOC carts or affidavit returns
                                cannot be removed.
                                <br />
                                <div>
                                  <a
                                    href="https://selfservice-help.penguinrandomhouse.biz/account-info/"
                                    target="_blank"
                                    className={"saved-card-link"}
                                  >
                                    How can I delete this card?
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {i < cards.length - 1 ? <Divider tight /> : ""}
                </div>
              ))}
            {cards.length == 0 ? (
              <div className="ml-1 mt-1 mb-4 fort-light-italic">
                You have no saved cards in your account.
              </div>
            ) : (
              ""
            )}
          </PRHContainerGeneral>
          <CreditCardRemoveModal
            isOpen={ccRemoveModalOpen}
            reloadCards={() => {
              reloadCards(ccAdded);
            }}
            removeCard={() => {
              removeCard();
            }}
            onClose={() => {
              ccRemoveModalControl(false);
            }}
            ccRemoveModalControl={() => {
              ccRemoveModalControl(false);
            }}
            card={{ lastFour, expiryMonth, expiryYear, cardHolderName }}
          />
        </>
      );
  }
};

export default withRouter(
  withSecurity(withApp(withCart(withMobile(SavedCreditCards))))
);
