export const errors = {
  1000: 'User API Errors',
  1001: 'Session Validation Failure',
  1002: 'User call failure',
  1003: 'Search lookup failure',
  1004: 'Set preferences failure',
  2000: 'Search API Errors',
  2001: 'History lookup failure',
  2002: 'Favorites Error',
  2003: 'View Toggle Error',
  2004: 'Column Change Error',
  3000: 'List Errors',
  3001: 'List of List api call failure',
  3002: 'List Detail failure',
  3003: 'List duplication failure',
  3004: 'List delete failure',
  3005: 'List update failure',
  3006: 'List public/private failure',
  3007: 'List rename failure',
  4000: 'Title Detail Errors',
  4001: 'Failed to retreive title detail',
  5000: 'Unknown Error Occured',
};
