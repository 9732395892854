import { useState } from "react";
import PropTypes from "prop-types";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Dropdown } from "semantic-ui-react";
import { PRHDropdown } from "../../shared-react-components/elements/elements";
import { Link } from "react-router-dom";
import { isIE, osName, isFirefox, browserName } from "react-device-detect";

function ActionMenu(props) {
  const { actions, backgroundColor = "white", borderColor = "white" } = props;

  const [amOpen, setAmOpen] = useState(false);

  return (
    <Dropdown
      trigger={
        <PRHButton
          icon='ellipsis vertical'
          iconColorOverride='gray'
          // backgroundHoverColorOverride="orange"
          iconColorHoverOverride='orange'
          textColorHoverOverride='orange'
          borderColorHoverOverride='orange'
          borderHoverColorOverride='orange'
          // borderColorOverride="gray-100"
          backgroundColorOverride={backgroundColor}
          // iconOnlyBorderAlways={true}
          borderColorOverride={borderColor}
          className={`ml-2 cop-menu-icon ${
            amOpen ? " active" : ""
          } ${osName} ${browserName}`}
        ></PRHButton>
      }
      compact={true}
      icon={false}
      options={actions}
      className='am-dropdown'
      direction='left'
      onOpen={(e) => setAmOpen(true)}
      onClose={(e) => setAmOpen(false)}
      // scrolling
    />
  );
}

export default ActionMenu;

ActionMenu.propTypes = {
  actions: PropTypes.array.isRequired,
};
