import React from "react";
import { Grid, Container, Icon } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import { IDENTITY_CONFIG } from "../../utils/authConst";
import "./pageFooter.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import useCart from "../hc/useCart";
import cn from "classnames";
import EventTrigger from "../../components/analytics/EventTrigger";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";

const PageFooter = ({ loggedIn, which }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });

  const cart = useCart();
  const isUserPow = cart.isUserPow();

  const siteNameForFooter = (
    <>
      Penguin Random House {which === "biz" && <>Self-Service</>}{" "}
      {which !== "biz" && <>Comp Copy</>}
    </>
  );

  const anylyticsTrigger = () => {
    EventTrigger("prhcomicsSource", "biz footer");
    window.open(IDENTITY_CONFIG.prhcomics_sales, "_newtab");
  };
  // let infoOptions = [];
  // const infoOsName = {
  //   key: 'infoOSName',
  //   text: `OS: ${osName}`,
  //   className: 'dropdown-item paragraph-1 app-section',
  //   value: 'infoOSName',
  //   content: (
  //     <Header
  //       className="app-info"
  //       content={
  //         <div className="text app-version signout">{`OS: ${osName}`}</div>
  //       }
  //     />
  //   ),
  // };
  // accountArray.push(infoOsName);

  // const infoBrowserName = {
  //   key: 'infoBrowserName',
  //   text: `Browser: ${browserName}`,
  //   className: 'dropdown-item paragraph-2 app-section',
  //   value: 'infoBrowserName',
  //   content: (
  //     <Header
  //       className="app-info"
  //       content={
  //         <div className="text app-version">{`Browser: ${browserName}`}</div>
  //       }
  //     />
  //   ),
  // };

  // accountArray.push(infoBrowserName);

  // const infoBrowserVersion = {
  //   key: 'infoBrowserVersion',
  //   text: `Version: ${fullBrowserVersion}`,
  //   className: 'dropdown-item paragraph-2 app-section',
  //   value: 'infoBrowserVersion',
  //   content: (
  //     <Header
  //       className="app-info"
  //       content={
  //         <div className="text app-version">
  //           {`Version: ${fullBrowserVersion}`}
  //         </div>
  //       }
  //     />
  //   ),
  // };
  // accountArray.push(infoBrowserVersion);

  // const appVersion = {
  //   key: 'appVersion',
  //   text: `Version: ${process.env.REACT_APP_VERSION}`,
  //   className: 'dropdown-item paragraph-2 app-section',
  //   value: 'appVersion',
  //   content: (
  //     <Header
  //       className="app-info"
  //       content={
  //         <div className="text app-version">
  //           {`UI Version: ${process.env.REACT_APP_VERSION}`}
  //         </div>
  //       }
  //     />
  //   ),
  // };
  // accountArray.push(appVersion);
  return (
    <>
      {/* {console.log("cart", cart)} */}
      <Container fluid className='bg-subfooter py-3 hide-in-print'>
        <Container className={`p-4 ${isMobile ? "bg-gray-700" : ""}`}>
          <Grid relaxed>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={4}
                largeScreen={4}
                widescreen={4}
                className='keep-padding text-white bg-transparent'
              >
                <div
                  className={cn(
                    `pb-1 ${
                      !isMobile ? "border-bottom-gray-500 mb-3" : "mb-0"
                    } `
                  )}
                >
                  <p className='lh-18 fs-12px fort-bold text-uppercase'>
                    Contact Support{!isMobile && <span>:</span>}
                  </p>
                </div>
                <div>
                  <p className='lsp--02 fs-13px lh-18 mb-3 text-gray-300'>
                    Contact our Customer Service representatives for inquiries
                    regarding product availability, ordering information, 
                    status of shipment, billing, defective merchandise, or
                    returns.
                  </p>
                </div>
                {which === "biz" && (
                  <div className='mb-2 d-flex align-items-start'>
                    <Icon
                      name='phone'
                      flipped='horizontally'
                      className='text-gray-500 mr-2 lh-18 fs-13px'
                    />
                    <div>
                      <p className='fort-bold lsp--02 fs-13px lh-15 my-0'>
                        {!!isUserPow ? `1-877-888-2918` : `1-800-733-3000`}
                      </p>
                      <p className='fort-book lsp--02 fs-13px lh-18 my-0'>
                        {!!isUserPow
                          ? `Mon- Fri 8:30am - 5:00pm (EST)`
                          : `Mon- Fri 8:30am - 5:30pm (EST)`}
                      </p>
                    </div>
                  </div>
                )}
                <div className='mb-2 d-flex align-items-start'>
                  <Icon
                    name='envelope'
                    className='text-gray-500 mr-2 lh-18 fs-13px'
                  />
                  <div>
                    <p className='fort-bold lsp--02 fs-13px lh-15 my-0'>
                      Customer Service:
                    </p>
                    {which !== "biz" && (
                      <p className='fort-book lsp--02 fs-13px lh-18 my-0'>
                        Mon- Fri 8:30am - 5:00pm (EST)
                      </p>
                    )}
                    {which !== "biz" && (
                      <p className='fort-book lsp--02 fs-13px lh-18 my-0'>
                        <a
                          className='text-white hover-underline text-hover-white'
                          href='mailto:csreviews@penguinrandomhouse.com'
                        >
                          <span className='fort-bold'>US:</span>&nbsp;&nbsp;
                          csreviews@prh.com
                        </a>
                        <br />
                        <a
                          className='text-white hover-underline text-hover-white'
                          href='mailto:prhpsreviews@penguinrandomhouse.com'
                        >
                          <span className='fort-bold'>Clients:</span>
                          &nbsp;&nbsp; prhpsreviews@prh.com
                        </a>
                      </p>
                    )}
                    {which === "biz" && (
                      <p className='fort-book lsp--02 fs-13px lh-18 my-0'>
                        <a
                          className='text-white hover-underline text-hover-white'
                          href={`${
                            !!isUserPow
                              ? "mailto:cscomicmarket@penguinrandomhouse.com"
                              : "mailto:customerservice@penguinrandomhouse.com"
                          }`}
                        >
                          {!!isUserPow
                            ? `CSComicMarket@prh.com`
                            : `customerservice@prh.com`}
                        </a>
                      </p>
                    )}
                  </div>
                </div>

                <div className='mb-2 d-flex align-items-start'>
                  <Icon
                    name='envelope'
                    className='text-gray-500 mr-2 lh-18 fs-13px'
                  />
                  <div>
                    <p className='fort-bold lsp--02 fs-13px lh-15 my-0'>
                      BIZ Site Support:
                    </p>

                    <p className='fort-book lsp--02 fs-13px lh-18 my-0'>
                      <a
                        className='text-white hover-underline text-hover-white'
                        href='mailto:bizcs@penguinrandomhouse.com'
                      >
                        bizcs@prh.com
                      </a>
                    </p>
                  </div>
                </div>

                <div className='mb-3 d-flex align-items-start'>
                  <Icon
                    name='envelope'
                    className='text-gray-500 mr-2 lh-18 fs-13px'
                  />
                  <div>
                    <p className='fort-bold lsp--02 fs-13px lh-15 my-0'>
                      New Account Setup:
                    </p>
                    <p className='fort-book lsp--02 fs-13px lh-18 my-0'>
                      <a
                        className='text-white hover-underline text-hover-white'
                        href='mailto:newaccount@penguinrandomhouse.com'
                      >
                        newaccount@prh.com
                      </a>
                    </p>
                  </div>
                </div>
                {/* Message for .biz only */}
                {which === "biz" && (
                  <>
                    <div>
                      <p className='lsp--02 fs-13px lh-18 mb-2 text-gray-300'>
                        Contact our Credit Department for inquiries regarding
                        your account statement or credit status.
                      </p>
                    </div>
                    <div className='mb-2 d-flex align-items-start'>
                      <Icon
                        name='envelope'
                        className='text-gray-500 mr-2 lh-18 fs-13px'
                      />
                      <div>
                        <p className='fort-bold lsp--02 fs-13px lh-15 my-0'>
                          Credit Department:
                        </p>
                        <p className='fort-book lsp--02 fs-13px lh-18 my-0'>
                          <a
                            className='text-white hover-underline text-hover-white'
                            href='mailto:prhcredit@prh.com '
                          >
                            prhcredit@prh.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </>
                )}

                {which !== "biz" && (
                  <div className='mb-2 d-flex align-items-start'>
                    <Icon
                      name='question circle'
                      className=' text-gray-500 mr-2 lh-18 fs-13px'
                    />
                    <div>
                      <p className='fort-bold lsp--02 fs-13px lh-15 my-0'>
                        <a
                          className='text-white hover-underline text-hover-white'
                          href='https://compcopy-help.penguinrandomhouse.com/'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          F.A.Q.
                        </a>
                      </p>
                    </div>
                  </div>
                )}
              </Grid.Column>
              {!!loggedIn && which === "biz" && (
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={4}
                  largeScreen={4}
                  widescreen={4}
                  className='d-none d-lg-block keep-padding text-white bg-transparent'
                >
                  <>
                    <div className='mb-5 mt-5 mt-lg-0'>
                      <div className='pb-1 border-bottom-gray-500 mb-3'>
                        <p className='lh-18 fs-12px fort-bold text-uppercase'>
                          Orders
                        </p>
                      </div>
                      <div>
                        <ul className='gray-500-bullet pl-2'>
                          <li className='mb-1'>
                            <Link
                              to='/orders'
                              className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02'
                            >
                              Orders
                            </Link>
                          </li>
                          <li className='mb-1'>
                            <Link
                              to='/invoices'
                              className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02'
                            >
                              Invoices &amp; Claims
                            </Link>
                          </li>
                          <li className='mb-1'>
                            <Link
                              to='/backorders'
                              className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02'
                            >
                              Backorders
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className='mb-5'>
                      <div className='pb-1 border-bottom-gray-500 mb-3'>
                        <p className='lh-18 fs-12px fort-bold text-uppercase'>
                          My Account
                        </p>
                      </div>
                      <div>
                        <ul className='gray-500-bullet pl-2'>
                          <li className='mb-1'>
                            <Link
                              to='/admin/account-details'
                              className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02'
                            >
                              Billing and Detail
                            </Link>
                          </li>
                          <li className='mb-1'>
                            <Link
                              to='/admin/account-reps'
                              className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02'
                            >
                              Account Reps
                            </Link>
                          </li>
                          <li className='mb-1'>
                            <Link
                              to='/admin/account-users'
                              className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02'
                            >
                              Manage Users
                            </Link>
                          </li>
                          <li className='mb-1'>
                            <Link
                              to='/admin/change-password'
                              className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02'
                            >
                              Change Password
                            </Link>
                          </li>
                          {/* {console.log('role number', cart.customerInfo?.currentUser?.roleNo)} */}
                          {cart.customerInfo?.currentUser?.roleNo !== "W1" ? (
                            <li className='mb-1'>
                              <Link
                                to='/admin/promotions'
                                className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02'
                              >
                                Promotions
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                  </>

                  <div className='mb-5'>
                    <div className='pb-1 border-bottom-gray-500 mb-3'>
                      <p className='lh-18 fs-12px fort-bold text-uppercase'>
                        Help &amp; Resources
                      </p>
                    </div>
                    <div>
                      <ul className='gray-500-bullet pl-2'>
                        <li className='mb-1'>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={IDENTITY_CONFIG.help}
                            className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02'
                          >
                            Support Center
                          </a>
                        </li>

                        <li className='mb-1'>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={`${IDENTITY_CONFIG.help}/frequently-asked-questions`}
                            className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02'
                          >
                            F.A.Q.
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Grid.Column>
              )}

              {which === "biz" && (
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  largeScreen={8}
                  widescreen={8}
                  className='keep-padding text-white bg-transparent hidden-on-mobile'
                >
                  <div className='mb-5'>
                    <div className='pb-1 border-bottom-gray-500 mb-3'>
                      <p className='lh-18 fs-12px fort-bold text-uppercase'>
                        Browse &amp; Discover Books
                      </p>
                    </div>
                    <div>
                      <ul className='gray-500-bullet pl-2'>
                        <li className='mb-3'>
                          <a
                            // target='_blank'
                            rel='noopener noreferrer'
                            href='#'
                            onClick={() => anylyticsTrigger()}
                            className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02 d-inline-block'
                          >
                            Penguin Random House Comics Retail
                          </a>
                          <div className='ml-2 fort-book text-gray-300'>
                            Our website for comics, graphic novels, manga, and
                            more, designed specifically for US Direct Market
                            comic shop retailers and consumers.
                          </div>
                        </li>
                        <li className='mb-3'>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={IDENTITY_CONFIG.speciality_retail_sales}
                            className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02 d-inline-block'
                          >
                            Penguin Random House Specialty Retail
                          </a>
                          <div className='ml-2 fort-book text-gray-300'>
                            Our Specialty Sales online catalog for gift stores,
                            distributors, wholesalers, and other specialty
                            retailers
                          </div>
                        </li>
                        <li className='mb-3'>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={IDENTITY_CONFIG.international_retail}
                            className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02 d-inline-block'
                          >
                            Penguin Random House International Sales USA
                          </a>
                          <div className='ml-2 fort-book text-gray-300'>
                            Our US Sales online catalog and resource for
                            International booksellers
                          </div>
                        </li>
                        <li className='mb-3'>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://penguinrandomhouseeducation.com/'
                            className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02 d-inline-block'
                          >
                            Penguin Random House Education
                          </a>
                          <div className='ml-2 fort-book text-gray-300'>
                            Our Education Sales portal, linking to the
                            Elementary, Secondary, Higher Ed, and Common Reads
                            websites.
                          </div>
                        </li>
                        <li className='mb-3'>
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href='https://www.penguinrandomhouse.com/'
                            className='text-white hover-underline text-hover-white fort-medium fs-13px lh-18 lsp--02 d-inline-block'
                          >
                            Penguin Random House
                          </a>
                          <div className='ml-2 fort-book text-gray-300'>
                            Our main consumer website
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Container>
      </Container>
      <Container
        fluid
        className='d-none d-lg-block bg-subfooter pt-1 pb-4 text-center hide-in-print'
      >
        <Container>
          <span className='d-inline-block fs-12px lh-15 lsp--02 fort-bold text-white text-uppercase pr-2 border-right-gray-500'>
            {siteNameForFooter}
          </span>
          <span className='d-inline-block fs-13px lh-15 lsp--02 fort-medium text-white px-2 border-right-gray-500'>
            <a
              href='https://www.penguinrandomhouse.com/privacy/'
              target='_blank'
              rel='noopener noreferrer'
              className='text-white hover-underline text-hover-white'
            >
              Privacy Policy
            </a>
          </span>
          <span className='d-inline-block fs-13px lh-15 lsp--02 fort-medium text-white px-2 border-right-gray-500'>
            <a
              href='https://www.penguinrandomhouse.com/terms/'
              target='_blank'
              rel='noopener noreferrer'
              className='text-white hover-underline text-hover-white'
            >
              Terms of use
            </a>
          </span>
          <span className='d-inline-block fs-13px lh-15 lsp--02 fort-medium text-white px-2 border-right-gray-500'>
            {process.env.REACT_APP_VERSION}
          </span>
          <span className='d-inline-block fs-13px lh-15 lsp--02 fort-book text-gray-300 pl-sm-2'>
            &copy; {new Date().getFullYear()} Penguin Random House{" "}
          </span>
        </Container>
      </Container>
      <Container
        fluid
        className='d-lg-none bg-subfooter pt-1 pb-4 text-center hide-in-print'
      >
        <span
          className={`mb-3 mb-sm-0 d-block d-sm-inline-block fs-12px lh-15 lsp--02 fort-bold text-white pr-sm-2 text-uppercase ${
            isMobile ? "" : "border-right-gray-500"
          }`}
        >
          {siteNameForFooter}
        </span>
        <span className='d-inline-block fs-13px lh-15 lsp--02 fort-medium text-white px-2 border-right-gray-500'>
          <a
            href='https://www.penguinrandomhouse.com/privacy/'
            target='_blank'
            rel='noopener noreferrer'
            className='text-white hover-underline text-hover-white'
          >
            Privacy Policy
          </a>
        </span>
        <span className='d-inline-block fs-13px lh-15 lsp--02 fort-medium text-white px-2 border-right-sm-gray-500 border-right-gray-500'>
          <a
            href='https://www.penguinrandomhouse.com/terms/'
            target='_blank'
            rel='noopener noreferrer'
            className='text-white hover-underline text-hover-white'
          >
            Terms Of Use
          </a>
        </span>
        <span className='d-inline-block fs-13px lh-15 lsp--02 fort-medium text-white px-2 border-right-sm-gray-500'>
          {process.env.REACT_APP_VERSION}
        </span>
        <span className='mt-3 mt-sm-0 d-block d-sm-inline-block fs-13px lh-15 lsp--02 fort-book text-gray-300 pl-sm-2'>
          &copy; {new Date().getFullYear()} Penguin Random House{" "}
        </span>
      </Container>
    </>
  );
};

PageFooter.propTypes = {
  which: PropTypes.string,
};

PageFooter.defaultProps = {
  loggedIn: false,
};

export default PageFooter;
