import { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import Tabs from '../../../components/common/Tabs';
import {
  NEWS_FROM_PRH_POST_TYPE,
  POST_POST_TYPE,
  TITLE_CHANGES_POST_TYPE,
} from '../../../utils/wordpressHelpers';
import WordPressSearch from './WordPressSearch';

/**
 * allowActiveToBeClicked - pass through the ability to click a tab, this should happen if we are on an individual post
 * postType - the post type in wordpress
 * className - class name
 * @param {*} param0
 * @returns
 */
const WordPressContentHeader = ({
  allowActiveToBeClicked,
  postType,
  className,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const links = {
    [NEWS_FROM_PRH_POST_TYPE]: {
      url: `/${NEWS_FROM_PRH_POST_TYPE}`,
      text: 'News from PRH',
    },
    [TITLE_CHANGES_POST_TYPE]: {
      url: `/${TITLE_CHANGES_POST_TYPE}`,
      text: 'Title Changes',
    },

    [POST_POST_TYPE]: {
      url: `/${POST_POST_TYPE}`,
      text: 'Title Collections',
    },
  };
  const active = postType;

  return (
    <div className={`${className}`}>
      <Grid container>
        <Grid.Row>
          <Grid.Column
            mobile={16}
            tablet={10}
            largeScreen={10}
            computer={10}
            widescreen={10}
          >
            <Tabs
              links={links}
              active={active}
              allowActiveToBeClicked={!!allowActiveToBeClicked}
            />
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={6}
            largeScreen={6}
            computer={6}
            widescreen={6}
            className="d-flex align-items-center justify-content-end"
          >
            <WordPressSearch
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              postType={postType}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

WordPressContentHeader.defaultProps = {
  postType: NEWS_FROM_PRH_POST_TYPE,
  className: null,
  allowActiveToBeClicked: false,
};

export default WordPressContentHeader;
