import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import {
  Link,
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter,
} from "react-router-dom";
import withDirty from "../../shared-react-components/components/hc/withDirty";
import { Dropdown, Grid, Icon, Image, Loader } from "semantic-ui-react";
import CartSummary from "./components/CartSummary";
import { Divider } from "../../components/elements/elements";
import PageStandard from "../../components/elements/PageStandard";
import AddISBNModal from "../../components/modals/AddISBNModal";
import DialogModal from "../../components/modals/DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Export } from "../../utils/Export";
import LazyImage from "../../utils/LazyImage";
import queryString from "query-string";
import {
  focDateDifference,
  formatDate,
  formatDateMMDDYYYY,
  isFocCartExpired,
  isGeneralCart,
  expiredFocOffset,
  getDefaultPO,
} from "../../utils/utilities";
import "./CartPage.scss";
import _ from "underscore";
import ActionMenu from "../../components/common/ActionMenu";
import DownloadDropdown from "../../components/common/DownloadDropdown";
import TitleRow from "../../components/common/TitleRow";
import ExpiredFocBanner from "./components/ExpiredFocBanner";
import { AddUPCISBNModal } from "../../components/modals/AddUPCISBNModal";
import UndoBlockedDialogModal from "../../components/modals/UndoBlockedModal";
import NoticeCard from "../../components/elements/NoticeCard";
import TitleRowMobile from "../../components/common/TitleRowMobile";
import withMobile from "../../components/hc/withMobile";
import withSecurity from "../../components/hc/withSecurity";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import { BackToTopButton } from "../../components/common/BackToTopButton";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";
// import RemoveConfirmedCartModal from '../../components/modals/RemoveConfirmedCartModal';
import RemoveCartModal from "../../components/modals/RemoveCartModal";
import VariantRestrictionsOrderDeleteModal from "../../components/modals/VariantRestrictionsOrderDeleteModal";
import VariantRestrictionsCartDeleteModal from "../../components/modals/VariantRestrictionsCartDeleteModal";
import BridgeAddRetailerTitlesModal from "../../components/modals/BridgeAddRetailerTitlesModal";
import VariantRestrictionsCartsDeleteModal from "../../components/modals/VariantRestrictionsCartsDeleteModal";
import useCart from "../../components/hc/useCart";
import useDirtyHandler from "../../shared-react-components/components/hc/useDirtyHandler";
import useSecurity from "../../components/hc/useSecurity";
import useMobile from "../../components/hc/useMobile";
import TitleRowCache from "../../components/common/TitleRowCache";

const CartPage = (props) => {
  const cart = useCart();
  const location = useLocation();
  const match = useRouteMatch();
  const dirtyHandler = useDirtyHandler();
  const history = useHistory();
  const user = useSecurity();
  const mobile = useMobile();

  const [cartType, setCartType] = useState(0);
  const [exportOptions, setExportOptions] = useState([]);
  const [cartConfirmedDialogOpen, setCartConfirmedDialogOpen] = useState(false);
  const [cartConfirmedDialogUpdate, setCartConfirmedDialogUpdate] =
    useState(false);
  const [undoBlockedDialogModal, setUndoBlockedDialogModal] = useState(false);
  const [undoUnsatisfiedTitles, setUndoUnsatisfiedTitles] = useState([]);
  const [shipTo, setShipTo] = useState(false);
  const [focDate, setFocDate] = useState(false);
  const [cartLoaded, setCartLoaded] = useState(false);
  const [currentSort, setCurrentSort] = useState("itemId");
  const [selectedItems, setSelectedItems] = useState({});
  const [modifyCartModalOpen, setModifyCartModalOpen] = useState(false);
  const [clearCartAction, setClearCartAction] = useState(false);
  const [variantOrderDeleteModalState, setVariantOrderDeleteModalState] =
    useState(false);
  const [variantOrderTitles, setVariantOrderTitles] = useState({
    mainVariantTitles: [],
    totalNumberOfItems: 0,
    totalNumberOfQuantities: 0,
  });
  const [mainVariantTitles, setMainVariantTitles] = useState([]);
  const [removeItemSelected, setRemoveItemSelected] = useState(false);
  const [variantCartDeleteModalState, setVariantCartDeleteModalState] =
    useState(false);
  const [variantCartsDeleteModal, setVariantCartsDeleteModal] = useState(false);
  const [listID, setListID] = useState(false);
  const [listName, setListName] = useState(false);
  const [confirmDeletedCart, setConfirmDeletedCart] = useState(false);

  useEffect(() => {
    // console.log('CartPage CDM', this.props);

    let listLoad = false;
    const value = queryString.parse(location.search);
    // console.log('queryString', value);
    if (Object.prototype.hasOwnProperty.call(value, "listID")) {
      let listName = Object.prototype.hasOwnProperty.call(value, "listName")
        ? value.listName
        : "Specialty Retailer"; //'Penguin Random House International'//'Penguin Randomhouse Retail'////'Penguin Randomhouse International Sales'//'Specialty Retailer';
      setListID(value.listID);
      setListName(listName);
      listLoad = true;
    }

    window.scrollTo(0, 0);
    const { cartType } = match.params;
    let wFocDate = location?.state?.focDate;
    let wShipTo = location?.state?.shipTo;

    if (cart.isUserPow() && match.params?.shipTo && match.params?.focDate) {
      wFocDate = match.params?.focDate;
      wShipTo = match.params?.shipTo;
    }

    if (cart.isUserPow() && cart.shipTo < 0 && !match.params?.shipTo) {
      history.push({ pathname: "/cart-overview" });
      return;
    }
    if (user?.isAppCompCopy() && !cart.ccSapAccountNo) {
      history.push({ pathname: "/" });
    }

    if (wShipTo && wFocDate) {
      setShipTo(shipTo);
      setFocDate(focDate);
    }

    let newCartType = 0;
    if (cartType) {
      newCartType = 1;
    }

    let exportOptions = [];
    exportOptions.push({
      key: "0",
      text: "Excel",
      value: "excel",
      icon: { name: "file excel outline", color: "orange" },
      content: "Excel",
      onClick: () => exportCart(),
    });
    setExportOptions(exportOptions);
    setCartType(newCartType);

    // console.log(
    //   "useEffect loading cart",
    //   wFocDate,
    //   wShipTo,
    //   match,
    //   location,
    //   cart.checkout.cartConfirmed
    // );

    // if (!wShipTo && !wFocDate) {
    if (cart.isUserPow()) {
      if (wFocDate) {
        if (cart.checkout.cartConfirmed) {
          setCartLoaded(true);
          setCartConfirmedDialogOpen(true);
          cart.setCheckoutValue({ cartConfirmed: false });
        } else {
          cart.clearCart();
          cart.clearCheckout();
          // console.log("loading estimates from CDM CP");
          // if (!dirtyHandler.setLoadingTakeover) {
          //   dirtyHandler.setLoadingTakeoverConfig({
          //     text: "Loading . . .",
          //     longLoadText: "Still loading . . .",
          //     inverted: true,
          //     backgroundBlurLight: true,
          //   });
          //   dirtyHandler.setLoadingTakeover(true);
          // }
          cart
            .loadEstimateCarts({
              focDate: wFocDate,
              simulate: true,
              orderDetail: true,
              forceTakeOver: true,
              silent: true,
            })
            .then((x) => {
              // console.log("loaded estimate carts");
              // if (this.props.cart.checkout.cartConfirmed) {
              //   this.setState({ cartConfirmedDialogOpen: true });
              //   this.props.cart.setCheckoutValue({ cartConfirmed: false });
              // }
              setCartLoaded(true);
            });
        }
      }
    } else {
      if (!!!listLoad) {
        dirtyHandler.setLoadingTakeoverConfig({
          text: "Loading . . .",
          longLoadText: "Still loading . . .",
          inverted: true,
          backgroundBlurLight: true,
        });
        dirtyHandler.setLoadingTakeover(true);
        cart.loadUserCart(cart.ccSapAccountNo, true, true).then((x) => {
          setCartLoaded(true);
        });
      } else {
        setCartLoaded(true);
      }
    }
    // }

    return () => {
      setCartConfirmedDialogOpen(false);
      cart.setCheckoutValue({ cartConfirmed: false });
    };
  }, []);

  useEffect(() => {
    if (location.pathname == "/cart" && cartType !== 0) {
      setCartType(0);
    }
  }, [location.pathname, cartType]);

  // useEffect(() => {
  //   console.log(
  //     "cartConfirmed useeffect",
  //     cartLoaded,
  //     cart.checkout.cartConfirmed
  //   );
  //   if (cartLoaded && cart.checkout.cartConfirmed) {
  //     setCartConfirmedDialogOpen(true);
  //     cart.setCheckoutValue({ cartConfirmed: false });
  //   }
  // }, [cartLoaded, cart.checkout.cartConfirmed]);

  useEffect(() => {
    if (
      cart.initialUserLoadComplete === false &&
      match.params?.shipTo &&
      match.params?.focDate
    ) {
      if (cart.isUserPow()) {
        if (focDate) {
          if (cart.checkout.cartConfirmed) {
            setCartLoaded(true);
          } else {
            cart.clearCart();
            cart.clearCheckout();
            // console.log("loading estimatesa from cwp CP");
            cart
              .loadEstimateCarts({
                focDate: focDate,
                simulate: true,
                orderDetail: true,
                forceTakeOver: true,
                silent: true,
              })
              .then((x) => {
                setCartLoaded(true);

                cart.setShipTo(
                  cart.customerInfo.shipToAccountNos.findIndex(
                    (s) => s.accountNo === shipTo
                  ),
                  false
                );
              });
          }
        }
      } else {
        cart.loadUserCart(cart.ccSapAccountNo, true, true).then((x) => {
          setCartLoaded(true);
        });
      }
    }
  }, [cart.initialUserLoadComplete]);

  useEffect(() => {
    // console.log('Setting focdate/shipto');
    window.scrollTo(0, 0);

    location?.state?.shipTo && setShipTo(location.state.shipTo);
    location?.state?.focDate && setFocDate(location.state.focDate);
  }, [location.state]);

  useEffect(() => {
    // console.log('cart change', this.props.cart.cart, nextProps.cart.cart);
    if (
      cart.isUserPow() &&
      cart.cart?.items.length === 0 &&
      cart.cart?.confirmationStatus === "DELETED"
    ) {
      history.push({
        pathname: "/cart-overview",
      });
    }
  }, [cart.cart]);

  const reloadBizCart = () => {
    dirtyHandler.setLoadingTakeoverConfig({
      text: "Loading . . .",
      longLoadText: "Still loading . . .",
      inverted: true,
      backgroundBlurLight: true,
    });
    dirtyHandler.setLoadingTakeover(true);

    cart.loadUserCart(cart.ccSapAccountNo, true, true).then((x) => {
      setCartLoaded(true);
      dirtyHandler.setLoadingTakeover(false);
    });
  };

  const logout = (e) => {
    window.location.href =
      "https://idp.dev.penguinrandomhouse.com/nidp/app/logout";
  };

  const toggleCart = (cartType) => {
    history.push({
      pathname: `/cart${cartType === 0 ? "" : "/sfl"}`,
    });
    setCartType(cartType);
  };

  const isPowFocCart = () => {
    // if (this.props.cart.isUserPow() && focDate && shipTo) return true;
    if (cart.isUserPow()) return true;
    return false;
  };

  const forceRefresh = () => {
    // this.forceUpdate();
  };

  const selectItem = (item, value) => {
    const xSelectedItems = { ...selectedItems };
    if (!!value) {
      xSelectedItems[item] = value;
    } else if (!!!value) {
      if (xSelectedItems[item]) delete xSelectedItems[item];
    }
    setSelectedItems(xSelectedItems);
  };

  const selectDeselectItems = (select = true) => {
    if (!!select) {
      let si = {};
      cart.cart.items.forEach((t) => {
        si[t.isbn] = true;
      });
      setSelectedItems(si);
    } else {
      setSelectedItems({});
      setClearCartAction(false);
    }
  };

  // removeSelectedItemsRemoveCart = (isbn = false) => {
  //   this.removeSelectedItems(isbn, true);
  // };

  const performRemoveSelectedItems = (isbn = false, force = false) => {
    let si = [];
    if (isbn) {
      si = cart.cart.items.filter((i) => {
        if (isbn === i.isbn) {
          return true;
        }
      });
    } else if (removeItemSelected) {
      si = cart.cart.items.filter((i) => {
        if (removeItemSelected === i.isbn) {
          return true;
        }
      });
    } else {
      si = cart.cart.items.filter((i) => {
        if (selectedItems[i.isbn]) {
          return true;
        }
      });
    }
    if (force) {
      setConfirmDeletedCart(true);
    }
    // console.log('removeSelectedItems', si);
    cart.isUserPow()
      ? removeEstimateItems(si, false) /* jeff */
      : removeItems(si);
  };

  const removeItem = (title) => {
    if (cart.isUserPow()) {
      setRemoveItemSelected(title.isbn);

      performRemoveSelectedItems(title.isbn);
    } else {
      removeItems([title]);
    }
  };

  const exportCart = (e) => {
    // console.log('exportCart', e);

    Export.Excel({
      fileName:
        cartType === 0
          ? `my-cart-${moment().format("YYYY-MM-DD")}`
          : `saved-for-later-${moment().format("YYYY-MM-DD")}`,
      csvData: cartType === 0 ? cart.cart?.items : cart.saveForLater.items,
      sheetName: "data",
    });
  };

  const removeItems = (itemId) => {
    // console.log('removeItems', itemId);
    if (itemId) {
      cart.removeItem(itemId);
    } else {
      cart.removeItem(cart.cart.items);
    }
    setSelectedItems({});
    setRemoveItemSelected(false);
    setClearCartAction(false);
  };

  const removeEstimateItems = (items, force = false) => {
    // console.log('removeEstimateItems', items);
    cart
      .removeEstimateItems({
        focDate,
        shipTo,
        items,
        simulate: true,
        force,
      })
      .then((r) => {
        if (r?.modalInfo === "REMOVE_VARIANTS") {
          setVariantOrderTitles(r);
          setVariantOrderDeleteModalState(true);
        } else {
          setSelectedItems({});
          setRemoveItemSelected(false);
          setClearCartAction(false);
        }
      });
  };

  const forceRemoveEstimateItems = () => {
    let si = [];
    if (removeItemSelected) {
      si = cart.cart.items.filter((i) => {
        if (removeItemSelected === i.isbn) {
          return true;
        }
      });
    } else {
      si = cart.cart.items.filter((i) => {
        if (selectedItems[i.isbn]) {
          return true;
        }
      });
    }
    cart.removeEstimateItems({
      focDate,
      shipTo,
      items: si,
      simulate: true,
      force: true,
      confirm: confirmDeletedCart,
    });

    setSelectedItems({});
    setRemoveItemSelected(false);
    setClearCartAction(false);
  };

  const removeFocCart = (confirm = false) => {
    cart
      .removeEstimateCart({
        foc: focDate,
        shipTo,
        confirm,
      })
      .then((r) => {
        if (r?.modalInfo === "REMOVE_VARIANTS") {
          setMainVariantTitles(r.mainVariantTitles);
          setVariantCartDeleteModalState(true);
        } else {
          // setConfirmDeleteDialog(false);
          setCartConfirmedDialogOpen(false);
          setSelectedItems({});
          setRemoveItemSelected(false);
          setClearCartAction(false);
          cart
            .loadEstimateCarts({
              simulate: false,
              orderDetail: true,
              forceTakeOver: true,
            })
            .then((r) => {
              history.push({ pathname: "/cart-overview" });
            });
        }
      });
  };

  const forceRemoveEstimateCart = () => {
    forceRemoveFocCart(true, confirmDeletedCart);
  };
  const forceRemoveFocCart = (force = false, confirm = false) => {
    cart
      .removeEstimateCarts({
        foc: [focDate],
        force: force,
        confirm: confirm,
      })
      .then((r) => {
        if (r?.modalInfo === "REMOVE_VARIANTS") {
          // console.log("forceRemoveFocCart", r);
          if (r?.mainVariantTitles) {
            setMainVariantTitles(r.mainVariantTitles);
            setVariantCartDeleteModalState(true);
          } else if (r?.focVariantTitles) {
            setMainVariantTitles(r.focVariantTitles);
            setVariantCartDeleteModalState(true);
          }
        } else {
          setCartConfirmedDialogOpen(false);
          // setConfirmDeleteDialog(false);
          setSelectedItems({});
          setRemoveItemSelected(false);
          setClearCartAction(false);
          // if (!isGeneralCart(this.state.focDate)) {
          cart
            .loadEstimateCarts({
              // focDate: this.state.focDate,
              simulate: true,
              orderDetail: true,
              forceTakeOver: true,
            })
            .then((r) => {
              // if (r && r.length === 0) {
              history.push({ pathname: "/cart-overview" });
              // }
            });
          // } else {
          //   this.props.history.push({ pathname: "/cart-overview" });
          // }
        }
      });
  };

  const moveFocToGeneral = (props) => {
    cart.moveEstimateCartToGeneral({ foc, items }).then((x) => {
      history.push({ pathname: "/cart-overview" });
    });
  };

  const checkOutOnClick = (e) => {
    // console.log('checkoutOnClick', this.props.cart.cart);
    setSelectedItems({});
    setClearCartAction(false);
    if (cart.isUserPow()) {
      // if (!isGeneralCart(this.state.focDate)) {
      if (isGeneralCart(focDate)) {
        cart.createCart({ cart: cart.cart }).then((e) => {
          history.push({
            pathname: "/checkout",
            state: { shipTo: shipTo, focDate: focDate },
          });
        });
      } else if (cart.cart?.confirmationStatus === "NONE") {
        // jeff should i load cart
        // cart
        //   .loadEstimateCarts({
        //     focDate: focDate,
        //     simulate: true,
        //     orderDetail: true,
        //     forceTakeOver: true,
        //   })
        //   .then((x) => {
        history.push({
          pathname: "/checkout",
          state: { shipTo: shipTo, focDate: focDate },
        });
        // });
      } else if (cart.cart?.confirmationStatus === "PENDING_UPDATE") {
        let data = { ...cart.cart.headers };
        data.shipTo = cart.customerInfo.shipToAccountNos[cart.shipTo].accountNo;
        data.foc = focDate;
        if (data.eventContactName) {
          data.orderEvent = true;
        }
        if (!data.poNumber) {
          data.poNumber = getDefaultPO();
        }

        // console.log('submitting confirmation', data);
        cart.confirmEstimateCart({ data }).then((o) => {
          // console.log("confirmed cart", data);
          setCartConfirmedDialogOpen(true);
          setCartConfirmedDialogUpdate(true);
        });
      }
      // } else {
      //   this.props.history.push({
      //     pathname: '/checkout',
      //     state: { shipTo: this.state.shipTo, focDate: this.state.focDate },
      //   });
      // }
    } else {
      history.push("/checkout");
    }
  };

  const undoChanges = (e) => {
    // console.log('undoChanges', e);

    cart.undoEstimateCart(e).then((r) => {
      // console.log("undo cart", r);
      if (r.data?.data?.modalInfo === "VARIANT_ROLLBACK_INFO") {
        setUndoUnsatisfiedTitles(r.data.data.unsatisfiedTitles);
        setUndoBlockedDialogModal(true);
      } else {
        setSelectedItems({});
        setClearCartAction(false);
        setRemoveItemSelected(false);
      }
    });
  };

  const handleSortChange = (value) => {
    // console.log('handleSortChange', value);
    setCurrentSort(value);
    // let sortedRows = [];
    // setCurrentSort(value);
    // sortedRows = _.sortBy(filteredResults, value);
    // setFilteredResults(sortedRows);
  };

  const getCartItems = (xCart, focDate) => {
    return cart.getCartItemsFiltered(
      {
        items: cart.getCartItemsSorted({
          sortCart: xCart,
          sortField: currentSort,
          sortOrder:
            currentSort === "itemId" || currentSort === "quantity"
              ? "desc"
              : "asc",
        }),
      },
      cart.isUserPow() && !isGeneralCart(focDate) ? true : false
    );
  };

  const renderCart = (xCart) => {
    const cartTotal = cart.getCartTotal(cart);
    let cartItems = getCartItems(xCart, focDate);
    let isCheckout = true;
    if (
      cartItems?.unavailable?.length > 0 ||
      cartItems?.unavailableInRegion?.length > 0
    )
      isCheckout = false;
    if (cart.customerInfo?.customer?.webShippable.length === 0)
      isCheckout = false;
    if (
      cart.isUserPow() &&
      isGeneralCart(focDate) &&
      cart.cart.items.length === 0
    )
      isCheckout = false;

    const cs = { marginTop: "-1rem" };

    const resultsSortOPtions = [
      {
        key: "itemId",
        text: "Sort by: Recently Added",
        content: "Recently Added",
        value: "itemId",
      },
      {
        key: "divisionName",
        text: "Sort by: Publisher - A to Z",
        content: "Publisher - A to Z",
        value: "divisionName",
      },
      {
        key: "title",
        text: "Sort by: Title - A to Z",
        content: "Title - A to Z",
        value: "title",
      },
      {
        key: "quantity",
        text: "Sort by: Quantity",
        content: "Qty - High to Low",
        value: "quantity",
      },
    ];

    const getSortTitleByKey = (key) => {
      const item = resultsSortOPtions.find((option) => option.key === key);
      return item.text;
    };

    const isMobile = mobile.isMobile;

    // console.log("CartPage render", cart);
    // console.log('this.props', this.props);
    return (
      <Grid
        container
        className={`cart-page ${isMobile ? "fluid bg-white" : ""}`}
      >
        <Grid.Row className=''>
          <Grid.Column
            mobile={16}
            tablet={10}
            computer={11}
            largeScreen={12}
            widescreen={12}
            className={`mb-8 ${isMobile ? "" : "keep-padding"}`}
          >
            {focDate !== false ? (
              isFocCartExpired(focDate) ? (
                <ExpiredFocBanner
                  foc={focDate}
                  className='mb-2 mt-2'
                  containerStyle={cs}
                  removeCarts={removeFocCart}
                  items={xCart?.items}
                  moveFocToGeneral={moveFocToGeneral}
                />
              ) : null
            ) : null}

            {focDate !== false &&
              isFocCartExpired(focDate) &&
              cart.cart?.confirmationStatus === "CONFIRMED" && (
                <div
                  className={`cv-confirmed d-flex flex-row align-items-center pl-3 pr-3 pt-1 pb-1 mb-2`}
                >
                  <Icon
                    name='check'
                    color='green'
                    className='align-self-start'
                  />
                  <div className='fort-medium fs-12px lh-16px ml-2'>
                    <div className='fort-bold'>
                      Your last-confirmed version of this cart has been
                      submitted as an order.
                    </div>
                    <div>
                      You can view details of this order on the orders page{" "}
                      <a
                        href='#'
                        onClick={(e) => {
                          history.push({ pahtname: "/orders" });
                        }}
                      >
                        Orders page
                      </a>
                      .
                    </div>
                  </div>
                </div>
              )}

            {isMobile && (
              <CartSummary
                shipTo={shipTo}
                foc={focDate}
                confirmationStatus={cart.cart?.confirmationStatus}
                updates={true}
                confirmed={true}
                workCart={xCart}
                subtotal={cartTotal.subtotal}
                discount={cartTotal.discount}
                shipping={cartTotal.shipping}
                tax={cartTotal.tax}
                total={cartTotal.total}
                retail={cartTotal.retail}
                error={cart.cart.error}
                disabled={true}
                checkOutOnClick={checkOutOnClick}
                undoChanges={undoChanges}
                cartLoaded={cartLoaded}
              />
            )}

            {!isMobile && (
              <div className='d-flex flex-row mb-2 align-items-center'>
                {Object.keys(selectedItems).length > 0 && (
                  <div className='flex' style={{ whiteSpace: "pre" }}>
                    <PRHButton
                      style={{ width: "148px" }}
                      className='mr-2'
                      onClick={(e) =>
                        selectDeselectItems(
                          Object.keys(selectedItems).length <
                            cartItems?.available?.length +
                              cartItems?.backordered.length
                        )
                      }
                    >
                      {Object.keys(selectedItems).length <
                      cartItems?.available?.length +
                        cartItems?.backordered.length
                        ? "Select all"
                        : "Unselect all"}
                    </PRHButton>

                    <PRHButton
                      className=''
                      icon='trash alternate'
                      onClick={(e) => {
                        setModifyCartModalOpen(true);
                      }}
                    >
                      Delete {Object.keys(selectedItems).length} items
                    </PRHButton>
                  </div>
                )}
                <div className='ml-auto d-flex flex-row cart-toolbar align-items-center fb-100 justify-content-end'>
                  <div className='download-dropdown-div mr-0'>
                    <Dropdown
                      selection
                      options={resultsSortOPtions}
                      value={currentSort}
                      direction='left'
                      onChange={(e, v) => {
                        handleSortChange(v.value);
                      }}
                      className='sort-dropdown sort-dropdown-catalog download-dropdown-div'
                    />
                  </div>
                </div>
              </div>
            )}
            {!isMobile && <Divider tight className='cart-container-divider' />}
            {cart.isUserPow() &&
            xCart.confirmationStatus === "PENDING_UPDATE" &&
            xCart.items?.length === 0 &&
            cartLoaded ? (
              <div className='d-flex flex-column align-items-center mt-8'>
                <LazyImage
                  src={"/images/shopping-cart-large-empty.svg"}
                  className='ml-auto mr-auto'
                />
                <div className='d-flex fort-book fs-24px lh-28p mt-2 mb-2'>
                  Your cart is empty.
                </div>
                <div className='fort-book fs-14px lh-19px'>
                  Add items or undo your updates.
                </div>
                <div
                  className='fort-book fs-14px lh-19px mt-3'
                  style={{ maxWidth: "250px" }}
                >
                  <span className='fort-bold'>Note:</span> Confirming this empty
                  cart will cancel your previously Confirmed quantities and
                  remove this cart.
                </div>
              </div>
            ) : xCart.items?.length === 0 && cartLoaded ? (
              <div className='d-flex fort-bold mt-3 mb-6'>
                Your Cart is empty
              </div>
            ) : null}

            {cartItems?.unavailable?.length > 0 ? (
              <div
                className={`cp-ua-titles pt-3 mt-2 ${
                  isMobile ? "ml-2 mr-2" : ""
                } pb-3`}
              >
                <div className='w-100 align-items-center'>
                  <div className='w-100 d-flex pl-3 pr-3'>
                    <Icon
                      className='fs-21px pr-2'
                      name='exclamation circle'
                      color='red'
                    />
                    <div className='d-flex flex-column cp-bo-header'>
                      <div className='fort-bold fs-14px lh-19 mb-1 text-red'>
                        Please Note: {cartItems.unavailable.length} titles are
                        not available to order.
                      </div>
                      <div className='fort-book fs-14px lh-19'>
                        These titles must be removed from your cart before
                        proceeding to checkout.
                      </div>
                    </div>
                    {!isMobile && (
                      <PRHButton
                        className='ml-auto'
                        onClick={(e) =>
                          cart.isUserPow()
                            ? removeEstimateItems(cartItems.unavailable)
                            : removeItems(cartItems.unavailable)
                        }
                      >
                        Remove All
                      </PRHButton>
                    )}
                  </div>
                </div>

                <Divider style={{ backgroundColor: "red" }} />
                <Grid
                  container
                  className={`${isMobile ? "" : "pl-3 pr-3"} d-block`}
                >
                  {cartItems.unavailable.map((item, idx) =>
                    !isMobile ? (
                      <TitleRow
                        key={`i-${item.isbn}`}
                        disabled={isFocCartExpired(focDate)}
                        data={{ ...item, foc: focDate }}
                        pageType='cart'
                        tab={cartType}
                        divider={!(cartItems.unavailable.length === idx + 1)}
                        update={true}
                        removeItem={removeItem}
                      />
                    ) : (
                      <TitleRowMobile
                        key={idx}
                        disabled={isFocCartExpired(focDate)}
                        data={{ ...item, foc: focDate }}
                        pageType='cart'
                        tab={cartType}
                        divider={!(cartItems.unavailable.length === idx + 1)}
                        update={true}
                        showShortInformation={true}
                        showQty={true}
                      />
                    )
                  )}
                </Grid>
              </div>
            ) : null}

            {cartItems?.unavailableInRegion?.length > 0 ? (
              <div
                className={`cp-ua-titles pt-3 mt-2 ${
                  isMobile ? "ml-2 mr-2" : ""
                } `}
              >
                <div className='w-100 align-items-center'>
                  <div className='w-100 d-flex pl-3 pr-3'>
                    <Icon
                      className='fs-21px pr-2'
                      name='exclamation circle'
                      color='red'
                    />
                    <div className='d-flex flex-column cp-bo-header mr-1'>
                      <div className='fort-bold fs-14px lh-19 mb-1 text-red'>
                        Please Note: {cartItems.unavailableInRegion.length}{" "}
                        titles are not available to order.
                      </div>
                      <div className='fort-book fs-14px lh-19'>
                        Sorry this item cannot be purchased at this time, please
                        remove it from your cart. There may be stock available.
                        Please contact customer service to order.
                      </div>
                    </div>
                    {!isMobile && (
                      <PRHButton
                        className='ml-auto cp-remove-all'
                        onClick={(e) =>
                          cart.isUserPow()
                            ? removeEstimateItems(cartItems.unavailableInRegion)
                            : removeItems(cartItems.unavailableInRegion)
                        }
                      >
                        Remove All
                      </PRHButton>
                    )}
                  </div>
                </div>
                <Divider style={{ backgroundColor: "red" }} />
                <Grid
                  container
                  className={`${isMobile ? "" : "pl-3 pr-3"} d-block`}
                >
                  {cartItems.unavailableInRegion.map((item, idx) =>
                    !isMobile ? (
                      <TitleRow
                        simulate={true}
                        key={`i-${item.isbn}`}
                        disabled={isFocCartExpired(focDate)}
                        data={{ ...item, foc: focDate }}
                        pageType='cart'
                        tab={cartType}
                        divider={
                          !(cartItems.unavailableInRegion.length === idx + 1)
                        }
                        update={true}
                        removeItem={removeItem}
                      />
                    ) : (
                      <TitleRowMobile
                        key={idx}
                        disabled={isFocCartExpired(focDate)}
                        data={{ ...item, foc: focDate }}
                        pageType='cart'
                        tab={cartType}
                        divider={
                          !(cartItems.unavailableInRegion.length === idx + 1)
                        }
                        update={true}
                        showShortInformation={true}
                        showQty={true}
                      />
                    )
                  )}
                </Grid>
              </div>
            ) : null}

            {cartItems?.backordered?.length > 0 && cartLoaded ? (
              <div
                className={`cp-bo-titles pt-3 mt-2 pb-3 ${
                  isMobile ? "ml-2 mr-2" : ""
                }`}
              >
                <div className='w-100 align-items-center'>
                  {
                    <div className='w-100 d-flex pl-3 pr-3'>
                      <Icon
                        className='fs-21px pr-2'
                        name='info circle'
                        color='orange'
                      />
                      {user.isAppBiz() ? (
                        <div className='d-flex flex-column cp-bo-header'>
                          <div className='fort-bold mb-1 fs-14px lh-19'>
                            Please Note: {cartItems.backordered.length} titles
                            are temporarily out of stock or have yet to publish.
                          </div>
                          <div className='fort-book fs-14px lh-19'>
                            These titles will be backordered unless removed
                            before Checkout
                          </div>
                        </div>
                      ) : (
                        <div className='d-flex flex-column cp-bo-header'>
                          <div className='fort-bold mb-1 fs-14px lh-19'>
                            Please Note: {cartItems.backordered.length} titles
                            are temporarily out of stock or have yet to publish.
                          </div>
                          <div className='fort-book fs-14px lh-19'></div>
                        </div>
                      )}
                    </div>
                  }
                  <Divider />
                  <Grid
                    container
                    padded
                    className={`${isMobile ? "" : "pl-3 pr-3"} d-block`}
                  >
                    {cartItems.backordered.map((item, idx) =>
                      !isMobile ? (
                        <TitleRow
                          selectItem={selectItem}
                          selected={selectedItems[item.isbn]}
                          disabled={isFocCartExpired(focDate)}
                          key={`i-${item.isbn}`}
                          simulate={true}
                          data={{ ...item, foc: focDate }}
                          pageType='cart'
                          tab={cartType}
                          divider={!(cartItems.backordered.length === idx + 1)}
                          update={true}
                          removeItem={removeItem}
                        />
                      ) : (
                        <TitleRowMobile
                          disabled={isFocCartExpired(focDate)}
                          key={idx}
                          simulate={
                            cartItems?.unavailable?.length > 0 ||
                            cartItems?.unavailableInRegion?.length > 0
                          }
                          data={{ ...item, foc: focDate }}
                          pageType='cart'
                          tab={cartType}
                          divider={!(cartItems.backordered.length === idx + 1)}
                          update={true}
                          showShortInformation={true}
                          showQty={true}
                        />
                      )
                    )}
                  </Grid>
                </div>
              </div>
            ) : null}

            <div className='mt-3'>
              <Grid container padded className='d-block'>
                {cartItems.available.map((item, idx) =>
                  !isMobile ? (
                    <TitleRow
                      key={`i-${item.isbn}`}
                      selectItem={selectItem}
                      selected={selectedItems[item.isbn]}
                      simulate={true}
                      forceRefresh={forceRefresh}
                      disabled={isFocCartExpired(focDate)}
                      data={{ ...item, foc: focDate }}
                      pageType='cart'
                      tab={cartType}
                      divider={!(cartItems.available.length === idx + 1)}
                      update={true}
                      removeItem={removeItem}
                    />
                  ) : (
                    <TitleRowMobile
                      key={idx}
                      simulate={
                        cartItems?.unavailable?.length > 0 ||
                        cartItems?.unavailableInRegion?.length > 0
                      }
                      forceRefresh={forceRefresh}
                      disabled={isFocCartExpired(focDate)}
                      data={{ ...item, foc: focDate }}
                      pageType='cart'
                      tab={cartType}
                      divider={!(cartItems.available.length === idx + 1)}
                      update={true}
                      showShortInformation={true}
                      showQty={true}
                    />
                  )
                )}
              </Grid>
            </div>
            {/* </>
            )} */}
          </Grid.Column>
          {!isMobile && (
            <Grid.Column
              mobile={16}
              tablet={6}
              computer={5}
              largeScreen={4}
              widescreen={4}
              className='order-2 order-lg-1'
            >
              <CartSummary
                shipTo={shipTo}
                foc={focDate}
                confirmationStatus={cart.cart?.confirmationStatus}
                updates={true}
                confirmed={true}
                workCart={xCart}
                error={cart.cart.error}
                disabled={
                  !isCheckout ? true : isFocCartExpired(focDate) ? true : false
                }
                checkOutOnClick={checkOutOnClick}
                undoChanges={undoChanges}
                cartLoaded={cartLoaded}
              />
            </Grid.Column>
          )}
        </Grid.Row>
        {!isMobile && <BackToTopButton />}
        {isMobile && <BackToTopButtonMobile />}
      </Grid>
    );
  };

  const renderSaveForLater = () => {
    return (
      <Grid container className='cart-page mb-8'>
        <Grid.Row className=''>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={11}
            largeScreen={12}
            widescreen={12}
            className='keep-padding'
          >
            {cart.saveForLater.items.length === 0 ? (
              <div className='d-flex fort-bold mt-3 mb-6'>
                Your Save for later list is empty
              </div>
            ) : null}

            <Grid container padded className='d-block'>
              {cart.saveForLater.items.map((item, idx) => (
                <TitleRow
                  key={`i-${item.isbn}`}
                  disabled={isFocCartExpired(focDate)}
                  data={{ ...item }}
                  pageType='sfl'
                  tab={cartType}
                  divider={!(cart.saveForLater.length === idx + 1)}
                />
              ))}
            </Grid>
          </Grid.Column>
          <Grid.Column computer={5} largeScreen={4} widescreen={4} />
        </Grid.Row>
      </Grid>
      // <div style={{ width: '463px' }}> </div>
    );
  };

  const buildExportData = (xCart) => {
    let d = [];
    // console.log("export cart", xCart);

    Object.keys(xCart).forEach((ct) => {
      xCart[ct].forEach((ei) => {
        let wi = {
          "ISBN / UPC": ei.isbn,
          Quantity: ei.quantity,
          Title: ei.title,
          Subtitle: ei.subtitle,
          Contributors: ei.author,
          "On Sale Date": ei.onsale,
          "Catalog Date": ei.catalog,
          "FOC Date": isGeneralCart(ei.foc) ? "General" : ei.foc,
          Format: ei.formatName,
          Publisher: ei.divisionName,
          "Carton Quantity": ei.cartonQuantity,
          "Inventory Status": ei.inventoryMsg,
          "Managed Stock Status": ei.managedMsg,
          "Est Delivery Date": formatDateMMDDYYYY(ei.deliveryDate, "/"),
          "Retail Unit Price": ei.priceSingle,
          "Total Retail Price": ei.priceTotal,
          "Base Discount %": ei.basePctDiscount,
          "Discounted Unit Price": ei.discountedPriceSingle,
          //ei.priceSingle - ei.priceSingle * ei.basePctDiscount,
          "Total Discounted Price": ei.discountedPriceTotal, //ei.sapBaseDiscountedPrice,
        };

        d.push(wi);
      });
    });
    return d;
  };

  const focDiff = focDateDifference(focDate);

  if (!cart.customerLoaded) {
    return <Loader />;
  }

  // console.log('CartPage render', cart, this.props.cart.isUserPow());
  let cartTotal = cart.getCartTotal(cart);
  if (cartType === 1) {
    cartTotal = cart.getCartTotal(cart.saveForLater.items);
  }

  const isMobile = mobile.isMobile;

  return (
    <PageStandard
      headerType='loggedIn'
      className={`standard-container cart-container ${
        isMobile ? "bg-gray-100" : ""
      }`}
      containerFluid={true}
    >
      <BridgeAddRetailerTitlesModal
        listID={listID}
        listName={listName}
        callback={reloadBizCart}
      />

      <VariantRestrictionsOrderDeleteModal
        openState={variantOrderDeleteModalState}
        variantOrderTitles={variantOrderTitles}
        setOpenState={(w) => {
          setVariantOrderDeleteModalState(w);
        }}
        foc={focDate}
        shipTo={shipTo}
        cancelCallback={() => {
          if (removeItemSelected) {
            setRemoveItemSelected(false);
          }
        }}
        proceedCallback={forceRemoveEstimateItems}
      />
      <VariantRestrictionsCartDeleteModal
        openState={variantCartDeleteModalState}
        setOpenState={(w) => {
          setVariantCartDeleteModalState(w);
        }}
        foc={focDate}
        mainVariantTitles={mainVariantTitles}
        shipTo={shipTo}
        cancelCallback={() => {}}
        proceedCallback={() => {
          const removeAll =
            Object.keys(selectedItems).length === cart.cart.items.length ||
            clearCartAction;
          if (removeAll) {
            forceRemoveEstimateCart();
          } else {
            forceRemoveEstimateItems();
          }
        }}
      />
      <RemoveCartModal
        open={modifyCartModalOpen}
        setOpen={(e) => setModifyCartModalOpen(!modifyCartModalOpen)}
        callback={
          clearCartAction
            ? cart.isUserPow()
              ? removeFocCart
              : removeItems
            : performRemoveSelectedItems
        }
        callbackRemove={() => {
          //confirmed  quantities modal return
          const removeAll =
            Object.keys(selectedItems).length === cart.cart.items.length ||
            clearCartAction;
          // this.forceRemoveFocCart(false, true);
          setConfirmDeletedCart(true);
          removeAll
            ? cart.isUserPow()
              ? removeFocCart(true)
              : removeItems()
            : performRemoveSelectedItems();
        }}
        cancelCallback={() => {
          setClearCartAction(false);
        }}
        itemCount={
          clearCartAction
            ? cart.cart?.items.length
            : Object.keys(selectedItems).length
        }
        clearCartAction={clearCartAction}
        foc={focDate}
        confirmationStatus={cart.cart?.confirmationStatus}
        removeAllItems={
          Object.keys(selectedItems).length === cart.cart.items.length ||
          clearCartAction
        }
      />
      <DialogModal
        open={
          cartConfirmedDialogOpen
          //  &&
          // !!!this.props.dirtyHandler.loadingTakeover
        }
        onXClose={() => setCartConfirmedDialogOpen(false)}
        // setOpen={this.setCartConfirmedDialogOpen}
        // options={{ size: 'mini' }}
        modalClassName='cp-confirmcart-popup'
        style={{ maxWidth: "448px" }}
        dialogOptions={{
          header: cartConfirmedDialogUpdate
            ? "UPDATES CONFIRMED"
            : "Cart Quantities Confirmed",
          actions: [
            {
              label: "OK",
              borderColorOverride: "blue",
              textColorOverride: "white",
              textColorHoverOverride: "blue",
              backgroundColorOverride: "blue",
              backgroundHoverColorOverride: "white",
              iconColorOverride: "white",
              iconColorHoverOverride: "blue",

              preAction: () => {
                return { proceed: true };
              },

              action: () => {
                setCartConfirmedDialogUpdate(false);
                setCartConfirmedDialogOpen(false);

                // this.props.cart.setCheckoutValue({ cartConfirmed: false });
                // console.log('cart modal', this.props.cart);
                if (cart?.cart?.items.length === 0) {
                  history.push({ pathname: "/cart-overview" });
                }
                return { proceed: true };
              },
            },
          ],
        }}
      >
        <div className='d-flex flex-column'>
          <LazyImage
            src={"/images/cart-conf-success.png"}
            className='ml-auto mr-auto'
          />
          <div className='fort-bold fs-18px lh-21px mb-1'>
            {cartConfirmedDialogUpdate
              ? "Your updates have been confirmed."
              : "Your FOC cart's quantities have been confirmed."}
          </div>
          <div className='fort-book fs-13px lh-18px mb-2 d-flex flex-row'>
            <Icon name='file alternate outline' style={{ color: "#515556" }} />
            Purchase Order: {cart.cart?.headers?.poNumber}
          </div>
          <div className='fort-book fs-14px lh-19px mb-2'>
            This FOC cart will be automatically placed as an order on the FOC
            date. Until then you can continue to add or remove items to your FOC
            cart.
          </div>
        </div>
      </DialogModal>
      <UndoBlockedDialogModal
        open={undoBlockedDialogModal}
        setOpen={(x) => setUndoBlockedDialogModal(x)}
        unsatisfiedTitles={undoUnsatisfiedTitles}
      />
      <div className='cart-page-main fort-bold fs-12px d-flex justify-content-start mb-2'>
        {/* {this.isPowFocCart() ? ( */}
        <div
          className='bg-gray-100 w-100 d-flex flex-row'
          style={!isMobile ? { borderBottom: "1px solid #ddd" } : {}}
          // style={{ borderBottom: '1px solid #ddd' }}
        >
          <Grid container className='cart-page pt-2 pb-2 w-100'>
            {isMobile && (
              <NoticeCard margin='mt-0 mb-3 fort-book'>
                Checkout is accessible through desktop only.
              </NoticeCard>
            )}
            <Grid.Row className='w-100'>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={16}
                widescreen={16}
                className='Xkeep-padding'
              >
                {isMobile && cart.isUserPow() && (
                  <div
                    className='cursor-pointer mb-1'
                    onClick={(e) => {
                      history.push({
                        pathname: "/cart-overview",
                      });
                    }}
                  >
                    <Icon name='arrow left' />
                    <a
                      href='#'
                      className='text-uppercase fs-13px lh-18 fort-medium text-blue'
                    >
                      back to all carts
                    </a>
                  </div>
                )}
                {!cart.isUserPow() && (
                  <div className='d-flex flex-row cp-toolbar mb-2'>
                    <div className='d-flex flex-row'>
                      <div
                        className={`fort-medium text-uppercase cp-toolbar-item ${
                          cartType === 0 ? "active" : ""
                        }`}
                        onClick={(e) => {
                          toggleCart(0);
                        }}
                      >
                        My Cart ({cart.cart.items?.length})
                      </div>
                      <Divider className='ml-2 mr-2 horizontal' horizontal />
                      <div
                        className={`fort-medium text-uppercase cp-toolbar-item ${
                          cartType === 1 ? "active" : ""
                        }`}
                        onClick={(e) => {
                          toggleCart(1);
                        }}
                      >
                        Saved For Later ({cart.saveForLater.items?.length})
                      </div>
                    </div>
                  </div>
                )}
                <div className='d-flex flex-row'>
                  <div
                    className={`d-flex flex-column ${isMobile ? "w-100" : ""}`}
                  >
                    {isPowFocCart() && !isMobile ? (
                      <div
                        className='cursor-pointer mb-1'
                        onClick={(e) => {
                          history.push({
                            pathname: "/cart-overview",
                          });
                        }}
                      >
                        <Icon name='arrow left' className='blue-hover' />
                        <a
                          href='#'
                          className='text-uppercase fs-13px lh-18 fort-medium text-blue'
                        >
                          back to all carts
                        </a>
                      </div>
                    ) : null}
                    <div className='mb-1 d-flex align-items-center'>
                      <Image
                        // width="22"
                        className='mr-1'
                        src={
                          cartType === 1
                            ? "/images/cart-SFL.svg"
                            : cart.cart?.confirmationStatus === "CONFIRMED"
                            ? "/images/cart-with-check.svg"
                            : cart.cart?.confirmationStatus === "PENDING_UPDATE"
                            ? "/images/cart-with-updates.svg"
                            : "/images/shopping-cart.svg"
                        }
                      />
                      <span
                        className='fs-24px lh-28 fort-light'
                        style={{ verticalAlign: "text-bottom" }}
                      >
                        {!cart.isUserPow()
                          ? cartType === 0
                            ? "My Cart"
                            : "Saved For Later"
                          : !isGeneralCart(focDate)
                          ? `FOC ${formatDateMMDDYYYY(focDate, "/")} ${
                              isFocCartExpired(focDate)
                                ? ""
                                : cart.cart?.confirmationStatus === "CONFIRMED"
                                ? " (Confirmed)"
                                : cart.cart?.confirmationStatus ===
                                  "PENDING_UPDATE"
                                ? " (Updated)"
                                : ""
                            }`
                          : `General Cart`}
                      </span>
                    </div>

                    <div
                      className={`mb-1 fort-medium fs-13px lh-18 ${
                        isMobile
                          ? "d-flex flex-row w-100 justify-content-space-between  pt-2"
                          : ""
                      }`}
                    >
                      <span
                        className={`${
                          isMobile
                            ? "d-flex flex-column text-center px-6 border-right-gray-500"
                            : "border-right-gray-500 pr-1"
                        }`}
                      >
                        {isMobile ? "TOTAL ITEMS" : `Total items:${" "}`}
                        <NumericFormat
                          className=''
                          value={cartTotal.itemCount}
                          thousandSeparator={true}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          displayType={"text"}
                        />
                        {}
                      </span>
                      <span
                        className={`${
                          cart.cart.catalogDate ? "border-right-gray-500" : ""
                        } ${
                          isMobile
                            ? "d-flex flex-column text-center border-none px-6 border-right-gray-500"
                            : "pl-1 pr-1"
                        }`}
                      >
                        {isMobile ? "TOTAL UNITS" : `Total units:${" "}`}
                        <NumericFormat
                          className=''
                          value={cartTotal.unitCount}
                          thousandSeparator={true}
                          decimalScale={0}
                          fixedDecimalScale={true}
                          displayType={"text"}
                        />
                      </span>
                      {!isMobile && (
                        <span className='pl-1'>
                          {cart?.catalogDate ? (
                            <Link to={`/catalog/${cart.cart.catalogDate}`}>
                              Catalog: {formatDate(cart.cart.catalogDate, true)}
                            </Link>
                          ) : null}
                        </span>
                      )}
                    </div>
                  </div>
                  {!isMobile && (
                    <div className='ml-auto d-flex flex-row cart-toolbar align-items-center justify-content-end'>
                      {cart.isUserPow() ? (
                        <AddUPCISBNModal
                          backgroundColorOverride='white'
                          addISBNsToList={[]}
                          onAddClick={(e) => {
                            cart.loadEstimateCarts({ focDate }).then((x) => {
                              setCartLoaded(true);
                            });
                          }}
                        />
                      ) : (
                        <AddISBNModal
                          className='mr-2'
                          addISBNsToList={[]}
                          disabled={isFocCartExpired(focDate)}
                        />
                      )}
                      <span className='d-none d-lg-flex'>
                        <DownloadDropdown
                          classes='download-dropdown ml-1 fort-book fs-14px'
                          fileName={`Cart${
                            cart.isUserPow() ? `-${shipTo}-${focDate}` : ``
                          }`}
                          data={
                            cartType === 1
                              ? buildExportData({
                                  saveforlater: cart.saveForLater.items,
                                })
                              : buildExportData(
                                  getCartItems(cart.cart, focDate)
                                )
                          }
                        />
                      </span>

                      {cartType !== 1 && (
                        <ActionMenu
                          actions={[
                            {
                              key: 1,
                              content: (
                                <div
                                  onClick={(e) => {
                                    setClearCartAction(true);
                                    setModifyCartModalOpen(true);
                                  }}
                                >
                                  Clear cart
                                </div>
                              ),
                              value: "clearcart",
                            },
                          ]}
                        />
                      )}
                    </div>
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
      {cartType === 0 && renderCart(cart.cart)}
      {cartType === 1 && renderSaveForLater()}
    </PageStandard>
  );
};

export default CartPage;
