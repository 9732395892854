import React, { useState } from "react";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import PageStandard from "../../components/elements/PageStandard";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import { BackorderedTitlesTab } from "./BackorderedTitlesTab";
import { InvoicesTab } from "./InvoicesTab";
import OIHeader from "./OIHeader";
import "./OrderInvoice.scss";
import { OrdersTab } from "./OrdersTab";
import { DeliveriesTab } from "./DeliveriesTab";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";
import StatementsTab from "./StatementsTab";
import useCart from "../../components/hc/useCart";
import NoAccessMessage from "../../components/common/NoAccessMessage";

const OrderInvoice = (props) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const cart = useCart();
  let tabs = [{ id: "/orders", name: "ORDERS", icon: "" }];

  if (props.user.isAppBiz()) {
    tabs.push({ id: "divider" });
    tabs.push({ id: "/deliveries", name: "Deliveries", icon: "" });
    tabs.push({ id: "divider" });

    tabs.push({
      id: "/invoices",
      name: isMobile ? "INVOICES" : "INVOICES & CLAIMS",
      icon: "",
    });

    if (cart.customerInfo.currentUser.roleNo === "W9" && !isMobile) {
      tabs.push({ id: "divider" });
      tabs.push({ id: "/statements", name: "Statements", icon: "" });
    }
  }

  tabs.push({ id: "divider" });
  tabs.push({ id: "/backorders", name: "BACKORDERED TITLES", icon: "" });

  const [state, setState] = useState({
    tabs,
    tab: props.history.location.pathname,
  });

  const tabChange = (tabId) => {
    setState({ ...state, tab: tabId });
    props.history.push({
      pathname:
        tabId /*, state:{...props.history.location.state, refresh: true}*/,
    });
  };

  return (
    <PageStandard headerType='loggedIn' containerFluid={true} cartBadge={true}>
      <Container fluid={isMobile}>
        <OIHeader
          currentTab={props.history.location.pathname}
          tabs={state.tabs}
          tabChange={tabChange}
          folderName=''
        />
        {!isMobile && <div className='oi-divider' />}
        {props.history.location.pathname === "/orders" && <OrdersTab />}
        {props.history.location.pathname === "/deliveries" && <DeliveriesTab />}

        {props.history.location.pathname === "/invoices" &&
          props.user.isAppBiz() && <InvoicesTab />}
        {props.cart?.customerInfo?.currentUser?.roleNo === "W9" &&
          props.history.location.pathname === "/statements" && (
            <><StatementsTab /></>
          )}
        {props.cart?.customerInfo?.currentUser?.roleNo !== "W9" &&
          props.history.location.pathname === "/statements" &&  (
            <NoAccessMessage />
          )}
        {props.history.location.pathname === "/backorders" && (
          <BackorderedTitlesTab />
        )}

        {isMobile && <BackToTopButtonMobile />}
      </Container>
    </PageStandard>
  );
};

export default withRouter(withSecurity(withApp(withCart(OrderInvoice))));
