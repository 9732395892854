import { PRHLabel } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import TextValidator from "../../shared-react-components/elements/input";
import "./customerenrolment.scss";
import "react-datepicker/dist/react-datepicker.css";
import { validateEmail, validateNumbers } from "./helpers";

const BankAccountInformation = ({ data, onChange, onNext, onBack }) => {
  const {
    name,
    accountNumber,
    address,
    phoneNumber,
    email,
    bankRepresentativeName,
    bankRepresentativePhone,
    bankRepresentativeEmail,
  } = data;

  const validateFields =
    validateEmail(email) &&
    validateNumbers(accountNumber) &&
    accountNumber?.length > 5 &&
    accountNumber?.length < 18 &&
    validateEmail(bankRepresentativeEmail);

  const disableNext =
    !name ||
    !accountNumber ||
    !address ||
    !phoneNumber ||
    !email ||
    !bankRepresentativeName ||
    !bankRepresentativePhone ||
    !bankRepresentativeEmail ||
    !validateFields;

  return (
    <>
      <div className="bold-label my-2">BANK ACCOUNT</div>
      <TextValidator
        id="name"
        name="name"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Bank Name
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("name", e.target.value)}
        value={name}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="accountNumber"
        name="accountNumber"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Bank Account Number
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("accountNumber", e.target.value)}
        value={accountNumber}
        validators={[
          "required",
          "isNumber",
          "minStringLength: 6",
          "maxStringLength: 17",
        ]}
        errorMessages={[
          "This field is required",
          "This field must be a number",
          "Min 6 digits",
          "Min 17 digits",
        ]}
        width={16}
      />

      <TextValidator
        id="address"
        name="address"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Bank Address (Street Address,
            City, State or Province, Zip or Postal Code)
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("address", e.target.value)}
        value={address}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="phoneNumber"
        name="phoneNumber"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Bank Phone Number
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("phoneNumber", e.target.value)}
        value={phoneNumber}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="email"
        name="email"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Bank Email Address (Customer
            Support, Business to Business Support, or Credit Support)
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("email", e.target.value)}
        value={email}
        validators={["required", "isEmail"]}
        errorMessages={["This field is required", "This field is email"]}
        width={16}
      />

      <div className="bold-label my-2">
        BANK REPRESENTATIVE CONTACT INFORMATION
      </div>

      <TextValidator
        id="bankRepresentativeName"
        name="bankRepresentativeName"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Bank Representative Name
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("bankRepresentativeName", e.target.value)}
        value={bankRepresentativeName}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="bankRepresentativePhone"
        name="bankRepresentativePhone"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Bank Representative Phone Number
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("bankRepresentativePhone", e.target.value)}
        value={bankRepresentativePhone}
        validators={["required"]}
        errorMessages={["This field is required"]}
        width={16}
      />

      <TextValidator
        id="bankRepresentativeEmail"
        name="bankRepresentativeEmail"
        className="form-field"
        label={
          <PRHLabel className="input-label">
            <span className="text-red">*</span>Bank Representative Email
          </PRHLabel>
        }
        type="text"
        onChange={(e) => onChange("bankRepresentativeEmail", e.target.value)}
        value={bankRepresentativeEmail}
        validators={["required", "isEmail"]}
        errorMessages={["This field is required", "This field is email"]}
        width={16}
      />

      <div className="d-flex flex-row Xbutton-holder my-5">
        <PRHButton
          size="large"
          iconleft
          icon="arrow left"
          className="mr-auto"
          onClick={onBack}
        >
          Back
        </PRHButton>
        <PRHButton
          disabled={disableNext}
          iconright
          icon="arrow right"
          size="large"
          className="ml-auto"
          type="submit"
          onClick={onNext}
        >
          Credit Reference
        </PRHButton>
      </div>
      <div className="d-flex flex-row my-5 fs-12px">
        <span className="text-red mr-1">*</span> denotes that this field is
        required to proceed
      </div>
    </>
  );
};

export default BankAccountInformation;
