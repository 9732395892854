import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DefaultTabBar, Tabs } from 'rmc-tabs';
import 'rmc-tabs/assets/index.css';
import _ from 'underscore';
import { getAccordionTitleDate, sortObj } from '../../pages/catalog/Catalog';
import TitleRowMobile from './TitleRowMobile';
import FOCStatus from './FOCStatus';
import './MobileCatalogItemsTabs.scss';
import { isFocCartExpired } from '../../utils/utilities';

const MobileCatalogItemsTabs = (props) => {
  const {
    filteredResults,
    currentSort,
    reloadCatalog,
    currentTabData,
    onSetCurrentTabData,
    filters,
  } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  let tabDataTemp = [];

  let passedDateFOCs = {};
  let validDateFOCs = {};
  let onSaleSortedResults = [];
  if (currentSort === 'cartQuantity') {
    onSaleSortedResults = _.sortBy(filteredResults, 'cartQuantity').reverse();
  } else {
    onSaleSortedResults = _.sortBy(filteredResults, 'onsale');
  }
  const focSortedResults = sortObj(_.groupBy(onSaleSortedResults, 'foc'));

  useEffect(() => {
    if (!currentTabData && tabDataTemp.length > 0) {
      onSetCurrentTabData(tabDataTemp[0]);
    }
  }, [tabDataTemp, currentTabData]);

  useEffect(() => {
    if (currentTabData && filteredResults) {
      const existingTab = filteredResults.find(
        (result) => result.foc === currentTabData.key
      );
      if (!existingTab) {
        setActiveIndex(0);
        onSetCurrentTabData(tabDataTemp[0]);
      } else {
        const newIndex = tabDataTemp.findIndex(
          (tab) => tab.key === existingTab.foc
        );
        setActiveIndex(newIndex);
      }

      const updatedCurrentTab = currentTabData.tab.filter((tabItem) => {
        return filteredResults.some((item) => {
          return tabItem.title.isbn === item.isbn;
        });
      });
    }
  }, [filteredResults]);

  _.each(focSortedResults, function (value, key) {
    isFocCartExpired(key)
      ? (passedDateFOCs[key] = value)
      : (validDateFOCs[key] = value);
  });

  const focFiltersCount = _.filter(
    _.find(filters, { resultsMatchID: 'foc' }).values,
    {
      checked: true,
    }
  );
  const isOneFocDate = focFiltersCount.length === 1;

  if (
    currentSort === 'foc' ||
    currentSort === 'cartQuantity' ||
    (currentSort === 'title' && isOneFocDate)
  ) {
    //valid FOC panels
    _.each(validDateFOCs, function (value, key) {
      const tabContentArray = value.map((title, key) => {
        return {
          title: title,
          key: key,
        };
      });
      tabDataTemp.push({
        title: `FOC ${getAccordionTitleDate(key)}`,
        tab: tabContentArray,
        key: key,
      });
    });

    //expired FOC panels
    _.each(passedDateFOCs, function (value, key) {
      const tabContentArray = value.map((title, key) => {
        return {
          title: title,
          key: key,
        };
      });
      tabDataTemp.push({
        title: `FOC ${getAccordionTitleDate(key)}`,
        tab: tabContentArray,
        key: key,
      });
    });
  } else {
    const onSaleDateSortedResults = sortObj(
      _.groupBy(_.sortBy(filteredResults, 'onsale'), currentSort)
    );
    _.each(onSaleDateSortedResults, function (value, key) {
      const tabContentArray = value.map((title, key) => {
        return {
          title: title,
          key: key,
        };
      });
      tabDataTemp.push({
        title: value[0].divisionName,
        tab: tabContentArray,
        key: key,
      });
    });
  }

  return currentTabData ? (
    <Tabs
      swipeable
      activeTab={activeIndex}
      page={activeIndex}
      animated={false}
      tabs={tabDataTemp}
      onChange={(tab, x) => {
        setActiveIndex(x);
        onSetCurrentTabData(tab);
      }}
      renderTabBar={(props) => <DefaultTabBar page={3} {...props} />}
      tabBarActiveTextColor="#0078a6 "
      tabBarUnderlineStyle={{
        borderBottom: '3px solid #0078a6 ',
      }}
    >
      <div>
        {currentSort === 'foc' && <FOCStatus focDate={currentTabData.key} />}
        {currentTabData &&
          currentTabData.tab &&
          filteredResults &&
          currentTabData.tab.map((tab) => (
            <div>
              {filteredResults.map((item) => {
                if (tab.title.isbn === item.isbn)
                  return (
                    <React.Fragment key={`valid-accord-title-${tab.key}`}>
                      <TitleRowMobile
                        data={tab.title}
                        pageType="catalog"
                        divider={true}
                        onRefreshTab={reloadCatalog}
                        key={`valid-accord-title-${tab.key}`}
                      />
                    </React.Fragment>
                  );
              })}
            </div>
          ))}
      </div>
    </Tabs>
  ) : null;
};

export default MobileCatalogItemsTabs;

MobileCatalogItemsTabs.propTypes = {
  filteredResults: PropTypes.array.isRequired,
  currentSort: PropTypes.string,
};
MobileCatalogItemsTabs.defaultProps = {
  filteredResults: [],
  currentSort: '',
};
