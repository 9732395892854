import React, { useState } from "react";
import { withRouter } from "react-router";
import { Container, Dropdown, Popup } from "semantic-ui-react";
import withSecurity from "../../components/hc/withSecurity";
import LazyImage from "../../utils/LazyImage";
import Download from "../../assets/images/download.svg";
import "./../search/Search.scss";
import { AddToCartContainer } from "./components/AddToCartContainer";
import { TitleMetadataContainer } from "./components/TitleMetadataContainer";
import { FormatsDropdown } from "./components/FormatsDropdown";
import "./TitleMetadata.scss";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TitleHistorySummary from "./components/TitleHistorySummary";
import SeriesOrderHistorySummary from "./components/SeriesOrderHistorySummary";
import withCart from "../../components/hc/withCart";
import { User } from "oidc-client";
import useSecurity from "../../components/hc/useSecurity";

const formatKeys = {
  AU: "Audio",
  BR: "Board",
  BX: "Boxed Set",
  CB: "Comic Book",
  CD: "Compact Disc",
  CS: "Cassette",
  DG: "Paperback",
  DN: "Audio Download",
  EL: "eBook",
  HC: "Hardcover",
  MG: "Multi Media",
  MH: "Display/Promo",
  MM: "Mass Market Paperback",
  NM: "New Media",
  NT: "Non-traditional book",
  TR: "Trade Paperback",
  TW: "Hardcover Library Binding",
  VI: "Video",
};

export const TitleMetadata = withSecurity(
  withRouter(
    withCart((props) => {
      const isMobile = useMediaQuery({
        query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
      });
      const {
        setFormat,
        metadata,
        selectedFormat,
        orderHistory,
        selectedLocation,
        setSelectedLocation,
        locationDropdownItems,
        cart,
        setSeriesHistoryTab,
      } = props;
      // console.log('TitleMetaData', props);
      const matchedISBN = metadata.formats.filter((i) => {
        return selectedFormat.isbnStr === i.isbnStr;
      });
      let ratioVariantReq = 0;
      if (matchedISBN.length > 0 && matchedISBN[0].orderReq > 0) {
        ratioVariantReq = matchedISBN[0].orderReq;
      }
      const contributors =
        selectedFormat.format.code === "CB"
          ? Object.values(selectedFormat?.contributors).filter((cont) =>
              ["I", "45", "36"].includes(cont.roleCode)
            )
          : Object.values(selectedFormat?.contributors);

      const allContributorRolles = [
        ...new Set(contributors.map((cont) => cont.roleName)),
      ];

      const roleRowData = allContributorRolles
        .map((contRole) => {
          return {
            text: contRole,
            names: contributors
              .filter((value) => value.roleName === contRole)
              .map((cont) => ({ authorName: cont.display, authorId: cont.id })),
            roleCode: contributors.filter(
              (value) => value.roleName === contRole
            )[0].roleCode,
          };
        })
        .sort((a, b) => {
          const contributorOrder = ["45", "I", "36"];

          const aContributorIndex = contributorOrder.indexOf(a.roleCode);
          const bContributorIndex = contributorOrder.indexOf(b.roleCode);

          if (aContributorIndex === bContributorIndex) return 0;

          return aContributorIndex - bContributorIndex;
        });

      const renderRoleRow = (roles) => {
        return (
          <div key={roles?.text} className='tm-info-text-style'>
            {roles?.text + ": "}{" "}
            <span>
              {roles.names.map((author, index) => {
                return (
                  <span key={`author_${index}`}>
                    <span>{author.authorName}</span>
                    {roles.names[index + 1] ? ", " : ""}
                  </span>
                );
              })}
            </span>
          </div>
        );
      };

      const coverHover = (isbn) => {
        return (
          <div className='d-flex flex-row'>
            <LazyImage
              className='book-detail-image-mobile'
              boxShadow={false}
              src={`https://images.penguinrandomhouse.com/cover/${isbn}`}
            />
          </div>
        );
      };

      const [isMobileImagePopupOpen, setIsMobileImagePopupOpen] =
        useState(false);

      let shipTo = "all";
      if (props.cart.isUserPow() && props.cart.shipTo >= 0) {
        shipTo = props.cart.getShipToAccount().accountNo;
      }

      return (
        <div style={{ minHeight: "30em" }}>
          <Container
            className={`d-flex flex-column tm-max-width pt-3 ${
              isMobile ? "title-meta-data-mobile" : ""
            }`}
          >
            {(orderHistory?.titleHistory?.titles.length > 0 ||
              orderHistory?.seriesHistory?.titles.length > 0) &&
              !cart.isUserPow() &&
              locationDropdownItems.length > 0 &&
              cart.customerInfo?.currentUser?.roleNo !== "W1" && (
                <div className='tad-shipto-dropdown-container'>
                  <Dropdown
                    options={locationDropdownItems.filter(
                      (o) => o.value !== selectedLocation
                    )}
                    onChange={(_e, { value }) => {
                      setSelectedLocation(value);
                    }}
                    value={selectedLocation}
                    // item={true}
                    // selection
                    className={""}
                    trigger={
                      <div>
                        {selectedLocation === "all"
                          ? "All locations"
                          : `${
                              locationDropdownItems.filter(
                                (o) => o.value === selectedLocation
                              )[0]?.text
                            }`}
                      </div>
                    }
                  />
                </div>
              )}
            <TitleHistorySummary
              selectedLocation={selectedLocation}
              selectedFormat={selectedFormat}
              allFormats={metadata.formats}
              titleHistory={orderHistory?.titleHistory}
            ></TitleHistorySummary>
            <SeriesOrderHistorySummary
              selectedLocation={selectedLocation}
              selectedFormat={selectedFormat}
              allFormats={metadata.formats}
              seriesOrderHistory={orderHistory?.seriesHistory}
              setSeriesHistoryTab={setSeriesHistoryTab}
            ></SeriesOrderHistorySummary>
          </Container>
          <Container
            className={`d-flex tm-max-width ${
              isMobile ? "title-meta-data-mobile" : ""
            }`}
          >
            <div className='tm-image-container'>
              {!isMobile && selectedFormat?.hasCoverImage && (
                <>
                  <LazyImage
                    src={`https://images.penguinrandomhouse.com/cover/${selectedFormat.isbnStr}`}
                    className='tm-image-style'
                    alt={"Book"}
                    altImageSize='large'
                  />
                  <div className='d-flex align-items-center justify-content-center w-100 mt-4'>
                    <a
                      className='text-blue fort-medium fs-12px'
                      href={`https://images.penguinrandomhouse.com/cover/tif/${selectedFormat.isbnStr}`}
                      target='_blank'
                    >
                      <img
                        src={Download}
                        width={12}
                        height={12}
                        alt='#'
                        className='mr-1'
                      />
                      DOWNLOAD HIGH RES IMAGE
                    </a>
                  </div>
                </>
              )}
              {isMobile && selectedFormat?.hasCoverImage && (
                <Popup
                  basic
                  popper={
                    <div
                      className='book-detail-popup-container-mobile'
                      onClick={() => setIsMobileImagePopupOpen(false)}
                    />
                  }
                  size='huge'
                  className='book-detail-image-popup-mobile'
                  content={coverHover(selectedFormat.isbnStr)}
                  on='click'
                  position='top center'
                  wide='very'
                  offset={[0, -350]}
                  flowing={true}
                  open={isMobileImagePopupOpen}
                  onClose={() => setIsMobileImagePopupOpen(false)}
                  onOpen={() => setIsMobileImagePopupOpen(true)}
                  trigger={
                    <LazyImage
                      src={`https://images.penguinrandomhouse.com/cover/${selectedFormat.isbnStr}`}
                      className='tm-image-style'
                      alt={"Book"}
                      altImageSize='large'
                    />
                  }
                  header={
                    <div className='book-detail-image-popup-header'>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className='book-detail-image-popup-header-icon'
                        color='#FA6400'
                        onClick={() => setIsMobileImagePopupOpen(false)}
                      />
                    </div>
                  }
                  style={{
                    padding: 0,
                    borderRadius: 0,
                    border: "none",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                />
              )}
            </div>
            <div className='tm-data-container'>
              <div
                className={`tm-title-row ${
                  isMobile ? "tm-title-row-mobile" : ""
                }`}
              >
                {selectedFormat.series && (
                  <div className='tm-series-text-style'>
                    {selectedFormat.series.title}
                  </div>
                )}
                <div className={`tm-title-text`}>
                  {selectedFormat.title}
                  {ratioVariantReq > 0 ? `[1:${ratioVariantReq}]` : ""}
                </div>
                <div className='tm-subtitle-text'>
                  {selectedFormat.subtitle}
                </div>
              </div>
              <div className='tm-data-row'>
                <div className='tm-data-left'>
                  {roleRowData && (
                    <div
                      className={`tm-data-left-segment ${
                        isMobile ? "mb-0" : ""
                      }`}
                    >
                      {roleRowData.map(renderRoleRow)}
                    </div>
                  )}
                  {!isMobile && metadata?.formats?.length > 1 && (
                    <FormatsDropdown
                      format={selectedFormat}
                      formats={metadata.formats}
                      formatKeys={formatKeys}
                      setFormat={setFormat}
                    />
                  )}
                  <div className='tm-data-left-segment hidden-on-mobile'>
                    <TitleMetadataContainer
                      format={selectedFormat}
                      formatKeys={formatKeys}
                    />
                  </div>
                </div>
                <div className='tm-data-right hidden-on-mobile'>
                  <AddToCartContainer
                    formatKeys={formatKeys}
                    format={selectedFormat}
                    formats={metadata.formats}
                    itemInCart={selectedFormat?.cartQuantity}
                    confirmationStatus={selectedFormat?.confirmationStatus}
                  />
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className='shown-on-mobile ml-2 mr-2'>
              <AddToCartContainer
                formatKeys={formatKeys}
                format={selectedFormat}
                formats={metadata.formats}
                itemInCart={selectedFormat?.cartQuantity}
                confirmationStatus={selectedFormat?.confirmationStatus}
              />
              {metadata?.formats?.length > 1 && (
                <FormatsDropdown
                  format={selectedFormat}
                  formats={metadata.formats}
                  formatKeys={formatKeys}
                  setFormat={setFormat}
                />
              )}
              <div className='tm-data-left-segment'>
                <TitleMetadataContainer
                  format={selectedFormat}
                  formatKeys={formatKeys}
                />
              </div>
            </div>
          </Container>
        </div>
      );
    })
  )
);
