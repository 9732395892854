import { Component } from "react";
import withApp from "../../../components/hc/withApp";
import withDirty from "../../../shared-react-components/components/hc/withDirty";
import withCart from "../../../components/hc/withCart";
import { Divider } from "../../../components/elements/elements";
import PRHButton from "../../../shared-react-components/elements/prhbutton";
import { PRHContainer } from "../../../shared-react-components/elements/elements";
import { Icon, Form, Radio } from "semantic-ui-react";
import { isIE } from "react-device-detect";

// import 'srcdoc-polyfill';
import * as srcDoc from "srcdoc-polyfill";
import CreditCardList from "../../../components/CreditCard/CreditCardList";
class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentForm: null,
      sessionTimedOut: false,
      creditCards: [],
    };

    // window.addEventListener(
    //   'message',
    //   (event) => {
    //     // console.log('Message Received', event);
    //     //  e.data has the response string passed by the gateway ( CC_INPUT_VALIDATION_FAILED or SAMEBILLSHIPADDRESS_CHECKED or SAMEBILLSHIPADDRESS_UNCHECKED)
    //     if (event.data === 'YOUR_PAYMENT') {
    //       // console.log('setting paymentCCPreAuth to true');
    //       // this.props.setValue('paymentCCPreAuth', true);
    //       this.props.getCCInfo();
    //       // $(‘#your_pay_new’).click();
    //     }
    //     if (event.data === 'SAMEBILLSHIPADDRESS_UNCHECKED') {
    //       this.props.setValue('paymentUseBillingAddress', true);
    //       // this.setState({ ccIframeClass: 'hri-billing-address' });
    //       // if(mobile == ‘false’){
    //       //     $(‘#externalSite’).css(‘height’, ‘1000px’);
    //       //     //$(‘#externalSite’).css(‘height’, ‘1200px’);
    //       // }
    //       // else{
    //       //     $(‘#externalSite’).css(‘height’, ‘1500px’);
    //       // }
    //       // same_billing = false;
    //     }
    //     if (event.data === 'SAMEBILLSHIPADDRESS_CHECKED') {
    //       this.props.setValue('paymentUseBillingAddress', false);
    //       // this.setState({ ccIframeClass: '' });
    //       // if(mobile == ‘false’){
    //       //     $(‘#externalSite’).css(‘height’, ‘1000px’);
    //       // }
    //       // else{
    //       //     $(‘#externalSite’).css(‘height’, ‘1000px’);
    //       // }
    //       // same_billing = true;
    //     }
    //     if (event.data === 'CC_INPUT_VALIDATION_FAILED') {
    //       this.setState({ ccIframeClass: 'hri-validation-failed' });
    //       // invalid_card_count++;
    //       // //if credit card preauth fails 5 times, delete the cart & redirect user to PRH home page
    //       // if(invalid_card_count >= 5){
    //       //     window.location = “/prhcart/clear-cart.php”;
    //       // }
    //       // validation_error = true;
    //       //   if(same_billing == true){
    //       //       if(mobile == ‘false’){
    //       //           $(‘#externalSite’).css(‘height’, ‘1000px’);
    //       //       }
    //       //       else{
    //       //           $(‘#externalSite’).css(‘height’, ‘1000px’);
    //       //       }
    //       // }
    //       // else{
    //       //     if(mobile == ‘false’){
    //       //         $(‘#externalSite’).css(‘height’, ‘1300px’);
    //       //     }
    //       //     else{
    //       //         $(‘#externalSite’).css(‘height’, ‘1500px’);
    //       //     }
    //       // }
    //     }
    //     if (event.data === 'BACK_BUTTON_CLICKED') {
    //       // payment_panel_back();
    //     }
    //     if (event.data === 'SESSION_TIMED_OUT') {
    //       this.setState({ sessionTimedOut: true });
    //     }
    //   },
    //   false
    // );
  }

  componentDidMount() {
    const { paymentCC, isCashOnly } = this.props;

    if (isCashOnly && !paymentCC) {
      this.props.setValue("paymentCC", true);
      // this.getHRForm();
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // console.log('CWU Payment', this.props, nextProps, this.state, nextState);
  }

  render() {
    // console.log("Payment render", this.props, this.state);
    const { shipToAddress, paymentCC, isCashOnly } = this.props;

    const { paymentForm, paymentCCErrorOpen } = this.state;

    // let ccIframeClass = '';

    // if (paymentUseBillingAddress) {
    //   ccIframeClass = 'hri-billing-address';
    // }

    return (
      <>
        <PRHContainer className='cp-left content-block-border w-100 m-0 pl-0 pr-0 mr-6 mb-3 pt-3'>
          <div className='d-flex  ml-3 mr-3  align-items-center mb-1'>
            <div className='fort-bold mb-2 fs-2 align-items-center'>
              <span className='cp-section-number fs-3'>3</span>PAYMENT
            </div>
          </div>
          <Divider tight />
          <div className='d-flex flex-row mt-3 ml-3 mr-3'>
            {!isCashOnly ? (
              <div
                className='d-flex flex-column align-items-center'
                style={{ width: "49%" }}
              >
                <div
                  className={`cp-payment ${
                    paymentCC === false ? "active" : ""
                  }`}
                  onClick={(e) => {
                    this.props.setValue({
                      paymentCC: false,
                      paymentCreditCard: false,
                    });
                    this.setState({ paymentForm: null });
                  }}
                >
                  <div className='d-flex '>
                    <div className='mr-1'>
                      <Form.Field>
                        <Radio
                          name='paymentCC'
                          value='false'
                          checked={!paymentCC}
                          onChange={(e, d) => {
                            this.props.setValue({
                              paymentCC: false,
                              paymentCreditCard: false,
                            });
                            this.setState({ paymentForm: null });
                          }}
                          //   onChange={this.handleChangeAddress}
                          // checked={this.state.value === 'that'}
                          // onChange={this.handleChange}
                        />
                      </Form.Field>
                    </div>
                    <div>
                      <div className='fort-bold'>Bill to My Account</div>
                      <div>
                        {/* {shipToAddress >= 0 &&
                        this.props.cart.customerInfo.shipTos.length > 0
                          ? this.props.cart.customerInfo.shipTos[shipToAddress]
                              .address.name1
                          : ''} */}
                        {this.props.cart.customerInfo.customer?.address
                          ?.name1 &&
                          this.props.cart.customerInfo.customer.address.name1}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div
              className='d-flex flex-column align-items-center'
              style={{ width: "2rem" }}
            ></div>
            <div
              className='d-flex flex-column align-items-center'
              style={{ width: "49%" }}
            >
              <div
                className={`cp-payment ${isIE ? "IE" : ""} ${
                  paymentCC === true ? "active" : ""
                }`}
                onClick={(e) => {
                  this.props.setValue("paymentCC", true);
                }}
              >
                <div className='d-flex'>
                  <div className='mr-1'>
                    <Form.Field>
                      <Radio
                        disabled={isIE}
                        name='paymentCC'
                        value='true'
                        checked={paymentCC}
                        onChange={(e, d) => {
                          this.props.setValue("paymentCC", d.value === "true");
                          // this.getHRForm(true);
                        }}
                      />
                    </Form.Field>
                  </div>

                  <div className='d-flex flex-row'>
                    <div className='fort-bold'>Pay by Credit Card</div>
                  </div>
                </div>
              </div>
              {isIE ? (
                <div
                  className='d-flex flex-row mt-2'
                  style={{ marginLeft: "5rem", marginRight: "5rem" }}
                >
                  <div className='mr-1'>
                    <Icon
                      name='exclamation'
                      className='cs-warning text-white bg-orange'
                    ></Icon>
                  </div>
                  <div
                    className='fort-light lh-3'
                    style={{ fontWeight: "600" }}
                  >
                    Payment with Credit Card is not supported on your browser.
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {paymentCC === true ? (
            <CreditCardList
              paymentCreditCard={this.props.paymentCreditCard}
              setValue={this.props.setValue}
              foc={this.props.foc}
              shipTo={
                this.props.cart.isUserPow()
                  ? this.props.shipTo
                  : this.props.cart.customerInfo.shipTos[this.props.cart.shipTo]
                      .accountNo
              }
            ></CreditCardList>
          ) : null}
        </PRHContainer>
      </>
    );
  }

  getHRForm = (loadIE = false) => {
    this.props.setValue("paymentCC", true);
  };

  ccFormOnload = (e) => {
    if (isIE) {
    }
  };
}

export default withDirty(withApp(withCart(Payment)));
