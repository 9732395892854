import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Icon } from "semantic-ui-react";
import { Divider } from "../../../shared-react-components/elements/elements";
import LazyImage from "../../../utils/LazyImage";
import { formatDate, formatDateMMDDYYYY } from "../../../utils/utilities";
import "./DeliveryCarton.scss";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH, TABLET_SCREEN_WIDTH } from "../../../utils/const";
import useDetectPrint from "use-detect-print";

function DeliveryCarton(props) {
  const { carton, cartonIndex, cartonTotal } = props;
  const [colapsed, setColapsed] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const isTablet = useMediaQuery({
    query: `(max-width: ${TABLET_SCREEN_WIDTH}px)`,
  });
  const isPrinting = useDetectPrint();

  const CartonItem = (props) => {
    const { item } = props;

    return (
      <div className={"carton-item ml-2 m-2"}>
        <div className='d-flex flex-row'>
          <div className='carton-item-cover d-flex'>
            <LazyImage
              className={`carton-title-cover ${isMobile ? "mobile" : ""}`}
              //   alt={`Cover: ${title}`}
              boxShadow={true}
              src={`https://images.penguinrandomhouse.com/cover/${item.isbn}`}
            />
          </div>

          <Grid className='w-100'>
            <Grid.Row>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={14}
                largeScreen={14}
                widescreen={14}
              >
                <div className='d-flex flex-column fs-13px lh-16px fort-book'>
                  <div className='fort-bold mb-dot3rem'>
                    <Link to={`/titleinfo/${item.isbn}`}>{item.title}</Link>
                  </div>
                  <div className='mb-dot3rem'>By {item.author}</div>
                  <div className='mb-dot3rem'>{item.isbn}</div>
                  <div>
                    {item.format} <span style={{ color: "#aaaa" }}>|</span>{" "}
                    {formatDate(item.onsale, false)}
                  </div>
                  {isMobile && (
                    <div
                      className={`fs-13px lh-16px mt-1 mt-lg-0 d-flex justify-content-lg-end w-100 ${
                        isTablet ? "" : "fort-bold"
                      }`}
                      // style={isMobile ? { marginTop: "-10px" } : {}}
                    >
                      Qty: {item.qty}
                    </div>
                  )}
                </div>
              </Grid.Column>
              {!isMobile && (
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={2}
                  largeScreen={2}
                  widescreen={2}
                  className='d-flex justify-content-xlend'
                >
                  <div
                    className={`fs-13px lh-16px mt-1 mt-lg-0 d-flex justify-content-lg-end w-100 ${
                      isTablet ? "" : "fort-bold"
                    }`}
                    // style={isMobile ? { marginTop: "-10px" } : {}}
                  >
                    Qty: {item.qty}
                  </div>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
          {/* <div className='d-flex flex-wrap w-100'>
            <div className={`d-flex ${isMobile ? "w-100" : ""}`}>
              <div className='d-flex flex-column fs-13px lh-16px fort-book'>
                <div className='fort-bold mb-1'>{item.title}</div>
                <div className='mb-1'>By {item.author}</div>
                <div className='mb-1'>{item.isbn}</div>
                <div>
                  {item.format} <span style={{ color: "#aaaa" }}>|</span>{" "}
                  {formatDate(item.onsale, false)}
                </div>
              </div>
            </div>
            <div
              className={`fs-13px lh-16px ${
                isMobile ? "" : "ml-auto fort-bold "
              }`}
              // style={isMobile ? { marginTop: "-10px" } : {}}
            >
              Qty: {item.qty}
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  const CartonHeader = (props) => {
    return (
      <div className={`carton-header ${colapsed ? "colapsed" : ""}`}>
        <div className='d-flex flex-row fs-13px fort-book lh-16px text-gray-700'>
          <Icon
            onClick={() => setColapsed(!colapsed)}
            className='carton-item-toggle fs-24px'
            style={{
              top: `${colapsed ? "10px" : "10px"}`,
              position: "relative",
            }}
            color='grey'
            name={`angle ${colapsed ? "right" : "down"}`}
          />
          <div className='d-flex flex-column ml-3'>
            <div>Carton</div>
            <div className='fs-14px fort-medium lh-18px'>
              {cartonIndex} of {cartonTotal}
            </div>
          </div>
          <div className='d-flex flex-column ml-3'>
            <div>Shipped on</div>
            <div className='fs-14px fort-medium lh-18px'>
              {formatDate(carton.shipDate, false)}
            </div>
          </div>
          <div className='d-flex flex-column ml-3'>
            <div>
              Items <span style={{ color: "#aaaa" }}>|</span> Units
            </div>
            <div className='fs-14px fort-medium lh-18px'>
              {carton.itemCt} <span style={{ color: "#aaaa" }}>|</span>{" "}
              {carton.unitCt}
            </div>
          </div>
          <div className='d-flex flex-column ml-3'>
            <div>Tracking Number</div>
            {carton.trackingCode ? (
              <a
                target='_blank'
                className='carton-tracking-link fs-14px fort-medium lh-18px'
                href={carton.trackingLink}
              >
                {carton.shipper} {carton.trackingCode}
                <Icon name='external alternate' />
              </a>
            ) : (
              <>Tracking link unavailable</>
            )}
          </div>
        </div>
      </div>
    );
  };

  const CartonHeaderPrint = (props) => {
    return (
      <div className={`carton-header`}>
        <div className='d-flex flex-row fs-14px lh-16px'>
          <div className={`d-flex flex-column`}>
            <div className='fort-bold'>Carton</div>
            <div className='fs-13px lh-18px'>
              {cartonIndex} of {cartonTotal}
            </div>
          </div>
          <div className='d-flex flex-column ml-3'>
            <div className='fort-bold'>Shipped on</div>
            <div className='fs-13px lh-18px'>
              {formatDate(carton.shipDate, false)}
            </div>
          </div>
          <div className='d-flex flex-column ml-3'>
            <div className='fort-bold'>
              Items <span style={{ color: "#aaaa" }}>|</span> Units
            </div>
            <div className='fs-13px lh-18px'>
              {carton.itemCt} <span style={{ color: "#aaaa" }}>|</span>{" "}
              {carton.unitCt}
            </div>
          </div>
          <div className='d-flex flex-column ml-3'>
            <div className='fort-bold'>Tracking Number</div>
            <div>
              {carton.shipper} {carton.trackingCode}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CartonHeaderMobile = (props) => {
    return (
      <div className={`carton-header ${colapsed ? "colapsed" : ""}`}>
        <div className='d-flex flex-row fs-16px lh-16px text-gray-700 justify-content-between'>
          <div className='d-flex flex-row'>
            <div className='mr-1'>Carton</div>
            <div className='fs-13px lh-18px'>
              {cartonIndex} of {cartonTotal}
            </div>
          </div>
          <Icon
            onClick={() => setColapsed(!colapsed)}
            className='carton-item-toggle fs-24px'
            style={{
              // top: `${colapsed ? "10px" : "10px"}`,
              position: "relative",
            }}
            color='grey'
            name={`angle ${colapsed ? "right" : "down"}`}
          />
        </div>
        <Divider tight className='mb-1' />
        <div className='d-flex flex-column fs-12px lh-16px text-gray-700'>
          <div className='d-flex flex-row'>
            <div className='mr-1'>Shipped on: </div>
            <div className=''>{formatDate(carton.shipDate, false)}</div>
          </div>
          <div className='d-flex flex-row '>
            <div className=''>
              {carton.itemCt} items <span style={{ color: "#aaaa" }}> |</span>{" "}
              {carton.unitCt} Units
            </div>
          </div>
          <div className='d-flex flex-row '>
            {carton.trackingCode ? (
              <a
                target='_blank'
                className='carton-tracking-link fs-13px lh-18px'
                href={carton.trackingLink}
              >
                {carton.shipper} {carton.trackingCode}
                <Icon name='external alternate' />
              </a>
            ) : (
              <>Tracking link unavailable</>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='carton-container'>
      {isPrinting ? (
        <CartonHeaderPrint />
      ) : isMobile ? (
        <CartonHeaderMobile />
      ) : (
        <CartonHeader />
      )}
      <div className={`carton-item-body ${colapsed ? "colapsed" : ""}`}>
        {carton.items.map((item, idx) => (
          <CartonItem key={idx} item={item} />
        ))}
      </div>
    </div>
  );
}

export default DeliveryCarton;
