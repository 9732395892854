import { decode } from 'html-entities';
import { useState } from 'react';
import withApp from '../../../components/hc/withApp';
import { getPrettyName } from '../../../utils/wordpressHelpers';
import Pagination from './Pagination';
import PostDigestHorizontal from './PostDigestHorizontal';

const PostsList = ({
  postType,
  posts,
  page,
  lastPage,
  category,
  searchQuery,
  totalResults,
}) => {
  const [actualPosts, setActualPosts] = useState(posts);
  const [loading, setLoading] = useState(false);
  const [actualTotalResults] = useState(
    !isNaN(parseInt(totalResults, 10)) ? parseInt(totalResults, 10) : 0
  );
  if (!Array.isArray(posts)) {
    return <p>Error</p>;
  }

  const addPosts = (posts) => {
    console.log('get actual posts', actualPosts.concat(posts));
    setActualPosts(actualPosts.concat(posts));
  };

  let pageTitle;

  if (!!searchQuery) {
    pageTitle = `Search for "${decode(searchQuery)}" ${
      !!category ? `tagged with "${decode(category.name)}"` : ``
    } returned ${actualTotalResults} ${
      actualTotalResults === 1
        ? getPrettyName(postType).singular
        : getPrettyName(postType).plural
    }`;
  } else {
    pageTitle = `All ${getPrettyName(postType).plural} ${
      !!category ? `tagged with "${decode(category.name)}"` : ``
    }`;
  }

  const output =
    actualTotalResults === 0 ? (
      <p className="mt-5 text-center">
        No {getPrettyName(postType).plural.toLowerCase()} found
      </p>
    ) : (
      actualPosts.map((post) => {
        return (
          <PostDigestHorizontal
            key={post.id}
            post={post}
            currentTitle={pageTitle}
          />
        );
      })
    );

  const style = loading ? { opacity: 0.5 } : {};

  return (
    <section style={style} className="mb-4">
      <p className="fort-light fs-24px lh-28px lsp--05 text-gray-900 pb-3 border-bottom-gray-500">
        {pageTitle} 
      </p>
      {output}
      {console.log('actualTotalResults', actualTotalResults)}
      {actualTotalResults > 0 && (
        <Pagination
          category={category}
          postType={postType}
          page={page}
          lastPage={lastPage}
          addPosts={addPosts}
          setLoading={setLoading}
        />
      )}
    </section>
  );
};

PostsList.defaultProps = {
  category: '',
};

export default withApp(PostsList);
