import { Component } from 'react';
import { Table, Header, Image } from 'semantic-ui-react';
import ManageUserForm from './ManageUserForm';
import withSecurity from '../hc/withSecurity';
import { withRouter } from 'react-router';
import UserList from './UserList';
import {
  PRHButton,
  Biz2Modal,
  PRHContainerGeneral,
  PRHContainerHeader,
} from '../elements/elements';
import './admin.scss';

class Notifications extends Component {
    constructor(props) {
      super(props);
      this.state = {
        modalOpen :false
      };
    }
    
    modalControl(e){
      this.setState({modalOpen: e});
    }
    

  modalControl(e) {
    this.setState({ modalOpen: e });
  }

  render() {
    const { admin } = this.props;
    const { modalOpen } = this.state;
    return (
      <>
        <PRHContainerGeneral className="content-block-border">
          <div className={'header-block'}>
            <PRHContainerHeader>Notifications</PRHContainerHeader>
          </div>
        </PRHContainerGeneral>
      </>
    );
  }
}

export default withRouter(withSecurity(Notifications));
