import { useState, useEffect } from "react";
import { Header, Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./MultiAddISBNs.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import useApp from "../hc/useApp";
import LazyImage from "../../utils/LazyImage";

const MultiAddISBNsSuccess = (props) => {
  const { data, open, setOpen, callback, cancelCallback } = props;
  // const [open, setOpen] = useState(false);

  let realTrigger = (
    <div className='d-flex flex-column fort-book-italic fs-13px lh-18 hover-underline text-blue cursor-pointer align-self-end'>
      <div
        onClick={(e) => {
          setOpen(true);
        }}
        className='v-details'
      ></div>
    </div>
  );

  const resetState = () => {
    setOpen(false);
  };

  const okStayInCart = {
    label: "Stay in catalog",
    // color: 'blue',
    // backgroundColorOverride: 'blue',
    // backgroundHoverColorOverride: 'light-blue',
    // textColorOverride: 'white',
    // borderHoverColorOverride: 'light-blue',
    // iconColorOverride: 'white',
    // iconColorHoverOverride: 'light-blue',
    // icon: 'checkmark',
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      if (cancelCallback) cancelCallback(true);
      resetState();
      return { proceed: true };
    },
  };

  const okGoToCarts = {
    label: "Go to your carts",
    color: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "light-blue",
    textColorOverride: "white",
    borderHoverColorOverride: "light-blue",
    iconColorOverride: "white",
    iconColorHoverOverride: "light-blue",
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      if (callback) callback();
      resetState();
      return { proceed: true };
    },
  };

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      modalClassName='vri-modal masuccess-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            Successfully Added to Your Carts
          </Header>
        ),
        actions: [okStayInCart, okGoToCarts],
      }}
      options={{
        trigger: realTrigger,
      }}
    >
      <div className='ar-box'>
        {data &&
          Array.isArray(data) &&
          _.sortBy(data, function (i) {
            return i.foc;
          })
            .reverse()
            .map((item, i) => (
              <div
                key={i}
                className='d-flex mr-4 ml-4 fs-13px lh-15px fort-book mb-1'
              >
                <div className='fort-bold mr-1 mb-1'>
                  {item.foc === "2099-12-31"
                    ? "General Cart"
                    : `FOC ${formatDateMMDDYYYY(item.foc, "/")}`}
                  :
                </div>
                <div>
                  {item.itemOrdered} items / {item.unitsOrdered} units added
                </div>
              </div>
            ))}
      </div>
    </DialogModal>
  );
};

MultiAddISBNsSuccess.propTypes = {};

MultiAddISBNsSuccess.defaultProps = {};

export default MultiAddISBNsSuccess;
