import { Component } from 'react';
import './elements.scss';

class PageStandard extends Component {
    render() {
        const { children } = this.props;
        return (
      <>
        <div className={'white-background-container'}>
            {children}
        </div>
      </>
    );
}
}

export default PageStandard;
