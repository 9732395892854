import { cloneElement, useState, useEffect, isValidElement } from "react";
import { Header, Form, TextArea, Icon, Popup, Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import withApp from "../hc/withApp";
import withCart from "../hc/withCart";
import "./AddISBNModal.scss";
import { useCleanISBNsMutation } from "../../api/ecomApi";

const AddISBNModal = ({
  app,
  cart,
  refresh = false,
  trigger,
  listName,
  listID,
  addISBNsToList,
  openState,
  className,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [validIsbnList, setValidIsbnList] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [helpScreen, setHelpScreen] = useState(false);
  const [performCleansIsbn, cleanIsbnResult] = useCleanISBNsMutation();

  const isAnyValid = () => {
    if (!isbnList) return false;
    if (!validIsbnList) return false;
    if (validIsbnList.length === 0) return false;
    const nv = validIsbnList.filter((il) => il.available);
    // console.log('nv', nv);
    if (nv.length === 0) return false;
    return true;
  };
  let realTrigger = trigger;
  if (!trigger) {
    realTrigger = (
      <PRHButton
        disabled={disabled}
        size='small'
        style={{ minWidth: "125px", height: "38px" }}
        className={className}
        onClick={() => {
          setOpen(true);
          if (openState) {
            openState(true);
          }
        }}
        icon='plus'
        // borderColorOverride="blue"
        // textColorOverride="white"
        // textColorHoverOverride="blue"
        // backgroundColorOverride="blue"
        // backgroundHoverColorOverride="white"
        // iconColorHoverOverride="blue"
        // iconColorOverride={disabled ? 'grey' : 'white'}
      >
        Add ISBNs
      </PRHButton>
    );
  } else {
    realTrigger = cloneElement(realTrigger, {
      onClick: () => setOpen(true),
    });
  }

  const [isbnList, setIsbnList] = useState("");
  const resetState = () => {
    setIsbnList("");
    setValidIsbnList(false);
    setHelpScreen(false);
  };

  const checkIsbnAction = {
    label: "Check ISBNs",
    color: "green",
    icon: "check",
    borderColorOverride: "blue",
    textColorOverride: "white",
    textColorHoverOverride: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "white",
    iconColorHoverOverride: "blue",
    iconColorOverride: !isbnList ? "grey" : "white",
    className: "ml-auto",
    disabled: !isbnList,
    preAction: () => cleanISBNListInternal(),
    action: () => {
      resetState();
      return { proceed: true };
    },
  };

  const backFromHelp = {
    label: "Back to enter ISBNs",
    color: "orange",
    icon: "arrow left",
    className: "mr-auto",
    preAction: () => {
      setHelpScreen(false);
      return { proceed: false };
    },
    action: () => {
      return { proceed: true };
    },
  };

  const backToCheckIsbn = {
    label: "Back",
    color: "orange",
    icon: "arrow left",
    disabled: !isbnList,
    className: "mr-auto",
    preAction: () => {
      setValidIsbnList(false);
      return { proceed: false };
    },
    action: () => {
      resetState();
      return { proceed: true };
    },
  };
  async function addIsbnsToCart() {
    // console.log('add isbns to cart logic', validIsbnList);
    let titles = [];

    validIsbnList.forEach((item) => {
      // console.log('vi', item);
      if (item.available) {
        let qty = item.quantity;
        // console.log('qty', qty);
        titles.push({ isbn: item.isbn, quantity: item.quantity });
      }
    });
    if (cart.cartBadgeOpen) {
      cart.setCartBadgeOpen(false);
    }
    const x = cart.addItems(titles, false, true);

    // console.log('x', x);

    // if (r.status === 200) {
    return { proceed: true };
    // } else {
    //   setErrorMsg('An error occured updating your cart.');
    //   return { proceed: false };
    // }
  }
  async function cleanISBNList() {
    // console.log('cleanISBNList');
    const r = await performCleansIsbn({
      data: isbnList,
      sapAccountNo: cart.ccSapAccountNo,
    });
    // console.log('r', r);
    if (r.data?.status === "OK") {
      setValidIsbnList(r.data.data.items);
    } else {
      setValidIsbnList(false);
    }
    // const { titles } = r.data.search_results;
    // addISBNsToList(titles);
    // if (r.data.status_code === -1) {
    //   return {
    //     proceed: false,
    //     message: { type: 'error', label: r.data.error_msg },
    //   };
    //   // setErrorMessage(r.data.error_msg);
    // }
    // if (refresh) {
    //   // await refresh();
    // }
    // setValidIsbnList(true);

    return { proceed: false };
  }

  const cleanISBNListInternal = () => cleanISBNList();

  useEffect(() => {
    if (!open) {
      resetState();
    }
    if (openState) {
      openState(open);
    }
  }, [open]);

  let totalValidISBNs = 0;

  const addIsbnsToCartAction = (totalValidISBNsProp) => {
    return {
      label: `Add ${totalValidISBNsProp} ISBNs to Cart`,
      borderColorOverride: "blue",
      textColorOverride: "white",
      textColorHoverOverride: "blue",
      backgroundColorOverride: "blue",
      backgroundHoverColorOverride: "white",
      iconColorOverride: !isAnyValid() ? "grey" : "white",
      iconColorHoverOverride: "blue",
      color: "orange",
      icon: "plus",
      // className: 'mr-auto ml-auto',
      disabled: !isAnyValid(),
      preAction: () => addIsbnsToCart(),
      action: () => {
        resetState();
        return { proceed: true };
      },
    };
  };

  let actionList = [];
  if (helpScreen) {
    actionList.push(backFromHelp);
  } else if (validIsbnList === false) {
    actionList.push(checkIsbnAction);
  } else {
    validIsbnList.map((item) => {
      if (item.available) {
        totalValidISBNs++;
      }
    });
    actionList.push(backToCheckIsbn);
    actionList.push(addIsbnsToCartAction(totalValidISBNs));
  }
  return (
    <DialogModal
      open={open}
      modalClassName='addisbn-modal'
      setOpen={setOpen}
      contentPx={validIsbnList ? "px-0" : "px-4"}
      contentPt='pt-2'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-bold fs-2 lh-2 my-0 mr-5'>
            ADD ISBNS TO CART
          </Header>
        ),
        actions: actionList,
      }}
      options={{
        size: "large",
        trigger: realTrigger,
      }}
    >
      {validIsbnList ? (
        <div>
          <div className='px-4 mb-2'>
            <span className='fort-bold'>
              {totalValidISBNs} of {validIsbnList.length}
            </span>{" "}
            ISBNs are valid and can be added to your Carts
          </div>
          {errorMsg ? <div className='text-red'>{errorMsg}</div> : null}
          <div className='px-0 aim-isbn-table'>
            <Table celled padded>
              <Table.Header>
                <Table.Row className='aim-isbn-header'>
                  <Table.HeaderCell>ISBN</Table.HeaderCell>
                  <Table.HeaderCell>TITLE</Table.HeaderCell>
                  <Table.HeaderCell>QTY</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {validIsbnList.map((item, idx) => (
                  <Table.Row
                    key={idx}
                    className={`${item.available === false ? "row-error" : ""}`}
                  >
                    <Table.Cell style={{ width: "220px" }}>
                      {item.available === false ? (
                        <div
                          style={{ marginRight: "5px" }}
                          className='aim-icon-holder invalid'
                        >
                          <Icon name='warning sign'></Icon>
                        </div>
                      ) : (
                        <div
                          style={{ marginRight: "10px" }}
                          className='aim-icon-holder valid'
                        >
                          <Icon name='check'></Icon>
                        </div>
                      )}

                      {item.isbn}
                    </Table.Cell>
                    <Table.Cell>
                      <div className='text-gray-900 fort-medium'>
                        {item.title}
                      </div>
                      <div>{item.available ? null : item.status}</div>
                    </Table.Cell>
                    <Table.Cell style={{ textAlign: "center" }}>
                      {item.quantity ? item.quantity : ""}
                    </Table.Cell>
                  </Table.Row>
                ))}

                {/* <Table.Row className="text-red">
                <Table.Cell>
                  <div className="aim-icon-holder invalid">
                    <Icon name="warning sign"></Icon>
                  </div>
                  9780307956036
                </Table.Cell>
                <Table.Cell>Invalid ISBN</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row> */}
              </Table.Body>
            </Table>
          </div>
        </div>
      ) : (
        <>
          {helpScreen ? (
            <div>
              <div>
                <div className='mb-2'>
                  You can import ISBN-13's. ISBNs can have dashes in them.
                </div>
                <div className='fort-bold mb-1'>
                  Copy and pasting from excel
                </div>
                <div className='mb-2'>
                  You can copy and paste a column of ISBNs, or to adjacent
                  columns of ISBNs and quantities
                </div>
                <div className='fort-bold mb-1'>
                  ISBNs without quantity can be separated by line breaks:
                </div>
                <div className='mb-1'>
                  Example 1 (ISBN separated by line breaks):
                  <div className='mr-1 mt-1'>ISBN</div>
                  <div className='mr-1'>ISBN</div>
                  <div className='mr-1 mb-1'>ISBN</div>
                </div>
              </div>
              <div className='mb-1'>
                Example 2 (ISBN with quantity separated by line breaks):
                <div className='mr-1 mt-1'>ISBN,10</div>
                <div className='mr-1'>ISBN,200</div>
                <div className='mr-1 mb-1'>ISBN,4000</div>
              </div>
            </div>
          ) : (
            <>
              <Form>
                <div className='mb-1 modal-content--note isbn-description-text'>
                  Enter or paste one or more ISBNs below. You may include
                  quantity per ISBN.{" "}
                </div>
                <TextArea
                  autoFocus
                  value={isbnList}
                  placeholder='Type or paste one or more ISBNs'
                  className={` aim-text-area fort-book mb-1`}
                  // ${
                  //   isbnList
                  //     ? 'modal-content--textarea has-text'
                  //     : 'modal-content--textarea no-text'
                  // } aim-text-area

                  rows={10}
                  onChange={(e) => setIsbnList(e.target.value)}
                />
              </Form>
              <div>
                <div
                  className='cursor-pointer text-blue hover-orange how-to-paste-button '
                  onClick={(e) => setHelpScreen(true)}
                >
                  How do I paste in ISBNs?
                </div>
              </div>
            </>
          )}
        </>
      )}
    </DialogModal>
  );
};

AddISBNModal.propTypes = {
  app: PropTypes.object.isRequired,
  refresh: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  trigger: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

AddISBNModal.defaultProps = {
  refresh: false,
  trigger: false,
};

export default withApp(withCart(AddISBNModal));
