import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoadingView from "../../pages/loading/LoadingView";
import useSecurity from "../hc/useSecurity";

export const Callback = (props) => {
  const user = useSecurity();
  const history = useHistory();
  useEffect(() => {
    //localStorage.setItem('SSO', window.location);
    user
      .signinRedirectCallback()
      .then((user) => {
        const redirectUri = localStorage.getItem("redirectUri");
        console.log("directing to ", redirectUri);
        localStorage.removeItem("redirectUri");
        redirectUriSend(redirectUri || "/");
      })
      .catch((error) => {
        window.location.href = "/";
      });
  }, []);

  const redirectUriSend = (uri, state = "") => {
    const url = uri.split("?");
    history.push({ pathname: url[0], search: url[1], state: state });
  };

  return <LoadingView></LoadingView>;
};
