import React, { useState, useEffect } from 'react';
import {
  Icon,
  Loader,
  Form,
  Radio
} from 'semantic-ui-react';
import LazyImage from '../../../utils/LazyImage';
import DialogModal from '../DialogModal';
import { Divider } from '../../../components/elements/elements';
import PRHButton from '../../../shared-react-components/elements/prhbutton';
import {
  isCreditCardExpired,
  willCreditCardExpire,
  isGeneralCart,
} from '../../../utils/utilities';
import './AffidavitReturnsChooseCC.scss';


function AffidavitReturnsChooseCC(props) {
  const {
    open,
    setOpen,
    callback,
    cancelCallback,
    onClose,
    creditCardList,
    handleCCChange,
    returnCreditCard,
    submitReturn,
    ccButtonLabel,
    ccModalMaxHeight,
    afdDate = 'November 28,2022',
    afdTime = '11:59 PM',
  } = props;
  return (
    <div className="returns-global">
    <DialogModal
      open={open}
      //size={"large"}
      onClose={onClose}
      setOpen={setOpen}
      contentPx={"px-0"}
      contentPt={"pt-4"}
      modalContentClass={"modal-content-override"}
      modalClassName="return-confirmcart-popup"
      //style={{ maxWidth: '560px'}}
      
      dialogOptions={{
        header: 'CHOOSE CREDIT CARD',
        actions: [
          {
            label: 'Cancel',
            color: 'white',
            action: () => {
              onClose();
              if (cancelCallback) cancelCallback();
              return { proceed: true };
            },
          },
          {
            label: ccButtonLabel,
            icon: !returnCreditCard ? '' : 'check',
            borderColorOverride: 'blue',
            textColorOverride: 'white',
            textColorHoverOverride: 'blue',
            backgroundColorOverride: 'blue',
            backgroundHoverColorOverride: 'white',
            iconColorOverride: 'white',
            iconColorHoverOverride: 'blue',
            disabled: !returnCreditCard,
            action: ()=>{submitReturn()},
            preAction: () => {
              if (callback) callback();
              return { proceed: true };
            },
            action: () => {
              submitReturn();
              return { proceed: true };
            },
          },
        ],
      }}
    >
      
      <div className="d-flex flex-column">
      <div className="px-2 pb-2">
      <b>Please choose the credit card used for the payment for your order.</b> If that card is no longer available, you may choose another to receive your credit.
      </div>
      <div className="ccv-container">
        
        <Form>
          <Divider tight />
         
          {!creditCardList && <Loader active />}
          {creditCardList && creditCardList.length === 0 && (
            <div className="d-flex justify-content-center fort-book mt-4">
              You have no credit cards on file. Please add a credit card for
              payment.
            </div>
          )}
           <div style={{maxHeight: `${ccModalMaxHeight}px`, overflowY:'scroll'}}>
           {creditCardList &&
            creditCardList.map((cc, idx) => (
              <div key={idx} className={`d-flex cc-line pl-3 pr-3 pt-2 pb-2`}>
                <Form.Field>
                  <Radio
                    className="mr-2"
                    name="ccard"
                    value={cc.cardId}
                    checked={returnCreditCard === cc.cardId}
                    onChange={(e) => handleCCChange(cc.cardId)}
                    disabled={
                      isCreditCardExpired(
                        cc.cardExpiryMonth,
                        cc.cardExpiryYear
                      ) ||
                      willCreditCardExpire(
                        cc.cardExpiryMonth,
                        cc.cardExpiryYear,
                      )
                    }
                  />
                </Form.Field>

                <div className="d-flex flex-column">
                  <div className="d-flex flex-row fort-book">
                    <div className="fort-bold">
                      Ending in {cc.cardLastFour}
                    </div>
                    <Divider horizontal />
                    <div>{cc.cardholderName}</div>
                    <Divider horizontal />
                    {isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear
                    ) ? (
                      <div className="fort-bold fs-13px lh-18px cc-error">
                        <Icon name="exclamation triangle" color="red"></Icon>
                        Expired: {cc.cardExpiryMonth}/{cc.cardExpiryYear}
                      </div>
                    ):''} 
                    
                    {willCreditCardExpire(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear
                    ) ? (
                      <div>
                        Expires: {cc.cardExpiryMonth}/{cc.cardExpiryYear}
                      </div>
                    ) : ''
                    }
                    {!willCreditCardExpire(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear
                    ) && !isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear
                    ) ? 
                      (<div>
                        Expires: {cc.cardExpiryMonth}/{cc.cardExpiryYear}
                      </div>
                    ):''}
                  </div>
                  {willCreditCardExpire(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear
                    ) ? (
                      <span className="cc-error">Note: This card will expire before the end of this return window.</span>
                    ) : ''
                    }
                  {/*maxOnsaleYear &&
                    maxOnsaleMonth &&
                    //isGeneralCart(foc) &&
                    !isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear,
                    ) &&
                    !isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear
                    )&&
                    (maxOnsaleYear > cc.cardExpiryYear ||
                      (maxOnsaleYear === cc.cardExpiryYear &&
                        maxOnsaleMonth > cc.cardExpiryMonth)) && (
                      <div className="d-flex flex-row fort-book">
                        <Icon name="info circle"></Icon>
                        <div>
                        Note: This card will expire before one or more items' On-Sale Date.
                          {//This card cannot be used because it expires before one of the sale dates}
                        </div>
                      </div>
                    )*/}
                  {/*cart.isUserPow() &&
                    isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear,
                    ) &&
                    !isCreditCardExpired(
                      cc.cardExpiryMonth,
                      cc.cardExpiryYear
                    )&&
                    
                    !isGeneralCart(foc) && (
                      <div className="d-flex flex-row fort-book">
                        <Icon name="info circle"></Icon>
                        <div>
                        Cannot use a card that expires before FOC date.
                        </div>
                      </div>
                    )*/}
                </div>
              </div>
            ))}</div>
            </Form>
            
        </div>
        {
        /*<PRHButton
                    borderColorOverride={'blue'}
                    textColorOverride={'white'}
                    textColorHoverOverride={'blue'}
                    backgroundColorOverride={'blue'}
                    backgroundHoverColorOverride={'white'}
                    iconColorOverride={'white'}
                    iconColorHoverOverride={'blue'}
                    icon={!returnCreditCard ? '' : 'check'}
                    disabled={!returnCreditCard}
                    onClick={()=>submitReturn()}
                  >
                    Request return
                  </PRHButton>*/
}
      </div>
      
    </DialogModal>
    </div>
  );
}

export default AffidavitReturnsChooseCC;
