import * as FileSaver from "file-saver";
// "@sheet/coredemo": "^1.20200410.1",
import * as XLSX from "@sheet/core";

// XLSX.utils.sheet_add_json takes an array of objects and updates an existing worksheet object. It follows the same process as json_to_sheet and accepts an options argument:

//   Option Name	Default	Description
//   header		Use specified column order (default Object.keys)
//   dateNF	FMT 14	Use specified date format in string output
//   cellDates	false	Store dates as type d (default is n)
//   skipHeader	false	If true, do not include header row in output
//   origin		Use specified cell as starting point (see below)

export class Export {
  static Excel = ({
    fileName = "spreadsheet",
    csvData,
    sheetName = "data",
  }) => {
    let eHeader = ["Title"];

    // const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    // for (let i = 0; i < df.length; i++) {
    //   if (df[i].field === 'priceusa') {
    //     priceUSColumn = alphabets[i];
    //   }
    //   if (df[i].field === 'pricecanada') {
    //     priceCAColumn = alphabets[i];
    //   }
    // }

    // let eData = [];
    // csvData.forEach((t) => {
    //   let col = {};
    //   for (let x = 0; x < eHeader.length; x++) {
    //     if (df[x].field === 'myh2_onsaledate') {
    //       col[eHeader[x]] = formatDateMMDDYYYY(t[df[x].field], '/');
    //     } else {
    //       col[eHeader[x]] = t[df[x].field] === '' ? null : t[df[x].field];
    //     }
    //   }
    //   eData.push(col);
    // });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // const ws = XLSX.utils.json_to_sheet(eData, { header: eHeader });
    const ws = XLSX.utils.json_to_sheet(csvData);

    // ws['A1'].s = {
    //   fill: {
    //     patternType: 'solid', // none / solid
    //     fgColor: { rgb: 'FFFFFFFF' },
    //     bgColor: { rgb: '00000000' },
    //   },
    //   font: {
    //     name: 'Times New Roman',
    //     sz: 16,
    //     color: { rgb: '00000000' },
    //     bold: true,
    //     italic: false,
    //     underline: false,
    //   },
    //   border: {
    //     top: { style: 'thin', color: { auto: 1 } },
    //     right: { style: 'thin', color: { auto: 1 } },
    //     bottom: { style: 'thin', color: { auto: 1 } },
    //     left: { style: 'thin', color: { auto: 1 } },
    //   },
    // };

    if (!ws["!cols"]) ws["!cols"] = [];
    for (let x = 0; x < eHeader.length; x++) {
      // if (df[x].width) {
      //   ws['!cols'][x] = { width: df[x].width / 6 };
      // } else {
      ws["!cols"][x] = { auto: 1 };
      // }
    }
    const headerPattern = /^[abcdecfgshijklmnopqrstuvwxyz]+[1]$/gi;
    const dataPattern = /^[abcdecfgshijklmnopqrstuvwxyz]/gi;
    Object.keys(ws).forEach((k) => {
      if (k.match(headerPattern)) {
        ws[k].s = {
          bold: true,
          color: { rgb: 0xffffff },
          fgColor: { rgb: 0x585858 },
          sz: 11,
        };
      } else if (k.match(dataPattern)) {
        ws[k].s = {
          sz: 11,
        };
      }

      // if (k.startsWith('D')) {
      //   if (k != 'D1') {
      //     ws[k].z = '@';
      //     // ws[k].t = 'text';
      //   }
      // }
    });

    // ws['!condfmt'] = [
    //   /* A1:A10 "Format only values that are above average" GT */
    //   {
    //     ref: 'A1:H1',
    //     /* "Light Red Fill with Dark Red Text" */
    //     s: { color: { rgb: '9C0006' }, bgColor: { rgb: 'FFC7CE' } },
    //   },
    // ];
    // if (!ws['!cols']) ws['!cols'] = [];
    // for (let x = 0; x < eHeader.length; x++) {
    //   if (df[x].width) {
    //     ws['!cols'][x] = { width: df[x].width / 6 };
    //   } else {
    //     ws['!cols'][x] = { auto: 1 };
    //   }
    // }

    // ws['A1'].s = { auto: 1, bgColor: { rgb: 'ECECEC' } };

    // ws['!freeze'] = 'A2';

    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    // console.log('sheet js', XLSX.utils.sheet_to_json(ws));
    // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    XLSX.writeFile(wb, fileName + fileExtension, {
      cellStyles: true,
      sheetStubs: true,
      bookType: "xlsx",
      bookSST: true,
    });
  };

  static DocumentDetails = ({
    fileName = "spreadsheet",
    metadata,
    list,
    sheetName = "data",
  }) => {
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.aoa_to_sheet(metadata, { origin: { c: 0, r: 0 } });
    // console.log("list", list, typeof list);
    if (Array.isArray(list)) {
      XLSX.utils.sheet_add_json(ws, list, {
        origin: { c: 0, r: metadata.length + 1 },
      });
    } else if (typeof list === "object") {
      console.log("list", list);
      let offset = metadata.length + 1;
      list.lists.forEach((l) => {
        if (l.length === 1 && l[0].headerLine) {
          delete l[0].headerLine;
          XLSX.utils.sheet_add_json(ws, l, {
            origin: { c: 0, r: offset },
          });
          offset += l.length;
        } else {
          XLSX.utils.sheet_add_json(ws, l, {
            origin: { c: 0, r: offset },
          });
          offset += l.length + 1;
        }
      });
    }

    ws["!sheetFormat"] = {
      col: {
        width: 20,
      },
    };
    var range = XLSX.utils.decode_range(ws["!ref"]);
    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var cell_address = { c: C, r: R };
        /* if an A1-style address is needed, encode the address */
        var cell_ref = XLSX.utils.encode_cell(cell_address);
        if (ws[cell_ref]) {
          ws[cell_ref].s = {
            border: {
              top: { style: "thin", color: { auto: 1 } },
              right: { style: "thin", color: { auto: 1 } },
              bottom: { style: "thin", color: { auto: 1 } },
              left: { style: "thin", color: { auto: 1 } },
            },
            fontSize: 12,
          };
        }
      }
    }

    ws["!outline"] = { above: true };

    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    XLSX.writeFile(wb, fileName + fileExtension, {
      cellStyles: true,
      bookType: "xlsx",
      bookSST: true,
    });
  };
}

export class ExportMultiple {
  static Excel = ({
    fileName = "spreadsheet",
    csvData,
    sheetName = "data",
  }) => {
    let eHeader = ["Title"];

    // const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    // for (let i = 0; i < df.length; i++) {
    //   if (df[i].field === 'priceusa') {
    //     priceUSColumn = alphabets[i];
    //   }
    //   if (df[i].field === 'pricecanada') {
    //     priceCAColumn = alphabets[i];
    //   }
    // }

    // let eData = [];
    // csvData.forEach((t) => {
    //   let col = {};
    //   for (let x = 0; x < eHeader.length; x++) {
    //     if (df[x].field === 'myh2_onsaledate') {
    //       col[eHeader[x]] = formatDateMMDDYYYY(t[df[x].field], '/');
    //     } else {
    //       col[eHeader[x]] = t[df[x].field] === '' ? null : t[df[x].field];
    //     }
    //   }
    //   eData.push(col);
    // });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // const ws = XLSX.utils.json_to_sheet(eData, { header: eHeader });
    const ws = XLSX.utils.json_to_sheet(csvData);

    // ws['A1'].s = {
    //   fill: {
    //     patternType: 'solid', // none / solid
    //     fgColor: { rgb: 'FFFFFFFF' },
    //     bgColor: { rgb: '00000000' },
    //   },
    //   font: {
    //     name: 'Times New Roman',
    //     sz: 16,
    //     color: { rgb: '00000000' },
    //     bold: true,
    //     italic: false,
    //     underline: false,
    //   },
    //   border: {
    //     top: { style: 'thin', color: { auto: 1 } },
    //     right: { style: 'thin', color: { auto: 1 } },
    //     bottom: { style: 'thin', color: { auto: 1 } },
    //     left: { style: 'thin', color: { auto: 1 } },
    //   },
    // };

    if (!ws["!cols"]) ws["!cols"] = [];
    for (let x = 0; x < eHeader.length; x++) {
      // if (df[x].width) {
      //   ws['!cols'][x] = { width: df[x].width / 6 };
      // } else {
      ws["!cols"][x] = { auto: 1 };
      // }
    }
    const headerPattern = /^[abcdecfgshijklmnopqrstuvwxyz]+[1]$/gi;
    const dataPattern = /^[abcdecfgshijklmnopqrstuvwxyz]/gi;
    Object.keys(ws).forEach((k) => {
      if (k.match(headerPattern)) {
        ws[k].s = {
          bold: true,
          color: { rgb: 0xffffff },
          fgColor: { rgb: 0x585858 },
          sz: 11,
        };
      } else if (k.match(dataPattern)) {
        ws[k].s = {
          sz: 11,
        };
      }

      // if (k.startsWith('D')) {
      //   if (k != 'D1') {
      //     ws[k].z = '@';
      //     // ws[k].t = 'text';
      //   }
      // }
    });

    // ws['!condfmt'] = [
    //   /* A1:A10 "Format only values that are above average" GT */
    //   {
    //     ref: 'A1:H1',
    //     /* "Light Red Fill with Dark Red Text" */
    //     s: { color: { rgb: '9C0006' }, bgColor: { rgb: 'FFC7CE' } },
    //   },
    // ];
    // if (!ws['!cols']) ws['!cols'] = [];
    // for (let x = 0; x < eHeader.length; x++) {
    //   if (df[x].width) {
    //     ws['!cols'][x] = { width: df[x].width / 6 };
    //   } else {
    //     ws['!cols'][x] = { auto: 1 };
    //   }
    // }

    // ws['A1'].s = { auto: 1, bgColor: { rgb: 'ECECEC' } };

    // ws['!freeze'] = 'A2';

    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    // console.log('sheet js', XLSX.utils.sheet_to_json(ws));
    // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    XLSX.writeFile(wb, fileName + fileExtension, {
      cellStyles: true,
      sheetStubs: true,
      bookType: "xlsx",
      bookSST: true,
    });
  };

  static DocumentDetails = ({
    fileName = "spreadsheet",
    metadata,
    list,
    sheetName = "data",
    amountColumn="G",
  }) => {
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.aoa_to_sheet(metadata, { origin: { c: 0, r: 0 } });
    // console.log("list", list, typeof list);
    if (Array.isArray(list)) {
      XLSX.utils.sheet_add_json(ws, list, {
        origin: { c: 0, r: metadata.length + 1 },
      });
    } else if (typeof list === "object") {
      console.log("list", list);
      let offset = metadata.length + 1;
      list.lists.forEach((l) => {
        if (l.length === 1 && l[0].headerLine) {
          delete l[0].headerLine;
          XLSX.utils.sheet_add_json(ws, l, {
            origin: { c: 0, r: offset },
          });
          offset += l.length;
        } else {
          XLSX.utils.sheet_add_json(ws, l, {
            origin: { c: 0, r: offset },
          });
          offset += l.length + 1;
        }
      });
    }

    ws["!sheetFormat"] = {
      col: {
        width: 20,
      },
    };
    var range = XLSX.utils.decode_range(ws["!ref"]);
    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var cell_address = { c: C, r: R };
        /* if an A1-style address is needed, encode the address */
        var cell_ref = XLSX.utils.encode_cell(cell_address);
        if (ws[cell_ref]) {
          ws[cell_ref].s = {
            border: {
              top: { style: "thin", color: { auto: 1 } },
              right: { style: "thin", color: { auto: 1 } },
              bottom: { style: "thin", color: { auto: 1 } },
              left: { style: "thin", color: { auto: 1 } },
            },
            fontSize: 12,
          };
          if (cell_ref.startsWith(amountColumn) && ws[cell_ref].v) {
            ws[cell_ref].z = '"$"#,##0.00';  // Currency format
        }
        }
      }
    }

    ws["!outline"] = { above: true };

    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    XLSX.writeFile(wb, fileName + fileExtension, {
      cellStyles: true,
      bookType: "xlsx",
      bookSST: true,
    });
  };
}
export class ExportCSV {
  static CSV = ({ fileName = "spreadsheet", csvData, sheetName = "data" }) => {
    let eHeader = ["Title"];
    // const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    // for (let i = 0; i < df.length; i++) {
    //   if (df[i].field === 'priceusa') {
    //     priceUSColumn = alphabets[i];
    //   }
    //   if (df[i].field === 'pricecanada') {
    //     priceCAColumn = alphabets[i];
    //   }
    // }

    // let eData = [];
    // csvData.forEach((t) => {
    //   let col = {};
    //   for (let x = 0; x < eHeader.length; x++) {
    //     if (df[x].field === 'myh2_onsaledate') {
    //       col[eHeader[x]] = formatDateMMDDYYYY(t[df[x].field], '/');
    //     } else {
    //       col[eHeader[x]] = t[df[x].field] === '' ? null : t[df[x].field];
    //     }
    //   }
    //   eData.push(col);
    // });

    const fileType = "text/csv;charset=UTF-8";
    const fileExtension = ".csv";
    const ws = XLSX.utils.json_to_sheet(csvData);
    //const ws = XLSX.utils.json_to_sheet(eData, { header: eHeader });
    //const ws = XLSX.utils.json_to_sheet(csvData);

    // ws['A1'].s = {
    //   fill: {
    //     patternType: 'solid', // none / solid
    //     fgColor: { rgb: 'FFFFFFFF' },
    //     bgColor: { rgb: '00000000' },
    //   },
    //   font: {
    //     name: 'Times New Roman',
    //     sz: 16,
    //     color: { rgb: '00000000' },
    //     bold: true,
    //     italic: false,
    //     underline: false,
    //   },
    //   border: {
    //     top: { style: 'thin', color: { auto: 1 } },
    //     right: { style: 'thin', color: { auto: 1 } },
    //     bottom: { style: 'thin', color: { auto: 1 } },
    //     left: { style: 'thin', color: { auto: 1 } },
    //   },
    // };

    if (!ws["!cols"]) ws["!cols"] = [];
    for (let x = 0; x < eHeader.length; x++) {
      // if (df[x].width) {
      //   ws['!cols'][x] = { width: df[x].width / 6 };
      // } else {
      ws["!cols"][x] = { auto: 1 };
      // }
    }
    const headerPattern = /^[abcdecfgshijklmnopqrstuvwxyz]+[1]$/gi;
    const dataPattern = /^[abcdecfgshijklmnopqrstuvwxyz]/gi;
    Object.keys(ws).forEach((k) => {
      if (k.match(headerPattern)) {
        ws[k].s = {
          bold: true,
          color: { rgb: 0xffffff },
          fgColor: { rgb: 0x585858 },
          sz: 11,
        };
      } else if (k.match(dataPattern)) {
        ws[k].s = {
          sz: 11,
        };
      }

      // if (k.startsWith('D')) {
      //   if (k != 'D1') {
      //     ws[k].z = '@';
      //     // ws[k].t = 'text';
      //   }
      // }
    });

    // ws['!condfmt'] = [
    //   /* A1:A10 "Format only values that are above average" GT */
    //   {
    //     ref: 'A1:H1',
    //     /* "Light Red Fill with Dark Red Text" */
    //     s: { color: { rgb: '9C0006' }, bgColor: { rgb: 'FFC7CE' } },
    //   },
    // ];
    // if (!ws['!cols']) ws['!cols'] = [];
    // for (let x = 0; x < eHeader.length; x++) {
    //   if (df[x].width) {
    //     ws['!cols'][x] = { width: df[x].width / 6 };
    //   } else {
    //     ws['!cols'][x] = { auto: 1 };
    //   }
    // }

    // ws['A1'].s = { auto: 1, bgColor: { rgb: 'ECECEC' } };

    // ws['!freeze'] = 'A2';

    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    // console.log('sheet js', XLSX.utils.sheet_to_json(ws));
    // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    XLSX.writeFile(wb, fileName + fileExtension, {
      cellStyles: true,
      sheetStubs: true,
      bookType: "csv",
      bookSST: true,
    });
  };

  static DocumentDetails = ({
    fileName = "spreadsheet",
    metadata,
    list,
    sheetName = "data",
  }) => {
    const fileExtension = ".csv";

    const ws = XLSX.utils.aoa_to_sheet(metadata, { origin: { c: 0, r: 0 } });
    XLSX.utils.sheet_add_json(ws, list, {
      origin: { c: 0, r: metadata.length + 1 },
    });
    ws["!sheetFormat"] = {
      col: {
        width: 20,
      },
    };
    var range = XLSX.utils.decode_range(ws["!ref"]);
    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var cell_address = { c: C, r: R };
        /* if an A1-style address is needed, encode the address */
        var cell_ref = XLSX.utils.encode_cell(cell_address);
        if (ws[cell_ref]) {
          ws[cell_ref].s = {
            border: {
              top: { style: "thin", color: { auto: 1 } },
              right: { style: "thin", color: { auto: 1 } },
              bottom: { style: "thin", color: { auto: 1 } },
              left: { style: "thin", color: { auto: 1 } },
            },
            fontSize: 12,
          };
        }
      }
    }

    ws["!outline"] = { above: true };

    const wb = { Sheets: { [sheetName]: ws }, SheetNames: [sheetName] };
    XLSX.writeFile(wb, fileName + fileExtension, {
      cellStyles: true,
      bookType: "csv",
      bookSST: true,
    });
  };
}
