import { useState, useEffect } from "react";
import {
  Header,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import "./VariantRestrictionsInfoModal.scss";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import { TOOLTIPS } from "../../utils/const";
import useApp from "../hc/useApp";
import LazyImage from "../../utils/LazyImage";
import {
  useGetVariantRestrictionQuery,
  useLazyGetVariantRestrictionQuery,
} from "../../api/estimateCartApi";

const VariantRestrictionsInfoModal = ({
  isbn,
  searchClass,
  country,
  orderReq,
  title,
}) => {
  const app = useApp();
  const [open, setOpen] = useState(false);
  // const [restrictions, setRestrictions] = useState({});
  // const [restrictionsLoaded, setRestrictionsLoaded] = useState(false);
  const {
    data: restrictions,
    error,
    isLoading,
    isFetching,
    refetch,
  } = useGetVariantRestrictionQuery({ isbn, searchClass, country });

  let realTrigger = (
    <div className='d-flex flex-column fort-book-italic fs-13px lh-18 hover-underline text-blue cursor-pointer align-self-end'>
      <div
        onClick={(e) => {
          setOpen(true);
        }}
        className='v-details'
      >
        View ordering restrictions
      </div>
    </div>
  );

  const resetState = () => {
    setOpen(false);
  };

  const okAction = {
    label: "Got it",
    color: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "light-blue",
    textColorOverride: "white",
    borderHoverColorOverride: "light-blue",
    iconColorOverride: "white",
    iconColorHoverOverride: "light-blue",
    icon: "checkmark",
    bold: true,
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      resetState();
      return { proceed: true };
    },
  };

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      modalClassName='vri-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            VARIANT ORDERING RESTRICTIONS
          </Header>
        ),
        actions: [okAction],
      }}
      options={{
        trigger: realTrigger,
      }}
    >
      <div className='ar-box'>
        {isFetching ? (
          <Loader active />
        ) : (
          <>
            <div className='ml-4 mr-4 mb-2'>
              {/* {restrictions?.searchMessage} */}
              <div className='restriction-header'>
                To add <span className='fort-bold'>{title},</span> there must be{" "}
                <span className='fort-bold'>{orderReq} total copies </span>
                of the following qualifying titles in your cart/other accounts’
                carts:{" "}
                <span>
                  <Popup
                    on='hover'
                    content={
                      <>
                        To add this variant to your cart, you must have the
                        required number of qualifying titles currently in your
                        cart or other accounts’ carts.
                        <br />
                        <br />
                        “Currently in cart” quantities are always from the{" "}
                        <b>latest quantities</b> in your carts; if you’ve
                        confirmed quantities and then made updates, the{" "}
                        <b>updated quantities</b> will be used to calculate your
                        eligibility.
                      </>
                    }
                    trigger={
                      <Icon
                        name='question circle'
                        className='sb_question_icon'
                      />
                    }
                  />
                </span>
              </div>
            </div>
            <Divider className='mt-0 mb-0' />
            <div
              style={{ maxHeight: "400px" }}
              className='pl-4 pr-4 pt-2 pb-1 overflow-auto bg-gray-100'
            >
              {restrictions.data.results.map((title, idx) => (
                <div key={idx}>
                  <div className='d-flex flex-row mb-1'>
                    <LazyImage
                      className='vri-title-cover'
                      boxShadow={true}
                      src={`https://images.penguinrandomhouse.com/cover/${title.isbn}`}
                    ></LazyImage>

                    <div className='d-flex flex-column ml-2 justify-content-center'>
                      <div className='fort-bold fs-13px lh-18px text-gray-900'>
                        {title.title}
                      </div>
                      <div className='fort-book fs-13px lh-18px text-gray-700'>
                        {title.isbn}
                      </div>
                    </div>
                  </div>
                  {idx + 1 < restrictions.data.results.length && (
                    <Divider className='mb-1' tight />
                  )}
                </div>
              ))}
            </div>
            <Divider className='mt-0 mb-0' />
          </>
        )}
        {/* {workPromos.length > 0 ? (
          <>
            <div className="mb-4">
              Please note: Only one discount may be applied at a time.
              Promotional codes cannot be applied on event orders. Please
              contact Customer Service if there's a promotion you'd like to use
              on an event order.
            </div>
            <Divider tight />
            <div
              className="mt-3"
              style={{ maxHeight: '600px', overflow: 'auto' }}
            >
              {workPromos.map((promo, idx) => (
                <div key={idx} className="mb-1">
                  <div className="d-flex flex-row mb-2 pr-2">
                    <div className="d-flex flex-column">
                      <div className="fort-medium">
                        {promo.promos[0].description}
                      </div>
                      {promo.promos.map((p, i2) => (
                        <div key={i2}>
                          {i2 === 0 ? (
                            <div>
                              <div>
                                Use code{' '}
                                <span className="fort-bold">{p.code}</span> to
                                save{' '}
                                <NumericFormat
                                  className=""
                                  allowNegative={false}
                                  value={p.discount}
                                  thousandSeparator={true}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  displayType={'text'}
                                  // prefix={'-'}
                                  suffix={'%'}
                                />
                              </div>
                              {p.minQty > 0 ? (
                                <div>
                                  Order must contain at least {p.minQty} books
                                </div>
                              ) : null}
                              {p.terms !== '' ? <div>{p.terms}</div> : null}
                            </div>
                          ) : (
                            <div key={i2}>
                              <div>
                                for{' '}
                                <NumericFormat
                                  className=""
                                  allowNegative={false}
                                  value={p.discount}
                                  thousandSeparator={true}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  displayType={'text'}
                                  // prefix={'-'}
                                  suffix={'%'}
                                />{' '}
                                off,{' '}
                                {p.minQty > 0 ? (
                                  <span>
                                    order must contain at least {p.minQty} books
                                  </span>
                                ) : null}
                                {p.terms !== '' ? <span>{p.terms}</span> : null}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      {promo.promos[0].endDate ? (
                        <div>
                          Expires{' '}
                          {formatDateMMDDYYYY(promo.promos[0].endDate, '/')}
                        </div>
                      ) : null}
                    </div>

                    <div className="d-flex flex-column ml-auto">
                      <PRHButton
                        disabled={promo.code === currentPromo}
                        style={{ width: '135px' }}
                        icon={promo.code === currentPromo ? 'check' : 'plus'}
                        onClick={(e) => {
                          applyPromoCode(promo.code);
                          setCurrentPromo(promo.code);
                          // setOpen(false);
                        }}
                      >
                        {promo.code === currentPromo ? 'Applied' : 'Apply Code'}
                      </PRHButton>
                    </div>
                  </div>
                  <Divider tight />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="mt-2 mb-2">
            {promosLoaded
              ? 'No promotions are currently available for your account.'
              : ''}
          </div>
        )} */}
      </div>
    </DialogModal>
  );
};

VariantRestrictionsInfoModal.propTypes = {
  isbn: PropTypes.string.isRequired,
  searchClass: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
};

VariantRestrictionsInfoModal.defaultProps = {};

export default VariantRestrictionsInfoModal;
