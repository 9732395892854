import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { ErrorComponent } from "../../components/ErrorComponent.jsx";
import PageFooter from "../../components/footer/PageFooter";
import useApp from "../../components/hc/useApp";
import useCart from "../../components/hc/useCart";
import useSecurity from "../../components/hc/useSecurity";
import PageHeader from "../../components/header/PageHeader";
import LoadingView from "../loading/LoadingView";
import { TitleAdditionalDetails } from "./TitleAdditionalDetails";
import { TitleCarousel } from "./TitleCarousel";
import "./TitleInfoPage.scss";
import { TitleMetadata } from "./TitleMetadata";
import PageStandard from "../../components/elements/PageStandard";
import withDirty from "../../shared-react-components/components/hc/withDirty";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import { useDispatch } from "react-redux";
import {
  ecomApi,
  useGetCarouselDataQuery,
  useGetOrderHistoryQuery,
  useLazyGetCarouselDataQuery,
  useLazyGetOrderHistoryQuery,
  useLazyGetTitleInfoQuery,
  useLazySearchMultipleBizQuery,
} from "../../api/ecomApi.jsx";
import { cloneDeep } from "lodash";

export const TitleInfoPage = withDirty((props) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const [state, setstate] = useState({
    metadata: undefined,
    error: false,
    focDate: undefined,
    selectedFormat: undefined,
  });
  const [performGetCarouselData, getCarouselDataResult] =
    useLazyGetCarouselDataQuery();
  // const [tabs, setTabs] = useState(false);
  const [orderHistory, setOrderHistory] = useState(false);
  const [locationDropdownItems, setLocationDropdownItems] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(false);
  const [currentTab, setCurrentTab] = useState(false);
  const dispatch = useDispatch();
  // const [itemInCart, setItemInCart] = useState(0);
  const [loadTitleInfo, { data: titleData }] = useLazyGetTitleInfoQuery();
  const [performSearchMultiBiz, { data: searchMultiBizData }] =
    useLazySearchMultipleBizQuery();
  const [performGetOrderHistory, getOrderHistoryResult] =
    useLazyGetOrderHistoryQuery();
  const app = useApp();
  const cart = useCart();
  const user = useSecurity();
  const history = useHistory();

  const hasCatalogDate =
    state?.selectedFormat?.catalog && state?.selectedFormat?.catalog !== null;
  const cataDate = moment(state?.selectedFormat?.catalog);

  useEffect(() => {
    // console.log("useEffect carts", cart, state);
    if (state?.metadata?.formats) {
      // console.log('state', state.metadata);
      const newSelectedFormat = { ...state.selectedFormat };
      const newFormats = [...state.metadata.formats];

      if (cart.isUserPow()) {
        newSelectedFormat.cartQuantity = 0;
        newSelectedFormat.confirmationStatus = false;
        newFormats.map((f) => {
          f.cartQuantity = 0;
          f.confirmationStatus = false;
        });

        orderHistory?.seriesHistory &&
          orderHistory?.seriesHistory.titles.map((f) => {
            f.cartQuantity = 0;
          });

        cart.estimateCarts.forEach((estimate) => {
          // console.log('checking estimate', estimate);
          if (newSelectedFormat.foc === estimate.foc) {
            newSelectedFormat.confirmationStatus = estimate.confirmationStatus;
          }
          estimate.items.forEach((i) => {
            if (i.isbn === newSelectedFormat.isbnStr) {
              // newSelectedFormat.confirmationStatus =
              //   estimate.confirmationStatus;
              newSelectedFormat.cartQuantity = i.quantity;
            }
            let shipTo = cart.getShipToAccount().accountNo;
            orderHistory?.seriesHistory &&
              orderHistory?.seriesHistory?.titles.map((f) => {
                if (i.isbn === f.isbn) {
                  f.cartQuantity = i.quantity;
                }
              });
            newFormats.map((f) => {
              if (f.foc === estimate.foc) {
                f.confirmationStatus = estimate.confirmationStatus;
              }
              if (i.isbn === f.isbnStr) {
                f.cartQuantity = i.quantity;
                // f.confirmationStatus = estimate.confirmationStatus;
              }
            });
          });
        });
      } else if (cart.cart.items.length > 0) {
        cart.cart.items.forEach((i) => {
          if (i.isbn === newSelectedFormat.isbnStr) {
            newSelectedFormat.cartQuantity = i.quantity;
          }
          orderHistory?.seriesHistory &&
            orderHistory?.seriesHistory?.titles.map((f) => {
              if (i.isbn === f.isbn) {
                f.cartQuantity = i.quantity;
              }
            });
          newFormats.map((f) => {
            if (i.isbn === f.isbnStr) {
              f.cartQuantity = i.quantity;
            }
          });
        });
      }
      // console.log('newmetadata', newFormats);
      setstate({
        ...state,
        selectedFormat: newSelectedFormat,
        metadata: {
          ...state.metadata,
          formats: newFormats,
        },
      });
    } else {
      const newSelectedFormat = { ...state.selectedFormat };
      if (cart.isUserPow()) {
        newSelectedFormat.cartQuantity = 0;
        newSelectedFormat.confirmationStatus = false;
        cart.estimateCarts.forEach((estimate) => {
          if (newSelectedFormat.foc === estimate.foc) {
            newSelectedFormat.confirmationStatus = estimate.confirmationStatus;
          }
          estimate.items.forEach((i) => {
            if (i.isbn === newSelectedFormat.isbnStr) {
              // newSelectedFormat.confirmationStatus =
              //   estimate.confirmationStatus;
              newSelectedFormat.cartQuantity = i.quantity;
            }
          });
        });
      } else if (cart.cart.items.length > 0) {
        cart.cart.items.forEach((i) => {
          if (i.isbn === newSelectedFormat.isbnStr) {
            newSelectedFormat.cartQuantity = i.quantity;
          }
        });
      }

      setstate({
        ...state,
        selectedFormat: newSelectedFormat,
      });
    }
  }, [cart.estimateCarts, cart.cart.items]);

  useEffect(() => {
    // if (cart.cartInitialized === false) return;
    // console.log(
    //   "props.match.params.titleId",
    //   props.match.params.titleId,
    //   cart.cartInitialized
    // );
    try {
      // console.log("shiptoAccnos", cart.customerInfo.shipToAccountNos);
      // console.log("shipTo 1", props.match.params);
      // console.log("shipTo 2",props.match.params.shipTo, typeof Number(props.match.params.shipTo));
      // console.log("shipTo 3", cart.customerInfo.shipToAccountNos.findIndex(
      //   (s) => s.accountNo == props.match.params.shipTo,
      // ));
      // console.log("shipTo 4", cart.customerInfo.shipToAccountNos);
      // console.log("shipTo 5", location.href);
      // if (props.match.params?.shipTo){
      //   cart.setShipTo(
      //     cart.customerInfo.shipToAccountNos.findIndex(
      //       (s) => s.accountNo === props.match.params.shipTo
      //     ),
      //     false
      //   );
      //   //history.replace('/titleinfo/'+props.match.params.titleId);
      //  //cart.setShipTo(Number(props.match.params.shipTo));
      // }
      setCurrentTab(false);
      if (cart.customerInfo.shipTos.length === 1) {
        setSelectedLocation("all");
      } else if (cart.customerInfo.shipTos.length > 1) {
        let options = [
          {
            key: "all",
            value: "all",
            header: <div>All locations</div>,
            text: <div>All locations'</div>,
            content: <div>All locations</div>,
          },
        ];
        cart.customerInfo.shipTos.map((f) => {
          let locTitle = f.accountNo;
          if (f.address.name1) {
            locTitle += ` | ${f.address.name1}`;
          }
          if (f.address.city) {
            locTitle += ` | ${f.address.city}`;
          }
          if (f.address.state) {
            locTitle += `, ${f.address.state}`;
          }

          options.push({
            key: f.accountNo,
            value: f.accountNo,
            text: locTitle,
            content: <div className='d-flex flex-row'>{f.accountNo}</div>,
          });
          // }
        });
        setLocationDropdownItems(options);
        if (cart.isUserPow()) {
          if (cart.shipTo >= 0) {
            setSelectedLocation(cart.getShipToAccount().accountNo);
          } else {
            setSelectedLocation(false);
          }
        } else {
          if (history?.location?.state?.shipTo) {
            setSelectedLocation(history?.location?.state?.shipTo);
          } else {
            setSelectedLocation("all");
          }
        }
      }

      if (cart.isUserPow() !== undefined) {
        props.dirtyHandler.setLoadingTakeoverConfig({
          text: "Loading title . . .",
          longLoadText: "Still loading . . .",
          inverted: true,
          backgroundBlurLight: true,
        });
        props.dirtyHandler.setLoadingTakeover(true);
        window.scrollTo(0, 0);
        loadTitleInfo(
          {
            isbn: props.match.params.titleId,
            sapAccountNo: cart.ccSapAccountNo,
          },
          true
        ).then((titleResponse) => {
          // console.log("titleresponse", titleResponse);
          if (titleResponse && titleResponse.status === "fulfilled") {
            const { data } = titleResponse;
            // console.log("titleResponse", data);
            const contributorData = Object.values(
              data.data.frontlistiestTitle?.contributors
            )
              .filter((contrib) => contrib.spotlight !== null)
              .filter(
                (contrib, index, self) =>
                  self.findIndex((t) => t.id === contrib.id) === index
              );

            // const filteredTabs = tempTabs;
            const formatValues = Object.values(data.data.formats);
            const formatIsbns =
              Object.keys(Object.assign({}, ...formatValues)) || [];

            const formats =
              Object.values(Object.assign({}, ...formatValues)) || [];

            if (formatIsbns?.length > 0) {
              performSearchMultiBiz(
                {
                  searchItems: formatIsbns,
                  country: cart.customerInfo.customer.country,
                  searchClass: cart.customerInfo.customer.searchClass,
                },
                true
              ).then((response) => {
                if (response && response.isSuccess) {
                  const { data: searchData } = response;
                  const mergedFormatsWithSeachInfo = formatIsbns?.map(
                    (isbnProp) => {
                      const foundFormat = formats?.find(
                        (f) => f.isbnStr === isbnProp
                      );
                      const foundSearch = searchData.data.results?.find(
                        (sd) => sd.isbn === isbnProp
                      );
                      return {
                        ...foundSearch,
                        ...foundFormat,
                      };
                    }
                  );

                  // console.log(
                  //   "after searchmultibiz",
                  //   response,
                  //   cart.isUserPow(),
                  //   cart
                  // );
                  if (cart.isUserPow() && cart.estimateCarts.length > 0) {
                    // console.log("loading estimate cart into title");
                    cart.estimateCarts.forEach((estimate) => {
                      estimate.items.forEach((i) => {
                        mergedFormatsWithSeachInfo.map((f) => {
                          if (f.foc === estimate.foc) {
                            f.confirmationStatus = estimate.confirmationStatus;
                          }
                          if (i.isbn === f.isbnStr) {
                            f.cartQuantity = i.quantity;
                            // f.confirmationStatus =
                            //   estimate.confirmationStatus;
                          }
                        });
                      });
                    });
                  } else if (cart.cart.items.length > 0) {
                    cart.cart.items.forEach((ci) => {
                      mergedFormatsWithSeachInfo.map((f) => {
                        if (ci.isbn === f.isbnStr) {
                          f.cartQuantity = ci.quantity;
                        }
                      });
                    });
                  }
                  const selectedFormat = mergedFormatsWithSeachInfo?.find(
                    (f) => f.isbnStr === props.match.params.titleId
                  );
                  setstate({
                    metadata: {
                      ...data.data,
                      formats: mergedFormatsWithSeachInfo?.filter(
                        (f) => f.itemTypeCode !== "PI"
                      ),
                      promoItems: mergedFormatsWithSeachInfo?.filter(
                        (f) => f.itemTypeCode === "PI"
                      ),
                    },
                    isPowUser: cart.isUserPow(),
                    selectedFormat: selectedFormat,
                  });

                  // const tempTabs = buildTabs(props.match.params.titleId);
                  // setTabs(tempTabs);
                  // if (!user.isAppCompCopy()) {
                  loadHistory(selectedFormat);
                  // }
                  // let shipTo = null;
                  // if (cart.shipTo >= 0) {
                  //   shipTo = cart.getShipToAccount().accountNo;
                  // }

                  //   console.log('getting series history', shipTo, selectedFormat.isbn);
                  // const tempTabs = buildTabs(selectedFormat);
                  // setTabs(tempTabs);

                  // loadHistory(selectedFormat);

                  // setstate({ ...state, tabs: tempTabs });

                  // }
                }
                props.dirtyHandler.setLoadingTakeover(false);
              });
            }
          } else {
            props.dirtyHandler.setLoadingTakeover(false);

            setstate({
              ...state,
              error: titleResponse.error,
            });
          }
        });
      }
    } catch (e) {
      console.log("e", e);
    }
  }, [props.match.params.titleId, , cart.cartInitialized === true]);

  useEffect(() => {
    if (cart.isUserPow()) {
      setSelectedLocation(cart.getShipToAccount().accountNo);
    }
  }, [cart.isUserPow() === true, cart.shipTo]);

  useEffect(() => {
    if (!(localStorage.getItem("shipTo") === null)) {
      cart.setShipTo(
        cart.customerInfo.shipToAccountNos.findIndex(
          (s) => s.accountNo === localStorage.getItem("shipTo")
        ),
        false
      );
      setSelectedLocation(localStorage.getItem("shipTo"));
      history.push({
        pathname: window.location.pathname,
        state: { shipTo: localStorage.getItem("shipTo") },
      });
      localStorage.removeItem("shipTo");
    } else if (history?.location?.state?.shipTo) {
      cart.setShipTo(
        cart.customerInfo.shipToAccountNos.findIndex(
          (s) => s.accountNo === history?.location?.state?.shipTo
        ),
        false
      );
      setSelectedLocation(history.location.state.shipTo);
    }
  }, []);

  useEffect(() => {
    if (state.selectedFormat) {
      loadHistory(state.selectedFormat);
    }
  }, [cart.estimateCarts]);

  /*
  useEffect(() => {
    console.log("cart.customerInfo 2", cart.customerInfo, cart.customerInfo.currentUser.roleNo, state.selectedFormat);
    const selectedFormat = mergedFormatsWithSeachInfoGeneral?.find(
      (f) => f.isbnStr === props.match.params.titleId
    );
    if (selectedFormat && cart.customerInfo.currentUser.roleNo==="W1") {
      console.log("reload history", selectedFormat);
      loadHistory(selectedFormat);
    }
  }, [cart.customerInfo]);
*/
  const loadHistory = (selectedFormat) => {
    let shipTo = null;

    if (cart.customerInfo?.currentUser?.roleNo === "W1") {
      shipTo = cart.getShipToAccount().accountNo;
      //shipTo = cart.;
    }

    performGetOrderHistory({
      isbn: selectedFormat.isbnStr,
      formatCode: selectedFormat?.format?.code,
      shipTo: shipTo,
      sapAccountNo: cart.ccSapAccountNo,
    }).then((orderHistoryResponse) => {
      // let summ = ordersResponse.data.data.summary.filter(
      //   (si) => {
      //     if (si.shipTo === shipTo) {
      //       return true;
      //     }
      //   }
      // );
      // console.log("orderHistoryResponse", orderHistoryResponse);
      let tempOrderHistory = cloneDeep(orderHistoryResponse.data.data);
      tempOrderHistory?.seriesHistory?.titles.map((f) => {
        f.cartQuantity = 0;
        f.confirmationStatus = false;
      });
      tempOrderHistory?.seriesHistory?.summary.map((f) => {
        f.cartQuantity = 0;
        f.confirmationStatus = false;
      });
      if (cart.isUserPow()) {
        cart.estimateCarts.forEach((estimate) => {
          // console.log("estimate", estimate);
          estimate.items.forEach((i) => {
            let shipTo = cart.getShipToAccount().accountNo;
            tempOrderHistory?.seriesHistory?.titles.map((f) => {
              if (i.isbn === f.isbn) {
                f.cartQuantity = i.quantity;
                if (estimate?.confirmationStatus) {
                  f.confirmationStatus = estimate.confirmationStatus;
                }
                if (estimate?.foc) {
                  f.foc = estimate.foc;
                }
              }
            });
            tempOrderHistory?.seriesHistory?.summary.map((f) => {
              // console.log("comparing", i.isbn, f.linkIsbn);
              if (i.workid === f.workId) {
                f.cartQuantity += i.quantity;
                if (estimate?.confirmationStatus) {
                  f.confirmationStatus = estimate.confirmationStatus;
                }
                if (estimate?.foc) {
                  f.foc = estimate.foc;
                }
              }
            });
          });
        });
      } else if (cart.cart.items.length > 0) {
        cart.cart.items.forEach((i) => {
          tempOrderHistory?.seriesHistory?.titles.map((f) => {
            if (i.isbn === f.isbn) {
              f.cartQuantity = i.quantity;
            }
          });
          tempOrderHistory?.seriesHistory?.summary.map((f) => {
            if (i.workid === f.workId) {
              f.cartQuantity += i.quantity;
            }
          });
        });
      }
      // console.log('setting orderhistory', tempOrderHistory);
      setOrderHistory(tempOrderHistory);
      // const th = buildTabs(selectedFormat);
      // setTabs(th);
    });
  };

  // useEffect(() => {
  //   // console.log('selectedformat change', state.selectedFormat);
  //   if (state.selectedFormat?.isbnStr) {
  //     const tempTabs = buildTabs(state.selectedFormat);
  //     setTabs(tempTabs);
  //     // loadHistory(state.selectedFormat);
  //   }
  //   //   if (!cart.isUserPow() && state?.selectedFormat?.isbn) {
  //   //     console.log('format changed', state.selectedFormat);
  //   //     loadSeriesHistory(state.selectedFormat);
  //   //   }

  //   //   return () => {
  //   //     console.log('loadSeriesHistory Completed');
  //   //   };
  // }, [state.selectedFormat]);

  useEffect(() => {
    let shipTo = false;

    if (cart.shipTo >= 0) {
      shipTo = true;
    }

    let tempOrderHistory = false;
    if (orderHistory?.seriesHistory) {
      tempOrderHistory = { ...orderHistory };

      tempOrderHistory.seriesHistory.titles.map((f) => {
        f.cartQuantity = 0;
      });
      let st = cart.getShipToAccount().accountNo.toString();

      cart.estimateCarts.forEach((estimate) => {
        estimate.items.forEach((i) => {
          tempOrderHistory.seriesHistory.titles.map((f) => {
            if (i.isbn === f.isbn) {
              f.cartQuantity = i.quantity;
            }
          });
        });
      });
      setOrderHistory(tempOrderHistory);
    }

    // if (shipTo) {
    //   const tempTabs = buildTabs(state.selectedFormat);
    //   setTabs(tempTabs);
    // }
  }, [cart.estimateCarts]);

  const buildTabs = () => {
    // console.log('buildTabs', selectedFormat);
    // let shipTo = null;
    // if (cart.isUserPow()) {
    //   if (cart.shipTo >= 0) {
    //     shipTo = cart.getShipToAccount().accountNo;
    //   }
    // }

    const tempTabs = [];

    // console.log('state', state, selectedFormat, orderHistory);
    if (state.selectedFormat?.aboutTheBook) {
      tempTabs.push({
        id: "about",
        key: "about",
        name: "About The Book",
        icon: "",
      });
    }

    const athrs = state.selectedFormat?.contributors
      ? Object.values(state.selectedFormat.contributors).filter(
          (a) => a.spotlight !== null
        )
      : [];
    // (a) => a.roleName === 'Author'
    // if (selectedFormat?.display) {
    if (athrs.length > 0) {
      tempTabs.push({
        id: "author",
        key: "author",
        name: "Author",
        icon: "",
      });
    }
    if (
      orderHistory?.seriesHistory?.titles.length > 0 &&
      ((cart.isUserPow() && cart.getShipToAccount().accountNo >= 0) ||
        !cart.isUserPow())
    ) {
      tempTabs.push({
        id: "seriesOrderHistory",
        key: "seriesOrderHistory",
        name: "Series Order History",
        icon: "",
        data: orderHistory.seriesHistory,
      });
    }

    // {
    //   id: 'author',
    //   key: 'author',
    //   name: 'AUTHOR',
    //   icon: '',
    // },
    // Object.keys(data.data.praises).length !== 0 && {id: 'praises', name: 'PRAISES', icon: '', data: data.data.praises},
    // Object.keys(data.data.bookAwards).length !== 0 && {id: 'awards', name: 'AWARDS', icon: '', data: data.data.bookAwards},
    // data.data.frontlistiestTitle.textExcerpt !== null && {id: 'excerpt', name: 'EXCERPT', icon: '', data: data.data.frontlistiestTitle.textExcerpt},
    // data.data.tableOfContents !== null && {id: 'tableofcontents', name: 'TABLE OF CONTENTS', icon: '', data: data.data.tableOfContents},
    // data.data.readingGuide !== null && {id: 'readingguide', name: 'GUIDES', icon: '', data: data.data.readingGuide},

    // console.log('build tabs', tempTabs);
    setCurrentTab(tempTabs[0]);
    return tempTabs;
  };

  const style = {
    backgroundImage: state.metadata
      ? `url(https://images.penguinrandomhouse.com/cover/${props.match.params.titleId})`
      : null,
  };

  // if (state.error) {
  //   return (
  //     <div style={{ backgroundColor: "white" }}>
  //       <PageHeader headerType='loggedIn' cartBadge={true} />
  //       <ErrorComponent error={state.error} from='title' />
  //     </div>
  //   );
  // }

  // console.log('titleinfo', seriesOrderHistory, state, cart);
  const tabs = useMemo(() => {
    const t = buildTabs();
    // console.log('build tab', t, orderHistory);
    return t;
  }, [state.selectedFormat, orderHistory]);

  const setSeriesHistoryTab = () => {
    const sohTab = tabs.findIndex((t) => t.id === "seriesOrderHistory");
    if (sohTab >= 0) {
      setCurrentTab(tabs[sohTab]);
    }
  };
  return (
    <PageStandard
      headerType='loggedIn'
      containerFluid={true}
      cartBadge={true}
      locationSelector={true}
    >
      <div style={style} className='ti-background-image' />
      {state.metadata && state.selectedFormat ? (
        <div className={`mb-6 ${isMobile ? "mt-3" : ""}`}>
          <TitleMetadata
            selectedFormat={state.selectedFormat}
            metadata={state.metadata}
            setFormat={(value) => setstate({ ...state, selectedFormat: value })}
            isPowUser={cart.isUserPow()}
            orderHistory={orderHistory}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            locationDropdownItems={locationDropdownItems}
            setSeriesHistoryTab={setSeriesHistoryTab}
          />
          {tabs.length > 0 && (
            <div className='tad-over-container'>
              <TitleAdditionalDetails
                tabs={tabs}
                title={state.selectedFormat}
                orderHistory={orderHistory}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                locationDropdownItems={locationDropdownItems}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
              />
            </div>
          )}
          {state.metadata.formats.length > 1 && (
            <TitleCarousel
              data={{
                data: state.metadata.formats
                  .filter((f) => f.isbnStr !== state.selectedFormat.isbnStr)
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(b.onSaleDate.date) - new Date(a.onSaleDate.date)
                  ),
              }}
              title={
                state.selectedFormat?.format?.code === "CB"
                  ? "Variant Covers"
                  : "Other Formats"
              }
              // action="View Formats"
              currentIsbn={state.selectedFormat.isbnStr}
            />
          )}
          {state.metadata.promoItems && (
            <TitleCarousel
              data={{
                data: state.metadata.promoItems
                  .slice()
                  .sort(
                    (a, b) =>
                      new Date(b.onSaleDate.date) - new Date(a.onSaleDate.date)
                  ),
              }}
              title={`${state.selectedFormat?.series?.code} Promo Items`}
              // action="View Variants"
              currentIsbn={state.selectedFormat.isbnStr}
            />
          )}
          {state.selectedFormat?.series?.code && (
            <TitleCarousel
              data={{
                // carouselName: "series",
                seriesCode: state.selectedFormat?.series?.code,
                ignoreWork: state.selectedFormat?.workId,
                rows: 19,
                sort: "onsale",
              }}
              title={`Other ${
                state.selectedFormat?.format?.code === "CB"
                  ? "comics"
                  : "titles"
              } from ${state.selectedFormat?.series?.title}`}
              // action="View Series"
              dataFunction={(dataProps) =>
                performGetCarouselData({ data: dataProps }, true)
              }
              currentIsbn={state.selectedFormat.isbnStr}
            />
          )}
          {hasCatalogDate &&
            state.selectedFormat?.cataDate &&
            cart.isUserPow() && (
              <TitleCarousel
                data={{
                  cataDate: cataDate.format("MM/DD/YYYY"),
                  ignoreWork: state.selectedFormat?.workId,
                  sort: "onsale",
                  rows: 19,
                }}
                title={`Other ${
                  state.selectedFormat?.format?.code === "CB"
                    ? "comics"
                    : "titles"
                } from ${cataDate.format("MMMM YYYY")} Catalog`}
                action={{
                  name: "Catalog",
                  onClick: () =>
                    history.push(`/catalog/${cataDate.format("YYYY-MM-DD")}`),
                }}
                dataFunction={(dataProps) =>
                  performGetCarouselData({ data: dataProps }, true)
                }
                currentIsbn={state.selectedFormat.isbnStr}
              />
            )}
          {state.selectedFormat?.format?.code !== "CB" && (
            <TitleCarousel
              data={{
                // carouselName: "authors",
                authorId: Object.values(
                  state.selectedFormat.contributors
                ).filter((a) => a.roleCode === "A")[0]?.id,
                ignoreWork: state.selectedFormat?.workId,
                contribRoleCode: "A",
                ignoreIsbn: props.match.params.titleId,
                sort: "onsale",
                rows: 19,
              }}
              title='Other books by author'
              // action="View All Books"
              dataFunction={(dataProps) =>
                performGetCarouselData({ data: dataProps }, true)
              }
              currentIsbn={state.selectedFormat.isbnStr}
            />
          )}
        </div>
      ) : (
        <div className='ti-loading-container'>
          {state.error && <ErrorComponent error={state.error} from='title' />}
        </div>
      )}
    </PageStandard>
  );
});
