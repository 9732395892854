import React, { useEffect, useRef, useState } from 'react';
import './OrderInvoiceTitleSearchMobile.scss';
import { Form, Icon, Input } from 'semantic-ui-react';

export const OrderInvoiceTitleSearchMobile = ({
  title,
  count,
  onSearch,
  placeholder,
}) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState(false);

  const focusInput = useRef(null);

  useEffect(() => {
    if (searchVisible) {
      focusInput.current.focus();
    }
  }, [searchVisible]);
  return (
    <div className="d-flex flex-row w-100 justify-content-space-between align-items-center mobile-search-box">
      {searchVisible ? (
        <Form
          className="mobile-search-form d-flex"
          onSubmit={() => {
            onSearch(searchValue);
            setSearchVisible(false);
            setSearchValue(null);
          }}
        >
          <Input
            ref={focusInput}
            className="mobile-search-input"
            type="text"
            onChange={(e, data) => setSearchValue(data.value)}
            placeholder={placeholder}
          />
        </Form>
      ) : (
        <div className="fort-light text-gray-700 fs-28px lh-28px">
          {count} {title}
        </div>
      )}
      <Icon
        name={searchVisible ? 'times' : 'search'}
        className="fs-16px mobile-search-icon"
        onClick={() => {
          setSearchVisible(!searchVisible);
          setSearchValue(null);
        }}
      />
    </div>
  );
};
