import React, { useState, useEffect } from "react";
import withDirty from "../../shared-react-components/components/hc/withDirty";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Container,
  Grid,
  Dropdown,
  Accordion,
  Icon,
  Loader,
  Modal,
} from "semantic-ui-react";
import PageStandard from "../../components/elements/PageStandard";
import { useHistory, useLocation, useParams } from "react-router";
import useCart from "../../components/hc/useCart";
import useApp from "../../components/hc/useApp";
// import TestData from './testData.json';
import CustomFilters from "../../components/common/Filters/customFilters";
import TitleRow from "../../components/common/TitleRow";
// import { Divider } from '../../components/elements/elements';
import _ from "underscore";
import "./Catalog.scss";
import FOCStatus from "../../components/common/FOCStatus";
// import catalogIcon from '../../assets/images/catalog.svg';
//import DownloadDropdown from '../../components/common/DownloadDropdown';
import NotificationBanners from "../../components/common/NotificationBanners";
import NotificationBanner from "../../components/common/NotificationBanner";
import { ConsoleView } from "react-device-detect";
import { BackToTopButton } from "../../components/common/BackToTopButton";
import { Export } from "../../utils/Export";
import { faFileCsv, faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import VariantRestrictionsOrderingModal from "../../components/modals/VariantRestrictionsOrderingModal";
import { isCurrentYear } from "../../utils/utilities";
import CatalogList from "./components/CatalogList";
import ArchiveCatalogs from "./components/ArchiveCatalogs";
import truncate from "truncate-html";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import { MobileOptionsMenu } from "../../components/header/components/MobileOptionsMenu";
import ArchiveCatalogsMobile from "./components/ArchiveCatalogsMobile";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";
import { useLazyGetCatalogArchiveQuery } from "../../api/catalogApi";

const CatalogArchive = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  //const [filters, setFilters] = useState(undefined);
  const [archives, setArchives] = useState([]);
  const d = new Date();
  let currentYear = d.getFullYear();
  // const currentYear = '2022';
  const [year, setYear] = useState(`${currentYear}`);
  const [loading, setLoading] = useState(true);
  const [downloadDropdownOptions, setDownloadDropdownOptions] = useState([]);
  let dummy = [<p>Nothing found</p>];
  const match = useParams();
  const history = useHistory();
  const location = useLocation();
  const Cart = useCart();
  const app = useApp();
  const [catalogId, setCatalogId] = useState("");
  const [filtersCount, setFiltersCount] = useState(0);
  const [filters, setFilters] = useState([]);
  const [filterModel, setFilterModel] = useState({});
  const [numOfResults, setNumOfResults] = useState(0);
  const [filteredResults, setFilteredResults] = useState([]);
  const [originalResults, setOriginalResults] = useState([]);
  const [currentSort, setCurrentSort] = useState("foc");
  const [customerLoaded, setCustomerLoaded] = useState(false);
  const [numOfSortedGroups, setNumOfSortedGroups] = useState([]);
  const [focDatesHeaderString, setFocDatesHeaderString] = useState("");
  const [accordionState, setaccordionState] = useState({});
  const [catalogDownloadData, setCatalogDownloadData] = useState([]);
  const [variantFilter, setVariantFilter] = useState(true);
  const [performGetCatalogArchive, getCatalogArchiveResult] =
    useLazyGetCatalogArchiveQuery();

  const resultsSortOPtions = [
    {
      key: "foc",
      text: "Sort By: Upcoming FOC",
      value: "foc",
    },
    {
      key: "divisionName",
      text: "Sort By: Publisher",
      value: "divisionName",
    },
    {
      key: "title",
      text: "Sort By: Title(A-Z)",
      value: "title",
    },
  ];

  useEffect(() => {
    async function asyncCall() {
      const archiveData = await performGetCatalogArchive();
      setArchives(archiveData.data.data);
      setFilters({
        ...archiveData.data.data.filters[0].values,
      });
      setLoading(false);
    }
    asyncCall();
  }, []);

  // useEffect(() => {
  //   if (originalResults.length > 0) {
  //     setFilteredResults(originalResults);
  //   }
  // }, [originalResults]);

  const setCurrYearParent = (i) => {
    // the callback. Use a better name
    /*
    setstate({
      ...state,
      currYear: i,
    });
    */
  };

  const getPastArchive = (date) => {
    let archive = [];
    const { archives } = this.state;
    archives.results &&
      archives.results.map((result) => {
        if (result.year == date) {
          archive = result.past;
        }
      });
    this.setState({ pastArchive: archive });
  };

  const getCurrentArchive = (date) => {
    let archive = [];
    const { archives } = this.state;
    archives.results &&
      archives.results.map((result) => {
        if (result.year == date) {
          archivesCurrent = result.current;
        }
      });
    this.setState({ current: archive });
  };

  const parseArchives = (date, type) => {
    /*
    data.results && data.results.map((result, i) =>{
        archivesCurrent[result.year]['current']=result.current;
        archivesCurrent[result.year]['past']=result.past;
    });
    */
    const archive = state.archives.results.filter((result) => {
      return result.year === date;
    });
    return archive[0][mytype];
  };

  const clearResultFilters = (e, data) => {
    const dFiltersArray = filters;
    for (let i = 0; i < dFiltersArray.length; i++) {
      for (let j = 0; j < dFiltersArray[i].values.length; j++) {
        dFiltersArray[i].values[j].checked = false;
      }
    }
    createFilterModel(dFiltersArray);
    setFilteredResults(originalResults);
    setVariantFilter(true);
    // document.getElementById('variant').checked = true;
  };

  const updateResults = (e, data) => {
    const dFiltersArray = filters;
    for (let i = 0; i < dFiltersArray.length; i++) {
      if (dFiltersArray[i].resultsMatchID === data.name) {
        for (let j = 0; j < dFiltersArray[i].values.length; j++) {
          if (dFiltersArray[i].values[j].match === data.value) {
            dFiltersArray[i].values[j].checked = data.checked;
          }
          if (data.name === "isVariant") {
            if (data.checked === false) {
              setVariantFilter(false);
              dFiltersArray[i].values[j].checked = true;
            } else {
              setVariantFilter(true);
              dFiltersArray[i].values[j].checked = false;
            }
          }
        }
        const newFilters = createFilterModel(dFiltersArray);
        const filtersKeysArray = Object.keys(newFilters);

        let filteredRows = [];
        let validTitle = 0;
        const totalfilters = filtersKeysArray.length;

        _.each(originalResults, function (title) {
          _.each(filtersKeysArray, function (filter) {
            if (newFilters[filter].indexOf(title[filter]) != -1) {
              validTitle++;
            }
          });
          if (validTitle === totalfilters) {
            filteredRows.push(title);
          }
          validTitle = 0;
        });
        setFilteredResults(filteredRows);
      }
    }
  };

  const getTodaysDate = () => {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const sortObj = (obj) => {
    return Object.keys(obj)
      .sort()
      .reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
  };

  const getAccordionTitleDate = (accDate) => {
    const tempDate = accDate.split("-");
    const newAccDate = tempDate[1] + "/" + tempDate[2] + "/" + tempDate[0];
    return newAccDate;
  };

  const AccordionPanels = () => {
    let passedDateFOCs = {};
    let validDateFOCs = {};
    const todaysDate = getTodaysDate();
    const onSaleSortedResults = _.sortBy(filteredResults, "onsale");
    const focSortedResults = sortObj(_.groupBy(onSaleSortedResults, "foc"));

    _.each(focSortedResults, function (value, key) {
      key < todaysDate
        ? (passedDateFOCs[key] = value)
        : (validDateFOCs[key] = value);
    });

    let newPanels = [];
    let i = 0;
    let accordionTitlePara = "";

    if (currentSort === "foc") {
      //valid FOC panels
      _.each(validDateFOCs, function (value, key) {
        const TitleRowsArray = value.map((title, key) => {
          return (
            <TitleRow
              data={title}
              pageType='catalog'
              divider={true}
              key={`valid-accord-title-${key}`}
            />
          );
        });
        const panelItem = {
          key: key,
          title: {
            content: (
              <div className='d-flex w-100 justify-content-between align-items-center'>
                <div>FOC {getAccordionTitleDate(key)}</div>
                <FOCStatus focDate={key}></FOCStatus>
              </div>
            ),
            // icon: `${active ? 'chevron down' : 'chevron right'}`,
          },
          content: TitleRowsArray,
        };
        newPanels.push(panelItem);
        i++;
      });

      //expired FOC panels
      _.each(passedDateFOCs, function (value, key) {
        const TitleRowsArray = value.map((title, key) => (
          <TitleRow
            data={title}
            pageType='catalog'
            divider={true}
            key={`accord-title-${key}`}
          />
        ));
        const panelItem = {
          key: key,
          title: {
            content: (
              <div className='d-flex w-100 justify-content-between align-items-center'>
                <div>FOC {getAccordionTitleDate(key)}</div>
                <FOCStatus focDate={key}></FOCStatus>
              </div>
            ),
            // icon: `${active ? 'chevron down' : 'chevron right'}`,
          },
          content: TitleRowsArray,
        };
        newPanels.push(panelItem);
        i++;
      });
    } else {
      const onSaleDateSortedResults = sortObj(
        _.groupBy(_.sortBy(filteredResults, "onsale"), currentSort)
      );
      _.each(onSaleDateSortedResults, function (value, key) {
        const TitleRowsArray = value.map((title, key) => (
          <TitleRow
            data={title}
            pageType='catalog'
            divider={true}
            key={`valid-accord-title-${key}`}
          />
        ));
        const panelItem = {
          key: key,
          title: {
            content: (
              <div className='d-flex w-100 justify-content-between align-items-center'>
                <div>
                  {accordionTitlePara} {value[0].divisionName}
                </div>
                {/* <FOCStatus focDate={key}></FOCStatus> */}
              </div>
            ),
            // icon: `${active ? 'chevron down' : 'chevron right'}`,
          },
          content: TitleRowsArray,
        };
        newPanels.push(panelItem);
        i++;
      });
    }

    return (
      <Accordion
        defaultActiveIndex={Array.from(Array(i).keys())}
        panels={newPanels}
        exclusive={false}
        fluid
        key={`accord-${i}`}
      />
    );
  };

  const createFilterModel = (filtersArray) => {
    const dFiltersArray = filtersArray;
    const customFilters = {};
    let filterCount = 0;
    // this.setState({ filtersCount: 0 });
    for (let i = 0; i < dFiltersArray.length; i++) {
      const dFilter = dFiltersArray[i];
      const filterOptions = dFilter.values;
      const filterValues = [];
      let hasFilter = false;

      for (let j = 0; j < filterOptions.length; j++) {
        if (filterOptions[j].checked === true) {
          if (filterOptions[j].match !== "None") {
            filterCount++;
            filterValues.push(filterOptions[j].match);
          } else {
            filterCount++;
            filterValues.push(null);
          }
          hasFilter = true;
        }
      }
      if (hasFilter === true) {
        customFilters[dFilter.resultsMatchID] = filterValues;
      }
    }
    setFiltersCount(filterCount);
    setFilterModel(customFilters);
    return customFilters;
  };

  const handleSortChange = (value) => {
    let sortedRows = [];
    setCurrentSort(value);
    sortedRows = _.sortBy(filteredResults, value);
    setFilteredResults(sortedRows);
  };

  const createTitleSortedRows = () => {
    const titleSortedResults = _.sortBy(filteredResults, "title");
    return titleSortedResults.map((title, key) => (
      <TitleRow data={title} pageType='catalog' divider={true} />
    ));
  };

  // const createCatalog = async (cID) => {
  //   const catalogData = await Cart.getCatalog(cID);
  //   console.log("jeff look at this", catalogData);
  //   if (catalogData.status === 200) {
  //     if (catalogData.data.status === "OK") {
  //       clearResultFilters();
  //       setOriginalResults(catalogData.data.data.results);
  //       setNumOfResults(catalogData.data.data.resultCount);
  //       setFilters(catalogData.data.data.filters);
  //       setFilteredResults(catalogData.data.data.results);
  //       const groupedResults = _.groupBy(
  //         _.sortBy(catalogData.data.data.results, "foc"),
  //         "foc"
  //       );
  //       let focDatesHeader = [];
  //       const focDatesArray = Object.keys(groupedResults);
  //       if (focDatesArray.length !== 0) {
  //         focDatesHeader = focDatesArray.filter((f) => {
  //           if (f != "null" && f != null && f !== "undefined") {
  //             return f;
  //           }
  //         });
  //         focDatesHeader = focDatesHeader.map((f) => {
  //           if (f !== "null" && f !== null && f !== "undefined") {
  //             const tempArray = f.split("-");
  //             return tempArray[1] + "/" + tempArray[2];
  //           }
  //         });
  //       }
  //       setFocDatesHeaderString(focDatesHeader.join(", "));
  //       getCatalogDownloadsInfo(cID);
  //     }
  //   } else {
  //     return <div>There is no catalog with this date</div>;
  //   }
  // };

  const handleCatDownload = async (downloadItem) => {
    let dataToPost = {};
    catalogDownloadData.forEach(function (item, index) {
      if (item.fileName === downloadItem) {
        dataToPost = item;
      }
    });
    const response = await Cart.getCatlogDownloadFile(dataToPost, catalogId);
    if (response.data?.status === "OK") {
      window.location.assign(response.data.data.downloadUrl);
    }
  };

  const getBacklistDownloadsInfo = (countryCode) => {
    Cart.getBacklistDownloadsInfo(countryCode).then((result) => {
      if (result.data.status === "OK") {
        const files = result.data.data.files;
        setCatalogDownloadData([...files]);
        const dropdownOptions = [];
        files.forEach(function (item, index) {
          dropdownOptions.push({
            key: item.fileName,
            text: (
              <div>
                <div>
                  {item.type === "covers" ? (
                    <FontAwesomeIcon
                      icon={faFileArchive}
                      className='mr-1'
                      color='grey'
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faFileCsv}
                      className='mr-1'
                      color='grey'
                    />
                  )}
                  {item.desc}
                </div>
              </div>
            ),
            value: item.fileName,
          });
        });
        setDownloadDropdownOptions(dropdownOptions);
      }
    });
  };

  const getCatalogDisplayTitle = () => {
    const dateArray = catalogId.split("-");
    // const d = new Date();
    const month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // d.setMonth(dateArray[1] - 1);
    // const monthName = d.toLocaleString('default', { month: 'long' });
    return month[parseInt(dateArray[1]) - 1] + " " + dateArray[0];
  };

  const [archiveOptionsMenuVisible, setArchiveOptionsMenuVisible] =
    useState(false);
  const filterOptionsForMobileMenu =
    filters &&
    Object.keys(filters).map((key) => {
      return {
        ...filters[key],
        key: filters[key].display,
        text: filters[key].display,
      };
    });

  if (!Cart.customerLoaded) {
    return <></>;
  } else if (customerLoaded === false) {
    setCustomerLoaded(true);
  }

  return (
    <>
      <Modal open={loading} dimmer={"inverted"}>
        <Loader inverted />
      </Modal>
      <PageStandard
        containerFluid={true}
        headerType='loggedIn'
        className='cop-container'
        locationSelector={true}
      >
        <div
          className={`d-flex w-100 mb-3 ${
            isMobile
              ? "flex-row p-2 justify-content-space-between"
              : "flex-column cop-header"
          }`}
        >
          {!isMobile && (
            <Container>
              <div className='d-flex justify-content-between'>
                <div className='d-flex flex-column cat-ttl-meta'>
                  <div className='catalog-title'>All Catalogs</div>
                </div>
                <div className='d-flex justify-content-end flex-row cat-dropdowns'>
                  <div className='catalog-titles-sort'>
                    {catalogId !== 0 && downloadDropdownOptions.length > 0 ? (
                      <div className='download-dropdown-div'>
                        <Dropdown
                          options={downloadDropdownOptions}
                          direction='left'
                          onChange={(e, v) => {
                            handleCatDownload(v.value);
                          }}
                          trigger={
                            <span style={{ marginRight: "10px" }}>
                              Download
                            </span>
                          }
                          // defaultValue={'Download'}
                          className={`download-dropdown-catalog`}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/*true && (
            <NotificationBanner
              catalogs={Cart.activeCatalogs}
              setShowNotifications={Cart.setShowNotifications}
              catalogAvailabilityDate={Cart.catalogAvailabilityDate}
              currentMonthCatalogAvailabilityDate={
                Cart.currentMonthCatalogAvailabilityDate
              }
              estimateDueDate={Cart.estimateDueDate}
              showNotifications={Cart.showNotifications}
              currentPage="catalog"
            />
            )*/}
              {/*true && (
            <NotificationBanners listenToPropertyForChange={Cart.shipTo} />
          )*/}
              {/* <VariantRestrictionsOrderingModal></VariantRestrictionsOrderingModal> */}
            </Container>
          )}
          {isMobile && (
            <>
              <div className='catalog-title'>Catalog Archive</div>
              <PRHButton
                className='mobile-options-button'
                onClick={() => setArchiveOptionsMenuVisible(true)}
              >
                {year} <Icon name='triangle down' />
              </PRHButton>
              <MobileOptionsMenu
                onClose={() =>
                  setArchiveOptionsMenuVisible(!archiveOptionsMenuVisible)
                }
                onOutsideMenuClick={() =>
                  setArchiveOptionsMenuVisible(!archiveOptionsMenuVisible)
                }
                menuItems={filterOptionsForMobileMenu}
                menuVisible={archiveOptionsMenuVisible}
                className='download-dropdown-catalog'
                onOptionClick={(option) => setYear(option.display)}
                currentSort={year}
                radio
              />
            </>
          )}
        </div>
        <Container className='position-relative cat-data'>
          <Grid>
            <Grid.Row>
              {!isMobile && (
                <>
                  <Grid.Column
                    mobile={16}
                    tablet={5}
                    computer={4}
                    largeScreen={3}
                    widescreen={3}
                    className='p-0'
                  >
                    <CatalogList
                      filters={filters}
                      setYear={setYear}
                      year={year}
                    />
                  </Grid.Column>
                  <Grid.Column
                    mobile={16}
                    tablet={11}
                    computer={12}
                    largeScreen={13}
                    widescreen={13}
                    className='p-0 catalog-rows'
                  >
                    {/*isCurrentYear(year) ? (
                  <>
                  <p className="fort-light fs-24px lh-28px lsp--05 text-gray-900 pb-3 border-bottom-gray-500">
                  {year} Catalogs
                  </p>
                  <ArchiveCatalogs archive={archives.results} currYear={year} type={'current'}/>
                  </>
                  ):''*/}
                    <p className='fort-light fs-24px lh-28px lsp--05 text-gray-900 pb-3 border-bottom-gray-500'>
                      {/*Past Catalogs*/}
                      {year} Catalogs
                    </p>
                    <ArchiveCatalogs
                      archive={archives.results}
                      currYear={year}
                      type={"all"}
                    />
                  </Grid.Column>
                </>
              )}
              {isMobile && (
                <Grid.Column
                  mobile={16}
                  tablet={5}
                  computer={4}
                  largeScreen={3}
                  widescreen={3}
                  className='p-0'
                >
                  <ArchiveCatalogsMobile
                    archives={archives?.results?.find(
                      (archive) => archive.year === year
                    )}
                  />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
          {!isMobile && <BackToTopButton />}
        </Container>
        {isMobile && <BackToTopButtonMobile />}
      </PageStandard>
    </>
  );
};

export default withDirty(CatalogArchive);
//CatalogArchive
//Catalog.propTypes = {};
