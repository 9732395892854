import { Component } from 'react';
import localforage from 'localforage';
import { SizeMe } from 'react-sizeme';
//import axios from "axios";
import withSecurity from '../../components/hc/withSecurity';
import withApp from '../../components/hc/withApp';
import withAccount from '../../components/hc/withAccount';
import MigrateCancel from './MigrateCancel';
import PageStandard from '../../components/elements/PageStandard';
import MigrateFormStep1 from '../../components/migrate/MigrateFormStep1';
import MigrateFormStep2 from '../../components/migrate/MigrateFormStep2';
import RegistrationResponse from '../../components/migrate/RegistrationResponse';
import { Loader, Modal } from 'semantic-ui-react';
import './migrate.scss';

class MigrateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    let userMigrationDescription = await localforage.getItem(
      'userMigrationDescription'
    );
    let userMigrationDisplayName = await localforage.getItem(
      'userMigrationDisplayName'
    );
  }

  async componentDidMount() {
    const { account } = this.props;
    account.setStateField ('authorizedAdministratorRequired', false);
  };

  handleChange = (e) => {
    const newState = { ...this.state };
    newState.legacyUserForm[e.target.name] = e.target.value;
    this.setState(newState);
    localforage.setItem('appState', this.state.legacyUserForm);
  };

  handleSubmitUser = () => {
    this.setState({ step: 2 }, this.updateUser);
  };

  updateUser = () => {
    const { legacyUserForm } = this.state;
    const userPostData = {
      user: {
        email: legacyUserForm.email,
        firstName: legacyUserForm.firstName,
        lastName: legacyUserForm.lastName,
        fullName: legacyUserForm.firstName + ' ' + legacyUserForm.lastName,
        userName: legacyUserForm.email,
        zipcode: '',
        mobile: '',
      },
      password: legacyUserForm.password,
      legacyToken: '',
    };
    this.props.app.registrationApi.createUser(userPostData);
  };

  nextStepHandler = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  previousStepHandler = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  setStepHandler = (e) => {
    this.setState({ step: e });
  };

  getLegacyUser = () => {
    const { legacyUserForm } = this.state;
    const newState = { ...this.state };
    const postData = {
      userName: legacyUserForm.userName,
      password: legacyUserForm.password,
    };
    const contentType = {
      'Content-Type': 'application/json',
    };
    newState.loading = true;
    this.setState(newState);
    this.props.app.registrationApi.getLegacyUser(postData).then((response) => {
      newState.loading = false;
      if (response.data.status === 'System_Error') {
        newState.systemResponse = 'System unavailable, please try again later.';
      } else if (response.data.status === 'OK') {
        newState.step = 2;
        newState.legacyUserForm.description = response.data.data.description
          ? response.data.data.description
          : '';
        newState.legacyUserForm.displayName = response.data.data.displayName
          ? response.data.data.displayName
          : '';
      } else {
        newState.step = 1;
        newState.systemResponse = response.data.message;
      }
      this.setState(newState);
    });
  };

  async isCache() {
    let userMigrationDescription = await localforage.getItem(
      'userMigrationDescription'
    );
    let userMigrationDisplayName = await localforage.getItem(
      'userMigrationDisplayName'
    );
    if (userMigrationDescription === null && userMigrationDisplayName == null)
      return false;
    else return true;
  }

  render() {
    //const { step, loading } = this.state;
    const { account } = this.props;
    return (
      <div className={'registration'}>
        <SizeMe monitorWidth={true}>
        {({ size }) => (
        <PageStandard jeff={true}>
          <Modal open={account.formState.loading}>
            <Loader inverted></Loader>
          </Modal>
          <div className={'register-page-header fort-bold'}>Update Account</div>
          {account.formState.step === 1 ? (
            <MigrateFormStep1
              handleSubmitUser={this.getLegacyUser}
              formState={account.formState}
            />
          ) : (
            ''
          )}
          {account.formState.step === 2 ? (
            <MigrateFormStep2
              handleSubmitUser={this.handleSubmitUser}
              handleChange={this.handleChange}
              formState={account.formState}
              size={size}
            />
          ) : (
            ''
          )}
          {account.formState.step === 3 ? (
            <MigrateCancel formState={account.formState} />
          ) : (
            ''
          )}
          {account.formState.step === 4 ? (
            <RegistrationResponse formState={account.formState} />
          ) : (
            ''
          )}
        </PageStandard>)}
        </SizeMe>
      </div>
    );
  }
}

export default withSecurity(withApp(withAccount(MigrateAccount)));
