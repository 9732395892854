import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Grid, Icon } from "semantic-ui-react";
import LazyImage from "../../../utils/LazyImage";
import "./DeliveriesCartonsRows.scss";

export const CartonRows = (props) => {
  const {
    cartons,
    testme,
    deliveryNo,
    setScrollToDelivery,
    ref,
    isMobile,
    cartonCt,
    shipDateMsg,
  } = props;
  const [viewAllCartons, setViewAllCartons] = useState(false);

  const cartonRef = useRef(null);
  // console.log("deliveryNo 2", deliveryNo, "testme", testme, "ref", ref);

  function setViewStatus(viewStatus) {
    setViewAllCartons(!viewStatus);
  }

  function scrollToMe() {
    // console.log("scroll to me", cartonRef?.current);
    cartonRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <div ref={cartonRef} className={deliveryNo} />

      {cartons &&
        cartons?.map((carton, i) =>
          // console.log("deliveryNo 3", deliveryNo),
          i < 10 || viewAllCartons ? ( //10 is correct
            <Grid.Row className='deliveries-data-row'>
              <Grid.Column
                mobile={10}
                computer={2}
                tablet={3}
                className='deliveries-data-column'
              >
                Carton {carton.cartonSeq} of {cartonCt}
                {isMobile && (
                  <>
                    <br />
                    {carton.trackingLink ? (
                      <a href={carton.trackingLink} target='_blank'>
                        {carton.shipper} {carton.trackingCode}{" "}
                        <Icon name='external alternate' />
                      </a>
                    ) : (
                      <span>Tracking link unavailable</span>
                    )}
                    <br />
                    {carton.itemCt} items{" "}
                    <span className='deliveries-divider'>|</span>{" "}
                    {carton.unitCt} units
                  </>
                )}
              </Grid.Column>
              <Grid.Column
                only='computer tablet'
                computer={3}
                tablet={3}
                className='deliveries-data-column'
              >
                {carton.itemCt} items{" "}
                <span className='deliveries-divider'>|</span> {carton.unitCt}{" "}
                units
              </Grid.Column>
              <Grid.Column computer={4} tablet={6} only='computer tablet'>
                {/* {console.log("carton.trackingLink", carton.trackingLink)} */}
                {carton.trackingLink ? (
                  <a href={carton.trackingLink} target='_blank'>
                    {carton.shipper} {carton.trackingCode}{" "}
                    <Icon name='external alternate' />
                  </a>
                ) : (
                  <span>Tracking link unavailable</span>
                )}
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
          ) : (
            ""
          )
        )}
      {cartons.length > 10 ? ( //10 is correct
        <Grid.Row className='deliveries-data-row'>
          <Grid.Column></Grid.Column>
          <Grid.Column only='computer tablet'></Grid.Column>
          <Grid.Column only='computer tablet' width={4}></Grid.Column>
          <Grid.Column computer={16}>
            <div
              style={{
                color: "#0078A6",
                marginLeft: "auto",
                marginRight: "auto",
                width: "150px",
                textAlign: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setViewAllCartons(!viewAllCartons);
                scrollToMe();
              }}
            >
              {!viewAllCartons
                ? `View All Cartons (${cartons.length})`
                : "View Less"}
              <Icon name={!viewAllCartons ? "chevron down" : "chevron up"} />
            </div>
          </Grid.Column>
        </Grid.Row>
      ) : (
        ""
      )}
      {}
      {shipDateMsg && ( //10 is correct
        <Grid.Row className='multiple-dates-deliveries'>
          <Grid.Column></Grid.Column>
          <Grid.Column only='computer tablet'></Grid.Column>
          <Grid.Column only='computer tablet' width={4}></Grid.Column>
          <Grid.Column computer={16}>
            <div
              className='d-flex'
              style={{
                //color: '#0078A6',
                //marginLeft:'auto',
                //marginRight:'auto',
                width: "300",
                //textAlign:'center'
              }}
            >
              <LazyImage
                width='12'
                //className='text-black-50 mr-1'
                src='/images/info-circle.svg'
              ></LazyImage>
              <span style={{ marginLeft: "2px" }} className='ship-date-message'>
                Some cartons ship on different days. View delivery details to
                learn more.
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};
