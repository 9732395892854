import React, { useState } from "react";
import "./PreDeliveryReportsMobile.scss";
import { Link } from "react-router-dom";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import { Divider } from "../../components/elements/elements";

const docTypes = {
  F2: "Invoice",
  L2: "Debit Memo",
  G2: "Credit Memo",
  ZL2: "Debit Memo-Return",
  ZG2: "Credit Memo-Return",
};

function docTypeFormatter(params) {
  return docTypes[params.value];
}

function currencyFormatter(params) {
  const number = Number(
    (params.data && params.data.netValue) || params.value
  ).toFixed(2);
  return `$${isNaN(number) ? (0).toFixed(2) : number}`;
}

export const mobileTableColumnsOrders = [
  {
    label: "LOCATION",
    key: "shipTo",
  },
  {
    label: "DELIVERIES",
    key: "deliveryCount",
  },
  {
    label: "TOTAL QTY",
    key: "deliveryItemCount",
  },
];

export const mobileTableColumnsInvoices = [
  {
    label: "BILLING DOC",
    key: "billingDocNumber",
    valueFormatter: null,
  },
  {
    label: "DATE CREATED",
    key: "dateCreated",
    valueFormatter: null,
  },
  {
    label: "PURCHASE ORDER",
    key: "purchaseOrderNumber",
    valueFormatter: null,
  },
  {
    label: "SHIP TO ACCOUNT",
    key: "soldToAccount",
    valueFormatter: null,
  },
  {
    label: "DOC TYPE",
    key: "docType",
    valueFormatter: docTypeFormatter,
  },
  {
    label: "NET VALUE",
    key: "netValue",
    valueFormatter: currencyFormatter,
    // valueFormatter: null
  },
];

const OrdersAndInvoicesMobileTable = ({
  columns,
  data,
  cleanedData,
  noDataMessage,
  pageType = null,
}) => {
  if (pageType === "invoice") {
    const newData = data.sort((a, b) => {
      return new Date(a.dateCreated) > new Date(b.dateCreated) ? -1 : 1;
    });
  }

  const mobileData = data.map((d) => {
    d.tableData = [];
    if (columns) {
      for (const index in columns) {
        const value = columns[index].valueFormatter
          ? columns[index].valueFormatter({ value: d[columns[index].key] })
          : d[columns[index].key];
        let link = null;
        if (columns[index].key === "salesOrderNumber") {
          link = `/orders/${d.salesOrderNumber}`;
        } else if (columns[index].key === "billingDocNumber") {
          link = `/invoices/${d.billingDocNumber}`;
        } else {
          link = null;
        }
        d.tableData.push({
          label: columns[index].label,
          key: columns[index].key,
          valueFormatter: columns[index].valueFormatter,
          value: value,
          //header:
          //link: link,
        });
      }
    }
    d.tableData.push({ header: d.onSaleDate });
    return d;
  });
  // .reverse();
  /*
  return (
    <div className="pdr">
    <div className="mobile-table">
      {mobileData && mobileData.length === 0 && <>{noDataMessage}</>}
      {console.log('cleanedData', cleanedData)}
      {mobileData &&
        mobileData.length > 0 &&
        mobileData.map((data, index) => {
          console.log('data new', data)
          return (
            <React.Fragment key={`${data.salesOrderNumber}_${index}`}>
              {data.onSaleDate ? <div className={'header'}><b>{formatDateMMDDYYYY(data.onSaleDate, '/')}</b></div>:''}
              {data.tableData &&
                data.tableData.map((d, idx) => {
                  return d.link ? (
                    <div
                      className={`cell row ${
                        idx === data.tableData.length - 1 ||
                        idx === data.tableData.length - 2
                          ? 'mb-0'
                          : ''
                      }`}
                      key={`${d.value}_${index}`}
                    >
                      <div>{d.label}</div>
                    </div>
                  ) : (
                    <>
                    <div
                      className={`cell row ${
                        idx === data.tableData.length - 1 ||
                        idx === data.tableData.length - 2
                          ? 'mb-0'
                          : ''
                      }`}
                      key={`${d.value}_${index}`}
                    >
                      <div>{d.label}</div>
                      <div>{d.value}</div>
                    </div>
                    </>
                  );
                })}
              {index < mobileData.length - 1 && (
                <Divider tight className="mt-2 mb-2" />
              )}
            </React.Fragment>
          );
        })}
    </div>
    </div>
  );
*/
  return (
    <div className='pdr'>
      <div className='mobile-table'>
        {mobileData && mobileData.length === 0 && <>{noDataMessage}</>}
        {console.log("cleanedData", cleanedData)}
        {cleanedData &&
          cleanedData.length > 0 &&
          cleanedData.map((data, index) => {
            return (
              <React.Fragment key={`${data.salesOrderNumber}_${index}`}>
                {data.onSaleDate ? (
                  <div className={"header"}>
                    <b>{formatDateMMDDYYYY(data.onSaleDate, "/")}</b>
                  </div>
                ) : (
                  ""
                )}
                <div className='meta-row'>
                  {data.shipTos.map((d, idx) => {
                    return (
                      <>
                        <div className={"cell-label row"} key={`${d}_${idx}`}>
                          <div>Location</div>
                        </div>
                        <div className={"cell row"} key={`${d}_${idx}`}>
                          <div>{d}</div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className='meta-row'>
                  {data.deliveries.map((d, idx) => {
                    return (
                      <>
                        <div
                          className={"cell-label row"}
                          key={`${d.deliveryCount}_${idx}`}
                        >
                          <div>Deliveries</div>
                        </div>
                        <div
                          className={"cell row"}
                          key={`${d.deliveryCount}_${idx}`}
                        >
                          <div>{d.deliveryCount}</div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className='meta-row'>
                  {data.deliveries.map((d, idx) => {
                    return (
                      <>
                        <div
                          className={"cell-label row"}
                          key={`${d.deliveryItemCount}_${idx}`}
                        >
                          <div>Total QTY</div>
                        </div>
                        <div
                          className={"cell-wider row"}
                          key={`${d.deliveryItemCount}_${idx}`}
                        >
                          <div>{d.deliveryItemCount}</div>
                        </div>
                      </>
                    );
                  })}
                </div>
                {/*
            data.tableData &&
              data.tableData.map((d, idx) => {
                return d.link ? (
                  <div
                    className={`cell row ${
                      idx === data.tableData.length - 1 ||
                      idx === data.tableData.length - 2
                        ? 'mb-0'
                        : ''
                    }`}
                    key={`${d.value}_${index}`}
                  >
                    <div>{d.label}</div>
                  </div>
                ) : (
                  <>
                  <div
                    className={`cell row ${
                      idx === data.tableData.length - 1 ||
                      idx === data.tableData.length - 2
                        ? 'mb-0'
                        : ''
                    }`}
                    key={`${d.value}_${index}`}
                  >
                    <div>{d.label}</div>
                    <div>{d.value}</div>
                  </div>
                  </>
                );
              })*/}
                {index < mobileData.length - 1 && (
                  <Divider tight className='mt-2 mb-2' />
                )}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
};

OrdersAndInvoicesMobileTable.defaultProps = {
  columns: [],
  data: [],
};

export default OrdersAndInvoicesMobileTable;
