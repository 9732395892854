import { Component, Fragment } from 'react';
import { isChrome, isIE, osName } from 'react-device-detect';
import { withRouter } from 'react-router';
import { Icon } from 'semantic-ui-react';
import { Divider } from '../../components/elements/elements';
import './OrderInvoice.scss';
import withMobile from '../../components/hc/withMobile';

class OIHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      hasFocus: false,
    };
  }
  render() {
    let { tabs, currentTab, mobile } = this.props;

    if (currentTab === undefined || currentTab === '') {
      currentTab = tabs[0].id;
    }

    const isMobile = mobile.isMobile;

    return (
      <div
        className={`home-tab-bar mb-3 ${
          isMobile ? 'mobile-home-tab-bar pt-2' : ''
        }`}
      >
        <div className="tb-row">
          {tabs.map((tab, idx) => (
            <Fragment key={idx}>
              {tab.id === 'divider' ? (
                !isMobile && <Divider key={idx} horizontal />
              ) : (
                <div
                  key={idx}
                  className={
                    'tb-column ' + (currentTab === tab.id ? ' active' : '')
                  }
                  onClick={(e) => this.props.tabChange(tab.id)}
                >
                  <span className={'ttb-tab'}>
                    {tab.icon === 'user' ? (
                      <Icon className="fs-2" name="user" />
                    ) : !isMobile ? (
                      <div />
                    ) : null}
                    {tab.icon === 'group' ? (
                      <Icon className="fs-2" name="group" />
                    ) : !isMobile ? (
                      <div />
                    ) : null}
                    <span
                      className={
                        isIE || (osName === 'Windows' && isChrome)
                          ? 'ttb-tab-desc'
                          : ''
                      }
                    >
                      {tab.name}
                    </span>
                  </span>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    );
  }

  search = (e) => {
    // console.log('submit', e);
    // this.props.searchLists(this.state.searchText);
  };

  searchInput = (e) => {
    // if (e.target.value === '') {
    //   this.props.searchLists(null);
    // }
    this.props.searchLists(e.target.value);
    this.setState({ searchText: e.target.value }); // just to track
  };

  moreTabSelect = (value) => {
    this.props.tabChange(value);
  };
}

export default withRouter(withMobile(OIHeader));
