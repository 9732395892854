import { Component } from 'react';
import localforage from 'localforage';
import { SizeMe } from 'react-sizeme';
import withAccount from '../../components/hc/withAccount';
import withApp from '../../components/hc/withApp';
import PageStandard from '../../components/elements/PageStandard';
import RegistrationStep1Form from '../../components/registration/RegistrationStep1Form';
import RegistrationStep2Form from '../../components/registration/RegistrationStep2Form';
import RegistrationCancel from '../../components/elements/RegistrationCancel';
import RegistrationResponse from '../../components/registration/RegistrationResponse';
import { Loader, Modal, Link } from 'semantic-ui-react';
import './registration.scss';

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      priorStep: 1,
      passwordsMatch: null,
      createPasswordValid: 1,
      confirmPasswordValid: 1,
      passwordMatchErrorMessage: "",
      permissionsAccepted: 0,
      openPasswordValidationPopUp: false,
      passwordValidationScore: 0,
      showValidationErrors: false,
      loading: false,
      systemResponse: "Account not found.",
      stepSucceeded: null,
      validations: {
        firstName: null,
        lastName: null,
        sapAcct: null,
      },
      createUserForm: {
        san: "",
        sapAcct: "",
        sapSAN: "",
        firstName: "",
        lastName: "",
        createPassword: "",
        confirmPassword: "",
        email: "",
        mobile: "",
        authorizedAdministrator: false,
      },
      createUserPost: {
        san: "",
        sapAcct: "",
        sapSAN: "",
        firstName: "",
        lastName: "",
        fullName: "",
        userName: "",
        password: "",
        email: "",
        mobile: "",
      },
    };
  }

  handleChange = (e) => {
    const newState = { ...this.state };
    if (e.target.name === "authorizedAdministrator") {
      newState.createUserForm[e.target.name] = e.target.checked;
    } else {
      newState.createUserForm[e.target.name] = e.target.value;
    }
    this.setState(newState);
    localforage.setItem("appState", this.state.createUserForm);

    const { createUserForm } = this.state;
    this.checkPassword(
      createUserForm.createPassword,
      createUserForm.firstName,
      createUserForm.lastName
    );
    if (e.target.name === "createPassword") {
      if (
        createUserForm.createPassword !== "" &&
        this.checkCreatePassword() < 7
      ) {
        this.setState({ openPasswordValidationPopUp: true });
      } else {
        this.setState({ openPasswordValidationPopUp: false });
      }
    }

    if (e.target.name === "confirmPassword") {
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({ passwordsMatch: 0 });
      } else {
        this.setState({ passwordsMatch: 1 });
      }
    }
  };

  handleBlur = (e) => {
    const { createUserForm } = this.state;
    if (e.target.name === "confirmPassword") {
      if (createUserForm.createPassword !== createUserForm.confirmPassword) {
        this.setState({
          passwordsMatch: 0,
          passwordMatchErrorMessage: "passwords must match",
        });
      } else {
        this.setState({ passwordsMatch: 1, passwordMatchErrorMessage: "" });
      }
    }
    if (e.target.name === "createPassword") {
      this.setState({ openPasswordValidationPopUp: false });
    }
  };

  handleClickCreatePassword = (e) => {
    const { createUserForm } = this.state;
    this.checkPassword(createUserForm.createPassword);
    if (this.checkCreatePassword() < 7) {
      this.setState({ openPasswordValidationPopUp: true });
    }
  };

  handleClickConfirmPassword = (e) => {
    this.setState({ passwordMatchErrorMessage: "" });
  };

  handleSubmitUser = () => {
    this.checkPassword();
    const {
      passwordValidationScore,
      createUserForm,
      passwordsMatch,
    } = this.state;
    if (
      passwordValidationScore === 8 &&
      createUserForm.authorizedAdministrator === true
    ) {
      this.setCreateUserPost();
    } else {
      if (passwordValidationScore !== 8) {
        this.setState({
          openPasswordValidationPopUp: true,
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (passwordsMatch === 0) {
        this.setState({
          createPasswordValid: 0,
          confirmPasswordValid: 0,
        });
      }
      if (createUserForm.authorizedAdministrator === false) {
        this.setState({ showValidationErrors: true });
      }
    }
  };

  setCreateUserPost = () => {
    this.setState((state) => {
      return {
        createUserPost: {
          san: state.createUserForm.san,
          sapSAN: state.createUserForm.san,
          sapAcct: state.createUserForm.sapAcct,
          firstName: state.createUserForm.firstName,
          lastName: state.createUserForm.lastName,
          fullName:
            state.createUserForm.firstName +
            " " +
            state.createUserForm.lastName,
          userName: state.createUserForm.email,
          email: state.createUserForm.email,
          mobile: state.createUserForm.mobile,
          password: state.createUserForm.createPassword,
        },
      };
    }, this.postUser);
  };

  nextStepHandler = () => {
    const { step } = this.state;
    this.setState({ step: step + 1, priorStep: step });
  };

  previousStepHandler = () => {
    const { step, priorStep } = this.state;
    this.setState({ step: priorStep, priorStep: priorStep - 1 });
  };

  setStepHandler = (e) => {
    this.setState({ step: e });
  };

  readResponse = (response, test) => {
    let responseTest = false;
    if (response && response.indexOf(test) !== -1) {
      responseTest = true;
    }
    return responseTest;
  };

  postUser = () => {
    const { createUserPost } = this.state;
    const newState = { ...this.state };
    const userPostData = {
      user: {
        email: createUserPost.email,
        firstName: createUserPost.firstName,
        lastName: createUserPost.lastName,
        fullName: createUserPost.fullName,
        userName: createUserPost.userName,
        groups: { biz: "admin" },
        sapAcct: createUserPost.sapAcct,
        sapSAN: createUserPost.san,
        pid: "1234",
        san: null,
        zipcode: createUserPost.zipcode,
        mobile: createUserPost.mobile,
      },
      password: createUserPost.password,
    };
    newState.loading = true;
    this.setState(newState);
    // console.log('registration userPostData', userPostData);
    this.props.app.registrationApi
      .createUser(userPostData)
      .then((userReturn) => {
        // console.log('registration userReturn', userReturn);
        newState.loading = false;
        if (userReturn.data.status === "System_Error") {
          newState.systemResponse =
            "System unavailable, please try again later.";
          newState.stepFailed = true;
        } else if (userReturn.data.status === "OK") {
          newState.step = 4;
          newState.stepFailed = false;
          newState.userReturn = userReturn.data.data;
          newState.createResponse = "created";
        } else if (userReturn.data.status === "CONFLICT") {
          if (this.readResponse(userReturn.data.message, "selfreg")) {
            newState.step = 4;
            newState.stepFailed = false;
            newState.createResponse = "self_registered";
          } else {
            newState.step = 4;
            newState.stepFailed = false;
            newState.createResponse = "registered";
          }
        } else {
          newState.systemResponse =
            "Registration failed. Please try again or contact customer service";
          newState.stepFailed = true;
        }
        this.setState(newState);
      });
  };

  validateSAPAccount = () => {
    const { createUserForm } = this.state;
    const newState = { ...this.state };
    newState.loading = true;
    this.setState(newState);
    this.props.app.registrationApi
      .validateSAPAccount(createUserForm.san, createUserForm.sapAcct)
      .then((validation) => {
        newState.loading = false;
        if (validation.data.status === "System_Error") {
          newState.systemResponse =
            "System unavailable, please try again later.";
          newState.validations.sapAcct = false;
        } else if (validation.data.data.status.success === true) {
          if (this.readResponse(validation.data.data.status.message, "Admin")) {
            newState.stepFailed = false;
            newState.createResponse = "admin_exists";
          } else {
            newState.step = 2;
            newState.validations.sapAcct = true;
          }
        } else {
          newState.step = 1;
          newState.systemResponse = "Account not found.";
          newState.validations.sapAcct = false;
        }
        this.setState(newState);
      });
  };

  resendVerifyEmail = () => {
    const { createUserForm } = this.state;
    this.props.app.registrationApi
      .resendVerifyEmail(createUserForm.email)
      .then((response) => {
        if (response.data.status === "OK") {
          newState.createResponse = "resent";
        } else {
          newState.createResponse = "resent-failed";
        }
        this.setState(newState);
      });
  };

  sendResetPasswordLink = () => {
    const { createUserForm } = this.state;
    this.props.app.registrationApi
      .sendResetPasswordLink(createUserForm.email)
      .then((response) => {});
  };

  checkPassword() {
    const { createUserForm } = this.state;
    let validationScore = 0;
    if (
      !(
        createUserForm.createPassword.length < 6 ||
        createUserForm.createPassword.length > 24
      )
    ) {
      // console.log('pass length');
      validationScore = validationScore + 1;
    }
    if (/[a-zA-Z]/.test(createUserForm.createPassword.charAt(0))) {
      // console.log('a-zA-Z');
      validationScore = validationScore + 1;
    }
    if (/[0-9]/.test(createUserForm.createPassword)) {
      // console.log('0-9');
      validationScore = validationScore + 1;
    }
    if (/[A-Z]/.test(createUserForm.createPassword)) {
      // console.log('A-Z');
      validationScore = validationScore + 1;
    }
    if (/[a-z]/.test(createUserForm.createPassword)) {
      // console.log('a-z');
      validationScore = validationScore + 1;
    }
    if (!/[\"\\\^\&]/.test(createUserForm.createPassword)) {
      // console.log('["\\^&]');
      validationScore = validationScore + 1;
    }
    if (
      !(
        createUserForm.createPassword
          .toUpperCase()
          .indexOf(createUserForm.firstName.toUpperCase()) >= 0 &&
        createUserForm.createPassword
          .toUpperCase()
          .indexOf(createUserForm.lastName.toUpperCase()) >= 0
      )
    ) {
      // console.log('first naem last name');
      validationScore = validationScore + 1;
    }
    if (createUserForm.createPassword === createUserForm.confirmPassword) {
      validationScore = validationScore + 1;
    }
    this.setState({ passwordValidationScore: validationScore });
    return validationScore;
  }

  checkCreatePassword() {
    const { createUserForm } = this.state;
    let validationScore = 0;
    if (
      !(
        createUserForm.createPassword.length < 6 ||
        createUserForm.createPassword.length > 24
      )
    ) {
      // console.log('pass length');
      validationScore = validationScore + 1;
    }
    if (/[a-zA-Z]/.test(createUserForm.createPassword.charAt(0))) {
      validationScore = validationScore + 1;
    }
    if (/[0-9]/.test(createUserForm.createPassword)) {
      // console.log('0-9');
      validationScore = validationScore + 1;
    }
    if (/[A-Z]/.test(createUserForm.createPassword)) {
      // console.log('A-Z');
      validationScore = validationScore + 1;
    }
    if (/[a-z]/.test(createUserForm.createPassword)) {
      // console.log('a-z');
      validationScore = validationScore + 1;
    }
    if (!/[\"\\\^\&]/.test(createUserForm.createPassword)) {
      validationScore = validationScore + 1;
    }
    if (
      !(
        createUserForm.createPassword
          .toUpperCase()
          .indexOf(createUserForm.firstName.toUpperCase()) >= 0 &&
        createUserForm.createPassword
          .toUpperCase()
          .indexOf(createUserForm.lastName.toUpperCase()) >= 0
      )
    ) {
      validationScore = validationScore + 1;
    }
    return validationScore;
  }

  render() {
    const { step, loading } = this.state;
    const { account } = this.props;
    // console.log('registration',account);
    return (
      <>
        <div className={'registration'}>
          <SizeMe monitorWidth={true}>
            {({ size }) => (
              <PageStandard
                jeff={true}
                alignment="center"
                cartBadge={false}
                pageType={'registration-path'}
              >
                <Modal open={account.formState.loading}>
                  <Loader inverted></Loader>
                </Modal>
                <div className={'register-page-header fort-bold'}>
                  Register an Account
                </div>
                {account.formState.step === 1 ? <RegistrationStep1Form /> : ''}

                {account.formState.step === 2 ? (
                  <RegistrationStep2Form
                    handleChange={this.handleChange}
                    handleBlur={this.handleBlur}
                    handleClickCreatePassword={this.handleClickCreatePassword}
                    handleClickConfirmPassword={this.handleClickConfirmPassword}
                    handleSubmitUser={this.handleSubmitUser}
                    nextStepHandler={this.nextStepHandler}
                    previousStepHandler={this.previousStepHandler}
                    formState={this.state}
                    size={size}
                  />
                ) : (
                  ''
                )}

                {account.formState.step === 3 ? (
                  <RegistrationCancel
                    handleChange={this.handleChange}
                    previousStepHandler={this.previousStepHandler}
                    setStepHandler={(e) => this.setStepHandler(e)}
                    formState={this.state}
                  />
                ) : (
                  ''
                )}

                {account.formState.step === 4 ? (
                  <RegistrationResponse
                    formState={this.state}
                    resendVerifyEmail={this.resendVerifyEmail}
                    sendResetPasswordLink={this.sendResetPasswordLink}
                  />
                ) : (
                  ''
                )}
                {step === 5 ? <Loader active /> : ''}
              </PageStandard>
            )}
          </SizeMe>
        </div>
      </>
    );
  }
}

export default withAccount(withApp(Registration));
