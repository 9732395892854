import { Component } from 'react';
import { withRouter } from 'react-router';
import withSecurity from '../hc/withSecurity';
import withApp from '../hc/withApp';
import withAccount from '../hc/withAccount';
import PRHButton from '../../shared-react-components/elements/prhbutton';
import { Link } from 'react-router-dom';
import './registration.scss';

class UpdateResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    // console.log('reg response props', this.props);
    let asyncData = await this.props.app.registrationApi.getCacheItem(
      'createUser'
    );
    // console.log('asyncData', asyncData);
    this.setState({ createUser: asyncData });
  }

  render() {
    const {
      formState,
      resendVerifyEmail,
      sendResetPasswordLink,
      account,
    } = this.props;
    // console.log('update response', account.formState);
    return (
      <>
        <div className={'white-background-container margin-bottom-100'}>
          {account.formState.createResponse !== 'failure' && (
            <div className={'white-box-header fort-light'}>
              Registration Complete!
            </div>
          )}
          {account.formState.createResponse === 'admin_exists' ? (
            <>
              It looks like an admin has been created for your account. Please
              ask your admin to create an account for you or{' '}
              <Link to="#">contact support</Link>. 
            </>
          ) : (
            ''
          )}
          {account.formState.createResponse === 'self_registered' ? (
            <>
              It looks like you already have an account. Click{' '}
              <Link onClick={resendVerifyEmail} to="#">
                here
              </Link>{' '}
              to send an email to reset your password to{' '}
              {formState.createUserForm.email} or{' '}
              <Link to="#">contact support</Link>. 
            </>
          ) : (
            ''
          )}
          {account.formState.createResponse === 'registered' ? (
            <>
              It looks like you already have an account. Click{' '}
              <Link onClick={sendResetPasswordLink} to="#">
                here
              </Link>{' '}
              to send an email to reset your password to{' '}
              {formState.createUserForm.email} or{' '}
              <Link to="#">contact support</Link>. 
            </>
          ) : (
            ''
          )}
          {account.formState.createResponse === 'failure' ? (
            <>
              Your account creation failed. Please contact your administrator or{' '}
              <Link to="#">support</Link>. 
            </>
          ) : (
            ''
          )}
          {account.formState.createResponse === 'created' ? (
            <>
              <br />
              You're all set to begin purchasing books and managing your Penguin
              Random House account.
              <br />
              <br />
              <div className={'button-holder'}>
                <PRHButton
                  iconright
                  icon="arrow right"
                  size="large"
                  className={'float-right margin-left'}
                  borderColorOverride="blue"
                  textColorOverride="white"
                  textColorHoverOverride="blue"
                  backgroundColorOverride="blue"
                  backgroundHoverColorOverride="white"
                  iconColorOverride="white"
                  iconColorHoverOverride="blue"
                  onClick={(e) => this.props.history.push('/login')}
                >
                  Sign In
                </PRHButton>
              </div>
              <br />
              <br />
            </>
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
}

export default withRouter(withApp(withAccount(UpdateResponse)));
