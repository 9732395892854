import { Api } from "./api";

export const catalogApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    getCatalog: builder.query({
      query: ({ catalogDate, searchClass, country, shipTo }) => {
        // console.log("getCatalog", catalogDate, searchClass, country, shipTo);
        const headers = {};
        let data = {
          catalogDate: catalogDate,
          searchClass: searchClass,
          country: country,
        };
        if (shipTo) {
          data.shipTo = shipTo;
        }

        return {
          url: `/ecom/search/catalog?extra=true&seriesHistory=true`,
          method: "POST",
          headers,
          body: data,
        };
      },
      providesTags: ["catalog"],
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getCatalog", arg);
      },
    }),
    getActiveCatalogs: builder.query({
      query: () => {
        const headers = {};

        return { url: `/ecom/search/catalog/list`, headers };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getActiveCatalogs", arg);
      },
    }),
    getCatalogDownloadsInfo: builder.query({
      query: ({ catalogId }) => {
        const headers = {};

        return { url: `/ecom/downloads/?cataDate=${catalogId}`, headers };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getCatalogDownloadsInfo", arg);
      },
    }),
    getCatalogDownloadFile: builder.query({
      query: ({ data, catalogId }) => {
        const headers = {};

        return {
          url: `/ecom/downloads/?cataDate=${catalogId}&fileName=${data.fileName}`,
          headers,
        };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getCatlogDownloadFile", arg);
      },
    }),

    getCatalogArchive: builder.query({
      query: () => {
        const headers = {};

        return { url: `/ecom/search/catalog/archive`, headers };
      },
      async onQueryStarted(
        arg,
        { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
      ) {
        getState().ecom.debug &&
          console.log("onQueryStarted - getCatalogArchive", arg);
      },
    }),
  }),
});

export const {
  useGetCatalogQuery,
  useLazyGetCatalogQuery,
  useGetActiveCatalogsQuery,
  useLazyGetActiveCatalogsQuery,
  useGetCatalogDownloadsInfoQuery,
  useLazyGetCatalogDownloadsInfoQuery,
  useGetCatalogDownloadFileQuery,
  useLazyGetCatalogDownloadFileQuery,
  useGetCatalogArchiveQuery,
  useLazyGetCatalogArchiveQuery,
  usePrefetch,
} = catalogApi;
