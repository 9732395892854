import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import withApp from "../../components/hc/withApp";
import ExportFile from "../../assets/images/export-blue.svg";
import { Divider } from "../../components/elements/elements";
import { Export, ExportCSV } from "../../utils/Export";
import PreDeliveryReportsModal from "../../components/modals/PreDeliveryReportsModal";
import "./PreDeliveryReports.scss";
import { useMediaQuery } from "react-responsive";
import { MOBILE_SCREEN_WIDTH } from "../../utils/const";
import {
  useLazyGetPreDeliveryReportQuery,
  useLazyGetPreDeliveryReportsQuery,
} from "../../api/ecomApi";

const PRE_DELIVERY_REPORT_CSV_HEADER = [
  "Delivery Date",
  "Ship-to Account",
  "UPC/ISBN",
  "Title",
  "Publisher",
  "USD List Price",
  "CAD List Price",
  "On Sale Date",
  "Carton Quantity",
  "Total Receiving Quantity",
];

const PreDeliveryReports = ({ app, accountNo }) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_SCREEN_WIDTH}px)`,
  });
  const [dataLoaded, setDataLoaded] = useState(false);
  const [preDeliveryData, setPreDeliveryData] = useState([]);
  const [isPreDeliveryModalOpen, setIsPreDeliveryModalOpen] = useState(false);
  const [performGetPreDeliveryReports, getPreDeliveryReportsResult] =
    useLazyGetPreDeliveryReportsQuery();
  const [performGetPreDeliveryReport, getPreDeliveryReportResult] =
    useLazyGetPreDeliveryReportQuery();

  const getPreDeliveryData = async () => {
    const result = await performGetPreDeliveryReports(
      {
        sapAccountNo: accountNo,
      },
      true
    );
    console.log("predeliver", result);
    return result;
  };

  const getPreDeliveryReportData = async (reportDate, accountNo) => {
    const result = await performGetPreDeliveryReport(
      {
        reportDate,
        sapAccountNo: accountNo,
      },
      true
    );
    return result;
  };

  useEffect(() => {
    getPreDeliveryData().then(({ data }) => {
      if (data.status === "OK") {
        setPreDeliveryData([...data.data.deliveryDates]);
        setDataLoaded(true);
      }
    });
  }, []);

  const resolveDateFormat = (date) => {
    const day = date.slice(8);
    const month = date.slice(5, 7);
    const year = date.slice(0, 4);

    return `${month}/${day}/${year}`;
  };

  const handleDownloadPreDeliveryReport = (deliveryDate, shipTo) => {
    getPreDeliveryReportData(deliveryDate, shipTo).then((res) => {
      const rows = [...res.data.data.deliveryItems];
      const details = {
        fileName: `predelivery_${shipTo}_${deliveryDate}`,
        metadata: [],
        list: rows
          .map((row) => {
            return {
              Delivery_Date: row.deliveryDate,
              Ship_To: row.shipTo,
              ISBN: row.isbn,
              Title: row.title.replace(",", " "),
              Publisher: row.publisher,
              US_Price: row.usPrice,
              CA_Price: row.canPrice,
              On_Sale_Date: row.onSaleDate,
              Carton_Quantity: row.cartonQuantity,
              Total_Quantity: row.totalQuantity,
            };
          })
          .flat(),
      };
      ExportCSV.DocumentDetails(details);
    });
  };

  const PreDeliveryReportRow = ({ predeliveryReport }) => {
    const {
      deliveryDate,
      shipTo,
      addressStr,
      deliveryCount,
      deliveryItemCount,
    } = predeliveryReport;
    return (
      <>
        <Divider className='m-0' />
        <div
          className={`pre-delivery-report-row d-flex ${
            isMobile
              ? `flex-direction-column justify-content-left mt-1 mb-1`
              : `justify-content-space-between align-items-center`
          }`}
        >
          <div className='fort-bold pr-1'>
            {resolveDateFormat(deliveryDate)}
          </div>
          <div
            className={`d-flex justify-content-space-between  ${
              !isMobile ? "align-items-center w-80" : "w-100"
            }`}
          >
            <div
              className={`pr-1 fort-book-light fs-13px ${
                isMobile ? "w-100" : "w-70"
              }`}
            >
              {shipTo} | 
              {isMobile ? (
                <div>
                  Shipments: {deliveryCount} | Total Qty:{" "}
                  {deliveryItemCount.toString()}
                </div>
              ) : (
                <span>
                  Shipments: {deliveryCount} | Total Qty:{" "}
                  {deliveryItemCount.toString()}
                </span>
              )}
            </div>
            {!isMobile ? (
              <div className='d-flex flex-row'>
                <div className='pt-2px'>
                  <img
                    src={ExportFile}
                    width={13}
                    height={13}
                    alt='#'
                    className='mr-1'
                  />
                </div>
                <div className='cursor-pointer'>
                  <a
                    onClick={() =>
                      handleDownloadPreDeliveryReport(deliveryDate, shipTo)
                    }
                  >
                    Pre-delivery Report (CSV)
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  };

  if (!dataLoaded || !preDeliveryData) {
    return null;
  }

  return (
    <>
      {/*<div
        className={`${
          isMobile
            ? 'border-gray-300 border-radius-3px p-3 mb-3 d-flex flex-column mt-3 white-background mobile-catalogs-container'
            : 'border-gray-300 border-radius-3px pt-3 pl-3 pr-3 mb-5'
        }`}
      >*/}
      {/*<div
          className={`d-flex flex-row ${
            isMobile
              ? 'justify-content-space-between mb-2'
              : 'justify-content-space-between w-100 align-items-center mb-3'
          }`}
        >*/}
      {/*<p
            className={`mb-0  ${
              isMobile
                ? 'mb-0 fort-light text-gray-900 fs-20px'
                : 'fort-medium fs-16px lh-18px mt-0'
            }`}
          >*/}
      {/*isMobile ? 'Upcoming Deliveries' : 'UPCOMING DELIVERIES'*/}
      {/*</p>*/}
      {preDeliveryData.length > 5 && (
        <a
          onClick={() => setIsPreDeliveryModalOpen(true)}
          className={`${
            isMobile
              ? "d-flex align-items-center lsp--02 fs-13px lh-18 fort-book"
              : "align-self-center cursor-pointer"
          }`}
        >
          {isMobile ? "View All" : "View all Pre-delivery Reports"}
        </a>
      )}
      {/*</div>*/}
      {/* {isMobile ? '' : <Divider tight className="mt-2 mb-2" />} */}
      {preDeliveryData.slice(0, 5).map((predeliveryReport, i) => (
        <PreDeliveryReportRow predeliveryReport={predeliveryReport} key={i} />
      ))}
      {/*</div>*/}
      <PreDeliveryReportsModal
        isOpen={isPreDeliveryModalOpen}
        onClose={() => setIsPreDeliveryModalOpen(false)}
        preDeliveryReports={preDeliveryData}
        resolveDateFormat={resolveDateFormat}
        onDownloadPreDeliveryReport={handleDownloadPreDeliveryReport}
        accountNumber={accountNo}
      />
    </>
  );
};

export default withApp(withRouter(PreDeliveryReports));
