import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Checkbox, Container } from "semantic-ui-react";
import PageStandard from "../../components/elements/PageStandard";
import "./CartOverviewPage.scss";
import LazyImage from "../../utils/LazyImage";
import { Export, ExportCSV } from "../../utils/Export";
import { AddUPCISBNModal } from "../../components/modals/AddUPCISBNModal";
import moment from "moment";
import CartView from "./components/CartView";
import queryString from "query-string";
import { formatDateMMDDYYYY, isGeneralCart } from "../../utils/utilities";
import NotificationBanner from "../../components/common/NotificationBanner";
import ActionMenu from "../../components/common/ActionMenu";
import DownloadDropdown from "../../components/common/DownloadDropdown";
import DialogModal from "../../components/modals/DialogModal";
import { NumericFormat } from "react-number-format";
import { Divider } from "../../components/elements/elements";
import NoticeCard from "../../components/elements/NoticeCard";
import CartViewMobile from "./components/CartViewMobile";
import { BackToTopButton } from "../../components/common/BackToTopButton";
import { BackToTopButtonMobile } from "../../components/common/BackToTopButtonMobile";
import VariantRestrictionsCartsDeleteModal from "../../components/modals/VariantRestrictionsCartsDeleteModal";
import _ from "underscore";
import BridgeAddComicsTitlesModal from "../../components/modals/BridgeAddComicsTitlesModal";
import useCart from "../../components/hc/useCart";
import useMobile from "../../components/hc/useMobile";
import useDirtyHandler from "../../shared-react-components/components/hc/useDirtyHandler";
import NoAccessMessage from "../../components/common/NoAccessMessage";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import useSecurity from "../../components/hc/useSecurity";

const CartOverviewPage = (props) => {
  const match = useRouteMatch();
  const history = useHistory();
  const mobile = useMobile();
  const dirtyHandler = useDirtyHandler();

  const [shipTo, setshipTo] = useState(0);
  const [exportOptions, setExportOptions] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [comicsWishlistDialog, setComicsWishlistDialog] = useState(false);
  const [selectedEstimateCarts, setSelectedEstimateCarts] = useState({});
  const [variantCartsDeleteModal, setVariantCartsDeleteModal] = useState(false);
  const [listID, setListID] = useState("");
  const [listName, setListName] = useState(false);
  const [foc, setFoc] = useState(false);
  const [focVariantTitles, setFocVariantTitles] = useState([]);
  const cart = useCart();
  const user = useSecurity();

  const value = queryString.parse(location.search);

  useEffect(() => {
    if (!!!cart.isUserPow() || cart.customerInfo.currentUser.roleNo === "W1") {
      window.location.href = "/";
    }
    if (Object.prototype.hasOwnProperty.call(value, "listID")) {
      // console.log("has listID", value.listID);
      let listName = Object.prototype.hasOwnProperty.call(value, "listName")
        ? value.listName
        : "Comics Retailer"; //'Penguin Random House International'//'Penguin Randomhouse Retail'////'Penguin Randomhouse International Sales'//'Specialty Retailer';
      setListID(value.listID);
      setListName(listName);
      //setComicsWishlistDialog(true);
    }
  }, []);

  useEffect(() => {
    let workExportOptions = [];
    workExportOptions.push({
      key: "0",
      text: "Excel",
      value: "excel",
      icon: { name: "file excel outline", color: "orange" },
      content: "Excel",
      onClick: () => exportCart("excel"),
      key: "1",
      text: "CSV",
      value: "csv",
      icon: { name: "file outline", color: "orange" },
      content: "CSV",
      onClick: () => exportCart("csv"),
    });
    setExportOptions(workExportOptions);
    return () => {};
  }, []);

  const selectShipTo = (workShipTo) => {
    setshipTo(workShipTo);
  };

  const setConfirmDeleteDialogOpen = (o) => {
    setConfirmDeleteDialog(o);
  };

  const setConfirmDialogOpen = (o) => {
    setConfirmDialog(o);
  };

  const resetSelected = () => {
    setSelectedEstimateCarts({});
  };

  const removeCarts = (singleFoc = false) => {
    let foc = [];
    if (singleFoc) {
      foc = [singleFoc];
    } else {
      foc = Object.keys(selectedEstimateCarts);
    }
    cart.removeEstimateCarts({ foc }).then((x) => {
      // console.log('x', x);
      if (x.modalInfo === "REMOVE_VARIANTS") {
        let sortedfocVariantTitles = _.sortBy(x.focVariantTitles, (i) => {
          if (isGeneralCart(i.foc)) return "0000-00-00";
          else return i.foc;
        });
        setVariantCartsDeleteModal(true);
        setFoc(foc);
        setFocVariantTitles(sortedfocVariantTitles);
      } else {
        // console.log("co page load est remove");
        setConfirmDeleteDialog(false);

        // jeff
        cart.loadEstimateCarts({ forceTakeOver: true }).then((ec) => {});
      }
    });
  };

  const forceRemoveCarts = () => {
    let foc = Object.keys(selectedEstimateCarts);
    cart.removeEstimateCarts({ foc, force: true }).then((x) => {
      setConfirmDeleteDialogOpen(false);
      cart.loadEstimateCarts({ forceTakeOver: true }).then((ec) => {});
    });
  };

  const moveFocToGeneral = (props) => {
    const { foc, items } = props;

    cart.moveEstimateCartToGeneral({ foc, items }).then((x) => {
      // console.log("co move foc");
      // jeff
      cart.loadEstimateCarts().then((ec) => {});
    });
  };

  const exportCart = (fileType) => {
    // console.log('exportCart', e);
    if (fileType === "excel") {
      Export.Excel({
        fileName: `my-cart-${moment().format("YYYY-MM-DD")}`,
        csvData: cart.cart.items,
        sheetName: "data",
      });
    } else if (fileType === "csv") {
      ExportCSV.CSV({
        fileName: `my-cart-${moment().format("YYYY-MM-DD")}`,

        csvData: cart.cart.items,
        sheetName: "data",
      });
    }
  };

  const buildExportData = () => {
    let d = [];

    cart.estimateCarts.forEach((e) => {
      e.items.forEach((ei) => {
        let wi = {
          "Cart Name": isGeneralCart(ei.foc)
            ? "General cart"
            : `FOC ${formatDateMMDDYYYY(ei.foc, "-")}`,
          "ISBN / UPC": ei.isbn,
          Quantity: ei.quantity,
          Title: ei.title,
          Subtitle: ei.subtitle,
          Contributors: ei.author,
          "On Sale Date": ei.onsale,
          "Catalog Date": ei.catalog,
          "FOC Date": isGeneralCart(ei.foc) ? "General" : ei.foc,
          Format: ei.formatName,
          Publisher: ei.divisionName,
          "Carton Quantity": ei.cartonQuantity,
          "Inventory Status": ei.inventoryMsg,
          "Managed Stock Status": ei.managedMsg,
          "Est Delivery Date": formatDateMMDDYYYY(ei.deliveryDate, "/"),
          "Retail Unit Price": ei.priceSingle,
          "Total Retail Price": ei.priceTotal,
          "Base Discount %": ei.basePctDiscount,
          "Discounted Unit Price": ei.discountedPriceSingle,
          // ei.priceSingle - ei.priceSingle * ei.basePctDiscount,
          "Total Discounted Price": ei.discountedPriceTotal, //ei.sapBaseDiscountedPrice,
        };

        d.push(wi);
      });
    });

    return d;
  };

  // console.log("cart overview", cart);
  const shipToAccountNo = cart.getShipToAccount().accountNo;

  if (!cart.isUserPow()) {
    history.push({ pathname: "/cart" });
  }

  let totalItems = 0;
  let totalUnits = 0;
  let totalSubTotal = 0;

  let numSelected = 0;
  cart.estimateCarts.forEach((e) => {
    totalItems += e.items.length;
    e.items.forEach((i) => {
      totalUnits += i.quantity;
      totalSubTotal +=
        i.priceSingle * i.quantity -
        (i.priceSingle - i.discountedPriceSingle) * i.quantity;
    });
    selectedEstimateCarts[e.foc] && numSelected++;
  });

  const isMobile = mobile.isMobile;
  if (cart?.customerInfo?.currentUser?.roleNo === "W1")
    return (
      <>
        <PageStandard
          containerFluid={true}
          headerType='loggedIn'
          className='cart-overview-container'
          locationSelector={true}
        >
          <NoAccessMessage />
        </PageStandard>
      </>
    );
  else
    return (
      <PageStandard
        containerFluid={true}
        headerType='loggedIn'
        className='cart-overview-container'
        locationSelector={true}
      >
        <VariantRestrictionsCartsDeleteModal
          openState={variantCartsDeleteModal}
          setOpenState={(w) => {
            setVariantCartsDeleteModal(w);
          }}
          foc={foc}
          focVariantTitles={focVariantTitles}
          shipTo={shipTo}
          cancelCallback={() => {}}
          proceedCallback={() => {
            forceRemoveCarts();
          }}
        />
        <DialogModal
          open={confirmDeleteDialog}
          setOpen={setConfirmDeleteDialogOpen}
          onClose={resetSelected}
          onXClose={resetSelected}
          options={{ size: "small" }}
          modalContentClass='cop-clear-cart'
          dialogOptions={{
            header: "CLEAR ONE OR MORE CARTS",
            className: "fs-24px text-gray-300",
            actions: [
              {
                label: "Cancel",
                className: "ml-auto",
                preAction: () => ({ proceed: true }),
                action: () => {
                  return { proceed: true };
                },
              },
              {
                label: `Clear Carts ${
                  numSelected > 0 ? `(${numSelected})` : ""
                }`,
                disabled: numSelected === 0,
                borderColorOverride: "blue",
                textColorOverride: "white",
                textColorHoverOverride: "blue",
                backgroundColorOverride: "blue",
                backgroundHoverColorOverride: "white",
                iconColorOverride: "white",
                iconColorHoverOverride: "blue",
                iconColorDisabledOverride: "gray",
                icon: "check",
                preAction: () => {
                  setConfirmDialog(true);
                  return { proceed: false };
                },

                action: () => {
                  return { proceed: true };
                },
              },
            ],
          }}
        >
          <div className='modal-content--heading mb-2'>
            <div className='fort-book mb-2 pl-2 pr-2'>
              Select one or more carts to empty.
            </div>
            <Divider tight />
            <div className='mt-2 pl-2 pr-2'>
              {cart.estimateCarts.map((ec, i) => {
                const cartTotal = cart.getCartTotal(ec, true);
                return (
                  <div key={i}>
                    <div
                      key={i}
                      className='d-flex align-items-center mb-2 fort-book'
                    >
                      <Checkbox
                        disabled={ec.items.length === 0}
                        className='mr-1'
                        checked={!!selectedEstimateCarts[ec.foc]}
                        onChange={(e) => {
                          let newSelected = {
                            ...selectedEstimateCarts,
                          };
                          if (selectedEstimateCarts[ec.foc]) {
                            delete newSelected[ec.foc];
                          } else {
                            newSelected[ec.foc] = ec;
                          }
                          setSelectedEstimateCarts(newSelected);
                        }}
                      />
                      {ec.foc === "2099-12-31" ? (
                        <span
                          className='border-right-gray-300 mr-1 fort-bold'
                          style={{ paddingRight: "2.8rem" }}
                        >
                          General Cart
                        </span>
                      ) : (
                        <span className='border-right-gray-300 pr-1 mr-1 fort-bold'>
                          FOC {formatDateMMDDYYYY(ec.foc, "/")}
                        </span>
                      )}
                      <span className='border-right-gray-300 pr-1 mr-1'>
                        Items: {ec.items.length}
                      </span>
                      <span className='border-right-gray-300 pr-1 mr-1'>
                        Units: {cartTotal.unitCount}
                      </span>
                      <span className=''>
                        Subtotal:{" "}
                        <NumericFormat
                          className=''
                          value={cartTotal.subtotal}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          displayType={"text"}
                          prefix={"$"}
                        />
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <Divider tight />
          </div>
        </DialogModal>
        <DialogModal
          modalClassName='clear-carts-conf-modal'
          open={confirmDialog}
          setOpen={setConfirmDialogOpen}
          options={{ size: "mini" }}
          dialogOptions={{
            header: "CLEAR CART(S) CONFIRMATION",
            actions: [
              {
                label: "Cancel",
                className: "ml-auto",
                // color: 'red',
                // icon: 'times',
                preAction: () => ({ proceed: true }),
                action: () => ({ proceed: true }),
              },
              {
                label: "Yes, Continue",
                borderColorOverride: "blue",
                textColorOverride: "white",
                textColorHoverOverride: "blue",
                backgroundColorOverride: "blue",
                backgroundHoverColorOverride: "white",
                iconColorOverride: "white",
                iconColorHoverOverride: "blue",
                // color: 'green',
                // icon: 'checkmark',
                preAction: () => {
                  // this.discardChanges();
                  return { proceed: true };
                },

                action: () => {
                  removeCarts();
                  return { proceed: true };
                },
              },
            ],
          }}
        >
          <div className='d-flex flex-column'>
            <div className='mb-1'>
              Are you sure you want to clear these carts?
            </div>
            <div className=' mb-2'>
              <span className='fort-bold'>Note:</span> Confirmed quantities in
              your FOC carts will <span className='fort-bold'>not</span> be
              canceled.
            </div>
          </div>
        </DialogModal>
        {
          <BridgeAddComicsTitlesModal
            //open={comicsWishlistDialog}
            //setOpen={(x) => setComicsWishlistDialog(x)}
            listID={listID}
            listName={listName}
            open={comicsWishlistDialog}
            setOpen={(x) => setComicsWishlistDialog(x)}
            shipTo={cart.shipTo}
            //address={"STORE NAME | 0483370005 | ADDRESS"}
            //listID={331}
            //listName={"PRHComics"}
          />
        }
        <div className='d-flex flex-column w-100 cop-header mb-3'>
          <Container>
            {isMobile && (
              <NoticeCard margin='mt-0 mb-3 fort-book'>
                {/* Confirming and editing carts is available on desktop only. */}
                Checkout is accessible through desktop only.
              </NoticeCard>
            )}
            <div className='d-flex flex-row align-items-center'>
              <div className='d-flex flex-column w-100'>
                <div className='d-flex'>
                  <div className='fs-4'>
                    <LazyImage
                      width='24'
                      className='mr-1'
                      src='/images/carts-header-icon.svg'
                    />
                  </div>
                  <div
                    className='w-100 ml-1 mr-4 fort-light'
                    style={{ fontSize: "24px" }}
                  >
                    <div>My Carts</div>
                  </div>
                </div>
                <div
                  className={`d-flex flex-row fort-book fs-13px lh-18 mt-2 ${
                    isMobile ? "justify-content-space-between mt-3" : ""
                  }`}
                >
                  {!isMobile && (
                    <div className=' pr-1 border-right-gray-300'>
                      Total carts:{" "}
                      <NumericFormat
                        className=''
                        value={cart.estimateCarts.length}
                        thousandSeparator={true}
                        decimalScale={0}
                        fixedDecimalScale={true}
                        displayType={"text"}
                        // prefix={'$'}
                      />
                    </div>
                  )}
                  <div
                    className={`border-right-gray-300 ${
                      isMobile
                        ? "d-flex flex-direction-column align-items-center pr-4"
                        : "ml-1  pr-1"
                    }`}
                  >
                    {isMobile ? "TOTAL ITEMS" : `Total items:${" "}`}
                    <NumericFormat
                      className={`${isMobile ? "fort-book fs-16px" : ""}`}
                      value={totalItems}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      // prefix={'$'}
                    />
                  </div>
                  <div
                    className={`border-right-gray-300 ${
                      isMobile
                        ? "d-flex flex-direction-column align-items-center pr-4"
                        : "ml-1 pr-1"
                    }`}
                  >
                    {isMobile ? "TOTAL UNITS" : `Total units:${" "}`}
                    <NumericFormat
                      className={`${isMobile ? "fort-book fs-16px" : ""}`}
                      value={totalUnits}
                      thousandSeparator={true}
                      decimalScale={0}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      // prefix={'$'}
                    />
                  </div>
                  <div
                    className={`${
                      isMobile
                        ? "d-flex flex-direction-column align-items-center"
                        : "ml-1"
                    }`}
                  >
                    {isMobile ? "SUBTOTAL" : `Subtotal:${" "}`}
                    <NumericFormat
                      className={`${isMobile ? "fort-book fs-16px" : ""}`}
                      value={totalSubTotal}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      displayType={"text"}
                      prefix={"$"}
                    />
                  </div>
                </div>
              </div>
              {!isMobile && (
                <div className='ml-auto d-flex flex-row cart-toolbar align-items-center fb-100 justify-content-end'>
                  <AddUPCISBNModal
                    disabled={cart.shipTo < 0}
                    backgroundColorOverride='white'
                    addISBNsToList={[]}
                    onAddClick={(e) => {
                      cart
                        .loadEstimateCarts({ forceTakeOver: true })
                        .then((ec) => {
                          cart.clearCart();
                        });
                    }}
                  />

                  <DownloadDropdown
                    classes='ml-1'
                    fileName={`Carts-${shipToAccountNo}`}
                    data={buildExportData()}
                    disabled={cart.shipTo < 0}
                  />
                  <ActionMenu
                    backgroundColor={"gray-100"}
                    borderColor={"gray-100"}
                    actions={[
                      {
                        key: 1,
                        disabled: cart.estimateCarts.length > 0 ? false : true,
                        content: (
                          <div
                            onClick={(e) =>
                              cart.estimateCarts.length > 0 &&
                              setConfirmDeleteDialog(true)
                            }
                          >
                            Clear one or more carts
                          </div>
                        ),
                        value: "clearcart",
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </Container>
        </div>
        <Container>
          {shipToAccountNo &&
            cart.estimateCarts.map((foc, i) => {
              return (
                <NotificationBanner
                  key={i}
                  estimateCart={foc}
                  focDate={foc.foc}
                  shipTo={shipToAccountNo}
                />
              );
            })}
          {shipToAccountNo &&
          !dirtyHandler.loadingTakeover &&
          cart.estimateCarts.length === 0 ? (
            <div className='mt-6 mb-6 fort-bold'>
              You do not have any carts for this location.
            </div>
          ) : null}
          {!!!shipToAccountNo ? (
            <div className='mt-6 mb-6 fort-bold'>
              Please select a location to view your carts.
            </div>
          ) : null}
          {shipToAccountNo &&
          (cart.estimateCarts.length === 0 ||
            cart.estimateCarts.filter((c) => isGeneralCart(c.foc)).length ===
              0) ? (
            isMobile ? (
              <CartViewMobile
                cartName={"2099-12-31"}
                shipToAccountNo={shipToAccountNo}
                cart={{ items: [] }}
                removeCarts={removeCarts}
                moveFocToGeneral={moveFocToGeneral}
              />
            ) : (
              <CartView
                cartName={"2099-12-31"}
                shipToAccountNo={shipToAccountNo}
                cart={{ items: [] }}
                removeCarts={removeCarts}
                moveFocToGeneral={moveFocToGeneral}
              />
            )
          ) : null}
          {shipToAccountNo &&
            cart.estimateCarts
              .filter((c) => isGeneralCart(c.foc))
              .map((foc, i) =>
                isMobile ? (
                  <CartViewMobile
                    key={i}
                    cartName={foc.foc}
                    shipToAccountNo={shipToAccountNo}
                    cart={foc}
                    removeCarts={removeCarts}
                    moveFocToGeneral={moveFocToGeneral}
                  />
                ) : (
                  <CartView
                    key={i}
                    cartName={foc.foc}
                    shipToAccountNo={shipToAccountNo}
                    cart={foc}
                    removeCarts={removeCarts}
                    moveFocToGeneral={moveFocToGeneral}
                  />
                )
              )}
          {shipToAccountNo &&
            cart.estimateCarts
              .filter((c) => !isGeneralCart(c.foc))
              .map((foc, i) =>
                isMobile ? (
                  <CartViewMobile
                    key={i}
                    cartName={foc.foc}
                    shipToAccountNo={shipToAccountNo}
                    cart={foc}
                    removeCarts={removeCarts}
                    moveFocToGeneral={moveFocToGeneral}
                  />
                ) : (
                  <CartView
                    key={i}
                    cartName={foc.foc}
                    shipToAccountNo={shipToAccountNo}
                    cart={foc}
                    removeCarts={removeCarts}
                    moveFocToGeneral={moveFocToGeneral}
                  />
                )
              )}
        </Container>
        {!isMobile && <BackToTopButton />}
        {isMobile && <BackToTopButtonMobile />}
      </PageStandard>
    );
};

export default CartOverviewPage;
