import { Component } from "react";
import withSecurity from "../hc/withSecurity";
import { MenuVerticalPointing } from "../elements/adminVerticalMenu";
import { withRouter } from "react-router";
import "./admin.scss";
import withCart from "../hc/withCart";
import withMobile from "../hc/withMobile";
import { AdminMenuMobile } from "./AdminMenuMobile";

class AdminMenu extends Component {
  constructor(props) {
    super(props);

    const menu = [
      {
        name: "account-details",
        label: "Billing and Details",
        component: "acount-details",
      },
      {
        name: "account-reps",
        label: "Account Reps",
        component: "account-reps",
      },
      {
        name: "credit-cards",
        label: "Credit Carts",
        component: "credit-cards",
      },
      {
        name: "account-users",
        label: "Manage Users",
        component: "account-users",
      },
      {
        name: "change-password",
        label: "Change Password",
        component: "change-password",
      },
      // {
      //   name: 'notifications',
      //   label: 'Notifications',
      //   component: 'notifications',
      // },
      // {
      //   name: 'co-op-advertising',
      //   label: 'Co-op Advertising',
      //   component: 'co-op-advertising',
      // },
    ];

    if (this.props.isCartBadge)
      menu.push({
        name: "promotions",
        label: "Promotions",
        component: "promotions",
      });
    this.state = {
      menu,
    };
  }

  render() {
    // console.log('AdminMenu render', this.props);
    const { handleMenuItemClick, activeItem, header } = this.props;

    const menu = [
      {
        name: "account-details",
        label: "Billing and Details",
        component: "account-details",
      },
      /*
      {
        name: 'saved-credit-cards',
        label: 'Saved Credit Cards',
        component: 'saved-credit-cards',
      },
      */
      {
        name: "account-reps",
        label: "Account Reps",
        component: "account-reps",
      },
      {
        name: "account-users",
        label: "Manage Users",
        component: "account-users",
      },
      {
        name: "change-password",
        label: "Change Password",
        component: "change-password",
      },
      // {
      //   name: 'notifications',
      //   label: 'Notifications',
      //   component: 'notifications',
      // },
      // {
      //   name: 'co-op-advertising',
      //   label: 'Co-op Advertising',
      //   component: 'co-op-advertising',
      // },
    ];

    // if (this.props.isCartBadge && !this.props.cart.isUserPow())
    if (this.props.cart.customerInfo?.currentUser?.roleNo !== "W1") {
      menu.push({
        name: "promotions",
        label: "Promotions",
        component: "promotions",
      });
      menu.splice(1, 0, {
        name: "saved-credit-cards",
        label: "Saved Credit Cards",
        component: "saved-credit-cards",
      });
    }

    const isMobile = this.props.mobile.isMobile;

    return !isMobile ? (
      <MenuVerticalPointing
        menu={menu}
        header={header}
        activeItem={activeItem}
        handleMenuItemClick={(e) => handleMenuItemClick(e)}
      />
    ) : (
      <AdminMenuMobile
        menu={menu}
        activeItem={activeItem}
        handleMenuItemClick={(e) => handleMenuItemClick(e)}
      />
    );
  }
}

export default withRouter(withSecurity(withCart(withMobile(AdminMenu))));
