import React, { Component } from "react";
import { Icon, Header, Loader } from "semantic-ui-react";
import withSecurity from "../hc/withSecurity";
import withApp from "../hc/withApp";
import withCart from "../hc/withCart";
import { withRouter } from "react-router";
import { NumericFormat } from "react-number-format";
import AccountsPromotions from "../../assets/images/accounts-promotions.svg";
import ToastGenerator from "../../shared-react-components/utils/ToastGenerator";

import PRHButton from "../../shared-react-components/elements/prhbutton";
import { PRHContainerGeneral } from "../elements/elements";
import { ecomApi } from "../../api/ecomApi";
import { connect } from "react-redux";
import "./admin.scss";
import { Divider } from "../../shared-react-components/elements/elements";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import withMobile from "../hc/withMobile";
import NoticeCard from "../elements/NoticeCard";
import NoAccessMessage from "../../components/common/NoAccessMessage";

class Promotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      promos: false,
    };
  }

  componentDidMount() {
    this.getPromotions();
    //this.props.getPromos({}, true);
  }

  modalControl(e) {
    this.setState({ modalOpen: e });
  }

  getPromotions = async () => {
    const result = await this.props.cart.getPromos();
    // console.log('Promos', result);
    if (result.data?.status === "OK") {
      this.setState({ promos: result.data });
    }
  };

  applyPromoCode = (promoCode) => {
    const button = {
      text: "View Cart",
      icon: "cart",
      link: "/cart",
    };
    const { history } = this.props;

    // console.log('applying promo', promoCode);
    this.props.cart.applyPromoCode(promoCode).then((response) => {
      if (response.data.code === 0) {
        ToastGenerator(
          {
            text: `Promo code has been copied`,
            history,
            button,
          },
          { className: "ap-toast" }
        );
      }
    });
  };
  render() {
    //const { promos } = this.state;
    const { data: promos, isSuccess, isLoading } = this.props.promos;
    const { cart, mobile } = this.props;

    let workPromos = [];
    let workPromo = false;
    let prevPromoCode = false;
    if (promos) {
      promos.forEach((promo) => {
        if (promo.code && promo.code !== "") {
          if (prevPromoCode !== promo.code) {
            if (prevPromoCode) {
              workPromos.push(workPromo);
            }
            workPromo = { code: promo.code, promos: [promo] };
            prevPromoCode = promo.code;
          } else {
            workPromo.promos.push(promo);
          }
        }
      });
      if (workPromo) workPromos.push(workPromo);
    }
    const isMobile = mobile.isMobile;
    // console.log('promo', workPromos);
    if (cart?.customerInfo?.currentUser?.roleNo === "W1")
      return <NoAccessMessage />;
    else
      return (
        <>
          <div className="accounts-settings">
            <div>
              <div className="accounts-page-headers-images">
                <img src={AccountsPromotions} alt="Promotions" />
              </div>
              <div className="accounts-page-headers fort-light text-gray-900 fs-24px">
                Promotions
              </div>
              {!isMobile && <Divider className="accounts-header-divider" />}
              <div className={isMobile ? "mt-2" : ""}>
                Try these discount codes on your next order. Only one discount
                may be applied at a time.
              </div>
            </div>
          </div>
          {isMobile && !this.props.cart.isUserPow() && (
            <NoticeCard>
              Applying Promo codes is accessible through desktop only.
            </NoticeCard>
          )}
          <PRHContainerGeneral
            className={`content-block-border mb-6 mt-4 ar-box ${
              isMobile
                ? "promotions-container-mobile border-none pt-2 pr-2 pb-2 pl-0"
                : "pb-0"
            }`}
          >
            {!promos && <Loader active />}
            {promos && workPromos && workPromos.length === 0 && (
              <div className="mb-3">
                You have no promotional code available.
              </div>
            )}
            {workPromos &&
              workPromos.map((promo, idx) => (
                <div key={idx} className="mb-2">
                  <div className="d-flex flex-row mb-2 pr-2">
                    <div className="d-flex flex-column">
                      <div className="fort-bold">
                        {promo.promos[0].description}
                      </div>
                      {promo.promos.map((p, i2) => (
                        <div key={i2}>
                          {i2 === 0 ? (
                            <div>
                              <div>
                                Use code{" "}
                                <span className="fort-bold">{p.code}</span> to
                                save{" "}
                                <NumericFormat
                                  className=""
                                  allowNegative={false}
                                  value={p.discount}
                                  thousandSeparator={true}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  displayType={"text"}
                                  // prefix={'-'}
                                  suffix={"%"}
                                />
                              </div>
                              {p.minQty > 0 ? (
                                <div>
                                  Order must contain at least {p.minQty} books
                                </div>
                              ) : null}
                              {p.terms !== "" ? <div>{p.terms}</div> : null}
                            </div>
                          ) : (
                            <div key={i2}>
                              <div>
                                for{" "}
                                <NumericFormat
                                  className=""
                                  allowNegative={false}
                                  value={p.discount}
                                  thousandSeparator={true}
                                  decimalScale={0}
                                  fixedDecimalScale={true}
                                  displayType={"text"}
                                  // prefix={'-'}
                                  suffix={"%"}
                                />{" "}
                                off,{" "}
                                {p.minQty > 0 ? (
                                  <span>
                                    order must contain at least {p.minQty} books
                                  </span>
                                ) : null}
                                {p.terms !== "" ? <span>{p.terms}</span> : null}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      {promo.promos[0].endDate ? (
                        <div>
                          Expires{" "}
                          {formatDateMMDDYYYY(promo.promos[0].endDate, "/")}
                        </div>
                      ) : null}
                    </div>

                    {this.props.cart.isUserPow() || isMobile ? null : (
                      <div className="d-flex flex-column ml-auto">
                        <PRHButton
                          size="medium"
                          className="ap-button"
                          disabled={promo.code === cart.cart.promoCode}
                          icon={
                            promo.code === cart.cart.promoCode
                              ? "check"
                              : "plus"
                          }
                          onClick={(e) => {
                            this.applyPromoCode(promo.code);
                            // applyPromoCode(promo.code);
                            // setCurrentPromo(promo.code);
                            // setOpen(false);
                          }}
                        >
                          {promo.code === cart.cart.promoCode
                            ? "Applied"
                            : "Apply Code"}
                        </PRHButton>
                      </div>
                    )}
                  </div>
                  {idx < workPromos.length - 1 ? (
                    <Divider className="mt-2" tight />
                  ) : null}
                </div>
              ))}
          </PRHContainerGeneral>
        </>
      );
  }
}

const mapState = (state) => ({
  promos: ecomApi.endpoints.getPromos.select({}, true)(state),
});
const mapDispatch = {
  getPromos: ecomApi.endpoints.getPromos.initiate,
};
const connector = connect(mapState, mapDispatch);

export default connector(
  withRouter(withSecurity(withApp(withCart(withMobile(Promotions)))))
);
