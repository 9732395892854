import { useState, useEffect, useLayoutEffect } from "react";
import { ItemGroup, Menu } from 'semantic-ui-react';
import './elements.scss';

export const MenuVerticalPointing = (props) => {
  //const [activeItem, setActiveItem] = useState('home');
  const [scrollY, setScrollY] = useState(0)
  const { menu, handleMenuItemClick, activeItem, header } = props;
  useLayoutEffect(() => {
    const handleScroll = e => {
      setScrollY(window.scrollY)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])
  //handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  //render() {
    //const { menu, handleMenuItemClick, activeItem, header } = this.props;
    // console.log('header', header);
    //style={{'--scrollY': `${Math.min(0, scrollY/3)}px`}
    return (
      <>
      <div className={scrollY > 0 ? ('admin-menu admin-menu-sticky'):('admin-menu admin-menu-50')}>
        {/*<div className={'admin-menu'}>*/}
          <Menu text vertical>
            <Menu.Item header>{header}</Menu.Item>
            {menu
              ? menu.map((item, i) => {
                  return (
                    <Menu.Item
                      key={i}
                      content={<span>{item.label}</span>}
                      active={item.name === activeItem}
                      onClick={() => handleMenuItemClick(item.component)}
                    />
                  );
                })
              : ''}
          </Menu>
        </div>
      </>
    );
  //}
}
