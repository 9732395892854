import { Component } from "react";
import { withRouter } from "react-router";
import { Icon, Popup } from "semantic-ui-react";
import { Form, Input } from "semantic-ui-react-form-validator";
import PageStandard from "../../components/elements/PageStandard";
import withApp from "../../components/hc/withApp";
import withCart from "../../components/hc/withCart";
import withSecurity from "../../components/hc/withSecurity";
import FormContainer from "../../elements/FormContainer";
import { Divider } from "../../shared-react-components/elements/elements";
import PRHButton from "../../shared-react-components/elements/prhbutton";
import { TOOLTIPS } from "../../utils/const";
import "./CompCopyLogin.scss";

class CompCopyLogin extends Component {
  constructor(props) {
    super(props);
    // 1152010003
    this.state = {
      sapAccountNo: "",
      validAccountNo: false,
      errorMsg: false,
    };
  }

  componentDidMount() {
    const sapAccountNo = localStorage.getItem("sapAccountNo");
    if (sapAccountNo) {
      let newState = { sapAccountNo: sapAccountNo.trim() };
      var cc = /^[\d|a-z|A-Z]{4,10}$/;
      if (sapAccountNo !== "" && sapAccountNo.match(cc) !== null) {
        newState.validAccountNo = true;
      }
      this.setState(newState);
    }
  }
  enterID = (e) => {
    this.setState({ sapAccountNo: e.target.value });
  };

  onSubmit = async () => {
    // console.log('onSubmit', this.state.sapAccountNo, this.state.validAccountNo);
    if (this.state.validAccountNo) {
      localStorage.setItem(
        "sapAccountNo",
        this.state.sapAccountNo.padStart(10, "0")
      );
      this.props.cart
        .getCustomerInfo(this.state.sapAccountNo.padStart(10, "0"), true)
        .then((result) => {
          // console.log("cc customInfo", result);
          if (result?.error?.status === 404) {
            this.setState({
              errorMsg:
                "Account number entered is not available for comp copy.",
            });
          } else if (result.data.data.customer.customerClass !== "S1") {
            // console.log('s1 error');
            this.setState({
              errorMsg:
                "Account number entered is not available for comp copy.",
            });
          } else {
            this.props.cart.setCCSapAccountNo(
              this.state.sapAccountNo.padStart(10, "0")
            );
            this.props.cart.loadUserCart(
              this.state.sapAccountNo.padStart(10, "0")
            );
            this.props.history.push({ pathname: "/" });
          }
        });
    }
    // const { Ecom_User_ID } = this.state;
    // this.props.app.accountApi.storeUserId(Ecom_User_ID);
    // console.log(this.validateEmail(Ecom_User_ID));
    // if (this.validateEmail(Ecom_User_ID) === false) {
    //   this.props.history.push('/legacy');
    // } else {
    //   document.getElementById('loginForm').submit();
    // }
  };

  handleChange = (e) => {
    const newState = { ...this.state };
    let validAccountNo = false;

    // let cc = new RegExp('/^[0-9]{10}$/');
    var cc = /^[\d|a-z|A-Z]{4,10}$/;
    newState[e.target.name] = e.target.value.trim();

    // console.log(
    //   'test ',
    //   newState[e.target.name],
    //   newState[e.target.name].match(cc)
    // );
    // if (newState[e.target.name].match(cc) !== null) {
    //   validAccountNo = true;
    // }

    if (
      newState[e.target.name] !== "" &&
      newState[e.target.name].match(cc) !== null
    ) {
      validAccountNo = true;
    }

    newState.validAccountNo = validAccountNo;
    this.setState(newState);
  };

  render() {
    const { sapAccountNo, password } = this.state;
    const { location } = this.props;
    // const showMe = IDENTITY_CONFIG.base + '/user/bdasgupta';
    // console.log(showMe);
    // console.log('this props', this.props);
    // console.log('this state', this.state);
    return (
      <>
        <PageStandard>
          <div className='w-100 d-flex justify-content-center align-items-center mt-4 mb-3 cc-login'>
            <div className='text-uppercase fort-bold fs-2 cc-login-header'>
              Comp Copy Ordering
            </div>
          </div>
          <FormContainer>
            {location.state &&
            location.state.err === "Login failed, please try again." ? (
              <div className={`ui negative message`}>
                <div className={`header`}>{location.state.err}</div>
              </div>
            ) : (
              ""
            )}
            <div>
              <div className='fort-book mb-2 fs-3'>Sign In</div>
              <div className='fort-book mb-2 fs-2'>
                Welcome to Comp Copy, Penguin Random House’s system for ordering
                free and review copies. Comp Copy is intended for business
                purposes only. To sign in, please enter your SAP Account Number
                below.
              </div>
            </div>
            <Divider vertical tight className='mt-4 mb-4' />
            <Form
              width={12}
              ref={this.loginForm}
              instantValidate={false}
              onSubmit={(e) => this.onSubmit()}
              method='post'
              //action="https://idp.dev.penguinrandomhouse.com/nidp/app/login?option=credential&forceAuth=true"
              // action={loginAction}
              id='loginForm'
            >
              <Input
                name='sapAccountNo'
                // type="number"
                className='cc-account-no'
                // max="9999999999"
                onChange={this.handleChange}
                validators={["required"]}
                errorMessages={["this field is required"]}
                value={sapAccountNo}
                label={
                  <label className={"fort-light fs-2 mb-1"}>
                    SAP Account Number{" "}
                    <Popup
                      className='cp-help-tooltip'
                      content={TOOLTIPS["sapNumber"]}
                      on='click'
                      position='top center'
                      offset={[0, 5]}
                      trigger={
                        <Icon
                          name='question circle outline'
                          className='sb_question_icon'
                        />
                      }
                    />
                  </label>
                }
                width={16}
              />
              {this.state.errorMsg ? (
                <div className='cclogin-error fort-bold'>
                  {this.state.errorMsg}
                </div>
              ) : null}
              <br />
              <div className='d-flex flex-column'>
                <strong>Don't have an SAP Account Number?</strong>
                <div className='mt-1'>
                  Please send an email to{" "}
                  <a href='mailto:newaccount@penguinrandomhouse.com'>
                    newaccount@penguinrandomhouse.com
                  </a>{" "}
                  and cc your business manager for approval.
                </div>
              </div>

              <br />
              <div className='d-flex justify-content-center'>
                <PRHButton
                  disabled={!this.state.validAccountNo}
                  iconright
                  icon='arrow right'
                  size='large'
                  className='mt-4'
                  onClick={(e) => this.onSubmit(null)}
                  backgroundColorOverride={"blue"}
                  textColorOverride={"white"}
                  textColorHoverOverride={"blue"}
                  iconColorOverride={"white"}
                  iconColorHoverOverride={"blue"}
                  backgroundHoverColorOverride={"white"}
                  borderColorHoverOverride={"blue"}
                >
                  Sign In
                </PRHButton>
              </div>
            </Form>
          </FormContainer>
        </PageStandard>
      </>
    );
  }
}

export default withRouter(withSecurity(withApp(withCart(CompCopyLogin))));
