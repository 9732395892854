import { useState, useEffect } from "react";
import {
  Header,
  Loader,
  TextArea,
  Icon,
  Popup,
  Table,
  Radio,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import DialogModal from "./DialogModal";
import { Divider, PRHError } from "../elements/elements";
import { NumericFormat } from "react-number-format";
import TitleRow from "../common/TitleRow";
import { formatDateMMDDYYYY } from "../../utils/utilities";
import useCart from "../hc/useCart";
import "./ConfirmationCartDetailsModal.scss";

const ConfirmationCartDetailsModal = ({
  foc,
  shipTo,
  open = false,
  setOpen,
  proceedCallback,
  cancelCallback,
}) => {
  const cart = useCart();
  const [dialogVisible, setDialogVisible] = useState(false);
  const [creditCard, setCreditCard] = useState({});
  const resetState = () => {
    setDialogVisible(false);
  };

  const CloseAction = {
    label: "Close",
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      resetState();
      if (cancelCallback) cancelCallback();
      return { proceed: true };
    },
  };

  const EditAction = {
    label: "Edit Payment & Order Details",
    borderColorOverride: "blue",
    textColorOverride: "white",
    textColorHoverOverride: "blue",
    backgroundColorOverride: "blue",
    backgroundHoverColorOverride: "white",
    iconColorOverride: "white",
    iconColorHoverOverride: "blue",
    icon: "pencil",
    preAction: () => {
      return { proceed: true };
    },
    action: () => {
      if (proceedCallback) proceedCallback();
      return { proceed: true };
    },
  };

  const shipToAddress = cart.customerInfo.shipToAccountNos[cart.shipTo];

  useEffect(() => {
    if (open && cart.cart?.headers?.cardId) {
      cart.getCreditCards(cart.cart?.headers?.cardId).then((cc) => {
        // console.log('getcredit card', cc, cart.cart.headers);
        if (cc.data?.status === "OK") {
          cc.data.data?.creditCards.forEach((c) => {
            if (c.cardId === cart.cart.headers.cardId) {
              setCreditCard(c);
            }
          });
        }
      });
    }
  }, [open]);

  return (
    <DialogModal
      open={open}
      setOpen={setOpen}
      // onXClose={cancelCallback}
      modalClassName='ccd-modal'
      modalContentClass='m-0 pt-0'
      dialogOptions={{
        header: (
          <Header className='fs-12px fort-light-bold fs-2 lh-2 my-0 mr-5 text-uppercase'>
            PAYMENT & ORDER DETAILS
          </Header>
        ),
        actions: [CloseAction, EditAction],
      }}
    >
      <div className='ar-box pl-3 pr-3 fs-13px lh-18px text-gray-900'>
        {cart.cart?.headers?.shipToAccountNo && (
          <div className='mt-1 mb-1'>
            <div className='fort-bold mb-1'>Ship-To:</div>
            <div className='fort-book fs-13px lh-18px'>
              {shipToAddress?.address?.name1}
            </div>
            {shipToAddress?.address?.name2 && (
              <div className='fort-book fs-13px lh-18px'>
                {shipToAddress?.address?.name2}
              </div>
            )}
            {shipToAddress?.address?.name3 && (
              <div className='fort-book fs-13px lh-18px'>
                {shipToAddress?.address?.name3}
              </div>
            )}
            <div className='fort-book fs-13px lh-18px'>
              {shipToAddress?.address?.street}
            </div>
            <div className='fort-book fs-13px lh-18px'>
              {shipToAddress?.address?.city}, {shipToAddress?.address?.state}
              {"  "}
              {shipToAddress?.address?.postalCode}
            </div>
          </div>
        )}
        <Divider tight className='mt-2 mb-2' />
        {cart.cart?.headers?.poNumber && (
          <div className='d-flex flex-row '>
            <div className='fort-bold w-50'>Purchase Order: </div>
            <div className='fort-book w-50'>{cart.cart?.headers?.poNumber}</div>
          </div>
        )}
        {cart.cart?.headers?.invoiceText &&
          cart.cart?.headers?.invoiceText !== "" && (
            <div className='d-flex flex-row '>
              <div className='fort-bold w-50'>Invoice Description: </div>
              <div className='fort-book w-50'>
                {cart.cart?.headers?.invoiceText}
              </div>
            </div>
          )}
        {cart.cart?.headers?.phone && (
          <div className='d-flex flex-row'>
            <div className='fort-bold w-50'>Phone #: </div>
            <div className='fort-book w-50'>{cart.cart?.headers?.phone}</div>
          </div>
        )}

        <Divider tight className='mt-2 mb-2' />
        {cart.cart?.headers?.eventContactName && (
          <>
            <div className='d-flex flex-row'>
              <div className='fort-bold w-50'>Order is for an event: </div>
              <div className='fort-book w-50'>Yes</div>
            </div>
            <div className='d-flex flex-row'>
              <div className='fort-bold w-50'>Event Date: </div>
              <div className='fort-book w-50'>
                {formatDateMMDDYYYY(cart.cart?.headers?.eventDate, "/")}
              </div>
            </div>
            <div className='d-flex flex-row'>
              <div className='fort-bold w-50'>Coordinator's Full Name: </div>
              <div className='fort-book w-50'>
                {cart.cart?.headers?.eventContactName}
              </div>
            </div>
            <div className='d-flex flex-row'>
              <div className='fort-bold w-50'>Coordinator's Phone #: </div>
              <div className='fort-book w-50'>
                {cart.cart?.headers?.eventContactPhone}
              </div>
            </div>
            <Divider tight className='mt-2 mb-2' />
          </>
        )}

        {cart.cart?.headers?.payByCreditCard === "true" ? (
          <div
            className='d-flex flex-column mt-1 mb-2 '
            style={{ position: "relative" }}
          >
            <div className='fort-bold'>Pay by Credit Card</div>
            {creditCard?.cardId ? (
              <div className='fort-book'>
                Card ending in {creditCard?.cardLastFour} |{" "}
                {creditCard?.cardholderName} | Expires:{" "}
                {creditCard?.cardExpiryMonth}/{creditCard?.cardExpiryYear}
              </div>
            ) : (
              <Loader active />
            )}
          </div>
        ) : (
          <div className='d-flex flex-column  mt-1 mb-2'>
            <div className='fort-bold'>Payment: Bill to Account</div>
            {/* <div className="fort-book">Account #{cart.cart.accountNo}</div> */}
          </div>
        )}

        {cart.cart?.promoCode && (
          <>
            <Divider tight className='mt-2 mb-2' />
            <div className='d-flex flex-row mt-1 mb-1'>
              <div className='fort-bold w-50'>Promo Code Applied: </div>
              <div className='fort-book w-50'>{cart.cart?.promoCode}</div>
            </div>
          </>
        )}
      </div>
    </DialogModal>
  );
};

ConfirmationCartDetailsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

ConfirmationCartDetailsModal.defaultProps = {};

export default ConfirmationCartDetailsModal;
